// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerWrapper[data-v-a2dcb67a] {
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./websrc/components/2fa/admin/recoveryEntry.vue","webpack://./recoveryEntry.vue"],"names":[],"mappings":"AACA;EACE,gBAAA;ACAF","sourcesContent":["\n.containerWrapper {\n  text-align: left;\n}\n",".containerWrapper {\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
