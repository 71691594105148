 <template>
  <div class="dayTimeOverlay d-flex flex-row">
    <!-- <span class="mx-2">{{$t('components.sambaRooms.totalUsers')}}:&nbsp;{{getTotalUsers}}</span> -->
    <div style="width:80%"></div>
    <span class="mx-2 pt-1" v-if="room.isFavorite">
      <font-awesome-icon :icon="['fas', 'star']" class="yellow-star" />
    </span>
  </div>
</template>
<script>
import store from "../../store";
import {totalUsersInRoom} from "../../utils/staticRoom"
export default {
  props: ["room"],
  data() {
    return {
      state: store.state,
    };
  },
  mounted: function () {},
  methods: {
  },
  computed: {
    getTotalUsers(){
      return totalUsersInRoom(this.room)
    }
  },
};
</script>
<style scoped lang="scss">
.dayTimeOverlay {
  // background-color: rgba(0, 0, 0, 0.50);
  position: absolute;
  top: 0px;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;

  .yellow-star {
    color: yellow;
  }
}
</style>