<template>
    <vue-scroll  :class="{'vueScrollVisitors': applyClass, 'vueScrollOne': applyOne, 'vueScrollTwo': applyTwo, 'vueScrollThree': applyThree}" v-if="visitors.length > 0">
      <div v-for="(item, index) in sortedVisitors" :key="index" class="person" :id="'visitor' + index">
          <VisitorListItem v-if="!item.user" flat :visitor="item" :section="section" />
          <VisitorListItem v-if="item.user" flat :visitor="item.user" :section="section" />
      </div>
    </vue-scroll>
</template>

<script>
import VisitorListItem from './visitorListItem.vue';
import store from '../../store';
export default {
  components: { VisitorListItem },
  props: ['visitors', 'section'],
  data() {
    return {
      state: store.state,
    };
  },
  methods:{
  },
  computed:{
   applyClass(){
      return ((this.section == 'My Visitors' || this.section=='My Invites') && Object.keys(this.visitors).length >= 3 );
    },
    applyOne(){
      return (this.state.searchTerm.length != '' && Object.keys(this.visitors).length <=1) || ((this.section == 'My Visitors' || this.section=='My Invites') && Object.keys(this.visitors).length <= 1 )
    },
    applyTwo(){
      return (this.state.searchTerm.length != '' && Object.keys(this.visitors).length == 2) || ((this.section == 'My Visitors' || this.section=='My Invites') && Object.keys(this.visitors).length == 2 )
    },
     applyThree(){
      return (this.state.searchTerm.length != '' && Object.keys(this.visitors).length == 3) || ((this.section == 'My Visitors' || this.section=='My Invites') && Object.keys(this.visitors).length == 3 )
    },
    sortedVisitors(){
      return [...this.visitors]
        .sort((a, b) => {
          return a.name < b.name ? -1 : (a.name > b.name ? 1 : 0)
        });
    }
  }
};
</script>

<style scoped lang="scss">

.scroller {
  height: 100%;
}
.vueScrollVisitors{
  height: 500px !important;
    @media screen and (max-height: 650px) {
      height: 500px !important;
    }
  & .__view{
    width: unset !important;
  }
}

.vueScrollOne{
  height: 150px !important;
   @media screen and (max-height: 650px) {
      height: 150px!important;
  }
}

.vueScrollTwo{
  // max-height: calc(100vh - 720px)!important;
  height: 300px !important;
   @media screen and (max-height: 650px) {
      height: 300px!important;
  }
}

.vueScrollThree{
  // max-height: calc(100vh - 720px)!important;
  height: 450px !important;
   @media screen and (max-height: 650px) {
      height: 450px!important;
  }
}

</style>

<style lang="scss">
.vueScrollVisitors{
  .__view{
    width: unset !important;
  }
}
</style>