import store, { EventBus } from '../store'; // eslint-disable-line no-unused-vars
// import { aDelay } from '../lib/asyncUtil';
import MD5 from './md5';
import { getIceServers } from './rtcSettings';
import { rtcMsg, sendNotificationToUUID } from './wsMsg';
import { getOwnUUID } from './wsConnect';
import Vue from '@vue/compat';
// import { maybePreferCodec, preferBitRate } from './sdpUtils';
import { getUserMedia } from './mediaDevices';
import RpcManager from './rpcManager';
import { allRTCConnectionsClosedEvent, callTransferedEvent, RTCConnectionOpenedEvent, typingInfoEvent, /*requestPaymentEvent,*/ successPaymentEvent } from '../effector/rtc';
import { showUserIsTypingEvent } from '../effector/message';
import { /*setQualityVotingModalEvent,*/ setRequestPaymentEventModal, setPaymentSuccessEventModal, setPaymentInProgressModal, setPaymentInProgressEventModal } from '../effector/modals';
import { dispatchSuccessAlert, } from '../effector/alerts';

import CallInfoManager from './callInfoManager';

const salvagedRtc = RTCPeerConnection;
const salvagedRTCSessionDescription = window.RTCSessionDescription;

// const CHUNK_LEN = 5000;
const CHUNK_LEN = 62000;
// const CHUNK_LEN = 25;

const webrtcConnections = {};

try {
  document.webrtcConnections = webrtcConnections;
} catch (err) { }

export { MD5 };

const dialGroup = [];
let currentCallStat;

function addToDialGroup(uuid) {
  if (dialGroup.indexOf(uuid) === -1) {
    dialGroup.push(uuid);
    pokeOnChangeDialGroup();
  }
}

function removeFromDialGroup(uuid) {
  const index = dialGroup.indexOf(uuid);
  if (index !== -1) {
    dialGroup.splice(index, 1);
    pokeOnChangeDialGroup();
  }
}

function broadcastDialGroup() {
  Object.values(webrtcConnections).forEach((webrtc) => {
    if (webrtc.hasMedia && !webrtc.onHold) {
      webrtc.sendDialGroup();
    }
  });
}

function updateCallStat() {
  const dialGroupCopy = [...dialGroup];
  if (!currentCallStat) {
    if (dialGroupCopy.length) {
      // New call
      currentCallStat = {
        startTs: Date.now(),
        endTs: null,
        events: [{
          type: 'users', users: dialGroupCopy, ts: Date.now(),
        }],
      };
      // console.log('updateCallStat - New Current Call', dialGroupCopy, currentCallStat);
    } else {
      // No call and no group, why are we here? Do nothing
      // console.warn('updateCallStat - dialGroup and currentCallStat are both nothing, what?', dialGroupCopy, currentCallStat);
    }
  } else {
    // Have a current call
    if (dialGroupCopy.length) {
      // Current call still going, maybe different members?
      // console.log('updateCallStat - Current Call Changed', dialGroupCopy, currentCallStat);
      currentCallStat.events.push({
        type: 'users', users: dialGroupCopy, ts: Date.now(),
      });
    } else {
      // Call we had is over, yay!
      // currentCallStat.events.push({ type: 'end', ts: Date.now(), });
      currentCallStat.endTs = Date.now();
      // console.log('updateCallStat - Current Call Finished', dialGroupCopy, currentCallStat);
      // Send / Save somewhere?
      currentCallStat.users = [];
      for (const event of currentCallStat.events) {
        if (event.type === 'users') {
          const usersToAppend = event.users.filter((uuid) => currentCallStat.users.indexOf(uuid) === -1);
          if (usersToAppend.length) currentCallStat.users = currentCallStat.users.concat(usersToAppend);
        }
      }
      if (currentCallStat.users.length > 1) store.appendGroupCallHistory({ ...currentCallStat });
      currentCallStat = undefined;
    }
  }
}

function onChangeDialGroup() {
  try {
    broadcastDialGroup();
    updateCallStat();
    pokeGroupCallMonitor(1000);
  } catch (err) {
    console.warn('onChangeDialGroup', err);
  }
}

let onChangeDialGroupTimer = undefined;
function pokeOnChangeDialGroup() {
  if (onChangeDialGroupTimer) clearTimeout(onChangeDialGroupTimer);
  onChangeDialGroupTimer = setTimeout(onChangeDialGroup, 500);
}

function groupCallMonitor() {
  try {
    if (!dialGroup.length) return;

    for (let uuid of dialGroup) {
      const peer = webrtcConnections[uuid];
      if (!peer) {
        // console.log('groupCallMonitor Calling:', uuid);
        // callUUID(uuid, true, true);
        callUUID(uuid, { force: true, join: true });
      }
    }

    Object.values(webrtcConnections).forEach((peer) => {
      if (!peer.dead && peer.inited && peer.hasMedia && !peer.onHold && dialGroup.indexOf(peer.uuid) === -1) {
        // console.log('groupCallMonitor Killing:', peer.uuid, peer.name);
        peer.die(true);
      }
    });
  } catch (err) {
    console.warn('groupCallMonitor', err);
  }

  pokeGroupCallMonitor();
}

let groupCallMonitorTimeout;
function pokeGroupCallMonitor(ms = 2500) {
  if (groupCallMonitorTimeout) clearTimeout(groupCallMonitorTimeout);
  groupCallMonitorTimeout = setTimeout(groupCallMonitor, ms);
}

async function checkRTPSenderOrReceiver(rtp) {
  if (!rtp) throw new Error('Null RTP');

  if (rtp.transport) {
    if (rtp.transport.state !== 'connected') {
      // console.log('checkRTPSenderOrReceiver - Stream Transport Not Connected', rtp.transport.state, rtp);
      throw new Error('Transport Not Connected');
    }
  } else {
    // console.log('checkRTPSenderOrReceiver - Stream Transport Not Connected', rtp);
    throw new Error('Transport Missing');
  }

  if (rtp.track) {
    if (rtp.track.readyState !== 'live') {
      // console.log('checkRTPSenderOrReceiver - Stream Track readyState Not Live', rtp.track.readyState, rtp);
      throw new Error('Track Not Ready');
    }

    if (rtp.track.enabled !== true) {
      // console.log('checkRTPSenderOrReceiver - Stream Track enabled Not True', rtp.track.enabled, rtp);
      throw new Error('Track Not Enabled');
    }
  }
  // Else an error? Might just not be sending anything on a given MLINE
}

async function checkRTPTranceiver(transceiver) {
  if (!transceiver) throw new Error('Null Transceiver');
  if (transceiver.stopped) {
    throw new Error('Transceiver Stopped');
  }
  if (transceiver.sender) {
    await checkRTPSenderOrReceiver(transceiver.sender);
  }
  if (transceiver.receiver) {
    await checkRTPSenderOrReceiver(transceiver.receiver);
  }
}

async function dumpWebrtcInfo(webrtc) {
  if (!webrtc) throw new Error('Null Webrtc');
  const pc = webrtc.pc;
  const receivers = await pc.getReceivers();
  const senders = await pc.getSenders();
  const transceivers = await pc.getTransceivers();
  const localStreams = await pc.getLocalStreams();
  const remoteStreams = await pc.getRemoteStreams();
  const configuration = await pc.getConfiguration();
  console.log('dumpWebrtcInfo', webrtc.name, webrtc.uuid, { receivers, senders, transceivers, localStreams, remoteStreams, configuration });
}

// eslint-disable-next-line no-unused-vars
async function checkWebrtcForProblems(webrtc) {
  if (!webrtc) throw new Error('Null Webrtc');
  // console.log('checkWebrtcForProblems', webrtc.name, webrtc.uuid, webrtc);
  await dumpWebrtcInfo(webrtc);

  // Check local senders for problems
  const senderIds = Object.keys(webrtc.senders);
  for (const senderId of senderIds) {
    const sender = webrtc.senders[senderId];
    await checkRTPSenderOrReceiver(sender);
  }

  // Check PeerConnection
  const transceivers = await webrtc.pc.getTransceivers();
  for (const transceiver of transceivers) {
    await checkRTPTranceiver(transceiver);
  }
}

/*
async function streamWatchdog() {
  if (!store.state.nerd) return;
  try {
    // console.log('- StreamWatchdog Tick');
    const uuids = Object.keys(webrtcConnections);
    for (const uuid of uuids) {
      const webrtc = webrtcConnections[uuid];
      try {
        await checkWebrtcForProblems(webrtc);
      } catch (err) {
        // console.warn('StreamWatchdog - Issue with connection', err.message, webrtc.name, webrtc.uuid, webrtc);
      }
    }
  } catch (err) {
    // console.warn('StreamWatchdog Err:', err);
  }
}
*/

// setInterval(streamWatchdog, 10000);

// Reusing transceivers seems to be a pain -- https://groups.google.com/forum/#!topic/discuss-webrtc/EgMmxXHidA0

export function amIConnectedTo(uuid) {
  const guy = webrtcConnections[uuid];
  if (!guy) return false;
  if (guy.pc.iceConnectionState === 'connected') return true;
  return false;
}

class RtcConnection {
  constructor(uuid) {
    if (webrtcConnections[uuid]) webrtcConnections[uuid].close();

    this.initiator = uuid.localeCompare(store.state.ownUUID) === 1;

    this.start = Date.now();
    this.uuid = uuid;
    this.name = store.getNameForUuid(uuid);
    this.inited = false;
    this.dead = false;
    // this._negotiating = false;
    this.senders = {};
    this.hasMedia = false;
    this.onDcOpenHandler = undefined;
    this.onHold = false;

    this.inc_transfers = {};

    this.rpc = new RpcManager({ timeout: 5000 });
    this.rpc.on('request', (rpc) => { this.sendObj({ type: 'rpc', rpc }); });
    this.rpc.on('answer', (rpc) => { this.sendObj({ type: 'rpc_ans', rpc }); });

    this.rpc.addHandler('startFileSend', (fileName, size) => {
      // console.log('File Send Start', this.name, fileName, size);
      this.inc_transfers[fileName] = { chunks: [], expectedSize: size };
      store.patchFileTransferFor(this.uuid, fileName, { incoming: true, fileName, expectedSize: size, sizeSoFar: 0, finalData: undefined, complete: false, uuid: this.uuid });
      return true;
    });

    this.rpc.addHandler('chunkFileSend', (fileName, chunk) => {
      if (!this.inc_transfers[fileName]) throw new Error('Got chunk file for nonexistant transfer?', this.name, fileName, chunk);
      // console.log('File Send Chunk', this.name, fileName, chunk.length);
      this.inc_transfers[fileName].chunks.push(chunk);
      store.patchFileTransferFor(this.uuid, fileName, { sizeSoFar: store.getFileTransferFor(this.uuid, fileName).sizeSoFar + chunk.length });
    });

    this.rpc.addHandler('endFileSend', (fileName) => {
      if (!this.inc_transfers[fileName]) throw new Error('Got end file for nonexistant transfer?', this.name, fileName);
      // TODO: modal to display file? Some sort of UI thing?
      const finalBuffer = this.inc_transfers[fileName].chunks.join('');
      if (finalBuffer.length !== this.inc_transfers[fileName].expectedSize) throw new Error('Final length mismatch');
      // console.log('File Send End', this.name, fileName, finalBuffer);
      store.patchFileTransferFor(this.uuid, fileName, { sizeSoFar: finalBuffer.length, complete: true, finalData: finalBuffer, actualMD5: MD5(finalBuffer) });
      delete this.inc_transfers[fileName];
      // this.dieIfUnneeded();
    });

    this.rpc.addHandler('setOnHold', (isOnHold) => {
      this.setOnHold(isOnHold);
    });

    this.rpc.addHandler('transfer_call', async (uuid) => {
      // await callUUID(uuid, true, false);
      await callUUID(uuid, { force: true });
    });

    this.rpc.addHandler('transfer_ready', async (uuid) => {
      if (!webrtcConnections[uuid]) new RtcConnection(uuid);
      await webrtcConnections[uuid].attachMedia();
    });

    this.rpc.addHandler('paymentRequested', (payload) => {
      // TODO: event to summon modal where person pays
      // showPaymentoal()payload;
      // console.log('SHOOOOOOOOOWWWWWWW', payload, this.uuid)
      setRequestPaymentEventModal(payload);
    });

    this.rpc.addHandler('inprogressRequested', (payload) => {
      // TODO: event to summon modal where person pays
      // showPaymentoal()payload;
      // console.log('SHOOOOOOOOOWWWWWWW', payload, this.uuid)
      setPaymentInProgressEventModal(payload);
    });

    
    this.rpc.addHandler('paymentSuccess', (payload) => {
      // TODO: event to summon modal where person pays
      // showPaymentoal()payload;
      setPaymentSuccessEventModal(payload);
      setPaymentInProgressModal(false)
    });

    this.rpc.addHandler('getCallInfoState', () => { return CallInfoManager.getState(); });
    this.rpc.addHandler('setCallInfoState', (s) => { return CallInfoManager.setStateFromRemote(s, this); });
    this.rpc.addHandler('setMessageToParticipants', (s) => { return CallInfoManager.setMessageToParticipants(s); });
    this.rpc.addHandler('setPresentationView', (s) => { return CallInfoManager.setPresentationView(s); });
    this.rpc.addHandler('setSpeakerRequestForUUID', (s, e) => { return CallInfoManager.setSpeakerRequestForUUID(s, e); });
    this.rpc.addHandler('setFullSizeForUUID', (s) => {
      if (CallInfoManager.isUUIDOwnerOfPresentation(this.uuid)) {
        return CallInfoManager.setFullSizeForUUID(s);
      } else {
        throw new Error('Not allowed to setFullSizeForUUID');
      }
    });

    this.rpc.addHandler('queryConnectedToUUID', (uuid) => {
      return amIConnectedTo(uuid);
    });

    this.rpc.addHandler('typingInfoEventRequested', (payload) => {
      // console.log(payload, 'payloooooad')
      if (payload.show) {
        showUserIsTypingEvent(payload);
      } else {
        showUserIsTypingEvent(false);
      }
      //
    });

    this.offerOptions = { OfferToReceiveAudio: true, OfferToReceiveVideo: true, offerToReceiveAudio: true, offerToReceiveVideo: true, mandatory: { OfferToReceiveAudio: true, OfferToReceiveVideo: true } };
    this.pc = new salvagedRtc({
      iceServers: getIceServers(),
      iceTransportPolicy: (store.state.namespaceSettings.forceRelay || store.state.user.individualRelay) ? 'relay' : 'all',
      bundlePolicy: 'max-bundle',
    });

    this.pc.addEventListener('datachannel', this.onDc.bind(this));
    this.pc.addEventListener('track', this.onTrack.bind(this));
    this.pc.addEventListener('icecandidate', this.onIceCandidate.bind(this));
    this.pc.addEventListener('icegatheringstatechange', this.onIceGatheringStateChanged.bind(this));
    this.pc.addEventListener('iceconnectionstatechange', this.onIceConnectionStateChanged.bind(this));
    this.pc.addEventListener('signalingstatechange', this.onSignalingStateChanged.bind(this));

    // TODO: event listener?
    this.pc.onnegotiationneeded = this.onNegotiationNeeded.bind(this);

    this.currentRenegotiatePromise = Promise.resolve();
    this.currentRenegotiatePromiseResolve = undefined;

    Vue.set(store.state.rtc, this.uuid, { signalingState: 'Init', iceConnectionState: 'Init', iceGatheringState: 'Init', ringingState: 'Init', stats: undefined, onHold: false });
    // Vue.set(store.state.remoteStreams, this.uuid, {});

    webrtcConnections[this.uuid] = this;
    updateRtcStatus();

    this.initDC();

    this.updateStats();
    this.statInterval = setInterval(this.updateStats.bind(this), 5000);
  }

  async getRemoteCallInfoState() {
    return await this.rpc.rpc('getCallInfoState');
  }

  async setRemoteCallInfoState(s) {
    return await this.rpc.rpc('setCallInfoState', s);
  }

  async hasRemoteConnectionTo(uuid) {
    return await this.rpc.rpc('queryConnectedToUUID', uuid);
  }

  async transferToUuid(uuid) {
    if (!webrtcConnections[uuid] || webrtcConnections[uuid].dead || !webrtcConnections[uuid].inited) throw new Error('Missing connection to ' + uuid);
    if (this.dead || !this.inited) throw new Error('Missing connection self!');

    // if (CallInfoManager.amIOwner()) {
    //   CallInfoManager.transferOwnership(uuid);
    //   await aDelay(1000);
    // }

    // console.log('call transfer', this.uuid, '=>', uuid);
    await webrtcConnections[uuid].rpc.rpc('transfer_ready', this.uuid);
    if (Object.keys(webrtcConnections).length == 2) {
      // console.log('kill connection with', uuid);
      webrtcConnections[uuid].die();
    }
    await this.rpc.rpc('transfer_call', uuid);
    this.die();
  }

  async updateStats() {
    try {
      if (this.dead) return;
      Vue.set(store.state.rtc[this.uuid], 'stats', await this.pc.getStats());
    } catch (err) {
      console.warn('updateStats Error:', this.name, err);
    }
  }

  async setOnHold(newVal) {
    this.onHold = newVal;
    Vue.set(store.state.rtc[this.uuid], 'onHold', newVal);

    if (newVal) {
      // await this.detachMedia();
      this.setSendersEnabled(false);
      // await this.setTransceiversEnabled(false);
      await removeFromDialGroup(this.uuid);
    } else {
      // await this.attachMedia();
      this.setSendersEnabled(true);
      // await this.setTransceiversEnabled(true);
      await addToDialGroup(this.uuid);
    }
  }

  // async setTransceiversEnabled(newVal) {
  //   const transceivers = await this.pc.getTransceivers();
  //   transceivers.forEach(t => t.stopped = !newVal);
  // }

  setSendersEnabled(newVal) {
    for (const id in this.senders) {
      const s = this.senders[id];
      // console.log('setSendersEnabled', id, s, newVal);
      // s.track.enabled = newVal;
      if (newVal) {
        if (s.trackBackup) {
          s.replaceTrack(s.trackBackup);
        }
      } else {
        if (s.track) {
          s.trackBackup = s.track;
          s.replaceTrack(null);
        }
      }
    }
  }

  dieIfUnneeded() {
    // console.log('dieIfUnneeded', this.name, this.inc_transfers, this.hasMedia);
    if (Object.keys(this.inc_transfers).length === 0 && !this.hasMedia) {
      // console.log('Connection for filetransfer no longer needed', this.name);
      this.die();
    } else {
      // console.log('Not dying yet!');
    }
  }

  invokeDeath() {
    this.dc.send('OHGOD_'.repeat(900000));
  }

  async sendFile(fileName, bigString) {
    if (!fileName) throw new Error('Missing fileName');
    if (!bigString || !bigString.length) throw new Error('Bad data');

    // Init file sending
    const result = await this.rpc.rpc('startFileSend', fileName, bigString.length);
    if (!result) return false;

    // chunk sending logic
    while (bigString.length) {
      let chunk;
      if (bigString.length > CHUNK_LEN) {
        chunk = bigString.slice(0, CHUNK_LEN); // getting chunk using predefined chunk length
      } else {
        chunk = bigString;
      }

      await this.rpc.rpc('chunkFileSend', fileName, chunk);

      store.patchFileTransferFor(this.uuid, fileName, { sizeSoFar: store.getFileTransferFor(this.uuid, fileName).sizeSoFar + chunk.length });

      bigString = bigString.slice(chunk.length);
    }

    // Let them know that was all
    await this.rpc.rpc('endFileSend', fileName);
    store.patchFileTransferFor(this.uuid, fileName, { complete: true });
    this.dieIfUnneeded();
    return true;
  }

  sendObj(o) {
    try {
      this.dc.send(JSON.stringify(o));
    } catch (err) {
      console.warn('sendObj Error', this.name, err.message, o);
    }
  }

  async sendRenegOffer() {
    const offer = modifySdp(await this.pc.createOffer(this.offerOptions));
    rtcMsg(this.uuid, {
      channel: 'webrtc_offer_renegotiate',
      offer
    });
  }

  actualReneg () {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      let resolved = false;
      this.currentRenegotiatePromiseResolve = () => {
        if (resolved) return;
        resolved = true;
        resolve();
      };
      setTimeout(this.currentRenegotiatePromiseResolve, 2500);
      if (this.initiator || this.addedStreamFlag) {
        if (this.addedStreamFlag) this.addedStreamFlag = false;
        await this.sendRenegOffer();
      } else {
        rtcMsg(this.uuid, {
          channel: 'webrtc_please_renegotiate',
        });
      }
    });
  }

  startRenegotiate() {
    return this.currentRenegotiatePromise = this.currentRenegotiatePromise.finally(this.actualReneg.bind(this));
  }

  async onNegotiationNeeded() {
    // if (this.onHold) return;
    // console.log('onNegotiationNeeded', this.name, this.pc.signalingState, this.inited, this.dead, this.renegTimer);
    if (this.pc.signalingState === 'have-remote-offer') {
      console.warn('onNegotiationNeeded called in bad state:', this.pc.signalingState);
      return;
    }
    if (!this.inited) {
      if (!this.dead) {
        if (this.renegTimer) clearTimeout(this.renegTimer);
        this.renegTimer = setTimeout(this.onNegotiationNeeded.bind(this), 500);
      }
    } else {
      if (this.renegTimer) {
        clearTimeout(this.renegTimer);
        this.renegTimer = undefined;
      }
      return this.startRenegotiate();
    }
  }

  onIceCandidate(event) {
    if (!event.candidate) return;

    rtcMsg(this.uuid, {
      channel: 'webrtc_ice_candidate',
      candidate: event.candidate
    });
  }

  onSignalingStateChanged() {
    if (!store.state.rtc[this.uuid]) return;
    store.state.rtc[this.uuid].signalingState = this.pc.signalingState;
  }

  onIceConnectionStateChanged() {
    if (!store.state.rtc[this.uuid]) return;
    store.state.rtc[this.uuid].iceConnectionState = this.pc.iceConnectionState;

    if (this.pc.iceConnectionState === 'closed' || this.pc.iceConnectionState === 'failed') { //  || this.pc.iceConnectionState === 'disconnected'
      // console.log('IceConnectionState causing death!', this.name, this.pc.iceConnectionState);
      this.die();
    } else if (this.pc.iceConnectionState === 'disconnected') {
      if (!this.dead) {
        // console.log('IceConnectionState Disconnected, triggering renegotiate.');
        this.setReaper();
        if (this.pc.restartIce) {
          this.pc.restartIce();
        } else {
          this.onNegotiationNeeded();
        }
      }
    } else if (this.pc.iceConnectionState === 'connected') {
      this.setRingingState('connected');
      this.clearReaper();

      if (this.hasMedia) {
        addToDialGroup(this.uuid);
        this.sendDialGroup();
      }
    }
  }

  onIceGatheringStateChanged() {
    if (!store.state.rtc[this.uuid]) return;
    store.state.rtc[this.uuid].iceGatheringState = this.pc.iceGatheringState;
  }

  setReaper() {
    this.clearReaper();
    this._reaperTimeout = setTimeout(() => {
      console.warn('REAPER FOR', this.name, this);
      this.die();
    }, 10000);
  }

  clearReaper() {
    if (this._reaperTimeout) clearTimeout(this._reaperTimeout);
  }

  onTrack(event) {
    event.streams.forEach((stream) => {
      try {
        stream.oninactive = stream.onremovetrack = this.onTrackDeath.bind(this, stream);
      } catch (err) {
        console.warn('stream oninactive/onremovetrack assignment error:', err);
      }
      if (!store.state.remoteStreams[this.uuid] || !store.state.remoteStreams[this.uuid].first || store.state.remoteStreams[this.uuid].first.id === stream.id || !store.state.remoteStreams[this.uuid].first.active) {
        store.addRemoteFirstStream(this.uuid, stream);
      } else {
        store.addRemoteSecondStream(this.uuid, stream);
      }
    });
  }

  onTrackDeath(stream) {
    if (!store.state.remoteStreams[this.uuid]) return;
    if (store.state.remoteStreams[this.uuid].first === stream) {
      Vue.delete(store.state.remoteStreams[this.uuid], 'first');
    } else if (store.state.remoteStreams[this.uuid].second === stream) {
      Vue.delete(store.state.remoteStreams[this.uuid], 'second');
    }
  }

  async initDC() {
    this.dc = await this.pc.createDataChannel('channel');
    // this.dc.addEventListener('message', this.onDcMsg.bind(this));
  }

  async attachMedia() {
    if (this.hasMedia) return;
    this.hasMedia = true;

    if (!store.state.remoteStreams[this.uuid]) {
      Vue.set(store.state.remoteStreams, this.uuid, { first: undefined, second: undefined });
    }

    try {
      await getUserMedia();
      // if (store.state.localStreams.user) {
      //   this.addAllTracks(store.state.localStreams.user);
      // }

      if (store.state.localStreams.userCanvas) {
        this.addAllTracks(store.state.localStreams.userCanvas);
        // if (!lastUserStream) lastUserStream = store.state.localStreams.userCanvas;
      } else if (store.state.localStreams.user) {
        this.addAllTracks(store.state.localStreams.user);
        // if (!lastUserStream) lastUserStream = store.state.localStreams.user;
      }
    } catch (err) {
      // console.log('User addtrack err:', this.name, err.message);
    }

    try {
      if (store.state.localStreams.display) {
        this.addAllTracks(store.state.localStreams.display);
      }
    } catch (err) {
      // console.log('Display addtrack err:', this.name, err.message);
    }
  }

  async detachMedia() {
    if (!this.hasMedia) return;
    this.hasMedia = false;

    if (store.state.localStreams.userCanvas) {
      this.removeAllTracks(store.state.localStreams.userCanvas);
    } else if (store.state.localStreams.user) {
      this.removeAllTracks(store.state.localStreams.user);
    }

    if (store.state.localStreams.display) {
      this.removeAllTracks(store.state.localStreams.display);
    }
  }

  onDc(event) {
    event.channel.addEventListener('open', this.onDcOpen.bind(this));
    event.channel.addEventListener('message', this.onDcMsg.bind(this));
    event.channel.addEventListener('close', this.onDcClosed.bind(this));
  }

  onDcOpen() {
    // console.log('onDcOpen', this.name, this.inited);
    if (!this.inited) {
      // First DC open indicates successful connection.
      this.inited = true;

      this.setRingingState('connected');
      this.clearReaper();

      if (this.hasMedia) {
        addToDialGroup(this.uuid);
        this.sendDialGroup();
        CallInfoManager.onOpenConnection(this);
        RTCConnectionOpenedEvent();
      }

      // this.testSend();
      if (this.onDcOpenHandler) this.onDcOpenHandler();
      this.updateStats();
    }
  }

  onDcClosed () {
    // Die if the datachannel disconnects (no implemented good reason for it to happen other than disconnect or network issues)
    this.die();
  }

  onDcMsg (event) {
    // TODO: improve this with rpc managers?
    if (event.data === 'hangup') this.die();
    else if (event.data.indexOf('hangupfor') !== -1) {
      try {
        webrtcConnections[event.data.trim().replace('hangupfor:', '')].die();
      } catch (err) {
        console.warn('hangupfor err:', err.message, event.data);
      }
    } else if (event.data.indexOf('dialGroup') !== -1) {
      // console.log('Got dialgroup:', this.name, event.data);
      event.data.trim().replace('dialGroup:', '').split(':').forEach((uuid) => {
        const ownUUID = getOwnUUID();
        if (uuid === ownUUID) return;
        addToDialGroup(uuid);
        // else if (webrtcConnections[uuid]) return;
        // callUUID(uuid, true);
      });
    } else {
      let jo;
      try {
        jo = JSON.parse(event.data);
      } catch (err) {}
      if (jo) this.onTypedMsg(jo);
    }
  }

  onTypedMsg (msg) {
    if (msg.type === 'rpc') this.rpc.handleRequest(msg.rpc);
    else if (msg.type === 'rpc_ans') this.rpc.handleAnswer(msg.rpc);
    else console.warn('Unknown typed message', this.name, msg);
  }

  sendDialGroup() {
    try {
      // const keys = [];
      // for (const uuid in webrtcConnections) {
      //   const w = webrtcConnections[uuid];
      //   if (w.inited && w.hasMedia) keys.push(uuid);
      // }
      // const command = 'dialGroup:' + keys.join(':');
      const command = 'dialGroup:' + dialGroup.join(':');
      // console.log('sendDialGroup', this.name, command);
      this.dc.send(command);
    } catch (err) {
      console.warn('hangup dc err:', err.message);
    }
  }

  setRingingState(state) {
    if (store.state.rtc[this.uuid]) store.state.rtc[this.uuid].ringingState = state;
  }

  hangup(force) {
    try {
      this.dc.send('hangup');
    } catch (err) {
      console.warn('hangup dc err:', err.message); // just in case
      try {
        rtcMsg(this.uuid, { channel: 'hangup' });
      } catch (err) {
        console.warn('hangup ws err:', err.message);
      }
    }

    try {
      if (force && this.hasMedia) {
        broadcasthangup(this.uuid);
      }
    } catch (err) {
      console.warn('broadcasthangup dc err:', err.message);
    }
  }

  die(force) {
    if (this.dead) return;
    this.dead = true;
    this.clearReaper();
    removeFromDialGroup(this.uuid);

    clearInterval(this.statInterval);

    // console.log('die for', this.uuid, this.name);

    this.hangup(force);

    store.removeRemoteStreams(this.uuid);
    this.pc.close();
    delete webrtcConnections[this.uuid];
    Vue.delete(store.state.rtc, this.uuid);

    CallInfoManager.onCloseConnection(this);
    updateRtcStatus();
    checkIfNeedCloseStreams();
    checkIfAllConnectionsClosed();
  }

  addAllTracks(stream) {
    // console.log('addAllTracks stream', this.name, stream);
    // this.pc.addStream(stream);
    for (const track of stream.getTracks()) {
      // console.log('addAllTracks track', track);
      try {
        if (this.senders[track.id]) {
          console.warn('addAllTracks -- PREVIOUS OF SAME TRACK AAAAAH, prevented', track, this.senders[track.id]);
          return;
        }
        this.senders[track.id] = this.pc.addTrack(track, stream);
        this.addedStreamFlag = true; // Force us to send offer in renegotiate!
      } catch (err) {
        console.warn('addAllTracks Error:', err.message);
      }
    }
  }

  removeAllTracks(stream) {
    // console.log('removeAllTracks stream', this.name, stream);
    for (const track of stream.getTracks()) {
      // console.log('removeAllTracks track', this.name, track, this.senders[track.id]);
      if (this.senders[track.id]) {
        this.pc.removeTrack(this.senders[track.id]);
        delete this.senders[track.id];
        this.addedStreamFlag = true; // Force us to send offer in renegotiate!
      }
    }
  }
}

export default RtcConnection;

export function updateRtcStatus () {
  try {
    // const keys = Object.keys(webrtcConnections).join(':');
    const keys = [];
    let earliestStart = 0;
    Object.values(webrtcConnections).forEach((webrtc) => {
      if (webrtc.hasMedia) {
        keys.push(webrtc.uuid);
        if (!earliestStart || webrtc.start < earliestStart) {
          earliestStart = webrtc.start;
        }
      }
    });
    // console.log('updateRtcStatus', keys, earliestStart);
    store.setRtcCallStatus(keys.join(':'), earliestStart);
  } catch (err) {
    console.warn('updateRtcStatus Err:', err);
  }
}

setInterval(updateRtcStatus, 5000);

export function getWebrtcConnections () {
  return webrtcConnections;
}

function modifySdp(thing) {
  let sdp = thing.sdp;

  // try {
  //   const videoSettings = store.getVideoSettings();
  //   // console.log('modifySdp pre', sdp);

  //   // Video codec
  //   sdp = maybePreferCodec(sdp, 'video', 'receive', videoSettings.useVP9 ? 'vp9' : 'vp8');
  //   sdp = maybePreferCodec(sdp, 'video', 'send', videoSettings.useVP9 ? 'vp9' : 'vp8');

  //   // Bitrate
  //   sdp = preferBitRate(sdp, videoSettings.videoBitrate || 8000, 'video');
  //   sdp = preferBitRate(sdp, videoSettings.audioBitrate || 1000, 'audio');
  // } catch (err) {
  //   console.warn('modifySdp', err);
  // }

  // console.log('modifySdp post', sdp);
  return { type: thing.type, sdp };
}

function broadcasthangup (uuid) {
  Object.values(webrtcConnections).forEach((webrtc) => {
    try {
      webrtc.dc.send('hangupfor:' + uuid);
    } catch (err) {
      console.warn('broadcasthangup dc err:', err.message);
    }
  });
}

function checkIfAllConnectionsClosed () {
  try {
    if (Object.keys(webrtcConnections).length === 0) {
      allRTCConnectionsClosedEvent();
    }
  } catch (err) {
    console.warn('checkIfAllConnectionsClosed Err:', err);
  }
}

function checkIfNeedCloseStreams () {
  try {
    if (store.state.localStreams.user || store.state.localStreams.display) {
      if (Object.keys(webrtcConnections).length === 0) {
        // console.log('Last connection died, closing local streams');
        store.closeLocalStreams();
      }
    }
  } catch (err) {
    console.warn('checkIfNeedCloseStreams Err:', err);
  }
}

export function addStreamToAllConnections (stream) {
  // console.log('addStreamToAllConnections', stream);
  Object.values(webrtcConnections).forEach((webrtc) => {
    try {
      if (webrtc.hasMedia) { // Only for ones with our media (calls)
        webrtc.addAllTracks(stream);
      }
    } catch (err) {
      console.warn('addStreamToAllConnections Err:', err.message, webrtc, stream);
    }
  });
}

export function removeStreamToAllConnections (stream) {
  // console.log('removeStreamToAllConnections', stream);
  Object.values(webrtcConnections).forEach((webrtc) => {
    try {
      if (webrtc.hasMedia) { // Only for ones with our media (calls)
        webrtc.removeAllTracks(stream);
      }
    } catch (err) {
      console.warn('removeStreamToAllConnections Err:', err.message, webrtc, stream);
    }
  });
}

// let lastUserStream;
export function ensureUserStream() {
  if (store.state.ensureUserMediaLock) return;

  if (store.state.localStreams.userCanvas) {
    removeStreamToAllConnections(store.state.localStreams.user);
    addStreamToAllConnections(store.state.localStreams.userCanvas);
  } else if (store.state.localStreams.user) {
    addStreamToAllConnections(store.state.localStreams.user);
  }
  // const desiredStream = store.state.localStreams.userCanvas || store.state.localStreams.user;
  // if (lastUserStream) {
  //   if (lastUserStream === desiredStream) return; // Nothing to do
  //   else removeStreamToAllConnections(lastUserStream); // Remove previous stream
  // }
  // if (desiredStream) {
  //   addStreamToAllConnections(desiredStream);
  // }
  // lastUserStream = desiredStream;
}

export function waitForRtcStable () {
  return Promise.all(Object.values(webrtcConnections).map(webrtc => webrtc.currentRenegotiatePromise));
}

function sdpToAcceptableThing (sdp) {
  if (!sdp) {
    console.trace('RTC Fuckup', sdp);
    throw new Error('Missing SDP!');
  }

  // console.log('sdpToAcceptableThing', sdp);
  try {
    if (salvagedRTCSessionDescription) {
      // Use this if available
      return new salvagedRTCSessionDescription(sdp);
    } else {
      // Otherwise pray they accept the old ways
      return sdp;
    }
  } catch (err) {
    console.warn('sdpToAcceptableThing Err', err);
    return sdp;
  }
}

// export function askIfWantToAcceptCall (uuid, typeCall = 'audio') {
export function askIfWantToAcceptCall (uuid, options = {}) {
  const typeCall = options.typeCall || 'video';
  const meeting_start = options.meeting_start || undefined;
  const limit_time = options.limit_time || undefined;
  const join = options.join || false;
  const dialGroup = options.dialGroup || [];
  const pinCode = options.pinCode || "";
  const isPinProtected = options.isPinProtected || false;
  const isConference = options.isConference || false;
  const confId = options.confId || null;

  if (store.isAtCallLimit()) {
    return false;
  }
  rtcMsg(uuid, { channel: 'ringing' }, true);
  const p = new Promise((resolve, reject) => {
    // console.log('askIfWantToAcceptCall', uuid, store.getNameForUuid(uuid));
    store.setIncomingCallFor(uuid, { resolve, reject, typeCall, meeting_start, limit_time, join, dialGroup, isPinProtected, pinCode, isConference, confId });
  });
  return p;
}

export function askIfWantToAcceptFile (uuid, fileName, expectedSize, expectedMD5) {
  const p = new Promise((resolve, reject) => {
    // console.log('askIfWantToAcceptFile', uuid, store.getNameForUuid(uuid), fileName, expectedSize, expectedMD5);
    store.setFileTransferFor(uuid, fileName, { incoming: true, uuid, fileName, expectedSize, resolve, reject, expectedMD5, actualMD5: undefined });
  });
  return p;
}

export async function rtcMessageFrom (uuid, message) {
  // console.log('rtc Recv', store.getNameForUuid(uuid), message.channel);
  // Pre webrtc
  // console.log('message ', message, uuid);
  switch (message.channel) {
    case 'reject_call':
    case 'hangup':
      if (store.state.rtc[uuid]) store.state.rtc[uuid].ringingState = 'hangup';
      store.setIncomingCallFor(uuid, undefined); // just in case
      if (webrtcConnections[uuid]) webrtcConnections[uuid].die();
      setTimeout(checkIfNeedCloseStreams, 1000);
      return;
    case 'ringing':
      if (store.state.rtc[uuid]) store.state.rtc[uuid].ringingState = 'ringing';
      return;
    case 'accept_call':
      if (store.state.rtc[uuid]) store.state.rtc[uuid].ringingState = 'accepted';
      return;
    case 'start_call':
      if (store.state.incomingCallModal.calls[uuid]) return;
      try {
        if (!(message.force && dialGroup.indexOf(uuid) !== -1) && !webrtcConnections[uuid] && !(!message.force && await askIfWantToAcceptCall(uuid, {
          typeCall: message.typeCall,
          meeting_start: message.meeting_start,
          limit_time: message.limit_time,
          join: message.join,
          dialGroup: message.dialGroup,
          isPinProtected: message.isPinProtected,
          pinCode: message.pinCode,
          isConference: message.isConference || false,
          confId: message.confId || null
        }))) {
          // if (!message.force && !webrtcConnections[uuid] && !(await askIfWantToAcceptCall(uuid))) {
          // console.log('Rejecting call', store.getNameForUuid(uuid));
          // console.log('setting incomming for user'  )
          store.setIncomingCallFor(uuid, undefined); // just in case
          return rtcMsg(uuid, { channel: 'reject_call', reason: 'busy' }, true);
        }
        // if(!message.join){
        //   console.log('Accepted call', store.getNameForUuid(uuid));
        //   store.setdurationMeeting(message.limit_time);
        //   store.setmeetingStartAt(message.meeting_start);
        // }
        // if ( message.presentationView && message.presentationView.show ){
        //   store.setPresentationView(message.presentationView.show, message.presentationView.owner, message.presentationView.fromScreenshare);
        // }
        store.removeAcceptedCallNotification(uuid);
        rtcMsg(uuid, { channel: 'accept_call' }, true);
      } catch (err) {
        console.warn('start_call err', err, uuid, message);
        // console.log('Rejecting call', store.getNameForUuid(uuid));
        store.setIncomingCallFor(uuid, undefined); // just in case
        return rtcMsg(uuid, { channel: 'reject_call', reason: err.message }, true);
      }
      store.setIncomingCallFor(uuid, undefined); // just in case
      break;
      // case 'update_call_time':
      // try {
      //   let durationMeeting = ''
      //   if(message.type === 'increase'){
      //     durationMeeting = store.state.durationMeeting + message.oneMinute
      //   }
      //   if(message.type === 'decrease'){
      //     durationMeeting = store.state.durationMeeting - message.oneMinute
      //   }
      //   store.setdurationMeeting(durationMeeting);
      //   return;
      // } catch (error) {

      // }
      // break;
      // case 'set_remote_full_size':
      //   try{
      //     if ( message.remoteUUID ){
      //       store.setRemotePresentationFullSize(true);
      //       store.setRemotePresentationFullSizeUUID(message.remoteUUID);
      //       const speakerRequests = store.state.speakerRequests;
      //       const indexUuid = speakerRequests.indexOf(message.remoteUUID);
      //       if ( indexUuid !== -1 ){
      //         store.state.speakerRequests.splice(indexUuid, 1);
      //       }
      //     }else{
      //       store.setRemotePresentationFullSize(false);
      //       store.setRemotePresentationFullSizeUUID(null);
      //     }
      //     store.closeDisplayMedia();
      //     return;
      //   }catch(error){
      //   }
      // break;
      // case 'speaker_view_requested':
      //   try{
      //     const speakerRequests = store.state.speakerRequests;
      //     if ( speakerRequests.indexOf(message.from) === -1 ){
      //       speakerRequests.push(message.from);
      //       dispatchSuccessAlert(store.getNameForUuid(message.from) + ' ' + Vue.prototype.$t('components.callsContent.speakerViewRequested'));
      //     }
      //     store.setSpeakerViewRequests(speakerRequests);
      //     return;
      //   }catch(error){
      //     console.log('Error on speaker_view_requested', error)
      //   }
      // break;
      // case 'send_message_call_participants':
      //   try {
      //     store.setMessageToParticipants(message.info)
      //     return;
      //   } catch (error) {

      //   }
      //   break;
    case 'update_time_Join_coversation':
      try {
        // console.log('update_time_Join_coversation', message)
        store.setdurationMeeting(message.limit_time);
        store.setmeetingStartAt(message.meeting_start);
      } catch (error) {
        console.warn('update_time_Join_coversation', message, error);
      }
      return;
    case 'sendFile':
      // if (store.state.incomingCallModal.calls[uuid]) return;
      try {
        store.setMessageFor(uuid, { fileName: message.fileName, info: 'New file', notificationType: 'file', subject: 'New file', playSound: true });
        if (!(await askIfWantToAcceptFile(uuid, message.fileName, message.expectedSize, message.expectedMD5))) {
          // console.log('Rejecting file', store.getNameForUuid(uuid), message.fileName, message.expectedSize, message.expectedMD5);
          store.delFileTransferFor(uuid, message.fileName); // just in case
          delete waitingToSendFiles[uuid + '_' + message.fileName];
          return rtcMsg(uuid, { channel: 'reject_file', fileName: message.fileName }, true);
        }
        // console.log('Accepted file', store.getNameForUuid(uuid), message.fileName, message.size);
        rtcMsg(uuid, { channel: 'accept_file', fileName: message.fileName }, true);
      } catch (err) {
        // console.log('Rejecting file', store.getNameForUuid(uuid), message.fileName, message.size, err);
        store.delFileTransferFor(uuid, message.fileName); // just in case
        delete waitingToSendFiles[uuid + '_' + message.fileName];
        return rtcMsg(uuid, { channel: 'reject_file', reason: err.message }, true);
      }
      break;

    case 'reject_file':
      delete waitingToSendFiles[uuid + '_' + message.fileName];
      // store.delFileTransferFor(uuid, message.fileName);
      store.patchFileTransferFor(uuid, message.fileName, { pending: false, accepted: false });
      return;

    case 'webrtc_please_offer':
    case 'webrtc_please_renegotiate':
    case 'webrtc_ice_candidate':
    case 'webrtc_offer':
    case 'webrtc_answer':
    case 'webrtc_offer_renegotiate':
    case 'webrtc_answer_renegotiate':
      if (!webrtcConnections[uuid]) {
        // console.log('Ignore rogue:', message);
        try {
          rtcMsg(uuid, { channel: 'hangup' }, true);
        } catch (err) {
          console.warn('hangup ws err:', err.message);
        }
        return;
      }
    // eslint-disable-next-line no-fallthrough
    default:
      break;
  }

  // if (!webrtcConnections[uuid]) await createNewWebrtcConnection(uuid);
  if (!webrtcConnections[uuid]) new RtcConnection(uuid);
  // if (!webrtc) await createNewWebrtcConnection(uuid);
  const webrtc = webrtcConnections[uuid];
  if (!webrtc) throw new Error('Webrtc Sanity Error!');
  switch (message.channel) {
    case 'start_call':
      if (webrtc.uuid !== uuid) return; // Sanity
      await webrtc.attachMedia();
      // if (webrtc.inited) return;
      try {
        if (webrtc.initiator) {
          const offer = modifySdp(await webrtc.pc.createOffer(webrtc.offerOptions));
          await webrtc.pc.setLocalDescription(sdpToAcceptableThing(offer));
          rtcMsg(uuid, {
            channel: 'webrtc_offer',
            offer
          });
        } else {
          rtcMsg(uuid, {
            channel: 'webrtc_please_offer',
          });
        }
      } catch (err) {
        console.warn('Failed to start_call:', uuid, err.message);
      }
      break;

    case 'webrtc_please_offer':
      try {
        if (webrtc.initiator) {
          const offer = modifySdp(await webrtc.pc.createOffer(webrtc.offerOptions));
          await webrtc.pc.setLocalDescription(sdpToAcceptableThing(offer));
          rtcMsg(uuid, {
            channel: 'webrtc_offer',
            offer
          });
        } else {
          console.warn('webrtc_please_offer rogue', uuid, message, webrtc);
        }
      } catch (err) {
        console.warn('Failed to webrtc_please_offer:', uuid, err.message, message, webrtc);
      }
      break;

    case 'accept_file':
      // waitingToSendFiles[uuid+'_'+message.fileName];
      store.patchFileTransferFor(uuid, message.fileName, { pending: false, accepted: true }); // just in case
      // eslint-disable-next-line no-case-declarations
      const f = () => {
        webrtc.sendFile(message.fileName, waitingToSendFiles[uuid + '_' + message.fileName].dataUrl);
        delete waitingToSendFiles[uuid + '_' + message.fileName];
      };
      if (webrtc.inited) {
        f();
      } else {
        webrtc.onDcOpenHandler = f;
      }
      break;

    case 'sendFile':
      if (webrtc.uuid !== uuid) return; // Sanity
      if (webrtc.inited) return;
      try {
        const offer = modifySdp(await webrtc.pc.createOffer(webrtc.offerOptions));
        await webrtc.pc.setLocalDescription(sdpToAcceptableThing(offer));
        rtcMsg(uuid, {
          channel: 'webrtc_offer',
          offer
        });
      } catch (err) {
        console.warn('Failed to sendFile:', uuid, err.message);
      }
      break;

    case 'webrtc_ice_candidate':
      try {
        await webrtc.pc.addIceCandidate(message.candidate);
      } catch (err) {
        console.warn('Failed to add ice candidate:', uuid, err.message);
      }
      break;

    case 'webrtc_please_renegotiate':
      setTimeout(webrtc.onNegotiationNeeded.bind(webrtc), 1000);
      break;

    case 'webrtc_offer':
      await webrtc.pc.setRemoteDescription(sdpToAcceptableThing(message.offer));
      // eslint-disable-next-line no-case-declarations
      const answer = modifySdp(await webrtc.pc.createAnswer(webrtc.offerOptions));
      await webrtc.pc.setLocalDescription(sdpToAcceptableThing(answer));

      rtcMsg(uuid, {
        channel: 'webrtc_answer',
        answer
      });
      break;

    case 'webrtc_answer':
      try {
        await webrtc.pc.setRemoteDescription(sdpToAcceptableThing(message.answer));
      } catch (err) {
        console.warn('webrtc_answer', webrtc.name, err.message);
        setTimeout(webrtc.onNegotiationNeeded.bind(webrtc), 1000);
      }
      break;

    case 'webrtc_offer_renegotiate':
      webrtc.currentRenegotiatePromise = webrtc.currentRenegotiatePromise.finally(async () => {
        try {
          await webrtc.pc.setRemoteDescription(sdpToAcceptableThing(message.offer));
          const ranswer = modifySdp(await webrtc.pc.createAnswer(webrtc.offerOptions));
          await webrtc.pc.setLocalDescription(sdpToAcceptableThing(ranswer));

          rtcMsg(uuid, {
            channel: 'webrtc_answer_renegotiate',
            answer: ranswer,
            offer: message.offer
          });
        } catch (err) {
          console.warn('webrtc_offer_renegotiate', err);
          setTimeout(webrtc.onNegotiationNeeded.bind(webrtc), 1000);
        }
      });
      break;

    case 'webrtc_answer_renegotiate':
      try {
        await webrtc.pc.setLocalDescription(sdpToAcceptableThing(message.offer));
        await webrtc.pc.setRemoteDescription(sdpToAcceptableThing(message.answer));
        if (webrtc.currentRenegotiatePromiseResolve) {
          webrtc.currentRenegotiatePromiseResolve();
          webrtc.currentRenegotiatePromiseResolve = undefined;
        }
      } catch (err) {
        console.warn('webrtc_answer_renegotiate', err);
        setTimeout(webrtc.onNegotiationNeeded.bind(webrtc), 1000);
      }
      break;

    // case 'set_presentation_view':
    //  if ( message.presentationView ){
    //     store.setPresentationView(message.presentationView.show, message.presentationView.owner, message.presentationView.fromScreenshare);
    //   }
    // break;

    default:
      // console.log('unknown message', message);
      break;
  }
}

// export async function callUUID (uuid, force = false, join=false) {
export async function callUUID (uuid, options = {}) {
  // Unpack options

  const recent = 60000 * 60;
  const force = options.force || false;
  const join = options.join || false;
  const typeCall = options.typeCall || 'video';
  const isPinProtected = options.isPinProtected || false;
  const pinCode = options.pinCode || "";
  const isConference = options.isConference || false;
  const confId = options.confId || "";

  if (uuid === store.state.ownUUID) {
    console.warn('Prevented attempted to callUUID self', uuid);
    return;
  }
  if (!store.state.group[uuid] || (!store.state.group[uuid].connected && !((Date.now() - store.state.group[uuid].user.mobileLastActive) < recent))) {
    console.warn('Prevented call to offline uuid', uuid);
    return;
  }
  if (store.isAtCallLimit()) {
    store.setCallFull(true);
    return;
  }
  if (!webrtcConnections[uuid]) new RtcConnection(uuid);
  await webrtcConnections[uuid].attachMedia();

  if (!CallInfoManager.haveCallActive()) webrtcConnections[uuid].callStarter = true;

  store.setIncomingCallFor(uuid, undefined); // just in case

  const limit_time = store.state.durationMeeting;
  let meeting_start = store.state.currentTS;
  const presentationView = store.state.presentationView;

  // if(!join){
  //   if(!store.state.meetingStartAt){
  //     store.setmeetingStartAt(meeting_start)
  //   }else {
  //     meeting_start = store.state.meetingStartAt
  //   }
  //   store.setOwnerMeeting(true);
  // }

  if (!force) {
    sendNotificationToUUID(uuid, { type: 'call' });
  }
  rtcMsg(uuid, {
    channel: 'start_call', force, typeCall, dialGroup, limit_time, meeting_start, join, presentationView, isPinProtected, pinCode, isConference, confId
  });
}

// export async function updateCallTime (uuid, type) {
//   console.warn('updateCallTime deprectated', uuid, type);
//   throw new Error('updateCallTime is deprecated');
//   // let oneMinute = 60000;
//   // // store.setdurationMeeting(durationMeeting);
//   // rtcMsg(uuid, {
//   //   channel: 'update_call_time', oneMinute, type
//   // });
// }

// export async function setRemoteFullSize (uuid, remoteUUID) {
//   rtcMsg(uuid, {
//     channel: 'set_remote_full_size', remoteUUID
//   });
// }

// export async function requestSpeakerView(uuid, from){
//   rtcMsg(uuid, {
//     channel: 'speaker_view_requested', from
//   });
// }

// export async function setPresentationViewScreenShare (uuid) {
//   throw new Error('Deprecated');
//   // const presentationView = store.state.presentationView;
//   // rtcMsg(uuid, {
//   //   channel: 'set_presentation_view', presentationView
//   // });
// }

// export async function sendMessageCallparticipants (uuid, info) {
//   rtcMsg(uuid, {
//     channel: 'send_message_call_participants', info
//   });
// }

// TODO: replace this with proper callInfoManager logic?
// NOTE: This is left alive because when we answer a new call, they want to be able to change the timer...
export async function setTimeJoinConversation (uuid, limit_time, meeting_start) {
  // store.setdurationMeeting(durationMeeting);
  // console.log("aquiiiiiiiiiiii", uuid, limit_time, meeting_start)
  rtcMsg(uuid, {
    channel: 'update_time_Join_coversation', limit_time, meeting_start
  }, true);
}

const waitingToSendFiles = {};

export async function sendFileToUUID (uuid, fileName, dataUrl) {
  // if (!webrtcConnections[uuid]) new RtcConnection(uuid);
  // await webrtcConnections[uuid].sendFile(fileName, dataUrl);
  store.setFileTransferFor(uuid, fileName, { outgoing: true, pending: true, accepted: false, uuid, fileName, expectedSize: dataUrl.length, sizeSoFar: 0 });
  waitingToSendFiles[uuid + '_' + fileName] = { dataUrl, fileName, uuid };
  rtcMsg(uuid, {
    channel: 'sendFile', fileName, size: dataUrl.length, expectedMD5: MD5(dataUrl),
  }, true);
  // sendNotificationToUUID(uuid, { type: 'call' });
}

export function rtcHangup () {
  Object.values(webrtcConnections).forEach(webrtc => {
    webrtc.die();
  });

  updateRtcStatus();
  store.closeLocalStreams();
}

window.addEventListener('beforeunload', function () {
  rtcHangup();
});

export function sendFileTo(uuid) {
  const input = document.createElement('input');
  input.type = 'file';

  input.onchange = e => {
    const file = e.target.files[0];
    // file.name // the file's name including extension
    // file.size // the size in bytes
    // file.type // file type ex. 'application/pdf'

    if (file.size > 5e+7) {
      alert('File too large! Max. size 50 MB');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    // here we tell the reader what to do when it's done reading...
    reader.onload = readerEvent => {
      const content = readerEvent.target.result; // this is the content!
      // console.log( content );
      sendFileToUUID(uuid, file.name, content);
    };
  };

  input.click();
}

export async function holdCall(uuid) {
  const webrtc = webrtcConnections[uuid];
  if (!webrtc) throw new Error('Webrtc connection not found');
  await webrtc.setOnHold(true);
  await webrtc.rpc.rpc('setOnHold', true);
}

export async function unholdCall(uuid) {
  const webrtc = webrtcConnections[uuid];
  if (!webrtc) throw new Error('Webrtc connection not found');
  await webrtc.setOnHold(false);
  await webrtc.rpc.rpc('setOnHold', false);
}

export async function transferCall(srcUuid, dstUuid, moreSrc) {
  const webrtc = webrtcConnections[srcUuid];
  if (!webrtc) throw new Error('Webrtc connection not found');
  await webrtc.transferToUuid(dstUuid);
  const data = {
    source: srcUuid,
    transferedTo: dstUuid
  };
  // console.log('r4aising eventttttttttttttttttttttttttttttttttttttttttttttttt')
  callTransferedEvent(data);
  dispatchSuccessAlert(Vue.prototype.$t('components.callsContent.videoCallForwared') + ' ' + store.getNameForUuid(dstUuid) + ' ' + Vue.prototype.$t('components.callsContent.forwared'));
}

export async function triggerIceRestart(uuid) {
  const webrtc = webrtcConnections[uuid];
  if (!webrtc) throw new Error('Webrtc connection not found');
  if (webrtc.pc.restartIce) {
    // console.log('triggerIceRestart - Restarting ICE for', webrtc.name, uuid);
    webrtc.pc.restartIce();
  } else {
    // console.log('triggerIceRestart - restartIce() not supported, renegotiating normally instead for', webrtc.name, uuid);
    webrtc.onNegotiationNeeded();
  }
}

// let hadOpenCall = false;

// RTCConnectionOpenedEvent.watch(() => { hadOpenCall = true; });

// allRTCConnectionsClosedEvent.watch(() => {
//   try {
//     if (store.state.ownerMeeting && hadOpenCall && store.state.namespaceSettings.showCountQualityStatistics) {
//       setQualityVotingModalEvent(true);
//     }
//     hadOpenCall = false;
//     // store.setdurationMeeting(undefined);
//     // store.setOwnerMeeting(undefined);
//     // store.setmeetingStartAt(undefined);
//   } catch (err) {
//     console.warn('allRTCConnectionsClosedEvent Reset Store Error', err);
//   }
// });
typingInfoEvent.watch(async (payload) => {
  // console.log('typingInfoEvent', payload);
  // SOMEHOW VERIFY SHIT ON SIGNLLING MAYBE???
  for (const c of Object.values(webrtcConnections)) {
    const uuid = c.uuid;
    if (payload.show && payload.uuid == uuid) {
      await c.rpc.rpc('typingInfoEventRequested', payload);
    } else if (!payload.show) {
      await c.rpc.rpc('typingInfoEventRequested', payload);
    }
  }
});

// requestPaymentEvent.watch(async (payload) => {
//   console.log('requestPaymentEvent RTCConn', payload);
//   // SOMEHOW VERIFY SHIT ON SIGNLLING MAYBE???
//   EventBus.$emit('paymentRequested', payload);
//   for (const c of Object.values(webrtcConnections)) {
//     const uuid = c.uuid;
//     if (payload.receiver === uuid) {
//       await c.rpc.rpc('paymentRequested', payload);
//     }
//   }
// });

setPaymentInProgressEventModal.watch(async (payload) => {
  // console.log('requestPaymentEvent', payload);
  // SOMEHOW VERIFY SHIT ON SIGNLLING MAYBE???
  for (const c of Object.values(webrtcConnections)) {
    await c.rpc.rpc('inprogressRequested', payload);
   
  }
});

successPaymentEvent.watch(async (payload) => {
  // console.log('successPaymentEvent', payload);
  // SOMEHOW VERIFY SHIT ON SIGNLLING MAYBE???#
  if ( payload.errorPayment ){
    for (const c of Object.values(webrtcConnections)) {
      await c.rpc.rpc('paymentSuccess', payload);
    }
  }else if ( payload.successPayment ){
    for (const c of Object.values(webrtcConnections)) {
      const uuid = c.uuid;
      if (payload.ownerMeeting === uuid) {
        await c.rpc.rpc('paymentSuccess', payload);
      }
    }
  }
});
