// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploadAvatarCropper .v-overlay__content {
  background: #fff;
}
.v-theme--dark.uploadAvatarCropper .v-overlay__content {
  color: #fff !important;
  background: #1e1e1e !important;
}
.sliderSize .cr-slider-wrap {
  width: 100%;
  margin: 15px auto;
}
.sliderSize .cr-slider {
  width: 256px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/uploadAvatarModal.vue","webpack://./uploadAvatarModal.vue"],"names":[],"mappings":"AAEE;EACE,gBAAA;ACDJ;ADME;EACE,sBAAA;EACA,8BAAA;ACHJ;ADOA;EACE,WAAA;EACA,iBAAA;ACJF;ADMA;EACE,YAAA;ACHF","sourcesContent":["\n.uploadAvatarCropper{\n  .v-overlay__content{\n    background: #fff;\n  }\n}\n\n.v-theme--dark.uploadAvatarCropper{ \n  .v-overlay__content{\n    color: #fff !important;\n    background: #1e1e1e !important;\n  }\n}\n\n.sliderSize .cr-slider-wrap {\n  width: 100%;\n  margin: 15px auto;\n}\n.sliderSize .cr-slider {\n  width: 256px;\n}\n",".uploadAvatarCropper .v-overlay__content {\n  background: #fff;\n}\n\n.v-theme--dark.uploadAvatarCropper .v-overlay__content {\n  color: #fff !important;\n  background: #1e1e1e !important;\n}\n\n.sliderSize .cr-slider-wrap {\n  width: 100%;\n  margin: 15px auto;\n}\n\n.sliderSize .cr-slider {\n  width: 256px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
