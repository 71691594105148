import { mailFooterDomain } from './domain';

import { setMailFooterEvent, resetMailFooterEvent } from './events';

export const mailFooterStore = mailFooterDomain.createStore(false, { name: 'mailFooter' });

mailFooterStore.on(setMailFooterEvent, (state, payload) => {
  return payload;
});

mailFooterStore.reset(resetMailFooterEvent);
