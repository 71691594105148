// import { timelineDomain.createEvent } from 'effector';
import { companyDocumentDomain } from './domain';

export const newCompanyDocumentEvent = companyDocumentDomain.createEvent('newCompanyDocument');
export const updateCompanyDocumentEvent = companyDocumentDomain.createEvent('updateCompanyDocument');

export const dispatchFetchMyCompanyDocumentsEvent = companyDocumentDomain.createEvent('dispatchFetchMyCompanyDocumentsEvent');
export const dispatchDeleteCompanyDocumentEvent = companyDocumentDomain.createEvent('dispatchDeleteCompanyDocumentEvent');
export const getMyCompanyDocumentsDetailsEvent = companyDocumentDomain.createEvent('getMyCompanyDocumentsDetailsEvent');
export const setCompanyDocumentDetailEvent = companyDocumentDomain.createEvent('setCompanyDocumentDetailEvent');

export const dispatchFetchCompanyDocumentInfosEvent = companyDocumentDomain.createEvent('dispatchFetchCompanyDocumentInfosEvent');

export const setCompanyDocumentUUIDsEvent = companyDocumentDomain.createEvent('setCompanyDocumentUUIDs');
export const setCompanyDocumentInfoEvent = companyDocumentDomain.createEvent('setCompanyDocumentInfo');
export const deleteCompanyDocumentEntryAndUUIDEvent = companyDocumentDomain.createEvent('deleteCompanyDocumentEntryAndUUID');
export const setCompanyDocumentsInfoBatchedEvent = companyDocumentDomain.createEvent('setCompanyDocumentsInfoBatched');
