// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-menu__content {
  margin-top: 36px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/dialUpTimeModal.vue","webpack://./dialUpTimeModal.vue"],"names":[],"mappings":"AACA;EACE,gBAAA;ACAF","sourcesContent":["\n.v-menu__content{\n  margin-top: 36px;\n}\n",".v-menu__content {\n  margin-top: 36px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
