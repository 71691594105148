<template>
  <div v-if="group">
      <label class="ml-2 userName font-weight-medium">{{group.groupName}}</label>
      <v-menu offset-y scrollable :close-on-click="true" v-if="group.groupId">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" class="groupCardEllipses" >
            <font-awesome-icon
              :icon="['fal', 'ellipsis-v']"
              :style="{ fontSize: '20px' }"
            />  
          </v-btn>
          </template>
          <v-list>
            <!-- ç{{ $t("components.conferenceForm.createGroup")} -->
            <v-list-item @click="editGroup(group)">
              <v-list-item-title>{{ $t("components.addGroupModal.editGroup")}}</v-list-item-title>
            </v-list-item>
             <v-list-item @click="createFromExistingGroup(group.groupId)">
              <v-list-item-title>{{ $t("components.addGroupModal.setConfForGroup")}}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="callMembers(group.groupId)">
             <v-list-item-title>{{ $t("components.addGroupModal.callAll")}}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="checkSendMessage(group.groupUUIDS)">
             <v-list-item-title>{{ $t("components.addGroupModal.sendMessageToAll")}}</v-list-item-title>
            </v-list-item>
            <v-list-item  @click="preDeleteGroup($event, group.groupId)">
              <v-tooltip location="top">
                <template v-slot:activator="{ on }">
                  <v-list-item-title v-on="on" :class="!deleteActive ? 'deleteBasketLinkNormal' :'deleteBasketLinkActive'">{{ $t("components.addGroupModal.deleteGroup")}}</v-list-item-title>
               </template>
                <span>{{
                  $t("components.conferenceForm.confirmRemove")
                }}</span>
              </v-tooltip>
            </v-list-item>
             <v-list-item @click="setFavorite(group.groupId)">
              <v-list-item-title>{{ group.favorite ?  $t("components.addGroupModal.unsetFavoriteGroup") : $t("components.addGroupModal.setFavoriteGroup") }}</v-list-item-title>
            </v-list-item>
          </v-list>
      </v-menu>
  </div>
</template>
<script>
import store, { EventBus } from "../../store";
import { getAvatarByType } from "../../utils/basicFunctions";
import { hasPrivilege } from "../../utils/privileges";
import { dispatchSuccessAlert, dispatchErrorAlert } from '../../effector/alerts';
export default {
  components: {},
  props: ["group", "editGroup", "deleteGroup", "setFavorite", "checkSendMessage","existingGroup", "callMembers"],
  data() {
    return {
      state: store.state,
      deleteActive: false
    }
  },
  methods: {
    createFromExistingGroup(groupId){
      this.existingGroup(groupId);
    },
    preDeleteGroup(event, groupId){
      event.stopPropagation();
      event.preventDefault();
       try{
        if (!this.deleteActive) {
          this.deleteActive = true;
          setTimeout(() => {
            this.deleteActive = false; 
          },1500)
        } else {
          this.deleteActive = false;
          const groupIndex = store.state.user.groups.findIndex(e => e.groupId === groupId);
          store.state.user.groups.splice(groupIndex, 1)
          this.viewingGroups = true;
          dispatchSuccessAlert(this.$t('components.groups.groupDeleted'));;       
        }
      }catch(err){
          this.deleteActive = false;
          console.warn('Error deleting group', err)
          dispatchErrorAlert('Error deleting group')
      }

    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    getAvatarByType(uuid) {
      return getAvatarByType(uuid);
    },
    getNameForUuid(uuid){
      return store.getNameForUuid(uuid);
    },
  }
}
</script>
<style scoped lang="scss">
.v-theme--light .deleteBasketLinkNormal {
  color: rgb(32, 31, 31);
}
.deleteBasketLinkActive{
  color: red !important;
  font-weight: 600 !important;
}
.groupCardEllipses {
  color: white !important;
  float: right;
  max-height: 20px;
  max-width: 20px;
}
.userName{
  vertical-align: middle;
}
</style>