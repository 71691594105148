import store from '../store';
export function wholeBarWithColor() {
    if (!store.state.namespaceSettings.displayUserStatus) {
        return false
    } else {
        if (store.state.user.colorsettings.wholeBar) {
            return true
        } else {
            return false
        }
    }
}

/**
 * @param {string} status
 */
export function getColorByStatus(status) {
    const value = `${status}`.toLowerCase();
    switch (value) {
        case "break":
        case "no calls":
        case "only phone":
            return '#ff0000'
        case "busy":
        case "in meeting":
        case "just urgent calls":
        case "out of office":
            return '#ffac13'
        case "ill":
        case "absence":
        case "holidays":
            return '#7208ff'
        case "no status":
            return '#000000'
        case "coffe break":
            return '#846256'
        default:
            break;
    }
}

/**
 * @param {string} color
 */
export function systemMessagesColorText(color) {
    const value = `${color}`.toLowerCase();
    switch (value) {
        case 'red':
        case '#ff0000':
        case 'green':
        case '#00ff00':
        case 'teal':
        case '#008080':
        case 'black':
        case '#000000':
            return 'white';
        case 'yellow':
        case '#ffff00':
        case 'white':
        case '#ffffff':
        case '#38a2b8':
        default:
            return 'black';
    }
}