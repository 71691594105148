// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-theme--dark.bg-error {
  background-color: rgb(255, 0, 11) !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/receivedAndSentMessagesDropDownEffector.vue","webpack://./receivedAndSentMessagesDropDownEffector.vue"],"names":[],"mappings":"AACE;EACE,4CAAA;ACAJ","sourcesContent":["\n  .v-theme--dark.bg-error{\n    background-color: rgb(255,0,11,1) !important;\n    // color: white !important;\n  }\n",".v-theme--dark.bg-error {\n  background-color: rgb(255, 0, 11) !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
