 <template>
  <span>
    <span>{{group.groupName}}</span>
    <br/>
    <template>
      <ul v-for="user in getGroupMembers" :key="user.uuid">
        <li>{{getUserTitel(user.uuid)}} {{user.name}}</li>
      </ul>  
    </template>
  </span>
</template>
<script>
import store from "../../store";
export default {
  props: ["group"],
  data() {
    return {
      state: store.state,
    };
  },

  methods: {
   getUserTitel(uuid){
     let title = store.getTitelForUuid(uuid) || '';
      if (title !== ''){
        title = title + ', ';
      }
      return title;
    },
  },
  mounted: function () {},

  computed: {
  getGroupMembers(){
     return this.group.groupMembers || [];
   },   
  }
};
</script>
<style scoped lang="scss">
.keepLineaBreak{
  white-space:pre-line
}
</style>