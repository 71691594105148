// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.closeButton[data-v-601cc3b4] {
  background-color: green !important;
  border-radius: 0 !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/statusColorModal.vue","webpack://./statusColorModal.vue"],"names":[],"mappings":"AACA;EACE,kCAAA;EACA,2BAAA;ACAF","sourcesContent":["\n.closeButton {\n  background-color: green !important;\n  border-radius: 0 !important;\n}\n",".closeButton {\n  background-color: green !important;\n  border-radius: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
