<template>
  <div class="mainMeetingLine timelineEntryContainer">
    <template v-if="getCurrentDateTimelineEvents.length > 0">
      <MeetingLineItem
        v-for="entry of getCurrentDateTimelineEvents"
        :key="entry.uuid"
        :totalEntries="currentDateTimelineEvents.length"
        :index="entry.collisionIndex"
        :entry="entry"
        :date="date"
      />
    </template>
    <div class="meetingLineSpacerBody">
      <div class="boxupperLast" @click="goToCalendar">
        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <div v-bind="props" class="d-flex justify-space-around align-center align-center pt-2 pr-2 pl-1">
                <font-awesome-icon :icon="['fal', 'chevron-left']" @click="prevDay($event)" class="" :style="{ color: 'white', fontSize: '20px' }" />
                <span class="d-md-none d-sm-none d-none d-lg-flex text-white text-caption font-weight-bold">{{moment(date).format('DD.MM.YY')}}</span>
                <font-awesome-icon :icon="['fal', 'chevron-right']" @click="nextDate($event)" class="" :style="{ color: 'white', fontSize: '20px' }" />
                <v-menu
                 v-if="showRepresentativeBtn"
                  content-class="bgw"
                  ref="startDateMenu"
                  :close-on-content-click="false"
                  :model-value="showSelector" @update:model-value="showSelector = $event"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                <template #activator="{ props }">
                  <div v-bind="props">
                  <v-tooltip location="top">
                    <template v-slot:activator="{props}">
                      <!-- <v-btn  v-on="{ ...onMenu, ...onTooltip }" icon small> -->
                      <font-awesome-icon
                        v-bind="props"
                        class="mr-2"
                        :style="{ color: 'white', fontSize: '14px' }"
                        :icon="['fal', 'user']"
                      />
                    <!-- </v-btn> -->
                    </template>
                    <span >{{ $t('components.calendarContainer.seeCalendarFor') }}</span>
                  </v-tooltip>
                  </div>
                </template>
                  <v-select
                    return-object
                    :model-value="representing"
                    item-title="name"
                    item-value="uuid"
                    :items="peopleIRepresent"
                    density="compact"
                    variant="outlined"
                    hide-details
                    class="ma-2 bgw"
                    @update:model-value="switchCalendarUser"
                  ></v-select>
                </v-menu>
              </div>
              <!-- <p v-on="on" class="timelineLast mb-0 mt-1">
                <font-awesome-icon :icon="['fal', 'chevron-left']" @click="prevDay($event)" class="prevDay" :style="{ color: 'white', fontSize: '20px' }" />
                <span class="spanDate">{{moment(date).format('DD.MM.YY')}}</span>
                <font-awesome-icon :icon="['fal', 'chevron-right']" @click="nextDate($event)" class="nextDate" :style="{ color: 'white', fontSize: '20px' }" />
              </p> -->
            </template>
            <span>{{ $t('components.calendarContainer.videoCallPlanner') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <v-btn style="width:26px; height:26px;" v-bind="props" icon  @click="goToMyEvents($event)">
                <v-badge
                    bordered
                    color="error"
                    v-if="totalEvents > 0"
                    :content="totalEvents"
                    :model-value="!!totalEvents"
                    height="26"
                    width="26"
                    style="margin-left: 0px;"
                    overlap
                > </v-badge>
                <font-awesome-icon
                  v-if="totalEvents == 0"
                  color="transparent"
                  :icon="['fal', 'eye']"
                />
              </v-btn>
            </template>
            <span v-if="totalEvents == 1" >{{ 1 + ' '+ $t('components.calendarContainer.viewMyEvent')}}</span> 
            <span v-else-if="totalEvents > 1" >{{ totalEvents + ' '+ $t('components.calendarContainer.viewMyEvents')}}</span> 
            <span v-else >{{ $t('components.calendarContainer.noEventsToSee')}}</span>
          </v-tooltip>
          <!-- <v-menu
            content-class="bgw"
            ref="startDateMenu"
            :close-on-content-click="false"
            :model-value="showSelector" @update:model-value="showSelector = $event"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
          <template #activator="{ on: onMenu }">
            <v-tooltip location="top">
              <template v-slot:activator="{ on: onTooltip   }">
                <v-btn v-show="showRepresentativeBtn" v-on="{ ...onMenu, ...onTooltip }" icon small>
                <font-awesome-icon
                  color="white"
                  :icon="['fal', 'user']"
                />
              </v-btn>
              </template>
              <span >{{ $t('components.calendarContainer.seeCalendarFor') }}</span>
            </v-tooltip>
          </template>
            <v-select
              return-object
              :value="representing"
              item-text="name"
              item-value="uuid"
              :items="peopleIRepresent"
              density="compact"
              variant="outlined"
              hide-details
              class="ma-2 bgw"
              @change="switchCalendarUser"
            ></v-select>
          </v-menu> -->
        </div>
      </div>
      <MeetingLineSpacer
        v-for="(hour, index) of ['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18','19']"
        :key="index"
        :hour="hour"
        :date="date"
        :handleNewEventEntry="handleNewEventEntry"
      />
      <MeetingLineNowIndicator v-show="showTimelineNow" />
    </div>
    <div></div>
    <ModalEventType 
      :showModalEventType="showModalEventType" 
      :showModalEventTypeFunction="showModalEventTypeFunction" 
      :closeModalEventTypeFunction="closeModalEventTypeFunction"
      :cancelModalEventTypeFunction="cancelModalEventTypeFunction"
      :typeVideoCall="typeVideoCall"
      :changeEventType="changeEventType"
    />
  </div>
</template>

<script>
import moment from "../../../sharedsrc/moment";
import store, { EventBus } from "../../store";
import MeetingLineItem from "./meetingLineItem.vue";
import MeetingLineSpacer from "./meetingLineSpacer.vue";
import MeetingLineNowIndicator from "./meetingLineNowIndicator.vue";
import ModalEventType from "./modalEventType.vue";
import { useStore } from "effector-vue/composition";
import {
  representedUsersStore,
  setRepresentedUuidEvent,
  uuidToRepresentStore,
  actingAsUuidStore,
} from "../../effector/representative";
import {
  timelineEvents,
  dispatchFetchMyItemsForDateEvent,
  getTimelineDateFilter,
  dateToCalendarKey,
  pleaseShowDateInTimelineEvent
} from "../../effector/timeline";
import { ownUserUUIDState, ownUserState } from "../../effector/users";

const hasOwn = Object.prototype.hasOwnProperty;

export default {
  components: {
    MeetingLineItem,
    MeetingLineSpacer,
    MeetingLineNowIndicator,
    ModalEventType
  },
  data() {
    return {
      state: store.state,
      setCurrentContentVisile: store.setCurrentContentVisile,
      currentDateTimelineEvents: [],
      date: Date.now(),
      showMenu: true,
      showCalMenu: true,
      seeDay: "",
      day: "",
      moment: moment,
      showSelector: false,
      showModalEventType: false,
      typeVideoCall: "directCall",
      dateFromClick: null,
      hourFromClick: null,
      // Effector
      representedUsers: useStore(representedUsersStore),
      ownUUID: useStore(ownUserUUIDState),
      ownState: useStore(ownUserState),
      impersonating: useStore(actingAsUuidStore),
      uuidToRepresent: useStore(uuidToRepresentStore),
      rawEvents: useStore(timelineEvents),
    };
  },
  methods: {
    prevDay(event){
      event.preventDefault();
      event.stopPropagation();
      const newDate = new Date(this.date);
      this.date = newDate.setDate(newDate.getDate() - 1)
      pleaseShowDateInTimelineEvent(this.date);
    },
    nextDate(event){
      event.preventDefault();
      event.stopPropagation();
      const newDate = new Date(this.date);
      this.date = newDate.setDate(newDate.getDate() + 1)
      pleaseShowDateInTimelineEvent(this.date);
    },
    switchCalendarUser(obj) {
      setRepresentedUuidEvent(obj.uuid);
      this.showSelector = false;
    },
    goToMyEvents(event){
      // set last visited calendar
      if(this.totalEvents < 1) return;
      event.preventDefault();
      event.stopPropagation();
      if (this.$route.path !== "/myevents") {
        store.setCurrentContentVisile("myevents", true, this.$router);
      }
    },
    goToCalendar() {
      if (this.$route.path === "/calendar") {
        store.setCurrentContentVisile("/home", true, this.$router);
      } else {
        this.setCurrentContentVisile(`/calendar`, true, this.$router);
      }
    },
    actualDay() {
      const date = new Date();
      date.setDate(date.getDate());
      if ((date.getDay() != new Date(this.date).getDay()) && date.getHours() < 2){
        this.date = date.getTime();
      }
    },
    handleNewEventEntry(event, date, hour) {
      event.preventDefault();
      event.stopPropagation();
        this.dateFromClick = date;
        this.hourFromClick = hour;
        const fullDate = new Date(date).setHours(hour,0,0,0);
        if ( !this.$route.path.includes("/newconference") ) {
          this.$router.push({ path: `/newconference/00-${fullDate}` });
        }
        else {
          EventBus.$emit("loadInsertingTodayNormal", fullDate);
        }
    },
    startEvent(){
        this.$router.push({ path: `/newconference` });
    },
    changeEventType(value){
      this.typeVideoCall = value;
    },
    showModalEventTypeFunction(){
      this.showModalEventType = true;
    },
    closeModalEventTypeFunction(){
      this.showModalEventType = false;
      this.startEvent();
    },
    cancelModalEventTypeFunction(){
      this.showModalEventType = false;
    },
    removeCollisions() {
      if (this.currentDateTimelineEvents) {
        this.currentDateTimelineEvents.forEach((v) => {
          if (v && hasOwn.call(v, 'collisionIndex')) delete v.collisionIndex;
          if (v && hasOwn.call(v, 'collisions')) delete v.collisions;
        });
      }
    },
    resubscribeEventFilter() {
      if (this._unsubFilter) this._unsubFilter();
      this._unsubFilter = this.currentDateTimelineEventsFilter.watch(state => {
        this.removeCollisions();
        for (let i = 0; i < state.length; i++) {
          const A = state[i];
          A.collisionIndex = 0;
          A.collisions = [];
        }
        for (let i = 0; i < state.length; i++) {
          const A = state[i];
          for (let j = 0; j < state.length; j++) {
            const B = state[j];
            const BstartCollides = moment(B.start).isBetween(
              A.start,
              A.end,
              undefined,
              "()"
            );
            const BendCollides = moment(B.end).isBetween(
              A.start,
              A.end,
              undefined,
              "()"
            );
            const AstartCollides = moment(A.start).isBetween(
              B.start,
              B.end,
              undefined,
              "()"
            );
            const AendCollides = moment(A.end).isBetween(
              B.start,
              B.end,
              undefined,
              "()"
            );
            if (
              BstartCollides ||
              BendCollides ||
              AstartCollides ||
              AendCollides
            ) {
              if (!A.collisions) A.collisions = [];
              if (!B.collisions) B.collisions = [];
              if (A.collisions.indexOf(B) === -1) A.collisions.push(B);
              if (B.collisions.indexOf(A) === -1) B.collisions.push(A);
            }
          }
        }
        const sortedState = state.sort(function(a, b) {
          const A = new Date(a.end) - new Date(a.start);
          const B = new Date(b.end) - new Date(b.start);
          if (A < B) return -1;
          if (A > B) return 1;
          return 0;
        });
        for (let i = 0; i < sortedState.length; i++) {
          const A = sortedState[i];
          if (A.collisionIndex === undefined) A.collisionIndex = 0;
          for (let j = 0; j < sortedState.length; j++) {
            const B = sortedState[j];
            if (A === B) continue;
            if (
              A.collisions &&
              A.collisions.indexOf(B) !== -1 &&
              A.collisionIndex === B.collisionIndex
            ) {
              A.collisionIndex++;
            }
          }
        }
        this.currentDateTimelineEvents = state.sort(function(a, b) {
          return new Date(a.start) - new Date(b.start);
        });
      });
    },
    timelineDateEventHandler(date) {
      this.date = new Date(date).getTime();
    },
  },
  computed: {
    getCurrentDateTimelineEvents() {
      const finalResult = this.currentDateTimelineEvents.filter(
        (e) => !e.isTemp
      );
      return finalResult;
    },
    totalEvents(){
      const dateCounter = new Date();
      const endDate = new Date();
      endDate.setDate(dateCounter.getDate() + 30);
      let lastViewed = ((store.state.user || {}).lastViewedCalendar || '');
      // eslint-disable-next-line no-unmodified-loop-condition
      while (dateCounter <= endDate) {
        dispatchFetchMyItemsForDateEvent(dateCounter.toISOString());
        dateCounter.setDate(dateCounter.getDate() + 1);
      }
      const eventsObject = {};
      const todaysDate = this.moment(new Date()).format('MM.DD.YYYY');
      this.rawEvents.forEach(event => {
        const evDate = this.moment(event.start).format('MM.DD.YYYY');
        const date = event.start;
        const creation = event.creationServerTs; 
          if (lastViewed === '' ){
            store.state.user.lastViewedCalendar = 946684800000;
          }
          if (lastViewed < creation){
            if ( !eventsObject[date] && (evDate > todaysDate) ){
              eventsObject[date] = [];
            }
            if (evDate > todaysDate) {
              eventsObject[date].push(event); 
            }
          }   
      });
      return Object.keys(eventsObject).length || 0;
    },
    showRepresentativeBtn() {
      return this.peopleIRepresent.length > 1;
    },
    representing: {
      get: function () {
        return this.peopleIRepresent.find(e => e.uuid === this.impersonating);
      },
    },
    peopleIRepresent() {
      const peopleArray = [];
      if (!this.representedUsers) return [];
      peopleArray.push({ name: store.state.user.name, uuid: this.ownUUID });
      if (this.representedUsers) {
        if (!this.representedUsers.length) {
          return peopleArray;
        } else {
          for (let i = 0; i < this.representedUsers.length; i++) {
            const uuid = this.representedUsers[i];
            const representative = {
              name: ((store.state.group[uuid] || {}).user || {}).name || "",
              uuid: uuid
            };
            peopleArray.push(representative);
          }
        }
      }
      return peopleArray;
    },
    currentDateTimelineEventsFilter() {
      return getTimelineDateFilter(this.date);
    },
    calendarKey() {
      return moment(dateToCalendarKey(this.date)).format("DD.MM.YYYY");
    },
    showTimelineNow() {
      const currentDate = new Date().toDateString();
      const selectedDate = new Date(this.date).toDateString();
      return currentDate === selectedDate;
    }
  },
  watch: {
    day() {
      this.seeDay = this.day;
      this.date = this.day;
    },
    currentDateTimelineEventsFilter() {
      this.resubscribeEventFilter();
    },
    date() {
      dispatchFetchMyItemsForDateEvent(this.date);
    }
  },
  mounted() {
    dispatchFetchMyItemsForDateEvent(this.date);
    this.resubscribeEventFilter();
    this._timer = setInterval(this.actualDay.bind(this), 3600000); //check every hour
  },
  created() {
    this._dateEventUnwatch = pleaseShowDateInTimelineEvent.watch(
      this.timelineDateEventHandler.bind(this)
    );
  },
  unmounted() {
    if (this._timer) clearInterval(this._timer);
    if (this._dateEventUnwatch) this._dateEventUnwatch();
    if (this._unsubFilter) this._unsubFilter();
    this.removeCollisions();
  }
};
</script>

<style lang="scss" scoped>
.spanDate{
  position: relative;
  top: 2px;
}
.prevDay{
  position: absolute;
  left: 8px;
  cursor: pointer;
}
.nextDate{
  position: absolute;
  right: 17px;
  cursor: pointer;
}
.boxupperLast {
  cursor: pointer;
  border-right: 1px solid #e0e0e0;
  background-color: var(--v-primary-color);
  height: 35px;
  z-index: 100;
  position: absolute;
  width: calc((100% / 13) - -11px);
  float: left;
  text-align: center;
  right: 0;
  top: 0;
}
.timelineLast {
  width: 100%;
  font-size: 12px;
  z-index: 99;
  position: relative;
  font-weight: 600;
  color: white;
  text-align: left;
  position: relative;
  top: 1px;
  text-align: center;
}
.meetingLineSpacerBody {
  width: 100%;
  height: 37px;
  z-index: 25;
  background-color: transparent;
}
.mainMeetingLine {
  height: 55px;
  width: 100%;
}
.todayEventsBadge {
  background-color: transparent !important;
  border-color: transparent !important;
  color: black !important;
}
.timelineEntryContainer {
  position: relative;
}
.bgw {
  background-color: #fff !important;
}
</style>
<style lang="scss">
.todayEventsBadge {
  & .v-badge__badge{
    color: black !important;
    font-family: fantasy;
    font-weight: bold;
  }
   & .v-badge__badge:after{
    border-color: transparent !important;
  }
}
</style>
