<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="570"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="state.infoModal.header"
        :closeModalFunction="closeModal"
      />
      <v-row :style="{ width: '100%', margin: 0 }" class="px-5">
        <v-card-text>
          <p class="breakSpaces">{{ state.infoModal.body }}</p>
        </v-card-text>
      </v-row>
      <FooterModal v-if="!state.infoModal.disableFooter" :closeModalFunction="closeModal" />
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
    };
  },
  methods: {
    closeModal() {
      const data = { show: false, header: "", body: "" };
      return store.setinfoModal(data);
    },
  },
  computed: {
    showModal() {
      return (
        store.state.infoModal.show &&
        store.state.infoModal.header &&
        store.state.infoModal.body
      );
    },
  },
};
</script>
<style scoped lang="scss">
.breakSpaces {
  white-space: break-spaces;
}
</style>
