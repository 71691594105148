<template>
  <div v-if="showAlertMessages">
    <div class="blinkContainer">
      <div v-if="micMute" class="microphoneSlashOverlay mr-2">
        <img
          src="/img/micro-full-red.svg"
        />
      </div>
      <div class="soundSlashOverlay mr-2" v-if="audioOutputMuted">
        <img
          src="/img/volume-slash-red-red.svg"
        />
      </div>
       <div class="cameraOffSlashOverlay mr-2" v-if="cameraOffByModerator">
        <img
          src="/img/user-camera-slash-red.svg"
        />
      </div>

    </div>
    <div v-if="getBadQuality" class="badQualityText">
      {{ $t("components.bridgeCallTooltips.badQuality") }}
    </div>
  </div>
</template>
<script>
import store from "../../../store";
export default {
  components: {},
  props: ["micMute", "badQualityUser", "audioOutputMuted", "room", "cameraOffByModerator"],
  data() {
    return {
      state: store.state,
    };
  },
  methods: {},
  computed: {
    getBadQuality() {
      if (!this.room) return;
      const participantId = this.room.myUserId();
      if (this.badQualityUser.indexOf(participantId) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    showAlertMessages() {
      return this.micMute || this.getBadQuality || this.audioOutputMuted;
    },
  },
};
</script>
<style scoped lang="scss">
.blinkContainer{
  position: absolute;
  // z-index: 9999999;
  width: 100%;
  bottom: 20px;
  z-index: 1;
  // animation: blinker 1s linear infinite;
}
.badQualityText {
  color: red;
  font-size: 20px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  // z-index: 9999999;
  position: absolute;
  // animation: blinker 1.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
  z-index: 1;
}
.cameraOffSlashOverlay{
    width: 30px;
    height: 30px;
    position: relative;
    float: right;
}
.soundSlashOverlay {
    width: 30px;
    height: 30px;
    position: relative;
    float: right;
}
// .sound-slash-overlay-blink {
//   animation: blinker 1s linear infinite;
// }
.microphoneSlashOverlay {
     width: 30px;
    height: 30px;
  // bottom: 20px;
  // right: 50px;
  // z-index: 1;
  position: relative;
  float: right;
}
// .microphone-slash-overlay-blink {
//   animation: blinker 1s linear infinite;
// }
@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>