// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.borderRadius5[data-v-ee0453e4] {
  border-radius: 5px !important;
}
.borderRadius4[data-v-ee0453e4] {
  border-radius: 4px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/image/defaultAvatar.vue","webpack://./defaultAvatar.vue"],"names":[],"mappings":"AACA;EACE,6BAAA;ACAF;ADEA;EACE,6BAAA;ACCF","sourcesContent":["\n.borderRadius5 {\n  border-radius: 5px !important;\n}\n.borderRadius4 {\n  border-radius: 4px !important;\n}\n",".borderRadius5 {\n  border-radius: 5px !important;\n}\n\n.borderRadius4 {\n  border-radius: 4px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
