<template>
  <div>
    <!-- copy link-->
    <v-tooltip location="top" v-if="getAmIModerator && hasPrivilege">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="mx-2 darkGreyIcon"
          color="white"
          icon
          large
          @click="copyLink($event)"
        >
          <font-awesome-icon
            :icon="['fal', 'link']"
            :style="{ fontSize: '20px' }"
          />
        </v-btn>
      </template>
      <span>{{ $t("components.callsContent.copyLink") }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import store from "../../../../store";
import { getDirectCallInviteLink } from "../../../../lib/wsMsg";
import { hasPrivilege, isOnlyOneCallUser } from "../../../../utils/privileges";
import { isMobile } from "../../../../lib/mobileUtil";
export default {
  components: {},
  props: ["getAmIModerator", "room"],
  data() {
    return {
      state: store.state,
          isMobile: isMobile(),
      link: "",
    };
  },
  methods: {
    async copyLink(evt) {
      let instalink = await getDirectCallInviteLink();
      const selBox = document.createElement("textarea");
      selBox.style.position = "fixed";
      selBox.style.left = "0";
      selBox.style.top = "0";
      selBox.style.opacity = "0";
      selBox.value = instalink;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand("copy");
      document.body.removeChild(selBox);
      evt.target.classList.add("greenClass");
      setTimeout(() => {
        evt.target.classList.remove("greenClass");
      }, 1000);
    },
  },
  computed: {
    hasPrivilege() {
      return hasPrivilege(store.state.ownUUID);
    },
  },
};
</script>
<style scoped lang="scss">
.v-btn--icon.v-size--large{
  width: 40px!important;
  height: 40px!important;
}
.darkGreyIcon {
  background-color: #464b4e !important;
  border-color: #464b4e !important;
}

.greenClass {
  font-weight: 800;
  color: green;
}
</style>
