<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="740"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('generics.language')"
        :closeModalFunction="closeModal"
      />
      <v-card-text>
        <v-radio-group
          inline
          class="ml-2 mt-0"
          :model-value="radiosLanguage" @update:model-value="radiosLanguage = $event"
          :mandatory="false"
        >
          <v-radio value="DE">
            <template v-slot:label>
              <v-list-item>
                <v-avatar
                  tile
                  width="23"
                  height="17"
                  min-width="23"
                  max-height="17"
                  max-width="23"
                >
                  <v-img  width="23" height="17" min-width="23" max-height="17" max-width="23"  :src="'img/flags/DE.png'"></v-img>
                </v-avatar>
                <v-list-item-title class="notranslate text-primary"
                  >DE</v-list-item-title
                >
              </v-list-item>
            </template>
          </v-radio>
          <v-radio value="EN">
            <template v-slot:label>
              <v-list-item>
                <v-avatar
                  tile
                  width="23"
                  height="17"
                  min-width="23"
                  max-height="17"
                  max-width="23"
                >
                  <v-img width="23" height="17" min-width="23" max-height="17" max-width="23" :src="'img/flags/EN.png'"></v-img>
                </v-avatar>
                <v-list-item-title class="notranslate text-primary"
                  >EN</v-list-item-title
                >
              </v-list-item>
            </template>
          </v-radio>
          <v-radio value="ES">
            <template v-slot:label>
              <v-list-item>
                <v-avatar
                  tile
                  width="23"
                  height="17"
                  min-width="23"
                  max-height="17"
                  max-width="23"
                >
                  <v-img width="23" height="17" min-width="23" max-height="17" max-width="23" :src="'img/flags/ES.png'"></v-img>
                </v-avatar>
                <v-list-item-title class="notranslate text-primary"
                  >ES</v-list-item-title
                >
              </v-list-item>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>
      <FooterModal :closeModalFunction="closeModal">
        <v-btn
          color="primary"
          @click="saveLanguage()"
          >{{ $t("generics.save") }}</v-btn
        >
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import {
  // setLanguageModalEvent,
  resetLanguageModalEvent,
  languageModalStore,
} from "../../effector/modals";
export default {
  props: [],
  components: { HeaderModal, FooterModal },
  data() {
    const effector = {
      showModal: languageModalStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      radiosLanguage: "",
      // Effector
      ...effector,
    };
  },
  watch: {},
  mounted() {
    this.radiosLanguage = this.$locale.current().toUpperCase();
  },
  methods: {
    closeModal() {
      resetLanguageModalEvent();
    },
    saveLanguage() {
      setTimeout(() => {
        this.$locale.change(this.radiosLanguage.toLowerCase());
        this.$set(this.state.user, "language", this.$locale.current());
      }, 300);
      this.closeModal();
    },
  },
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>