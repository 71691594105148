<template>
  <div style="display: flex">
    <v-text-field :model-value="tempValue" @update:model-value="tempValue = $event" :placeholder="placeholder" :rules="rules" />
    <v-tooltip>
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon style="margin-top: 6px;" @click="$emit('accept', tempValue)">
                <font-awesome-icon :icon="['fal', 'check']" />
            </v-btn>
        </template>
        <span>{{ $t('components.manageOrganisation.acceptChanges') }}</span>
    </v-tooltip>
    <v-tooltip location="top">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon style="margin-top: 6px;" @click="$emit('discard')">
                <font-awesome-icon :icon="['fal', 'times']" />
            </v-btn>
        </template>
        <span>{{ $t('components.manageOrganisation.discardChanges') }}</span>
    </v-tooltip>
</div>
</template>

<script>
import Vue from "@vue/compat";
import Popper from 'vue-popperjs'

export default {
  props: ["value", "placeholder", "rules"],
  watch: {
      value: {
        immediate: true,
        deep: true,
        handler(value) {
            this.tempValue = value
        }
      }
  },
  data() {
    return {
      tempValue: null
    }
  },
}
</script>

<style lang="scss" scoped>
:deep() .v-btn--icon {
    width: 24px !important;
    height: 24px !important;
}
</style>