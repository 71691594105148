// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.colImage[data-v-2245db09] {
  width: 25%;
}
.w100[data-v-2245db09] {
  width: 100%;
}
.pRelative[data-v-2245db09] {
  position: relative;
}
.buttonDeleteWallpaper[data-v-2245db09] {
  position: absolute;
  top: -12px;
  left: -38px;
}
.selectedWallpaper[data-v-2245db09] {
  position: absolute;
  top: 5px;
  left: 5px;
}
.viewingLabel[data-v-2245db09] {
  margin-left: 25px;
  font-size: 12px;
}
.internalPlayerCB[data-v-2245db09] {
  margin-left: 20px;
  margin-top: 0px;
  padding-top: 0px;
  max-height: 26px;
}
.footerModal[data-v-2245db09] {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
}
.footerModal .btns[data-v-2245db09] {
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/bridgeCallStreamSettingsModal.vue","webpack://./bridgeCallStreamSettingsModal.vue"],"names":[],"mappings":"AACA;EACE,UAAA;ACAF;ADEA;EACE,WAAA;ACCF;ADCA;EACE,kBAAA;ACEF;ADAA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;ACGF;ADDA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;ACIF;ADFA;EACE,iBAAA;EACA,eAAA;ACKF;ADHA;EACE,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;ACMF;ADJA;EACE,WAAA;EACA,6BAAA;EACA,uBAAA;ACOF;ADNE;EACE,yBAAA;ACQJ","sourcesContent":["\n.colImage {\n  width: calc(100% / 4);\n}\n.w100 {\n  width: 100%;\n}\n.pRelative {\n  position: relative;\n}\n.buttonDeleteWallpaper {\n  position: absolute;\n  top: -12px;\n  left: -38px;\n}\n.selectedWallpaper {\n  position: absolute;\n  top: 5px;\n  left: 5px;\n}\n.viewingLabel {\n  margin-left: 25px;\n  font-size: 12px;\n}\n.internalPlayerCB {\n  margin-left: 20px;\n  margin-top: 0px;\n  padding-top: 0px;\n  max-height: 26px;\n}\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n  .btns {\n    justify-content: flex-end;\n  }\n}\n",".colImage {\n  width: 25%;\n}\n\n.w100 {\n  width: 100%;\n}\n\n.pRelative {\n  position: relative;\n}\n\n.buttonDeleteWallpaper {\n  position: absolute;\n  top: -12px;\n  left: -38px;\n}\n\n.selectedWallpaper {\n  position: absolute;\n  top: 5px;\n  left: 5px;\n}\n\n.viewingLabel {\n  margin-left: 25px;\n  font-size: 12px;\n}\n\n.internalPlayerCB {\n  margin-left: 20px;\n  margin-top: 0px;\n  padding-top: 0px;\n  max-height: 26px;\n}\n\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n}\n.footerModal .btns {\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
