<template>
    <vue-scroll  :class="{'vueScrollTemplates': applyClass, 'vueScrollOne': applyOne, 'vueScrollTwo': applyTwo, 'vueScrollThree': applyThree }" v-if="groups.length > 0">
      <div v-for="(item, index) in sortedGroups" :key="index" class="conf" :id="item.groupId">
          <TemplateListItem  :makeSelection="makeSelection" flat :confTemplate="item" />
      </div>
    </vue-scroll>
</template>

<script>
import TemplateListItem from './templateListItem.vue';
import store from '../../store';
export default {
  components: { TemplateListItem },
  props: ['groups', 'section', 'selectTemplate'],
  data() {
    return {
      state: store.state,
    };
  },
  mounted() {
  },
  methods:{
   makeSelection(templateId){
     this.selectTemplate(templateId);
   } 
    
  },
  computed:{
   applyClass(){
      return (this.section=='myTemplates' && this.groups.length >= 3 );
    },
    applyOne(){
      return (this.section == 'myTemplates'  && this.groups && this.groups.length && this.groups.length <= 1 )
    },
    applyTwo(){
      return ( this.section == 'myTemplates'  && this.groups && this.groups.length && this.groups.length == 2 )
    },
     applyThree(){
      return ( this.section == 'myTemplates'  && this.groups && this.groups.length && this.groups.length == 3 )
    },
    
    sortedGroups(){
      return [...this.groups]
        .sort((a, b) => {
          return a.groupName < b.groupName ? -1 : (a.groupName > b.groupName ? 1 : 0)
        });
    }
  }
};
</script>

<style scoped lang="scss">

.scroller {
  height: 100%;
}
.vueScrollTemplates{
  height: 260px !important;
    @media screen and (max-height: 650px) {
      height: 260px !important;
    }
  & .__view{
    width: unset !important;
  }
}

.vueScrollOne{
  height: 65px !important;
   @media screen and (max-height: 650px) {
      height: 65px!important;
  }
}

.vueScrollTwo{
  // max-height: calc(100vh - 720px)!important;
  height: 130px !important;
   @media screen and (max-height: 650px) {
      height: 130px!important;
  }
}

.vueScrollThree{
  // max-height: calc(100vh - 720px)!important;
  height: 195px !important;
   @media screen and (max-height: 650px) {
      height: 195px!important;
  }
}

</style>

<style lang="scss">
.vueScrollVisitors{
  .__view{
    width: unset !important;
  }
}
</style>