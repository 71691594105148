
<template>
  <vue-scroll :class="`${!isMobile ? 'vueScrollFooter' : 'vueScrollFooterMobile'}`">
    <v-row :style="{width: '100%', margin: 0}" class="px-12 mx-0 text-justify">
      <v-col>
        <p>vOffice wurde 2020 entwickelt von der Berliner RA-MICRO Unternehmensgruppe unter Leitung des geschäftsführenden Unternehmers Rechtsanwalt Dr. Becker. Die zur Unternehmensgruppe gehörende RA-MICRO Software AG wurde von RA Dr. Becker 1982 gegründet; Diese ist seit Jahrzehnten der deutsche Marktführer für Anwalts-EDV, ihr vertraut die deutsche Anwaltschaft.</p>

        <p>Ziel von vOffice ist:</p>

        <ul>
          <li>Maximal effiziente und produktive arbeitsteilige Internet-Zusammenarbeit im vOffice Virtuellen Büro von ortsunabhängig organisierten Mitarbeitern von kleinen und mittlerem Office-Strukturen mittels Visualisierung der Organisationsstrukturen als strukturierte Arbeitsgrundlage.</li>
          <li>Zielgruppe sind KMUs und Organisations-Teile von Unternehmen mit gesondertem</li>
          <li>Sichere Daten und Videokommunikation innerhalb der Dateninsel vOffice Virtuelles Büro.</li>
          <li>Moderne Peer to Peer Sicherheitstechnologie Ende zu Ende verschlüsselt für hohe Sicherheitsanforderungen, wie diese insb.. für Anwälte und Steuerberater gelten.</li>
          <li>Herstellung in der Europäischen Union (EU). Vertrieb und Support in der EU für Kunden mit Sitz in der EU.</li>
          <li>Keine Server Datenerfassungen, keine Datensammlungen, keine Werbung.</li>
          <li>Einfaches und sicheres Produkt für den Arbeitsalltag - vom Unternehmer für Unternehmer.</li>
        </ul>
        <p>vOffice wird in Deutschland von Unternehmen der RA-MICRO Unternehmensgruppe vertrieben und supportet:</p>

        <ul>
          <li>RA-MICRO Software AG Berlin exklusiv für deutsche Rechtsanwälte und Steuerberater</li>
          <li>Jurasoft AG Berlin für vOffice Betreiber mit Sitz in Deutschland</li>
          <li>vOffice AG Schweiz für vOffice Betreiber mit Sitz im Ausland</li>
        </ul>
      </v-col>
    </v-row>
  </vue-scroll>
</template>

<script>
// import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
export default {
  props: ["primaryDrawer"],
  data: () => ({
    isMobile: isMobile()
  }),
  methods: {
  },
  computed: {
  }
};
</script>

<style scoped lang="scss">
#updateIcon {
  animation: blink 1s linear infinite;
}
.vueScrollFooter {
  height: calc(100% - 350px) !important;
}
.vueScrollFooterMobile {
  height: calc(100% - 400px) !important;
}
.textColor {
  color: var(--v-primary-base);
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>