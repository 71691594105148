// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputMessageWaitingRoom .v-input__append-inner {
  margin-top: 1px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/waitingRoom/messagesWaitingRoom.vue","webpack://./messagesWaitingRoom.vue"],"names":[],"mappings":"AAQE;EACE,0BAAA;ACPJ","sourcesContent":["\n.inputMessageWaitingRoom {\n  fieldset{\n    //color: white !important;\n  }\n  input, label{\n    //color: white !important;\n  }\n  .v-input__append-inner{\n    margin-top: 1px !important\n  }\n}\n",".inputMessageWaitingRoom .v-input__append-inner {\n  margin-top: 1px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
