// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nBRadius[data-v-72fbbd4d] {
  border-radius: 0px;
}
.titleGroup[data-v-72fbbd4d] {
  color: white;
}
.titleGroup .v-icon[data-v-72fbbd4d] {
  font-size: 14px !important;
}
.v-list[data-v-72fbbd4d] {
  padding: 0;
}
.v-list-item__icon[data-v-72fbbd4d] {
  margin-right: 8px !important;
}
.v-card[data-v-72fbbd4d]:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.btnIn[data-v-72fbbd4d] {
  position: absolute;
  right: 76px;
  top: 5px;
}
.btnOut[data-v-72fbbd4d] {
  position: absolute;
  top: 5;
  right: 143px;
}
.btnAddInfo[data-v-72fbbd4d] {
  position: absolute;
  right: 31px;
  top: 5px;
}
.boxactive[data-v-72fbbd4d] {
  background-color: rgba(6, 104, 119, 0.5490196078);
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/infoboard.vue","webpack://./infoboard.vue"],"names":[],"mappings":"AACA;EACE,kBAAA;ACAF;ADEA;EACE,YAAA;ACCF;ADAE;EACE,0BAAA;ACEJ;ADEA;EACE,UAAA;ACCF;ADEA;EACE,4BAAA;ACCF;ADEA;EACE,gBAAA;ACCF;ADEA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;ACCF;ADCA;EACE,kBAAA;EACA,MAAA;EACA,YAAA;ACEF;ADAA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;ACGF;ADDA;EACE,iDAAA;ACIF","sourcesContent":["\n.nBRadius {\n  border-radius: 0px;\n}\n.titleGroup {\n  color: white;\n  .v-icon {\n    font-size: 14px !important;\n  }\n}\n\n.v-list {\n  padding: 0;\n}\n\n.v-list-item__icon {\n  margin-right: 8px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}\n\n.btnIn {\n  position: absolute;\n  right: 76px;\n  top: 5px;\n}\n.btnOut {\n  position: absolute;\n  top: 5;\n  right: 143px;\n}\n.btnAddInfo {\n  position: absolute;\n  right: 31px;\n  top: 5px;\n}\n.boxactive {\n  background-color: #0668778c;\n}\n",".nBRadius {\n  border-radius: 0px;\n}\n\n.titleGroup {\n  color: white;\n}\n.titleGroup .v-icon {\n  font-size: 14px !important;\n}\n\n.v-list {\n  padding: 0;\n}\n\n.v-list-item__icon {\n  margin-right: 8px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}\n\n.btnIn {\n  position: absolute;\n  right: 76px;\n  top: 5px;\n}\n\n.btnOut {\n  position: absolute;\n  top: 5;\n  right: 143px;\n}\n\n.btnAddInfo {\n  position: absolute;\n  right: 31px;\n  top: 5px;\n}\n\n.boxactive {\n  background-color: rgba(6, 104, 119, 0.5490196078);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
