// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DASDText[data-v-0221aa2c] {
  color: black;
  font-size: 16px;
  /* position: absolute; */
  font-weight: bold;
  letter-spacing: 0;
  font-family: "ROBOTO";
  margin-right: 3px;
}
.customDASD[data-v-0221aa2c] {
  position: absolute !important;
  right: -3px;
  bottom: 8px;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/externalServicesNew.vue","webpack://./externalServicesNew.vue"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,eAAA;EACA,wBAAA;EACA,iBAAA;EACA,iBAAA;EACA,qBAAA;EACA,iBAAA;ACAF;ADEA;EACE,6BAAA;EACA,WAAA;EACA,WAAA;EACA,kCAAA;EACA,uBAAA;EACA,2BAAA;ACCF","sourcesContent":["\n.DASDText {\n  color: black;\n  font-size: 16px;\n  /* position: absolute; */\n  font-weight: bold;\n  letter-spacing: 0;\n  font-family: \"ROBOTO\";\n  margin-right: 3px;\n}\n.customDASD {\n  position: absolute !important;\n  right: -3px;\n  bottom: 8px;\n  background: transparent !important;\n  border: none !important;\n  box-shadow: none !important;\n}\n",".DASDText {\n  color: black;\n  font-size: 16px;\n  /* position: absolute; */\n  font-weight: bold;\n  letter-spacing: 0;\n  font-family: \"ROBOTO\";\n  margin-right: 3px;\n}\n\n.customDASD {\n  position: absolute !important;\n  right: -3px;\n  bottom: 8px;\n  background: transparent !important;\n  border: none !important;\n  box-shadow: none !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
