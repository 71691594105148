// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mdi-chevron-down {
  color: var(--v-primary-300) !important;
}
.v-navigation-drawer .__rail-is-horizontal {
  display: none !important;
}
.v-navigation-drawer .v-navigation-drawer__border {
  display: none !important;
}
.v-navigation-drawer .v-navigation-drawer__content {
  overflow-y: hidden !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/drawer.vue","webpack://./drawer.vue"],"names":[],"mappings":"AACA;EACE,sCAAA;ACAF;ADGE;EACE,wBAAA;ACAJ;ADEE;EACE,wBAAA;ACAJ;ADEE;EACE,6BAAA;ACAJ","sourcesContent":["\n.mdi-chevron-down {\n  color: var(--v-primary-300) !important;\n}\n.v-navigation-drawer {\n  .__rail-is-horizontal {\n    display: none !important;\n  }\n  .v-navigation-drawer__border {\n    display: none !important;\n  }\n  .v-navigation-drawer__content {\n    overflow-y: hidden !important;\n  }\n}\n",".mdi-chevron-down {\n  color: var(--v-primary-300) !important;\n}\n\n.v-navigation-drawer .__rail-is-horizontal {\n  display: none !important;\n}\n.v-navigation-drawer .v-navigation-drawer__border {\n  display: none !important;\n}\n.v-navigation-drawer .v-navigation-drawer__content {\n  overflow-y: hidden !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
