import { callChatDomain } from './domain';
import { setCallChatEvent, resetCallChatEvent, appendCallChatEvent } from './events';

export const callChatStore = callChatDomain.createStore([], { name: 'callChat' });

callChatStore.on(setCallChatEvent, (state, payload) => {
  return payload;
});

callChatStore.on(appendCallChatEvent, (state, payload) => {
  return [ ...state, payload ];
});

callChatStore.reset(resetCallChatEvent);