<template>
  <div>
    <v-dialog
      :model-value="showModalDirectLink" @update:model-value="showModalDirectLink = $event"
      persistent
      max-width="750"
      @keydown.esc="cleanUpOnCancel"
    >
      <v-card>
        <HeaderModal
          :titleModal="$t('components.drawerContentCard.directLink')"
          :closeModalFunction="cleanUpOnCancel"
        />
        <div>
          <v-col class="col-6 px-4 py-1 pt-0 mt-3">
            <v-text-field
              :model-value="confTitle" @update:model-value="confTitle = $event"
              :label="$t('components.inviteVisitor.conferenceName')"
              required
              variant="outlined"
              density="compact"
            ></v-text-field>
          </v-col>

          <directCallMaskMultiUser
            ref="directCallMaskRef"
            :inviteList="inviteList"
          />

          <!-- <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
            <v-row class="px-2 btns w-100 mx-0 my-0">
              <template>
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="mr-2"
                      :disabled="
                        !$refs.directCallMaskRef.userListToSend ||
                        $refs.directCallMaskRef.userListToSend.length === 0 ||
                        confTitle.trim().length < 1
                      "
                      v-bind="props"
                      @click="precheckNewVisitor"
                      :loading="$refs.directCallMaskRef.isProcessing"
                      >{{ $t("generics.add") }}</v-btn
                    >
                  </template>
                  <span>{{ $t("generics.add") }}</span>
                </v-tooltip>
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="mr-2"
                      v-bind="props"
                      @click="cleanUpOnCancel()"
                      :disabled="$refs.directCallMaskRef.isProcessing"
                      >{{ $t("generics.cancel") }}</v-btn
                    >
                  </template>
                  <span>{{ $t("generics.cancel") }}</span>
                </v-tooltip>
              </template>
            </v-row>
          </v-card>
          <v-spacer></v-spacer> -->
          <FooterModal :closeModalFunction="cleanUpOnCancel" class="mt-4">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-btn
                  class="mr-2"
                  :disabled="
                    !$refs.directCallMaskRef.userListToSend ||
                    $refs.directCallMaskRef.userListToSend.length === 0 ||
                    confTitle.trim().length < 1
                  "
                  v-bind="props"
                  @click="precheckNewVisitor"
                  :loading="$refs.directCallMaskRef.isProcessing"
                  >{{ $t("generics.add") }}</v-btn
                >
              </template>
              <span>{{ $t("generics.add") }}</span>
            </v-tooltip>
          </FooterModal>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "../../store";
import directCallMaskMultiUser from "../timeline/directCallMaskMultiUser.vue";
import FooterModal from "./modalComponents/footerModal.vue";
import HeaderModal from "./modalComponents/headerModal.vue";

export default {
  props: [
    "showModalDirectLink",
    "openCloseModalDirectLink",
    "inviteList",
    "forceSingleUse",
    "cancelMultiSelect",
    "title",
  ],
  components: { FooterModal, HeaderModal },
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      confTitle: "",
    };
  },
  mounted() {
    if (this.title && this.title.length) {
      this.confTitle = this.title;
    }
  },
  components: { directCallMaskMultiUser },
  methods: {
    cleanUpOnCancel() {
      this.cancelMultiSelect();
    },
    async precheckNewVisitor() {
      this.openCloseModalDirectLink(this.confTitle);
    },
    async addUserToList() {
      this.$refs.directCallMaskRef.addUserToList();
    },
  },
};
</script>
<style scoped lang="scss">
.buttonAddUserToList {
  position: relative;
  top: 13px;
}
.selactive {
  background-color: #c1c1c166;
}
.w-100 {
  width: 100%;
}
.userNameCard {
  margin-bottom: 0px;
  font-size: 19px;
  color: #2a3133;
}
.dataUserCard {
  margin-bottom: 0px;
}
.borderRadius10,
.v-avatar .v-image {
  border-radius: 10px !important;
}
.w-200 {
  width: 200px;
}
.w-100-230 {
  width: calc(100% - 230px);
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
