// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputDirectInviteLink[data-v-0b36a0a2] {
  width: 80%;
}
.buttonCopyLink[data-v-0b36a0a2] {
  top: -5px;
  position: relative;
}
.custom-loader[data-v-0b36a0a2] {
  animation: loader-0b36a0a2 1s infinite;
}
@-moz-keyframes loader-0b36a0a2 {
from {
    transform: rotate(0);
}
to {
    transform: rotate(360deg);
}
}
@-webkit-keyframes loader-0b36a0a2 {
from {
    transform: rotate(0);
}
to {
    transform: rotate(360deg);
}
}
@-o-keyframes loader-0b36a0a2 {
from {
    transform: rotate(0);
}
to {
    transform: rotate(360deg);
}
}
@keyframes loader-0b36a0a2 {
from {
    transform: rotate(0);
}
to {
    transform: rotate(360deg);
}
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/calls/sidebarCall/addParticipantsCall.vue","webpack://./addParticipantsCall.vue"],"names":[],"mappings":"AACE;EACE,UAAA;ACAJ;ADEE;EACE,SAAA;EACA,kBAAA;ACCJ;ADCE;EACE,sCAAA;ACEJ;ADAE;AACE;IACE,oBAAA;ACGJ;ADDE;IACE,yBAAA;ACGJ;AACF;ADDE;AACE;IACE,oBAAA;ACGJ;ADDE;IACE,yBAAA;ACGJ;AACF;ADDE;AACE;IACE,oBAAA;ACGJ;ADDE;IACE,yBAAA;ACGJ;AACF;ADDE;AACE;IACE,oBAAA;ACGJ;ADDE;IACE,yBAAA;ACGJ;AACF","sourcesContent":["\n  .inputDirectInviteLink {\n    width: 80%;\n  }\n  .buttonCopyLink {\n    top: -5px;\n    position: relative;\n  }\n  .custom-loader {\n    animation: loader 1s infinite;\n  }\n  @-moz-keyframes loader {\n    from {\n      transform: rotate(0);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }\n  @-webkit-keyframes loader {\n    from {\n      transform: rotate(0);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }\n  @-o-keyframes loader {\n    from {\n      transform: rotate(0);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }\n  @keyframes loader {\n    from {\n      transform: rotate(0);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }\n",".inputDirectInviteLink {\n  width: 80%;\n}\n\n.buttonCopyLink {\n  top: -5px;\n  position: relative;\n}\n\n.custom-loader {\n  animation: loader 1s infinite;\n}\n\n@-moz-keyframes loader {\n  from {\n    transform: rotate(0);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes loader {\n  from {\n    transform: rotate(0);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n@-o-keyframes loader {\n  from {\n    transform: rotate(0);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n@keyframes loader {\n  from {\n    transform: rotate(0);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
