// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#greenCheck .mdi-checkbox-blank-outline {
  color: green;
}
#orangeCheck .mdi-checkbox-blank-outline {
  color: orange;
}`, "",{"version":3,"sources":["webpack://./websrc/components/navbar/navbarIcons.vue","webpack://./navbarIcons.vue"],"names":[],"mappings":"AAEE;EACE,YAAA;ACDJ;ADKE;EACE,aAAA;ACFJ","sourcesContent":["\n#greenCheck {\n  .mdi-checkbox-blank-outline {\n    color: green;\n  }\n}\n#orangeCheck {\n  .mdi-checkbox-blank-outline {\n    color: orange;\n  }\n}\n","#greenCheck .mdi-checkbox-blank-outline {\n  color: green;\n}\n\n#orangeCheck .mdi-checkbox-blank-outline {\n  color: orange;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
