// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-list-item-title {
  font-weight: 300 !important;
}
.buttonHome1, .buttonHome2 {
  margin-right: 36px;
}
.noBoxShadow {
  box-shadow: none !important;
}
@media only screen and (max-width: 592px) {
.buttonHome1, .buttonHome2 {
    margin-right: 0px;
}
}
@media only screen and (min-width: 593px) and (max-width: 857px) {
.buttonHome2 {
    margin-right: 0px;
}
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/mainContainer.vue","webpack://./mainContainer.vue"],"names":[],"mappings":"AACA;EACE,2BAAA;ACAF;ADEA;EACE,kBAAA;ACCF;ADCA;EACE,2BAAA;ACEF;ADAA;AACM;IACG,iBAAA;ACGP;AACF;ADDA;AACM;IACG,iBAAA;ACGP;AACF","sourcesContent":["\n.v-list-item-title {\n  font-weight: 300 !important;\n}\n.buttonHome1, .buttonHome2 {\n  margin-right: 36px;\n}\n.noBoxShadow {\n  box-shadow: none !important;\n}\n@media only screen and (max-width: 592px) {\n      .buttonHome1, .buttonHome2{\n         margin-right: 0px;\n      }\n    }\n@media only screen and (min-width: 593px) and (max-width: 857px){\n      .buttonHome2{\n         margin-right: 0px;\n      }\n    }\n",".v-list-item-title {\n  font-weight: 300 !important;\n}\n\n.buttonHome1, .buttonHome2 {\n  margin-right: 36px;\n}\n\n.noBoxShadow {\n  box-shadow: none !important;\n}\n\n@media only screen and (max-width: 592px) {\n  .buttonHome1, .buttonHome2 {\n    margin-right: 0px;\n  }\n}\n@media only screen and (min-width: 593px) and (max-width: 857px) {\n  .buttonHome2 {\n    margin-right: 0px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
