import { userSettingsDomain } from './domain';

import { setUserSettingsEvent, resetUserSettingsEvent } from './events';

export const userSettingsStore = userSettingsDomain.createStore(false, { name: 'userSettings' });

userSettingsStore.on(setUserSettingsEvent, (state, payload) => {
  return payload;
});

userSettingsStore.reset(resetUserSettingsEvent);
