<template>
  <div>
    <v-dialog
       :model-value="showModal"
    @update:model-value="showModal= $event"
      persistent
      max-width="570"
      @keydown.esc="closeModal"
    >
      <v-card>
        <HeaderModal
          :titleModal="$t('components.endMeetingModal.disconnected')"
        />
        <FooterModal :closeModalFunction="closeModal" :showClose="true">
        </FooterModal>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
    };
  },
  mounted() {
    try {
      const audioPath = '/media/notificationSound.mp3';
      // Get real devices id's
      const ringingDeviceId = store.state.persisted.mediaDeviceSetup.ringingOutputId;
      const audioDeviceId = store.state.persisted.mediaDeviceSetup.audioOutputId;
      // Pre-Set outputs
      const audioOutput = new Audio(audioPath);
      const ringingOutput = new Audio(audioPath);
      // Sync audioDevice
      let promise = Promise.resolve();
      if ('sinkId' in audioOutput && 'setSinkId' in audioOutput && audioDeviceId) {
        promise = audioOutput.setSinkId(audioDeviceId);
      }
      promise
        .then(() => audioOutput.play())
        .catch(err => console.warn('modal(endMeetingModal) Failed to play notification audio on audioOutput', err));
      // Sync && Play at ringing device only if we have ringingDeviceId for it and if it's different from audioOutputId
      if (audioDeviceId && ringingDeviceId && 'sinkId' in ringingOutput && 'setSinkId' in ringingOutput && ringingDeviceId !== audioDeviceId) {
        promise = ringingOutput.setSinkId(ringingDeviceId);
        promise
          .then(() => ringingOutput.play())
          .catch(err => console.warn('modal(endMeetingModal) Failed to play notification audio on ringingOutput', err));
      }
      setTimeout(() => {
        this.closeModal();
      }, 2000);
    } catch (error) {
      console.warn("Failed to play notification audio", error);
    }
  },
  methods: {
    closeModal() {
      return store.setshowModalEndMeeting(undefined);
    },
  },
  computed: {
    showModal() {
      return store.state.showModalEndMeeting !== undefined;
    },
  },
};
</script>
