<template>
  <v-menu offset-y :close-on-click="true" :max-height="370" :max-width="350">
        <template v-slot:activator="{ on }">
          <div v-on="on">
              <v-tooltip location="top">
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    :color="phoneColorColor"
                    v-on="on"
                  >
                  <!-- <font-awesome-icon :icon="['fas', 'headset']" :style="{ fontSize: '20px' }" v-if="iconsBlack"/> -->
                  <font-awesome-icon :icon="['fal', 'headset']" :style="{ fontSize: '20px' }"/>
                  </v-btn>
                </template>
                <span>{{ $t('components.drawerContentCard.protocol') }}</span>
              </v-tooltip>
          </div>
        </template>
        <ProtocolCallDropDown />
      </v-menu>
</template>

<script>
import ProtocolCallDropDown from "../protocolCallDropDown.vue";
export default {
  props: ['phoneColorColor', 'iconsBlack'],
  components: { ProtocolCallDropDown },
}
</script>