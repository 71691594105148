import { globalAgreementModalDomain } from './domain';

import { setAgreementStateModalEvent, resetAgreementStateModalEvent } from './events';

export const agreementModalStateStore = globalAgreementModalDomain.createStore(null, { name: 'agreementModalState' });

// eventModalStateStore.on(setEventStateModalEvent);

agreementModalStateStore.on(setAgreementStateModalEvent, (state, payload) => {
  // console.log('Updated Modal Event..... ', payload)
  return payload;
});

// agreementModalStateStore.on(resetAgreementStateModalEvent, (state, payload) => {
//   return payload;
// });

agreementModalStateStore.reset(resetAgreementStateModalEvent);
