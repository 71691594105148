<template>
  <v-main class="px-0 py-0">
    <v-row>
      <v-col class="mx-2 mr-6">
        <v-card class="elevation-0">
          <v-card-title>
            <v-text-field
              :model-value="search" @update:model-value="search = $event"
              variant="outlined"
              density="compact"
              :label="$t('generics.search')"
              single-line
              hide-details
            >
            <template v-slot:prepend-inner>
              <font-awesome-icon :icon="['fal', 'search']"/>
            </template>
            </v-text-field>
          </v-card-title>

          <!-- <template> -->
            <v-data-table
              :headers="headers"
              :items="payments"
              :items-per-page="10"
              class="guestTableList elevation-0"
              :page.sync="page"
              @page-count="pageCount = $event"
              fixed-header
              density="compact"
              :search="search"
              height="calc(100vh - 265px)"
            >
              <template v-slot:item.transactionDate="{ item }">
                {{ moment(item.transactionDate).format("DD.MM.YYYY HH:mm") }}
              </template>

              <template v-slot:item.amount="{ item }">
                {{ item.amount }} {{ item.currency }}
              </template>
              <template v-slot:item.transactionStatus="{ item }">
                {{
                  item.transactionStatus === "approved"
                    ? $t("components.payment.approved")
                    : item.transactionStatus
                }}
              </template>
              <template v-slot:item.isFacePay="{ item }">
                {{
                  item.isFacePay == 1 ? $t("generics.yes") : $t("generics.no")
                }}
              </template>

              <template v-slot:item.isPayLink="{ item }">
                {{
                  item.isPayLink == 1 ? $t("generics.yes") : $t("generics.no")
                }}
              </template>
            </v-data-table>
          <!-- </template> -->
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import store from "../../store";
import { getPayments } from "../../lib/wsMsg";
import moment from "moment";
export default {
  props: [],
  data() {
    return {
      state: store.state,
      moment: moment,
      ownUUID: store.state.ownUUID,
      setCurrentContentVisile: store.setCurrentContentVisile,
      page: 1,
      pageCount: 0,
      link: "",
      payments: [],
      search: "",
    };
  },
  mounted() {
    if (!store.getUserIsAdmin(this.state.ownUUID)) {
      this.$router.push({ path: "/home" });
    } else {
      this.getPaymentsData();
    }
  },
  methods: {
    async getPaymentsData() {
      this.payments = (await getPayments()) || [];
    },
  },
  computed: {
    headers() {
      return [
        {
          title: this.$t("components.payment.domain"),
          value: "officeDomain",
          class: "subtitle-1 px-0",
          sortable: true,
        },
        {
          title: this.$t("components.payment.payer"),
          value: "requestingUserId",
          class: "subtitle-1 px-0",
          sortable: true,
        },
        {
          title: this.$t("components.payment.payee"),
          value: "clientOriginalUserId",
          class: "subtitle-1 px-0",
          sortable: true,
        },
        {
          title: this.$t("components.payment.amount"),
          value: "amount",
          class: "subtitle-1 px-0",
          sortable: true,
        },
        {
          title: this.$t("components.payment.transactionID"),
          value: "transactionId",
          class: "subtitle-1 px-0",
          sortable: true,
        },
        {
          title: this.$t("components.payment.date"),
          value: "transactionDate",
          class: "subtitle-1 px-0",
          sortable: true,
        },
        {
          title: this.$t("components.stats.status"),
          value: "transactionStatus",
          class: "subtitle-1 px-0",
          sortable: true,
        },
        {
          title: "FacePay",
          value: "isFacePay",
          class: "subtitle-1 px-0",
          sortable: true,
        },
        {
          title: "PayLink",
          value: "isPayLink",
          class: "subtitle-1 px-0",
          sortable: true,
        },
        {
          title: this.$t("components.payment.callDuration"),
          value: "callDuration",
          class: "subtitle-1 px-0",
          sortable: true,
        },
        {
          title: this.$t("components.payment.product"),
          value: "productUUID",
          class: "subtitle-1 px-0",
          sortable: true,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.avatarList {
  border-radius: 4px !important;
}
.greenClass {
  font-weight: 800;
  color: green;
}
.guestTableList td {
  height: 55px !important;
  font-weight: 300 !important;
  font-size: 16px !important;
}
.pointer {
  cursor: pointer;
}
.v-theme--dark.v-data-table tbody tr td {
  color: white !important;
}
.v-theme--light.v-data-table thead tr th {
  color: black !important;
}
.v-theme--dark.v-data-table thead tr th {
  color: white !important;
}
// .v-pagination__item {
//   display: none;
// }
</style>