// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.saveButton[data-v-7f82f9fc] {
  width: auto !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/setVisitorNameModal.vue","webpack://./setVisitorNameModal.vue"],"names":[],"mappings":"AACA;EACE,sBAAA;ACAF","sourcesContent":["\n.saveButton {\n  width: auto !important;\n}\n\n",".saveButton {\n  width: auto !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
