<template>
<div>
  <div>{{getDate}}</div>
  <div>{{ getTimeRange }}</div>
  <div v-for="(uuid, key) in entry.users" :key="key">
    {{getTitelByUuid(uuid)}} {{getNameByUuid(uuid)}}
  </div>
  <div>{{getInternalNote}}</div>
</div>
</template>

<script>
import moment from "../../../../sharedsrc/moment";
import store from "../../../store";
// import { getUserStateStore, ownUserUUIDState } from '../../../effector/users';
export default {
  props: ['entry'],
  data() {
    return {
      state: store.state,
      moment:moment
    };
  },
  mounted(){

  },
  computed: {
    getDate(){
      return moment(this.entry.start).format("DD.MM.YY")
    },
  getInternalNote(){
     return store.state.persisted.userVisitors[this.entry.users[0]].noteForContact
    },
    getTimeRange(){
      return moment(this.entry.start).format("HH:mm") +  ' ' + this.$t('components.conferenceForm.to') + ' ' + moment(this.entry.end).format("HH:mm");
    },
    rsvp() {
      return this.entry.rsvp;
    },
    keys() {
      if (!this.rsvp) return [];
      return Object.keys(this.rsvp);
    },
  },
  methods: {
    getNameByUuid(uuid){
      return store.getNameForUuid(uuid) 
    },
    getTitelByUuid(uuid){
      return store.getTitelForUuid(uuid)
    },
    
  },
};
</script>

<style lang="scss" scoped>

</style>