// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.domain[data-v-2856d3b3] {
  border: 1px solid blue;
  padding: 3px;
  margin: 2px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/dev/blinkenlights/domain.vue","webpack://./domain.vue"],"names":[],"mappings":"AACA;EACE,sBAAA;EACA,YAAA;EACA,WAAA;ACAF","sourcesContent":["\n.domain {\n  border: 1px solid blue;\n  padding: 3px;\n  margin: 2px;\n}\n",".domain {\n  border: 1px solid blue;\n  padding: 3px;\n  margin: 2px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
