// import { timelineDomain.createEffect } from 'effector';
import { messageDomain } from './domain';

import { getUserMessages, createMessageEntry, createReplyMessageEntry, getMessageEntryByUUID, updateMessagesUsersParams, updateSpecificMessagesParam, updateMessagesParams, updateConversationParams } from '../../lib/wsMsg';

export const fetchMyMessagesEffect = messageDomain.createEffect({
  name: 'fetchMyMessagesEffect',
  handler: getUserMessages,
});

export const createMessageEntryEffect = messageDomain.createEffect({
  name: 'createMessageEntryEffect',
  handler: createMessageEntry,
});

export const createReplyMessageEntryEffect = messageDomain.createEffect({
  name: 'createReplyMessageEntryEffect',
  handler: createReplyMessageEntry,
});

export const getMessageEntryByUUIDEffect = messageDomain.createEffect({
  name: 'getMessageEntryByUUIDEffect',
  handler: getMessageEntryByUUID,
});
export const markAsDeletedByUserEffect = messageDomain.createEffect({
  name: 'markAsDeletedByUserEffect',
  handler: (payload) => { return updateSpecificMessagesParam(payload[0], payload[1], payload[2]); },
});

export const markAsViewedByUserEffect = messageDomain.createEffect({
  name: 'markAsViewedByUserEffect',
  handler: (payload) => { return updateMessagesUsersParams(payload[0], payload[1]); }
});

export const markAsReadedByUserEffect = messageDomain.createEffect({
  name: 'markAsReadedByUserEffect',
  handler: (payload) => { return updateMessagesUsersParams(payload[0], payload[1]); }
});

export const markAsRepliedByUserEffect = messageDomain.createEffect({
  name: 'markAsRepliedByUserEffect',
  handler: (payload) => { return updateMessagesUsersParams(payload[0], payload[1]); }
});

export const markAsDeleteAlldByUserEffect = messageDomain.createEffect({
  name: 'markAsDeleteAlldByUserEffect',
  handler: (payload) => { return updateMessagesUsersParams(payload[0], payload[1]); }
});

export const markAsDeleteAllSentByEffect = messageDomain.createEffect({
  name: 'markAsDeleteAllSentByEffect',
  handler: (payload) => { return updateMessagesParams(payload[0], payload[1]); }
});

export const markAsDeleteConversationByEffect = messageDomain.createEffect({
  name: 'markAsDeleteConversationByEffect',
  handler: (payload) => { return updateConversationParams(payload[0], payload[1], payload[2]); }
});

export const markAsViewedSpecificMessageEffect = messageDomain.createEffect({
  name: 'markAsViewedSpecificMessageEffect',
  handler: (payload) => { return updateSpecificMessagesParam(payload[0], payload[1], payload[2]); }
});

export const markAsReadSpecificMessageEffect = messageDomain.createEffect({
  name: 'markAsReadSpecificMessageEffect',
  handler: (payload) => { return updateSpecificMessagesParam(payload[0], payload[1], payload[2]); }
});
