// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userTable td:first-child {
  padding-left: 7px !important;
}
.userTable td {
  font-weight: 300;
}
.userTable tr.v-data-table__selected {
  background: #e4f4f6 !important;
}
.v-theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: #505050 !important;
}
.userTable .v-data-footer {
  margin-right: 0px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/userManagement/userManagementTable.vue","webpack://./userManagementTable.vue"],"names":[],"mappings":"AACA;EACE,4BAAA;ACAF;ADEA;EACE,gBAAA;ACCF;ADEE;EACE,8BAAA;ACCJ;ADEA;EACE,8BAAA;ACCF;ADCA;EACE,4BAAA;ACEF","sourcesContent":["\n.userTable td:first-child {\n  padding-left: 7px !important;\n}\n.userTable td {\n  font-weight: 300;\n}\n.userTable {\n  tr.v-data-table__selected {\n    background: #e4f4f6 !important;\n  }\n}\n.v-theme--dark.v-data-table tbody tr.v-data-table__selected {\n  background: #505050 !important;\n}\n.userTable .v-data-footer {\n  margin-right: 0px !important;\n}\n",".userTable td:first-child {\n  padding-left: 7px !important;\n}\n\n.userTable td {\n  font-weight: 300;\n}\n\n.userTable tr.v-data-table__selected {\n  background: #e4f4f6 !important;\n}\n\n.v-theme--dark.v-data-table tbody tr.v-data-table__selected {\n  background: #505050 !important;\n}\n\n.userTable .v-data-footer {\n  margin-right: 0px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
