// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.saveFunctionButton[data-v-406a6362] {
  position: absolute;
  right: 21px;
  top: 2px;
}
.functionFieldPadd[data-v-406a6362] {
  padding-right: 40px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/groups-conferences/addGuestModal.vue","webpack://./addGuestModal.vue"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;ACAF;ADEA;EACE,mBAAA;ACCF","sourcesContent":["\n.saveFunctionButton {\n  position: absolute;\n  right: 21px;\n  top: 2px;\n}\n.functionFieldPadd {\n  padding-right: 40px;\n}\n",".saveFunctionButton {\n  position: absolute;\n  right: 21px;\n  top: 2px;\n}\n\n.functionFieldPadd {\n  padding-right: 40px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
