import { combine } from 'effector';
import { setAgreementStateModalEvent } from '../../modals';
import { ownUserState } from '../../users';
import { namespaceSettingsStore } from '../../namespaceSettings';

// const onCallDisplayConsentCombine = combine(ownUserState, namespaceSettingsStore, (ownState, namespaceSettings) => {
//   const setting = !namespaceSettings || !namespaceSettings.displayCurrentVideocallForUser;
//   const consent = !ownState || !ownState.user || (ownState.user.consents && ownState.user.consents.onCallDisplay !== null && ownState.user.consents.onCallDisplay !== undefined);
//   // console.log('onCallDisplayConsentCombine inner', setting, consent);
//   if (setting || consent) return false;
//   return true;
// });

// onCallDisplayConsentCombine.watch((state) => {
//   // console.log('onCallDisplayConsentCombine', state);
//   setAgreementStateModalEvent(state);
// });
