import { historyMessagesDomain } from './domain';

import { setHistoryMessagesModalEvent, resetHistoryMessagesModalEvent } from './events';

export const historyMessagesModalStore = historyMessagesDomain.createStore(false, { name: 'historyMessages' });

historyMessagesModalStore.on(setHistoryMessagesModalEvent, (state, payload) => {
  return payload;
});

historyMessagesModalStore.reset(resetHistoryMessagesModalEvent);
