<template>
  <v-dialog :model-value="callEndedInfoModal" @update:model-value="callEndedInfoModal = $event" persistent max-width="500">
    <div>
      <v-card class="mx-auto">
        <HeaderModal :titleModal="$t('components.beforeEndingCallModal.callEndedInfoTitle')" />
        <v-card-text>
          <div class="mt-2 d-flex justify-center">
            <div class="mx-3 pointer">
              {{$t('components.beforeEndingCallModal.callEndedInfo')}}
            </div>
          </div>
          <div class="mt-2 d-flex justify-center">
            <div class="mx-3 pointer">
              <v-btn class="bg-primary" @click="closeModal">{{
                $t("components.userAgreementModal.ok")
              }}</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import { callEndedInfoModalStore, setCallEndedInfoModalEvent } from "../../effector/modals";
export default {
  components: { HeaderModal },
  props: [],
  data () {
    const effector = {
      callEndedInfoModal : callEndedInfoModalStore
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      // Effector
      ...effector,
    };
  },
  methods: {
    closeModal() {
      setCallEndedInfoModalEvent(false);
    }
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>