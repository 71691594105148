<template>
  <v-list :opened="opened" @update:opened="newOpened => opened = newOpened" max-width="320" @click.stop.prevent>
    <v-list-group
      active-class="listColor"
      @click="handlerClickWaitingRoom($event)"
      value="waitingroom"
      v-if="!mini"
      :expand-icon="'$expand'"
    >
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" @click="handlerClickWaitingRoom($event)">
          <template v-slot:prepend>
            <v-icon size="20"
              class="primary--text-sidepanel"
              :class="`${activeBlueClass ? ' primary--text-sidepanel' : ''}`"
            >
              <font-awesome-icon
                v-if="currentPage('/waitingroomfor/' + waitingRoomFor)"
                :icon="['fas', 'users-class']"
                class="primary--text-sidepanel iconsSize20"
              />
              <font-awesome-icon
                v-else
                :icon="['fal', 'users-class']"
                class="primary--text-sidepanel iconsSize20"
              />
            </v-icon>
          </template>
          <v-badge
            @click.native="handleWaitingRoom()"
            v-if="getconnecetedWaitingrooms > 0"
            right
            color="error"
            :content="getconnecetedWaitingrooms"
            :value="getconnecetedWaitingrooms"
            bordered
            overlap
            offset-x="25"
            offset-y="-5"
            class="waitingRoomBadge"
          ></v-badge>
          <v-list-item-title
            class="pr-4 primary--text-sidepanel"
            :class="`${
              activeBlueClass
                ? 'font-weight-bold'
                : ''
            }`"
            @click.stop.prevent="
              setCurrentContentVisile('', false, $router);
              handleWaitingRoom();
              handlerClickWaitingRoom($event);
            "
          >
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <span v-bind="props">
                  {{ getTitleWaitingROOM }}
                  {{
                    `${
                      getconnecetedWaitingrooms > 0
                        ? "(" + getconnecetedWaitingrooms + ")"
                        : ""
                    }`
                  }}
                </span>
              </template>
              <span>{{ waitingRoomTT }}</span>
            </v-tooltip>
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  icon
                  variant="text"
                  density="compact"
                  class="btnAddGroup"
                  @click.stop.prevent="gotoPage(`invitationform/${waitingRoomFor}`)"
                >
                  <font-awesome-icon
                    class="primary--text-sidepanel"
                    :icon="['fal', 'plus-circle']"
                    :style="{
                      fontSize: '20px',
                    }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.sidebar.guestTT") }}</span>
            </v-tooltip>
          </v-list-item-title>
          <CustomTT
            v-if="showCustomTT"
            :text="`${$t('components.hotkeys.controlKey')} + R`"
            position="bottom"
            :extraclass="'ctrr'"
          />
        </v-list-item>
      </template>
      <UserList
        v-if="sortedUsersWaitingRoom.length"
        :persons="sortedUsersWaitingRoom"
        :section="'guests'"
        typeData="waitingroom"
      />
    </v-list-group>
    <v-tooltip right v-if="!isGuest && mini">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" class="cursorPointer">
          <v-icon size="20" @click="handleWaitingRoom($event)">
            <font-awesome-icon
              v-if="currentPage('/waitingroomfor/' + waitingRoomFor)"
              :icon="['fas', 'users-class']"
              class="primary--text-sidepanel iconsSize20"
            />
            <font-awesome-icon
              v-if="!currentPage('/waitingroomfor/' + waitingRoomFor)"
              :icon="['fal', 'users-class']"
              class="primary--text-sidepanel iconsSize20"
            />
            <v-badge
              @click.native="handleWaitingRoom()"
              v-if="getconnecetedWaitingrooms > 0"
              right
              color="error"
              :content="getconnecetedWaitingrooms"
              :value="getconnecetedWaitingrooms"
              bordered
              overlap
              offset-x="-10"
              offset-y="-5"
              :class="!!getconnecetedWaitingrooms ? 'badgetWithData': ''"
              class="waitingRoomBadge"
            ></v-badge>
          </v-icon>
        </v-list-item>
      </template>
      <span>{{ waitingRoomTT }}</span>
    </v-tooltip>
  </v-list>
</template>
<script>
import { useStore } from "effector-vue/composition";
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import { hasPrivilege } from "../../utils/privileges";
import {userInCall} from "../../utils/calls"
import CustomTT from "../content/customTT.vue";
import UserList from "./userList.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";
import { waitingInfoStore, markAsCallWaitingRoomForUserEvent, waitingForInfoStore } from "../../effector/users/waitingInfo";

export default {
  components: { CustomTT, UserList },
  props: ["setList", "active", "waitingRoomFor", "mini"],
  data() {
    const effector = {
      showCustomTT: hotkeysInfoStore,
      waitingUsesEffect: waitingInfoStore,
      waitingForUsesEffect: waitingForInfoStore
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      moment,
      state: store.state,
      setCurrentContentVisile: store.setCurrentContentVisile,
      ownUUID: store.state.ownUUID,
      opened: [],
      // Effector
      ...effector,
    };
  },
  watch: {
    // logic to see if a visitor is on a call, we also mark it as a call since that means that it is on a call with the user that I assist
    assistantWaitingRoomList: {
      immediate: true,
      deep:true,
      handler: function (assiantantsList) {
        const calledVisitors = Object.entries(this.getWaitingForUsesEffect.called)
        .filter(([uuid, value]) => {
          return !!(value && this.assistantWaitingRoomList.find((e) => e.uuid === uuid))
        })
        .map(([uuid, value]) => uuid);
        assiantantsList.forEach(assistantsVisitor => {
          if(this.userInCall(assistantsVisitor.user.uuid)){
            if(!calledVisitors.find(uuid=>uuid !== assistantsVisitor.user.uuid)){
              markAsCallWaitingRoomForUserEvent(assistantsVisitor.user.uuid);
            }
          }
        });
      },
    },
    "isActive": {
      handler(value) {
        if(!value){
          this.opened = [];
        }
      },
    },
  },
  methods: {
    userInCall(uuid){
      return userInCall(uuid);
    },
    currentPage(page) {
      const actualRoute = this.$route.path;
      if (actualRoute === page) {
        return true;
      }
      return false;
    },
    handleWaitingRoom() {
      if (hasPrivilege(this.state.ownUUID)) {
        if (this.$route.path !== "/waitingroomfor") {
          if (this.state.ownUUID === this.waitingRoomFor) {
            store.setCurrentContentVisile("waitingroomfor", true, this.$router);
          } else {
            store.setCurrentContentVisile(
              `waitingroomfor/${this.waitingRoomFor}`,
              true,
              this.$router
            );
          }
        } else if (
          this.$route.path == "/waitingRoomFor" ||
          this.$route.path == "/waitingroomfor"
        ) {
          this.setCurrentContentVisile("home", true, this.$router);
          store.setShowHomeView(true);
        }
      }
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    handlerClickWaitingRoom(event) {
      if (this.mini) {
        event.preventDefault();
        event.stopPropagation();
        this.handleWaitingRoom();
      } else {
        this.setList("waitingroomfor");
      }
    },
    gotoPage(route) {
      this.setCurrentContentVisile(route, true, this.$router);
    },
  },
  computed: {
    getWaitingUsesEffect() {
      return this.waitingUsesEffect;
    },
    getWaitingForUsesEffect() {
      return this.waitingForUsesEffect;
    },
    getconnecetedWaitingrooms() {
      if (!this.assistantWaitingRoomList || !this.assistantWaitingRoomList.length) {
        return 0;
      }
      const calledVisitors = Object.entries(this.getWaitingForUsesEffect.called)
        .filter(([uuid, value]) => {
          return !!(value && this.assistantWaitingRoomList.find((e) => e.uuid === uuid))
        })
        .map(([uuid, value]) => uuid);
      const total = this.assistantWaitingRoomList.length - calledVisitors.length;
      return total;
    },
    activeBlueClass() {
      if (
        (this.getRoute.indexOf("/waitingroomfor") !== -1 &&
          this.$route.params.uuid === this.waitingRoomFor) ||
        (!hasPrivilege(this.state.ownUUID) && this.getRoute === "/home")
      ) {
        return true;
      } else {
        return false;
      }
    },
    getTitleWaitingROOM() {
      const waitingRoomName = this.$t(
        "components.drawerContentExpansion.guest"
      );
      if (this.waitingRoomFor === this.state.ownUUID) {
        return waitingRoomName;
      } else {
        return (
          waitingRoomName +
          " " +
          this.$t("generics.from") +
          " " +
          this.getTitelForUuid(this.waitingRoomFor) +
          " " +
          this.getNameForUuid(this.waitingRoomFor)
        );
      }
    },
    assistantWaitingRoomList() {
      return store.getWaitingRoomList(this.waitingRoomFor);
    },
    getRoute() {
      return this.$route.path;
    },
    waitingRoomAlias() {
      return store.state.namespaceSettings.waitingRoomAlias;
    },
    isActive() {
      return this.active == "waitingroomfor";
    },
    waitingRoomTT() {
      return this.waitingRoomAlias
        ? this.$t("components.sidebar.waitingRoomTTAlias", [
            this.waitingRoomAlias,
          ])
        // Changing: Tooltip for assisted waiting room #581
        // : this.$t("components.sidebar.waitingRoomMiniTT");
        // From sidebar call:
        : this.$t("components.sidebarCall.waitingRoomFor", [
          this.getTitelForUuid(this.waitingRoomFor),
          this.getNameForUuid(this.waitingRoomFor),
        ]);
        // From the old component:
        // : this.$t("components.waitingRoom.myWaitinRoomTT", [this.getTitelForUuid(this.waitingRoomFor) + " " + this.getNameForUuid(this.waitingRoomFor)]);
    },
    assistantsUser() {
      return (store.state.user.assists || []).length || 0;
    },
    sortedUsersWaitingRoom: function () {
      const inviterUUID = this.waitingRoomFor;
      // const uuid = this.waitingRoomFor || this.ownUUID;
      // const visitors = this.state.persisted.userVisitors || {};
      let visitors = Object.keys(store.state.group).filter(
        (uuid) =>
          store.state.group[uuid] &&
          store.state.group[uuid].user &&
          store.state.group[uuid].user.visitorData &&
          store.state.group[uuid].user.visitorData.userInviter == inviterUUID // &&
          // !store.state.group[uuid].connected
      )
      .map((uuid) => ({
        ...store.state.group[uuid],
        uuid,
        visitorId: uuid,
      }));
      visitors = visitors.sort((a, b) => {
        const aConnected = +a.connected;
        const bConnected = +b.connected;
        const orderConnected = bConnected - aConnected;
        if (orderConnected) return orderConnected;
        const aDate = (a.user.visitorData && a.user.visitorData.created) ? a.user.visitorData.created : a.user.created;
        const bDate = (b.user.visitorData && b.user.visitorData.created) ? b.user.visitorData.created : b.user.created;
        if (aDate < bDate) return 1;
        if (aDate > bDate) return -1;
        // If dates are equal, fallback to comparing UUIDs
        const aUUID = a.uuid || "";
        const bUUID = b.uuid || "";
        if (aUUID < bUUID) return -1;
        if (aUUID > bUUID) return 1;
        return 0; // They are equal
      });
      return visitors;
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
  },
};
</script>
<style scoped lang="scss">
.ctrr {
  top: 58px;
  left: 15px;
}
.waitingRoomBadge {
  font-size: 10px !important;
  height: 16px !important;
  min-width: 16px !important;
  padding: 2px 6px !important;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  text-indent: 0;
  top: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  border-radius: 50% !important;
  font-style: normal;
}
.btnAddGroup {
  position: absolute !important;
  right: 57px;
  top: 5px;
}
.cursorPointer {
  cursor: pointer;
}
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.v-theme--dark .listColor {
  color: white !important;
}
.v-theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-list-group__header__append-icon .v-icon {
  margin-left: 36px !important;
}
.iconsSize20{
  width: 20px !important;
  height: 20px !important;
}
</style>
