// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lockMessageIcon {
  position: absolute;
  right: -6px;
  top: -10px;
}
.pointer {
  cursor: pointer;
}
.v-theme--dark.v-data-table tbody tr td {
  color: white !important;
}
.v-theme--light.v-data-table thead tr th {
  color: black !important;
}
.v-theme--dark.v-data-table thead tr th {
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/stats/conferences/conferenceTable.vue","webpack://./conferenceTable.vue"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,WAAA;EACA,UAAA;ACAF;ADEA;EACE,eAAA;ACCF;ADCA;EACE,uBAAA;ACEF;ADAA;EACE,uBAAA;ACGF;ADDA;EACE,uBAAA;ACIF","sourcesContent":["\n.lockMessageIcon {\n  position: absolute;\n  right: -6px;\n  top: -10px;\n}\n.pointer {\n  cursor: pointer;\n}\n.v-theme--dark.v-data-table tbody tr td {\n  color: white !important;\n}\n.v-theme--light.v-data-table thead tr th {\n  color: black !important;\n}\n.v-theme--dark.v-data-table thead tr th {\n  color: white !important;\n}\n",".lockMessageIcon {\n  position: absolute;\n  right: -6px;\n  top: -10px;\n}\n\n.pointer {\n  cursor: pointer;\n}\n\n.v-theme--dark.v-data-table tbody tr td {\n  color: white !important;\n}\n\n.v-theme--light.v-data-table thead tr th {\n  color: black !important;\n}\n\n.v-theme--dark.v-data-table thead tr th {\n  color: white !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
