<template>
  <div>
    <!-- maxwidth307 -->
    <!-- <v-expansion-panels
      flat
      class="extensionPanelsORga"
      v-if="showOrganigram && !isMobile && !mini"
      :value="state.searchTerm ? '0' : '' || isActive"
    >
      <v-expansion-panel @click="setList('organisation')">
        <v-expansion-panel-header style="max-height: 48px; min-height: unset"> -->
          <v-list class="d-flex  pointer relative" @click="setList('organisation')"  v-if="showOrganigram && !isMobile && !mini">
            <v-list-item class="w100">
              <template v-slot:prepend>
                <v-icon size="20">
                  <font-awesome-icon
                    v-if="currentPage('/organisation')"
                    class="primary--text-sidepanel iconsSize20"
                    :icon="['fas', 'sitemap']"
                  />
                  <font-awesome-icon
                    v-else
                    class="primary--text-sidepanel iconsSize20"
                    :icon="['fal', 'sitemap']"
                  />
                </v-icon>
              </template>
              <v-list-item-title
                class="primary--text-sidepanel"
                  :class="`${
                    getRoute === '/organisation'
                      ? 'font-weight-bold primary--text-sidepanel'
                      : ''
                  }`"
                  @click.stop.prevent="handleOrganisation()"
                >
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      density="compact"
                      v-if="!isMobile && amIAdmin && !isBigOrganisation"
                      icon
                      variant="text"
                      class="btnAddGroup"
                      v-bind:disabled="false"
                      @click.stop.prevent="showEditableView()"
                    >
                      <font-awesome-icon
                        class="primary--text-sidepanel"
                        :icon="['fal', 'plus-circle']"
                        :style="{ fontSize: '20px' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ state.user.organisationEditable ? $t('components.sidebar.cancelEditOrga') : $t('components.sidebar.organigramPlusTT') }}</span>
                </v-tooltip>
                <span>{{ $t("generics.organisation") }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        <!-- </v-expansion-panel-header>
        <v-expansion-panel-content> -->
          <!-- sections -->
          <!-- <Sections
            :setList="setList"
            :active="active"
            v-if="
              !isGuest &&
              (state.namespaceSettings.processedOrganisation || {}).sections &&
              Object.keys(
                state.namespaceSettings.processedOrganisation.sections
              ).length > 0
            "
            :data="separatedPeople.sections"
            :setActiveOrganisationSection="setActiveOrganisationSection"
            :searchFoundSomethingSection="searchFoundSomethingSection"
          />
          <!-- Departments -->
          <!-- <Departments
            :setList="setList"
            :active="active"
            v-if="
              !isGuest &&
              (state.namespaceSettings.processedOrganisation || {})
                .departments &&
              Object.keys(
                state.namespaceSettings.processedOrganisation.departments
              ).length > 0
            "
            :data="separatedPeople.departments"
            :setActiveOrganisationSection="setActiveOrganisationSection"
            :searchFoundSomethingDepartment="searchFoundSomethingDepartment"
          /> -->
          <!-- Teams -->
          <!-- <Teams
            :setList="setList"
            :active="active"
            v-if="
              !isGuest &&
              (state.namespaceSettings.processedOrganisation || {}).teams &&
              Object.keys(state.namespaceSettings.processedOrganisation.teams)
                .length > 0
            "
            :data="separatedPeople.teams"
            :setActiveOrganisationSection="setActiveOrganisationSection"
            :totalTeams="getTotalTeams"
            :searchFoundSomethingTeam="searchFoundSomethingTeam"
            :isMobile="isMobile"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> --> 
    <v-tooltip right v-if="showOrganigram && !isMobile && mini">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" class="cursorPointer">
          <v-icon size="20" @click.stop.prevent="handlerClickOrgaMini">
            <font-awesome-icon
              :icon="['fas', 'sitemap']"
              class="primary--text-sidepanel iconsSize20"
              v-if="currentPage('/organisation')"
            />
            <font-awesome-icon
              :icon="['fal', 'sitemap']"
              class="primary--text-sidepanel iconsSize20"
              v-else
            />
          </v-icon>
        </v-list-item>
      </template>
      <span>{{ $t("generics.organisation") }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import {
  webLicensedBaseFeatures
} from '../../../../sharedsrc/licensedFeatures';
import store, { EventBus } from "../../../store";
import Sections from "../sections.vue";
import Departments from "../departments.vue";
import Teams from "../teams.vue";
import { canIShowOrganigram } from "../../../utils/basicFunctions";

const hasOwn = Object.prototype.hasOwnProperty;

export default {
  props: [
    "closeOnClick",
    "closeOnContent",
    "sectionSelected",
    "handleOrganisation",
    "setActiveOrganisationSection",
    "setList",
    "isMobile",
    "getTotalTeams",
    "sendToEntities",
    "sendToEntitiesSelected",
    "active",
    "openSidebar",
    "mini",
  ],
  components: {
    Sections,
    Departments,
    Teams,
  },
  data: () => ({
    state: store.state,
  }),
  watch: {
    $route(to, from) {
      if (to.path !== "/messages") {
        store.setSubordinateUser("");
      }
      if (
        to.path !== "/teams" ||
        to.path !== "/sections" ||
        to.path !== "/departments"
      ) {
        store.state.orgaSearchTerm.searching = "";
      }
      store.state.user.activeGroupName = "";
    },
  },
  mounted() {
    EventBus.$on("openOrganigramEvent", this.handleOrganisation);
  },
  unmounted(){
    EventBus.$off("openOrganigramEvent", this.handleOrganisation);
  },
  methods: {
    showEditableView(){
      if ( this.state.user.organisationEditable ){
        store.setOrganisationEditableView(false);
      }else{
        store.setOrganisationEditableView(true);      
      }
      this.setCurrentContentVisile("/organisation", true, this.$router);
    },
    currentPage(page) {
      const actualRoute = this.$route.path;
      if (actualRoute === page) {
        return true;
      }
      return false;
    },
    handlerClickOrgaMini() {
      // this.$router.push({ path: "/organisation" });
      this.setCurrentContentVisile("/organisation", true, this.$router);
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
    filterOrga: function (person) {
      return (
        ((person || "").trim().toLowerCase() || "").indexOf(
          this.state.orgaSearchTerm.searching.trim().toLowerCase()
        ) !== -1
      );
    },
  },
  computed: {
    isBigOrganisation(){
      return (
        webLicensedBaseFeatures.isPkg &&
        Object.keys(store.state.group).length >= 1000
      );
    },
    showOrganigram() {
      return canIShowOrganigram();
    },
    isActive() {
      return (
        this.active == "sections" ||
        this.active == "teams" ||
        this.active == "departments" ||
        this.active == "organisation"
      );
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
    getRoute() {
      return this.$route.path;
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    searchFoundSomethingTeam() {
      return (
        ((store.state.orgaSearchTerm.searching || "").length || 0 >= 3) &&
        Object.keys(this.separatedPeople.teams || []).length > 0 &&
        this.state.orgaSearchTerm.type === "teams"
      );
    },
    searchFoundSomethingDepartment() {
      return (
        ((store.state.orgaSearchTerm.searching || "").length || 0 >= 3) &&
        Object.keys(this.separatedPeople.departments || []).length > 0 &&
        this.state.orgaSearchTerm.type === "departments"
      );
    },
    searchFoundSomethingSection() {
      return (
        ((store.state.orgaSearchTerm.searching || "").length || 0 >= 3) &&
        Object.keys(this.separatedPeople.sections || []).length > 0 &&
        this.state.orgaSearchTerm.type === "sections"
      );
    },
    separatedPeople: function () {
      const isFiltered = store.state.isAreaSearch;
      const result = { sections: {}, departments: {}, teams: {} };
      if (!store.state.namespaceSettings.processedOrganisation) {
        return result;
      }
      let sections =
        store.state.namespaceSettings.processedOrganisation.sections || {};
      let departments =
        store.state.namespaceSettings.processedOrganisation.departments || {};
      let teams =
        store.state.namespaceSettings.processedOrganisation.teams || {};

      if ((this.state.orgaSearchTerm.searching || "").length >= 3) {
        if (this.state.orgaSearchTerm.type === "sections") {
          sections = {};
          const sectionSearch = Object.keys(
            this.state.namespaceSettings.processedOrganisation.sections
          ).filter(this.filterOrga);
          for (let i = 0; i < sectionSearch.length; i++) {
            const section = sectionSearch[i];
            sections[
              section
            ] = this.state.namespaceSettings.processedOrganisation.sections[
              section
            ];
          }
        } else if (this.state.orgaSearchTerm.type === "departments") {
          departments = {};
          const departmentSearch = Object.keys(
            this.state.namespaceSettings.processedOrganisation.departments
          ).filter(this.filterOrga);
          for (let i = 0; i < departmentSearch.length; i++) {
            const department = departmentSearch[i];
            departments[
              department
            ] = this.state.namespaceSettings.processedOrganisation.departments[
              department
            ];
          }
        } else if (this.state.orgaSearchTerm.type === "teams") {
          teams = {};
          const teamSearch = Object.keys(
            this.state.namespaceSettings.processedOrganisation.teams
          ).filter(this.filterOrga);
          for (let i = 0; i < teamSearch.length; i++) {
            const team = teamSearch[i];
            teams[
              team
            ] = this.state.namespaceSettings.processedOrganisation.teams[team];
          }
        }
      }
      // get section members
      for (const key in sections) {
        let temporalMembersArray = [];
        if (hasOwn.call(sections, key)) {
          const section = sections[key];
          // loop to search members in section teams
          if (section.teams) {
            for (let i = 0; i < section.teams.length; i++) {
              const team = section.teams[i];
              temporalMembersArray = temporalMembersArray.concat(
                this.state.namespaceSettings.processedOrganisation.teams[team]
                  .members
              );
            }
            result.sections[key] = temporalMembersArray;
            // remove duplicated items
            result.sections[key] = [...new Set(result.sections[key])];
          }
        }
      }
      // get departments members
      for (const key in departments) {
        let temporalMembersArray = [];
        if (hasOwn.call(departments, key)) {
          const department = departments[key];
          // loop to search members in departments teams
          for (let i = 0; i < department.teams.length; i++) {
            const team = department.teams[i];
            temporalMembersArray = temporalMembersArray.concat(
              this.state.namespaceSettings.processedOrganisation.teams[team]
                .members
            );
          }
          result.departments[key] = temporalMembersArray;
          // remove duplicated items
          result.departments[key] = [...new Set(result.departments[key])];
        }
      }
      // get team members
      for (const key in teams) {
        if (hasOwn.call(teams, key)) {
          const team = teams[key];
          result.teams[key] = team.members;
          // remove duplicated items
          result.teams[key] = [...new Set(result.teams[key])];
        }
      }
      function sortObject(o) {
        return Object.keys(o)
          .sort((a, b) => {
            const str1 = a.toLowerCase();
            const str2 = b.toLowerCase();
            return (str1 > str2) - (str1 < str2);
          })
          .reduce((r, k) => ((r[k] = o[k]), r), {});
      }
      function sortAndFilterObject(o, filterFunction) {
        return Object.keys(o)
          .filter((v) => filterFunction(v))
          .sort((a, b) => {
            const str1 = a.toLowerCase();
            const str2 = b.toLowerCase();
            return (str1 > str2) - (str1 < str2);
          })
          .reduce((r, k) => ((r[k] = o[k]), r), {});
      }
      result.sections = sortObject(result.sections);
      result.departments = sortObject(result.departments);
      result.teams = isFiltered
        ? sortAndFilterObject(
            result.teams,
            (v) =>
              v
                .trim()
                .toLowerCase()
                .indexOf(
                  store.state.orgaSearchTerm.searching.trim().toLowerCase()
                ) !== -1
          )
        : sortObject(result.teams);
      return result;
    },
  },
};
</script>

<style scoped lang="scss">
.w100{
  width: 100%;
}
.cursorPointer {
  cursor: pointer;
}
.extensionPanelsORga {
  // height: calc(100% - 100px);
  max-height: calc(100% - 100px);
  overflow: auto;
}
.maxwidth307 {
  max-width: 307px;
}
.titleGroup {
  .v-icon {
    font-size: 14px !important;
  }
}
.select-box {
  background-color: var(--v-primary-base) !important;
}
.mencheckbox {
  padding: 0px !important;
  margin: 0px;
  margin-right: 24px;
}

.v-list {
  padding: 0;
}

.v-list-item__icon {
  margin-right: 8px !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-list-item__title {
  line-height: 2.5 !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-expansion-panel-header {
  padding: 0px 25px 0px 16px !important;
}
.v-expansion-panel-header__icon {
  min-width: 48px !important;
  margin-right: 8px !important;
}
.relative {
  position: relative;
}
.iconsSize20{
  width: 20px !important;
  height: 20px !important;
}
</style>