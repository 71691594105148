import { joinConferenceDomain } from './domain';

import { setJoinConferenceEvent, resetJoinConferenceEvent, deleteUserJoinConferenceEvent  } from './events';

export const joinConferenceStore = joinConferenceDomain.createStore(false, { name: 'joinConference' });

joinConferenceStore.on(setJoinConferenceEvent, (state, payload) => {
  let users = []
  if(state){
    users = state
  }
  if(users.indexOf(payload)==-1){
    users.push(payload );
  }
  return users
});

joinConferenceStore.on(deleteUserJoinConferenceEvent, (state, payload) => {
  let users = []
  if(state){
    users = state
  }
  if(users.indexOf(payload) != -1){
    users.splice(users.indexOf(payload), 1);
  }
  return users
});

joinConferenceStore.reset(resetJoinConferenceEvent);
