<template>
  <!-- TEMPLATE dominantSpeaker-->
  <div :id="isLocal ? 'localIconDominant' : `remoteIconDominant-${participantId}`" class="dominantSpeakerIcon" v-show="isDominantSpeaker">
    <div class="point left"></div>
    <div class="point middle"></div>
    <div class="point right"></div>
  </div>
  <!-- END TEMPLATE dominantSpeaker -->
</template>
<script>


export default {
  components: {},
  props: [
    "isDominantSpeaker", "isLocal", "participantId"
  ],
  data() {
    return {};
  },

  methods: {},
  computed: {

  },
};
</script>
<style scoped lang="scss">

.dominantSpeakerIcon {
  position: absolute;
  display: flex;
  height: 30px;
  width: 28px;
  background: var(--v-primary-base);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  align-content: center;
  top: 35px;
  right: 9px;
  z-index: 1;
  .point{
    background: white;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    margin: 0px 1.7px;
    transition: height .1s linear;
  }
}

</style>