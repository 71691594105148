import { presenceDomain } from './domain';
import { ownUserUUIDState } from '../users';

import {
  fillPresenceWatchingEvent,
  fillWatchingListEvent,
  fillWatchedListEvent,
} from './events';

// const ownUUID = ownUserUUIDState.getState();
// Selected UUID to report (if any)
export const presenceWatchingStore = presenceDomain.createStore([], { name: 'PresenceWatchingStore' });
export const watchingList = presenceDomain.createStore([], { name: 'watchingList' });
export const watchList = presenceDomain.createStore([], { name: 'watchListStore' });

presenceWatchingStore.on(fillPresenceWatchingEvent, (state, payload) => {
  const ownUUID = ownUserUUIDState.getState();
  if(payload && payload.watching){
    const wData = payload.watching.filter(e => e.owner === ownUUID);
    // watching
    if (wData && wData[0] && wData[0].watching){
      fillWatchingListEvent(wData[0].watching)
    } else {
      fillWatchingListEvent([])
    }
    // watch
    if (wData && wData[0] && wData[0].watched){
      fillWatchedListEvent(wData[0].watched)
    } else {
      fillWatchedListEvent([])
    }
  }
  return payload; 
});

watchingList.on(fillWatchingListEvent, (state, payload) => {
  return payload;  
  });

watchList.on(fillWatchedListEvent, (state, payload) => {
  return payload;  
  });
