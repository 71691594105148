<template>
  <v-card class="mx-auto" variant="outlined">
    <v-list-item three-line>
      <v-list-item-content>
        <!-- <div class="headline mb-2">SUPPORT</div> -->
        <v-list-item-title class="headline">vOffice support</v-list-item-title>
        <v-list-item-subtitle class="d-flex align-center">
          <a ref="supportMail" style="text-decoration:unset" href="mailto: support-voffice@ra-micro.de">
            <font-awesome-icon
              :icon="['fal', 'envelope-square']"
              :style="{ fontSize: '20px' }"
            />
          </a>
            <span @click="goToMail()" class="ml-2" style="cursor:pointer">support-voffice@ra-micro.de</span>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-avatar tile size="80" color="#9e9e9e21">
        <font-awesome-icon
          :icon="['fal', 'user-headset']"
          color="#00889C"
          :style="{ fontSize: '40px' }"
        />
      </v-avatar>
    </v-list-item>
    <!-- // expansion panels -->
    <template>
      <v-row class="mx-4 scrollfaqs">
        <div class="title"> {{ $t("components.faqs.basicInformation.basicInformation") }}</div>
        <v-expansion-panels focusable class="my-3">
          <v-expansion-panel v-for="(item, i) in 5" :key="i">
            <v-expansion-panel-header>
              <v-list-item-title class="subtitle-2" v-if="i == 0">
                {{ $t("components.faqs.basicInformation.whatIsvOffice") }}
              </v-list-item-title>
              <v-list-item-title class="subtitle-2" v-if="i == 1">
                {{ $t("components.faqs.basicInformation.whatOperatinSystem") }}
              </v-list-item-title>
              <v-list-item-title class="subtitle-2" v-if="i == 2">
                {{
                  $t("components.faqs.basicInformation.isvOfficeAvailableApp")
                }}
              </v-list-item-title>
              <v-list-item-title class="subtitle-2" v-if="i == 3">
                {{ $t("components.faqs.basicInformation.whatBrowser") }}
              </v-list-item-title>
              <v-list-item-title class="subtitle-2" v-if="i == 4">
                {{ $t("components.faqs.basicInformation.howDoIStart") }}
              </v-list-item-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list-item-content class="preLine" v-if="i == 0">
                {{ $t("components.faqs.basicInformation.whatIsvOfficeText")}}
              </v-list-item-content>
              <v-list-item-content class="preLine" v-if="i == 1">
                {{
                  $tm("components.faqs.basicInformation.whatOperatinSystemText").join("\n")
                }}
              </v-list-item-content>
              <v-list-item-content class="" v-if="i == 2">
                {{
                  $t(
                    "components.faqs.basicInformation.isvOfficeAvailableAppText",
                  )
                }}
                <br />
                 {{
                  $t(
                    "components.faqs.basicInformation.isvOfficeAvailableAppText1",
                  )
                }} <a target="_blank" href="https://apps.apple.com/de/app/voffice-app/id1523298495">https://apps.apple.com/de/app/voffice-app/id1523298495</a>
                <br />
                 {{
                  $t(
                    "components.faqs.basicInformation.isvOfficeAvailableAppText2",
                  )
                }} <a target="_blank" href="https://play.google.com/store/apps/details?id=com.voffice.vofficepro&gl=DE"> https://play.google.com/store/apps/details?id=com.voffice.vofficepro&gl=DE</a>
              </v-list-item-content>
              <v-list-item-content class="preLine" v-if="i == 3">
                {{ $tm("components.faqs.basicInformation.whatBrowserText").join("\n") }}
              </v-list-item-content>
              <v-list-item-content class="preLine" v-if="i == 4">
                {{ $tm("components.faqs.basicInformation.howDoIStartText").join("\n") }}
              </v-list-item-content>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="title"> {{ $t("components.faqs.utilization.utilization")}}</div>
        <v-expansion-panels focusable class="my-3">
          <v-expansion-panel v-for="(item, i) in 18" :key="i">
            <v-expansion-panel-header>
               <v-list-item-title class="subtitle-2" v-if="i == 0">
                {{ $t("components.faqs.utilization.howDoInvite") }}
              </v-list-item-title>
              <v-list-item-title class="subtitle-2" v-if="i == 1">
                {{ $t("components.faqs.utilization.howDoInviteVisitor") }}
              </v-list-item-title>
              <v-list-item-title class="subtitle-2" v-if="i == 2">
                {{
                  $t("components.faqs.utilization.howDoCreateConference")
                }}
              </v-list-item-title>
              <v-list-item-title class="subtitle-2" v-if="i == 3">
                {{ $t("components.faqs.utilization.howDoOpenSidepanel") }}
              </v-list-item-title>
              <v-list-item-title class="subtitle-2" v-if="i == 4">
                {{ $t("components.faqs.utilization.whatIsMaximunParticipants") }}
              </v-list-item-title>
              <v-list-item-title class="subtitle-2" v-if="i == 5">
                {{ $t("components.faqs.utilization.howDoCommunicate") }}
              </v-list-item-title>
                <v-list-item-title class="subtitle-2" v-if="i == 6">
                {{ $t("components.faqs.utilization.canIAddMoreParticipants") }}
              </v-list-item-title>
                <v-list-item-title class="subtitle-2" v-if="i == 7">
                {{ $t("components.faqs.utilization.howDoIUseWaiting") }}
              </v-list-item-title>
                <v-list-item-title class="subtitle-2" v-if="i == 8">
                {{ $t("components.faqs.utilization.howDoIForward") }}
              </v-list-item-title>
                <v-list-item-title class="subtitle-2" v-if="i == 9">
                {{ $t("components.faqs.utilization.canIMute") }}
              </v-list-item-title>
                <v-list-item-title class="subtitle-2" v-if="i == 10">
                {{ $t("components.faqs.utilization.canITurnOffCamera") }}
              </v-list-item-title>
                <v-list-item-title class="subtitle-2" v-if="i == 11">
                {{ $t("components.faqs.utilization.howToShare") }}
              </v-list-item-title>
                <v-list-item-title class="subtitle-2" v-if="i == 12">
                {{ $t("components.faqs.utilization.howCanExtendVideo") }}
              </v-list-item-title>
                <v-list-item-title class="subtitle-2" v-if="i == 13">
                {{ $t("components.faqs.utilization.canVideoConferenceRecorded") }}
              </v-list-item-title>
                <v-list-item-title class="subtitle-2" v-if="i == 14">
                {{ $t("components.faqs.utilization.howCanAdjustUserData") }}
              </v-list-item-title>
                <v-list-item-title class="subtitle-2" v-if="i == 15">
                {{ $t("components.faqs.utilization.howCanIChangeUserRights") }}
              </v-list-item-title>
                <v-list-item-title class="subtitle-2" v-if="i == 16">
                {{ $t("components.faqs.utilization.howCanIChangePicture") }}
              </v-list-item-title>
                <v-list-item-title class="subtitle-2" v-if="i == 17">
                {{ $t("components.faqs.utilization.howDoIUseTwo") }}
              </v-list-item-title>
             
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list-item-content class="preLine" v-if="i == 0">
                {{ $t("components.faqs.utilization.howDoInviteText")}}
              </v-list-item-content>
                <v-list-item-content class="preLine" v-if="i == 1">
                {{ $tm("components.faqs.utilization.howDoInviteVisitorText").join("\n").trim() }}
              </v-list-item-content>
                <v-list-item-content class="preLine" v-if="i == 2">
                {{ $tm("components.faqs.utilization.howDoCreateConferenceText").join("\n").trim() }}
              </v-list-item-content>
                <v-list-item-content class="preLine" v-if="i == 3">
                {{ $tm("components.faqs.utilization.howDoOpenSidepanelText").join("\n")}}
              </v-list-item-content>
                <v-list-item-content class="preLine" v-if="i == 4">
                {{ $t("components.faqs.utilization.whatIsMaximunParticipantsText")}}
              </v-list-item-content>
                <v-list-item-content class="preLine" v-if="i == 5">
                {{ $tm("components.faqs.utilization.howDoCommunicateText").join("\n")}}
              </v-list-item-content>
                     <v-list-item-content class="preLine" v-if="i == 6">
                {{ $t("components.faqs.utilization.canIAddMoreParticipantsText")}}
              </v-list-item-content>
                     <v-list-item-content class="preLine" v-if="i == 7">
                {{ $tm("components.faqs.utilization.howDoIUseWaitingText").join("\n").trim() }}
              </v-list-item-content>
                     <v-list-item-content class="preLine" v-if="i == 8">
                {{ $tm("components.faqs.utilization.howDoIForwardText").join("\n")}}
              </v-list-item-content>
                     <v-list-item-content class="preLine" v-if="i == 9">
                {{ $t("components.faqs.utilization.canIMuteText")}}
              </v-list-item-content>
                     <v-list-item-content class="preLine" v-if="i == 10">
                {{ $t("components.faqs.utilization.canITurnOffCameraText")}}
              </v-list-item-content>
                     <v-list-item-content class="preLine" v-if="i == 11">
                {{ $tm("components.faqs.utilization.howToShareText").join("\n")}}
              </v-list-item-content>
                     <v-list-item-content class="preLine" v-if="i == 12">
                {{ $tm("components.faqs.utilization.howCanExtendVideoText").join("\n")}}
              </v-list-item-content>
                     <v-list-item-content class="preLine" v-if="i == 13">
                {{ $t("components.faqs.utilization.canVideoConferenceRecordedText")}}
              </v-list-item-content>
                     <v-list-item-content class="preLine" v-if="i == 14">
                {{ $tm("components.faqs.utilization.howCanAdjustUserDataText").join("\n")}}
              </v-list-item-content>
                     <v-list-item-content class="preLine" v-if="i == 15">
                {{ $tm("components.faqs.utilization.howCanIChangeUserRightsText").join("\n")}}
              </v-list-item-content>
                     <v-list-item-content class="preLine" v-if="i ==16">
                {{ $tm("components.faqs.utilization.howCanIChangePictureText").join("\n").trim() }}
              </v-list-item-content>
                     <v-list-item-content class="preLine" v-if="i == 17">
                {{ $tm("components.faqs.utilization.howDoIUseTwoText").join("\n").trim() }}
              </v-list-item-content>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="title"> {{ $t("components.faqs.technicalQuestion.technicalQuestion") }}</div>
        <v-expansion-panels focusable class="my-3">
          <v-expansion-panel v-for="(item, i) in 13" :key="i">
            <v-expansion-panel-header>
              <v-list-item-title class="subtitle-2" v-if="i == 0">
                {{ $t("components.faqs.technicalQuestion.iAmNotAsked") }}
              </v-list-item-title>
              <v-list-item-title class="subtitle-2" v-if="i == 1">
                {{ $t("components.faqs.technicalQuestion.howCanITestCamera") }}
              </v-list-item-title>
              <v-list-item-title class="subtitle-2" v-if="i == 2">
                {{
                  $t("components.faqs.technicalQuestion.camerNotWork")
                }}
              </v-list-item-title>
              <v-list-item-title class="subtitle-2" v-if="i == 3">
                {{ $t("components.faqs.technicalQuestion.connectionQuality") }}
              </v-list-item-title>
              <v-list-item-title class="subtitle-2" v-if="i == 4">
                {{ $t("components.faqs.technicalQuestion.vOfficeTerminal") }}
              </v-list-item-title>
                 <v-list-item-title class="subtitle-2" v-if="i == 5">
                {{ $t("components.faqs.technicalQuestion.theWindowIWant") }}
              </v-list-item-title>
                 <v-list-item-title class="subtitle-2" v-if="i == 6">
                {{ $t("components.faqs.technicalQuestion.whyDoISee") }}
              </v-list-item-title>
                 <v-list-item-title class="subtitle-2" v-if="i == 7">
                {{ $t("components.faqs.technicalQuestion.vOfficeDontFind") }}
              </v-list-item-title>
                 <v-list-item-title class="subtitle-2" v-if="i == 8">
                {{ $t("components.faqs.technicalQuestion.vOfficeStartUp") }}
              </v-list-item-title>
                 <v-list-item-title class="subtitle-2" v-if="i == 9">
                {{ $t("components.faqs.technicalQuestion.theEmail") }}
              </v-list-item-title>
                 <v-list-item-title class="subtitle-2" v-if="i == 10">
                {{ $t("components.faqs.technicalQuestion.whatIsSignaling") }}
              </v-list-item-title>
                 <v-list-item-title class="subtitle-2" v-if="i == 11">
                {{ $t("components.faqs.technicalQuestion.whatIsStun") }}
              </v-list-item-title>
                 <v-list-item-title class="subtitle-2" v-if="i == 12">
                {{ $t("components.faqs.technicalQuestion.whatIsTurn") }}
              </v-list-item-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list-item-content class="preLine" v-if="i == 0">
                  {{ $t("components.faqs.technicalQuestion.iAmNotAskedText") }}
              </v-list-item-content>
                   <v-list-item-content class="preLine" v-if="i == 1">
                  {{ $t("components.faqs.technicalQuestion.howCanITestCameraText") }}
              </v-list-item-content>
                   <v-list-item-content class="preLine" v-if="i == 2">
                  {{ $tm("components.faqs.technicalQuestion.camerNotWorkText").join("\n") }}
              </v-list-item-content>
                   <v-list-item-content class="preLine" v-if="i == 3">
                  {{ $tm("components.faqs.technicalQuestion.connectionQualityText").join("\n") }}
              </v-list-item-content>
                   <v-list-item-content class="preLine" v-if="i == 4">
                  {{ $tm("components.faqs.technicalQuestion.vOfficeTerminalText").join("\n") }}
              </v-list-item-content>
                   <v-list-item-content class="preLine" v-if="i == 5">
                  {{ $t("components.faqs.technicalQuestion.theWindowIWantText") }}
              </v-list-item-content>
                   <v-list-item-content class="preLine" v-if="i == 6">
                  {{ $tm("components.faqs.technicalQuestion.whyDoISeeText").join("\n") }}
              </v-list-item-content>
                   <v-list-item-content class="preLine" v-if="i == 7">
                  {{ $tm("components.faqs.technicalQuestion.vOfficeDontFindText").join("\n").trim() }}
              </v-list-item-content>
                   <v-list-item-content class="preLine" v-if="i == 8">
                  {{ $tm("components.faqs.technicalQuestion.vOfficeStartUpText").join("\n") }}
              </v-list-item-content>
                   <v-list-item-content class="preLine" v-if="i == 9">
                  {{ $tm("components.faqs.technicalQuestion.theEmailText").join("\n") }}
              </v-list-item-content>
                   <v-list-item-content class="preLine" v-if="i == 10">
                  {{ $t("components.faqs.technicalQuestion.whatIsSignalingText") }}
              </v-list-item-content>
                   <v-list-item-content class="preLine" v-if="i == 11">
                  {{ $tm("components.faqs.technicalQuestion.whatIsStunText").join("\n") }}
              </v-list-item-content>
                   <v-list-item-content class="preLine" v-if="i == 12">
                  {{ $tm("components.faqs.technicalQuestion.whatIsTurnText").join("\n") }}
              </v-list-item-content>
              
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
                <div class="title"> {{ $t("components.faqs.dataProtection.dataProtection") }}</div>
               <v-expansion-panels focusable class="my-3">
          <v-expansion-panel v-for="(item, i) in 5" :key="i">
            <v-expansion-panel-header>
              <v-list-item-title class="subtitle-2" v-if="i == 0">
                {{ $t("components.faqs.dataProtection.whereCanIFindPrivacy") }}
              </v-list-item-title>
              <v-list-item-title class="subtitle-2" v-if="i == 1">
                {{ $t("components.faqs.dataProtection.whereIsData") }}
              </v-list-item-title>
              <v-list-item-title class="subtitle-2" v-if="i == 2">
                {{
                  $t("components.faqs.dataProtection.whatData")
                }}
              </v-list-item-title>
              <v-list-item-title class="subtitle-2" v-if="i == 3">
                {{ $t("components.faqs.dataProtection.whyIsSafe") }}
              </v-list-item-title>
              <v-list-item-title class="subtitle-2" v-if="i == 4">
                {{ $t("components.faqs.dataProtection.canThirdParties") }}
              </v-list-item-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list-item-content class="preLine" v-if="i == 0">
                {{$tm("components.faqs.dataProtection.whereCanIFindPrivacyText").join("\n")}}
              </v-list-item-content>
              <v-list-item-content class="preLine" v-if="i == 1">
                {{
                 $tm("components.faqs.dataProtection.whereIsDataText").join("\n")
                }}
              </v-list-item-content>
              <v-list-item-content class="preLine" v-if="i == 2">
               {{
                 $tm("components.faqs.dataProtection.whatDataText").join("\n")
                }}
              </v-list-item-content>
              <v-list-item-content class="preLine" v-if="i == 3">
              {{
                 $tm("components.faqs.dataProtection.whyIsSafeText").join("\n")
                }}
              </v-list-item-content>
              <v-list-item-content class="preLine" v-if="i == 4">
              {{
                 $t("components.faqs.dataProtection.canThirdPartiesText")
                }}
              </v-list-item-content>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </template>
  </v-card>
</template>
<script>
import store from "../../../store";

export default {
  components: {},

  data() {
    return {
      state: store.state,
    };
  },
  watch: {},
  created: function () {},
  methods: {
    goToMail(){
      this.$refs.supportMail.click();
    }
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.preLine {
  white-space: pre-line;
  padding: 0;
}
.scrollfaqs{
  height: 70vh;
  overflow: auto;
}
</style>

