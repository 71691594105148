// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customWidh[data-v-e28d1512] {
  width: 89%;
}
.contentWrapper[data-v-e28d1512] {
  max-width: 180px;
}
.noBRadius[data-v-e28d1512] {
  border-radius: 0px !important;
}
.borderRadius5[data-v-e28d1512] {
  border-radius: 5px !important;
}
.cursorPointer[data-v-e28d1512] {
  cursor: pointer;
}
.v-application--is-ltr .v-list-item__avatar[data-v-e28d1512]:last-of-type:not(:only-child) {
  margin-left: 0px !important;
}
.v-card .v-list-item[data-v-e28d1512] {
  padding-left: 4px !important;
}
.v-card[data-v-e28d1512]:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0px !important;
}
.grayScale[data-v-e28d1512] {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.iconCardsContainerLeft[data-v-e28d1512] {
  position: absolute;
  top: 8px;
  left: 8px;
  background: unset;
  height: 28px;
  z-index: 1;
}
.fontSize12[data-v-e28d1512] {
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/userListItem.vue","webpack://./userListItem.vue"],"names":[],"mappings":"AACA;EACE,UAAA;ACAF;ADEA;EACE,gBAAA;ACCF;ADCA;EACE,6BAAA;ACEF;ADAA;EACE,6BAAA;ACGF;ADDA;EACE,eAAA;ACIF;ADFA;EACE,2BAAA;ACKF;ADHA;EACE,4BAAA;ACMF;ADJA;EACE,6BAAA;ACOF;ADLA;EACE,+BAAA;EACA,uBAAA;ACQF;ADNA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,iBAAA;EACA,YAAA;EACA,UAAA;ACSF;ADPA;EACE,eAAA;ACUF","sourcesContent":["\n.customWidh {\n  width: 89%;\n}\n.contentWrapper {\n  max-width: 180px;\n}\n.noBRadius {\n  border-radius: 0px !important;\n}\n.borderRadius5 {\n  border-radius: 5px !important;\n}\n.cursorPointer {\n  cursor: pointer;\n}\n.v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child) {\n  margin-left: 0px !important;\n}\n.v-card .v-list-item {\n  padding-left: 4px !important;\n}\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0px !important;\n}\n.grayScale {\n  -webkit-filter: grayscale(100%);\n  filter: grayscale(100%);\n}\n.iconCardsContainerLeft {\n  position: absolute;\n  top: 8px;\n  left: 8px;\n  background: unset;\n  height: 28px;\n  z-index: 1;\n}\n.fontSize12 {\n  font-size: 12px;\n}\n",".customWidh {\n  width: 89%;\n}\n\n.contentWrapper {\n  max-width: 180px;\n}\n\n.noBRadius {\n  border-radius: 0px !important;\n}\n\n.borderRadius5 {\n  border-radius: 5px !important;\n}\n\n.cursorPointer {\n  cursor: pointer;\n}\n\n.v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child) {\n  margin-left: 0px !important;\n}\n\n.v-card .v-list-item {\n  padding-left: 4px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0px !important;\n}\n\n.grayScale {\n  -webkit-filter: grayscale(100%);\n  filter: grayscale(100%);\n}\n\n.iconCardsContainerLeft {\n  position: absolute;\n  top: 8px;\n  left: 8px;\n  background: unset;\n  height: 28px;\n  z-index: 1;\n}\n\n.fontSize12 {\n  font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
