// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vueScrollConditions[data-v-0cbc39bc] {
  max-height: calc(100% - 90px);
}
.imgLogo[data-v-0cbc39bc] {
  height: 100px;
}
.titleWelcome[data-v-0cbc39bc] {
  font-size: 30px;
  display: block;
  color: #2a3133;
  margin: 50px auto 10px;
  text-align: left !important;
}
.v-card[data-v-0cbc39bc] {
  height: calc(100% - 110px) !important;
}
.v-card-ios[data-v-0cbc39bc] {
  height: calc(100vh - 250px) !important;
}
.conditionsContent[data-v-0cbc39bc] {
  max-height: 350px;
  text-align: left !important;
  white-space: pre-line;
}
.mt-90[data-v-0cbc39bc] {
  margin-top: 90px;
}
.checkTerms[data-v-0cbc39bc] {
  display: inline-block;
  height: 30px;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./websrc/components/firstSetup/conditions.vue","webpack://./conditions.vue"],"names":[],"mappings":"AACA;EACE,6BAAA;ACAF;ADEA;EACE,aAAA;ACCF;ADEA;EACI,eAAA;EACA,cAAA;EACA,cAAA;EACE,sBAAA;EACA,2BAAA;ACCN;ADCA;EACE,qCAAA;ACEF;ADAA;EACE,sCAAA;ACGF;ADDA;EACI,iBAAA;EACA,2BAAA;EACA,qBAAA;ACIJ;ADFA;EACE,gBAAA;ACKF;ADHA;EACE,qBAAA;EACA,YAAA;EACA,sBAAA;ACMF","sourcesContent":["\n.vueScrollConditions{\n  max-height: calc(100% - 90px);\n}\n.imgLogo{\n  height: 100px;\n\n}\n.titleWelcome{\n    font-size: 30px;\n    display: block;\n    color: #2a3133; \n      margin: 50px auto 10px;\n      text-align: left !important;\n}\n.v-card{\n  height: calc(100% - 110px) !important;\n}\n.v-card-ios{\n  height: calc(100vh - 250px) !important;\n}\n.conditionsContent{\n    max-height: 350px;\n    text-align: left !important;\n    white-space: pre-line;\n}\n.mt-90{\n  margin-top: 90px;\n}\n.checkTerms{\n  display: inline-block;\n  height: 30px;\n  vertical-align: middle;\n}\n",".vueScrollConditions {\n  max-height: calc(100% - 90px);\n}\n\n.imgLogo {\n  height: 100px;\n}\n\n.titleWelcome {\n  font-size: 30px;\n  display: block;\n  color: #2a3133;\n  margin: 50px auto 10px;\n  text-align: left !important;\n}\n\n.v-card {\n  height: calc(100% - 110px) !important;\n}\n\n.v-card-ios {\n  height: calc(100vh - 250px) !important;\n}\n\n.conditionsContent {\n  max-height: 350px;\n  text-align: left !important;\n  white-space: pre-line;\n}\n\n.mt-90 {\n  margin-top: 90px;\n}\n\n.checkTerms {\n  display: inline-block;\n  height: 30px;\n  vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
