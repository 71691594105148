<template>
 <v-dialog
    :model-value="showModal"
    @update:model-value="showModal = $event" persistent max-width="560">
      <v-card class="mx-auto">
        <HeaderModal
          :titleModal="$t('components.beforeEndingCallModal.title')"
        />
        <v-card-text>
          {{
            amIOwnerMeeting
              ? $t("components.beforeEndingCallModal.text")
              : $t("components.beforeEndingCallModal.textRegularUser")
          }}
          <div class="mt-8 d-flex justify-center" v-if="amIOwnerMeeting">
            <div class="mx-3 pointer">
              <v-btn class="bg-primary color-white" @click="addMoreTime()">{{
                $t("components.userAgreementModal.ok")
              }}</v-btn>
              <v-btn class="bg-primary color-white ml-2" @click="endCall()">{{
                $t("components.beforeEndingCallModal.end")
              }}</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
  </v-dialog>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import {
  resetBeforeEndingCallModallEvent,
  beforeEndingCallModalStore,
} from "../../effector/modals";
import { callAddDurationEvent } from "../../effector/call";
import { rtcHangup } from "../../lib/rtcConn";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
export default {
  components: { HeaderModal },
  data() {
    const effector = {
      showModalEffector: beforeEndingCallModalStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      // Effector
      ...effector,
    }
  },
  watch: {
    "state.durationMeeting": {
      handler() {
        if (this.showModal) {
          this.closeModal();
        }
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.closeModal();
    }, 5000);
  },
  methods: {
    addMoreTime() {
      callAddDurationEvent(300000);
    },
    endCall() {
      this.hangupUp();
      this.ringAudioFinalCall();
      this.closeModal();
    },
    hangupUp() {
      return rtcHangup();
    },
    closeModal() {
      resetBeforeEndingCallModallEvent();
      // add reset modal
    },
    ringAudioFinalCall() {
      try {
        // Get real devices id's
        const audioDeviceId = store.state.persisted.mediaDeviceSetup.audioOutputId;
        // Set outputs
        const audioPath = "/media/endCall.mp3";
        const audioOutput = new Audio(audioPath);
        // Sync audioDevice
        let promise = Promise.resolve();
        if ('sinkId' in audioOutput && 'setSinkId' in audioOutput && audioDeviceId) {
          promise = audioOutput.setSinkId(audioDeviceId); // Here catch!
        }
        promise
          .then(() => audioOutput.play())
          .catch((error) =>
            console.warn("Failed to play notification audio", error)
          );
      } catch (error) {
        console.warn("Failed to play notification audio", error);
      }
    },
  },
  computed: {
    showModal: {
      get() {
        return this.showModalEffector;
      },
      set(newValue) {
        this.showModalEffector && !newValue && this.closeModal();
      }
    },
    amIOwnerMeeting() {
      return this.state.ownerMeeting;
    },
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>