import { alertsDomain } from './domain';

import { addAlertEvent, delAlertEvent, clearAlertsEvent } from './events';

export const alertsArrayStore = alertsDomain.createStore([], { name: 'alertsArray' });

alertsArrayStore.on(addAlertEvent, (state, payload) => {
  if (!payload.id) throw new Error('Missing ID');
  return [...state, payload];
});

alertsArrayStore.on(delAlertEvent, (state, payload) => {
  return state.filter(item => item.id !== payload.id);
});

alertsArrayStore.reset(clearAlertsEvent);

// alertsArrayStore.watch((state) => { console.log('alertsArrayStore', state); });
