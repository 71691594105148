import store from '../store';
import { setNamespaceSetting } from './wsMsg';
// const rtcSettings = require('./../../www/settings.json');

function getSettingsXhr() {
  const settingsFile = process.env.PKG ? '/settings.json' : (window.location.hostname.startsWith('beta.voffice.') ? '/settingsBeta.json' : '/settings.json');
  const xhr = new XMLHttpRequest()
  xhr.open("GET", settingsFile, false);
  xhr.setRequestHeader("Accept", "application/json");
  xhr.send(null);
  return xhr.status === 200 && JSON.parse(xhr.responseText);
}
let rtcSettings;
function getSettings() {
  rtcSettings = rtcSettings || getSettingsXhr();
  return rtcSettings;
}

export async function getSettingsAsync() {
  const settingsFile = process.env.PKG ? '/settings.json' : (window.location.hostname.startsWith('beta.voffice.') ? '/settingsBeta.json' : '/settings.json');
  const resp = await fetch(settingsFile, {
    headers: {
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest' 
    }
  });
  return resp.status === 200 && await resp.json();
}
export async function preloadSettingsAsync() {
  rtcSettings = rtcSettings || await getSettingsAsync();
}

export function defaultIceServers () {
  return getSettings().defaultIceServers || [];
}
export function jvbOptions () {
  return getSettings().jvbOptions;
}

export function getIceServers () {
  if (store.state.namespaceSettings.iceServers.length > 0) {
    return store.state.namespaceSettings.iceServers;
  }
  return defaultIceServers();
}

export async function setIceServers (iceServers) {
  return await setNamespaceSetting('iceServers', iceServers);
}
