import { deleteVisitorPaylinkModalDomain } from './domain';

import { setDeleteVisitorPaylinkModalEvent, resetDeleteVisitorPaylinkModalEvent} from './events';

export const deleteVisitorPaylinkModalStore = deleteVisitorPaylinkModalDomain.createStore(false, { name: 'deleteVisitorPaylinkModal' });

deleteVisitorPaylinkModalStore.on(setDeleteVisitorPaylinkModalEvent, (state, payload) => {
  return payload;
});

deleteVisitorPaylinkModalStore.reset(resetDeleteVisitorPaylinkModalEvent);
