const pageTitleNotificationConfig = {
  currentTitle: null,
  interval: null
};

export const on = (notificationText, intervalSpeed) => {
  if ('hidden' in document && document.hidden && !pageTitleNotificationConfig.interval) {
    pageTitleNotificationConfig.currentTitle = document.title;
    pageTitleNotificationConfig.interval = window.setInterval(() => {
      document.title = (pageTitleNotificationConfig.currentTitle === document.title)
        ? notificationText
        : pageTitleNotificationConfig.currentTitle;
    }, intervalSpeed || 1000);
  }
};

export const off = () => {
  window.clearInterval(pageTitleNotificationConfig.interval);
  pageTitleNotificationConfig.interval = null;
  document.title = pageTitleNotificationConfig.currentTitle;
};

const onBecomePrimaryTab = () => {
  if (!document.hidden && pageTitleNotificationConfig.interval) {
    off();
  }
};

document.addEventListener('visibilitychange', onBecomePrimaryTab);
window.addEventListener('focus', onBecomePrimaryTab);
