// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.closeButton[data-v-58c8d0a2] {
  border-radius: 4px;
  min-width: 45px;
}
.v-theme--light .v-dialog .v-card[data-v-58c8d0a2] {
  background: rgba(255, 255, 255, 0.7);
  word-break: break-word;
}
.v-theme--dark .v-dialog .v-card[data-v-58c8d0a2] {
  background: rgba(30, 30, 30, 0.7);
  word-break: break-word;
}
@media screen and (min-width: 993px) {
.v-dialog__content[data-v-58c8d0a2] {
    position: absolute !important;
}
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/paymentModals/paymentCanceled.vue","webpack://./paymentCanceled.vue"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,eAAA;ACAF;ADGE;EACE,oCAAA;EACA,sBAAA;ACAJ;ADIE;EACE,iCAAA;EACA,sBAAA;ACDJ;ADIA;AACE;IACE,6BAAA;ACDF;AACF","sourcesContent":["\n.closeButton {\n  border-radius: 4px;\n  min-width: 45px;\n  }\n.v-theme--light .v-dialog{\n  .v-card {\n    background: rgba(255,255,255,.7);\n    word-break: break-word;\n  }\n}\n.v-theme--dark .v-dialog{\n  .v-card {\n    background: rgba(30,30,30,.7);\n    word-break: break-word;\n  }\n}\n@media screen and (min-width: 993px) {\n  .v-dialog__content{\n    position: absolute !important\n  }\n}\n",".closeButton {\n  border-radius: 4px;\n  min-width: 45px;\n}\n\n.v-theme--light .v-dialog .v-card {\n  background: rgba(255, 255, 255, 0.7);\n  word-break: break-word;\n}\n\n.v-theme--dark .v-dialog .v-card {\n  background: rgba(30, 30, 30, 0.7);\n  word-break: break-word;\n}\n\n@media screen and (min-width: 993px) {\n  .v-dialog__content {\n    position: absolute !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
