// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerScroll[data-v-6fdc7ff6] {
  overflow: auto;
  max-height: 75vh;
}
.hideElement[data-v-6fdc7ff6] {
  opacity: 0;
}
.fullHeigth[data-v-6fdc7ff6] {
  height: calc(100% - 140px);
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/hotkeysModal.vue","webpack://./hotkeysModal.vue"],"names":[],"mappings":"AACA;EACE,cAAA;EACA,gBAAA;ACAF;ADEA;EACE,UAAA;ACCF;ADCA;EACE,0BAAA;ACEF","sourcesContent":["\n.containerScroll{\n  overflow: auto;\n  max-height: 75vh;\n}\n.hideElement {\n  opacity: 0;\n}\n.fullHeigth{\n  height: calc(100% - 140px);\n}\n",".containerScroll {\n  overflow: auto;\n  max-height: 75vh;\n}\n\n.hideElement {\n  opacity: 0;\n}\n\n.fullHeigth {\n  height: calc(100% - 140px);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
