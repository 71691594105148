<template>
  <div>
    <v-row class="mx-0" align="center">
      <v-col cols="12" class="text-center px-0 py-0">
        <div class="headline">
          {{ $t('components.plans.title')}}
        </div>
        <v-card-subtitle>
          {{ $t('components.plans.subtitle')}}
        </v-card-subtitle>
      </v-col>
    </v-row>
    <v-row class="mx-0" align="center">
       <v-col 
          v-for="n in 2"
          :key="n"
          :lg="cols[n - 1]"
          :md="cols[n - 1]"
          :sm="cols[n - 1]"
          class="col-12">
        <div v-if="n === 1">
          <v-card min-height="500">
            <div class="pa-4">
              <div class="headerPlan">
                <p class="title mb-0">
                  Basis version
                </p>
                <p class="captiom">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <p class="title">
                  25€ / Mo
                </p>
                <v-btn color="primary" width="100%" @click="buyIt('2500')">
                  Buy it
                </v-btn>
              </div>
              <div class="bodyPlan mt-4">
                <p class="title">
                  Features
                </p>
                <div class="featuresDiv">
                  <p class="caption mb-0">
                    <font-awesome-icon
                    color="primary"
                      :icon="['fal', 'check']"
                      :style="{ fontSize: '14px' }"
                    />
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                  <p class="caption mb-0">
                    <font-awesome-icon
                      :icon="['fal', 'check']"
                      color="primary"
                      :style="{ fontSize: '14px' }"
                    />
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                </div>
                
              </div>
            </div>
          </v-card>
        </div>
        <div v-if="n === 2">
          <v-card min-height="500">
            <div class="pa-4">
              <div class="headerPlan">
                <p class="title mb-0">
                  Pro version
                </p>
                <p class="captiom">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <p class="title">
                  50€ / Mo
                </p>
                <v-btn color="primary" width="100%" @click="buyIt('5000')">
                  Buy it
                </v-btn>
              </div>
              <div class="bodyPlan mt-4">
                <p class="title">
                  Features
                </p>
                <div class="featuresDiv">
                  <p class="caption mb-0">
                    <font-awesome-icon
                    color="primary"
                      :icon="['fal', 'check']"
                      :style="{ fontSize: '14px' }"
                    />
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                  <p class="caption mb-0">
                    <font-awesome-icon
                      :icon="['fal', 'check']"
                      color="primary"
                      :style="{ fontSize: '14px' }"
                    />
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                  <p class="caption mb-0">
                    <font-awesome-icon
                      :icon="['fal', 'check']"
                      color="primary"
                      :style="{ fontSize: '14px' }"
                    />
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                  <p class="caption mb-0">
                    <font-awesome-icon
                      :icon="['fal', 'check']"
                      color="primary"
                      :style="{ fontSize: '14px' }"
                    />
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                </div>
                
              </div>
            </div>
          </v-card>
        </div>
       </v-col>
    </v-row>
    <v-row class="mx-0" align="center">
      <v-col cols="12" class="text-center px-0 py-0">
        <v-card-subtitle>
          Supported payment methods:

        </v-card-subtitle>
        <v-flex >
          <!--  class="mr-4" -->
          <span>
             <img
              src="/img/paymentIcons/cc.png"
              class="paymentIcon"
              
             />
             Credit Card
          </span>
  
          <!-- <span class="mr-4">
             <img
              src="/img/paymentIcons/paypal.png"
              class="paymentIcon"
              
             />
            Paypal
          </span>
          
          <span class="mr-0">
            <img
              src="/img/paymentIcons/wire.png"
              class="paymentIcon"
              
             />
            Bank transfer
          </span> -->
        </v-flex>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from '../../store';

export default {
  components: {  },
  data () {
    return{
      state: store.state,
      setSetUpConfigRoute: store.setSetUpConfigRoute,
    }
  },
  computed:{
    cols () {
      const { lg, sm, md, xs } = this.$vuetify.display
      return lg ? [6, 6] : sm ? [12, 12] : md ? [6, 6] : xs ? [12, 12] : [6, 6];
    },
  },

  watch: {
   
  },

  methods: {
    buyIt(amount){
      localStorage.setItem('amountToPay', amount);
      this.setSetUpConfigRoute('payment');
    }
  },
}
</script>
<style scoped lang="scss">
.paymentIcon{
  width: 30px;
  vertical-align: middle;;
}
</style>