// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputMessageWaitingRoom .v-text-field input {
  margin-top: 4px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/historyMessagesModalEffector.vue","webpack://./historyMessagesModalEffector.vue"],"names":[],"mappings":"AAEE;EACE,0BAAA;ACDJ","sourcesContent":["\n.inputMessageWaitingRoom {\n  .v-text-field input{\n    margin-top: 4px!important;\n  }\n}\n",".inputMessageWaitingRoom .v-text-field input {\n  margin-top: 4px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
