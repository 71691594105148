import { videoTestModalDomain } from './domain';

import { setVideoTestModalEvent, resetVideoTestModalEvent} from './events';

export const videoTestModalStore = videoTestModalDomain.createStore(false, { name: ' videoTestModal' });

videoTestModalStore.on(setVideoTestModalEvent, (state, payload) => {
  return payload;
});

videoTestModalStore.reset(resetVideoTestModalEvent);
