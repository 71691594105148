<template>
  <v-list max-width="320">
    <v-list-group
      :value="searchFoundSomethingSection || isActive"
      @click="setList('sections')"
      :color="isDarkMode ? 'white' : 'black'"
    >
      <template v-slot:activator>
        <v-icon>
          <font-awesome-icon class="primary--text" :icon="['fal', 'sitemap']" />
        </v-icon>
        <v-list-item-title
          :class="`${
            getRoute === '/organisation' &&
            state.activeOrganisationSection == 'sections'
              ? 'font-weight-bold'
              : ''
          }`"
          @click.stop.prevent="
            handleOrganisation();
            searchFoundSomethingSection;
          "
        >
          <span class="pr-4">{{ data ? Object.keys(data).length : "0" }}</span>
          {{
            state.namespaceSettings.sectionAlias
              ? state.namespaceSettings.sectionAlias
              : $t("generics.sections")
          }}
        </v-list-item-title>
      </template>
      <div>
       <v-list density="compact" class="listHeight">
          <v-list-item
            link
            class="ml-9 cursorAuto"
            v-for="(people, section) in data"
            :key="section"
          >
          <div class="text-truncate " :class="selectedSectionName === section ? 'font-weight-bold primary--text': ''"  @click="openUserDepartment(section)" >
            {{ section }}
            </div>
          </v-list-item>
        </v-list> 
      </div>
      <!-- <vue-scroll class="vueScrollOrganistion"> -->
      <!-- <div class="divScroll">
          <v-list-group
            no-action
            sub-group
            v-for="(people, section) in data"
            :key="section"
            class="py-0 vListGroupSections"
            :color="isDarkMode? 'white':'black'"
          >
            <template v-slot:activator>
              <v-list-item-title class="dark fontSize15">{{
                section
              }}</v-list-item-title>
            </template>
            <UserList :persons="people" :section="section" />
          </v-list-group>
        </div> -->
      <!-- </vue-scroll> -->
    </v-list-group>
  </v-list>
</template>

<script>
import store, { EventBus } from "../../store";
import UserList from "./userList.vue";

export default {
  props: ["data", "active", "setList", "searchFoundSomethingSection"],
  components: {
    UserList,
  },
  data: () => ({
    state: store.state,
    ownUUID: store.state.ownUUID,
    selectedSectionName: null
  }),
  watch:{
    $route (to, from){
      if(to.path !== '/sections'){
        this.selectedSectionName = null;
      }
    }
  },
  methods: {
    openUserDepartment(sectionName){
      if(this.selectedSectionName === sectionName){
        this.selectedSectionName = null;
        EventBus.$emit('showUsersOrganisation', undefined);
        return;
      }
      this.selectedSectionName = sectionName
        const data = {
          type:'sections',
          name: sectionName
        };
      if(this.$router.currentRoute.path !== '/sections'){
        this.$router.push({ path: `/sections` });
      }
      setTimeout(() => {
        EventBus.$emit('showUsersOrganisation', data);
      }, 900);
    },
    handleOrganisation() {
      this.setCurrentContentVisile("sections", true, this.$router);
      this.selectedSectionName = null;
      EventBus.$emit('showUsersOrganisation', undefined);
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
  },
  computed: {
    isDarkMode() {
      return this.state.persisted.isDark;
    },
    getRoute() {
      return this.$route.path;
    },
    isActive() {
      return this.active == "sections";
    },
  },
};
</script>
<style scoped lang="scss">
.cursorAuto{
  cursor: auto;
}
.w80 {
  width: 80%;
}
.listHeight{
  max-height: 160px;
  overflow:auto;
}
.fontSize15 {
  font-size: 15px !important;
}
.vueScrollOrganistion {
  & .divScroll {
    max-height: calc(100vh - 650px) !important;
    @media screen and (max-height: 650px) {
      max-height: calc(100vh - 150px) !important;
    }
  }
  // avoiding empty spaces
}
.v-list {
  padding: 0;
}

.v-list-item__icon {
  margin-right: 8px !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
</style>

<style lang="scss">
.vueScrollOrganistion {
  & .__view {
    width: unset !important;
  }
}
.vListGroupSections {
  .v-list-item {
    height: 36px !important;
    min-height: 36px !important;
    max-height: 36px !important;
  }
  .v-list-item__icon {
    margin: 5px !important;
  }
}
</style>