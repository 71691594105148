// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendarSize[data-v-3270ec69] {
  height: calc(100vh - 170px);
  overflow: auto;
  padding-bottom: 20px;
}
.btnCalendar[data-v-3270ec69] {
  margin: 0px !important;
  color: white;
}
.text-white[data-v-3270ec69] {
  color: white;
}
.calendarDark[data-v-3270ec69] {
  background: #303030;
  border: 1px solid #9e9e9e;
  border-bottom: 0px;
}
.iconAddEvent[data-v-3270ec69] {
  font-size: 20px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  left: 5px;
}
.closeHome[data-v-3270ec69] {
  position: absolute;
  right: 50px;
}
.refreshData[data-v-3270ec69] {
  position: absolute;
  right: 20px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/calendarContainerEffector.vue","webpack://./calendarContainerEffector.vue"],"names":[],"mappings":"AACA;EACE,2BAAA;EACA,cAAA;EACA,oBAAA;ACAF;ADEA;EACE,sBAAA;EACE,YAAA;ACCJ;ADCA;EACE,YAAA;ACEF;ADAA;EACE,mBAAA;EACA,yBAAA;EACA,kBAAA;ACGF;ADDA;EACE,eAAA;EACA,sBAAA;EACA,kBAAA;EACA,SAAA;EACA,SAAA;ACIF;ADFA;EACE,kBAAA;EACA,WAAA;ACKF;ADHA;EACE,kBAAA;EACA,WAAA;ACMF","sourcesContent":["\n.calendarSize {\n  height: calc(100vh - 170px);\n  overflow: auto;\n  padding-bottom: 20px;\n}\n.btnCalendar{\n  margin: 0px !important;\n    color: white;\n}\n.text-white{\n  color: white;\n}\n.calendarDark{\n  background: #303030;\n  border: 1px solid #9e9e9e;\n  border-bottom: 0px;\n}\n.iconAddEvent{\n  font-size: 20px;\n  vertical-align: middle;\n  position: relative;\n  top: -2px;\n  left: 5px;\n}\n.closeHome{\n  position: absolute;\n  right: 50px;\n}\n.refreshData{\n  position: absolute;\n  right: 20px;\n}\n",".calendarSize {\n  height: calc(100vh - 170px);\n  overflow: auto;\n  padding-bottom: 20px;\n}\n\n.btnCalendar {\n  margin: 0px !important;\n  color: white;\n}\n\n.text-white {\n  color: white;\n}\n\n.calendarDark {\n  background: #303030;\n  border: 1px solid #9e9e9e;\n  border-bottom: 0px;\n}\n\n.iconAddEvent {\n  font-size: 20px;\n  vertical-align: middle;\n  position: relative;\n  top: -2px;\n  left: 5px;\n}\n\n.closeHome {\n  position: absolute;\n  right: 50px;\n}\n\n.refreshData {\n  position: absolute;\n  right: 20px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
