import { wsDomain } from './domain';

import { wsConnectedEvent, wsDisconnectedEvent } from './events';

export const wsConnectionStateStore = wsDomain.createStore(false, { name: 'wsConnectionState' });

wsConnectionStateStore.on(wsConnectedEvent, () => true);
wsConnectionStateStore.on(wsDisconnectedEvent, () => false);

// DEBUG TESTING
// wsConnectionStateStore.watch((newState) => {
//   console.log('wsConnectionStateStore Watch:', newState);
// });
