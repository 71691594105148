import { userProfileDomain } from './domain';

import { setUserProfileEvent, resetUserProfileEvent } from './events';

export const userProfileStore = userProfileDomain.createStore(false, { name: 'userProfile' });

userProfileStore.on(setUserProfileEvent, (state, payload) => {
  return payload;
});

userProfileStore.reset(resetUserProfileEvent);
