import { combine } from 'effector';
import { timelineDomain } from './domain';
import store from '../../store';
import { ownUserUUIDState } from '../users';

import {
  uuidToRepresentStore,
} from "../representative";
import { setTimelineEventInfoEvent, setTimelineEventInfoBatchedEvent, setTimelineEventUUIDsEvent, deleteTimelineEntryAndUUIDEvent, resetAndRefetchDatesEvent } from './events';

export const timelineItemUUIDStore = timelineDomain.createStore([], { name: 'timelineItemUUID' }); // List of event UUIDs that I care about (owner of, invited by, etc)

// TODO: rpc call to remove from this array (deletions)
// TODO: clean cache of stuff we no longer care about

timelineItemUUIDStore.on(setTimelineEventUUIDsEvent, (state, payload) => {
  // console.log('timelineItemUUIDStore setTimelineEventUUIDsEvent', state, payload);
  const newArray = [...state];
  let changed = false;
  payload[1].forEach((i) => {
    const extant = newArray.find(e => e.uuid === i.uuid);
    // console.log('timelineItemUUIDStore setTimelineEventUUIDsEvent 2', extant, i);
    if (!extant) {
      newArray.push(i);
      changed = true;
    } else if (extant.updatedServerTs < i.updatedServerTs) {
      extant.updatedServerTs = i.updatedServerTs;
      changed = true;
    }
  });
  return changed ? newArray : state; // If nothing new was added/altered, send same state to prevent updates
});

timelineItemUUIDStore.on(deleteTimelineEntryAndUUIDEvent, (state, payload) => {
  return state.filter(t => t.uuid !== payload);
});

timelineItemUUIDStore.reset(resetAndRefetchDatesEvent);

export const cachedTimelineEvents = timelineDomain.createStore({}, { name: 'cachedTimeline' }); // All tickets we fetched so far

cachedTimelineEvents.reset(resetAndRefetchDatesEvent);

cachedTimelineEvents.on(setTimelineEventInfoEvent, (state, payload) => {
  // console.log('cachedTimelineEvents setTimelineEventInfoEvent', state, payload);
  return { ...state, [payload[0]]: payload[1] };
});

cachedTimelineEvents.on(setTimelineEventInfoBatchedEvent, (state, payload) => {
  // console.log('cachedTimelineEvents setTimelineEventInfoBatchedEvent', state, payload);
  return { ...state, ...payload };
});

cachedTimelineEvents.on(deleteTimelineEntryAndUUIDEvent, (state, payload) => {
  const newState = { ...state };
  delete newState[payload];
  return newState;
});

const myTimelineEvents = combine(timelineItemUUIDStore, cachedTimelineEvents, (uuids, cachedEvents) => {
  const events = [];
  for (const uuidObj of uuids) {
    const uuid = uuidObj.uuid;
    // events.push(cachedEvents[uuid] ? { ...cachedEvents[uuid], uuid } : uuid);
    const singleEvent = cachedEvents[uuid] ? cachedEvents[uuid] : uuid
    if (!singleEvent.rsvp) {
      events.push(singleEvent);
    } else {
      const myUUID = uuidToRepresentStore.getState() || store.state.ownUUID;
      if (singleEvent.creatorUUID == myUUID || (singleEvent.creatorUUID != myUUID && singleEvent.rsvp[myUUID] )) {
        events.push(singleEvent);
      }
    }
  }
  return events;
});

export const unknownTimelineEvents = myTimelineEvents.map(s => s.filter(e => typeof e === 'string'));
export const timelineEvents = myTimelineEvents.map(s => s.filter(e => typeof e === 'object'));

export const outdatedTimelineEvents = combine(timelineItemUUIDStore, cachedTimelineEvents, (uuidAndTimings, cachedEvents) => {
  const outdatesUUIDs = [];
  for (const uuidObj of uuidAndTimings) {
    const entry = cachedEvents[uuidObj.uuid];
    if (entry && uuidObj.updatedServerTs > entry.updatedServerTs) {
      // console.log('outdatedTimelineEvents', entry, uuidObj);
      outdatesUUIDs.push(uuidObj.uuid);
    }
  }
  return outdatesUUIDs;
});

// getStatsConference

export const timelineStats = timelineEvents.map((s) => {
  const ownUUID = ownUserUUIDState.getState();
  let temporal = [];
  temporal = s.filter((e) => {
    return e && (new Date(e.start) <= new Date()) && ((e.users && e.users.indexOf(ownUUID) !== -1) || e.creatorUUID == ownUUID)
  });
  return temporal.sort(function (a, b) {
    const aDate = new Date(a.start);
    const bDate = new Date(b.start);
    return aDate > bDate
      ? -1
      : aDate < bDate
        ? 1
        : (a.creatorUUID || '').localeCompare(b.creatorUUID || '');
  });
});

// export const searchFilteredEvents = timelineEvents.map(s => s.filter(e => e.title.indexOf('derp') !== -1));

// Debug
// outdatedTimelineEvents.watch((state) => {
//   console.log('outdatedTimelineEvents', state);
// });
// timelineItemUUIDStore.watch((state) => {
//   console.log('timelineItemUUIDStore', state);
// });
// unknownTimelineEvents.watch((state) => {
//   console.log('unknownTimelineEvents', state);
// });
// myTimelineEvents.watch((state) => {
//   console.log('myTimelineEvents', state);
// });
// timelineEvents.watch((state) => {
//   console.log('timelineEvents', state);
// });
