// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flatButtons[data-v-ac953488] {
  font-size: 0.625rem;
  height: 20px !important;
  min-width: 36px;
  padding: 0 8.8888888889px;
}
.borderRadius4[data-v-ac953488] {
  border-radius: 4px !important;
}
.footerModal[data-v-ac953488] {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
}
.footerModal .btns[data-v-ac953488] {
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/joinConferenceStartedModal.vue","webpack://./joinConferenceStartedModal.vue"],"names":[],"mappings":"AACA;EACE,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,yBAAA;ACAF;ADEA;EACE,6BAAA;ACCF;ADCA;EACE,WAAA;EACA,6BAAA;EACA,uBAAA;ACEF;ADDE;EACE,yBAAA;ACGJ","sourcesContent":["\n.flatButtons{\n  font-size: .625rem;\n  height: 20px !important;\n  min-width: 36px;\n  padding: 0 8.8888888889px;\n}\n.borderRadius4 {\n  border-radius: 4px!important;\n}\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n  .btns {\n    justify-content: flex-end;\n  }\n}\n",".flatButtons {\n  font-size: 0.625rem;\n  height: 20px !important;\n  min-width: 36px;\n  padding: 0 8.8888888889px;\n}\n\n.borderRadius4 {\n  border-radius: 4px !important;\n}\n\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n}\n.footerModal .btns {\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
