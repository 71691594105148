// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-dialog--fullscreen {
  overflow: hidden !important;
}
.vueScroll {
  height: calc(100% - 56px) !important;
}
.vueScroll .__view {
  width: unset !important;
}
.v-theme--dark .v-dialog.v-dialog--active {
  background-color: #1e1e1e;
}
.v-theme--light .v-dialog.v-dialog--active {
  background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./websrc/components/mobile/footernav.vue","webpack://./footernav.vue"],"names":[],"mappings":"AACA;EACE,2BAAA;ACAF;ADEA;EACE,oCAAA;ACCF;ADAE;EACE,uBAAA;ACEJ;ADCA;EACE,yBAAA;ACEF;ADAA;EACE,sBAAA;ACGF","sourcesContent":["\n.v-dialog--fullscreen{\n  overflow: hidden !important;\n}\n.vueScroll {\n  height: calc(100% - 56px) !important;\n  .__view{\n    width: unset !important;\n  }\n}\n.v-theme--dark .v-dialog.v-dialog--active{\n  background-color: #1e1e1e;\n}\n.v-theme--light .v-dialog.v-dialog--active{\n  background-color: #fff;\n}\n",".v-dialog--fullscreen {\n  overflow: hidden !important;\n}\n\n.vueScroll {\n  height: calc(100% - 56px) !important;\n}\n.vueScroll .__view {\n  width: unset !important;\n}\n\n.v-theme--dark .v-dialog.v-dialog--active {\n  background-color: #1e1e1e;\n}\n\n.v-theme--light .v-dialog.v-dialog--active {\n  background-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
