<template>
  <v-list :opened="opened" @update:opened="newOpened => opened = newOpened"  max-width="320" @click.stop.prevent>
    <v-list-group
      active-class="listColor"
      v-if="!isGuest && !mini"
      value="favorites"
      @click="handlerClickFav($event)"
      :expand-icon="sortedFavoriteUsers.length === 0 ? null : '$expand'"
    >
      <template v-slot:activator="{ props }">
        <v-list-item  v-bind="props" @click="handlerClickFav($event)">
          <template v-slot:prepend>
            <v-icon size="18">
              <font-awesome-icon
                v-if="currentPage('/my-favorites')"
                :icon="['fas', 'star']"
                class="primary--text-sidepanel"
                :style="{fontSize: '18px'}"
              />
              <font-awesome-icon
                v-else
                :icon="['fal', 'star']"
                class="primary--text-sidepanel"
                :style="{fontSize: '18px'}"
              />
            </v-icon>
          </template>
          <v-tooltip location="top" >
            <template v-slot:activator="{ props }">
                <!-- class="primary--text" -->
              <v-list-item-title  @click="handlerClickFav($event)"
              class="primary--text-sidepanel"
                :class="`${
                  getRoute === '/my-favorites'
                    ? 'font-weight-bold'
                    : ''
                }`"
                @click.stop.prevent="
                  setCurrentContentVisile('', false, $router);
                  handleMyFavoriteView();
                "
                v-bind="props"
              >
                {{ $t("components.footernav.myFavorites") }}
                <span v-if="sortedFavoriteUsers.length > 0">
                  (<span class="textColor">{{ connectedFavoriteUsersLen }}</span
                  >/ {{ totalUnfilteredUsers }})</span
                >
              </v-list-item-title>
            </template>
            <span>{{ $t("components.footernav.myFavoritesTT") }}</span>
          </v-tooltip>
          <CustomTT
            v-if="showCustomTT"
            :text="`${$t('components.hotkeys.controlKey')} + F`"
            position="bottom"
            :extraclass="'ctrf'"
          />
        </v-list-item>
      </template>
      <UserList :persons="sortedFavoriteUsers" :section="'Favorites'" />
    </v-list-group>
    <v-tooltip right v-if="!isGuest && mini">
      <template v-slot:activator="{ props }">
        <v-list-item  v-bind="props" class="cursorPointer">
          <v-icon size="18" @click.stop.prevent="handlerClickFav()">
            <font-awesome-icon
              v-if="currentPage('/my-favorites')"
              :icon="['fas', 'star']"
              class="primary--text-sidepanel"
              :style="{fontSize: '18px'}"
            />
            <font-awesome-icon
              v-if="!currentPage('/my-favorites')"
              :icon="['fal', 'star']"
              class="primary--text-sidepanel"
              :style="{fontSize: '18px'}"
            />
          </v-icon>
        </v-list-item>
      </template>
      <span>{{ $t("components.sidebar.favoritesMiniTT") }}</span>
    </v-tooltip>
  </v-list>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store, { EventBus } from "../../store";
import UserList from "./userList.vue";
import CustomTT from "../content/customTT.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";

export default {
  props: [
    "mini",
    "persons",
    "filterPerson",
    "setCurrentContentVisile",
    "isGuest",
    "sortedFavoriteUsers",
    "active",
    "setList",
    "totalUnfilteredUsers",
    "isMobile",
  ],
  components: {
    UserList,
    CustomTT,
  },
   data() {
    const effector = {
      showCustomTT: hotkeysInfoStore,
    };
     Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      opened: [],
      // Effector
      ...effector,
    }
  },
  watch: {
    "isActive": {
      handler(value) {
        if(!value){
          this.opened = [];
        }
      },
    },
  },
  mounted() {
    EventBus.$on("openFavoritesEvent", this.handleMyFavoriteView);
  },
  unmounted(){
    EventBus.$off("openFavoritesEvent", this.handleMyFavoriteView);
  },
  methods: {
    handlerClickFav(event) {
      if (this.mini) {
        this.goToPage("my-favorites");
      } else {
        this.setList("favorites");
      }
    },
    currentPage(page) {
      const actualRoute = this.$route.path;
      if (actualRoute === page) {
        return true;
      }
      return false;
    },
    goToPage(page) {
      this.setCurrentContentVisile(page, true, this.$router);
    },
    handleMyFavoriteView() {
      if (!this.isMobile) {
        this.setCurrentContentVisile("my-favorites", true, this.$router);
      }
    },
  },
  computed: {
    connectedFavoriteUsersLen: function () {
      return this.sortedFavoriteUsers.filter((p) => p.connected).length;
    },
    isActive() {
      return this.active == "favorites";
    },
    getRoute() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped lang="scss">
.cursorPointer {
  cursor: pointer;
}
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.textColor {
  color: #008000;
}

.v-theme--dark .listColor {
  color: white !important;
}
.v-theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
.ctrf {
  left: 15px;
  bottom: 0px;
}
</style>

