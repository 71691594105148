<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="570"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="titleModal"
        :closeModalFunction="closeModal"
      />
      <v-row class="mx-0 pt-4 pb-1 px-4">
        <v-col class="dataContainer">
          <p v-for="(user, index) in parsedUserList" :key="index">{{ user }}</p>
        </v-col>
      </v-row>
      <FooterModal :closeModalFunction="closeModal">
         <v-btn color="primary" @click="confirmPrompt">{{
          $t("generics.accept")
        }}</v-btn>
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  props: ["titleModal", "showModal", "closeModal", "userList"],
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
    };
  },
  methods: {
    confirmPrompt() {
      return this.closeModal("accept");
    }
  },
  computed: {
    parsedUserList() {
      if (!Array.isArray(this.userList)) return [];
      return this.userList.map(user => {
        const titel = (user.titel && user.titel !== "unknown") ? user.titel : "";
        return `${titel} ${user.name} (${user.email})`.trim();
      });
    }
  }
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
.dataContainer {
  max-height: 420px;
  overflow-y: auto;
}
</style>
