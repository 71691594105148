<template>
<div v-if="hour !== lastHour" :class="hour !== lastHour ? 'boxupper' : 'boxNoTime'" @click="hour !== lastHour ? handleNewEventEntry($event,date,hour) : null">
  <div class="rulerDigit" :class="{'hide': hour < getHour}">{{hour}}</div><span :class="{'timelineDate': hour !== lastHour,'timelineLast': hour == lastHour}" v-if="hour === lastHour"></span>
</div>
</template>

<script>
import moment from "../../../sharedsrc/moment";
import MeetingLineNowIndicator from "./meetingLineNowIndicator.vue";
export default {
  components:{MeetingLineNowIndicator},
  props: ['hour','date','handleNewEventEntry'],
 
  data() {
    return {
       lastHour: '19',
       moment:moment
     };
  },
  computed: {
    getHour(){
      const d = new Date();
      const n = d.getHours();
      return n;

    },
    magicStyle() {
      return {
        position: 'absolute',
        top: '0px',
        bottom: '0px',
        left: this.leftStartRule + '%',
        right: this.rightEndRule + '%',
        width: (100 - this.rightEndRule) - this.leftStartRule + '%',
      };
    },
    leftStartRule() {
      const aDayMs = 60000 * 60 * 24;
      const aDayMsSplit = 100 / aDayMs;

      const diff = 60000 * 60 * this.hour;
      const per = aDayMsSplit * diff;
      return per;
    },
    rightEndRule() {
      const per = this.leftStartRule + 0.1;
      return (100 - per);
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.hide{
  visibility: hidden;
}
.boxupper {
  border-right: 1px solid #e0e0e0;
  width: calc(100% / 12);
  display: inline-block;
  height: 35px;
  z-index: 50;
}
.boxupper:hover {
  background-color: #dedce0bd;
  cursor: pointer;
}
.boxNoTime{
    border-right: 1px solid #e0e0e0;
    // background-color: var(--v-primary-lighten1);
    display: inline-block;
    height: 45px;
    z-index: 50;
    // width: 1px;
    position: relative;
    width: 1px;
    // right: -5px;
}
.rulerDigit{
    width: 100%;
    font-size: 12px;
    // font-size: 0.8em;
    text-align: left;
    position: relative;
    top: calc(100% - 1px);
    border-top: 1px solid #e0e0e0;
    z-index: 9;
}
.timelineDate{
    position: absolute;
    right: 16px;
    top: 40px;
    z-index: 99;
    font-size: 15px;
    font-weight: 600;
}
.timelineLast{
  position: absolute;
  left: 8px;
  top: 13px;
  z-index: 99;
  font-size: 12px;
  font-weight: 600;
  color: white;
}

</style>
