<template>
  <OrganisationRegularGrid :data="sections" />
</template>

<script>
import store from "../../store";
import OrganisationRegularGrid from "./organisationRegularGrid.vue";
export default {
  components: { OrganisationRegularGrid },
  data() {
    return {
      state: store.state,
      sections: store.state.namespaceSettings.processedOrganisation.sections,
    };
  },
};
</script>