// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dragFullScreen[data-v-f482fc72] {
  z-index: 99999;
}
.local-video[data-v-f482fc72] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
}
.noCameraStream[data-v-f482fc72] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
}
.noCameraContent[data-v-f482fc72] {
  width: 100%;
  height: 100%;
  position: relative;
}
.imageNotVideo[data-v-f482fc72] {
  width: 100%;
  height: 100%;
  position: relative;
}
.resizable-content[data-v-f482fc72] {
  height: 100%;
  width: 100%;
  background-color: black;
  border: 3px dashed red;
  cursor: move;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/webCamDragable.vue","webpack://./webCamDragable.vue"],"names":[],"mappings":"AACA;EACE,cAAA;ACAF;ADEA;EAEE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,YAAA;ACAF;ADGA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,YAAA;ACAF;ADEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;ACCF;ADCA;EAEE,WAAA;EACA,YAAA;EACA,kBAAA;ACCF;ADIA;EACE,YAAA;EACA,WAAA;EACA,uBAAA;EACA,sBAAA;EACA,YAAA;ACDF","sourcesContent":["\n.dragFullScreen {\n  z-index: 99999;\n}\n.local-video {\n  // width: 197px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  padding: 5px;\n}\n\n.noCameraStream {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  padding: 5px;\n}\n.noCameraContent {\n  width: 100%;\n  height: 100%;\n  position: relative;\n}\n.imageNotVideo {\n  // width: 154px;\n  width: 100%;\n  height: 100%;\n  position: relative;\n}\n// .dialog-drag {\n//   background-color: black !important;\n// }\n.resizable-content {\n  height: 100%;\n  width: 100%;\n  background-color: black;\n  border: 3px dashed red;\n  cursor: move;\n}\n",".dragFullScreen {\n  z-index: 99999;\n}\n\n.local-video {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  padding: 5px;\n}\n\n.noCameraStream {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  padding: 5px;\n}\n\n.noCameraContent {\n  width: 100%;\n  height: 100%;\n  position: relative;\n}\n\n.imageNotVideo {\n  width: 100%;\n  height: 100%;\n  position: relative;\n}\n\n.resizable-content {\n  height: 100%;\n  width: 100%;\n  background-color: black;\n  border: 3px dashed red;\n  cursor: move;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
