import { changelogModalDomain } from './domain';

import { setChangelogModalEvent, resetChangelogModalEvent } from './events';

export const changelogModalStore = changelogModalDomain.createStore(false, { name: 'changelogModal' });

changelogModalStore.on(setChangelogModalEvent, (state, payload) => {
  return payload;
});

changelogModalStore.reset(resetChangelogModalEvent);
