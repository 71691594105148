import { companyDocumentDomain } from './domain';

import { 
  getUserCompanyDocuments, 
  createCompanyDocumentEntry, 
  getCompanyDocumentEntryByUUID, 
  deleteCompanyDocumentEntry, 
  getMyCompanyDocumentEntries, 
  updateCompanyDocument,
 } from '../../lib/wsMsg';

export const fetchMyCompanyDocumentsEffect = companyDocumentDomain.createEffect({
  name: 'fetchMyCompanyDocumentsEffect',
  handler: getUserCompanyDocuments,
});

export const createCompanyDocumentEntryEffect = companyDocumentDomain.createEffect({
  name: 'createCompanyDocumentEntryEffect',
  handler: createCompanyDocumentEntry,
});

export const getCompanyDocumentEntryByUUIDEffect = companyDocumentDomain.createEffect({
  name: 'getCompanyDocumentEntryByUUIDEffect',
  handler: getCompanyDocumentEntryByUUID,
});

export const deleteCompanyDocumentEntryEffect = companyDocumentDomain.createEffect({
  name: 'deleteCompanyDocumentEntryEffect',
  handler: (payload) => { return deleteCompanyDocumentEntry(payload[0]); }
});

export const getMyCompanyDocumentEntriesDetailEffect = companyDocumentDomain.createEffect({
  name: 'getMyCompanyDocumentEntriesDetailEffect',
  handler: (payload) => { return getMyCompanyDocumentEntries() }
});

export const updateCompanyDocumentEffect = companyDocumentDomain.createEffect({
  name: 'updateCompanyDocumentEffect',
  handler: updateCompanyDocument,
});
