<template>
  <v-dialog :model-value="vOfficeProVersionModal" @update:model-value="vOfficeProVersionModal = $event" persistent max-width="800" @keydown.esc="closeModal">
    <template>
      <v-card class="mx-auto">
        <v-row flex class="mx-0 my-0 justify-space-between align-end">
          <v-col class="px-0" align="left">
            <v-card-title class="text-h5" primary-title>
              <v-img
                class="mr-1"
                src="assets/icon.png"
                max-height="30"
                max-width="30"
                contain
              ></v-img>
              {{ $t("components.proVersionModal.title") }}
            </v-card-title>
          </v-col>
          <div class="pa-4">
              <template>
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    icon
                    color="black"
                    @click="closeModal()"
                  >
                    <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '25px' }" />
                  </v-btn>
                </template>
                <span>{{ $t('generics.close') }}</span>
              </v-tooltip>
            </template>
            </div>
        </v-row>
        <v-card-text>
         <p>{{$t("components.proVersionModal.text")}}</p>
         <p>{{$t("components.proVersionModal.section")}}</p>
         <p class="mb0-i">{{$t("components.proVersionModal.section1")}}</p>
         <p>{{$t("components.proVersionModal.section11")}}</p>
         <!-- <p>{{$t("components.proVersionModal.section2")}}</p> -->
         <p  class="mb0-i">{{$t("components.proVersionModal.section3")}}</p>
         <p>{{$t("components.proVersionModal.section31")}}</p>
         <p  class="mb0-i">{{$t("components.proVersionModal.section4")}}</p>
         <p>{{$t("components.proVersionModal.section41")}}</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn color="primary" :loading="proVersionLoading" @click="saveProVersion">{{
            $t("generics.accept")
          }}</v-btn>
          <v-btn color="primary" @click="closeModal">{{
            $t("generics.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { useStore } from "effector-vue/composition";
import { webLicensedBaseFeatures } from "../../../sharedsrc/licensedFeatures";
import store, { EventBus } from "../../store";
import { generatePurchaseToken, setNamespaceSetting } from "../../lib/wsMsg";
import {
  resetvOfficeProVersionModallEvent,
  vOfficeProVersionModalStore,
} from "../../effector/modals";
import { dispatchErrorAlert } from '../../effector/alerts';

export default {
  props: [],
  components: {},
  data() {
    const effector = {
      vOfficeProVersionModal: vOfficeProVersionModalStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
   });
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      proVersionLoading: false,
      // Effector
      ...effector,
    };
  },
  mounted() {},
  unmounted() {},
  watch: {},
  methods: {
    async saveProVersion(){
      this.proVersionLoading = true;
      if (webLicensedBaseFeatures.isRaMicroCustomer) {
        const domain = window.location.hostname.toLowerCase().replace(/\.ra-micro\..+?$/, '');
        await generatePurchaseToken()
          .then(token => {
            window.open("https://voffice.ra-micro.de/?domain=" + encodeURIComponent(domain) + "&token=" + encodeURIComponent(token), "_blank");
          })
          .catch(err => {
            dispatchErrorAlert(err.message);
            console.warn('generatePurchaseToken', err.stack);
          });
        EventBus.$emit("resetProVersionVariable");
      } else {
        await setNamespaceSetting("basicVersion", false)
          .catch(err => {
              dispatchErrorAlert(err.message);
              console.warn('setNamespaceSetting', err.stack);
            });
      }
      this.proVersionLoading = false;
      resetvOfficeProVersionModallEvent()
    },
    closeModal() {
      EventBus.$emit("resetProVersionVariable");
      resetvOfficeProVersionModallEvent();
    },
  },
};
</script>

<style scoped lang="scss">
.mb0-i {
  margin-bottom: 0px !important;
}
</style>