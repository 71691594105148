<template>
    <v-dialog
        :model-value="showModal"
        @update:model-value="showModal= $event"
        persistent
        max-width="700"
        @keydown.esc="closeModal"
    >
      <v-card>
        <HeaderModal
          :titleModal="$t('components.noCallsModal.title')"
          :closeModalFunction="closeModal"
          :showInfoButton="`No Calls`"
        />
        <v-card-text class="mx-1 px-2">
            <v-textarea class="px-4" :model-value="textFieldValue" @update:model-value="textFieldValue = $event"
                :placeholder="$t('components.noCallsModal.textFieldPlaceholder')"
                maxlength="150"
                no-resize
                counter
                color="primary"
                variant="outlined"
            />
        </v-card-text>
        <FooterModal :showCancel="true" :closeModalFunction="closeModal">
            <v-btn
                color="primary"
                flat
                class="ml-2"
                :disabled="textFieldValue.trim() === ''"
                @click="saveNoCallsStatusFunction(textFieldValue)"
                >{{ $t("generics.save") }}</v-btn
                >
        </FooterModal>
      </v-card>
    </v-dialog>
</template>

<script>
import FooterModal from "../modal/modalComponents/footerModal.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";

export default {
  components: { HeaderModal, FooterModal },
  props: ["showModal", "closeModal", "userUUID", "saveNoCallsStatusFunction"],
  data: () => ({
    textFieldValue: ''
  }),
  mounted: function () {
  },
  methods: {
  },
  computed: {
  },
};
</script>

<style lang="scss">

</style>