// import { timelineDomain.createEffect } from 'effector';
import { timelineDomain } from './domain';
import {
  dispatchErrorAlert
} from '../alerts';
import Vue from '@vue/compat';
import { getTimelineForDate, getTimelineEntryByUUID, createTimelineEntry, updateTimelineEntry, deleteTimelineEntry, rsvpTimelineEntry, updateTimelineItemDuration, viewedTimelineEntry, updateTimelineEntryColor , pdfTimelineEntry} from '../../lib/wsMsg';

export const fetchMyEventsEffect = timelineDomain.createEffect({
  name: 'fetchMyEventsEffect',
  handler: getTimelineForDate,
});

export const fetchEventByUUIDEffect = timelineDomain.createEffect({
  name: 'fetchEventByUUIDEffect',
  handler: getTimelineEntryByUUID,
});

export const createTimelineEntryEffect = timelineDomain.createEffect({
  name: 'createTimelineEntry',
  handler: createTimelineEntry,
});

export const updateTimelineItemEffect = timelineDomain.createEffect({
  name: "updateTimelineItem",
  handler: (payload) => { 
   // console.log('payload from effector', payload)
    if (payload[1].collisions && payload[1].collisions.length){
      dispatchErrorAlert(Vue.prototype.$t('components.waitingRoom.DeleteUserMultieventError'));
      return false;
    } else {
      return updateTimelineEntry(payload[0], payload[1], payload[2], payload[3] || "", payload[4] || ""); 
    }
  },
});

export const updateTimelineItemDurationEffect = timelineDomain.createEffect({
  name: "updateTimelineItemDuration",
  handler: (payload) => { return updateTimelineItemDuration(payload[0], payload[1], payload[2] ); },
});

export const updateTimelineEntryColorEffect = timelineDomain.createEffect({
  name: "updateTimelineEntryColor",
  handler: (payload) => { return updateTimelineEntryColor(payload[0], payload[1] ); },
});

export const deleteTimelineEntryEffect = timelineDomain.createEffect({
  name: 'deleteTimelineEntryEffect',
  handler: deleteTimelineEntry,
});

export const rsvpTimelineEntryEffect = timelineDomain.createEffect({
  name: 'rsvpTimelineEntryEffect',
  handler: (payload) => { return rsvpTimelineEntry(payload[0], payload[1], payload[2]); },
});

export const viewedTimelineEntryEffect = timelineDomain.createEffect({
  name: 'viewedTimelineEntryEffect',
  handler: (payload) => { return viewedTimelineEntry(payload[0], payload[1]); },
});

export const pdfTimelineEntryEffect = timelineDomain.createEffect({
  name: 'pdfTimelineEntryEffect',
  handler: (payload) => { return pdfTimelineEntry(payload[0], payload[1]); },
});

//  fetchMyEventsEffect.done.watch(({params, result}) => { console.log('fetchMyEventsEffect success', params, result); });
// fetchMyEventsEffect.fail.watch(({ params, error }) => { console.log('fetchMyEventsEffect error', params, error); });

// await rpc.ws.rsvpTimelineEntry(entryUUID,true);

// const aDelay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// async function fetchMyEventsEffectMock() {
//   console.log('fetchMyEventsEffectMock');
//   await aDelay(500);
//   return ['0', '1', '2'];
// }

// async function fetchEventByUUIDEffectMock(uuid) {
//   console.log('fetchEventByUUIDEffectMock', uuid);
//   await aDelay(500);
//   switch(uuid) {
//     case '0': return {t: 'zero'};
//     case '1': return {t: 'one'};
//     case '2': return {t: 'two'};
//     default: return  {t: 'wtf'};
//   }
// }
