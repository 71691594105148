// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerButton[data-v-1cec4b66] {
  width: auto !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/removeIceServerModal.vue","webpack://./removeIceServerModal.vue"],"names":[],"mappings":"AACA;EACE,sBAAA;ACAF","sourcesContent":["\n.footerButton {\n  width: auto !important;\n}\n",".footerButton {\n  width: auto !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
