<template>
  <v-dialog
    :model-value="uploadAvatarModalData.showModal" @update:model-value="uploadAvatarModalData.showModal = $event"
    persistent
    max-width="550"
    @keydown.esc="closeModal"
    class="uploadAvatarCropper"
  >
    <HeaderModal
      :titleModal="$t('components.userManagement.changePicture')"
      :closeModalFunction="closeModal"
    />
    <v-card-text>
      <div class="d-flex justify-center" style="position: relative">
        <v-row class="d-flex mx-auto pt-5 pb-2 pl-3">
          <v-col cols="12" class="py-0">
            <cropper
              :stencil-size="stencilSize"
              :default-size="defaultSize"
              ref="cropper"
              class="twitter-cropper"
              background-class="twitter-cropper__background"
              foreground-class="twitter-cropper__foreground"
              image-restriction="stencil"
              :stencil-props="{
                lines: {},
                handlers: {},
                movable: false,
                scalable: false,
                aspectRatio: 1,
                previewClass: 'twitter-cropper__stencil',
              }"
              :transitions="false"
              :canvas="true"
              :debounce="false"
              :min-width="150"
              :min-height="150"
              :src="tmpAvatar"
              @change="onChange"
            />
            <v-slider :model-value="zoomValue" @update:model-value="zoomValue = $event; onZoom(zoomValue)"></v-slider>
          </v-col>
          <div class="rotateIcons">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" icon color="grey" @click="rotate(-90)">
                  <font-awesome-icon
                    :icon="['fal', 'undo']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.navbar.rotateLeft") }}</span>
            </v-tooltip>
            <br />
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" icon color="grey" @click="rotate(90)">
                  <font-awesome-icon
                    :icon="['fal', 'undo']"
                    :style="{ fontSize: '20px' }"
                    class="fa-flip-horizontal"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.navbar.rotateRight") }}</span>
            </v-tooltip>
          </div>
        </v-row>
      </div>
    </v-card-text>
    <FooterModal :closeModalFunction="closeModal" :showFooter="true">
      <v-btn class="buttonPrimaryFooter" color="white" @click="crop()">{{
        $t("generics.save")
      }}</v-btn>
    </FooterModal>
  </v-dialog>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import HeaderModal from "./modalComponents/headerModal.vue";
import FooterModal from "./modalComponents/footerModal.vue";
import {
  uploadAvatarModalStore,
  resetUploadAvatarModalEvent,
} from "../../effector/modals";
export default {
  components: { HeaderModal, FooterModal },
  data(){
    const effector = {
      uploadAvatarModalData: uploadAvatarModalStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      zoomValue: 0,
      tmpAvatar: effector.uploadAvatarModalData?.imageUrl,
      // Effector
      ...effector,
    }
  },
  methods: {
    closeCroppie(){
      this.closeModal();
    },
    onChange(result) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const { coordinates, imageSize } = cropper;
        if (
          imageSize.width / imageSize.height >
          coordinates.width / coordinates.height
        ) {
          // Determine the position of slider bullet
          // It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
          this.zoom =
            (cropper.imageSize.height - cropper.coordinates.height) /
            (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
        } else {
          this.zoom =
            (cropper.imageSize.width - cropper.coordinates.width) /
            (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
        }
      }
    },
    // Croppie setup methods
    defaultSize({ imageSize }) {
      return {
        width: Math.min(imageSize.height, imageSize.width),
        height: Math.min(imageSize.height, imageSize.width),
      };
    },
    onZoom(result) {
      const value = result / 100
      const cropper = this.$refs.cropper;
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight;
          const imageHeight = cropper.imageSize.height;
          // Determine the current absolute zoom and the new absolute zoom
          // to calculate the sought relative zoom value
          cropper.zoom(
            (imageHeight - this.zoom * (imageHeight - minHeight)) /
              (imageHeight - value * (imageHeight - minHeight))
          );
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth;
          const imageWidth = cropper.imageSize.width;
          cropper.zoom(
            (imageWidth - this.zoom * (imageWidth - minWidth)) /
              (imageWidth - value * (imageWidth - minWidth))
          );
        }
      }
    },
    stencilSize({ boundaries }) {
      return {
        width: Math.min(boundaries.height, boundaries.width) - 10,
        height: Math.min(boundaries.height, boundaries.width) - 10,
      };
    },
    closeModal() {
      resetUploadAvatarModalEvent();
    },
    async crop() {
      const canvas = this.$refs.cropper.getResult().canvas;
      if (canvas) {
        const base64Image = canvas.toDataURL('image/jpeg');
        const resizedImage = await this.resizeImage(base64Image, 256);
        // Do something with the base64 image
        store.setAvatar(resizedImage, this.uploadAvatarModalData.userId);
      }
      this.closeModal();
    },
    resizeImage(imagePath, newWidth, newHeight) {
      const originalImage = new Image();
      originalImage.src = imagePath;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      return new Promise((resolve, reject) => {
        originalImage.addEventListener('error', (err) => reject(err));
        originalImage.addEventListener('load', () => {
          const originalWidth = originalImage.naturalWidth;
          const originalHeight = originalImage.naturalHeight;
          const aspectRatio = originalWidth/originalHeight;
          if (newHeight === undefined) {
            newHeight = newWidth/aspectRatio;
          }
          canvas.width = newWidth;
          canvas.height = newHeight;
          ctx.drawImage(originalImage, 0, 0, newWidth, newHeight);
          const dataUrl = canvas.toDataURL("image/jpeg", 0.9);
          return resolve(dataUrl);
        });
      });
    },
    rotate(rotationAngle) {
      this.$refs.cropper.rotate(rotationAngle);
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.uploadAvatarModalData.imageUrl) {
        this.tmpAvatar = this.uploadAvatarModalData.imageUrl;
      }
    }, 400);
  },
};
</script>

<style scoped lang="scss">

.croppie-container {
  height: unset;
}
.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  border: 2px solid #2a3133 !important;
}
.rotateIcons {
  position: absolute;
  right: 10%;
  top: 25%;
  button:nth-of-type(2) {
  margin-top: 10px;
  }
}
.twitter-cropper {
  height: 256px;
  width: 256px;
  &__background {
    background-color: #edf2f4;
  }
  &__foreground {
    background-color: #edf2f4;
  }
  &__stencil {
    border: solid 5px rgb(29, 161, 242);
  }
}
.croppie-container {
  height: unset;
}
.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  border: 2px solid #2a3133 !important;
}
</style>
<style lang="scss">
.uploadAvatarCropper{
  .v-overlay__content{
    background: #fff;
  }
}

.v-theme--dark.uploadAvatarCropper{ 
  .v-overlay__content{
    color: #fff !important;
    background: #1e1e1e !important;
  }
}

.sliderSize .cr-slider-wrap {
  width: 100%;
  margin: 15px auto;
}
.sliderSize .cr-slider {
  width: 256px;
}
</style>