<template>
  <div class="width100 mt-4">
    <div v-if="entry" class="width100">
      <GeneralView :entry="entry" />
      <div class="px-2" v-if="entry.users && entry.users.length > 0">
        <div style="font-weight: bold">
          {{ $t("components.eventEntryForm.participants") }}
        </div>
        <v-row class="mx-0">
          <v-col
            cols="3"
            class="px-1 py-1"
            v-for="userUUID in entry.users"
            :key="userUUID"
          >
            <UserDisplay
              v-if="userUUID !== entry.creatorUUID"
              :userUUID="userUUID"
              :userHasRejected="userHasRejectedEvent(userUUID)"
            />
            <RsvpView v-if="isRsvp" :userUUID="userUUID" :entry="entry" />
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else>No Entry</div>
  </div>
</template>

<script>
import store from "../../store";
import GeneralView from "./eventViews/generalView.vue";
import OneCallView from "./eventViews/oneCallView.vue";
import RsvpView from "./eventViews/rsvpView.vue";
import EventParticipants from "./eventViews/eventParticipants.vue";
import UserDisplay from "../userDisplay/userDisplay.vue";

export default {
  props: ["entry"],
  components: {
    OneCallView,
    RsvpView,
    GeneralView,
    EventParticipants,
    UserDisplay,
  },
  data() {
    return { state: store.state };
  },
  computed: {
    isRsvp() {
      if (!this.entry) return false;
      return typeof this.entry.rsvp === "object";
    },
  },
  methods: {
    userHasRejectedEvent(userUUID) {
      if (!this.isRsvp || this.entry.creatorUUID !== this.state.ownUUID) {
        return false;
      } else {
        if (!this.entry.rsvp[userUUID]) return false;
        if (this.entry.rsvp[userUUID].status === "rejected") return true;
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.width100 {
  width: 100%;
}
</style>