// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vCardSearch[data-v-57bbf025] {
  display: flex;
  background: transparent !important;
}
.vCardSearch .closeButtonSearch[data-v-57bbf025] {
  position: relative;
  top: 10px;
  right: -7px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/drawerContentSearch.vue","webpack://./drawerContentSearch.vue"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,kCAAA;ACAF;ADCE;EACE,kBAAA;EACA,SAAA;EACA,WAAA;ACCJ","sourcesContent":["\n.vCardSearch {\n  display: flex;\n  background: transparent !important;\n  & .closeButtonSearch {\n    position: relative;\n    top: 10px;\n    right: -7px;\n  }\n}\n",".vCardSearch {\n  display: flex;\n  background: transparent !important;\n}\n.vCardSearch .closeButtonSearch {\n  position: relative;\n  top: 10px;\n  right: -7px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
