<template>
  <InviteVisitorModal ref="inviteVisitorModal" :standalone="true" :showModal="true" :options="$route.query" />
</template>

<script>
import InviteVisitorModal from "../modal/inviteVisitorModal.vue";

export default {
  components: { InviteVisitorModal },
  mounted() {
    this.$refs.inviteVisitorModal.$refs.modalContent.style.height = `calc(100vh + 116px)`
  }
};
</script>

<style scoped lang="scss">
</style>