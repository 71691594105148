<template>
  <div class="localButtonsBar myLocal">
    <!-- left side bar -->
    <div class="leftLocalBar">
      <v-tooltip location="top">
        <template v-slot:activator="{ props }">
          <v-btn
            large
            icon
            color="white"
            class="darkGreyIcon pAbsoluteLeft"
            @click="openCloseChat"
            v-bind="props"
          >
            <font-awesome-icon
              :icon="['fal', 'comments']"
              :style="{ fontSize: '20px' }"
            />
            <v-badge
              v-if="hasUnreadChatMessages"
              right
              color="error"
              overlap
              offset-x="25"
              offset-y="3"
              class="docsBadgeDots"
              :content="totalUnreadChatMessages"
              :model-value="hasUnreadChatMessages"
            ></v-badge>
          </v-btn>
        </template>
        <span>{{ $t("components.callsContent.openGroupChat") }}</span>
      </v-tooltip>
    </div>
    <!-- end left side bar -->

    <!-- center side bar d -->
    <div class="rightLocalBar">
      <v-tooltip location="top" >
        <template #activator="{ props }">
          <v-btn
            large
            icon
            color="white"
            class="toggleMic darkGreyIcon"
            :class="{ 'mx-3': isMobile, 'mx-6': !isMobile }"
            v-bind="props"
            @click="
              toggleMic(false);
              preventMultiClick('mic');
            "
            :disabled="room && micMutedByAdmin.indexOf(room.myUserId()) !== -1 || loadingMic"
            :loading="loadingMic"
          >
            <font-awesome-icon
              v-if="room && !micMute && micMutedByAdmin.indexOf(room.myUserId()) == -1"
              :icon="['fal', 'microphone']"
              :style="{ fontSize: '20px' }"
            />
            <img
              v-if="room && micMute && micMutedByAdmin.indexOf(room.myUserId()) == -1"
              class="microphone-slash"
              src="/img/microphone-slash-red-white.svg"
            />
            <img
              v-if="room && micMutedByAdmin.indexOf(room.myUserId()) !== -1"
              class="microphone-slash"
              src="/img/microphone-slash-red-grey.svg"
            />
            <template v-slot:loader>
              <span class="custom-loader">
                <font-awesome-icon
                  :icon="['fal', 'spinner-third']"
                  :style="{ fontSize: '15px', color: 'white' }"
                />
              </span>
            </template>
            <div
              id="myAudioBar"
              class="progressBarBridge"
              v-show="room && !micMute && micMutedByAdmin.indexOf(room.myUserId()) == -1"
            >
              <div class="progressBarLevelBridge"></div>
            </div>
            <!-- <v-progress-linear
            v-if="!micMute && !mutedByModerator"
            class="progressBarBridge"
            :value="getMicrophoneLevel"
          ></v-progress-linear> -->
          </v-btn>
        </template>
        <span>{{
          room && !micMute && micMutedByAdmin.indexOf(room.myUserId()) == -1
            ? $t("components.callsContent.micOff")
            : $t("components.callsContent.micOn")
        }}</span>
      </v-tooltip>

      <v-tooltip location="top" >
        <!-- :disabled="!videoDeviceId" -->
        <template #activator="{ props }">
          <v-btn
            large
            icon
            :disabled="!hasVideoDevice || cameraOffByModerator || loadingVideo"
            class="toggleVideo darkGreyIcon"
            :class="{ 'mx-3': isMobile, 'mx-6': !isMobile }"
            @click="
              toggleVideo();
              preventMultiClick('video');
            "
            v-bind="props"
            color="white"
            :loading="loadingVideo"
          >
            <!-- <img
            v-if="!videoDisabled"
            class="video-image"
            src="/img/camera_black.svg"
          /> - -->
            <img
              v-if="!hasVideoDevice || cameraOffByModerator"
              class="video-slash"
              src="/img/video-slash-red-gray.svg"
            />
            <font-awesome-icon
              v-else-if="!videoDisabled"
              :icon="['fal', 'video']"
              :style="{ fontSize: '20px' }"
            />
            <img
              v-else
              class="video-slash"
              src="/img/video-slash-red-white.svg"
            />
            <!-- :src="
                !theme.global.current.dark
                  ? '/img/video-slash-red.svg'
                  : '/img/video-slash-red-white.svg'
              " -->
            <template v-slot:loader>
              <span class="custom-loader">
                <font-awesome-icon
                  :icon="['fal', 'spinner-third']"
                  :style="{ fontSize: '15px', color: 'white' }"
                />
              </span>
            </template>
          </v-btn>
        </template>
        <span>{{
          videoDisabled
            ? $t("components.callsContent.cameraOn")
            : $t("components.callsContent.cameraOff")
        }}</span>
      </v-tooltip>

      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-btn
            large
            icon
            color="white"
            class="toggleMuteAudio darkGreyIcon"
            :class="{ 'mx-3': isMobile, 'mx-6': !isMobile }"
            v-bind="props"
            @click="toggleMuteAudioFunction()"
          >
            <font-awesome-icon
              v-if="!audioOutputMuted"
              :icon="['fal', 'volume-up']"
              :style="{ fontSize: '20px' }"
            />
            <!-- <font-awesome-icon
            v-else
            :icon="['fal', 'volume-slash']"
            :style="{ fontSize: '20px' }"
          /> -->
            <img
              v-else
              class="volume-up-slash"
              src="/img/volume-slash-red-white.svg"
            />
            <!-- :src="
                !theme.global.current.dark
                  ? '/img/volume-slash-red.svg'
                  : '/img/volume-slash-red-white.svg'
              " -->
          </v-btn>
        </template>
        <span>{{
          audioOutputMuted
            ? $t("components.callsContent.unmuteSoundAllParticipants")
            : $t("components.callsContent.muteAllParticipants")
        }}</span>
      </v-tooltip>

      <!-- Presenter mode -->
      <v-tooltip
        top
        v-if="
          !isMobile &&
          !getIsMobile &&
          !getIsConferenceHall &&
          !currentDesktop &&
          ((room && presenterModeInitiatedBy == room.myUserId()) ||
            (room && !presenterModeInitiatedBy) ||
            getAmIModerator)
        "
      >
        <template v-slot:activator="{ props }">
          <v-btn
            large
            :disabled="getTotalRemoteParticipants < 1"
            @click="
              togglePresentation();
              speakerView ? changeSpeakerView(!speakerView) : null;
            "
            v-bind="props"
            color="white"
            icon
            :class="{ 'mx-3': isMobile, 'mx-6': !isMobile }"
            class="darkGreyIcon"
          >
            <img
              v-if="getTotalRemoteParticipants < 1"
              class="volume-up-slash"
              src="/img/icons/voffice_viewB_black-grey.svg"
            />
            <img
              v-else-if="
                presenterModeInitiatedBy &&
                presenterModeInitiatedBy == room.myUserId()
              "
              class="volume-up-slash"
              src="/img/icons/voffice_viewB-redslash-white.svg"
            />
            <img
              v-else
              class="volume-up-slash"
              src="/img/icons/voffice_viewB_black-white.svg"
            />
          </v-btn>
        </template>
        <span>{{
          presenterModeInitiatedBy &&
          presenterModeInitiatedBy == room.myUserId()
            ? $t("components.bridgeCallTooltips.endPresentation")
            : $t("components.bridgeCallTooltips.presentation")
        }}</span>
      </v-tooltip>
      <!-- Menu shareScreen -->
      <v-tooltip
        :disabled="currentDesktop && currentDesktop == getMyDesktopRoomUserID"
        top
        v-if="!isMobile && !getIsMobile"
      >
        <template v-slot:activator="{ props }">
          <v-menu
            class="customMenuLocal"
            :model-value="menuShareScreenOpen" @update:model-value="menuShareScreenOpen = $event"
            :disabled="!currentDesktop"
            right
            top
            :nudge-top="10"
            :nudge-left="25"
            :offset-y="true"
            :offset-x="true"
            :close-on-content-click="true"
            z-index="999999"
            :attach="!isMobile ? '.myLocal' : false"
          >
            <template v-slot:activator="{ props }">
              <v-btn
                large
                :disabled="
                  currentDesktop && currentDesktop !== getMyDesktopRoomUserID
                "
                @click.stop.prevent="
                  !currentDesktop ? toggleShareScreen() : null;
                  speakerView ? changeSpeakerView(!speakerView) : null;
                "
                v-bind="props"
                color="white"
                icon
                :class="{ 'mx-3': isMobile, 'mx-6': !isMobile }"
                class="darkGreyIcon"
              >
                <img
                  v-if="
                    currentDesktop && currentDesktop == getMyDesktopRoomUserID
                  "
                  class="volume-up-slash"
                  src="/img/desktop-slash-red-white.svg"
                />
                <font-awesome-icon
                  :icon="['fal', 'desktop']"
                  :style="{ fontSize: '20px' }"
                  v-else
                />
              </v-btn>
            </template>
            <v-list class="text-left" dark>
              <v-list-item
                @click="stopShareScreen()"
                v-if="
                  currentDesktop && currentDesktop == getMyDesktopRoomUserID
                "
              >
                <v-icon>
                  <img
                    class="volume-up-slash"
                    src="/img/desktop-slash-red-white.svg"
                  />
                </v-icon>
                <v-list-item-title>
                  {{ $t("components.callsContent.endShareScreen") }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="stopStartShareScreen()"
                v-if="
                  currentDesktop &&
                  currentDesktop == getMyDesktopRoomUserID &&
                  getTotalRemoteParticipants >= 1
                "
              >
                <v-icon>
                  <font-awesome-icon
                    :icon="['fal', 'desktop']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-icon>
                <v-list-item-title>
                  {{ $t("components.callsContent.changeShareScreen") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- END Sharescreen Menu -->
          <!-- <v-btn
            large
            :disabled="
              currentDesktop && currentDesktop !== getMyDesktopRoomUserID
            "
            @click.stop.prevent="
              toggleShareScreen();
              speakerView ? changeSpeakerView(!speakerView) : null;
            "
            v-on="on"
            color="white"
            icon
            :class="{ 'mx-3': isMobile, 'mx-6': !isMobile }"
            class="darkGreyIcon"
          >
            <img
              v-if="currentDesktop && currentDesktop == getMyDesktopRoomUserID"
              class="volume-up-slash"
              src="/img/desktop-slash-red-white.svg"
            />
            <font-awesome-icon
              :icon="['fal', 'desktop']"
              :style="{ fontSize: '20px' }"
              v-else
            />
          </v-btn> -->
        </template>
        <span>
          {{ $t("components.callsContent.shareScreen") }}
        </span>
        <!-- <span>{{
          currentDesktop && currentDesktop == getMyDesktopRoomUserID
            ? $t("components.callsContent.endShareScreen")
            : $t("components.callsContent.shareScreen")
        }}</span> -->
      </v-tooltip>
      <v-tooltip location="top" v-if="!isMobile">
        <template #activator="{ props }">
          <v-btn
            large
            icon
            color="white"
            v-bind="props"
            @click="toggleLocalFullScreen()"
            class="darkGreyIcon"
            :class="{ 'mx-3': isMobile, 'mx-6': !isMobile }"
          >
            <font-awesome-icon
              :icon="['fal', 'expand-wide']"
              :style="{ fontSize: '20px' }"
              v-if="!localFullScreen"
            />
            <font-awesome-icon
              v-else
              :icon="['fal', 'compress-wide']"
              :style="{ fontSize: '20px' }"
            />
          </v-btn>
        </template>
        <span
          >{{
            !localFullScreen
              ? $t("generics.fullscreen")
              : $t("generics.normalScreen")
          }}
        </span>
      </v-tooltip>
      <!-- <v-tooltip location="top" v-if="!isMobile && getIsConferenceHall && !getIsParticipantIsPodium(state.ownUUID) && !isListenerSpeakerConferenceHall">
        <template #activator="{ on }">
          <v-btn
            large
            icon
            color="white"
            v-on="on"
            @click="toggleRequestToSpeakConferenceHall(room.myUserId())"
            class="darkGreyIcon"
            :class="{ 'mx-3': isMobile, 'mx-6': !isMobile }"
          >
            <font-awesome-icon
              :icon="['fal', 'hand-point-up']"
              :style="{ fontSize: '20px' }"
              v-if="!getAmIRequestingWord"
            />
             <font-awesome-icon
              :icon="['fas', 'hand-point-up']"
              :style="{ fontSize: '20px' }"
              v-else
            />

          </v-btn>
        </template>
        <span
          > {{
              !getAmIRequestingWord
                ? $t("components.callsContent.requestWord")
                : $t("components.callsContent.endRequestWord")
            }}
        </span>
      </v-tooltip> -->

      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-btn
            large
            icon
            color="white"
            v-bind="props"
            :loading="rejectingCall"
            :disabled="rejectingCall"
            @click="rejectCallFromLocal()"
            class="bg-red"
            :class="{ 'mx-3': isMobile, 'mx-6': !isMobile }"
          >
            <font-awesome-icon
              :icon="['fal', 'phone']"
              class="faPhoneRotate"
              :style="{ fontSize: '20px' }"
            />
            <template v-slot:loader>
              <span class="custom-loader">
                <font-awesome-icon
                  :icon="['fal', 'spinner-third']"
                  :style="{ fontSize: '15px', color: 'white' }"
                />
              </span>
            </template>
          </v-btn>
        </template>
        <span>{{ $t("generics.hangUp") }}</span>
      </v-tooltip>

      <!--
    <v-tooltip location="top" v-if="isMobile">
      <template #activator="{ on }">
        <v-btn icon color="white" v-on="on" @click="toggleRotateCamera()">
          <img
            class="rotateCameraButton"
            src="/img/rotateCamera.svg"
          />
        </v-btn>
      </template>
      <span></span>
    </v-tooltip> -->

      <!--  3 POINT MENU -->
      <div class="pAbsoluteRight">
        <v-menu
          class="customMenuLocal"
          content-class="customDotsMenuLocal"
          :model-value="menuOpen" @update:model-value="menuOpen = $event"
          right
          :offset-x="true"
          :close-on-content-click="true"
          z-index="999999"
          :attach="!isMobile ? '.myLocal' : false"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              large
              class="buttonsCall darkGreyIcon"
              :class="{ 'mx-6': !isMobile }"
              color="white"
              icon
              @click.stop.prevent
              v-bind="props"
            >
              <font-awesome-icon
                :icon="['fal', 'ellipsis-v']"
                :style="{ fontSize: '20px' }"
              />
              <v-badge
                v-if="hasDocuments"
                right
                color="error"
                overlap
                offset-x="25"
                offset-y="3"
                class="docsBadgeDots"
                :content="getTotalDocumentsRead"
                :model-value="getTotalDocumentsRead"
              ></v-badge>
            </v-btn>
          </template>
          <v-list class="text-left" dark>
            <!-- <v-list-item @click="toggleLocalFullScreen()" v-if="!isMobile">
              <v-icon>
                <font-awesome-icon
                  :icon="['fal', 'expand-wide']"
                  :style="{ fontSize: '20px' }"
                  v-if="!localFullScreen"
                />
                <font-awesome-icon
                  v-else
                  :icon="['fal', 'compress-wide']"
                  :style="{ fontSize: '20px' }"
                />
              </v-icon>
              <v-list-item-title>
                {{
                  !localFullScreen
                    ? $t("generics.fullscreen")
                    : $t("generics.normalScreen")
                }}
              </v-list-item-title>
            </v-list-item> -->

            <!-- <v-list-item
              @click="toggleMultiMessage()"
              v-if="getAmIModerator && !fullScreenActive"
            >
              <v-icon>
                <font-awesome-icon
                  :icon="['fal', 'paper-plane']"
                  :style="{ fontSize: '20px' }"
                />
              </v-icon>
              <v-list-item-title>
                {{ $t("components.bridgeCallTooltips.messageAll") }}
              </v-list-item-title>
            </v-list-item> -->

            <v-list-item
              @click="toggleAllRemoteMic"
              v-if="getAmIModerator && getTotalRemoteParticipants > 1 && !allMods && !getIsConferenceHall"
            >
              <!-- <v-icon v-if="!theme.global.current.dark">
                <img
                  v-if="!allRemoteMicMuted"
                  class="muteAllButon"
                  src="/img/icons/microphone_all_muted.svg"
                />d
                <img
                  v-else
                  class="muteAllButon"
                  src="/img/icons/microphone_all_muted-slash-red.svg"
                />
              </v-icon> -->

              <v-icon>
                <img
                  v-if="!allRemoteMicMuted"
                  class="muteAllButon"
                  src="/img/icons/microphone_all_muted-white.svg"
                />
                <img
                  v-else
                  class="muteAllButon"
                  src="/img/icons/microphone_all_muted-slash-red-white.svg"
                />
              </v-icon>
              <v-list-item-title>
                {{
                  allRemoteMicMuted
                    ? $t("components.callsContent.unmuteAllParticipants")
                    : $t("components.callsContent.muteAllTT")
                }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              @click.stop.prevent
              v-if="
                room &&
                getAmIModerator &&
                presenterModeInitiatedBy &&
                !currentDesktop &&
                !getIsConferenceHall
              "
            >
              <v-list-item-title class="d-flex">
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <div class="d-flex ml-2 switchContainer" v-on="props">
                      <v-switch
                        @click.stop.prevent="changeSpeakerView(!speakerView)"
                         :model-value="speakerView"
    @update:model-value="speakerView= $event"
                        hide-details
                        class="mt-0"
                        width="100%"
                      ></v-switch>
                      <!-- <img
                        v-if="!theme.global.current.dark && speakerView"
                        class="imgButton"
                        src="/img/icons/account-tie-voice-outline.svg"
                      /> -->
                      <img
                        v-if="speakerView"
                        class="imgButton"
                        src="/img/icons/account-tie-voice-outline-white.svg"
                      />
                      <!-- <img
                        v-if="!theme.global.current.dark && !speakerView"
                        class="imgButton"
                        src="/img/icons/account-tie-voice-outline-slash.svg"
                      /> -->
                      <img
                        v-if="!speakerView"
                        class="imgButton"
                        src="/img/icons/account-tie-voice-outline-white-slash.svg"
                      />
                      <span class="pt-1 pl-2">{{
                        $t("components.callsContent.speakerView")
                      }}</span>
                    </div>
                  </template>
                  <span>{{
                    speakerView
                      ? $t("components.callsContent.presentationView")
                      : $t("components.callsContent.speakerViewTT")
                  }}</span>
                </v-tooltip>
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="toggleGiveWord(room.myUserId())"
              v-if="
                (room &&
                getAmIModerator &&
                currentDesktop &&
                currentDesktop !== getMyDesktopRoomUserID)
              "
            >
                <!-- (room &&
                currentDesktop &&
                currentDesktop !== getMyDesktopRoomUserID && getIsConferenceHall && getIsParticipantIsPodium(state.ownUUID)) -->
              <!-- <v-icon v-if="!theme.global.current.dark">
                <img
                  class="imgButton"
                  src="/img/icons/voffice_viewB_black.svg"
                />
              </v-icon> -->
              <!-- TODO: fixme -->
              <v-icon>
                <img
                  class="imgButton"
                  src="/img/icons/voffice_viewB_black-white.svg"
                />
              </v-icon>
              <v-list-item-title>
                {{ $t("components.callsContent.endShareScreen") }}
              </v-list-item-title>
            </v-list-item>

            <!--
           v-if="
              room &&
              ((presenterModeInitiatedBy &&
                presenterModeInitiatedBy !== room.myUserId() &&
                !getAmIModerator) ||
                (!getAmIModerator &&
                  currentDesktop &&
                  currentDesktop !== getMyDesktopRoomUserID))
            "
            -->
            <v-list-item
              @click="toggleWaveHand"
              v-if="
                room &&
                presenterModeInitiatedBy &&
                presenterModeInitiatedBy !== room.myUserId() &&
                !getAmIModerator &&
                !currentDesktop && !getIsConferenceHall
              "
            >
              <v-icon>
                <font-awesome-icon
                  :icon="['fal', 'hand-point-up']"
                  v-if="wavingHand.indexOf(room.myUserId()) == -1"
                  :style="{ fontSize: '20px' }"
                />
                <font-awesome-icon
                  :icon="['fas', 'hand-point-up']"
                  v-else
                  :style="{ fontSize: '20px' }"
                />
              </v-icon>
              <v-list-item-title>
                {{
                  wavingHand.indexOf(room.myUserId()) == -1
                    ? $t("components.callsContent.requestWord")
                    : $t("components.callsContent.endRequestWord")
                }}
              </v-list-item-title>
            </v-list-item>

            <!--  v-if="
              room &&
              getAmIModerator &&
              implicitPresenterMode &&
              presenterModeInitiatedBy &&
              presenterModeInitiatedBy !== room.myUserId()
            " -->
            <v-list-item
              @click="
                toggleGiveWord(room.myUserId());
                speakerView ? changeSpeakerView(!speakerView) : null;
              "
              v-if="
              getAmIModerator &&
                ((room &&
                !currentDesktop &&
                !implicitPresenterMode &&
                presenterModeInitiatedBy &&
                presenterModeInitiatedBy !== room.myUserId()) ||
                 (room &&
                !currentDesktop &&
                !implicitPresenterMode &&
                presenterModeInitiatedBy &&
                presenterModeInitiatedBy !== room.myUserId() && getIsConferenceHall && getIsParticipantIsPodium(state.ownUUID)))
              "
            >
              <v-icon>
                <font-awesome-icon
                  :icon="['fal', 'comment']"
                  :style="{ fontSize: '20px' }"
                />
              </v-icon>
              <v-list-item-title>
                {{ $t("components.callsContent.giveWord") }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item @click="toggleCamMic" v-if="!fullScreenActive">
              <v-icon>
                <font-awesome-icon
                  :icon="['fal', 'cog']"
                  :style="{ fontSize: '20px' }"
                />
              </v-icon>
              <v-list-item-title>
                {{ $t("components.navbar.camAndMic") }}
              </v-list-item-title>
            </v-list-item>

            <!-- turnoff camera all user -->
            <v-list-item
              @click="toggleAllCameraOff"
              v-if="room && getAmIModerator && !allMods && !getIsConferenceHall"
            >
              <!-- && getTotalRemoteParticipants > 1 -->
              <v-icon>
                <img
                  v-if="allRemoteCameraOff"
                  class="imgButton"
                  src="/img/users-camera-slash-red-white.svg"
                />
                <img
                  v-else
                  class="imgButton"
                  src="/img/users-camera-white.svg"
                />
              </v-icon>
              <v-list-item-title>
                {{
                  !allRemoteCameraOff
                    ? $t("components.callsContent.cameraOffAllParticipants")
                    : $t("components.callsContent.stopCameraOffAllParticipants")
                }}
              </v-list-item-title>
            </v-list-item>
            <!-- end turnoff camera all user -->
            <!-- //documents -->
              <!-- v-if="!getIsConferenceHall" -->
            <v-list-item
              :disabled="!getAmIModerator && !hasDocuments"
              @click="openConferenceDocumentsModal()"
            >
              <v-icon>
                <font-awesome-icon
                  :icon="['fal', 'file-upload']"
                  :style="{ fontSize: '20px' }"
                />
                <v-badge
                  v-if="hasDocuments"
                  right
                  color="error"
                  overlap
                  offset-x="25"
                  offset-y="3"
                  class="docsBadge"
                  :content="getTotalDocumentsRead"
                  :model-value="getTotalDocumentsRead"
                ></v-badge>
              </v-icon>
              <v-list-item-title>
                {{ $t("components.callsContent.documentManagementShare") }}
              </v-list-item-title>
            </v-list-item>

            <!-- manage conference -->
                <!-- !getIsConferenceHall && -->
            <v-list-item
              @click="openManageConference"
              v-if="
              !getIsConferenceHall &&
                getDataConference &&
                (getDataConference.creatorUUID === state.ownUUID ||
                  getDataConference.confOwner === state.ownUUID ||
                  getAmIModerator ||
                  (getDataConference.confModerators &&
                    getDataConference.confModerators.indexOf(state.ownUUID) !==
                      -1))
              "
            >
              <v-icon>
                <font-awesome-icon
                  :icon="['fal', 'users']"
                  :style="{ fontSize: '20px' }"
                />
              </v-icon>
              <v-list-item-title>
                {{ $t("components.callsContent.manageConference") }}
              </v-list-item-title>
            </v-list-item>

            <!-- end conference -->
            <v-list-item
              @click="endConference()"
              v-if="
                getDataConference &&
                (getDataConference.creatorUUID === state.ownUUID ||
                  getDataConference.confOwner === state.ownUUID || (!getIsConferenceHall &&
                  getAmIModerator))
              "
            >
              <v-icon>
                <font-awesome-icon
                  :icon="['fal', 'door-open']"
                  :style="{ fontSize: '20px' }"
                />
              </v-icon>
              <v-list-item-title>
                {{ !getIsConferenceHall ? $t("components.conferenceForm.endConference") : $t("components.conferenceForm.endConferenceHall") }}
              </v-list-item-title>
            </v-list-item>

            <!-- request to speak conference hall -->
            <!-- <v-list-item
              @click="toggleRequestToSpeakConferenceHall(room.myUserId())"
              v-if="getIsConferenceHall && !getIsParticipantIsPodium(state.ownUUID) && !isListenerSpeakerConferenceHall"
            >
              <v-icon>
                <font-awesome-icon
                  :icon="['fal', 'hand-point-up']"
                  :style="{ fontSize: '20px' }"
                />
              </v-icon>
              <v-list-item-title>
                {{
                  !getAmIRequestingWord
                    ? $t("components.callsContent.requestWord")
                    : $t("components.callsContent.endRequestWord")
                }}
              </v-list-item-title>
            </v-list-item> -->
            <!-- end request to speak conference hall -->

            <!-- support log button -->
            <v-list-item
              @click="downloadSupportLog()"
              v-if="showDownloadSupportLog"
            >
              <v-icon>
                <font-awesome-icon
                  :icon="['fal', 'notes-medical']"
                  :style="{ fontSize: '20px' }"
                />
              </v-icon>
              <v-list-item-title>
                {{ $t("components.callsContent.downloadSupportLog") }}
              </v-list-item-title>
            </v-list-item>
            <!-- end support log button -->

            <!-- Export chat from conferences -->
            <v-list-item
              @click="downloadChat()"
              v-if="showDownloadChat"
            >
              <v-icon>
                <font-awesome-icon
                  :icon="['fal', 'comments']"
                  :style="{ fontSize: '20px' }"
                />
              </v-icon>
              <v-list-item-title>
                {{ $t("components.callsContent.downloadChat") }}
              </v-list-item-title>
            </v-list-item>
            <!-- end Export chat from conferences -->

            <!-- speaker in the middle -->
            <!-- <v-list-item
            @click="changeSpeakerView(!speakerView)"
            v-if="
              room &&
              getAmIModerator &&
              presenterModeInitiatedBy &&
              !currentDesktop
            "
          >
            <v-icon v-if="!theme.global.current.dark && speakerView">
              <img class="imgButton" src="/img/icons/account-tie-voice-outline.svg" />
            </v-icon>
             <v-icon v-if="theme.global.current.dark && speakerView">
              <img class="imgButton" src="/img/icons/account-tie-voice-outline-white.svg" />
            </v-icon>
            <v-icon v-if="!theme.global.current.dark && !speakerView">
              <img class="imgButton" src="/img/icons/account-tie-voice-outline-slash.svg" />
            </v-icon>
            <v-icon v-if="theme.global.current.dark && !speakerView">
              <img class="imgButton" src="/img/icons/account-tie-voice-outline-white-slash.svg" />
            </v-icon>
            <v-list-item-title>
              {{speakerView ? $t("components.callsContent.presentationView") :  $t("components.callsContent.speakerView")}}
            </v-list-item-title>
          </v-list-item> -->
          </v-list>
        </v-menu>
        <!-- END 3 POINT MENU -->
      </div>
      <!-- end center side bar -->

      <DocumentsManager
        v-if="showConferenceDocumentsModal"
        :showModal="showConferenceDocumentsModal"
        :eventType="isConferenceCall ? 'conference' : 'uuid'"
        :documentsStore="isConferenceCall ? conferenceStore : callStore"
        :amIModerator="getAmIModerator"
        :attachTo="'videos'"
        :setTempDocStore="setTempDocStore"
        :isConferenceCall="isConferenceCall ? isConferenceCall : false"
      />
      <ManageConference
        v-if="showManageConference"
        :isConferenceCall="isConferenceCall"
        :showModal="showManageConference"
        :closeModal="closeManageConference"
        :attachTo="'videos'"
        :callUUID="this.getCallUUID"
        :checkUserInCall="checkUserInCall"
        :kickUser="kickUser"
        :getIsConferenceHall="getIsConferenceHall"
      />
    </div>
  </div>
</template>
<script>
import { useStore } from "effector-vue/composition";
import store, { EventBus } from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
// import { webLicensedBaseFeatures } from '../../../../sharedsrc/licensedFeatures';
import {
  // getDirectCallInviteLink,
  getCompanyDocumentsForCall,
  sendInviteMailCustom,
} from "../../../lib/wsMsg";
// import ConferenceInfo from "./conferenceInfo.vue";
// import CallInfo from "./callInfo/callInfo.vue";
import { bridgeInfoStore } from "../../../effector/users/bridgeInfo";
import {
  // myCompanyDocumentsDetails,
  newCompanyDocumentEvent,
  updateCompanyDocumentEvent,
} from "../../../effector/companyDocuments";
import {
  setConferenceDocumentsEvent,
  conferenceDocumentsStore,
} from "../../../effector/modals";
import { v4 as uuidv4 } from "uuid";
import DocumentsManager from "../../documents/documentsMgr.vue";
import {
  myConferenceDetails,
  // dispatchDeleteGroupConferenceEvent,
  // updateGroupConferenceEvent,
} from "../../../effector/groupConferences";
import {
  myConferenceHallDetails,
  // dispatchDeleteGroupConferenceEvent,
  // updateGroupConferenceEvent,
} from "../../../effector/conferenceHalls";
import ManageConference from "../../conferenceLeftBar/manageConference.vue";
// import { callPerson } from "../../../utils/calls";
// import { wsCall } from "../../../lib/wsConnect";
import {
  // fetchEventByUUIDEffect,
  timelineEvents,
  // deleteTimelineItemEvent,
} from "../../../effector/timeline";
import { parallel } from "../../../lib/asyncUtil";
import LocalLeftBar from "./LocalBar/localLeftBar.vue";

export default {
  components: { DocumentsManager, ManageConference, LocalLeftBar },
  props: [
    "downloadSupportLog",
    "downloadChat",
    "rejectCall",
    "rejectingCall",
    "toggleVideo",
    "videoDisabled",
    "toggleMic",
    "micMute",
    "getMicrophoneLevel",
    "toggleMuteAudio",
    "audioOutputMuted",
    "toggleLocalFullScreen",
    "localFullScreen",
    // "toggleMultiMessage",
    "getAmIModerator",
    "toggleAllRemoteMic",
    "allRemoteMicMuted",
    "toggleShareScreen",
    "currentDesktop",
    "getMyDesktopRoomUserID",
    "togglePresentation",
    "presenterModeInitiatedBy",
    "room",
    "toggleGiveWord",
    "toggleWaveHand",
    "wavingHand",
    "getTotalRemoteParticipants",
    "stopShareScreen",
    "implicitPresenterMode",
    "isConferenceCall",
    "hangUpBridgeCall",
    "getCallUUID",
    "getconfId",
    "presenterMode",
    "wordGivenTo",
    "fullScreenActive",
    "changeMenuIsOpen",
    "isUserInCall",
    "kickUser",
    "getIsMobile",
    "toggleRotateCamera",
    "changeSpeakerView",
    "speakerView",
    "getActiveUsers",
    "myDocuments",
    "videoDeviceId",
    "toggleChat",
    "getTotalReceivedChatMessages",
    "chatOpen",
    "toggleAllCameraOff",
    "allRemoteCameraOff",
    "cameraOffByModerator",
    "allMods",
    "getIsParticipantIsPodium",
    "getIsConferenceHall",
    // "toggleRequestToSpeakConferenceHall",
    // "isListenerSpeakerConferenceHall",
    // "requestToSpeakConferenceHall",
    "chatMessageNotificationSound",
    "micMutedByAdmin"
  ],
  data() {
    const effector = {
      bridgeInfoStore: bridgeInfoStore,
      showConferenceDocumentsModal: conferenceDocumentsStore,
      myConferenceDetails: myConferenceDetails,
      myConferenceHallDetails: myConferenceHallDetails,
      cacheTimeline: timelineEvents,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      showMenu: false,
      menuOpen: false,
      menuShareScreenOpen: false,
      isMobile: isMobile(),
      windowWidth: window.innerWidth,
      tempDocumentStore: null,
      showManageConference: false,
      totalDocumentsRead: 0,
      totalChatMessagesRead: 0,
      chatMounted: false,
      hasVideoDevice: false,
      runtimeStopStartShareScreenActive: false,
      loadingMic: false,
      loadingVideo: false,
      // Effector
      ...effector,
    };
  },
  watch: {
    // if the user has no the speak privilege, we disable the audio, video, and the option to mute the volume.
    // listenerButtonsEnabled: {
    //   immediate: true,
    //   deep: true,
    //   handler: function (value) {
    //     if(value !== true) {
    //       if (!this.videoDisabled) this.toggleVideo();
    //       if (!this.micMute) this.toggleMic();
    //       // if (this.audioOutputMuted) this.toggleMuteAudio(false);
    //     }
    //   },
    // },
    menuOpen: {
      // immediate: true,
      handler: function (value) {
        this.changeMenuIsOpen(value);
      },
    },
    docCount: {
      // immediate: true,
      handler: function (totalDocuments) {
        if (this.totalDocumentsRead > totalDocuments) {
          this.totalDocumentsRead = totalDocuments;
        }
      },
    },
    getTotalReceivedChatMessages: {
      // immediate: true,
      handler: function (received) {
        if (
          received &&
          received !== this.totalChatMessagesRead &&
          this.chatOpen
        ) {
          this.totalChatMessagesRead = this.getTotalReceivedChatMessages;
        }
        // fire sound notification
        if (
          received &&
          received !== this.totalChatMessagesRead &&
          !this.chatOpen &&
          this.chatMounted &&
          this.chatMessageNotificationSound
        ) {
          this.chatMessageNotification();
        }
      },
    },
    currentDesktop: {
      // immediate: true,
      handler: function (id) {
        if (!id && this.runtimeStopStartShareScreenActive) {
          this.runtimeStopStartShareScreenActive = false;
          this.toggleShareScreen();
        }
      },
    },
  },
  mounted() {
    this.getNavigatorDevices();
    // window.onresize = () => {
    //   this.windowWidth = window.innerWidth;
    // };
    window.addEventListener("resize", this.handleResize);
    // prevent sound in mounte
    setTimeout(() => {
      this.chatMounted = true;
    }, 10000);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    toggleMuteAudioFunction(){
      // if(this.getIsConferenceHall){
      //   if(this.getIsParticipantIsPodium(this.state.ownUUID)){
      //     this.toggleMuteAudio(true)
      //   }else{
      //     if(this.listenerButtonsEnabled){
      //       this.toggleMuteAudio(true) // muted audio and mic
      //     }else{
      //        this.toggleMuteAudio(false) // just muted audio
      //     }
      //   }
      // }else{
         this.toggleMuteAudio(true)
      // }
    },
    preventMultiClick(icon) {
      switch (icon) {
        case "video":
          this.loadingVideo = true;
          setTimeout(() => {
            this.loadingVideo = false;
          }, 1000);
          break;
        case "mic":
          this.loadingMic = true;
          setTimeout(() => {
            this.loadingMic = false;
          }, 500);
          break;
      }
    },
    rejectCallFromLocal() {
      this.rejectCall();
    },
    stopStartShareScreen() {
      if (
        this.currentDesktop &&
        this.currentDesktop == this.getMyDesktopRoomUserID
      ) {
        this.runtimeStopStartShareScreenActive = true;
        this.stopShareScreen();
      }
    },
    getNavigatorDevices() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        return;
      }
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            if (device.kind === "videoinput") {
              this.hasVideoDevice = true;
            }
          });
        })
        .catch((err) => {
          console.error(err.name + ": " + err.message);
        });
    },
    chatMessageNotification() {
      const audioPath = '/media/notificationSound.mp3';
      // Get real devices id's
      const ringingDeviceId = store.state.persisted.mediaDeviceSetup.ringingOutputId;
      const audioDeviceId = store.state.persisted.mediaDeviceSetup.audioOutputId;
      // Pre-Set outputs
      const audioOutput = new Audio(audioPath);
      const ringingOutput = new Audio(audioPath);
      // Sync audioDevice
      let promise = Promise.resolve();
      if ('sinkId' in audioOutput && 'setSinkId' in audioOutput && audioDeviceId) {
        promise = audioOutput.setSinkId(audioDeviceId);
      }
      promise
        .then(() => audioOutput.play())
        .catch(err => console.warn('calls(localButtonsBar) Failed to play notification audio on audioOutput', err));
      // Sync && Play at ringing device only if we have ringingDeviceId for it and if it's different from audioOutputId
      if (audioDeviceId && ringingDeviceId && 'sinkId' in ringingOutput && 'setSinkId' in ringingOutput && ringingDeviceId !== audioDeviceId) {
        promise = ringingOutput.setSinkId(ringingDeviceId);
        promise
          .then(() => ringingOutput.play())
          .catch(err => console.warn('calls(localButtonsBar) Failed to play notification audio on ringingOutput', err));
      }
    },
    openCloseChat() {
      if (
        !this.chatOpen &&
        this.totalChatMessagesRead !== this.getTotalReceivedChatMessages
      ) {
        this.totalChatMessagesRead = this.getTotalReceivedChatMessages;
      }
      this.toggleChat();
    },
    async endConference() {
      const extant = this.getDataConference;
      // const timelineEv = this.cacheTimeline.find(
      //   (e) => e.confId == this.getDataConference.confId
      // );
      const users = extant.confUUIDS || extant.members;
      if (extant) {
        this.hangUpBridgeCall();
        // ticket #3636 no delete on close conference
        // if (timelineEv && timelineEv.uuid) {
        //   await deleteTimelineItemEvent(timelineEv.uuid);
        // }
        // const confId = extant.confId;
        // if (confId) {
        //   await dispatchDeleteGroupConferenceEvent(confId);
        // }
      }
      const usersArray = [];
      if (users) {
        users.forEach((user) => {
          if (user.uuid) {
            usersArray.push(user.uuid);
          }
        });
      }
      this.handleNotifications(usersArray, extant.name || "");
    },
    handleNotifications(recipients, name) {
      this.users = [];
      this.visitors = [];
      this.users = recipients.filter(
        (e) => store.state.group[e].user.visitor == false
      );
      this.visitors = recipients.filter(
        (e) => store.state.group[e].user.visitor == true
      );
      // this.preparedataToSendMsgEffector();
      this.sendEndedConferenceMail();
    },
    async sendEndedConferenceMail() {
      let visitors = this.visitors;
      await parallel(1, [...visitors], async (uuid) => {
        if (store.state.ownUUID === uuid) return;
        const language =
          ((store.state.group[uuid] || {}).user || {}).language || "en";
        const email = ((store.state.group[uuid] || {}).user || {}).email || "";
        if (email && email.indexOf("@") !== -1) {
          const textToSend = this.$t(
            "components.conferenceForm.conferenceEnded"
          );
          await sendInviteMailCustom(uuid, {
            guest:
              ((store.state.group[uuid] || {}).user || {}).permissions === 10,
            name: ((store.state.group[uuid] || {}).user || {}).name,
            email,
            subject: this.$t(
              "components.conferenceForm.conferenceEndedSubject"
            ),
            language,
            customMessage: textToSend,
            conferenceNotification: true,
            noInviteLink: true,
          });
        }
      });
    },
    openManageConference() {
      this.showManageConference = true;
    },
    closeManageConference() {
      this.showManageConference = false;
    },
    openConferenceDocumentsModal() {
      setConferenceDocumentsEvent(
        this.isConferenceCall ? this.isConferenceCall : this.getCallUUID
      );
      this.totalDocumentsRead = this.docCount;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    hangupUser(uuid) {
      if (this.kickUser) {
        this.kickUser(uuid);
      }
    },
    getConferenceId() {
      return this.getconfId;
    },
    toggleCamMic() {
      EventBus.$emit("togleMicSettings");
    },
    async createDocumentStore(id) {
      if (this.isConferenceCall) {
        let original = {}
        if(this.getIsConferenceHall){
          original = this.myConferenceHallDetails.find((e) => e.confId == id);
        }else{
          original = this.myConferenceDetails.find((e) => e.confId == id);
        }
        if (original && original.confId) {
          const existingDocStore = await getCompanyDocumentsForCall(
            id,
            "conference"
          );
          if (existingDocStore) {
            existingDocStore.docMembersUUIDS = original.confUUIDS;
            existingDocStore.moderators = original.confModerators;
            await updateCompanyDocumentEvent(existingDocStore);
          } else {
            const entry = {
              docStoreUUID: uuidv4(),
              docMembersUUIDS: original.confUUIDS,
              moderators: original.confModerators,
              creator: original.creator,
              callUUID: false,
              confId: id,
              timelineId: false,
              templateId: false,
              documents: [],
            };
            await newCompanyDocumentEvent(entry);
          }
        }
      } else {
        const existingDocStore = await getCompanyDocumentsForCall(id, "uuid");
        const tmpUsersArray = [];
        if (this.bridgeInfoStore && this.bridgeInfoStore.users) {
          Object.keys(this.bridgeInfoStore.users).forEach((user) => {
            if (tmpUsersArray.indexOf(user == -1)) {
              tmpUsersArray.push(user);
            }
          });
        }

        if (existingDocStore) {
          existingDocStore.docMembersUUIDS = tmpUsersArray;
          existingDocStore.moderators = [this.state.ownUUID];
          await updateCompanyDocumentEvent(existingDocStore);
        } else {
          const entry = {
            docStoreUUID: uuidv4(),
            docMembersUUIDS: tmpUsersArray,
            moderators: [this.state.ownUUID],
            creator: this.state.ownUUID,
            callUUID: id,
            confId: false,
            timelineId: false,
            templateId: false,
            documents: [],
          };
          await newCompanyDocumentEvent(entry);
        }
      }
    },
    setTempDocStore(tempStore) {
      this.tempDocumentStore = tempStore;
    },

    //   getAttendeesObject(uuids) {
    //   let myMembers = [];
    //   uuids.forEach((uuid) => {
    //     if (uuid) {
    //       const aMember = {
    //         uuid,
    //         name: store.getNameForUuid(uuid),
    //         addUserToCall: true,
    //         showUserName: true,
    //       };
    //       myMembers.push(aMember);
    //     }
    //   });
    //   return myMembers;
    // },
    // async addUserToConference(uuid) {
    //   let tmpData = this.getDataConference; // full conference
    //   if (!tmpData || !tmpData.confId) {
    //     console.warn("No conference to add user to");
    //     return;
    //   }
    //   if (tmpData.confUUIDS.indexOf(uuid) == -1) {
    //     const newMember = {
    //       isGuest: false,
    //       isModerator: false,
    //       name: this.state.group[uuid].user.name,
    //       uuid: uuid,
    //       isGroup: false,
    //       isTeam: false,
    //     };
    //     tmpData.members.push(newMember);
    //     tmpData.confRealUsers.push(uuid);
    //     tmpData.confUUIDS.push(uuid);
    //     await updateGroupConferenceEvent(tmpData);
    //     const newAttendees = this.getAttendeesObject(tmpData.confUUIDS);
    //     this.getConfOnlineUsers(tmpData, newAttendees);
    //   }
    // },
    // getConfOnlineUsers(entry, newAttendees) {
    //   let usernames = [];
    //   if (entry && entry.confUUIDS) {
    //     entry.confUUIDS.forEach((uuid) => {
    //       const username =
    //         ((store.state.group[uuid] || {}).user || {}).name || "";
    //       if (username && username !== "") {
    //         let data = { username: username, uuid: uuid };
    //         usernames.push(data);
    //       }
    //     });
    //     usernames.forEach((name) => {
    //       let isOnline = false;
    //       if (this.bridgeInfoStore && this.bridgeInfoStore.calls) {
    //         Object.keys(this.bridgeInfoStore.calls).forEach((call) => {
    //           if (this.bridgeInfoStore.calls[call].confId === entry.confId) {
    //             isOnline = this.bridgeInfoStore.users[name.uuid] || false;
    //           }
    //         });
    //       }
    //       if (isOnline) {
    //         wsCall("sendToUUID", name.uuid, {
    //           type: "bridge-signal",
    //           action: "updateConferenceAttendees",
    //           sender: this.state.ownUUID,
    //           info: {
    //             callUUID: this.getCallUUID,
    //             confAttendees: newAttendees,
    //           },
    //         });
    //       }
    //     });
    //   }
    // },
    checkUserInCall(uuid) {
      if (this.isUserInCall) {
        return this.isUserInCall(uuid);
      }
      return false;
    },
  },
  computed: {
    // getAmIRequestingWord(){
    //   if (this.room
    //     && this.requestToSpeakConferenceHall[this.room.myUserId()]
    //     && this.requestToSpeakConferenceHall[this.room.myUserId()].request === true)
    //     {
    //       return true
    //     }
    //   return false
    // },
    // listenerButtonsEnabled(){
    //   return this.isListenerSpeakerConferenceHall;
    //   // console.log('11111111111', this.isListenerSpeakerConferenceHall(this.room.myUserId()))
    //   // if ( this.room && this.isListenerSpeakerConferenceHall(this.room.myUserId())) return true;
    //   // return false
    //   // console.log('listenerButtonsEnabledlistenerButtonsEnabled', this.listenerSpeakerConferenceHall, this.listenerSpeakerConferenceHall && this.listenerSpeakerConferenceHall[this.ownUUID])
    //   // if (this.listenerSpeakerConferenceHall && this.listenerSpeakerConferenceHall[this.ownUUID]) return true;
    //   // return false;
    // },
    showDownloadSupportLog() {
      return true; // store.state.nerd || (!webLicensedBaseFeatures.isRaMicroCustomer && ((webLicensedBaseFeatures.isRaMicro || webLicensedBaseFeatures.isDev)));
    },
    showDownloadChat(){
      return (this.isConferenceCall || this.getIsConferenceHall) && (this.getDataConference && (this.getDataConference.creatorUUID === this.state.ownUUID || this.getDataConference.confOwner === this.state.ownUUID))
    },
    totalUnreadChatMessages() {
      const total =
        this.getTotalReceivedChatMessages - this.totalChatMessagesRead;
      if (total <= 0) {
        return 0;
      }
      return total;
    },
    hasUnreadChatMessages() {
      if (this.totalChatMessagesRead !== this.getTotalReceivedChatMessages) {
        return true;
      }
      return false;
    },
    isDark() {
      return store.state.persisted.isDark;
    },
    hasDocuments() {
      if (this.isConferenceCall) {
        let cStore = this.conferenceStore;
        if (cStore && cStore.documents && cStore.documents.length) {
          return true;
        } else {
          return false;
        }
      } else {
        let callUUID = this.getCallUUID;
        let docs = this.myDocuments.find((e) => e.callUUID === callUUID);
        if (docs && docs.documents && docs.documents.length) {
          return true;
        } else {
          return false;
        }
      }
    },
    conferenceStore() {
      let foundStore = null;
      let confId = this.getConferenceId() || this.isConferenceCall;
      if (confId) {
        foundStore = this.myDocuments.find((e) => e.confId === confId);
        if (foundStore && foundStore.confId) {
          return foundStore;
        } else {
          this.createDocumentStore(confId);
          return false;
        }
      }
      //// build more cases for different sources...
    },
    //   docCount() {
    //   let total = 0;
    //   let callUUID = this.getCallUUID;
    //   let docs = this.myDocuments.find((e) => e.callUUID === callUUID);
    //   if (docs && docs.documents.length) {
    //     total = docs.documents.length;
    //   }
    //   return total;
    // },
    getTotalDocumentsRead() {
      const total = this.docCount - this.totalDocumentsRead;
      if (total <= 0) {
        return 0;
      } else {
        return total;
      }
    },
    docCount() {
      if (this.isConferenceCall) {
        let cStore = this.conferenceStore;
        if (cStore && cStore.documents) {
          return cStore.documents.length;
        } else {
          return 0;
        }
      } else {
        let total = 0;
        let callUUID = this.getCallUUID;
        let docs = this.myDocuments.find((e) => e.callUUID === callUUID);
        if (docs && docs.documents.length) {
          total = docs.documents.length;
        }
        return total;
      }
    },
    callStore() {
      let foundStore = null;
      let callUUID = this.getCallUUID;

      if (callUUID) {
        foundStore = this.myDocuments.find((e) => e.callUUID === callUUID);
        if (foundStore && foundStore.callUUID) {
          return foundStore;
        } else {
          this.createDocumentStore(callUUID);
        }
      }
      //// build more cases for different sources...
    },
    getDataConference() {
      if (this.isConferenceCall) {
        let confId = this.isConferenceCall;
        let dataConference = null;
        if(this.getIsConferenceHall){
           dataConference = this.myConferenceHallDetails.find(
            (e) => e.confId === confId
          );

        }else{
          dataConference = this.myConferenceDetails.find(
            (e) => e.confId === confId
          );

        }
        if (!dataConference) {
          dataConference = getCompanyDocumentsForCall(confId, "conference");
        }
        this.dataConference = dataConference;
        return dataConference;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.v-btn--icon.v-size--large {
  width: 40px !important;
  height: 40px !important;
}
.darkGreyIcon {
  background-color: #464b4e !important;
  border-color: #464b4e !important;
}
.pAbsoluteLeft {
  position: absolute;
  left: 0;
}
.pAbsoluteRight {
  position: absolute;
  right: 0;
}
.docsBadgeDots {
  height: 15px !important;
  min-width: 15px !important;
  position: absolute !important;
  left: 35px !important;
  bottom: 0px !important;
}
.docsBadge {
  height: 15px !important;
  min-width: 15px !important;
  position: absolute !important;
  left: 35px !important;
  bottom: 15px !important;
}
// .localButtons {
//   margin-right: 48px;
//   margin-left: 48px;
// }
// @media (max-width: 1700px) {
//   .rightLocalBar {
//     justify-content: flex-end !important;
//   }
// }
// @media (max-width: 1300px) {
//   .localButtons {
//     margin-left: 20px;
//     margin-right: 20px;
//   }
// }
// @media (max-width: 1015px) {
//   .localButtons {
//     margin-left: 5px;
//     margin-right: 5px;
//   }
// }
.switchContainer {
  position: relative;
  top: -4px;
}
.progressBarLevelBridge {
  width: 1%;
  height: 3px;
  max-height: 3px;
  min-height: 3px;
  background-color: #2a3133;
}
.progressBarBridge {
  width: 100%;
  background-color: transparent;
  position: absolute;
  bottom: -17px;
}
// .myLocal .dotsMenus {
//   .v-menu__content {
//     bottom: 55px !important;
//     top: unset !important;
//     right: 45px !important;
//     left: unset !important;
//   }
// }
.customDotsMenuLocal {
  bottom: 55px !important;
  top: unset !important;
  right: 45px !important;
  left: unset !important;
}
.microphone-slash {
  width: 25px !important;
  height: 25px !important;
  color: #fff;
}
.volume-up-slash {
  width: 25px !important;
  height: 25px !important;
  color: #fff;
}

.video-slash {
  width: 25px !important;
  height: 25px !important;
}

.imgButton {
  width: 25px;
}
.progressBarBridge {
  position: absolute !important;
  left: 0px !important;
  bottom: -17px !important;
}
.faPhoneRotate {
  transform: rotate(225deg) !important;
}
.leftLocalBar {
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;
  height: 100%;
}
.rightLocalBar {
  display: flex;
  justify-content: center;
  width: 100%;
}

// .v-theme--dark.v-btn.v-btn--icon {
//   color: white !important;
// }

// .v-theme--dark #videos .localButtonsBar {
//   background-color: rgba(0, 0, 0, 0.5);
// }
.custom-loader {
  animation: loader 1s infinite;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>