// import { timelineDomain.createEvent } from 'effector';
import { timelineDomain } from './domain';

export const newTimelineItemEvent = timelineDomain.createEvent('newTimelineItem');
export const deleteTimelineItemEvent = timelineDomain.createEvent('deleteTimelineItem');
export const updateTimelineItemEvent = timelineDomain.createEvent('updateTimelineItem');
export const updateTimelineDurationItemEvent = timelineDomain.createEvent('updateTimelineDurationItemEvent');

export const dispatchFetchMyItemsForDateEvent = timelineDomain.createEvent('dispatchFetchMyItemsForDate');
export const dispatchFetchTicketInfoEvent = timelineDomain.createEvent('dispatchFetchTicketInfo');
export const dispatchFetchTicketInfosEvent = timelineDomain.createEvent('dispatchFetchTicketInfos');

export const setTimelineEventUUIDsEvent = timelineDomain.createEvent('setTimelineEventUUIDs');
export const setTimelineEventInfoEvent = timelineDomain.createEvent('setTimelineEventInfo');
export const setTimelineEventInfoBatchedEvent = timelineDomain.createEvent('setTimelineEventInfoBatched');

export const deleteTimelineEntryAndUUIDEvent = timelineDomain.createEvent('deleteTimelineEntryAndUUID');

export const dispatchRsvpTimelineEntry = timelineDomain.createEvent('dispatchRsvpTimelineEntry');
export const dispatchUpdatePdfTimeLineEntry = timelineDomain.createEvent('dispatchUpdatePdfTimeLineEntry');

export const dispatchColorChangeTimelineEntry = timelineDomain.createEvent('dispatchColorChangeTimelineEntry');

export const dispatchViewedTimelineEntry = timelineDomain.createEvent('dispatchViewedTimelineEntry');

// Solely listened to by the timeline vue component
export const pleaseShowDateInTimelineEvent = timelineDomain.createEvent('pleaseShowDateInTimeline');

export const resetAndRefetchDatesEvent = timelineDomain.createEvent('resetAndRefetchDates');
