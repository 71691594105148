<template>
  <v-dialog
    persistent
    :model-value="showSetupDevice" @update:model-value="showSetupDevice = $event"
    max-width="570"
    @keydown.esc="closeModalKeyDown"
  >
    <v-card>
      <HeaderModal
        :titleModal="isGuestOrBeacon?$t('components.prepLiveCall.modalTitle'):$t('generics.settings')"
        :closeModalFunction="closeModalFunction"
      >
        <v-spacer></v-spacer>
      </HeaderModal>
      <CamAndMicGuestOrBeacon v-if="isGuestOrBeacon" :isInModal="true" :hideClose="!closeModalFunction" :closeModal="closeModal" />
      <CamAndMic v-else :isInModal="true" :hideClose="!closeModalFunction" :closeModal="closeModal" />
    </v-card>
  </v-dialog>
</template>
<script>
import store from "../../store";
import CamAndMic from "../firstSetup/camAndMic.vue";
import CamAndMicGuestOrBeacon from "../firstSetup/camAndMicGuestOrBeacon.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import { hasPrivilege } from "../../utils/privileges";
export default {
  components: { CamAndMic, HeaderModal, CamAndMicGuestOrBeacon },
  props: ["showSetupDevice", "toggleModalSettings"],
  data: () => ({
    state: store.state,
    ownUUID: store.state.ownUUID,
    hasVisitorNameOnStart: !!store.state.user.name,
  }),
  methods: {
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    closeModal() {
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      if (this.toggleModalSettings) {
        this.toggleModalSettings();
      }
    },
    closeModalKeyDown() {
      if (this.state.user.name) {
        this.closeModal();
      }
    },
  },
  computed: {
    isGuestOrBeacon() {
      return (this.isBeaconInvite || (!hasPrivilege(this.ownUUID) && !this.hasVisitorNameOnStart))
    },
    isBeaconInvite() {
      return store.state.group[this.ownUUID].beaconCallInvite;
    },
    closeModalFunction() {
      if (!this.state.user.name) {
        return null;
      }
      return this.closeModal;
    }
  },
};
</script>
<style scoped lang="scss">
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
</style>