import { waitingInfoStore, waitingForInfoStore } from './state';
import {markAsCallWaitingRoomUserEvent, markAsCallWaitingRoomForUserEvent} from './events'
import './init';

export {
  // Stores
  waitingInfoStore,
  waitingForInfoStore,
  // Events
  markAsCallWaitingRoomUserEvent,
  markAsCallWaitingRoomForUserEvent
};
