<template>
  <div id="contentWrapper" class="gridWrapperNormalMode p-0 container-fluid">

    <div class="container-fluid hidd call-grid" id="call-grid-19">
      <div class="row no-gutters flex-fill d-flex" style="height: 100%">
        <div class="col d-flex">
          <div class="container-fluid d-flex p-0">
            <div class="row no-gutters" style="width: 100%">
              <div class="col-auto d-flex" style="width: 100%">
                <div class="bgyellow d-flex grm vidR1" style="width: 100%">
                  <div
                    style="position: relative; width: 100%"
                    id="call-grid-19-1"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid hidd call-grid" id="call-grid-1">
      <div class="row no-gutters flex-fill d-flex" style="height: 100%">
        <div class="col d-flex">
          <div class="container-fluid d-flex p-0">
            <div class="row no-gutters" style="width: 100%">
              <div class="col-auto" style="width: 25%">
                <div class="container-fluid p-0">
                  <div class="row no-gutters" style="height: 50%">
                    <div
                      class="col d-flex bgyellow grm vidR2"
                      id="call-grid-1-2"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 50%">
                    <div
                      class="col d-flex bgyellow grm vidR2"
                      id="call-grid-1-4"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="col-auto d-flex" style="width: 50%">
                <div class="bgyellow d-flex grm vidR1" style="width: 100%">
                  <div
                    style="position: relative; width: 100%"
                    id="call-grid-1-1"
                  ></div>
                </div>
              </div>
              <div class="col-auto" style="width: 25%">
                <div class="container-fluid p-0">
                  <div class="row no-gutters" style="height: 50%">
                    <div
                      class="col d-flex bgyellow grm vidR2"
                      id="call-grid-1-3"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 50%">
                    <div
                      class="col d-flex bgyellow grm vidR2"
                      id="call-grid-1-5"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid hidd call-grid" id="call-grid-21">
      <div class="row no-gutters flex-fill d-flex" style="height: 100%">
        <div class="col d-flex">
          <div class="container-fluid d-flex p-0">
            <div class="row no-gutters" style="width: 100%">
              <div class="col-auto" style="width: 25%">
                <div class="container-fluid p-0">
                  <div class="row no-gutters" style="height: 33.3%">
                    <div
                      class="col d-flex bgyellow grm vidR3"
                      id="call-grid-21-2"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 33.3%">
                    <div
                      class="col d-flex bgyellow grm vidR3"
                      id="call-grid-21-4"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 33.4%">
                    <div
                      class="col d-flex bgyellow grm vidR3"
                      id="call-grid-21-6"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="col-auto d-flex" style="width: 50%">
                <div class="bgyellow d-flex grm vidR1" style="width: 100%">
                  <div
                    style="position: relative; width: 100%"
                    id="call-grid-21-1"
                  ></div>
                </div>
              </div>
              <div class="col-auto" style="width: 25%">
                <div class="container-fluid p-0">
                  <div class="row no-gutters" style="height: 33.3%">
                    <div
                      class="col d-flex bgyellow grm vidR3"
                      id="call-grid-21-3"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 33.3%">
                    <div
                      class="col d-flex bgyellow grm vidR3"
                      id="call-grid-21-5"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 33.4%">
                    <div
                      class="col d-flex bgyellow grm vidR3"
                      id="call-grid-21-7"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid hidd call-grid" id="call-grid-2">
      <div class="row no-gutters flex-fill d-flex" style="height: 66.67%">
        <div class="col d-flex">
          <div class="container-fluid d-flex p-0">
            <div class="row no-gutters" style="width: 100%">
              <div class="col-auto" style="width: 25%">
                <div class="container-fluid p-0">
                  <div class="row no-gutters" style="height: 50%">
                    <div
                      class="col d-flex bgyellow grm vidR3"
                      id="call-grid-2-2"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 50%">
                    <div
                      class="col d-flex bgyellow grm vidR3"
                      id="call-grid-2-4"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="col-auto d-flex" style="width: 50%">
                <div class="bgyellow d-flex grm vidR3_2" style="width: 100%">
                  <div
                    style="position: relative; width: 100%"
                    id="call-grid-2-1"
                  ></div>
                </div>
              </div>
              <div class="col-auto" style="width: 25%">
                <div class="container-fluid p-0">
                  <div class="row no-gutters" style="height: 50%">
                    <div
                      class="col d-flex bgyellow grm vidR3"
                      id="call-grid-2-3"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 50%">
                    <div
                      class="col d-flex bgyellow grm vidR3"
                      id="call-grid-2-5"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters" style="height: 33.33%">
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-2-6"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-2-7"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-2-8"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-2-9"></div>
      </div>
    </div>

    <div class="container-fluid hidd call-grid" id="call-grid-22">
      <div class="row no-gutters flex-fill d-flex" style="height: 75%">
        <div class="col d-flex">
          <div class="container-fluid d-flex p-0">
            <div class="row no-gutters" style="width: 100%">
              <div class="col-auto" style="width: 25%">
                <div class="container-fluid p-0">
                  <div class="row no-gutters" style="height: 33.3%">
                    <div
                      class="col d-flex bgyellow grm vidR4"
                      id="call-grid-22-2"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 33.3%">
                    <div
                      class="col d-flex bgyellow grm vidR4"
                      id="call-grid-22-4"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 33.4%">
                    <div
                      class="col d-flex bgyellow grm vidR4"
                      id="call-grid-22-6"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="col-auto d-flex" style="width: 50%">
                <div class="bgyellow d-flex grm vidR4_3" style="width: 100%">
                  <div
                    style="position: relative; width: 100%"
                    id="call-grid-22-1"
                  ></div>
                </div>
              </div>
              <div class="col-auto" style="width: 25%">
                <div class="container-fluid p-0">
                  <div class="row no-gutters" style="height: 33.3%">
                    <div
                      class="col d-flex bgyellow grm vidR4"
                      id="call-grid-22-3"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 33.3%">
                    <div
                      class="col d-flex bgyellow grm vidR4"
                      id="call-grid-22-5"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 33.4%">
                    <div
                      class="col d-flex bgyellow grm vidR4"
                      id="call-grid-22-7"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters" style="height: 25%">
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-22-8"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-22-9"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-22-10"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-22-11"></div>
      </div>
    </div>


    <div class="container-fluid hidd call-grid" id="call-grid-6">
      <div class="row no-gutters flex-fill d-flex" style="height: 100%">
        <div class="col d-flex">
          <div class="container-fluid d-flex p-0">
            <div class="row no-gutters" style="width: 100%">
              <div class="col-auto" style="width: 25%">
                <div class="container-fluid p-0">
                  <div class="row no-gutters" style="height: 33.3%">
                    <div
                      class="col d-flex bgyellow grm vidR3"
                      id="call-grid-6-2"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 33.3%">
                    <div
                      class="col d-flex bgyellow grm vidR3"
                      id="call-grid-6-4"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 33.4%">
                    <div
                      class="col d-flex bgyellow grm vidR3"
                      id="call-grid-6-6"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="col-auto d-flex" style="width: 50%">
                <div class="bgyellow d-flex grm vidR3_2" style="width: 100%">
                  <div
                    style="position: relative; width: 100%"
                    id="call-grid-6-1"
                  ></div>
                </div>
              </div>
              <div class="col-auto" style="width: 25%">
                <div class="container-fluid p-0">
                  <div class="row no-gutters" style="height: 33.3%">
                    <div
                      class="col d-flex bgyellow grm vidR3"
                      id="call-grid-6-3"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 33.3%">
                    <div
                      class="col d-flex bgyellow grm vidR3"
                      id="call-grid-6-5"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 33.4%">
                    <div
                      class="col d-flex bgyellow grm vidR3"
                      id="call-grid-6-7"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid hidd call-grid" id="call-grid-3">
      <div class="row no-gutters" style="height: 25%">
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-3-2"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-3-3"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-3-4"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-3-5"></div>
      </div>
      <div class="row no-gutters flex-fill d-flex" style="height: 50%">
        <div class="col d-flex">
          <div class="container-fluid d-flex p-0">
            <div class="row no-gutters" style="width: 100%">
              <div class="col-auto" style="width: 25%">
                <div class="container-fluid p-0">
                  <div class="row no-gutters" style="height: 50%">
                    <div
                      class="col bgyellow d-flex grm vidR4"
                      id="call-grid-3-6"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 50%">
                    <div
                      class="col bgyellow d-flex grm vidR4"
                      id="call-grid-3-8"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="col-auto d-flex" style="width: 50%">
                <div class="bgyellow d-flex grm vidR4_2" style="width: 100%">
                  <div
                    style="position: relative; width: 100%"
                    id="call-grid-3-1"
                  ></div>
                </div>
              </div>
              <div class="col-auto" style="width: 25%">
                <div class="container-fluid p-0">
                  <div class="row no-gutters" style="height: 50%">
                    <div
                      class="col d-flex bgyellow grm vidR4"
                      id="call-grid-3-7"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 50%">
                    <div
                      class="col d-flex bgyellow grm vidR4"
                      id="call-grid-3-9"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters" style="height: 25%">
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-3-10"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-3-11"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-3-12"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-3-13"></div>
      </div>
    </div>

    <div class="container-fluid hidd call-grid" id="call-grid-7">
      <div class="row no-gutters flex-fill d-flex" style="height: 70%">
        <div class="col d-flex">
          <div class="container-fluid d-flex p-0">
            <div class="row no-gutters" style="width: 100%">
              <div class="col-auto" style="width: 25%">
                <div class="container-fluid p-0">
                  <div class="row no-gutters" style="height: 33.3%">
                    <div
                      class="col bgyellow d-flex grm vidR4"
                      id="call-grid-7-2"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 33.3%">
                    <div
                      class="col bgyellow d-flex grm vidR4"
                      id="call-grid-7-4"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 33.4%">
                    <div
                      class="col bgyellow d-flex grm vidR4"
                      id="call-grid-7-6"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="col-auto d-flex" style="width: 50%">
                <div class="bgyellow d-flex grm vidR4_2" style="width: 100%">
                  <div
                    style="position: relative; width: 100%"
                    id="call-grid-7-1"
                  ></div>
                </div>
              </div>
              <div class="col-auto" style="width: 25%">
                <div class="container-fluid p-0">
                  <div class="row no-gutters" style="height: 33.3%">
                    <div
                      class="col d-flex bgyellow grm vidR4"
                      id="call-grid-7-3"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 33.3%">
                    <div
                      class="col d-flex bgyellow grm vidR4"
                      id="call-grid-7-5"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 33.4%">
                    <div
                      class="col d-flex bgyellow grm vidR4"
                      id="call-grid-7-7"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters" style="height: 25%">
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-7-8"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-7-9"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-7-10"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-7-11"></div>
      </div>
    </div>

    <div class="container-fluid hidd call-grid" id="call-grid-4">
      <div class="row no-gutters" style="height: 16.66%">
        <div class="col bgyellow d-flex grm vidR6" id="call-grid-4-2"></div>
        <div class="col bgyellow d-flex grm vidR6" id="call-grid-4-3"></div>
        <div class="col bgyellow d-flex grm vidR6" id="call-grid-4-4"></div>
        <div class="col bgyellow d-flex grm vidR6" id="call-grid-4-5"></div>
        <div class="col bgyellow d-flex grm vidR6" id="call-grid-4-6"></div>
        <div class="col bgyellow d-flex grm vidR6" id="call-grid-4-7"></div>
      </div>
      <div class="row no-gutters flex-fill d-flex" style="height: 66.68%">
        <div class="col d-flex">
          <div class="container-fluid d-flex p-0">
            <div class="row no-gutters" style="width: 100%">
              <div class="col-auto" style="width: 16.66%">
                <div class="container-fluid p-0">
                  <div class="row no-gutters" style="height: 25%">
                    <div
                      class="col bgyellow d-flex grm vidR6"
                      id="call-grid-4-8"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 25%">
                    <div
                      class="col bgyellow d-flex grm vidR6"
                      id="call-grid-4-10"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 25%">
                    <div
                      class="col bgyellow d-flex grm vidR6"
                      id="call-grid-4-12"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 25%">
                    <div
                      class="col bgyellow d-flex grm vidR6"
                      id="call-grid-4-14"
                    ></div>
                  </div>
                </div>
              </div>
              <div class="col-auto d-flex" style="width: 66.68%">
                <div class="bgyellow d-flex grm vidR6_4" style="width: 100%">
                  <div
                    style="position: relative; width: 100%"
                    id="call-grid-4-1"
                  ></div>
                </div>
              </div>
              <div class="col-auto" style="width: 16.66%">
                <div class="container-fluid p-0">
                  <div class="row no-gutters" style="height: 25%">
                    <div
                      class="col d-flex bgyellow grm vidR6"
                      id="call-grid-4-9"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 25%">
                    <div
                      class="col d-flex bgyellow grm vidR6"
                      id="call-grid-4-11"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 25%">
                    <div
                      class="col d-flex bgyellow grm vidR6"
                      id="call-grid-4-13"
                    ></div>
                  </div>
                  <div class="row no-gutters" style="height: 25%">
                    <div
                      class="col d-flex bgyellow grm vidR6"
                      id="call-grid-4-15"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters" style="height: 16.66%">
        <div class="col bgyellow d-flex grm vidR6" id="call-grid-4-16"></div>
        <div class="col bgyellow d-flex grm vidR6" id="call-grid-4-17"></div>
        <div class="col bgyellow d-flex grm vidR6" id="call-grid-4-18"></div>
        <div class="col bgyellow d-flex grm vidR6" id="call-grid-4-19"></div>
        <div class="col bgyellow d-flex grm vidR6" id="call-grid-4-20"></div>
        <div class="col bgyellow d-flex grm vidR6" id="call-grid-4-21"></div>
      </div>
    </div>

    <div class="container-fluid hidd call-grid" id="call-grid-5">
      <div class="row no-gutters" style="height: 100%">
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-5-1"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-5-2"></div>
      </div>
    </div>

    <div class="container-fluid hidd call-grid" id="call-grid-10">
      <div class="row no-gutters" style="height: 50%">
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-10-1"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-10-2"></div>
      </div>
      <div class="row no-gutters" style="height: 50%">
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-10-3"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-10-4"></div>
      </div>
    </div>
    <div class="container-fluid hidd call-grid" id="call-grid-9">
      <div class="row no-gutters" style="height: 50%">
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-9-1"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-9-2"></div>
      </div>
      <div class="row no-gutters" style="height: 50%">
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-9-3"></div>
      </div>
    </div>
    <div class="container-fluid hidd call-grid" id="call-grid-11">
      <div class="row no-gutters" style="height: 50%">
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-11-1"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-11-2"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-11-3"></div>
      </div>
      <div class="row no-gutters" style="height: 50%">
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-11-4"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-11-5"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-11-6"></div>
      </div>
    </div>
    <div class="container-fluid hidd call-grid" id="call-grid-8">
      <div class="row no-gutters" style="height: 50%">
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-8-1"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-8-2"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-8-3"></div>
      </div>
      <div class="row no-gutters" style="height: 50%">
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-8-4"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-8-5"></div>
      </div>
    </div>
    <div class="container-fluid hidd call-grid" id="call-grid-12">
      <div class="row no-gutters" style="height: 50%">
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-12-1"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-12-2"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-12-3"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-12-4"></div>
      </div>
      <div class="row no-gutters" style="height: 50%">
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-12-5"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-12-6"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-12-7"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-12-8"></div>
      </div>
    </div>

    <div class="container-fluid hidd call-grid" id="call-grid-13">
      <div class="row no-gutters" style="height: 33.33%">
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-13-1"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-13-2"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-13-3"></div>
      </div>
      <div class="row no-gutters" style="height: 33.33%">
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-13-4"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-13-5"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-13-6"></div>
      </div>
      <div class="row no-gutters" style="height: 33.34%">
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-13-7"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-13-8"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-13-9"></div>
      </div>
    </div>

    <div class="container-fluid hidd call-grid" id="call-grid-14">
      <div class="row no-gutters" style="height: 50%">
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-14-1"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-14-2"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-14-3"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-14-4"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-14-5"></div>
      </div>
      <div class="row no-gutters" style="height: 50%">
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-14-6"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-14-7"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-14-8"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-14-9"></div>
        <div class="col bgyellow d-flex grm vidR2" id="call-grid-14-10"></div>
      </div>
    </div>

    <div class="container-fluid hidd call-grid" id="call-grid-15">
      <div class="row no-gutters" style="height: 33.33%">
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-15-1"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-15-2"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-15-3"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-15-4"></div>
      </div>
      <div class="row no-gutters" style="height: 33.33%">
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-15-5"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-15-6"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-15-7"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-15-8"></div>
      </div>
      <div class="row no-gutters" style="height: 33.34%">
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-15-9"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-15-10"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-15-11"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-15-12"></div>
      </div>
    </div>

    <div class="container-fluid hidd call-grid" id="call-grid-16">
      <div class="row no-gutters" style="height: 33.33%">
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-16-1"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-16-2"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-16-3"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-16-4"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-16-5"></div>
      </div>
      <div class="row no-gutters" style="height: 33.33%">
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-16-6"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-16-7"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-16-8"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-16-9"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-16-10"></div>
      </div>
      <div class="row no-gutters" style="height: 33.34%">
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-16-11"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-16-12"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-16-13"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-16-14"></div>
        <div class="col bgyellow d-flex grm vidR3" id="call-grid-16-15"></div>
      </div>
    </div>

    <div class="container-fluid hidd call-grid" id="call-grid-17">
      <div class="row no-gutters" style="height: 25%">
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-17-1"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-17-2"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-17-3"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-17-4"></div>
      </div>
      <div class="row no-gutters" style="height: 25%">
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-17-5"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-17-6"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-17-7"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-17-8"></div>
      </div>
      <div class="row no-gutters" style="height: 25%">
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-17-9"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-17-10"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-17-11"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-17-12"></div>
      </div>
      <div class="row no-gutters" style="height: 25%">
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-17-13"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-17-14"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-17-15"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-17-16"></div>
      </div>
    </div>

    <div class="container-fluid hidd call-grid" id="call-grid-18">
      <div class="row no-gutters" style="height: 25%">
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-1"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-2"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-3"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-4"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-5"></div>
      </div>
      <div class="row no-gutters" style="height: 25%">
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-6"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-7"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-8"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-9"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-10"></div>
      </div>
      <div class="row no-gutters" style="height: 25%">
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-11"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-12"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-13"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-14"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-15"></div>
      </div>
      <div class="row no-gutters" style="height: 25%">
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-16"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-17"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-18"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-19"></div>
        <div class="col bgyellow d-flex grm vidR4" id="call-grid-18-20"></div>
      </div>
    </div>

    <div class="container-fluid call-grid" id="call-grid-20">
      <div class="row no-gutters" style="height: 100%">
        <div class="col bgyellow d-flex grm vidR1" id="call-grid-20-1"></div>
      </div>
      <div class="hidd">
        <div id="call-grid-20-2"></div>
        <div id="call-grid-20-3"></div>
        <div id="call-grid-20-4"></div>
        <div id="call-grid-20-5"></div>
        <div id="call-grid-20-6"></div>
        <div id="call-grid-20-7"></div>
        <div id="call-grid-20-8"></div>
        <div id="call-grid-20-9"></div>
        <div id="call-grid-20-10"></div>
        <div id="call-grid-20-11"></div>
        <div id="call-grid-20-12"></div>
        <div id="call-grid-20-13"></div>
        <div id="call-grid-20-14"></div>
        <div id="call-grid-20-15"></div>
        <div id="call-grid-20-16"></div>
        <div id="call-grid-20-17"></div>
        <div id="call-grid-20-18"></div>
        <div id="call-grid-20-19"></div>
        <div id="call-grid-20-20"></div>
        <div id="call-grid-20-21"></div>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap";
export default {};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/bootstrap";
html,
body {
  height: 100%;
}
.bgred {
  background: #dddddd;
}
.bggreen {
  background: #aaaaaa;
}
.bgblue {
  background: blue;
}
.bgyellow {
  background: #888888;
}
.hidd {
  display: none;
}
.grm {
  border: solid 4px white;
  margin: 0px;
}
div {
  font-size: 20px;
}
.title {
  position: relative;
}
.topbar {
  position: absolute;
  width: 100%;
  text-align: center;
  color: white;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}
.iconbar {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  color: white;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}
.call-grid {
  padding: 4px !important;
  height: 100vh;
}
.dominantSpeaker{
  border: solid 4px #6DBAC6 !important;
}
</style>
