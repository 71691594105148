import { hotkeysDomain } from './domain';

import { setHotkesysvent, resetHotkeysEvent } from './events';

export const hotkeysStore = hotkeysDomain.createStore(false, { name: 'hotkeys' });

hotkeysStore.on(setHotkesysvent, (state, payload) => {
  return payload;
});

hotkeysStore.reset(resetHotkeysEvent);
