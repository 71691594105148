<template>
<!-- @mouseleave="handleShowButtons" -->
  <div class="h100">
    <div ref="localVideoDiv" class="relative localVideo">
      <!-- <div class="callerName">
        <span>{{getNameForUuid(state.ownUUID)}}</span>
      </div>-->
      <!-- <span v-if="state.ownUUID === ownerUUID" class="italicFont videoIdTag" >{{callInfo.callUUID || ''}}</span> -->
      <div
                class="messageToParticipants messageToParticipantsBlink"
                v-if="isLocalAudioMuted"
              >
                <!-- v-if="amIMuted || amISoundMuted" -->
                <div v-if="isLocalAudioMuted">
                  {{ $t("components.callsContent.audioMessage") }}
                </div>
                <!-- <div v-if="amISoundMuted">
                  {{ $t("components.callsContent.soundMessage") }}
                </div> -->
              </div>
      <video
        id="local-video"
        v-if="!state.localStreams.display && !this.state.persisted.useBlurEffect"
        playsinline
        autoplay="autoplay"
        :src-object.prop.camel="state.localStreams.user"
        v-bind:class="{ 'd-none': !haveLocalVideo }"
        muted
      ></video>
      <StreamCanvas
        id="local-video"
        v-if="!state.localStreams.display && this.state.persisted.useBlurEffect"
        v-bind:class="{ 'd-none': !haveLocalVideo }"
        :stream="state.localStreams.user"
      ></StreamCanvas>

      <video
        id="local-screen"
        playsinline
        autoplay="autoplay"
        :src-object.prop.camel="state.localStreams.display"
        v-bind:class="{ 'd-none': !state.localStreams.display }"
        muted
      ></video>
      <div class="noCameraStream" v-bind:class="{'d-none': haveLocalVideo}">
        <div class="noCameraContent">
          <v-img
            class="imageNotVideo"
            contain
            :src="getAvatarFromUuid(state.ownUUID)"
            lazy-src="assets/default_profile_picture.png"
          ></v-img>
          <div class="emptyDiv"></div>
        </div>
      </div>
      <div

        v-bind:class="{ hidden: Object.keys(state.remoteStreams).length == 0, 'buttonsIpad': isIpad && isIos, 'showButtons': showButtons}"
        class="buttonsUser"

      >
        <!-- <div class="divButtonsLocal"> -->
        <template>
          <v-list v-if="openCameraQuality" class="pt-0">
            <v-list-item-group color="primary">
              <div class="d-flex justify-end blueBackground">
                <v-btn icon @click="openCameraQuality = false">
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    :style="{ fontSize: '15px' }"
                    v-if="state.persisted.rtcVideo"
                    color="white"
                  />
                </v-btn>
              </div>
              <v-list-item @click="setQuality('high')">
                <v-list-item-content>
                  <v-list-item-title>{{$t("components.camAndMic.high")}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="setQuality('medium')">
                <v-list-item-content>
                  <v-list-item-title>{{$t("components.camAndMic.medium")}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="setQuality('low')">
                <v-list-item-content>
                  <v-list-item-title>{{$t("components.camAndMic.low")}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </template>
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              color="white"
              v-long-press="300"
              @long-press-start="openMenuQuality"
              @long-press-stop="onLongPressStop"
              icon
              @click="tryWebcam()"
              class="mx-3"
            >
              <font-awesome-icon
                :icon="['fal', 'video']"
                :style="{ fontSize: '20px' }"
                v-if="state.persisted.rtcVideo"
              />
              <font-awesome-icon
                :icon="['fal', 'video-slash']"
                :style="{ fontSize: '20px' }"
                v-if="!state.persisted.rtcVideo"
              />
            </v-btn>
          </template>
          <span>{{ $t('generics.camera') }}</span>
        </v-tooltip>

        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" color="white"
            icon @click="toggleMuteLocalAudio()"
            :disabled="isGlobalAudioMuted" class="mx-3">
              <font-awesome-icon
                :icon="['fal', 'microphone']"
                :style="{ fontSize: '20px' }"
                v-if="!isLocalAudioMuted"
              />
              <font-awesome-icon
                :icon="['fal', 'microphone-slash']"
                :style="{ fontSize: '20px' }"
                v-if="isLocalAudioMuted"
              />
              <canvas id="canvasAudioMyLocal" width="50" height="15" :class="{hidden: isLocalAudioMuted}"/>
            </v-btn>
          </template>
          <span>{{ $t('components.callsContent.mic') }}</span>
        </v-tooltip>

        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              color="white"
              icon
              class="redBtn mx-3"
              @click="rtcHangup()"
              ref="cancelCall"
            >
              <font-awesome-icon
                :icon="['fal', 'phone']"
                class="faPhoneRotate"
                :style="{ fontSize: '20px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t('generics.hangUp') }}</span>
        </v-tooltip>

        <v-tooltip location="top" v-if="ownerUUID === state.ownUUID">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" icon color="white" class="mx-3" @click="setFullSizeVideo()">
              <font-awesome-icon :icon="['fal', 'comment']" :style="{ fontSize: '24px' }" />
            </v-btn>
          </template>
          <span>{{$t('components.callsContent.giveWord')}}</span>
        </v-tooltip>

        <v-tooltip
          top
          v-if="state.ownUUID !== ownerUUID"
        >
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" color="white" icon class="mx-3" :class="{'bgOnRequest': imOnRequest}" :disabled="imOnRequest" @click="requestViewAsSpeaker">
              <font-awesome-icon :icon="['fal', 'hand-point-up']" color="white" :style="{ fontSize: '20px' }" />
            </v-btn>
          </template>
          <span>{{$t('components.remotePersonCall.requestView')}}</span>
        </v-tooltip>
        <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                color="white"
                icon
                @click="toggleModalSettings"
                class="mx-3"
              >
                <font-awesome-icon :icon="['fal', 'cog']" :style="{ fontSize: '20px' }" />
              </v-btn>
            </template>
            <span>{{$t("components.navbar.camAndMic")}}</span>
          </v-tooltip>
          <v-tooltip location="top" v-if="ownerUUID === state.ownUUID">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" icon color="white" @click.stop.prevent="setSendMessageInCallModal" class="mx-3">
                <font-awesome-icon :icon="['fal', 'paper-plane']" :style="{ fontSize: '20px' }" />
                <!-- <font-awesome-icon
                    :icon="['fal', 'lock']"
                    :style="{ fontSize: '14px' }"
                    class="lockMessageIcon"
                  /> -->
              </v-btn>
            </template>
            <span>{{$t("components.bridgeCallTooltips.messageAll")}}</span>
          </v-tooltip>
        <!-- <v-menu offset-y scrollable :close-on-click="true">
          <template v-slot:activator="{ on }">
            <v-btn icon color="white" v-on="on">
              <font-awesome-icon :icon="['fal', 'ellipsis-v']" :style="{ fontSize: '30px' }" />
            </v-btn>
          </template>

          <v-list>
            <v-list-item class="cursorPointer" @click="toggleUseBlurEffect">
              <v-list-item-title>{{ $t('components.userListItem.blurEffect') }} {{ this.state.persisted.useBlurEffect ? $t('components.userListItem.on') : $t('components.userListItem.off') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->

        <!-- </div> -->
      </div>
      <!-- @mouseenter="showButtons = true" -->
      <div class="callerName" >
        <span class="text-truncate">
          <font-awesome-icon @click="showButtons = !showButtons" color="white" :icon="['far', 'chevron-down']" :style="{ cursor: 'pointer' , fontSize: '16px' }" v-if="showButtons" />
          <font-awesome-icon @click="showButtons = !showButtons" color="white" :icon="['far', 'chevron-up']" :style="{ cursor: 'pointer' , fontSize: '16px' }" v-if="!showButtons" />
         {{isVisitor(state.ownUUID) ?  $t('components.userSettings.guest')+ ' - ' : ''}} {{getTitelForUuid(state.ownUUID)}} {{getNameForUuid(state.ownUUID)}}
        </span>
        <!-- <span class="italicFont" v-if="state.ownUUID === ownerUUID">Moderator</span> -->
        <!-- v-if="ownerUUID === state.ownUUID" -->
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <span v-bind="props" class="italicFont" style="float: right;margin-right:9px" > P2P</span>
            </template>
          <span>{{ $t('components.remotePersonCall.p2p')}}</span>
        </v-tooltip>
        <!-- <v-tooltip location="top"  v-if="state.ownUUID === ownerUUID">
            <template v-slot:activator="{ on }">
              <span v-on="on" style="float: left;margin-left:9px" >
                <font-awesome-icon
                  v-on="on"
                  :icon="['fal', 'users-crown']"
                  :style="{ fontSize: '20px' }"
                />
              </span>
            </template>
            <span>{{ $t("components.remotePersonCall.moderator") }}</span>
          </v-tooltip> -->
          <span  v-if="state.ownUUID === ownerUUID" style="float: left;margin-left:9px">Moderator</span>

          <v-tooltip location="top" v-if="state.ownUUID !== ownerUUID && ( !isWaitingRoomUser(state.ownUUID) && !isVisitor(state.ownUUID) )">
            <template v-slot:activator="{ props }">
              <span v-on="on" style="float: left;margin-left:9px" >
                <font-awesome-icon
                  v-bind="props"
                  :icon="['fal', 'user-crown']"
                  :style="{ fontSize: '20px' }"
                />
              </span>
            </template>
            <span>{{ $t("components.remotePersonCall.user") }}</span>
          </v-tooltip>

          <v-tooltip location="top" v-if="state.ownUUID !== ownerUUID && ( isWaitingRoomUser(state.ownUUID) || isVisitor(state.ownUUID) )">
            <template v-slot:activator="{ props }">
              <span v-on="on" style="float: left;margin-left:9px" >
                <font-awesome-icon
                  v-bind="props"
                  :icon="['fal', 'user-friends']"
                  :style="{ fontSize: '20px' }"
                />
              </span>
            </template>
            <span>{{ $t("components.remotePersonCall.guest") }}</span>
          </v-tooltip>
      </div>

      <!-- <div class="microphoneLevel">
            <font-awesome-icon
              :icon="['fal', 'microphone']"
              class="microphonoLevelIcon"
              v-if="!isLocalAudioMuted"
            />
            <font-awesome-icon
              :icon="['fal', 'microphone-slash']"
              class="microphonoLevelIcon microphonoLevelIconSlash"
              v-if="isLocalAudioMuted"
            />
            <canvas id="canvasAudioMyLocal" width="50" height="15" :class="{hidden: isLocalAudioMuted}"/>

        </div> -->
        <div>
          <AudioStreamMeter :canvasId="'canvasAudioMyLocal'" :stream="state.localStreams.user" />
          <AudioStreamMeter :canvasId="'canvasAudioMyLocal'" :stream="state.localStreams.display" />
        </div>

    </div>
    <!-- <ConfirmCloseCallModal v-if="showStopCallModal" :showModal="showStopCallModal" :closeModal="closeStopCallModal" :stopCall="stopCall"/> -->
  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
// import { tryWebcam, tryScreenShare, rtcHangup, toggleMuteLocalAudio } from '../../lib/rtcCall';
import {
  rtcHangup,
  // ensureUserStream,
  // updateCallTime,
  // setRemoteFullSize,
  // requestSpeakerView
} from "../../lib/rtcConn";
import {
  tryWebcam,
  tryScreenShare,
  toggleMuteLocalAudio,
  cycleUserMedia,
} from "../../lib/mediaDevices";
// import { Splitpanes, Pane } from "splitpanes";
import VueDragResize from "vue-drag-resize";
// import draggable from "vuedraggable";
// import RemotePersonCall from "../rtc/remotePersonCall.vue";
import StreamCanvas from "../rtc/streamCanvas.vue";
import moment from "../../../sharedsrc/moment";
// import ProgressLinear from "../progressLinear/progressLinear.vue";
import LongPress from "vue-directive-long-press";
import { callSetWantToSpeak, callSetRemoteFullSizeFor, allowedToTalkMap, ownerUUID, callInfoStateStore, amMutedByGlobal } from '../../effector/call';
import ConfirmCloseCallModal from '../modal/confirmCloseCallModal.vue'
import AudioStreamMeter from '../rtc/audioStreamMeter.vue'
import { isVisitor, isWaitingRoomUser } from '../../utils/privileges';
import {getSubjectVisitor} from "../../utils/basicFunctions.js";
import {setSendMessageInCallModalEvent, newMessageFromCallStore} from "../../effector/modals"
import { wsCall } from "../../lib/wsConnect";

export default {
    directives: {
    "long-press": LongPress
  },
  components: { VueDragResize, ConfirmCloseCallModal, AudioStreamMeter, StreamCanvas },
  props: ["toggleModalSettings"],
  data() {
    const effector = {
      callInfo: callInfoStateStore,
      allowedToTalk: allowedToTalkMap,
      ownerUUID:ownerUUID,
      amMutedByGlobal,
      newMessageFromCall:newMessageFromCallStore
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      showButtons: true,
      state: store.state,
      ownUUID: store.state.ownUUID,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      pLimitW: 0,
      pLimitH: 0,
      spaceShortcutCallAcceptTimeout: null,
      isIpad: this.$device.ipad,
      isIos: this.$device.ios,
      isLocalSoundMuted: false,
      tenSecondSound: false,
      fiveSecondSound: 0,
      actualSize: {
        remote: 80,
        me: 20,
        position: 0
      },
      ctrlPressed: false,
      originalCallTime: undefined,
      moment: moment,
      secondsBeep1: 0,
      secondsBeep2: 0,
      secondsBeep3: 0,
      isLongPress: false,
      openCameraQuality: false,
      // showStopCallModal: false,
      amIMuted: false,
      // Effector
      ...effector,
    };
  },
  mounted(){
        // this.changeAutoQuality();
    document.addEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
    setTimeout(() => {
      this.showButtons = false
    }, 3000);
  },
  watch: {
    // "state.remoteStreams": function() {
    //   this.changeAutoQuality();
    // }
    newMessageFromCall:{
       handler:function (newMessageFromCall) {
         if(newMessageFromCall){
            for (let uuid in store.state.remoteStreams) {
              wsCall("sendToUUID", uuid, {
                type: "rtcSendMessageToAll",
                message: newMessageFromCall
            });
          }
         }
       }
    }
  },
    unmounted() {
    document.removeEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
  },
  methods: {
    setSendMessageInCallModal(){
      setSendMessageInCallModalEvent(true)
    },
    isWaitingRoomUser(uuid){
      return isWaitingRoomUser(uuid)
    },
        getSubjectVisitor(uuid){
       return getSubjectVisitor(uuid);
    },
    isVisitor(uuid) {
      return isVisitor(uuid);
    },
    getTitelForUuid(uuid) {
      if (store.getTitelForUuid(uuid) !== "unknown") {
        return store.getTitelForUuid(uuid);
      } else {
        return "";
      }
    },
    handleShowButtons(){
      if ( this.showButtons ){
        this.showButtons = !this.showButtons
      }
    },
    stopCall(){
      if (this.$refs.cancelCall) {
        this.$refs.cancelCall.$el.click();
      }
    },
    // closeStopCallModal(){
    //   this.showStopCallModal = false;
    // },
    onKeyUp(event) {
      // console.log('keyup', event.keyCode);
      if (
        this.spaceShortcutCallAcceptTimeout &&
        event.type === "keyup" &&
        event.keyCode === 32
      ) {
        clearTimeout(this.spaceShortcutCallAcceptTimeout);
        this.spaceShortcutCallAcceptTimeout = null;
        if (this.$refs.cancelCall) {
          if(event.target.nodeName !== 'TEXTAREA' && event.target.nodeName !== 'INPUT'){
            // this.$refs.cancelCall.$el.click();
            // this.showStopCallModal = true;
            this.stopCall();
          }
        }
      } else if (event.type === "keyup" && event.keyCode === 32) {
        this.spaceShortcutCallAcceptTimeout = setTimeout(() => {
          this.spaceShortcutCallAcceptTimeout = null;
        }, 300);
      }
    },
    //  changeAutoQuality() {
    //   const peopleInCall = Object.keys(this.state.remoteStreams)
    //     .length;
    //   if (peopleInCall === 1) {
    //     this.state.persisted.rtcVideoQuality = "high";
    //   } else if (peopleInCall === 2) {
    //     this.state.persisted.rtcVideoQuality = "medium";
    //   } else if (peopleInCall > 2) {
    //     this.state.persisted.rtcVideoQuality = "low";
    //   }
    //   cycleUserMedia();
    // },
    setQuality(value) {
      this.state.persisted.rtcVideoQuality = value;
      cycleUserMedia();
      this.openCameraQuality = false;
    },
    openMenuQuality() {
      // triggers after 300ms of mousedown
      if (this.state.persisted.rtcVideo) {
        this.isLongPress = true;
        // open quality menu
        this.openCameraQuality = true;
      }
    },
    onLongPressStop() {
      setTimeout(() => {
        if (this.isLongPress) {
          this.isLongPress = false;
        }
      }, 100);
    },
    requestViewAsSpeaker() {
      callSetWantToSpeak(true);
      // if ( store.state.speakerRequests.indexOf(store.state.ownUUID) === -1 ){
      //   let personRemote = Object.keys(store.state.remoteStreams);
      //   requestSpeakerView(
      //     store.state.presentationView.owner,
      //     store.state.ownUUID
      //   );

      //   store.state.speakerRequests.push(store.state.ownUUID);
      //   for (const key in personRemote) {
      //     let remote_uuid = personRemote[key];
      //     if (store.state.rtc[remote_uuid].ringingState == "connected") {
      //       requestSpeakerView(remote_uuid, store.state.ownUUID);
      //     }
      //   }
      // }
    },
    setFullSizeVideo() {
      callSetRemoteFullSizeFor(store.state.ownUUID);
      // store.setRemotePresentationFullSize(false);
      // store.setRemotePresentationFullSizeUUID(null);
      // let personRemote = Object.keys(store.state.remoteStreams);
      // for (const key in personRemote) {
      //   let remote_uuid = personRemote[key];
      //   if (store.state.rtc[remote_uuid].ringingState == "connected") {
      //     setRemoteFullSize(remote_uuid, null);
      //   }
      // }
    },

    // toggleMuteSound() {
    //   if (this.isGlobalAudioMuted) return;
    //   this.isLocalSoundMuted = !this.isLocalSoundMuted;
    // },

    toggleUseBlurEffect() {
      store.setUseBlurEffect(!this.state.persisted.useBlurEffect);
      // ensureUserStream();
    },
    resizePane(event) {
      // console.log('Resize pan!!!!!!', event)
      this.handleResize();
    },
    handleResize() {
      const el = this.$refs.localVideoDiv;
      if (el) {
        this.pLimitW = el.clientWidth;
        this.pLimitH = el.clientHeight;
      }
    },
    resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
      this.handleResize();
    },
    tryWebcam() {
      return tryWebcam(this.isLocalAudioMuted);
    },
    tryScreenShare() {
      return tryScreenShare();
    },
    closeDisplayMedia() {
      return store.closeDisplayMedia();
    },
    rtcHangup() {
      // this.setdurationMeeting(undefined);
      // this.setOwnerMeeting(undefined);
      // this.setmeetingStartAt(undefined);
      this.notificationEnd = false;
      this.originalCallTime = undefined;
      return rtcHangup();
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getAvatarFromUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    toggleMuteLocalAudio() {
      if (this.isGlobalAudioMuted) return;
      this.amIMuted = !this.amIMuted;
      return toggleMuteLocalAudio();
    },
    setdurationMeeting(time) {
      return store.setdurationMeeting(time);
    },
    setmeetingStartAt(value) {
      return store.setmeetingStartAt(value);
    },
    // setOwnerMeeting(value) {
    //   store.setOwnerMeeting(value);
    // },
    // setshowModalEndMeeting(value) {
    //   return store.setshowModalEndMeeting(value);
    // }
  },
  computed: {
    isGlobalAudioMuted() {
      return this.amMutedByGlobal;
      // console.log('isGlobalAudioMuted local', this.allowedToTalk.length !== 0);
      // return this.allowedToTalk.length !== 0;
    },
    imOnRequest(){
      if ( store.state.speakerRequests.indexOf(store.state.ownUUID) !== -1 ){
        return true;
      }
      return false;
    },
    haveLocalVideo() {
      if (!this.state.localStreams.user) return false;
      for (const track of this.state.localStreams.user.getTracks()) {
        if (track.kind === "video") return true;
      }
      return false;
    },
    isLocalAudioMuted() {
      if (!this.state.localStreams.user) return false;
      if (!this.state.localStreams.lastMicChange) return false; // Just for the reference
      for (const track of this.state.localStreams.user.getTracks()) {
        if (track.kind === "audio") return !track.enabled;
      }
      return false;
    }
  }
};
</script>

<style scoped lang="scss">
.messageToParticipantsBlink {
  animation: blink 2s linear infinite;
}
.messageToParticipants {
  // font-size: 12px;
  z-index: 1;
  position: absolute;
  padding: 10px;
  background: red;
  border-radius: 3px;
  left: 5px;
  top: 60px;
  color: white;
}
#canvasAudioMyLocal{
    position: absolute;
    top: 2px;
    height: 3px;
    width: 100%;
}
.microphonoLevelIconSlash{
  margin-bottom: -18px !important;
}
.microphonoLevelIcon{
    font-size: 29px;
    transform: rotate(90deg);
    margin-right: 13px;
    margin-bottom: -3px;
}
.microphoneLevel{
  position: absolute;
  top: 40%;
  left: -25px;
  width: 100px;
  transform: rotate(270deg);
    z-index: 99999;
}
.bgOnRequest{
  background: #5d5454;
}
.faPhoneRotate {
  transform: rotate(230deg) !important;
}
.cardPositionInfo {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  margin: auto;
}
.cardPositionInfoLogo {
  position: absolute;
  left: 0;
  right: unset;
  bottom: 5px;
  margin: auto;
}
.opacity {
  opacity: 0.4;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}
video {
  display: flex;
 background-color: #424242;
  border-radius: 3px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
}
.h100 {
  height: calc((100% / 4) - 30px);
  z-index: 0;
  position: relative;
}

.oneRemote {
  height: calc(100% - 24px);
  z-index: 0;
  position: relative;
}
.twoRemote {
  height: calc((100% - 24px) / 2);
  z-index: 0;
  position: relative;
}
.threeRemote {
  height: calc((100% - 24px) / 3);
  z-index: 0;
  position: relative;
}
.callerName {
  // position: absolute;
  width: 100%;
  color: white;
  // z-index: 2;
  z-index: 99999999;
  height: 30px;
  padding: 6px;
  background-color: linear-gradient;
  background: rgb(21, 30, 39);
  // background: linear-gradient(
  //   0deg,
  //   rgba(21, 30, 39, 0.42) 0%,
  //   rgba(31, 43, 48, 0.43) 100%
  // );
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.86) 0%,
    rgba(0, 0, 0, 0.77) 100%
  );
  font-size: 15px;
  text-align: center;
}
.rtcStatus {
  font-size: 10px;
  z-index: 1;
  position: absolute;
  padding: 10px;
  background: #000000b5;
  border-radius: 3px;
  left: 5px;
  bottom: 5px;
  color: white;
}
.green {
  color: green;
}

.v-theme--light .noVideoStreamContent,
.v-theme--light .noCameraContent {
  background-color: #f2f2f2 !important;
}

.v-theme--dark .noVideoStreamContent,
.v-theme--dark .noCameraContent {
  background-color: #272727 !important;
}

.videos {
  position: relative;
  height: 100%;
  width: 100%;
  .divButtons {
    display: block;
    position: absolute;
    text-align: center;
    width: 30px;
    height: auto;
    justify-content: center;
    left: 25px;
    top: calc(50% - 90px);
    .redBtn {
      background: red;
      color: white !important;
      border-radius: 4px !important;
    }
  }
  // &:hover {
  //   > .divButtons {
  //     display: block;
  //   }
  // }
  .remote-first {
    width: 100%;
    height: 100%;
    max-height: unset;
    &.sharing {
      position: absolute;
      bottom: 0;
      width: 350px;
      left: 0;
      height: auto;
      animation: width 0.7s linear;
    }
  }
  .remote-second {
    width: 100%;
    height: 100%;
    max-height: unset;
  }
  .noVideoStream {
    position: absolute;
    display: table;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: -10;
    font-weight: bold;
    font-size: 30px;
    .noVideoStreamContent {
      display: table-cell;
      vertical-align: middle;
      background: #f2f2f2f2;
    }
    .noVideoStreamAvatar {
      width: 65px;
      height: 65px;
      border-radius: 10px;
      margin: 10px auto 0px auto;
      padding-bottom: 0px !important;
    }
    .v-avatar .v-image {
      border-radius: 10px !important;
    }
    .callAvatarWrapper {
      padding-bottom: 0px;
      img,
      .v-avatar .v-image {
        width: 65px;
        height: 65px;
        border: 4px solid white;
        margin-bottom: 10px !important;
        border-radius: 10px !important;
      }
    }
  }
  .callingText {
    font-weight: normal;
    font-size: 15px;
  }
  #wave {
    margin-top: -15px;
    .dot {
      background: #2a3133;
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 3px;
      animation: wave 1.3s linear infinite;
    }
    .dot:nth-child(2) {
      animation-delay: -1.1s;
    }
    .dot:nth-child(3) {
      animation-delay: -0.9s;
    }

    @keyframes wave {
      0%,
      60%,
      100% {
        transform: initial;
      }

      30% {
        transform: translateY(-15px);
      }
    }
  }
}
.bg {
  background-color: black;
  z-index: 999999 !important;
}

.buttonsUser{
  &.showButtons{
    display: block;
  }
  display: block;
  height: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 45px;
  padding-top: 7px !important;
  bottom: 0px;
  position: absolute;
  width: 100%;
  z-index: 9999999999999;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  .pUserName {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0px;
    margin-top: 5px;
    color: white;
  }
  .divButtonsLocal {
    button {
      margin-right: 5px;
      color: white;
      // background: #2a3133;
      // border-radius: 4px;
      &:last-of-type {
        margin-right: 0px;
      }
      &.redBtn {
        // background: red;
      }
    }
  }
}

.localVideo {
  /* grid-area: 2 / 3 / span 1 / span 2; */
  z-index: 999;
  // position: absolute;
  // bottom: 0;
  /*width: 340px;*/
  height: 100%;
  right: 0;
  // border: 1px solid #00000066;
  #local-video {
    width: 100%;
    max-height: unset;
    position: relative;
    bottom: 0;
    height: 100%;
  }

.videoIdTag {
  float: left;
  margin-left: 4px;
  color: white;
  position: absolute;
  top: 32px;
  z-index: 9999;
  -webkit-text-stroke: .06em black;
  font-size: 1em;
  font-family: verdana;
}

  #local-screen {
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    max-height: unset;
  }
  .noCameraStream {
    // position: absolute;
    display: table;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: -10;
    font-weight: bold;
    font-size: 30px;
    .noCameraContent {
      display: table-cell;
      vertical-align: middle;
      .emptyDiv {
        height: 30px;
      }
    }
    .imageNotVideo {
      width: 65px;
      height: 65px;
      border-radius: 10px;
      margin: 0 auto;
    }
  }

  .buttonsIpad {
    bottom: 64px !important;
  }
  .buttonsUser {
     &.showButtons{
    display: block;
  }
  display: none;
  height: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 45px;
  padding-top: 7px !important;
  bottom: 0px;
  position: absolute;
  width: 100%;
  z-index: 9999999999999;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      bottom: 64px !important;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      bottom: 64px !important;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      bottom: 64px !important;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
      bottom: 64px !important;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      bottom: 64px !important;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
      bottom: 64px !important;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      bottom: 64px !important;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
      bottom: 64px !important;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
      bottom: 64px !important;
    }
    .pUserName {
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 0px;
      margin-top: 5px;
      color: white;
    }
    .divButtonsLocal {
      button {
        margin-right: 5px;
        color: white;
        // background: #2a3133;
        // border-radius: 4px;
        &:last-of-type {
          margin-right: 0px;
        }
        &.redBtn {
          // background: red;
        }
      }
    }
  }
  .v-list-item {
  min-height: 35px;
}
.v-list-item .v-list-item__title {
  line-height: 1 !important;
}
.blueBackground {
  background: #2a3133;
}
}
</style>