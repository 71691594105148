// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fontsemi[data-v-e3344ea8] {
  font-weight: 600 !important;
}
.footerDate[data-v-e3344ea8] {
  color: white;
  line-height: 2.8em;
  text-align: right;
}
.noteText[data-v-e3344ea8] {
  white-space: pre-line;
}
.v-application .v-card__title[data-v-e3344ea8] {
  font-size: 18px !important;
  margin-bottom: 0px;
}
.messageText[data-v-e3344ea8] {
  overflow: hidden;
  white-space: pre-line;
  user-select: text;
}
.messageTextExpand[data-v-e3344ea8] {
  overflow: hidden;
  overflow-y: auto;
  padding-right: 5px;
  white-space: pre-line;
  user-select: text;
  word-break: break-word;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/userSelectModal.vue","webpack://./userSelectModal.vue"],"names":[],"mappings":"AACA;EACE,2BAAA;ACAF;ADEA;EACE,YAAA;EACA,kBAAA;EACA,iBAAA;ACCF;ADCA;EACE,qBAAA;ACEF;ADAA;EACE,0BAAA;EACA,kBAAA;ACGF;ADAA;EACE,gBAAA;EACA,qBAAA;EACA,iBAAA;ACGF;ADAA;EACE,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;EACA,iBAAA;EACA,sBAAA;ACGF","sourcesContent":["\n.fontsemi {\n  font-weight: 600 !important;\n}\n.footerDate {\n  color: white;\n  line-height: 2.8em;\n  text-align: right;\n}\n.noteText {\n  white-space: pre-line;\n}\n.v-application .v-card__title {\n  font-size: 18px !important;\n  margin-bottom: 0px;\n}\n\n.messageText {\n  overflow: hidden;\n  white-space: pre-line;\n  user-select: text;\n}\n\n.messageTextExpand {\n  overflow: hidden;\n  overflow-y: auto;\n  padding-right: 5px;\n  white-space: pre-line;\n  user-select: text;\n  word-break: break-word;\n}\n",".fontsemi {\n  font-weight: 600 !important;\n}\n\n.footerDate {\n  color: white;\n  line-height: 2.8em;\n  text-align: right;\n}\n\n.noteText {\n  white-space: pre-line;\n}\n\n.v-application .v-card__title {\n  font-size: 18px !important;\n  margin-bottom: 0px;\n}\n\n.messageText {\n  overflow: hidden;\n  white-space: pre-line;\n  user-select: text;\n}\n\n.messageTextExpand {\n  overflow: hidden;\n  overflow-y: auto;\n  padding-right: 5px;\n  white-space: pre-line;\n  user-select: text;\n  word-break: break-word;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
