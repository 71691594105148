<template>
  <!-- debug Call -->
  <div class="debugContainer" v-if="state.nerd">
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header style="background: black; color: white">
          Debug Calls
        </v-expansion-panel-header>
        <v-expansion-panel-content style="background: black; color: white">
          <div v-if="localStats">
            <div>
              <div style="color: green">Server stats</div>
              <div>
                type:
                {{
                  localStats.transport && localStats.transport[0]
                    ? localStats.transport[0].type
                    : ""
                }}
              </div>
              <div>
                p2p:
                {{
                  localStats.transport && localStats.transport[0]
                    ? localStats.transport[0].p2p
                    : ""
                }}
              </div>
               <div>
                E2E:
                {{
                  room && room.isE2EEEnabled()
                    ? 'true'
                    : "false"
                }}
              </div>
              <div>connectionQuality: {{ localStats.connectionQuality }}</div>
              <div>
                maxEnabledResolution: {{ localStats.maxEnabledResolution }}
              </div>
              <div>bitrate: {{ localStats.bitrate }}</div>
              <div>packetLoss: {{ localStats.packetLoss }}</div>
            </div>
            <div class="mt-2" style="color: green">
              Stats {{ state.user.name }} - {{ this.getMyUserId }}
            </div>
            <div>
              Webcam Resolution:
              {{
                localStats.resolution && localStats.resolution[this.getMyUserId]
                  ? localStats.resolution[this.getMyUserId]
                  : ""
              }}
            </div>
            <div v-if="getMyDesktopRoomUserID">
              ShareScreen Resolution:
              {{
                localStats.resolution &&
                localStats.resolution[this.getMyDesktopRoomUserID]
                  ? localStats.resolution[getMyDesktopRoomUserID]
                  : ""
              }}
            </div>
            <div>
              Framerate:
              {{
                localStats.framerate && localStats.framerate[this.getMyUserId]
                  ? localStats.framerate[this.getMyUserId]
                  : ""
              }}
            </div>
            <div
              class="mt-2"
              v-for="participant in remoteParticipants"
              :key="participant.participantId"
            >
              <div style="color: green">
                Stats {{ getNameForUuid(participant.userUUID) }} -
                {{ participant.participantId }}
              </div>
              <div>
                Webcam Resolution:
                {{
                  localStats.resolution &&
                  localStats.resolution[participant.participantId]
                    ? localStats.resolution[participant.participantId]
                    : ""
                }}
              </div>
              <div>
                Framerate:
                {{
                  localStats.framerate &&
                  localStats.framerate[participant.participantId]
                    ? localStats.framerate[participant.participantId]
                    : ""
                }}
              </div>
            </div>
            <!-- <div v-for="(property, key, index) in localStats" :key="index">
              {{ key }} : {{ property }}
            </div> -->
            <div class="d-none">{{ localStats }}</div>
          </div>
          <div v-else>Loading...</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
  <!-- end debug Call -->
</template>
<script>
import store from "../../../../store";
export default {
  components: {},
  props: ["localStats", "remoteParticipants", "room", "getMyDesktopRoomUserID"],
  data() {
    return {
      state: store.state,
    };
  },
  methods: {
    getNameForUuid(userUUID) {
      // console.log(this.room);
      // console.log(this.localStats);
      // console.log("e2ee " + this.room.isE2EEEnabled());
      return store.getNameForUuid(userUUID);
    },
  },
  computed: {
    getMyUserId() {
      if (this.room) {
        return this.room.myUserId();
      }
      return undefined;
    },
  },
};
</script>
<style scoped lang="scss">
.debugContainer {
  position: absolute;
  top: 60px;
  right: 60px;
  z-index: 3;
  background: black;
  color: white;
  padding: 10px;
  max-width: 350px;
  border: 1px solid red;
  max-height: 580px;
  overflow: auto;
  font-size: 12px;
}
</style>
