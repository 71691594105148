// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heightModalMobile[data-v-20e1dd2f] {
  height: calc(100% - 150px);
}
.directionModalMobilie[data-v-20e1dd2f] {
  flex-direction: column;
  text-align: center;
}
.borderRadius4[data-v-20e1dd2f] {
  border-radius: 4px !important;
}
.font15[data-v-20e1dd2f] {
  font-size: 15px;
}
.btns[data-v-20e1dd2f] {
  justify-content: flex-end;
}
.v-application .v-card__title[data-v-20e1dd2f] {
  font-size: 18px !important;
  margin-bottom: 0px;
}
.vueScroll[data-v-20e1dd2f] {
  height: calc(100% - 64px) !important;
}
.vueScroll .__view[data-v-20e1dd2f] {
  width: unset !important;
}
.messageTextWrapper[data-v-20e1dd2f] {
  height: 300px;
  white-space: pre-line;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/notificationsModal.vue","webpack://./notificationsModal.vue"],"names":[],"mappings":"AACA;EACE,0BAAA;ACAF;ADEA;EACE,sBAAA;EACA,kBAAA;ACCF;ADCA;EACE,6BAAA;ACEF;ADAA;EACE,eAAA;ACGF;ADDA;EACE,yBAAA;ACIF;ADFA;EACE,0BAAA;EACA,kBAAA;ACKF;ADHA;EACE,oCAAA;ACMF;ADLE;EACE,uBAAA;ACOJ;ADJA;EACE,aAAA;EACA,qBAAA;ACOF","sourcesContent":["\n.heightModalMobile {\n  height: calc(100% - 150px);\n}\n.directionModalMobilie {\n  flex-direction: column;\n  text-align: center;\n}\n.borderRadius4 {\n  border-radius: 4px!important;\n}\n.font15 {\n  font-size: 15px;\n}\n.btns {\n  justify-content: flex-end;\n}\n.v-application .v-card__title {\n  font-size: 18px !important;\n  margin-bottom: 0px;\n}\n.vueScroll {\n  height: calc(100% - 64px) !important;\n  .__view {\n    width: unset !important;\n  }\n}\n.messageTextWrapper {\n  height: 300px;\n  white-space: pre-line;\n}\n",".heightModalMobile {\n  height: calc(100% - 150px);\n}\n\n.directionModalMobilie {\n  flex-direction: column;\n  text-align: center;\n}\n\n.borderRadius4 {\n  border-radius: 4px !important;\n}\n\n.font15 {\n  font-size: 15px;\n}\n\n.btns {\n  justify-content: flex-end;\n}\n\n.v-application .v-card__title {\n  font-size: 18px !important;\n  margin-bottom: 0px;\n}\n\n.vueScroll {\n  height: calc(100% - 64px) !important;\n}\n.vueScroll .__view {\n  width: unset !important;\n}\n\n.messageTextWrapper {\n  height: 300px;\n  white-space: pre-line;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
