import { callEndedInfoModalDomain } from './domain';

import { setCallEndedInfoModalEvent, resetCallEndedInfoModalEvent} from './events';

export const callEndedInfoModalStore = callEndedInfoModalDomain.createStore(false, { name: 'callEndedInfoModal' });

callEndedInfoModalStore.on(setCallEndedInfoModalEvent, (state, payload) => {
  return payload;
});

callEndedInfoModalStore.reset(resetCallEndedInfoModalEvent);
// qualityVotingModalStore.watch((state) => { console.log('qualityVotingModalStore', state); });
