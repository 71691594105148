<template>
  <div>
    <v-dialog
    :model-value="showModal"
    @update:model-value="showModal = $event" persistent max-width="570">
      <v-card>
        <div v-for="pingPong in pingsPongsArray" :key="pingPong.uuid">
          <!-- <v-card-title class="text-h5">
            <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
            {{ $t('components.pingPongModal.pingPong') }}
          </v-card-title> -->
          <HeaderModal
            :titleModal="$t('components.pingPongModal.pingPong')"
            :closeModalFunction="setPingPongs(pingPong.uuid, undefined)"
          />
            <v-row flex class="w-100 mx-0 my-0">
              <div class="ml-5 w-200">
                <v-badge
                  bordered
                  overlay
                  height="60"
                  width="60"
                  bottom
                  color="#008000"
                  dot
                  offset-x="22"
                  offset-y="15"
                >
                  <v-avatar tile  size="150" class="borderRadius10">
                    <v-img
                      class="borderRadius10"
                      max-height="150"
                      max-width="150"
                      contain
                      :src="getAvatarForUuid(pingPong.uuid)"
                      lazy-src="assets/default_profile_picture.png"
                    ></v-img>
                  </v-avatar>
                </v-badge>
              </div>
              <div class="w-100-230 pt-2">
                <p class="userNameCard my-0">{{getNameForUuid(pingPong.uuid)}}</p>
                <p class="dataUserCard my-0"  v-if="pingPong.status == 'pong'">
                  {{ getNameForUuid(pingPong.uuid )}} has acknowledged your ping!
                </p>
                <p class="dataUserCard my-0"  v-if="pingPong.status == 'ping'">
                  {{ getNameForUuid(pingPong.uuid )}} has pinged you! Dismiss to acknowledge.
                </p>
              </div>
            </v-row>

             <!-- <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
              <v-row class="px-2 btns w-100 mx-0 my-0">
                <template v-if="pingPong.status == 'ping'">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" icon color="white" @click="setPingPongs(pingPong.uuid, undefined); pongMsg(pingPong.uuid, true);">
                        <font-awesome-icon :icon="['fal', 'table-tennis']" :style="{ fontSize: '20px' }"/>
                      </v-btn>
                    </template>
                    <span>{{ $t('generics.accept') }}</span>
                  </v-tooltip>

                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" icon color="white" @click="setPingPongs(pingPong.uuid, undefined);">

                        <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '20px' }"/>
                      </v-btn>
                    </template>
                    <span>{{ $t('generics.close') }}</span>
                  </v-tooltip>
                </template>

                <template v-if="pingPong.status == 'pong'">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" icon color="white" @click="setPingPongs(pingPong.uuid, undefined);">
                        <font-awesome-icon :icon="['fal', 'check']" :style="{ fontSize: '20px' }"/>
                      </v-btn>
                    </template>
                    <span>{{ $t('generics.close') }}</span>
                  </v-tooltip>
                </template>
              </v-row>
            </v-card>
            <v-spacer></v-spacer> -->
            <FooterModal :closeModalFunction="setPingPongs(pingPong.uuid, undefined)" class="mt-4">
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" icon color="white" @click="setPingPongs(pingPong.uuid, undefined); pongMsg(pingPong.uuid, true);">
                    <font-awesome-icon :icon="['fal', 'table-tennis']" :style="{ fontSize: '20px' }"/>
                  </v-btn>
                </template>
                <span>{{ $t('generics.accept') }}</span>
              </v-tooltip>
            </FooterModal>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>


import Vue from "@vue/compat";
import store from "../../store";
import { pongMsg } from "../../lib/wsMsg.js";
import FooterModal from "./modalComponents/footerModal.vue";
import HeaderModal from "./modalComponents/headerModal.vue";

export default {
  components: { FooterModal, HeaderModal },
  data() {
    return {
      state: store.state,
    };
  },

  methods: {
    setPingPongs(uuid, val) {
      return store.setPingPongs(uuid, val);
    },
    pongMsg(...args) {
      return pongMsg(...args);
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
  },
  computed: {
    pingsPongsArray: function() {
      const pinsgPongs = [];
      for (let uuid in store.state.pingModal.pingsPongs) {
        const pingPong = store.state.pingModal.pingsPongs[uuid];
        if (!pingPong) continue;
        pinsgPongs.push({ ...pingPong, uuid });
      }
      return pinsgPongs.sort((a, b) => {
        return (a.uuid || "").localeCompare(b.uuid || "");
      });
    },
    showModal(){
      return this.pingsPongsArray.length !== 0
    },
  }
};

</script>
<style scoped lang="scss">
  .w-100 {
    width: 100%;
  }
  .userNameCard {
    margin-bottom: 0px;
    font-size: 19px;
    color: #2a3133;
  }
  .dataUserCard {
    margin-bottom: 0px;
  }
  .borderRadius10, .v-avatar .v-image{
    border-radius: 10px !important;
  }
  .w-200{
    width: 200px;
  }
  .w-100-230{
    width: calc(100% - 230px);
  }
  .footerModal{
    width: 100%;
    border-radius: 0px !important;
    color: white !important;
    .btns{
          justify-content: flex-end;
    }
  }
</style>
