import { notificationDomain } from './domain';

import { setNotificationModalEvent, resetNotificationModalEvent } from './events';

export const notificationModalStore = notificationDomain.createStore(null, { name: 'notificationModal' });

notificationModalStore.on(setNotificationModalEvent, (state, payload) => {
  return payload;
});

notificationModalStore.reset(resetNotificationModalEvent);
