// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonCallFrontCard[data-v-7a8e2488] {
  background: white;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  font-size: 0px !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/callBackIcon.vue","webpack://./callBackIcon.vue"],"names":[],"mappings":"AACA;EACE,iBAAA;EACA,sBAAA;EACA,uBAAA;EACA,6BAAA;EACA,yBAAA;EACA,6BAAA;EACA,0BAAA;ACAF","sourcesContent":["\n.buttonCallFrontCard {\n  background: white;\n  width: 20px !important;\n  height: 20px !important;\n  border-radius: 50% !important;\n  font-size: 0px !important;\n  margin-bottom: 5px !important;\n  margin-top: 5px !important;\n}\n",".buttonCallFrontCard {\n  background: white;\n  width: 20px !important;\n  height: 20px !important;\n  border-radius: 50% !important;\n  font-size: 0px !important;\n  margin-bottom: 5px !important;\n  margin-top: 5px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
