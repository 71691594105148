// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentDomainList[data-v-39ecc6f8] {
  max-height: 270px;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/beaconDomainWhitelistModal.vue","webpack://./beaconDomainWhitelistModal.vue"],"names":[],"mappings":"AACA;EACE,iBAAA;EACA,cAAA;ACAF","sourcesContent":["\n.contentDomainList {\n  max-height: 270px;\n  overflow: auto;\n}\n",".contentDomainList {\n  max-height: 270px;\n  overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
