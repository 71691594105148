import { showTeamsModalDomain } from './domain';

import { setShowTeamsModalEvent, resetShowTeamsModalEvent} from './events';

export const showTeamsModalStore = showTeamsModalDomain.createStore(false, { name: ' showTeamsModal' });

showTeamsModalStore.on(setShowTeamsModalEvent, (state, payload) => {
  return payload;
});

showTeamsModalStore.reset(resetShowTeamsModalEvent);
// qualityVotingModalStore.watch((state) => { console.log('qualityVotingModalStore', state); });
