// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-container[data-v-39c35e80] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90%;
  width: 100%;
  margin: auto;
  margin-top: 3%;
  padding: 0px 10px;
}
.socialMediaImg[data-v-39c35e80] {
  height: 100%;
  width: 100%;
  object-fit: scale-down;
}
.mainContentContainer .content-container[data-v-39c35e80] {
  height: 90%;
  width: 100%;
  margin-top: 3%;
  padding: 0px 10px;
}
video[data-v-39c35e80] {
  max-width: 100%;
  margin-bottom: 20px;
}
.adobe-link[data-v-39c35e80] {
  text-decoration: none;
  color: blue;
  font-weight: bold;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/socialMediaContent.vue","webpack://./socialMediaContent.vue"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,iBAAA;ACAJ;ADEA;EACI,YAAA;EACA,WAAA;EACA,sBAAA;ACCJ;ADEI;EACI,WAAA;EACA,WAAA;EACA,cAAA;EACA,iBAAA;ACCR;ADGA;EACI,eAAA;EACA,mBAAA;ACAJ;ADGA;EACI,qBAAA;EACA,WAAA;EACA,iBAAA;EACA,gBAAA;ACAJ","sourcesContent":["\n.content-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 90%;\n    width: 100%;\n    margin: auto;\n    margin-top: 3%;\n    padding: 0px 10px;\n}\n.socialMediaImg {\n    height: 100%;\n    width: 100%;\n    object-fit: scale-down;\n}\n.mainContentContainer {\n    .content-container {\n        height: 90%;\n        width: 100%;\n        margin-top: 3%;\n        padding: 0px 10px;\n    }\n}\n\nvideo {\n    max-width: 100%;\n    margin-bottom: 20px;\n}\n\n.adobe-link {\n    text-decoration: none;\n    color: blue;\n    font-weight: bold;\n    margin-top: 10px;\n}\n",".content-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 90%;\n  width: 100%;\n  margin: auto;\n  margin-top: 3%;\n  padding: 0px 10px;\n}\n\n.socialMediaImg {\n  height: 100%;\n  width: 100%;\n  object-fit: scale-down;\n}\n\n.mainContentContainer .content-container {\n  height: 90%;\n  width: 100%;\n  margin-top: 3%;\n  padding: 0px 10px;\n}\n\nvideo {\n  max-width: 100%;\n  margin-bottom: 20px;\n}\n\n.adobe-link {\n  text-decoration: none;\n  color: blue;\n  font-weight: bold;\n  margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
