// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-theme--light .v-dialog .v-card[data-v-1a983dbb] {
  background: rgb(255, 255, 255);
  word-break: break-word;
}
.v-theme--dark .v-dialog .v-card[data-v-1a983dbb] {
  background: rgb(30, 30, 30);
  word-break: break-word;
}
@media screen and (min-width: 993px) {
.v-dialog__content[data-v-1a983dbb] {
    position: absolute !important;
}
}
.font15[data-v-1a983dbb] {
  font-size: 15px;
}
.styleHeader[data-v-1a983dbb] {
  border-bottom: 1px solid var(--v-primary-base) !important;
  margin-bottom: 15px;
  color: var(--v-primary-base) !important;
}
.userStyle[data-v-1a983dbb] {
  padding: 5px;
  width: 90%;
}
.imageLogo[data-v-1a983dbb] {
  display: inline-block;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/paymentModals/paymentInProgress.vue","webpack://./paymentInProgress.vue"],"names":[],"mappings":"AAEE;EACE,8BAAA;EACA,sBAAA;ACDJ;ADKE;EACE,2BAAA;EACA,sBAAA;ACFJ;ADKA;AACE;IAEE,6BAAA;ACHF;AACF;ADKA;EACE,eAAA;ACHF;ADKA;EACE,yDAAA;EACA,mBAAA;EACA,uCAAA;ACFF;ADIA;EACE,YAAA;EACA,UAAA;ACDF;ADGA;EACE,qBAAA;EACA,sBAAA;ACAF","sourcesContent":["\n.v-theme--light .v-dialog {\n  .v-card {\n    background: rgba(255, 255, 255, 1);\n    word-break: break-word;\n  }\n}\n.v-theme--dark .v-dialog {\n  .v-card {\n    background: rgba(30, 30, 30, 1);\n    word-break: break-word;\n  }\n}\n@media screen and (min-width: 993px) {\n  .v-dialog__content {\n    // background: #ffffff;\n    position: absolute !important;\n  }\n}\n.font15 {\n  font-size: 15px;\n}\n.styleHeader {\n  border-bottom: 1px solid var(--v-primary-base) !important;\n  margin-bottom: 15px;\n  color: var(--v-primary-base) !important;\n}\n.userStyle {\n  padding: 5px;\n  width: 90%;\n}\n.imageLogo {\n  display: inline-block;\n  vertical-align: middle;\n}\n",".v-theme--light .v-dialog .v-card {\n  background: rgb(255, 255, 255);\n  word-break: break-word;\n}\n\n.v-theme--dark .v-dialog .v-card {\n  background: rgb(30, 30, 30);\n  word-break: break-word;\n}\n\n@media screen and (min-width: 993px) {\n  .v-dialog__content {\n    position: absolute !important;\n  }\n}\n.font15 {\n  font-size: 15px;\n}\n\n.styleHeader {\n  border-bottom: 1px solid var(--v-primary-base) !important;\n  margin-bottom: 15px;\n  color: var(--v-primary-base) !important;\n}\n\n.userStyle {\n  padding: 5px;\n  width: 90%;\n}\n\n.imageLogo {\n  display: inline-block;\n  vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
