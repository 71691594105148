// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iconsAppWhite[data-v-7f498c44] {
  color: white;
}
.facePayLogo[data-v-7f498c44] {
  height: 20px;
  width: 20px;
}
.imageIcon[data-v-7f498c44] {
  margin-left: -1px;
}
.imgBubble[data-v-7f498c44] {
  margin-left: -5px;
}
.pointer[data-v-7f498c44] {
  cursor: pointer;
}
.blink[data-v-7f498c44] {
  animation: blink-7f498c44 1s linear infinite;
}
@keyframes blink-7f498c44 {
0% {
    opacity: 0;
}
50% {
    opacity: 0.5;
}
100% {
    opacity: 1;
}
}
.ctrm[data-v-7f498c44] {
  bottom: -5px;
  right: 117px;
  z-index: 101;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/settings.vue","webpack://./settings.vue"],"names":[],"mappings":"AACA;EACE,YAAA;ACAF;ADEA;EACE,YAAA;EACA,WAAA;ACCF;ADCA;EACE,iBAAA;ACEF;ADAA;EACE,iBAAA;ACGF;ADDA;EACE,eAAA;ACIF;ADFA;EACE,4CAAA;ACKF;ADFA;AACE;IACE,UAAA;ACKF;ADHA;IACE,YAAA;ACKF;ADHA;IACE,UAAA;ACKF;AACF;ADHA;EACE,YAAA;EACA,YAAA;EACA,YAAA;ACKF","sourcesContent":["\n.iconsAppWhite{\n  color: white;\n}\n.facePayLogo {\n  height: 20px;\n  width: 20px;\n}\n.imageIcon {\n  margin-left: -1px;\n}\n.imgBubble {\n  margin-left: -5px;\n}\n.pointer {\n  cursor: pointer;\n}\n.blink {\n  animation: blink 1s linear infinite;\n}\n\n@keyframes blink {\n  0% {\n    opacity: 0;\n  }\n  50% {\n    opacity: 0.5;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n.ctrm {\n  bottom: -5px;\n  right: 117px;\n  z-index: 101;\n}\n",".iconsAppWhite {\n  color: white;\n}\n\n.facePayLogo {\n  height: 20px;\n  width: 20px;\n}\n\n.imageIcon {\n  margin-left: -1px;\n}\n\n.imgBubble {\n  margin-left: -5px;\n}\n\n.pointer {\n  cursor: pointer;\n}\n\n.blink {\n  animation: blink 1s linear infinite;\n}\n\n@keyframes blink {\n  0% {\n    opacity: 0;\n  }\n  50% {\n    opacity: 0.5;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n.ctrm {\n  bottom: -5px;\n  right: 117px;\n  z-index: 101;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
