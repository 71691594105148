// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-input.v-checkbox {
  height: unset !important;
  min-height: unset !important;
}
.v-input.v-checkbox .v-selection-control {
  min-height: unset !important;
  height: unset !important;
}
.v-input.v-checkbox .v-selection-control .v-selection-control__wrapper {
  height: unset !important;
  width: unset !important;
}
.v-input.v-checkbox .v-selection-control .v-selection-control__wrapper .v-selection-control__input {
  height: unset !important;
  width: unset !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/multiUserCallIconGrid.vue","webpack://./multiUserCallIconGrid.vue"],"names":[],"mappings":"AACA;EACE,wBAAA;EACA,4BAAA;ACAF;ADCE;EACE,4BAAA;EACA,wBAAA;ACCJ;ADAI;EACE,wBAAA;EACA,uBAAA;ACEN;ADDM;EACE,wBAAA;EACA,uBAAA;ACGR","sourcesContent":["\n.v-input.v-checkbox {\n  height: unset !important;\n  min-height: unset !important;\n  .v-selection-control {\n    min-height: unset !important;\n    height: unset !important;\n    .v-selection-control__wrapper{\n      height: unset !important;\n      width: unset !important;\n      .v-selection-control__input{\n        height: unset !important;\n        width: unset !important;\n      }\n    }\n  }\n}\n\n",".v-input.v-checkbox {\n  height: unset !important;\n  min-height: unset !important;\n}\n.v-input.v-checkbox .v-selection-control {\n  min-height: unset !important;\n  height: unset !important;\n}\n.v-input.v-checkbox .v-selection-control .v-selection-control__wrapper {\n  height: unset !important;\n  width: unset !important;\n}\n.v-input.v-checkbox .v-selection-control .v-selection-control__wrapper .v-selection-control__input {\n  height: unset !important;\n  width: unset !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
