<template>
<div class="px-2">
  <div>{{ entry.name }}</div>
  <div v-if="entry.details">{{ entry.details }}</div>
  <span style="font-weight:bold;">{{$t('components.remotePersonCall.moderator')}}</span> 
  <v-row class="mx-0">
    <v-col cols="3" class="px-1 py-1">
      <UserDisplay :userUUID="hostUUID" />
    </v-col>
  </v-row>
</div>
</template>

<script>
import moment from "../../../../sharedsrc/moment";
// import store from "../../../store";
import UserDisplay from '../../userDisplay/userDisplay.vue';
export default {
  props: ['entry'],
  components: {
    UserDisplay,
  },
  data() {
    return {
      // state: store.state,
    };
  },
  mounted(){
    
  },
  computed: {
    getTimeRange(){
      return moment(this.entry.start).format("HH:mm") + '-' + moment(this.entry.end).format("HH:mm");
    },
    creatorUserUUID() {
      return this.entry.creatorUUID;
    },
    hostUUID() {
      return this.entry.host || this.entry.creatorUUID;
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>

</style>