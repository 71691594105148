// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerModal[data-v-1ceb8997] {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
}
.footerModal .btns[data-v-1ceb8997] {
  justify-content: flex-end;
}
.takeSnapshotScreen video[data-v-1ceb8997] {
  height: 200px;
  width: 267px;
}
.takeSnapshotScreenSquare[data-v-1ceb8997] {
  max-width: 200px;
  overflow: hidden;
  min-height: 190px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./websrc/components/camera/camera.vue","webpack://./camera.vue"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,6BAAA;EACA,uBAAA;ACAF;ADCE;EACE,yBAAA;ACCJ;ADEA;EACE,aAAA;EACA,YAAA;ACCF;ADCA;EACE,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;ACEF","sourcesContent":["\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n  .btns {\n    justify-content: flex-end;\n  }\n}\n.takeSnapshotScreen video {\n  height: 200px;\n  width: 267px;\n}\n.takeSnapshotScreenSquare {\n  max-width: 200px;\n  overflow: hidden;\n  min-height: 190px;\n  margin: 0 auto;\n}\n",".footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n}\n.footerModal .btns {\n  justify-content: flex-end;\n}\n\n.takeSnapshotScreen video {\n  height: 200px;\n  width: 267px;\n}\n\n.takeSnapshotScreenSquare {\n  max-width: 200px;\n  overflow: hidden;\n  min-height: 190px;\n  margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
