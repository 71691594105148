// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usersCombo .v-select__slot {
  max-height: 130px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/calls/sidebarCall/addParticipantsCall.vue","webpack://./addParticipantsCall.vue"],"names":[],"mappings":"AAEI;EACE,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,6BAAA;EACA,0BAAA;ACDN","sourcesContent":["\n  .usersCombo {\n    .v-select__slot {\n      max-height: 130px;\n      overflow-y: auto;\n      overflow-x: hidden;\n      margin-bottom: 5px !important;\n      margin-top: 5px !important;\n    }\n  }\n",".usersCombo .v-select__slot {\n  max-height: 130px;\n  overflow-y: auto;\n  overflow-x: hidden;\n  margin-bottom: 5px !important;\n  margin-top: 5px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
