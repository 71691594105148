import { ownUserUUIDState } from '../users';
export function prepadeDataToSendMessageEffector(userUUID, body, header, type, isHtml = false, expiryTs = undefined) {
    const ownUUID = ownUserUUIDState.getState();
    const userToSendMsg = {};
    const users = Array.isArray(userUUID) ? userUUID : [userUUID];
    users.forEach(uuid => {
      if (uuid !== ownUUID){
        userToSendMsg[uuid] = {
            userUUID: uuid,
            read: false,
            replied: false,
            deleted: false,
            viewed: false,
        };
      }
    });
    const dataMsg = {
        body,
        header,
        date: Date.now(),
        users: userToSendMsg,
        isReply: false,
        creatorUUID: ownUUID,
        masterMessageUUID: null,
        parentMessageUUID: null,
        type,
        isHtml,
        expiryTs,
    };
    return dataMsg;
}