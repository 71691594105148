// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outlookSave {
  cursor: pointer;
  font-size: 42px !important;
  padding-left: 10px;
  padding-bottom: 4px;
  margin-bottom: 10px;
  position: relative;
}
.additionalInfo .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
#select_screenSaver .v-menu__content {
  margin-top: 46px;
  margin-left: 24px;
}
#combobox_waitingRoomAssistants .v-menu__content {
  margin-top: 8px;
  margin-left: 24px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/userSettingsNewModal.vue","webpack://./userSettingsNewModal.vue"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,0BAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;ACAF;ADEA;EACE,6BAAA;ACCF;ADEE;EACE,gBAAA;EACA,iBAAA;ACCJ;ADGE;EACE,eAAA;EACA,iBAAA;ACAJ","sourcesContent":["\n.outlookSave {\n  cursor: pointer;\n  font-size: 42px !important;\n  padding-left: 10px;\n  padding-bottom: 4px;\n  margin-bottom: 10px;\n  position: relative;\n}\n.additionalInfo .v-text-field.v-text-field--enclosed .v-text-field__details {\n  margin-bottom: 0px !important;\n}\n#select_screenSaver{\n  .v-menu__content{\n    margin-top: 46px;\n    margin-left: 24px;\n  }\n}\n#combobox_waitingRoomAssistants{\n  .v-menu__content{\n    margin-top: 8px;\n    margin-left: 24px;\n  }\n}\n",".outlookSave {\n  cursor: pointer;\n  font-size: 42px !important;\n  padding-left: 10px;\n  padding-bottom: 4px;\n  margin-bottom: 10px;\n  position: relative;\n}\n\n.additionalInfo .v-text-field.v-text-field--enclosed .v-text-field__details {\n  margin-bottom: 0px !important;\n}\n\n#select_screenSaver .v-menu__content {\n  margin-top: 46px;\n  margin-left: 24px;\n}\n\n#combobox_waitingRoomAssistants .v-menu__content {\n  margin-top: 8px;\n  margin-left: 24px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
