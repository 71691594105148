// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95% !important;
}
.checkboxRSVP .v-input__slot {
  margin-bottom: 0px !important;
  margin-top: 2px !important;
}
.minutesInput label,
.minutesInput fieldset,
.minutesInput input {
  color: white !important;
}
.minutesInput input {
  text-align: center;
}
.w154 label,
.w154 fieldset,
.w154 input {
  color: white !important;
}
.w154 input {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./websrc/components/timeline/newEventForm.vue","webpack://./newEventForm.vue"],"names":[],"mappings":"AACA;EACE,0BAAA;ACAF;ADGE;EACE,6BAAA;EACA,0BAAA;ACAJ;ADIE;;;EAGE,uBAAA;ACDJ;ADGE;EACE,kBAAA;ACDJ;ADKE;;;EAGE,uBAAA;ACFJ;ADIE;EACE,kBAAA;ACFJ","sourcesContent":["\n.v-dialog:not(.v-dialog--fullscreen) {\n  max-height: 95% !important;\n}\n.checkboxRSVP {\n  & .v-input__slot {\n    margin-bottom: 0px !important;\n    margin-top: 2px !important;\n  }\n}\n.minutesInput {\n  label,\n  fieldset,\n  input {\n    color: white !important;\n  }\n  input {\n    text-align: center;\n  }\n}\n.w154 {\n  label,\n  fieldset,\n  input {\n    color: white !important;\n  }\n  input {\n    text-align: center;\n  }\n}\n",".v-dialog:not(.v-dialog--fullscreen) {\n  max-height: 95% !important;\n}\n\n.checkboxRSVP .v-input__slot {\n  margin-bottom: 0px !important;\n  margin-top: 2px !important;\n}\n\n.minutesInput label,\n.minutesInput fieldset,\n.minutesInput input {\n  color: white !important;\n}\n.minutesInput input {\n  text-align: center;\n}\n\n.w154 label,\n.w154 fieldset,\n.w154 input {\n  color: white !important;\n}\n.w154 input {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
