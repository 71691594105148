<template>
  <v-app id="paylink_sandbox">
    <v-container fill-height fluid class="py-0">
      <v-app-bar
        :class="`width100 topBar ${isMobile ? 'px-2' : ''}`"
        :clipped-left="primaryDrawer.clipped"
        app
      >
        <v-layout row wrap justify-start flex-column>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-img
                class="mb-0 mt-0"
                v-on="on"
                v-bind:class="{
                  'ml-1 mt--6': isMobile,
                  'ml-3': isMobile,
                  'mr-2 ml-2': !isMobile,
                  'mt-0 ml-5': !isMobile,
                }"
                :max-height="!isMobile ? 40 : 35"
                :max-width="!isMobile ? 40 : 35"
                src="assets/icon.png"
                contain
                style="cursor: pointer"
              ></v-img>
            </template>
            <span>vOffice</span>
          </v-tooltip>
        </v-layout>
        <v-layout row wrap justify-center>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                :class="`font-weight-bold body-1 ${isMobile ? 'pr-0' : ''}`"
              >
                <template v-if="!isMobile">
                  <a
                    class="linkApp font-weight-bold customTitle"
                    href="https://www.voffice.pro"
                    target="_blank"
                  >
                    vOffice
                    <span
                      :class="` font-weight-bold customTitle ${
                        isMobile &&
                        (state.namespaceSettings.companyInfo.virtualOfficePrefix
                          .length > 21 ||
                          virtualOfficePrefix.length > 21)
                          ? 'font13'
                          : ''
                      }`"
                      >{{
                        state.namespaceSettings.companyInfo
                          .virtualOfficePrefix || virtualOfficePrefix
                      }}</span
                    >
                  </a>
                </template>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-layout>
      </v-app-bar>
      <div class="width100 text-center title" v-if="!hasError">
        {{ $t("components.paypal.waitingRedirect") }}
        <div id="wave">
          <div color="primary" class="dot"></div>
          <div color="primary" class="dot"></div>
          <div color="primary" class="dot"></div>
        </div>
      </div>
      <div class="width100 text-center title" v-else>
         {{ $t("generics.somethingWrong") }}
      </div>
      <!-- <v-app-bar :clipped-left="primaryDrawer.clipped" app>
      </v-app-bar> -->
    </v-container>
  </v-app>
</template>
<script>
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
export default {
  components: {},
  data() {
    return {
      isMobile: isMobile(),
      primaryDrawer: {
        model: null,
        type: "default (no property)",
        clipped: false,
        floating: false,
        mini: false,
      },
      panel: 0,
      state: store.state,
      ownUUID: store.state.ownUUID,
      hasError: undefined,
    };
  },
  created() {
    fetch("/api/paypal/paylink/requestVisitorPayPalPayment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-secret": this.secret,
      },
      body: JSON.stringify({
        uuid: this.ownUUID,
      }),
    })
      .then(res => res.json())
      .then(res => {
        if (res.approval_url) {
          window.location.href = res.approval_url;
        } else {
          this.hasError = true;
        }
      });
  },
  computed: {
    secret() {
      const uuid = this.ownUUID;
      const localSecretKey = "ownSecret_" + uuid;
      return localStorage.getItem(localSecretKey);
    },
    virtualOfficePrefix() {
      return store.getVirtualOfficePrefix();
    },
  },
  methods: {},
  watch: {},
};
</script>
<style scoped lang="scss">
.width100{
  width: 100%!important;
}
.logoMobileGuest {
  position: absolute;
  top: 10px;
}
.topBar {
  box-shadow: none !important;
  // height: 77px !important;
  // height: 93px !important;
  border-bottom: 1px solid #9e9e9e !important;
  width: 0;
}
.linkApp {
  text-decoration: unset;
  color: unset !important;
}
.customTitle {
  font-size: 28px !important;
}
#wave {
  position: relative;
  margin-top: 25px;
  .dot {
    background: #2a3133;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 3px;
    animation: wave 1.3s linear infinite;
  }
  .dot:nth-child(2) {
    animation-delay: -1.1s;
  }
  .dot:nth-child(3) {
    animation-delay: -0.9s;
  }

  @keyframes wave {
    0%,
    60%,
    100% {
      transform: initial;
    }

    30% {
      transform: translateY(-15px);
    }
  }
}
</style>