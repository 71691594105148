<template>
<div>
  <div v-if="1 === 2"> OneCall - Visitor Name: {{name}} Is Connected: {{connected}}</div>
</div>
</template>

<script>
import store from "../../../store";

export default {
  props: ['entry'],
  data() {
    return {
      state: store.state,
    };
  },
  mounted(){
    
  },
  computed: {
    visitorUUID() {
      return this.entry.oneCall.visitorUUID;
    },
    visitorGroupEntry() {
      return this.state.group[this.visitorUUID] || {};
    },
    visitorUser() {
      return this.visitorGroupEntry.user || {};
    },
    name() {
      return this.visitorUser.name || 'Unknown';
    },
    connected() {
      return this.visitorGroupEntry.connected || false;
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>

</style>