// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-container[data-v-7f355b74] {
  display: flex !important;
  align-items: center !important;
  max-width: 100% !important;
}
.item-label[data-v-7f355b74] {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  word-wrap: break-all !important; /* Break words if needed */
  line-height: 1.2 !important; /* Adjust line height for better spacing */
  max-width: calc(100% - 30px) !important; /* Max width considering image width (23px + some padding) */
}

/* Ensure wrapping if text is too long */
@media (max-width: 600px) {
.item-label[data-v-7f355b74] {
    white-space: normal; /* Allowing the text to wrap */
}
}`, "",{"version":3,"sources":["webpack://./websrc/components/firstSetup/companyRegister.vue","webpack://./companyRegister.vue"],"names":[],"mappings":"AACA;EACE,wBAAA;EACA,8BAAA;EACA,0BAAA;ACAF;ADGA;EACE,2BAAA;EACA,8BAAA;EACA,kCAAA;EACA,+BAAA,EAAA,0BAAA;EACA,2BAAA,EAAA,0CAAA;EACA,uCAAA,EAAA,4DAAA;ACAF;;ADGA,wCAAA;AACA;AACE;IACE,mBAAA,EAAA,8BAAA;ACAF;AACF","sourcesContent":["\n.item-container {\n  display: flex!important;\n  align-items: center!important;\n  max-width: 100%!important;\n}\n\n.item-label {\n  overflow: hidden!important;\n  white-space: nowrap!important;\n  text-overflow: ellipsis!important;\n  word-wrap: break-all!important; /* Break words if needed */\n  line-height: 1.2!important; /* Adjust line height for better spacing */\n  max-width: calc(100% - 30px)!important; /* Max width considering image width (23px + some padding) */\n}\n\n/* Ensure wrapping if text is too long */\n@media (max-width: 600px) {\n  .item-label {\n    white-space: normal; /* Allowing the text to wrap */\n  }\n}\n",".item-container {\n  display: flex !important;\n  align-items: center !important;\n  max-width: 100% !important;\n}\n\n.item-label {\n  overflow: hidden !important;\n  white-space: nowrap !important;\n  text-overflow: ellipsis !important;\n  word-wrap: break-all !important; /* Break words if needed */\n  line-height: 1.2 !important; /* Adjust line height for better spacing */\n  max-width: calc(100% - 30px) !important; /* Max width considering image width (23px + some padding) */\n}\n\n/* Ensure wrapping if text is too long */\n@media (max-width: 600px) {\n  .item-label {\n    white-space: normal; /* Allowing the text to wrap */\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
