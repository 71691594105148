import { dailyReportModalDomain } from './domain';

import { setDailyReportModalEvent, resetDailyReportModalEvent} from './events';

export const dailyReportModalStore = dailyReportModalDomain.createStore(false, { name: 'dailyReportModal' });

dailyReportModalStore.on(setDailyReportModalEvent, (state, payload) => {
  return payload;
});

dailyReportModalStore.reset(resetDailyReportModalEvent);
