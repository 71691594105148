<template>
  <v-badge
    bordered
    :class="!!totalNotification ? 'badgetWithData': ''"
    color="error"
    :content="totalNotification"
    :model-value="!!totalNotification"
    :offset-y="isNavbar ? 25 : 5"
    :offset-x="isNavbar ? 20 : 0"
  >
    <v-menu
      offset-y
      :close-on-click="true"
      :max-height="370"
      :max-width="isMobile ? '-webkit-fill-available': '350'"
      :min-width="350"
      :width="350"
      :model-value="menuOpen" @update:model-value="menuOpen = $event"
    >
      <template v-slot:activator="{ props }">
        <div  v-bind="props">
              <v-btn
                :small="isMobile"
                density="comfortable"
                variant="text"
                class="ml-1"
                icon
                 v-tooltip.top="$t('components.drawerContentCard.messages')"
                :color="
                  !hasPrivilege(state.ownUUID)
                    ? '#2a3133'
                    : wholeBarWithColors()
                    ? 'white'
                    : 'black'
                "
                v-on="props"
                @click="setNotificationEffector()"
                id="v-tour-messages"
              >
                <font-awesome-icon
                  v-if="!hasPrivilege(state.ownUUID)"
                  :icon="['fal', 'bell']"
                  :style="{ fontSize: '16px' }"
                />
                <font-awesome-icon
                  v-else
                  :icon="['fal', 'paper-plane']"
                  :style="{ fontSize: '16px' }"
                />
              </v-btn>
        </div>
      </template>
      <ReceivedAndSentMessagesDropDownEffector
        :notificationType="'message'"
        :closeMenu="closeMenu"
        :menuOpen="menuOpen"
      />
    </v-menu>
  </v-badge>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
import ReceivedAndSentMessagesDropDownEffector from "../receivedAndSentMessagesDropDownEffector.vue";
import {
  unreadMessagesToMe,
  dispatchViewedMessageEvent,
  messagesToMe,
} from "../../../effector/message";
import DownloadsDropdown from "../downloadsDropdown.vue";
import { wholeBarWithColor } from "../../../utils/color";
import { hasPrivilege } from "../../../utils/privileges";

export default {
  props: [
    "setTotalNotificationFile",
    "isNavbar",
  ],
  components: { ReceivedAndSentMessagesDropDownEffector, DownloadsDropdown },
  data() {
    const effector = {
      unreadMessagesToMe:unreadMessagesToMe,
      messagesToMe: messagesToMe,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      menuOpen: false,
      isMobile: isMobile(),
      // Effector
      ...effector,
    }
  },
  methods: {
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    closeMenu() {
      this.menuOpen = false;
    },
    setNotificationEffector() {
      this.setAllNotification();
    },
    setAllNotification() {
      if (this.messagesToMe.length > 0) {
        dispatchViewedMessageEvent();
      }
      if (this.state.persisted.totalNotificationFile > 0) {
        this.setTotalNotificationFile(0);
      }
    },
    wholeBarWithColors() {
      return wholeBarWithColor();
    },
  },
  computed: {
    totalNotification() {
      return (
        this.unreadMessagesToMe +
        this.state.persisted.totalNotificationFile
      );
    },
  },
};
</script>
<style lang="scss">
.badgetWithData{
  padding: 0 !important;
}
.v-badge__badge{
  font-weight: 300 !important;
}
</style>
<style scoped lang="scss">
.v-theme--dark .v-menu__content {
  background-color: #1e1e1e;
}
.v-theme--light .v-menu__content {
  background-color: #fff;
}
.v-menu__content {
  // max-height: 370px !important;
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #2a3133;
    border-radius: 10px;
  }
}
</style>