// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-container {
  height: 100%;
  width: 100%;
}
.v-theme--light .swiper-slide {
  background: white;
}
.v-theme--dark .swiper-slide {
  background: #131313;
}
.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100% !important;
}
.externalGridContainer {
  height: calc(100vh - 16px) !important;
  width: 100%;
  overflow-x: hidden !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/regularGrid.vue","webpack://./regularGrid.vue"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,WAAA;ACAF;ADGA;EACE,iBAAA;ACAF;ADGA;EACE,mBAAA;ACAF;ADGA;EACE,oBAAA;EACA,oBAAA;EACA,aAAA;EACA,wBAAA;EACA,qBAAA;EACA,uBAAA;EACA,yBAAA;EACA,sBAAA;EACA,mBAAA;EACA,sBAAA;ACAF;ADEA;EACE,qCAAA;EACA,WAAA;EACA,6BAAA;ACCF","sourcesContent":["\n.swiper-container {\n  height: 100%;\n  width: 100%;\n}\n\n.v-theme--light .swiper-slide {\n  background: white;\n}\n\n.v-theme--dark .swiper-slide {\n  background: #131313;\n}\n\n.swiper-slide {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n  -ms-flex-pack: center;\n  justify-content: center;\n  -webkit-box-align: center;\n  -ms-flex-align: center;\n  align-items: center;\n  width: 100% !important;\n}\n.externalGridContainer{\n  height: calc(100vh - 16px)!important;\n  width: 100%;\n  overflow-x: hidden!important;\n}\n",".swiper-container {\n  height: 100%;\n  width: 100%;\n}\n\n.v-theme--light .swiper-slide {\n  background: white;\n}\n\n.v-theme--dark .swiper-slide {\n  background: #131313;\n}\n\n.swiper-slide {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n  -ms-flex-pack: center;\n  justify-content: center;\n  -webkit-box-align: center;\n  -ms-flex-align: center;\n  align-items: center;\n  width: 100% !important;\n}\n\n.externalGridContainer {\n  height: calc(100vh - 16px) !important;\n  width: 100%;\n  overflow-x: hidden !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
