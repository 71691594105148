import { loggingFilter } from './state';

function doLog(...args) {
  if (!loggingFilter) return;
  const filter = loggingFilter.getState();
  if (!filter) return;

  let wantThisLine = false;
  for (const arg of args) {
    if (typeof arg !== 'string') continue;
    if (filter instanceof RegExp) {
      if (filter.test(arg)) {
        wantThisLine = true;
        break;
      }
    } else if (typeof filter === 'string') {
      if (arg.indexOf(filter) !== -1) {
        wantThisLine = true;
        break;
      }
    } else {
      console.warn('doLog filter is insane', filter);
      break;
    }
  }

  // console.log(...args);
}

function doWarn(...args) {
  console.warn(...args);
}

export function attachLoggingToDomain(domain, prefix='') {
  doLog('attachLoggingToDomain', domain, prefix);

  const getName = () => { return prefix + ':' + domain.shortName; };

  domain.onCreateEvent(event => {
    if (event.parent !== domain) return;
    doLog(getName(), 'onCreateEvent', event.shortName);
    event.watch((payload) => {
      doLog(getName(), 'event', event.shortName, payload);
    });
  });

  domain.onCreateStore(store => {
    if (store.parent !== domain) return;
    doLog(getName(), 'onCreateStore', store.shortName);
    store.watch((state) => {
      doLog(getName(), 'store', store.shortName, state);
    });
  });

  domain.onCreateEffect(effect => {
    if (effect.parent !== domain) return;
    doLog(getName(), 'onCreateEffect', effect.shortName);

    effect.done.watch(({result, params}) => {
      doLog(getName(), 'effect', effect.shortName, params, result);
    });

    effect.fail.watch(({error, params}) => {
      doWarn(getName(), 'effect', effect.shortName, params, error);
    });
  });

  domain.onCreateDomain(subDomain => {
    if (subDomain.parent !== domain) return;
    doLog(getName(), 'onCreateDomain', subDomain.shortName);
    
    attachLoggingToDomain(subDomain, getName());
  });
}