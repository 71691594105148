
<template>
  <v-card class="mx-auto primaryBackground" width="100%" flat>
    <v-list px-0>
      <DrawerContentFavorites
        :mini="mini"
        :active="active"
        :setList="setList"
        v-if="hasPrivilege(ownUUID)"
      />
    </v-list>
  </v-card>
</template>

<script>
import store from "../../store";
import { hasPrivilege } from "../../utils/privileges";
import DrawerContentFavorites from "./drawerContentFavorites.vue";
import DrawerContentUserSearch from "./drawerContentUserSearch.vue";
import Users from "./users.vue";
export default {
  components: { Users, DrawerContentFavorites, DrawerContentUserSearch },
  props: ["active", "setList", "mini"],
  data: () => ({
    state: store.state,
    ownUUID: store.state.ownUUID,
  }),
  methods: {
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
  },
  computed: {
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
  },
};
</script>
<style scoped lang="scss">
.v-list {
  padding: 0;
}

.v-list-item__icon {
  margin-right: 8px !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
</style>

