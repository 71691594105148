import { vOfficeProVersionModalDomain } from './domain';

import { setvOfficeProVersionModalEvent, resetvOfficeProVersionModallEvent} from './events';

export const vOfficeProVersionModalStore = vOfficeProVersionModalDomain.createStore(false, { name: 'vOfficeProVersionModal' });

vOfficeProVersionModalStore.on(setvOfficeProVersionModalEvent, (state, payload) => {
  return payload;
});

vOfficeProVersionModalStore.reset(resetvOfficeProVersionModallEvent);
