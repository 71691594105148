<template>
  <v-dialog
     :model-value="showSendSMSModal"
    @update:model-value="showSendSMSModal= $event"
    persistent
    max-width="570"
    height="329"
    @keydown.esc="closeModal"
  >
    <v-card>
      <v-row flex class="w-100 mx-0 my-0 justify-space-between">
        <v-col class="px-0" align="left">
          <v-card-title class="text-h5">
            <v-img
              src="assets/icon.png"
              max-height="30"
              max-width="30"
              contain
            ></v-img>
            <span class="font-weight-medium pl-4 spanModalTitle">{{
              $t("components.2faRecovery.recoveryTitle")
            }}</span>
          </v-card-title>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-card-subtitle class="pt-2 heightContent">
        <div class="py-2">
          <span>{{ $t("components.2faRecovery.recoveryText") }}</span>
        </div>
      </v-card-subtitle>

      <v-card  class="py-2 pl-4 mt-0 pr-2 footerModal footersBackground">
        <v-row class="px-2 btns mx-0">
          <v-col cols="12" class="px-0 py-0" align="right">
            <v-btn color="primary" :loading="requestingSms" @click="sendSMS">
              {{ $t("components.2faSms.sendSMS") }}
            </v-btn>
            <!-- <v-btn
                  color="primary"
                  @click="cancelBtn">
                <font-awesome-icon
                  :icon="['fal', 'times']"
                  :style="{ fontSize: '25px' }"
                />
            </v-btn> -->
            <v-btn
              @click="cancelBtn"
              color="primary"
              style="border-radius: 4px; min-width: 45px;"
              icon
              tile
              variant = "outlined"
              density="comfortable"
              class="ml-3 mr-2 greyBtn"
              rounded="5px"
            >
              <font-awesome-icon
                :icon="['fal', 'times']"
                :style="{ fontSize: '20px' }"
              />
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../../../store";
export default {
  props: ["toggleShowSendSMS", "showSendSMSModal", "requestSms"],
  data() {
    return {
      state: store.state,
      requestingSms: false,
    };
  },
  methods: {
    async sendSMS() {
      this.requestingSms = true;
      await this.requestSms();
      this.requestingSms = false;
      this.closeModal();
    },
    closeModal() {
      this.cancelBtn();
    },
    cancelBtn() {
      this.toggleShowSendSMS();
    },
  },
  watch: {},
  computed: {},
};
</script>
<style scoped lang="scss">
.spanModalTitle {
  font-size: 18px;
  word-break: break-word;
  max-width: 90%;
  line-height: 2;
}
.heightContent {
  height: 207px;
}
</style>