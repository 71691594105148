<template>
  <div class="InviteFormContainer">
    <v-card class="pl-10 py-6 h100 overflow-y-auto">
      <SambaRoomGrid ref="confRoom" />
    </v-card>
  </div>
</template>
<script>
import store from "../../store";
import { hasPrivilege } from "../../utils/privileges";
import SambaRoomGrid from "./sambaRoomGrid.vue";
import { isMobile } from "../../lib/mobileUtil";
export default {
  props: [],
  components: { SambaRoomGrid },
  data() {
    return {
      isMobile: isMobile(),
      tab: 0,
      state: store.state,
      loadGroup: false,
      membersFromConference: null,
      loadConferenceForm: null,
      isInserting: false,
    };
  },
  watch: {},
  beforeMount() {},
  unmounted() {},
  methods: {
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    getUserTitel(uuid) {
      return store.getTitelForUuid(uuid);
    },
  },
  computed: {},
};
</script>
<style scoped lang="scss">
.InviteFormContainer .v-card {
  box-shadow: none;
}
.InviteFormContainer {
  min-height: calc(100vh - 124px);
  height: 100%;
  // overflow: auto;
  position: relative;
}
.h100 {
  height: 100%;
}
.overflow-y-auto{
  overflow-y: auto !important;
}
</style>
