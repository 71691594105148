<template>
  <div>
    <div class="containerIframe" :class="inBridgeCall ? 'containerIframeCall': 'containerIframe'" v-if="iframeUrl">
      <iframe
          width="100%"
          height="100%"
          frameborder="0"
          style="border: 0"
          :src="iframeUrl"
          allow="camera; microphone; display-capture; autoplay;"
          allowfullscreen
          :class="inBridgeCall ? 'iframeCall': ''"
        ></iframe>
    </div>
  </div>
</template>
<script>
import store from "../../../store";
export default {
  components: {},
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      resultUuid: null,
      iframeUrl: null,
      amInCall: false,
    };
  },
  watch: {
    $route: {
      handler: function (to, from) {
        if (to !== from) {
          this.getIframeUrl();
        }
      },
    },
    amInCall: {
      immediate: true,
      handler: function(value) {
        if (value && this.state.user.activity !== 'No Calls' && this.state.user.noCallsAdditionalInfo !== 'components.conferenceForm.externalConference') {
          this.state.user.originalActivity = this.state.user.activity;
          store.setActivityUser('No Calls');
          store.setNoCallsAdditionalInfo('components.conferenceForm.externalConference');
        } else if (this.state.user.activity === 'No Calls' && this.state.user.noCallsAdditionalInfo === 'components.conferenceForm.externalConference') {
          this.state.user.activity = this.state.user.originalActivity;
          store.setNoCallsAdditionalInfo('');
        }
      }
    }
  },
  created() {},
  mounted() {
    this.getIframeUrl();
    window.document.addEventListener('syncExternalCall', this.syncExternalUserCall, false);
  },
  beforeUnmount() {},
  unmounted() {
    // Remove event listener
    window.document.removeEventListener('syncExternalCall', this.syncExternalUserCall, false);
    this.clearSessionStorage();
  },
  methods: {
    clearSessionStorage() {
      if (this.resultUuid) {
        sessionStorage.removeItem('userState_' + this.resultUuid);
        sessionStorage.removeItem('persistedState_' + this.resultUuid);
      }
    },
    syncExternalUserCall(e) {
      this.amInCall = e.detail?.inCall;
    },
    getNamespace() {
      return this.$route.params.namespace;
    },
    async getIframeUrl(){
      this.iframeUrl = null;
      const namespace = this.getNamespace();
      const result = await fetch(`/api/externalCall/getAuth/${namespace}?uuid=${this.ownUUID}`, {
        method: 'GET',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-secret": this.secret,
        },
      }).then(res => res.json());
      if (result.success) {
        this.clearSessionStorage();
        const newUUID = this.resultUuid = result.uuid;
        const persistedData = JSON.parse(localStorage.getItem("persistedState_" + store.state.ownUUID));
        sessionStorage.setItem(`persistedState_${newUUID}`, JSON.stringify(persistedData));
        this.iframeUrl = `${location.origin}/#${result.uuid}_${result.secret}_${result.namespace}`;
      } else {
        this.iframeUrl = '/unsupported.html';
      }
    }
  },
  computed: {
    isExternalUser() {
      return this.state.group[this.state.ownUUID].externalCall;
    },
    inBridgeCall() {
      return this.amInCall;
    },
    secret() {
      const uuid = this.ownUUID;
      const localSecretKey = "ownSecret_" + uuid;
      return localStorage.getItem(localSecretKey);
    },
  },
};
</script>
<style scoped lang="scss">
  .containerIframe{
    width: 100%;
    height: 90vh;
  }
  .containerIframeCall{
    height: 100vh;
     position: fixed;
     top: 0;
     width: 100vw;
     left: 0;
     z-index: 99999;
  }
  .iframeCall{
    position: absolute;
  }
</style>
