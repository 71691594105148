// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-card[data-v-66d3a3cf] {
  box-shadow: none !important;
}
.v-application .v-card__title[data-v-66d3a3cf] {
  font-size: 18px !important;
  margin-bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/replyMessageEffector.vue","webpack://./replyMessageEffector.vue"],"names":[],"mappings":"AACA;EACE,2BAAA;ACAF;ADEA;EACE,0BAAA;EACA,kBAAA;ACCF","sourcesContent":["\n.v-card {\n  box-shadow: none !important;\n}\n.v-application .v-card__title {\n  font-size: 18px !important;\n  margin-bottom: 0px;\n}\n",".v-card {\n  box-shadow: none !important;\n}\n\n.v-application .v-card__title {\n  font-size: 18px !important;\n  margin-bottom: 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
