// import store from '../../store';
import { wsConnectedEvent } from '../ws';
import { purgeMyVisitorsEffect } from './effects'

// On connected, run visitor purge check 
wsConnectedEvent.watch(() => {
    // purgeMyVisitorsEffect(); removed auto delete #2821
});

purgeMyVisitorsEffect.done.watch(({ result }) => {
    // console.log('purgeMyVisitorsEffect done', result); 
});

purgeMyVisitorsEffect.fail.watch(({ error }) => {
    // console.log('purgeMyVisitorsEffect fail', error);
});
