// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.holidaysDatePickers .v-picker.v-sheet {
  overflow-y: auto !important;
}
.holidaysDatePickers .v-date-picker-years {
  overflow: hidden !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/holidaysCalendarModal.vue","webpack://./holidaysCalendarModal.vue"],"names":[],"mappings":"AAEE;EACI,2BAAA;ACDN;ADGE;EACE,2BAAA;ACDJ","sourcesContent":["\n.holidaysDatePickers{\n  .v-picker.v-sheet{\n      overflow-y: auto !important;\n  }\n  .v-date-picker-years{\n    overflow: hidden !important;\n  }\n}\n",".holidaysDatePickers .v-picker.v-sheet {\n  overflow-y: auto !important;\n}\n.holidaysDatePickers .v-date-picker-years {\n  overflow: hidden !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
