import store from '../store';
import { getStatusColorByUser } from '../utils/status';

export function hasDasdUserId(uuid) {
  if (store.state.group[uuid] && store.state.group[uuid].user && store.state.group[uuid].user.dasdUserId && store.state.group[uuid].user.dasdUserId.length > 0) {
    return true;
  } else {
    return false;
  }
}
export function hasOMKUserId(uuid) {
  if (store.state.group[uuid] && store.state.group[uuid].user && store.state.group[uuid].user.omk && store.state.group[uuid].user.omk.length > 0) {
    return true;
  } else {
    return false;
  }
}

export function hasActiveBeaconUserId(uuid) {
  if (store.state.group[uuid] && store.state.group[uuid].user && store.state.group[uuid].user.userSettings && store.state.group[uuid].user.userSettings.beacon) {
    return true;
  } else {
    return false;
  }
}

export function hasDasdGuestId(person) {
  if (person && person.user && person.user.visitor && person.user.visitorData.isDASD) {
    return true;
  } else {
    return false;
  }
}

function activateExternalServices(uuid) {
  if (store.state.group[uuid] && store.state.group[uuid].user && store.state.group[uuid].user.externalServicesActives && store.state.group[uuid].user.externalServicesActives.length > 0) {
    return true;
  } else {
    return false;
  }
}
export function getActivateExternalServices(uuid) {
  if (store.state.group[uuid] && store.state.group[uuid].user && store.state.group[uuid].user.externalServicesActives && store.state.group[uuid].user.externalServicesActives.length > 0) {
    return store.state.group[uuid].user.externalServicesActives;
  } else {
    return [];
  }
}

function externalServicesStatus(uuid) {
  if (store.state.group[uuid] && store.state.group[uuid].user && store.state.group[uuid].user.externalServicesStatus && store.state.group[uuid].user.externalServicesStatus.length > 0) {
    return store.state.group[uuid].user.externalServicesStatus;
  } else {
    return [];
  }
}

export function isExternalServicesStatusColorActive(user) {
  const color = getStatusColorByUser(user || {}, (store.state.group[user.uuid] || {}).connected || false);
  if (color === '#008000' && externalServicesStatus(user.uuid).indexOf('green') !== -1) {
    return true;
  }
  if (color === '#ffa500' && externalServicesStatus(user.uuid).indexOf('yellow') !== -1) {
    return true;
  }
  if (color === '#ff0000' && externalServicesStatus(user.uuid).indexOf('red') !== -1) {
    return true;
  }
  return false;
}

export function isExternalServicesIconActive(user) {
  if (activateExternalServices(user.uuid)) {
    return isExternalServicesStatusColorActive(user);
  } else {
    return false;
  }
}

// export function dasdStatusColor(uuid) {
//   if (hasDasdUserId(uuid)) {
//     const dasdUserStatus = store.state.group[uuid].user.dasdUserStatus;
//     switch (dasdUserStatus) {
//       case 'inactive':
//         return '#ffa500';
//       case 'active':
//         return '#008000';
//       case 'busy':
//         return '#008000';
//       default:
//         return '#ffa500';
//     }
//   }
// }
