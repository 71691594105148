<template >
  <v-menu v-model="isMenuOpen" offset-y :close-on-click="true" :close-on-content-click="true" :max-height="370" :max-width="350">
    <template v-slot:activator="{ props }">
      <div  v-bind="props" @click="setTotalNotificationCall(0)">
        <v-badge
          bordered
          color="error"
          :class="!!totalNotificationCall ? 'badgetWithData': ''"
          :content="totalNotificationCall"
          :model-value="!!totalNotificationCall"
          class="badgetSize"
          :offset-y="5"
          :offset-x="0"
        >
              <v-btn
                variant="text"
                density="comfortable"
                icon
                :color="phoneColorColor"
                v-tooltip.top="$t('components.drawerContentCard.missedCalls')"
                v-on="props"
                @click="setTotalNotificationCall(0)"
                id="buttonMissedCalls"
              >
                <font-awesome-icon
                  :icon="['fas', 'phone']"
                  :style="{ fontSize: '16px' }"
                  v-if="iconsBlack"
                />
                <font-awesome-icon
                  :icon="['fal', 'phone']"
                  :style="{ fontSize: '16px' }"
                  v-else
                />
              </v-btn>
        </v-badge>
      </div>
    </template>
    <NotificationDropDown :notificationType="'missedCalls'" :closeMenuMissedCalls="closeMenuMissedCalls"/>
  </v-menu>
</template>

<script>
import NotificationDropDown from "../notificationDropDown.vue";
export default {
  data() {
    return {
      isMenuOpen: false
    };
  },
  props: [
    "setTotalNotificationCall",
    "totalNotificationCall",
    "phoneColorColor",
    "iconsBlack",
  ],
  components: { NotificationDropDown },
  methods: {
    closeMenuMissedCalls(){
      this.isMenuOpen = false;
    }
  }
};
</script>
<style scoped lang="scss">
.badgetSize{
  height: 36px;
  width: 36px;
}
.v-menu__content {
  // height: 370px !important;
  overflow: hidden;
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #2a3133;
    border-radius: 10px;
  }
}
</style>