import { mobileModalDomain } from './domain';

import { setMobileInsideCallModalEvent, resetMobileInsideCallModalEvent, setMobileCallModalEvent, resetMobileCallModalEvent } from './events';

export const insideMobileModalStore = mobileModalDomain.createStore(false, { name: 'mobileModal' });
export const callMobileModalStore = mobileModalDomain.createStore(false, { name: 'mobileModal' });

insideMobileModalStore.on(setMobileInsideCallModalEvent, (state, payload) => {
  return payload;
});

insideMobileModalStore.reset(resetMobileInsideCallModalEvent);

callMobileModalStore.on(setMobileCallModalEvent, (state, payload) => {
  return payload;
});

callMobileModalStore.reset(resetMobileCallModalEvent);