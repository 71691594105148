<template>
  <div class="ml-2" v-if="selectedUser">
    <div class="backColor py-1 mx-0">
      <div class="avatarWrapper uploadAvatarCropper">
        <v-btn
          icon
          density="comfortable"
          variant="text"
          color="gray"
          @click="closeCross"
          class="userMgmtHeaderClose"
        >
          <font-awesome-icon
            :icon="['fal', 'times']"
            :style="{ fontSize: '18px' }"
          />
        </v-btn>
        <v-tooltip left v-if="!isInsertingRecord && canDeleteUser">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              icon
              density="comfortable"
              variant="text"
              color="gray"
              @click="showDeleteUserModal = true"
              class="userMgmtHeaderDelete"
            >
              <font-awesome-icon
                :icon="['fal', 'trash']"
                :style="{ fontSize: '17px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t("generics.deleteUserTitle") }}</span>
        </v-tooltip>
        <img class="userMgmtAvatar" :src="getSelectedUserAvatar" />
        <div
          :class="`uploadAvatarButtons px-4 mx-auto ${
            disableAvatarBtn ? 'd-none' : ''
          }`"
          :disabled="disableAvatarBtn"
          flex
        >
          <v-btn
            :class="`avatarChange mr-1 ml-1 ${
              disableAvatarBtn ? 'd-none' : ''
            }`"
            :disabled="disableAvatarBtn"
            flat
            color="primary"
            isSmall="true"
            v-on:click="changeAvatar()"
          >
            <span>{{
              getSelectedUserAvatar === "img/default_profile_picture.png"
                ? $t("generics.addImage")
                : $t("components.userManagement.changePicture")
            }}</span>
          </v-btn>
        </div>
      </div>
    </div>
    <v-row class="mx-0 backColor2">
      <v-col style="text-align: center">
        <span>{{ vofficeName }}</span>
      </v-col>
    </v-row>
    <v-row class="mx-0 backColor">
      <v-col class="cols-12 col px-0 py-0">
        <v-tabs
          centered
          background-color="var(--v-primary-200)"
          class="tabInvite"
          :model-value="tab" 
          @update:model-value="tab = $event"
        >
          <v-tab>
            {{ $t("components.userManagement.general") }}
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <font-awesome-icon
                  v-bind="props"
                  color="#2a3133"
                  :icon="['fal', 'info-circle']"
                  :style="{
                    fontSize: '15px',
                    position: 'relative',
                    marginLeft: '2px',
                  }"
                />
              </template>
              <span>
                {{ $t("components.userManagement.generalTT") }}
              </span>
            </v-tooltip>
          </v-tab>
          <v-tab>
            {{ $t("components.userManagement.administration") }}
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <font-awesome-icon
                  v-bind="props"
                  color="#2a3133"
                  :icon="['fal', 'info-circle']"
                  :style="{
                    fontSize: '15px',
                    position: 'relative',
                    marginLeft: '2px',
                  }"
                />
              </template>
              <span>
                {{ $t("components.userManagement.administrationTT") }}
              </span>
            </v-tooltip>
          </v-tab>
          <v-tab>
            {{ $t("components.userManagement.permissions") }}
             <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <font-awesome-icon
                  v-bind="props"
                  color="#2a3133"
                  :icon="['fal', 'info-circle']"
                  :style="{
                    fontSize: '15px',
                    position: 'relative',
                    marginLeft: '2px',
                  }"
                />
              </template>
              <span>
                {{ $t("components.userManagement.permissionsTabTT") }}
              </span>
            </v-tooltip>
            </v-tab>
        </v-tabs>
       <v-window :model-value="tab" @update:model-value="tab = $event">
          <!--TAB 1-->
          <v-window-item>
            <v-card flat class="detailsCard px-5 pb-5 pt-5">
              <v-row class="userMgmtdetails mx-0">
                <v-col cols="12" class="col-12 px-1 pt-1 pb-3 d-flex">
                  <div class="w100 pr-2">
                    <v-tooltip location="top" max-width="400" :disabled="!userIsConnected">
                      <template v-slot:activator="{ props }">
                        <div v-bind="props">
                          <v-checkbox
                            hide-details
                            :model-value="isAdmin" @update:model-value="isAdmin = $event; changesInForm(true)"
                            class=""
                            label="Administrator"
                            :disabled="userIsConnected"
                          ></v-checkbox>
                        </div>
                      </template>
                      <span>{{ $t("components.userManagement.noChange") }}</span>
                    </v-tooltip>
                    <v-checkbox
                      v-show="canSeeWorkingTime"
                      hide-details
                      :model-value="isHr" @update:model-value="isHr = $event; changesInForm(true)"
                      :disabled="noData"
                      :label="`${$t('components.userManagement.RRHH')} ${$t('generics.comingSoon')}`"
                    ></v-checkbox>
                  </div>
                  <div class="w100 pl-2">
                    <v-checkbox
                      hide-details
                      :model-value="isIntern" @update:model-value="isIntern = $event; changesInForm(true)"
                      :label="$t('components.userManagement.intern')"
                    ></v-checkbox>
                    <v-checkbox
                      v-show="canSeeWorkingTime"
                      hide-details
                      :model-value="countTime" @update:model-value="countTime = $event; changesInForm(true)"
                      :label="$t('components.workingTime.workingTime')"
                    ></v-checkbox>
                  </div>
                  <div class="w100 pl-2" v-if="state.namespaceSettings.showGPT4BOT">
                     <v-checkbox
                      hide-details
                      v-model="isAiUser"
                      @update:model-value="changesInForm(true)"
                      label="AI-Avatar"
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12" class="col-12 px-1 py-1">
                  <v-text-field
                    @change="changesInForm(true)"
                    variant="outlined"
                    :model-value="personTitle" @update:model-value="personTitle = $event"
                    density="compact"
                    autocomplete="off"
                    :label="$t('components.userManagement.title')"
                    hide-details
                    class="mb-5"
                  ></v-text-field>
                  <v-text-field
                    variant="outlined"
                    @change="changesInForm(true)"
                    :model-value="lastName" @update:model-value="lastName = $event"
                    density="compact"
                    autocomplete="off"
                     :label="$t('components.userManagement.surname')"
                    hide-details
                    class="mb-5"
                  ></v-text-field>
                  <v-text-field
                    @change="changesInForm(true)"
                    variant="outlined"
                    :model-value="firstName" @update:model-value="firstName = $event"
                    density="compact"
                    autocomplete="off"
                    :label="$t('components.userManagement.firstName')"
                    hide-details
                    class="mb-5"
                  ></v-text-field>
                  <v-text-field
                    variant="outlined"
                    @change="changesInForm(true)"
                    :rules="vofficeNameRules"
                    :model-value="vofficeName" @update:model-value="vofficeName = $event"
                    density="compact"
                    autocomplete="off"
                    :label="$t('components.userManagement.vOfficeUserName')"
                    hide-details
                    class="mb-5"
                  ></v-text-field>
                  <v-text-field
                    variant="outlined"
                    @change="changesInForm(true)"
                    :model-value="email" @update:model-value="email = $event"
                    density="compact"
                    autocomplete="off"
                    :disabled="!isInsertingRecord"
                    :label="$t('components.userManagement.email')"
                    :rules="emailRules"
                    hide-details
                    class="mb-5"
                  ></v-text-field>
                  <v-text-field
                    density="compact"
                    variant="outlined"
                    @change="changesInForm(true)"
                    :model-value="position" @update:model-value="position = $event"
                    :label="$t('excel.position')"
                    hide-details
                    class="mb-5"
                  />
                  <v-combobox
                    variant="outlined"
                    :model-value="representative" @update:model-value="representative = $event;searchUsers = ''; changesInForm(true);"
                    :items="dataComboboxAllUsers"
                    :search-input.sync="searchUsers"
                    select
                    :label="$t('excel.representative')"
                    hide-details
                    class="mb-5"
                    density="compact"
                    item-title="text"
                    item-value="value"
                    :return-object="true"
                  >
                  </v-combobox>
                  <v-combobox
                    v-show="canSeeWorkingTime && countTime"
                    variant="outlined"
                    :model-value="weekCountTimeModel" @update:model-value="weekCountTimeModel = $event;changesInForm(true)"
                    :items="weeklyTimecountingTemplates"
                    select
                    :label="$t('components.workingTime.workingTime')"
                    hide-details
                    class="mb-5"
                    density="compact"
                    item-value="_id"
                    item-title="name"
                    :return-object="true"
                  >
                  </v-combobox>
                </v-col>
              </v-row>
            </v-card>
          </v-window-item>
          <!--TAB 2-->
          <v-window-item>
            <v-card flat class="detailsCard pa-5">
              <v-row class="userMgmtdetails mx-0">
                <v-col class="col-12 px-1 py-1">
                  <!-- {{  assistants }}
                  {{ dataComboboxAllUsers }} -->
                  <v-combobox
                    variant="outlined"
                    :model-value="assistants" @update:model-value="assistants = $event;searchUsers = ''; changesInForm(true);"
                    :items="dataComboboxAllUsers"
                    :search-input.sync="searchUsers"
                    select
                    :label="$t('excel.assistants')"
                    multiple
                    hide-details
                    class="mb-5"
                    density="compact"
                    item-title="text"
                    item-value="value"
                    :return-object="true"
                  >
                  </v-combobox>
                  <v-text-field
                    variant="outlined"
                    v-on:keypress="hasCorrectPhone($event, phone)"
                    @change="changesInForm(true)"
                    :model-value="phone" @update:model-value="phone = $event"
                    density="compact"
                    autocomplete="off"
                    :label="$t('excel.phone')"
                    hide-details
                    class="mb-5"
                  ></v-text-field>
                  <v-text-field
                    variant="outlined"
                    v-on:keypress="isOnlyNumbers($event)"
                    @change="changesInForm(true)"
                    :model-value="extension" @update:model-value="extension = $event"
                    density="compact"
                    autocomplete="off"
                    :label="$t('excel.extension')"
                    hide-details
                    class="mb-5"
                  ></v-text-field>
                  <v-text-field
                    variant="outlined"
                    v-on:keypress="hasCorrectPhone($event, mobilePhone)"
                    @change="changesInForm(true)"
                    :model-value="mobilePhone" @update:model-value="mobilePhone = $event"
                    density="compact"
                    autocomplete="off"
                    :label="$t('excel.mobilePhone')"
                    hide-details
                    class="mb-5"
                  ></v-text-field>
                  <template v-if="!editingPhone">
                    <div class="tex0t-truncate text-body-2 containerWrapper mb-5">
                      <span :style="phoneNumberValid ? {} : { color: 'red' }">{{
                        $t("components.2faSms.recoveryPhoneNumber")
                      }}</span>
                      {{ recoveryPhone }}
                      <div class="d-flex">
                        <span slot="label">{{
                          $t("components.2faSms.userPhoneNumberLabel")
                        }}</span>
                        <v-checkbox
                          class="mt-0 py-0 px-1"
                          :model-value="dontUsePhone" @update:model-value="dontUsePhone = $event; changesInForm(true)"
                        >
                        </v-checkbox>
                      </div>
                    </div>
                  </template>
                  <template v-if="editingPhone">
                    <vue-tel-input
                      @input="changesInForm(true)"
                      :model-value="recoveryPhone"
                      @update:model-value="recoveryPhone = $event"
                      required
                      defaultCountry="DE"
                      enabledCountryCode
                      @validate="onTelInput"
                      name="number"
                      id="numDisplay"
                      class="
                        focusStyle
                        mb-5
                        form-control
                        text-center
                        input-sm
                        inputPhone2FA
                      "
                      value
                      autocomplete="off"
                      :class="{ borderError: !phoneInputValid }"
                      :inputOptions="{placeholder:  $t('components.userManagement.recoverPinTT')}"
                    ></vue-tel-input>
                  </template>
                  <v-text-field
                    @change="changesInForm(true)"
                    v-model="emailAlias"
                    density="compact"
                    variant="outlined"
                    autocomplete="off"
                    :label="$t('components.userManagement.alternativeEmail')"
                    :rules="emailAliasRules"
                    hide-details
                    class="mb-5"
                  >
                    <template v-slot:append>
                      <v-tooltip top :max-width="520">
                        <template v-slot:activator="{ props }">
                          <v-icon v-bind="props">
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>{{ $t('components.userManagement.alternativeEmailTT') }}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                  <div class="d-flex">
                  <v-checkbox
                  class="mt-0"
                    :model-value="noaccessmail" @update:model-value="noaccessmail = $event; changesInForm(true)"
                    :label="$t('excel.noSendEmail')"
                  ></v-checkbox>
                  <v-tooltip location="top">
                          <template v-slot:activator="{ props }">
                            <font-awesome-icon
                              v-bind="props"
                              color="#2a3133"
                              :icon="['fal', 'info-circle']"
                              :style="{
                                fontSize: '18px',
                                position: 'relative',
                                marginLeft: '4px',
                                marginTop: '4px',
                              }"
                            />
                          </template>
                          <span>
                            {{
                              $t("components.userManagement.sendInvitationTT")
                            }}
                          </span>
                        </v-tooltip>
                  </div>
                  <p class="my-0" v-if="noaccessmail">
                    {{ $t("components.userManagement.noInvitationTT") }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-window-item>
          <!-- TAB 5 -->
          <v-window-item>
            <v-card flat class="detailsCard pa-5">
              <v-col class="userMgmtdetails mx-0">
                <v-row class="col-12 px-1 py-1">
                  <v-tooltip location="top" max-width="400">
                    <template v-slot:activator="{ props }">
                      <v-text-field
                        v-bind="props"
                        density="compact"
                        variant="outlined"
                        @change="changesInForm(true)"
                        :model-value="rmoUserId" @update:model-value="rmoUserId = $event"
                        :label="$t('excel.rmoUserId')"
                        hide-details
                        class="mb-5 w-100"
                      />
                    </template>
                    <span>{{
                      $t("components.userManagement.customerNumberTT")
                    }}</span>
                  </v-tooltip>
                  <v-tooltip location="top" max-width="400">
                    <template v-slot:activator="{ props }">
                      <v-text-field
                        v-bind="props"
                        density="compact"
                        variant="outlined"
                        @change="changesInForm(true)"
                        :model-value="dasdUserId" @update:model-value="dasdUserId = $event"
                        :label="$t('excel.dasdUserId')"
                        hide-details
                        class="mb-5 w-100"
                      />
                    </template>
                    <span>{{
                      $t("components.userManagement.dasdUserNumberTT")
                    }}</span>
                  </v-tooltip>
                  <!-- <v-tooltip location="top" max-width="400">
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        density="compact"
                        variant="outlined"
                        @change="changesInForm(true)"
                        :model-value="omk" @update:model-value="omk = $event"
                        :label="$t('excel.omk')"
                        hide-details
                        class="mb-5"
                      />
                    </template>
                    <span>{{ $t("components.userManagement.omkTT") }}</span>
                  </v-tooltip> -->
                </v-row>
                <v-row class="col-12 px-1 py-1 d-flex">
                  <v-checkbox
                    class="mr-2 mt-0"
                    :model-value="enabledBeacon" @update:model-value="enabledBeacon = $event; changesInForm(true)"
                    hide-details
                    :label="$t('components.userManagement.beaconEnabled')"
                  ></v-checkbox>
                  <v-tooltip location="top" max-width="450">
                    <template v-slot:activator="{ props }">
                      <font-awesome-icon
                        v-bind="props"
                        color="#2a3133"
                        :icon="['fal', 'info-circle']"
                        :style="{
                          fontSize: '17px',
                          top: '7px',
                          position: 'relative',
                        }"
                      />
                    </template>
                    <span>
                      {{ $t("components.userManagement.permissionsTT") }}
                    </span>
                  </v-tooltip>
                </v-row>
                <v-row v-if="enabledBeacon" class="col-12 px-1 py-1 mt-4">
                  <div class="mb-6 w-100">
                    {{ $t("components.userManagement.displayNameInVideo") }}

                  </div>
                  <div class="d-flex w-95 align-baseline">
                    <v-text-field
                      dense
                      outlined
                      @change="changesInForm(true)"
                      v-model="beaconNameActualLanguageModel"
                      :label="`${$t('generics.username')} ${getLanguage}`"
                      hide-details
                      class="mb-5 w-95"
                      />
                      <v-tooltip top>
                          <template v-slot:activator="{ props }">
                            <v-btn v-bind="props" class="ml-1 showMoreLangsButton" icon @click="showModelBeaconName=!showModelBeaconName" >
                                <font-awesome-icon
                                    v-if="!showModelBeaconName"
                                    :icon="['fal', 'chevron-down']"
                                    :style="{ fontSize: '16px' }"
                                    color="primary"
                                  />
                                  <font-awesome-icon
                                    v-else
                                    :icon="['fal', 'chevron-up']"
                                    :style="{ fontSize: '16px' }"
                                    color="primary"
                                  />
                            </v-btn>
                          </template>
                          <span>{{!showModelBeaconName? $t(
                                    "components.adminSettingsModal.moreLanguages"
                                  ) : $t(
                                    "components.adminSettingsModal.hideMoreLangauges"
                                  )}}
                            </span>
                        </v-tooltip>
                  </div>
                  <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
                  <div v-if="showModelBeaconName" v-for="(modelsData, index) in beaconNameOtherLanguageModel" :key="`modelData-${modelsData.lang}-${index}`" class="w-95">
                    <v-text-field
                      dense
                      outlined
                      @change="changesInForm(true)"
                      v-model="modelsData.value"
                      @update:model-value="beaconNameOtherLanguageModel = { lang: modelsData.lang, value:$event };"
                      :label="`${$t('generics.username')}  ${modelsData.lang}`"
                      hide-details
                      class="mb-5 w-100"
                      />
                  </div>
                  <!-- <div>
                    <v-text-field
                      dense
                      outlined
                      @change="changesInForm(true)"
                      v-model="beaconNameES"
                      :label="`${$t('generics.username')} ES`"
                      hide-details
                      class="mb-5"
                      />
                  </div> -->
                </v-row>
              </v-col>
            </v-card>
          </v-window-item>
       </v-window>
        <v-alert v-if="errMsg" type="error" :dismissible="true">{{
          errMsg
        }}</v-alert>
      </v-col>
    </v-row>
    <input
      type="file"
      ref="fileInput"
      class="d-none"
      @change="showCroppieModal($event)"
    />
    <template v-if="showCloseDetailModal">
      <CloseManagementDetailModal
        :showModal="showCloseDetailModal"
        :closeModal="openCloseDetailModal"
        :acceptCloseModal="closeDetails"
      />
    </template>
    <template v-if="showDeleteUserModal">
      <DeleteUserModal
        :showModal="showDeleteUserModal"
        :closeModal="closeDeleteUserModal"
        :deleteUser="deleteUser"
        :userId="userId"
      />
    </template>
  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import moment from "../../../sharedsrc/moment";
import store, { EventBus } from "../../store";
import CloseManagementDetailModal from "../modal/closeManagementDetailModal.vue";
import {
  isEmailRegistered,
  sendInviteMailCustom,
  setRemoteUser,
  createNewUser,
  deleteUserByUUID,
} from "../../lib/wsMsg.js";
import {
  setUploadAvatarModalEvent,
  uploadAvatarModalStore,
} from "../../effector/modals";
import DeleteBasket from "../ui/deleteBasket.vue";
import DeleteUserModal from "../modal/deleteUserModal.vue";
import { hasPrivilege } from "../../utils/privileges.js";

export default {
  props: [
    "user",
    "selectedUser",
    "closeDetails",
    "saveUserData",
    "hideSaveButtons",
    "changesInForm",
    "formIsChanged",
  ],
  components: { DeleteBasket, CloseManagementDetailModal, DeleteUserModal },
  data() {
    const effector = {
      uploadAvatarModal: uploadAvatarModalStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      tab: 0,
      assistants: [],
      searchUsers: null,
      isInserting: false,
      editingPhone: true,
      phoneNumberInput: "",
      phoneNumberParsed: "",
      phoneInputValid: true,
      hours: 0,
      homeHours: 0,
      isAdmin: false,
      isIntern: false,
      isHr: false,
      isAiUser: false,
      lastLogin: "",
      absence: "",
      // first form
      avatar: "img/default_profile_picture.png",
      personTitle: "",
      firstName: "",
      lastName: "",
      vofficeName: "",
      // second form
      email: "",
      phone: "",
      extension: "",
      mobilePhone: "",
      recoveryPhone: "",
      noaccessmail: false,
      // fourth form
      userId: "",
      position: "",
      location: "",
      qualification: "",
      company: "",
      // fifth form
      rmoUserId: 0,
      dasdUserId: 0,
      omk: 0,
      permissions: 0,
      noData: true,
      errMsg: "",
      emailRules: [
        (v) => !!v && !!v.trim().length,
        (v) => /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v),
      ],
      emailAliasRules: [
        (v) => (!v && !v.trim().length) || /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v),
      ],
      vofficeNameRules: [(v) => !!v && !!v.trim().length],
      showCloseDetailModal: false,
      originalAssistants: [],
      showDeleteUserModal: false,
      enabledBeacon: false,
      countTime: undefined,
      weekCountTimeModel: undefined,
      weeklyTimecountingTemplates: store.state.namespaceSettings.weeklyTimecountingTemplates,
      representative: "",
      emailAlias: "",
      beaconNameDE: "",
      beaconNameEN: "",
      beaconNameES: "",
      showModelBeaconName: false,
      // Effector
      ...effector,
    };
  },
  watch: {
    selectedUser() {
      this.originalAssistants = [];
      this.loadFromFresh();
    },
  },
  mounted() {
    EventBus.$on("saveUserMgmtData", this.updateRecord);
    this.loadFromFresh();
  },
  unmounted(){
    EventBus.$off("saveUserMgmtData", this.updateRecord);
  },
  methods: {
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    closeDeleteUserModal() {
      this.showDeleteUserModal = false;
    },
    closeCross() {
      if (this.formIsChanged) {
        this.openCloseDetailModal();
        this.changesInForm(false);
      } else {
        this.closeDetails();
      }
    },
    openCloseDetailModal() {
      this.showCloseDetailModal = !this.showCloseDetailModal;
    },
    async deleteUser(userUUID) {
      await deleteUserByUUID(userUUID);
      this.closeDetails();
      this.closeDeleteUserModal();
    },
    isOnlyNumbers(event) {
      if (!/\d/.test(event.key)) {
        return event.preventDefault();
      }
    },
    hasCorrectPhone(event, message) {
      if (message && message.length && !/\d/.test(event.key)) {
        return event.preventDefault();
      } else if (!/\+|\d/.test(event.key)) {
        return event.preventDefault();
      }
    },
    fixSelection(uuid) {
      EventBus.$emit("selectTableItem", uuid);
    },
    isValidEmail(email) {
      return /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(email);
    },
    onTelInput({ number, valid }) {
      if (number && valid) {
        this.phoneNumberParsed = number.replace(/[-\s]/g, "");
      }
      this.phoneInputValid = valid;
    },
    showCroppieModal(e) {
      let temporal = {};
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      var reader = new FileReader();
      reader.onload = (e) => {
        temporal = {
          showModal: true,
          userId: this.userId,
          imageUrl: e.target.result || "",
        };
        setUploadAvatarModalEvent(temporal);
      };
      reader.readAsDataURL(files[0]);
    },
    async changeAvatar() {
      if (this.isInsertingRecord) {
        const id = await this.updateRecord(false);
        if (id) {
          this.isInserting = false;
        }
      }
      this.$refs.fileInput.click();
    },
    loadFromFresh() {
      if (this.selectedUser.uuid === "") {
        this.isInserting = true;
      } else {
        this.isInserting = false;
      }
      this.isAdmin = this.selectedUser.permissions === 1 ? true : false;
      this.isIntern = this.selectedUser.intern;
      this.isHr = false;
      this.isAiUser = this.selectedUser.aiUser;
      this.avatar =
        this.selectedUser.avatar || "img/default_profile_picture.png";
      this.personTitle = this.selectedUser.titel || "";
      this.firstName = this.selectedUser.firstName || "";
      this.lastName = this.selectedUser.lastName || "";
      this.vofficeName = this.selectedUser.name;
      this.assistants = this.fixAssistants();
      // second form
      this.email = this.selectedUser.email || "";
      this.phone = this.selectedUser.phone || "";
      this.extension = this.selectedUser.extension || "";
      this.hours = this.selectedUser.hours || "";
      this.homeHours = this.selectedUser.homeHours || "";
      this.mobilePhone = this.selectedUser.mobilePhone || "";
      this.recoveryPhone = this.selectedUser.recoveryPhone || "";
      this.phoneNumberParsed = this.selectedUser.recoveryPhone || "";
      this.noaccessmail =
        this.selectedUser.accessmail !== undefined &&
        this.selectedUser.accessmail != 1;
      // third form
      this.lastLogin = this.canSeeLastLogin
        ? moment(new Date(this.selectedUser.presentFrom)).format(
            "DD.MM.YY H:mm"
          ) || ""
        : "";
      this.absence = this.holidaysInfo;
      // fourth form
      this.userId = this.selectedUser.uuid || "";
      this.position = this.selectedUser.position || "";
      this.location = this.selectedUser.location || "";
      this.qualification = this.selectedUser.qualification || "";
      this.company = this.selectedUser.company || "";
      // fifth form
      this.rmoUserId = this.selectedUser.rmoUserId || "";
      this.dasdUserId = this.selectedUser.dasdUserId || "";
      this.omk = this.selectedUser.omkUserStatus || "";
      this.permissions = this.selectedUser.permissions;
      this.enabledBeacon = this.selectedUser.enabledBeacon || false;
      this.beaconNameDE = this.selectedUser.beaconNameDE || "";
      this.beaconNameEN = this.selectedUser.beaconNameEN || "";
      this.beaconNameES = this.selectedUser.beaconNameES || "";
      this.countTime = this.selectedUser.countTime || false;
      this.weekCountTimeModel = this.state.namespaceSettings.weeklyTimecountingTemplates.find(template => template._id === this.selectedUser.weekCountTimeUUID) || "";
      this.representative = this.selectedUser.representative || "";
      this.emailAlias = this.selectedUser.emailAlias || "";
    },
    async updateRecord(closeAfterSave) {
      if (this.formIsChanged) {
        this.errMsg = "";
        let hasError = false;
        let uuid = null;
        const data = {
          avatar: this.avatar || "img/default_profile_picture.png",
          titel: (this.personTitle || "").trim(),
          firstName: (this.firstName || "").trim(),
          lastName: (this.lastName || "").trim(),
          name: (this.vofficeName || "").trim(),
          assistants: this.extractAssistants(),
          phone: this.phone || "",
          extension: this.extension,
          mobilePhone: (this.mobilePhone || "").trim(),
          recoveryPhone: (this.phoneNumberParsed || "").trim().replace(/[-\s]/g, ""),
          position: (this.position || "").trim(),
          location: (this.location || "").trim(),
          qualification: (this.qualification || "").trim(),
          hours: this.hours || 0,
          homeHours: this.homeHours || 0,
          company: (this.company || "").trim(),
          rmoUserId: (this.rmoUserId || "").trim(),
          dasdUserId: (this.dasdUserId || "").trim(),
          omkUserStatus: (this.omk || "").trim(),
          enabledBeacon: this.enabledBeacon,
          beaconNameDE: this.beaconNameDE,
          beaconNameEN: this.beaconNameEN,
          beaconNameES: this.beaconNameES,
          intern: this.isIntern,
          aiUser: this.isAiUser,
          countTime: this.countTime,
          weekCountTimeUUID: this.weekCountTimeModel?._id || "",
          representative: this.representative,
          emailAlias: (this.emailAlias || "").trim(),
        };
        if (this.formValidate && !this.isInsertingRecord) {
          // UPDATE existing user
          const options = {};
          if(this.isIntern !== this.selectedUser.intern) {
            options.intern = this.isIntern;
            this.selectedUser.intern = options.intern;
          }
          if(this.isAiUser !== this.selectedUser.aiUser) {
            options.aiUser = this.isAiUser;
            this.selectedUser.aiUser = options.aiUser;
          }
          if (+this.isAdmin !== this.selectedUser.permissions) {
            options.permissions = +this.isAdmin;
            this.selectedUser.permissions = options.permissions;
          }
          if (
            this.noaccessmail !==
            !!(
              this.selectedUser.accessmail !== undefined &&
              this.selectedUser.accessmail != 1
            )
          ) {
            data.accessmail = this.noaccessmail ? 0 : 1;
            this.selectedUser.accessmail = data.accessmail;
          }
          await setRemoteUser(this.userId, data, options);
        } else if (this.hasMinInfo && this.isInsertingRecord) {
          // INSERT NEW USER
          const options = {};
          data.name = this.vofficeName.trim();
          data.email = this.email.trim().toLowerCase();
          if (this.isAdmin) options.createAdmin = true;
          const emailRegistered = await isEmailRegistered(this.email);
          if (!emailRegistered.found) {
            uuid = emailRegistered.key;
            if (this.noaccessmail) data.accessmail = 0;
            await createNewUser(data, uuid, options);
            if (!this.noaccessmail) {
              await sendInviteMailCustom(uuid, {
                name: data.name,
                email: data.email,
              });
            }
            this.fixSelection(uuid);
            return uuid;
          } else {
            this.errMsg = this.$t("components.inviteGuestPanel.errAlreadyUser");
            hasError = true;
          }
        }
        // update assists for the new user assistants
        if (this.originalAssistants.length > 0) {
          for (let i = 0; i < this.originalAssistants.length; i++) {
            const assistantUUID = this.originalAssistants[i];
            if (data.assistants.indexOf(assistantUUID) === -1) {
              // original assistant removed
              // remove in assists the user uuid
              const assists = this.state.group[assistantUUID].user.assists;
              await setRemoteUser(assistantUUID, {
                assists: assists.filter((uuid) => uuid !== this.userId),
              });
            }
          }
        }
        for (let i = 0; i < data.assistants.length; i++) {
          const assistantUUID = data.assistants[i];
          const assists = this.state.group[assistantUUID].user.assists || [];
          if (assists.indexOf(this.userId) == -1) {
            await setRemoteUser(assistantUUID, {
              assists: [...assists, this.userId],
            });
          }
        }
        if (!hasError) {
          await this.changesInForm(false);
          this.closeDetails();
        }
        if (!hasError && closeAfterSave) {
          this.goToHome();
        }
      } else {
        this.closeDetails();
        if (closeAfterSave) this.goToHome();
      }
    },
    goToHome() {
      store.setCurrentContentVisile("/home", true, this.$router);
    },
    extractAssistants() {
      let normalizedAssistants = [];
      this.assistants.forEach((element) => {
        if (element.value) {
          normalizedAssistants.push(element.value);
        }
      });
      return normalizedAssistants;
    },
    fixAssistants() {
      let results = [];
      if (
        this.selectedUser &&
        (this.selectedUser.assistants || []).length > 0
      ) {
        this.selectedUser.assistants.forEach((element) => {
          if (
            (((store.state.group[element] || {}).user || {}).name || "") !== ""
          ) {
            const data = {
              text: store.state.group[element].user.name,
              value: element,
            };
            results.push(data);
            this.originalAssistants.push(data.value);
          }
        });
      }
      return results;
    },
  },
  computed: {
    beaconNameActualLanguageModel: {
      get() {
        switch (this.getLanguage) {
          case 'DE':
            return this.beaconNameDE;
          case 'EN':
            return this.beaconNameEN;
          case 'ES':
            return this.beaconNameES;
          default:
            return ''; // or any default value
        }
      },
      set(newValue) {
          switch (this.getLanguage) {
          case 'DE':
            this.beaconNameDE = newValue;
            break;
          case 'EN':
            this.beaconNameEN = newValue;
            break;
          case 'ES':
            this.beaconNameES = newValue;
            break;
          // Handle other cases if necessary
        }
      }
    },
    beaconNameOtherLanguageModel: {
      get() {
        switch (this.getLanguage) {
          case 'DE':
            return [{ lang: 'EN', value: this.beaconNameEN }, { lang: 'ES', value: this.beaconNameES }];
          case 'EN':
            return [{ lang: 'DE', value: this.beaconNameDE }, { lang: 'ES', value: this.beaconNameES }];
          case 'ES':
            return [{ lang: 'DE', value: this.beaconNameDE }, { lang: 'EN', value: this.beaconNameEN }];
          default:
            break;
        }
      },
      set(newValue) {
        if (newValue && newValue.lang && newValue.value !== undefined) {
          switch (newValue.lang) {
            case 'DE':
              this.beaconNameDE = newValue.value;
              break;
            case 'EN':
              this.beaconNameEN = newValue.value;
              break;
            case 'ES':
              this.beaconNameES = newValue.value;
              break;
            // Handle other cases if necessary
          }
        }
      }
    },
    getLanguage() {
      return this.state.user.language.toUpperCase();
    },
    canDeleteUser() {
      return this.amIAdmin && this.selectedUser.uuid !== this.state.ownUUID;
    },
    canSeeLastLogin() {
      return this.amIAdmin && this.state.namespaceSettings.loginDaily;
    },
    canSeeWorkingTime() {
      return this.amIAdmin && this.state.namespaceSettings.showWorkingTime;
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
    userIsConnected() {
      if (
        this.state.group[this.selectedUser.uuid] &&
        this.state.group[this.selectedUser.uuid].connected
      ) {
        return true;
      }
      return false;
    },
    disableAvatarBtn() {
      this.hideSaveButtons(!this.hasMinInfo);
      return !this.hasMinInfo;
    },
    isInsertingRecord() {
      return this.isInserting;
    },
    getSelectedUserAvatar() {
      const useravatar =
        ((this.state.group[this.selectedUser.uuid] || {}).user || {}).avatar ||
        "img/default_profile_picture.png";
      this.avatar = useravatar;
      return useravatar;
    },
    dataComboboxAllUsers() {
      const membersList = JSON.parse(JSON.stringify(this.state.group));
      if (this.selectedUser?.uuid && membersList && membersList[this.selectedUser?.uuid]) {
        delete membersList[this.selectedUser?.uuid]
      }
      let finalResult = [];
      for (let uuid in membersList) {
        let person = membersList[uuid];
        if (person && person.user && person.user.name && person.user.uuid && this.hasPrivilege(person.user.uuid)) {
          const item = {
            value: person.user.uuid,
            text: person.user.name,
          };
          finalResult.push(item);
        }
      }
      return finalResult;
    },
    formValidate() {
      // TODO: look at the recovery phone if populated
      return (
        this.vofficeName.trim() != "" &&
        this.userId.trim() != "" &&
        this.isValidEmail(this.email)
      );
    },
    hasMinInfo() {
      return this.vofficeName && this.vofficeName.trim() != "" && this.isValidEmail(this.email);
    },
    holidaysInfo() {
      if (this.selectedUser.startHolidays && this.selectedUser.endHolidays) {
        return `${this.$t("components.holidays.from")} ${moment(
          new Date(this.selectedUser.startHolidays)
        ).format("DD.MM.YY H:mm")} ${this.$t(
          "components.holidays.to"
        )} ${moment(new Date(this.selectedUser.endHolidays)).format(
          "DD.MM.YY H:mm"
        )}`;
      }
      return null;
    },
  },
};
</script>

<style >
.v-theme--dark .tabInvite > .v-slide-group__container,
.v-theme--dark.tabInvite > .v-tabs-bar {
  background-color: var(--v-primary-600) !important;;
}
</style>
<style scoped lang="scss">
.showMoreLangsButton{
  top: -18px;
  width: 0px !important;
  left: 15px;
  height: 0px !important;
}
.w-95 {
  width: 95%;
}
.w100 {
  width: 100%;
}
.focusStyle:focus-within {
  border: 2px solid var(--v-primary-color);
  border-radius: 4px;
  box-shadow: none;
}
.vue-tel-input :focus::-webkit-input-placeholder {
  visibility: hidden; /*hide placeholder on focus*/
}
.focusStyle:focus-within + .customLabelTlf {
  display: block;
}
.tabInvite .v-tab {
  text-transform: capitalize !important;
  font-size: 12px;
  padding: 0 13px;
}
.v-theme--dark.v-card .backColor {
  background: #313131 !important;
}
.v-theme--dark.v-card .backColor2 {
  background: #464646 !important;
}
.backColor,
.tabColorBack .tabInvite .v-tabs-bar {
  background-color: var(--v-primary-200)
}
.backColor2 {
 background-color: var(--v-primary-300)
}
.avatarWrapper {
  text-align: center;
  position: relative;
}
.uploadAvatarButtons {
  margin-top: -18px;
  button {
    height: 33px !important;
    min-width: 40px !important;
    font-size: 12px;
  }
  &.hasImage {
    background: #6666668f;
    display: none;
    &:hover {
      display: block;
    }
  }
  &.hasNoImage {
    display: none;
    &:hover {
      display: block;
    }
  }
}
.userMgmtHeader {
  font-size: 14px;
  border-bottom: 1px solid #c1c1c1;
}
.userMgmtHeaderDelete {
  float: left;
  margin-left: 8px;
  margin-top: 3px;
  position: absolute;
  left: 0;
  cursor: pointer;
}
.userMgmtHeaderClose {
  float: right;
  margin-right: 3px;
  margin-top: -8px;
  color: #c1c1c1 !important;
  position: absolute;
  right: 0;
}
.detailsCard {
  padding-left: 20px;
  padding-right: 30px;
}
.v-text-field {
  padding-top: 0px !important;
}
.userMgmtAvatar {
  width: 150px;
  max-width: 150px;
  border-radius: 4px;
}
</style>
