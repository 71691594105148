<template>
  <v-dialog
    :model-value="showSuccessPayment" @update:model-value="showSuccessPayment = $event"
    persistent
    max-width="570"
    hide-overlay
    class="paymentModal"
  >
    <div class="w100">
      <v-card class="mx-auto">
        <!-- Title -->
        <HeaderModal
          :titleModal="$t('components.paymentModal.titleRequestPayment')"
          :closeModalFunction="declinePayment"
          :actualDate="actualDate"
        />
        <!-- End title -->
        <!-- Modal body -->
        <v-card-text>
          <v-row class="mx-0 mb-2">
            <v-col cols="12">
              <p v-if="isPayPalSandbox" class="mb-3 -mt-2 colorRed">
                {{ $t("components.paymentModal.testMode") }}
              </p>
              <p class="mb-0">
                {{
                  $t("components.paymentModal.paymentSuccessText", [
                    `${this.formatProductPrice(
                      this.state.user.paymentState.total
                    )} ${this.state.namespaceSettings.currency}`,
                    this.virtualOfficePrefix,
                  ])
                }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- End modal body -->
        <!-- Footer -->
        <v-card color="primary" class="py-2 pl-4 pr-2 footerModal">
          <v-row class="px-2 btns w-100 mx-0 my-0">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  variant="outlined"
                  icon
                  tile
                  color="white"
                  @click="declinePayment($event)"
                  class="closeButton ml-2"
                >
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("generics.close") }}</span>
            </v-tooltip>
          </v-row>
        </v-card>
        <!-- End footer -->
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import store from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
import { getPayPalRegistrationSubscriptionEnv } from "../../../lib/wsMsg";
import HeaderModal from "../../modal/modalComponents/headerModal.vue";
import moment from "moment";
export default {
  components: { HeaderModal },
  props: [],
  data: () => ({
    state: store.state,
    isPayPalSandbox: false,
    isMobile: isMobile(),
  }),
  created() {
    // Using getPayPalRegistrationSubscriptionEnv as it exposes the mode PayPal is configured in the environment
    getPayPalRegistrationSubscriptionEnv()
      .then(({ mode }) => {
        this.isPayPalSandbox = mode === "sandbox";
      })
      .catch((err) =>
        console.warn("getPayPalRegistrationSubscriptionEnv", err)
      );
  },
  mounted() {},
  methods: {
    declinePayment(event) {
      // Something else
      event.preventDefault();
      event.stopPropagation();
      this.state.user.paymentState.showSuccessPayment = false;
      this.state.user.paymentState.attachTo = false;
    },
    formatProductPrice() {
      return parseFloat(this.state.user.paymentState.total).toLocaleString(
        this.$locale.current(),
        { minimumFractionDigits: 2 }
      );
    },
  },
  computed: {
    virtualOfficePrefix() {
      return (
        store.state.namespaceSettings.companyInfo.virtualOfficePrefix ||
        store.state.namespaceSettings.companyInfo.company_name ||
        store.getVirtualOfficePrefix()
      );
    },
    actualDate() {
      return moment(new Date(Date.now())).format("DD.MM.YYYY HH:mm");
    },
    showSuccessPayment() {
      return this.state.user.paymentState.showSuccessPayment;
    },
  },
};
</script>
<style scoped lang="scss">
.-mt-2{
  margin-top: -12px;
}
.closeButton {
  border-radius: 4px;
  min-width: 45px;
}
.v-theme--light .v-dialog {
  .v-card {
    background: rgba(255, 255, 255, 0.7);
    word-break: break-word;
  }
}
.v-theme--dark .v-dialog {
  .v-card {
    background: rgba(30, 30, 30, 0.7);
    word-break: break-word;
  }
}
@media screen and (min-width: 993px) {
  .v-dialog__content {
    position: absolute !important;
  }
}
.colorRed {
  color: red;
}
</style>
<style lang="scss">
.videBridgeContainer {
  .v-dialog {
    background-color: transparent !important;
  }
}
</style>