<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="550"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('components.bridgeCallTooltips.streamSettings')"
        :closeModalFunction="closeModal"
      />
      <v-card-text style="padding-bottom: 0px">
        <v-text-field
          :disabled="useInternalPlayer"
          :model-value="streamKey" @update:model-value="streamKey = $event"
          :label="$t('components.bridgeCallTooltips.streamKey')"
          class="body-2"
          type="password"
        >
        </v-text-field>
        <v-text-field
          :disabled="useInternalPlayer"
          :model-value="streamUrl" @update:model-value="streamUrl = $event"
          :label="$t('components.bridgeCallTooltips.streamUrl')"
          class="body-2"
        >
        </v-text-field>
      </v-card-text>
      <v-col cols="12" class="px-6 pt-0" style="display: inline-block">
        <v-text-field
          label="Viewing Url"
          hide-details="auto"
          :model-value="urlLinkParam" @update:model-value="urlLinkParam = $event"
          :prefix="permaurl"
          class="body-2"
          @blur="checkExistance()"
          :rules="isDuplicated ? dupRules : ignoreRules"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <v-row class="mx-0 w100">
          <v-radio-group :model-value="selectedWallpaper" @update:model-value="selectedWallpaper = $event" row>
            <v-col class="pRelative colImage">
               <v-radio :value="'img/pause.png'">
                <template v-slot:label>
                  <v-img
                    class="mr-1"
                    src="/img/pause.png"
                    max-height="70"
                    max-width="70"
                    height="70"
                    width="70"
                    contain
                  ></v-img>
                </template>
              </v-radio>
            </v-col>
            <v-col
              v-for="path in wallpapersList"
              :key="path"
              class="pRelative colImage"
            >
              <v-radio :value="path">
                <template v-slot:label>
                  <v-img
                    class="mr-1"
                    :src="path"
                    max-height="70"
                    max-width="70"
                    height="70"
                    width="70"
                    contain
                  ></v-img>
                  <v-btn
                    icon
                    color="primary"
                    class="buttonDeleteWallpaper"
                  >
                      <DeleteBasket :indexData="path" :delFunction="deleteWallpaper" extraClasses="" fontSize="20" prevent= true />  
                  </v-btn>
                </template>
              </v-radio>
            </v-col>
          </v-radio-group>
        </v-row>
        <v-col cols="12">
          <input
            ref="uploadfile"
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            @change="uploadWallpaper"
            v-show="false"
          />
          <v-btn
            :disabled="wallpapersList.length > 2"
            color="primary"
            @click="$refs['uploadfile'].click()"
            >{{ "Upload Wallpaper" }}</v-btn
          >
        </v-col>
      </v-col>
      <v-col cols="12" class="px-0 py-0">
        <v-checkbox
          @change="internalPlayerChange($event)"
          :model-value="useInternalPlayer" @update:model-value="useInternalPlayer = $event"
          class="internalPlayerCB"
          :label="$t('components.bridgeCallTooltips.useInternalPlayer')"
        ></v-checkbox>
      </v-col>
      <FooterModal :closeModalFunction="closeModal" :showClose="true">
        <v-btn color="primary" @click="saveStreamData()">{{
          $t("generics.save")
        }}</v-btn>
      </FooterModal>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import {
  dispatchSuccessAlert,
  dispatchErrorAlert,
} from "../../effector/alerts";
import DeleteBasket from "../../components/ui/deleteBasket.vue";
import {
  uploadWallpaper,
  listWallpapers,
  deleteWallpaper,
} from "../../lib/wsMsg";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal, DeleteBasket },
  props: ["showModal", "closeModal"],
  data() {
    return {
      state: store.state,
      isDuplicated: false,
      dupRules: [(v) => this.isDuplicated || "Info taken"],
      ignoreRules: [],
      streamKey: (store.state.user.streamingSettings || {}).streamKey || "",
      streamUrl: (store.state.user.streamingSettings || {}).streamUrl || "",
      useInternalPlayer:
        (store.state.user.streamingSettings || {}).internalPlayerOnly || false,
      permaurl:
        window.location.protocol +
        "//" +
        window.location.hostname +
        "/player.html#",
      urlLinkParam: (store.state.user.streamingSettings || {}).streamLink || "",
      wallpapersList: [],
      selectedWallpaper:
        (store.state.user.streamingSettings || {}).wallpaperSrc ||
        "img/pause.png",
    };
  },
  mounted() {
    this.listWallpapers();
  },
  methods: {
    async deleteWallpaper(filename) {
      const name = filename.split("/").pop();
      await deleteWallpaper(name);
      await this.listWallpapers();
    },
    async listWallpapers() {
      this.wallpapersList = await listWallpapers();
    },
    async uploadWallpaper(e) {
      const files = e.target.files || e.dataTransfer.files;
      const file = files[0];
      const base64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (err) => reject(err);
        reader.readAsDataURL(file);
      });
      await uploadWallpaper(base64);
      await this.listWallpapers();
    },
    checkExistance() {
      let result = false;
      for (let uuid in store.state.group) {
        if (
          ((store.state.group[uuid].user.streamingSettings || {}).streamLink ||
            "") === this.urlLinkParam
        ) {
          if (uuid !== store.state.ownUUID) {
            this.isDuplicated = true;
            // console.log("returning iduplicated ");
            result = true;
            break;
          }
        }
      }
      return result;
    },
    internalPlayerChange(evt) {
      if (evt) {
        this.useInternalPlayer = evt;
      }
    },
    checkData() {
      // Add logic of what fields must be filled if checked ???
      let isValid = true;
      if (this.urlLinkParam && this.urlLinkParam.trim().length > 0) {
        if (this.checkExistance() == true) {
          isValid = false;
        }
      }
      return isValid;
    },
    setUserStreamData(data) {
      return store.setUserStreamData(data);
    },
    saveStreamData() {
      // console.log(this.selectedWallpaper, "selectedWallpaper");
      if (this.checkData()) {
        const tempData = {
          streamUrl: this.streamUrl,
          streamKey: this.streamKey,
          internalPlayerOnly: this.useInternalPlayer,
          streamLink: this.urlLinkParam,
          wallpaperSrc: this.selectedWallpaper,
        };
        // console.log("setUserStreamData ", tempData);
        this.setUserStreamData(tempData);
        this.closeModal();
        dispatchSuccessAlert(
          this.$t("components.bridgeCallTooltips.settingsSaveSuccess")
        );
      } else {
        dispatchErrorAlert(
          this.$t("components.bridgeCallTooltips.settingsSaveError")
        );
      }
    },
  },
};
</script>
<style scoped lang="scss">
.colImage {
  width: calc(100% / 4);
}
.w100 {
  width: 100%;
}
.pRelative {
  position: relative;
}
.buttonDeleteWallpaper {
  position: absolute;
  top: -12px;
  left: -38px;
}
.selectedWallpaper {
  position: absolute;
  top: 5px;
  left: 5px;
}
.viewingLabel {
  margin-left: 25px;
  font-size: 12px;
}
.internalPlayerCB {
  margin-left: 20px;
  margin-top: 0px;
  padding-top: 0px;
  max-height: 26px;
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
