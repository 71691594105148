import { bridgeInfoStore } from './state';
import { receivedAllUserStateEvent, receivedSpecificUserStateEvent, updateOnlineStatusEvent } from '../events';
import { allUsersState } from '../state';

// Check all users in bridge call and populate the state 
bridgeInfoStore.on(receivedAllUserStateEvent, (state, payload) => {
  return Object.entries(payload)
    .filter(([uuid, entry]) => { // Filter all users with a bridge call id
      return entry && entry.connected && entry.user && (entry.user.inBridgeCall || entry.user.inBridgeCallListener);
    })
    .reduce((obj, [uuid, entry]) => {
      const bridgeCallId = `${entry.user.inBridgeCall || entry.user.inBridgeCallListener}`;
      const isListener = !!entry.user.inBridgeCallListener;
      const isPodium = !!entry.user.inBridgeCall;
      const currentCallObj = (obj.calls[bridgeCallId] || {});
      return { // Convert to object indexed by bridge call id
        calls: {
          ...obj.calls,
          [bridgeCallId]: {
            ...currentCallObj,
            isMobile: currentCallObj.isMobile || !!(entry.user.inBridgeCall && entry.user.isMobile) || false,
            confId: currentCallObj.confId || (entry.user.bridgeCallInfo || {}).confId || '',
            users: [uuid, ...(currentCallObj.users || [])].filter((v, i, a) => (i === a.indexOf(v))),
            listeners: (
              isListener
                ? [uuid, ...(currentCallObj.listeners || [])].filter((v, i, a) => (i === a.indexOf(v)))
                : (currentCallObj.listeners || [])
            ),
            podium: (
              isPodium
                ? [uuid, ...(currentCallObj.podium || [])].filter((v, i, a) => (i === a.indexOf(v)))
                : (currentCallObj.podium || [])
            ),
          }
        },
        conferences: {
          ...obj.conferences,
          ...(
            (entry.user.bridgeCallInfo || {}).confId
              ? { [(entry.user.bridgeCallInfo || {}).confId]: bridgeCallId }
              : {}
          )
        },
        users: {
          ...obj.users,
          [uuid]: bridgeCallId,
        },
        listeners: {
          ...obj.listeners,
          ...(
            isListener
              ? { [uuid]: bridgeCallId }
              : {}
          ),
        },
        podium: {
          ...obj.podium,
          ...(
            isPodium
              ? { [uuid]: bridgeCallId }
              : {}
          ),
        }
      }
    }, state);
});

// Modify the state based on a single user event
bridgeInfoStore.on(receivedSpecificUserStateEvent, (state, payload) => {
  const [uuid, entry] = payload;
  // Obtain the bridge call id
  const bridgeCallId = entry && entry.connected && entry.user && (entry.user.inBridgeCall || entry.user.inBridgeCallListener);
  const isListener = bridgeCallId && !!entry.user.inBridgeCallListener;
  const isPodium = bridgeCallId && !!entry.user.inBridgeCall;
  // Obtain the previous call id
  const previousCallId = state.users[uuid];
  const previousListernerCallId = state.listeners[uuid];
  const previousPodiumCallId = state.podium[uuid];
  // Handle the cases where user is promoted from listeners into podium and vice-versa
  let listenerOrPodiumChanged = false;
  if (previousListernerCallId && (previousListernerCallId !== bridgeCallId || !isListener)) {
    if ((state.calls[previousListernerCallId] || {}).listeners && state.calls[previousListernerCallId].listeners.length) {
      state.calls[previousListernerCallId].listeners = state.calls[previousListernerCallId].listeners.filter(v => v !== uuid); // Remove uuid from call
    }
    delete state.listeners[uuid];
    listenerOrPodiumChanged = true;
  }
  if (previousPodiumCallId && (previousPodiumCallId !== bridgeCallId || !isPodium)) {
    if ((state.calls[previousPodiumCallId] || {}).podium && state.calls[previousPodiumCallId].podium.length) {
      state.calls[previousPodiumCallId].podium = state.calls[previousPodiumCallId].podium.filter(v => v !== uuid); // Remove uuid from call
    }
    delete state.podium[uuid];
    listenerOrPodiumChanged = true;
  }
  if (!listenerOrPodiumChanged && previousCallId && previousCallId === bridgeCallId) return state; // No changes
  // Remove user from previous call
  if (previousCallId && previousCallId !== bridgeCallId && state.calls[previousCallId]) {
    if (state.calls[previousCallId].users && state.calls[previousCallId].users.length) {
      state.calls[previousCallId].users = state.calls[previousCallId].users.filter(v => v !== uuid); // Remove uuid from call
    }
    if (!state.calls[previousCallId].users || !state.calls[previousCallId].users.length) {
      if (state.calls[previousCallId].confId) delete state.conferences[state.calls[previousCallId].confId];
      delete state.calls[previousCallId]; // Delete calls with no users
    } else {
      const group = allUsersState.getState(); // Check isMobile of the existing users and update flag accordingly
      state.calls[previousCallId].isMobile = [...state.calls[previousCallId].users].reduce((acc, uuid) => (acc || !!((group[uuid] || {}).user && group[uuid].user.inBridgeCall && group[uuid].user.isMobile)), false);
    }
    delete state.users[uuid];
  }
  // Populate the respective calls entry
  if (bridgeCallId) {
    const currentCallObj = (state.calls[bridgeCallId] || {});
    state.calls[bridgeCallId] = {
      ...currentCallObj,
      isMobile: currentCallObj.isMobile || !!(entry.user.inBridgeCall && entry.user.isMobile) || false,
      confId: currentCallObj.confId || (entry.user.bridgeCallInfo || {}).confId || '',
      users: [uuid, ...(currentCallObj.users || [])].filter((v, i, a) => (i === a.indexOf(v))),
      listeners: (
        isListener
          ? [uuid, ...(currentCallObj.listeners || [])].filter((v, i, a) => (i === a.indexOf(v)))
          : (currentCallObj.listeners || [])
      ),
      podium: (
        isPodium
          ? [uuid, ...(currentCallObj.podium || [])].filter((v, i, a) => (i === a.indexOf(v)))
          : (currentCallObj.podium || [])
      ),
    };
    if (state.calls[bridgeCallId].confId) state.conferences[state.calls[bridgeCallId].confId] = bridgeCallId;
    state.users[uuid] = bridgeCallId;
    if (isListener) state.listeners[uuid] = bridgeCallId;
    if (isPodium) state.podium[uuid] = bridgeCallId;
  }
  // The modified state
  return { ...state };
});

// Add conference Id from conference content
bridgeInfoStore.on(updateOnlineStatusEvent, (state, payload) => {
  const bridgeCallId = payload[1];
  if (payload[1]) {
    state.calls[bridgeCallId] = {
      ...(state.calls[bridgeCallId] || {}),
      isMobile: (state.calls[bridgeCallId] || {}).isMobile || false,
      confId: payload[0] || '',
      users: [...((state.calls[bridgeCallId] || {}).users || [])]
    };
    if (payload[0]) state.conferences[payload[0]] = bridgeCallId;
  }
  // The modified state
  return { ...state };
});
