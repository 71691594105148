<template>
  <v-list max-width="320" @click.stop.prevent>
    <v-list-group
      active-class="listColor"
      v-if="!isGuest && !mini"
      :value="isActive"
      @click="handlerClickSbRoom($event)"
      :append-icon="null"
      :expand-icon="null"
    >
     <template v-slot:activator="{ props }">
        <v-list-item v-bind="props">
        <template v-slot:prepend>
        <v-icon size="18">
          <font-awesome-icon
            v-if="currentPage('/new-room')"
            :icon="['fas', 'comment']"
            class="primary--text-sidepanel"
          />
          <font-awesome-icon
            v-else
            :icon="['fal', 'comment']"
            class="primary--text-sidepanel"
          />
        </v-icon>
        </template>
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-list-item-title
             class="primary--text-sidepanel"
              :class="`${
                getRoute === '/new-room'
                  ? 'font-weight-bold'
                  : ''
              }`"
              @click.stop.prevent="
                setCurrentContentVisile('', false, $router);
                handleNewRoomView();
              "
              v-bind="props"
            >
              {{ $t("components.sambaRooms.callRooms") }}
            </v-list-item-title>
          </template>
          <span>{{ $t("components.sambaRooms.callRooms") }}</span>
        </v-tooltip>
      </v-list-item>
      </template>
    </v-list-group>
    <v-tooltip right v-if="!isGuest && mini">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" class="cursorPointer">
          <v-icon size="18" @click.stop.prevent="handlerClickSbRoom()">
            <font-awesome-icon
              v-if="currentPage('/new-room')"
              :icon="['fas', 'comment']"
              class="primary--text-sidepanel"
            />
            <font-awesome-icon
              v-if="!currentPage('/new-room')"
              :icon="['fal', 'comment']"
              class="primary--text-sidepanel"
            />
          </v-icon>
        </v-list-item>
      </template>
      <span>{{ $t("components.sambaRooms.callRooms") }}</span>
    </v-tooltip>
  </v-list>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store, { EventBus } from "../../store";
import UserList from "./userList.vue";
import CustomTT from "../content/customTT.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";

export default {
  props: [
    "mini",
    "setCurrentContentVisile",
    "isGuest",
    "active",
    "setList",
    "isMobile",
  ],
  components: {
    UserList,
    CustomTT,
  },
  data() {
    const effector = {
      showCustomTT: hotkeysInfoStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      // Effector
      ...effector,
    }
  },
  mounted() {
    // EventBus.$on("openCoffeeBreakEvent", this.handleNewRoomView);
  },
  unmounted(){
    // EventBus.$off("openCoffeeBreakEvent", this.handleNewRoomView);
  },
  methods: {
    handlerClickSbRoom(event) {
      if (this.mini) {
        this.goToPage("new-room");
      } else {
        this.setList("new-room");
      }
    },
    currentPage(page) {
      const actualRoute = this.$route.path;
      if (actualRoute === page) {
        return true;
      }
      return false;
    },
    goToPage(page) {
      this.setCurrentContentVisile(page, true, this.$router);
    },
    handleNewRoomView() {
      if (!this.isMobile) {
        this.setCurrentContentVisile("new-room", true, this.$router);
      }
    },
  },
  computed: {
    isActive() {
      return this.active == "new-room";
    },
    getRoute() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped lang="scss">
.cursorPointer {
  cursor: pointer;
}
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.textColor {
  color: #008000;
}

.v-theme--dark .listColor {
  color: white !important;
}
.v-theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
.ctrb {
  left: 15px;
}
.iconsSize20{
  width: 20px !important;
  height: 20px !important;
}
</style>

