// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vue-tel-input {
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/firstSetup/lawyerCompanyRegister.vue","webpack://./lawyerCompanyRegister.vue"],"names":[],"mappings":"AAiBE;EAME,YAAA;ACrBJ","sourcesContent":["\n  // .pRelative{\n  //   position: relative;\n  // }\n  // .pContact{\n  //   margin-bottom: -6px !important;\n  //   font-size: 16px;\n  //   color: rgba(0,0,0,.6);\n  //   line-height: 20px;\n  //   height: 20px;\n  //   max-width: 133%;\n  //   transform: translateY(0px) translateX(-40px) scale(.75);\n  //   position: absolute;\n  // top: -10px;\n  // left: 48px;\n  // background: white;\n  // }\n  .vue-tel-input {\n    // border: none !important;\n    // border-bottom: 1px solid !important;\n    // border-radius: 0px !important;\n    // box-shadow: none !important;\n    // outline: none !important;\n    height: 40px;\n  }\n",".vue-tel-input {\n  height: 40px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
