// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alertMobile[data-v-19e9fe45] {
  left: 0px !important;
  width: 100% !important;
  transform: none !important;
}
.alertContainer[data-v-19e9fe45] {
  position: fixed;
  bottom: 25px;
  left: 50%;
  width: 40%;
  transform: translate(-50%, -50%);
  /* https://stackoverflow.com/questions/78799611/how-to-show-a-v-alert-above-a-v-dialog */
  z-index: 2401;
}
.alertItem[data-v-19e9fe45] {
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./websrc/components/alerts/alertContainer.vue","webpack://./alertContainer.vue"],"names":[],"mappings":"AACA;EACE,oBAAA;EACA,sBAAA;EACA,0BAAA;ACAF;ADEA;EACE,eAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,gCAAA;EACA,wFAAA;EACA,aAAA;ACCF;ADCA;EACE,eAAA;ACEF","sourcesContent":["\n.alertMobile{\n  left: 0px !important;\n  width: 100% !important;\n  transform: none !important;\n}\n.alertContainer {\n  position: fixed;\n  bottom: 25px;\n  left: 50%;\n  width: 40%;\n  transform: translate(-50%, -50%);\n  /* https://stackoverflow.com/questions/78799611/how-to-show-a-v-alert-above-a-v-dialog */\n  z-index: 2401;\n}\n.alertItem {\n  max-width: 100%;\n}\n",".alertMobile {\n  left: 0px !important;\n  width: 100% !important;\n  transform: none !important;\n}\n\n.alertContainer {\n  position: fixed;\n  bottom: 25px;\n  left: 50%;\n  width: 40%;\n  transform: translate(-50%, -50%);\n  /* https://stackoverflow.com/questions/78799611/how-to-show-a-v-alert-above-a-v-dialog */\n  z-index: 2401;\n}\n\n.alertItem {\n  max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
