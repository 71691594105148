import { newMessageFromCallDomain } from './domain';

import { setNewMessageFromCallEvent, resetNewMessageFromCallEvent} from './events';

export const newMessageFromCallStore = newMessageFromCallDomain.createStore(false, { name: ' newMessageFromCall' });

newMessageFromCallStore.on(setNewMessageFromCallEvent, (state, payload) => {
  return payload;
});

newMessageFromCallStore.reset(resetNewMessageFromCallEvent);
// qualityVotingModalStore.watch((state) => { console.log('qualityVotingModalStore', state); });
