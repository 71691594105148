<template>
  <div>
    <div class="blinkContainer">
      <div v-if="participantsMuted.indexOf(participantId)!==-1" class="microphoneSlashOverlay mr-2">
        <img
          src="/img/micro-full-red.svg"
        />
      </div>
      <div class="soundSlashOverlay mr-2" v-if="isUserAudioOff && isUserAudioOff(participantId)">
        <img
          src="/img/volume-slash-red-red.svg"
        />
      </div>
      <div class="cameraOffSlashOverlay mr-2" v-if="participantsCameraOff && participantsCameraOff[participantId]">
        <img
          src="/img/user-camera-slash-red.svg"
        />
      </div>
    </div>
    <div v-if="getBadQuality" class="badQualityText">
      {{ $t("components.bridgeCallTooltips.badQuality") }}
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: [
    "participantId",
    "isUserAudioOff",
    "userUUID",
    "badQualityUser",
    "participantsMuted",
    "participantsCameraOff",
    "getIsParticipantIsPodium",
    "isListenerSpeakerConferenceHall",
    "getIsConferenceHall"
  ],
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    // showMutedIcons(){
    //   if (!this.getIsConferenceHall) return true;
    //   if (!this.getIsParticipantIsPodium && !!this.isListenerSpeakerConferenceHall === true) {
    //     return true;
    //   }
    //   if (!this.getIsParticipantIsPodium && !!this.isListenerSpeakerConferenceHall === false){
    //     return false;
    //   }
    //   return true;
    // },
    getBadQuality() {
      const participantId = this.participantId;
      if (this.badQualityUser && this.badQualityUser.indexOf(participantId) !== -1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.badQualityText {
  color: red;
  font-size: 20px;
  position: absolute;
  bottom: 20px;
  left: 20px;

  position: absolute;
  // animation: blinker 1.5s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
  z-index: 1;
}
.blinkContainer{
  position: absolute;
  width: 100%;
  bottom: 20px;
  z-index: 1;
  // animation: blinker 1s linear infinite;
}
.soundSlashOverlay {
    width: 30px;
    height: 30px;
  position: relative;
  float: right;
}
.cameraOffSlashOverlay{
    width: 30px;
    height: 30px;
    position: relative;
    float: right;
}

.microphoneSlashOverlay {
     width: 30px;
    height: 30px;
  position: relative;
  float: right;
}
@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>