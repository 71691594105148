// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-menu__content {
  margin-top: 0 !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/userMoreActions.vue","webpack://./userMoreActions.vue"],"names":[],"mappings":"AACE;EACE,wBAAA;ACAJ","sourcesContent":["\n  .v-menu__content{\n    margin-top: 0 !important;\n  }\n",".v-menu__content {\n  margin-top: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
