// import { createEvent } from 'effector';
import { usersDomain } from './domain';

export const receivedAllUserStateEvent = usersDomain.createEvent('receivedAllUserState');
export const receivedSpecificUserStateEvent = usersDomain.createEvent('receivedSpecificUserState');

export const setOwnUserUUIDEvent = usersDomain.createEvent('setOwnUserUUID');

export const findUserEmailEvent = usersDomain.createEvent('findUserEmailEvent');
export const findUserByEmailEvent = usersDomain.createEvent('findUserByEmail');

export const updateOnlineStatusEvent = usersDomain.createEvent('updateOnlineStatus');
// TESTING DEBUG
// receivedAllUserStateEvent.watch((payload) => console.log('receivedAllUserStateEvent', payload));
// receivedSpecificUserStateEvent.watch((payload) => console.log('receivedSpecificUserStateEvent', payload));
