// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-input__control .v-selection-control .v-label {
  margin-left: 0.25em;
}
.selectTimeWidthPadd .v-input__control .v-field .v-field__field .v-field__input,
.selectDurationWidthPadd .v-input__control .v-field .v-field__field .v-field__input {
  margin-left: 64%;
}
.selectDateWidthPadd .v-input__control .v-field__field .v-field__input {
  margin-left: 75%;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/inviteForm.vue","webpack://./inviteForm.vue"],"names":[],"mappings":"AAII;EACE,mBAAA;ACHN;ADaQ;;EACE,gBAAA;ACTV;ADmBM;EACE,gBAAA;AChBR","sourcesContent":["\n// Labels aside checkboxes\n.v-input__control{\n  .v-selection-control{\n    .v-label{\n      margin-left: 0.25em;\n    }\n  }\n}\n.selectTimeWidthPadd,\n.selectDurationWidthPadd{\n  .v-input__control{\n    // Icons inside inputs\n    .v-field{\n      .v-field__field{\n        .v-field__input{\n          margin-left: 64%;\n        }\n      }\n    }\n  }\n}\n.selectDateWidthPadd {\n  .v-input__control{\n    // Icons inside inputCalendar icon-field{\n    .v-field__field{\n      .v-field__input{\n        margin-left: 75%;\n      }\n    }\n  }\n}\n",".v-input__control .v-selection-control .v-label {\n  margin-left: 0.25em;\n}\n\n.selectTimeWidthPadd .v-input__control .v-field .v-field__field .v-field__input,\n.selectDurationWidthPadd .v-input__control .v-field .v-field__field .v-field__input {\n  margin-left: 64%;\n}\n\n.selectDateWidthPadd .v-input__control .v-field__field .v-field__input {\n  margin-left: 75%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
