import { ownUserUUIDState, ownUserState } from '../users';
// import { clearPresenceWatching } from '../../lib/wsMsg';
import { wsConnectedEvent } from '../ws';

import {
  fillPresenceWatchingEvent,
  newPresenceWatchingEvent,
} from './events';

import {
  getPresenceWatchingEffect,
  createPresenceWatchingEntryEffect,
  messageWatchedUsersEffect,
} from './effects';

// import {
//   watchList,
// } from './state';

import {
  dispatchErrorAlert,
} from '../alerts';

let justLoggedIn = false;

wsConnectedEvent.watch(() => {
  //clearPresenceWatching(); // This to clear store for all Watching Events of presence
  justLoggedIn = true;
  getPresenceWatchingEffect();
   
 });

getPresenceWatchingEffect.done.watch(({ params, result }) => {
   fillPresenceWatchingEvent(result)
});

fillPresenceWatchingEvent.watch( (result) => {
 const ownUUID = ownUserUUIDState.getState();
 const myState = ownUserState.getState();
 const reportingStatus = [
    "Out of Office",
    "No Calls",
    "Break",
    "Holidays",
    "No status",
    "inCall",
    "inRoom",
    "Busy",
    "Just urgent calls",
    "In meeting",
    "Ill"
  ];
  setTimeout(() => {
      if(justLoggedIn && reportingStatus.indexOf(((myState || {}).user || {}).activity || 'nada') === -1){
        justLoggedIn = false;
          if(result && result.watching){
          const extant = result.watching.filter(e => e.owner === ownUUID);
            if(extant && extant.length > 0  && extant[0].watched && extant[0].watched.length > 0){
              messageWatchedUsersEffect(extant[0].watched);
            }
          }
      }
  }, 100);
});

newPresenceWatchingEvent.watch((payload) => {
  createPresenceWatchingEntryEffect(payload);
});

createPresenceWatchingEntryEffect.done.watch(({results, params}) => {
  getPresenceWatchingEffect();
  // console.log(results, params)
  
});

createPresenceWatchingEntryEffect.fail.watch(({error, params}) => {
  // console.log(error, params)
  dispatchErrorAlert('Failed to update watching event:' + error.message);
});
