// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-theme--dark .deleteBasketButtonNormal[data-v-63f1d424] {
  color: white !important;
}
.deleteBasketButtonActive[data-v-63f1d424] {
  color: red !important;
  font-weight: 600 !important;
}
.groupCardEllipses[data-v-63f1d424] {
  color: white !important;
  float: right;
  max-height: 20px;
  max-width: 20px;
}
.userName[data-v-63f1d424] {
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/roomCardActions.vue","webpack://./roomCardActions.vue"],"names":[],"mappings":"AACA;EACE,uBAAA;ACAF;ADEA;EACE,qBAAA;EACA,2BAAA;ACCF;ADCA;EACE,uBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;ACEF;ADAA;EACE,sBAAA;ACGF","sourcesContent":["\n.v-theme--dark .deleteBasketButtonNormal {\n  color: white !important;\n}\n.deleteBasketButtonActive {\n  color: red !important;\n  font-weight: 600 !important;\n}\n.groupCardEllipses {\n  color: white !important;\n  float: right;\n  max-height: 20px;\n  max-width: 20px;\n}\n.userName{\n  vertical-align: middle;\n}\n",".v-theme--dark .deleteBasketButtonNormal {\n  color: white !important;\n}\n\n.deleteBasketButtonActive {\n  color: red !important;\n  font-weight: 600 !important;\n}\n\n.groupCardEllipses {\n  color: white !important;\n  float: right;\n  max-height: 20px;\n  max-width: 20px;\n}\n\n.userName {\n  vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
