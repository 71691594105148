// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-theme--light .noWhiteBg[data-v-30b28db5] {
  background: linear-gradient(rgba(0, 0, 0, 0.2509803922), rgba(0, 0, 0, 0.6)) !important;
}
.v-theme--dark .noWhiteBg[data-v-30b28db5] {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)) !important;
}
.noWhiteBg[data-v-30b28db5] {
  color: white !important;
}
.noWhiteBg svg[data-v-30b28db5] {
  font-size: 15px !important;
}
.callIcon[data-v-30b28db5] {
  background-color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/callIcon.vue","webpack://./callIcon.vue"],"names":[],"mappings":"AACA;EACE,uFAAA;ACAF;ADEA;EACE,8EAAA;ACCF;ADCA;EACE,uBAAA;ACEF;ADDE;EACE,0BAAA;ACGJ;ADAA;EACE,yBAAA;ACGF","sourcesContent":["\n.v-theme--light .noWhiteBg {\n  background: linear-gradient(#00000040, #00000099) !important;\n}\n.v-theme--dark .noWhiteBg {\n  background: linear-gradient(#00000099, #000000cc) !important;\n}\n.noWhiteBg {\n  color: white !important;\n  svg {\n    font-size: 15px !important;\n  }\n}\n.callIcon {\n  background-color: #ffffff;\n}\n",".v-theme--light .noWhiteBg {\n  background: linear-gradient(rgba(0, 0, 0, 0.2509803922), rgba(0, 0, 0, 0.6)) !important;\n}\n\n.v-theme--dark .noWhiteBg {\n  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8)) !important;\n}\n\n.noWhiteBg {\n  color: white !important;\n}\n.noWhiteBg svg {\n  font-size: 15px !important;\n}\n\n.callIcon {\n  background-color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
