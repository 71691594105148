<template>
  <v-dialog :model-value="showDirectInviteCallModal" @update:model-value="showDirectInviteCallModal = $event" persistent max-width="560" ref="modalDirectInviteCall">
    <template>
      <v-card class="mx-auto">
        <HeaderModal :titleModal="'Link'" />
        <v-card-text>
          <v-row>
            <v-text-field id="inputLink" readonly density="compact" variant="outlined" hide-details :lable="'Direct lnk'" ref="linkRef" :model-value="showDirectInviteCallModal" @update:model-value="showDirectInviteCallModal = $event"></v-text-field>
          </v-row>
        </v-card-text>
         <FooterModal :closeModalFunction="closeModal" :showFooter="true">
          <v-btn flat @click="copyLink($event)">
            <font-awesome-icon
              :icon="['fal', 'copy']"
              :style="{ fontSize: '18px' }"
            />
          </v-btn>
          </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { resetDirectCallInviteModalEvent, directCallInvitationModalStore } from "../../effector/modals";
import { dispatchSuccessAlert } from "../../effector/alerts";
export default {
  components: { HeaderModal, FooterModal },
  data(){
    const effector = {
      showDirectInviteCallModal: directCallInvitationModalStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      // Effector
      ...effector,
    }
  },
  mounted() {},
  methods: {
    async copyLink(evt) {
      if ( this.showDirectInviteCallModal ){
        document.getElementById('inputLink').focus();
        document.getElementById('inputLink').select();
        document.execCommand("copy");
        evt.target.classList.add("greenClass");
        setTimeout(() => {
          evt.target.classList.remove("greenClass");
          this.closeModal();
          dispatchSuccessAlert(this.$t('components.inviteVisitor.copiedLInk'))
          // document.getElementById('inputLink').blur();
        }, 1000);
      }
    },
    closeModal() {
      resetDirectCallInviteModalEvent(false);
    }
  },
  computed: {}
};
</script>

<style scoped lang="scss">
.greenClass {
  font-weight: 800;
  color: green;
}
.pointer {
  cursor: pointer;
}
.thumbs-down {
  color: red;
}
.thumbs-up {
  color: green;
}
</style>