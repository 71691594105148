<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="460"
    @keydown.esc="closeModal"
  >
    <v-card>
      <!-- <v-card-title class="headline">
        <v-img
          class="mr-1"
          src="assets/icon.png"
          max-height="30"
          max-width="30"
          contain
        ></v-img>
        {{ $t("components.2faSms.title") }}
      </v-card-title> -->
      <HeaderModal
        :titleModal="$t('components.2faSms.title')"
        :closeModalFunction="closeModal"
      />
      <v-card-text>
        <div class="d-flex">
          <template v-if="!editingPhone">
            <div class="tex0t-truncate text-body-1">
              <span :style="phoneNumberValid ? {} : { color: 'red' }">{{
                $t("components.2faSms.phoneNumber")
              }}</span>
              {{ phoneNumber }}
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" icon @click="editPhone()">
                    <font-awesome-icon
                      :icon="['fal', 'pen']"
                      :style="{ fontSize: '20px' }"
                    />
                  </v-btn>
                </template>
                <span>{{ $t("components.2faSms.editPhoneNumber") }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-if="editingPhone">
            <vue-tel-input
              :model-value="phoneNumberInput" 
              @update:model-value="phoneNumberInput = $event"
              required
              defaultCountry="DE"
              enabledCountryCode
              @validate="onTelInput"
              name="number"
              id="numDisplay"
              class="form-control text-center input-sm inputPhone2FA"
              value
              placeholder
              autocomplete="off"
              :inputOptions="{placeholder: ''}"
              :class="{ borderError: !phoneInputValid }"
            ></vue-tel-input>
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  icon
                  class="my-3"
                  @click="savePhone()"
                  :disabled="!phoneInputValid"
                >
                  <font-awesome-icon
                    :icon="['fal', 'check']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("generics.save") }}</span>
            </v-tooltip>
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" icon class="my-3" @click="cancelPhone()">
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("generics.cancel") }}</span>
            </v-tooltip>
          </template>
        </div>
        <div class="d-flex mb-4">
          <v-btn
            outlined
            density="compact"
            hide-details
            :disabled="requestSmsDone || editingPhone || !phoneNumberValid"
            small
            color="error"
            @click="requestSms()"
            >{{ $t("components.2faSms.sendSMS") }}</v-btn
          >
          <span v-if="requestSmsDone" class="px-2 align-center d-flex">{{
            $t("components.2fa.sentSMSMsg")
          }}</span>
        </div>
        <v-alert
          v-if="errMsg && !requestSmsDone"
          type="error"
          :dismissible="true"
          >{{ errMsg }}</v-alert
        >
        <div v-if="requestSmsDone" class="mt-3">
          <v-text-field
            @keyup.enter="smsCode && enable2faSms()"
            autocomplete="off"
            :model-value="smsCode" @update:model-value="smsCode = $event"
            :label="$t('components.2fa.enterSmsCode')"
            outlined
            density="compact"
            @keydown.space.prevent
            required
            autofocus
          ></v-text-field>
          <v-alert v-if="errMsg" type="error" :dismissible="true">{{
            errMsg
          }}</v-alert>
          <div class="d-flex justify-end mt-3">
            <v-btn
              color="primary"
              hide-details
              small
              :disabled="!smsCode"
              @click="enable2faSms()"
              >{{ $t("components.2faSms.btnEnable") }}</v-btn
            >
          </div>
        </div>
      </v-card-text>
      <!-- footer modal -->
      <FooterModal :closeModalFunction="closeModal" class="mt-4">
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../../store";
import { setup2FA, enable2FA } from "../../../lib/wsMsg";
import FooterModal from "../../modal/modalComponents/footerModal.vue";
import HeaderModal from "../../modal/modalComponents/headerModal.vue";

export default {
  props: ["showModal", "toggle2faSms"],
  components: { FooterModal, HeaderModal },
  data() {
    return {
      state: store.state,
      editingPhone: false,
      phoneNumberInput: "",
      phoneNumberParsed: "",
      phoneInputValid: true,
      requestSmsDone: false,
      errMsg: "",
      smsCode: "",
    };
  },
  computed: {
    phoneNumber: {
      get: function () {
        return this.state.user.mobilePhone &&
          this.state.user.mobilePhone !== "unknown"
          ? this.state.user.mobilePhone
            ? `${this.state.user.mobilePhone}`
            : ""
          : this.state.user.phone
          ? `${this.state.user.phone}`
          : "";
      },
      set: function (newValue) {
        this.state.user.mobilePhone = newValue;
      },
    },
    phoneNumberValid() {
      return (
        this.phoneNumber &&
        typeof this.phoneNumber === "string" &&
        this.phoneNumber.startsWith("+") &&
        /^\+\d+$/.test(this.phoneNumber)
      );
    },
  },
  methods: {
    closeModal() {
      this.toggle2faSms();
    },
    editPhone() {
      this.phoneNumberInput = this.phoneNumber;
      this.phoneInputValid = this.phoneNumberValid;
      this.editingPhone = true;
    },
    onTelInput({ number, valid }) {
      if (number && valid) {
        this.phoneNumberParsed = number.replace(/[-\s]/g, "");
      }
      this.phoneInputValid = valid;
    },
    savePhone() {
      this.phoneNumber = this.phoneNumberParsed;
      this.editingPhone = false;
    },
    cancelPhone() {
      this.editingPhone = false;
      this.phoneNumberInput = this.phoneNumber;
    },
    async requestSms() {
      this.errMsg = "";
      const result = await setup2FA("sms").catch((err) => {
        this.errMsg = err.message || err;
        console.warn(err);
        return false;
      });
      if (result) {
        this.requestSmsDone = true;
      } else if (!this.errMsg) {
        this.errMsg = this.$t("components.2faSms.anErrorOccurred");
      }
    },
    async enable2faSms() {
      this.errMsg = "";
      const result = await enable2FA(this.smsCode).catch((err) => {
        this.errMsg = err.message || err;
        console.warn(err);
        return false;
      });
      if (result) {
        this.toggle2faSms(true);
      } else if (!this.errMsg) {
        this.errMsg = this.$t("components.2faOtp.invalidCode");
      }
    },
  },
};
</script>

<style lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
.v-theme--dark .inputPhone2FA {
  .vti__dropdown.open,
  .vti__dropdown:hover {
    background-color: #202020;
    color: black;
  }
  .vti__dropdown-list,
  .vti__dropdown-list:hover {
    background: #202020;
    color: white !important;
    border: none;
  }
  .vti__dropdown-item.highlighted,
  .vti__dropdown-item:hover {
    background: #202020;
    color: white !important;
  }
  .vti__country-code,
  .vti__dropdown-arrow {
    color: #ffffffb3 !important;
  }
}
</style>
