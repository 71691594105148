<template>
  <div>
    <div
      v-if="!isRecorder"
      :class="getRightClass">
      <BridgeCallGrid
        :isRecorder="isRecorder"
      />
    </div>
    <div
      v-if="isRecorder" style="height:calc(100vh - 8px)">
      <BridgeCallGrid_rec
        :isRecorder="isRecorder"
      />
    </div>
  </div>
</template>
<script>
import BridgeCallGrid from "../../components/content/brigdeCallGrid.vue";
import BridgeCallGrid_rec from "../../components/content/brigdeCallGrid_rec.vue";
import { isMobile, getMobileOperatingSystem} from "../../lib/mobileUtil"
export default {
  components: {
    BridgeCallGrid,
    BridgeCallGrid_rec,
  },
  props: ["isRecorder", "hasPrivilege", "showTimeLine", "userIsOneCall"],
  data() {
    return {
      isMobile: isMobile(),
      getMobileOperatingSystem: getMobileOperatingSystem()
    };
  },
  computed: {
    getRightClass() {
      let classToApply = "videBridgeContainer";
      if ( this.isMobile && this.getMobileOperatingSystem === 'iOS' ){
        classToApply = classToApply + ' iOSMobile'; 
      }
      if (!this.hasPrivilege) {
        if ( this.userIsOneCall ){
          classToApply = classToApply + ' gridOneCallUser'; 
        }else{
          classToApply = classToApply + ' inWaitingRoom'; 
        }
      }
      if ( this.showTimeLine ){
        classToApply = classToApply + ' withTimeLine'; 
      }else{
        classToApply = classToApply + ' withoutTimeLine'; 
      }
      return classToApply;
    }
  },
};
</script>