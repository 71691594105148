<template>
<div class="px-2">
  <div>
    {{$t('components.calendarContainer.oneCallParticipant')}}
  </div>
  
  <v-row class="mx-0">
    <v-col cols="6" class="px-1 py-1">
      <OneCallParticipants flat :visitor="visitorUser" />
    </v-col>
  </v-row>
</div>
</template>

<script>
import store from "../../../store";
import DefaultAvatar from "../../image/defaultAvatar.vue";
import { getStatusColorByUser } from "../../../utils/status";
import OneCallParticipants from "./oneCallParticipants.vue";
export default {
  props: ['entry'],
  components: {
    DefaultAvatar,
    OneCallParticipants
  },
  data() {
    return {
      state: store.state,
    };
  },
  mounted(){
    
  },
  computed: {
    setBorderByStatus() {
      try {
        return getStatusColorByUser(this.visitorUser, this.connected);
        
      } catch {}
    },
    visitorUUID() {
      return this.entry.oneCall.visitorUUID;
    },
    visitorGroupEntry() {
      return this.state.group[this.visitorUUID] || {};
    },
    visitorUser() {
      return this.visitorGroupEntry.user || {};
    },
    name() {
      return this.visitorUser.name || 'Unknown';
    },
    connected() {
      return this.visitorGroupEntry.connected || false;
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>

</style>