// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pointer[data-v-1f1429a8] {
  cursor: pointer;
}
.thumbs-down[data-v-1f1429a8] {
  color: red;
}
.thumbs-up[data-v-1f1429a8] {
  color: green;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/votingModal.vue","webpack://./votingModal.vue"],"names":[],"mappings":"AACA;EACE,eAAA;ACAF;ADEA;EACE,UAAA;ACCF;ADCA;EACE,YAAA;ACEF","sourcesContent":["\n.pointer {\n  cursor: pointer;\n}\n.thumbs-down {\n  color: red;\n}\n.thumbs-up {\n  color: green;\n}\n",".pointer {\n  cursor: pointer;\n}\n\n.thumbs-down {\n  color: red;\n}\n\n.thumbs-up {\n  color: green;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
