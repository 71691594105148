"use strict";

import debounce from "lodash.debounce";
import io from "socket.io-client";
import { aDelay } from '../lib/asyncUtil';
import { uid } from "./basicFunctions";

/* eslint-disable no-prototype-builtins, no-unused-vars, no-var */

/*
// https://gist.github.com/abhinavnigam2207/ecac54352f8f94db7a1a65d1d30e13d2
var debounce = (func, delay) => {
  let clearTimer;
  return function() {
    // eslint-disable-next-line consistent-this, no-invalid-this
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    clearTimeout(clearTimer);
    clearTimer = setTimeout(() => func.apply(context, args), delay);
  }
};
*/

/**
 * @param {Function} asyncCallback
 * @param {number} [maxRetries=2]
 * @param {number} [waitX=12]
 * @param {number} [waitY=99]
 * @throws {Error} If the call fails for over 5 seconds (with the current default retry-wait values).
 */
async function retryAsync(asyncCallback, maxRetries = 2, waitX = 12, waitY = 99) {
  let attempts = 0;
  let retVal;
  while (attempts <= maxRetries) {
    try {
      // If successful, return
      retVal = await asyncCallback();
      break;
    } catch (err) {
      attempts++;
      // Throw the error if exceeded max retries
      if (attempts > maxRetries) throw err;
      const wait = waitX * ((attempts * waitY) + (3 ** attempts));
      console.error('retryAsync - Waiting', wait, 'ms - Caught:', err.message || err.stack);
      await aDelay(wait); // await new Promise((resolve) => setTimeout(resolve, wait));
    }
  }
  return retVal;
}

export default function (socketioHost) {
// var Meeting = function (socketioHost) {
  let exports = {};

  let _isInitiator = false;
  let _localStream;
  let _localCamera; // Ours.
  let _localShareScreen; // Ours.
  let _localScreenStream;
  let _remoteStream;
  let _remoteScreenStream;
  let _turnReady;
  let _pcConfig = {
    iceServers: [
      {
        urls: "stun:stun.ra-micro.de:443",
        username: "voffice",
        credential: "xnPbGusEj7gAcHYH",
      },
      {
        urls: "turn:turn.ra-micro.de:443",
        username: "voffice",
        credential: "xnPbGusEj7gAcHYH",
      },
      {
        urls: "turn:turn.ra-micro.de:443?transport=tcp",
        username: "voffice",
        credential: "xnPbGusEj7gAcHYH",
      },
    ],
    sdpSemantics: "unified-plan",
  };
  let _constraints = {
    video: {
      width: { ideal: 640 },
      height: { ideal: 480 },
      frameRate: { ideal: 20 },
    },
    audio: {
      googEchoCancellation: true,
      googAutoGainControl: true,
      googNoiseSuppression: true,
      googHighpassFilter: true,
      googEchoCancellation2: true,
      googAutoGainControl2: true,
      googNoiseSuppression2: true,
    },
    options: {
      mirror: true,
    },
  };
  let _defaultChannel;
  let _privateAnswerChannel;
  let _offerChannels = {};
  let _opc = {};
  let _apc = {};
  let _sendChannel = {};
  let _room;
  let _myID;
  let _onRemoteVideoCallback;
  let _onSocketMessageCallback;
  let _onLocalVideoCallback;
  let _onRemoteScreenCallback;
  let _onRemoveRemoteScreenCallback;
  let _onLocalScreenCallback;
  let _onChatMessageCallback;
  let _onChatReadyCallback;
  let _onChatNotReadyCallback;
  let _onParticipantHangupCallback;
  let _onScreenShareHangupCallback;
  let _host = socketioHost;
  let _scpc = {};

  ////////////////////////////////////////////////
  // PUBLIC FUNCTIONS
  ////////////////////////////////////////////////

  /**
   * @returns The random ID assigned to our session.
   */
  function getMyID() {
    _myID = _myID || generateID();
    return _myID
  }

  /**
   *
   * Add callback function to be called when a chat message is available.
   *
   * @param {string} name of the room to join
   * @param {Function|Array=} createLocalTracksCallback which creates the cam & mic source
   */
  function joinRoom(name, createLocalTracksCallback) {
    _room = name;

    _myID = getMyID();
    console.debug("Generated ID:", _myID);

    // Open up a default communication channel
    initDefaultChannel();
    initScreenShareDefaultChannel();

    if (_room !== "") {
      console.debug("Create or join room", _room);
      _defaultChannel.emit("create or join", { room: _room, from: _myID });
    }

    // Open up a private communication channel
    initPrivateChannel();

    // Get local media data
    if (createLocalTracksCallback && typeof createLocalTracksCallback === 'object') {
      handleUserMedia(createLocalTracksCallback);
    } else {
      const constraints = (window.constraints = _constraints);
      const func = typeof createLocalTracksCallback === 'function' ? createLocalTracksCallback() : (navigator.mediaDevices
        .getUserMedia(constraints)
        .catch((err) => {
          console.warn('Video stream not accessible, falling back to audio only.', err);
          constraints.video = false;
          return navigator.mediaDevices.getUserMedia(constraints)
            .then((mediaSteam) => {
              // https://blog.mozilla.org/webrtc/warm-up-with-replacetrack/
              /** @returns {MediaStreamTrack} dummy video */
              const black = ({ width = 640, height = 480 } = {}) => {
                const canvas = Object.assign(document.createElement("canvas"), { width, height });
                canvas.getContext('2d').fillRect(0, 0, width, height);
                const stream = canvas.captureStream();
                const videoTrack = stream.getVideoTracks()[0];
                return Object.assign(videoTrack, { enabled: false });
              }
              return new MediaStream([black(), ...mediaSteam.getTracks()])
            });
        })
      );
      func
        .then(handleUserMedia)
        .catch(handleUserMediaError);
    }

    window.addEventListener("unload", ()=>{
      _defaultChannel.emit("message", { type: "bye", from: _myID });
    });
  }

  function startScreenShare(mediaSource = null) {
    if (mediaSource) return handleScreenMedia(mediaSource);
    navigator.mediaDevices
      .getDisplayMedia({
        video: {
          cursor: "always",
          displaySurface: "monitor",
          logicalSurface: false,
        },
        audio: false,
      })
      .then(handleScreenMedia, handleScreenMediaError);
  }

  // function to stop screen sharing
  function stopScreenSharing() {
    // This would be triggered by some UI element or event
    _localScreenStream.getTracks().forEach((track) => track.stop()); // Stop all tracks
    if (_defaultChannel) _defaultChannel.emit("stop-screen-share", {roomId : "sc_" + _room, offerId: _myID});
  }

  /**
   *
   * Send a chat message to all channels.
   *
   * @param message String message to be send
   */
  function sendChatMessage(message) {
    console.debug("Sending", message);
    for (let channel in _sendChannel) {
      if (_sendChannel.hasOwnProperty(channel)) {
        _sendChannel[channel].send(message);
      }
    }
  }

  /**
   *
   * Toggle microphone availability.
   *
   */
  function toggleMic() {
    const tracks = _localStream.getTracks();
    let micEnabled;
    for (let i = 0; i < tracks.length; i++) {
      if (tracks[i].kind == "audio") {
        tracks[i].enabled = !tracks[i].enabled;
        micEnabled = tracks[i].enabled;
      }
    }
    if (_defaultChannel && micEnabled !== undefined) _defaultChannel.emit("message", { type: "micToggled", from: _myID, enabled: micEnabled });
    return micEnabled;
  }

  /**
   *
   * Toggle video availability.
   *
   */
  function toggleVideo() {
    const tracks = _localStream.getTracks();
    let videoToggled;
    for (let i = 0; i < tracks.length; i++) {
      if (tracks[i].kind == "video") {
        tracks[i].enabled = !tracks[i].enabled;
        videoToggled = tracks[i].enabled;
      }
    }
    if (!_localCamera) {
      // TODO: Actually check if the camera is okay, if not - free and re-request
      // TODO: Recover camera somehow
    }
    if (_defaultChannel && videoToggled !== undefined) _defaultChannel.emit("message", { type: "videoToggled", from: _myID, enabled: videoToggled });
    return videoToggled;
  }

  /**
   * @param {MediaStream|false} mediaSource of the screen share
   */
  function toggleShareScreen(mediaSource) {
    if (mediaSource) {
      startScreenShare(mediaSource);
    } else {
      stopScreenSharing();
    }
    _localShareScreen = mediaSource;
    if (_defaultChannel) _defaultChannel.emit("message", { type: "shareScreen", from: _myID, enabled: !!_localShareScreen });
  }

  /**
   *
   * Add callback function to be called when remote video is available.
   *
   * @param callback of type function(stream, participantID)
   */
  function onRemoteVideo(callback) {
    _onRemoteVideoCallback = callback;
  }

  /**
   *
   * Add callback function to be called when a regular websocket message is received.
   *
   * @param callback of type function(message) // { type, from, enabled }
   */
  function onSocketMessage(callback) {
    _onSocketMessageCallback = callback;
  }

  /**
   *
   * Add callback function to be called when local video is available.
   *
   * @param callback function of type function(stream)
   */
  function onLocalVideo(callback) {
    _onLocalVideoCallback = callback;
  }

  /**
   *
   * Add callback function to be called when remote screen is available.
   *
   * @param callback of type function(stream, participantID)
   */
  function onRemoteScreen(callback) {
    _onRemoteScreenCallback = callback;
  }

  function onRemoveRemoteScreen(callback) {
    _onRemoveRemoteScreenCallback = callback;
  }

  /**
   *
   * Add callback function to be called when local screen is available.
   *
   * @param callback function of type function(stream)
   */
  function onLocalScreen(callback) {
    _onLocalScreenCallback = callback;
  }

  /**
   *
   * Add callback function to be called when chat is available.
   *
   * @parama callback function of type function()
   */
  function onChatReady(callback) {
    _onChatReadyCallback = callback;
  }

  /**
   *
   * Add callback function to be called when chat is no more available.
   *
   * @parama callback function of type function()
   */
  function onChatNotReady(callback) {
    _onChatNotReadyCallback = callback;
  }

  /**
   *
   * Add callback function to be called when a chat message is available.
   *
   * @parama callback function of type function(message)
   */
  function onChatMessage(callback) {
    _onChatMessageCallback = callback;
  }

  /**
   *
   * Add callback function to be called when a a participant left the conference.
   *
   * @parama callback function of type function(participantID)
   */
  function onParticipantHangup(callback) {
    _onParticipantHangupCallback = callback;
  }

  ////////////////////////////////////////////////
  // INIT FUNCTIONS
  ////////////////////////////////////////////////

  function initDefaultChannel() {
    _defaultChannel = openSignalingChannel("");

    _defaultChannel.on("created", function (room) {
      console.debug("Created room", room);
      _isInitiator = true;
    });

    _defaultChannel.on("join", function (room) {
      console.debug("Another peer made a request to join room", room);
    });

    _defaultChannel.on("joined", function (room) {
      console.debug("This peer has joined room", room);
    });

    _defaultChannel.on("message", function (message) {
      console.debug("Client received message:", message);
      if (message.type === "newparticipant") {
        let partID = message.from;

        // Open a new communication channel to the new participant
        _offerChannels[partID] = openSignalingChannel(partID);

        // Wait for answers (to offers) from the new participant
        _offerChannels[partID].on("message", function (msg) {
          if (msg.dest === _myID) {
            if (msg.type === "answer") {
              if (_opc[msg.from]?.signalingState !== 'have-local-offer') {
                return console.error("Cannot set remote description, signaling state is not 'have-local-offer'", { from: msg.from });
              }
              _opc[msg.from].setRemoteDescription(
                new RTCSessionDescription(msg.snDescription),
                () => {} /* setRemoteDescriptionSuccess */,
                setRemoteDescriptionError
              );
            } else if (msg.type === "candidate") {
              let candidate = new RTCIceCandidate({
                sdpMLineIndex: msg.label,
                candidate: msg.candidate,
              });
              console.debug("got ice candidate from", msg.from);
              _opc[msg.from].addIceCandidate(
                candidate,
                addIceCandidateSuccess,
                addIceCandidateError
              );
            }
          }
        });

        // Send an offer to the new participant
        createOffer(partID, _offerChannels[partID]);
      } else if (message.type === "bye") {
        hangup(message.from);
        hangupScreenshare(message.from);
      } else if (typeof _onSocketMessageCallback === 'function') {
        _onSocketMessageCallback(message);
      }
    });
  }

  function initScreenShareDefaultChannel() {
    // Join a predefined room
    _defaultChannel.emit("join-screen-share-room", "sc_" + _room);

    _defaultChannel.on("prepare-for-screen-share", (data) => {
      _defaultChannel.emit("request-screen-share", { to: data.from });
    });

    _defaultChannel.on("requested-screen-share", async (data) => {
      const peerConnection = createPeerConnection(data.from, _myID);
      _localScreenStream
        .getTracks()
        .forEach((track) => peerConnection.addTrack(track, _localScreenStream));
    });

    _defaultChannel.on("offer", async (data) => {
      const peerConnection = createPeerConnection(data.from, data.offerId);
      await peerConnection.setRemoteDescription(
        new RTCSessionDescription(data.offer)
      );
      const answer = await peerConnection.createAnswer();
      await peerConnection.setLocalDescription(answer);
      _defaultChannel.emit("answer", { to: data.from, answer });
    });

    _defaultChannel.on("answer", async (data) => {
      const peerConnection = _scpc[data.from];
      await peerConnection.setRemoteDescription(
        new RTCSessionDescription(data.answer)
      );
    });

    _defaultChannel.on("candidate", (data) => {
      const peerConnection = _scpc[data.from];
      if (peerConnection) {
        peerConnection.addIceCandidate(new RTCIceCandidate(data.candidate));
      }
    });

    _defaultChannel.on('stop-screen-share', (data) => {
      removeRemoteScreen(data.offerId);
  });
  }

  function createPeerConnection(socketId, userId) {
    console.debug("createPeerConnection userId:",  userId);
    const peerConnection = new RTCPeerConnection(_pcConfig);
    _scpc[socketId] = peerConnection;

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        _defaultChannel.emit("candidate", {
          to: socketId,
          candidate: event.candidate,
        });
      }
    };

    peerConnection.ontrack = (event) => {
      _remoteScreenStream = event.streams[0];

      if (event.track.kind == "video") {
        addRemoteScreen(_remoteScreenStream, userId);
      }
    };

    if (_localScreenStream) {
      peerConnection.onnegotiationneeded = async () => {
        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);
        _defaultChannel.emit("offer", { to: socketId, offer, offerId: userId });
      };
    }

    return peerConnection;
  }

  function initPrivateChannel() {
    // Open a private channel (namespace = _myID) to receive offers
    _privateAnswerChannel = openSignalingChannel(_myID);

    // Wait for offers or ice candidates
    _privateAnswerChannel.on("message", function (message) {
      if (message.dest === _myID) {
        if (message.type === "offer") {
          let to = message.from;
          createAnswer(message, _privateAnswerChannel, to);
        } else if (message.type === "candidate") {
          let candidate = new RTCIceCandidate({
            sdpMLineIndex: message.label,
            candidate: message.candidate,
          });
          _apc[message.from].addIceCandidate(
            candidate,
            addIceCandidateSuccess,
            addIceCandidateError
          );
        }
      }
    });
  }

  /**
   * Releases all resources held by meeting. To be invoked after leave.
   */
  function destroyMeeting() {
    if (_defaultChannel) {
      _defaultChannel.emit("message", { type: "bye", from: _myID });
      _defaultChannel.close();
      _defaultChannel = undefined;
    }
    if (_privateAnswerChannel) {
      _privateAnswerChannel.close();
      _privateAnswerChannel = undefined;
    }
    for (const key in _offerChannels) {
      if (Object.prototype.hasOwnProperty.call(_offerChannels, key)) {
        const channel = _offerChannels[key];
        channel.close();
        delete _offerChannels[key];
      }
    }
    for (const key in _sendChannel) {
      if (Object.prototype.hasOwnProperty.call(_sendChannel, key)) {
        const channel = _sendChannel[key];
        channel.close();
        delete _sendChannel[key];
      }
    }
    // Stop all tracks of the local media stream
    if (_localStream) {
      _localStream.getTracks().forEach(track => track.stop());
      _localStream = undefined; // Ensure the stream is cleared after stopping tracks
    }
  }

  function requestTurn(turn_url) {
    let turnExists = false;
    for (let i in _pcConfig.iceServers) {
      if (_pcConfig.iceServers[i].urls.substr(0, 5) === "turn:") {
        turnExists = true;
        _turnReady = true;
        break;
      }
    }

    if (!turnExists) {
      console.debug("Getting TURN server from", turn_url);
      let xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          let turnServer = JSON.parse(xhr.responseText);
          console.debug("Got TURN server:", turnServer);
          _pcConfig.iceServers.push({
            urls: "turn:" + turnServer.username + "@" + turnServer.turn,
            credential: turnServer.password,
          });
          _turnReady = true;
        }
      };
      xhr.open("GET", turn_url, true);
      xhr.send();
    }
  }

  ///////////////////////////////////////////
  // UTIL FUNCTIONS
  ///////////////////////////////////////////

  /**
   *
   * Call the registered _onRemoteVideoCallback
   *
   */
  function addRemoteVideo(stream, from) {
    // queue broadcast of mute status
    broadcastMuteStatusOnRemoteJoin();
    // call the callback
    _onRemoteVideoCallback(stream, from);
  }

  /**
   *
   * Call the registered _onRemoteScreenCallback
   *
   */
  function addRemoteScreen(stream, userId) {
    // call the callback
    _onRemoteScreenCallback(stream, userId);
  }

  function removeRemoteScreen(offerId) {
    // call the callback
    _onRemoveRemoteScreenCallback(offerId);
  }
  /**
   * Debounced broadcast of mute status to remote participants
   */
  let broadcastMuteStatusOnRemoteJoin = debounce(function () {
    const tracks = _localStream?.getTracks();
    if (!tracks) return;
    let micEnabled;
    for (let i = 0; i < tracks.length; i++) {
      if (tracks[i].kind == "audio") {
        micEnabled = tracks[i].enabled;
      }
    }
    let videoToggled;
    for (let i = 0; i < tracks.length; i++) {
      if (tracks[i].kind == "video") {
        videoToggled = tracks[i].enabled;
      }
    }
    if (_defaultChannel && micEnabled !== undefined) _defaultChannel.emit("message", { type: "micToggled", from: _myID, enabled: micEnabled });
    if (_defaultChannel && videoToggled !== undefined) _defaultChannel.emit("message", { type: "videoToggled", from: _myID, enabled: videoToggled });
    if (_defaultChannel && _localShareScreen) _defaultChannel.emit("message", { type: "shareScreen", from: _myID, enabled: !!_localShareScreen });
  }, 1000);

  /**
   *
   * Generates a random ID.
   *
   * @return a random ID
   */
  function generateID() {
    /*
    let s4 = function () {
      return Math.floor(Math.random() * 0x10000).toString(16);
    };
    return (s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4());
    */
    return (_myID = uid());
  }

  ////////////////////////////////////////////////
  // COMMUNICATION FUNCTIONS
  ////////////////////////////////////////////////

  /**
   *
   * Connect to the server and open a signal channel using channel as the channel's name.
   *
   * @return the socket
   */
  function openSignalingChannel(channel) {
    let namespace = _host + "/" + channel;
    console.debug("Opening private channel:",  namespace);
    let sckt = io.connect(namespace, {
      forceNew: true,
      transports: ["websocket"],
    });
    return sckt;
  }

  /**
   *
   * Send an offer to peer with id participantId
   *
   * @param participantId the participant's unique ID we want to send an offer
   */
  function createOffer(participantId, cnl) {
    console.debug("Creating offer for peer", participantId);
    _opc[participantId] = new RTCPeerConnection(_pcConfig);
    _opc[participantId].onicecandidate = handleIceCandidateAnswerWrapper(
      _offerChannels[participantId],
      participantId
    );
    _opc[participantId].ontrack = handleRemoteStreamAdded(participantId);
    // _opc[participantId].onremovetrack = handleRemoteStreamRemoved;

    if (_localStream) for (const track of _localStream.getTracks()) {
      _opc[participantId].addTrack(track, _localStream);
    }

    try {
      // Reliable Data Channels not yet supported in Chrome
      _sendChannel[participantId] = _opc[participantId].createDataChannel(
        "sendDataChannel",
        { reliable: false }
      );
      _sendChannel[participantId].onmessage = handleMessage;
      console.debug("Created send data channel");
    } catch (e) {
      alert(
        "Failed to create data channel. " +
          "You need Chrome M25 or later with RtpDataChannel enabled"
      );
      console.error("createDataChannel() failed with exception:", e.message);
    }
    _sendChannel[participantId].onopen =
      handleSendChannelStateChange(participantId);
    _sendChannel[participantId].onclose =
      handleSendChannelStateChange(participantId);

    let onSuccess = function (participantId) {
      return function (sessionDescription) {
        if (_opc[participantId]?.signalingState !== 'stable') {
          return console.error("Cannot create offer, signaling state is not 'stable'", { dest: participantId });
        }
        let channel = cnl || _offerChannels[participantId];

        // Set Opus as the preferred codec in SDP if Opus is present.
        sessionDescription.sdp = preferOpus(sessionDescription.sdp);

        _opc[participantId].setLocalDescription(sessionDescription)
          .then(() => {
            console.debug("Sending offer to channel", channel.nsp);
            channel.emit("message", {
              snDescription: sessionDescription,
              from: _myID,
              type: "offer",
              dest: participantId,
            });
          })
          .catch(setLocalDescriptionError);
      };
    };

    _opc[participantId].createOffer(
      onSuccess(participantId),
      handleCreateOfferError
    );
  }

  function createAnswer(sdp, cnl, to) {
    console.debug("Creating answer for peer", to);
    _apc[to] = new RTCPeerConnection(_pcConfig);
    _apc[to].onicecandidate = handleIceCandidateAnswerWrapper(cnl, to);
    _apc[to].ontrack = handleRemoteStreamAdded(to);
    // _apc[to].onremovetrack = handleRemoteStreamRemoved;

    if (_localStream) for (const track of _localStream.getTracks()) {
      _apc[to].addTrack(track, _localStream);
    }

    _apc[to].ondatachannel = gotReceiveChannel(to);

    let onSuccess = function (channel) {
      return function (sessionDescription) {
        if (_apc[to]?.signalingState !== 'have-remote-offer') {
          return console.error("Cannot set local description, signaling state is not 'have-remote-offer'", { dest: to });
        }
        // Set Opus as the preferred codec in SDP if Opus is present.
        sessionDescription.sdp = preferOpus(sessionDescription.sdp);

        _apc[to].setLocalDescription(sessionDescription)
          .then(() => {
            console.debug("Sending answer to channel", channel.nsp);
            channel.emit("message", {
              snDescription: sessionDescription,
              from: _myID,
              type: "answer",
              dest: to,
            });
          })
          .catch(setLocalDescriptionError);
      };
    };

    _apc[to]
      .setRemoteDescription(new RTCSessionDescription(sdp.snDescription))
      .then(() => _apc[to].createAnswer(onSuccess(cnl), handleCreateAnswerError))
      .catch(setRemoteDescriptionError);
  }

  function hangup(from) {
    console.debug("Bye received from", from);

    if (_opc.hasOwnProperty(from)) {
      _opc[from]?.close();
      _opc[from] = null;
    }

    if (_apc.hasOwnProperty(from)) {
      _apc[from]?.close();
      _apc[from] = null;
    }

    _onParticipantHangupCallback(from);
  }

  function hangupScreenshare(from) {
    removeRemoteScreen(from)
    // _defaultChannel.emit("stop-screen-share", {roomId : "sc_" + _room, offerId: from});
  }

  ////////////////////////////////////////////////
  // HANDLERS
  ////////////////////////////////////////////////

  // SUCCESS HANDLERS

  function handleUserMedia(stream) {
    console.debug("Adding local stream");
    _localStream = stream;
    _localCamera = stream?.getTracks().find(t => t.kind === 'video');
    _onLocalVideoCallback(stream);
    _defaultChannel.emit("message", { type: "newparticipant", from: _myID });
  }

  function handleScreenMedia(stream) {
    console.debug("Adding local screen stream");
    _onLocalScreenCallback(stream);
    _localScreenStream = stream;
    _defaultChannel.emit("start-screen-share", "sc_" + _room);
  }

  function handleRemoteStreamRemoved(event) {
    console.debug("Remote stream removed. Event:", event);
  }

  function handleRemoteStreamAdded(from) {
    return function (event) {
      console.debug("Remote stream added");

      _remoteStream = event.streams[0];

      if (event.track.kind == "video") {
        addRemoteVideo(_remoteStream, from);
      }
    };
  }

  function handleIceCandidateAnswerWrapper(channel, to) {
    return function handleIceCandidate(event) {
      console.debug("handleIceCandidate event");
      if (event.candidate) {
        channel.emit("message", {
          type: "candidate",
          label: event.candidate.sdpMLineIndex,
          id: event.candidate.sdpMid,
          candidate: event.candidate.candidate,
          from: _myID,
          dest: to,
        });
      } else {
        console.debug("End of candidates.");
      }
    };
  }

  function addIceCandidateSuccess() {}

  function gotReceiveChannel(id) {
    return function (event) {
      console.debug("Receive Channel Callback");
      _sendChannel[id] = event.channel;
      _sendChannel[id].onmessage = handleMessage;
      _sendChannel[id].onopen = handleReceiveChannelStateChange(id);
      _sendChannel[id].onclose = handleReceiveChannelStateChange(id);
    };
  }

  function handleMessage(event) {
    console.debug("Received message:", event.data);
    _onChatMessageCallback(event.data);
  }

  function handleSendChannelStateChange(participantId) {
    return function () {
      let readyState = _sendChannel[participantId]?.readyState;
      console.debug("Send channel state is:", readyState);

      // check if we have at least one open channel before we set hat ready to false.
      let open = checkIfOpenChannel();
      enableMessageInterface(open);
    };
  }

  function handleReceiveChannelStateChange(participantId) {
    return function () {
      let readyState = _sendChannel[participantId]?.readyState;
      console.debug("Receive channel state is:", readyState);

      // check if we have at least one open channel before we set hat ready to false.
      let open = checkIfOpenChannel();
      enableMessageInterface(open);
    };
  }

  function checkIfOpenChannel() {
    let open = false;
    for (let channel in _sendChannel) {
      if (_sendChannel.hasOwnProperty(channel)) {
        open = _sendChannel[channel].readyState == "open";
        if (open == true) {
          break;
        }
      }
    }

    return open;
  }

  function enableMessageInterface(shouldEnable) {
    if (shouldEnable) {
      _onChatReadyCallback();
    } else {
      _onChatNotReadyCallback();
    }
  }

  // ERROR HANDLERS

  function handleCreateOfferError(event) {
    console.error("createOffer error:", event);
  }

  function handleCreateAnswerError(event) {
    console.error("createAnswer error:", event);
  }

  function handleUserMediaError(error) {
    console.error("getUserMedia error:", error);
  }

  function handleScreenMediaError(error) {
    console.error("getDisplayMedia error:", error);
  }

  function setLocalDescriptionError(error) {
    console.error("setLocalDescription error:", error);
  }

  function setRemoteDescriptionError(error) {
    console.error("setRemoteDescription error:", error);
  }

  function addIceCandidateError(error) {
    console.error("addIceCandidate error:", error);
  }

  ////////////////////////////////////////////////
  // CODEC
  ////////////////////////////////////////////////

  // Set Opus as the default audio codec if it's present.
  function preferOpus(sdp) {
    let sdpLines = sdp.split("\r\n");
    let mLineIndex;
    // Search for m line.
    for (let i = 0; i < sdpLines.length; i++) {
      if (sdpLines[i].search("m=audio") !== -1) {
        mLineIndex = i;
        break;
      }
    }
    if (mLineIndex === null || mLineIndex === undefined) {
      return sdp;
    }

    // If Opus is available, set it as the default in m line.
    for (let i = 0; i < sdpLines.length; i++) {
      if (sdpLines[i].search("opus/48000") !== -1) {
        let opusPayload = extractSdp(sdpLines[i], /:(\d+) opus\/48000/i);
        if (opusPayload) {
          sdpLines[mLineIndex] = setDefaultCodec(
            sdpLines[mLineIndex],
            opusPayload
          );
        }
        break;
      }
    }

    // Remove CN in m line and sdp.
    sdpLines = removeCN(sdpLines, mLineIndex);

    sdp = sdpLines.join("\r\n");
    return sdp;
  }

  function extractSdp(sdpLine, pattern) {
    let result = sdpLine.match(pattern);
    return result && result.length === 2 ? result[1] : null;
  }

  // Set the selected codec to the first in m line.
  function setDefaultCodec(mLine, payload) {
    let elements = mLine.split(" ");
    let newLine = [];
    let index = 0;
    for (let i = 0; i < elements.length; i++) {
      if (index === 3) {
        // Format of media starts from the fourth.
        newLine[index++] = payload; // Put target payload to the first.
      }
      if (elements[i] !== payload) {
        newLine[index++] = elements[i];
      }
    }
    return newLine.join(" ");
  }

  // Strip CN from sdp before CN constraints is ready.
  function removeCN(sdpLines, mLineIndex) {
    let mLineElements = sdpLines[mLineIndex].split(" ");
    // Scan from end for the convenience of removing an item.
    for (let i = sdpLines.length - 1; i >= 0; i--) {
      let payload = extractSdp(sdpLines[i], /a=rtpmap:(\d+) CN\/\d+/i);
      if (payload) {
        let cnPos = mLineElements.indexOf(payload);
        if (cnPos !== -1) {
          // Remove CN payload from m line.
          mLineElements.splice(cnPos, 1);
        }
        // Remove CN line in sdp
        sdpLines.splice(i, 1);
      }
    }

    sdpLines[mLineIndex] = mLineElements.join(" ");
    return sdpLines;
  }

  ////////////////////////////////////////////////
  // EXPORT PUBLIC FUNCTIONS
  ////////////////////////////////////////////////

  exports.getMyID = getMyID;
  exports.generateID = generateID;
  exports.destroyMeeting = destroyMeeting;
  exports.joinRoom = joinRoom;
  exports.startScreenShare = startScreenShare;
  exports.stopScreenSharing = stopScreenSharing;
  exports.toggleMic = toggleMic;
  exports.toggleVideo = toggleVideo;
  exports.toggleShareScreen = toggleShareScreen;
  exports.onLocalVideo = onLocalVideo;
  exports.onRemoteVideo = onRemoteVideo;
  exports.onSocketMessage = onSocketMessage;
  exports.onLocalScreen = onLocalScreen;
  exports.onRemoteScreen = onRemoteScreen;
  exports.onRemoveRemoteScreen = onRemoveRemoteScreen;
  exports.onChatReady = onChatReady;
  exports.onChatNotReady = onChatNotReady;
  exports.onChatMessage = onChatMessage;
  exports.sendChatMessage = sendChatMessage;
  exports.onParticipantHangup = onParticipantHangup;

  return exports;
}
