<template>
  <div>
    <span>Effect {{name}} -- Working: {{pendingBool}} {{inFlightAmount}} Done: {{doneCounter}} Fail: {{failCounter}} Finally: {{finallyCounter}}</span>
    <div v-if="failLastError">
      <span>Last Error:</span>
      <pre>{{JSON.stringify(failLastParams, null, 2)}}</pre>
      <pre>{{JSON.stringify(failLastError.message, null, 2)}}</pre>
    </div>
  </div>
</template>

<script>
export default {
  props: ['effect'],
  // components: { },
  data() {
    return {
      doneCounter: 0,
      failCounter: 0,
      failLastParams: null,
      failLastError: null,
      finallyCounter: 0,
      inFlightAmount: 0,
      pendingBool: false,
    };
  },
  methods: {
    doneWatcher({params, result}) {
      this.doneCounter++;
    },
    failWatcher({params, error}) {
      this.failCounter++;
      this.failLastParams = params;
      this.failLastError = error;
    },
    finallyWatcher() {
      this.finallyCounter++;
    },
    inFlightWatcher(amount) {
      this.inFlightAmount = amount;
    },
    pendingWatcher(bool) {
      this.pendingBool = bool;
    },
    subscribe() {
      this.unsubscribe();
      if (!this.effect) return;
      this._unsub.push(this.effect.done.watch(this.doneWatcher.bind(this)));
      this._unsub.push(this.effect.fail.watch(this.failWatcher.bind(this)));
      this._unsub.push(this.effect.finally.watch(this.finallyWatcher.bind(this)));
      this._unsub.push(this.effect.inFlight.watch(this.inFlightWatcher.bind(this)));
      this._unsub.push(this.effect.pending.watch(this.pendingWatcher.bind(this)));
    },
    unsubscribe() {
      if (this._unsub) {
        for (const u of this._unsub) {
          try {
            u();
          } catch (err) { console.warn('unsub error', err); }
        }
      }
      this._unsub = [];
    },
  },
  computed: {
    name() {
      if (!this.effect) return '';
      return this.effect.shortName;
    }
  },
  mounted() {
    this.subscribe();
  },
  unmounted() {
    this.unsubscribe();
  },
  watch: {
    effect() { this.subscribe(); },
  }
};
</script>

<style scoped lang="scss">
pre {
  font-size: 8px;
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>