import Vue from '@vue/compat';

export function isSectionSupervisor (row) {
  return row && !!row.sectionRights;
}

export function isDepartmentSupervisor (row) {
  return row && !!row.departmentRights;
}

export function initComponent (td, component, options) {
  td.innerHTML = '';
  td.className = 'is-paddingless';
  td.appendChild(document.createElement('div'));

  return new Vue({
    el: td.firstChild,
    render: createElement => createElement(component, options)
  });
}

export function filterUndefined(object) {
  return Object.fromEntries(
      Object.entries(object).filter(([k, v]) => v !== undefined)
  )
}

export function mergeIgnoreUndefined(original, objectToMerge) {
  return Object.assign(original, filterUndefined(objectToMerge));
}
