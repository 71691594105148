<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="750"
    height="95%"
    @keydown.esc="closeModal"
  >
    <v-card>
      <v-card-title
        class="headline"
        :class="setBackground"
        :style="`color:${
          setBackground !== 'white' && setBackground !== null
            ? 'white'
            : 'unset'
        }`"
        v-if="!editMode"
      >
        <v-row class="mx-0">
          <v-col cols="6" class="px-1 py-1">
            <v-img
              class="mr-1 logoModal"
              src="assets/icon.png"
              max-height="30"
              max-width="30"
              contain
            ></v-img>
            {{ $t("components.eventEntryForm.plannedVideoCall") }}
          </v-col>
          <v-col cols="6" class="px-1 py-1 text-right" v-if="!editMode">
            <span class="font13">{{ getStartDate }}</span>
            <span class="font13">{{ getTimeRange }}</span>
            <v-tooltip location="top" v-if="canRSVP">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon color="white" @click="rsvp(true)">
                  <font-awesome-icon
                    :icon="['fal', 'check']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.meetingLineModal.accept") }}</span>
            </v-tooltip>
            <v-tooltip location="top" v-if="canRSVP">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="white" icon @click="rsvp(false)">
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.meetingLineModal.reject") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>
      <v-list-item class="py-0 switchWrapper" :class="{ 'px-0': editMode }">
        <NewEventForm
          v-show="editMode"
          :editMode="editMode"
          ref="entryForm"
          :existingEvent="modalEntry"
          :hideButtons="true"
          :setBackground="setBackground"
        />
        <ViewEvent v-show="!editMode" :entry="modalEntry" />
      </v-list-item>
      <v-card color="primary" class="pl-4 pr-2 footerModal">
        <v-row class="px-2 btns w-100 mx-0 my-0">
          <template>
            <v-list
              class="menuFooter"
              color="primary"
              :style="{ display: 'inline-flex' }"
            >
              <v-list-item class="mx-3" v-if="editMode">
                <v-tooltip v-if="editMode" top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :disabled="!canDeleteEvent"
                      v-on="on"
                      icon
                      color="white"
                      class="closeButton"
                    >
                      <DeleteBasket
                        indexData=""
                        :delFunction="deleteEvent"
                        extraClasses=""
                        fontSize="25"
                        prevent="false"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("generics.delete") }}</span>
                </v-tooltip>
              </v-list-item>
              <v-list-item class="mx-3" v-if="editMode">
                <v-tooltip v-if="editMode" top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :disabled="!canEditEvent"
                      v-on="on"
                      icon
                      color="white"
                      @click="resetForm()"
                      class="closeButton"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'undo']"
                        :style="{ fontSize: '25px' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("components.eventEntryForm.reset") }}</span>
                </v-tooltip>
              </v-list-item>
              <v-list-item class="mx-3" v-if="editMode">
                <v-tooltip v-if="editMode" top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :disabled="!canAddEvent"
                      v-on="on"
                      icon
                      color="white"
                      @click="saveEvent()"
                      class="closeButton"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'check']"
                        :style="{ fontSize: '25px' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("generics.save") }}</span>
                </v-tooltip>
              </v-list-item>
              <v-list-item
                class="mx-3"
                v-if="haveExistingEntry && canEditEvent"
              >
                <v-tooltip v-if="haveExistingEntry && canEditEvent" top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      color="white"
                      @click="toggleEdit()"
                      class="closeButton"
                    >
                      <font-awesome-icon
                        v-if="editMode"
                        :icon="['fal', 'file']"
                        :style="{ fontSize: '25px' }"
                      />
                      <font-awesome-icon
                        v-else
                        :icon="['fal', 'edit']"
                        :style="{ fontSize: '25px' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{
                    editMode ? $t("generics.info") : $t("generics.edit")
                  }}</span>
                </v-tooltip>
              </v-list-item>
              <v-list-item class="mx-3">
                <v-tooltip location="top" v-if="isMyConference">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      color="white"
                      @click="callEveryone()"
                      class="closeButton"
                      :disabled="!canCallEveryone"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'phone']"
                        :style="{ fontSize: '25px' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("components.eventEntryForm.callUsers") }}</span>
                </v-tooltip>
              </v-list-item>
              <v-list-item class="mx-3">
                <v-tooltip location="top">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      color="white"
                      @click="closeModal()"
                      class="closeButton"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'times']"
                        :style="{ fontSize: '25px' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("generics.close") }}</span>
                </v-tooltip>
              </v-list-item>
            </v-list>
          </template>
        </v-row>
      </v-card>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>
<script>
import { useStore } from "effector-vue/composition";
import {
  setEventStateModalEvent,
  resetEventStateModalEvent,
  eventModalStateStore,
} from "../../effector/modals";
import { actingAsUuidStore } from "../../effector/representative";
import NewEventForm from "../../components/timeline/newEventForm.vue";
import DeleteBasket from "../../components/ui/deleteBasket.vue";
import ViewEvent from "../../components/meetingLine/viewEvent.vue";
import { callUUID } from "../../lib/rtcConn";
import store from "../../store";
import {
  dispatchRsvpTimelineEntry,
  updateTimelineItemDurationEffect,
} from "../../effector/timeline";
import { callPerson } from "../../utils/calls";
import moment from "../../../sharedsrc/moment";
export default {
  components: { NewEventForm, ViewEvent, DeleteBasket },
  data() {
    const effector = {
      modalEntry: eventModalStateStore,
      ownUserUUID: actingAsUuidStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      editMode: false,
      mounted: false,
      entryFormRef: null,
      duration: 0,
      initialDuration: 0,
      moment: moment,
      // Effector
      ...effector,
    };
  },
  watch: {
    modalEntry() {
      this.getInitialValue;
      this.updateEditmodeIfNeeded();
    },
  },
  mounted() {
    this.updateEditmodeIfNeeded();
  },
  updated() {
    if (this.entryFormRef !== this.$refs.entryForm)
      this.entryFormRef = this.$refs.entryForm;
  },
  methods: {
    updateDuration() {
      let tempend = moment(this.modalEntry.start).format("YYYY-MM-DD HH:mm");
      tempend = moment(tempend)
        .add(this.duration, "minutes")
        .format("YYYY-MM-DD HH:mm");
      this.modalEntry.end = moment(tempend).format("YYYY-MM-DD HH:mm");
      const mssg = this.$t("components.notificationsModal.notificationText", [
        moment(this.modalEntry.start).format("DD.MM.YYYY HH:mm"),
      ]);
      updateTimelineItemDurationEffect([
        this.modalEntry.uuid,
        this.modalEntry,
        mssg,
      ]);
      setEventStateModalEvent(this.modalEntry);
      this.closeModal();
    },
    rsvp(acceptBool) {
      if (!this.modalEntry || !this.modalEntry.uuid)
        throw new Error("Missing modal entry/uuid");
      if (typeof acceptBool !== "boolean")
        throw new Error("Invalid argument type");
      if (this.duration !== this.initialDuration && acceptBool === true) {
        // update duration
        this.updateDuration();
      } else {
        dispatchRsvpTimelineEntry([this.modalEntry.uuid, acceptBool]);
        this.closeModal();
      }
    },
    callUser(uuid) {
      callPerson(uuid, this.$router);
    },
    async callEveryone() {
      let options = {};
      options.isConference = true;
      options.confId = this.modalEntry.uuid;
      let confGroup = store.state.user.userGroups.find(
        (e) => e.id == (this.modalEntry || {}).groupId || "0"
      );
      if (confGroup && (confGroup.pinProtected || false) === true) {
        options.isPinProtected = true;
        options.pinCode = confGroup.pinCode;
      }
      const confObj = {
        isConference: options.isConference,
        confId: options.confId,
        ownerConference: this.modalEntry.creatorUUID,
        color: this.modalEntry.color,
      };
      store.setUserInConference(confObj);
      if (this.modalEntry.end && this.modalEntry.start) {
        const durationMin = this.moment(this.modalEntry.end).diff(
          this.moment(this.modalEntry.start)
        );
        this.setdurationMeeting(durationMin);
      }
      if (this.modalEntry && this.modalEntry.type === "presentationCall") {
        this.setPresentationView();
      }
      if (this.modalEntry && this.modalEntry.users) {
        for (const userUUID of this.modalEntry.users) {
          const person = store.state.group[userUUID];
          if (
            person &&
            !person.user.rtcCallStatus &&
            !person.user.rtcCallStatus.length &&
            (!person.user.rtcCallStatus.length > 5 || !person.user.inBridgeCall)
          ) {
            if (!this.amInACall) {
              await callUUID(userUUID, options);
            } else {
              this.callUser(userUUID, options);
            }
          }
        }
      }
      if (this.modalEntry && this.modalEntry.host !== store.state.ownUUID) {
        if (!this.amInACall) {
          await callUUID(this.modalEntry.host);
        } else {
          this.callUser(this.modalEntry.host);
        }
      } else if (
        this.modalEntry &&
        this.modalEntry.creatorUUID !== store.state.ownUUID
      ) {
        if (!this.amInACall) {
          await callUUID(this.modalEntry.creatorUUID);
        } else {
          this.callUser(this.modalEntry.creatorUUID);
        }
      }
      this.closeModal();
    },
    setdurationMeeting(duration) {
      return store.setdurationMeeting(parseInt(duration));
    },
    setPresentationView() {
      return store.setPresentationView(true, this.ownUserUUID);
    },
    updateEditmodeIfNeeded() {
      if (this.modalEntry === null) return;
      // has initVals for new entry
      if (this.modalEntry.startHourSupplied) {
        this.editMode = true;
        this.initialData = this.modalEntry;
      } else {
        if (this.haveExistingEntry) this.editMode = false;
        else this.editMode = true;
      }
    },
    toggleEdit() {
      this.editMode = !this.editMode;
    },
    deleteEvent() {
      this.$refs.entryForm.deleteEvent();
    },
    resetForm() {
      this.$refs.entryForm.resetInputEvent();
    },
    saveEvent() {
      this.$refs.entryForm.saveEventData();
    },
    closeModal() {
      if (store.state.showAddGroupModal) {
        store.state.showAddGroupModal = false;
      }
      resetEventStateModalEvent();
    },
  },
  computed: {
    isMyConference() {
      if (this.modalEntry) {
        return this.modalEntry.creatorUUID === store.state.ownUUID;
      } else {
        return false;
      }
    },
    amInACall() {
      return !!Object.keys(store.state.rtc).length;
    },
    getInitialValue() {
      if (!this.modalEntry) return;
      const start = moment(this.modalEntry.start);
      const end = moment(this.modalEntry.end);
      const mins = moment(end).diff(moment(start));
      /* eslint-disable vue/no-side-effects-in-computed-properties */
      this.duration = moment.duration(mins).asMinutes();
      this.initialDuration = moment.duration(mins).asMinutes();
      /* eslint-enable vue/no-side-effects-in-computed-properties */
    },
    getStartDate() {
      if (!this.modalEntry) return false;
      return moment(new Date(this.modalEntry.start)).format("DD.MM.YYYY");
    },
    getTimeRange() {
      if (!this.modalEntry) return false;
      return (
        moment(this.modalEntry.start).format("HH:mm") +
        "-" +
        moment(this.modalEntry.end).format("HH:mm")
      );
    },
    canRSVP() {
      if (!this.modalEntry) return false;
      if (!this.modalEntry.rsvp) return false;
      const ourRSVP = this.modalEntry.rsvp[this.ownUserUUID];
      if (!ourRSVP) return false;
      return true;
    },
    setBackground() {
      if (this.modalEntry && this.modalEntry.color) {
        return this.modalEntry.color;
      } else if (
        this.modalEntry &&
        !this.modalEntry.color &&
        this.modalEntry.isConference
      ) {
        return "blue";
      } else {
        return "orange";
      }
    },
    showModal() {
      // Only if Visible
      if (store.state.showAddGroupModal) {
        return true;
      } else {
        return !!this.modalEntry;
      }
    },
    haveExistingEntry() {
      return this.modalEntry && this.modalEntry.uuid;
    },
    canAddEvent() {
      if (!this.entryFormRef) return false;
      return this.entryFormRef.canAddEvent;
    },
    canEditEvent() {
      if (!this.entryFormRef) return false;
      return this.entryFormRef.canEditEvent;
    },
    canDeleteEvent() {
      if (!this.entryFormRef) return false;
      return this.entryFormRef.canDeleteEvent;
    },
    canCallEveryone() {
      return (
        !this.editMode &&
        this.modalEntry &&
        this.modalEntry.users &&
        this.modalEntry.users.length > 0 &&
        this.modalEntry.creatorUUID === store.state.ownUUID &&
        !this.amInACall
      );
    },
  },
};
</script>
<style scoped lang="scss">
.logoModal {
  display: inline-block;
  top: 6px;
}
.font13 {
  font-size: 15px;
}
.menuFooter {
  .v-list-item {
    padding-left: 4px;
    padding-right: 4px;
    max-width: 51px;
  }
}
</style>