// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputDirectInviteLink[data-v-4e9830ee] {
  width: 80%;
  margin: 0 auto !important;
}
.buttonCopyLink[data-v-4e9830ee] {
  top: -5px;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/calls/sidebarCall/settingsCall.vue","webpack://./settingsCall.vue"],"names":[],"mappings":"AACA;EACE,UAAA;EACA,yBAAA;ACAF;ADEA;EACE,SAAA;EACA,kBAAA;ACCF","sourcesContent":["\n.inputDirectInviteLink {\n  width: 80%;\n  margin: 0 auto !important;\n}\n.buttonCopyLink{\n  top: -5px;\n  position: relative;\n}\n",".inputDirectInviteLink {\n  width: 80%;\n  margin: 0 auto !important;\n}\n\n.buttonCopyLink {\n  top: -5px;\n  position: relative;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
