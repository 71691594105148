// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.locationIcon[data-v-0e03071a] {
  pointer-events: none;
  background-color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/customStatusIcon.vue","webpack://./customStatusIcon.vue"],"names":[],"mappings":"AACA;EACE,oBAAA;EACA,yBAAA;ACAF","sourcesContent":["\n.locationIcon {\n  pointer-events: none;\n  background-color: #ffffff;\n}\n",".locationIcon {\n  pointer-events: none;\n  background-color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
