// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w-100[data-v-789110b2] {
  width: 100%;
}
.borderRadius10[data-v-789110b2],
.v-avatar .v-image[data-v-789110b2] {
  border-radius: 10px !important;
}
.footerModal[data-v-789110b2] {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
}
.footerModal .btns[data-v-789110b2] {
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/downloadsFilesModal.vue","webpack://./downloadsFilesModal.vue"],"names":[],"mappings":"AACA;EACE,WAAA;ACAF;ADEA;;EAEE,8BAAA;ACCF;ADCA;EACE,WAAA;EACA,6BAAA;EACA,uBAAA;ACEF;ADDE;EACE,yBAAA;ACGJ","sourcesContent":["\n.w-100 {\n  width: 100%;\n}\n.borderRadius10,\n.v-avatar .v-image {\n  border-radius: 10px !important;\n}\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n  .btns {\n    justify-content: flex-end;\n  }\n}\n",".w-100 {\n  width: 100%;\n}\n\n.borderRadius10,\n.v-avatar .v-image {\n  border-radius: 10px !important;\n}\n\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n}\n.footerModal .btns {\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
