<template>
 <v-tooltip location="top">
  <template v-slot:activator="{ props }">
    <font-awesome-icon
      style="cursor:pointer"
      v-bind="props"
      :icon="['fal', 'trash']"
      :class="`${!deleteActive ? color === 'white'? 'deleteBasketWhite' : 'deleteBasketNormal' : ''} ${
        deleteActive ? 'deleteBasketActive' : ''
      } ${extraClasses}`"
      :style="{ fontSize: `${parseInt(fontSize) + 'px'}` }"
      :disabled="isDeleting"
      @click="handleClick($event)"
    >
    </font-awesome-icon>
  </template>
  <span>{{
    text
  }}</span>
</v-tooltip>
</template>
<script>
export default {
  props: [
    "indexData",
    "delFunction",
    "extraFunction",
    "extraFunctionParams",
    "extraClasses",
    "fontSize",
    "prevent",
    "color",
    "organigramTT"
  ],
  components: {},
  data() {
    return {
      deleteActive: false,
      isDeleting: false,
    };
  },
  methods: {
    async handleClick(e) {
      if (this.prevent) {
        e.stopPropagation();
        e.preventDefault();
      }
      if (!this.deleteActive) {
        this.deleteActive = true;
        setTimeout(() => {
          this.deleteActive = false;
        }, 2000);
      } else {
        if (this.indexData !== "") {
          this.isDeleting = true;
          if (Array.isArray(this.indexData)) {
            await this.delFunction(...this.indexData);
          } else {
            await this.delFunction(this.indexData);
          }

          this.isDeleting = false;
        } else {
          this.isDeleting = true;
          await this.delFunction();

          this.isDeleting = false;
        }
        this.deleteActive = false;
        if (this.extraFunction) {
          if (
            this.extraFunctionParams &&
            Array.isArray(this.extraFunctionParams)
          ) {
            this.extraFunction(...this.extraFunctionParams);
          } else {
            this.extraFunction(this.extraFunctionParams);
          }
        }
      }
    },
  },
  computed: {
    text() {
      return this.organigramTT || this.$t("components.conferenceForm.confirmRemove")
    }
  },
};
</script>