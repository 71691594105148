// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blinken[data-v-5cac8ec3] {
  position: fixed;
  top: 100px;
  right: 5px;
  z-index: 100000;
  min-width: 400px;
  background-color: gray;
  border: 1px solid black;
  max-height: 90%;
  max-width: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./websrc/components/dev/blinkenlights/blinkenlights.vue","webpack://./blinkenlights.vue"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,UAAA;EACA,UAAA;EACA,eAAA;EACA,gBAAA;EACA,sBAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;ACAF","sourcesContent":["\n.blinken {\n  position: fixed;\n  top: 100px;\n  right: 5px;\n  z-index: 100000;\n  min-width: 400px;\n  background-color: gray;\n  border: 1px solid black;\n  max-height: 90%;\n  max-width: 400px;\n  overflow-y: scroll;\n  overflow-x: hidden;\n}\n",".blinken {\n  position: fixed;\n  top: 100px;\n  right: 5px;\n  z-index: 100000;\n  min-width: 400px;\n  background-color: gray;\n  border: 1px solid black;\n  max-height: 90%;\n  max-width: 400px;\n  overflow-y: scroll;\n  overflow-x: hidden;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
