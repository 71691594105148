import moment from "../../../sharedsrc/moment";
import { cachedTimelineEvents, timelineEvents } from './state';

const ticketCacheStores = {};

export function getTicketStore(uuid) {
  if (!ticketCacheStores[uuid]) {
    ticketCacheStores[uuid] = cachedTimelineEvents.map(state => state[uuid]);
  }
  // console.log('userStateFilterCache', uuid, userStateFilterCache[uuid], userStateFilterCache);
  return ticketCacheStores[uuid];
}

const timelineDateFilterCache = {};

export function getTimelineDateFilter(date) {
  const startDate = new Date(date);
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + 1);

  const key = startDate.toISOString() + '_' + endDate.toISOString();

  if (!timelineDateFilterCache[key]) {
    timelineDateFilterCache[key] = timelineEvents.map(state => state.filter((entry) => {
      
      if (entry.start && new Date(entry.start) >= startDate && new Date(entry.start) < endDate) return true;
      if (entry.end && new Date(entry.end) >= startDate && new Date(entry.end)  < endDate) return true;
      // else if(entry.start && new Date(e))

      if (moment(startDate).isBetween(entry.start, entry.end, undefined, '()')) return true;
      if (moment(endDate).isBetween(entry.start, entry.end, undefined, '()')) return true;

      return false;
    }));
  }
  // console.log('getTimelineDateFilter', date, key, timelineDateFilterCache[key]);
  return timelineDateFilterCache[key];
}