import { errorCameraPermissionsModalDomain } from './domain';

import { setErrorCameraPermissionsModalEvent, resetErrorCameraPermissionsModalEvent } from './events';

export const errorCameraPermissionsModalStore = errorCameraPermissionsModalDomain.createStore(false, { name: 'errorCameraPermissionsModal' });

errorCameraPermissionsModalStore.on(setErrorCameraPermissionsModalEvent, (state, payload) => {
  return payload;
});

errorCameraPermissionsModalStore.reset(resetErrorCameraPermissionsModalEvent);
