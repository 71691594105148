import { groupConferenceDomain } from './domain';

import { 
  getUserGroupConferences, 
  createGroupConferenceEntry, 
  getGroupConferenceEntryByUUID, 
  deleteGroupConferenceEntry, 
  getMyConferencesDetailEntries, 
  updateGroupConference,
 } from '../../lib/wsMsg';

export const fetchMyGroupConferencesEffect = groupConferenceDomain.createEffect({
  name: 'fetchMyGroupConferencesEffect',
  handler: getUserGroupConferences,
});

export const createGroupConferenceEntryEffect = groupConferenceDomain.createEffect({
  name: 'createGroupConferenceEntryEffect',
  handler: createGroupConferenceEntry,
});

export const getGroupConferenceEntryByUUIDEffect = groupConferenceDomain.createEffect({
  name: 'getGroupConferenceEntryByUUIDEffect',
  handler: getGroupConferenceEntryByUUID,
});

export const deleteGroupConferenceEntryEffect = groupConferenceDomain.createEffect({
  name: 'deleteGroupConferenceEntryEffect',
  handler: (payload) => { return deleteGroupConferenceEntry(payload[0]); }
});

export const getMyConferencesDetailEffect = groupConferenceDomain.createEffect({
  name: 'getMyConferencesDetailEffect',
  handler: (payload) => { return getMyConferencesDetailEntries() }
});

export const updateGroupConferenceEffect = groupConferenceDomain.createEffect({
  name: 'updateGroupConferenceEffect',
  handler: updateGroupConference,
});
