<template>
  <v-list max-width="320" @click.stop.prevent>
    <v-list-group
      active-class="listColor"
      v-if="!isGuest && !mini"
      :value="isActive"
      @click="handlerClickCoffBr($event)"
      :expand-icon="sortedCoffeeBreakUsers.length === 0 ? null : '$expand'"
    >
      <!-- :append-icon="sortedCoffeeBreakUsers.length === 0 ? null : '$expand'" -->
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props">
          <template v-slot:prepend>
            <v-icon size="20">
              <font-awesome-icon
                v-if="currentPage('/coffee-break')"
                :icon="['fas', 'mug']"
                class="primary--text-sidepanel iconsSize20"
              />
              <font-awesome-icon
                v-else
                :icon="['fal', 'mug']"
                class="primary--text-sidepanel iconsSize20"
              />
            </v-icon>
          </template>
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
                <!-- class="primary--text-sidepanel" -->
              <v-list-item-title
              class="primary--text-sidepanel"
                :class="`${
                  getRoute === '/coffee-break'
                    ? 'font-weight-bold'
                    : ''
                }`"
                @click.stop.prevent="
                  setCurrentContentVisile('', false, $router);
                  handleMyCoffeeBreakView();
                "
                v-bind="props"
              >
                {{ $t("status.Coffee break") }}
              </v-list-item-title>
            </template>
            <span>{{ $t("status.Coffee break") }}</span>
          </v-tooltip>
          <CustomTT
            v-if="showCustomTT"
            :text="`${$t('components.hotkeys.controlKey')} + B`"
            position="bottom"
            :extraclass="'ctrb'"
          />
        </v-list-item>
      </template>
      <UserList :persons="sortedCoffeeBreakUsers" :section="'coffee-break'" />
    </v-list-group>
    <v-tooltip right v-if="!isGuest && mini">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" class="cursorPointer">
          <v-icon size="20" @click.stop.prevent="handlerClickCoffBr()">
            <font-awesome-icon
              v-if="currentPage('/coffee-break')"
              :icon="['fas', 'mug']"
              class="primary--text-sidepanel iconsSize20"
            />
            <font-awesome-icon
              v-if="!currentPage('/coffee-break')"
              :icon="['fal', 'mug']"
              class="primary--text-sidepanel iconsSize20"
            />
            <v-badge
              @click="handleMyCoffeeBreakView()"
              v-if="getConnectedCoffeeBreak > 0"
              right
              color="error"
              :content="getConnectedCoffeeBreak"
              :model-value="getConnectedCoffeeBreak"
              bordered
              overlap
              offset-x="-1"
              offset-y="-5"
              class="coffeeBreakBadge"
            ></v-badge>
          </v-icon>
        </v-list-item>
      </template>
      <span>{{ $t("status.Coffee break") }}</span>
    </v-tooltip>
  </v-list>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store, { EventBus } from "../../store";
import UserList from "./userList.vue";
import CustomTT from "../content/customTT.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";

export default {
  props: [
    "mini",
    "persons",
    "filterPerson",
    "setCurrentContentVisile",
    "isGuest",
    "sortedCoffeeBreakUsers",
    "active",
    "setList",
    "isMobile",
  ],
  components: {
    UserList,
    CustomTT,
  },
  data() {
    const effector = {
      showCustomTT: hotkeysInfoStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      // Effector
      ...effector,
    }
  },
  mounted() {
    EventBus.$on("openCoffeeBreakEvent", this.handleMyCoffeeBreakView);
  },
  unmounted(){
    EventBus.$off("openCoffeeBreakEvent", this.handleMyCoffeeBreakView);
  },
  methods: {
    handlerClickCoffBr(event) {
      if (this.mini) {
        this.goToPage("coffee-break");
      } else {
        this.setList("coffee-break");
      }
    },
    currentPage(page) {
      const actualRoute = this.$route.path;
      if (actualRoute === page) {
        return true;
      }
      return false;
    },
    goToPage(page) {
      this.setCurrentContentVisile(page, true, this.$router);
    },
    handleMyCoffeeBreakView() {
      if (!this.isMobile) {
        this.setCurrentContentVisile("coffee-break", true, this.$router);
      }
    },
  },
  computed: {
    getConnectedCoffeeBreak() {
      let tempCoffeeBreak = 0;
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        if (person.connected && person.user && person.user.presentFrom) {
          if (
            person.user.activity === "Coffee break"
          ) {
            tempCoffeeBreak++;
          }
        }
      }
      return tempCoffeeBreak;
    },
    isActive() {
      return this.active == "coffee-break";
    },
    getRoute() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped lang="scss">
.coffeeBreakBadge {
  font-size: 10px !important;
  height: 16px !important;
  min-width: 16px !important;
  pointer-events: auto;
  position: fixed;
  text-align: center;
  text-indent: 0;
  top: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  border-radius: 50% !important;
  font-style: normal;
}
.cursorPointer {
  cursor: pointer;
}
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.textColor {
  color: #008000;
}

.v-theme--dark .listColor {
  color: white !important;
}
.v-theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
.ctrb {
  left: 15px;
}
.iconsSize20{
  width: 20px !important;
  height: 20px !important;
}
</style>

