<template>
  <v-row>
    <v-row class="mx-0">
      <v-col cols="12" class="pb-0">
        <v-checkbox
          :model-value="companyRegisterObject.isRamicroCustomer" @update:model-value="companyRegisterObject.isRamicroCustomer = $event"
          density="compact"
          class="mt-0 pt-1"
        >
          <template v-slot:label
            ><span
              v-html="$t('components.companyRegister.isRamicroCustomer')"
            ></span
          ></template>
        </v-checkbox>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
  import store from '../../store'
  import salesPartners from '../../salesPartners.json'
  export default {
    props: [],
    components: {},
    data: () => ({
      valid: true,
      state: store.state,
      setSetUpConfigRoute: store.setSetUpConfigRoute,
      companyRegisterObject: store.state.namespaceSettings.companyInfo,
      salutationSelected: store.state.namespaceSettings.companyInfo.salutation,
      phoneValid: false,
    }),
    methods: {
      setSalutation(value) {
        this.companyRegisterObject.salutationSelected = value.value
      },
      setSalesPartner(value) {
        this.companyRegisterObject.salesPartnerSelected = value.value
      },
      customFilter(item, queryText, itemText) {
        const textOne = item.text.toLowerCase()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1
      },
    },
    computed: {
      salutationsArray() {
        let arraySalutations = []
        const salutations = this.$t('components.companyRegister.salutations')
        for (let key in salutations) {
          if (key !== 'others') {
            const obj = {
              text: salutations[key],
              value: key,
            }
            arraySalutations.push(obj)
          }
        }
        arraySalutations = arraySalutations.sort(function(a, b) {
          var textA = a.text.toUpperCase()
          var textB = b.text.toUpperCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })
        if (salutations['others']) {
          const obj = {
            text: salutations['others'],
            value: 'others',
          }
          arraySalutations.push(obj)
        }
        return arraySalutations
      },
      salesPartnersArray() {
        let arraySalesPartners = []
        salesPartners.forEach((element) => {
          const obj = {
            text: element['name'],
            value: element['id'],
          }
          arraySalesPartners.push(obj)
        })
        arraySalesPartners = arraySalesPartners.sort(function(a, b) {
          var textA = a.text.toUpperCase()
          var textB = b.text.toUpperCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })
        return arraySalesPartners
      },
      cols() {
        const { lg, sm, md, xs } = this.$vuetify.display
        return lg
          ? [12, 12, 12, 12]
          : sm
          ? [12, 12, 12, 12]
          : md
          ? [12, 12, 12, 12]
          : xs
          ? [12, 12, 12, 12]
          : [12, 12, 12, 12]
      },
      cols2() {
        const { lg, sm, md, xs } = this.$vuetify.display
        return lg ? [8, 4] : sm ? [8, 4] : md ? [8, 4] : xs ? [8, 4] : [8, 4]
      },
      cols3() {
        const { lg, sm, md, xs } = this.$vuetify.display
        return lg ? [6, 6] : sm ? [6, 6] : md ? [6, 6] : xs ? [6, 6] : [6, 6]
      },
      companyNameRules() {
        return [
          (v) =>
            !!v || this.$t('components.companyRegister.companyNameRequired'),
          (v) =>
            v.length >= 3 ||
            this.$t('components.companyRegister.companyNameMinimum'),
        ]
      },
      contactNameRules() {
        return [
          (v) =>
            !!v || this.$t('components.companyRegister.contactNameRequired'),
          (v) =>
            /^[A-Za-zÑÖÄÜñöäü]{1,}[\-/\s]{0,1}[A-Za-zÑÖÄÜñöäü]{0,}$/.test(v) ||
            this.$t('components.companyRegister.contactNameInvalid'),
        ]
      },
      emailRules() {
        return [
          (v) => !!v || this.$t('components.companyRegister.emailRequired'),
          (v) =>
            /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) ||
            this.$t('components.companyRegister.emailMustValid'),
        ]
      },
      salutationRules() {
        return [
          (v) =>
            !!v || this.$t('components.companyRegister.salutationRequired'),
        ]
      },
      // salesPartnerRules () { return [ v => !!v || this.$t('components.companyRegister.salesPartnerRequired') ] },
      lawOfficeRules() {
        return [
          (v) => !!v || this.$t('components.companyRegister.lawOfficeRequired'),
        ]
      },
      streetHouseRules() {
        return [
          (v) =>
            !!v || this.$t('components.companyRegister.streetHouseRequired'),
        ]
      },
      zipCodeRules() {
        return [
          (v) => !!v || this.$t('components.companyRegister.zipCodeRequired'),
        ]
      },
      cityRules() {
        return [
          (v) => !!v || this.$t('components.companyRegister.cityRequired'),
        ]
      },
      ramicroCustomerNumberRules() {
        return [
          (v) =>
            !!v ||
            this.$t('components.companyRegister.ramicroCustomerNumberRequired'),
        ]
      },
    },
    mounted() {
      // this.$refs.form.validate();
    },
  }
</script>
<style lang="scss">
  // .pRelative{
  //   position: relative;
  // }
  // .pContact{
  //   margin-bottom: -6px !important;
  //   font-size: 16px;
  //   color: rgba(0,0,0,.6);
  //   line-height: 20px;
  //   height: 20px;
  //   max-width: 133%;
  //   transform: translateY(0px) translateX(-40px) scale(.75);
  //   position: absolute;
  // top: -10px;
  // left: 48px;
  // background: white;
  // }
  .vue-tel-input {
    // border: none !important;
    // border-bottom: 1px solid !important;
    // border-radius: 0px !important;
    // box-shadow: none !important;
    // outline: none !important;
    height: 40px;
  }
</style>
