<template>
  <!-- Incoming call modal  -->
  <div>
    <v-dialog :model-value="haveCalls" @update:model-value="haveCalls = $event" hide-overlay persistent max-width="550" class="incomingCallModalTest">
      <audio id="audioSource" ref="audioSource" :src="playingRingTone" autoplay loop></audio>
      <audio v-if="shouldPlayRinging" id="ringingSource" ref="ringingSource" :src="playingRingTone" autoplay loop></audio>
      <IncomingCallSub :key="call.uuid" v-for="call in incommingCallsArray" tabindex="0" :call="call" />
    </v-dialog>
    <v-dialog :model-value="haveConferenceCalls" @update:model-value="haveConferenceCalls = $event" hide-overlay persistent max-width="550" class="incomingCallModalTest">
      <audio id="audioSource" ref="audioSource" :src="playingRingTone" autoplay loop></audio>
      <audio v-if="shouldPlayRinging" id="ringingSource" ref="ringingSource" :src="playingRingTone" autoplay loop></audio>
      <ConferenceIncomingCallSub :key="call.uuid" v-for="call in incommingConfCallsArray" tabindex="0" :call="call" />
    </v-dialog>
  </div>

  <!-- end incoming call modal -->
</template>

<script>
import store from "../../store";
import IncomingCallSub from "./incomingCallSub.vue";
import ConferenceIncomingCallSub from "./conferenceIncomingCallSub.vue";

export default {
  components: { IncomingCallSub, ConferenceIncomingCallSub },
  data() {
    return {
      state: store.state,
      shouldPlayRinging: true,
    };
  },
  mounted() {
    const ringingDeviceId = store.state.persisted.mediaDeviceSetup.ringingOutputId;
    const audioDeviceId = store.state.persisted.mediaDeviceSetup.audioOutputId;
    // #87 - Check if we have a different ringing device and play ringing sound
    if (this.$refs.ringingSource) {
      const ringingElement = this.$refs.ringingSource;
      if (!ringingDeviceId || !audioDeviceId || ringingDeviceId === audioDeviceId) {
        this.shouldPlayRinging = false; // Don't play double sound if no different ringing Device
      } else if ('sinkId' in ringingElement && 'setSinkId' in ringingElement) {
        ringingElement.setSinkId(ringingDeviceId)
          .then(() => {
            console.debug('Success, ringing output device attached:', ringingDeviceId);
          })
          .catch((error) => {
            console.warn('Error, unable to attach ringing output device:', error);
          });
      } else {
        this.shouldPlayRinging = false;
      }
    }
    // Check if selected audioDevice is different from Windows Default device
    if (this.$refs.audioSource && audioDeviceId && audioDeviceId !== 'default') {
      const audioElement = this.$refs.audioSource;
      if ('sinkId' in audioElement && 'setSinkId' in audioElement) {
        audioElement.setSinkId(audioDeviceId).then(() => {
          console.debug('Success, setting main audio element with ID:', audioDeviceId);
        }).catch((error) => {
          console.warn('Possible Error, unable to attach main audio output device, will use browser default audio output.\n', error);
        });
      }
    }
    // Avoid double ringtone: https://gitlab.ra-micro.de/devcups/voffice/-/issues/468
    if (this.$refs.audioSource && this.shouldPlayRinging) {
      this.$refs.audioSource.muted = true;
      this.$refs.audioSource.volume = 0;
    }
  },
  beforeUnmount() {
    // Remove ringing source after call is answered or rejected
    if (this.$refs.ringingSource) this.$refs.ringingSource.volume = 0;
    if (this.$refs.audioSource) this.$refs.audioSource.volume = 0;
    this.shouldPlayRinging = false;
  },
  methods: {},
  computed: {
    volumCallTone() {
      let volum = store.state.persisted.mediaDeviceSetup.volumeCallTone;
      if (isNaN(volum)) {
        volum = 100;
      }
      volum = volum / 100;
      return volum;
    },
    haveCalls: function() {
      // this.$refs.test.removeOverlay
      //console.log('incomming calls ' , this.incommingCallsArray)
      return this.incommingCallsArray.length > 0 ;
    },
    haveConferenceCalls: function(){
      return this.incommingConfCallsArray.length > 0 ;
    },
    incommingConfCallsArray: function() {
      const confCalls = [];
      // this.$nextTick(() => this.$refs.divModal.$el.focus())
      for (let uuid in store.state.incomingCallModal.calls) {
        const call = store.state.incomingCallModal.calls[uuid];
        if (!call) continue;
          if (call.isConference){
            confCalls.push({ ...call, uuid });
          }  
      }
      // console.log('incommingConfCallsArray ', confCalls)
      return confCalls.sort((a, b) => {
        return (a.uuid || "").localeCompare(b.uuid || "");
      });
    },
    incommingCallsArray: function() {
      const calls = [];
      // this.$nextTick(() => this.$refs.divModal.$el.focus())
      for (let uuid in store.state.incomingCallModal.calls) {
        const call = store.state.incomingCallModal.calls[uuid];
        if (!call) continue;
         if (!call.isConference){
            calls.push({ ...call, uuid });
         }
      }
      // console.log('incommingCallsArray ', calls)
      return calls.sort((a, b) => {
        return (a.uuid || "").localeCompare(b.uuid || "");
      });
    },
    playingRingTone() {
      if (!this.incommingCallsArray.length) return "";
      for (let call of this.incommingCallsArray) {
        if (this.$refs.audioSource) {
          this.$refs.audioSource.volume = this.volumCallTone;
        }
        if (this.$refs.ringingSource) {
          this.$refs.ringingSource.volume = this.volumCallTone;
        }
        if (
          store
            .getNameForUuid(call.uuid)
            .toLowerCase()
            .indexOf("lazy") !== -1
        ) {
          return "/media/incommingCallLazy.mp3";
        }
      }
      return "/media/incommingCall1.mp3";
    }
  }
};
</script>
<style scoped lang="scss">
</style>
