<template>
<!-- UNSUED MODAL -->
  <div>
    <v-dialog :model-value="this.state.showAddGroupModal" @update:model-value="this.state.showAddGroupModal = $event" persistent max-width="570" @keydown.esc="closeModal">
      <v-card>
        <div>
          <v-row class="w-100 mx-0 my-0 d-flex justify-space-between align-center">
            <v-col>
              <HeaderModal
                :titleModal="$t('components.addGroupModal.addNewGroup')"
                :closeModalFunction="closeModal"
              />
            </v-col>
            <div class="pa-4">
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" icon color="close" @click="closeModal">
                    <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '20px' }" />
                  </v-btn>
                </template>
                <span>{{ $t('generics.close') }}</span>
              </v-tooltip>
            </div>
          </v-row>
          <v-row flex class="w-100 mx-0 my-0"></v-row>
          <v-form style="width: 100%; margin: 0" ref="form" :model-value="valid" @update:model-value="valid = $event" lazy-validation>
            <v-row style="width: 100%; margin: 0" class="px-5">
              <v-col class="col-12 px-2 py-2">
                <v-text-field
                  autofocus
                  :model-value="newGroupName" @update:model-value="newGroupName = $event"
                  :label="$t('components.addGroupModal.groupName')"
                  counter
                  maxlength="30"
                  required
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row :style="{width: '100%', margin: 0}" class="px-5">
              <v-col cols="5" class="py-0 pl-0">
                <v-menu
                  ref="startDateMenu"
                  :model-value="startDateMenu" @update:model-value="startDateMenu = $event"
                  :close-on-content-click="false"
                  :return-value.sync="dateStart"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ props }">
                    <v-text-field
                      :model-value="formatedStart" @update:model-value="formatedStart = $event"
                      readonly
                      v-bind="props"
                      variant="outlined"
                      density="compact"
                      hide-details
                      class="mt-3"
                      :label="$t('components.eventEntryForm.startEvent')"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    @click:date="$refs.startDateMenu.save(dateStart)"
                    :model-value="dateStart" @update:model-value="dateStart = $event"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4" class="py-0 pl-0">
                <v-menu
                  ref="startTimeMenu"
                  :model-value="startTimeMenu" @update:model-value="startTimeMenu = $event"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="timeStart"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ props }">
                    <v-text-field
                      class="mt-3"
                      :model-value="timeStart" @update:model-value="timeStart = $event"
                      :label="$t('components.eventEntryForm.startTime')"
                      readonly
                      v-bind="props"
                      variant="outlined"
                      density="compact"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="startTimeMenu"
                    :model-value="timeStart" @update:model-value="timeStart = $event"
                    full-width
                    format="24hr"
                    @click:minute="$refs.startTimeMenu.save(timeStart)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="3" class="py-0 pl-0">
                <v-text-field
                  class="mt-3"
                  variant="outlined"
                  density="compact"
                  max-width="50"
                  :model-value="durationMin" @update:model-value="durationMin = $event"
                  type="number"
                  min="0"
                  max="120"
                  step="1"
                  :label="$t('components.eventEntryForm.minutes')"
                />
              </v-col>
              <v-col cols="12" class="px-2 py-2">
                <!-- :model-value="state.user.activeGroupMembers.members" @update:model-value="state.user.activeGroupMembers.members = $event" -->
                <v-combobox
                  :model-value="membersToGroup" @update:model-value="membersToGroup = $event"
                  :items="dataCombobox.users"
                  select
                  :search-input.sync="searchUsers"
                  item-text="name"
                  :label="$t('generics.users')"
                  multiple
                  class="mx-1 label600"
                  density="compact"
                  auto-select-first
                  @change="searchUsers = ''"
                ></v-combobox>
                <!-- :rules="rules" -->
              </v-col>
              <v-col cols="12" class="px-2 py-2">
                <v-combobox
                  :model-value="guestsToGroup" @update:model-value="guestsToGroup = $event"
                  :items="dataCombobox.guests"
                  select
                  :search-input.sync="searchVisitors"
                  item-text="name"
                  :label="$t('generics.guest')"
                  multiple
                  class="mx-1 label600"
                  density="compact"
                  auto-select-first
                  @change="searchVisitors = ''"
                ></v-combobox>
              </v-col>
              <!-- Tdo add error message in red and position -->
              <v-col v-if="totalUserCombobox > 4" cols="12" class="px-2 py-2 text-right">
                <span class="text-red">{{$t('components.addGroupModal.maxUser')}}</span>
              </v-col>
              <!--
              <v-col cols="12" class="px-2 py-2">
                <v-combobox
                  :model-value="visitorsToGroup" @update:model-value="visitorsToGroup = $event"
                  :items="dataCombobox.visitors"
                  select
                  :search-input.sync="searchGuests"
                  item-text="name"
                  :label="$t('generics.myVisitors')"
                  multiple
                  class="mx-1 label600"
                  density="compact"
                  counter
                  auto-select-first
                  @change="searchGuests = ''"
                ></v-combobox>
              </v-col>-->
            </v-row>
          </v-form>
          <!-- <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
            <v-row class="px-2 btns w-100 mx-0 my-0">
              <template>
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      :disabled="!valid || (membersToGroup.length + guestsToGroup.length) > 4"
                      v-bind="props"
                      icon
                      color="white"
                      @click="handleNewGroup"
                      class="mr-3"
                    >
                      <font-awesome-icon :icon="['fal', 'check']" :style="{ fontSize: '20px' }" />
                    </v-btn>
                  </template>
                  <span>{{ $t('generics.accept') }}</span>
                </v-tooltip>
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" icon color="white" @click="closeModal">
                      <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '20px' }" />
                    </v-btn>
                  </template>
                  <span>{{ $t('generics.close') }}</span>
                </v-tooltip>
              </template>
            </v-row>
          </v-card> -->
          <!-- <v-spacer></v-spacer> -->
          <FooterModal :closeModalFunction="closeModal" class="mt-4">
            <v-btn
              :disabled="!valid || (membersToGroup.length + guestsToGroup.length) > 4"
              v-bind="props"
              icon
              color="white"
              @click="handleNewGroup"
              class="mr-3"
            >
              <font-awesome-icon :icon="['fal', 'check']" :style="{ fontSize: '20px' }" />
            </v-btn>
          </FooterModal>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "@vue/compat";
import store from "../../store";
import { v4 as uuidv4 } from "uuid";
import moment from "../../../sharedsrc/moment";
import { createTimelineEntryEffect } from "../../effector/timeline";
import FooterModal from "./modalComponents/footerModal.vue";
import HeaderModal from "./modalComponents/headerModal.vue";

// import { pongMsg } from "../../lib/wsMsg.js";
export default {
  data() {
    return {
      state: store.state,
      moment: moment,
      newGroupName: "",
      //nameExists: false,
      valid: true,
      searchUsers: null,
      searchVisitors: null,
      searchGuests: null,
      membersToGroup: [],
      visitorsToGroup: [],
      guestsToGroup: [],
      startDateMenu: false,
      startTimeMenu: false,
      dateStart: null,
      timeStart: null,
      durationMin:
        Math.floor(
          store.state.namespaceSettings.defaultMeetingDuration.value / 1000 / 60
        ) || 0,
    };
  },
  components: { FooterModal, HeaderModal },
  methods: {
    resetInputs() {
      this.dateStart = null;
      this.timeStart = null;
      this.newGroupName = "";
      this.membersToGroup = [];
      this.visitorsToGroup = [];
      this.guestsToGroup = [];
    },
    closeModal() {
      this.resetInputs();
      store.state.showAddGroupModal = false;
    },
    async createNewConferenceTimeLine() {
      // console.log(this.dateStart, this.timeStart, "rrrrrrr");
      const dateTimeStart = this.dateStart + " " + this.timeStart;
      const dateTimeEnd = this.moment(new Date(dateTimeStart)).add(
        this.durationMin,
        "minutes"
      );
      const finalMembersList = this.membersToGroup
        .concat(this.visitorsToGroup)
        .concat(this.guestsToGroup);
      // console.log(finalMembersList, "finalMembersList");
      const tmpData = {
        name: "Conference",
        details: this.newGroupName,
        users: finalMembersList
          .filter((e) => typeof e === "object")
          .map((user) => user.uuid),
        start: new Date(dateTimeStart),
        end: new Date(dateTimeEnd),
        color: "blue",
        // hours: this.showModal.hours
      };
      const newEventCreatedtimeline = await createTimelineEntryEffect(tmpData);
      return newEventCreatedtimeline;
    },
    async handleNewGroup() {
      const finalMembersList = this.membersToGroup
        .concat(this.visitorsToGroup)
        .concat(this.guestsToGroup);
      let newGroup = {};
      newGroup.id = uuidv4();
      newGroup.newGroupName = this.newGroupName.trim();
      newGroup.members = finalMembersList;
      newGroup.owner = store.state.user.name;
      if (this.dateStart && this.timeStart && this.durationMin) {
        const resp = await this.createNewConferenceTimeLine();
        if (resp) {
          newGroup.timelineConference = resp.uuid;
        }
      }
      store.state.user.userGroups.push(newGroup);
      this.closeModal();
    },
    clearObject() {
      this.newGroup.newGroupName = "";
      this.newGroup.members = [];
      this.newGroup.owner = "";
    },
  },
  computed: {
    formatedStart() {
      if (!this.dateStart) {
        return null;
      }
      return this.moment(new Date(this.dateStart)).format("DD.MM.YYYY");
    },
    totalUserCombobox() {
      return this.membersToGroup.length + this.guestsToGroup.length;
    },
    checkMaxUsers() {
      let totaLength =
        (((store.state.user || {}).activeGroupMembers || []).members || [])
          .length +
        (((store.state.user || {}).activeGroupMembers || []).guests || [])
          .length +
        (((store.state.user || {}).activeGroupMembers || []).visitors || [])
          .length;
      return totaLength > 24;
    },
    dataCombobox: function (data) {
      const result = { users: [], guests: [], visitors: [] };
      const users = this.state.group || {};
      const visitors = this.state.persisted.userVisitors || {};
      for (const key in users) {
        const data = { name: (users[key].user || {}).name || "", uuid: key };

        const isGuest = store.getUuidFromGuests(key);
        if (isGuest && data.name !== "") {
          // condition to check if the user invited was invited by me
          if ((this.state.group[key].userInviter || 0) === this.state.ownUUID) {
            result.guests.push(data);
          }
        } else if (!isGuest && data.name !== "") {
          result.users.push(data);
        }
      }

      for (const visitorId in visitors) {
        const visitor = store.state.persisted.userVisitors[visitorId];
        if (visitor) {
          visitor.uuid = visitor.visitorId;
          result.guests.push(visitor);
        }
      }

      return result;
    },
    requiredRule() {
      return [
        (v) => !!v || this.$t("components.addGroupModal.groupNameRequired"),
        (v) =>
          !this.doesNameExists ||
          this.$t("components.addGroupModal.groupAlreadyExists"),
      ];
    },
    checkMaxToSave() {
      return store.state.user.activeGroupMembers.members.length > 9;
    },
    doesNameExists() {
      let exists = false;
      // console.log(
      //   store.state.user.userGroups.indexOf(this.newGroupName) === -1
      // );
      for (let index = 0; index < store.state.user.userGroups.length; index++) {
        const element = store.state.user.userGroups[index];
        // console.log(element)
        if (
          element.newGroupName.trim().toLowerCase() ===
          this.newGroupName.trim().toLowerCase()
        ) {
          exists = true;
          break;
        }
      }
      return exists;
    },
  },
};
</script>
<style scoped lang="scss">
.w-100 {
  width: 100%;
}
.userNameCard {
  margin-bottom: 0px;
  font-size: 19px;
  color: #2a3133;
}
.dataUserCard {
  margin-bottom: 0px;
}
.borderRadius10,
.v-avatar .v-image {
  border-radius: 10px !important;
}
.w-200 {
  width: 200px;
}
.w-100-230 {
  width: calc(100% - 230px);
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
