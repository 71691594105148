// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listWrapper[data-v-a5d0ee16] {
  overflow-y: auto;
  min-height: 75vh;
  max-height: 75vh;
  height: 75vh;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/statusBySections.vue","webpack://./statusBySections.vue"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;ACAF","sourcesContent":["\n.listWrapper {\n  overflow-y: auto;\n  min-height: calc(100vh - 25vh);\n  max-height: calc(100vh - 25vh);\n  height: calc(100vh - 25vh);\n}\n",".listWrapper {\n  overflow-y: auto;\n  min-height: 75vh;\n  max-height: 75vh;\n  height: 75vh;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
