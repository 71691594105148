// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerModal[data-v-22e211dd] {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
}
.footerModal .btns[data-v-22e211dd] {
  justify-content: flex-end;
}
.leftSidePosition[data-v-22e211dd] {
  position: absolute;
  left: 0;
}
.chipContainer[data-v-22e211dd] {
  height: 41px !important;
  background: var(--v-primary-100) !important;
}
.chipContainer .rounded[data-v-22e211dd] {
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/addEditOrganigramModal.vue","webpack://./addEditOrganigramModal.vue"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,6BAAA;EACA,uBAAA;ACAF;ADCE;EACE,yBAAA;ACCJ;ADEA;EACE,kBAAA;EACA,OAAA;ACCF;ADCA;EACE,uBAAA;EACA,2CAAA;ACEF;ADAA;EACE,kBAAA;ACGF","sourcesContent":["\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n  .btns {\n    justify-content: flex-end;\n  }\n}\n.leftSidePosition{\n  position: absolute;\n  left: 0;\n}\n.chipContainer{\n  height: 41px!important;\n  background: var(--v-primary-100) !important;;\n}\n.chipContainer .rounded {\n  border-radius: 4px;\n}\n",".footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n}\n.footerModal .btns {\n  justify-content: flex-end;\n}\n\n.leftSidePosition {\n  position: absolute;\n  left: 0;\n}\n\n.chipContainer {\n  height: 41px !important;\n  background: var(--v-primary-100) !important;\n}\n\n.chipContainer .rounded {\n  border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
