// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badgetSize[data-v-6302420d] {
  height: 36px;
  width: 36px;
}
.v-menu__content[data-v-6302420d] {
  overflow: hidden;
  /* width */
  /* Track */
}
.v-menu__content[data-v-6302420d] ::-webkit-scrollbar {
  width: 8px;
}
.v-menu__content[data-v-6302420d] ::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.v-menu__content[data-v-6302420d] ::-webkit-scrollbar-thumb {
  background: #2a3133;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/userActionBar/calls.vue","webpack://./calls.vue"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,WAAA;ACAF;ADEA;EAEE,gBAAA;EACA,UAAA;EAKA,UAAA;ACJF;ADAE;EACE,UAAA;ACEJ;ADEE;EACE,mBAAA;EACA,eAAA;EACA,kBAAA;ACAJ;ADEE;EACE,mBAAA;EACA,mBAAA;ACAJ","sourcesContent":["\n.badgetSize{\n  height: 36px;\n  width: 36px;\n}\n.v-menu__content {\n  // height: 370px !important;\n  overflow: hidden;\n  /* width */\n  ::-webkit-scrollbar {\n    width: 8px;\n  }\n\n  /* Track */\n  ::-webkit-scrollbar-track {\n    border-radius: 10px;\n    margin-top: 8px;\n    margin-bottom: 8px;\n  }\n  ::-webkit-scrollbar-thumb {\n    background: #2a3133;\n    border-radius: 10px;\n  }\n}\n",".badgetSize {\n  height: 36px;\n  width: 36px;\n}\n\n.v-menu__content {\n  overflow: hidden;\n  /* width */\n  /* Track */\n}\n.v-menu__content ::-webkit-scrollbar {\n  width: 8px;\n}\n.v-menu__content ::-webkit-scrollbar-track {\n  border-radius: 10px;\n  margin-top: 8px;\n  margin-bottom: 8px;\n}\n.v-menu__content ::-webkit-scrollbar-thumb {\n  background: #2a3133;\n  border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
