// Also see src/i18n.js

import {
  de as deVuetify,
  en as enVuetify,
  es as esVuetify
} from 'vuetify/lib/locale';

import de from './de/strings.json';
import en from './en/strings.json';
import es from './es/strings.json';

export const defaultLocale = 'en';

export const messages = {
  de: { $vuetify: { ...deVuetify }, ...de },
  en: { $vuetify: { ...enVuetify }, ...en },
  es: { $vuetify: { ...esVuetify }, ...es }
};

export const availableLocales = Object.keys(messages);
