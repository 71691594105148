 <template>
  <div class="dayTimeOverlay">
    <span class="mx-2">{{$t('components.conferenceForm.startDate')}}:&nbsp;{{ formatDate }}</span>
    <span class="mx-2">{{$t('components.conferenceForm.startTime')}}:&nbsp;{{ formatTime }}</span>
  </div>
</template>
<script>
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import { getTimezone } from "../../utils/basicFunctions";
export default {
  props: ["conference"],
  data() {
    return {
      state: store.state,
    };
  },
  computed: {
    conferenceDateTime() {
      const conferenceData = this.conference;
      const conferenceISODate = (conferenceData.confStartDate + 'T' + conferenceData.confStartTime.split(':').map(e => e.padStart(2, '0')).join(':') + ':00' + (conferenceData.confTimezone || getTimezone(conferenceData.confStartDate, conferenceData.confStartTime)));
      return new Date(conferenceISODate);
    },
    formatDate(){
      return this.getFormattedDate(this.conferenceDateTime); //moment(this.conferenceDateTime).format('DD.MM.YYYY');
    },
    formatTime(){
      // Again, make sure to call the method to get the DateTime object
      const timeString = moment(this.conferenceDateTime).format('HH:mm');
      // Append the localized word for hours
      return timeString + ' ' + this.$t('components.meetingLineModal.hours');
    }
  },
  mounted: function () {},
  methods: {
    getFormattedDate(date) {
      if (!(date instanceof Date)) throw new Error('Input is not a Date object');

      const day = ('0' + date.getDate()).slice(-2); // Add leading zero for day if necessary
      const month = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero for month if necessary
      const year = date.getFullYear().toString(); // Get last 4 digits of the year

      return `${day}.${month}.${year}`;
    }
  }
};
</script>
<style scoped lang="scss">
.dayTimeOverlay {
  background-color: rgba(0, 0, 0, 0.50);
  position: absolute;
  top: 0px;
  display: grid;
  font-size: 14px;
  width: 100%;
}
.keepLineaBreak{
  white-space:pre-line
}
</style>