import Vue from '@vue/compat';
import sanitize from './lib/sanitize';
import UseOnDesktop from './components/firstSetup/useOnDesktop.vue';
import vuetify from './vuetify';
import { createApp } from 'vue';
import { i18n, $locale } from './i18n';

let inited = false;
export const initUseOnDesktop = () => {
  if (inited) return;
  inited = true;
  try {
    UseOnDesktop.vuetify = vuetify;
    const app = createApp(UseOnDesktop)
      .use(i18n)
      .use(vuetify);

    app.config.globalProperties.$delete = Vue.delete;
    app.config.globalProperties.$set = Vue.set;
    app.config.globalProperties.$vuetify = vuetify;
    app.config.globalProperties.$locale = $locale;

    Vue.prototype.$sanitize = sanitize;
    app.config.globalProperties.$sanitize = sanitize;

    app.mount('#useOnDesktop_anchor');
  } catch (err) {
    console.error('Init Vue Error:', err);
  }
};
