// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-box[data-v-a3ae902a] {
  background-color: var(--v-primary-base) !important;
}
.v-list[data-v-a3ae902a] {
  padding: 0;
}
.v-list-item__icon[data-v-a3ae902a] {
  margin-right: 8px !important;
}
.v-card[data-v-a3ae902a]:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-list-item__title[data-v-a3ae902a] {
  line-height: 2.5 !important;
}
.v-card[data-v-a3ae902a]:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-expansion-panel-header[data-v-a3ae902a] {
  padding: 0px 25px 0px 16px !important;
}
.v-expansion-panel-header__icon[data-v-a3ae902a] {
  min-width: 48px !important;
  margin-right: 8px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/drawerContentGuest.vue","webpack://./drawerContentGuest.vue"],"names":[],"mappings":"AACA;EACE,kDAAA;ACAF;ADGA;EACE,UAAA;ACAF;ADGA;EACE,4BAAA;ACAF;ADGA;EACE,gBAAA;ACAF;ADEA;EACE,2BAAA;ACCF;ADEA;EACE,gBAAA;ACCF;ADCA;EACE,qCAAA;ACEF;ADAA;EACE,0BAAA;EACA,4BAAA;ACGF","sourcesContent":["\n.select-box {\n  background-color: var(--v-primary-base) !important;\n}\n\n.v-list {\n  padding: 0;\n}\n\n.v-list-item__icon {\n  margin-right: 8px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}\n.v-list-item__title {\n  line-height: 2.5 !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}\n.v-expansion-panel-header {\n  padding: 0px 25px 0px 16px !important;\n}\n.v-expansion-panel-header__icon {\n  min-width: 48px !important;\n  margin-right: 8px !important;\n}\n",".select-box {\n  background-color: var(--v-primary-base) !important;\n}\n\n.v-list {\n  padding: 0;\n}\n\n.v-list-item__icon {\n  margin-right: 8px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}\n\n.v-list-item__title {\n  line-height: 2.5 !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}\n\n.v-expansion-panel-header {\n  padding: 0px 25px 0px 16px !important;\n}\n\n.v-expansion-panel-header__icon {\n  min-width: 48px !important;\n  margin-right: 8px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
