// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-list-item-subtitle {
  opacity: 1 !important;
  font-weight: 300 !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/userListItem.vue","webpack://./userListItem.vue"],"names":[],"mappings":"AACE;EACE,qBAAA;EACA,2BAAA;ACAJ","sourcesContent":["\n  .v-list-item-subtitle{\n    opacity: 1 !important;\n    font-weight: 300 !important;\n  }\n",".v-list-item-subtitle {\n  opacity: 1 !important;\n  font-weight: 300 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
