<template>
  <v-dialog :model-value="speechlive" @update:model-value="speechlive = $event" persistent max-width="650" @keydown.esc="closeModal">
    <v-card>
      <v-row flex class="w-100 mx-0 my-0 justify-space-between">
        <v-col :cols="isMobile ? 12 : 5" class="px-0 py-0" align="left">
          <v-card-title class="headline">
            <v-img
              class="mr-1"
              src="assets/icon.png"
              max-height="30"
              max-width="30"
              contain
            ></v-img>
            SpeechLive
          </v-card-title>
        </v-col>
        <div class="pa-4">
          <template>
            <v-tooltip location="top">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon color="black" @click="closeModal">
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    :style="{ fontSize: '25px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("generics.close") }}</span>
            </v-tooltip>
          </template>
        </div>
      </v-row>
      <v-spacer></v-spacer>
      <!-- Content -->
      <div class="d-flex flex-column px-6">
        <v-text-field :model-value="username" @update:model-value="username = $event" :label="$t('generics.username')"></v-text-field>
        <v-text-field
          :model-value="password" @update:model-value="password = $event"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          name="input-10-1"
          :label="$t('generics.password')"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-select   :model-value="selectlanguage" @update:model-value="selectlanguage = $event" :items="languagesItems" :label="$t('generics.language')"></v-select>
        <v-checkbox
          :model-value="activeSpeechlive" @update:model-value="activeSpeechlive = $event"
        :label="$t('components.userSettings.activeDesactiveSpeechlive')" 
        ></v-checkbox>
      </div>
      <!-- Footer -->
      <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
        <v-row class="px-2 btns w-100 mx-0 my-0">
          <template>
                <v-btn  color="primary" class="mr-2" @click="saveSpeechlive()">
                  {{ $t("generics.save") }}
                </v-btn>
                <v-btn  color="primary" @click="closeModal()">
                  {{ $t("generics.cancel") }}
                </v-btn>
          </template>
        </v-row>
      </v-card>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>
<script>
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
export default {
  props: ["speechlive", "showSpeechlive"],
  data: () => ({
    state: store.state,
    isMobile: isMobile(),
    showPassword: false,
    password: store.state.persisted.speechlive.password || '',
    username: store.state.persisted.speechlive.username || '',
    languagesItems: ["DE", "EN", "NL", "FR"],
    selectlanguage:store.state.user.speechlive.language || 'DE',
    activeSpeechlive: store.state.user.speechlive.activated || '',
  }),
  methods: {
    saveSpeechlive(){
      const temporalSpeechlive = {
        username: this.username,
        password: this.password,
        language: this.selectlanguage,
        activated: this.activeSpeechlive
      };
      this.setUserSpeechlive(temporalSpeechlive);
      this.closeModal();
    },
    setUserSpeechlive(data){
      return store.setUserSpeechlive(data);
    },
    closeModal() {
      this.showSpeechlive();
    },
  },
};
</script>

<style scoped lang="scss">
</style>