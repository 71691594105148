<template>
  <v-dialog
    persistent
    :model-value="showInfoModal" @update:model-value="showInfoModal = $event"
    max-width="560"
    @keydown.esc="closeInfoModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('components.inviteVisitor.title')"
        :closeModalFunction="closeInfoModal"
      />
      <v-divider></v-divider>
      <v-row flex :style="{ width: '100%' }" class="bodyContainer">
        <v-col lg="6" class="px-0 py-0 mx-auto text-center">
          <v-card-text v-if="!userSaveLimit">{{
            $t("components.inviteVisitor.youCantInviteUser")
          }}</v-card-text>
          <v-card-text v-if="userSaveLimit">{{
            $t("components.inviteVisitor.maxEmailsAllowed")
          }}</v-card-text>
        </v-col>
      </v-row>
      <FooterModal :closeModalFunction="closeInfoModal" :showClose="true" />
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  props: ["showInfoModal", "closeInfoModal", "userSaveLimit"],
  components: { HeaderModal, FooterModal },
  data: () => ({
    state: store.state,
  }),
  methods: {
    closeModalAdvice() {
      store.setCallFull(false);
    },
  },
};
</script>

<style scoped lang="scss">
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
.bodyContainer {
  margin: 40px auto;
}
</style>