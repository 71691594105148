// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paymentIcon[data-v-6c155220] {
  width: 30px;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./websrc/components/firstSetup/plans.vue","webpack://./plans.vue"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,sBAAA;ACAF","sourcesContent":["\n.paymentIcon{\n  width: 30px;\n  vertical-align: middle;;\n}\n",".paymentIcon {\n  width: 30px;\n  vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
