import { sendMessageInCallModalDomain } from './domain';

import { setSendMessageInCallModalEvent, resetSendMessageInCallModalEvent} from './events';

export const sendMessageInCallStore = sendMessageInCallModalDomain.createStore(false, { name: ' sendMessageInCallModal' });

sendMessageInCallStore.on(setSendMessageInCallModalEvent, (state, payload) => {
  return payload;
});

sendMessageInCallStore.reset(resetSendMessageInCallModalEvent);
// qualityVotingModalStore.watch((state) => { console.log('qualityVotingModalStore', state); });
