import { infoConferenceModalDomain } from './domain';

import { setInfoConferenceModalEvent, resetInfoConferenceModalEvent} from './events';

export const infoConferenceStore = infoConferenceModalDomain.createStore(false, { name: ' infoConferenceModal' });

infoConferenceStore.on(setInfoConferenceModalEvent, (state, payload) => {
  return payload;
});

infoConferenceStore.reset(resetInfoConferenceModalEvent);
// qualityVotingModalStore.watch((state) => { console.log('qualityVotingModalStore', state); });
