import { beforeEndingCallModalDomain } from './domain';

import { setBeforeEndingCallModalEvent, resetBeforeEndingCallModallEvent } from './events';

export const beforeEndingCallModalStore = beforeEndingCallModalDomain.createStore(false, { name: 'beforeEndingCallModal' });

beforeEndingCallModalStore.on(setBeforeEndingCallModalEvent, (state, payload) => {
  return payload;
});

beforeEndingCallModalStore.reset(resetBeforeEndingCallModallEvent);
