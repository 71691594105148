<template>
  <v-avatar
    :min-height="size"
    :min-width="size"
    :max-height="size"
    :max-width="size"
    class="mx-2 noBorderRadius"
    :color=" !userHasRejected ? 'primary' : '#ff00006e'"
  >
    <span class="white--text">{{getInitials}}</span>
  </v-avatar>
</template>

<script>
export default {
  props: ["size", "username", 'userHasRejected'],
  components: {},
  data: () => ({

  }),
  methods: {

  },
  computed: {
    getInitials() {
      if(!this.username) return "";
      const firstName = this.username.split(" ");
      if (firstName[0] && firstName[1]) {
        return firstName[0].charAt(0) + firstName[1].charAt(0);
      } else if (firstName[0] && !firstName[1]) {
        return firstName[0].charAt(0)
      }else if (!firstName[0] && firstName[1]) {
        return firstName[1].charAt(0);
      }else{
        return "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.noBorderRadius{
  border-radius: 0px;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
</style>

