<template>
  <v-row class="mx-auto" style="max-height: 60px" @click="handleClickInHeader($event)">
    <!-- <v-col cols="3" class="px-0 pl-2 mr-2">
      <v-text-field
        :label="$t('generics.users')"
        rounded
        variant="outlined"
        density="compact"
        prepend-inner-icon="mdi-account-search-outline"
        :model-value="inputSearch" @update:model-value="inputSearch = $event"
        class="CustomInput"
        @input="updateSearch()"
        id="searchTextField"
        ref="searchTextField"
      ></v-text-field>
    </v-col> -->
    <v-spacer />
    <v-col cols="6" class="px-0 d-flex justify-center">
      <span
        class="text-white customFontSize subtitle-1 font-weight-bold mb-0"
        v-if="typeData === 'users' || typeData === 'favorites'"
      >
        {{
          this.inputSearch.trim().length > 2 ? $t("generics.searchResult") : 
          typeData === "users"
            ? $t("generics.users")
            : typeData === "favorites"
            ? $t("components.footernav.myFavorites")
            : ""
        }}
      </span>
      <v-tooltip
        bottom
        v-if="!isMobile && amIAdmin && $route.path === '/users'"
      >
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <InviteGuest
              :isNavBar="true"
              :isSidebar="true"
              :typeInvite="'user'"
              :isIpad="isIpad"
            />
          </div>
        </template>
        <span>{{ $t("components.footernav.inviteUser") }}</span>
      </v-tooltip>
      <template v-if="$route.path === '/users'">
        <v-checkbox
          :model-value="showGreen" @update:model-value="showGreen = $event"
          @change="isShowGreen(showGreen)"
          @targetEvent.stop
          class="py-0 mx-2 my-0"
        >
          <template v-slot:label>
            <span class="mb-0 font-weight-bold labelTitle">
              {{ $t("components.organisationCarousel.green") }}
            </span>
          </template>
        </v-checkbox>
        <v-checkbox
          :model-value="showOrange" @update:model-value="showOrange = $event"
          @change="isShowOrange(showOrange)"
          @targetEvent.stop
          class="py-0 mx-2 my-0"
        >
          <template v-slot:label>
            <span class="mb-0 font-weight-bold labelTitle">
              {{ $t("components.organisationCarousel.orange") }}
            </span>
          </template>
        </v-checkbox>
      </template>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
</template>
<script>
import store, { EventBus } from "../../store";
import { isMobile, isIpad } from "../../lib/mobileUtil";
import InviteGuest from "../navbar/inviteGuest.vue";
export default {
  components: {InviteGuest},
  props: ["typeData", "searchingUser", "isShowGreen", "isShowOrange"],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      isIpad: isIpad(),
      isMobile: isMobile(),
      inputSearch: "",
      showGreen: false,
      showOrange: false
    };
  },
  methods: {
    handleClickInHeader(event){
      const targetClicked = event.target.id
      if ( targetClicked !== 'searchTextField'){
        EventBus.$emit('sendOpenCloseSidebar');
      }
    },
    updateSearch() {
      this.searchingUser(this.inputSearch);
    },
  },
  computed: {
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
  },
};
</script>
<style scoped lang="scss">
</style>