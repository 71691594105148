// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.participantsButton[data-v-2c860877] {
  position: absolute;
  right: 5px;
  top: 6px;
}
.participantsButMobile[data-v-2c860877] {
  background-color: white;
  height: 36px;
  padding-left: 12px;
}
.participantsBut[data-v-2c860877] {
  background-color: white;
  height: 36px;
}
.v-theme--dark .participantsButMobile[data-v-2c860877], .v-theme--dark .participantsBut[data-v-2c860877] {
  background-color: #313131;
  color: white;
}
.InviteFormContainer .v-card[data-v-2c860877] {
  box-shadow: none;
}
.InviteFormContainer[data-v-2c860877] {
  /*width: 100%;
  height: calc(100vh - 124px);*/
  height: calc(100vh - 124px);
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/conferenceContainer.vue","webpack://./conferenceContainer.vue"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,UAAA;EACA,QAAA;ACAF;ADEA;EACE,uBAAA;EACA,YAAA;EACA,kBAAA;ACCF;ADCA;EACE,uBAAA;EAIA,YAAA;ACDF;ADGA;EACE,yBAAA;EACA,YAAA;ACAF;ADEA;EACE,gBAAA;ACCF;ADCA;EACE;+BAAA;EAEA,2BAAA;EACA,cAAA;ACEF","sourcesContent":["\n.participantsButton {\n  position: absolute;\n  right: 5px;\n  top: 6px;\n}\n.participantsButMobile {\n  background-color: white;\n  height: 36px;\n  padding-left: 12px;\n}\n.participantsBut {\n  background-color: white;\n  // position: absolute;\n  // top: -62px;\n  // right: 2px;\n  height: 36px;\n}\n.v-theme--dark .participantsButMobile, .v-theme--dark .participantsBut {\n  background-color: #313131;\n  color: white;\n}\n.InviteFormContainer .v-card {\n  box-shadow: none;\n}\n.InviteFormContainer {\n  /*width: 100%;\n  height: calc(100vh - 124px);*/\n  height: calc(100vh - 124px);\n  overflow: auto;\n}\n",".participantsButton {\n  position: absolute;\n  right: 5px;\n  top: 6px;\n}\n\n.participantsButMobile {\n  background-color: white;\n  height: 36px;\n  padding-left: 12px;\n}\n\n.participantsBut {\n  background-color: white;\n  height: 36px;\n}\n\n.v-theme--dark .participantsButMobile, .v-theme--dark .participantsBut {\n  background-color: #313131;\n  color: white;\n}\n\n.InviteFormContainer .v-card {\n  box-shadow: none;\n}\n\n.InviteFormContainer {\n  /*width: 100%;\n  height: calc(100vh - 124px);*/\n  height: calc(100vh - 124px);\n  overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
