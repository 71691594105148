// import { timelineDomain.createEvent } from 'effector';
import { messageDomain } from './domain';

export const newMessageEvent = messageDomain.createEvent('newMessage');
export const replyMessageEvent = messageDomain.createEvent('replyMessageEvent');

export const setMessageUUIDsEvent = messageDomain.createEvent('setMessageUUIDs');
// export const setMessageEventInfo = messageDomain.createEvent('setMessageInfo');

export const dispatchFetchMyMessagesEvent = messageDomain.createEvent('dispatchFetchMyMessagesEvent');
export const dispatchFetchMessageInfoEvent = messageDomain.createEvent('dispatchFetchMessageInfoEvent');
export const dispatchFetchMessageInfosEvent = messageDomain.createEvent('dispatchFetchMessageInfosEvent');

export const setMessagesInfoEvent = messageDomain.createEvent('setMessagesInfo');
export const setMessagesInfoBatchedEvent = messageDomain.createEvent('setMessagesInfoBatched');
export const deleteMessageEntryAndUUIDEvent = messageDomain.createEvent('deleteMessageEntryAndUUID');

export const dispatchDeleteMessageEvent = messageDomain.createEvent('dispatchDeleteMessage');
export const dispatchViewedMessageEvent = messageDomain.createEvent('dispatchViewedMessageEvent');
export const dispatchRepliedMessageEvent = messageDomain.createEvent('dispatchRepliedMessageEvent');
export const dispatchReadedMessageEvent = messageDomain.createEvent('dispatchReadedMessageEvent');

export const markAsViewedSpecificMessageEvent = messageDomain.createEvent('markAsViewedSpecificMessageEvent');
export const markAsReadSpecificMessageEvent = messageDomain.createEvent('markAsReadSpecificMessageEvent');

// export const dispatchGetMessageHistoryEvent = messageDomain.createEvent('dispatchGetMessageHistoryEvent');

export const dispatchDeleteAllMessageEvent = messageDomain.createEvent('dispatchDeleteAllMessage');
export const dispatchDeleteAllSentMessageEvent = messageDomain.createEvent('dispatchDeleteAllSentMessage');

export const dispatchDeleteConversationEvent = messageDomain.createEvent('dispatchDeleteConversation');

export const showUserIsTypingEvent = messageDomain.createEvent('showUserIsTypingEvent');
