// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
.videBridgeContainer {
  .v-dialog {
    background-color: transparent !important;
  }
}
  */`, "",{"version":3,"sources":["webpack://./websrc/components/modal/paymentModals/paymentInProgress.vue"],"names":[],"mappings":"AACA;;;;;;GAAA","sourcesContent":["\n/*\n.videBridgeContainer {\n  .v-dialog {\n    background-color: transparent !important;\n  }\n}\n  */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
