<template>
<div
  :class="{
    'd-none': hideVideoContainer,
    maximizeScreen: maximizeScreen,
    maximizeScreenMobile: isMobile
  }"
>
  <div class="sidebarCallWrapper">
    <SidebarCall
      v-if="maximizeScreen && hasPrivilege(this.ownUUID)"
      :maximizeScreen="maximizeScreen"
      :isConferenceCall="isConferenceCall"
      :getAmIModerator="getAmIModerator"
    />
  </div>
  <div
    class="displayContainer"
    ref="callContainerWithProgressLinear"
  >
      <div v-if="chatOpen" class="chatWrapper">
        <JitsiChat
        :chatOpen="chatOpen"
        :chatMessages="chatMessages"
        :showTimeLine="showTimeLine"
        :toggleChat="toggleChat"
        :chatMessageNotificationSound="chatMessageNotificationSound"
        :toggleChatMessageNotificationSound="toggleChatMessageNotificationSound"
      />
      </div>
    <div :class="getIsConferenceHall ? 'gridContainerConferenceHall' : 'gridContainer'">
      <DebugCall
        v-if="state.nerd"
        :localStats="getLocalStats"
        :remoteParticipants="remoteParticipants"
        :room="room"
        :getMyDesktopRoomUserID="getMyDesktopRoomUserID"
      />
      <div style="width:100%">
        <!-- CODE FOR TEST GRID ADD AND REMOVE FAKE USER -->
        <!-- <div
          style="
            position: absolute;
            background: black;
            color: white;
            top: 60px;
            left: 60px;
            z-index: 10;
            padding: 10px;
          "
        >
          <v-btn class="mb-2" @click="addRemoveUserTest('add')"
            >add user test</v-btn
          >
          <br />
          <v-btn class="mb-2" @click="addRemoveUserTest('remove')"
            >remove user test</v-btn
          >
          <br />
          <span>micMutedByAdmin: {{ micMutedByAdmin }}</span>
          <br />
          <span>participantsMuted{{participantsMuted}}</span>
        </div> -->
        <!-- END CODE FOR TEST GRID ADD AND REMOVE FAKE USER -->
        <div
          :class="{videoContainer: isMobile}"
          class="w100"
          style="position: relative; height:100%"
        >
          <!-- ref="callContainerWithProgressLinear" -->
          <ProgressLinearBridge
            :amIModerator="getAmIModerator"
            :updateTimeForUser="updateTimeForUser"
            :setCallDuration="setCallDuration"
            :hangUpBridgeCall="hangUpBridgeCall"
            :redirectToStartView="redirectToStartView"
            :isConferenceCall="isConferenceCall"
            :rejectCall="rejectCall"
            v-if="showTimeLine"
          />
          <div
            id="videos"
            ref="callContainer"
            :class="[
              getIsConferenceHall && showTimeLine ? 'conferenceHallPodiumContainer'
              :getIsConferenceHall && !showTimeLine ? 'conferenceHallPodiumContainerNoTimeline'
              : !showTimeLine && !isMobile ? 'hideTimeLine' : 'showTimeLine',
              currentDesktop || presenterMode
                ? getGridPresenterContainerClass
                : getGridRegularContainerClass,
              isMobile ? 'hideTimeLineMobile' : null,
            ]"
          >
            <v-col
              :id="getMyRoomId"
              class="participantColumn px-0 py-0"
              :class="[
                userMaximized ? 'd-none' : '',
                isMobile ? 'myLocalMobile' : '',
                isMobile && showLocalBar ? 'myLocalMobileWithBar' : '',
                currentDesktop || presenterMode
                  ? getPresentationStyle(1)
                  : getRegularStyle(1),
              ]"
            >
              <CallTopBar
                :currentDesktop="currentDesktop"
                :presenterMode="presenterMode"
                :uuid="state.ownUUID"
                :amImoderator="getAmIModerator"
                :room="room"
                :isOnlyOneCallUser="isOnlyOneCallUser"
                v-if="!isMobile"
              />
              <DominantSpeakerIconCall
                :isDominantSpeaker="isDominantSpeaker()"
                :isLocal="true"
              />
              <template v-if="!localVideoTrack || videoDisabled">
                <div class="imgContent">
                  <div class="imgUserContainer">
                    <v-img
                      contain
                      :class="`imageCall`"
                      :max-height="!isMobile ? 105 : 80"
                      :max-width="!isMobile ? 105 : 80"
                      :src="
                        getAvatarForUuid(state.ownUUID) ||
                        'img/default_profile_picture.png'
                      "
                      lazy-src="img/default_profile_picture.png"
                    ></v-img>
                  </div>
                </div>
              </template>
              <video
                :class="{ 'd-none': !localVideoTrack || videoDisabled }"
                ref="localVideo"
                autoplay
                playsinline
                muted
                class="localVideo objectFit"
              ></video>
              <audio
                ref="localAudio"
                class="d-none"
                controls
                autoplay
                muted
              ></audio>
              <LocalAlertsMessages
                :micMute="micMute"
                :room="room"
                :badQualityUser="badQualityUser"
                :audioOutputMuted="audioOutputMuted"
                :cameraOffByModerator="cameraOffByModerator"
              />
            </v-col>
            <v-col
              :id="participant.participantId"
              :ref="`remoteParticipantContainer-${participant.participantId}`"
              class="participantColumn px-0 py-0"
              v-for="(participant, index) in remoteParticipants"
              :key="participant.participantId"
              :class="[
                userMaximized && userMaximized !== participant.participantId
                  ? 'd-none'
                  : '',
                currentDesktop || presenterMode
                  ? getPresentationStyle(index + 2, participant.participantId)
                  : getRegularStyle(index, participant),
              ]"
              @mouseleave="hideRemoteBar ? hideRemoteBar() : false"
            >
              <div class="pRelative removeVideoParent">
                <CallTopBar
                  :uuid="participant.userUUID"
                  :amImoderator="isUserModerator(participant.participantId)"
                  :currentDesktop="currentDesktop"
                  :presenterMode="presenterMode"
                  :showHand="showWaveHand(participant.participantId)"
                  :moveMouserCardRemote="moveMouserCardRemote"
                  :hideRemoteBar="hideRemoteBar"
                  :participantId="participant.participantId"
                  :getActiveUsers="getActiveUsers"
                  :room="room"
                  :isOnlyOneCallUser="isOnlyOneCallUser"
                />
                <CallMessageAlerts
                  :participantId="participant.participantId"
                  :userUUID="participant.userUUID"
                  :isUserAudioOff="getUserAudioOff"
                  :badQualityUser="badQualityUser"
                  :participantsMuted="participantsMuted"
                  :participantsCameraOff="participantsCameraOff"
                />
                <DominantSpeakerIconCall
                  :isDominantSpeaker="isDominantSpeaker(participant.participantId)"
                  :isLocal="false"
                  :participantId="participant.participantId"
                />
                <div
                  class="imgContent"
                  :class="{ 'd-none': participant.videoTrackActive }"
                >
                  <div
                    :class="{
                      imgUserContainerCalling: participant.calling,
                      imgUserContainer: !participant.calling,
                    }"
                  >
                    <v-img
                      contain
                      :class="`imageCall`"
                      max-height="105px"
                      max-width="105px"
                      :src="
                        getAvatarForUuid(participant.userUUID) ||
                        'img/default_profile_picture.png'
                      "
                      lazy-src="img/default_profile_picture.png"
                    ></v-img>
                    <div class="waveCallingBridge" v-if="participant.calling">
                      <div class="dot" v-for="index in 3" :key="index"></div>
                    </div>
                    <div
                      :ref="`callingBar_${participant.userUUID}`"
                      class="icons"
                      v-if="participant.calling"
                    >
                      <div class="buttosContainer">
                        <v-tooltip right>
                          <template v-slot:activator="{ props }">
                            <v-btn
                              class="buttonsCall"
                              icon
                              color="white"
                             
                              v-bind="props"
                              @click.prevent.stop="
                                hangUpCalling(participant.userUUID)
                              "
                            >
                              <font-awesome-icon
                                :icon="['fal', 'phone']"
                                class="faPhoneRotate"
                                :style="{ fontSize: '20px' }"
                              />
                            </v-btn>
                          </template>
                          <span>{{ $t("generics.hangUp") }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="wh100" :class="{ 'd-none': !participant.videoTrackActive }">
                  <video
                    class="remoteVideo"
                    :class="{
                      'd-none': !participant.videoTrackActive,
                      objectFit: addObjectFit(index),
                    }"
                    :ref="participant.videoTrack"
                    autoplay
                    playsinline
                  />
                  <audio
                    :ref="participant.audioTrack"
                    class="d-none remoteAudio"
                    controls
                    autoplay
                  />
                </div>
                <RemoteButtonsBar
                  v-show="showBarOnOverRemote === participant.participantId"
                  :parentReferences="$refs"
                  :participant="participant"
                  :isUserModerator="isUserModerator(participant.participantId)"
                  :room="room"
                  v-if="participant.participantId"
                  :participantsMuted="participantsMuted"
                  :getMicrophoneLevel="getMicrophoneLevel"
                  :isFullScreen="isFullScreen"
                  :exitFullScreen="exitFullScreen"
                  :enterFullScreen="enterFullScreen"
                  :setUserMaximized="setUserMaximized"
                  :isLocalFullScreen="localFullScreen"
                  :hasPrivilege="hasPrivilege(participant.userUUID)"
                  :getAmIModerator="getAmIModerator"
                  :presenterModeInitiatedBy="presenterModeInitiatedBy"
                  :toggleGiveWord="toggleGiveWord"
                  :wavingHand="wavingHand"
                  :currentDesktop="currentDesktop"
                  :setMaximizeScreen="setMaximizeScreen"
                  :showBarOnOverRemote="
                    showBarOnOverRemote === participant.participantId
                  "
                  :fullScreenActive="fullScreenActive"
                  :isOnlyOneCallUser="isOnlyOneCallUser"
                  :isConferenceCall="isConferenceCall"
                  :changeActualFullScreenTypeRemote="
                    changeActualFullScreenTypeRemote
                  "
                  :actualFullScreenTypeRemote="actualFullScreenTypeRemote"
                  :changeMenuIsOpen="changeMenuIsOpen"
                  :wordGivenTo="wordGivenTo"
                  :speakerView="speakerView"
                  :changeSpeakerView="changeSpeakerView"
                  :moveMouserCardRemote="moveMouserCardRemote"
                  :hideRemoteBar="hideRemoteBar"
                  :getActiveUsers="getActiveUsers"
                  :participantsCameraOff="participantsCameraOff"
                  :toggleCameraOff="toggleCameraOff"
                  :getIsParticipantIsPodium="getIsParticipantIsPodium"
                  :getIsConferenceHall="getIsConferenceHall"
                  :getCallUUID="getCallUUID"
                  :micMutedByAdmin="micMutedByAdmin"
                  :toggleRemoteMic="toggleRemoteMic"
                />
              </div>
            </v-col>
            <!-- SHARE SCREEN -->
            <v-col
              class="participantColumn px-0 py-0"
              :class="[
                userMaximized ? 'd-none' : '',
                currentDesktop ? getMiddleClass : '',
                classChooseShareScreen,
              ]"
              v-if="currentDesktop"
              ref="shareScreenContainer"
            >
              <div @mousemove="moveMouseShareScreen()">
                <CallTopBar
                  :uuid="getShareScreenTopBar"
                  :amImoderator="getDesktopIsModerator"
                  :currentDesktop="currentDesktop"
                  :presenterMode="presenterMode"
                  :isOnlyOneCallUser="isOnlyOneCallUser"
                  :room="room"
                />
                <div
                  class="iconsShareScreen"
                  @mouseleave="hideShareScreenBar()"
                  @mousemove="moveMouseShareScreen()"
                  v-show="showShareScreenBar"
                >
                  <div class="buttosContainer">
                    <v-tooltip location="top">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          icon
                          color="white"
                          v-bind="props"
                          @click.prevent.stop="toggleFullSharescreen()"
                        >
                          <font-awesome-icon
                            :icon="['fal', 'expand-wide']"
                            :style="{ fontSize: '20px' }"
                            v-if="!localFullScreen"
                          />
                          <font-awesome-icon
                            v-else
                            :icon="['fal', 'compress-wide']"
                            :style="{ fontSize: '20px' }"
                          />
                        </v-btn>
                      </template>
                      <span
                        >{{
                          !localFullScreen
                            ? $t("generics.fullscreen")
                            : $t("generics.normalScreen")
                        }}
                      </span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
              <div
                v-if="classChooseShareScreen"
                class="textChooseShareScreen text-center text-white"
              >
                {{ $t("components.callsContent.screenSharePreparation") }}
              </div>
              <video
                ref="localVideoShareScreen"
                autoplay
                playsinline
                muted
                class="localVideoShareScreen"
              ></video>
              <audio
                ref="remoteAudioShareScreen"
                class="d-none remoteAudio"
                controls
                autoplay
              />
            </v-col>
            <!-- END SHARE SCREEN -->
          </div>
        </div>
      <div v-if="getIsConferenceHall" class="listenerWrapper">
      <ListenerView :chatOpen="chatOpen" :getAmIModerator="getAmIModerator" :isListenerView="false" />
    </div>
      </div>
    </div>
    <div class="localButtonsWrapper localButtonsBar">
    <LocalButtonsBar
          :downloadSupportLog="downloadSupportLog"
          :rejectCall="rejectCall"
          :rejectingCall="rejectingCall"
          :toggleVideo="toggleVideo"
          :videoDisabled="videoDisabled"
          :toggleMic="toggleMic"
          :micMute="micMute"
          :getMicrophoneLevel="getMicrophoneLevel"
          :toggleMuteAudio="toggleMuteAudio"
          :audioOutputMuted="audioOutputMuted"
          :toggleLocalFullScreen="toggleLocalFullScreen"
          :localFullScreen="localFullScreen"
          :getAmIModerator="getAmIModerator"
          :toggleAllRemoteMic="toggleAllRemoteMic"
          :toggleShareScreen="toggleShareScreen"
          :currentDesktop="currentDesktop"
          :getMyDesktopRoomUserID="getMyDesktopRoomUserID"
          :togglePresentation="togglePresentation"
          :presenterModeInitiatedBy="presenterModeInitiatedBy"
          :room="room"
          :toggleGiveWord="toggleGiveWord"
          :toggleWaveHand="toggleWaveHand"
          :wavingHand="wavingHand"
          :getTotalRemoteParticipants="getTotalRemoteParticipants"
          :implicitPresenterMode="implicitPresenterMode"
          :isConferenceCall="isConferenceCall"
          :hangUpBridgeCall="hangUpBridgeCall"
          :getCallUUID="getCallUUID"
          :getconfId="getconfId"
          :presenterMode="presenterMode"
          :wordGivenTo="wordGivenTo"
          :fullScreenActive="fullScreenActive"
          :changeMenuIsOpen="changeMenuIsOpen"
          :isUserInCall="isUserInCall"
          :kickUser="kickUser"
          :getIsMobile="getIsMobile"
          :toggleRotateCamera="toggleRotateCamera"
          :changeSpeakerView="changeSpeakerView"
          :speakerView="speakerView"
          :getActiveUsers="getActiveUsers"
          :myDocuments="myDocuments"
          :videoDeviceId="videoDeviceId"
          :toggleChat="toggleChat"
          :getTotalReceivedChatMessages="getTotalReceivedChatMessages"
          :chatOpen="chatOpen"
          :stopShareScreen="stopShareScreen"
          :cameraOffByModerator="cameraOffByModerator"
          :toggleAllCameraOff="toggleAllCameraOff"
          :allRemoteCameraOff="allRemoteCameraOff"
          :allMods="allMods"
          :getIsParticipantIsPodium="getIsParticipantIsPodium"
          :getIsConferenceHall="getIsConferenceHall"
          :chatMessageNotificationSound="chatMessageNotificationSound"
          :micMutedByAdmin="micMutedByAdmin"
          :allRemoteMicMuted="allRemoteMicMuted"
          :downloadChat="downloadChat"
        />
    </div>
  </div>
  <FinishCallModal
    v-if="showFinishCallModal"
    :showFinishCallModal="showFinishCallModal"
    :closeModal="showCloseFinishCallModal"
    :rejectCall="rejectCall"
  />
  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import JitsiMeetJS from "../../../jitsi/JitsiMeetJS";
import { LOCAL_TRACK_STOPPED, TRACK_AUDIO_LEVEL_CHANGED, TRACK_MUTE_CHANGED } from "../../../jitsi/JitsiTrackEvents";
import Deferred from "../../../jitsi/modules/util/Deferred";
import $ from "jquery"; // this is for jitsi internals only
import debounce from "lodash.debounce";
import { webLicensedBaseFeatures } from "../../../../sharedsrc/licensedFeatures";
import store, { EventBus, syncedUserState } from "../../../store";
import { aDelay, parallel } from "../../../lib/asyncUtil";
import { jvbOptions } from "../../../lib/rtcSettings";
import { wsCall } from "../../../lib/wsConnect";
import { isMobile } from "../../../lib/mobileUtil";
import { bridgeCallScreenShareState, bridgeCallGetAllChatMessages, bridgeCallStartStreaming } from "../../../lib/wsMsg";
import {
  isVisitor,
  isWaitingRoomUser,
  hasPrivilege,
  isOnlyOneCallUser,
} from "../../../utils/privileges";
import CallTopBar from "./callTopBar.vue";
import CallMessageAlerts from "./callMessagesAlert.vue";
import LocalAlertsMessages from "./localAlertsMessages.vue";
import LocalButtonsBar from "./localButtonsBar.vue";
import RemoteButtonsBar from "./remoteButtonsBar.vue";
import ProgressLinearBridge from "../../progressLinearBridge/progressLinearBridge.vue";
import { isGuestOrVisitor } from "../../../utils/routerAcl.js";
import { amInABridgeCall, isConferenceCall, isConferenceHallCall } from "../../../utils/calls";
import { bridgeInfoStore } from "../../../effector/users/bridgeInfo";
import { callChatStore, setCallChatEvent } from "../../../effector/callChat";
import {
  myConferenceDetails,
  updateGroupConferenceEvent,
} from "../../../effector/groupConferences";
import {
  myCompanyDocumentsDetails,
  updateCompanyDocumentEvent,
} from "../../../effector/companyDocuments";
import DebugCall from "./debugCall/debugCall.vue";
import SidebarCall from "./sidebarCall/sidebarCall.vue";
import DominantSpeakerIconCall from "./dominanSpeakerIconCall.vue";
import JitsiChat from "./jitsiChat.vue";
import ListenerView from "./listenerView.vue";
import { myConferenceHallDetails } from "../../../effector/conferenceHalls";
import FinishCallModal from  "../../modal/finishCallModal.vue";

export default {
  components: {
    CallTopBar,
    CallMessageAlerts,
    LocalButtonsBar,
    RemoteButtonsBar,
    ProgressLinearBridge,
    LocalAlertsMessages,
    DebugCall,
    SidebarCall,
    DominantSpeakerIconCall,
    JitsiChat,
    ListenerView,
    FinishCallModal
  },
  data() {
    const effector = {
      bridgeInfoStore: bridgeInfoStore,
      myConferenceDetails: myConferenceDetails,
      myDocuments: myCompanyDocumentsDetails,
      chatMessages: callChatStore,
      myConferenceHallDetails: myConferenceHallDetails
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      temporalIndex: 0,
      state: store.state,
      callUUID: undefined,
      ownUUID: store.state.ownUUID,
      acceptReadyPromise: undefined,
      acceptReadyResolve: undefined,
      acceptReadyTimeout: undefined,
      disableDevicesTimeout: undefined,
      localAudioPromise: null,
      localAudioTrack: null,
      localTracksQueue: Promise.resolve(),
      localVideoPromise: null,
      localVideoTrack: null,
      joinedCall: false,
      rejectedCall: false,
      rejectingCall: false,
      connection: null,
      room: null,
      remoteParticipants: [],
      videoDisabled: false,
      micMute: false,
      participantsMuted: [],
      microphoneLevel: 0,
      toRemoveFromCalling: [],
      removeCheckerInterval: null,
      currentCallingState: [],
      toBeRemovedFromCallState: {},
      audioOutputMuted: false,
      participantsAudioMuted: {},
      localMuted: false,
      localFullScreen: false,
      currentCallTime: undefined,
      bridgeCallEnd: false,
      setCurrentContentVisile: store.setCurrentContentVisile,
      dominantSpeaker: false,
      userMaximized: null,
      isNormalMode: true,
      dropModOnNewMod: false,
      showBarOnOverRemote: false,
      shareScreenMode: 0,
      wordGivenTo: null,
      desktopRoom: null,
      desktopConnection: null,
      implicitPresenterMode: false,
      presenterMode: false,
      localDesktopDisposedPromise: null,
      localDesktopMediaPromise: null,
      localDesktopPromise: null,
      localDesktopTrack: null,
      currentDesktop: null,
      presenterModeInitiatedBy: null,
      recorderParticipantId: null,
      wavingHand: [],
      shareFullScreen: false,
      maximizeScreen: null,
      showLocalBar: false,
      timeoutLocalBar: undefined,
      showShareScreenBar: false,
      timeoutShareScreenBar: undefined,
      fullScreenActive: false,
      actualFullScreenTypeRemote: "maximizeScreen",
      menuIsOpen: false,
      badQualityUser: [],
      isMobile: isMobile(),
      mobileCamera: "user",
      showHandFor: {},
      spaceShortcutCallAcceptTimeout: null,
      speakerView: false,
      localStats: undefined,
      remoteSpeakerIndex: false,
      e2e_active: false,
      chatOpen: false,
      classChooseShareScreen: null,
      allRemoteCameraOff: false,
      participantsCameraOff: {},
      cameraOffByModerator: false,
      allMods: false,
      chatMessageNotificationSound: true,
      micMutedByAdmin: [],
      showFinishCallModal: false,
      // Effector
      ...effector,
    };
  },
  watch: {
    "bridgeInfoStore.calls": {
      immediate: true,
      handler: debounce(function (newVal, oldVal) {
        const callUUID = this.getCallUUID;
        const acceptReady = !!(callUUID && newVal && newVal[callUUID]);
        if (acceptReady && this.acceptReadyResolve) this.acceptReadyResolve();
      }, 100),
    },
    "state.user.inBridgeCall": {
      handler: debounce(function (newVal, oldVal) {
        // Handler for recovering inBridgeCall and bridgeCallInfo after user reconnects (in case connection to vOffice backend drops)
        if (!newVal && oldVal && (this.connection && this.room && this.joinedCall && !this.rejectingCall) && this.getCallUUID) {
          if (Object.keys(this.state.remoteBridgeStreams)[0] !== this.getCallUUID) {
            console.warn("jitsi: call uuid mismatch", Object.keys(this.state.remoteBridgeStreams)[0], this.getCallUUID);
            return;
          }
          this.state.user.inBridgeCall = this.getCallUUID;
          this.state.user.bridgeCallInfo =
            this.state.remoteBridgeStreams[this.getCallUUID] || {};
          if (
            this.state.user.activity !== "inCall" &&
            this.state.user.activity !== "No status"
          ) {
            this.state.user.originalActivity = this.state.user.activity;
            this.state.user.activity = "inCall";
          }
        }
      }, 300),
    },
    "state.user.name": {
      handler: debounce(function (newVal, oldVal) {
        if (oldVal !== newVal && this.room && !this.rejectingCall) {
          const userName =
            this.getTitelForUuid(this.ownUUID) == "" ||
            this.getTitelForUuid(this.ownUUID) == "unknown"
              ? this.getVisitorFunctionForUuid(this.ownUUID) !== ""
                ? this.getNameForUuid(this.ownUUID) +
                  " " +
                  this.getVisitorFunctionForUuid(this.ownUUID)
                : this.getNameForUuid(this.ownUUID)
              : this.getTitelForUuid(this.ownUUID) +
                " " +
                this.getNameForUuid(this.ownUUID);
          this.room.setDisplayName(
            this.ownUUID == "recorder"
              ? "recorder"
              : "regular_" +
                  this.ownUUID +
                  `_${JSON.stringify([
                    this.getAvatarForUuid(this.ownUUID),
                    userName,
                  ])}`
          );
        }
      }, 300),
    },
    fullScreenActive: {
      handler: function (value) {
        this.localFullScreen = value;
      },
    },
    videoDeviceId: {
      handler: debounce(async function (videoDeviceId) {
        if (
          this.connection &&
          this.room &&
          this.joinedCall &&
          !this.rejectingCall
        ) {
          if (this.localVideoPromise) {
            await this.localVideoPromise.catch(
              console.warn.bind(console, "local video promise rejection")
            );
          }
          const room = this.room;
          const toRemove = room && room.getLocalTracks("video");
          await parallel(1, toRemove || [], async (track) => {
            if (room === track.conference && !track.disposed) {
              const trackString = track.toString();
              if (track.containers?.length) track.detach();
              await track.mute().catch(console.warn.bind(console, 'mute room video track', trackString));
              if (track === this.localVideoTrack) this.localVideoTrack = null;
              await track.dispose().catch(console.warn.bind(console, 'dispose room video track', trackString));
            }
          });
          if (this.localVideoTrack) {
            const track = this.localVideoTrack;
            track.detach();
            this.localVideoTrack = null;
            await track.dispose()
              .catch(console.warn.bind(console, 'dispose local video track', track.toString()));
          }
          console.log("switch selected cam:", videoDeviceId);
          if (!this.videoDisabled) {
            await this.createLocalTracks(
              {
                devices: ["video"],
                cameraDeviceId: videoDeviceId,
              },
              this.onLocalTracks.bind(this)
            );
          }
        }
      }, 500),
    },
    audioDeviceId: {
      handler: debounce(async function (audioDeviceId) {
        if (
          this.connection &&
          this.room &&
          this.joinedCall &&
          !this.rejectingCall
        ) {
          if (this.localAudioPromise) {
            await this.localAudioPromise.catch(
              console.warn.bind(console, "local audio promise rejection")
            );
          }
          const room = this.room;
          const toRemove = room && room.getLocalTracks("audio");
          await parallel(1, toRemove || [], async (track) => {
            if (room === track.conference && !track.disposed) {
              const trackString = track.toString();
              if (track.containers?.length) track.detach();
              await track.mute().catch(console.warn.bind(console, 'mute room audio track', trackString));
              if (track === this.localAudioTrack) this.localAudioTrack = null;
              await track.dispose().catch(console.warn.bind(console, 'dispose room audio track', trackString));
            }
          });
          if (this.localAudioTrack) {
            const track = this.localAudioTrack;
            track.detach();
            this.localAudioTrack = null;
            await track.dispose()
              .catch(console.warn.bind(console, 'dispose local audio track', track.toString()));
          }
          console.log("switch selected mic:", audioDeviceId);
          await this.createLocalTracks(
            {
              devices: ["audio"],
              micDeviceId: audioDeviceId,
            },
            this.onLocalTracks.bind(this)
          );
        }
      }, 500),
    },
    getCallUUID: {
      immediate: true,
      handler: function (callUUID) {
        if (callUUID) {
          this.state.user.originalActivity = this.state.user.activity;
          if (this.state.user.activity !== "No status") {
            this.state.user.activity = "inCall";
          }
          this.isNormalMode =
            this.state.remoteBridgeStreams[callUUID].isNormalMode;
        } else if (this.state.user.activity === "inCall" || this.state.user.activity === "inRoom") {
          store.setActivityUser(this.state.user.originalActivity);
        }
      },
    },
    amICalling: {
      handler: function (amICalling) {
        for (const uuid of this.currentCallingState) {
          if (!amICalling.includes(uuid)) {
            this.toBeRemovedFromCallState[uuid] = Date.now();
          }
        }
        var newcallstate = [];
        for (const uuid of amICalling) {
          newcallstate.push(uuid);
          if (!this.currentCallingState.includes(uuid)) {
            if (this.toBeRemovedFromCallState[uuid]) {
              delete this.toBeRemovedFromCallState[uuid];
              const userIndex = this.remoteParticipants.findIndex(
                (e) => e.userUUID === uuid && e.calling
              );
              if (userIndex !== -1) {
                this.remoteParticipants.splice(userIndex, 1);
              }
            } else {
              // add to this.remoteParticipants
              const userIndex = this.remoteParticipants.findIndex(
                (e) => e.userUUID === uuid
              );
              if (userIndex == -1) {
                if(this.getIsConferenceHall){
                  if(this.getIsParticipantIsPodium(uuid)){
                    let obj = {
                    userUUID: uuid,
                    participantId: null,
                    audioTrack: null,
                    videoTrack: null,
                    videoTrackActive: false,
                    videoTrackObj: null,
                    audioTrackObj: null,
                    calling: true,
                    };
                    this.remoteParticipants.push(obj);
                  }
                }else{
                  let obj = {
                    userUUID: uuid,
                    participantId: null,
                    audioTrack: null,
                    videoTrack: null,
                    videoTrackActive: false,
                    videoTrackObj: null,
                    audioTrackObj: null,
                    calling: true,
                  };
                  this.remoteParticipants.push(obj);
                }
              }
            }
          }
        }
        this.currentCallingState = newcallstate;
      },
      deep: true,
    },
    currentCallTime: {
      handler: function (currentTime) {
        if (this.room) {
          let users = this.remoteParticipants.map((users) => {
            return users.userUUID;
          });
          users.push(this.ownUUID);
          this.room.sendTextMessage(
            JSON.stringify({
              cmd: "cmdCurrentTime",
              value: currentTime,
              users: users,
            })
          );
        }
      },
    },
    bridgeCallEnd: {
      handler: function (isEnd) {
        if (isEnd) {
          this.rejectFullCall();
        }
      },
    },
    menuIsOpen: {
      immediate: true,
      handler: function (isOpen) {
        if (isOpen) {
          this.showLocalBar = true;
          if (this.timeoutLocalBar) {
            clearTimeout(this.timeoutLocalBar);
            this.timeoutLocalBar = undefined;
          }
          if (this.timeoutRemoteBar) {
            clearTimeout(this.timeoutRemoteBar);
            this.timeoutRemoteBar = undefined;
          }
          if (this.timeoutShareScreenBar) {
            clearTimeout(this.timeoutShareScreenBar);
            this.timeoutShareScreenBar = undefined;
          }
          this.getIfAllParticipantsAreMuted()
        }
      },
    },
    currentDesktop: {
      immediate: true,
      handler: function (id) {
        if (id && !this.$refs.localVideoShareScreen) {
          this.classChooseShareScreen = "chooseShareScreenBg";
        }
      },
    },
    getTotalRemoteParticipants: {
      immediate: true,
      handler: function (totalRemoteParticipants, oldTotalRemoteParticipants) {
        if (totalRemoteParticipants) {
          if(oldTotalRemoteParticipants > totalRemoteParticipants && this.allRemoteCameraOff){
            this.checkAllRemoteCameraOff();
          }
        }
      },
    },
    micMutedByAdmin: {
      immediate: true,
      handler: function (participants) {
        if (participants) {
          this.getIfAllParticipantsAreMuted()
        }
      },
    },
  },
  created() {
    const initOptions = {
      disableAudioLevels: false,
      disableSimulcast: false,
      disableThirdPartyRequests: true,
    };
    if (webLicensedBaseFeatures.isDev) {
      JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.WARN);
    } else {
      JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
    }
    JitsiMeetJS.init(initOptions);
    this.acceptReadyPromise = new Promise((resolve, reject) => {
      this.acceptReadyResolve = () => {
        if (this.acceptReadyTimeout) {
          clearTimeout(this.acceptReadyTimeout);
          this.acceptReadyTimeout = undefined;
        }
        this.acceptReadyResolve = undefined;
        return resolve();
      };
      this.acceptReadyTimeout = setTimeout(() => {
        this.acceptReadyTimeout = undefined;
        if (this.acceptReadyResolve) this.acceptReadyResolve();
      }, 1000); // maximum delay of 1 second
    });
    if (!this.callInitiator || this.amIInitiator) {
      this.$nextTick(
        () => this.acceptReadyResolve && this.acceptReadyResolve()
      );
    }
  },
  mounted() {
    document.addEventListener("wheel", this.onMouseWheel);
    document.addEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
    window.$ = window.jQuery = $;
    window.addEventListener("beforeunload", this.onBeforeUnload);
    // DONT REMOVE TEST CODE
    // for (let index = 0; index < 5; index++) {
    //   const data = {
    //     participantId: "2f6bfb2a88447a05715bbf5b46665526",
    //     userUUID: "2f6bfb2a88447a05715bbf5b46665526",
    //     videoTrack: null,
    //     audioTrack: null,
    //     calling: false,
    //   };
    //   this.remoteParticipants.push(data);
    // }
    const delay = syncedUserState().catch(() => {});
    this.acceptReadyPromise.then(async () => {
      await delay;
      const ready = !this.rejectedCall && !this.rejectingCall && amInABridgeCall() && this.getCallUUID;
      console.log("ready to init connection:", ready);
      if (ready) {
        this.initConnection();
        const messages = await bridgeCallGetAllChatMessages();
        setCallChatEvent(messages);
        try {
          const [remoteStreamsKey, remoteStream] = Object.entries(store.state.remoteBridgeStreams)[0];
          const result = store.setRemoteBridgeStream(remoteStreamsKey, { ...remoteStream, roomId: remoteStreamsKey, roomGuid: remoteStreamsKey });
          if (!result) throw new Error('setRemoteBridgeStream failed');
        } catch (err) {
          console.error('jitsiCall acceptReadyPromise', err);
          this.rejectCall();
        }
      } else if (!this.state.user.inBridgeCallListener || this.state.user.inBridgeCallListener !== this.getCallUUID) {
        this.rejectCall();
      }
    });
    if (!this.isMobile) {
      this.setMaximizeScreen(true);
    }
    document.addEventListener("fullscreenchange", this.onFullScreenChange);
  },
  beforeUnmount() {
    if (!this.rejectedCall) {
      return this.rejectCall();
    }
  },
  unmounted() {
    window.removeEventListener("beforeunload", this.onBeforeUnload);
    if (this.acceptReadyTimeout) {
      clearTimeout(this.acceptReadyTimeout);
      this.acceptReadyTimeout = undefined;
    }
    if (this.disableDevicesTimeout) {
      clearTimeout(this.disableDevicesTimeout);
      this.disableDevicesTimeout = undefined;
    }
    if (this.removeCheckerInterval) {
      clearInterval(this.removeCheckerInterval);
      this.removeCheckerInterval = null;
    }
    document.removeEventListener("wheel", this.onMouseWheel);
    document.removeEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
    document.removeEventListener("fullscreenchange", this.onFullScreenChange);
    this.redirectToStartView();
  },
  methods: {
    // DONT REMOVE CODE FOR TEST
    // addRemoveUserTest(data) {
    //   if (data == "add") {
    //     // const temporalArray = ["31553789-61ab-4a4a-8d83-c61744617f01", "31553789-61ab-4a4a-8d83-c61744617f01", "31553789-61ab-4a4a-8d83-c61744617f01" ,"31553789-61ab-4a4a-8d83-c61744617f01" ,"31553789-61ab-4a4a-8d83-c61744617f01" ,"31553789-61ab-4a4a-8d83-c61744617f01" ,"31553789-61ab-4a4a-8d83-c61744617f01" , "31553789-61ab-4a4a-8d83-c61744617f01", "31553789-61ab-4a4a-8d83-c61744617f01", "31553789-61ab-4a4a-8d83-c61744617f01" ,"31553789-61ab-4a4a-8d83-c61744617f01" ,"31553789-61ab-4a4a-8d83-c61744617f01" ,"31553789-61ab-4a4a-8d83-c61744617f01" ,"31553789-61ab-4a4a-8d83-c61744617f01" , "31553789-61ab-4a4a-8d83-c61744617f01", "31553789-61ab-4a4a-8d83-c61744617f01", "31553789-61ab-4a4a-8d83-c61744617f01" ,"31553789-61ab-4a4a-8d83-c61744617f01" ,"31553789-61ab-4a4a-8d83-c61744617f01" ,"31553789-61ab-4a4a-8d83-c61744617f01" ,"31553789-61ab-4a4a-8d83-c61744617f01" ,]
    //     const temporalArray = ["0a11c7b3d8400ef39c6f80fd8bc25054", "0cf4fc96263bba042c6b1d71004c1986",
    //     "0db52e4ae8ca43978110703c59e2f2c9", "0e803fe6970422ccfb02c2ae0e4ecc4d", "0f6e0c622f4571491b83d7a08a0a1565" , "01fb64a2225d6549d18d2117b2de6310",
    //     "1acd31f7243f4c7b2cbf2995b9dd33a5", "1c9ad547944ae5d363ac78d2bb66b8ea", "1d704ac76d81b33649c94c92bfde0326", "1e4d7bb8f5752aea1791be16c741828e", "1ea582abd15da61a2117fd572606b8de",
    //     "1f0005abb23ccf2a026be9d64ada2d40", "1fb85bddc380054fce69806c3a44bbbc"
    //     ]
    //     const data = {
    //       participantId: temporalArray[this.temporalIndex],
    //       userUUID: temporalArray[this.temporalIndex],
    //       videoTrack: null,
    //       audioTrack: null,
    //       calling: false,
    //     };
    //     this.remoteParticipants.push(data);
    //     this.temporalIndex += 1;
    //   } else {
    //     this.remoteParticipants.pop();
    //     this.temporalIndex -= 1;
    //   }
    // },
    showCloseFinishCallModal(val){
      this.showFinishCallModal = val;
    },
    cleanParticipantMutedLogic(participantId){
      if (!participantId) return;
      const participantMutedIndex = this.participantsMuted.indexOf(participantId);
      const participantMutedByAdminIndex = this.micMutedByAdmin.indexOf(participantId);
      if(participantMutedIndex !== -1) {
        // clean user for array
        this.participantsMuted.splice(participantMutedIndex, 1)
      }
      if(participantMutedByAdminIndex !== -1) {
        // clean user for muted by admin array
        this.micMutedByAdmin.splice(participantMutedByAdminIndex, 1);
        this.room.sendTextMessage(
          JSON.stringify({
            cmd: "cmdMicMutedByAdmin",
            value: this.micMutedByAdmin,
          })
        )
      }
      // check if all participants are muted
      this.getIfAllParticipantsAreMuted()
    },
    getIfAllParticipantsAreMuted(){
      if (!this.room) {
        this.allRemoteMicMuted = false;
        return;
      }
      if(this.allMods){
        this.allRemoteMicMuted = false;
        return;
      }
      const participants = this.room.getParticipants();
      if (participants.length > 0) {
        for (const participant of participants) {
          if (!participant.isModerator() && this.micMutedByAdmin.indexOf(participant.getId()) == -1) {
            this.allRemoteMicMuted = false;
            return;
          }
        }
        this.allRemoteMicMuted = true;
        return;
      }
      this.allRemoteMicMuted = false;
    },
    toggleRemoteMic(participant) {
        if(!participant) return
        const micMutedByAdminIndex = this.micMutedByAdmin.indexOf(participant.participantId);
        if (micMutedByAdminIndex == -1) {
          this.micMutedByAdmin.push(participant.participantId)
          // if (this.$refs[participant.audioTrack]) {
          //   this.$refs[participant.audioTrack][0].muted = true;
          // }
        } else {
          this.micMutedByAdmin.splice(micMutedByAdminIndex, 1);
          // if (this.$refs[participant.audioTrack]) {
          //   this.$refs[participant.audioTrack][0].muted = false;
          // }
        }
        this.room.sendTextMessage(
          JSON.stringify({
            cmd: "cmdMicMutedByAdmin",
            value: this.micMutedByAdmin,
          })
        );
      // if (
      //   this.participantsMuted[this.participant.participantId] &&
      //   this.participantsMuted[this.participant.participantId].muted == true
      // ) {
      //   delete this.participantsMuted[this.participant.participantId];
      //   this.room.sendTextMessage(
      //     JSON.stringify({
      //       cmd: "cmdParticipantsMuted",
      //       value: this.participantsMuted,
      //     })
      //   );
      //   if (this.parentReferences[this.participant.audioTrack]) {
      //     this.parentReferences[this.participant.audioTrack][0].muted = false;
      //   }
      // } else {
      //   this.participantsMuted[this.participant.participantId] = {
      //     muted: true,
      //     byAdmin: true,
      //   };
      //   this.room.sendTextMessage(
      //     JSON.stringify({
      //       cmd: "cmdParticipantsMuted",
      //       value: this.participantsMuted,
      //     })
      //   );
      //   if (this.parentReferences[this.participant.audioTrack]) {
      //     this.parentReferences[this.participant.audioTrack][0].muted = true;
      //   }
      // }
    },
    onBeforeUnload(e) {
      e = e || window.event;
      if (e) {
        e.preventDefault();
        e.returnValue = '';
      }
      return '';
    },
    onMouseWheel(e){
      if (!this.chatOpen && this.$refs.listenerContainerConferenceHall) {
        this.$refs.listenerContainerConferenceHall.scrollLeft += e.deltaY;
      }
    },
    getIsParticipantIsPodium(userUUID) {
      if (!this.getIsConferenceHall) return false;
      if ((this.remoteParticipants || []).length) {
        const participantObj = this.remoteParticipants.find(
          (e) => e.userUUID == userUUID
        );
        if (participantObj) return true;
        if ((this.bridgeInfoStore?.podium || {})[userUUID]) {
          return (this.bridgeInfoStore.podium[userUUID] === this.getCallUUID);
        }
      }
      const callObject = this.state.remoteBridgeStreams[this.getCallUUID];
      for (let i = 0; i < callObject.conferenceAttendees.length; i++) {
        const member = callObject.conferenceAttendees[i];
        if (member.uuid === userUUID) return member.isPodium || false;
      }
      return false;
    },
    areAllMods() {
      if (!this.room) return false;
      const participants = this.room.getParticipants();
      if (participants.length > 0) {
        let finalResult = true;
        for (const participant of participants) {
          if (!participant.isModerator()) {
            finalResult = false;
          }
        }
        this.allMods = finalResult;
      }
    },
    toggleChat() {
      this.chatOpen = !this.chatOpen;
    },
    toggleChatMessageNotificationSound(){
      this.chatMessageNotificationSound= !this.chatMessageNotificationSound
    },
    downloadSupportLog(filename = 'meetlog.json') {
      const logs = [];
      const dumpNavigator = function (o, recurse = true) {
        const data = {};
        for (const k in o) {
          if (o[k] === null || typeof o[k] === 'function' || o[k] === "" || ( // ignore these
            o[k] && typeof o[k] === 'object' && !Array.isArray(o[k]) &&
              (typeof o[k].length === "number" || // ignore array-likes not array
                (!recurse && !Object.keys(o[k]).length && JSON.stringify(o[k]) === '{}')) // ignore empty objects
          )) continue;
          data[k] = (recurse && o[k] && typeof o[k] === 'object' && !Array.isArray(o[k]))
            ? dumpNavigator(o[k], false)
            : o[k];
        }
        return Object.keys(data).length ? data : undefined;
      };
      logs.push(dumpNavigator({
        userAgent: window.navigator.userAgent,
        hardwareConcurrency: window.navigator.hardwareConcurrency,
        deviceMemory: window.navigator.deviceMemory || "",
        connection: window.navigator.connection || {},
      }));
      if (this.connection) logs.push(this.connection.getLogs());
      if (this.desktopConnection) logs.push(this.desktopConnection.getLogs());
      const downloadJSON = function (json, filename) {
        const data = encodeURIComponent(JSON.stringify(json, null, 2));
        const elem = document.createElement('a');
        elem.download = filename;
        elem.href = `data:application/json;charset=utf-8,${data}`;
        elem.dataset.downloadurl = ['text/json', elem.download, elem.href].join(':');
        elem.dispatchEvent(new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: false
        }));
      };
      downloadJSON(logs, filename);
    },
    downloadChat(filename = 'chatMessages.txt'){
      if(!this.chatMessages) return
      const formattedchat = this.chatMessages.map((message)=>{
        return `${new Date(message.ts).toLocaleString()} - ${this.getNameForUuid(message.user)}: ${message.text.replace('\n','')}\r\n`
      }).join('')
      const downloadTextFile = function (text, filename) {
        const data = encodeURIComponent(text);
        const elem = document.createElement('a');
        elem.download = filename;
        elem.href = `data:text/plain;charset=utf-8,${data}`;
        elem.dataset.downloadurl = ['text/plain', elem.download, elem.href].join(':');
        elem.dispatchEvent(new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: false
        }));
      };
      downloadTextFile(formattedchat, filename);
    },
    rejectCallUserConnectionInterrupted() {
      const participantId = this.room?.myUserId();
      const isP2PActive = this.room?.isP2PActive();
      console.log(
        "participant connection status changed:",
        participantId,
        "interrupted",
        isP2PActive
      );
      if (participantId && isP2PActive && this.joinedCall && !this.rejectingCall) {
        // sometimes p2p connections fail
        this.room.stopP2PSession();
      } else {
        // reject the call for the own participant who lost the connection
        return this.rejectCall();
      }
    },
    remoteVideoTrackCheckAttach(track, reason = "", wantedContainer = undefined) {
      const participantId = track.getParticipantId();
      const trackId = track.getId();
      const trackMuted = track.isMuted();
      const trackType = track.getType();
      const container = track?.containers?.length && track.containers[0];
      const participantObj = // video camera participant
        (track.videoType == undefined || track.videoType == "camera") &&
        (this.remoteParticipants || []).length &&
        this.remoteParticipants.find(
          (e) => e.participantId == participantId
        );
      if (participantObj && participantObj.videoTrackObj === track) {
        participantObj.videoTrackActive = !trackMuted;
      }
      if (trackMuted && container) {
        console.log(
          `remote ${track.isP2P ? "p2p " : ""}${track.videoType ? `${track.videoType} ` : ""}${trackType} muted track detach ${reason ? `due to ${reason} ` : ""}for ` + participantId
        );
        const isInBadQuality = this.badQualityUser.indexOf(participantId);
        if (isInBadQuality !== -1) {
          this.badQualityUser.splice(isInBadQuality, 1);
        }
        track.detach();
      } else if (!trackMuted && this.room && !this.rejectingCall) {
        const newContainer = wantedContainer || // container wanted by the caller
          (participantObj && participantObj.videoTrackObj === track && (this.$refs[trackId] || [])[0]) // video camera container
            || (track.videoType == "desktop" && this.currentDesktop == participantId && this.$refs.localVideoShareScreen) // desktop screen container
        if (!container && newContainer) {
          track.attach(newContainer);
          console.log(
            `remote ${track.isP2P ? "p2p " : ""}${track.videoType ? `${track.videoType} ` : ""}${trackType} unmuted track attached ${reason ? `due to ${reason} ` : ""}for ` + participantId
          );
          if (!track.isP2P) this.updateHqTracks();
        } else {
          console.warn(
            `remote ${track.isP2P ? "p2p " : ""}${track.videoType ? `${track.videoType} ` : ""}${trackType} unmuted track attach ${reason ? `due to ${reason} ` : ""}failed for ` + participantId
          )
        }
      } else {
        console.log(
          `remote ${track.isP2P ? "p2p " : ""}${track.videoType ? `${track.videoType} ` : ""}${trackType} track ${trackMuted ? "muted" : "unmuted"} ${reason ? `due to ${reason} ` : ""}for ` + participantId
        );
      }
    },
    onRemoteTrackMuted(track) {
      const participantId = track.getParticipantId();
      const trackMuted = track.isMuted();
      const trackType = track.getType();
      if (trackType === "audio") {
        this.addRemoveParticipantMuted(participantId, trackMuted);
      } else if (trackType === "video") {
        const reason = trackMuted ? 'mute' : 'unmute';
        this.remoteVideoTrackCheckAttach(track, reason);
      }
    },
    addRemoveParticipantMuted(participantId, isMuted){
      if(!participantId) return false;
      const participantsMutedIndex = this.participantsMuted.indexOf(participantId);
      if(isMuted) {
        if (participantsMutedIndex == -1) this.participantsMuted.push(participantId);
      } else {
        if (participantsMutedIndex !== -1) this.participantsMuted.splice(participantsMutedIndex, 1);
      }
    },
    onAudioLevelChanged() {
      /* noop */
    },
    onFullScreenChange(evt) {
      if (this.isFullScreen()) {
        this.fullScreenActive = true;
      } else {
        this.fullScreenActive = false;
      }
    },
    onKeyUp(event) {
      if (
        this.spaceShortcutCallAcceptTimeout &&
        event.type === "keyup" &&
        event.keyCode === 32
      ) {
        clearTimeout(this.spaceShortcutCallAcceptTimeout);
        this.spaceShortcutCallAcceptTimeout = null;
        if (
          event.target.nodeName !== "TEXTAREA" &&
          event.target.nodeName !== "INPUT"
        ) {
          // return this.rejectCall();
          this.showCloseFinishCallModal('components.callsContent.confirmFinishCall');
        }
      } else if (event.type === "keyup" && event.keyCode === 32) {
        this.spaceShortcutCallAcceptTimeout = setTimeout(() => {
          this.spaceShortcutCallAcceptTimeout = null;
        }, 300);
      }
    },
    showWaveHand(participantId) {
      return (
        this.getAmIModerator &&
        this.wavingHand.indexOf(participantId) !== -1 &&
        this.showHandFor[participantId] &&
        this.showHandFor[participantId].showHand === true
      );
    },
    // showHandRequesToSpeakConferenceHall(participantId) {
    //   return (
    //     this.getIsConferenceHall &&
    //     participantId &&
    //     this.requestToSpeakConferenceHall &&
    //     this.requestToSpeakConferenceHall[participantId] &&
    //     this.requestToSpeakConferenceHall[participantId].request
    //   );
    // },
    // requestToSpeakTimestamp(participantId){
    //   if (
    //     this.showHandRequesToSpeakConferenceHall(participantId)
    //   ) {
    //     const timestampParticipant = new Date(this.requestToSpeakConferenceHall[participantId].timestamp);
    //     let finalResult = timestampParticipant.getHours() + ":" + timestampParticipant.getMinutes();
    //     return finalResult;
    //   }
    // },
    // isListenerSpeakerConferenceHall(participantId){
    //   return (
    //     this.getIsConferenceHall &&
    //     participantId &&
    //     this.listenerSpeakerConferenceHall &&
    //     this.listenerSpeakerConferenceHall[participantId] &&
    //     this.listenerSpeakerConferenceHall[participantId].speak
    //   );
    // },
    resetHands() {
      this.showHandFor = {};
    },
    removeFromObj(uuid) {
      if (this.showHandFor[uuid]) {
        delete this.showHandFor[uuid];
      }
    },
    updateDocuments(personId, isModerator) {
      const foundStore = this.myDocuments.find(
        (e) => e.callUUID === this.getCallUUID
      );
      if (foundStore && foundStore.callUUID) {
        if (foundStore.docMembersUUIDS.indexOf(personId) == -1) {
          foundStore.docMembersUUIDS.push(personId);
        }
        if (isModerator && foundStore.moderators.indexOf(personId) == -1) {
          foundStore.moderators.push(personId);
        }
        updateCompanyDocumentEvent(foundStore);
      }
    },
    async toggleRotateCamera() {
      if (this.mobileCamera == "user") {
        this.mobileCamera = "environment";
      } else {
        this.mobileCamera = "user";
      }
      if (this.localVideoPromise) {
        await this.localVideoPromise.catch(
          console.warn.bind(console, "local video promise rejection")
        );
      }
      for (const track of this.room.getLocalTracks("video")) {
        const trackString = track.toString();
        track.detach();
        await track.mute()
          .catch(console.warn.bind(console, 'mute room video track', trackString));
        if (track === this.localVideoTrack) this.localVideoTrack = null;
        await track.dispose()
          .catch(console.warn.bind(console, 'dispose room video track', trackString));
      }
      if (this.localVideoTrack) {
        const track = this.localVideoTrack;
        track.detach();
        this.localVideoTrack = null;
        await track
          .dispose()
          .catch(console.warn.bind(console, 'dispose local video track', track.toString()));
      }
      await this.createLocalTracks(
        {
          devices: ["video"],
          facingMode: { exact: this.mobileCamera },
        },
        this.onLocalTracks.bind(this)
      );
    },
    isUserInCall(uuid) {
      if (!this.room) return;
      const participantObj = this.remoteParticipants.find(
        (e) => e.userUUID == uuid
      );
      if (participantObj) {
        return true;
      } else {
        return false;
      }
    },
    async kickUser(uuid) {
      if (!this.room) return;
      const participantObj = this.remoteParticipants.find(
        (e) => e.userUUID == uuid
      );
      if (participantObj) {
        if (
          this.room
            .getParticipantById(participantObj.participantId)
            .isModerator()
        ) {
          await this.room.takeMod(participantObj.participantId);
        }
        this.room.kickParticipant(participantObj.participantId);
      }
    },
    changeMenuIsOpen(state) {
      this.menuIsOpen = state;
    },
    hideRemoteBar() {
      if (this.timeoutRemoteBar || this.menuIsOpen) {
        clearTimeout(this.timeoutRemoteBar);
        this.timeoutRemoteBar = undefined;
      }
      if (this.menuIsOpen) return;
      this.showBarOnOverRemote = false;
    },
    hideShareScreenBar() {
      if (this.timeoutShareScreenBar || this.menuIsOpen) {
        clearTimeout(this.timeoutShareScreenBar);
        this.timeoutShareScreenBar = undefined;
      }
      if (this.menuIsOpen) return;
      this.showShareScreenBar = false;
    },
    moveMouseShareScreen() {
      if (this.menuIsOpen) return;
      this.showShareScreenBar = true;
      if (this.timeoutShareScreenBar || this.menuIsOpen) {
        clearTimeout(this.timeoutShareScreenBar);
        this.timeoutShareScreenBar = undefined;
      }
      this.timeoutShareScreenBar = setTimeout(() => {
        this.showShareScreenBar = false;
      }, 3000);
    },
    moveMouserCardRemote(participantId) {
      if (this.menuIsOpen) return;
      this.showBarOnOverRemote = participantId;
      if (this.timeoutRemoteBar || this.menuIsOpen) {
        clearTimeout(this.timeoutRemoteBar);
        this.timeoutRemoteBar = undefined;
      }
      this.timeoutRemoteBar = setTimeout(() => {
        this.showBarOnOverRemote = false;
      }, 3000);
    },
    changeActualFullScreenTypeRemote(type) {
      this.actualFullScreenTypeRemote = type;
    },
    hangUpCalling(id) {
      this.$refs[`callingBar_${id}`][0].classList.add("d-none");
      const callUUID = this.getCallUUID;
      const callInfo = this.state.remoteBridgeStreams[callUUID];
      wsCall("sendToUUID", id, {
        type: "bridge-signal",
        action: "cancel_bridge_call",
        sender: this.state.ownUUID,
        info: {
          callUUID: callInfo.callUUID,
        },
      });
      store.removeCallingUser(id, callInfo);
    },
    updateHqTracks: debounce(function () {
      if (this._delayUpdateHqTracks) {
        this._delayUpdateHqTracks = false;
        return this.updateHqTracks();
      }
      if (
        this.rejectingCall ||
        !this.joinedCall ||
        !this.connection ||
        !this.room ||
        !this.getTotalRemoteParticipants ||
        this.room?.isP2PActive()
      ) {
        return;
      }
      /**
       * @type string[]
       */
      const hqTracks = [];
      if (this.getTotalRemoteParticipants < 4) {
        const participants = this.room.getParticipants();
        for (const participant of participants) {
          if (participant.getDisplayName() == "recorder") continue;
          if (this.desktopRoom && this.desktopRoom.myUserId()) {
            if (this.desktopRoom.myUserId() != participant.getId())
              hqTracks.push(participant.getId());
          } else {
            hqTracks.push(participant.getId());
          }
        }
      } else {
        const participantIds = this.room
          .getParticipants()
          .map((participant) => participant.getId());
        if (
          this.presenterModeInitiatedBy &&
          this.presenterModeInitiatedBy != this.room.myUserId() &&
          participantIds.includes(this.presenterModeInitiatedBy)
        ) {
          hqTracks.push(this.presenterModeInitiatedBy);
        }
        if (
          this.wordGivenTo &&
          this.wordGivenTo != this.room.myUserId() &&
          participantIds.includes(this.wordGivenTo)
        ) {
          hqTracks.push(this.wordGivenTo);
        }
        if (
          !this.desktopRoom &&
          this.currentDesktop &&
          participantIds.includes(this.currentDesktop)
        ) {
          hqTracks.push(this.currentDesktop);
        }
      }
      /**
       * @type Array<[string, JitsiLocalTrack]>
       */
      const videoTracksMap = this.remoteParticipants
        .map(e => e.videoTrackActive && e.videoTrackObj && [e.participantId, e.videoTrackObj])
        .concat((!this.desktopRoom && this.currentDesktop) ? [[this.currentDesktop, this.room.getParticipantById(this.currentDesktop)?.getTracksByMediaType("video")[0]]] : [])
        .filter(e => !!e && !!e[0] && !!e[1] && !this.badQualityUser.includes(e[0]) && hqTracks.includes(e[0]))
        .filter(([participantId, videoTrackObj]) => (!videoTrackObj.isP2P && videoTrackObj.containers?.length));
      if (!videoTracksMap.length) return;
      this.room.setReceiverConstraints({ constraints: videoTracksMap.reduce(
        (a, v) => {
          const [participantId, videoTrackObj] = v;
          a[videoTrackObj.getSourceName()] = (this.currentDesktop && participantId === this.currentDesktop)
            ? { maxHeight: 1080 }
            : { maxHeight: 720 };
          return a;
        }, {})
      });
      console.log('update hq tracks, length:', videoTracksMap.length);
    }, 900),
    toggleFullSharescreen() {
      this.shareFullScreen = !this.shareFullScreen;
      const element = this.$refs.shareScreenContainer;
      if (!this.isFullScreen()) {
        this.enterFullScreen(element);
        this.localFullScreen = true;
      } else {
        this.exitFullScreen();
        this.localFullScreen = false;
      }
    },
    onWaveHandCommand(participantId, value) {
      if (this.ownUUID == "recorder") return;
      const indexwavingHand = this.wavingHand.indexOf(participantId);
      if (value == true) {
        if (!this.room.isModerator()) return;
        this.reproduceDingDong();
        if (indexwavingHand == -1) {
          this.wavingHand.push(participantId);
        }
        if (!this.showHandFor[participantId]) {
          this.showHandFor[participantId] = { showHand: true };
        }
      } else {
        if (indexwavingHand !== -1) {
          this.wavingHand.splice(indexwavingHand, 1);
        }
        if (this.showHandFor[participantId]) {
          this.removeFromObj(participantId);
        }
      }
    },
    reproduceDingDong() {
      const audioPath = '/media/notificationSound.mp3';
      // Get real devices id's
      const ringingDeviceId = store.state.persisted.mediaDeviceSetup.ringingOutputId;
      const audioDeviceId = store.state.persisted.mediaDeviceSetup.audioOutputId;
      // Pre-Set outputs
      const audioOutput = new Audio(audioPath);
      const ringingOutput = new Audio(audioPath);
      // Sync audioDevice
      let promise = Promise.resolve();
      if ('sinkId' in audioOutput && 'setSinkId' in audioOutput && audioDeviceId) {
        promise = audioOutput.setSinkId(audioDeviceId);
      }
      promise
        .then(() => audioOutput.play())
        .catch(err => console.warn('calls(jitsiCall) Failed to play notification audio on audioOutput', err));
      // Sync && Play at ringing device only if we have ringingDeviceId for it and if it's different from audioOutputId
      if (audioDeviceId && ringingDeviceId && 'sinkId' in ringingOutput && 'setSinkId' in ringingOutput && ringingDeviceId !== audioDeviceId) {
        promise = ringingOutput.setSinkId(ringingDeviceId);
        promise
          .then(() => ringingOutput.play())
          .catch(err => console.warn('calls(jitsiCall) Failed to play notification audio on ringingOutput', err));
      }
    },
    toggleWaveHand() {
      if (!this.room) return;
      const indexwavingHand = this.wavingHand.indexOf(this.room.myUserId());
      if (indexwavingHand !== -1) {
        this.wavingHand.splice(indexwavingHand, 1);
        this.room.sendTextMessage(
          JSON.stringify({
            cmd: "cmdWaveHand",
            value: false,
            user: this.room.myUserId(),
          })
        );
      } else {
        this.wavingHand.push(this.room.myUserId());
        this.room.sendTextMessage(
          JSON.stringify({
            cmd: "cmdWaveHand",
            value: true,
            user: this.room.myUserId(),
          })
        );
      }
    },
    toggleGiveWord(participantId = false) {
      if (!this.room) return;
      const participant = participantId ? participantId : this.room.myUserId();
      if (this.showHandFor[participant]) {
        this.removeFromObj(participant);
      }
      if (
        participant == this.room.myUserId() ||
        this.room.getParticipantById(participant).isModerator()
      ) {
        this.wordGivenTo = null;
        this.room.sendTextMessage(
          JSON.stringify({ cmd: "cmdGiveWord", user: null })
        );
        this.room.sendTextMessage(
          JSON.stringify({
            cmd: "cmdPresenterMode",
            value: true,
            initiator: participant,
          })
        );
      } else if (
        participant != this.room.myUserId() &&
        (!this.wordGivenTo || participant != this.wordGivenTo)
      ) {
        this.wordGivenTo = participant;
        this.room.sendTextMessage(
          JSON.stringify({ cmd: "cmdGiveWord", user: participant })
        );
        this.room.sendTextMessage(
          JSON.stringify({
            cmd: "cmdWaveHand",
            value: false,
            user: participant,
          })
        );
      } else {
        this.wordGivenTo = null;
        this.room.sendTextMessage(
          JSON.stringify({ cmd: "cmdGiveWord", user: null })
        );
      }
    },
    onGiveWordCommand(participantId) {
      this.wordGivenTo = participantId;
      this.presenterModeInitiatedBy = participantId;
      if (this.currentDesktop && this.currentDesktop === this.getMyDesktopRoomUserID) {
        this.stopShareScreen();
      }
      this.updateHqTracks();
    },
    async togglePresentation(tileId) {
      if (!this.room) return;
      if (!this.presenterMode) {
        this.presenterModeInitiatedBy = this.room.myUserId();
      } else {
        this.presenterModeInitiatedBy = null;
        this.remoteSpeakerIndex = false;
        this.resetHands();
      }
      this.presenterMode = !this.presenterMode;
      this.room.sendTextMessage(
        JSON.stringify({
          cmd: "cmdPresenterMode",
          value: this.presenterMode,
          initiator: this.room.myUserId(),
        })
      );
    },
    onMicMutedByAdmin: debounce(function (val){
      if (!this.room) return;
      this.micMutedByAdmin = val;
      // check if you participantId exist mute your mic
      const micMutedByAdminIndex = this.micMutedByAdmin.indexOf(this.room.myUserId())
      // force to mute and disable mic icon
      if(micMutedByAdminIndex !== -1 && !this.micMute) this.toggleMic();
    }, 100),
    onPresenterModeCommand(presentationData) {
      if (presentationData && presentationData.value) {
        this.presenterModeInitiatedBy = presentationData.initiator;
        this.presenterMode = presentationData.value;
      } else {
        this.presenterModeInitiatedBy = null;
        this.presenterMode = false;
      }
      this.updateHqTracks();
    },
    toggleShareScreen() {
      this.shareScreenMode = this.shareScreenMode == 0 ? 1 : 0;
      this.resetHands();
      if (this.shareScreenMode == 1) {
        // create media
        this.localDesktopMediaPromise = JitsiMeetJS.createLocalTracks({
          devices: ["desktop"],
        }).catch((err) => {
          console.warn("create local desktop media", err);
          this.localDesktopMediaPromise = null;
          return Promise.reject(err);
        });
        // create connection
        const connection = new JitsiMeetJS.JitsiConnection(
          null,
          null,
          this.jvbOptions
        );
        this.desktopConnection = connection;
        connection.addEventListener(
          JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
          () => this.onDesktopConnectionSuccess(this.getCallUUID)
        );
        connection.addEventListener(
          JitsiMeetJS.events.connection.CONNECTION_FAILED,
          () => this.stopShareScreen()
        );
        connection.connect();
      } else {
        // remove connection
        return this.stopShareScreen();
      }
    },
    async stopShareScreen(userLeft = false) {
      console.log("stop share screen:", userLeft);
      if (
        userLeft && (
          this.currentDesktop !== userLeft ||
          (this.localDesktopMediaPromise && this.shareScreenMode == 0)
        )
      ) {
        return;
      }
      const d = (this.localDesktopDisposedPromise = new Deferred());
      try {
        if (this.shareScreenMode || this.desktopRoom || this.localDesktopTrack || this.desktopConnection) {
          this.bridgeCallScreenShareState(false);
        }
        if (this.currentDesktop === this.getMyDesktopRoomUserID) {
          this.shareScreenMode = 0;
        }
        if (this.desktopRoom) {
          if (this.localDesktopPromise) {
            await this.localDesktopPromise.catch(
              console.warn.bind(console, "local desktop promise rejection")
            );
          }
          // Leave desktop room
          const room = this.desktopRoom;
          const toRemove = room && room.getLocalTracks();
          if (room && room.isJoined()) await room.leave().catch(console.warn.bind(console, 'leave desktop room'));
          // All tracks must be disposed after leaving
          await parallel(1, toRemove || [], async (track) => {
            if (!track.conference && !track.disposed) {
              const trackString = track.toString();
              if (track.containers?.length) track.detach();
              if (track === this.localDesktopTrack) this.localDesktopTrack = null;
              await track.dispose().catch(console.warn.bind(console, 'dispose desktop room track', trackString));
            }
          });
          if (room === this.desktopRoom) this.desktopRoom = null;
        }
        if (this.localDesktopTrack) {
          const track = this.localDesktopTrack;
          const trackString = track.toString();
          track.detach();
          this.localDesktopTrack = null;
          await track.dispose()
            .catch(console.warn.bind(console, 'dispose local desktop track', trackString));
        }
        if (this.desktopConnection) {
          let promise = Promise.resolve();
          if (
            this.desktopConnection.xmpp &&
            this.desktopConnection.xmpp.connection &&
            !this.desktopConnection.xmpp.disconnectInProgress
          ) {
            promise = this.desktopConnection.disconnect()
              .catch(console.warn.bind(console, "drop desktop connection"));
          } else {
            console.warn("desktop connection already dropped");
          }
          this.desktopConnection = null;
          await promise;
        }
        if (this.implicitPresenterMode == true) {
          this.implicitPresenterMode = false;
          this.presenterMode = this.getIsConferenceHall ? true : !this.presenterMode;
          this.presenterModeInitiatedBy = null;
          if (this.room) this.room.sendTextMessage(
            JSON.stringify({
              cmd: "cmdPresenterMode",
              value: this.presenterMode,
              initiator: this.presenterModeInitiatedBy,
            })
          );
        }
        if (!this.getIsConferenceHall) {
          this.onPresenterModeCommand({ value: false });
        }
        if (this.localDesktopMediaPromise) {
          this.localDesktopMediaPromise
            .then((tracks) => {
              const promises = [];
              tracks.forEach((track) => {
                const trackId = track.getId();
                const trackType = track.getType();
                if (!track.disposed) {
                  const promise = track.dispose().catch(console.warn.bind(console, `dispose desktop ${trackType} track: ${trackId}`));
                  promises.push(promise);
                }
              });
              return Promise.allSettled(promises);
            })
            .catch(console.warn.bind(console, "dispose local desktop media"));
          this.localDesktopMediaPromise = null;
        }
        this.currentDesktop = null;
      } catch (error) {
        console.warn("stop share screen", error);
      }
      return d.resolve();
    },
    bridgeCallScreenShareState(active) {
      return bridgeCallScreenShareState(active);
    },
    toggleAllRemoteMic() {
      if(this.allRemoteMicMuted){
        this.micMutedByAdmin = []
      }else{
        const participants = this.room.getParticipants();
        if (participants.length > 0) {
          for (const participant of participants) {
            if (!participant.isModerator() && this.micMutedByAdmin.indexOf(participant.getId()) == -1) {
              this.micMutedByAdmin.push(participant.getId())
            }
          }
        }
      }
       this.room.sendTextMessage(
          JSON.stringify({
            cmd: "cmdMicMutedByAdmin",
            value: this.micMutedByAdmin,
          })
        )
    },
    toggleAllCameraOff() {
      let allCameraOff = true;
      let count = 0;
      for (const participant of this.room.getParticipants()) {
        if (participant.getDisplayName() == "recorder") continue;
        if (participant.getDisplayName().split("_")[0] == "desktop") continue;
        if (!participant.isModerator()) {
          if (
            !this.participantsCameraOff[participant.getId()] ||
            this.participantsCameraOff[participant.getId()].cameraOff != true
          )
            allCameraOff = false;
          count++;
        }
      }
      if (!allCameraOff || count == 0) {
        for (const participant of this.room.getParticipants()) {
          if (participant.getDisplayName() == "recorder") continue;
          if (participant.getDisplayName().split("_")[0] == "desktop") continue;
          const participantId = participant.getId();
          if (!participant.isModerator()) {
            this.participantsCameraOff[participantId] = {
              cameraOff: true,
            };
          }
        }
      } else {
        for (const participant of this.room.getParticipants()) {
          if (participant.getDisplayName() == "recorder") continue;
          if (participant.getDisplayName().split("_")[0] == "desktop") continue;
          const participantId = participant.getId();
          delete this.participantsCameraOff[participantId];
        }
        if (
          this.getAmIModerator &&
          this.participantsCameraOff[this.getMyRoomId]
        ) {
          delete this.participantsCameraOff[this.getMyRoomId];
        }
      }
      this.allRemoteCameraOff = !allCameraOff;
      this.room.sendTextMessage(
        JSON.stringify({
          cmd: "cmdParticipantsCameraOff",
          value: this.participantsCameraOff,
          allRemoteCameraOff: this.allRemoteCameraOff,
        })
      );
    },
    toggleCameraOff(participantId, justDisable = false) {
      if (
        this.participantsCameraOff[participantId] &&
        this.participantsCameraOff[participantId].cameraOff == true
        && !justDisable
      ) {
        delete this.participantsCameraOff[participantId];
        this.room.sendTextMessage(
          JSON.stringify({
            cmd: "cmdParticipantsCameraOff",
            value: this.participantsCameraOff,
          })
        );
      } else {
        this.participantsCameraOff[participantId] = {
          cameraOff: true,
        };
      }
      this.checkAllRemoteCameraOff();
    },
    checkAllRemoteCameraOff() {
      if (!this.room) return;
      if (this.room.getParticipants().length == 0) return;
      this.allRemoteCameraOff = true;
      for (const participant of this.room.getParticipants()) {
        if (participant.getDisplayName() == "recorder") continue;
        if (participant.getDisplayName().split("_")[0] == "desktop") continue;
        const participantId = participant.getId();
        if (
          this.participantsCameraOff &&
          !this.participantsCameraOff[participantId] &&
          !this.isUserModerator(participantId)
        ) {
          this.allRemoteCameraOff = false;
        }
      }
      this.room.sendTextMessage(
        JSON.stringify({
          cmd: "cmdParticipantsCameraOff",
          value: this.participantsCameraOff,
          allRemoteCameraOff: this.allRemoteCameraOff,
        })
      );
    },
    hasPrivilege(userUUID) {
      return hasPrivilege(userUUID);
    },
    handlerClickRemoteParticipant(participantId) {
      if (this.menuIsOpen || this.isOnlyOneCallUser) return;
      EventBus.$emit("toggleFullscreenRoutine", participantId);
    },
    setUserMaximized(participantId) {
      this.userMaximized = participantId;
      this.localFullScreen = false;
    },
    setMaximizeScreen(data) {
      this.maximizeScreen = data;
      this.localFullScreen = false;
      this.userMaximized = null;
    },
    onParticipantsCameraOffCommand: debounce(function (val, allRemoteCameraOff = false) {
      if (!this.room) return;
      this.participantsCameraOff = {};
      for (const pid of Object.keys(val)) {
        this.participantsCameraOff[pid] = val[pid];
      }
      if (!this.getAmIModerator) {
        if (
          val[this.room.myUserId()] &&
          val[this.room.myUserId()].cameraOff == true
        ) {
          // disable camera icon
          this.cameraOffByModerator = true;
          // check if camera enable, toggle camera to disable
          if (!this.videoDisabled) {
            setTimeout(() => {
              if (!this.videoDisabled) this.toggleVideo();
            }, 900);
          }
        } else {
          // check camera icon and if disabled
          if (this.cameraOffByModerator) {
            this.cameraOffByModerator = false;
          }
        }
      } else {
        if (this.cameraOffByModerator) {
          this.cameraOffByModerator = false;
        }
      }
      this.allRemoteCameraOff = allRemoteCameraOff;
    }, 100),
    isDominantSpeaker(participantId = false) {
      if (!this.room) return;
      if (participantId) {
        return this.dominantSpeaker === participantId;
      } else {
        const myParticipantId = this.room.myUserId();
        return this.dominantSpeaker === myParticipantId;
      }
    },
    redirectToStartView() {
      const startView =
        "/" +
        (isGuestOrVisitor()
          ? "home"
          : store.state.user.userSettings.startView || "my-favorites");
      this.setCurrentContentVisile(startView, true, this.$router);
    },
    rejectFullCall() {
      if (this.room) {
        this.room.sendTextMessage(
          JSON.stringify({
            cmd: "cmdEndCall",
          })
        );
        this.redirectToStartView();
      }
    },
    hangUpBridgeCall() {
      this.bridgeCallEnd = true;
    },
    setCallDuration(callUUID, callDuration, users = null) {
      if (users) {
        if (users.indexOf(this.ownUUID) !== -1) {
          return store.changeCallDurationMsBridgeStream(callUUID, callDuration);
        }
      } else {
        return store.changeCallDurationMsBridgeStream(callUUID, callDuration);
      }
    },
    updateTimeForUser(newTime) {
      if (newTime) {
        this.currentCallTime = newTime;
      }
    },
    isUserModerator(participantId) {
      if (!this.room) return;
      const participant = this.room.getParticipantById(participantId);
      if (participant) return participant.isModerator();
      if (participantId === this.room.myUserId()) {
        return this.room.isModerator();
      }
      return false;
    },
    toggleLocalFullScreen() {
      const element = this.$refs.callContainerWithProgressLinear;
      if (!this.isFullScreen()) {
        this.enterFullScreen(element);
        this.localFullScreen = true;
      } else {
        this.exitFullScreen();
        this.localFullScreen = false;
      }
    },
    enterFullScreen(element) {
      if (element.requestFullscreen) {
        try {
          element.requestFullscreen();
        } catch (error) {
          console.error("request fullscreen", error);
        }
      } else if (element.webkitRequestFullscreen) {
        /* Safari */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        /* IE11 */
        element.msRequestFullscreen();
      }
      this.fullScreenActive = true;
    },
    exitFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen().catch(() => {});
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      this.fullScreenActive = false;
    },
    isFullScreen() {
      return (
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement &&
          document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement &&
          document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null)
      );
    },
    localStatsUpdated(statsObject) {
      if (!statsObject.framerate) return;
      let refreshHqTracks = false;
      for (const participantId of Object.keys(statsObject.framerate)) {
        for (const rate of Object.keys(statsObject.framerate[participantId])) {
          const valueRate = statsObject.framerate[participantId][rate];
          const isInBadQuality = this.badQualityUser.indexOf(participantId);
          let isConfirmedBadQuality = valueRate < 4;
          if (isConfirmedBadQuality) {
            const participantObj = (this.remoteParticipants || []).length &&
              this.remoteParticipants.find(
                (e) => e.participantId == participantId
              );
            isConfirmedBadQuality = (participantObj && participantObj.videoTrackActive) || this.currentDesktop == participantId;
          }
          if (isConfirmedBadQuality) {
            if (isInBadQuality === -1) {
              this.badQualityUser.push(participantId);
              refreshHqTracks = true;
            }
          } else if (isInBadQuality !== -1) {
            this.badQualityUser.splice(isInBadQuality, 1);
            refreshHqTracks = true;
          }
        }
      }
      if (refreshHqTracks) {
        this.updateHqTracks();
      }
      this.localStats = statsObject;
    },
    getUserAudioOff(participantId) {
      return this.participantsAudioMuted[participantId];
    },
    onParticipantDeaf(participantId, val) {
      this.participantsAudioMuted = {};
      for (const pid of Object.keys(val)) {
        this.participantsAudioMuted[pid] = true;
      }
    },
    toggleMuteAudio() {
      if (!this.room) return;
      this.audioOutputMuted = !this.audioOutputMuted;
      if (this.audioOutputMuted) {
        this.participantsAudioMuted[this.room.myUserId()] = true;
      } else {
        delete this.participantsAudioMuted[this.room.myUserId()];
      }
      const remoteAudios = document.getElementsByClassName("remoteAudio");
      if (remoteAudios.length > 0) {
        remoteAudios.forEach((remote) => {
          remote.muted = this.audioOutputMuted;
        });
      }
      this.room.sendTextMessage(
        JSON.stringify({
          cmd: "cmdParticipantDeaf",
          value: this.participantsAudioMuted,
        })
      );
      // const userIsMutedByAdmin = this.micMutedByAdmin.indexOf(this.room.myUserId()) !==-1 ? true : false ;
      if (this.audioOutputMuted && !this.micMute) {
        this.toggleMic();
      }
      // if (!this.audioOutputMuted && this.micMute && !userIsMutedByAdmin) {
      //   this.toggleMic();
      // }
    },
    initConnection() {
      // JitsiMeetJS.mediaDevices.enumerateDevices(this.updateDevices);
      // JitsiMeetJS.mediaDevices.addEventListener(
      //   JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
      //   this.updateDevices
      // );
      const connection = new JitsiMeetJS.JitsiConnection(
        null,
        null,
        this.jvbOptions
      );
      this.connection = connection;
      connection.connect();
      connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
        () => {
          this.onConnectionSuccess(this.getCallUUID);
        }
      );
    },
    removeChecker() {
      if (
        !this.joinedCall ||
        !this.connection ||
        !this.room ||
        !this.toBeRemovedFromCallState ||
        !this.remoteParticipants
      ) {
        return;
      }
      for (const uuid of Object.keys(this.toBeRemovedFromCallState)) {
        if (Date.now() - this.toBeRemovedFromCallState[uuid] > 5000) { // 5s
          delete this.toBeRemovedFromCallState[uuid];
          const userIndex = this.remoteParticipants.findIndex(
            (e) => e.userUUID === uuid && e.calling
          );
          if (userIndex !== -1) {
            this.remoteParticipants.splice(userIndex, 1);
          }
          if (this.remoteParticipants.length < 1 && !this.isConferenceCall) {
            console.log("hang up call: no remote participants");
            return this.rejectCall();
          }
        }
      }
    },
    getUUIDForDisplayName(userUUID) {
      return userUUID.split("_")[1];
    },
    getTitelForUuid(userUUID) {
      return store.getTitelForUuid(userUUID);
    },
    getAvatarForUuid(userUUID) {
      return store.getAvatarForUuid(userUUID);
    },
    getVisitorFunctionForUuid(userUUID) {
      return store.getVisitorFunctionForUuid(userUUID);
    },
    getNameForUuid(userUUID) {
      return store.getNameForUuid(userUUID);
    },
    getMaximizedStyle() {
      return {
        width: "100%",
        height: "100%",
        flex: "0 0 100%",
      };
    },
    addObjectFit(index) {
      const totalRemoteParticipants = this.remoteParticipants.length;
      if (
        !this.currentDesktop &&
        !this.presenterMode &&
        totalRemoteParticipants == 2 &&
        index == 1
      ) {
        return false;
      } else {
        return true;
      }
    },
    getRemoteStyle(index) {
      const totalRemoteParticipants = this.remoteParticipants.length;
      let style = {
        width: 0,
        height: 0,
        flex: 0,
      };
      if (totalRemoteParticipants == 1) {
        //  2 or 4 participants
        style.width = "50%";
        style.height = "100%";
        style.flex = "0 0 50%";
      } else if (totalRemoteParticipants == 2) {
        // 3 participants
        if (index == 0) {
          style.width = "50%";
          style.height = "50%";
          style.flex = "0 0 50%";
        } else if (index == 1) {
          style.width = "100%";
          style.height = "50%";
          style.flex = "0 0 100%";
        }
      } else if (totalRemoteParticipants == 3) {
        style.width = "50%";
        style.height = "50%";
        style.flex = "0 0 50%";
      } else if (totalRemoteParticipants == 4) {
        // 5 participants
        if (index == 0 || index === 1) {
          style.width = "33.33%";
          style.height = "50%";
          style.flex = "0 0 33.33%";
        } else if (index >= 2) {
          // participant 4 and 5
          style.width = "50%";
          style.height = "50%";
          style.flex = "0 0 50%";
        }
      } else if (totalRemoteParticipants == 5) {
        // 6 participants
        style.width = "33.33%";
        style.height = "50%";
        style.flex = "0 0 33.33%";
      } else if (totalRemoteParticipants == 6) {
        // 7 participants
        if (index >= 0 && index < 3) {
          style.width = "25%";
          style.height = "50%";
          style.flex = "0 0 25%";
        } else if (index >= 3) {
          // participant 4 and 5
          style.width = "33.33%";
          style.height = "50%";
          style.flex = "0 0 33.33%";
        }
      } else if (totalRemoteParticipants == 7) {
        // 8 participants
        style.width = "25%";
        style.height = "50%";
        style.flex = "0 0 25%";
      } else if (totalRemoteParticipants == 8) {
        // 9 participants
        style.width = "33.33%";
        style.height = "33.33%";
        style.flex = "0 0 33.33%";
      } else if (totalRemoteParticipants == 9) {
        // 10 participants
        style.width = "20%";
        style.height = "50%";
        style.flex = "0 0 20%";
      } else if (
        totalRemoteParticipants == 10 ||
        totalRemoteParticipants == 11
      ) {
        style.width = "25%";
        style.height = "33.33%";
        style.flex = "0 0 25%";
      } else if (totalRemoteParticipants == 12) {
        // 13 participants
        if (index >= 0 && index <= 3) {
          style.width = "20%";
          style.height = "33.33%";
          style.flex = "0 0 20%";
        } else if (index > 3) {
          style.width = "25%";
          style.height = "33.33%";
          style.flex = "0 0 25%";
        }
      } else if (totalRemoteParticipants == 13) {
        // 14 participants
        if (index >= 0 && index <= 8) {
          style.width = "20%";
          style.height = "33.33%";
          style.flex = "0 0 20%";
        } else if (index > 8) {
          style.width = "25%";
          style.height = "33.33%";
          style.flex = "0 0 25%";
        }
      } else if (totalRemoteParticipants == 14) {
        // 15 participants
        style.width = "20%";
        style.height = "33.33%";
        style.flex = "0 0 20%";
      } else if (totalRemoteParticipants == 15) {
        // 16 participants
        style.width = "25%";
        style.height = "25%";
        style.flex = "0 0 25%";
      } else if (totalRemoteParticipants == 16) {
        // 17 participants
        if (index >= 0 && index <= 3) {
          style.width = "20%";
          style.height = "25%";
          style.flex = "0 0 20%";
        } else if (index > 3) {
          style.width = "25%";
          style.height = "25%";
          style.flex = "0 0 25%";
        }
      } else if (totalRemoteParticipants == 17) {
        // 18 participants
        if (index >= 0 && index <= 8) {
          style.width = "20%";
          style.height = "25%";
          style.flex = "0 0 20%";
        } else if (index > 8) {
          style.width = "25%";
          style.height = "25%";
          style.flex = "0 0 25%";
        }
      } else if (totalRemoteParticipants == 18) {
        // 19 participants
        if (index >= 0 && index <= 13) {
          style.width = "20%";
          style.height = "25%";
          style.flex = "0 0 20%";
        } else if (index > 13) {
          style.width = "25%";
          style.height = "25%";
          style.flex = "0 0 25%";
        }
      } else if (totalRemoteParticipants == 19) {
        // 20 participants
        style.width = "20%";
        style.height = "25%";
        style.flex = "0 0 20%";
      }
      // const participantId = this.remoteParticipants[index].participantId;
      // const parentElement = document.getElementById(participantId);
      // if (
      //   totalRemoteParticipants == 2 &&
      //   index == 1 &&
      //   !this.currentDesktop &&
      //   !this.presenterModeInitiatedBy
      // ) {
      //   if (parentElement) {
      //     const video = parentElement.getElementsByTagName("video")[0];
      //     if (video) {
      //       const canvas = document.createElement("canvas");
      //       // scale the canvas accordingly
      //       canvas.width = video.videoWidth;
      //       canvas.height = video.videoHeight;
      //       // draw the video at that frame
      //       canvas
      //         .getContext("2d")
      //         .drawImage(video, 0, 0, canvas.width, canvas.height);
      //       // convert it to a usable data URL
      //       const dataURL = canvas.toDataURL();
      //       const images = parentElement.getElementsByClassName("videoBgImage");
      //       if (images.length > 0) {
      //         images.forEach((img) => {
      //           img.remove();
      //         });
      //       }
      //       const parentImage = document.createElement("img");
      //       parentImage.src = dataURL;
      //       parentImage.classList.add("videoBgImage");
      //       parentElement.appendChild(parentImage);
      //     }
      //   }
      // } else {
      //   if (parentElement) {
      //     const images = parentElement.getElementsByClassName("videoBgImage");
      //     if (images.length > 0) {
      //       images.forEach((img) => {
      //         img.remove();
      //       });
      //     }
      //   }
      // }
      return style;
    },
    async toggleMic(mutedUnMutedByModerator = false) {
      if (!this.room) return;
      this.micMute = !this.micMute; // change to your variable
      if (this.micMute) {
        // this.participantsMuted[this.room.myUserId()] = {
        //   muted: true,
        //   byAdmin: mutedUnMutedByModerator,
        // };
        for (const track of this.room.getLocalTracks("audio")) {
          await track.mute();
        }
      } else {
        // delete this.participantsMuted[this.room.myUserId()];
        for (const track of this.room.getLocalTracks("audio")) {
          await track.unmute();
        }
      }
      // this.room.sendTextMessage(
      //   JSON.stringify({
      //     cmd: "cmdUserMuted",
      //     value: this.participantsMuted,
      //   })
      // );
    },
    onTrackAudioLevelChanged(participantId, audioLevel) {
      if (!this.room) return;
      const remotAudioBar = document.getElementById(
        "remotAudioBar-" + participantId
      );
      const remoteIconDominant = document.getElementById(
        "remoteIconDominant-" + participantId
      );
      const fullLevel = audioLevel * 100;
      const halfLevel = fullLevel / 2;
      if (participantId === this.room.myUserId()) {
        const myAudioBar = document.getElementById("myAudioBar");
        const level = myAudioBar.getElementsByClassName(
          "progressBarLevelBridge"
        )[0];
        level.style.width = audioLevel * 100 + "%";
        const localIconDominant = document.getElementById("localIconDominant");
        if (localIconDominant) {
          const leftBar = localIconDominant.getElementsByClassName("left")[0];
          const middleBar =
            localIconDominant.getElementsByClassName("middle")[0];
          const rightBar = localIconDominant.getElementsByClassName("right")[0];
          if (localIconDominant && leftBar && middleBar && rightBar) {
            middleBar.style.height = fullLevel <= 0.8 ? "4px" : fullLevel + "%";
            rightBar.style.height = halfLevel <= 0.4 ? "4px" : halfLevel + "%";
            leftBar.style.height = halfLevel <= 0.4 ? "4px" : halfLevel + "%";
            // radius
            middleBar.style.borderRadius = fullLevel < 1 ? "50%" : "0%";
            leftBar.style.borderRadius = halfLevel < 1 ? "50%" : "0%";
            rightBar.style.borderRadius = halfLevel < 1 ? "50%" : "0%";
          }
        }
      }
      if (remotAudioBar) {
        const levelRemote = remotAudioBar.getElementsByClassName(
          "progressBarLevelBridge"
        )[0];
        levelRemote.style.width = audioLevel * 100 + "%";
      }
      if (remoteIconDominant) {
        const remoteLeftBar =
          remoteIconDominant.getElementsByClassName("left")[0];
        const remoteMiddleBar =
          remoteIconDominant.getElementsByClassName("middle")[0];
        const remoteRightBar =
          remoteIconDominant.getElementsByClassName("right")[0];
        remoteMiddleBar.style.height =
          fullLevel <= 0.8 ? "4px" : fullLevel + "%";
        remoteRightBar.style.height =
          halfLevel <= 0.4 ? "4px" : halfLevel + "%";
        remoteLeftBar.style.height = halfLevel <= 0.4 ? "4px" : halfLevel + "%";
        // radius
        remoteMiddleBar.style.borderRadius = fullLevel < 1 ? "50%" : "0%";
        remoteRightBar.style.borderRadius = halfLevel < 1 ? "50%" : "0%";
        remoteLeftBar.style.borderRadius = halfLevel < 1 ? "50%" : "0%";
      }
    },
    async toggleVideo() {
      if (!this.room) return;
      this.videoDisabled = !this.videoDisabled; // change to your variable
      if (this.videoDisabled) {
        if (this.localVideoPromise) {
          await this.localVideoPromise.catch(
            console.warn.bind(console, "local video promise rejection")
          );
        }
        for (const track of this.room.getLocalTracks("video")) {
          const trackString = track.toString();
          track.detach();
          await track.mute()
            .catch(console.warn.bind(console, 'mute room video track', trackString));
          if (track === this.localVideoTrack) this.localVideoTrack = null;
          await track.dispose()
            .catch(console.warn.bind(console, 'dispose room video track', trackString));
        }
        if (this.localVideoTrack) {
          const track = this.localVideoTrack;
          track.detach();
          this.localVideoTrack = null;
          await track.dispose()
            .catch(console.warn.bind(console, 'dispose local video track', track.toString()));
        }
        const participantId = this.room && this.room.myUserId();
        const isInBadQuality = this.badQualityUser.indexOf(participantId);
        if (isInBadQuality !== -1) {
          this.badQualityUser.splice(isInBadQuality, 1);
        }
      } else {
        const devices = await navigator.mediaDevices
          .enumerateDevices("video")
          .then((devices) => {
            let videoInput;
            if (this.videoDeviceId) {
              videoInput = devices.find(
                (d) => d.kind === "videoinput" && d.deviceId === this.videoDeviceId
              );
            }
            if (!videoInput) {
              videoInput = devices.find(
                (d) => d.kind === "videoinput"
              );
            }
            return {
              videoInputId: (videoInput || {}).deviceId,
            };
          });
        await this.createLocalTracks(
          {
            devices: ["video"],
            cameraDeviceId: devices.videoInputId,
          },
          this.onLocalTracks.bind(this)
        );
      }
    },
    createLocalTracks(options = {}, callback) {
      const create = () =>
        JitsiMeetJS.createLocalTracks(options).then(callback);
      return (this.localTracksQueue = this.localTracksQueue.then(
        create,
        create
      ));
    },
    onLocalTracks(tracks) {
      if (!this.room || this.rejectingCall) {
        tracks.forEach((track) => {
          const trackId = track.getId();
          const trackType = track.getType();
          track.dispose().catch(console.warn.bind(console, `dispose ${trackType} track: ${trackId}`));
        });
        return;
      }
      const resetDeferredTimeout = (d) => {
        d.clearRejectTimeout();
        d.setRejectTimeout(15000); // timeout in 15 seconds to avoid things getting stuck
      };
      const promises = [];
      const invokeAudioAttach = (track, d) =>
        this.$nextTick(async () => {
          const trackId = track.getId();
          const trackType = track.getType();
          try {
            if (this.room && this.localAudioTrack === track && this.$refs.localAudio) {
              track.attach(this.$refs.localAudio);
              this.$refs.localAudio.muted = true;
              resetDeferredTimeout(d);
              await Promise.race([this.room.addTrack(track), d]);
              console.log(`local ${trackType} track added:`, trackId);
              d.resolve(track);
            } else {
              throw new Error(
                `failed to add local ${trackType} track: ${trackId}`
              );
            }
          } catch (err) {
            console.warn(`invoke ${trackType} attach`, err);
            if (track === this.localAudioTrack) this.localAudioTrack = null;
            await track
              .dispose()
              .catch(
                console.warn.bind(
                  console,
                  `dispose ${trackType} track: ${trackId}`
                )
              );
            if (err.message !== "timeout") { d.reject(err); }
            else { this.rejectCallUserConnectionInterrupted(); }
          }
        });
      const conferenceTracks = this.room.getLocalTracks();
      tracks.forEach((track) => {
        const trackId = track.getId();
        const trackType = track.getType();
        if (conferenceTracks.indexOf(track) !== -1) {
          console.warn(`local ${trackType} track already added: ${trackId}`);
        } else if (
          trackType === "video" &&
          this.localVideoTrack === null &&
          !this.videoDisabled
        ) {
          const d = (this.localVideoPromise = new Deferred());
          promises.push(d);
          this.localVideoTrack = track;
          this.$nextTick(async () => {
            try {
              if (this.room && this.localVideoTrack === track && this.$refs.localVideo) {
                track.attach(this.$refs.localVideo);
                resetDeferredTimeout(d);
                await Promise.race([this.room.addTrack(track), d]);
                console.log(`local ${trackType} track added:`, trackId);
                d.resolve(track);
              } else {
                throw new Error(
                  `failed to add local ${trackType} track: ${trackId}`
                );
              }
            } catch (err) {
              console.warn(`invoke ${trackType} attach`, err);
              if (track === this.localVideoTrack) this.localVideoTrack = null;
              await track
                .dispose()
                .catch(
                  console.warn.bind(
                    console,
                    `dispose ${trackType} track: ${trackId}`
                  )
                );
              if (err.message !== "timeout") { d.reject(err); }
              else { this.rejectCallUserConnectionInterrupted(); }
            }
          });
        } else if (trackType === "audio" && this.localAudioTrack === null) {
          const d = (this.localAudioPromise = new Deferred());
          promises.push(d);
          this.localAudioTrack = track;
          if (
            this.micMute ||
            (this.isConferenceCall && !this.amIInitiator) ||
            (!this.isConferenceCall && !this.state.user.userSettings.audioCallOn)
          ) {
            this.micMute = true;
            resetDeferredTimeout(d);
            track.mute().then(() => {
              // this.participantsMuted[this.room.myUserId()] = {
              //   muted: true,
              //   byAdmin: false,
              // };
              // this.room.sendTextMessage(
              //   JSON.stringify({
              //     cmd: "cmdUserMuted",
              //     value: this.participantsMuted,
              //   })
              // );
              invokeAudioAttach(track, d);
            }, d.reject);
          } else {
            invokeAudioAttach(track, d);
          }
        } else {
          console.warn(`failed to add local ${trackType} track: ${trackId}`);
          const p = track
            .dispose()
            .catch(
              console.warn.bind(
                console,
                `dispose ${trackType} track: ${trackId}`
              )
            );
          promises.push(p);
        }
      });
      if (this.allRemoteCameraOff) {
        this.participantsCameraOff[this.room.myUserId()] = {
          cameraOff: true,
        };
        this.room.sendTextMessage(
          JSON.stringify({
            cmd: "cmdParticipantsCameraOff",
            value: this.participantsCameraOff,
            allRemoteCameraOff: this.allRemoteCameraOff,
          })
        );
      }
      return Promise.allSettled(promises);
    },
    isWaitingRoomUser(uuid) {
      return isWaitingRoomUser(uuid);
    },
    isVisitor(uuid, skipDirectCallLink = false) {
      // Workaround to report direct call invite anonymous visitors as non-visitors to disable FacePay
      if (skipDirectCallLink && (store.state.group[uuid] || {}).directCallUUID)
        return false;
      // Default behaviour
      return isVisitor(uuid);
    },
    async rejectCall() {
      if (this.rejectingCall) return;
      this.rejectingCall = true;
      const timeout = setTimeout(() => (this.rejectingCall = false), 10000);
      try {
        if (this.removeCheckerInterval) {
          clearInterval(this.removeCheckerInterval);
          this.removeCheckerInterval = null;
        }
        const leavePromises = [];
        /** Custom awaiter for leave queue - Wait, log and clean leave promises */
        const processLeave = async (why = '') => {
          const donePromises = await Promise.allSettled(leavePromises); // Wait for unresolved promises to resolve
          if (store.state.nerd) donePromises.forEach(({status, value}, index) => (status === 'fulfilled' && console.debug(why, index, value)));
          donePromises.forEach(({status, reason}, index) => (status === 'rejected' && console.warn(why, index, reason)));
          leavePromises.splice(0, leavePromises.length); // Reset leave promises
        }
        // DISPOSE LOCAL VIDEO AND SCREEN SHARE RESOURCES
        if (!this.videoDisabled) {
          const promise = this.toggleVideo();
          leavePromises.push(promise);
        }
        if (this.shareScreenMode && this.getMyDesktopRoomUserID && this.currentDesktop === this.getMyDesktopRoomUserID) {
          const promise = this.stopShareScreen(this.getMyDesktopRoomUserID);
          leavePromises.push(promise);
        }
        if (leavePromises.length) {
          const promise = aDelay(100); // Introduce some fixed delay
          leavePromises.push(promise);
        }
        await processLeave('dispose local video and screen share resources');
        // DISPOSE ROOMS AND ASSOCIATED LOCAL TRACKS
        if (this.desktopRoom) {
          if (this.localDesktopPromise) {
            await this.localDesktopPromise.catch(
              console.warn.bind(console, "local desktop promise rejection")
            );
          }
          const desktopLeave = (async () => { // Promise of leave desktop room
            // Wait for pending stop share screen
            if (this.localDesktopDisposedPromise) await this.localDesktopDisposedPromise;
            const room = this.desktopRoom;
            const toRemove = room && room.getLocalTracks();
            if (room && room.isJoined()) await room.leave().catch(console.warn.bind(console, 'leave desktop room'));
            // All tracks must be disposed after leaving
            await parallel(1, toRemove || [], async (track) => {
              if (!track.conference && !track.disposed) {
                const trackString = track.toString();
                if (track.containers?.length) track.detach();
                if (track === this.localDesktopTrack) this.localDesktopTrack = null;
                await track.dispose().catch(console.warn.bind(console, 'dispose desktop room local track', trackString));
              }
            });
            if (room === this.desktopRoom) this.desktopRoom = null;
          })();
          leavePromises.push(desktopLeave);
        }
        if (this.room) {
          if (this.localAudioPromise) {
            await this.localAudioPromise.catch(
              console.warn.bind(console, "local audio promise rejection")
            );
          }
          if (this.localVideoPromise) {
            await this.localVideoPromise.catch(
              console.warn.bind(console, "local video promise rejection")
            );
          }
          const roomLeave = (async () => { // Promise of leave room
            const room = this.room;
            const toRemove = room && room.getLocalTracks();
            if (room && room.isJoined()) await room.leave().catch(console.warn.bind(console, 'leave room'));
            // All tracks must be disposed after leaving
            await parallel(1, toRemove || [], async (track) => {
              if (!track.conference && !track.disposed) {
                const trackString = track.toString();
                if (track.containers?.length) track.detach();
                if (track === this.localAudioTrack) this.localAudioTrack = null;
                if (track === this.localVideoTrack) this.localVideoTrack = null;
                await track.dispose().catch(console.warn.bind(console, 'dispose room local track', trackString));
              }
            });
            if (room === this.room) this.room = null;
          })();
          leavePromises.push(roomLeave);
        }
        await processLeave('dispose rooms and associated local tracks');
        // DISPOSE ALL LOCAL TRACKS
        if (this.localDesktopTrack) {
          const track = this.localDesktopTrack;
          track.detach();
          const promise = track.dispose()
            .catch(console.warn.bind(console, 'dispose local desktop track', track.toString()));
          leavePromises.push(promise);
          this.localDesktopTrack = null;
        }
        if (this.localAudioTrack) {
          const track = this.localAudioTrack;
          track.detach();
          const promise = track.dispose()
            .catch(console.warn.bind(console, 'dispose local audio track', track.toString()));
          leavePromises.push(promise);
          this.localAudioTrack = null;
        }
        if (this.localVideoTrack) {
          const track = this.localVideoTrack;
          track.detach();
          const promise = track.dispose()
            .catch(console.warn.bind(console, 'dispose local video track', track.toString()));
          leavePromises.push(promise);
          this.localVideoTrack = null;
        }
        await processLeave('dispose all local tracks');
        // DISPOSE ALL CONNECTIONS
        if (this.desktopConnection) {
          if (
            this.desktopConnection.xmpp &&
            this.desktopConnection.xmpp.connection &&
            !this.desktopConnection.xmpp.disconnectInProgress
          ) {
            const promise = this.desktopConnection.disconnect()
              .catch(console.warn.bind(console, "drop desktop connection"));
            leavePromises.push(promise);
            this.desktopConnection = null;
          } else {
            console.warn("desktop connection already dropped");
          }
        }
        if (this.connection) {
          if (
            this.connection.xmpp &&
            this.connection.xmpp.connection &&
            !this.connection.xmpp.disconnectInProgress
          ) {
            const promise = this.connection.disconnect()
              .catch(console.warn.bind(console, "drop connection"));
            leavePromises.push(promise);
            this.connection = null;
          } else {
            console.warn("connection already dropped");
          }
        }
        await processLeave('dispose all connections');
      } catch (error) {
        console.warn("reject call", error);
      } finally {
        if (!this.rejectedCall) {
          if (window.stream) {
            window.stream.getTracks().forEach((track) => {
              track.stop();
            });
          }
          const callUUID = this.getCallUUID;
          const callInfo = callUUID && this.state.remoteBridgeStreams[callUUID];
          if (callInfo) {
            const callingUser = callInfo.calling;
            if (callingUser && callingUser.length > 0) {
              callingUser.forEach((element) => {
                wsCall("sendToUUID", element, {
                  type: "bridge-signal",
                  action: "cancel_bridge_call",
                  sender: this.state.ownUUID,
                  info: {
                    callUUID: callInfo.callUUID,
                  },
                });
              });
            }
            if (!this.state.user.inBridgeCallListener || this.state.user.inBridgeCallListener !== callInfo.callUUID) {
              store.removeRemoteBridgeStreams(callInfo.callUUID);
            }
          }
          // Set the call end time for visitors in their store
          if (
            !this.getIsConferenceHall && (
              this.isVisitor(this.ownUUID) ||
              this.isWaitingRoomUser(this.ownUUID)
            )
          ) {
            store.setEndCallDateVisitor();
          }
          this.joinedCall = false;
          this.rejectedCall = true;
        }
      }
      clearTimeout(timeout);
      this.rejectingCall = false;
    },
    async toggleHangUp(participantId) {
      if (this.room) {
        if (this.room.getParticipantById(participantId).isModerator()) {
          await this.room.takeMod(participantId);
        }
        if (
          !this.currentDesktop &&
          participantId === this.presenterModeInitiatedBy
        ) {
          this.onPresenterModeCommand({ value: false });
          this.presenterMode = this.getIsConferenceHall ? true : false;
          this.wordGivenTo = null;
          this.presenterModeInitiatedBy = null;
          this.currentDesktop = null;
        }
        this.room.kickParticipant(participantId);
      }
    },
    changeSpeakerView(value) {
      this.room.sendTextMessage(
        JSON.stringify({
          cmd: "cmdChangeSpeakerView",
          value: value,
        })
      );
    },
    onChangeSpeakerView(value) {
      this.speakerView = value;
    },
    onDominantSpeakerChanged(participantId) {
      this.dominantSpeaker = participantId;
      // Prepared to place dominant speaker in the middle  when presentation Mode
      if (
        this.presenterMode &&
        !this.currentDesktop &&
        this.wordGivenTo !== participantId &&
        this.speakerView &&
        this.getAmIModerator
      ) {
        setTimeout(() => {
          this.toggleGiveWord(participantId);
        }, 2000);
      }
    },
    onUserJoined(participantId) {
      if (
        this.desktopRoom &&
        this.desktopRoom.myUserId() &&
        this.desktopRoom.myUserId() == participantId
      ) {
        this.currentDesktop = participantId;
        return;
      }
      const objParticipant = this.room.getParticipantById(participantId);
      const userData = objParticipant.getDisplayName();
      const personId = this.getUUIDForDisplayName(userData);
      this.currentDesktop =
        userData.split("_")[0] == "desktop"
          ? participantId
          : this.currentDesktop;
      const desktopOrUser = this.currentDesktop == participantId ? "desktop" : "user";
      console.log(`${userData == "recorder" ? "recorder" : desktopOrUser} joined:`, participantId);
      if (userData == "recorder") {
        this.recorderParticipantId = participantId;
      }
      const userIndex = this.remoteParticipants.findIndex(
        (e) => e.userUUID === personId
      );
      if (!this.room) return;
      if (
        userData != "recorder" &&
        userData.split("_")[0] != "desktop" &&
        userIndex === -1 &&
        participantId !== this.room.myUserId()
      ) {
        let obj = {
          userUUID: personId,
          participantId: participantId,
          audioTrack: null,
          videoTrack: null,
          videoTrackActive: false,
          videoTrackObj: null,
          audioTrackObj: null,
          calling: false,
        };
        this.remoteParticipants.push(obj);
      } else if (
        personId &&
        userData.split("_")[0] != "desktop" &&
        userIndex !== -1 &&
        participantId !== this.room.myUserId()
      ) {
        const participantObj = this.remoteParticipants.find(
          (e) => e.userUUID === personId
        );
        if (participantObj && participantObj.calling) {
          participantObj.participantId = participantId;
          participantObj.calling = false;
          this.remoteParticipants.splice(userIndex, 1, participantObj);
        }
      }
      if (personId && this.isConferenceCall) {
        if (this.getconfId) {
          let dataConference = null;
          const conferenceStore = this.getIsConferenceHall ? this.myConferenceHallDetails : this.myConferenceDetails;
            dataConference = conferenceStore.find(
              (e) => e.confId === this.getconfId
            )
          if (
            dataConference &&
            dataConference.confUUIDS.length > 0 &&
            dataConference.confUUIDS.indexOf(personId) === -1 &&
            (dataConference.confModerators.indexOf(this.state.ownUUID) !== -1 ||
              dataConference.confOwner == this.state.ownUUID)
          ) {
            dataConference.confUUIDS.push(personId);
            const memberObj = {
              name: this.state.group[personId].user.name,
              uuid: personId,
              isModerator: false,
              isGuest: this.state.group[personId].user.visitor,
              userFunction: this.state.group[personId].user.function,
              isGroup: false,
              isTeam: false,
            };
            dataConference.members.push(memberObj);
            //update conference with new members
            this.updateConference(dataConference);
            const newAttendees = this.getAttendeesObject(
              dataConference.confUUIDS
            );
            this.getConfOnlineUsers(dataConference, newAttendees);
          }
          if (
            dataConference &&
            dataConference.confModerators.length > 0 &&
            dataConference.confModerators.indexOf(personId) !== -1 &&
            !this.isUserModerator(participantId)
          ) {
            this.room.giveMod(participantId);
          }
        }
      } else if (personId) {
        this.updateDocuments(personId, false);
        if (
          this.room.isModerator() &&
          this.callInitiator &&
          store.state.group[store.state.ownUUID].beaconCallInvite
        ) {
          this.room.giveMod(participantId);
          this.room.takeMod();
        }
        //#3588 In a standard call (not conference) every user except guest is moderator
        if (
          this.hasPrivilege(personId) &&
          !store.state.group[store.state.ownUUID].beaconCallInvite &&
          !this.getIsConferenceHall
        ) {
          this.room.giveMod(participantId);
        }
      }
      if (this.getAmIModerator) {
        this.areAllMods();
      }
      if(this.getAmIModerator && this.allRemoteCameraOff){
        this.toggleCameraOff(participantId ,true)
      }
      // check if all participants are muted
      this.getIfAllParticipantsAreMuted()
      // if(this.getAmIModerator && this.allRemoteMicMuted){
      //   this.participantsMuted[participantId] = {
      //     muted: true,
      //     byAdmin: true,
      //   };
      //   this.checkAllRemoteMicMute()
      // }
    },
    // checkAllRemoteMicMute(){
    //   this.allRemoteMicMuted = true;
    //   for (const participant of this.room.getParticipants()) {
    //     if (participant.getDisplayName() == "recorder") continue;
    //     if (participant.getDisplayName().split("_")[0] == "desktop") continue;
    //     const participantId = participant.getId();
    //     if (
    //       this.participantsMuted &&
    //       !this.participantsMuted[participantId] &&
    //       !this.isUserModerator(participantId)
    //     ) {
    //       this.allRemoteMicMuted = false;
    //     }
    //   }
    //   this.room.sendTextMessage(
    //     JSON.stringify({
    //       cmd: "cmdParticipantsMuted",
    //       value: this.participantsMuted,
    //       allRemoteMicMuted: this.allRemoteMicMuted
    //     })
    //   );
    // },
    getConfOnlineUsers(entry, newAttendees) {
      let usernames = [];
      if (entry && entry.confUUIDS) {
        entry.confUUIDS.forEach((uuid) => {
          const username =
            ((store.state.group[uuid] || {}).user || {}).name || "";
          if (username && username !== "") {
            let data = { username: username, uuid: uuid };
            usernames.push(data);
          }
        });
        usernames.forEach((name) => {
          let isOnline = false;
          if (this.bridgeInfoStore && this.bridgeInfoStore.calls) {
            Object.keys(this.bridgeInfoStore.calls).forEach((call) => {
              if (this.bridgeInfoStore.calls[call].confId === entry.confId) {
                isOnline = this.bridgeInfoStore.users[name.uuid] || false;
              }
            });
          }
          if (isOnline) {
            wsCall("sendToUUID", name.uuid, {
              type: "bridge-signal",
              action: "updateConferenceAttendees",
              sender: this.state.ownUUID,
              info: {
                callUUID: this.getCallUUID,
                confAttendees: newAttendees,
              },
            });
          }
        });
      }
    },
    getAttendeesObject(uuids) {
      let myMembers = [];
      uuids.forEach((uuid) => {
        if (uuid) {
          const aMember = {
            uuid,
            name: store.getNameForUuid(uuid),
            addUserToCall: true,
            showUserName: true,
          };
          myMembers.push(aMember);
        }
      });
      return myMembers;
    },
    async updateConference(confObj) {
      await updateGroupConferenceEvent(confObj);
      confObj.isFromConf = true;
      this.doUpdateDocumentStore(confObj);
    },
    doUpdateDocumentStore(confObject) {
      const foundStore = this.myDocuments.find(
        (e) => e.confId === confObject.confId
      );
      if (foundStore && foundStore.confId) {
        foundStore.isFromConf = true;
        foundStore.docMembersUUIDS = confObject.confUUIDS;
        foundStore.moderators = confObject.confModerators;
        updateCompanyDocumentEvent(foundStore);
      }
    },
    onKicked(participant) {
      const participantId = participant.getId();
      console.log("kicked by:", participantId);
      if (this.room && participantId !== this.room.myUserId()) {
        return this.rejectCall();
      }
    },
    onUserLeft(participantId) {
      const desktopOrUser = this.currentDesktop == participantId ? "desktop" : "user";
      console.log(`${participantId == this.recorderParticipantId ? "recorder" : desktopOrUser} left:`, participantId);
      if (!this.room) return;
      if (this.currentDesktop == participantId) {
        this.stopShareScreen(participantId);
      }
      if (
        !this.currentDesktop &&
        participantId === this.presenterModeInitiatedBy
      ) {
        this.togglePresentation();
      }
      if (participantId == this.recorderParticipantId) {
        this.recorderParticipantId = null;
      }
      // check is onlyguest
      let nonGuestInCall = false;
      for (const participant of this.room.getParticipants()) {
        const userData = participant.getDisplayName();
        const personId = this.getUUIDForDisplayName(userData);
        if (this.hasPrivilege(personId)) {
          nonGuestInCall = true;
        }
      }
      if (this.hasPrivilege(this.state.ownUUID)) nonGuestInCall = true;
      const userIndex = this.remoteParticipants.findIndex(
        (e) => e.participantId === participantId
      );
      if (userIndex !== -1) {
        // this.cleanConferenceHallArrays(participantId);
        const userUUIDLeft = this.remoteParticipants[userIndex].userUUID;
        this.remoteParticipants.splice(userIndex, 1);
        if (this.isConferenceCall) {
          if (!this.getIsConferenceHall) {
            if (this.remoteParticipants.length === 0) {
              if (this.amIInitiator && !this.rejectingCall) this.showCloseFinishCallModal(true);
              if (!this.amIInitiator) return this.rejectCall();
            }
          } else if (this.callInitiator == userUUIDLeft) {
            // this.getIsConferenceHall
            // user that leaves the call is the creator of the conference
            return this.rejectCall()
          }
        } else if (this.remoteParticipants.length === 0 || !nonGuestInCall) {
          return this.rejectCall();
        }
      }
      if (this.getAmIModerator) {
        // always presentermode in conferencehall
        setTimeout(() => {
          if (this.getIsConferenceHall && !this.presenterMode && !this.presenterModeInitiatedBy) {
            this.togglePresentation();
          }
        }, 1000);
        this.areAllMods();
      }
      if (!this.rejectingCall) {
        this.updateHqTracks();
      }
      this.cleanParticipantMutedLogic(participantId);
    },
    async onConferenceJoined() {
      const myParticipantId = this.room?.myUserId();
      console.log("joined room as:", myParticipantId);
      if (!myParticipantId || this.rejectingCall || this.rejectedCall) return;
      this.room.on(
        JitsiMeetJS.events.conference.TRACK_ADDED,
        this.onRemoteTrackAdd
      );
      this.currentCallingState = [];
      for (const uuid of this.amICalling) {
        const userIndex = this.remoteParticipants.findIndex(
          (e) => e.userUUID === uuid
        );
        if (userIndex == -1) {
          if(this.getIsConferenceHall){
            if(this.getIsParticipantIsPodium(uuid)){
              let obj = {
              userUUID: uuid,
              participantId: null,
              audioTrack: null,
              videoTrack: null,
              videoTrackActive: false,
              videoTrackObj: null,
              audioTrackObj: null,
              calling: true,
              };
              this.remoteParticipants.push(obj);
            }
          }else{
            let obj = {
              userUUID: uuid,
              participantId: null,
              audioTrack: null,
              videoTrack: null,
              videoTrackActive: false,
              videoTrackObj: null,
              audioTrackObj: null,
              calling: true,
            };
            this.remoteParticipants.push(obj);
          }
        }
        this.currentCallingState.push(uuid);
      }
      if (this.getIsAudioOnly) {
        this.videoDisabled = true;
        const devices = await navigator.mediaDevices
          .enumerateDevices("audio")
          .then((devices) => {
            let audioInput;
            if (this.audioDeviceId) {
              audioInput = devices.find(
                (d) => d.kind === "audioinput" && d.deviceId === this.audioDeviceId
              );
            }
            // should try for "communications", then "default" then 1st element existing
            if (!audioInput) {
              audioInput = devices.find(
                (d) => d.kind === "audioinput" && d.deviceId === "communications"
              );
            }
            if (!audioInput) {
              audioInput = devices.find(
                (d) => d.kind === "audioinput" && d.deviceId === "default"
              );
            }
            if (!audioInput) {
              audioInput = devices.find(
                (d) => d.kind === "audioinput"
              );
            }
            return {
              audioInputId: (audioInput || {}).deviceId,
            };
          });
        console.log(`selected mic: ${devices.audioInputId}`);
        if (devices.audioInputId) {
          await this.createLocalTracks(
            {
              devices: ["audio"],
              micDeviceId: devices.audioInputId,
            },
            this.onLocalTracks.bind(this)
          );
        } else {
          await this.createLocalTracks(
            {
              devices: ["audio"],
            },
            this.onLocalTracks.bind(this)
          );
        }
      } else {
        const devices = await navigator.mediaDevices
          .enumerateDevices("audio", "video")
          .then((devices) => {
            let audioInput;
            if (this.audioDeviceId) {
              audioInput = devices.find(
                (d) => d.kind === "audioinput" && d.deviceId === this.audioDeviceId
              );
            }
            // should try for "communications", then "default" then 1st element existing
            if (!audioInput) {
              audioInput = devices.find(
                (d) => d.kind === "audioinput" && d.deviceId === "communications"
              );
            }
            if (!audioInput) {
              audioInput = devices.find(
                (d) => d.kind === "audioinput" && d.deviceId === "default"
              );
            }
            if (!audioInput) {
              audioInput = devices.find(
                (d) => d.kind === "audioinput"
              );
            }
            let videoInput;
            if (this.videoDeviceId) {
              videoInput = devices.find(
                (d) => d.kind === "videoinput" && d.deviceId === this.videoDeviceId
              );
            }
            if (!videoInput) {
              videoInput = devices.find(
                (d) => d.kind === "videoinput"
              );
            }
            return {
              audioInputId: (audioInput || {}).deviceId,
              videoInputId: (videoInput || {}).deviceId,
            };
          });
        console.log(`selected mic: ${devices.audioInputId}`);
        console.log(`selected cam: ${devices.videoInputId}`);
        if (devices.audioInputId && devices.videoInputId) {
          await this.createLocalTracks(
            {
              devices: ["audio", "video"],
              micDeviceId: devices.audioInputId,
              cameraDeviceId: devices.videoInputId,
            },
            this.onLocalTracks.bind(this)
          );
        } else if (devices.audioInputId) {
          this.videoDisabled = true;
          await this.createLocalTracks(
            {
              devices: ["audio"],
              micDeviceId: devices.audioInputId,
            },
            this.onLocalTracks.bind(this)
          );
        } else {
          await this.createLocalTracks(
            {
              devices: ["audio", "video"],
            },
            this.onLocalTracks.bind(this)
          );
        }
      }
      if (!this.room || this.rejectingCall) {
        return;
      }
      if (this.room.isModerator() && !this.hasPrivilege(this.ownUUID)) {
        for (const participant of this.room.getParticipants()) {
          const userData = participant.getDisplayName();
          const personId = this.getUUIDForDisplayName(userData);
          if (this.hasPrivilege(personId)) {
            this.dropModOnNewMod = true;
            this.room.giveMod(participant.getId());
          }
        }
      }
      this.joinedCall = true;
      if (this.removeCheckerInterval) {
        clearInterval(this.removeCheckerInterval);
      }
      this.removeCheckerInterval = setInterval(this.removeChecker, 2000);
      if (this.amIInitiator && this.getIsConferenceHall) {
        const conferenceData = this.myConferenceHallDetails.find(
          (e) => e.confId === this.getconfId
        );
        if (conferenceData) {
          const liveStreamURL = conferenceData.liveStreamURL || '';
          syncedUserState(() => bridgeCallStartStreaming(liveStreamURL))
            .then((result) => {
              if (result.status != 200) {
                console.warn("conference hall streaming failed", result.text);
              }
            })
            .catch((err) => {
              console.warn("conference hall streaming error", err);
            });
        } else {
          console.warn("conference hall streaming missing data");
        }
      }
    },
    async onConnectionSuccess(callUUID) {
      this.callUUID = callUUID;
      /* const isChromiumBased = Boolean(
        // https://stackoverflow.com/questions/57660234/how-can-i-check-if-a-browser-is-chromium-based
        // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData
        window.chrome || (navigator.userAgentData &&
          navigator.userAgentData.brands &&
          navigator.userAgentData.brands.some(data => data.brand == 'Chromium'))
      ); */
      const confOptions = {
        forceTurnRelay: store.state.nerd && store.state.user.individualRelay,
        p2p: {
          enabled: false, // isChromiumBased && !(this.getIsConferenceHall || store.state.namespaceSettings.activateE2E),
          // forceTurnRelay: store.state.nerd && store.state.user.individualRelay,
        },
      };
      console.log("xmpp connected:", callUUID, JSON.stringify(confOptions));
      const room = this.connection.initJitsiConference(
        callUUID.toLowerCase(),
        confOptions
      );
      room.setSenderVideoConstraint(720);
      room.setReceiverVideoConstraint(1080);
      if (!this.getIsConferenceHall && !(store.state.namespaceSettings.activateE2E == false)) {
        room.setLocalParticipantProperty("e2e_support", room.isE2EESupported());
        await room.toggleE2EE(true);
        this.e2e_active = true;
        console.log("e2ee activated");
      }
      const userName =
        this.getTitelForUuid(this.ownUUID) == "" ||
        this.getTitelForUuid(this.ownUUID) == "unknown"
          ? this.getVisitorFunctionForUuid(this.ownUUID) !== ""
            ? this.getNameForUuid(this.ownUUID) +
              " " +
              this.getVisitorFunctionForUuid(this.ownUUID)
            : this.getNameForUuid(this.ownUUID)
          : this.getTitelForUuid(this.ownUUID) +
            " " +
            this.getNameForUuid(this.ownUUID);
      room.setDisplayName(
        this.ownUUID == "recorder"
          ? "recorder"
          : "regular_" +
              this.ownUUID +
              `_${JSON.stringify([
                this.getAvatarForUuid(this.ownUUID),
                userName,
              ])}`
      );
      room.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft);
      room.on(
        JitsiMeetJS.events.conference.CONFERENCE_JOINED,
        this.onConferenceJoined
      );
      room.on(JitsiMeetJS.events.conference.KICKED, this.onKicked);
      room.on(JitsiMeetJS.events.conference.USER_JOINED, this.onUserJoined);
      room.on(
        JitsiMeetJS.events.conference.TRACK_REMOVED,
        this.onRemoteTrackRemove
      );
      room.on(
        JitsiMeetJS.events.conference.USER_ROLE_CHANGED,
        this.onUserRoleChanged
      );
      room.on(
        JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
        this.onTrackAudioLevelChanged
      );
      room.on(JitsiMeetJS.events.conference.CONFERENCE_FAILED, (err) => {
        if (err == JitsiMeetJS.errors.conference.AUTHENTICATION_REQUIRED) {
        }
        if (err == JitsiMeetJS.errors.conference.OFFER_ANSWER_FAILED) {
        }
        if (err == JitsiMeetJS.errors.conference.PASSWORD_REQUIRED) {
        }
      });
      room.on(
        JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED,
        this.onDominantSpeakerChanged
      );
      room.on(
        JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED,
        this.localStatsUpdated
      );
      room.on(
        JitsiMeetJS.events.conference.CONNECTION_INTERRUPTED,
        this.rejectCallUserConnectionInterrupted
      );
      const mma = this.onMicMutedByAdmin;
      const pmc = this.onPresenterModeCommand;
      const whc = this.onWaveHandCommand;
      const gwc = this.onGiveWordCommand;
      const pdf = this.onParticipantDeaf;
      const scd =
        this.ownUUID == "recorder" ? () => {} : this.setCallDuration;
      const rjc = this.rejectCall;
      const pll = this.onPleaseLeave;
      const csw = this.onChangeSpeakerView;
      const pco = this.onParticipantsCameraOffCommand;
      room.on(
        JitsiMeetJS.events.conference.MESSAGE_RECEIVED,
        function (id, msg, ts) {
          const message = JSON.parse(msg);
          // if (message.cmd == "cmdUserMuted") {
          //   usm(message.value);
          // } else
          if (message.cmd == "cmdPresenterMode") {
            pmc({
              value: message.value,
              initiator: message.initiator,
            });
          }
          // else if (message.cmd == "cmdParticipantsMuted") {
          //   pmu(message.value, message.allRemoteMicMuted);
          // }
          else if (message.cmd == "cmdParticipantDeaf") {
            pdf(message.participant, message.value);
          } else if (message.cmd == "cmdCurrentTime") {
            scd(callUUID, message.value, message.users);
          } else if (message.cmd == "cmdEndCall") {
            rjc();
          } else if (message.cmd == "cmdWaveHand") {
            whc(message.user, message.value);
          } else if (message.cmd == "cmdLeave") {
            pll(message.user);
          } else if (message.cmd == "cmdGiveWord") {
            gwc(message.user);
          } else if (message.cmd === "cmdChangeSpeakerView") {
            csw(message.value);
          } else if (message.cmd == "cmdParticipantsCameraOff") {
            pco(message.value, message.allRemoteCameraOff);
          } else if (message.cmd == "cmdMicMutedByAdmin") {
            mma(message.value);
          }
        }
      );
      this.room = room;
      room.join();
      if (this.isConferenceCall) {
        let conferenceId = this.getconfId;
        if (conferenceId) {
          let dataConference = null;
          dataConference = this.myConferenceDetails.find(
            (e) => e.confId === conferenceId
          );
          if (
            dataConference &&
            dataConference.confOwner == this.state.ownUUID &&
            !dataConference.callUUID
          ) {
            dataConference.callUUID = this.getCallUUID;
            this.updateConference(dataConference);
          }
        }
      }
    },
    onPleaseLeave(userid) {
      if (this.room && userid == this.room.myUserId()) {
        return this.rejectCall();
      }
    },
    async onDesktopConnectionSuccess(callUUID) {
      const confOptions = {
        disableSimulcast: true, // https://github.com/jitsi/lib-jitsi-meet/issues/2264
        forceTurnRelay: store.state.nerd && store.state.user.individualRelay,
        p2p: {
          enabled: false,
        },
      };
      console.log("desktop xmpp connected:", callUUID, JSON.stringify(confOptions));
      const room = this.desktopConnection.initJitsiConference(
        callUUID.toLowerCase(),
        confOptions
      );
      room.setSenderVideoConstraint(1080);
      if (!(store.state.namespaceSettings.activateE2E == false)) {
        if (this.e2e_active) {
          await room.toggleE2EE(true);
          console.log("desktop e2ee activated");
          room.setLocalParticipantProperty("e2e_support", true);
        } else {
          room.setLocalParticipantProperty("no_e2e_support", true);
        }
      } else {
        room.setLocalParticipantProperty("no_e2e_support", true);
      }
      room.setDisplayName(
        "desktop_" +
          this.ownUUID +
          (this.ownUUID == "recorder"
            ? ""
            : `_${JSON.stringify([
                this.getAvatarForUuid(this.ownUUID),
                this.getTitelForUuid(this.ownUUID) == "" ||
                this.getTitelForUuid(this.ownUUID) == "unknown"
                  ? this.getVisitorFunctionForUuid(this.ownUUID) !== ""
                    ? this.getNameForUuid(this.ownUUID) +
                      " " +
                      this.getVisitorFunctionForUuid(this.ownUUID)
                    : this.getNameForUuid(this.ownUUID)
                  : this.getTitelForUuid(this.ownUUID) +
                    " " +
                    this.getNameForUuid(this.ownUUID),
              ])}`)
      );
      room.on(
        JitsiMeetJS.events.conference.CONFERENCE_FAILED,
        () => this.stopShareScreen()
      );
      room.on(
        JitsiMeetJS.events.conference.CONFERENCE_JOINED,
        () => this.onDesktopConferenceJoined()
      );
      this.desktopRoom = room;
      room.join();
    },
    stopPresenterMode() {
      if (this.presenterMode && this.implicitPresenterMode == true) {
        this.implicitPresenterMode = false;
        this.room.sendTextMessage(
          JSON.stringify({
            cmd: "cmdPresenterMode",
            value: this.presenterMode,
            initiator: null,
          })
        );
        this.onPresenterModeCommand({ value: false });
      }
    },
    async onDesktopConferenceJoined() {
      if (!this.room) return;
      // this.$nextTick(() => {
      this._delayUpdateHqTracks = true;
      // this.room?.isP2PActive() && this.room?.stopP2PSession();
      // });
      console.log("joined desktop room as:", this.getMyDesktopRoomUserID);
      if (!this.getMyDesktopRoomUserID) return;
      if (!this.presenterMode) {
        this.implicitPresenterMode = true;
        this.room.sendTextMessage(
          JSON.stringify({
            cmd: "cmdPresenterMode",
            value: true, //this.presenterMode,
            initiator: this.room.myUserId(),
          })
        );
      }
      if (!this.localDesktopMediaPromise) {
        this.localDesktopMediaPromise = JitsiMeetJS.createLocalTracks({
          devices: ["desktop"],
        });
      }
      this.localDesktopMediaPromise
        .then((tracks) => {
          return this.onLocalDesktopTracks(tracks);
        })
        .catch((err) => {
          console.warn("desktop conference joined", err);
          this.toggleShareScreen();
        });
    },
    onDesktopTrackStopped(track) {
      if (this.shareScreenMode == 1) {
        this.toggleShareScreen();
      }
      track?.off(LOCAL_TRACK_STOPPED, this.onDesktopTrackStopped);
    },
    onLocalDesktopTracks(tracks) {
      if (!this.room || !this.desktopRoom || this.rejectingCall) {
        let videoTrack = undefined;
        tracks.forEach((track) => {
          const trackId = track.getId();
          const trackType = track.getType();
          track.dispose().catch(console.warn.bind(console, `dispose desktop ${trackType} track: ${trackId}`));
          if (trackType === "video") videoTrack = track;
        });
        return this.onDesktopTrackStopped(videoTrack);
      }
      const resetDeferredTimeout = (d) => {
        d.clearRejectTimeout();
        d.setRejectTimeout(15000); // timeout in 15 seconds to avoid things getting stuck
      };
      const d = (this.localDesktopPromise = new Deferred());
      tracks.forEach((track) => {
        const trackId = track.getId();
        const trackType = track.getType();
        resetDeferredTimeout(d);
        if (this.desktopRoom && trackType === "audio") {
          track.on(
            LOCAL_TRACK_STOPPED,
            this.onDesktopTrackStopped
          );
          this.desktopRoom
            .addTrack(track)
            .then(() => {
              console.log(
                "local desktop " + trackType + " track added: " + trackId
              );
            })
            .catch((err) => {
              console.warn(
                `failed to add local desktop ${trackType} track: ${trackId}`,
                err
              );
              track
                .dispose()
                .catch(
                  console.warn.bind(
                    console,
                    `dispose desktop ${trackType} track: ${trackId}`
                  )
                );
            });
        } else if (
          this.desktopRoom &&
          this.localDesktopTrack === null &&
          trackType === "video"
        ) {
          track.on(
            LOCAL_TRACK_STOPPED,
            this.onDesktopTrackStopped
          );
          this.localDesktopTrack = track;
          track.attach(this.$refs.localVideoShareScreen);
          this.classChooseShareScreen = null;
          this.desktopRoom
            .addTrack(track)
            .then(() => {
              console.log(
                "local desktop " + trackType + " track added: " + trackId
              );
              d.resolve(track);
            })
            .catch((err) => {
              console.warn(
                `failed to add local desktop ${trackType} track: ${trackId}`,
                err
              );
              if (track === this.localDesktopTrack) {
                this.localDesktopTrack = null;
              }
              track
                .dispose()
                .catch(
                  console.warn.bind(
                    console,
                    `dispose desktop ${trackType} track: ${trackId}`
                  )
                );
              d.reject(err);
            });
          this.bridgeCallScreenShareState(true);
        } else {
          console.warn(
            `failed to add local desktop ${trackType} track: ${trackId}`
          );
          track
            .dispose()
            .catch(
              console.warn.bind(
                console,
                `dispose desktop ${trackType} track: ${trackId}`
              )
            );
          d.reject();
        }
      });
      return d;
    },
    // updateDevices(devices) {
    //   var videoDevices = {};
    //   var audioDevices = {};
    //   for (const device of devices) {
    //     if (device.kind == "videoinput") {
    //       videoDevices[device.deviceId] = device;
    //     } else if (device.kind == "audioinput") {
    //       audioDevices[device.deviceId] = device;
    //     }
    //   }
    //   this.videoDevices = videoDevices;
    //   this.audioDevices = audioDevices;
    // },
    onRemoteTrackAdd(track) {
      if (!this.room || track.isLocal()) return;
      const participantId = track.getParticipantId();
      const trackId = track.getId();
      const trackType = track.getType();
      console.log(
        `remote ${track.isP2P ? "p2p " : ""}` + (track.videoType ? `${track.videoType} ` : "") + trackType + " track added for " + participantId
      );
      if (
        this.desktopRoom &&
        this.desktopRoom.myUserId() &&
        this.desktopRoom.myUserId() == participantId
      ) {
        return;
      }
      const objParticipant = this.room.getParticipantById(participantId);
      if (!objParticipant._properties["features_e2ee"]) {
        if (this.e2e_active) {
          this.room.toggleE2EE(false);
          if (this.desktopRoom) this.desktopRoom.toggleE2EE(false);
          this.e2e_active = false;
          console.log("disable e2ee");
        }
      }
      const participantObj = this.remoteParticipants.find(
        (e) => e.participantId == participantId
      );
      if (trackType === "video") {
        if (participantObj && (track.videoType == undefined || track.videoType == "camera")) {
          if (
            !this.currentDesktop ||
            (this.currentDesktop && this.currentDesktop != participantId)
          ) {
            participantObj.videoTrack = trackId;
            participantObj.videoTrackActive = !track.isMuted();
            participantObj.videoTrackObj = track;
            const participantIndex = this.remoteParticipants.findIndex(
              (e) => e.participantId == participantId
            );
            this.remoteParticipants.splice(participantIndex, 1, participantObj);
          }
        }
      } else if (trackType === "audio") {
        if (participantObj) {
          participantObj.audioTrack = trackId;
          participantObj.audioTrackObj = track;
          const participantIndex = this.remoteParticipants.findIndex(
            (e) => e.participantId == participantId
          );
          this.remoteParticipants.splice(participantIndex, 1, participantObj);
        }
        track.addEventListener(
          TRACK_AUDIO_LEVEL_CHANGED,
          this.onAudioLevelChanged
        );
      }
      track.addEventListener(
        TRACK_MUTE_CHANGED,
        this.onRemoteTrackMuted
      );
      this.$nextTick(() => {
        if (
          (this.currentDesktop && track.videoType == "desktop") ||
          (this.currentDesktop && this.currentDesktop == participantId)
        ) {
          track.attach(
            trackType === "audio"
              ? this.$refs.remoteAudioShareScreen
              : this.$refs.localVideoShareScreen
          );
          console.log(
            `remote ${track.isP2P ? "p2p " : ""}` + (track.videoType ? `${track.videoType} ` : "") + trackType + " track attached for " + participantId
          );
          if (trackType === "audio" && this.audioOutputMuted) {
            this.$refs.remoteAudioShareScreen.muted = this.audioOutputMuted;
          }
          this.classChooseShareScreen = null;
        } else if (participantObj && (
          (trackType === "video" && participantObj.videoTrackObj === track) ||
          (trackType === "audio" && participantObj.audioTrackObj === track)) &&
          (this.$refs[trackId] || [])[0]
        ) {
          if (trackType === "video") {
            this.remoteVideoTrackCheckAttach(track, 'add', (this.$refs[trackId] || [])[0]);
          } else {
            track.attach(this.$refs[trackId][0]);
            console.log(
              `remote ${track.isP2P ? "p2p " : ""}` + (track.videoType ? `${track.videoType} ` : "") + trackType + " track attached for " + participantId
            );
          }
          if (trackType === "audio" && this.audioOutputMuted) {
            this.$refs[trackId][0].muted = this.audioOutputMuted;
          }
        } else {
          console.warn(
            `remote ${track.isP2P ? "p2p " : ""}` + (track.videoType ? `${track.videoType} ` : "") + trackType + " track attach failed for " + participantId
          );
        }
        if (!track.isP2P && track.containers?.length && trackType === "video") {
          this.updateHqTracks();
        }
      });
      if (trackType === 'audio') {
        this.addRemoveParticipantMuted(participantId, track.isMuted())
      }
    },
    onUserRoleChanged(participantId, role) {
      console.log(`role of ${participantId} is now:`, role);
      if (!participantId) return;
      this.hideRemoteBar(); //force refresh bar
      if (!this.room) return;
      if (
        role == "moderator" &&
        this.room.isModerator() &&
        this.dropModOnNewMod == true
      ) {
        this.room.takeMod();
        this.dropModOnNewMod = false;
      }
      const participantObj = this.remoteParticipants.find(
        (e) => e.participantId == participantId
      );
      if (role === "moderator" && participantObj && !this.isConferenceCall) {
        this.updateDocuments(participantObj.userUUID, true);
      }
      if (
        participantId == this.room.myUserId() &&
        role == "moderator" &&
        !this.hasPrivilege(this.ownUUID)
      ) {
        var onlyGuests = true;
        for (const participant of this.room.getParticipants()) {
          const userData = participant.getDisplayName();
          const personId = this.getUUIDForDisplayName(userData);
          if (this.hasPrivilege(personId)) {
            onlyGuests = false;
          }
        }
        if (
          onlyGuests &&
          !store.state.group[store.state.ownUUID].beaconCallInvite
        ) {
          return this.rejectCall();
        } else {
          for (const participant of this.room.getParticipants()) {
            const userData = participant.getDisplayName();
            const personId = this.getUUIDForDisplayName(userData);
            if (this.hasPrivilege(personId)) {
              this.dropModOnNewMod = true;
              this.room.giveMod(participant.getId());
            }
          }
        }
      }
      if (this.getAmIModerator) {
        // always presentermode in conferencehall
        if(this.getIsConferenceHall && !this.presenterMode &&  !this.presenterModeInitiatedBy){
          this.togglePresentation();
        }
        this.areAllMods();
        if(this.participantsCameraOff[this.room.myUserId()] && this.participantsCameraOff[this.room.myUserId()].cameraOff == true){
          this.checkAllRemoteCameraOff()
        }
        // check if the new moderator was muted by another mod, if is true remove it from the array to disable the mic button
        const userIsMutedByAdmin = this.micMutedByAdmin.indexOf(participantId) !==-1 ? true : false ;
        if (userIsMutedByAdmin && participantId == this.room.myUserId()) this.toggleRemoteMic({participantId});
      }
    },
    onRemoteTrackRemove(track) {
      if (!this.room || track.isLocal()) return;
      const participantId = track.getParticipantId();
      const trackType = track.getType();
      console.log(
        `remote ${track.isP2P ? "p2p " : ""}` + (track.videoType ? `${track.videoType} ` : "") + trackType + " track removed for " + participantId
      );
      const participantObj = this.remoteParticipants.find(
        (e) => e.participantId == participantId
      );
      if (trackType === "video") {
        if (participantObj) {
          participantObj.videoTrack = null;
          participantObj.videoTrackActive = false;
          participantObj.videoTrackObj = null;
          const participantIndex = this.remoteParticipants.findIndex(
            (e) => e.participantId == participantId
          );
          this.remoteParticipants.splice(participantIndex, 1, participantObj);
        }
        const isInBadQuality = this.badQualityUser.indexOf(participantId);
        if (isInBadQuality !== -1) {
          this.badQualityUser.splice(isInBadQuality, 1);
        }
      } else if (trackType === "audio") {
        if (participantObj) {
          participantObj.audioTrack = null;
          participantObj.audioTrackObj = null;
          const participantIndex = this.remoteParticipants.findIndex(
            (e) => e.participantId == participantId
          );
          this.remoteParticipants.splice(participantIndex, 1, participantObj);
        }
        track.removeEventListener(
          TRACK_AUDIO_LEVEL_CHANGED,
          this.onAudioLevelChanged
        );
      }
      track.removeEventListener(
        TRACK_MUTE_CHANGED,
        this.onRemoteTrackMuted
      );
      track.detach();
    },
    getRegularStyle(originalIndex, participant = false) {
      if (!this.room) return;
      let totalRemoteParticipants = this.getActiveUsers;
      let index = originalIndex;
      if (participant) {
        index += 2;
      }
      // // add blur participant 3
      // if (participant) {
      //   const participantId = participant.participantId;
      //   const parentElement = document.getElementById(participantId);
      //   if (
      //     totalRemoteParticipants == 3 &&
      //     index == 3 &&
      //     !this.currentDesktop &&
      //     !this.presenterModeInitiatedBy
      //   ) {
      //     if (parentElement) {
      //       const video = parentElement.getElementsByTagName("video")[0];
      //       if (video) {
      //         const canvas = document.createElement("canvas");
      //         // scale the canvas accordingly
      //         canvas.width = video.videoWidth;
      //         canvas.height = video.videoHeight;
      //         // draw the video at that frame
      //         canvas
      //           .getContext("2d")
      //           .drawImage(video, 0, 0, canvas.width, canvas.height);
      //         // convert it to a usable data URL
      //         const dataURL = canvas.toDataURL();
      //         const images =
      //           parentElement.getElementsByClassName("videoBgImage");
      //         if (images.length > 0) {
      //           images.forEach((img) => {
      //             img.remove();
      //           });
      //         }
      //         const parentImage = document.createElement("img");
      //         parentImage.src = dataURL;
      //         parentImage.classList.add("videoBgImage");
      //         parentElement.appendChild(parentImage);
      //       }
      //     }
      //   } else {
      //     if (parentElement) {
      //       const images = parentElement.getElementsByClassName("videoBgImage");
      //       if (images.length > 0) {
      //         images.forEach((img) => {
      //           img.remove();
      //         });
      //       }
      //     }
      //   }
      // }
      // end add blur participant 3
      switch (totalRemoteParticipants) {
        case 1:
          return `span-col-12 span-row-12 order${index}`;
        case 2:
        case 4:
          return `span-col-6 span-row-6 order${index}`;
        case 3:
          if (index >= 3) {
            return `span-col-12 span-row-6 order${index}`;
          }
          return `span-col-6 span-row-6 order${index}`;
        case 5:
          if (index > 3) {
            return `span-col-6 span-row-6 order${index}`;
          }
          return `span-col-4 span-row-6 order${index}`;
        case 6:
          return `span-col-4 span-row-6 order${index}`;
        case 7:
          if (index > 4) {
            return `span-col-4 span-row-6 order${index}`;
          }
          return `span-col-3 span-row-6 order${index}`;
        case 8:
          return `span-col-3 span-row-6 order${index}`;
        case 9:
          return `span-col-4 span-row-4 order${index}`;
        case 10:
          return `span-col-2 span-row-5 order${index}`;
        case 11:
          if (index > 8) {
            return `span-col-4 span-row-4 order${index}`;
          }
          return `span-col-3 span-row-4 order${index}`;
        case 12:
          return `span-col-3 span-row-4 order${index}`;
        case 13:
          if (index > 5) {
            return `span-col-15 span-row-20 order${index}`;
          }
          return `span-col-12 span-row-20 order${index}`;
        case 14:
          if (index > 10) {
            return `span-col-15 span-row-20 order${index}`;
          }
          return `span-col-12 span-row-20 order${index}`;
        case 15:
          return `span-col-3 span-row-5 order${index}`;
        case 16:
          return `span-col-2 span-row-2 order${index}`;
        case 17:
          if (index > 5) {
            return `span-col-5 span-row-5 order${index}`;
          }
          return `span-col-4 span-row-5 order${index}`;
        case 18:
          if (index > 10) {
            return `span-col-5 span-row-4 order${index}`;
          }
          return `span-col-4 span-row-5 order${index}`;
        case 19:
          if (index > 15) {
            return `span-col-5 span-row-5 order${index}`;
          }
          return `span-col-4 span-row-5 order${index}`;
        case 20:
          return `span-col-4 span-row-5 order${index}`;
        default:
          break;
      }
    },
    // getConferenceHallStyle(originalIndex, participantId = false, userUUID=false) {
    //   if (!this.room) return;
    //   let totalRemoteParticipants = this.getActiveUsers;
    //   if (this.getIsConferenceHall) {
    //     if(this.getIsParticipantIsPodium(this.ownUUID)){
    //       totalRemoteParticipants = (this.getPodiumArray.length + 1);
    //     }else{
    //       totalRemoteParticipants = this.getPodiumArray.length;
    //     }
    //   }
    //   // do a return empty order for listener
    //   if((!userUUID &&  !this.getIsParticipantIsPodium(this.ownUUID)) || (userUUID && !this.getIsParticipantIsPodium(userUUID)) ){
    //     return "";
    //   }
    //   // find index for podiumParticipant
    //   // let podiumIndex= null
    //   let index = originalIndex;
    //   // if(userUUID){
    //   //   podiumIndex = this.getPodiumArray.indexOf(userUUID)
    //   // }
    //   // //re asign index for podium
    //   // if(podiumIndex && podiumIndex !==-1){
    //   //   // if(!this.getIsParticipantIsPodium(this.ownUUID)){
    //   //   //   index = (podiumIndex+1)
    //   //   // }else{
    //   //   //   index = (podiumIndex + 2)
    //   //   // }
    //   //    index = (podiumIndex + 2)
    //   // }
    //   if (
    //     !this.currentDesktop &&
    //     ((originalIndex === 1 &&
    //       this.presenterModeInitiatedBy == this.room.myUserId() &&
    //       !participantId) ||
    //       (participantId && participantId === this.presenterModeInitiatedBy))
    //   ) {
    //     this.remoteSpeakerIndex = originalIndex;
    //     return this.getMiddleClass;
    //   }
    //   if (this.currentDesktop) {
    //     totalRemoteParticipants += 1;
    //   }
    //   if (
    //     !this.currentDesktop &&
    //     this.presenterModeInitiatedBy == this.room.myUserId()
    //   ) {
    //     index = index - 1;
    //   }
    //   if (
    //     !this.currentDesktop &&
    //     participantId &&
    //     this.presenterModeInitiatedBy &&
    //     this.presenterModeInitiatedBy != participantId &&
    //     this.presenterModeInitiatedBy != this.room.myUserId() &&
    //     this.remoteSpeakerIndex &&
    //     this.remoteSpeakerIndex < index
    //   ) {
    //     index = index - 1;
    //   }
    //   // if(!this.getIsParticipantIsPodium(this.ownUUID) && this.presenterModeInitiatedBy != participantId){
    //   //   index -= 1
    //   // }
    //   if(!this.getIsParticipantIsPodium(this.ownUUID) && this.currentDesktop &&  participantId && this.presenterModeInitiatedBy == participantId){
    //     index = 1
    //   }
    //   if(!this.getIsParticipantIsPodium(this.ownUUID) && !this.currentDesktop){
    //     index = index - 1;
    //   }
    //   // new for congerencehall
    //   // if (
    //   //   this.getIsConferenceHall &&
    //   //   ((!this.getIsParticipantIsPodium(this.ownUUID) &&
    //   //     this.getIsConferenceHall)
    //   //     ||
    //   //     (this.getIsParticipantIsPodium(this.ownUUID) &&
    //   //       (participantId || originalIndex != 1) &&
    //   //       this.presenterModeInitiatedBy &&
    //   //       this.presenterModeInitiatedBy == this.room.myUserId()))
    //   // ) {
    //   //   index = index - 1;
    //   // }
    //   switch (totalRemoteParticipants) {
    //     case 1:
    //       return `order${index}`;
    //     case 2:
    //       return `order${index}`;
    //     case 3:
    //       if (index >= 2) {
    //         return `order${index + 1}`;
    //       }
    //       return `order${index}`;
    //     case 4:
    //       if (index >= 2) {
    //         return `order${index + 1}`;
    //       }
    //       return `order${index}`;
    //     case 5:
    //       if (index >= 2) {
    //         return `span-col-10 order${index + 1}`;
    //       }
    //       return `span-col-10 order${index}`;
    //     case 6:
    //       if (index >= 2) {
    //         if ((index + 1) % 2 == 0) {
    //           return `span-col-10 span-row-2 order${index + 1}`;
    //         } else {
    //           return `span-col-10 span-row-3 order${index + 1}`;
    //         }
    //       }
    //       return `span-col-10 span-row-2 order${index}`;
    //     case 7:
    //       if (index >= 2) {
    //         return `span-col-10 span-row-2 order${index + 1}`;
    //       }
    //       return `span-col-10 span-row-2 order${index}`;
    //     case 8:
    //       if (index >= 2) {
    //         if ((index + 1) % 2 == 0) {
    //           return `span-col-10 span-row-3 order${index + 1}`;
    //         } else {
    //           return `span-col-10 span-row-4 order${index + 1}`;
    //         }
    //       }
    //       return `span-col-10 span-row-3 order${index}`;
    //     case 9:
    //       if (index >= 2) {
    //         return `span-col-10 span-row-3 order${index + 1}`;
    //       }
    //       return `span-col-10 span-row-3 order${index}`;
    //     case 10:
    //       if (index >= 7) {
    //         return `span-col-10 span-row-25 order${index + 1}`;
    //       }
    //       if (index == 6) {
    //         return `span-col-10 span-row-25 order${index}`;
    //       }
    //       return `span-col-12 span-row-10 order${index}`;
    //     case 11:
    //       if (index >= 8) {
    //         return `span-col-10 span-row-25 order${index + 1}`;
    //       }
    //       if (index === 7) {
    //         return `span-col-10 span-row-25 order${index}`;
    //       }
    //       return `span-col-10 span-row-10 order${index}`;
    //     case 12:
    //       if (index >= 7 && index < 11) {
    //         return `span-col-10 span-row-17 order${index + 1}`;
    //       }
    //       if (index === 6) {
    //         return `span-col-10 span-row-17 order${index}`;
    //       }
    //       if (index === 11 || index === 12) {
    //         return `span-col-10 span-row-16 order${index + 1}`;
    //       }
    //       return `span-col-12 span-row-10 order${index}`;
    //     case 13:
    //       if (index >= 8 && index < 11) {
    //         return `span-col-10 span-row-17 order${index + 1}`;
    //       }
    //       if (index === 11 || index === 12) {
    //         return `span-col-10 span-row-16 order${index + 1}`;
    //       }
    //       if (index === 7) {
    //         return `span-col-10 span-row-17 order${index}`;
    //       }
    //       return `span-col-10 span-row-10 order${index}`;
    //     case 14:
    //       if (index === 8 || index === 10) {
    //         return `span-col-10 span-row-13 order${index + 1}`;
    //       }
    //       if (index === 9 || index === 11 || index === 13) {
    //         return `span-col-10 span-row-10 order${index + 1}`;
    //       }
    //       if (index === 12) {
    //         return `span-col-10 span-row-14 order${index + 1}`;
    //       }
    //       return `span-col-10 span-row-10 order${index}`;
    //     case 15:
    //       if (index >= 8) {
    //         return `span-col-10 span-row-10 order${index + 1}`;
    //       }
    //       return `span-col-10 span-row-10 order${index}`;
    //     case 16:
    //       if (index === 7) {
    //         return `span-col-10 span-row-13 order${index}`;
    //       }
    //       if (index >= 8) {
    //         if (index + 1 == 9 || index + 1 == 10 || index + 1 == 11) {
    //           return `span-col-10 span-row-13 order${index + 1}`;
    //         }
    //         if (index + 1 == 12 || index + 1 == 13) {
    //           return `span-col-10 span-row-14 order${index + 1}`;
    //         }
    //         if (index + 1 >= 14) {
    //           return `span-col-20 span-row-10 order${index + 1}`;
    //         }
    //       }
    //       return `span-col-10 span-row-10 order${index}`;
    //     case 17:
    //       if (index === 7) {
    //         return `span-col-10 span-row-13 order${index}`;
    //       }
    //       if (index + 1 === 9 || index + 1 === 10 || index + 1 === 11)
    //         return `span-col-10 span-row-13 order${index + 1}`;
    //       if (index + 1 == 12 || index + 1 == 13) {
    //         return `span-col-10 span-row-14 order${index + 1}`;
    //       }
    //       if (index + 1 >= 14) {
    //         return `span-col-15 span-row-10 order${index + 1}`;
    //       }
    //       return `span-col-10 span-row-10 order${index}`;
    //     case 18:
    //       if (index === 7) {
    //         return `span-col-10 span-row-13 order${index}`;
    //       }
    //       if (index + 1 === 9 || index + 1 === 10 || index + 1 === 11)
    //         return `span-col-10 span-row-13 order${index + 1}`;
    //       if (index + 1 == 12 || index + 1 == 13) {
    //         return `span-col-10 span-row-14 order${index + 1}`;
    //       }
    //       if (index + 1 >= 14) {
    //         return `span-col-12 span-row-10 order${index + 1}`;
    //       }
    //       return `span-col-10 span-row-10 order${index}`;
    //     case 19:
    //       if (index + 1 >= 9 && index + 1 <= 15)
    //         return `span-col-10 span-row-10 order${index + 1}`;
    //       if (index + 1 >= 16) {
    //         return `span-col-15 span-row-10 order${index + 1}`;
    //       }
    //       return `span-col-10 span-row-10 order${index}`;
    //     case 20:
    //       if (index + 1 >= 9 && index + 1 <= 15)
    //         return `span-col-10 span-row-10 order${index + 1}`;
    //       if (index + 1 >= 16) {
    //         return `span-col-12 span-row-10 order${index + 1}`;
    //       }
    //       return `span-col-10 span-row-10 order${index}`;
    //     case 21:
    //       if (index >= 8) {
    //         return `span-col-10 span-row-10 order${index + 1}`;
    //       }
    //       return `span-col-10 span-row-10 order${index}`;
    //   }
    // },
    getPresentationStyle(originalIndex, participantId = false) {
      if (!this.room) return;
      let totalRemoteParticipants = this.getActiveUsers;
      let index = originalIndex;
      if (
        !this.currentDesktop &&
        ((originalIndex === 1 &&
          this.presenterModeInitiatedBy == this.room.myUserId() &&
          !participantId) ||
          (participantId && participantId === this.presenterModeInitiatedBy))
      ) {
        this.remoteSpeakerIndex = originalIndex;
        return this.getMiddleClass;
      }
      if (this.currentDesktop) {
        totalRemoteParticipants += 1;
      }
      if (
        !this.currentDesktop &&
        this.presenterModeInitiatedBy == this.room.myUserId()
      ) {
        index = index - 1;
      }
      if (
        !this.currentDesktop &&
        participantId &&
        this.presenterModeInitiatedBy &&
        this.presenterModeInitiatedBy != participantId &&
        this.presenterModeInitiatedBy != this.room.myUserId() &&
        this.remoteSpeakerIndex &&
        this.remoteSpeakerIndex < index
      ) {
        index = index - 1;
      }
      switch (totalRemoteParticipants) {
        case 1:
          return "";
        case 2:
          return `order${index}`;
        case 3:
          if (index >= 2) {
            return `order${index + 1}`;
          }
          return `order${index}`;
        case 4:
          if (index >= 2) {
            return `order${index + 1}`;
          }
          return `order${index}`;
        case 5:
          if (index >= 2) {
            return `span-col-10 order${index + 1}`;
          }
          return `span-col-10 order${index}`;
        case 6:
          if (index >= 2) {
            if ((index + 1) % 2 == 0) {
              return `span-col-10 span-row-2 order${index + 1}`;
            } else {
              return `span-col-10 span-row-3 order${index + 1}`;
            }
          }
          return `span-col-10 span-row-2 order${index}`;
        case 7:
          if (index >= 2) {
            return `span-col-10 span-row-2 order${index + 1}`;
          }
          return `span-col-10 span-row-2 order${index}`;
        case 8:
          if (index >= 2) {
            if ((index + 1) % 2 == 0) {
              return `span-col-10 span-row-3 order${index + 1}`;
            } else {
              return `span-col-10 span-row-4 order${index + 1}`;
            }
          }
          return `span-col-10 span-row-3 order${index}`;
        case 9:
          if (index >= 2) {
            return `span-col-10 span-row-3 order${index + 1}`;
          }
          return `span-col-10 span-row-3 order${index}`;
        case 10:
          if (index >= 7) {
            return `span-col-10 span-row-25 order${index + 1}`;
          }
          if (index == 6) {
            return `span-col-10 span-row-25 order${index}`;
          }
          return `span-col-12 span-row-10 order${index}`;
        case 11:
          if (index >= 8) {
            return `span-col-10 span-row-25 order${index + 1}`;
          }
          if (index === 7) {
            return `span-col-10 span-row-25 order${index}`;
          }
          return `span-col-10 span-row-10 order${index}`;
        case 12:
          if (index >= 7 && index < 11) {
            return `span-col-10 span-row-17 order${index + 1}`;
          }
          if (index === 6) {
            return `span-col-10 span-row-17 order${index}`;
          }
          if (index === 11 || index === 12) {
            return `span-col-10 span-row-16 order${index + 1}`;
          }
          return `span-col-12 span-row-10 order${index}`;
        case 13:
          if (index >= 8 && index < 11) {
            return `span-col-10 span-row-17 order${index + 1}`;
          }
          if (index === 11 || index === 12) {
            return `span-col-10 span-row-16 order${index + 1}`;
          }
          if (index === 7) {
            return `span-col-10 span-row-17 order${index}`;
          }
          return `span-col-10 span-row-10 order${index}`;
        case 14:
          if (index === 8 || index === 10) {
            return `span-col-10 span-row-13 order${index + 1}`;
          }
          if (index === 9 || index === 11 || index === 13) {
            return `span-col-10 span-row-10 order${index + 1}`;
          }
          if (index === 12) {
            return `span-col-10 span-row-14 order${index + 1}`;
          }
          return `span-col-10 span-row-10 order${index}`;
        case 15:
          if (index >= 8) {
            return `span-col-10 span-row-10 order${index + 1}`;
          }
          return `span-col-10 span-row-10 order${index}`;
        case 16:
          if (index === 7) {
            return `span-col-10 span-row-13 order${index}`;
          }
          if (index >= 8) {
            if (index + 1 == 9 || index + 1 == 10 || index + 1 == 11) {
              return `span-col-10 span-row-13 order${index + 1}`;
            }
            if (index + 1 == 12 || index + 1 == 13) {
              return `span-col-10 span-row-14 order${index + 1}`;
            }
            if (index + 1 >= 14) {
              return `span-col-20 span-row-10 order${index + 1}`;
            }
          }
          return `span-col-10 span-row-10 order${index}`;
        case 17:
          if (index === 7) {
            return `span-col-10 span-row-13 order${index}`;
          }
          if (index + 1 === 9 || index + 1 === 10 || index + 1 === 11)
            return `span-col-10 span-row-13 order${index + 1}`;
          if (index + 1 == 12 || index + 1 == 13) {
            return `span-col-10 span-row-14 order${index + 1}`;
          }
          if (index + 1 >= 14) {
            return `span-col-15 span-row-10 order${index + 1}`;
          }
          return `span-col-10 span-row-10 order${index}`;
        case 18:
          if (index === 7) {
            return `span-col-10 span-row-13 order${index}`;
          }
          if (index + 1 === 9 || index + 1 === 10 || index + 1 === 11)
            return `span-col-10 span-row-13 order${index + 1}`;
          if (index + 1 == 12 || index + 1 == 13) {
            return `span-col-10 span-row-14 order${index + 1}`;
          }
          if (index + 1 >= 14) {
            return `span-col-12 span-row-10 order${index + 1}`;
          }
          return `span-col-10 span-row-10 order${index}`;
        case 19:
          if (index + 1 >= 9 && index + 1 <= 15)
            return `span-col-10 span-row-10 order${index + 1}`;
          if (index + 1 >= 16) {
            return `span-col-15 span-row-10 order${index + 1}`;
          }
          return `span-col-10 span-row-10 order${index}`;
        case 20:
          if (index + 1 >= 9 && index + 1 <= 15)
            return `span-col-10 span-row-10 order${index + 1}`;
          if (index + 1 >= 16) {
            return `span-col-12 span-row-10 order${index + 1}`;
          }
          return `span-col-10 span-row-10 order${index}`;
        case 21:
          if (index >= 8) {
            return `span-col-10 span-row-10 order${index + 1}`;
          }
          return `span-col-10 span-row-10 order${index}`;
      }
    },
    // showDivAlerts(participantId) {
    //   return (
    //     this.participantsMuted[participantId] ||
    //     this.participantsAudioMuted[participantId] ||
    //     this.participantsCameraOff[participantId] ||
    //     (this.badQualityUser && this.badQualityUser.length)
    //   );
    // },
    // sortListenerConferenceHall(){
    //   this.getSortedListenersConferenceHall.forEach((participantId, index) => {
    //     //  remoteParticipantContainer-${participant.participantId}
    //     const participantObj = this.remoteParticipants.find(
    //       (e) => e.participantId == participantId
    //     );
    //     if (participantObj && document.getElementById(participantId) && participantObj.userUUID && !this.getIsParticipantIsPodium(participantObj.userUUID)) {
    //       document.getElementById(participantId).style.order=index
    //     }
    //   });
    // }
  },
  computed: {
    isPkg() {
      return webLicensedBaseFeatures.isPkg;
    },
    // getPodiumArray(){
    //   let podium = this.remoteParticipants.filter(
    //     (e) => this.getIsParticipantIsPodium(e.userUUID)
    //   ).map((e) =>   e.userUUID)
    //   return podium
    //   // let participantsPodium = []
    //   // this.remoteParticipants.forEach(participant => {
    //   //   if (this.getIsParticipantIsPodium(participant.userUUID)) {
    //   //     participantsPodium.push(participant.userUUID)
    //   //   }
    //   // });
    //   // return participantsPodium;
    // },
    // getSortedListenersConferenceHall(){
    //   if(!this.getIsConferenceHall) return
    //   let finalArray = Object.keys(this.listenerSpeakerConferenceHall).concat(Object.keys(this.requestToSpeakConferenceHall));
    //   this.remoteParticipants.forEach((participant) => {
    //     if(finalArray.indexOf(participant.participantId)==-1){
    //       finalArray.push(participant.participantId)
    //     }
    //   });
    //   return finalArray;
    // },
    // getTotalListenerConferenceHall() {
    //   if(!this.getIsConferenceHall) return false
    //   let total = this.remoteParticipants.filter(
    //     (e) => !this.getIsParticipantIsPodium(e.userUUID)
    //   ).length;
    //   if (!this.getIsParticipantIsPodium(this.ownUUID)) {
    //     total += 1;
    //   }
    //   return total;
    // },
    getIsConferenceHall() {
      return isConferenceHallCall(this.getCallUUID);
      // return true;
    },
    getTotalReceivedChatMessages() {
      if (!this.chatMessages) return 0;
      return this.chatMessages.filter(
        (message) => message.creatorUUID !== this.state.ownUUID
      ).length;
    },
    getGridRegularContainerClass() {
      let totalRemoteParticipants = this.getActiveUsers;
      switch (totalRemoteParticipants) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 11:
        case 12:
          return "gridBase12";
        case 10:
          return "gridBase10";
        case 13:
        case 14:
          return "gridBase60";
        case 15:
          return "gridBase15";
        case 16:
          return "gridBase8";
        case 17:
        case 18:
        case 19:
        case 20:
          return "gridBase20";
      }
    },
    getGridPresenterContainerClass() {
      let totalRemoteParticipants = this.getActiveUsers;
      // if (this.getIsConferenceHall) {
      //   if(this.getIsParticipantIsPodium(this.ownUUID)){
      //     totalRemoteParticipants = (this.getPodiumArray.length + 1);
      //   }else{
      //     totalRemoteParticipants = this.getPodiumArray.length;
      //   }
      //   // totalRemoteParticipants =
      //   //   this.getActiveUsers - this.getTotalListenerConferenceHall;
      // }
      if (this.currentDesktop) {
        totalRemoteParticipants += 1;
      }
      switch (totalRemoteParticipants) {
        case 1:
          return "gridBase1";
        case 2:
        case 3:
        case 4:
          return "gridBase5";
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
        case 16:
        case 17:
        case 18:
        case 19:
        case 20:
        case 21:
          return "gridBase60";
        default:
          break;
      }
    },
    getActiveUsers() {
      return this.remoteParticipants.length + 1;
    },
    getLocalStats() {
      return this.localStats;
    },
    isOnlyOneCallUser() {
      return isOnlyOneCallUser(store.state.ownUUID);
    },
    getconfId() {
      const callUUID = this.getCallUUID;
      if (!callUUID || !this.isConferenceCall) return;
      if (
        this.bridgeInfoStore?.calls &&
        this.bridgeInfoStore.calls[callUUID]
      ) {
        return this.bridgeInfoStore.calls[callUUID].confId;
      }
      return (this.state.remoteBridgeStreams[callUUID] || {}).confId;
    },
    // remoteParticipantsInHall() {
    //   return (
    //     (this.remoteParticipants || []).filter((participant) => (
    //       (this.getIsParticipantIsPodium(participant.userUUID) &&
    //         this.getIsConferenceHall) ||
    //       !this.getIsConferenceHall))
    //   ) || [];
    // },
    // remoteParticipantsNotInHall() {
    //   return (
    //     (this.remoteParticipants || []).filter((participant) => (
    //       !this.getIsParticipantIsPodium(participant.userUUID) &&
    //         this.getIsConferenceHall))
    //   ) || [];
    // },
    getTotalRemoteParticipants() {
      return (this.remoteParticipants || []).length;
    },
    getMyRoomId() {
      if (!this.room) return false;
      return this.room.myUserId();
    },
    getShareScreenTopBar() {
      if (!this.room) return false;
      if (this.currentDesktop) {
        const objParticipant = this.room.getParticipantById(
          this.currentDesktop
        );
        const userData = objParticipant.getDisplayName();
        const personId = this.getUUIDForDisplayName(userData);
        return personId;
      }
      return false;
    },
    getDesktopIsModerator() {
      if (!this.room) return false;
      if (this.currentDesktop) {
        const objParticipant = this.room.getParticipantById(
          this.currentDesktop
        );
        if (objParticipant) {
          const userData = objParticipant.getDisplayName();
          const personId = this.getUUIDForDisplayName(userData);
          const participantObj = this.remoteParticipants.find(
            (e) => e.userUUID == personId
          );
          if (participantObj && participantObj.participantId) {
            return this.isUserModerator(participantObj.participantId);
          } else {
            if (
              this.desktopRoom &&
              this.currentDesktop == this.desktopRoom.myUserId()
            ) {
              return this.getAmIModerator;
            }
          }
        }
      }
      return false;
    },
    getMyDesktopRoomUserID() {
      return this.desktopRoom && this.desktopRoom.myUserId();
    },
    getIsAudioOnly() {
      const callUUID = this.getCallUUID;
      if (this.state.remoteBridgeStreams[callUUID]) {
        return this.state.remoteBridgeStreams[callUUID].isAudioOnly;
      } else {
        return true;
      }
    },
    callInitiator() {
      const callUUID = this.getCallUUID;
      if (!callUUID) return;
      return (this.state.remoteBridgeStreams[callUUID] || {}).initiator;
    },
    amIInitiator() {
      return (this.callInitiator && this.callInitiator === this.ownUUID);
    },
    videoDeviceId() {
      return this.state.persisted.mediaDeviceSetup.videoDeviceId;
    },
    audioDeviceId() {
      return this.state.persisted.mediaDeviceSetup.audioDeviceId;
    },
    hideVideoContainer() {
      return (
        this.state.currentContentVisile.showComponent ||
        Object.keys(this.state.remoteBridgeStreams).length === 0
      );
    },
    getIsMobile() {
      const callUUID = this.getCallUUID;
      if (
        !this.getIsConferenceHall &&
        this.state.remoteBridgeStreams[callUUID] &&
        this.state.remoteBridgeStreams[callUUID].calling &&
        this.state.remoteBridgeStreams[callUUID].calling.length
      ) {
        const calling = this.state.remoteBridgeStreams[callUUID].calling;
        const group = this.state.group;
        const retVal = [...calling].reduce(
          (acc, uuid) => acc || !!((group[uuid] || {}).user || {}).isMobile,
          false
        );
        if (retVal) return retVal; // Return true if any of the users being called isMobile
      }
      if (
        this.bridgeInfoStore &&
        this.bridgeInfoStore.calls &&
        this.bridgeInfoStore.calls[callUUID]
      ) {
        return this.bridgeInfoStore.calls[callUUID].isMobile;
      } else {
        return false;
      }
    },
    showTimeLine() {
      if (!this.room) return false;
      if (
        !this.getTotalRemoteParticipants ||
        this.getTotalRemoteParticipants == 0
      ) {
        return false;
      }
      // || this.getIsConferenceHall
      if (this.getIsMobile) {
        return false;
      }
      const callUUID = this.getCallUUID;
      if (this.state.remoteBridgeStreams[callUUID]) {
        if (this.state.remoteBridgeStreams[callUUID].infiniteCall) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    isConferenceCall() {
      const callUUID = this.getCallUUID;
      return callUUID && isConferenceCall(callUUID);
    },
    getAmIModerator() {
      if (!this.room) return false;
      return this.room.isModerator();
    },
    isAudioOutputMuted() {
      return this.audioOutputMuted;
    },
    getExcludedFromCalling() {
      const callUUID = this.getCallUUID;
      return (this.state.remoteBridgeStreams[callUUID] &&
        this.state.remoteBridgeStreams[callUUID].excludedFromCalling) || [];
    },
    amICalling() {
      const callUUID = this.getCallUUID;
      const excluded = this.getExcludedFromCalling;
      if (excluded && excluded.length > 0) {
        return this.state.remoteBridgeStreams[callUUID].calling.filter(
          (e) => this.getExcludedFromCalling.indexOf(e) == -1
        );
      } else {
        return (this.state.remoteBridgeStreams[callUUID] &&
          this.state.remoteBridgeStreams[callUUID].calling) || [];
      }
    },
    getMiddleClass() {
      let totalRemoteParticipants = this.getActiveUsers;
      // if (this.getIsConferenceHall) {
      //   if(this.getIsParticipantIsPodium(this.ownUUID)){
      //     totalRemoteParticipants = (this.getPodiumArray.length + 1);
      //   }else{
      //     totalRemoteParticipants = this.getPodiumArray.length;
      //   }
      // }
      if (this.currentDesktop) {
        totalRemoteParticipants += 1;
      }
      switch (totalRemoteParticipants) {
        case 2:
          return `span-col-4 order2`;
        case 3:
          return `span-col-4 span-row-2 order2`;
        case 4:
          return `span-col-4 span-row-3 order2`;
        case 5:
          return `span-col-40 span-row-2 order2`;
        case 6:
          return `span-col-40 span-row-6 order2`;
        case 7:
          return `span-col-40 span-row-6 order2`;
        case 8:
          return `span-col-40 span-row-12 order2`;
        case 9:
          return `span-col-40 span-row-12 order2`;
        case 10:
          return `span-col-40 span-row-50 order7`;
        case 11:
          return `span-col-40 span-row-50 order8`;
        case 12:
          return `span-col-40 span-row-50 order7`;
        case 13:
          return `span-col-40 span-row-50 order8`;
        case 14:
        case 15:
        case 16:
        case 17:
        case 18:
        case 19:
        case 20:
        case 21:
          return `span-col-40 span-row-40 order8`;
        default:
          return "order2";
      }
    },
    getMicrophoneLevel() {
      return this.microphoneLevel;
    },
    jvbOptions() {
      const options = jvbOptions();
      return { disableBeforeUnloadHandlers: true, ...options };
    },
    getCallUUID() {
      const callUUID = Object.keys(this.state.remoteBridgeStreams)[0];
      return this.callUUID || callUUID;
    },
  },
};
</script>

<style scoped lang="scss">
.listenerWrapper {
  height: 20%;
}
.chatWrapper {
  height: 94%;
  width: 20%;
}
.sidebarCallWrapper {
  height: 100%;
}
.localButtonsWrapper {
  height: 6%;
}
.displayContainer {
  display: flex;
  width: 100%;
  position: relative;
}
.gridContainer {
  display: flex;
  width: 100%;
  height: 94%;
}
.gridContainerConferenceHall {
  display: flex;
  width: 100%;
  height: 78%;
}
.listenerColumn {
  width: 10% !important;
  min-width: 10% !important;
  max-width: 10% !important;
}
.listenerContainerConferenceHall::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/* Track */
.listenerContainerConferenceHall::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #000000;
  border-radius: 10px;
}
/* Handle */
.listenerContainerConferenceHall::-webkit-scrollbar-thumb {
  background: #06a5df;
  border-radius: 10px;
}
.listenerContainerConferenceHall {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: auto;
  position: absolute;
  bottom: 60px;
  background: black;
  padding: 0;
  height: 185px;
  //just for firefox
  scrollbar-color: #06a5df #000000;
  scrollbar-width: thin;
}
.chooseShareScreenBg {
  background-color: #0078d4;
  background-image: url("/img/chooseShareScreen_color__figures.svg");
  background-position: center;
  background-size: cover;
}
.textChooseShareScreen {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: 200;
  line-height: 3.125rem;
}
.wh100 {
  width: 100%;
  height: 100%;
}
.input {
  width: 100%;
  padding: 8px 10px;
}
// .gridContainer {
//   background-color: #ccc;
//   height: 250px;
// }
.gridLayout {
  width: 100%;
}
.gridItem {
  -webkit-transition-property: left, top, right, width;
  transition-property: left, top, right, width;
}
.gridItem.hidden {
  display: none;
}
.gridItem.maximized {
  transform: inherit !important;
  height: 100% !important;
  width: 100% !important;
}
.removeVideoParent {
  // z-index: 1;
  width: 100%;
  height: 100%;
}
.iconsShareScreen {
  position: absolute;
  cursor: pointer;
  z-index: 2;
  top: 33;
  margin: auto;
  height: 36px;
  width: 100%;
  text-align: left;
  left: 0;
  display: flex;
  justify-content: center;
  &:hover {
    .buttosContainer {
      opacity: 0.8;
    }
  }
  .buttosContainer {
    background-color: rgb(0, 0, 0);
    box-shadow: 2px 2px 24px #636363;
    opacity: 0.6;
    padding: 6px;
    height: 50px;
    border-radius: 15px;
  }
}
@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
.myLocalMobile {
  position: relative !important;
  // position: absolute !important;
  bottom: 0px;
  max-width: 30% !important;
  max-height: 25% !important;
  width: 30% !important;
  height: 25% !important;
  right: 0px;
  flex: 0 0 30%;
  z-index: 9;
  &.myLocalMobileWithBar {
    bottom: 72px;
  }
}
.pRelative {
  position: relative;
  .buttonMore {
    position: absolute;
    bottom: -3px;
    height: 15px;
    width: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    right: -3px;
    opacity: 0.6;
    &:hover {
      background: var(--v-primary-base);
    }
  }
}
.icons {
  position: absolute;
  cursor: pointer;
  z-index: 2;
  bottom: 0;
  top: 0;
  margin: auto;
  height: 36px;
  width: 100%;
  text-align: left;
  left: 0;
  display: flex;
  justify-content: center;
  &:hover {
    .buttosContainer {
      opacity: 0.8;
    }
  }
  .buttosContainer {
    background-color: rgb(0, 0, 0);
    box-shadow: 2px 2px 24px #636363;
    opacity: 0.6;
    padding: 6px;
    height: 50px;
    border-radius: 15px;
  }
  .buttonsCall {
    border-radius: 50%;
    margin: 0 5px;
    .rotatedIcon {
      transform: rotate(226deg);
    }
    &:hover {
      box-shadow: 4px 4px 48px rgb(14, 13, 13);
      background: #242525;
    }
  }
}
.imageCall {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 50%;
}
.imageCallListeners {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 10%;
}
.faPhoneRotate {
  transform: rotate(230deg) !important;
}
.maximizeScreenMobile{
  position: absolute;
  width: 100vw;
  height: 100%;
  bottom: 0;
  right: 0;
  z-index: 10;
  display: flex;
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  /* Styles */
  }
}
.maximizeScreen {
  position: absolute;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  right: 0;
  z-index: 10;
  display: flex;
}
.dominantSpeaker {
  border: solid 4px #6dbac6 !important;
}
.incomingPersonAvatar img {
  position: relative !important;
  border-radius: 50% !important;
  width: 40% !important;
}
.participantColumn {
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  max-width: 100%;
  min-width: 100%;
}
.imgContent {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
}
.imgUserContainer {
  position: relative;
  height: 100%;
  width: 100%;
}
.imgUserContainerCalling {
  position: relative;
  height: 100%;
  width: 100%;
}
.borderRadius4 {
  border-radius: 4px!important;
}
.callingElements {
  display: flex;
  justify-content: center;
}
.callingBar {
  position: absolute;
  height: 36px;
  width: 100%;
  bottom: 0;
  left: 0;
  text-align: center;
  color: white;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}
.waveCallingBridge {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 20px auto;
  .dot {
    background: #2a3133;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 3px;
    animation: wave 1.3s linear infinite;
  }
  .dot:nth-child(2) {
    animation-delay: -1.1s;
  }
  .dot:nth-child(3) {
    animation-delay: -0.9s;
  }
  @keyframes wave {
    0%,
    60%,
    100% {
      transform: initial;
    }
    30% {
      transform: translateY(-15px);
    }
  }
}
.conferenceTitleWrap {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: auto;
  position: absolute;
  bottom: 245px;
  background: #808080;
  padding: 0;
  height: 25px;
}
.conferenceTitle {
  margin: 0 auto;
  color: white;
}
#videos {
  height: 100%;
  -webkit-transition: all 1s;
  transition: all 1s;
  width: 100%;
  left: 0;
  background: black;
  top: 0;
  z-index: 10;
  &.presentationMainContainer {
    flex-direction: column;
  }
  &.showTimeLine {
    height: calc(100% - 24px);
    max-height: calc(100% - 24px);
    min-height: calc(100% - 24px);
  }
  &.hideTimeLine {
    // height: calc(100% - 60px);
    // max-height: calc(100% - 60px);
    // min-height: calc(100% - 60px);
  }
  &.conferenceHallPodiumContainer {
    height: calc(100% - 23px);
    // height: calc(100% - 245px);
    // max-height: calc(100% - 245px);
    // min-height: calc(100% - 245px);
    // height: calc(100% - 293px);
    // max-height: calc(100% - 293px);
    // min-height: calc(100% - 293px);
  }
  &.conferenceHallPodiumContainerNoTimeline{
    height: 100%;
  }
  &.hideTimeLineMobile {
    height: calc(100% - 2px);
    max-height: calc(100% - 2px);
    min-height: calc(100% - 2px);
  }
  .pRelative {
    position: relative;
  }
  .localVideoShareScreen {
    position: absolute;
    height: 100%;
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    max-width: 100%;
    min-width: 100%;
    bottom: 0;
    right: 0;
    background-size: cover;
    overflow: hidden;
  }
  .localVideo,
  .remoteVideo {
    position: absolute;
    height: 100%;
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    max-width: 100%;
    min-width: 100%;
    bottom: 0;
    right: 0;
    background-size: cover;
    overflow: hidden;
  }
  .objectFit {
    object-fit: cover;
  }
  .buttonsBar {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
  }
}
.localButtonsBar {
    background-color: #202124;
    backdrop-filter: blur(5px);
    color: white;
    z-index: 10 !important;
    align-content: center;
    &.myLocal {
      height: 60px;
    }
    .logoButtonBar {
      margin-top: 2px;
    }
  }
.buttonsBar,
.localButtonsBar {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    z-index: 1;
    opacity: 1;
  }
/* ShareScreen and presentation */
/* GRID BASE START*/
.gridBase1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
.gridBase5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.gridBase6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.gridBase8 {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}
.gridBase10 {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}
.gridBase12 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.gridBase15 {
  display: grid;
  grid-template-columns: repeat(15, 1fr);
}
.gridBase20 {
  display: grid;
  grid-template-columns: repeat(20, 1fr);
}
.gridBase60 {
  display: grid;
  grid-template-columns: repeat(60, 1fr);
}
/* GRID BASE END*/
/* SPAN-COL START*/
.span-col-2 {
  grid-column: span 2 / auto;
}
.span-col-3 {
  grid-column: span 3 / auto;
}
.span-col-4 {
  grid-column: span 4 / auto;
}
.span-col-5 {
  grid-column: span 5 / auto;
}
.span-col-6 {
  grid-column: span 6 / auto;
}
.span-col-8 {
  grid-column: span 8 / auto;
}
.span-col-9 {
  grid-column: span 9 / auto;
}
.span-col-10 {
  grid-column: span 10 / auto;
}
.span-col-12 {
  grid-column: span 12 / auto;
}
.span-col-15 {
  grid-column: span 15 / auto;
}
.span-col-20 {
  grid-column: span 20 / auto;
}
.span-col-36 {
  grid-column: span 36 / auto;
}
.span-col-40 {
  grid-column: span 40 / auto;
}
/* SPAN-COL END*/
/* SPAN-ROW START */
.span-row-2 {
  grid-row: span 2 / auto;
}
.span-row-3 {
  grid-row: span 3 / auto;
}
.span-row-4 {
  grid-row: span 4 / auto;
}
.span-row-5 {
  grid-row: span 5 / auto;
}
.span-row-6 {
  grid-row: span 6 / auto;
}
.span-row-8 {
  grid-row: span 8 / auto;
}
.span-row-10 {
  grid-row: span 10 / auto;
}
.span-row-12 {
  grid-row: span 12 / auto;
}
.span-row-13 {
  grid-row: span 13 / auto;
}
.span-row-14 {
  grid-row: span 14 / auto;
}
.span-row-15 {
  grid-row: span 15 / auto;
}
.span-row-16 {
  grid-row: span 16 / auto;
}
.span-row-17 {
  grid-row: span 17 / auto;
}
.span-row-20 {
  grid-row: span 20 / auto;
}
.span-row-25 {
  grid-row: span 25 / auto;
}
.span-row-40 {
  grid-row: span 40 / auto;
}
.span-row-50 {
  grid-row: span 50 / auto;
}
/* SPAN-ROW END*/
/*ORDER START */
.order1 {
  order: 1;
}
.order2 {
  order: 2;
}
.order3 {
  order: 3;
}
.order4 {
  order: 4;
}
.order5 {
  order: 5;
}
.order6 {
  order: 6;
}
.order7 {
  order: 7;
}
.order8 {
  order: 8;
}
.order9 {
  order: 9;
}
.order10 {
  order: 10;
}
.order11 {
  order: 11;
}
.order12 {
  order: 12;
}
.order13 {
  order: 13;
}
.order14 {
  order: 14;
}
.order15 {
  order: 15;
}
.order16 {
  order: 16;
}
.order17 {
  order: 17;
}
.order18 {
  order: 18;
}
.order19 {
  order: 19;
}
.order20 {
  order: 20;
}
.order21 {
  order: 21;
}
/* ORDER END*/
</style>