<template>
  <v-list>
        <v-list-item @click.stop.prevent="handleConference()">
          <v-icon
            class="text-primary"
            :class="`${getRoute === '/newconference' ? ' primary--text' : ''}`"
          >
            <font-awesome-icon class="text-primary" :icon="['fal', 'users']" />
          </v-icon>
          <v-list-item-title
            class="text-truncate text-primary"
            :class="`${getRoute === '/groups' ? 'font-weight-bold primary--text' : ''}`"
          >
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <span
                  v-bind="props"
                >{{ $t('components.groups.konferenzen') }} {{`${userGroupsLength > 0 ? '('+userGroupsLength +')' : ''}`}}</span>
              </template>
              <span>{{ $t('components.groups.manageConferences')}}</span>
            </v-tooltip>
          </v-list-item-title>
        </v-list-item>
        
  </v-list>
</template>
<script>
import store from "../../store";
// import UserList from "./userList.vue";
import { hasPrivilege } from "../../utils/privileges";
export default {
  // components: { UserList },
  props: ["setList", "active", "filterPerson", "waitingRoomFor"],
  data: () => ({
    state: store.state,
    setCurrentContentVisile: store.setCurrentContentVisile,
    ownUUID: store.state.ownUUID,
  }),
  methods: {
    handleConference(){
      this.$router.push({ path: "/newconference" });
    },
    
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
  },
  computed: {
    userGroupsLength() {
      const MyConferences = (this.state.user.userGroups || []).filter(e => (e.isTemplate || false) == false);
      return MyConferences.length || 0;
    },
    assistantsUser() {
      return (store.state.user.assists || []).length || 0;
    },
    getTitleWaitingROOM() {
      const waitingRoomName = this.state.namespaceSettings.waitingRoomAlias
        ? this.state.namespaceSettings.waitingRoomAlias
        : this.$t("components.drawerContentExpansion.waitingRoom");
      if (this.waitingRoomFor === this.state.ownUUID) {
        return (
          waitingRoomName +
          ` ${
            this.assistantsUser > 0
              ? this.getTitelForUuid(this.state.ownUUID) + " " + this.getNameForUuid(this.state.ownUUID)
              : ""
          }`
        );
      } else {
        return (
          waitingRoomName +
          " " +
          this.getTitelForUuid(this.waitingRoomFor) +
          " " +
          this.getNameForUuid(this.waitingRoomFor)
        );
      }
      // {{ state.namespaceSettings.waitingRoomAlias ? state.namespaceSettings.waitingRoomAlias : $t('components.drawerContentExpansion.waitingRoom') }} {{`${sortedUsersWaitingRoom.length > 0 ? '('+sortedUsersWaitingRoom.length +')' : ''}`}}
    },
    sortedUsersWaitingRoom: function () {
      let waitingUsers = [];
      const visitors = this.state.persisted.userVisitors || {};
      for (const visitorId in visitors) {
        const visitor = store.state.persisted.userVisitors[visitorId];
        if (visitor && visitor.isWaitingRoom) {
          const person = store.state.group[visitorId];
          if (
            !person ||
            !person.user ||
            person.user.name == "" ||
            !person.connected
          )
            continue;
          waitingUsers.push({ ...person, uuid: visitorId });
        }
      }
      return waitingUsers.filter(this.filterPerson).sort((a, b) => {
        // Compare by connected state
        if (a.connected && !b.connected) {
          return -1;
        } else if (!a.connected && b.connected) {
          return 1;
        }
        return ((a.user || {}).name || a.uuid || "").localeCompare(
          (b.user || {}).name || b.uuid || ""
        );
      });
    },
    getRoute() {
      return this.$route.path;
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    isActive() {
      return this.active == "waitingroom";
    },
  },
};
</script>
<style scoped lang="scss">
.activeTab {
  font-weight: 700;
}
.titleGroup {
  .v-icon {
    font-size: 14px !important;
  }
}

.v-list {
  padding: 0;
}

.v-list-item__icon {
  margin-right: 8px !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}

.textColor {
  //  color: var(--v-primary-base);
  color: #008000;
}
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.v-theme--dark .listColor {
  color: white !important;
}
.v-theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
