<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="460"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('components.userListItem.removeFromMyFavorites')"
        :closeModalFunction="closeModal"
      />
      <v-card-text>{{
        $t("components.userListItem.removeFromMyFavoritesConfirm")
      }}</v-card-text>
      <FooterModal :closeModalFunction="closeModal" :showCancel="true">
        <v-btn color="primary" @click="removePersonFav(person)">{{
          $t("generics.accept")
        }}</v-btn>
      </FooterModal>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";

export default {
  props: ["person", "closeModal", "showModal"],
  components: {
    FooterModal,
    HeaderModal,
  },
  data: () => ({
    state: store.state,
  }),
  methods: {
    removePersonFav(person) {
      store.setUuidInFavorites(person.uuid, undefined);
      this.closeModal();
    },
  },
};
</script>


<style scoped lang="scss">
</style>
