import { fullBridgeCallModalDomain } from './domain';

import { setFullBridgeCallModalEvent, resetFullBridgeCallModalEvent} from './events';

export const fullBridgeCallStore = fullBridgeCallModalDomain.createStore(false, { name: ' fullBridgeCallModal' });

fullBridgeCallStore.on(setFullBridgeCallModalEvent, (state, payload) => {
  return payload;
});

fullBridgeCallStore.reset(resetFullBridgeCallModalEvent);
// qualityVotingModalStore.watch((state) => { console.log('qualityVotingModalStore', state); });
