<template>
  <div class="pa-0 col-xl-4 col-lg-3 col-md-3 col-sm-5  px-2 d-flex white">
    <div
      class="pa-0 text-truncate justify-space-between"
      :class="amIModerator ? 'col-3' : 'col-12'"
    >
      {{ getTitleText() }}
    </div>
    <div class="pa-0 col-9 text-center" v-if="amIModerator">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" class="mx-1" x-small v-on="on" @click="openAddUserConference">
            <span class="hidden-md-and-down pl-1 mr-3">{{
              $t("components.conferenceForm.user")
            }}</span>
            <font-awesome-icon
              :icon="['fal', 'plus']"
              :style="{ fontSize: '12px' }"
            />
          </v-btn>
        </template>
        <span>{{ $t("components.conferenceForm.user") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" class="mx-1" x-small v-on="on" @click="openVisitorConference">
            <span class="hidden-md-and-down pl-1 mr-3">{{
              $t("generics.guests")
            }}</span>
            <font-awesome-icon
              :icon="['fal', 'plus']"
              :style="{ fontSize: '12px' }"
            />
          </v-btn>
        </template>
        <span>{{ $t("generics.guests") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" class="mx-1" x-small v-on="on" @click="getUniversalLink">
            <!-- <span class="hidden-md-and-down pl-1 mr-3">{{
              $t("generics.guests")
            }}</span> -->
            <font-awesome-icon
              :icon="['fal', 'plus']"
              :style="{ fontSize: '12px' }"
            />
          </v-btn>
        </template>
        <span>{{$t('components.conferenceForm.directCallInvite')}}</span>
      </v-tooltip>

      <v-tooltip bottom v-if="getDataConference && (getDataConference.confOwner === state.ownUUID)">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" class="mx-1" x-small v-on="on" @click="endConference">
            <!-- <span class="hidden-md-and-down pl-1 mr-3">{{ $t("components.conferenceForm.endConference") }}</span> -->
            <font-awesome-icon
              :icon="['fal', 'door-open']"
              :style="{ fontSize: '12px' }"
            />
          </v-btn>
        </template>
        <span>{{ $t("components.conferenceForm.endConference") }}</span>
      </v-tooltip>

    </div>
    <AddUsersConference v-if="showAddUserConference" :isConferenceCall="isConferenceCall" :dataConference="getDataConference"  :showModal="showAddUserConference" :closeModal="closeAddUserConference" />
    <AddVisitorsConference v-if="showAddVisitorConference" :isConferenceCall="isConferenceCall"   :showModal="showAddVisitorConference" :closeModal="closeVisitorConference" :dataConference="getDataConference" />

  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import {
  // fetchEventByUUIDEffect,
  timelineEvents,
  deleteTimelineItemEvent
} from "../../effector/timeline";
import {
  setDirectCalLInviteModalEvent
} from "../../effector/modals";
import {
  dispatchDeleteGroupConferenceEvent,
  myConferenceDetails
} from "../../effector/groupConferences";
import { newMessageEvent } from "../../effector/message"
import { parallel } from "../../lib/asyncUtil";
import { getDirectCallInviteLink, sendInviteMailCustom } from '../../lib/wsMsg';
import AddUsersConference  from "./addUsersConference.vue";
import AddVisitorsConference from "./addVisitorsConference.vue"

export default {
  props: ["isConferenceCall", "amIModerator", "hangUpBridgeCall", "getCallUUID"],
  components: {AddUsersConference, AddVisitorsConference},
  data() {
    const effector = {
      cacheTimeline: timelineEvents,
      myConferenceDetails: myConferenceDetails,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      dataConference: null,
      showAddUserConference: false,
      showAddVisitorConference: false,
      users: [], 
      visitors: [],
      link: '',
      // Effector
      ...effector,
    };
  },
  watch: {},
  mounted() {},
  unmounted() {},
  methods: {
    async getUniversalLink(){
      /// show the the link .... to have callUUID
      this.link = await getDirectCallInviteLink();
      setDirectCalLInviteModalEvent(this.link);
    },
    async endConference(){
      const extant = this.getDataConference;
      const confId = extant.confId
      const extantTimeLine = await this.cacheTimeline.find((e) => e.confId === confId)
      const users = extant.confUUIDS;
        if ( extant && extant.confUUIDS){
          this.hangUpBridgeCall();
          if(extantTimeLine){
            deleteTimelineItemEvent(extantTimeLine.uuid);
          }
          if (confId){
            await dispatchDeleteGroupConferenceEvent(confId)
          }  
        }
      this.handleNotifications(users, extant.name || '');    
    },
    openAddUserConference(){
      this.showAddUserConference = true;
    },
    closeAddUserConference(){
      this.showAddUserConference = false;
    },
    openVisitorConference(){
      this.showAddVisitorConference = true;
    },
    closeVisitorConference(){
      this.showAddVisitorConference = false;
    },
    handleNotifications(recipients, name){
      this.users = [];
      this.visitors = [];
      this.users = recipients.filter(e => store.state.group[e].user.visitor == false) 
      this.visitors = recipients.filter(e => store.state.group[e].user.visitor == true) 
     // this.preparedataToSendMsgEffector();  
      this.sendEndedConferenceMail()

    },
    preparedataToSendMsgEffector() {
      const userToSendMsg = {};
      this.users.forEach(id => {
        userToSendMsg[id] = {
          userUUID: id,
          read: false,
          replied: false,
          deleted: false,
          viewed: false,
        }; 
      });
 
      const header={
      translateMessage:{
        translateKey:'components.conferenceForm.conferenceEnded'
      }
    }
      const dataMsg = {
        body: this.$t('components.conferenceForm.conferenceEnded'),
        header: header,
        date: Date.now(),
        users: userToSendMsg,
        isReply: false,
        creatorUUID: this.ownUUID,
        masterMessageUUID: null,
        parentMessageUUID: null,
        type: "message",
      };
      newMessageEvent(dataMsg);
    },
    async sendEndedConferenceMail(){
       let visitors = this.visitors;
      await parallel(1, [...visitors], async (uuid) => {
        if (store.state.ownUUID === uuid) return;
        const language =  (((store.state.group[uuid] || {}).user || {}).language || "en");
        const email = (((store.state.group[uuid] || {}).user || {}).email || '');
        if (email && email.indexOf('@') !== -1) {
          const textToSend = this.$t('components.conferenceForm.conferenceEnded');
          await sendInviteMailCustom(uuid, {
            guest: ((store.state.group[uuid] || {}).user || {}).permissions === 10,
            name: ((store.state.group[uuid] || {}).user || {}).name,
            email,
            subject: this.$t('components.conferenceForm.conferenceEndedSubject'),
            language,
            customMessage: textToSend,
            conferenceNotification: true,
            noInviteLink: true,
          });
        }
      });
    },
  },
  computed: {
    getTitleText(){
      let text = '';
      if (this.dataConference){
        if (this.dataConference.confDescription && this.dataConference.confDescription.length > 2){
         text = this.dataConference.confDescription;
        } else {
         text = this.dataConference.confName 
        }
      } 
      return text;
    },
    getDataConference() {
      const dataConference = myConferenceDetails.find(e => e.confId === this.isConferenceCall)
      this.dataConference = dataConference;
      return dataConference;
    },
  },
};
</script>

<style  lang="scss">
</style>