// import { combine } from 'effector';
import CallInfoManager from '../../lib/callInfoManager';
import { closeDisplayMedia, setMuteLocalAudio } from '../../lib/mediaDevices';
import { ownUserUUIDState } from '../users';

import {
  callAddDurationEvent,
  callLessDurationEvent,
  callSetMessageToParticipantsEvent,
  callSetPresentationViewEvent,
  callSetWantToSpeak,
  callSetRemoteFullSizeFor,
  callSetAllowedToTalkUUIDs,
  callUpdateAddAllowedToTalkUUID,
  callUpdateRemoveAllowedToTalkUUID,
  callUpdateAddNotAllowedToTalkUUID,
  callUpdateRemoveNotAllowedToTalkUUID,
} from './events';

import {
  callInfoStateStore,
  // allowedToTalkMap,
  amMutedByGlobal,
} from './state';

callAddDurationEvent.watch((payload = 60000 ) => {
  // console.log('callAddDurationEvent', payload);
  if (!CallInfoManager.haveCallActive()) return;
  CallInfoManager.adjustCallDurationMs(payload);
});

callLessDurationEvent.watch((payload) => {
  // console.log('callLessDurationEvent', payload);
  if (!CallInfoManager.haveCallActive()) return;
  CallInfoManager.adjustCallDurationMs(-60000);
});

callSetMessageToParticipantsEvent.watch((payload) => {
  // console.log('callSetMessageToParticipantsEvent', payload);
  if (!CallInfoManager.haveCallActive()) return;
  CallInfoManager.setMessageToParticipants(payload);
});

callSetPresentationViewEvent.watch((payload) => {
  // console.log('callSetPresentationViewEvent', payload);
  if (!CallInfoManager.haveCallActive()) return;
  CallInfoManager.setPresentationView(payload);
});

callSetWantToSpeak.watch((payload) => {
  // console.log('wantsToSpeak', payload);
  if (!CallInfoManager.haveCallActive()) return;
  CallInfoManager.setWantToSpeak(payload);
});

callSetRemoteFullSizeFor.watch((payload) => {
  // console.log('callSetRemoteFullSizeFor', payload);
  if (!CallInfoManager.haveCallActive()) return;
  CallInfoManager.setFullSizeForUUID(payload);
});

// Logic to close displayMedia if fullscreen is set and its not us!
callInfoStateStore.watch((state) => {
  // if (!CallInfoManager.amIOwner()) return;
  if (!CallInfoManager.haveCallActive()) return;
  if (state.remoteFullSize && state.remoteToFullSizeUUID !== ownUserUUIDState.getState()) {
    // console.log('callInfoStateStore detected remoteFullSize for other user, closing display media');
    closeDisplayMedia();
  }
});

callSetAllowedToTalkUUIDs.watch((payload) => {
  CallInfoManager.setAllowedToTalk(payload);
});

callUpdateAddAllowedToTalkUUID.watch((payload) => {
  CallInfoManager.addToAllowedToTalk(payload);
});

callUpdateRemoveAllowedToTalkUUID.watch((payload) => {
  CallInfoManager.removeToAllowedToTalk(payload);
});

callUpdateAddNotAllowedToTalkUUID.watch((payload) => {
  CallInfoManager.addToNotAllowedToTalk(payload);
});

callUpdateRemoveNotAllowedToTalkUUID.watch((payload) => {
  CallInfoManager.removeToNotAllowedToTalk(payload);
});

// === Global Mute Reaction Start ===
let mutedByGlobal = false;
function mute() {
  if (!mutedByGlobal) {
    if (setMuteLocalAudio(true)) {
      mutedByGlobal = true;
      // console.log('global mute');
    }
  }
}
function unmute() {
  if (mutedByGlobal) {
    setMuteLocalAudio(false);
    mutedByGlobal = false;
    // console.log('global unmute');
  }
}
amMutedByGlobal.watch((shouldBeMuted) => {
  // console.log('amMutedByGlobal', shouldBeMuted);
  shouldBeMuted ? mute() : unmute();
});
// === Global Mute Reaction End ===
