// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  font-size: 15px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
}
.PWAButton {
  border: 1px solid black;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/PWAButton.vue","webpack://./PWAButton.vue"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;ACAF;ADGA;EAEE,uBAAA;ACDF","sourcesContent":["\nh1 {\n  font-size: 15px;\n  font-weight: bold;\n  margin: 0px;\n  padding: 0px;\n}\n\n.PWAButton {\n  // background-color:white;\n  border: 1px solid black;\n}\n","h1 {\n  font-size: 15px;\n  font-weight: bold;\n  margin: 0px;\n  padding: 0px;\n}\n\n.PWAButton {\n  border: 1px solid black;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
