// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vueScrollinviteOneCall[data-v-0028d4dc] {
  height: calc(100% - 250px) !important;
}
.pre[data-v-0028d4dc] {
  white-space: pre;
}
.bolder[data-v-0028d4dc] {
  font-weight: 400;
}
.tightDialog[data-v-0028d4dc] {
  margin-top: 0px;
  padding-top: 0px;
}
.v-text-field[data-v-0028d4dc] {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.v-time-picker-title__time .v-picker__title__btn[data-v-0028d4dc],
.v-time-picker-title__time span[data-v-0028d4dc] {
  height: 40px !important;
  font-size: 30px !important;
}
.autoscroll[data-v-0028d4dc] {
  overflow-y: auto;
}
.inviteTextClass[data-v-0028d4dc] {
  font-size: 13px;
  font-weight: normal;
}
.cursorPointer[data-v-0028d4dc] {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/inviteVisitorModal.vue","webpack://./inviteVisitorModal.vue"],"names":[],"mappings":"AACA;EACE,qCAAA;ACAF;ADEA;EACE,gBAAA;ACCF;ADCA;EACE,gBAAA;ACEF;ADAA;EACE,eAAA;EACA,gBAAA;ACGF;ADDA;EACE,2BAAA;EACA,0BAAA;ACIF;ADFA;;EAEE,uBAAA;EACA,0BAAA;ACKF;ADHA;EACE,gBAAA;ACMF;ADJA;EACE,eAAA;EACA,mBAAA;ACOF;ADLA;EACE,eAAA;ACQF","sourcesContent":["\n.vueScrollinviteOneCall {\n  height: calc(100% - 250px) !important;\n}\n.pre {\n  white-space: pre;\n}\n.bolder {\n  font-weight: 400;\n}\n.tightDialog {\n  margin-top: 0px;\n  padding-top: 0px;\n}\n.v-text-field {\n  padding-top: 0px !important;\n  margin-top: 0px !important;\n}\n.v-time-picker-title__time .v-picker__title__btn,\n.v-time-picker-title__time span {\n  height: 40px !important;\n  font-size: 30px !important;\n}\n.autoscroll {\n  overflow-y: auto;\n}\n.inviteTextClass {\n  font-size: 13px;\n  font-weight: normal;\n}\n.cursorPointer {\n  cursor: pointer;\n}\n",".vueScrollinviteOneCall {\n  height: calc(100% - 250px) !important;\n}\n\n.pre {\n  white-space: pre;\n}\n\n.bolder {\n  font-weight: 400;\n}\n\n.tightDialog {\n  margin-top: 0px;\n  padding-top: 0px;\n}\n\n.v-text-field {\n  padding-top: 0px !important;\n  margin-top: 0px !important;\n}\n\n.v-time-picker-title__time .v-picker__title__btn,\n.v-time-picker-title__time span {\n  height: 40px !important;\n  font-size: 30px !important;\n}\n\n.autoscroll {\n  overflow-y: auto;\n}\n\n.inviteTextClass {\n  font-size: 13px;\n  font-weight: normal;\n}\n\n.cursorPointer {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
