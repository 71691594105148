// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deleteUserFromList[data-v-1f67df27] {
  font-size: 18px;
  right: 5px;
  top: 5px;
  position: absolute;
  z-index: 5;
}
.relative[data-v-1f67df27] {
  position: relative;
}
.buttonOnPic[data-v-1f67df27] {
  position: absolute;
  left: calc(50% - 10px);
  top: 69px;
  color: white;
}
.rejectedUser[data-v-1f67df27] {
  filter: grayscale(1);
}
.marginTop40[data-v-1f67df27] {
  margin-top: 40%;
}
.marginTop23[data-v-1f67df27] {
  margin-top: 23%;
}`, "",{"version":3,"sources":["webpack://./websrc/components/userDisplay/userDisplay.vue","webpack://./userDisplay.vue"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,UAAA;EACA,QAAA;EACA,kBAAA;EACA,UAAA;ACAF;ADEA;EACE,kBAAA;ACCF;ADCA;EACE,kBAAA;EACA,sBAAA;EACA,SAAA;EACA,YAAA;ACEF;ADAA;EACE,oBAAA;ACGF;ADDA;EACE,eAAA;ACIF;ADFA;EACE,eAAA;ACKF","sourcesContent":["\n.deleteUserFromList{\n  font-size: 18px;\n  right: 5px;\n  top: 5px;\n  position: absolute;\n  z-index: 5;\n}\n.relative{\n  position: relative;\n}\n.buttonOnPic{\n  position: absolute;\n  left: calc(50% - 10px);\n  top: 69px;\n  color: white; \n}\n.rejectedUser{\n  filter: grayscale(1);\n}\n.marginTop40{\n  margin-top: 40%;\n}\n.marginTop23{\n  margin-top: 23%;\n}\n// .userDisplay {\n// border: 1px solid black;\n// border-radius: 5px;\n// padding: 5px;\n// margin: 5px;\n// }\n",".deleteUserFromList {\n  font-size: 18px;\n  right: 5px;\n  top: 5px;\n  position: absolute;\n  z-index: 5;\n}\n\n.relative {\n  position: relative;\n}\n\n.buttonOnPic {\n  position: absolute;\n  left: calc(50% - 10px);\n  top: 69px;\n  color: white;\n}\n\n.rejectedUser {\n  filter: grayscale(1);\n}\n\n.marginTop40 {\n  margin-top: 40%;\n}\n\n.marginTop23 {\n  margin-top: 23%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
