import { ownUserUUIDState } from '../users';
// import { clearPresenceWatching } from '../../lib/wsMsg';
import { wsConnectedEvent } from '../ws';

import {
  fillAllConferencesEvent,
  fillMyConferencesEvent,
  newConferenceEvent,

} from './events';

import {
  fetchAllConferencesEffect,
  createConferenceEntryEffect,
  // clearAllConferencesEffect,
  deleteConferenceEffect,
  deleteGroupFromUserEffect,
  // updateConferenceEffect,
} from './effects';

// import {
//   allConferencesStore,
//   myConferencesStore,
// } from './state';

// import {
//   dispatchErrorAlert,
// } from '../alerts';

wsConnectedEvent.watch(() => {
// get all entries from
//clearAllConferencesEffect()
fetchAllConferencesEffect()
   
});

newConferenceEvent.watch((payload) => {
  // console.log('conf init eff', payload)
  createConferenceEntryEffect(payload);
});

fetchAllConferencesEffect.done.watch(({ params, result }) => {
  const ownUUID = ownUserUUIDState.getState();
  // console.log('all confs ', result)
  fillAllConferencesEvent(result);
  fillMyConferencesEvent(result[ownUUID] || [])
});

deleteConferenceEffect.done.watch(({ params, result }) => {
  // console.log('back from delete ', result, params );
  deleteGroupFromUserEffect(params);
});

deleteGroupFromUserEffect.done.watch(({ params, result }) => {
  // console.log('after deleting group',params, result);
});

deleteGroupFromUserEffect.fail.watch(({ error, params }) => {
  console.warn('error deleting group',error.message, params);
});

deleteConferenceEffect.done.watch(({ params, result }) => {
  // console.log('group removed ', params, result);
});  
