import { combine, forward } from 'effector';
import { callDomain } from './domain';

import { setCallInfoStateFromManagerEvent } from './events';
import { ownUserUUIDState } from '../users';

// For public access to current call info state by vue et al
export const callInfoStateStore = callDomain.createStore({}, { name: 'callInfoState' });

callInfoStateStore.on(setCallInfoStateFromManagerEvent, (state, payload) => {
  return payload;
});

// Debug
callInfoStateStore.watch((s) => {
  // console.log('callInfoStateStore', s);
});

const allowedToTalkMapActual = callInfoStateStore.map((state) => { return (state || {}).allowedToTalk || []; });
export const allowedToTalkMap = callDomain.createStore([], { name: 'allowedToTalkMap' });
forward({ from: allowedToTalkMapActual, to: allowedToTalkMap });

const notAllowedToTalkMapActual = callInfoStateStore.map((state) => { return (state || {}).notAllowedToTalk || []; });
export const notAllowedToTalkMap = callDomain.createStore([], { name: 'notAllowedToTalkMap' });
forward({ from: notAllowedToTalkMapActual, to: notAllowedToTalkMap });

const amMutedByGlobalCombine = combine([ownUserUUIDState, allowedToTalkMap, notAllowedToTalkMap], ([ownUUID, allowedToTalk, notAllowedToTalk]) => {
  if (!ownUUID || !allowedToTalk || !notAllowedToTalk) return;
  if (notAllowedToTalk.length) {
     // Selective ute Set
     if (notAllowedToTalk.indexOf(ownUUID) !== -1) {
      // We are not allowed to talk
      return true;
    }
  }
  if (allowedToTalk.length) {
    // Mute Set
    if (allowedToTalk.indexOf(ownUUID) !== -1) {
      // We are allowed to talk
      return false;
    } else {
      // We are not allowed to talk
      return true;
    }
  } else {
    // Mute Unset
    return false;
  }
});

export const amMutedByGlobal = callDomain.createStore({}, { name: 'amMutedByGlobal' });
forward({ from: amMutedByGlobalCombine, to: amMutedByGlobal });

export const activeCallUUID = callInfoStateStore.map((state) => { return (state || {}).callUUID; });
export const ownerUUID = callInfoStateStore.map((state) => { return (state || {}).ownerUUID; });
export const haveActiveCall = callInfoStateStore.map((state) => { return (state || {}).callActive || false; });
