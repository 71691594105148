<template>
  <div class="domain">
    <!-- <span>Domain {{name}}</span>
    <DomainComp :domain="d" v-for="d in domains" :key="d.shortName" />
    <StoreComp :store="s" v-for="s in stores" :key="s.shortName" />
    <EventComp :event="e" v-for="e in events" :key="e.shortName" />
    <EffectComp :effect="f" v-for="f in effects" :key="f.shortName" /> -->

    <v-expansion-panels>
      <v-expansion-panel :title="`Domain ${name}`">
        <v-expansion-panel-text>
          <DomainComp :domain="d" v-for="d in domains" :key="d.shortName" />
          <StoreComp :store="s" v-for="s in stores" :key="s.shortName" />
          <EventComp :event="e" v-for="e in events" :key="e.shortName" />
          <EffectComp :effect="f" v-for="f in effects" :key="f.shortName" />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
// import DomainComp from './domain.vue';
import StoreComp from './store.vue';
import EventComp from './event.vue';
import EffectComp from './effect.vue';
export default {
  name: 'DomainComp',
  props: ['domain'],
  components: { StoreComp, EventComp, EffectComp },
  data() {
    return {
      domains: [],
      stores: [],
      events: [],
      effects: [],
    };
  },
  methods: {
    onCreateDomain(domain) {
      if (domain.parent !== this.domain) return;
      if (this.domains.indexOf(domain) === -1) this.domains.push(domain);
    },
    onCreateStore(store) {
      if (store.parent !== this.domain) return;
      if (this.stores.indexOf(store) === -1) this.stores.push(store);
    },
    onCreateEvent(event) {
      if (event.parent !== this.domain) return;
      if (this.events.indexOf(event) === -1) this.events.push(event);
    },
    onCreateEffect(effect) {
      if (effect.parent !== this.domain) return;
      if (this.effects.indexOf(effect) === -1) this.effects.push(effect);
    },
    subscribe() {
      this.unsubscribe();
      if (!this.domain) return;
      this._unsub.push(this.domain.onCreateDomain(this.onCreateDomain.bind(this)));
      this._unsub.push(this.domain.onCreateStore(this.onCreateStore.bind(this)));
      this._unsub.push(this.domain.onCreateEvent(this.onCreateEvent.bind(this)));
      this._unsub.push(this.domain.onCreateEffect(this.onCreateEffect.bind(this)));

      for (let e of this.domain.history.domains) { if (e.parent === this.domain && this.domains.indexOf(e) === -1) this.domains.push(e) };
      for (let e of this.domain.history.stores) { if (e.parent === this.domain && this.stores.indexOf(e) === -1) this.stores.push(e) };
      for (let e of this.domain.history.events) { if (e.parent === this.domain && this.events.indexOf(e) === -1) this.events.push(e) };
      for (let e of this.domain.history.effects) { if (e.parent === this.domain && this.effects.indexOf(e) === -1) this.effects.push(e) };
    },
    unsubscribe() {
      if (this._unsub) {
        for (const u of this._unsub) {
          try {
            u();
          } catch (err) { console.warn('unsub error', err); }
        }
      }
      this._unsub = [];
    },
  },
  computed: {
    name() {
      if (!this.domain) return '';
      return this.domain.shortName;
    }
  },
  mounted() {
    this.subscribe();
  },
  unmounted() {
    this.unsubscribe();
  },
  watch: {
    domain() { this.subscribe(); },
  }
};
</script>

<style scoped lang="scss">
.domain {
  border: 1px solid blue;
  padding: 3px;
  margin: 2px;
}
</style>