const { EventEmitter } = require('events');
const uniqid = require('uniqid');
const hasOwn = Object.prototype.hasOwnProperty;

class RpcManager extends EventEmitter {
  constructor (options = {}) {
    super();
    this.log = console.log.bind(console, 'RpcManager');
    this._rpcHandlers = {};
    this._waitingRpc = {};
    this.timeout = options.timeout || 60000;
  }

  // Local handlers
  addHandler (funcName, func) {
    this._rpcHandlers[funcName] = func;
  }

  async handle (func, args) {
    if (!func || typeof func !== 'string' || !Array.isArray(args)) throw new TypeError('Invalid RPC Call');
    if (!hasOwn.call(this._rpcHandlers, func) || typeof this._rpcHandlers[func] !== 'function') throw new Error('Handler not found for ' + func);
    return this._rpcHandlers[func](...args);
  }

  async handleRequest (request) {
    const retVal = {};
    try {
      retVal.echo = request.echo;
      retVal.ret = await this.handle(request.func, request.args || []);
    } catch (err) {
      retVal.error = err.message;
      this.log('handleRequest Error:', err.message);
    }
    this._emitAnswer(retVal);
    return retVal;
  }

  _emitRequest (request) {
    this.emit('request', request);
  }

  _emitAnswer (answer) {
    this.emit('answer', answer);
  }

  // Remote Invocation
  rpc (func, ...args) {
    return new Promise((resolve, reject) => {
      const echo = 'rpc-' + uniqid();

      const clear = () => {
        clearTimeout(this._waitingRpc[echo].timeout);
        delete this._waitingRpc[echo];
      };

      const timeout = setTimeout(() => {
        clear();
        reject(new Error('Timeout'));
      }, this.timeout);

      this._waitingRpc[echo] = {
        resolve: (...args) => {
          clear();
          resolve(...args);
        },
        reject: (error) => {
          clear();
          reject(error);
        },
        timeout
      };
      this._emitRequest({
        func,
        args,
        echo
      });
    });
  }

  handleAnswer (answer) {
    try {
      if (!this._waitingRpc[answer.echo]) {
        this.log('Rogue Answer:', answer.echo);
      } else if (answer.error) {
        this._waitingRpc[answer.echo].reject(new Error('RPC Remote Error: ' + answer.error));
      } else {
        this._waitingRpc[answer.echo].resolve(answer.ret);
      }
    } catch (err) {
      this.log('Failed to handle RPC Answer:', err, answer);
    }
  }
}

export default RpcManager;
