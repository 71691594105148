// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conditionsContent[data-v-63a17258] {
  max-height: calc(100vh - 152px);
  text-align: left !important;
  white-space: pre-line;
}
.vueScrollConditions[data-v-63a17258] {
  max-height: calc(100% - 90px);
}`, "",{"version":3,"sources":["webpack://./websrc/components/payment/payment-modals/modalConditions.vue","webpack://./modalConditions.vue"],"names":[],"mappings":"AACA;EACI,+BAAA;EACA,2BAAA;EACA,qBAAA;ACAJ;ADEA;EACE,6BAAA;ACCF","sourcesContent":["\n.conditionsContent{\n    max-height: calc(100vh - 152px);\n    text-align: left !important;\n    white-space: pre-line;\n}\n.vueScrollConditions{\n  max-height: calc(100% - 90px);\n}\n",".conditionsContent {\n  max-height: calc(100vh - 152px);\n  text-align: left !important;\n  white-space: pre-line;\n}\n\n.vueScrollConditions {\n  max-height: calc(100% - 90px);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
