import { createStore } from 'effector';
// import { EventBus } from "../../../store";
import { setRequestedPaymentEffector } from "./effects";
import { setRequestPaymentModal, setRequestPaymentEventModal, setPaymentSuccessEventModal, setPaymentInProgressModal, setSuccessPayment, setPaymentInProgressEventModal, setRequestedPaymentEventModal } from './events';

export const paymentModalStore = createStore(false);
export const paymentEventModalStore = createStore(false);
export const successPaymentEventModalStore = createStore(false);
export const paymentInProgressModalStore = createStore(false);
export const successPaymentStore = createStore(false);
export const requestPaymentStore = createStore(false);

requestPaymentStore.on(setRequestedPaymentEventModal, (state, payload) => {
  // console.log('Paylooooad!!!!!', payload)
  return payload;
});

successPaymentStore.on(setSuccessPayment, (state, payload) => {
  return payload;
})

paymentInProgressModalStore.on(setPaymentInProgressModal, (state, payload) => {
  return payload;
});
paymentInProgressModalStore.on(setPaymentInProgressEventModal, (state, payload) => {
  return payload;
})

paymentInProgressModalStore.watch((state) => {
})

paymentModalStore.on(setRequestPaymentModal, (state, payload) => {
  return payload;
});

paymentModalStore.watch((state) => {
  // console.log('paymentModalStore', state);
});

paymentEventModalStore.on(setRequestPaymentEventModal, (state, payload) => {
  //console.log('STAATE !!!!!! ', state, 'payliad!!', payload);
  return payload;
});

paymentEventModalStore.watch((state) => {
  // console.log('paymentEventModalStore', state);
});

paymentEventModalStore.on(setPaymentSuccessEventModal, (state, payload) => {
  // console.log('STAATE !!!!!! ', state, 'payliad!!', payload);
  setRequestedPaymentEffector(payload)
  return payload;
});

successPaymentEventModalStore.watch((state) => {

});

// requestPaymentEvent.watch((payload) => {
//   console.log('requestPaymentEvent State', payload);
//   EventBus.$emit('paymentRequested', payload);
// });
