<template>
  <RegularGrid :typeData="'group'" />
</template>

<script>
import store from "../../store";
import RegularGrid from "./regularGrid.vue"
export default {
  components: { RegularGrid },
  data() {
    return {
      state: store.state,
    };
  },
  methods: {
  
  },

  computed: {
   
  },
  mounted: function() {

  }
};
</script>

<style scoped lang="scss">

</style>