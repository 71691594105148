// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dayTimeOverlay[data-v-fa8da116] {
  position: absolute;
  top: 0px;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
}
.dayTimeOverlay .yellow-star[data-v-fa8da116] {
  color: yellow;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/sambaRoomOverlay.vue","webpack://./sambaRoomOverlay.vue"],"names":[],"mappings":"AACA;EAEE,kBAAA;EACA,QAAA;EACA,8BAAA;EACA,eAAA;EACA,WAAA;ACDF;ADGE;EACE,aAAA;ACDJ","sourcesContent":["\n.dayTimeOverlay {\n  // background-color: rgba(0, 0, 0, 0.50);\n  position: absolute;\n  top: 0px;\n  justify-content: space-between;\n  font-size: 14px;\n  width: 100%;\n\n  .yellow-star {\n    color: yellow;\n  }\n}\n",".dayTimeOverlay {\n  position: absolute;\n  top: 0px;\n  justify-content: space-between;\n  font-size: 14px;\n  width: 100%;\n}\n.dayTimeOverlay .yellow-star {\n  color: yellow;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
