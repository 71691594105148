// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InviteFormContainer .v-card[data-v-359df5ce] {
  box-shadow: none;
}
.InviteFormContainer[data-v-359df5ce] {
  min-height: calc(100vh - 124px);
  height: 100%;
  position: relative;
}
.h100[data-v-359df5ce] {
  height: 100%;
}
.overflow-y-auto[data-v-359df5ce] {
  overflow-y: auto !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/sambaRoomsContainer.vue","webpack://./sambaRoomsContainer.vue"],"names":[],"mappings":"AACA;EACE,gBAAA;ACAF;ADEA;EACE,+BAAA;EACA,YAAA;EAEA,kBAAA;ACAF;ADEA;EACE,YAAA;ACCF;ADCA;EACE,2BAAA;ACEF","sourcesContent":["\n.InviteFormContainer .v-card {\n  box-shadow: none;\n}\n.InviteFormContainer {\n  min-height: calc(100vh - 124px);\n  height: 100%;\n  // overflow: auto;\n  position: relative;\n}\n.h100 {\n  height: 100%;\n}\n.overflow-y-auto{\n  overflow-y: auto !important;\n}\n",".InviteFormContainer .v-card {\n  box-shadow: none;\n}\n\n.InviteFormContainer {\n  min-height: calc(100vh - 124px);\n  height: 100%;\n  position: relative;\n}\n\n.h100 {\n  height: 100%;\n}\n\n.overflow-y-auto {\n  overflow-y: auto !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
