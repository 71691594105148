// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.whiteColor[data-v-53c9b030] {
  color: white;
}
.buttonColor[data-v-53c9b030] {
  background-color: #ffa500 !important;
}
#returnToCall[data-v-53c9b030] {
  animation: blink-53c9b030 1s linear infinite;
}
@keyframes blink-53c9b030 {
0% {
    opacity: 0;
}
50% {
    opacity: 0.5;
}
100% {
    opacity: 1;
}
}`, "",{"version":3,"sources":["webpack://./websrc/components/navbar/returnToCall.vue","webpack://./returnToCall.vue"],"names":[],"mappings":"AACA;EACE,YAAA;ACAF;ADEA;EACE,oCAAA;ACCF;ADCA;EACE,4CAAA;ACEF;ADAA;AACE;IACE,UAAA;ACGF;ADDA;IACE,YAAA;ACGF;ADDA;IACE,UAAA;ACGF;AACF","sourcesContent":["\n.whiteColor {\n  color: white;\n}\n.buttonColor {\n  background-color: #ffa500 !important;\n}\n#returnToCall {\n  animation: blink 1s linear infinite;\n}\n@keyframes blink {\n  0% {\n    opacity: 0;\n  }\n  50% {\n    opacity: 0.5;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n",".whiteColor {\n  color: white;\n}\n\n.buttonColor {\n  background-color: #ffa500 !important;\n}\n\n#returnToCall {\n  animation: blink 1s linear infinite;\n}\n\n@keyframes blink {\n  0% {\n    opacity: 0;\n  }\n  50% {\n    opacity: 0.5;\n  }\n  100% {\n    opacity: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
