<style scoped>
/* @import 'splitpanes/dist/splitpanes.css'; */
</style>


<template>
  <div
    style="z-index:999"
    id="video-container"
    class="absolute"
    v-bind:class="{'d-none': state.currentContentVisile.showComponent || Object.keys(state.remoteStreams).length === 0}"
  >
    <div class="progressBar" v-if="!infiniteCall">
      <!-- <ProgressLinear
        :click="()=>addMoreTime()"
        :classeProgress="`${(state.currentTS - state.meetingStartAt) >= (state.durationMeeting - 60000) ? 'progressBarBlink': ''}`"
        :value="meetingTime"
        :timerCall="timerCall"
        :elapseTime="elapseTime"
        :originalCallTime="formatTime(state.durationMeeting)"
      /> -->
      <ProgressLinear />
    </div>
    <!-- <div
      class="messageToParticipants messageToParticipantsBlink"
      v-if="state.messageToParticipants.audio || state.messageToParticipants.sound"
    >
      <div v-if="state.messageToParticipants.audio">{{state.messageToParticipants.audio}}</div>
      <div v-if="state.messageToParticipants.sound">{{state.messageToParticipants.sound}}</div>
    </div> -->
    <div
                class="messageToParticipants messageToParticipantsBlink"
                v-if="isLocalAudioMuted || amISoundMuted"
              >
                <div v-if="isLocalAudioMuted">
                  {{ $t("components.callsContent.audioMessage") }}
                </div>
                <div v-if="amISoundMuted">
                  {{ $t("components.callsContent.soundMessage") }}
                </div>
              </div>

    <div
      class="mainDivRemote"
      :style="{ backgroundImage: 'url(' + backgroundPresentation + ')' }"
      :class="`${!infiniteCall ?' heightTop' :''}`"
      v-if="state.localStreams.display && Object.keys(state.remoteStreams).length === 4 || (state.presentationView.owner !== state.ownUUID && (state.remoteStreams[state.presentationView.owner] || {}).second && Object.keys(state.remoteStreams).length === 4) || remoteFullSizeJustVideoLeftSide"
    >
      <VideoSharingScreen
        v-if="state.localStreams.display && Object.keys(state.remoteStreams).length === 4"
      />
      <RemotePersonJustVideo
        :uuid="state.presentationView.owner"
        :remote="state.remoteStreams[state.presentationView.owner]"
        :isLocalSoundMuted="isLocalSoundMuted"
        v-if="state.presentationView.owner !== state.ownUUID && (state.remoteStreams[state.presentationView.owner] || {}).second && Object.keys(state.remoteStreams).length === 4"
      />
      <RemotePersonJustVideo
        :setFullSizeVideo="setFullSizeVideo"
        :uuid="state.remoteToFullSizeUUID"
        :remote="state.remoteStreams[state.remoteToFullSizeUUID]"
        :isLocalSoundMuted="isLocalSoundMuted"
        v-if="remoteFullSizeJustVideoLeftSide"
      />
    </div>

    <div
      class="mainDivCall default-theme"
      :class="{'mt-6' : !infiniteCall, 'mainDivCall2Columns': state.localStreams.display && Object.keys(state.remoteStreams).length === 4 || (state.presentationView.owner !== state.ownUUID && (state.remoteStreams[state.presentationView.owner] || {}).second && Object.keys(state.remoteStreams).length === 4)}"
       ref="videos"
    >
      <div
        ref="localVideoDiv"
        class="relative localVideo"
        v-if="(state.ownUUID === state.presentationView.owner && !isRemoteFullSize) || (isRemoteFullSize && state.remoteToFullSizeUUID == state.ownUUID)"
      >
        <div class="callerName">
          <!-- <span>{{isVisitor(state.ownUUID) ?  $t('components.userSettings.guest')+ ' - ' : ''}} {{getTitelForUuid(state.ownUUID)}} {{getNameForUuid(state.ownUUID)}}  {{isVisitor(state.ownUUID) ? ' - ' +getSubjectVisitor(state.ownUUID) : ''}}</span> -->
                    <span>{{isVisitor(state.ownUUID) ?  $t('components.userSettings.guest')+ ' - ' : ''}} {{getTitelForUuid(state.ownUUID)}} {{getNameForUuid(state.ownUUID)}}  </span>
          <!-- <span class="italicFont" v-if="state.ownUUID === ownerUUID">Moderator</span> -->
          <!-- v-if="ownerUUID === state.ownUUID" -->
           <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="italicFont" style="float: right;margin-right:9px" > P2P</span>
            </template>
              <span>{{ $t('components.remotePersonCall.p2p')}}</span>
          </v-tooltip>

          <!-- <v-tooltip location="top" v-if="state.ownUUID === ownerUUID">
            <template v-slot:activator="{ on }">
              <span v-on="on" style="float: left;margin-left:9px" >
                <font-awesome-icon
                  v-on="on"
                  :icon="['fal', 'users-crown']"
                  :style="{ fontSize: '20px' }"
                />
              </span>
            </template>
            <span>{{ $t("components.remotePersonCall.moderator") }}</span>
          </v-tooltip> -->
          <span  v-if="state.ownUUID === ownerUUID" style="float: left;margin-left:9px">Moderator</span>

            <v-tooltip location="top" v-if="state.ownUUID !== ownerUUID && ( !isWaitingRoomUser(state.ownUUID) && !isVisitor(state.ownUUID) )">
            <template v-slot:activator="{ on }">
              <span v-on="on" style="float: left;margin-left:9px" >
                <font-awesome-icon
                  v-on="on"
                  :icon="['fal', 'user-crown']"
                  :style="{ fontSize: '20px' }"
                />
              </span>
            </template>
            <span>{{ $t("components.remotePersonCall.user") }}</span>
          </v-tooltip>

          <v-tooltip location="top" v-if="state.ownUUID !== ownerUUID && ( isWaitingRoomUser(state.ownUUID) || isVisitor(state.ownUUID) )">
            <template v-slot:activator="{ on }">
              <span v-on="on" style="float: left;margin-left:9px" >
                <font-awesome-icon
                  v-on="on"
                  :icon="['fal', 'user-friends']"
                  :style="{ fontSize: '20px' }"
                />
              </span>
            </template>
            <span>{{ $t("components.remotePersonCall.guest") }}</span>
          </v-tooltip>

        </div>

        <div>
          <AudioStreamMeter :canvasId="'canvasAudioCallsContentPresentation'" :stream="state.localStreams.user" />
          <AudioStreamMeter :canvasId="'canvasAudioCallsContentPresentation'" :stream="state.localStreams.display" />
        </div>

         <!-- <span v-if="state.ownUUID === ownerUUID" class="italicFont videoIdTag" >{{callInfo.callUUID || ''}}</span> -->
        <video
          id="local-video"
          v-if="!state.localStreams.display && !this.state.persisted.useBlurEffect"
          playsinline
          autoplay="autoplay"
          :src-object.prop.camel="state.localStreams.user"
          v-bind:class="{ 'd-none': !haveLocalVideo }"
          muted
        ></video>
        <StreamCanvas
          id="local-video"
          v-if="!state.localStreams.display && this.state.persisted.useBlurEffect"
          v-bind:class="{ 'd-none': !haveLocalVideo }"
          :stream="state.localStreams.user"
        ></StreamCanvas>

        <video
          id="local-screen"
          playsinline
          autoplay="autoplay"
          :src-object.prop.camel="state.localStreams.display"
          v-bind:class="{ 'd-none': !state.localStreams.display }"
          muted
        ></video>
        <div class="noCameraStream" v-bind:class="{'d-none': haveLocalVideo}">
          <div class="noCameraContent">
            <v-img
              class="imageNotVideo"
              contain
              :src="getAvatarFromUuid(state.ownUUID)"
              lazy-src="assets/default_profile_picture.png"
            ></v-img>
            <div class="emptyDiv"></div>
          </div>
        </div>

        <div class="testMeSomehow" v-if="showTypingInfo.show" :class="{'active': showTypingInfo.show}">
          {{getNameForUuid(showTypingInfo.from)}} {{$t("components.callsContent.isTyping")}} ...
        </div>
        <div
          v-bind:class="{ hidden: Object.keys(state.remoteStreams).length == 0, 'buttonsIpad': isIpad && isIos}"
          class="buttonsUser"
        >
          <!-- <div class="divButtonsLocal"> -->
          <template>
            <v-list v-if="openCameraQuality" class="pt-0 cameraQuality">
              <v-list-item-group color="primary">
                <div class="d-flex justify-end blueBackground">
                  <v-btn icon @click="openCameraQuality = false">
                    <font-awesome-icon
                      :icon="['fal', 'times']"
                      :style="{ fontSize: '15px' }"
                      v-if="state.persisted.rtcVideo"
                      color="white"
                    />
                  </v-btn>
                </div>
                <v-list-item @click="setQuality('high')">
                  <v-list-item-content>
                    <v-list-item-title>{{$t("components.camAndMic.high")}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="setQuality('medium')">
                  <v-list-item-content>
                    <v-list-item-title>{{$t("components.camAndMic.medium")}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="setQuality('low')">
                  <v-list-item-content>
                    <v-list-item-title>{{$t("components.camAndMic.low")}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </template>
          <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="white"
                v-long-press="300"
                @long-press-start="openMenuQuality"
                @long-press-stop="onLongPressStop"
                icon
                @click="tryWebcam()"
                class="mx-3"
              >
                <font-awesome-icon
                  :icon="['fal', 'video']"
                  :style="{ fontSize: '20px' }"
                  v-if="state.persisted.rtcVideo"
                />
                <font-awesome-icon
                  :icon="['fal', 'video-slash']"
                  :style="{ fontSize: '20px' }"
                  v-if="!state.persisted.rtcVideo"
                />
              </v-btn>
            </template>
            <span>{{ $t('generics.camera') }}</span>
          </v-tooltip>
          <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="white" icon @click="toggleMuteLocalAudio()"  
              :disabled="amIMutedByAdmin" 
              class="mx-3">
                <font-awesome-icon
                  :icon="['fal', 'microphone']"
                  :style="{ fontSize: '20px' }"
                  v-if="!isLocalAudioMuted"
                />
                <font-awesome-icon
                  :icon="['fal', 'microphone-slash']"
                  :style="{ fontSize: '20px' }"
                  v-if="isLocalAudioMuted"
                />
                <canvas id="canvasAudioCallsContentPresentation" width="50" height="15" :class="{hidden: isLocalAudioMuted}"/>
              </v-btn>
            </template>
            <span>{{ $t('components.callsContent.mic') }}</span>
          </v-tooltip>

          <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="white" icon @click="toggleMuteSound()" class="mx-3">
                <font-awesome-icon
                  :icon="['fal', 'volume-up']"
                  :style="{ fontSize: '20px' }"
                  v-if="!isLocalSoundMuted"
                />
                <font-awesome-icon
                  :icon="['fal', 'volume-slash']"
                  :style="{ fontSize: '20px' }"
                  v-if="isLocalSoundMuted"
                />
              </v-btn>
            </template>
            <span>{{ $t('components.callsContent.loudspeaker') }}</span>
          </v-tooltip>

          <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="white"
                icon
                class="redBtn mx-3"
                @click="rtcHangup()"
                ref="cancelCall"
              >
                <font-awesome-icon
                  :icon="['fal', 'phone']"
                  class="faPhoneRotate"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </template>
            <span>{{ $t('generics.hangUp') }}</span>
          </v-tooltip>

          <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="white"
                icon
                @click="state.localStreams.display ? closeDisplayMedia() : tryScreenShare()"
                class="mx-3"
              >
                <font-awesome-icon :icon="['fal', 'desktop']" :style="{ fontSize: '20px' }" />
                <font-awesome-icon
                  :icon="['fal', 'slash']"
                  :style="{ fontSize: '20px', position: 'absolute', top: -1, left: 5 }"
                  v-if="!state.localStreams.display"
                />
              </v-btn>
            </template>
            <span>{{ $t('components.callsContent.shareScreen') }}</span>
          </v-tooltip>

          <v-tooltip location="top" v-if="state.ownUUID === state.presentationView.owner">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="white"
                icon
                @click.stop.prevent="hasOnHold ? unholdCall() : holdCall()"
                class="mx-3"
              >
              <font-awesome-icon
                :icon="['fal', 'long-arrow-up']"
                :style="{ fontSize: '20px' }"
                :class="{'progressBarBlink': hasOnHold}"
                :color="hasOnHold ? 'red' : 'white'"
              />
              <font-awesome-icon :color="hasOnHold ? 'red' : 'white'" :class="{'progressBarBlink': hasOnHold}" :icon="['fal', 'long-arrow-up']" class="rotatedArrow" />
              </v-btn>
            </template>
             <span>{{$t('components.userListItem.forwarding')}}</span>
          </v-tooltip>

          <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="white"
                icon
                @click="toggleModalSettings"
                class="mx-3"
              >
                <font-awesome-icon :icon="['fal', 'cog']" :style="{ fontSize: '20px' }" />
              </v-btn>
            </template>
            <span>{{$t("components.navbar.camAndMic")}}</span>
          </v-tooltip>

          <!-- <v-tooltip location="top" v-if="state.ownUUID === state.presentationView.owner && hasOnHold">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="white"
                icon
                @click.stop.prevent="unholdCall()"
                class="mx-3"
              >
                <font-awesome-icon :icon="['fal', 'play']" :style="{ fontSize: '20px' }" />
              </v-btn>
            </template>
            <span>Resume call</span>
          </v-tooltip> -->

          <!-- <v-tooltip location="top" v-if="state.ownUUID === state.presentationView.owner && hasOnHold">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="white"
                icon
                @click.stop.prevent="transferCall()"
                class="mx-3"
              >
                <font-awesome-icon
                  :icon="['fal', 'long-arrow-up']"
                  :style="{ fontSize: '20px' }"
                />
                <font-awesome-icon :icon="['fal', 'long-arrow-up']" class="rotatedArrow" />
              </v-btn>
            </template>
            <span>{{ $t('components.userListItem.forwarding') }}</span>
          </v-tooltip> -->

          <v-tooltip location="top" v-if="state.ownUUID === state.presentationView.owner">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="white" icon @click="backToGridView()" class="mx-3">
                <font-awesome-icon :icon="['fal', 'th-large']" :style="{ fontSize: '20px' }" />
              </v-btn>
            </template>
            <span>{{ $t('components.callsContent.endPresentationMode') }}</span>
          </v-tooltip>

          <v-tooltip location="top" v-if="state.ownUUID === state.presentationView.owner">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon color="white" @click.stop.prevent="setSendMessageInCallModal" class="mx-3">
                <font-awesome-icon :icon="['fal', 'paper-plane']" :style="{ fontSize: '20px' }" />
                <!-- <font-awesome-icon
                    :icon="['fal', 'lock']"
                    :style="{ fontSize: '14px' }"
                    class="lockMessageIcon"
                  /> -->
              </v-btn>
            </template>
            <span>{{$t("components.bridgeCallTooltips.messageAll")}}</span>
          </v-tooltip>

          <!-- <v-menu offset-y scrollable :close-on-click="true"  v-if="isBlurEffectEnabled">
            <template v-slot:activator="{ on }">
              <v-btn icon color="white" v-on="on">
                <font-awesome-icon :icon="['fal', 'ellipsis-v']" :style="{ fontSize: '30px' }" />
              </v-btn>
            </template>

            <v-list>
              <v-list-item class="cursorPointer" @click="toggleUseBlurEffect" >
                <v-list-item-title>{{ $t('components.userListItem.blurEffect') }} {{ this.state.persisted.useBlurEffect ? $t('components.userListItem.on') : $t('components.userListItem.off') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>-->

          <!-- <v-tooltip location="top" v-if="isBlurEffectEnabled">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="white"
                icon
                @click.stop.prevent="toggleUseBlurEffect"
                class="mx-3"
              >
                <img
                  v-if="!state.persisted.useBlurEffect"
                  src="/img/vo_blur.svg"
                  style="width:30px"
                />
                <img
                  v-if="state.persisted.useBlurEffect"
                  src="/img/vo_no-blur-2.svg"
                  style="width:30px"
                />
              </v-btn>
            </template>
            <span>{{$t('components.callsContent.blurEffect') }}</span>
          </v-tooltip> -->

           <v-tooltip location="top" v-if="state.ownerMeeting && Object.keys(state.remoteStreams).length > 1">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="white" icon @click="toggleMuteGlobalAudio()" class="mx-3">
                 <!-- <font-awesome-icon
                  :icon="['fal', 'microphone']"
                  :style="{ fontSize: '20px' }"
                  v-if="!isGlobalAudioMuted"
                />
                <font-awesome-icon
                  :icon="['fal', 'microphone-slash']"
                  :style="{ fontSize: '20px' }"
                  v-if="isGlobalAudioMuted"
                /> -->
                  <!-- v-if="isGlobalAudioMuted" -->
                <font-awesome-icon
                  :icon="['fal', 'user-friends']"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </template>
            <span>{{$t('components.remotePersonCall.muteAll') }}</span>
          </v-tooltip>

          <!-- </div> -->
        </div>
      </div>

      <!-- Owner Cll -->
      <div
        ref="localVideoDiv"
        class="relative localVideo TestMeeeee"
        v-if="state.ownUUID !== state.presentationView.owner && !isRemoteFullSize"
      >
        <div class="onHoldOverlay" v-if="hasOnHold">
          <font-awesome-icon class="onHoldCenterIcon" :icon="['fal', 'phone-plus']" :style="{ fontSize: '45px' }" />
          <div class="messageToParticipants messageToParticipantsBlink">{{ $t('components.remotePersonCall.onHoldMessage')}}</div>
        </div>

        <div class="callerName">
          <span>{{isVisitor(state.presentationView.owner) ?  $t('components.userSettings.guest')+ ' - ' : ''}} {{getTitelForUuid(state.presentationView.owner)}} {{getNameForUuid(state.presentationView.owner)}} </span>
          <!-- <span class="italicFont" v-if="state.presentationView.owner === ownerUUID">Moderator</span> -->
          <!-- v-if="state.presentationView.owner === ownerUUID" -->
          <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="italicFont" style="float: right;margin-right:9px" > P2P</span>
            </template>
              <span>{{ $t('components.remotePersonCall.p2p')}}</span>
          </v-tooltip>

          <!-- <v-tooltip location="top"  v-if="state.presentationView.owner === ownerUUID">
            <template v-slot:activator="{ on }">
              <span v-on="on" style="float: left;margin-left:9px" >
                <font-awesome-icon
                  v-on="on"
                  :icon="['fal', 'users-crown']"
                  :style="{ fontSize: '20px' }"
                />
              </span>
            </template>
            <span>{{ $t("components.remotePersonCall.moderator") }}</span>
          </v-tooltip> -->
          <span style="float: left;margin-left:9px" v-if="state.presentationView.owner === ownerUUID">Moderator</span>

            <v-tooltip location="top" v-if="state.presentationView.owner !== ownerUUID && ( !isWaitingRoomUser(state.presentationView.owner) && !isVisitor(state.presentationView.owner) )">
            <template v-slot:activator="{ on }">
              <span v-on="on" style="float: left;margin-left:9px" >
                <font-awesome-icon
                  v-on="on"
                  :icon="['fal', 'user-crown']"
                  :style="{ fontSize: '20px' }"
                />
              </span>
            </template>
            <span>{{ $t("components.remotePersonCall.user") }}</span>
          </v-tooltip>

          <v-tooltip location="top" v-if="state.presentationView.owner !== ownerUUID && ( isWaitingRoomUser(state.presentationView.owner) || isVisitor(state.presentationView.owner) )">
            <template v-slot:activator="{ on }">
              <span v-on="on" style="float: left;margin-left:9px" >
                <font-awesome-icon
                  v-on="on"
                  :icon="['fal', 'user-friends']"
                  :style="{ fontSize: '20px' }"
                />
              </span>
            </template>
            <span>{{ $t("components.remotePersonCall.guest") }}</span>
          </v-tooltip>
          <!-- <span class="italicFont videoIdTag" v-if="state.presentationView.owner === ownerUUID" >{{callInfo.callUUID || ''}}</span> -->
        </div>
        <WebCamDragable v-show="showWebCamDrag && ((state.remoteStreams[state.presentationView.owner] || {}).second)" :userSrc="state.presentationView.owner"/>
        <video
          id="local-video"
          class="local-video"
          autoplay="autoplay"
          :src-object.prop.camel="state.remoteStreams[state.presentationView.owner].first"
          v-bind:class="{ hidden: !state.remoteStreams[state.presentationView.owner].first || !haveRemoteFirstVideo}"
          :muted="isLocalSoundMuted"
          v-if="state.remoteStreams[state.presentationView.owner] && !((state.remoteStreams[state.presentationView.owner] || {}).second)"
          @click.prevent
        ></video>

        <WebCamDragable v-show="showWebCamDrag && ((state.remoteStreams[state.presentationView.owner] || {}).second)" :userSrc="state.ownUUID" :ownVideo="true"/>
        <video
          id="local-screen"
          playsinline
          autoplay="autoplay"
          :src-object.prop.camel="state.remoteStreams[state.presentationView.owner].second"
          v-bind:class="{ hidden: !state.remoteStreams[state.presentationView.owner].second }"
          :muted="isLocalSoundMuted"
          v-if="state.remoteStreams[state.presentationView.owner]"
          @click.prevent
        ></video>

        <div class="noCameraStream" v-bind:class="{'d-none': haveLocalVideo}">
          <div class="noCameraContent">
            <v-img
              class="imageNotVideo"
              contain
              :src="getAvatarFromUuid(state.presentationView.owner)"
              lazy-src="assets/default_profile_picture.png"
            ></v-img>
            <div class="emptyDiv"></div>
          </div>
        </div>

        <div
          v-bind:class="{ hidden: Object.keys(state.remoteStreams).length == 0}"
          class="buttonsUser"
        >
          <v-tooltip
            top
            v-if="Object.keys(state.remoteStreams).length > 1 && state.ownerMeeting && state.ownUUID === state.presentationView.owner"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="white"
                icon
                class="mx-3"
                @click="getWebrtcConnections()[uuid].die(true)"
              >
                <font-awesome-icon
                  :icon="['fal', 'phone']"
                  :style="{ fontSize: '20px' }"
                  class="faPhoneRotate"
                />
              </v-btn>
            </template>
            <span>{{ $t('generics.hangUp') }}</span>
          </v-tooltip>
          <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                color="white"
                @click="setShowModalSenMsg(state.presentationView.owner)"
                class="mx-3"
              >
                <font-awesome-icon :icon="['fal', 'paper-plane']" :style="{ fontSize: '20px' }" />
                <!-- <font-awesome-icon
                  :icon="['fal', 'lock']"
                  :style="{ fontSize: '14px' }"
                  class="lockMessageIcon"
                /> -->
              </v-btn>
            </template>
            <span>{{ $t('components.userProfile.sendAMessage') }}</span>
          </v-tooltip>
          <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                color="white"
                class="mx-3"
                target="_blank"
                :href="`mailto:${getEmailForUuid(state.presentationView.owner)}?subject=${state.user.name}%20from%20vOffice&body=${emailSalutation}%20${getNameForUuid(state.presentationView.owner)},`"
              >
                <font-awesome-icon :icon="['fal', 'envelope-square']" :style="{ fontSize: '24px' }" />
              </v-btn>
            </template>
            <span>{{ $t('components.userListItem.sendEMail') }}</span>
          </v-tooltip>
               <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                color="white"
                class="mx-3"
                target="_blank"
                @click="fullSreen($event)"
              >
                <font-awesome-icon :icon="['fal', 'expand-wide']" :style="{ fontSize: '24px' }" v-if="!fullScreen" />
                <font-awesome-icon :icon="['fas', 'compress']" :style="{ fontSize: '20px' }" v-if="fullScreen" />
              </v-btn>
            </template>
            <span>{{ $t('generics.fullscreen') }}</span>
          </v-tooltip>

          <!-- <span v-if="activeCandidates && (activeCandidates.remote.candidateType === 'relay' || activeCandidates.local.candidateType === 'relay')" :class="activeCandidates && (activeCandidates.remote.candidateType === 'relay' || activeCandidates.local.candidateType === 'relay') ? 'darkGreen' : 'lightGreen'">Relay</span> -->
        </div>
      </div>
      <!-- END OWNER CALL -->

      <!-- VIDEO ON FULL SIZE -->
      <div
        ref="localVideoDiv"
        class="relative localVideo"
        v-if="state.remoteToFullSizeUUID !== state.ownUUID && isRemoteFullSize"
      >
        <div class="callerName">
          <span> {{isVisitor(state.remoteToFullSizeUUID) ?  $t('components.userSettings.guest')+ ' - ' : ''}} {{getTitelForUuid(state.remoteToFullSizeUUID)}} {{getNameForUuid(state.remoteToFullSizeUUID)}}  </span>
          <!-- <span class="italicFont" v-if="state.remoteToFullSizeUUID === ownerUUID">Moderator</span> -->
          <!-- v-if="state.remoteToFullSizeUUID === ownerUUID" -->
          <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="italicFont" style="float: right;margin-right:9px" > P2P</span>
            </template>
              <span>{{ $t('components.remotePersonCall.p2p')}}</span>
          </v-tooltip>
          <!-- <v-tooltip location="top"  v-if="state.remoteToFullSizeUUID === ownerUUID">
            <template v-slot:activator="{ on }">
              <span v-on="on" style="float: left;margin-left:9px" >
                <font-awesome-icon
                  v-on="on"
                  :icon="['fal', 'users-crown']"
                  :style="{ fontSize: '20px' }"
                />
              </span>
            </template>
            <span>{{ $t("components.remotePersonCall.moderator") }}</span>
          </v-tooltip> -->
          <span style="float: left;margin-left:9px" v-if="state.remoteToFullSizeUUID === ownerUUID" >Moderator</span>
          <v-tooltip location="top" v-if="state.remoteToFullSizeUUID !== ownerUUID && ( !isWaitingRoomUser(state.remoteToFullSizeUUID) && !isVisitor(state.remoteToFullSizeUUID) )">
            <template v-slot:activator="{ on }">
              <span v-on="on" style="float: left;margin-left:9px" >
                <font-awesome-icon
                  v-on="on"
                  :icon="['fal', 'user-crown']"
                  :style="{ fontSize: '20px' }"
                />
              </span>
            </template>
            <span>{{ $t("components.remotePersonCall.user") }}</span>
          </v-tooltip>

          <v-tooltip location="top" v-if="state.remoteToFullSizeUUID !== ownerUUID && ( isWaitingRoomUser(state.remoteToFullSizeUUID) || isVisitor(state.remoteToFullSizeUUID) )">
            <template v-slot:activator="{ on }">
              <span v-on="on" style="float: left;margin-left:9px" >
                <font-awesome-icon
                  v-on="on"
                  :icon="['fal', 'user-friends']"
                  :style="{ fontSize: '20px' }"
                />
              </span>
            </template>
            <span>{{ $t("components.remotePersonCall.guest") }}</span>
          </v-tooltip>

          <!-- <span v-if="state.remoteToFullSizeUUID === ownerUUID" class="italicFont videoIdTag" >{{callInfo.callUUID || ''}}</span> -->

        </div>
        <WebCamDragable v-show="showWebCamDrag && ((state.remoteStreams[state.remoteToFullSizeUUID] || {}).second)" :userSrc="state.remoteToFullSizeUUID"/>
        <video
          id="local-video"
          class="local-video"
          autoplay="autoplay"
          :src-object.prop.camel="state.remoteStreams[state.remoteToFullSizeUUID].first"
          v-bind:class="{ hidden: !state.remoteStreams[state.remoteToFullSizeUUID].first || !haveRemoteFullSizeFirstVideo}"
          :muted="isLocalSoundMuted"
          v-if=" state.remoteStreams[state.remoteToFullSizeUUID] && !((state.remoteStreams[state.remoteToFullSizeUUID] || {}).second)"
          @click.prevent
        ></video>

        <WebCamDragable v-show="showWebCamDrag && ((state.remoteStreams[state.remoteToFullSizeUUID] || {}).second)" :userSrc="state.ownUUID" :ownVideo="true" />
        <video
          id="local-screen"
          playsinline
          autoplay="autoplay"
          :src-object.prop.camel="state.remoteStreams[state.remoteToFullSizeUUID].second"
          v-bind:class="{ hidden: !state.remoteStreams[state.remoteToFullSizeUUID].second }"
          :muted="isLocalSoundMuted"
          v-if="state.remoteStreams[state.remoteToFullSizeUUID]"
          @click.prevent
        ></video>

        <div class="noCameraStream" v-bind:class="{'d-none': haveLocalVideo}">
          <div class="noCameraContent">
            <v-img
              class="imageNotVideo"
              contain
              :src="getAvatarFromUuid(state.remoteToFullSizeUUID)"
              lazy-src="assets/default_profile_picture.png"
            ></v-img>
            <div class="emptyDiv"></div>
          </div>
        </div>
        <div
          v-bind:class="{ hidden: Object.keys(state.remoteStreams).length == 0}"
          class="buttonsUser"
          id="secondaryButtonUser"
        >
         <!-- && state.ownUUID === state.presentationView.owner -->
          <v-tooltip
            top
            v-if="Object.keys(state.remoteStreams).length > 1 && state.ownerMeeting"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                color="white"
                icon
                class="mx-3"
                @click="getWebrtcConnections()[state.remoteToFullSizeUUID].die(true)"
              >
                <font-awesome-icon
                  :icon="['fal', 'phone']"
                  :style="{ fontSize: '20px' }"
                  class="faPhoneRotate"
                />
              </v-btn>
            </template>
            <span>{{ $t('generics.hangUp') }}</span>
          </v-tooltip>
          <v-tooltip location="top" v-if="state.ownUUID === ownerUUID"> 
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"  color="white" icon class="mx-3" @click.stop.prevent="transferModerator(state.remoteToFullSizeUUID)">
              <font-awesome-icon
                :icon="['fal', 'users-crown']"
                :style="{ fontSize: '20px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t('components.remotePersonCall.transferModerator') }}</span>
        </v-tooltip>
          <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                color="white"
                @click="setShowModalSenMsg(state.remoteToFullSizeUUID)"
                class="mx-3"
              >
                <font-awesome-icon :icon="['fal', 'paper-plane']" :style="{ fontSize: '20px' }" />
                <!-- <font-awesome-icon
                  :icon="['fal', 'lock']"
                  :style="{ fontSize: '14px' }"
                  class="lockMessageIcon"
                /> -->
              </v-btn>
            </template>
            <span>{{ $t('components.userProfile.sendAMessage') }}</span>
          </v-tooltip>
          <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                color="white"
                class="mx-3"
                target="_blank"
                :href="`mailto:${getEmailForUuid(state.remoteToFullSizeUUID)}?subject=${state.user.name}%20from%20vOffice&body=${emailSalutation}%20${getNameForUuid(state.remoteToFullSizeUUID)},`"
              >
                <font-awesome-icon :icon="['fal', 'envelope-square']" :style="{ fontSize: '24px' }" />
              </v-btn>
            </template>
            <span>{{ $t('components.userListItem.sendEMail') }}</span>
          </v-tooltip>
               <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                color="white"
                class="mx-3"
                target="_blank"
                @click="fullSreen($event)"
              >
                <font-awesome-icon :icon="['fal', 'expand-wide']" :style="{ fontSize: '24px' }" v-if="!fullScreen" />
                <font-awesome-icon :icon="['fas', 'compress']" :style="{ fontSize: '20px' }" v-if="fullScreen" />
              </v-btn>
            </template>
            <span>{{ $t('generics.fullscreen') }}</span>
          </v-tooltip>

          <!-- <span v-if="activeCandidates && (activeCandidates.remote.candidateType === 'relay' || activeCandidates.local.candidateType === 'relay')" :class="activeCandidates && (activeCandidates.remote.candidateType === 'relay' || activeCandidates.local.candidateType === 'relay') ? 'darkGreen' : 'lightGreen'">Relay</span> -->
        </div>
      </div>

      <!-- END VIDEO ON FULL SIZE -->
    </div>

    <div class="mainDivRemote" :class="`${!infiniteCall?' heightTop mt-6' :''}`" :style="{ backgroundImage: 'url(' + backgroundPresentation + ')' }">
      <div class="mb-7"
        v-if="(state.ownUUID !== state.presentationView.owner && !isRemoteFullSize) || (state.ownUUID !== state.remoteToFullSizeUUID  && isRemoteFullSize)"
      >
        <MyLocalCall :toggleModalSettings="toggleModalSettings" />
      </div>
      <div
        v-for="(remote, uuid, index) in state.remoteStreams"
        :key="uuid"
        v-if="(uuid !== state.presentationView.owner && !isRemoteFullSize) || (uuid !== state.remoteToFullSizeUUID && isRemoteFullSize)"
      >
        <RemotePersonCallPresentation
          :uuid="uuid"
          :remote="remote"
          :isLocalSoundMuted="isLocalSoundMuted"
          :setFullSizeVideo="setFullSizeVideo"
          :index="index"
        />
      </div>

      <RemotePersonJustVideo
        :setFullSizeVideo="setFullSizeVideo"
        :uuid="state.presentationView.owner"
        :remote="state.remoteStreams[state.presentationView.owner]"
        :isLocalSoundMuted="isLocalSoundMuted"
        v-if="(state.presentationView.owner !== state.ownUUID && (state.remoteStreams[state.presentationView.owner] || {}).second && Object.keys(state.remoteStreams).length < 4)"
      />
      <RemotePersonJustVideo
        :setFullSizeVideo="setFullSizeVideo"
        :uuid="state.remoteToFullSizeUUID"
        :remote="state.remoteStreams[state.remoteToFullSizeUUID]"
        :isLocalSoundMuted="isLocalSoundMuted"
        v-if="remoteFullSizeJustVideoRightSide"
      />

      <div
        v-if="(state.ownUUID == state.presentationView.owner && state.localStreams.display && Object.keys(state.remoteStreams).length < 4) || (isRemoteFullSize && state.ownUUID === state.remoteToFullSizeUUID && state.localStreams.display && Object.keys(state.remoteStreams).length < 4)"
      >
        <VideoSharingScreen />
      </div>
    </div>
    <!-- <ErrorInCall
      v-if="errorInCall.show"
      :errorInCall="errorInCall"
      :closeModalError="closeModalError"
    /> -->
    <!-- <ConfirmCloseCallModal v-if="showStopCallModal" :showModal="showStopCallModal" :closeModal="closeStopCallModal" :stopCall="stopCall"/> -->
  </div>
</template>
<script>
import { useStore } from "effector-vue/composition";
import moment from "../../../sharedsrc/moment";
import store from "../../store";
// import { aDelay } from '../lib/asyncUtil';
import {
  rtcHangup,
  ensureUserStream,
  // updateCallTime,
  // setRemoteFullSize,
  getWebrtcConnections,
  // sendMessageCallparticipants,
  // setPresentationViewScreenShare,
  holdCall,
  unholdCall,
  transferCall
} from "../../lib/rtcConn";
import {
  cycleUserMedia,
  tryWebcam,
  tryScreenShare,
  toggleMuteLocalAudio,
} from "../../lib/mediaDevices";
import { Splitpanes, Pane } from "splitpanes";
import VueDragResize from "vue-drag-resize";
import draggable from "vuedraggable";
import RemotePersonCallPresentation from "../rtc/remotePersonCallPresentation.vue";
import VideoSharingScreen from "../rtc/videoSharingScreen.vue";
import RemotePersonJustVideo from "../rtc/remotePersonJustVideo.vue";
import MyLocalCall from "../rtc/myLocalCall.vue";
import StreamCanvas from "../rtc/streamCanvas.vue";
import ProgressLinear from "../progressLinear/progressLinear.vue";
import ErrorInCall from "../modal/errorInCall.vue";
import LongPress from "vue-directive-long-press";
import { callAddDurationEvent, callLessDurationEvent, callSetMessageToParticipantsEvent, callSetPresentationViewEvent, callSetRemoteFullSizeFor, callSetAllowedToTalkUUIDs, callInfoStateStore, allowedToTalkMap,  amMutedByGlobal,ownerUUID } from '../../effector/call';
import CallInfoManager from '../../lib/callInfoManager';
import ConfirmCloseCallModal from '../modal/confirmCloseCallModal.vue';
import AudioStreamMeter from '../rtc/audioStreamMeter.vue'
import { typingInfoStore } from "../../effector/message"; 
import { isVisitor, isWaitingRoomUser } from "../../utils/privileges.js";
import {getSubjectVisitor} from "../../utils/basicFunctions.js";
import WebCamDragable from "./webCamDragable.vue";
import {setSendMessageInCallModalEvent, newMessageFromCallStore, /* setSendMessageModalEvent, */ setHistoryMessagesModalEvent} from "../../effector/modals"
import { wsCall } from "../../lib/wsConnect";

export default {
  directives: {
    "long-press": LongPress,
  },
  components: {
    RemotePersonCallPresentation,
    StreamCanvas,
    Splitpanes,
    Pane,
    VueDragResize,
    draggable,
    ProgressLinear,
    MyLocalCall,
    VideoSharingScreen,
    RemotePersonJustVideo,
    ErrorInCall,
    ConfirmCloseCallModal,
    AudioStreamMeter,
    WebCamDragable
  },
  props: ["primaryDrawer", "openCloseSidebar", "toggleModalSettings", "mini"],
  data() {
    const effector = {
      callInfo: callInfoStateStore,
      allowedToTalk: allowedToTalkMap,
      amMutedByGlobal,
      ownerUUID:ownerUUID,
      showTypingInfo: typingInfoStore,
      newMessageFromCall:newMessageFromCallStore
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      pLimitW: 0,
      pLimitH: 0,
      spaceShortcutCallAcceptTimeout: null,
      isIpad: this.$device.ipad,
      isIos: this.$device.ios,
      isLocalSoundMuted: false,
      //isGlobalAudioMuted:false,
      tenSecondSound: false,
      fiveSecondSound: 0,
      actualSize: {
        remote: 80,
        me: 20,
        position: 0,
      },
      ctrlPressed: false,
      originalCallTime: undefined,
      moment: moment,
      secondsBeep1: 0,
      secondsBeep2: 0,
      secondsBeep3: 0,
      ownerCall: undefined,
      errorInCall: {
        show: false,
        messageError: "",
      },
      isLongPress: false,
      openCameraQuality: false,
      showWebCamDrag : false,
      // showStopCallModal: false,
      amIMuted: false,
      amISoundMuted: false,
      infiniteCall: false,
      fullScreen: false,
      // Effector
      ...effector,
    };
  },
  beforeUnmount() {
    // if (!store.state.presentationView.fromScreenshare) {
      // this.setdurationMeeting(undefined);
      // this.setOwnerMeeting(undefined);
      // this.setmeetingStartAt(undefined);
      this.setPresentationView(false, undefined);
      this.notificationEnd = false;
      this.originalCallTime = undefined;
      this.ownerCall = undefined;
      this.restoreFullSizeVideo();
    // }
    // store.setRemotePresentationFullSizeObject(null);
  },
  unmounted() {
    // console.log("removin lisatener on destroy");
    document.removeEventListener("keydown", this.onKeyDown, {
      capture: true,
      passive: true,
    });
    document.removeEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
  },
  mounted() {
    //  console.log('IOS IPAD', this.isIpad, this.isIos)
    this.handleResize();
    document.addEventListener("keydown", this.onKeyDown, {
      capture: true,
      passive: true,
    });
    document.addEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
    if (!this.infiniteCall){
      let checkDurationMeeting = setInterval(() => {
        if (this.state.durationMeeting) {
          this.originalCallTime = this.state.durationMeeting;
          clearInterval(checkDurationMeeting);
        }
      }, 1000);
    }
    // this.changeAutoQuality();
    // if ( this.state.presentationView.owner !== this.state.ownUUID){
    //   // this.ownerCall = this.state.remoteStreams[this.state.presentationView.owner];
    //   const obj = {
    //     first: this.state.localStreams.user,
    //     second: this.state.localStreams.display
    //   }
    //   console.log("obj11111111111111", obj)
    //   this.myLocalStream[this.state.ownUUID]=obj
    //   console.log(this.myLocalStream, "localstreammmmmmm")
    //   // if ( !this.state.remoteStreams[this.state.ownUUID] ) this.state.remoteStreams[this.state.ownUUID];

    //   // this.state.remoteStreams[this.state.ownUUID] = obj;

    // }
    // if (this.primaryDrawer.model === true) {
    //   this.openCloseSidebar();
    // }
    if (this.mini === false) {
      this.openCloseSidebar();
    }
  },
  watch: {
    "callInfo": function () {
      this.infiniteCall = this.callInfo.infiniteCall
    },
    "state.localStreams.user": function () {
      ensureUserStream();
    },
    "state.localStreams.userCanvas": function () {
      ensureUserStream();
    },
    //detect is fullscrenn
    "$vuetify.display.height": function (actualSize) {
      if(actualSize === screen.height){
        this.showWebCamDrag = true
      }else {
        this.showWebCamDrag = false
      }
    },
    "state.remoteStreams": function(data){
      if((this.state.ownUUID === this.state.presentationView.owner) && this.isRemoteFullSize &&  (Object.keys(data).indexOf(this.state.remoteToFullSizeUUID) === -1)){
        this.setFullSizeVideo(null, this.state.presentationView.owner)
      }
    },
    newMessageFromCall:{
       handler:function (newMessageFromCall) {
         if(newMessageFromCall){
            for (let uuid in store.state.remoteStreams) {
              wsCall("sendToUUID", uuid, {
                type: "rtcSendMessageToAll",
                message: newMessageFromCall
            });
          }
         }
       }
    }
  },
  methods: {
    setSendMessageInCallModal(){
      setSendMessageInCallModalEvent(true)
    },
    transferModerator(uuid){
      return CallInfoManager.transferOwnership(uuid);
    },
    isWaitingRoomUser(uuid){
      return isWaitingRoomUser(uuid)
    },
    fullSreen(event) {
      if (!this.IsFullScreen()) {
        // const elem = event.target;
        const elem = this.$refs.videos;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari & Opera */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          elem.msRequestFullscreen();
        }
        this.fullScreen = true
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen().catch(() => {});
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.fullScreen = false
      }
    },
    IsFullScreen() {
      return (
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement &&
          document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement &&
          document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null)
      );
    },
    getSubjectVisitor(uuid){
       return getSubjectVisitor(uuid);
    },
    isVisitor(uuid) {
      return isVisitor(uuid);
    },
    toggleMuteGlobalAudio(){
      if (this.isGlobalAudioMuted) {
        callSetAllowedToTalkUUIDs([]);
      } else {
        callSetAllowedToTalkUUIDs([this.state.ownUUID]);
      }
    },
    stopCall(){
      if (this.$refs.cancelCall) {
        this.$refs.cancelCall.$el.click();
      }
    },
    // closeStopCallModal(){
    //   this.showStopCallModal = false;
    // },
    // changeAutoQuality() {
    //   const peopleInCall = Object.keys(this.state.remoteStreams)
    //     .length;
    //   if (peopleInCall === 1) {
    //     this.state.persisted.rtcVideoQuality = "high";
    //   } else if (peopleInCall === 2) {
    //     this.state.persisted.rtcVideoQuality = "medium";
    //   } else if (peopleInCall > 2) {
    //     this.state.persisted.rtcVideoQuality = "low";
    //   }
    //   cycleUserMedia();
    // },
    async transferCall() {
      let uuidToTransfer;
      for (let uuid in store.state.remoteStreams) {
        if (!store.state.rtc[uuid].onHold) {
          uuidToTransfer = uuid;
        }
      }
      if (CallInfoManager.amIOwner()) {
        CallInfoManager.transferOwnership(uuidToTransfer);
        // await aDelay(1000);
        await CallInfoManager.syncToPeers();
      }
      const connections = store.state.remoteStreams;
      for (let uuid in connections) {
        if (store.state.rtc[uuid].onHold) {
          // console.log('Forwarding ', uuid, 'to', uuidToTransfer);
          await transferCall(uuid, uuidToTransfer);
        }
      }
    },
   async unholdCall(){
      for (let uuid in store.state.remoteStreams) {
        if (store.state.rtc[uuid].onHold) {
         await unholdCall(uuid);
          if (!store.state.rtc[uuid].onHold) {
            // this.rtcHangup();
          }
        }
      }
    },
    holdCall(){
      for (let uuid in store.state.remoteStreams) {
        holdCall(uuid);
      }
    },
    setQuality(value) {
      this.state.persisted.rtcVideoQuality = value;
      cycleUserMedia();
      this.openCameraQuality = false;
    },
    openMenuQuality() {
      // triggers after 300ms of mousedown
      if (this.state.persisted.rtcVideo) {
        this.isLongPress = true;
        // open quality menu
        this.openCameraQuality = true;
      }
    },
    onLongPressStop() {
      setTimeout(() => {
        if (this.isLongPress) {
          this.isLongPress = false;
        }
      }, 100);
    },
    backToGridView() {
      const moderator = this.state.presentationView.owner;
      // callSetPresentationViewEvent({ value: false, owner: undefined, fromScreenshare: undefined });
      callSetPresentationViewEvent({ value: false, owner: moderator, fromScreenshare: undefined });
      this.closeDisplayMedia();
      // store.setPresentationView(false, undefined);
      // let personRemote = Object.keys(store.state.remoteStreams);
      // for (const key in personRemote) {
      //   let remote_uuid = personRemote[key];
      //   if (store.state.rtc[remote_uuid].ringingState == "connected") {
      //     setPresentationViewScreenShare(remote_uuid);
      //   }
      // }
    },
    restoreFullSizeVideo() {
      if (!store.state.presentationView.fromScreenshare) {
        if (store.state.ownUUID !== store.state.presentationView.owner) {
          callSetRemoteFullSizeFor(this.state.presentationView.owner);
        } else {
          // callSetRemoteFullSizeFor(null);
          callSetRemoteFullSizeFor(store.state.ownUUID);
        }
        
      }
    },
    setFullSizeVideo(remote, uuid) {
      callSetRemoteFullSizeFor(uuid);
      // let speakerRequests = store.state.speakerRequests;
      // const indexUUID = speakerRequests.indexOf(uuid);
      // if (indexUUID !== -1) {
      //   store.state.speakerRequests.splice(indexUUID, 1);
      // }
      // store.setRemotePresentationFullSize(true);
      // store.setRemotePresentationFullSizeUUID(uuid);
      // let personRemote = Object.keys(store.state.remoteStreams);
      // if (store.state.localStreams.display) {
      //   this.closeDisplayMedia();
      // }
      // for (const key in personRemote) {
      //   let remote_uuid = personRemote[key];
      //   if (store.state.rtc[remote_uuid].ringingState == "connected") {
      //     setRemoteFullSize(remote_uuid, uuid);
      //   }
      // }
    },
    // closeModalError() {
    //   this.errorInCall = {
    //     show: false,
    //     messageError: "",
    //   };
    //   this.rtcHangup();
    // },
    getEmailForUuid(uuid) {
      return store.getEmailForUuid(uuid);
    },
    setShowModalSenMsg(uuid) {
      // return store.setShowModalSenMsg(uuid);
      // setSendMessageModalEvent({show:true, users: [uuid], type: 'regularMsg'})
      setHistoryMessagesModalEvent(uuid)
    },
    // ShowTicketEntry(assignee) {
    //   console.log("show ticket entry ", this.state.showTicketEntry, assignee);
    //   this.state.currentContentVisile.showComponent = true;
    //   this.state.currentContentVisile.component = "ticketEntry";
    //   this.state.showTicketEntry = true;
    //   this.state.ticket.t_assignee = assignee;
    // },
    setPresentationView(value, owner) {
      store.setPresentationView(value, owner);
    },
    changeActualSize() {
      if (Object.keys(this.state.remoteStreams).length == 1) {
        let Remotesizes = [75, 50, 100];
        let MeSizes = [25, 50, 0.1];
        this.actualSize.position += 1;
        if (this.actualSize.position > 2) {
          this.actualSize.position = 0;
        }
        this.actualSize.remote = Remotesizes[this.actualSize.position];
        this.actualSize.me = MeSizes[this.actualSize.position];
      }
    },
    addMoreTime() {
      if (this.state.ownerMeeting) {
        callAddDurationEvent();
        // let personRemote = Object.keys(store.state.remoteStreams);
        // let oneMinute = 60000;
        // this.originalCallTime += oneMinute;
        // let durationMeeting = store.state.durationMeeting + oneMinute;
        // store.setdurationMeeting(durationMeeting);
        // for (const key in personRemote) {
        //   let uuid = personRemote[key];
        //   if (store.state.rtc[uuid].ringingState == "connected") {
        //     updateCallTime(uuid, "increase");
        //   }
        // }
      }
    },
    lessTime() {
      callLessDurationEvent();
      // if (
      //   this.state.ownerMeeting &&
      //   this.state.currentTS - this.state.meetingStartAt <
      //     this.state.durationMeeting - 60000
      // ) {
      //   let personRemote = Object.keys(store.state.remoteStreams);
      //   let oneMinute = 60000;
      //   let durationMeeting = store.state.durationMeeting - oneMinute;
      //   store.setdurationMeeting(durationMeeting);
      //   for (const key in personRemote) {
      //     let uuid = personRemote[key];
      //     if (store.state.rtc[uuid].ringingState == "connected") {
      //       updateCallTime(uuid, "decrease");
      //     }
      //   }
      // }
    },
    toggleMuteSound() {
      this.amISoundMuted = !this.amISoundMuted;
      this.isLocalSoundMuted = !this.isLocalSoundMuted;
      // let personRemote = Object.keys(store.state.remoteStreams);
      const messageToParticipants = store.state.messageToParticipants;
      if (this.isLocalSoundMuted) {
        messageToParticipants.sound = this.$t(
          "components.callsContent.soundMessage"
        );
        callSetMessageToParticipantsEvent(messageToParticipants);
        // for (const key in personRemote) {
        //   let uuid = personRemote[key];
        //   if (store.state.rtc[uuid].ringingState == "connected") {
        //     sendMessageCallparticipants(uuid, messageToParticipants);
        //   }
        // }
        // store.setMessageToParticipants(messageToParticipants);
      } else {
        if (store.state.messageToParticipants) {
          messageToParticipants.sound = undefined;
          callSetMessageToParticipantsEvent(messageToParticipants);
          // for (const key in personRemote) {
          //   let uuid = personRemote[key];
          //   if (store.state.rtc[uuid].ringingState == "connected") {
          //     sendMessageCallparticipants(uuid, messageToParticipants);
          //   }
          // }
          // store.setMessageToParticipants(messageToParticipants);
        }
      }
    },
    onKeyDown(event) {
      if (event.type === "keydown" && event.keyCode === 17) {
        this.ctrlPressed = true;
      }
    },
    onKeyUp(event) {
      if (
        this.spaceShortcutCallAcceptTimeout &&
        event.type === "keyup" &&
        event.keyCode === 32
      ) {
        clearTimeout(this.spaceShortcutCallAcceptTimeout);
        this.spaceShortcutCallAcceptTimeout = null;
        if (this.$refs.cancelCall) {
          if(event.target.nodeName !== 'TEXTAREA' && event.target.nodeName !== 'INPUT'){
            // this.$refs.cancelCall.$el.click();
            //  this.showStopCallModal = true;
            this.stopCall();
          }
        }
      } else if (event.type === "keyup" && event.keyCode === 32) {
        this.spaceShortcutCallAcceptTimeout = setTimeout(() => {
          this.spaceShortcutCallAcceptTimeout = null;
        }, 300);
      } else if (event.type === "keyup" && event.keyCode === 13) {
        if (this.ctrlPressed) {
          // console.log("click ctrPress");
          if(event.target.nodeName !== 'TEXTAREA' && event.target.nodeName !== 'INPUT'){
            return this.lessTime();
          }
        } else {
          if (Object.keys(store.state.remoteStreams).length > 0) {
            if(event.target.nodeName !== 'TEXTAREA' && event.target.nodeName !== 'INPUT'){
              this.addMoreTime();
            
            }
          }
        }
      } else if (event.type === "keyup" && event.keyCode === 17) {
        this.ctrlPressed = false;
      }
    },
    toggleUseBlurEffect() {
      store.setUseBlurEffect(!this.state.persisted.useBlurEffect);
      // ensureUserStream();
    },
    resizePane(event) {
      // console.log('Resize pan!!!!!!', event)
      this.handleResize();
    },
    handleResize() {
      const el = this.$refs.localVideoDiv;
      if (el) {
        this.pLimitW = el.clientWidth;
        this.pLimitH = el.clientHeight;
      }
    },
    resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
      this.handleResize();
    },
    tryWebcam() {
      return tryWebcam(this.isLocalAudioMuted);
    },
    tryScreenShare() {
      return tryScreenShare();
    },
    closeDisplayMedia() {
      return store.closeDisplayMedia();
    },
    rtcHangup() {
      // this.setdurationMeeting(undefined);
      // this.setOwnerMeeting(undefined);
      // this.setmeetingStartAt(undefined);
      this.notificationEnd = false;
      this.originalCallTime = undefined;
      //hangUp for everybody
      if (store.state.ownUUID == store.state.presentationView.owner) {
        for (const key in this.state.remoteStreams) {
          getWebrtcConnections()[key].die(true);
        }
      } else {
        this.restoreFullSizeVideo();
      }
      return rtcHangup();
    },
     getWebrtcConnections() {
      return getWebrtcConnections();
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      if (store.getTitelForUuid(uuid) !== "unknown") {
        return store.getTitelForUuid(uuid);
      } else {
        return "";
      }
    },
    getAvatarFromUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    toggleMuteLocalAudio() {
      if (this.isGlobalAudioMuted) return;
      this.amIMuted = !this.amIMuted;
      const muted = toggleMuteLocalAudio();
      let personRemote = Object.keys(store.state.remoteStreams);
      const messageToParticipants = store.state.messageToParticipants;
      if (this.isLocalAudioMuted) {
        messageToParticipants.audio = this.$t(
          "components.callsContent.audioMessage"
        );
        callSetMessageToParticipantsEvent(messageToParticipants);
        // for (const key in personRemote) {
        //   let uuid = personRemote[key];
        //   if (store.state.rtc[uuid].ringingState == "connected") {
        //     sendMessageCallparticipants(uuid, messageToParticipants);
        //   }
        // }
        // store.setMessageToParticipants(messageToParticipants);
      } else {
        if (store.state.messageToParticipants) {
          messageToParticipants.audio = undefined;
          callSetMessageToParticipantsEvent(messageToParticipants);
          // for (const key in personRemote) {
          //   let uuid = personRemote[key];
          //   if (store.state.rtc[uuid].ringingState == "connected") {
          //     sendMessageCallparticipants(uuid, messageToParticipants);
          //   }
          // }
          // store.setMessageToParticipants(messageToParticipants);
        }
      }
      return muted;
    },
    // setdurationMeeting(time) {
    //   return store.setdurationMeeting(time);
    // },
    // setmeetingStartAt(value) {
    //   return store.setmeetingStartAt(value);
    // },
    // setOwnerMeeting(value) {
    //   store.setOwnerMeeting(value);
    // },
    // setshowModalEndMeeting(value) {
    //   return store.setshowModalEndMeeting(value);
    // },
    // formatTime(time) {
    //   // let calc = (time / 1000 / 60).toFixed(2);
    //   // let calcTmp = calc.split(".");
    //   // let timer = "0:00";
    //   // if (parseInt(calcTmp[0]) < 10) {
    //   //   timer = "0" + calcTmp[0] + ":" + calcTmp[1];
    //   // } else {
    //   //   timer = calcTmp[0] + ":" + calcTmp[1];
    //   // }
    //   // return timer;

    //   if (time >= 3600000) {
    //     return this.moment.utc(time).format("hh:mm:ss");
    //   } else {
    //     return this.moment.utc(time).format("mm:ss");
    //   }
    // },
    // ringAudio() {
    //   try {
    //     const audio = new Audio("/media/callFinishTone.mp3");
    //     audio
    //       .play()
    //       .catch((error) =>
    //         console.warn("Failed to play notification audio", error)
    //       );
    //   } catch (error) {
    //     console.warn("Failed to play notification audio", error);
    //   }
    // },
    // ringAudioFinalCall() {
    //   try {
    //     const audio = new Audio("/media/endCall.mp3");
    //     audio
    //       .play()
    //       .catch((error) =>
    //         console.warn("Failed to play notification audio", error)
    //       );
    //   } catch (error) {
    //     console.warn("Failed to play notification audio", error);
    //   }
    // },
  },
  computed: {
    backgroundPresentation() {
      return (
        store.state.namespaceSettings.companyLogo ||
        "/img/icon.png"
      );
    },
    hasOnHold() {
      let onHold = 0;
        for (let uuid in store.state.remoteStreams) {
          if (store.state.rtc[uuid].onHold) {
            onHold = onHold + 1;
          }
        }
        if ( onHold > 0 ){
          return true;
        }
        return false;
    },
    amIMutedByAdmin(){
       return this.amMutedByGlobal;
    },
    isGlobalAudioMuted() {
     return this.allowedToTalk.length !== 0;
    },
    isBlurEffectEnabled() {
      return this.state.namespaceSettings.showBlurEffectOption;
    },

    remoteFullSizeJustVideoRightSide() {
      return (
        this.isRemoteFullSize &&
        this.state.presentationView.owner !== this.state.remoteToFullSizeUUID &&
        this.state.ownUUID !== this.state.remoteToFullSizeUUID &&
        this.state.remoteStreams[this.state.remoteToFullSizeUUID] &&
        this.state.remoteStreams[this.state.remoteToFullSizeUUID].second &&
        Object.keys(this.state.remoteStreams).length < 4
      );
    },
    remoteFullSizeJustVideoLeftSide() {
      return (
        this.isRemoteFullSize &&
        this.state.presentationView.owner !== this.state.remoteToFullSizeUUID &&
        this.state.ownUUID !== this.state.remoteToFullSizeUUID &&
        this.state.remoteStreams[this.state.remoteToFullSizeUUID] &&
        this.state.remoteStreams[this.state.remoteToFullSizeUUID].second &&
        Object.keys(this.state.remoteStreams).length === 4
      );
    },
    isRemoteFullSize() {
      return store.state.remoteFullSize;
    },
    emailSalutation() {
      return encodeURIComponent(
        this.$t("components.userListItem.emailSalutation")
      );
    },
    myLocalData() {
      if (this.state.presentationView.owner !== this.state.ownUUID) {
        // this.ownerCall = this.state.remoteStreams[this.state.presentationView.owner];
        const obj = {
          first: this.state.localStreams.user,
          second: this.state.localStreams.display,
        };
        // console.log("obj11111111111111", obj);
        const myLocalStream = {};
        myLocalStream[this.state.ownUUID] = obj;
        // if ( !this.state.remoteStreams[this.state.ownUUID] ) this.state.remoteStreams[this.state.ownUUID];
        // console.log(myLocalStream, "mylocallllllllll");
        return myLocalStream;
        // this.state.remoteStreams[this.state.ownUUID] = obj;
      }
    },

    haveRemoteFullSizeFirstVideo() {
      if (!this.state.remoteStreams[this.state.remoteToFullSizeUUID])
        return false;
      if (!this.state.remoteStreams[this.state.remoteToFullSizeUUID].first)
        return false;
      for (const track of this.state.remoteStreams[
        this.state.remoteToFullSizeUUID
      ].first.getTracks()) {
        if (track.kind === "video") return true;
      }
      return false;
    },
    haveRemoteFirstVideo() {
      if (!this.state.remoteStreams[this.state.presentationView.owner])
        return false;
      if (!this.state.remoteStreams[this.state.presentationView.owner].first)
        return false;
      for (const track of this.state.remoteStreams[
        this.state.presentationView.owner
      ].first.getTracks()) {
        if (track.kind === "video") return true;
      }
      return false;
    },
    // elapseTime() {
    //   let milisegundos = store.state.currentTS - store.state.meetingStartAt;
    //   let progress = "";
    //   if (!milisegundos) return '';
    //   if (milisegundos >= 3600000) {
    //     progress = this.moment
    //       .utc(milisegundos)
    //       // .add(this.moment.duration(1000))
    //       .format("hh:mm:ss");
    //   } else {
    //     progress = this.moment
    //       .utc(milisegundos)
    //       // .add(this.moment.duration(1000))
    //       .format("mm:ss");
    //   }
    //   return progress;
    // },
    // timerCall() {
    //   // let calc = (((store.state.durationMeeting-(store.state.currentTS - store.state.meetingStartAt))/1000)/60).toFixed(2)
    //   let milisegundos =
    //     store.state.durationMeeting -
    //     (store.state.currentTS - store.state.meetingStartAt);
    //     if (!milisegundos) return '';
    //   let progress = "";
    //   if (milisegundos >= 3600000) {
    //     progress = this.moment
    //       .utc(milisegundos)
    //       // .add(this.moment.duration(1000))
    //       .format("hh:mm:ss");
    //   } else {
    //     progress = this.moment
    //       .utc(milisegundos)
    //       // .add(this.moment.duration(1000))
    //       .format("mm:ss");
    //   }
    //   const audio = new Audio("/media/notificationSound.mp3");
    //   // if (
    //   //   milisegundos <= 10000 &&
    //   //   milisegundos > 5000 &&
    //   //   !this.tenSecondSound
    //   // ) {
    //   //   this.tenSecondSound = true;
    //   //   try {
    //   //     const audio = new Audio("/media/notificationSound.mp3");
    //   //     audio
    //   //       .play()
    //   //       .catch(error =>
    //   //         console.warn("Failed to play notification audio", error)
    //   //       );
    //   //   } catch (error) {
    //   //     console.warn("Failed to play notification audio", error);
    //   //   }
    //   // }
    //   // if (milisegundos < 10000 && milisegundos >= 0) {
    //   //   try {
    //   //     const audio = new Audio("/media/notificationSound.mp3");
    //   //     audio
    //   //       .play()
    //   //       .catch(error =>
    //   //         console.warn("Failed to play notification audio", error)
    //   //       );
    //   //   } catch (error) {
    //   //     console.warn("Failed to play notification audio", error);
    //   //   }
    //   // }
    //   if (milisegundos <= 30000 && milisegundos > 20000) {
    //     // one ring
    //     if (this.secondsBeep1 < 1) {
    //       this.ringAudio();
    //       this.secondsBeep1 = this.secondsBeep1 + 1;
    //     }
    //   } else if (milisegundos <= 20000 && milisegundos > 10000) {
    //     // two rings
    //     if (this.secondsBeep2 < 2) {
    //       this.ringAudio();
    //       this.secondsBeep2 = this.secondsBeep2 + 1;
    //     }
    //   } else if (milisegundos <= 10000 && milisegundos > 0) {
    //     // three rings
    //     if (this.secondsBeep3 < 3) {
    //       this.ringAudio();
    //       this.secondsBeep3 = this.secondsBeep3 + 1;
    //     }
    //   }
    //   return progress;
    // },
    haveLocalVideo() {
      if (!this.state.localStreams.user) return false;
      for (const track of this.state.localStreams.user.getTracks()) {
        if (track.kind === "video") return true;
      }
      return false;
    },
    isLocalAudioMuted() {
      if (!this.state.localStreams.user) return false;
      if (!this.state.localStreams.lastMicChange) return false; // Just for the reference
      for (const track of this.state.localStreams.user.getTracks()) {
        if (track.kind === "audio") return !track.enabled;
      }
      return false;
    },
    // meetingTime() {
    //   let personRemote = Object.keys(store.state.remoteStreams)[0];
    //   if (
    //     store.state.rtc[personRemote] &&
    //     store.state.rtc[personRemote].ringingState === "ringing" &&
    //     store.state.meetingStartAt &&
    //     store.state.meetingDialUPTime
    //   ) {
    //     let timeIncall = store.state.currentTS - store.state.meetingStartAt;
    //     if (timeIncall >= store.state.meetingDialUPTime) {
    //       return rtcHangup();
    //     }
    //   }
    //   if (
    //     store.state.rtc[personRemote] &&
    //     store.state.rtc[personRemote].ringingState == "connected" &&
    //     store.state.meetingStartAt
    //   ) {
    //     let timeIncall = store.state.currentTS - store.state.meetingStartAt;
    //     let limitTimeCall = store.state.durationMeeting;
    //     let porcentaje = ((timeIncall * 100) / limitTimeCall).toFixed(0);
    //     if (porcentaje > 200 || porcentaje < -15) {
    //       // alert("Incorrect time, please verify your date/time settings");
    //       this.errorInCall = {
    //         show: true,
    //         messageError:
    //           "Incorrect time, please verify your date/time settings",
    //       };
    //     }
    //     // console.log(
    //     //   "limitTimeCall---=",
    //     //   limitTimeCall,
    //     //   "timeInCall---=",
    //     //   timeIncall,
    //     //   "meetingStart---=",
    //     //   store.state.meetingStartAt,
    //     //   "currentTs----=",
    //     //   store.state.currentTS,
    //     //   "porcentaje---=",
    //     //   porcentaje
    //     // );
    //     // if (porcentaje >= 95) {
    //     //   if (!this.notificationEnd) {
    //     //     try {
    //     //       const audio = new Audio("/media/notificationSound.mp3");
    //     //       audio
    //     //         .play()
    //     //         .catch(error =>
    //     //           console.warn("Failed to play notification audio", error)
    //     //         );
    //     //       this.notificationEnd = true;
    //     //     } catch (error) {
    //     //       console.warn("Failed to play notification audio", error);
    //     //     }
    //     //   }
    //     //   // this.setshowModalEndMeeting(true)
    //     // }
    //     if (porcentaje >= 100) {
    //       this.ringAudioFinalCall();
    //       return rtcHangup();
    //     }
    //     return porcentaje;
    //   } else {
    //     return 0;
    //   }
    // },
  },
  // beforeUnmount() {
  //   this.ringAudioFinalCall();
  // }
};
</script>
<style scoped lang="scss">
.lockMessageIcon{
  position: absolute;
  right: -6px;
  top: -10px;
}
.testMeSomehow{
  position: absolute;
  top: 0px;
  opacity: 0;
  background: #2f2f2f;
  color: white;
  width: 100%;
  text-align: center;
  padding: 5px;

  &.active{
    top: 30px;
    opacity: 1;
    transition: all .5s ease-in;
  }
}
#canvasAudioCallsContentPresentation{
    position: absolute;
    top: -8px;
    height: 3px;
    width: 100%;
}
.microphonoLevelIconSlash{
  margin-bottom: -18px !important;
}
.microphonoLevelIcon{
    font-size: 29px;
    transform: rotate(90deg);
    margin-right: 13px;
    margin-bottom: -3px;
}
.microphoneLevel{
  position: absolute;
  top: 40%;
  left: -25px;
  width: 100px;
  transform: rotate(270deg);
  z-index: 99999;
}
.onHoldOverlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color:rgba(163, 163, 163, 0.38)
}
.rotatedArrow {
  font-size: 16px;
  transform: rotate(45deg);
  margin-left: -2px;
  margin-top: 0px;
}
.italicFont {
  font-style: italic;
}
.callerName {
  position: absolute;
  width: 100%;
  color: white;
  // z-index: 2;
  height: 30px;
  padding: 6px;
  background-color: linear-gradient;
  background: rgb(21, 30, 39);
  z-index: 99999999;
  // background: linear-gradient(
  //   0deg,
  //   rgba(21, 30, 39, 0.42) 0%,
  //   rgba(31, 43, 48, 0.43) 100%
  // );
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.86) 0%,
    rgba(0, 0, 0, 0.77) 100%
  );
  font-size: 15px;
  text-align: center;
}
.bg {
  background-color: black;
  z-index: 999999 !important;
}

#video-container {
  height: 100%;
  width: 100%;
  min-width: 100%;
  position: absolute;
  // display: grid;
  // grid-template-rows: repeat(2, 1fr);
  // grid-template-columns: repeat(4, 0.5fr);
  overflow-x: hidden !important;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  z-index: 1;
  display: flex;
  video {
    display: flex;
    background-color: black;
    border-radius: 3px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
    /* max-width: 800px; */
    /* max-height: 600px; */
    max-width: 100%;
    max-height: 40%;
  }
  .remotePersonCall {
    position: relative;
    min-width: 100px;
    min-height: 100px;
    margin: 5px;
    margin: 0;
    /* 1 person (1 in call plus user) */
    &.total-remote-1.remote-video--0 {
      grid-area: 1 / 1 / span 4 / span 4;
    }

    /* 3 person (2 in call plus user) */
    &.total-remote-2.remote-video--0 {
      grid-area: 1 / 1 / span 2 / span 2;
    }

    &.total-remote-2.remote-video--1 {
      grid-area: 1 / 3 / span 2 / span 2;
    }

    /* 4 person (3 in call plus user) */
    &.total-remote-3.remote-video--0 {
      grid-area: 1 / 1 / span 1 / span 2;
    }

    &.total-remote-3.remote-video--1 {
      grid-area: 2 / 1 / span 1 / span 2;
    }

    &.total-remote-3.remote-video--2 {
      grid-area: 1 / 3 / span 2 / span 2;
    }

    /* 5 person (4 in call plus user) */
    &.total-remote-4.remote-video--0 {
      grid-area: 1 / 1 / span 1 / span 2;
    }

    &.total-remote-4.remote-video--1 {
      grid-area: 2 / 1 / span 1 / span 2;
    }

    &.total-remote-4.remote-video--2 {
      grid-area: 1 / 3 / span 1 / span 2;
    }

    &.total-remote-4.remote-video--3 {
      grid-area: 2 / 3 / span 1 / span 2;
    }
  }
  .localVideo {
    /* grid-area: 2 / 3 / span 1 / span 2; */
    z-index: 999;
    // position: absolute;
    // bottom: 0;
    /*width: 340px;*/
    height: 100%;
    right: 0;
    // border: 1px solid #00000066;
    #local-video {
      width: 100%;
      max-height: unset;
      position: relative;
      bottom: 0;
      height: 100%;
    }

    #local-screen {
      width: 100%;
      position: absolute;
      top: 0;
      height: 100%;
      max-height: unset;
    }
    .noCameraStream {
      position: absolute;
      display: table;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      text-align: center;
      z-index: -10;
      font-weight: bold;
      font-size: 30px;
      .noCameraContent {
        display: table-cell;
        vertical-align: middle;
        .emptyDiv {
          height: 125px;
        }
      }
      .imageNotVideo {
        width: 160px;
        height: 160px;
        border-radius: 10px;
        margin: 0 auto;
      }
    }

    .buttonsIpad {
      bottom: 64px !important;
    }
    .buttonsUser {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
      padding-right: 10px;
      height: 45px;
      padding-top: 7px !important;
      bottom: 0px;
      position: absolute;
      width: 100%;
      z-index: 9999999999999;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.6) 100%
      );
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        bottom: 64px !important;
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        bottom: 64px !important;
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        bottom: 64px !important;
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
        bottom: 64px !important;
      }

      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        bottom: 64px !important;
      }

      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
        bottom: 64px !important;
      }

      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
        bottom: 64px !important;
      }

      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
        bottom: 64px !important;
      }

      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
        bottom: 64px !important;
      }
      .pUserName {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 0px;
        margin-top: 5px;
        color: white;
      }
      .divButtonsLocal {
        button {
          margin-right: 5px;
          color: white;
          // background: #2a3133;
          // border-radius: 4px;
          &:last-of-type {
            margin-right: 0px;
          }
          &.redBtn {
            // background: red;
          }
        }
      }
    }
  }
}
.mainDivCall {
  // background-color: transparent !important;
  z-index: 0;
  position: relative;
  width: calc(100% - 350px);
}

.mainDivCall2Columns {
  width: calc(100% - 700px) !important;
}

.mainDivRemote {
  width: 350px;
  background-color: #ffffff;
  background-position: center;
  background-size: 30%;
}

.v-theme--dark .mainDivRemote {
  // background-color: #3aa3b3;
  & .noVideoStreamContent {
    background: #272727 !important;
  }
}

.v-theme--light .mainDivRemote {
  // background-color: #3aa3b3;
  & .noVideoStreamContent {
    background: #f2f2f2 !important;
  }
}

.heightTop {
  height: calc(100% - 24px);
}

.v-theme--dark .default-theme.mainDivCall {
  background-color: #272727;
}
.v-theme--light .default-theme.mainDivCall {
  background-color: #f2f2f2;
}
.faPhoneRotate {
  transform: rotate(230deg) !important;
}

.videoIdTag {
  float: left;
  margin-left: 4px;
  color: white;
  position: absolute;
  top: 32px;
  z-index: 9999;
  -webkit-text-stroke: .06em black;
  font-size: 1em;
  font-family: verdana;
  left: 10px;
}
.progressBar {
  position: absolute;
  width: 100%;
  top: 0px;
  z-index: 1;
  background-color: #f44336;
}
.progressBarBlink {
  animation: blink 1s linear infinite;
  //  pointer-events: none;
}
.messageToParticipantsBlink {
  animation: blink 2s linear infinite;
}
.messageToParticipants {
  // font-size: 12px;
  z-index: 1;
  position: absolute;
  padding: 10px;
  background: red;
  border-radius: 3px;
  left: 5px;
  top: 60px;
  color: white;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.cameraQuality {
  bottom: 65px !important;
  position: relative !important;
}
.v-list-item {
  min-height: 35px;
}
.v-list-item .v-list-item__title {
  line-height: 1 !important;
}
.blueBackground {
  background: #2a3133;
}
</style>