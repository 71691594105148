<template>
  <div :class="`${getTTPosition} ${extraclass || ''}`">
    <span :class="`tooltippedtooltip tooltippedtooltipButton`">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: ["text","position", "extraclass"],
  data() {
    return {};
  },
  computed: {
    getTTPosition() {
      switch (this.position) {
        case "bottom":
          return "speech-bubbleBottom";
        case "top":
          return "speech-bubbleTop";
        case "right":
          return "speech-bubbleRight";
        case "left":
          return "speech-bubbleLeft";
        default:
          return "speech-bubbleTop";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.tooltippedtooltip,
.tooltippedtooltipButton {
  font-size: 12px !important;
  border-radius: 4px;
  color: #fff;
  display: block;
  background: rgba(97, 97, 97, 0.9);
  position: fixed;
  // position: absolute;
  margin-top: -20px;
  // z-index: 1000;
  z-index: 1;
  // width: auto;
  width: 65px;
  pointer-events: none;
  padding: 3px 8px;
  text-transform: none;
  font-family: Roboto, sans-serif;
  line-height: 14px;
  font-weight: 300;
  letter-spacing: 0 !important;
}

// start bottom TT
.speech-bubbleBottom {
  position: absolute;
  bottom: -15px;
  background: rgba(97, 97, 97, 0.9);
  border-radius: 0.4em;
  color: #fff;
}

.speech-bubbleBottom:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-bottom-color: rgba(97, 97, 97, 0.9);
  border-top: 0;
  margin-left: 24px;
  margin-top: -25px;
  color: #fff;
}
//  end bottom TT
// start top TT
.speech-bubbleTop {
  position: absolute;
  top: -5px;
  background: rgba(97, 97, 97, 0.9);
  border-radius: 0.4em;
  color: #fff;
}
.speech-bubbleTop:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 51%;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: rgba(97, 97, 97, 0.9);
  border-bottom: 0;
  margin-left: 24px;
  margin-bottom: -5px;
  color: #fff;
}
// end top TT
// start right TT
.speech-bubbleRight {
  position: absolute;
  right: 0;
  background: rgba(97, 97, 97, 0.9);
  border-radius: 0.4em;
  color: #fff;
}

.speech-bubbleRight:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-right-color: rgba(97, 97, 97, 0.9);
  border-left: 0;
  margin-top: -15px;
  margin-left: -5px;
}
// end right TT
// start left TT
.speech-bubbleLeft {
  position: absolute;
  background: rgba(97, 97, 97, 0.9);
  border-radius: 0.4em;
}

.speech-bubbleLeft:after {
  color: #fff;
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-left-color: rgba(97, 97, 97, 0.9);
  border-right: 0;
  margin-top: -20px;
  margin-right: -20px;
}
// end left TT
</style>