<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="800"
    @keydown.esc="closeModal"
  >
  <div>
    <v-card class="mx-auto">
      <HeaderModal
        :titleModal="$t('generics.info')"
        :closeModalFunction="closeModal"
      />
        <v-card-text>
          <div class="d-flex justify-center">
            <v-row>
              <v-col>
                <p>
                  {{ $t("components.twoFAInfoModal.text1") }}
                </p>
                <p>
                  {{ $t("components.twoFAInfoModal.text2") }}
                </p>
                <p>
                  <span class="font-weight-bold">{{
                    $t("components.twoFAInfoModal.sms")
                  }}</span
                  >: {{ $t("components.twoFAInfoModal.smsText") }}
                </p>
                <p>
                  <span class="font-weight-bold">{{
                    $t("components.twoFAInfoModal.auth")
                  }}</span
                  >: {{ $t("components.twoFAInfoModal.authText") }}
                </p>
                <p>
                  <span class="font-weight-bold">{{
                    $t("components.twoFAInfoModal.pin")
                  }}</span
                  >: {{ $t("components.twoFAInfoModal.pinText") }}
                </p>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <FooterModal :closeModalFunction="closeModal" :showFooter="true" />
      </v-card>
      </div>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  props: ["showModal", "closeModal"],
  components: { HeaderModal, FooterModal },
  data: () => ({
    state: store.state,
  }),
};
</script>