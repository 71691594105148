// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lockMessageIcon[data-v-09af1238] {
  position: absolute;
  right: 28px;
  top: -6px;
}
.textBlue[data-v-09af1238] {
  color: #2a3133;
}
.notiUnread[data-v-09af1238] {
  /* background-color: #f5f5f5; */
}
.btnRed[data-v-09af1238] {
  background: red;
  border-radius: 4px;
}
.vueScroll .__view[data-v-09af1238] {
  width: unset !important;
}
.divNotNotis[data-v-09af1238] {
  height: 40px;
  padding: 9px;
}
.divNotNotis .notNotifications[data-v-09af1238] {
  color: var(--form-text);
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/sentMessagesDropDownEffector.vue","webpack://./sentMessagesDropDownEffector.vue"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;ACAF;ADEA;EACE,cAAA;ACCF;ADCA;EACE,+BAAA;ACEF;ADAA;EACE,eAAA;EACA,kBAAA;ACGF;ADAE;EACE,uBAAA;ACGJ;ADAA;EACE,YAAA;EACA,YAAA;ACGF;ADFE;EACE,uBAAA;ACIJ","sourcesContent":["\n.lockMessageIcon{\n  position: absolute;\n  right: 28px;\n  top: -6px;\n}\n.textBlue{\n  color: #2a3133;\n}\n.notiUnread {\n  /* background-color: #f5f5f5; */\n}\n.btnRed {\n  background: red;\n  border-radius: 4px;\n}\n.vueScroll {\n  .__view {\n    width: unset !important;\n  }\n}\n.divNotNotis {\n  height: 40px;\n  padding: 9px;\n  .notNotifications {\n    color: var(--form-text);\n  }\n}\n",".lockMessageIcon {\n  position: absolute;\n  right: 28px;\n  top: -6px;\n}\n\n.textBlue {\n  color: #2a3133;\n}\n\n.notiUnread {\n  /* background-color: #f5f5f5; */\n}\n\n.btnRed {\n  background: red;\n  border-radius: 4px;\n}\n\n.vueScroll .__view {\n  width: unset !important;\n}\n\n.divNotNotis {\n  height: 40px;\n  padding: 9px;\n}\n.divNotNotis .notNotifications {\n  color: var(--form-text);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
