<template>
  <div>
    <v-dialog persistent  :model-value="showModal"
    @update:model-value="showModal= $event" max-width="560" hide-overlay>
      <div>
        <v-card>
          <!-- <v-card-title class="headline d-flex">
            <v-img
              class="mr-1"
              src="assets/icon.png"
              max-height="30"
              max-width="30"
              contain
            ></v-img>
            <span class="mr-auto">Call {{ inviterName }}</span>
          </v-card-title> -->

          <HeaderModal
            :titleModal="'Call' + inviterName"
          />

          <v-divider></v-divider>

          <v-row flex :style="{ width: '100%', margin: 0 }">
            <v-col class="col-12">
              <div class="d-flex">
                <v-badge
                  bordered
                  overlay
                  height="60"
                  width="60"
                  bottom
                  dot
                  offset-x="17"
                  offset-y="9"
                >
                  <v-avatar
                    tile
                    size="76"
                    class="borderRadius10"
                    style="margin: 0px 10px"
                  >
                    <v-img
                      class="borderRadius10"
                      max-height="76"
                      max-width="76"
                      contain
                      :src="getAvatarForUuid(inviterUUID)"
                      lazy-src="assets/default_profile_picture.png"
                    ></v-img>
                  </v-avatar>
                </v-badge>
                <v-row :style="{ width: '80%', margin: 0 }">
                  <v-col cols="12" class="pb-0 pt-1 d-flex">
                    <ul style="width: 500px; list-style-type: none">
                      <li>
                        <p
                          color="primary"
                          class="userNameCard mb-0 pb-0 mr-auto"
                        >
                          {{ inviterName }}
                        </p>
                      </li>
                      <li v-show="appointmentStarts.length > 10">
                        {{ $t("components.inviteVisitor.appointment") }}
                        {{ appointmentStarts || "" }}:
                      </li>
                    </ul>
                    <v-tooltip location="top">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          id="buttonCallDirectly"
                          ref="callInviter"
                          style="
                            width: 70px !important;
                            height: 70px !important;
                            width: 60px !important;
                          "
                          v-bind="props"
                          color="primary"
                          class="bR4"
                          v-on:click="!inviterOnline ? false : callInviter()"
                        >
                          <font-awesome-icon
                            :icon="['fal', 'phone']"
                            :style="{ fontSize: '50px' }"
                          />
                        </v-btn>
                      </template>
                      <span>{{
                        !inviterOnline
                          ? "Dieser Benutzer ist zur Zeit offline"
                          : $t("components.userProfile.callUser")
                      }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-spacer></v-spacer>
          <!-- <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
            <v-row class="px-2 btns mx-0" flex>
              <v-col cols="8" class="px-0 py-0" align="right">
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      icon
                      color="white"
                      @click="showCamSettings = true"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'camera']"
                        :style="{ fontSize: '30px' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("components.navbar.camAndMic") }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card> -->
          <FooterModal :closeModalFunction="closeModal" class="mt-4">
            <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      icon
                      color="white"
                      @click="showCamSettings = true"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'camera']"
                        :style="{ fontSize: '30px' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("components.navbar.camAndMic") }}</span>
                </v-tooltip>
          </FooterModal>
        </v-card>
      </div>
    </v-dialog>
    <DeviceSetupModal
      v-if="showCamSettings"
      :showSetupDevice="true"
      :toggleModalSettings="closeModal"
    />
    <HistoryMessagesModalEffector
      v-if="showHistoryMessagesModal && isOnlyOneCallUser"
    />
    <SuccessPaymentModal v-if="state.user.paymentState.showSuccessPayment" />
    <ErrorPaymentModal v-if="state.user.paymentState.showErrorPayment" />
    <PaymentCanceled v-if="state.user.paymentState.showCancelPayment" />
    <PaymentEventModal v-if="state.user.requestedPayment.show" />
    <InfoModal v-if="showInfoModal" />
    <SetVisitorNameModal v-if="showVisitorNameModal" />
    <UserProfile v-if="showUserProfilesModal" />
  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import debounce from "lodash.debounce";
import moment from "../../../sharedsrc/moment";
import store, { EventBus, syncedGroupState, syncedUserState } from "../../store";
import { bridgeInfoStore } from "../../effector/users/bridgeInfo";
import { isVisitor, isOnlyOneCallUser } from "../../utils/privileges";
import { historyMessagesModalStore, visitorNameModalStore, userProfileStore } from "../../effector/modals";
import { wsCall } from "../../lib/wsConnect";
import { wsCallStartBridgeCall } from "../../utils/calls";
import DeviceSetupModal from "../modal/deviceSetupModal.vue";
import HistoryMessagesModalEffector from "../modal/historyMessagesModalEffector.vue";
import SuccessPaymentModal from "../modal/paymentModals/successPayment.vue";
import ErrorPaymentModal from "../modal/paymentModals/errorPayment.vue";
import PaymentCanceled from "../modal/paymentModals/paymentCanceled.vue";
import PaymentEventModal from "../modal/paymentModals/paymentEventModal.vue";
import InfoModal from "../modal/infoModal.vue";
import SetVisitorNameModal from "../modal/setVisitorNameModal.vue"
import UserProfile from "../modal/userProfile.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";

export default {
  components: {
    DeviceSetupModal,
    HistoryMessagesModalEffector,
    SuccessPaymentModal,
    ErrorPaymentModal,
    PaymentCanceled,
    PaymentEventModal,
    InfoModal,
    SetVisitorNameModal,
    UserProfile,
    HeaderModal,
    FooterModal
  },
  data() {
    const effector ={
    showHistoryMessagesModal: historyMessagesModalStore,
    bridgeInfoStore,
    showVisitorNameModal: visitorNameModalStore,
    showUserProfilesModal: userProfileStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      showModal: false,
      moment,
      showCamSettings: false,
      fullCallModalOpen: false,
      // Effector
      ...effector,
    };
  },
  watch: {
    "state.user.visitorData.userLastCallTs": function (value) {
      if (value && store.state.user.visitorData.isImmediate) {
        this.logout();
      }
    },
    "state.remoteBridgeStreams": {
      deep: true,
      handler: debounce(function (value) {
        if (store.state.user.visitorData.isImmediate) {
          const hasCall = Object.keys(value || {}).length;
          if (!hasCall) this.logout();
        }
      }, 3000),
    },
    inviterOnline: {
      immediate: true,
      handler: function (value) {
        if (value) {
          if (isVisitor(this.state.ownUUID)) {
            if (
              store.state.user.visitorData.isImmediate &&
              !store.state.user.visitorData.userLastCallTs &&
              store.state.user.visitorData.isOneTime
            ) {
              if (!store.state.persisted.mediaFirstSetupDone) {
                this.showCamSettings = true;
              } else {
                const uuid = this.state.user.visitorData.userInviter;
                if (uuid) {
                  if (!this.isFullCall) {
                    this.callInviter();
                    if(!this.state.user.name){
                      this.showSetVisitorNameModal()
                    }
                  } else {
                    this.showFullCallModal();
                  }
                }
              }
            }
          }
        }
      },
    },
    showInfoModal: {
      handler: function (value) {
        if (!value && this.fullCallModalOpen) {
          this.logout();
        }
      },
    },
  },
  mounted() {
    EventBus.$on("togleMicSettings", this.setShowCameraSettings);
  },
  unmounted(){
    EventBus.$off("togleMicSettings", this.setShowCameraSettings);
  },
  methods: {
    setShowCameraSettings(){
      this.showCamSettings = true;
    },
    showSetVisitorNameModal(){
      // setVisitorNameModalEvent(true)
    },
    showFullCallModal() {
      const data = {
        show: true,
        header: this.$t("generics.info"),
        body: this.$t("generics.callFull") + ".",
      };
      this.fullCallModalOpen = true;
      return store.setinfoModal(data);
    },
    logout() {
      const localSecretKey = "ownSecret_" + this.state.ownUUID;
      const localTwoTokenKey = "ownTwoToken_" + this.state.ownUUID;
      localStorage.removeItem(localSecretKey);
      localStorage.removeItem(localTwoTokenKey);
      localStorage.removeItem("temporalStatus");
      wsCall("revokeTwoToken");
      window.location.reload();
    },
    closeModal() {
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      this.showCamSettings = false;
      if (
        store.state.user.visitorData.isImmediate &&
        !store.state.user.visitorData.userLastCallTs
      ) {
        const uuid = this.state.user.visitorData.userInviter;
        if (uuid) {
          if (!this.isFullCall) {
            this.callInviter();
            if(!this.state.user.name){
              this.showSetVisitorNameModal()
            }
          } else {
            this.showFullCallModal();
          }
        }
      }
      if (this.toggleModalSettings) {
        this.toggleModalSettings();
      }
    },
    callInviter() {
      if (!this.inviterOnline || this.state.group[this.state.ownUUID].directCallUUID !== this.inviterBridgeCallId) {
        return;
      }
      const infoStartBridgeCall = Object.assign({}, this.inviterBridgeCallInfo, { calling: [], isAudioOnly: true });
      infoStartBridgeCall.inBridgeCallListener = !!infoStartBridgeCall.conferenceHall;
      Object.keys(infoStartBridgeCall).forEach(key => infoStartBridgeCall[key] === undefined && delete infoStartBridgeCall[key]);
      if (infoStartBridgeCall.conferenceHall && infoStartBridgeCall.conferenceAttendees?.length) {
        let userIsInConferenceAttendees = false;
        for (let i = 0; i < infoStartBridgeCall.conferenceAttendees.length; i++) {
          const member = infoStartBridgeCall.conferenceAttendees[i];
          if (member.uuid === this.state.ownUUID) {
            userIsInConferenceAttendees = true;
            member.isPodium = false;
            break;
          }
        }
        if (!userIsInConferenceAttendees) {
          infoStartBridgeCall.conferenceAttendees.push({
            addUserToCall: true,
            isPodium: false,
            name: this.getNameForUuid(this.state.ownUUID),
            showUserName: true,
            uuid: this.state.ownUUID,
          });
        }
        infoStartBridgeCall.inBridgeCallListener = true;
      }
      const delay = Promise.all([
        syncedGroupState(true),
        syncedUserState().catch(() => {}),
      ]);
      delay.then(() => {
        wsCallStartBridgeCall(
          this.inviterUUID,
          this.state.ownUUID,
          infoStartBridgeCall,
          true,
          true
        );
      });
      store.addRemoteBridgeStream(
        infoStartBridgeCall.callUUID,
        infoStartBridgeCall
      );
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
  },
  computed: {
    showInfoModal() {
      return (
        store.state.infoModal.show &&
        store.state.infoModal.header &&
        store.state.infoModal.body
      );
    },
    isFullCall() {
      if (this.isConferenceCall) {
        if (this.inviterBridgeCallInfo.conferenceAttendees.length < 100) {
          return false;
        } else {
          return true;
        }
      } else {
        if (
          this.bridgeInfoStore &&
          this.bridgeInfoStore.calls &&
          this.bridgeInfoStore.calls[this.inviterBridgeCallId]
        ) {
          const callObject = this.bridgeInfoStore.calls[this.inviterBridgeCallId];
          if (callObject.users.length >= 100) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
    isOnlyOneCallUser() {
      return isOnlyOneCallUser(store.state.ownUUID);
    },
    visitorData() {
      return this.state.user.visitorData;
    },
    inviterUUID() {
      return this.visitorData.userInviter;
    },
    inviterState() {
      return this.state.group[this.inviterUUID] || {};
    },
    inviterUser() {
      return this.inviterState.user || {};
    },
    inviterOnline() {
      return this.inviterState.connected;
    },
    inviterBridgeCallId() {
      return this.inviterUser.inBridgeCall;
    },
    inviterBridgeCallInfo() {
      return this.inviterUser.bridgeCallInfo || {};
    },
    inviterName() {
      return this.inviterUser.name;
    },
    appointmentStarts() {
      return (
        this.visitorData.linkDateTo +
        " " +
        this.$t("components.inviteVisitor.at") +
        " " +
        this.visitorData.linkTimeTo
      );
    },
    isConferenceCall(){
      return this.inviterBridgeCallInfo.isConferenceCall && !this.inviterBridgeCallInfo.conferenceHall
    }
  },
};
</script>