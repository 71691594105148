import { newVersionAvailableModalDomain } from './domain';

import { setNewVersionAvailableModalEvent, resetNewVersionAvailableModalEvent } from './events';

export const newVersionAvailableModalStore = newVersionAvailableModalDomain.createStore(false, { name: 'newVersionAvailableModal' });

newVersionAvailableModalStore.on(setNewVersionAvailableModalEvent, (state, payload) => {
  return payload;
});

newVersionAvailableModalStore.reset(resetNewVersionAvailableModalEvent);
