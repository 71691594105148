import './lib/splashAndTheme'; // Load as fast as you can!
import { storeInit } from './store';
import konamiInit from './lib/konami';
import { preloadSettingsAsync } from './lib/rtcSettings';
import { wsInit } from './lib/wsConnect';
import './effector/init';
import 'olm';

const serviceWorkerVersion = process.env.PACKAGE_VERSION; 

async function onDomLoaded (event) {
  // console.log('Dom loaded.', event);
  storeInit();
  konamiInit();
  preloadSettingsAsync();
  wsInit();

  if (window.Olm) {
    window.Olm.init().catch(e => {
      console.error('Failed to initialize Olm, E2EE will be disabled', e);
      delete window.Olm;
    });
  } else {
    console.error('Failed to initialize Olm, E2EE will be disabled');
  }

  // Check that service workers are supported
  if ('serviceWorker' in navigator) {
    // Use the window load event to keep the page load performant
    window.addEventListener('load', () => {
      navigator.serviceWorker.register(`/service.bundle.js?v=${serviceWorkerVersion}`);
    });
  }
}

// Invokes method to shim Promise.allSettled if it is unavailable or noncompliant.
require('promise.allsettled').shim();

window.AudioContext = window.AudioContext || window.webkitAudioContext;
window.$ = window.jQuery = require('jquery');
document.addEventListener('DOMContentLoaded', onDomLoaded);
