<template>
  <v-list max-width="320">
    <v-list-group
      :value="searchFoundSomethingTeam || isActive"
      @click="setList('teams')"
      :color="isDarkMode ? 'white' : 'black'"
    >
      <template v-slot:activator>
        <v-icon>
          <font-awesome-icon class="text-primary" :icon="['fal', 'sitemap']" />
        </v-icon>
        <v-list-item-title
          :class="`${
            getRoute === '/organisation' &&
            state.activeOrganisationSection == 'teams'
              ? 'font-weight-bold'
              : ''
          }`"
          @click.stop.prevent="
            handleOrganisation();
            searchFoundSomethingTeam;
          "
        >
          <span class="pr-4">{{ totalTeams || 0 }}</span>
          {{
            state.namespaceSettings.teamAlias
              ? state.namespaceSettings.teamAlias
              : $t("components.teams.teams")
          }}
        </v-list-item-title>
      </template>
      <div>
        <v-list density="compact" class="listHeight">
          <v-list-item link class="ml-9" v-for="team in teams" :key="team.team">
            <div :class="selectedTeamName === team.team ? 'font-weight-bold primary--text': ''"   class="text-truncate w80 cursorAuto" @click="openUserTeam(team.team)" >
              {{ team.team }}
            </div>
            <v-icon
              class="alingIcon"
              @click="
              openMenu($event, team.team)
              "
            >
              <font-awesome-icon
                class="text-primary"
                :icon="['fal', 'video']"
              />
            </v-icon>
          </v-list-item>
        </v-list>
        <!-- MENU -->
        <v-menu
          :model-value="showMenuTeam" @update:model-value="showMenuTeam = $event"
          :close-on-click="closeOnClick"
          :close-on-content-click="closeOnContent"
          :position-x="positionX"
          :position-y="positionY"
        >
          <v-list v-if="selectedTeam">
            <div class="userListTeams">
              <v-list-item
                v-for="(item, y) in membersOfTeams(selectedTeam)"
                :key="y"
                class="mencheckbox section"
                item-text="name"
              >
                <v-checkbox
                  :model-value="actionCallUserList" @update:model-value="actionCallUserList = $event; groupVideoCall($event, item.id)"
                  class="mencheckbox chkList sel-check-item"
                  :label="item.name"
                  :value="item.id"
                  :disabled="disableCallBtnForConference(item.id)"
                ></v-checkbox>
              </v-list-item>
            </div>
            <v-list-item class="select-box justify-center">
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <!-- <v-btn
                    v-bind:disabled="actionCallUserList.length === 0"
                    v-on="on"
                    icon
                    color="white"
                    @click="callPeople"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'phone']"
                      :style="{ fontSize: '20px' }"
                    />
                  </v-btn> -->
                  <v-btn
                  class="ml-3 buttonIconPrimaryFooter text-primary px-2"
                  v-bind="props"
                  tile
                  color="white"
                  @click="callPeople"
                  v-bind:disabled="actionCallUserList.length === 0 || amIInCall"
                >
                  {{ $t("generics.group") }} 
                </v-btn>
                </template>
                <span>{{ $t("components.userListItem.videoCall") }}</span>
              </v-tooltip>
              <!-- <span style="font-size: 14px; color: white">{{
                $t("generics.group")
              }}</span> -->
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- END MENU -->
      </div>
      <!-- <div class="teamsList">
        <v-list-group
          two-line
          no-action
          sub-group
          v-for="team in teams"
          :key="team.team"
          :color="isDarkMode? 'white':'black'"
        >
          <template v-slot:activator>
            <v-list-item-content class="text-truncate">
              <v-list-item-title class="fontSize15">{{
                team.team
              }}</v-list-item-title>
              <v-list-item-subtitle style="position: absolute; bottom: -15px">
                <v-menu
                  bottom
                  origin="center center"
                  transition="scale-transition"
                  :close-on-click="closeOnClick"
                  :close-on-content-click="closeOnContent"
                >
                  <template #activator="{ on: onMenu }">
                    <v-tooltip location="top">
                      <template v-slot:activator="{ on: onTooltip }">
                        <v-icon
                          v-on="{ ...onMenu, ...onTooltip }"
                          @click="
                            actionCallUserList = [];
                            allUserActionList = [];
                            hookFirst4(team.team);
                          "
                        >
                          <font-awesome-icon
                            class="primary--text"
                            :icon="['fal', 'video']"
                          />
                        </v-icon>
                      </template>
                      <span
                        >{{ $t("components.userListItem.videoCallTeam") }}
                        {{ team.team }}
                        {{ $t("components.userListItem.teamCall") }}</span
                      >
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, y) in membersOfTeams(team.team)"
                      :key="y"
                      class="mencheckbox section"
                      item-text="name"
                    >
                      <v-checkbox
                        :model-value="actionCallUserList" @update:model-value="actionCallUserList = $event"
                        class="mencheckbox chkList sel-check-item"
                        :label="item.name"
                        :value="item.id"
                        @change="groupVideoCall($event, item.id)"
                      ></v-checkbox>
                    </v-list-item>
                    <v-list-item class="select-box">
                      <v-tooltip location="top">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-bind:disabled="actionCallUserList.length === 0"
                            v-on="on"
                            icon
                            color="white"
                            @click="callPeople"
                          >
                            <font-awesome-icon
                              :icon="['fal', 'phone']"
                              :style="{ fontSize: '20px' }"
                            />
                          </v-btn>
                        </template>
                        <span>{{
                          $t("components.userListItem.videoCall")
                        }}</span>
                      </v-tooltip>
                      <span style="font-size: 14px; color: white">{{
                        $t("generics.group")
                      }}</span>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu
                  bottom
                  origin="center center"
                  transition="scale-transition"
                  :close-on-click="closeOnClick"
                  :close-on-content-click="closeOnContent"
                >
                  <v-list>
                    <v-checkbox
                      :model-value="allUserActionList" @update:model-value="allUserActionList = $event"
                      class="mencheckbox sel-check-item-first"
                      label="All users"
                      value="all"
                    ></v-checkbox>
                    <v-divider></v-divider>
                    <v-list-item
                      v-for="(item, y) in membersOfTeams(team.team)"
                      :key="y"
                      class="mencheckbox section sel-check-item"
                      item-text="name"
                    >
                      <v-checkbox
                        :model-value="actionUserList" @update:model-value="actionUserList = $event"
                        class="mencheckbox sel-check-item"
                        :label="item.name"
                        :value="item.id"
                        @change="messageCBUserChange($event, item.id)"
                      ></v-checkbox>
                    </v-list-item>
                    <v-list-item class="select-box">
                      <v-tooltip location="top">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-bind:disabled="actionUserList.length === 0"
                            v-on="on"
                            icon
                            color="white"
                            @click.stop.prevent="setShowModalSenMsg(ownUUID)"
                          >
                            <font-awesome-icon
                              class="primary--text"
                              :icon="['fal', 'paper-plane']"
                              :style="{ fontSize: '20px' }"
                            />
                          </v-btn>
                        </template>
                        <span>{{
                          $t("components.sendMessageModal.send")
                        }}</span>
                      </v-tooltip>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <UserList :persons="team.people" :section="team.team" />
        </v-list-group>
        <infinite-loading :distance="10" @infinite="infiniteHandler">
          <div slot="no-results"></div>
          <div slot="no-more"></div>
        </infinite-loading>
      </div> -->
    </v-list-group>
  </v-list>
</template>
<script>
import store,{ EventBus } from "../../store";
import UserList from "./userList.vue";
// import { v4 as uuidv4 } from "uuid";
import { disableCallBtn, wsCallStartBridgeCall, userInCall } from "../../utils/calls";
import { uid } from "../../utils/basicFunctions";

export default {
  props: [
    "data",
    "active",
    "setList",
    "totalTeams",
    "searchFoundSomethingTeam",
    "isMobile",
  ],
  components: {
    UserList,
  },
  data: () => ({
    state: store.state,
    closeOnClick: true,
    closeOnContent: false,
    allUserActionList: [],
    allUserTaskActionList: [],
    actionUserList: [],
    actionCallUserList: [],
    actionTaskUserList: [],
    ownUUID: store.state.ownUUID,
    selectAll: true,
    activeTeam: "",
    teamsArray: [],
    sliceIndex: 5,
    showMenuTeam: false,
    selectedTeam: undefined,
    positionX: 0,
    positionY:0,
    selectedTeamName: null,
  }),
  watch: {
    allUserActionList: function () {
      let allofthem = this.membersOfTeams(this.activeTeam);
      if (
        allofthem.length !== this.actionUserList.length &&
        this.actionUserList.length > 0 &&
        this.allUserActionList.length === 0
      ) {
        return;
      }
      if (this.allUserActionList.length > 0) {
        this.messageCheckAll();
      } else {
        this.messageUnchekAll();
      }
    },
    allUserTaskActionList: function () {
      let allofthem = this.membersOfTeams(this.activeTeam);
      if (
        allofthem.length !== this.actionTaskUserList.length &&
        this.actionTaskUserList.length > 0 &&
        this.allUserTaskActionList.length === 0
      ) {
        return;
      }
      if (this.allUserTaskActionList.length > 0) {
        this.tasksCheckAll();
      } else {
        this.tasksUnchekAll();
      }
    },
     $route (to, from){
      if(to.path !== '/teams'){
        this.selectedTeamName = null;
      }
    }
  },
  methods: {
    openUserTeam(teamName){
      if(this.selectedTeamName === teamName){
        this.selectedTeamName = null;
        EventBus.$emit('showUsersOrganisation', undefined);
        return;
      }
      this.selectedTeamName = teamName
        const data = {
          type:'teams',
          name: teamName
        };
      if(this.$router.currentRoute.path !== '/teams'){
        this.$router.push({ path: `/teams` });
      }
      setTimeout(() => {
        EventBus.$emit('showUsersOrganisation', data);
      }, 900);
    },
    disableCallBtnForConference(uuid){
      const person  = this.state.group[uuid]
      if(!uuid || !person) return true
      if(uuid == this.ownUUID) return true
      if(!person.connected) return true
      if(this.state.user.inBridgeCall && this.state.user.inBridgeCall.length>0) return true
      if(person.user.inBridgeCall && person.user.inBridgeCall.length>0) return true
      if(person.user.activity === "Out of Office" || person.user.activity === 'No Calls' || person.user.activity === 'No status' || store.state.isCallFull) return true
    },
    openMenu(evt, team){
      this.actionCallUserList = [];
      this.allUserActionList = [];
      this.showMenuTeam = !this.showMenuTeam;
      if (this.selectedTeam && this.selectedTeam !== team) {
        setTimeout(() => {
        this.showMenuTeam = true;
        }, 250);
      }
      this.selectedTeam = team;
      this.positionX = evt.clientX;
      this.positionY = evt.clientY + 10;
    },
    infiniteHandler($state) {
      if (this.sliceIndex < this.teamsArray.length) {
        this.sliceIndex += 5;
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    // hookFirst4(team) {
    //   this.membersOfTeams(team).forEach((element, index) => {
    //     if (index < 4) {
    //       this.actionCallUserList.push(element.id);
    //     }
    //   });
    // },
    disableCallBtn(person) {
      if(!person)return 
      return disableCallBtn(person);
    },
    callPeople() {
      this.showMenuTeam = false;
      let peopleToCall = this.actionCallUserList;
      const id = uid();
      let cleanedDuplicates = peopleToCall.filter((item, index) => {
        return peopleToCall.indexOf(item) === index;
      });
      cleanedDuplicates.map((uuid, idx) => {
        if (
          !this.disableCallBtn(this.state.group[uuid]) &&
          uuid !== this.state.ownUUID
        ) {
          // callUUID(uuid);
          const infoStartBridgeCall = {
            callUUID: id,
            isNormalMode: true,
            initiator: this.state.ownUUID,
            callStartTs: Date.now(),
            callDurationMs: Math.floor(60000 * 20),
            infiniteCall: false,
          };
          wsCallStartBridgeCall(
            uuid,
            this.state.ownUUID,
            infoStartBridgeCall,
            idx > 0
          );
        }
      });
      const info = {
        callUUID: id,
        calling: cleanedDuplicates,
        isNormalMode: true,
        initiator: this.state.ownUUID,
        callStartTs: Date.now(),
        callDurationMs: Math.floor(60000 * 20),
        infiniteCall: false,
      };
      store.addRemoteBridgeStream(id, info);
      store.setCurrentContentVisile("", false, this.$router);
      // this.setCurrentContentVisile("", false, this.$router);
    },
    groupVideoCall(event, user) {
      if (event.length > 4) {
        this.actionCallUserList.forEach(function (item, index, object) {
          if (item === user) {
            object.splice(index, 1);
          }
        });
      }
    },
    messageCheckAll() {
      let allofthem = this.membersOfTeams(this.activeTeam);
      if (allofthem.length) {
        this.actionUserList = [];
        allofthem.forEach((element) => {
          this.actionUserList.push(element.id);
        });
      }
    },
    messageUnchekAll() {
      this.actionUserList = [];
    },
    tasksCheckAll() {
      let allofthem = this.membersOfTeams(this.activeTeam);
      if (allofthem.length) {
        this.actionTaskUserList = [];
        allofthem.forEach((element) => {
          this.actionTaskUserList.push(element.id);
        });
      }
    },
    tasksUnchekAll() {
      this.actionTaskUserList = [];
    },
    messageCBUserChange(event, user) {
      let allofthem = this.membersOfTeams(this.activeTeam);
      const isAllChecked = this.allUserActionList.length !== 0;
      let ischecked = (event || []).indexOf(user) !== -1;
      if (!ischecked && isAllChecked) {
        this.allUserActionList = [];
      } else if (ischecked) {
        if (allofthem.length === event.length) {
          this.allUserActionList.push("all");
        }
      }
    },
    handleOrganisation: function () {
      this.setCurrentContentVisile("", false, this.$router);
      this.setCurrentContentVisile("teams", true, this.$router);
      this.selectedTeamName = null;
      EventBus.$emit('showUsersOrganisation', undefined);
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
    setShowModalSenMsg(uuid) {
      const data = {
        options: "multUsers",
        recipients: this.actionUserList,
        team: this.activeTeam,
        isAllMembers:
          this.membersOfTeams(this.activeTeam).length ===
          this.actionUserList.length,
      };
      return store.setShowModalSenMsg(uuid, data);
    },
    membersOfTeams: function (team) {
      let filteredMembers = [];
      const supervisors = [];
      const members =
        (
          ((store.state.namespaceSettings.processedOrganisation || {}).teams ||
            {})[team] || {}
        ).members || [];
      const rawSupervisors =
        (
          ((store.state.namespaceSettings.processedOrganisation || {}).teams ||
            {})[team] || {}
        ).supervisors || [];
      rawSupervisors.forEach((element) => {
        if (element.rights > 0) {
          supervisors.push(element.uuid);
        }
      });
      const data = members.concat(supervisors);
      if (data) {
        for (let index = 0; index < data.length; index++) {
          const ouuid = data[index];
          const oname =
            ((store.state.group[ouuid] || {}).user || {}).name || "noname";
          const myData = { id: ouuid, name: oname };
          filteredMembers.push({ ...myData });
        }
      }
      return filteredMembers;
    },
  },
  computed: {
    amIInCall(){
      return userInCall(this.state.ownUUID)
    },
    isDarkMode() {
      return this.state.persisted.isDark;
    },
    teams() {
      this.teamsArray = [];
      for (let key in this.data) {
        let tmpObject = {
          people: this.data[key],
          team: key,
        };
        this.teamsArray.push(tmpObject);
      }
      // return this.teamsArray.slice(0, this.sliceIndex);
      return this.teamsArray;
    },
    isActive() {
      return this.active == "teams";
    },
    getRoute() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped lang="scss">
.cursorAuto{
  cursor: auto;
}
.userListTeams{
  max-height: 200px;
  overflow:auto;
}
.w80 {
  width: 80%;
}
.alingIcon {
  right: 12px;
  position: absolute;
  top: 3px;
}
.listHeight {
  max-height: 160px;
  overflow: auto;
}
.fontSize15 {
  font-size: 15px !important;
}
.teamsList {
  max-height: calc(100vh - 650px);
  overflow-y: scroll;
  overflow-x: hidden;
  @media screen and (max-height: 650px) {
    max-height: 280px !important;
  }
}
.select-box {
  background-color: var(--v-primary-base) !important;
}

.mencheckbox {
  padding: 0px !important;
  margin: 0px;
  margin-right: 24px;
}

.mencheckbox .v-input__control {
  height: 20px;
  padding-left: 10px;
}

.v-list {
  padding: 0;
}

.sel-check-item {
  height: 20px !important;
  padding-left: 10px !important;
}
.sel-check-item-first {
  height: 35px !important;
  padding-left: 20px !important;
}
.sel-check-item-first > .v-input__control {
  margin-top: 8px !important;
}

.v-list-item__icon {
  margin-right: 8px !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
</style>
<style lang="scss">
.vListGroupSections {
  .v-list-item {
    height: 36px !important;
    min-height: 36px !important;
    max-height: 36px !important;
  }
  .v-list-item__icon {
    margin: 5px !important;
  }
}
</style>