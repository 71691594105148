// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imgLogo[data-v-dbf7afe2] {
  height: 150px;
  margin: 80px auto 20px;
}
.titleWelcome[data-v-dbf7afe2] {
  margin: 80px auto 20px;
  font-size: 30px;
  display: block;
  color: #2a3133;
}
.subtitleWelcome[data-v-dbf7afe2] {
  font-size: 19px;
}
.btnWelcome[data-v-dbf7afe2] {
  width: 30%;
}`, "",{"version":3,"sources":["webpack://./websrc/components/firstSetup/subdomainAvailable.vue","webpack://./subdomainAvailable.vue"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;ACAF;ADEA;EACE,sBAAA;EACA,eAAA;EACA,cAAA;EACA,cAAA;ACCF;ADCA;EACE,eAAA;ACEF;ADAA;EACE,UAAA;ACGF","sourcesContent":["\n.imgLogo {\n  height: 150px;\n  margin: 80px auto 20px;\n}\n.titleWelcome {\n  margin: 80px auto 20px;\n  font-size: 30px;\n  display: block;\n  color: #2a3133;\n}\n.subtitleWelcome {\n  font-size: 19px;\n}\n.btnWelcome {\n  width: 30%;\n}\n",".imgLogo {\n  height: 150px;\n  margin: 80px auto 20px;\n}\n\n.titleWelcome {\n  margin: 80px auto 20px;\n  font-size: 30px;\n  display: block;\n  color: #2a3133;\n}\n\n.subtitleWelcome {\n  font-size: 19px;\n}\n\n.btnWelcome {\n  width: 30%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
