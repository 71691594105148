// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-list-item__prepend {
  width: 32px;
}
.usersListItemNav .v-list-item__icon .v-list-group__header__append-icon {
  min-width: 35px !important;
}
.ctrs {
  top: 50px !important;
  left: 15px;
}
.iconsSize20 {
  width: 20px !important;
  height: 20px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/users.vue","webpack://./users.vue"],"names":[],"mappings":"AACA;EACE,WAAA;ACAF;ADGE;EACE,0BAAA;ACAJ;ADGA;EACE,oBAAA;EACA,UAAA;ACAF;ADEA;EACE,sBAAA;EACA,uBAAA;ACCF","sourcesContent":["\n.v-list-item__prepend {\n  width: 32px;\n}\n.usersListItemNav {\n  .v-list-item__icon .v-list-group__header__append-icon {\n    min-width: 35px !important;\n  }\n}\n.ctrs {\n  top: 50px !important;\n  left: 15px;\n}\n.iconsSize20{\n  width: 20px !important;\n  height: 20px !important;\n}\n",".v-list-item__prepend {\n  width: 32px;\n}\n\n.usersListItemNav .v-list-item__icon .v-list-group__header__append-icon {\n  min-width: 35px !important;\n}\n\n.ctrs {\n  top: 50px !important;\n  left: 15px;\n}\n\n.iconsSize20 {\n  width: 20px !important;\n  height: 20px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
