<template>
  <div
    class="px-2 d-flex justify-space-between align-baseline w100"
    v-if="systemMessagesArray"
    :style="`background:${getColorSystemMessage}; color:${systemMessagesColorText(getColorSystemMessage)}`"
  >
    <div
      v-if="systemMessagesArray[0] && systemMessagesArray[0].body"
      v-html="$sanitize(checkForUrls(systemMessagesArray[0].body))"
    ></div>
    <div>
      <v-btn
        icon
        @click="readSystemMessage(systemMessagesArray[0].messageUUID)"
        :color="systemMessagesColorText(getColorSystemMessage)"
      >
        <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '25px' }" />
      </v-btn>
    </div>
  </div>
</template>

<script>
import store from "../../store";
import { isMobile, isIpad } from "../../lib/mobileUtil";
import { markAsReadSpecificMessageEvent } from "../../effector/message";
import { systemMessagesColorText } from "../../utils/color"
import { checkForUrls } from "../../utils/basicFunctions";
import moment from "moment";

export default {
  props: ["systemMessagesArray"],
  components: {},
  data() {
    return {
      state: store.state,
      moment: moment,
      isMobile: isMobile(),
      isIpad: isIpad(),
    };
  },
  watch: {
    systemMessagesArray: {
      immediate: true,
      handler: function (menssages) {
        menssages.forEach((message, index) => {
          if (index > 0) {
            markAsReadSpecificMessageEvent(message.messageUUID);
          }
        });
      },
    },
    'state.currentTS': {
      handler: function (currentTS) {
        const actualMessage = this.systemMessagesArray && this.systemMessagesArray[0];
        if (actualMessage && actualMessage.expiryTs && actualMessage.expiryTs < currentTS) {
          this.readSystemMessage(actualMessage.messageUUID);
        }
      },
    }
  },
  methods: {
    systemMessagesColorText(color) {
      return systemMessagesColorText(color)
    },
    readSystemMessage(messageUUID) {
      markAsReadSpecificMessageEvent(messageUUID);
    },
    checkForUrls(message) {
      return checkForUrls(message, false);
    },
  },
  computed: {
    getColorSystemMessage() {
      if (
        this.systemMessagesArray &&
        this.systemMessagesArray[0] &&
        this.systemMessagesArray[0].header &&
        this.systemMessagesArray[0].header.color
      ) {
        return this.systemMessagesArray[0].header.color;
      } else {
        return "#f5f5f5";
      }
    },
  },
};
</script>