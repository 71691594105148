<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="570"
    @keydown.esc="closeModal"
  >
    <template>
      <v-card class="mx-auto">
        <HeaderModal
          :titleModal="$t('generics.info')"
          :closeModalFunction="closeModal"
        />
        <v-container>
          <p>
            {{ $t("components.mobile.insideCallText1") }}
          </p>
        </v-container>
        <FooterModal :closeModalFunction="closeModal"> </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../../store";
import HeaderModal from "../../modal/modalComponents/headerModal.vue";
import FooterModal from "../../modal/modalComponents/footerModal.vue";
import {
  resetMobileInsideCallModalEvent,
  insideMobileModalStore,
} from "../../../effector/modals";

export default {
  //   props: ["showModal", "closeModal"],
  components: {
    HeaderModal,
    FooterModal,
  },
  data() {
    const effector = {
      showModal: insideMobileModalStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      // Effector
      ...effector,
    };
  },
  mounted() {},
  unmounted() {},
  watch: {},
  methods: {
    closeModal() {
      resetMobileInsideCallModalEvent();
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
</style>
