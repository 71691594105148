<template>
  <v-main class="px-0 py-0 contentGridWrapper">
    <v-row class="mx-auto" v-if="!showUsersGrid">
      <v-col
        v-for="(section, index) in gridData"
        :key="index"
        class="custom6cols pb-1 px-2"
        cols="6"
      >
        <v-card
          class="cardWidth"
          @click="
            handleClicks($event, getSupervisorCardUserPerson(section), section)
          "
          :style="
            getBorderByStatusFull(getSupervisorPerson(section))
          "
        >
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <div v-bind="props">
                <v-img
                  :src="getSupervisorAvatar(section, 'sections')"
                  class="text-white align-end"
                  height="180px"
                >
                  <div class="gradientBg">
                    <v-card-subtitle
                      style="color: #fff !important"
                      class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate"
                    >
                      {{ section.team }}
                    </v-card-subtitle>
                    <v-card-subtitle
                      style="color: #fff !important"
                      class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate subTitleCard"
                    >
                      {{
                        getSupervisorTitel(section) +
                        " " +
                        getSupervisorName(section)
                      }}
                    </v-card-subtitle>
                    <ButtonsFrontCard
                      :person="getSupervisorByUuid(section)"
                      :uuid="getSupervisorUuid(section)"
                    />
                  </div>
                  <ButtonsCardWrapper
                    :person="getSupervisorPerson(section)"
                    :isRegularGrid="true"
                  />
                </v-img>
              </div>
            </template>
            <UserInfoTooltip
              :person="getSupervisorPerson(section)"
            ></UserInfoTooltip>
          </v-tooltip>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-auto" v-if="showUsersGrid">
      <v-col
        v-for="(user, index) in usersArray"
        :key="index"
        class="custom6cols pb-1 px-2"
        cols="6"
      >
        <v-card
          class="cardWidth"
          @click="
            handleClicks($event, user, user.user.uuid)
          "
          :style="
            getBorderByStatusFull(user)
          "
        >
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <div v-bind="props">
                <v-img
                  :src="getAvatarForUuid(user.user.uuid)"
                  class="text-white align-end"
                  height="180px"
                >
                  <div class="gradientBg">
                    <v-card-subtitle
                      style="color: #fff !important"
                      class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate subTitleCard"
                    >
                      {{
                        getTitelByUuid(user.user.uuid) +
                        " " +
                        getNameByUuid(user.user.uuid) 
                      }}
                    </v-card-subtitle>
                    <ButtonsFrontCard
                      :person="user"
                      :uuid="user.user.uuid"
                    />
                  </div>
                  <ButtonsCardWrapper
                    :person="user"
                    :isRegularGrid="true"
                  />
                </v-img>
              </div>
            </template>
            <UserInfoTooltip
              :person="user"
            ></UserInfoTooltip>
          </v-tooltip>
        </v-card>
      </v-col>
    </v-row>
    <infinite-loading :distance="5" @infinite="infiniteHandler">
      <div slot="no-results"></div>
      <div slot="no-more"></div>
    </infinite-loading>
  </v-main>
</template>

<script>
import store, { EventBus } from "../../store";
import UserInfoTooltip from "./userInfoTooltip.vue";
import { callUUID } from "../../lib/rtcConn";
import DefaultAvatarGrid from "../image/defaultAvatarGrid.vue";
import UserProfile from "../modal/userProfile.vue";
import BackOrganisationCard from "./backOrganisationCard.vue";
import ButtonsFrontCard from "./buttonsFrontCard.vue";
import ButtonsCardWrapper from "./buttonsCardWrapper.vue";
import {
  callPerson,
  disableCallBtn,
  personIsInBridgeCall,
  directBridgeCall,
} from "../../utils/calls";
import { getBorderByStatusFull } from "../../utils/basicFunctions";
export default {
  components: {
    ButtonsFrontCard,
    DefaultAvatarGrid,
    UserProfile,
    UserInfoTooltip,
    BackOrganisationCard,
    ButtonsCardWrapper,
  },
  props: ["data"],
  data() {
    return {
      state: store.state,
      clicks: 0,
      timer: null,
      deleteFavorite: {
        show: false,
        person: undefined,
      },
      ownUUID: store.state.ownUUID,
      sliceIndex: 48,
      dataArray: [],
      showUsersGrid: false,
      usersArray:[]
    };
  },
  mounted() {
    for (let key in this.sortedData) {
      let tmpObject = {
        department: this.data[key].department,
        members: this.data[key].members,
        supervisors: this.data[key].supervisors,
        team: key,
      };
      this.dataArray.push(tmpObject);
    }
  },
  beforeMount() {
    EventBus.$on("showUsersOrganisation", this.showUsersOrganisation);
  },
  unmounted() {
    EventBus.$off("showUsersOrganisation", this.showUsersOrganisation);
  },
  methods: {
    showUsersOrganisation(data) {
      if (data) {
        this.showUsers(data)
      } else {
        this.showUsersGrid = false;
        this.usersArray = [];
      }
    },
    getNameByUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelByUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    showUsers(dataUser){
      const type = dataUser.type
      const name = dataUser.name
      this.usersArray = [];
      if(!type || !name) return;
      this.showUsersGrid = true;
      Object.keys(this.userList).forEach(uuid=>{
        switch (type) {
          case 'teams':
            if(this.userList[uuid]['memberOfTeams']?.find(i=> i === name)){
              if(!this.usersArray.find(e=>e.user.uuid === uuid)){
                this.usersArray.push(this.state.group[uuid])
              }
            }
            break;
            case 'departments':
              if(this.userList[uuid]['teamDepartments']?.find(i=> i === name)){
                if(!this.usersArray.find(e=>e.user.uuid === uuid)){
                  this.usersArray.push(this.state.group[uuid])
                }
              }
            break;
            case 'sections':
              if(this.userList[uuid]['teamSections']?.find(i=> i === name)){
                if(!this.usersArray.find(e=>e.user.uuid === uuid)){
                  this.usersArray.push(this.state.group[uuid])
                }
              }
            break;
          default:
            break;
        }
      })
    },
    getBorderByStatusFull(person) {
      return getBorderByStatusFull(person);
    },
    infiniteHandler($state) {
      if (this.sliceIndex < this.dataArray.length) {
        this.sliceIndex += 6;
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    getSupervisorPerson(section) {
      if (
        section?.supervisors?.length &&
        section.supervisors[0].uuid &&
        this.state.group[section.supervisors[0].uuid]
      ) {
        return Object.assign(
          { uuid: section.supervisors[0].uuid },
          this.state.group[section.supervisors[0].uuid]
        );
      }
      return {};
    },
    callUser(uuid) {
      callPerson(uuid, this.$router);
    },
    getSupervisorTitel(value) {
      if (
        value?.supervisors?.length &&
        value.supervisors[0].uuid &&
        this.state.group[value.supervisors[0].uuid]?.user?.titel &&
        this.state.group[value.supervisors[0].uuid].user.titel !== "unknown"
      ) {
        return this.state.group[value.supervisors[0].uuid].user.titel;
      }
      return "";
    },
    getSupervisorName(card) {
      if (
        card?.supervisors?.length &&
        card.supervisors[0].uuid &&
        this.state.group[card.supervisors[0].uuid]?.user?.name
      ) {
        return this.state.group[card.supervisors[0].uuid].user.name;
      }
      return "";
    },
    getSupervisorByUuid(value) {
      if (
        value?.supervisors?.length &&
        value.supervisors[0].uuid
      ) {
        return this.state.group[value.supervisors[0].uuid];
      }
      return false;
    },
    getSupervisorUuid(value) {
      if (
        value?.supervisors?.length &&
        value.supervisors[0].uuid
      ) {
        return value.supervisors[0].uuid;
      }
      return false;
    },
    getSupervisorAvatar(card, type) {
      const defaultImage = "img/default_profile_picture.png";
      try {
        if (type === "sections") {
          // loop in sections searching for the card name
          if (card.supervisors.length > 0) {
            return (
              this.state.group[card.supervisors[0].uuid].user.avatar ||
              defaultImage
            );
          } else {
            return defaultImage;
          }
        }
        if (type === "departments") {
          // loop in departments searching for the card name
          if (card.supervisors.length > 0) {
            return (
              this.state.group[card.supervisors[0].uuid].user.avatar ||
              defaultImage
            );
          } else {
            return defaultImage;
          }
        }
        if (type === "teams") {
          // loop in departments searching for the card name
          if (card.supervisors.length > 0) {
            return (
              this.state.group[card.supervisors[0].uuid].user.avatar ||
              defaultImage
            );
          } else {
            return defaultImage;
          }
        }
      } catch {}
    },
    getSupervisorCardUser(card) {
      if (
        card?.supervisors?.length &&
        card.supervisors[0].uuid &&
        this.state.group[card.supervisors[0].uuid]
      ) {
        return this.state.group[card.supervisors[0].uuid].user;
      }
      return null;
    },
    getSupervisorCardUserPerson(card) {
      if (
        card?.supervisors?.length &&
        card.supervisors[0].uuid &&
        this.state.group[card.supervisors[0].uuid]
      ) {
        return this.state.group[card.supervisors[0].uuid];
      }
      return null;
    },
    handleClicks(event, person, section) {
      this.clicks++;
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          this.clicks = 0;
          if (this.getSupervisorUuid(section) !== this.ownUUID) {
            if (!this.disableCallBtn(person)) {
              this.callUser(person.uuid);
            }
          }
        }, 700);
      } else {
        clearTimeout(this.timer);
        this.clicks = 0;
        if (!this.disableCallBtn(person)) {
          if (this.inBridgeCall) {
            this.callUser(person.uuid);
          } else {
            if (!this.userIsInCall(person)) {
              if (personIsInBridgeCall(person.uuid)) {
                this.callUser(person.uuid);
              } else {
                this.directBridgeCall(person.uuid);
              }
            }
          }
        }
      }
    },
    directBridgeCall(uuid) {
      directBridgeCall(uuid, this.$router);
    },
    defaultCall(uuid) {
      this.setdurationMeeting();
      this.setCurrentContentVisile("", false, this.$router);
      callUUID(uuid);
    },
    setdurationMeeting() {
      return store.setdurationMeeting(300000);
    },
    disableCallBtn(personObj) {
      let person = personObj;
      return disableCallBtn(person);
    },
    userIsInCall(person) {
      if (
        person.connected &&
        person.user &&
        person.user.rtcCallStatus &&
        person.user.rtcCallStatus.length > 5
      ) {
        return true;
      }
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
  },
  computed: {
    gridData() {
      return this.dataArray.slice(0, this.sliceIndex);
    },
    sortedData() {
      const information = {};
      Object.keys(this.data)
        .sort()
        .forEach((apartName) => {
          information[apartName] = this.data[apartName];
        });
      return information;
    },
    userList() {
      return store.getProcessedUserList();
    },
  },
};
</script>

<style scoped lang="scss">
.subTitleCard {
  color: rgb(255, 255, 255) !important;
  margin-top: -10px !important;
}
.v-theme--light .gradientBg {
  background: linear-gradient(#00000040, #00000099);
}
.v-theme--dark .gradientBg {
  background: linear-gradient(#00000099, #000000cc);
}
.gradientBg {
  position: relative;
}
.customFontSize {
  font-size: 13px !important;
  word-break: break-word;
  max-width: 85%;
}
.colorWhite {
  color: white !important;
  opacity: 1!important;
}
.contentGridWrapper {
  height: calc(100vh - 130px);
  overflow: auto;
}
.custom6cols {
  width: 16%;
  flex-basis: 16%;
}

@media (max-width: 765px) {
  .contentGridWrapper .custom6cols {
    width: 50%;
    min-width: 50%;
  }
}
// 6 columns
@media (max-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
@media (min-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
.v-card__subtitle {
  color: black !important;
}

.v-card__title {
  font-size: 15px !important;
}
</style>