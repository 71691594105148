import { dailyReportSettingsModalDomain } from './domain';

import { setDailyReportSettingsModalEvent, resetDailyReportSettingsModalEvent} from './events';

export const dailyReportSettingsModalStore = dailyReportSettingsModalDomain.createStore(false, { name: 'dailyReportSettingsModal' });

dailyReportSettingsModalStore.on(setDailyReportSettingsModalEvent, (state, payload) => {
  return payload;
});

dailyReportSettingsModalStore.reset(resetDailyReportSettingsModalEvent);
