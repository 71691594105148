// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mb0-i[data-v-282d9eda] {
  margin-bottom: 0px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/vOfficeProVersionModal.vue","webpack://./vOfficeProVersionModal.vue"],"names":[],"mappings":"AACA;EACE,6BAAA;ACAF","sourcesContent":["\n.mb0-i {\n  margin-bottom: 0px !important;\n}\n",".mb0-i {\n  margin-bottom: 0px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
