// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-list[data-v-8d49545c] {
  padding: 0;
}
.v-list-item__icon[data-v-8d49545c] {
  margin-right: 8px !important;
}
.v-card[data-v-8d49545c]:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/drawerContentExpansion.vue","webpack://./drawerContentExpansion.vue"],"names":[],"mappings":"AACA;EACE,UAAA;ACAF;ADGA;EACE,4BAAA;ACAF;ADGA;EACE,gBAAA;ACAF","sourcesContent":["\n.v-list {\n  padding: 0;\n}\n\n.v-list-item__icon {\n  margin-right: 8px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}\n",".v-list {\n  padding: 0;\n}\n\n.v-list-item__icon {\n  margin-right: 8px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
