// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wallpaper[data-v-414691ec] {
  background-size: 400% 400%;
  animation: gradient-414691ec 30s ease infinite;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0px;
  height: calc(100% - 48px);
  z-index: -1;
}
@keyframes gradient-414691ec {
0% {
    background-position: 0% 50%;
}
50% {
    background-position: 100% 50%;
}
100% {
    background-position: 0% 50%;
}
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/background.vue","webpack://./background.vue"],"names":[],"mappings":"AACA;EACE,0BAAA;EACA,8CAAA;EACA,WAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,yBAAA;EACA,WAAA;ACAF;ADEA;AACE;IACE,2BAAA;ACCF;ADCA;IACE,6BAAA;ACCF;ADCA;IACE,2BAAA;ACCF;AACF","sourcesContent":["\n.wallpaper {\n  background-size: 400% 400%;\n  animation: gradient 30s ease infinite;\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0px;\n  height: calc(100% - 48px);\n  z-index: -1;\n}\n@keyframes gradient {\n  0% {\n    background-position: 0% 50%;\n  }\n  50% {\n    background-position: 100% 50%;\n  }\n  100% {\n    background-position: 0% 50%;\n  }\n}\n",".wallpaper {\n  background-size: 400% 400%;\n  animation: gradient 30s ease infinite;\n  width: 100%;\n  position: absolute;\n  top: 0;\n  left: 0px;\n  height: calc(100% - 48px);\n  z-index: -1;\n}\n\n@keyframes gradient {\n  0% {\n    background-position: 0% 50%;\n  }\n  50% {\n    background-position: 100% 50%;\n  }\n  100% {\n    background-position: 0% 50%;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
