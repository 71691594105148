<template>
  <div id="v-tour-navBar">
    <v-card
      class="d-flex justify-center flex-wrap py-1"
      height="30px"
      :color="getColornavbarIcon"
      flat
      tile
    >
      <!-- START FAVORITES ICON -->
      <!-- <v-card
        :color="getColornavbarIcon"
        flat
        tile
        style="height: 25px"
        class="pt-1 mx-xl-6 mx-lg-6 mx-md-6 mx-sm-3"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span
              @click="gotoPage('my-favorites')"
              class="cursorPointer"
              v-on="on"
              id="v-tour-favoriteNavbar"
            >
              <font-awesome-icon
                v-if="currentPage('/my-favorites')"
                :icon="['fas', 'star']"
                class="primary--text"
                :style="{ fontSize: '18px' }"
              />
              <font-awesome-icon
                v-if="!currentPage('/my-favorites')"
                :icon="['fal', 'star']"
                class="primary--text"
                :style="{ fontSize: '18px' }"
              />
            </span>
          </template>
          <span>{{ $t("components.footernav.myFavorites") }}</span>
        </v-tooltip>
      </v-card> -->
      <!-- END FAVORITES ICON -->
      <!-- START INVITATION TO VISITORS -->
      <!-- <v-card
        :color="getColornavbarIcon"
        flat
        tile
        style="height: 25px"
        class="pt-1 mx-xl-6 mx-lg-6 mx-md-6 mx-sm-3"
      >
        <DirectLinkInvitation v-if="!amIGuest" />
      </v-card> -->
      <!-- END INVITATION TO VISITORS -->

      <!-- START VISITORS ICON -->
      <!-- <v-card
        :color="getColornavbarIcon"
        flat
        tile
        style="height: 25px"
        class="pt-1 mx-xl-6 mx-lg-6 mx-md-6 mx-sm-3 guestIcon"
        v-if="!isConference"
      >
        <v-menu
          content-class="bgw inviteVisitorMenu"
          ref="startDateMenu"
          :close-on-content-click="false"
          :model-value="showSelector" @update:model-value="showSelector = $event"
          offset-y
          min-width="290px"
        >
          <template #activator="{ on: onMenu }">
            <v-tooltip location="top">
              <template v-slot:activator="{ on: onTooltip }">
                <span v-on="{ ...onMenu, ...onTooltip }" @click="openSidebar()">
                  <font-awesome-icon
                    class="primary--text cursorPointer"
                    :icon="['fal', 'user-friends']"
                    :style="{ fontSize: '18px' }"
                  />
                </span>
                <div
                  class="numberNavbarRight font-weight-bold"
                  v-on="{ ...onMenu, ...onTooltip }"
                  @click="openSidebar()"
                >
                  <font-awesome-icon
                    class="primary--text cursorPointer"
                    :icon="['fal', 'plus-circle']"
                    :style="{ fontSize: '14px' }"
                  />
                </div>
              </template>
              <span>{{ $t("generics.invitationNavbar") }}</span>
            </v-tooltip>
          </template>
          <InviteVisitor :closeMenu="closeOpenInvitationInvitorMaskMenu" />
        </v-menu>

        <CustomTT
          v-if="showCustomTT"
          :text="`${$t('components.hotkeys.controlKey')} + I`"
          position="right"
        />
      </v-card> -->
      <!-- END VISITORS ICON -->

      <!-- START USER ICON -->
      <!-- <v-card
        flat
        tile
        style="height: 25px"
        class="pt-1 mx-xl-6 mx-lg-6 mx-md-6 mx-sm-3"
        :color="getColornavbarIcon"
      >
        <v-tooltip bottom v-if="showUserIcon">
          <template v-slot:activator="{ on }">
            <span
              @click="gotoPage('users')"
              class="cursorPointer d-flex"
              v-on="on"
              id="v-tour-usersNavbar"
            >
              <font-awesome-icon
                v-if="currentPage('/users')"
                :icon="['fas', 'user']"
                class="primary--text"
                :style="{ fontSize: '18px' }"
              />
              <font-awesome-icon
                v-if="!currentPage('/users')"
                :icon="['fal', 'user']"
                class="primary--text"
                :style="{ fontSize: '18px' }"
              />


            </span>
          </template>
          <span>{{ $t("generics.users") }}</span>
        </v-tooltip>
      </v-card> -->
      <!-- END USER ICON -->

      <!-- START CONFERENCE ICON -->
      <!-- <v-card
        :color="getColornavbarIcon"
        flat
        tile
        style="height: 25px"
        class="pt-1 mx-xl-6 mx-lg-6 mx-md-6 mx-sm-3"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span
              @click="gotoPage('newconference')"
              class="cursorPointer"
              v-on="on"
              id="v-tour-newConferenceNavbar"
            >
              <font-awesome-icon
                v-if="currentPage('/groups')"
                :icon="['fas', 'users']"
                class="primary--text"
                :style="{ fontSize: '18px' }"
              />
              <font-awesome-icon
                v-if="!currentPage('/groups')"
                :icon="['fal', 'users']"
                class="primary--text"
                :style="{ fontSize: '18px' }"
              />
            </span>
          </template>
          <span>{{ $t("components.groups.konferenzen") }}</span>
        </v-tooltip>

        <div class="numberNavbarOrganisation font-weight-bold cursorPointer">

          <template>
            <span @click="gotoPage('newconference')">
              <font-awesome-icon
                class="primary--text"
                :icon="['fal', 'plus-circle']"
                :style="{ fontSize: '14px' }"
              />
            </span>
          </template>
        </div>
      </v-card> -->
      <!-- END CONFERENCE ICON -->

      <!-- START WAITINGROOM ICON -->
      <!-- <v-card
        :color="getColornavbarIcon"
        flat
        tile
        style="height: 25px"
        class="pt-1 mx-xl-6 mx-lg-6 mx-md-6 mx-sm-3"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span
              @click="checkGoToWaiting()"
              class="cursorPointer"
              v-on="on"
              id="v-tour-waitingroomNavbar"
            >
              <font-awesome-icon
                v-if="currentPage('/waitingroom')"
                :icon="['fas', 'users-class']"
                class="primary--text"
                :style="{ fontSize: '18px' }"
              />
              <font-awesome-icon
                v-if="!currentPage('/waitingroom')"
                :icon="['fal', 'users-class']"
                class="primary--text"
                :style="{ fontSize: '18px' }"
              />
            </span>
          </template>
          <span>{{ waitingRoomTT }}</span>
        </v-tooltip>
        <v-badge
          right
          v-if="getWaitingRoomCount > 0"
          color="error"
          :content="getWaitingRoomCount"
          :value="getWaitingRoomCount"
          bordered
          overlap
          offset-x="12"
          offset-y="-4"
          class="blinkingBadge"
        ></v-badge>
        <v-badge
          left
          v-if="getAssistantsWaitingRoomCount > 0"
          color="error"
          :content="getAssistantsWaitingRoomCount"
          :value="getAssistantsWaitingRoomCount"
          bordered
          overlap
          offset-x="-22"
          offset-y="-10"
          class="blinkingBadge"
        ></v-badge>
      </v-card> -->
      <!-- END WAITINGROOM ICON -->
      <!-- START ORGANIGRAM ICON -->
      <!-- <v-card
        :color="getColornavbarIcon"
        flat
        tile
        style="height: 25px"
        class="pt-1 mx-xl-6 mx-lg-6 mx-md-6 mx-sm-3"
        v-if="showOrganigram && isRaMicro"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span
              @click="gotoPage('organisation')"
              class="cursorPointer"
              v-on="on"
              id="v-tour-organisationNavbar"
            >
              <font-awesome-icon
                v-if="currentPage('/organisation')"
                :icon="['fas', 'sitemap']"
                class="primary--text"
                :style="{ fontSize: '18px' }"
              />
              <font-awesome-icon
                v-if="!currentPage('/organisation')"
                :icon="['fal', 'sitemap']"
                class="primary--text"
                :style="{ fontSize: '18px' }"
              />
              <CustomTT
                v-if="showCustomTT"
                :text="`${$t('components.hotkeys.controlKey')} + O`"
              />
            </span>
          </template>
          <span>{{ $t("generics.organisationName") }}</span>
        </v-tooltip>
        <template v-if="amIAdmin && currentPage('/organisation')">
          <div class="numberNavbarOrganisation font-weight-bold cursorPointer">
            <v-tooltip location="top">
              <template v-slot:activator="{ on }">
                <span
                  v-on="on"
                  @click="state.simpleOrganisationManagerVisible = true"
                >
                  <font-awesome-icon
                    class="primary--text"
                    :icon="['fal', 'plus-circle']"
                    :style="{ fontSize: '14px' }"
                  />
                </span>
              </template>
              <span>{{
                $t("components.organisationCarousel.editOrganisation")
              }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-card> -->
      <!-- END ORGANIGRAM ICON -->
      <!-- START INFOBOARD ICON -->
      <!-- <v-card
        :color="getColornavbarIcon"
        flat
        tile
        style="height: 25px"
        class="pt-1 mx-xl-6 mx-lg-6 mx-md-6 mx-sm-3"
        v-if="
          state.namespaceSettings.notes.length > 0 &&
          this.state.namespaceSettings.featureInfoBoard
        "
      >
        <v-badge
          right
          v-if="unreadInfoNotes > 0"
          color="error"
          :content="unreadInfoNotes"
          :value="unreadInfoNotes"
          bordered
          overlap
          offset-x="-19"
          offset-y="-4"
          style="zindex: 9"
        ></v-badge>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span
              @click="
                gotoPage('infoboard');
                handleNewInfoNote();
              "
              class="cursorPointer"
              v-on="on"
              id="v-tour-infoBoardNavbar"
            >
              <font-awesome-icon
                v-if="currentPage('/infoboard')"
                :icon="['fas', 'sticky-note']"
                class="primary--text"
                :style="{ fontSize: '20px' }"
              />
              <font-awesome-icon
                v-if="!currentPage('/infoboard')"
                :icon="['fal', 'sticky-note']"
                class="primary--text"
                :style="{ fontSize: '20px' }"
              />
            </span>
          </template>
          <span>{{ $t("generics.infoboard") }}</span>
        </v-tooltip>
      </v-card> -->
      <!-- END INFOBOARD ICON -->
      <!-- <waitingRoomNotification /> -->
    </v-card>
  </div>
</template>
<script>
import { useStore } from "effector-vue/composition";
import { webLicensedBaseFeatures } from "../../../sharedsrc/licensedFeatures";
import store, { EventBus } from "../../store";
import InviteVisitor from "../sidebar/inviteVisitorsMenu_new.vue";
import { isMobile } from "../../lib/mobileUtil";
import { canIShowOrganigram } from "../../utils/basicFunctions";
// import DirectLinkInvitation from "../sidebar/userActionBar/directLinkInvitation.vue";
import { representedUsersStore } from "../../effector/representative";
import {
  // setEventStateModalEvent,
  // eventModalStateStore,
} from "../../effector/modals";
// import waitingRoomNotification from "../waitingRoomNotification/waitingRoomNotification.vue";

import CustomTT from "../content/customTT.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";
import { isConferenceCall } from "../../utils/calls";
import { useTheme } from 'vuetify';

export default {
  props: ["primaryDrawer", "mini"],
  components: {
    InviteVisitor,
    // waitingRoomNotification,
    CustomTT /* DirectLinkInvitation */,
  },
  data() {
    const effector = {
      representedUsersStore: representedUsersStore,
      showCustomTT: hotkeysInfoStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      theme: useTheme(),
      state: store.state,
      isMobile: isMobile(),
      ownUUID: store.state.ownUUID,
      setCurrentContentVisile: store.setCurrentContentVisile,
      openInvitationInvitorMask: false,
      lengthList: 0,
      showGreen: false,
      showOrange: false,
      showSelector: false,
      // Effector
      ...effector,
    };
  },
  watch: {
    getWaitingRoomCount() {
      this.waitingRoomOnline;
    },
    getAssistantsWaitingRoomCount() {
      //this.waitingRoomOnline;
    },
    //  getRoute: {
    //   immediate: true,
    //   handler: function (route) {
    //    if( this._interval && route === '/waitingroom') {
    //     clearInterval(this._interval);
    //     this._interval = undefined
    //   }
    //   },
    // }
  },
  methods: {
    checkGoToWaiting() {
      if (this.state.user.assists && this.state.user.assists.length > 0) {
        const persons = store.getWaitingRoomList(this.state.user.assists[0]);
        if (persons.length && persons.length > 0) {
          store.setCurrentContentVisile(
            `waitingroomfor/${this.state.user.assists[0]}`,
            true,
            this.$router
          );
        } else {
          const persons = store.getWaitingRoomList();
          if (persons.length && persons.length > 0) {
            this.gotoPage("waitingroom");
          } else {
            //if (!this.primaryDrawer.model)
            // if (!this.mini)
            //   EventBus.$emit("sendOpenCloseSidebar");
          }
        }
      } else {
        this.gotoPage("waitingroom");
      }
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    filterUsers() {
      EventBus.$emit("filterUsers", {
        showGreen: this.showGreen,
        showOrange: this.showOrange,
      });
    },
    // reproduceDingDong() {
    //   try {
    //     const audio = new Audio("/media/dingDong.mp3");
    //     audio
    //       .play()
    //       .catch((error) =>
    //         console.warn("Failed to play notification audio", error)
    //       );
    //   } catch (error) {
    //     console.warn("Failed to play notification audio", error);
    //   }
    // },
    openSidebar() {
      // EventBus.$emit("sendOpenCloseSidebar");
    },
    closeOpenInvitationInvitorMaskMenu() {
      this.openInvitationInvitorMask = false;
      this.showSelector = false;
    },
    handleNewInfoNote() {
      if (this.amIAdmin) {
        store.setShowNote(true, this.newNote(), true, false);
      }
    },
    gotoPage(route) {
      this.setCurrentContentVisile(route, true, this.$router);
    },
    currentPage(page) {
      const actualRoute = this.$route.path;
      if (actualRoute === page) {
        return true;
      }
      return false;
    },
    // WTF
    newNote() {
      return {
        id: this.calcID,
        title: this.noteTitle,
        date: this.todayDate,
        text: this.noteText,
        color: this.noteColor,
        long: this.longNote,
        completed: false,
        author: this.author,
        authorName: this.authorName,
        targetUsers: [],
      };
    },
    // isConferenceCall(callUUID){
    //   return isConferenceCall(callUUID)
    // }
  },
  computed: {
    isConference(){
      if(Object.keys(store.state.remoteBridgeStreams)[0]) return isConferenceCall(Object.keys(store.state.remoteBridgeStreams))
    },
    isRaMicro() {
      return webLicensedBaseFeatures.isRaMicro || webLicensedBaseFeatures.isRaMicroCustomer;
    },
    showOrganigram() {
      return canIShowOrganigram();
    },
    amIGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    showUserIcon() {
      if (this.amIAdmin || this.state.namespaceSettings.displayAllUsers) {
        return true;
      } else {
        return false;
      }
    },
    waitingRoomTT() {
      let fullSentence = `${
        this.getTitelForUuid(this.ownUUID) +
        " " +
        this.getNameForUuid(this.ownUUID)
      }`;
      if (this.state.user.assists) {
        for (let i = 0; i < this.state.user.assists.length; i++) {
          const uuid = this.state.user.assists[i];
          fullSentence +=
            ", " + this.getTitelForUuid(uuid) + " " + this.getNameForUuid(uuid);
        }
      }
      const waitingroomAlias = this.state.namespaceSettings.waitingRoomAlias
        ? this.state.namespaceSettings.waitingRoomAlias
        : this.$t("components.footernav.myWaitingRoomTT");
      return waitingroomAlias + " " + fullSentence;
    },
    getColornavbarIcon() {
      if (this.theme.global.current.dark) {
        return null;
      } else {
        return "#f5f5f5";
      }
    },
    // statsNav() {
    //   let totalSections = [];
    //   const sections = Object.keys(
    //     this.state.namespaceSettings.processedOrganisation.sections
    //   );
    //   for (let i = 0; i < sections.length; i++) {
    //     const section = sections[i];
    //     if (section !== "all") {
    //       totalSections.push(section);
    //     }
    //   }
    //   const totalDepartments = Object.keys(
    //     this.state.namespaceSettings.processedOrganisation.departments
    //   ).length;
    //   const totalTeams = Object.keys(
    //     this.state.namespaceSettings.processedOrganisation.teams
    //   ).length;
    //   return `${totalSections.length} ${this.$t(
    //     "generics.sections"
    //   )} / ${totalDepartments} ${this.$t(
    //     "generics.departments"
    //   )} / ${totalTeams} ${this.$t("components.teams.teams")}`;
    // },

    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    // getTotalNotes() {
    //   let count = 0;
    //   const allNotes = store.state.namespaceSettings.notes;
    //   const filteredRawNotes = allNotes.filter((note) => {
    //     return (
    //       note.targetUsers.length === 0 ||
    //       (note.targetUsers.length === 1 &&
    //         note.targetUsers[0] === "PartyTime") ||
    //       note.targetUsers[0] === "allUsers" ||
    //       note.author === this.ownUUID ||
    //       note.targetUsers.includes(this.ownUUID)
    //     );
    //   });
    //   const currentDate = new Date();
    //   const filteredNotes = filteredRawNotes.filter((note) => {
    //     return !note.activeUntil || new Date(note.activeUntil) > currentDate;
    //   });
    //   if (filteredNotes.length > 0) {
    //     count = filteredNotes.length;
    //   }
    //   return count;
    // },
    unreadInfoNotes() {
      let count = 0;
      const allNotes = store.state.namespaceSettings.notes;
      const filteredRawNotes = allNotes.filter((note) => {
        return (
          note.targetUsers.length === 0 ||
          (note.targetUsers.length === 1 &&
            note.targetUsers[0] === "PartyTime") ||
          note.targetUsers[0] === "allUsers" ||
          note.targetUsers[0] === "" ||
          note.author === this.ownUUID ||
          note.targetUsers.includes(this.ownUUID)
        );
      });
      const currentDate = new Date();
      const filteredNotes = filteredRawNotes.filter((note) => {
        return !note.activeUntil || new Date(note.activeUntil) > currentDate;
      });
      if (((store.state.user || {}).lastViewedInfoboard || "") === "") {
        count = filteredNotes.length;
      } else {
        const lastViewedInfoboard = new Date(
          store.state.user.lastViewedInfoboard
        );
        for (let index = 0; index < filteredNotes.length; index++) {
          const element = filteredNotes[index];
          if (new Date(element.date) > lastViewedInfoboard) {
            ++count;
          }
        }
      }
      return count;
    },
    featureOrganizationOption() {
      return store.state.namespaceSettings.featureOrganization;
    },
    getAssistantsWaitingRoomCount() {
      const myAssists = this.state.user.assists;
      let totalCount = 0;
      if (myAssists) {
        for (let i = 0; i < myAssists.length; i++) {
          const uuid = myAssists[i];
          totalCount += store.getWaitingRoomList(uuid).length;
        }
      }
      return totalCount;
    },
    getWaitingRoomCount() {
      let total = 0;
      // this is to stop blinking and dingDong when user is in waitingroom already #2111
      if (this.$route.path === "/waitingroom") return 0;

      const mycalls = Object.keys(store.state.rtc);
      const waiters = store.getWaitingRoomList();
      if (waiters && waiters.length > 0) {
        waiters.forEach((waiter) => {
          if (mycalls.indexOf(waiter.uuid) == -1) {
            total++;
          }
          // console.log( waiter, mycalls)
        });
      }
      // total = store.getWaitingRoomList().length;
      return total;
      // return store.getWaitingRoomList().length;
    },
    timeForIntervalDingDong() {
      return store.state.namespaceSettings.dingDongTimeForRepeat || 180000;
    },
    amInACall() {
      return !!Object.keys(store.state.rtc).length;
    },
    getRoute() {
      return this.$route.path;
    },
    // waitingRoomOnline() {
    //   const previous = this.lengthList;
    //   this.lengthList = store.getWaitingRoomList().length;

    //   if (this.lengthList === 0 || (!this.lengthList && this._interval)) {
    //     clearInterval(this._interval);
    //     this._interval = undefined;
    //   }
    //   if (previous < this.lengthList && !this._interval) {
    //     this.reproduceDingDong();
    //     this._interval = setInterval(() => {
    //       if (!this.amInACall) {
    //         this.reproduceDingDong();
    //       }
    //     }, this.timeForIntervalDingDong);
    //   }
    // },
    getvisitors() {
      const visitors = Object.keys(store.state.persisted.userVisitors)
        .filter((uuid) => !!store.state.persisted.userVisitors[uuid])
        .map((uuid) => ({
          ...store.state.persisted.userVisitors[uuid],
          uuid,
          visitorId: uuid,
        }));
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        const isGuest =
          person.permissions === 10 ||
          !person.user ||
          person.user.guest ||
          person.user.visitor;
        if (
          !person ||
          !person.user ||
          person.user.name == "" ||
          isGuest ||
          !(person.user.inviters || {})[this.ownUUID] ||
          person.user.rtcCallStart !== undefined
        )
          continue;
        person.user.visitorId = uuid;
        person.user.created = person.userTS;
        person.user.instalink = "";
        visitors.push({ ...person, uuid });
      }
      return visitors;
    },
  },
};
</script>

<style scoped lang="scss">
.v-theme--dark {
  .inviteVisitorMenu {
    background-color: #1f1f1f;
  }
}
.v-theme--light {
  .inviteVisitorMenu {
    background-color: #ffffff;
  }
}

.inviteVisitorMenu {
  top: 0px !important;
  left: 0px !important;
  height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
}
.guestIcon {
  position: absolute;
  left: 0px;
}
.v-badge {
  z-index: 9;
}
.cursorPointer {
  cursor: pointer;
}
.numberNavbarOrganisation {
  left: 25px;
  top: 3;
  position: absolute;
}
.numberNavbarNotes {
  left: 34px;
  position: absolute;
  top: 4px;
  font-size: 13px;
  color: #2a3133;
}
.numberNavbarRight {
  position: absolute;
  top: 0px;
  // right: 0px;
  left: 25px;
  font-size: 12px;
  color: #2a3133;
}
.blinkingBadge {
  animation: blink 1s linear infinite;
}
</style>

<style lang="scss">
#greenCheck {
  .mdi-checkbox-blank-outline {
    color: green;
  }
}
#orangeCheck {
  .mdi-checkbox-blank-outline {
    color: orange;
  }
}
</style>