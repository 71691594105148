<template>
  <div>
    <v-dialog persistent  :model-value="showFirstModal"
    @update:model-value="showFirstModal= $event" max-width="570">
      <v-card>
        <div class="w-100">
          <v-row flex class="w-100 mx-0 my-0 justify-space-between">
            <v-col class="px-0" align="left">
              <v-card-title class="text-h5 d-flex items-center">
                <v-img
                  src="assets/icon.png"
                  max-height="30"
                  max-width="30"
                  contain
                ></v-img>
                <span
                  class="font-weight-medium pl-4 spanModalCall"
                  v-html="$t('components.firstModal.firstUserSetup')"
                ></span>
                <v-tooltip location="top" v-if="amIAdmin">
                  <template v-slot:activator="{ props }">
                    <div class="">
                      <v-btn v-bind="props" color="primary" variant="text" small icon>
                        <font-awesome-icon
                          color="primary"
                          :icon="['fal', 'info-circle']"
                          :style="{ fontSize: '17px' }"
                        />
                      </v-btn>
                    </div>
                  </template>
                  <span>{{ $t("components.firstModal.infoA") }}</span>
                  <br />
                  <span>{{ $t("components.firstModal.infoB") }}</span>
                </v-tooltip>
              </v-card-title>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>
        <camera
          v-if="this.showCam"
          :getSnapshot="getSnapshot"
          :closeCamera="closeCamera"
        />
        <div class="pt-5" v-show="showCroppie">
          <v-row class="d-flex justify-center mx-auto pt-5 pb-2 pl-3">
            <v-col cols="6" class="py-0">
              <cropper
                  :stencil-size="stencilSize"
                  :default-size="defaultSize"
                  ref="cropper"
                  class="twitter-cropper"
                  background-class="twitter-cropper__background"
                  foreground-class="twitter-cropper__foreground"
                  image-restriction="stencil"
                  :stencil-props="{
                    lines: {},
                    handlers: {},
                    movable: false,
                    scalable: false,
                    aspectRatio: 1,
                    previewClass: 'twitter-cropper__stencil',
                  }"
                  :transitions="false"
                  :canvas="true"
                  :debounce="false"
                  :min-width="150"
                  :min-height="150"
                  :src="tmpAvatar"
                  @change="onChange"
                />
              <v-slider :model-value="zoomValue" @update:model-value="zoomValue = $event; onZoom(zoomValue)"></v-slider>
            </v-col>
            <div class="rotateIcons">
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" icon color="grey" @click="rotate(-90)">
                    <font-awesome-icon
                      :icon="['fal', 'undo']"
                      :style="{ fontSize: '20px' }"
                    />
                  </v-btn>
                </template>
                <span>{{ $t("components.navbar.rotateLeft") }}</span>
              </v-tooltip>
              <br />
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" icon color="grey" @click="rotate(90)">
                    <font-awesome-icon
                      :icon="['fal', 'undo']"
                      :style="{ fontSize: '20px' }"
                      class="fa-flip-horizontal"
                    />
                  </v-btn>
                </template>
                <span>{{ $t("components.navbar.rotateRight") }}</span>
              </v-tooltip>
            </div>
          </v-row>
          <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
            <v-row class="px-2 btns" flex :style="{ width: '100%', margin: 0 }">
              <v-col lg="6" class="px-0 py-0" align="right">
                  <!-- style="color: #11889d !important" -->
                <v-btn
                  flat
                  class="mr-2"
                  color="white"
                  @click="crop()"
                >
                  {{ $t("generics.save") }}
                </v-btn>

                <v-btn
                  color="white"
                  style="border-radius: 4px"
                  icon
                  tile
                  variant="outlined"
                  @click="cancelCrop()"
                >
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <v-spacer></v-spacer>
        </div>
        <v-form
          ref="userForm"
          :model-value="valid" @update:model-value="valid = $event"
          lazy-validation
          :autocomplete="false"
          v-if="!showCroppie && !this.showCam"
        >
          <input
            type="file"
            ref="fileInput"
            class="d-none"
            @change="showCroppieModal($event)"
          />
          <v-row class="pt-2" :style="{ width: '100%', margin: 0 }">
            <v-col
              v-for="n in 2"
              :key="n"
              :lg="cols[n - 1]"
              :md="cols[n - 1]"
              :sm="cols[n - 1]"
              :xl="cols[n - 1]"
              class="col-12"
            >
              <div v-if="n == 1" align="center">
                <div>
                  <v-avatar
                    tile
                    :size="190"
                    class="
                      mx-0
                      my-0
                      buttonOverflow
                      borderRadius4
                      borderRadius10
                    "
                  >
                    <div style="overflow: hidden" class="borderRadius4">
                      <v-img
                        v-if="!showCam"
                        class="borderRadius10"
                        max-height="190"
                        max-width="190"
                        height="190"
                        width="190"
                        contain
                        v-bind:src="state.user.avatar"
                        lazy-src="assets/default_profile_picture.png"
                      >
                        <div
                          style="width: 100%"
                          v-if="
                            state.user.avatar ==
                            'img/default_profile_picture.png'
                          "
                        >
                          <p
                            class="
                              imageTextWrapper
                              px-2
                              font-weight-bold
                              justify-center
                            "
                          >
                            {{ $t("components.firstModal.textWrapper") }}
                          </p>
                        </div>
                      </v-img>
                      <div
                        flex
                        class="uploadAvatarButtons p-4"
                        v-if="!showCam"
                        :class="{
                          hasImage:
                            state.user.avatar !=
                            'img/default_profile_picture.png',
                          hasNoImage:
                            state.user.avatar ==
                            'img/default_profile_picture.png',
                        }"
                      >
                        <v-tooltip location="top">
                          <template v-slot:activator="{ props }">
                            <v-btn
                              flat
                              v-bind="props"
                              color="primary"
                              class="mx-1"
                              isSmall="true"
                              v-on:click="selectAvatar"
                            >
                              <font-awesome-icon
                                :icon="['fal', 'upload']"
                                :style="{ fontSize: '20px' }"
                              />
                            </v-btn>
                          </template>
                          <span>{{
                            $t("components.navbar.uploadPicture")
                          }}</span>
                        </v-tooltip>
                        <v-tooltip location="top">
                          <template v-slot:activator="{ props }">
                            <v-btn
                              flat
                              v-bind="props"
                              color="primary"
                              class="mx-1"
                              isSmall="true"
                              v-on:click="showCamFunc"
                            >
                              <font-awesome-icon
                                :icon="['fal', 'camera']"
                                :style="{ fontSize: '20px' }"
                              />
                            </v-btn>
                          </template>
                          <span>{{
                            $t("components.camera.takeSnapshot")
                          }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </v-avatar>
                </div>
              </div>
              <div
                class="pt-2 pr-4 pl-0"
                v-if="
                  n == 2 &&
                  state.user.name == '' &&
                  !state.persisted.userUploadedExcel
                "
              >
                <v-row class="my-0">
                  <v-col class="py-0 pr-0" cols="4">
                    <v-text-field
                      class="pb-0"
                      variant="outlined"
                      density="compact"
                      :model-value="titleName" @update:model-value="titleName = $event"
                      :label="$t('components.firstModal.title')"
                      :rules="tileRules"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0" cols="8">
                    <v-text-field
                      variant="outlined"
                      density="compact"
                      :model-value="first_name" @update:model-value="first_name = $event"
                      :label="$t('components.firstModal.firstName')"
                      :rules="firstNameRules"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-text-field
                  variant="outlined"
                  density="compact"
                  :model-value="last_name" @update:model-value="last_name = $event"
                  :label="$t('components.firstModal.lastName')"
                  :rules="lastNameRules"
                  required
                ></v-text-field>

                <v-text-field
                  variant="outlined"
                  density="compact"
                  :model-value="email" @update:model-value="email = $event"
                  :label="$t('components.firstModal.email')"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <!-- /////// Error text ///////// -->
          <v-row :style="{ width: '100%', margin: 0 }" v-if="errText">
            <div class="mx-auto">
              <span style="color: red">{{ errText }}</span>
            </div>
          </v-row>

          <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
            <v-row class="px-2 btns" flex :style="{ width: '100%', margin: 0 }">
              <v-col lg="12" class="px-0 py-0" align="right">
                <v-btn
                  flat
                  color="white"
                  @click="validate"
                  :class="`buttonIconPrimaryFooter ${
                    disableButton ? 'buttonIconPrimaryFooterDisable' : ''
                  }`"
                  :disabled="disableButton"
                  >{{ $t("generics.save") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
          <v-spacer></v-spacer>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "../../store";
import {
  migrateCreator,
  migrateUser,
  isEmailRegistered,
} from "../../lib/wsMsg";
import camera from "../camera/camera.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
export default {
  props: ["showFirstModal", "toggleUserFirstModal"],
  components: {
    camera,
    HeaderModal,
  },
  data() {
    return {
      ownUUID: store.state.ownUUID,
      adminSelected: null,
      userAdmins: [],
      allUsers: [],
      state: store.state,
      showCam: false,
      titleName: "",
      first_name: "",
      last_name: "",
      email: "",
      errText: "",
      valid: true,
      showCroppie: false,
      tmpAvatar: null,
      zoomValue: 0,
    };
  },
  methods: {
    closeCroppie(){
      this.showCroppie = false;
    },
    onChange(result) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const { coordinates, imageSize } = cropper;
        if (
          imageSize.width / imageSize.height >
          coordinates.width / coordinates.height
        ) {
          // Determine the position of slider bullet
          // It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
          this.zoom =
            (cropper.imageSize.height - cropper.coordinates.height) /
            (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
        } else {
          this.zoom =
            (cropper.imageSize.width - cropper.coordinates.width) /
            (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
        }
      }
    },
    // Croppie setup methods
    defaultSize({ imageSize }) {
      return {
        width: Math.min(imageSize.height, imageSize.width),
        height: Math.min(imageSize.height, imageSize.width),
      };
    },
    onZoom(result) {
      const value = result / 100
      const cropper = this.$refs.cropper;
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight;
          const imageHeight = cropper.imageSize.height;
          // Determine the current absolute zoom and the new absolute zoom
          // to calculate the sought relative zoom value
          cropper.zoom(
            (imageHeight - this.zoom * (imageHeight - minHeight)) /
              (imageHeight - value * (imageHeight - minHeight))
          );
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth;
          const imageWidth = cropper.imageSize.width;
          cropper.zoom(
            (imageWidth - this.zoom * (imageWidth - minWidth)) /
              (imageWidth - value * (imageWidth - minWidth))
          );
        }
      }
    },
    stencilSize({ boundaries }) {
      return {
        width: Math.min(boundaries.height, boundaries.width) - 10,
        height: Math.min(boundaries.height, boundaries.width) - 10,
      };
    },
    // start methods for camera
    showCamFunc() {
      this.showCam = true;
    },
    getSnapshot(image) {
      this.showCam = false;
      this.showCroppie = true;
      // this.$refs.cropper.bind({
      //   url: image,
      // });
    },
    closeCamera() {
      this.showCam = false;
    },
    // end methods for camera
    cancelCrop() {
      this.resetInputAvatar();
      this.toggleCroppieModal();
    },
    async crop() {
      const canvas = this.$refs.cropper.getResult().canvas;
      if (canvas) {
        const base64Image = canvas.toDataURL("image/jpeg");
        const resizedImage = await this.resizeImage(base64Image, 256);
        // Do something with the base64 image
        store.setAvatar(resizedImage);
      }
      this.toggleCroppieModal();
      // this.closeCroppie();
    },
    resizeImage(imagePath, newWidth, newHeight) {
      const originalImage = new Image();
      originalImage.src = imagePath;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      return new Promise((resolve, reject) => {
        originalImage.addEventListener('error', (err) => reject(err));
        originalImage.addEventListener('load', () => {
          const originalWidth = originalImage.naturalWidth;
          const originalHeight = originalImage.naturalHeight;
          const aspectRatio = originalWidth/originalHeight;
          if (newHeight === undefined) {
            newHeight = newWidth/aspectRatio;
          }
          canvas.width = newWidth;
          canvas.height = newHeight;
          ctx.drawImage(originalImage, 0, 0, newWidth, newHeight);
          const dataUrl = canvas.toDataURL("image/jpeg", 0.9);
          return resolve(dataUrl);
        });
      });
    },
    result(output) {
      this.cropped = output;
    },
    rotate(rotationAngle) {
      // Rotates the image
      this.$refs.cropper.rotate(rotationAngle);
    },
    selectAvatar() {
      this.$refs.fileInput.click();
    },
    migrateState(newUUID, name, titel) {
      const user =
        (
          this.state.group[newUUID] ||
          this.state.group[store.state.ownUUID] ||
          {}
        ).user || {};
      const userObj = {
        name: name || user.name,
        activity: "Available",
        rtcCallStatus: "",
        rtcCallStart: 0,
        avatar: this.state.user.avatar || "img/default_profile_picture.png",
        guest: user.guest || false,
        sections: user.sections || [],
        locations: user.locations || [],
        teams: user.teams || [],
        assignedTickets: user.assignedTickets || [],
        assignedTicketsByMe: user.assignedTicketsByMe || [],
        assignedArchivedTickets: user.assignedArchivedTickets || [],
        assignedArchivedTicketsByMe: user.assignedArchivedTicketsByMe || [],
        lastTicketNumber: user.lastTicketNumber || 0,
        titel: titel,
      };
      const persistedData = JSON.parse(
        localStorage.getItem("persistedState_" + store.state.ownUUID)
      );
      localStorage.setItem(
        `persistedState_${newUUID}`,
        JSON.stringify(persistedData)
      );
      localStorage.setItem(`userState_${newUUID}`, JSON.stringify(userObj));
    },
    validate() {
      this.errText = "";
      if (!this.state.persisted.userUploadedExcel) {
        if (this.$refs.userForm.validate()) {
          if (this.state.user.guest || this.state.user.visitor) {
            // condition to know if the guest has set the info the first time
            this.state.persisted.guestInfoConfigDone = true;
          } else {
            this.email = this.email.trim().toLowerCase();
            if (this.email === this.state.user.email && store.state.ownUUID.indexOf('-') === -1) {
              this.state.user.name = `${this.first_name} ${this.last_name}`;
            } else {
              const name = `${this.first_name} ${this.last_name}`;
              const email = this.email;
              // eslint-disable-next-line no-async-promise-executor
              return new Promise(async () => {
                const { key, found } = await isEmailRegistered(email);
                if (found) {
                  this.errText = this.$t(
                    "components.inviteGuestPanel.errAlreadyUser"
                  );
                  return;
                }
                if (
                  store.state.ownUUID ===
                  (store.state.namespaceSettings.creatorInfo || {}).uuid
                ) {
                  localStorage.setItem("creatorFirstSetup", true);
                }
                const invite_link = await migrateUser(
                  name,
                  email,
                  this.titleName
                );
                this.migrateState(key, name, this.titleName);
                if (invite_link) window.location.href = invite_link;
              });
            }
          }
          store.state.identitySeted = true;
          this.toggleUserFirstModal();
        }
      } else {
        store.state.userFromConfig = true;
        const newUUID = this.adminSelected;
        if (
          store.state.ownUUID ===
          (store.state.namespaceSettings.creatorInfo || {}).uuid
        ) {
          localStorage.setItem("creatorFirstSetup", true);
        }
        migrateCreator(newUUID).then((invite_link) => {
          this.migrateState(newUUID);
          if (invite_link) window.location.href = invite_link;
        });
      }
    },
    showCroppieModal(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      var reader = new FileReader();
      reader.onload = (e) => {
        // this.$refs.cropper.bind({
        //   url: e.target.result,
        // });
        this.tmpAvatar = e.target.result;
        this.showCroppie = true;
      };
      reader.readAsDataURL(files[0]);
      this.resetInputAvatar();
    },
    toggleCroppieModal() {
      this.showCroppie = false;
    },
    resetInputAvatar() {
      if (this.$refs && this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
    },
  },
  computed: {
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    tileRules() {
      return [
        (v) =>
          (v || "").indexOf(" ") < 0 ||
          this.$t("components.firstModal.noSpacesAllowed"),
      ];
    },
    firstNameRules() {
      return [(v) => !!v || this.$t("components.firstModal.firstNameRequired")];
    },
    lastNameRules() {
      return [(v) => !!v || this.$t("components.firstModal.lastNameRequired")];
    },
    emailRules() {
      return [
        (v) => !!v || this.$t("components.firstModal.emailRequired"),
        (v) =>
          /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) ||
          this.$t("components.firstModal.emailMustValid"),
      ];
    },
    cols() {
      const { lg, sm, md, xl } = this.$vuetify.display;
      return lg ? [5, 7] : sm ? [5, 7] : md ? [5, 7] : xl ? [5, 7] : [12, 12];
    },
    disableButton() {
      if (
        !this.state.user.guest &&
        !this.state.persisted.userUploadedExcel &&
        (!this.valid ||
          this.email === "" ||
          this.first_name === "" ||
          this.last_name === "")
      ) {
        return true;
      } else if (
        this.state.persisted.userUploadedExcel &&
        this.adminSelected === null
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    const emailRegistered = localStorage.getItem("emailRegistered");
    const firstNameRegistered = localStorage.getItem("firstNameRegistered");
    const lastNameRegistered = localStorage.getItem("lastNameRegistered");
    if (lastNameRegistered !== null && lastNameRegistered !== undefined) {
      this.last_name = lastNameRegistered;
      localStorage.removeItem("lastNameRegistered");
    }
    if (firstNameRegistered !== null && firstNameRegistered !== undefined) {
      this.first_name = firstNameRegistered;
      localStorage.removeItem("firstNameRegistered");
    }
    if (emailRegistered !== null && emailRegistered !== undefined) {
      this.email = emailRegistered;
      localStorage.removeItem("emailRegistered");
    }
    if (this.state.persisted.userUploadedExcel) {
      setTimeout(() => {
        for (let key in store.state.group) {
          const obj = {
            value: key,
            text: store.state.group[key].user.name,
          };
          if ((obj.text || "").length) {
            this.allUsers.push(obj);
            if (store.state.group[key].permissions === 1) {
              this.userAdmins.push(obj);
            }
          }
        }
      }, 50);
    }
  },
};
</script>

<style scoped lang="scss">
.items-center{
  align-items: center !important;
}
.twitter-cropper {
  height: 256px;
  width: 256px;
  &__background {
    background-color: #edf2f4;
  }
  &__foreground {
    background-color: #edf2f4;
  }
  &__stencil {
    border: solid 5px rgb(29, 161, 242);
  }
}
.croppie-container {
  height: unset;
}
.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  border: 2px solid #2a3133 !important;
}
.imageTextWrapper {
  color: red;
  width: 100%;
  position: relative;
  top: 10px;
}
.buttonOverflow {
  overflow: visible !important;
}
.btns {
  justify-content: flex-end;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
  display: block;
}
.uploadAvatarButtons {
  position: absolute;
  left: 0px;
  height: 190px;
  width: 190px;
  top: 0px;
  border-radius: 4px;
  text-align: center;
  padding-top: 90%;
  button {
    height: 33px !important;
    min-width: 40px !important;
    padding: 0 10px !important;
  }
  &.hasImage {
    background: #6666668f;
    display: block;
    &:hover {
      display: block;
    }
  }
  &.hasNoImage {
    display: block;
    &:hover {
      display: block;
    }
  }
}
.borderRadius10 {
  &:hover > .hasImage {
    display: block;
  }
  &:hover > .hasNoImage {
    display: block;
  }
}
.borderRadius4 {
  border-radius: 4px!important;
}
.croppie-container {
  height: unset;
}
.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  border: 2px solid #2a3133 !important;
}
.rotateIcons {
  position: absolute;
  right: 16%;
  top: 40%;
}
</style>
<style lang="scss">
.sliderSize .cr-slider-wrap {
  width: 100%;
  margin: 15px auto;
}
.sliderSize .cr-slider {
  width: 256px;
}
</style>