// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkboxOrganigram .v-theme--light.v-icon {
  color: white !important;
}
.removeBorderLeft .v-image__image {
  border-top-left-radius: 0px !important;
}
.removeBorderRight .v-image__image {
  border-top-right-radius: 0px !important;
}
.removeBorderTop .v-image__image {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.bottomImagePosition .v-image__image {
  background-position: top center !important;
}
.grayScale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/organigram/organisationCards.vue","webpack://./organisationCards.vue"],"names":[],"mappings":"AACA;EACE,uBAAA;ACAF;ADGA;EACE,sCAAA;ACAF;ADGA;EACE,uCAAA;ACAF;ADGA;EACE,sCAAA;EACA,uCAAA;ACAF;ADGA;EACE,0CAAA;ACAF;ADGA;EACE,+BAAA;EACA,uBAAA;ACAF","sourcesContent":["\n.checkboxOrganigram .v-theme--light.v-icon {\n  color: white !important;\n}\n\n.removeBorderLeft .v-image__image {\n  border-top-left-radius: 0px !important;\n}\n\n.removeBorderRight .v-image__image {\n  border-top-right-radius: 0px !important;\n}\n\n.removeBorderTop .v-image__image {\n  border-top-left-radius: 0px !important;\n  border-top-right-radius: 0px !important;\n}\n\n.bottomImagePosition .v-image__image {\n  background-position: top center !important;\n}\n\n.grayScale {\n  -webkit-filter: grayscale(100%);\n  filter:grayscale(100%);\n}\n",".checkboxOrganigram .v-theme--light.v-icon {\n  color: white !important;\n}\n\n.removeBorderLeft .v-image__image {\n  border-top-left-radius: 0px !important;\n}\n\n.removeBorderRight .v-image__image {\n  border-top-right-radius: 0px !important;\n}\n\n.removeBorderTop .v-image__image {\n  border-top-left-radius: 0px !important;\n  border-top-right-radius: 0px !important;\n}\n\n.bottomImagePosition .v-image__image {\n  background-position: top center !important;\n}\n\n.grayScale {\n  -webkit-filter: grayscale(100%);\n  filter: grayscale(100%);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
