// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userCard[data-v-320d2fe4] {
  height: fit-content;
}
.contentWrapper[data-v-320d2fe4] {
  width: 100%;
  height: auto;
  max-height: calc(100vh - 355px);
  min-height: auto;
  overflow: auto;
}
.cardStyle[data-v-320d2fe4] {
  height: calc(100vh - 250px);
  min-height: calc(100vh - 250px);
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/internTable.vue","webpack://./internTable.vue"],"names":[],"mappings":"AACA;EACE,mBAAA;ACAF;ADEA;EACE,WAAA;EACA,YAAA;EACA,+BAAA;EACA,gBAAA;EACA,cAAA;ACCF;ADCA;EACE,2BAAA;EACA,+BAAA;ACEF","sourcesContent":["\n.userCard {\n  height: fit-content;\n}\n.contentWrapper {\n  width: 100%;\n  height: auto;\n  max-height: calc(100vh - 355px);\n  min-height: auto;\n  overflow: auto;\n}\n.cardStyle {\n  height: calc(100vh - 250px);\n  min-height: calc(100vh - 250px);\n}\n",".userCard {\n  height: fit-content;\n}\n\n.contentWrapper {\n  width: 100%;\n  height: auto;\n  max-height: calc(100vh - 355px);\n  min-height: auto;\n  overflow: auto;\n}\n\n.cardStyle {\n  height: calc(100vh - 250px);\n  min-height: calc(100vh - 250px);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
