<template>
  <div class="containerPreview" :key="currentVideoDevice">
    <div v-show="videoCameraCallOn">
      <!-- <WebCam
        :height="720"
        :width="1280"
        :resolution="{width:1280,height:720}"
        ref="webcam"
        :device-id="deviceId"
        @cameras="onCameras"
        @camera-change="onCameraChange"
      /> -->
      <WebCam ref="webcam" :classList="'videoWebcam'" @init="webcamInit" />
    </div>
    <div class="h100 w100" v-show="!videoCameraCallOn">
      <div class="avatarContainer">
        <v-avatar size="120">
          <v-img
            class="borderRadius4"
            max-height="120"
            max-width="120"
            :src="getAvatarForUuid(ownUUID) || 'img/default_profile_picture.png'"
            lazy-src="assets/default_profile_picture.png"
          ></v-img>
        </v-avatar>
      </div>
    </div>
    <div class="iconSetting">
      <div class="buttosContainer">
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-btn
              class="buttonsCamera pb-2"
              icon
              color="white"
              variant="text"
              v-bind="props"
              @click="toggleCamMic"
            >
              <font-awesome-icon
                :icon="['fal', 'cog']"
                :style="{ fontSize: '20px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t("generics.settings") }}</span>
        </v-tooltip>
      </div>
    </div>
    <div class="deviceIcons">
      <div class="buttosContainer d-flex pt-6">
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-switch
              v-bind="props"
              :disabled="hasVideoDevices.length === 0"
              :model-value="videoCameraCallOn" @update:model-value="videoCameraCallOn = $event"
              @click.stop.prevent="changeCallSetting('video')"
              class="px-2"
            >
              <template v-slot:prepend>
                <font-awesome-icon
                  v-if="videoCameraCallOn"
                  color="white"
                  :icon="['fal', 'video']"
                  :style="{ fontSize: '20px' }"
                />
                <img
                  v-else
                  class="video-slash"
                  src="/img/video-slash-red-white.svg"
                />
              </template>
            </v-switch>
          </template>
          <span>{{
            videoCameraCallOn
              ? $t("generics.cameraOff")
              : $t("generics.cameraOn")
          }}</span>
        </v-tooltip>
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-switch
              v-bind="props"
              :model-value="audioCallOn" @update:model-value="audioCallOn = $event"
              @click.stop.prevent="changeCallSetting('audio')"
              class="px-2"
            >
              <template v-slot:prepend>
                <font-awesome-icon
                  v-if="audioCallOn"
                  color="white"
                  :icon="['fal', 'microphone']"
                  :style="{ fontSize: '20px' }"
                />
                <img
                  v-else
                  class="microphone-slash"
                  src="/img/microphone-slash-red-white.svg"
                />
              </template>
            </v-switch>
          </template>
          <span>{{
            audioCallOn ? $t("generics.micOff") : $t("generics.micOn")
          }}</span>
        </v-tooltip>
      </div>
    </div>
    <div @click="toggleCamMic" class="videoBackground" v-if="state.persisted.mediaDeviceSetup.videoBackground">
      <v-tooltip location="top">
        <template v-slot:activator="{ props }">
          <div v-bind="props" class="buttosContainer">
            <div class="iconVideoBackground">
              <img class="imgBackground" v-if="state.persisted.mediaDeviceSetup.videoBackground.image" :src="state.persisted.mediaDeviceSetup.videoBackground.url">
              <img class="imgBackground" v-if="state.persisted.mediaDeviceSetup.videoBackground.icon" :src="state.persisted.mediaDeviceSetup.videoBackground.icon">
            </div>
          </div>
        </template>
        <span>{{$t('components.callsContent.videoBackground')}}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import store, { EventBus } from "../../store";
// import { WebCam } from "vue-web-cam";
import { WebCam } from 'vue-camera-lib'
import DefaultAvatar from "../image/defaultAvatar.vue";
export default {
  components: { WebCam, DefaultAvatar },
  data: () => ({
    state: store.state,
    deviceId: null,
    devices: [],
    videoCameraCallOn: store.state.user.userSettings.videoCameraCallOn,
    audioCallOn: store.state.user.userSettings.audioCallOn,
    ownUUID: store.state.ownUUID,
    currentVideoDevice: store.state.persisted.mediaDeviceSetup.videoDeviceId,
    hasVideoDevices: [],
    cameras: [],
  }),
  beforeMount() {
    this.start();
    this.videoCameraCallOn = this.getVideoDeviceSetted
      ? store.state.user.userSettings.videoCameraCallOn
      : false;
    this.audioCallOn = store.state.user.userSettings.audioCallOn;
  },
  mounted() {
    this.getSetting();
    this.cameras = this.$refs.webcam.cameras;
    if (this.cameras.length === 0) {
      // if no camera found, we will try to refresh cameras list each second until there is some camera
      let reloadCamInterval = setInterval(() => {
        this.loadCameras();
        if (this.cameras.length > 0) {
          this.changeCamera(this.currentVideoDevice);
          clearInterval(reloadCamInterval);
        }
      }, 1000);
    }
  },
  beforeUnmount(){
    if (window.stream) {
      window.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    this.$refs.webcam.stop();
  },
  watch: {
    devices: function () {
      this.getSetting();
      if (this.deviceId) return;
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.deviceId = first.deviceId;
      }
    },
    "state.persisted.mediaDeviceSetup.videoDeviceId": {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.currentVideoDevice = value;
      },
    },
  },
  methods: {
    loadCameras() {
      if(this.$refs.webcam){
        this.$refs.webcam.loadCameras();
        this.cameras = this.$refs.webcam.cameras;
      }
    },
    changeCamera(deviceId) {
      if(this.$refs.webcam){
        this.$refs.webcam.changeCamera(deviceId);
        this.cameras = this.$refs.webcam.cameras;
      }
    },
    webcamInit() {
      this.deviceId = this.currentVideoDevice;
      this.changeCamera(this.currentVideoDevice);
      this.$emit('init', this.deviceId);
    },
    start() {
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      const audioSource = store.state.persisted.mediaDeviceSetup.audioDeviceId;
      const videoSource = store.state.persisted.mediaDeviceSetup.videoDeviceId;
      let constraints = {
        audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
        video: { deviceId: videoSource ? { exact: videoSource } : undefined,
            aspectRatio: 16/9,
            width: { min: 640, ideal: 1280, max: 1920 },
            height: { min: 360, ideal: 720, max: 1080 },
            frameRate: { ideal: 30 },
            advanced: [
              { width: 1280, height: 720 },  // Try for 720p first
              { width: 640, height: 360 }    // Fallback to 360p
            ] },
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .catch((error) => {
          if (error && error.name === "OverconstrainedError") {
            this.state.persisted.mediaDeviceSetup.videoDeviceId = undefined;
            this.state.persisted.mediaDeviceSetup.audioDeviceId = undefined;
            constraints = {
              audio: { deviceId: undefined },
              video: { deviceId: undefined },
            };
            return navigator.mediaDevices.getUserMedia(constraints);
          }
          return navigator.mediaDevices.getUserMedia({
            audio: constraints.audio,
          });
        })
        .then(this.gotStream)
        .then(this.gotDevices);
    },
    gotDevices(deviceInfos) {
      this.hasVideoDevices = deviceInfos.filter(device => device.kind === 'videoinput');
      if (this.hasVideoDevices.length > 0){
        for (let i = 0; i !== this.hasVideoDevices.length; ++i) {
          const deviceInfo = this.hasVideoDevices[i];
            if (
              (this.state.persisted.mediaDeviceSetup.videoDeviceId == "" || this.state.persisted.mediaDeviceSetup.videoDeviceId == undefined)
            ) {
              this.state.persisted.mediaDeviceSetup.videoDeviceId = deviceInfo.deviceId;
              return;
            }
        }
      } else {
        this.videoCameraCallOn = false;
        const temporalUserSettings = this.state.user.userSettings;
        temporalUserSettings.videoCameraCallOn = false;
        store.setUserSettings(temporalUserSettings);
        this.state.persisted.mediaDeviceSetup.videoDeviceId = undefined;
      }
    },
    gotStream(stream) {
      window.stream = stream; // make stream available to console
      // Refresh button list in case labels have become available
      return navigator.mediaDevices.enumerateDevices();
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    toggleCamMic() {
      EventBus.$emit("togleMicSettings");
    },
    changeCallSetting(type) {
      const temporalUserSettings = this.state.user.userSettings;
      if (type === "video") {
        this.videoCameraCallOn = !this.videoCameraCallOn;
        temporalUserSettings.videoCameraCallOn = this.videoCameraCallOn;
        if (this.videoCameraCallOn && this.getVideoDeviceSetted) {
          this.$nextTick(() => {
            this.$refs.webcam.start();
          });
        }
      } else if (type === "audio") {
        this.audioCallOn = !this.audioCallOn;
        temporalUserSettings.audioCallOn = this.audioCallOn;
      }
      store.setUserSettings(temporalUserSettings);
    },
    getSetting() {
      const videoSetting = store.getRtcVideo();
      if (videoSetting && videoSetting.deviceId) {
        this.deviceId = videoSetting.deviceId;
      }
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
    },
  },
  computed: {
    getVideoDeviceSetted() {
      // if (this.state.persisted.mediaDeviceSetup) {
      return this.state.persisted.mediaDeviceSetup.videoDeviceId ? true : false;
      // }
      // return undefined;
    },
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },
};
</script>
<style scoped lang="scss">
.avatarContainer {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  .v-image {
    border-radius: 50% !important;
  }
}
.containerPreview {
  // position: relative;
  // height: 250px;
  // max-height: 250px;
  // min-height: 250px;
  position: relative;
  overflow: hidden;
  width: 100%; /* Set the width you desire */
  height: 0; /* Set to 0 */
  padding-bottom: 56.25%; 
}
.microphone-slash {
  width: 24px !important;
  height: 21px !important;
  color: #fff;
}
.video-slash {
  width: 24px !important;
  height: 21px !important;
}
.borderRadius4 {
  border-radius: 4px!important;
}
video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleX(-1); /* Flip and center the video */
  max-width: 100%;
  max-height: 100%;
  width: 100%;  /* Auto sizing for aspect ratio */
  height: 100%; /* Auto sizing for aspect ratio */
  object-fit: cover; /* Contain within the element, adding bars as needed */
  border-radius: 4px;
  background: #101010; /* Fallback background color */
}
.iconSetting {
  position: absolute;
  cursor: pointer;
  z-index: 2;
  top: 15px !important;
  margin: 0 !important;
  right: 15px;
  .buttosContainer {
    align-items: center;
    background-color: rgb(0, 0, 0);
    box-shadow: 2px 2px 24px #636363;
    opacity: 0.6;
    padding: 6px;
    height: 50px;
    border-radius: 15px;
  }
  .buttonsCamera {
    border-radius: 50%;
    margin: 0 5px;
  }
}
.deviceIcons {
  position: absolute;
  cursor: pointer;
  z-index: 2;
  bottom: 12px !important;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: default !important;
  .buttosContainer {
    align-items: center;
    background-color: rgb(0, 0, 0);
    box-shadow: 2px 2px 24px #636363;
    opacity: 0.6;
    padding: 6px;
    height: 50px;
    border-radius: 15px;
  }
}
.videoBackground {
  position: absolute;
  z-index: 2;
  bottom: 12px !important;
  right: 15px;
  min-height: 50px;
  min-width: 79px;
  cursor: pointer;
  .buttosContainer {
    align-items: center;
    background-color: rgb(0, 0, 0);
    box-shadow: 2px 2px 24px #636363;
    opacity: 0.6;
    padding: 6px;
    height: 50px;
    border-radius: 12px;
  }
  .imgBackground {
    height: 100%;
    border-radius: 5px;
  }
  .iconVideoBackground {
    text-align: center;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: #fff;
  }
}
</style>