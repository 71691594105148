export const isMobile = () => {
  if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  } else {
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(navigator.userAgent);
    if ( isTablet ){
      return true;
    }
    if ( isIpad() ){
      return true;
    }
    return false;
  }
};

export const isIpad = () => {
  const ua = window.navigator.userAgent;
  if (ua.indexOf('iPad') > -1) {
    return true;
  }

  if (ua.indexOf('Macintosh') > -1) {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {}
  }

  return false;
};
export const isTablet = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
  return isTablet;
}

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iP(ad|hone|od)/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}