// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gray {
  color: white !important;
  background-color: rgb(175, 173, 173) !important;
}
.v-calendar-weekly__day.v-future, .v-calendar-weekly__day.v-present {
  cursor: pointer;
}
.v-calendar .v-event {
  margin-left: 6px !important;
  padding-left: 6px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/calendarContainerEffector.vue","webpack://./calendarContainerEffector.vue"],"names":[],"mappings":"AACA;EACE,uBAAA;EACA,+CAAA;ACAF;ADEA;EACE,eAAA;ACCF;ADCA;EACE,2BAAA;EACA,4BAAA;ACEF","sourcesContent":["\n.gray{\n  color:  white !important;\n  background-color: rgb(175, 173, 173) !important;\n}\n.v-calendar-weekly__day.v-future, .v-calendar-weekly__day.v-present{\n  cursor: pointer;\n}\n.v-calendar .v-event {\n  margin-left: 6px!important;\n  padding-left: 6px!important;\n}\n",".gray {\n  color: white !important;\n  background-color: rgb(175, 173, 173) !important;\n}\n\n.v-calendar-weekly__day.v-future, .v-calendar-weekly__day.v-present {\n  cursor: pointer;\n}\n\n.v-calendar .v-event {\n  margin-left: 6px !important;\n  padding-left: 6px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
