import { sendMessageDomain } from './domain';

import { setSendMessageModalEvent, resetSendMessageModalEvent } from './events';

export const sendMessageModalStore = sendMessageDomain.createStore(false, { name: 'sendMessageModal' });

sendMessageModalStore.on(setSendMessageModalEvent, (state, payload) => {
  return payload;
});

sendMessageModalStore.reset(resetSendMessageModalEvent);
