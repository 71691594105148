
<template>
  <div  id="video-container" class="absolute" v-bind:class="{'d-none': state.currentContentVisile.showComponent || Object.keys(state.remoteStreams).length === 0}">
     <div class="progressBar" v-if="!infiniteCall">
      <!-- <ProgressLinear
        :click="()=>addMoreTime()"
        :classeProgress="`${(state.currentTS - state.meetingStartAt) >= (state.durationMeeting - 60000) ? 'progressBarBlink': ''}`"
        :value="meetingTime"
        :timerCall="timerCall"
         :elapseTime="elapseTime"
        :originalCallTime="formatTime(state.durationMeeting)"
      /> -->
      <ProgressLinear />
    </div>
  <splitpanes horizontal class="default-theme" style="height: calc(100% - 24px)" :class="`${!infiniteCall?' mt-6':''}`">
    <pane v-for="(remote, uuid) in state.remoteStreams" :key="uuid" :size="Object.keys(state.remoteStreams).length == 1 ? actualSize.remote : 50">
      <div  @click="changeActualSize()">
        <RemotePersonCall :uuid="uuid" :remote="remote" />
      </div>
    </pane>
    <pane :size="Object.keys(state.remoteStreams).length == 1 ? actualSize.me: 50">
       <div ref="localVideoDiv" class="relative localVideo"  @click="changeActualSize()">
        <div class="callerName">
          <span>{{getNameForUuid(state.ownUUID)}}</span>
        </div>

        <div class="divSharingScreen" v-if="state.localStreams.display">
           <video id="local-video" webkit-playsinline playsinline autoplay="autoplay" :src-object.prop.camel="state.localStreams.user"
            v-bind:class="{ 'd-none': !haveLocalVideo }" muted></video>
        </div>
          <video id="local-video" v-if="!state.localStreams.display" webkit-playsinline playsinline autoplay="autoplay" :src-object.prop.camel="state.localStreams.user"
            v-bind:class="{ 'd-none': !haveLocalVideo }" muted></video>
        <video id="local-screen" webkit-playsinline playsinline autoplay="autoplay" :src-object.prop.camel="state.localStreams.display"
          v-bind:class="{ 'd-none': !state.localStreams.display }" muted></video>
        <div class="noCameraStream" v-bind:class="{'d-none': haveLocalVideo}">
          <div class="noCameraContent">
            <v-img 
              class="imageNotVideo"
              contain
              :src="getAvatarFromUuid(state.ownUUID)"
              lazy-src="assets/default_profile_picture.png"
            ></v-img>
            <div class="emptyDiv"></div>
          </div>
        </div>
        <div v-bind:class="{ hidden: Object.keys(state.remoteStreams).length == 0, 'buttonsIpad': isIpad && isIos}"  class="buttonsUser">
            <v-tooltip location="top">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="white" icon @click.stop.prevent="tryWebcam()">
                  <font-awesome-icon :icon="['fal', 'video']" :style="{ fontSize: '20px' }" v-if="state.persisted.rtcVideo"/>
                  <font-awesome-icon :icon="['fal', 'video-slash']" :style="{ fontSize: '20px' }" v-if="!state.persisted.rtcVideo"/>
                </v-btn>
              </template>
              <span>{{ $t('generics.camera') }}</span>
            </v-tooltip>

            <v-tooltip location="top">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="white" icon @click.stop.prevent="toggleMuteLocalAudio()">
                  <font-awesome-icon :icon="['fal', 'microphone']" :style="{ fontSize: '20px' }" v-if="!isLocalAudioMuted"/>
                  <font-awesome-icon :icon="['fal', 'microphone-slash']" :style="{ fontSize: '20px' }" v-if="isLocalAudioMuted"/>
                </v-btn>
              </template>
              <span>{{ $t('components.callsContent.mic') }}</span>
            </v-tooltip>

          <v-tooltip location="top">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="white" icon class="redBtn" @click.stop.prevent="rtcHangup()">
                <font-awesome-icon :icon="['fal', 'phone']" :rotation="270" :style="{ fontSize: '20px' }"/>
                </v-btn>
              </template>
              <span>{{ $t('generics.hangUp') }}</span>
            </v-tooltip>
        </div>
      </div>
    </pane>
  </splitpanes>
</div>

</template>
<script>
import { useStore } from "effector-vue/composition";
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import { rtcHangup } from '../../lib/rtcConn';
import { tryWebcam, tryScreenShare, toggleMuteLocalAudio } from '../../lib/mediaDevices';
import { Splitpanes, Pane } from 'splitpanes'
import VueDragResize from 'vue-drag-resize';
import draggable from 'vuedraggable';
import RemotePersonCall from '../rtc/remotePersonCall.vue';
import ProgressLinear from "../progressLinear/progressLinear.vue";
import { callAddDurationEvent, callLessDurationEvent, callInfoStateStore } from '../../effector/call';

export default {
  components: { RemotePersonCall, Splitpanes, Pane , VueDragResize, draggable, ProgressLinear},
  data() {
    const effector = {
      callInfo: callInfoStateStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      pLimitW: 0,
      pLimitH: 0,
      isIpad: this.$device.ipad,
      isIos: this.$device.ios,
      moment:moment,
      actualSize: {
        remote: 80,
        me: 20,
        position: 0
      },
      // Effector
      ...effector,
    };
  },
   watch: {
    "callInfo": function () {
      this.infiniteCall = this.callInfo.infiniteCall
    }
  },
  mounted() {
    this.handleResize();
  },
  methods: {
     changeActualSize() {
      if (Object.keys(this.state.remoteStreams).length == 1) {
        let Remotesizes = [75, 50, 100];
        let MeSizes = [25, 50, 0.1];
        this.actualSize.position += 1;
        if (this.actualSize.position > 2) {
          this.actualSize.position = 0;
        }
        this.actualSize.remote = Remotesizes[this.actualSize.position];
        this.actualSize.me = MeSizes[this.actualSize.position];
      }
    },
    addMoreTime() {
      if (this.state.ownerMeeting) {
        callAddDurationEvent();
      } else {
        const data = {
          show: true,
          header: this.$t("components.modalAddTimeMeeting.header"),
          body: this.$t("components.modalAddTimeMeeting.info")
        };
        return store.setinfoModal(data);
      }
    },
    lessTime() {
      callLessDurationEvent();
      // if (
      //   this.state.ownerMeeting &&
      //   this.state.currentTS - this.state.meetingStartAt <
      //     this.state.durationMeeting - 60000
      // ) {
      //   let personRemote = Object.keys(store.state.remoteStreams);
      //   let oneMinute = 60000;
      //   let durationMeeting = store.state.durationMeeting - oneMinute;
      //   store.setdurationMeeting(durationMeeting);
      //   for (const key in personRemote) {
      //     let uuid = personRemote[key];
      //     if (store.state.rtc[uuid].ringingState == "connected") {
      //       updateCallTime(uuid, "decrease");
      //     }
      //   }
      // }
    },
    // formatTime(time) {
    //   let calc = (time / 1000 / 60).toFixed(2);
    //   let calcTmp = calc.split(".");
    //   let timer = "0:00";
    //   if (parseInt(calcTmp[0]) < 10) {
    //     timer = "0" + calcTmp[0] + ":" + calcTmp[1];
    //   } else {
    //     timer = calcTmp[0] + ":" + calcTmp[1];
    //   }
    //   return timer;
    // },
    resizePane(event){
      // console.log('Resize pan!!!!!!', event)
      this.handleResize();
    },
    handleResize() {
      const el = this.$refs.localVideoDiv;
      if (el) {
        this.pLimitW = el.clientWidth;
        this.pLimitH = el.clientHeight;
      }
    },
    resize(newRect) {
        this.width = newRect.width;
        this.height = newRect.height;
        this.top = newRect.top;
        this.left = newRect.left;
        this.handleResize();
    },
    tryWebcam() {
      return tryWebcam();
    },
    tryScreenShare() {
      return tryScreenShare();
    },
    closeDisplayMedia() {
      return store.closeDisplayMedia();
    },
    rtcHangup() {
      return rtcHangup();
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getAvatarFromUuid(uuid){
      return store.getAvatarForUuid(uuid);
    },
    toggleMuteLocalAudio() {
      return toggleMuteLocalAudio();
    },
  },
  computed: {
    // elapseTime(){
    //     let milisegundos = (store.state.currentTS - store.state.meetingStartAt);
    //     if (!milisegundos) return '';
    //      let progress = ''
    //     if(milisegundos>=3600000){
    //       progress = this.moment.utc(milisegundos)
    //         // .add(this.moment.duration(1000))
    //         .format("hh:mm:ss");
    //     }else {
    //       progress = this.moment.utc(milisegundos)
    //         // .add(this.moment.duration(1000))
    //         .format("mm:ss");
    //     }
    //     return progress;
    // },
    //  timerCall() {
    //   // let calc = (((store.state.durationMeeting-(store.state.currentTS - store.state.meetingStartAt))/1000)/60).toFixed(2)
    //   let milisegundos =
    //     store.state.durationMeeting -
    //     (store.state.currentTS - store.state.meetingStartAt);
    //     if (!milisegundos) return '';
    //   let progress = this.moment(new Date(milisegundos))
    //     .add(this.moment.duration(1000))
    //     .format("mm:ss");
    //   const audio = new Audio("/media/callFinishTone.mp3");
    //   if (milisegundos <= 30000 && milisegundos > 20000) {
    //     // one ring
    //     if (this.secondsBeep1 < 1) {
    //       this.ringAudio();
    //       this.secondsBeep1 = this.secondsBeep1 + 1;
    //     }
    //   } else if (milisegundos <= 20000 && milisegundos > 10000) {
    //     // two rings
    //     if (this.secondsBeep2 < 2) {
    //       this.ringAudio();
    //       this.secondsBeep2 = this.secondsBeep2 + 1;
    //     }
    //   } else if (milisegundos <= 10000 && milisegundos > 0) {
    //     // three rings
    //     if (this.secondsBeep3 < 3) {
    //       this.ringAudio();
    //       this.secondsBeep3 = this.secondsBeep3 + 1;
    //     }
    //   }
    //   return progress;
    // },
    haveLocalVideo() {
      if (!this.state.localStreams.user) return false;
      for (const track of this.state.localStreams.user.getTracks()) {
        if (track.kind === 'video') return true;
      }
      return false;
    },
    isLocalAudioMuted() {
      if (!this.state.localStreams.user) return false;
      if (!this.state.localStreams.lastMicChange) return false; // Just for the reference
      for (const track of this.state.localStreams.user.getTracks()) {
        if (track.kind === 'audio') return !track.enabled;
      }
      return false;
    },
    //  meetingTime() {
    //   let personRemote = Object.keys(store.state.remoteStreams)[0];
    //   if (
    //     store.state.rtc[personRemote] &&
    //     store.state.rtc[personRemote].ringingState === "ringing" &&
    //     store.state.meetingStartAt &&
    //     store.state.meetingDialUPTime
    //   ) {
    //     let timeIncall = store.state.currentTS - store.state.meetingStartAt;
    //     if (timeIncall >= store.state.meetingDialUPTime) {
    //       return rtcHangup();
    //     }
    //   }
    //   if (
    //     store.state.rtc[personRemote] &&
    //     store.state.rtc[personRemote].ringingState == "connected" &&
    //     store.state.meetingStartAt
    //   ) {
    //     let timeIncall = store.state.currentTS - store.state.meetingStartAt;
    //     let limitTimeCall = store.state.durationMeeting;
    //     let porcentaje = ((timeIncall * 100) / limitTimeCall).toFixed(0);
    //     if (porcentaje > 200 || porcentaje < -15) {
    //       alert("Incorrect time, please verify your date/time settings");
    //     }
    //     // console.log(
    //     //   "limitTimeCall---=",
    //     //   limitTimeCall,
    //     //   "timeInCall---=",
    //     //   timeIncall,
    //     //   "meetingStart---=",
    //     //   store.state.meetingStartAt,
    //     //   "currentTs----=",
    //     //   store.state.currentTS,
    //     //   "porcentaje---=",
    //     //   porcentaje
    //     // );
    //     // if (porcentaje >= 95) {
    //     //   if (!this.notificationEnd) {
    //     //     try {
    //     //       const audio = new Audio("/media/notificationSound.mp3");
    //     //       audio
    //     //         .play()
    //     //         .catch(error =>
    //     //           console.warn("Failed to play notification audio", error)
    //     //         );
    //     //       this.notificationEnd = true;
    //     //     } catch (error) {
    //     //       console.warn("Failed to play notification audio", error);
    //     //     }
    //     //   }
    //     //   // this.setshowModalEndMeeting(true)
    //     // }
    //     if (porcentaje >= 100) {
    //       this.ringAudioFinalCall();
    //       return rtcHangup();
    //     }
    //     return porcentaje;
    //   } else {
    //     return 0;
    //   }
    // }
  }
};

</script>
<style scoped lang="scss">
  .divSharingScreen{
    position: absolute;
    width: 250px;
    height: 125px;
    top: 35px;
    left: 5px;
    z-index: 99999999;
  }
  .progressBar {
    position: absolute;
    width: 100%;
    top: 0px;
    z-index: 1;
    background-color: #f44336;
  }
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  .progressBarBlink {
    animation: blink 1s linear infinite;
    //  pointer-events: none;
  }
  .callerName {
    position: absolute;
    width: 100%;
    color: white;
    // z-index: 2;
    height: 30px;
    padding: 6px;
    background-color: linear-gradient;
    background: rgb(21, 30, 39);
    z-index: 99999999;
    // background: linear-gradient(
    //   0deg,
    //   rgba(21, 30, 39, 0.42) 0%,
    //   rgba(31, 43, 48, 0.43) 100%
    // );
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.86) 0%, rgba(0, 0, 0, 0.77) 100%);
    font-size: 15px;
    text-align: center;
  }
  .bg{
    background-color: black;
    z-index: 999999 !important;
  }

  #video-container {
    height: 100%;
    width: 100%;
    min-width: 100%;
    position: absolute;
    // display: grid;
    // grid-template-rows: repeat(2, 1fr);
    // grid-template-columns: repeat(4, 0.5fr);
    overflow-x: hidden !important;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    z-index: 1;
    video {
      display: flex;
      background-color: black;
      border-radius: 3px;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
      /* max-width: 800px; */
      /* max-height: 600px; */
      max-width: 100%;
      max-height: 40%;
    }
    .remotePersonCall {
      position: relative;
      min-width: 100px;
      min-height: 100px;
      margin: 5px;
      margin: 0;
      /* 1 person (1 in call plus user) */
      &.total-remote-1.remote-video--0 {
        grid-area: 1 / 1 / span 4 / span 4;
      }

      /* 3 person (2 in call plus user) */
      &.total-remote-2.remote-video--0 {
        grid-area: 1 / 1 / span 2 / span 2;
      }

      &.total-remote-2.remote-video--1 {
        grid-area: 1 / 3 / span 2 / span 2;
      }

      /* 4 person (3 in call plus user) */
      &.total-remote-3.remote-video--0 {
        grid-area: 1 / 1 / span 1 / span 2;
      }

      &.total-remote-3.remote-video--1 {
        grid-area: 2 / 1 / span 1 / span 2;
      }

      &.total-remote-3.remote-video--2 {
        grid-area: 1 / 3 / span 2 / span 2;
      }

      /* 5 person (4 in call plus user) */
      &.total-remote-4.remote-video--0 {
        grid-area: 1 / 1 / span 1 / span 2;
      }

      &.total-remote-4.remote-video--1 {
        grid-area: 2 / 1 / span 1 / span 2;
      }

      &.total-remote-4.remote-video--2 {
        grid-area: 1 / 3 / span 1 / span 2;
      }

      &.total-remote-4.remote-video--3 {
        grid-area: 2 / 3 / span 1 / span 2;
      }
    }
    .localVideo {
      /* grid-area: 2 / 3 / span 1 / span 2; */
      z-index: 999;
      // position: absolute;
      // bottom: 0;
      /*width: 340px;*/
      height: 100%;
      right: 0;
      border: 1px solid #00000066;
      #local-video {
        width: 100%;
        max-height: unset;
        position: relative;
        bottom: 0;
        height: 100%;
      }

      #local-screen {
        width: 100%;
        position: absolute;
        top: 0;
        height: 100%;
        max-height: unset;
      }
      .noCameraStream {
        position: absolute;
        display: table;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        text-align: center;
        z-index: -10;
        font-weight: bold;
        font-size: 30px;
        .noCameraContent{
          display: table-cell;
          vertical-align: middle;
            .emptyDiv{
            height: 125px;
          }
        }
        .imageNotVideo{
          width: 160px;
          height: 160px;
          border-radius: 10px;
          margin: 0 auto;
          }
  
      }
      
      .buttonsIpad{
        bottom: 64px !important;
      }

      .buttonsUser{
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 10px;
          padding-right: 10px;
          height: 45px;
          padding-top: 7px !important;
          bottom: 0;
          position: absolute;
          width: 100%;
          z-index: 9999999999999;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
          @media only screen  and (min-device-width : 768px) and (max-device-width : 1024px)  {
            bottom: 64px !important;
          }
          @media only screen 
          and (min-device-width : 768px) 
          and (max-device-width : 1024px) 
          and (orientation : landscape) {
            bottom: 64px !important;
          }
          @media only screen 
          and (min-device-width : 768px) 
          and (max-device-width : 1024px) 
          and (orientation : portrait) { 
            bottom: 64px !important;
           }
           @media only screen 
          and (min-device-width : 768px) 
          and (max-device-width : 1024px)
          and (-webkit-min-device-pixel-ratio: 2) {
            bottom: 64px !important;
          }

          @media only screen 
          and (min-device-width : 768px) 
          and (max-device-width : 1024px) 
          and (orientation : landscape)
          and (-webkit-min-device-pixel-ratio: 2) {
            bottom: 64px !important;
          }

          @media only screen 
          and (min-device-width : 768px) 
          and (max-device-width : 1024px) 
          and (orientation : portrait)
          and (-webkit-min-device-pixel-ratio: 2) {
            bottom: 64px !important;
           }

          @media only screen 
          and (min-device-width : 768px) 
          and (max-device-width : 1024px) 
          and (-webkit-min-device-pixel-ratio: 1){
            bottom: 64px !important;
          }

          @media only screen 
          and (min-device-width : 768px) 
          and (max-device-width : 1024px) 
          and (orientation : landscape)
          and (-webkit-min-device-pixel-ratio: 1)  {
            bottom: 64px !important;
          }

          @media only screen 
          and (min-device-width : 768px) 
          and (max-device-width : 1024px) 
          and (orientation : portrait) 
          and (-webkit-min-device-pixel-ratio: 1) {
            bottom: 64px !important;
           }
          .pUserName{
            font-size: 14px;
            font-weight: normal;
            margin-bottom: 0px;
            margin-top: 5px;
            color: white;
          }
          .divButtonsLocal{
            button{
              margin-right: 5px;
              color: white;
              // background: #2a3133;
              // border-radius: 4px;
              &:last-of-type{
                margin-right: 0px;
              }
              &.redBtn{
                // background: red;
              }
            }
          }
        }
    }

  }
  .splitpanes__pane{
    // background-color: transparent !important;
    z-index: 0;
    position: relative;
  }

  .v-theme--dark .splitpanes.default-theme .splitpanes__pane{
    background-color: #272727;
  }
  .v-theme--light .splitpanes.default-theme .splitpanes__pane{
    background-color: #f2f2f2;
  }
</style>