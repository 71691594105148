// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.templateName {
  position: absolute;
  top: 30px;
  left: 60px;
}
.templateNameHeader {
  position: absolute;
  top: 10px;
  left: 60px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/templateListItem.vue","webpack://./templateListItem.vue"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;ACAF;ADEA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;ACCF","sourcesContent":["\n.templateName {\n  position: absolute;\n  top: 30px;\n  left: 60px;\n}\n.templateNameHeader {\n  position: absolute;\n  top: 10px;\n  left: 60px;\n}\n",".templateName {\n  position: absolute;\n  top: 30px;\n  left: 60px;\n}\n\n.templateNameHeader {\n  position: absolute;\n  top: 10px;\n  left: 60px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
