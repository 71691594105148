<template>
  <v-card>
    <v-row class="px-0 btns" flex :style="{width: '100%', margin: 0}">
      <!-- <v-col class="px-0 py-0" align="left">
        <v-tooltip location="top" v-if="amIAdmin">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              color="primary"
              class="btnRed mr-2"
              v-on:click="seeUserMessages(notificationInfo.creatorUUID); closeModal()"
            >
              <font-awesome-icon :icon="['fal', 'eye']" :style="{ fontSize: '20px' }" />
            </v-btn>
          </template>
          <span>{{$t('components.sendMessageModal.seeUserMessages')}}</span>
        </v-tooltip>
      </v-col> -->
      <!-- <v-col class="px-0 py-0" align="right">
        <v-tooltip location="top">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon color="primary" class="btnRed mx-0" v-on:click="toggleIsReply()">
              <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '20px' }" />
            </v-btn>
          </template>
          <span>{{ $t('generics.cancel') }}</span>
        </v-tooltip>
      </v-col> -->
    </v-row>
    <v-divider></v-divider>
    <v-textarea
      :model-value="replyMessage" @update:model-value="replyMessage = $event"
      :rules="rules"
      :no-resize="true"
       autofocus
      rows="3"
      variant="outlined"
      :label="$t('components.notificationsModal.reply')"
    ></v-textarea>
  </v-card>
</template>
<script>
import store from "../../store";
import { sendOfflineMsg, sendMsg } from "../../lib/wsMsg.js";
export default {
  components: {},
  props: ["toggleIsReply", "notificationInfo", "closeModal"],
  data: () => ({
    state: store.state,
    ownUUID: store.state.ownUUID,
    valid: true,
    setCurrentContentVisile: store.setCurrentContentVisile,
    closeOnClick: true,
    closeOnContent: false,
    offset: true,
    shown: true,
    linkText: " Go Here ",
    linkUrl: "https://www.ibm.com",
    content: `
      <h1>Yay Headlines!</h1>
      <p>All these <strong>cool tags</strong> are working now.</p>
    `,
    rules: [(v) => !!v || "", (v) => !!v.trim() || "Message can not be blank"],
    contentWidth: 0,
    contentHeight: 0,
    previousHeight: 0,
    replyMessage: "",
  }),
  methods: {
    seeUserMessages(user) {
      // store.state.user.subordinateActiveUser = user;
      this.setCurrentContentVisile("messages", false, this.$router);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    userIsConnected(uuid) {
      const users = this.state.group || {};
      if (users[uuid].connected) {
        return true;
      } else {
        return false;
      }
    },
    sendOfflineMsg(...arg) {
      return sendOfflineMsg(...arg);
    },
    sendMsg(...arg) {
      return sendMsg(...arg);
    },
    setshowCompSendMsg(v) {
      return store.setshowCompSendMsg(v);
    },
    separatedPeople: function () {
      const result = { sections: {}, departments: {}, teams: {} };
      if (!store.state.namespaceSettings.processedOrganisation) {
        return result;
      }
      const sections =
        store.state.namespaceSettings.processedOrganisation.sections || {};
      const departments =
        store.state.namespaceSettings.processedOrganisation.departments || {};
      const teams =
        store.state.namespaceSettings.processedOrganisation.teams || {};

      if (Object.keys(sections).length > 0) {
        Object.keys(sections).forEach((orgKey) => {
          if (!result.sections[orgKey]) result.sections[orgKey] = [];
          for (var i = 0; i < sections[orgKey].supervisors.length; i++) {
            if (
              this.ownUUID !== sections[orgKey].supervisors[i].uuid &&
              !result.sections[orgKey].includes(
                sections[orgKey].supervisors[i].uuid
              )
            ) {
              result.sections[orgKey].push(
                sections[orgKey].supervisors[i].uuid
              );
            }
          }
          for (var i = 0; i < sections[orgKey].departments.length; i++) {
            const departKey = sections[orgKey].departments[i];
            const department = departments[departKey];
            if (!result.departments[departKey])
              result.departments[departKey] = [];
            for (var j = 0; j < department.supervisors.length; j++) {
              if (
                this.ownUUID !== department.supervisors[j].uuid &&
                !result.sections[orgKey].includes(
                  department.supervisors[j].uuid
                )
              ) {
                result.sections[orgKey].push(department.supervisors[j].uuid);
              }
              if (
                this.ownUUID !== department.supervisors[j].uuid &&
                !result.departments[departKey].includes(
                  department.supervisors[j].uuid
                )
              ) {
                result.departments[departKey].push(
                  department.supervisors[j].uuid
                );
              }
            }
            for (
              var teamKey = 0;
              teamKey < department.teams.length;
              teamKey++
            ) {
              const team_key = department.teams[teamKey];
              const team = teams[team_key];
              if (!result.teams[team_key]) result.teams[team_key] = [];
              for (var j = 0; j < team.supervisors.length; j++) {
                if (
                  this.ownUUID !== team.supervisors[j].uuid &&
                  !result.sections[orgKey].includes(team.supervisors[j].uuid)
                ) {
                  result.sections[orgKey].push(team.supervisors[j].uuid);
                }
                if (
                  this.ownUUID !== team.supervisors[j].uuid &&
                  !result.departments[departKey].includes(
                    team.supervisors[j].uuid
                  )
                ) {
                  result.departments[departKey].push(team.supervisors[j].uuid);
                }
                if (
                  this.ownUUID !== team.supervisors[j].uuid &&
                  !result.teams[team_key].includes(team.supervisors[j].uuid)
                ) {
                  result.teams[team_key].push(team.supervisors[j].uuid);
                }
              }
              for (var j = 0; j < team.members.length; j++) {
                if (
                  this.ownUUID !== team.members[j] &&
                  !result.sections[orgKey].includes(team.members[j])
                ) {
                  result.sections[orgKey].push(team.members[j]);
                }
                if (
                  this.ownUUID !== team.members[j] &&
                  !result.departments[departKey].includes(team.members[j])
                ) {
                  result.departments[departKey].push(team.members[j]);
                }
                if (
                  this.ownUUID !== team.members[j] &&
                  !result.teams[team_key].includes(team.members[j])
                ) {
                  result.teams[team_key].push(team.members[j]);
                }
              }
            }
          }
        });
      }
      function sortObject(o) {
        return Object.keys(o)
          .sort((a, b) => {
            const str1 = a.toLowerCase();
            const str2 = b.toLowerCase();
            return (str1 > str2) - (str1 < str2);
          })
          .reduce((r, k) => ((r[k] = o[k]), r), {});
      }
      result.sections = sortObject(result.sections);
      result.departments = sortObject(result.departments);
      result.teams = sortObject(result.teams);
      return result;
    },
  },
  computed: {
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
  },
};
</script>
<style scoped lang="scss">
.v-card {
  box-shadow: none !important;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
</style>