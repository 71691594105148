<template>
  <v-container fluid class="wallpaper"> </v-container>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.wallpaper {
  background-size: 400% 400%;
  animation: gradient 30s ease infinite;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0px;
  height: calc(100% - 48px);
  z-index: -1;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>