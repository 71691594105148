<template>
  <div>
    <TwofaModal
      v-if="show2faStatus"
      :showModal="show2faStatus"
      :toggle2Modal2faSetting="toggle2Modal2faSetting"
      :goToModal="goToModal"
    ></TwofaModal>
    <TwofaOtp v-if="show2faOtp" :showModal="show2faOtp" :toggle2faOtp="toggle2faOtp"></TwofaOtp>
    <TwofaPin v-if="show2faPin" :showModal="show2faPin" :toggle2faPin="toggle2faPin"></TwofaPin>
    <TwofaSms v-if="show2faSms" :showModal="show2faSms" :toggle2faSms="toggle2faSms"></TwofaSms>
    <TwofaDisable
      v-if="show2faDisable"
      :showModal="show2faDisable"
      :toggle2faDisable="toggle2faDisable"
      :goToModal="goToModal"
      :redirectTo="redirectTo"
    ></TwofaDisable>
  </div>
</template>

<script>
import store from "../../../store";
import TwofaModal from "./2faModal.vue";
import TwofaOtp from "./2faOtp.vue";
import TwofaPin from "./2faPin.vue";
import TwofaSms from "./2faSms.vue";
import TwofaDisable from "./2faDisable.vue";
export default {
  components: { TwofaModal, TwofaOtp, TwofaPin, TwofaSms, TwofaDisable },
  props: ["showModalStatus", "toggle2Modal2faSetting"],
  data() {
    return {
      state: store.state,
      show2faOtp: false,
      show2faPin: false,
      show2faSms: false,
      show2faDisable: false,
      show2faStatus: true,
      redirectTo: null,
    };
  },
  methods: {
    toggle2Modal2faStatus() {
      this.show2faStatus = !this.show2faStatus;
    },
    toggle2faOtp(dismissAll = false) {
      if (!dismissAll) this.toggle2Modal2faStatus();
      this.show2faOtp = !this.show2faOtp;
    },
    toggle2faPin(dismissAll = false) {
      if (!dismissAll) this.toggle2Modal2faStatus();
      this.show2faPin = !this.show2faPin;
    },
    toggle2faSms(dismissAll = false) {
      if (!dismissAll) this.toggle2Modal2faStatus();
      this.show2faSms = !this.show2faSms;
    },
    toggle2faDisable() {
      this.toggle2Modal2faStatus();
      this.show2faDisable = !this.show2faDisable;
    },
    goToModal(modalName, extraParam = null) {
      this.toggle2Modal2faStatus();
      switch (modalName) {
        case "2faOtp":
          this.show2faOtp = true;
          break;
        case "2faPin":
          this.show2faPin = true;
          break;
        case "2faSms":
          this.show2faSms = true;
          break;
        case "2faDisable":
          if(extraParam){
            this.redirectTo = extraParam
          }
          this.show2faDisable = true;
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped lang="scss">
</style>