import { createRouter, createWebHashHistory } from 'vue-router';
// import TicketsContainer from './components/content/ticketsContainer.vue';
// import InfoboardContainer from './components/content/infoboardContainer.vue';
import UserGroupsContent from './components/content/userGroupsContainer.vue';
import OrganisationGrid from './components/content/organigram/newOrganisationGrid.vue';
import FavoriteUserContainer from './components/content/favoriteUserContainer.vue';
import SocialMediaContainer from './components/content/socialMediaContainer.vue';
import UserContainer from './components/content/userContainer.vue';
// import GuestContainer from './components/content/guestContainer.vue';
// import InvitesContainer from './components/content/invitesContainer.vue';
// import Home from './components/content/home.vue';
import StatusContainer from './components/content/statusContainer.vue';
// import UserMessages from './components/content/adminMessages/viewMessages.vue';
import UserMessages from './components/content/adminMessages/viewMessagesEffector.vue';
import RdpContainer from './components/content/rdpContainer.vue';
import MainContainer from './components/content/mainContainer.vue';
import SectionsContainer from './components/content/sectionsContainer.vue';
import DepartmentsContainer from './components/content/departmentsContainer.vue';
import TeamsContainer from './components/content/teamsContainer.vue';
// import GroupsContainer from './components/content/groupsContainer.vue';
import CalendarContainer from './components/content/calendarContainerEffector.vue';
import VisitorsContainer from './components/content/visitorContainer.vue';
import OneCallContainer from './components/content/onecallContainer.vue';
import WaitingRoomContainerNew from './components/content/waitingRoom/waitingRoomContainerNew.vue';
import SupportView from './components/content/support/support.vue';
import AllEventViewEffector from './components/content/allEventViewEffector.vue';
// import NewConferenceForm from './components/timeline/newConferenceForm.vue'
import ConferenceContainer from './components/content/conferenceContainer.vue'
import SambaRoomsContainer from './components/content/sambaRoomsContainer.vue'
import ConferenceStats from "./components/content/stats/conferences/conferenceStats.vue"
import InvitationForm from "./components/content/inviteForm.vue"
import InvitationFormUser from "./components/content/inviteFormUser.vue"
import PaymentsTable from "./components/content/paymentsTable.vue"
import ExternalCall from "./components/content/externalCall.vue"
import CallListenerWrapper from "./components/content/calls/callListenerWrapper.vue"
import DailyReport from "./components/content/dailyReport/dailyReportContainer.vue"
import CoffeeBreakUserContainer from './components/content/coffeeBreakUserContainer.vue';
import WorkingTimeContainer from './components/content/workingTimeContainer.vue';
import EmptyComponent from './components/content/emptyComponent/emptyComponent.vue'
import ExternalSambaCall from './components/content/calls/externalSambaCall.vue';
// import DecryptError from './components/firstSetup/decryptError.vue';
// import SupportContainer from './components/content/supportContainer.vue';
// import CcPayment from './components/payment/ccpayment.vue';
// import CcPaymentFail from './components/payment/ccpaymentFail.vue';
// import CcPaymentSuccess from './components/payment/ccpaymentSuccess.vue';

export default createRouter({
  history: createWebHashHistory(),
  routes: [
    // { path: '/infoboard', component: InfoboardContainer },
    // { path: '/tasks/:type/:uuid', component: TicketsContainer },
    // { path: '/tasks/:type', component: TicketsContainer },
    { path: '/', component: EmptyComponent },
    { path: '/organisation/:uuid', component: OrganisationGrid },
    { path: '/organisation', component: OrganisationGrid },
    // { path: '/groups', component: GroupsContainer },
    { path: '/group/:name', component: UserGroupsContent }, // this.$route.params.name
    { path: '/my-favorites', component: FavoriteUserContainer },
    { path: '/socialmedia', component: SocialMediaContainer },
    { path: '/sections', component: SectionsContainer },
    { path: '/departments', component: DepartmentsContainer },
    { path: '/teams', component: TeamsContainer },
    { path: '/users', component: UserContainer },
    // { path: '/guest', component: GuestContainer },
    // { path: '/invites', component: InvitesContainer },
    { path: '/visitor', component: VisitorsContainer },
    { path: '/home', component: MainContainer },
    { path: '/status/:type', component: StatusContainer },
    { path: '/messages', component: UserMessages },
    { path: '/vviewer', component: RdpContainer },
    { path: '/calendar', component: CalendarContainer },
    // { path: '/calendar/:uuid', component: CalendarContainer },
    { path: '/onecall', component: OneCallContainer },
    { path: '/waitingroom', component: WaitingRoomContainerNew },
    { path: '/waitingroomfor/:uuid', component: WaitingRoomContainerNew },
    { path: '/support', component: SupportView },
    { path: '/myevents', component: AllEventViewEffector },
    { path: '/newconference', component: ConferenceContainer},
    { path: '/newconference/:id', component: ConferenceContainer},
    { path: '/new-room', component: SambaRoomsContainer},
    // { path: '/newconference', component: NewConferenceForm},
    // { path: '/newconference/:id', component: NewConferenceForm},
    { path: '/statsconferences', component: ConferenceStats},
    { path: '/invitationform', component: InvitationForm},
    { path: '/invitationform/:id', component: InvitationForm},
    { path: '/invitationformuser', component: InvitationFormUser},    
    { path: '/payments', component: PaymentsTable},
    { path: '/externalcall/:email', component: ExternalCall},
    { path: '/dailyreport', component: DailyReport}, 
    // { path: '/decryptError', component: DecryptError },
    // { path: '/support', component: SupportContainer },
    // { path: '/ccpayment', component: CcPayment },
    // { path: '/ccpayment/fail', component: CcPaymentFail },
    // { path: '/ccpayment/success', component: CcPaymentSuccess }
    { path: '/stage-hall', component: CallListenerWrapper },
    { path: '/coffee-break', component: CoffeeBreakUserContainer },
    { path: '/working-time', component: WorkingTimeContainer },
    { path: '/external/:namespace', component: ExternalSambaCall },
  ]
});
