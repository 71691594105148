import { callPersonConferenceHallModalDomain } from './domain';

import { setCallPersonConferenceHallModalEvent, resetCallPersonConferenceHallModalEvent} from './events';

export const callPersonConferenceHallModalStore = callPersonConferenceHallModalDomain.createStore(false, { name: ' callPersonConferenceHallModal' });

callPersonConferenceHallModalStore.on(setCallPersonConferenceHallModalEvent, (state, payload) => {
  return payload;
});

callPersonConferenceHallModalStore.reset(resetCallPersonConferenceHallModalEvent);
// qualityVotingModalStore.watch((state) => { console.log('qualityVotingModalStore', state); });
