import { removeHashFromUrl } from '../lib/wsConnect';
import store, { isIframe } from '../store';

export function isGuestOrVisitor () {
  return (store.state.user.guest || store.state.user.visitor);
}

const guestVisitorPages = ['/', '/home'];

function routerAcl (router) {
  router.beforeEach((to, from, next) => {
    if (isGuestOrVisitor() && guestVisitorPages.indexOf(to.path) === -1) {
      return next('/home');
    }
    if (isIframe() && to.path === '/home' && from.path === '/users') {
      try {
        const remoteStreamsKey = Object.keys(window.top?.document?.store?.state?.remoteBridgeStreams || {})[0];
        if (remoteStreamsKey) { // Detected call on top, do not navigate.
          return next('');
        }
      } catch (err) {
        console.error('An error occurred:', err);
      }
    }
    const hashFromUrl = removeHashFromUrl();
    return next(hashFromUrl);
  });
  return router;
}

export default routerAcl;
