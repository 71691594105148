import { qrCodeModalDomain } from './domain';

import { setQrCodeModalEvent, resetQrCodeModalEvent} from './events';

export const qrCodeModalStore = qrCodeModalDomain.createStore(false, { name: ' qrCodeModal' });

qrCodeModalStore.on(setQrCodeModalEvent, (state, payload) => {
  return payload;
});

qrCodeModalStore.reset(resetQrCodeModalEvent);
