// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-ripple__animation {
  opacity: 0 !important;
}
.v-card--link:focus:before {
  opacity: 0 !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/groupsCardGrid.vue","webpack://./groupsCardGrid.vue"],"names":[],"mappings":"AACA;EACE,qBAAA;ACAF;ADGA;EACE,qBAAA;ACAF","sourcesContent":["\n.v-ripple__animation {\n  opacity: 0 !important;\n}\n\n.v-card--link:focus:before {\n  opacity: 0 !important;\n}\n",".v-ripple__animation {\n  opacity: 0 !important;\n}\n\n.v-card--link:focus:before {\n  opacity: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
