<template>
  <v-dialog
    persistent
     :model-value="!!showModal"
    @update:model-value="showModal= $event"
    max-width="720"
    @keydown.esc="closeModal"
  >
    <!-- :fullscreen="isMobile" -->
    <div>
      <v-card>
        <HeaderModal
          :titleModal="$t('generics.messages')"
          :closeModalFunction="closeModal"
        />
        <v-divider></v-divider>
        <v-row flex :style="{ width: '100%', margin: 0 }">
          <v-col v-for="n in 1" :key="n" class="col-12">
            <div v-if="n == 1" class="d-flex">
                <v-avatar
                  tile
                  size="76"
                  class="borderRadius4"
                  style="margin: 0px 10px"
                  :style="getBorderByStatusFull(
                      state.group[showModal], '2px'
                    )"
                >
                  <DefaultAvatar
                    :size="76"
                    :userData="historyMessagesInfo.user"
                    v-if="
                      getAvatarForUuid(showModal) ===
                      'img/default_profile_picture.png'
                    "
                  ></DefaultAvatar>
                  <v-img
                    v-if="
                      getAvatarForUuid(showModal) !==
                      'img/default_profile_picture.png'
                    "
                    max-height="76"
                    max-width="76"
                    contain
                    :src="getAvatarForUuid(showModal)"
                    lazy-src="img/default_profile_picture.png"
                  ></v-img>
                </v-avatar>
              <v-row :style="{ width: '80%', margin: 0 }">
                <v-col cols="12" class="pb-0 pt-1 d-flex flex-column">
                  <p color="primary" class="userNameCard mb-0 pb-0 mr-auto">
                    {{ getNameForUuid(showModal) }}
                  </p>
                  <div class="pt-6">
                    <v-tooltip location="top" v-if="!isWaitingRoomUser">
                      <template v-slot:activator="{ props }">
                        <v-btn
                          density="compact"
                          variant="text"
                          @click.stop.prevent="callUser(showModal)"
                          icon
                          :disabled="disableCallBtn(showModal) == true"
                          v-bind="props"
                        >
                          <font-awesome-icon
                            :icon="['fal', 'phone']"
                            :color="setBorderByStatus(state.group[showModal])"
                            :style="{ fontSize: '16px' }"
                          ></font-awesome-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("components.userProfile.callUser") }}</span>
                    </v-tooltip>
                    <v-btn icon density="compact" variant="text" v-if="messageHistory.length > 0">
                      <DeleteBasket
                        :indexData="showModal"
                        :delFunction="deleteMessage"
                        fontSize="16"
                        prevent="true"
                      />
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row
          flex
          :style="{ width: '100%', margin: 0 }"
          :class="{ heightMobileMessages: isMobile }"
        >
          <v-col class="col-12">
            <div>
              <v-container
                :class="`contentMessagesIsReply ${
                  isMobile ? 'contentMessagesIsReplyMobile' : ''
                }`"
                ref="messageContainer"
              >
                <MessageHistoryEffector :messages="messageHistory" />
              </v-container>
              <div class="px-6">
                <v-divider></v-divider>
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- <FooterModal :closeModalFunction="closeModal" class="mt-4"> -->
        <v-card
          :class="isDark ? 'footersBackgroundMessagesDark' : 'footersBackgroundMessages'" 
          class="py-2 pl-4 pr-2 footerModal"
        >
          <v-row class="pl-2 btns w-100 mx-0 my-0">
            <v-text-field
                  :model-value="replyMessage" @update:model-value="replyMessage = $event"
                  class=""
                  @keyup.enter="
                    replyMessage.trim().length > 0 ? sendReply() : null
                  "
                  :bg-color="isDark ? '#1e1e1e' : '#fff'"
                  autofocus
                  hide-details
                  variant="outlined"
                  :maxlength="(!replyMessage.trim().length && 960) || (replyMessage.match(/(https?:|www\.)/i) ? 2048 : 480)"
                  autocomplete="off"
                  density="compact"
                  :placeholder="$t('components.notificationsModal.reply')"
                >
                  <template v-slot:append-inner>
                    <v-btn
                      class="ml-2"
                      @click="sendReply()"
                      icon
                      density="compact"
                      variant="text"
                      :disabled="replyMessage.trim().length === 0"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'paper-plane']"
                        :style="{ fontSize: '20px' }"
                        color="primary"
                      />
                    </v-btn>
                  </template>
            </v-text-field>
            <v-btn
              rounded="5px"
              color="primary"
              style="border-radius: 4px; min-width: 45px;margin-top: 2px;"
              icon
              tile
              variant = "outlined"
              density="comfortable"
              class="ml-3  mr-2 greyBtn"
              @click="closeModal()"
            >
              <font-awesome-icon
                :icon="['fal', 'times']"
                :style="{ fontSize: '20px' }"
              />
              <!--{{ $t("generics.cancel") }}-->
            </v-btn>
          </v-row>
        </v-card>
        <!-- </FooterModal> -->
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import { callPerson, disableCallBtn } from "../../utils/calls";
import { getStatusColorByUser } from "../../utils/status";
import moment from "../../../sharedsrc/moment";
import { isWaitingRoomUser } from "../../utils/privileges";
import MessageHistoryEffector from "../content/messageHistoryEffector.vue";
import {
  getBorderByStatusFull,
} from "../../utils/basicFunctions";
import {
  historyMessagesModalStore,
  resetHistoryMessagesModalEvent,
} from "../../effector/modals";
import {
  messages,
  newMessageEvent,
  markAsViewedSpecificMessageEvent,
  markAsReadSpecificMessageEvent,
  getMessageHistoryByUserUUID,
  dispatchDeleteConversationEvent,
} from "../../effector/message";
import DefaultAvatar from "../image/defaultAvatar.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import DeleteBasket from "../../components/ui/deleteBasket.vue";
import FooterModal from "./modalComponents/footerModal.vue";

export default {
  components: {
    DefaultAvatar,
    MessageHistoryEffector,
    HeaderModal,
    DeleteBasket,
    FooterModal
  },
  data() {
    const effector = {
      showModal: historyMessagesModalStore,
      messages: messages,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      loading: false,
      moment,
      setCurrentContentVisile: store.setCurrentContentVisile,
      ownUUID: store.state.ownUUID,
      showTextareaReply: false,
      rules: [(v) => !!v || "", (v) => !!v.trim() || "Message can not be blank"],
      messageHistory: [],
      replyMessage: "",
      isMobile: isMobile(),
      // Effector
      ...effector,
    }
  },
  watch: {
    messages: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) return;
        this.messageHistory = this.historyMessagesStore.getState() || [];
        this.updateMessageParams(this.messageHistory);
      },
    },
    totalMessages: {
      handler: function (total) {
        if (total) {
          const percentage =
            (this.$refs["messageContainer"].$el.scrollTop /
              (this.$refs["messageContainer"].$el.scrollHeight -
                this.$refs["messageContainer"].$el.clientHeight)) *
            100;
          if (percentage > 70) {
            setTimeout(() => {
              this.scrollToBottom();
            }, 300);
          }
        }
      },
    },
  },
  mounted: function () {
    if (!this.isWaitingRoomUser) {
      this.updateMessageParams(this.messageHistory);
    }
    this.scrollToBottom();
  },
  methods: {
    getBorderByStatusFull(person, customCss) {
      return getBorderByStatusFull(person, customCss);
    },
    deleteMessage(userUUID) {
      dispatchDeleteConversationEvent(userUUID);
    },
    disableCallBtn(uuid) {
      let person = this.state.group[uuid];
      return !!disableCallBtn(person);
    },
    setBorderByStatus(person) {
      if (!person || !person.user) return;
      return getStatusColorByUser(person.user, person.connected);
    },
    scrollToBottom() {
      // scroll to bottom
      this.$refs["messageContainer"].$el.scrollTop =
        this.$refs["messageContainer"].$el.scrollHeight;
    },
    updateMessageParams(data) {
      for (const messages in data) {
        if (Object.prototype.hasOwnProperty.call(data, messages)) {
          const message = data[messages];
          if (message.users[this.state.ownUUID]) {
            if (!message.users[this.state.ownUUID].viewed) {
              markAsViewedSpecificMessageEvent(message.messageUUID);
            }
            if (!message.users[this.state.ownUUID].read) {
              markAsReadSpecificMessageEvent(message.messageUUID);
            }
          }
        }
      }
    },
    getPerson(uuid) {
      return this.state.group[uuid];
    },
    badgetClassObj(userUUID) {
      return getStatusColorByUser(
        this.state.group[userUUID].user,
        this.state.group[userUUID].connected
      );
    },
    closeModal() {
      resetHistoryMessagesModalEvent();
    },
    sendReply() {
      const userToSend = this.showModal;
      const userToSendMsg = {};
      userToSendMsg[userToSend] = {
        userUUID: userToSend,
        read: false,
        replied: false,
        deleted: false,
        viewed: false,
      };
      const dataMsg = {
        body: this.replyMessage,
        header: "individual message",
        date: Date.now(),
        users: userToSendMsg,
        isReply: false,
        creatorUUID: this.ownUUID,
        masterMessageUUID: null,
        parentMessageUUID: null,
        type: "message",
      };
      newMessageEvent(dataMsg);
      this.replyMessage = "";
    },
    callUser(uuid) {
      setTimeout(function () {
        callPerson(uuid, this.$router);
      }, 200);
      this.closeModal();
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    isSentMessage(message) {
      if (message && message.creatorUUID == this.ownUUID) {
        return true;
      } else {
        return false;
      }
    },
    getDataUserMessage(message) {
      if (this.isSentMessage(message)) {
        return Object.keys(message.users)[0];
      } else {
        return message.creatorUUID;
      }
    },
  },
  computed: {
    isDark() {
      return store.state.persisted.isDark;
    },
    totalMessages() {
      return this.messageHistory.length;
    },
    historyMessagesStore() {
      const userUUID = this.showModal;
      return getMessageHistoryByUserUUID(userUUID);
    },
    historyMessagesInfo() {
      return store.state.group[this.showModal];
    },
    isWaitingRoomUser() {
      return isWaitingRoomUser(this.ownUUID);
    },
    amInACall() {
      return !!Object.keys(this.state.rtc).length;
    },
  },
};
</script>

<style scoped lang="scss">
.borderRadius4 {
  border-radius: 4px!important;
}
.pointer {
  cursor: pointer;
}
.heightMobileMessages {
  height: calc(100% - 243px);
}
.inputMessageWaitingRoom {
  .v-input__append-inner {
    margin-top: 1px !important;
  }
  .v-text-field input {
    color: black !important;
    margin-top: 4px
  }
}
.contentMessages {
  height: calc(100vh - 400px) !important;
  overflow: auto !important;
}
.contentMessagesIsReplyMobile {
  // height: calc(100% - 10px) !important;
  height: 150px !important;
}
.contentMessagesIsReply {
  height: 388px;

  overflow: auto !important;
}
.btnMssgClass {
  width: 60px;
}
.btnCallClass {
  width: 70px !important;
  height: 70px !important;
}
.btns {
  justify-content: flex-end;
}
.btnMssgClass {
  width: 60px;
}
.btnCallClass {
  width: 70px;
  height: 70px !important;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}

.btnRed {
  background: red;
  border-radius: 4px;
}
.btnWhite {
  background: #ffffffa6;
  border-radius: 4px;
}
.vueScroll {
  height: calc(100% - 64px) !important;
  .__view {
    width: unset !important;
  }
}
.bR4 {
  border-radius: 4px;
  width: 36px;
  height: 36px !important;
  min-width: 36px !important;
}
.messageTextWrapper {
  height: 200px;
  white-space: pre-line;
}
</style>
<style lang="scss">
.inputMessageWaitingRoom {
  .v-text-field input{
    margin-top: 4px!important;
  }
}
</style>