<template>
  <div v-if="person && person.user && uuid">
    <v-row align="center" class="mx-0 mt-4" justify="space-around">
      <!-- :style="{background: `${setBackgroundByStatus(person)}`}" -->
      <!-- <v-col cols="4"> -->
      <v-tooltip location="top" v-if="uuid !== ownUUID">
        <template v-slot:activator="{ on }">
          
          <v-btn
            v-on="on"
            v-on:click="callUser(uuid)"
            icon
            v-bind:disabled="disableCallBtn(person)"
          >
            <font-awesome-icon :icon="['fal', 'phone']" :style="{ fontSize: '20px' }" />
          </v-btn>
        </template>
        <span>{{ $t('components.userProfile.callUser') }}</span>
      </v-tooltip>
      <!-- <v-tooltip location="top" v-if="getCallText(person) && uuid !== ownUUID">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon v-on:click="getWebrtcConnections()[uuid].die(true)">
            <font-awesome-icon
              class="faPhoneRotate"
              :icon="['fal', 'phone']"
              :rotation="270"
              :style="{ fontSize: '20px' }"
            />
          </v-btn>
        </template>
        <span>{{ $t('components.userListItem.hangOff') }}</span>
      </v-tooltip> -->

      
      <!-- </v-col> -->
      <!-- <v-col cols="4"> -->
      <!-- <v-badge
          bordered
          color="error"
          v-if="!personIsGuest(person) && person.user"
          :content="(person.user.userUnreadTicketCounter === 0 || !person.user.userUnreadTicketCounter)  ? null : person.user.userUnreadTicketCounter"
          :value="(person.user.userUnreadTicketCounter === 0 || !person.user.userUnreadTicketCounter)  ? null : person.user.userUnreadTicketCounter"
          overlap
      >-->
      <!-- <v-tooltip location="top" v-if="!personIsGuest(person)">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click.stop.prevent="ShowTicketEntry(uuid)">
            <font-awesome-icon :icon="['fal', 'ballot-check']" :style="{ fontSize: '20px' }" />
          </v-btn>
        </template>
        <span>{{ $t('components.userListItem.tasks') }}</span>
      </v-tooltip>-->
      <!-- </v-badge> -->
      <!-- </v-col> -->
      <!-- <v-col cols="4"> -->
      <!-- <v-badge
          v-if="person.user"
          bordered
          color="error"
          :content="(person.user.unreadMessageCounter === 0 || !person.user.unreadMessageCounter)  ? null : person.user.unreadMessageCounter"
          :value="(person.user.unreadMessageCounter === 0 || !person.user.unreadMessageCounter)  ? null : person.user.unreadMessageCounter"
          overlap
      >-->
      <v-tooltip location="top" v-if="!isUserInCall(person) && hasOnHold">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            icon
            :color="wholeBarWithColors() ? 'white' : 'black'"
            v-on:click="transferCall(uuid)"
          >
            <font-awesome-icon :icon="['fal', 'long-arrow-up']" :style="{ fontSize: '20px' }" />
            <font-awesome-icon :icon="['fal', 'long-arrow-up']" class="rotatedArrow" />
          </v-btn>
        </template>
        <span>{{ $t('components.userListItem.callForwarding') }}</span>
      </v-tooltip>

      <v-tooltip location="top">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="goToOrganisation(uuid)">
            <font-awesome-icon :icon="['fal', 'sitemap']" :style="{ fontSize: '24px' }" />
          </v-btn>
        </template>
        <span>{{ $t("components.organisationCarousel.showInOrganigram") }}</span>
      </v-tooltip>

      <v-tooltip location="top">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click.stop.prevent="checkSendMessage(uuid)">
            <font-awesome-icon :icon="['fal', 'paper-plane']" :style="{ fontSize: '20px' }" />
      
          </v-btn>
        </template>
        <span>{{$t('components.userProfile.sendAMessage')}}</span>
      </v-tooltip>
    </v-row>
    <v-row align="center" class="mx-0 mt-4" justify="space-around">

      <v-tooltip location="top">
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="!isBasicView && showUploadFile"
            v-on="on"
            icon
            v-bind:disabled="disableSendFileBtn(person)"
            @click.stop.prevent=" uuid !== ownUUID ? sendFileTo(uuid) : openDropdown()"
          >
            <font-awesome-icon
              :icon="['fal', 'upload']"
              :rotation="uuid === ownUUID ? 180 : null"
              :style="{ fontSize: '20px' }"
            />
          </v-btn>
          <v-btn v-else icon :style="'visibility: hidden'">
            <font-awesome-icon :icon="['fas', 'comment']" :style="{ fontSize: '24px' }" />
          </v-btn>
        </template>
        <span>{{ uuid !== ownUUID ? $t('generics.uploadFileMax50') : $t('components.drawerContentCard.files') }}</span>
      </v-tooltip>
      
      <v-tooltip location="top" v-if="!personIsGuest(person) && person.user && !isreachable">
        <template v-slot:activator="{ on }" >
          <v-btn v-on="on" icon>
             <img
             v-if="IsInList"
              src="/img/icons/voffice_online-back-on.svg"
              class="backOnLine"
              @click.stop.prevent="toggleMessageMeRequest(person.uuid)"
             />
             <img
             v-else
              src="/img/icons/voffice_online-back-off.svg"
              class="backOnLine"
              @click.stop.prevent="toggleMessageMeRequest(person.uuid)"
             />           
          </v-btn>
        </template>
        <span>{{$t('components.userListItem.callWhenAvailable')}}</span>
      </v-tooltip>

      <v-tooltip
        top
        v-if="!personIsGuest(person) && person.user && showHelpscoutButton && showVisitPage"
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click.stop.prevent="showHelpscout = true">
            <font-awesome-icon :icon="['fas', 'comment']" :style="{ fontSize: '24px' }" />
          </v-btn>
        </template>
        <span>Helpscout</span>
      </v-tooltip>

      <v-btn icon v-else :style="'display: none'">
        <font-awesome-icon :icon="['fas', 'comment']" :style="{ fontSize: '24px' }" />
      </v-btn>
      <!-- </v-col> -->

      <!-- <v-col cols="4"> -->
        <UserMoreActions :person="person" :section="null" :customStyle="false" />
      <!-- </v-col> -->
    </v-row>
    <template v-if="openingDropdown">
      <DonwnloadsFilesModal :showModal="openingDropdown" :closeModal="openDropdown" />
    </template>
    <template v-if="amIGuest">
      <RemoveFromFavoriteModal
        :person="deleteFavorite.person"
        :showModal="deleteFavorite.show"
        :closeModal="closeDeleteFavoriteModal"
      ></RemoveFromFavoriteModal>
    </template>
    <template>
      <ChangeFunctionModal
        :modalData="changeUserFunction"
        :cancelChangeFunction="cancelChangeFunction"
        v-if="changeUserFunction.show"
      ></ChangeFunctionModal>
    </template>
    <!-- <UserProfile
      v-if="showUserProfile"
      :showUserProfile="showUserProfile"
      :toggleUserProfile="toggleUserProfile"
    /> -->
    <SendInvitationModal
      v-if="showInvitationModal"
      :closeShowInviteInfo="closeShowInviteInfo"
      :showInviteInfo="showInvitationModal"
    />
    <template v-if="deleteUser">
      <RemoveUserModal :person="person" :showModal="deleteUser" :closeModal="closeRemoveUserModal"></RemoveUserModal>
    </template>
    <template v-if="showAddHomePage">
      <HomePageModal
        :sectionData="sectionData"
        :showModal="showAddHomePage"
        :closeModal="closeSetHomePageModal"
      ></HomePageModal>
    </template>
    <template v-if="showSendMessageToAll">
      <SendMessageModalGrid
        :sectionData="sectionData"
        :showModal="showSendMessageToAll"
        :closeModal="closeSendMessageModalGrid"
      ></SendMessageModalGrid>
    </template>
    <template v-if="showHelpscout">
      <InProgressModal :showModal="showHelpscout" :closeModal="closeHelpscoutModal"></InProgressModal>
    </template>
    <template v-if="openHolidaysCalendar">
      <HolidaysCalendarModal
        :userUUID="person.uuid"
        :closeModal="toggleShowHolidaysModal"
        :showModal="openHolidaysCalendar"
      ></HolidaysCalendarModal>
    </template>
    <template v-if="openDisplayAssistants">
      <DisplayAssistantsModal
        :closeModal="toggleDisplayAssistantsModal"
        :showModal="openDisplayAssistants"
      ></DisplayAssistantsModal>
    </template>
    <template v-if="showDirectLink">
      <DirectLinkInvitationModal
        v-if="showDirectLink"
        :showModalDirectLink="showDirectLink"
        :openCloseModalDirectLink="setDirectLink"
      />
    </template>
  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import {
  callUUID /*, rtcHangup*/,
  getWebrtcConnections,
  sendFileTo
} from "../../lib/rtcConn";
import { wsCall } from "../../lib/wsConnect";
import {
  sendInviteMailCustom,
  sendNotificationToUUID,
  importUsersParsed,
  deleteUserByUUID,
  setNamespaceSetting,
  setRemoteUser,
} from "../../lib/wsMsg";
import {
  newPresenceWatchingEvent,
  watchingList,
  watchList,
  newPresenceWatchedEvent
} from "../../effector/presence";
import RemoveFromFavoriteModal from "../modal/removeFromFavoriteModal.vue";
import UserProfile from "../modal/userProfile.vue";
import SendInvitationModal from "../modal/sendInvitationModal.vue";
import ChangeFunctionModal from "../modal/changeFunctionModal.vue";
import { callPerson, disableCallBtn, userInCallText } from "../../utils/calls";
import { getStatusColorByUser } from "../../utils/status";
import RemoveUserModal from "../modal/removeUserModal.vue";
import HomePageModal from "../modal/homePageModal.vue";
import SendMessageModalGrid from "../modal/sendMessageModalGrid.vue";
import InProgressModal from "../modal/inProgressModal.vue";
// import { hasRepresentative } from "../../utils/representatives";
import { basicView } from "../../utils/privileges";
import HolidaysCalendarModal from "../modal/holidaysCalendarModal.vue";
import DisplayAssistantsModal from "../modal/displayAssistantsModal.vue";
import { wholeBarWithColor } from "../../utils/color";
import DirectLinkInvitationModal from "../modal/directLinkInvitationModal.vue";
import DonwnloadsFilesModal from "../modal/downloadsFilesModal.vue";
// import { setUserProfileEvent } from "../../effector/modals";
// import { is } from 'effector';
import UserMoreActions from "../content/userMoreActions.vue";
import { setSendMessageModalEvent, setHistoryMessagesModalEvent } from "../../effector/modals"

export default {
  props: ["person", "uuid", "color", "sectionData", "sectionName", "flipCard", "index"],
  components: {
    RemoveFromFavoriteModal,
    UserProfile,
    SendInvitationModal,
    ChangeFunctionModal,
    RemoveUserModal,
    HomePageModal,
    SendMessageModalGrid,
    InProgressModal,
    HolidaysCalendarModal,
    DisplayAssistantsModal,
    DirectLinkInvitationModal,
    DonwnloadsFilesModal,
    UserMoreActions
  },
  data() {
    const effector = {
      watchingList: watchingList,
      watchedList: watchList
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      setCurrentContentVisile: store.setCurrentContentVisile,
      state: store.state,
      deleteFavorite: {
        show: false,
        person: undefined
      },
      // showUserProfile: false,
      ownUUID: store.state.ownUUID,
      showInvitationModal: false,
      changeUserFunction: {
        show: false,
        person: undefined
      },
      deleteUser: false,
      showAllSection: false,
      showAddHomePage: false,
      showSendMessageToAll: false,
      showHelpscout: false,
      hideShowUser: false,
      openHolidaysCalendar: false,
      openDisplayAssistants: false,
      showDirectLink: false,
      openingDropdown: false,
      subcribedToMssg: false,
      // Effector
      ...effector,
    };
  },
  mounted: function() {
    this.checkHidedUser();
    this.checkShowAllSection();
  },
  methods: {
    getCallText(person){
      return userInCallText(person);
    },
    async toggleMessageMeRequest(uuid) {
      newPresenceWatchingEvent(uuid);
      if ( this.flipCard ){
        this.flipCard(`flipCard-${this.index}`)
      }
      // newPresenceWatchedEvent(uuid);
    },
    goToOrganisation(uuid) {
      // go to the user organisation
      this.setCurrentContentVisile("organisation/" + uuid, true, this.$router);
    },
    openDropdown() {
      this.openingDropdown = !this.openingDropdown;
    },
    setDirectLink() {
      this.showDirectLink = !this.showDirectLink;
    },
    wholeBarWithColors() {
      return wholeBarWithColor();
    },
    toggleDisplayAssistantsModal() {
      this.openDisplayAssistants = !this.openDisplayAssistants;
    },
    toggleShowHolidaysModal() {
      this.openHolidaysCalendar = !this.openHolidaysCalendar;
    },
    // hasRepresentative(uuid) {
    //   return hasRepresentative(uuid);
    // },
    checkShowAllSection() {
      const showAll = this.state.namespaceSettings.showAllSectionsOrga;
      for (let i = 0; i < showAll.length; i++) {
        const section = showAll[i];
        if (this.sectionData && this.sectionData.name === section) {
          this.showAllSection = true;
          return;
        }
      }
      this.showAllSection = false;
    },
    checkHidedUser() {
      const uuid = this.person.uuid;
      if (this.state.group[uuid] && this.state.group[uuid].user.hidedByAdmin) {
        this.hideShowUser = true;
      } else {
        this.hideShowUser = false;
      }
    },
    closeHelpscoutModal() {
      this.showHelpscout = false;
    },
    isUserInCall(person) {
      if (
        person.connected &&
        person.user &&
        person.user.rtcCallStatus &&
        person.user.rtcCallStatus.length > 5
      ) {
        return true;
      } else {
        return false;
      }
    },
    closeSendMessageModalGrid() {
      this.showSendMessageToAll = false;
    },
    checkSendMessage(uuid) {
      if (uuid === this.ownUUID) {
        // I'm the user, send message to others managers and users (#178)
        this.showSendMessageToAll = true;
      } else {
        // send message to the user
        this.setShowModalSenMsg(uuid);
      }
    },
    goToExternalHomePage() {
      //open in new page
      // let expression = /^http[s]?\:\/\//;
      // let regex = new RegExp(expression);
      // let companyHomePage = this.state.namespaceSettings.companyHomePage;
      // if (!companyHomePage.match(regex)) {
      //   companyHomePage = "https://" + companyHomePage;
      // }
      // window.open(companyHomePage, "_blank");
    },
    closeSetHomePageModal() {
      this.showAddHomePage = false;
    },
    // removeHomePage(){
    //   setNamespaceSetting("homePage", "");
    // },
    // setHomePage() {
    //   // const fullPath = window.location.href;
    //   // const fullPathSplitted = fullPath.split("#");
    //   // const finalPath = fullPathSplitted[0] +'#/' + this.sectionData.type;
    //   const path = "/" + this.sectionData.type;
    //   setNamespaceSetting("homePage", path);
    // },
    goToCalendar() {
      this.setCurrentContentVisile(`calendar/${this.uuid}`, true, this.$router);
    },
    changeShowAllSection() {
      let showAllSectionsOrga = this.state.namespaceSettings
        .showAllSectionsOrga;
      if (this.showAllSection) {
        // push section to array
        showAllSectionsOrga.push(this.sectionData.name);
      } else {
        // remove section from array
        let sectionToRemove = showAllSectionsOrga.indexOf(
          this.sectionData.name
        );
        showAllSectionsOrga.splice(sectionToRemove, 1);
      }
      setNamespaceSetting("showAllSectionsOrga", showAllSectionsOrga);
    },
    changeUserVisibility(uuid) {
      setRemoteUser(uuid, { hidedByAdmin: this.hideShowUser });
    },
    closeRemoveUserModal() {
      this.deleteUser = false;
    },
    callUser(uuid) {
      if (uuid !== this.ownUUID) {
        callPerson(uuid, this.$router);
      }
    },
    changeFunction(person) {
      this.changeUserFunction = {
        show: true,
        person: person
      };
    },
    cancelChangeFunction() {
      this.changeUserFunction = {
        show: false,
        person: undefined
      };
    },
    closeShowInviteInfo() {
      this.showInvitationModal = false;
    },
    async setShowModalSendInvitation(person) {
      /* const result = */ await sendInviteMailCustom(person.uuid, person.user);
      store.state.sendInvitationGuestModal = {
        newInvitation: true,
        invitationData: {
          name: person.user.name,
          email: person.user.emailAlias || person.user.email,
          date: new Date(),
        }
      };
      this.showInvitationModal = true;
      setTimeout(() => {
        if (this.showInvitationModal) {
          this.showInvitationModal = false;
        }
      }, 2000);
    },
    // setUserInModal() {
    //   //open modal if im not guest
    //   if (!this.isGuest) {
    //     store.state.ownModalUser = (this.person.uuid === this.state.ownUUID);
    //     store.state.userOnModal = { ...this.person.user };
    //     store.state.userOnModal.connected = this.person.connected;
    //     store.state.userOnModal.uuid = this.person.uuid;
    //     store.state.userOnModal.userTS = this.person.userTS;
    //     this.toggleUserProfile();
    //   }
    // },
    // toggleUserProfile() {
    //    setUserProfileEvent(true)
    //   // this.showUserProfile = !this.showUserProfile;
    // },
    closeDeleteFavoriteModal() {
      this.deleteFavorite = {
        show: false,
        person: undefined
      };
    },
    setBackgroundByStatus(person) {
      return getStatusColorByUser(person.user, person.connected);
    },
    isFavorite(person) {
      return this.state.persisted.favoriteUsers[this.uuid];
    },
    hasBeenOnPage(person) {
      return person.user.rtcCallStart !== undefined;
    },
    mailBoxFull(person) {
      return ((person || {}).user || {}).unreadMessageCounter > 9;
    },
    amIAdmin(uuid) {
      if (uuid) {
        return store.getUserIsAdmin(uuid);
      }
    },
    personPing: function(person) {
      // store.setLastInteractionWithUuid(this.uuid);
      wsCall("sendToUUID", this.uuid, { type: "ping" });
      sendNotificationToUUID(this.uuid, { type: "ping" });
    },
    setPersonFav: function(person) {
      store.setUuidInFavorites(this.uuid, true);
    },
    removePersonFav: function(person) {
      store.setUuidInFavorites(this.uuid, undefined);
    },
    sendFileTo(personUuid) {
      let uuid = personUuid;
      // const representativeUuid = this.hasRepresentative(personUuid);
      // if (representativeUuid && representativeUuid !== this.state.ownUUID) {
      //   uuid = representativeUuid;
      // }
      return sendFileTo(uuid);
    },
    setShowModalSenMsg(uuid) {
      // return store.setShowModalSenMsg(uuid);
      // setSendMessageModalEvent({show:true, users: [uuid], type: 'regularMsg'})
      setHistoryMessagesModalEvent(uuid)
    },
    // ShowTicketEntry(assignee) {
    //   this.state.currentContentVisile.showComponent = true;
    //   this.state.currentContentVisile.component = "ticketEntry";
    //   this.state.showTicketEntry = true;
    //   this.state.ticket.t_assignee = assignee;
    // },
    personIsGuest(person) {
      if (person && person.user) {
        return this.uuid && store.getUserIsGuest(this.uuid);
      } else {
        return false;
      }
    },
    isInCallWithUs(person) {
      return (
        !!this.state.rtc[this.uuid] && !!this.state.remoteStreams[this.uuid]
      );
    },

    // personCall: function(person) {
    //   // store.setLastInteractionWithUuid(this.uuid);
    //   if (!this.disableCallBtn(person)) {
    //     this.setCurrentContentVisile("", false, this.$router);
    //     callUUID(this.uuid);
    //   }
    // },
    // setShowModalMeetingTime(uuid) {
    //   store.setmeetingStartAt(undefined);
    //   store.setdurationMeeting(undefined);
    //   return store.setShowModalMeetingTime(uuid);
    // },
    disableSendFileBtn(personObj) {
      let person = personObj;
      // const representativeUuid = this.hasRepresentative(personObj.uuid);
      // if (representativeUuid && representativeUuid !== store.state.ownUUID) {
      //everybody can call to the representative and the representative can call the user
      // person = store.state.group[representativeUuid];
      // } else {
      person = personObj;
      // }
      return !!(!person || !person.connected);
    },
    disableCallBtn(personObj) {
      let person = personObj;
      return disableCallBtn(person);
    },
    fullCall() {
      return this.state.isCallFull;
    }
  },

  computed: {
    IsInList() {
      if (this.watchingList.indexOf(this.person.uuid) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    isreachable(){
      let reportingStatus = ["Out of Office","Only phone", "No Calls", "Break", "Holidays"];
      let isReachable = true;
      if(!this.person.connected){
        isReachable = false;
      } else {
        if(reportingStatus.indexOf(this.person.user.activity) !== -1 || this.getCallText(this.person)){
           isReachable = false;
        } else {
          isReachable = true;
        }
      } 
      return isReachable;
    },
    showUploadFile() {
      return this.state.namespaceSettings.featureFileTranfer;
    },
    isBasicView() {
      return basicView();
    },
    hasOnHold() {
      if (Object.keys(store.state.remoteStreams).length == 1) {
        for (let uuid in store.state.remoteStreams) {
          if (store.state.rtc[uuid].onHold) {
            return true;
          }
        }
        return false;
      }
    },
    emailSalutation() {
      return encodeURIComponent(
        this.$t("components.userListItem.emailSalutation")
      );
    },
    getHomePage() {
      return (
        ((this.state.namespaceSettings.organisationSettings || {})[
          this.sectionData.type
        ] || {})[this.sectionData.name] || {}
      ).homepage;
    },
    showVisitPage() {
      try {
        if (
          (
            ((this.state.namespaceSettings.organisationSettings || {})[
              this.sectionData.type
            ] || {})[this.sectionData.name] || {}
          ).homepage
        ) {
          return true;
        }
        return false;
      } catch {
        return false;
      }
    },
    // apartAlreadySetted() {
    //   return false
    //   try {
    //     const currentPath = this.state.namespaceSettings.homePage.split("/");
    //     if (currentPath[1] === this.sectionData.type) {
    //       return true;
    //     }
    //     return false;
    //   } catch {
    //     return false;
    //   }
    // },
    showHelpscoutButton() {
      if (
        this.sectionData &&
        (this.sectionData.type === "sections" ||
          this.sectionData.type === "departments" ||
          this.sectionData.type === "teams")
      ) {
        return true;
      }
      return false;
    },
    showApartsOption() {
      if (
        this.sectionData &&
        (this.sectionData.type === "sections" ||
          this.sectionData.type === "departments" ||
          this.sectionData.type === "teams")
      ) {
        return true;
      }
      return false;
    },
    showSectionOption() {
      if (this.sectionData && this.sectionData.type === "sections") {
        return true;
      }
      return false;
    },
    checkApartSupervisor() {
      try {
        if (this.sectionData.type === "sections") {
          const section = this.state.namespaceSettings.processedOrganisation
            .sections[this.sectionData.name];
          if (section && section.supervisors[0]) {
            if (section.supervisors[0].uuid === this.ownUUID) {
              return true;
            }
          }
        } else if (this.sectionData.type === "departments") {
          const department = this.state.namespaceSettings.processedOrganisation
            .departments[this.sectionData.name];
          if (department && department.supervisors[0]) {
            if (department.supervisors[0].uuid === this.ownUUID) {
              return true;
            }
          }
        } else if (this.sectionData.type === "teams") {
          const team = this.state.namespaceSettings.processedOrganisation.teams[
            this.sectionData.name
          ];
          if (team && team.supervisors[0]) {
            if (team.supervisors[0].uuid === this.ownUUID) {
              return true;
            }
          }
        }
      } catch {}
    },
    checkImSupervisor() {
      try {
        const section = this.state.namespaceSettings.processedOrganisation
          .sections[this.sectionData.name];
        if (section && section.supervisors[0]) {
          if (section.supervisors[0].uuid === this.ownUUID) {
            return true;
          }
        }
        return false;
      } catch {
        return false;
      }
    },
    getRoute() {
      return this.$route.path;
    },
    amIGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    amInACall() {
      return !!Object.keys(this.state.rtc).length;
    },
    canRemoveGuest() {
      const inviters = this.person.user.inviters;
      if (
        this.amIAdmin(this.ownUUID) ||
        (inviters && inviters[this.state.ownUUID]) ||
        this.getRoute === "/guest"
      ) {
        return true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.lockMessageIcon{
  position: absolute;
  right: -6px;
  top: -10px;
}
.backOnLine {
  width: 25px;
}

.rotatedArrow {
  font-size: 16px;
  transform: rotate(45deg);
  margin-left: -2px;
  margin-top: 0px;
}
.height50 {
  height: 50%;
}
</style>