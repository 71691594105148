<template>
  <div>
    <v-list v-if="sidebar && !mini" max-width="320" @click.stop.prevent>
      <v-list-item @click.stop.prevent="openGpt4bot()">
        <!-- :appendIcon="sortedFavoriteUsers.length === 0 ? null : '$expand'" -->
        <template v-slot:prepend>
            <v-icon>
              <font-awesome-icon
                :icon="['fal', 'comments']"
                class="primary--text-sidepanel"
                :style="{fontSize: '20px'}"
              />
            </v-icon>
        </template>
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-list-item-title
                class="primary--text-sidepanel"
                v-bind="props"
              >
                {{ $t("components.sidebar.askYourQuestion") }}
              </v-list-item-title>
            </template>
            <span>{{ $t("components.sidebar.askYourQuestion") }}</span>
          </v-tooltip>
      </v-list-item>
    
    </v-list>
      <v-tooltip right v-if="!isGuest && mini">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" class="cursorPointer">
            <v-icon @click.stop.prevent="openGpt4bot()">
              <font-awesome-icon
                :icon="['fal', 'comments']"
                class="primary--text-sidepanel"
                :style="{fontSize: '20px'}"
              />
            </v-icon>
          </v-list-item>
        </template>
        <span>{{ $t("components.sidebar.askYourQuestion") }}</span>
      </v-tooltip>
  </div>
</template>
<script>
import store, { EventBus } from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import moment from "../../../sharedsrc/moment";

export default {
  props: ["sidebar", "mini"],
  components: {},
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      isMobile: isMobile(),
      moment: moment,
    };
  },
  watch: {
   
  },
  mounted() {
   
  },
  unmounted() {
    
  },
  methods: {
    openGpt4bot() {
      EventBus.$emit("openCloseGpt4bot");
    }
  },
  computed: {
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
  },
};
</script>
<style scoped lang="scss">
</style>
