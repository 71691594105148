// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-mt-2[data-v-7089fd8b] {
  margin-top: -12px;
}
.closeButton[data-v-7089fd8b] {
  border-radius: 4px;
  min-width: 45px;
}
.v-theme--light .v-dialog .v-card[data-v-7089fd8b] {
  background: rgba(255, 255, 255, 0.7);
  word-break: break-word;
}
.v-theme--dark .v-dialog .v-card[data-v-7089fd8b] {
  background: rgba(30, 30, 30, 0.7);
  word-break: break-word;
}
@media screen and (min-width: 993px) {
.v-dialog__content[data-v-7089fd8b] {
    position: absolute !important;
}
}
.colorRed[data-v-7089fd8b] {
  color: red;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/paymentModals/errorPayment.vue","webpack://./errorPayment.vue"],"names":[],"mappings":"AACA;EACE,iBAAA;ACAF;ADEA;EACE,kBAAA;EACA,eAAA;ACCF;ADEE;EACE,oCAAA;EACA,sBAAA;ACCJ;ADGE;EACE,iCAAA;EACA,sBAAA;ACAJ;ADGA;AACE;IACE,6BAAA;ACAF;AACF;ADEA;EACE,UAAA;ACAF","sourcesContent":["\n.-mt-2{\n  margin-top: -12px;\n}\n.closeButton {\n  border-radius: 4px;\n  min-width: 45px;\n}\n.v-theme--light .v-dialog {\n  .v-card {\n    background: rgba(255, 255, 255, 0.7);\n    word-break: break-word;\n  }\n}\n.v-theme--dark .v-dialog {\n  .v-card {\n    background: rgba(30, 30, 30, 0.7);\n    word-break: break-word;\n  }\n}\n@media screen and (min-width: 993px) {\n  .v-dialog__content {\n    position: absolute !important;\n  }\n}\n.colorRed {\n  color: red;\n}\n",".-mt-2 {\n  margin-top: -12px;\n}\n\n.closeButton {\n  border-radius: 4px;\n  min-width: 45px;\n}\n\n.v-theme--light .v-dialog .v-card {\n  background: rgba(255, 255, 255, 0.7);\n  word-break: break-word;\n}\n\n.v-theme--dark .v-dialog .v-card {\n  background: rgba(30, 30, 30, 0.7);\n  word-break: break-word;\n}\n\n@media screen and (min-width: 993px) {\n  .v-dialog__content {\n    position: absolute !important;\n  }\n}\n.colorRed {\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
