// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iconCardsContainerRightCustom[data-v-7016ec40] {
  position: absolute;
  top: 30px;
  right: 0;
  background: unset;
  height: 28px;
}
.iconCardsContainerRight[data-v-7016ec40] {
  position: absolute;
  top: 0;
  right: 0;
  background: unset;
  height: 28px;
}
.iconCardsContainerLeftCustom[data-v-7016ec40] {
  position: absolute;
  top: 30px;
  left: 0;
  background: unset;
  height: 28px;
}
.iconCardsContainerLeft[data-v-7016ec40] {
  position: absolute;
  top: 0;
  left: 0;
  background: unset;
  height: 28px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/buttonsCardWrapper.vue","webpack://./buttonsCardWrapper.vue"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,iBAAA;EACA,YAAA;ACAF;ADEA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,iBAAA;EACA,YAAA;ACCF;ADCA;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,iBAAA;EACA,YAAA;ACEF;ADAA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,iBAAA;EACA,YAAA;ACGF","sourcesContent":["\n.iconCardsContainerRightCustom {\n  position: absolute;\n  top: 30px;\n  right: 0;\n  background: unset;\n  height: 28px;\n}\n.iconCardsContainerRight {\n  position: absolute;\n  top: 0;\n  right: 0;\n  background: unset;\n  height: 28px;\n}\n.iconCardsContainerLeftCustom {\n  position: absolute;\n  top: 30px;\n  left: 0;\n  background: unset;\n  height: 28px;\n}\n.iconCardsContainerLeft {\n  position: absolute;\n  top: 0;\n  left: 0;\n  background: unset;\n  height: 28px;\n}\n",".iconCardsContainerRightCustom {\n  position: absolute;\n  top: 30px;\n  right: 0;\n  background: unset;\n  height: 28px;\n}\n\n.iconCardsContainerRight {\n  position: absolute;\n  top: 0;\n  right: 0;\n  background: unset;\n  height: 28px;\n}\n\n.iconCardsContainerLeftCustom {\n  position: absolute;\n  top: 30px;\n  left: 0;\n  background: unset;\n  height: 28px;\n}\n\n.iconCardsContainerLeft {\n  position: absolute;\n  top: 0;\n  left: 0;\n  background: unset;\n  height: 28px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
