<template>
 <v-dialog
    :model-value="showModal"
    @update:model-value="showModal = $event" persistent max-width="460" @keydown.esc="closeModal">
    <v-card>
      <v-card-title
        class="headline"
      >
        <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
        {{ $t('components.inviteVisitor.removeInviteTitle') }}
      </v-card-title>
      <v-card-text>{{ $t('components.inviteVisitor.removeInviteText') }}</v-card-text>
      <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
        <v-row class="px-2 btns mx-0" flex>
          <v-col cols="8" class="px-0 py-0" align="right">
            <v-btn color="primary" @click="deleteVisitorFunc"
              :loading="loading"
              :disabled="loading"
            
            >{{ $t('generics.accept') }}</v-btn>
            <v-btn               :disabled="loading" color="primary" @click="closeModal">{{ $t('generics.cancel') }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import { deleteUserByUUID, removeUserInviters } from "../../lib/wsMsg";
export default {
  props: ["deleteVisitor", "closeModal", "showModal"],
  components: {},
  data: () => ({
    state: store.state,
    loading: false,
  }),
  methods: {
    deleteVisitorFunc(){
      this.loading = true;
      this.deleteVisitor()
    }
  },
  computed: {}
};
</script>
<style scoped lang="scss">
</style>
