<template>
  <div>
    <span>Store {{name}} -- Count: {{counter}}</span>
    <!-- <v-badge color="green" :content="counter" overlap>Counter</v-badge> -->
    <pre>{{JSON.stringify(lastState, null, 2)}}</pre>
  </div>
</template>

<script>
export default {
  props: ['store'],
  // components: { },
  data() {
    return {
      counter: 0,
      lastState: null,
    };
  },
  methods: {
    storeWatcher(state) {
      this.counter++;
      this.lastState = state;
    },
    subscribe() {
      this.unsubscribe();
      if (!this.store) return;
      this._unsub.push(this.store.watch(this.storeWatcher.bind(this)));
    },
    unsubscribe() {
      if (this._unsub) {
        for (const u of this._unsub) {
          try {
            u();
          } catch (err) { console.warn('unsub error', err); }
        }
      }
      this._unsub = [];
    },
  },
  computed: {
    name() {
      if (!this.store) return '';
      return this.store.shortName;
    }
  },
  mounted() {
    this.subscribe();
  },
  unmounted() {
    this.unsubscribe();
  },
  watch: {
    store() { this.subscribe(); },
  }
};
</script>

<style scoped lang="scss">
pre {
  font-size: 8px;
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>