// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainInputSearch {
  width: calc(100% - 7px);
  margin-top: 8px !important;
  margin-left: 2px !important;
}
.mainInputSearch .v-text-field--solo .v-input__control {
  min-height: 30px !important;
}
.titleGroup .v-icon {
  font-size: 14px !important;
}
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.vCardSearch .itemTitleSearch {
  width: calc(100% - 36px) !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/drawerContentSearch.vue","webpack://./drawerContentSearch.vue"],"names":[],"mappings":"AACA;EACE,uBAAA;EACA,0BAAA;EACA,2BAAA;ACAF;ADGE;EACE,2BAAA;ACAJ;ADIE;EACE,0BAAA;ACDJ;ADKA;EACE,UAAA;ACFF;ADIA;EACE,4BAAA;ACDF;ADIA;EACE,gBAAA;ACDF;ADIE;EACE,mCAAA;ACDJ","sourcesContent":["\n.mainInputSearch {\n  width: calc(100% - 7px);\n  margin-top: 8px !important;\n  margin-left: 2px !important;\n}\n.mainInputSearch {\n  .v-text-field--solo .v-input__control {\n    min-height: 30px !important;\n  }\n}\n.titleGroup {\n  .v-icon {\n    font-size: 14px !important;\n  }\n}\n\n.v-list {\n  padding: 0;\n}\n.v-list-item__icon {\n  margin-right: 8px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}\n.vCardSearch {\n  & .itemTitleSearch {\n    width: calc(100% - 36px) !important;\n  }\n}\n",".mainInputSearch {\n  width: calc(100% - 7px);\n  margin-top: 8px !important;\n  margin-left: 2px !important;\n}\n\n.mainInputSearch .v-text-field--solo .v-input__control {\n  min-height: 30px !important;\n}\n\n.titleGroup .v-icon {\n  font-size: 14px !important;\n}\n\n.v-list {\n  padding: 0;\n}\n\n.v-list-item__icon {\n  margin-right: 8px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}\n\n.vCardSearch .itemTitleSearch {\n  width: calc(100% - 36px) !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
