// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toTheLeft[data-v-0a034236] {
  left: 0px !important;
}
.justPaddingTop[data-v-0a034236] {
  padding: 64px 0px 0px 0px !important;
}
.fontSize17[data-v-0a034236] {
  font-size: 17px !important;
}
.fontSize22[data-v-0a034236] {
  font-size: 22px !important;
}
.title[data-v-0a034236] {
  color: #2a3133;
}
.requestButton[data-v-0a034236] {
  background-color: #2a3133 !important;
  border-color: #2a3133 !important;
}
.linkApp[data-v-0a034236] {
  text-decoration: unset;
  color: unset !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/firstSetup/decryptError.vue","webpack://./decryptError.vue"],"names":[],"mappings":"AACA;EACE,oBAAA;ACAF;ADEA;EACE,oCAAA;ACCF;ADCA;EACE,0BAAA;ACEF;ADAA;EACE,0BAAA;ACGF;ADDA;EACE,cAAA;ACIF;ADFA;EACE,oCAAA;EACA,gCAAA;ACKF;ADHA;EACE,sBAAA;EACA,uBAAA;ACMF","sourcesContent":["\n.toTheLeft{\n  left: 0px !important;\n}\n.justPaddingTop{\n  padding: 64px 0px 0px 0px !important;\n}\n.fontSize17{\n  font-size: 17px !important;\n}\n.fontSize22{\n  font-size: 22px !important;\n}\n.title {\n  color: #2a3133;\n}\n.requestButton {\n  background-color: #2a3133 !important;\n  border-color: #2a3133 !important;\n}\n.linkApp {\n  text-decoration: unset;\n  color: unset !important;\n}\n",".toTheLeft {\n  left: 0px !important;\n}\n\n.justPaddingTop {\n  padding: 64px 0px 0px 0px !important;\n}\n\n.fontSize17 {\n  font-size: 17px !important;\n}\n\n.fontSize22 {\n  font-size: 22px !important;\n}\n\n.title {\n  color: #2a3133;\n}\n\n.requestButton {\n  background-color: #2a3133 !important;\n  border-color: #2a3133 !important;\n}\n\n.linkApp {\n  text-decoration: unset;\n  color: unset !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
