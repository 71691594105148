<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn variant="outlined" small v-on="on" flat color="default" class="ml-2 primaryBorder">
        <v-img
          class="borderRadius10"
          max-height="23"
          max-width="17"
          :src="'img/flags/'+language+'.png'"
        ></v-img>
        <span class="notranslate primary--text">{{language}}</span>
      </v-btn>
    </template>

    <v-list density="compact">
      <v-list-item-group color="primary">
        <v-tooltip v-for="locale in locales" :key="locale.code" bottom>
          <template v-slot:activator="{ on }">
            <v-list-item @click="changeLanguage(locale.code)" v-on="on">
              <v-avatar
                tile
                width="23"
                height="17"
                min-width="23"
                max-height="17"
                max-width="23"
              >
                <v-img :src="'img/flags/'+locale.code+'.png'"></v-img>
              </v-avatar>
              <v-list-item-title class="notranslate primary--text">{{ locale.code }}</v-list-item-title>
            </v-list-item>
          </template>
          <span>{{ locale.name }}</span>
        </v-tooltip>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { messages } from '../../_locales';
export default {
  computed: {
    locales () {
      return Object.keys(messages).map((key) => messages[key]._locale);
    },
    language () {
      return this.$locale.current().toUpperCase();
    },
  },
  methods: {
    changeLanguage (language) {
      this.$locale.change(language.toLowerCase());
    },
  }
}
</script>

<style scoped lang="scss">
.primaryBorder{
  border-color: var(--v-primary-base) !important;
}
</style>