<template >
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="550"
    @keydown.esc="closeModalFunction"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('generics.info')"
        :closeModalFunction="closeModalFunction"
      />
      <v-divider></v-divider>
      <v-row :style="{ width: '100%', margin: 0 }" class="px-5">
        <v-card-text>
          <span>{{$t("components.userManagement.saveDataModal")}}</span>
        </v-card-text>
      </v-row>
      <FooterModal :closeModalFunction="closeModalFunction">
        <v-btn color="primary" @click="closeFunction">{{
          $t("generics.close")
        }}</v-btn>
      </FooterModal>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";

export default {
  props: ["showModal", "closeModal", "acceptCloseModal", "setNewUser"],
  components: { HeaderModal, FooterModal },
  data: () => ({
    state: store.state,
  }),
  methods: {
    closeFunction() {
      this.acceptCloseModal();
      this.closeModal();
    },
    closeModalFunction() {
      if (this.setNewUser) {
        this.setNewUser(undefined);
      }
      this.closeModal();
    },
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>