/* eslint-disable no-undef, no-return-assign */
export default async function (client_id, currencyCode = '', locale = '') {
  const addScript = (to, src, attrs = {}) => {
    const guard = `at_${src}_promise`;
    if (window[guard]) return window[guard];
    return window[guard] = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      Object.keys(attrs).forEach(key => script.setAttribute(key, attrs[key]));
      script.onload = resolve;
      script.onerror = reject;
      to.appendChild(script);
    });
  };
  try {
    const parent = document.documentElement;
    const params = (currencyCode ? `&currency=${currencyCode}` : '') + (locale ? `&locale=${locale}` : '');
    const retVal = await addScript(parent, `https://www.paypal.com/sdk/js?client-id=${client_id}` + params, {});
    document.dispatchEvent(new CustomEvent(`${'client'}-loaded`));
    return retVal;
  } catch (err) {
    document.dispatchEvent(new CustomEvent(`${'client'}-load-error`, {detail: err.message || err}));
    throw err;
  }
}