import { i18n, useI18n } from './i18n';
import { createVuetify } from 'vuetify';
import { createVueI18nAdapter } from 'vuetify/lib/locale/adapters/vue-i18n';
import * as components from 'vuetify/lib/components';
import * as directives from 'vuetify/lib/directives';
import { VCalendar } from 'vuetify/lib/labs/VCalendar';
import { VTimePicker } from 'vuetify/lib/labs/VTimePicker';
import "vuetify/lib/styles/main.css";

// https://github.com/vuetifyjs/vuetify/issues/6755
/*
const VImg = Vue.component('VImg').extend({
  name: 'VImg',
  methods: {
    onError() {
      // console.warn(`Image load failed\n${this.normalisedSrc.src}`);
      this.$emit('error', this.src);
    }
  },
});
Vue.component('VImg', VImg); // Override VImg globally
*/

const opts = {
  components: {
    ...components,
    VCalendar,
    VTimePicker,
  },
  directives,
  icons: {
    iconFont: 'fa'
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        colors: {
          primary: '#2a3133',
          secondary: '#2a3133',
          accent: '#2a3133',
          error: '#ff000b',
          warning: '#ffd000',
          info: '#2a3133',
          success: '#009b1c',
        }
      },
      dark: {
        colors:{
          primary: '#2a3133',
          error: '#ff000b',
        }
      }
    }
  }
};

export default createVuetify(opts);
