<template>
  <TwoFaAdminConfirmation
    v-if="show2FaModal"
    :showModal="show2FaModal"
    :toggle2faAdminConfirmation="toggle2faAdminConfirmation"
  ></TwoFaAdminConfirmation>
  <div v-else>
    <ExcelEditor :fromWhere="'content'" :excelEdited="excelEdited" />
  </div>
</template>
<script>
import store from "../../store";
import TwoFaAdminConfirmation from "../2fa/modals/2faAdminConfirmation.vue";
import ExcelEditor from "../config/excelEditor.vue";
import UserManager from "../config/userManager.vue";

export default {
  components: { TwoFaAdminConfirmation, ExcelEditor, UserManager },
  data() {
    return {
      show2FaModal: true,
      state: store.state,
      setCurrentContentVisile: store.setCurrentContentVisile,
    };
  },
  methods: {
    toggle2faAdminConfirmation(confirmed) {
      if (confirmed) this.show2FaModal = false;
      else this.excelEdited();
    },
    excelEdited() {
      this.setCurrentContentVisile("", false, this.$router);
    },
  },
};
</script>