<template>
  <v-dialog
    :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="590"
    @keydown.esc="closeModal"
  >
    <template v-if="loaded">
      <v-card class="">
        <HeaderModal
          :titleModal="$t('components.paypal.title')"
          :closeModalFunction="closeModal"
        />
        <v-container>
          <v-text-field
            autocomplete="false"
            density="compact"
            variant="outlined"
            :model-value="APIUser" @update:model-value="APIUser = $event"
            :label="$t('components.modalPayPalSettings.APIUser')"
          />
          <v-text-field
            autocomplete="false"
            density="compact"
            variant="outlined"
            :model-value="APIPassword" @update:model-value="APIPassword = $event"
            :label="$t('components.modalPayPalSettings.APIPassword')"
            type="password"
          />
          <!-- :append-icon="`${showPassword ? 'mdi-eye-off' : 'mdi-eye'}`" -->
          <!-- :type="`${showPassword ? 'text' : 'password'}`" -->
          <!-- @click:append="showPass()" -->
          <div class="d-flex justify-space-between align-baseline mb-2">
            <p style="word-break: break-word">
              {{ $t("components.modalPayPalSettings.yourWebHookURL") }}:
              {{ webhookURL }}
            </p>
            <v-btn color="primary" @click="openInfoPaypalSettings()"
              >{{ $t("generics.info") }}
            </v-btn>
          </div>
          <v-text-field
            autocomplete="false"
            density="compact"
            variant="outlined"
            :model-value="APISignature" @update:model-value="APISignature = $event"
            :label="$t('components.modalPayPalSettings.APISignature')"
            style="height: 50px"
            hide-details
          />
          <div>
            <p class="my-0">
              <a
                href="https://helpcenter.voffice.pro/paypalsetup"
                target="_blank"
                >{{ $t("components.modalPayPalSettings.helpLink") }}</a
              >
            </p>
            <p class="my-0">
              <a
                href="https://developer.paypal.com/developer/applications/"
                target="_blank"
                >{{ $t("components.modalPayPalSettings.developerLink") }}</a
              >
            </p>
          </div>
        </v-container>
        <FooterModal :closeModalFunction="closeModal">
          <!-- <v-btn @click="setPaypalInfoModal()" class="mr-3" color="primary">{{
            $t("generics.info")
          }}</v-btn> -->
          <v-btn
            color="primary"
            @click="savePayPalSettings"
            :loading="savingPayPalSettings"
            :disabled="!paypalSettingsFilled"
            >{{ $t("generics.save") }}</v-btn
          >
        </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { dispatchErrorAlert } from "../../../effector/alerts";
import store from "../../../store";
import HeaderModal from "../../modal/modalComponents/headerModal.vue";
import FooterModal from "../../modal/modalComponents/footerModal.vue";
import { getPayPalSettings, setPayPalSettings } from "../../../lib/wsMsg";
// import { setPaypalInfoModalEvent } from "../../../effector/modals";

export default {
  props: ["showModal", "closeModal"],
  components: {
    HeaderModal,
    FooterModal,
  },
  data() {
    return {
      state: store.state,
      loaded: false,
      APIUser: "",
      APIPassword: "",
      APISignature: "",
      webhookURL: "",
      showPassword: false,
      savingPayPalSettings: false,
    };
  },
  mounted() {},
  unmounted() {},
  watch: {
    showModal: {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.loadData();
        } else {
          this.APIUser = "";
          this.APIPassword = "";
          this.APISignature = "";
          this.webhookURL = "";
          this.loaded = false;
        }
      },
    },
  },
  methods: {
    // setPaypalInfoModal() {
    //   setPaypalInfoModalEvent(true);
    // },
    openInfoPaypalSettings() {
      const data = {
        show: true,
        header: this.$t("generics.info"),
        body: this.$t("components.paypal.infoSettingsPaypal"),
      };
      return store.setinfoModal(data);
    },
    showPass() {
      this.showPassword = !this.showPassword;
    },
    async loadData() {
      let settings = await getPayPalSettings().catch((err) => {
        dispatchErrorAlert(err.message);
        console.warn("getPayPalSettings", err.stack);
      });
      if (!settings) settings = {};
      this.APIUser = settings.APIUser || "";
      this.APIPassword = settings.APIPassword || "";
      this.APISignature = settings.APISignature || "";
      this.webhookURL = settings.webhookURL || `${window.location.protocol}//${window.location.hostname}/api/paypal/webhook`;
      this.loaded = true;
    },
    async savePayPalSettings() {
      // console.log("Save paypal settins");
      if (this.paypalSettingsFilled) {
        const payPalObj = {
          APIUser: this.APIUser,
          APIPassword: this.APIPassword,
          APISignature: this.APISignature,
        };
        this.savingPayPalSettings = true;
        let error = false;
        await setPayPalSettings(payPalObj).catch((err) => {
          error = true;
          dispatchErrorAlert(
            this.$t("components.modalPayPalSettings.errorCredentials")
          );
          console.warn("setPayPalSettings", err.stack);
        });
        this.savingPayPalSettings = false;
        if (!error) this.closeModal();
      }
    },
  },
  computed: {
    paypalSettingsFilled() {
      return this.APIUser != "" && this.APIPassword != "";
    },
  },
};
</script>

<style scoped lang="scss">
</style>
