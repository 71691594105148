import { representativeDomain } from './domain';

import { setImpersonatingUserUUID, setConferenceForRepresentive, removeConferenceForRepresentive, setVisitorForRepresentive } from '../../lib/wsMsg';

export const setRepresentativeUserUUIDEffect = representativeDomain.createEffect({
  handler: setImpersonatingUserUUID,
  name: 'setRepresentativeUserUUID',
});

export const setConferenceForRepresentiveEffect = representativeDomain.createEffect({
  handler: setConferenceForRepresentive,
  name: 'setConferenceForRepresentive',
});

export const removeConferenceForRepresentiveEffect = representativeDomain.createEffect({
  handler: removeConferenceForRepresentive,
  name: 'removeConferenceForRepresentive',
});

export const setVisitorForRepresentiveEffect = representativeDomain.createEffect({
  handler: setVisitorForRepresentive,
  name: 'setVisitorForRepresentive',
});
