<template>
  <div style="padding:2px;">
    <v-tooltip location="top">
      <template v-slot:activator="{ on }">
        <v-btn width="20" height="20" v-on="on" icon :class="getSizeImage == 'rectangle' ? 'speechLiveIconWhite': 'speechLiveIcon'"  @click.stop.prevent="openSpeechLive" :loading="loadingSpeechLive" :disabled="loadingSpeechLive">
          <v-img v-if="getSizeImage == 'rectangle'" src="img/speechlive.svg" height="20" width="20" contain />
          <v-img v-else src="img/speechlive.svg" height="20" width="20" contain />
        </v-btn>
      </template>
      <span>SpeechLive</span>
    </v-tooltip>
  </div>
</template>
<script>
import store from "../../store";
import { loginSpeechLive } from '../../lib/wsMsg';
export default {
  components: {},
  props: ["person"],
  data() {
    return {
      state: store.state,
      loadingSpeechLive: false,
    };
  },
  methods: {
    async openSpeechLive(){
      try {
        this.loadingSpeechLive = true
        const result = await loginSpeechLive(this.person.uuid);
        window.open(result[0].LoginUrl, "_blank");
      } catch (err) {
        console.warn('openSpeechLive', err.stack);
      } finally {
        this.loadingSpeechLive = false
      }
    }
  },
  computed: {
    getSizeImage(){
      return this.state.namespaceSettings.pictureSize
    }
  },
};
</script>
<style scoped lang="scss">
.speechLiveIconWhite.v-theme--light.v-btn.v-btn--icon{
  color: black!important;
}

.speechLiveIcon {
  position: relative;
  background-color: #c1c1c1 !important;
}
.speechLiveIconWhite{
  position: relative;
  background-color: #c1c1c1 !important;
}
</style>