import { presenceDomain } from './domain';

import { getPresenceWatching, createPresenceWatchingEntry, messageWatchedUsers  } from '../../lib/wsMsg';

export const getPresenceWatchingEffect = presenceDomain.createEffect({
  handler: getPresenceWatching,
  name: 'getPresenceWatchingEffect',
});

export const createPresenceWatchingEntryEffect = presenceDomain.createEffect({
  handler: createPresenceWatchingEntry,
  name: 'createPresenceWatchingEntryEffect',
});

export const messageWatchedUsersEffect = presenceDomain.createEffect({
  handler: messageWatchedUsers,
  name: 'messageWatchedUsersEffect',
});
