<template>
 <v-dialog
    :model-value="showModal"
    @update:model-value="showModal = $event" persistent max-width="650" @keydown.esc="closeModal">
    <v-card>
      <HeaderModal
        :titleModal="$t('components.conferenceForm.titleModalPDF')"
        :closeModalFunction="closeModal"
      />
      <v-row class="mx-0">
        <v-col cols="12">
          <v-list v-if="existingDocuments">
            <v-list-item v-for="document in existingDocuments.documents" :key="document.documentId">
                <v-list-item-title>
                  <a :href="document.url" target="_blank">
                    {{
                    document.name
                    }}
                  </a>
                </v-list-item-title>
                <template v-slot:append>
                  <v-btn icon variant="text">
                    <DeleteBasket
                      :indexData="document"
                      :delFunction="deleteDocumentBtn"
                      extraClasses
                      fontSize="17"
                      prevent="false"
                    />
                  </v-btn>
                </template>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <v-row class="mx-0" v-if="showAddNewDocument">
        <v-col cols="6" class="py-0">
          <v-text-field
            hide-details
            :model-value="documentName" @update:model-value="documentName = $event"
            density="compact"
            variant="outlined"
            solo
            :label="$t('components.conferenceForm.pdfSubject')"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-text-field hide-details :model-value="documentURL" @update:model-value="documentURL = $event" density="compact" variant="outlined" solo :label="'URL'"></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mx-0" v-if="amIModerator">
        <v-col cols="12" class="textRight mb-4">
          <v-tooltip location="top" v-if="showAddNewDocument">
            <template v-slot:activator="{ props }">
              <v-btn
                flat
                v-bind="props"
                color="primary"
                @click="saveNewDocument"
                :disabled="documentName == '' || documentURL == ''"
              >{{ $t("generics.save") }}</v-btn>
            </template>
            <span>{{ $t("generics.save") }}</span>
          </v-tooltip>

          <v-tooltip location="top" v-if="!showAddNewDocument">
            <template v-slot:activator="{ props }">
              <v-btn
                flat
                v-bind="props"
                color="primary"
                @click="showAddNewDocument = true"
              >{{ $t("components.conferenceForm.addpdfConference") }}</v-btn>
            </template>
            <span>{{ $t("components.conferenceForm.addpdfConference") }}</span>
          </v-tooltip>
          <v-tooltip location="top" v-if="showAddNewDocument">
            <template v-slot:activator="{ props }">
              <v-btn
                flat
                class="ml-2"
                v-bind="props"
                color="primary"
                variant="outlined"
                @click="showAddNewDocument = false"
              >{{ $t("generics.cancel") }}</v-btn>
            </template>
            <span>{{ $t("generics.cancel") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <FooterModal :closeModalFunction="closeModal" />
    </v-card>
    <v-spacer></v-spacer>
  </v-dialog>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import {
  dispatchSuccessAlert,
  dispatchErrorAlert
} from "../../effector/alerts";
import { resetConferenceDocumentsEvent } from "../../effector/modals";
import { updateCompanyDocumentEvent } from "../../effector/companyDocuments";
import { timelineEvents } from "../../effector/timeline";
import { myConferenceDetails } from "../../effector/groupConferences";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import DeleteBasket from "../ui/deleteBasket.vue";
import { v4 as uuidv4 } from "uuid";
export default {
  props: ["showModal", "amIModerator", "documentsStore", "setTempDocStore", "isConferenceCall"],
  components: {
    HeaderModal,
    FooterModal,
    DeleteBasket
  },
  data() {
    const effector = {
      cacheTimeline: timelineEvents,
      myConferences: myConferenceDetails
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      showAddNewDocument: false,
      documentName: "",
      documentURL: "",
      tempDocumentStore: null,
      // Effector
      ...effector,
    };
  },
  computed: {
    existingDocuments(){
      if (this.documentsStore){
        return this.documentsStore;
      } else if (this.tempDocumentStore && this.tempDocumentStore.documents){
        return this.tempDocumentStore;
      }
    },
    getDocuments() {
      return this.documentsStore;
    }
  },

  methods: {
    fixDocPath(url){
     return (url.indexOf('://') === -1) ? 'http://' + url : url; 
    },
    isTemporary(store) {
      return (!store.confId && !store.templateId && !store.timelineId && !store.callUUID);
    },
    closeModal() {
      resetConferenceDocumentsEvent();
    },
    async deleteDocumentBtn(documentToDelete) {
      let docsTosave = this.documentsStore.documents;
      if (this.documentsStore && this.documentsStore.docStoreUUID) {
        docsTosave = this.documentsStore.documents.filter(
          e => e.url !== documentToDelete.url
        );
      }
      if (this.documentsStore.documents.length !== docsTosave.length) {
        this.documentsStore.documents = docsTosave;
        if (!this.isTemporary(this.documentsStore)){
          await updateCompanyDocumentEvent(this.documentsStore);
        } else {
           this.setTempDocStore(this.documentsStore); 
        }
        
        this.cleanForm();
        dispatchSuccessAlert(this.$t("generics.documentDeleted"));
      }
    },

    isValidURL(s) {
      return s.match(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g
      );
    },
    async saveNewDocument() {
      if (!this.isValidURL(this.documentURL)) {
        dispatchErrorAlert(this.$t("generics.invalidUrl"));
        return;
      } else if (!this.documentName || this.documentName.trim().length < 1){ 
         dispatchErrorAlert(this.$t('generics.nameRequired'));
        return;
      }
      const newDocument = {
        name: this.documentName,
        url: this.fixDocPath(this.documentURL)
      };
      // console.log('url ', newDocument.url);
      if (this.documentsStore && this.documentsStore.docStoreUUID && !this.isTemporary(this.documentsStore)) {
        this.documentsStore.documents.push(newDocument);
        await updateCompanyDocumentEvent(this.documentsStore);
        dispatchSuccessAlert(this.$t("generics.documentsaved"));
        this.cleanForm();
      } else {
        this.createTempDocStore(newDocument);
      }
    },
    async createTempDocStore(newDocument) {
      if (!this.documentsStore){
        const entry = {
        docStoreUUID: uuidv4(),
        docMembersUUIDS: [store.state.ownUUID],
        moderators: [store.state.ownUUID],
        creator: store.state.ownUUID,
        callUUID: false,
        confId: false,
        timelineId: false,
        templateId: false,
        documents: [newDocument]
        };
        this.setTempDocStore(entry);
      } else if (this.isTemporary(this.documentsStore)){
        this.documentsStore.documents.push(newDocument);
      }

      this.cleanForm();  
    },

    cleanForm() {
      this.documentName = "";
      this.documentURL = "";
      this.showAddNewDocument = false;
      this.selectedDocument = null;
    }
  },
};
</script>

<style scoped lang="scss">
.textRight {
  text-align: right;
}
</style>
