import { uploadAvatarModalDomain } from './domain';

import { setUploadAvatarModalEvent, resetUploadAvatarModalEvent} from './events';

export const uploadAvatarModalStore = uploadAvatarModalDomain.createStore(false, { name: 'uploadAvatarModal' });

uploadAvatarModalStore.on(setUploadAvatarModalEvent, (state, payload) => {
  return payload;
});

uploadAvatarModalStore.reset(resetUploadAvatarModalEvent);
