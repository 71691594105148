import { paypalInfoDomain } from './domain';

import { setPaypalInfoModalEvent, resetPaypalInfoModalEvent} from './events';

export const paypalInfoModalStore = paypalInfoDomain.createStore(false, { name: ' paypalInfoModal' });

paypalInfoModalStore.on(setPaypalInfoModalEvent, (state, payload) => {
  return payload;
});

paypalInfoModalStore.reset(resetPaypalInfoModalEvent);
