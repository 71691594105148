import { canJoinConferenceDomain } from './domain';

import { setCanJoinConferenceEvent, resetCanJoinConferenceEvent } from './events';

export const canJoinConferenceStore = canJoinConferenceDomain.createStore(false, { name: 'canJoinConference' });

canJoinConferenceStore.on(setCanJoinConferenceEvent, (state, payload) => {
  return payload;
});

canJoinConferenceStore.reset(resetCanJoinConferenceEvent);
