<template>
  <div>
    <span>Event {{name}} Count: {{counter}}</span>
    <pre>{{JSON.stringify(lastPayload, null, 2)}}</pre>
  </div>
</template>

<script>
export default {
  props: ['event'],
  // components: { },
  data() {
    return {
      counter: 0,
      lastPayload: null,
    };
  },
  methods: {
    eventWatcher(payload) {
      this.counter++;
      this.lastPayload = payload;
    },
    subscribe() {
      this.unsubscribe();
      if (!this.event) return;
      this._unsub.push(this.event.watch(this.eventWatcher.bind(this)));
    },
    unsubscribe() {
      if (this._unsub) {
        for (const u of this._unsub) {
          try {
            u();
          } catch (err) { console.warn('unsub error', err); }
        }
      }
      this._unsub = [];
    },
  },
  computed: {
    name() {
      if (!this.event) return '';
      return this.event.shortName;
    }
  },
  mounted() {
    this.subscribe();
  },
  unmounted() {
    this.unsubscribe();
  },
  watch: {
    event() { this.subscribe(); },
  }
};
</script>

<style scoped lang="scss">
pre {
  font-size: 8px;
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>