// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paypal-button {
  margin-right: 15px;
}
.paypal-button .paypal-buttons {
  height: 35px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/paymentModals/payPalButton.vue","webpack://./payPalButton.vue"],"names":[],"mappings":"AACA;EAIE,kBAAA;ACHF;ADKE;EACE,uBAAA;ACHJ","sourcesContent":["\n.paypal-button{\n  // vertical-align: middle;\n  // width: 43%;\n  // display: inline-block;\n  margin-right: 15px;\n  // height: 35px;\n  .paypal-buttons{\n    height: 35px !important;\n  }\n}\n",".paypal-button {\n  margin-right: 15px;\n}\n.paypal-button .paypal-buttons {\n  height: 35px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
