 <template>
  <div>
    <div>{{ conference.confName }}</div>
    <div>{{ getNameModerator }}</div>
    <div>
      <span> {{ $t("components.remotePersonCall.attendees") }}: </span>
      <div>
        {{ getAllUsers }}
      </div>
    </div>
  </div>
</template>
<script>
import store from "../../store";
export default {
  props: ["conference"],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
    };
  },

  methods: {
    getNameByUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelByUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
  },
  computed: {
    getNameModerator() {
      const moderatorName =
        this.$t("components.remotePersonCall.moderator") +
        ": " +
        this.getTitelByUuid(this.conference.confOwner) +
        " " +
        this.getNameByUuid(this.conference.confOwner);
      return moderatorName;
    },
    getAllUsers() {
      const users = this.conference.members || [];
      if (users.length > 0) {
        const participants = users
          .filter((user) => this.ownUUID !== user.uuid)
          .map((user) => {
            return store.state.group[user.uuid]
              ? this.getTitelByUuid(user.uuid) + " " + this.getNameByUuid(user.uuid) ||
                  "Anonymous"
              : "";
          })
          .join(", ");
        return participants;
      }
      return false;
    },
  },
};
</script>
<style scoped lang="scss">
</style>