// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.todayEventsBadge .v-badge__badge {
  color: black !important;
  font-family: fantasy;
  font-weight: bold;
}
.todayEventsBadge .v-badge__badge:after {
  border-color: transparent !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/meetingLine/meetingLineEffector.vue","webpack://./meetingLineEffector.vue"],"names":[],"mappings":"AAEE;EACE,uBAAA;EACA,oBAAA;EACA,iBAAA;ACDJ;ADGG;EACC,oCAAA;ACDJ","sourcesContent":["\n.todayEventsBadge {\n  & .v-badge__badge{\n    color: black !important;\n    font-family: fantasy;\n    font-weight: bold;\n  }\n   & .v-badge__badge:after{\n    border-color: transparent !important;\n  }\n}\n",".todayEventsBadge .v-badge__badge {\n  color: black !important;\n  font-family: fantasy;\n  font-weight: bold;\n}\n.todayEventsBadge .v-badge__badge:after {\n  border-color: transparent !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
