
import {
  newConferenceHallEvent,
  updateConferenceHallEvent,
  dispatchFetchMyConferenceHallsEvent,
  dispatchDeleteConferenceHallEvent,
  dispatchFetchConferenceHallInfosEvent,
  setConferenceHallsInfoBatchedEvent,
  setConferenceHallUUIDsEvent,
  getMyConferenceDetailsEvent,
  setConferenceHallDetailEvent,

} from './events';

import {
  fetchMyConferenceHallsEffect,
  getConferenceHallEntryByUUIDEffect,
  createConferenceHallEntryEffect,
  updateConferenceHallEffect,
  deleteConferenceHallEntryEffect,
  getMyConferencesHallDetailEffect,
} from './effects';

import { parallel } from '../../lib/asyncUtil';
import { wsConnectedEvent } from '../ws';

let hasUserEntry = false;
let tempConfId = null;

// TODO: Remove from here put in the actual component for messages
wsConnectedEvent.watch(() => {
  dispatchFetchMyConferenceHallsEvent();
  getMyConferenceDetailsEvent()
});

dispatchFetchMyConferenceHallsEvent.watch((payload) => {
  fetchMyConferenceHallsEffect();
});

fetchMyConferenceHallsEffect.done.watch(({ params, result }) => {
  if (result && result.length) {
    hasUserEntry = true;
    tempConfId = result[0].confId;
  }
  setConferenceHallUUIDsEvent(result);
});

getMyConferenceDetailsEvent.watch((payload) => {
  getMyConferencesHallDetailEffect();
});

getMyConferencesHallDetailEffect.done.watch(({ params, result }) => {
  if (hasUserEntry && !result.length) {
    getConferenceHallEntryByUUIDEffect(tempConfId).then(function (value) {
      setConferenceHallDetailEvent([value]);
    }, function (reason) {
    });
  } else {
    setConferenceHallDetailEvent(result);
  }

});

newConferenceHallEvent.watch((payload) => {
  createConferenceHallEntryEffect(payload);
});

updateConferenceHallEvent.watch((payload) => {
  updateConferenceHallEffect(payload);
});

updateConferenceHallEffect.done.watch(({ params, result }) => {
  fetchMyConferenceHallsEffect();
});

dispatchFetchConferenceHallInfosEvent.watch(async (confIds) => {
  if (!confIds.length) return; // Nope
  if (getConferenceHallEntryByUUIDEffect.pending.getState()) {
    // No need to fetch while fetching, new fetched things will trigger an update which will then fetch whatever new thing could be missing
    return;
  }
  const acc = {};
  await parallel(10, [...confIds], async (confId) => {
    const info = await getConferenceHallEntryByUUIDEffect(confId);
    if (typeof info !== 'undefined' && info !== null) {
      acc[confId] = info;
    }
  });
  const notEmpty = Object.keys(acc).length;
  // console.log('dispatchFetchConferenceHallInfosEvent Fetched', notEmpty, acc);
  if (notEmpty) setConferenceHallsInfoBatchedEvent(acc);
});

dispatchDeleteConferenceHallEvent.watch((confId) => {
  const params = [confId];
  deleteConferenceHallEntryEffect(params);
});
