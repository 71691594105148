<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="560px"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('generics.support')"
        :closeModalFunction="closeModal"
      />
      <v-divider></v-divider>
      <v-form
        ref="messageForm"
        :model-value="valid" @update:model-value="valid = $event"
        lazy-validation
        :autocomplete="false"
      >
        <v-row
          :style="{ width: '100%', margin: 0 }"
          class="d-flex flex-column px-5 my-4"
        >
        <v-row>
            <v-col cols="6">
              <v-combobox
                :model-value="selectProblem" @update:model-value="selectProblem = $event"
                :items="itemsProblems"
                :label="$t('components.bugReport.vofficeProblem')"
                variant="outlined"
                :rules="rules"
                density="compact"
                hide-details
              ></v-combobox>
            </v-col>
            <v-col cols="6">
              <v-combobox
                hide-details
                :model-value="selectBrowser" @update:model-value="selectBrowser = $event"
                :items="itemsBrowser"
                :label="$t('components.bugReport.browser')"
                variant="outlined"
                :rules="rules"
                density="compact"
                class=""
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="6">
              <v-combobox
                hide-details
                :model-value="selectSystem" @update:model-value="selectSystem = $event"
                :items="itemsSystem"
                :label="$t('components.bugReport.system')"
                variant="outlined"
                :rules="rules"
                density="compact"
              ></v-combobox>
            </v-col>
            <v-col cols="6">
              <v-combobox
                :rules="rules"
                hide-details
                :model-value="selectNetwork" @update:model-value="selectNetwork = $event"
                :items="itemsNetwork"
                :label="$t('components.bugReport.network')"
                variant="outlined"
                density="compact"
                class=""
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="6" class="">
              <v-text-field
                counter
                variant="outlined"
                :label="$t('components.bugReport.location')"
                :placeholder="'Office, Home Office'"
                :rules="rules"
                maxlength="100"
                :model-value="location" @update:model-value="location = $event"
              ></v-text-field>
            </v-col>
            <v-col cols="6">               
              <v-text-field
                counter
                variant="outlined"
                :label="$t('components.bugReport.subject')"
                :rules="rules"
                maxlength="100"
                :model-value="bugTitle" @update:model-value="bugTitle = $event"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="d-flex py-0">
              <v-textarea
                counter
                variant="outlined"
                contenteditable="true"
                :label="$t('components.bugReport.body')"
                :model-value="bugText" @update:model-value="bugText = $event"
                :no-resize="true"
                maxlength="5000"
                id="bugTextarea"
                @keydown.esc="closeModal"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-row>
        <FooterModal :closeModalFunction="closeModal" :showCancel="true">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="sendBug()"
            :disabled="disabledSendBug"
            >{{ $t("components.sendMessageModal.send") }}</v-btn
          >
        </FooterModal>
      </v-form>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import DialogDrag from "vue-dialog-drag";
import { createGitLabIssue } from "../../lib/wsMsg";
import {
  sendBugModalStore,
  resetSendBugModalEvent,
} from "../../effector/modals";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";

export default {
  components: { HeaderModal, FooterModal, DialogDrag },
  data() {
    const effector = {
      showModal: sendBugModalStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      setCurrentContentVisile: store.setCurrentContentVisile,
      rules: [(v) => !!(v || "").trim() || ""],
      bugText: "",
      bugTitle: "",
      selectProblem: "",
      selectBrowser: "",
      selectSystem: "",
      selectNetwork: "",
      location: "",
      itemsProblems: ['vOffice App', 'vOffice Call'],
      itemsBrowser: ['Chrome', 'Edge', 'Firefox', 'Safari', 'Andere'],
      itemsSystem: ['Windows', 'macOS', 'Linux', 'iOS', 'Android'],
      itemsNetwork: ['Kabel' , 'WLAN', 'Hotspot'],
      valid: true,
      // Effector
      ...effector,
    }
  },
  watch: {},
  mounted() {
    this.loadDataFromLocalstorage();
  },
  methods: {
    closeModal() {
      this.closeModalMsg();
      this.bugText = "";
      this.bugTitle = "";
      this.selectProblem = "";
      this.selectBrowser = "";
      this.selectSystem = "";
      this.selectNetwork = "";
      this.location = "";
    },
    sendBug() {
      createGitLabIssue(this.bugTitle, this.bugText, this.selectProblem, this.selectBrowser, this.selectSystem, this.selectNetwork, this.location);
      this.saveDataInToLocalstorage();
    },
    closeModalMsg() {
      resetSendBugModalEvent();
    },
    loadDataFromLocalstorage(){
      const localStorageItem = localStorage.getItem('bugModalSelections');
      if (!localStorageItem) return;
      const bugDataSelections = JSON.parse(localStorageItem);
      if (!bugDataSelections) return;
      this.selectProblem = bugDataSelections?.selectProblem || "";
      this.selectBrowser = bugDataSelections?.selectBrowser || "";
      this.selectSystem = bugDataSelections?.selectSystem || "";
      this.selectNetwork = bugDataSelections?.selectNetwork || "";
      this.location = bugDataSelections?.location || "";
    },
    saveDataInToLocalstorage(){
      const bugDataSelections = {
        selectProblem: this.selectProblem,
        selectBrowser: this.selectBrowser,
        selectSystem: this.selectSystem,
        selectNetwork: this.selectNetwork,
        location: this.location,
      };
      localStorage.setItem('bugModalSelections', JSON.stringify(bugDataSelections));
      this.closeModal();
    }
  },
  computed: {
    disabledSendBug(){
      return !this.bugTitle ||
        this.bugTitle.trim().length == 0 ||
        !this.location ||
        this.location.trim().length == 0 ||
        !this.selectProblem ||
        this.selectProblem.trim().length == 0 ||
        !this.selectBrowser ||
        this.selectBrowser.trim().length == 0 ||
        !this.selectSystem ||
        this.selectSystem.trim().length == 0 ||
        !this.selectNetwork ||
        this.selectNetwork.trim().length == 0
    }
  },
};
</script>
<style scoped lang="scss">
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
</style>