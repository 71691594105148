// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frontButtons[data-v-51117596] {
  position: absolute;
  right: 1px;
  bottom: 6px;
  padding: 2px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/buttonsFrontCard.vue","webpack://./buttonsFrontCard.vue"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;ACAF","sourcesContent":["\n.frontButtons {\n  position: absolute;\n  right: 1px;\n  bottom: 6px;\n  padding: 2px;\n}\n",".frontButtons {\n  position: absolute;\n  right: 1px;\n  bottom: 6px;\n  padding: 2px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
