// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.msgCardDark a {
  color: white !important;
}
.messageBorders {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/messageHistoryEffector.vue","webpack://./messageHistoryEffector.vue"],"names":[],"mappings":"AACA;EACE,uBAAA;ACAF;ADEA;EACE,+GAAA;EACA,6BAAA;ACCF","sourcesContent":["\n.msgCardDark a {\n  color: white!important;\n}\n.messageBorders{\n  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);\n  border-radius: 4px !important;\n}\n",".msgCardDark a {\n  color: white !important;\n}\n\n.messageBorders {\n  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);\n  border-radius: 4px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
