<template>
<!-- ${isListenerSpeakerConferenceHall? 'listenerSpeakerBarConferenceHall': null} -->
  <div
    :class="`topbar ${uuid !== state.ownUUID ? 'remoteTopBar' : null} `"
    @mousemove="
      moveMouserCardRemote && participantId
        ? moveMouserCardRemote(participantId)
        : false
    "
  >
    <div class="userIconInfo" v-if="showInfoIcons">
      <span class="userIconTile font-weight-light d-flex align-center">
        <font-awesome-icon
          v-if="amImoderator"
          class="mr-2"
          :icon="['fal', 'users-crown']"
          :style="{ fontSize: '20px' }"
        />
        <font-awesome-icon
          v-if="!isGuest && !amImoderator"
          class="mr-2"
          :icon="['fal', 'user']"
          :style="{ fontSize: '20px' }"
        />
        <font-awesome-icon
          v-if="isGuest"
          class="mr-2"
          :icon="['fal', 'user-clock']"
          :style="{ fontSize: '20px' }"
        />
      </span>
    </div>
    <v-tooltip location="top" :disabled="disableParticipantTT">
      <template v-slot:activator="{ props }">
        <v-card-subtitle
          v-bind="props"
          style="color: #fff !important"
          class="callerName font-weight-light pt-0 pb-0 px-0 text-truncate"
          :class="{ 'font-weight-bold': showHand }"
        >
          {{ titelPerson() }} {{ getName() }}
        </v-card-subtitle>
      </template>
      <span>
        {{ titelPerson() }} {{ getName() }}
      </span>
      <template v-if="getLocation()">
        <br />
        <span>
          {{getLocation()}}
        </span>
      </template>
      <template v-if="getDescription()">
        <br />
        <span>
          {{getDescription()}}
        </span>
      </template>
      <!-- <template v-if="requestToSpeakTimestamp">
        <br />
        <span>{{requestToSpeakTimestamp}}</span>
      </template> -->
    </v-tooltip>
    <div class="rightSideBar">
      <font-awesome-icon
        color="white"
        :icon="['fal', 'hand-point-up']"
        class="iconHand ml-2 mr-2"
        :style="{ fontSize: '18px' }"
        v-if="showHand"
      />
      <font-awesome-icon
        color="red"
        :icon="['fas', 'circle']"
        class="iconHand ml-2"
        :style="{ fontSize: '12px' }"
        @click="toggleMessage"
        v-if="(isOnlyOneCallUser || amIGuest) && unseeMessageOneCallUser"
      />
      <v-tooltip bottom v-if="showInfoIcons">
        <template v-slot:activator="{ props }">
          <span
            v-bind="props"
            class="userIconTile font-weight-light d-flex align-center"
          >
            <v-img
              v-if="room && room.isE2EEEnabled()"
              lazy-src="img/icons/e2e-lock-alt-white.svg
            "
              src="img/icons/e2e-lock-alt-white.svg
            "
              max-width="20px"
              width="20px"
            />

            <v-img
              v-else
              lazy-src="img/icons/vo_e2e-lock-white.svg
            "
              src="img/icons/vo_e2e-lock-white.svg
            "
              max-width="20px"
              width="20px"
            />
          </span>
          <!-- </v-btn> -->
        </template>
        <span>{{
          room && room.isE2EEEnabled()
            ? $t("components.callsContent.e2eEnableTT")
            : $t("components.callsContent.e2eDisableTT")
        }}</span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import { useStore } from "effector-vue/composition";
import store from "../../../store";
import { messagesToMe } from "../../../effector/message";
import {
  setHistoryMessagesModalEvent,
} from "../../../effector/modals";
export default {
  components: {},
  props: [
    "uuid",
    "amImoderator",
    "currentDesktop",
    "presenterMode",
    "showHand",
    "moveMouserCardRemote",
    "hideRemoteBar",
    "participantId",
    "getActiveUsers",
    "room",
    "isOnlyOneCallUser",
    // "showHandRequesToSpeakConferenceHall",
    // "isListenerSpeakerConferenceHall",
    "getIsParticipantIsPodium",
    "getIsConferenceHall",
    // "requestToSpeakTimestamp"
  ],
  data() {
    const effector = {
      messagesToMe: messagesToMe,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      // Effector
      ...effector,
    };
  },
  methods: {
     toggleMessage() {
      if (this.uuid) {
        setHistoryMessagesModalEvent(this.uuid);
      }
    },
    titelPerson() {
      if (!this.uuid) return "";
      return store.getTitelForUuid(this.uuid);
    },
    getName() {
      if (!this.uuid || !store.state.group[this.uuid]) return "";
      return store.state.group[this.uuid].user.name;
    },
    getDescription() {
      if (!this.uuid || !store.state.group[this.uuid]) return "";
      return store.state.group[this.uuid].user.description;
    },
    getLocation() {
      if (!this.uuid || !store.state.group[this.uuid]) return "";
      return store.state.group[this.uuid].user.location;
    },
  },
  computed: {
    disableParticipantTT(){
      const fullNameLength = this.titelPerson.length + this.getName.length;
      if (!this.getIsConferenceHall || (this.getIsConferenceHall && this.getIsParticipantIsPodium)) return true;
      if (this.getIsConferenceHall && !this.getIsParticipantIsPodium && fullNameLength > 14) return false;
      return false;
    },
    showInfoIcons(){
      if (!this.getIsConferenceHall || (this.getIsConferenceHall && this.getIsParticipantIsPodium)) return true;
      if (this.getIsConferenceHall && !this.getIsParticipantIsPodium) return false;
      return true;
    },
    addTextUsers() {
      if (this.currentDesktop || this.presenterMode) return;
      return this.amImoderator
        ? this.$t("components.conferenceForm.moderator")
        : this.isGuest
        ? this.$t("components.conferenceForm.guest")
        : this.$t("components.conferenceForm.user");
    },
    isGuest() {
      if (!this.uuid) return false;
      return store.getUserIsGuest(this.uuid);
    },
    unseeMessageOneCallUser() {
      const receivedMessage = this.messagesToMe.filter(
        (message) =>
          message.creatorUUID == this.uuid &&
          message.users[this.state.ownUUID] &&
          !message.users[this.state.ownUUID].read
      );
      return receivedMessage.length;
    },
    amIGuest() {
      if (!store.state.ownUUID) return false;
      return store.getUserIsGuest(store.state.ownUUID);
    },
  },
};
</script>
<style scoped lang="scss">
.cursorDefault {
  cursor: default;
}
.noHover::before {
  background-color: transparent !important;
}
.remoteTopBar {
  cursor: pointer;
}
.listenerSpeakerBarConferenceHall{
  background-color: rgba(56, 162, 184, 0.5)!important;
}
.topbar {
  position: absolute;
  width: 100%;
  text-align: center;
  color: white;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  height: 30px;
  font-size: 15px;
  top: 0px;
  padding: 4px;
  .userIconInfo {
    position: absolute;
    left: 13px;
    top: 5px;
    font-size: 15px;
  }
  .rightSideBar {
    position: absolute;
    right: 0;
    top: 6px;
    font-size: 15px;
    display: flex;
    align-items: center;
  }
  & .callerName {
    max-width: 60%;
    margin: 0 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>