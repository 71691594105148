// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profileWrapper[data-v-5ad732cc] {
  height: calc(100vh - 320px);
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./websrc/components/userManagement/userManagementWrapper.vue","webpack://./userManagementWrapper.vue"],"names":[],"mappings":"AACA;EACI,2BAAA;EACA,cAAA;ACAJ","sourcesContent":["\n.profileWrapper {\n    height: calc(100vh - 320px);\n    overflow: auto;\n}\n",".profileWrapper {\n  height: calc(100vh - 320px);\n  overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
