<template>
  <v-row :class="{'mx-0': isFromModal, 'fill-height': !isFromModal}">
    <v-col cols="6" class="primary backgroundImage hidden-sm-and-down" :md="6" :xl="6" v-if="!isFromModal">
      <div class="fill-half-width divContentText">
        <div class="logoLeftsideDiv">
          <v-img
            src="assets/icon.png"
            max-height="150"
            max-width="150"
            contain
          ></v-img>
        </div>
        <h2 class="font-weight-bold mb-2">{{ getUrl }}</h2>
        <p v-if="isFromRegister" class="mb-0 customFontSize font-weight-medium">{{ checkIfFree ? $t('components.companyRegister.domainIsFree') : $t('components.subdomainTaken.namespaceNotFree')}}</p>
        
        <!-- <p class="mb-0 customFontSize font-weight-medium">{{ $t('components.companyRegister.yourNewVoffice') }}</p> -->
      </div>
      <div class="copyRightDiv">RA-MICRO Software AG © 2024</div>
    </v-col>

    <v-col :cols="isFromModal ? 12 : 6" class="formRightSide" :md="isFromModal ? 12 : 6" :sm="12" :xs="12" :xl="isFromModal ? 12 : 6">
      <div :class="{'contentRightSideModal': isFromModal, 'contentRightSide': !isFromModal}">
        <v-row flex class="w-100 mx-0 my-0 justify-space-between">
          <v-col class="px-0 py-0" align="left">
            <v-card-title class="titleSize d-flex px-0" v-if="!isFromModal">
              <v-img
                v-if="isFromModal"
                src="assets/icon.png"
                max-height="30"
                max-width="30"
                contain
              ></v-img>
          <!--<img src="img/icon.png" class="logoRegister mr-2" />-->
          <span class="font-weight-medium" :class="{'pl-4': isFromModal}" v-html="$t('components.companyRegister.regPaypal.headline')"></span>
            </v-card-title>
         </v-col>
        </v-row>
        <v-divider v-if="!isFromModal"></v-divider>
        
        <v-row>
          <v-col cols="12" v-if="isAdmin">
            <v-row class="mx-0 priceStyle mt-6">
              <v-col cols="10" class="py-0 px-0">
                <v-card-title
                  class="titleSize px-0 py-0"
                >{{$t('components.companyRegister.regPaypal.subtitle')}}</v-card-title>
              </v-col>
              <v-col cols="2" class="py-0 px-0">
                <v-card-title
                  class="titleSize px-0 py-0 justifyContentEnd"
                  justify="flex-end"
                >{{$t('components.companyRegister.regPaypal.price', ['Euro'])}}</v-card-title>
              </v-col>
            </v-row>
          </v-col>

          <template v-if="isFromRegister">
            <v-col
              cols="12"
              class="contenText"
            ><p>{{$t('components.companyRegister.regPaypal.text1')}}</p></v-col>
            <v-col
              cols="12"
              class="contenText"
            ><p>{{$t('components.companyRegister.regPaypal.text2')}}</p></v-col>
          </template>

          <template v-else>
            <v-col
              cols="12"
              class=""
            ><p>{{ getMessage() }}
              <!-- {{daysLeft === 0 ? $t('components.companyRegister.regPaypal.textExpired1') : $t('components.companyRegister.regPaypal.textAlmostExpired1', [getDaysToExpire])}} -->
              </p></v-col>
            <v-col
              cols="12"
              class=""
            ><p>{{ getMessage2()}}
              <!-- {{daysLeft === 0 ? $t('components.companyRegister.regPaypal.textExpired2') : $t('components.companyRegister.regPaypal.textAlmostExpired2')}} -->
              </p></v-col>
          </template>

          <v-col :cols="isFromModal ? 12 : 8" :class="{'text-left': !isFromModal, 'text-right': isFromModal}">
             <div v-show="loaderDone && isAdmin" id="paypal-button-container" class="paypal-button-container"></div>
          </v-col>
          <v-col cols="4" class="text-right" v-if="!isFromModal">
           <v-btn
              v-if="isFromRegister"
              flat
              variant="outlined"
              color="primary"
              class="verticalTop "
              @click="goBack();"
            >{{ $t('tour.buttonPrevious') }}</v-btn>
            <!-- <v-btn
              flat
              variant="outlined"
              v-else-if="daysLeft > 0"
              color="primary"
              class="verticalTop "
              @click="closeModal ? closeModal() : null"
            >{{ $t('generics.cancel') }}</v-btn> -->
          </v-col>

        </v-row>
      </div>
    </v-col>
  </v-row>
</template>
<script>
// import { webLicensedBaseFeatures } from '../../../sharedsrc/licensedFeatures';
import store from "../../store";
import { dispatchErrorAlert } from "../../effector/alerts";
import paypalSubscriptionLoader from "../../lib/paypalSubscriptionLoader";
import { getPayPalRegistrationSubscriptionEnv, setNamespaceSetting } from '../../lib/wsMsg';
export default {
  props: [
    "namespaceName",
    "isFree",
    "isFromRegister",
    "daysLeft",
    "closeModal",
    "isFromModal"
  ],
  components: {},
  data: () => ({
    clientId: undefined,
    planId: undefined,
    loaderDone: false,
    loaderPromise: undefined,
    state: store.state,
    setSetUpConfigRoute: store.setSetUpConfigRoute,
    companyRegisterObject: store.state.namespaceSettings.companyInfo,
    isExpired: false,
    daysToExpire: 3,
    setShowSetup: store.setShowSetup,
  }),
  methods: {
    getMessage(){
     if (this.daysLeft === 0) {
       if (this.isAdmin){
         return this.$t('components.companyRegister.regPaypal.textExpired1');
       } else {
         return this.$t('components.companyRegister.regPaypal.userTextExpired1');
       } 
     } else {
       return this.$t('components.companyRegister.regPaypal.textAlmostExpired1', [this.getDaysToExpire])
     }  
    },
    getMessage2(){
     if (this.daysLeft === 0) {
       if (this.isAdmin){
         return this.$t('components.companyRegister.regPaypal.textExpired2');
       } else {
         return '';
       } 
     } else {
       return this.$t('components.companyRegister.regPaypal.textAlmostExpired2', [this.getDaysToExpire])
     }  
    },
    goBack() {
      this.setSetUpConfigRoute("subdomainAvailable");
    },
    async loadData() {
      const {
        client_id,
        plan_id,
        plan_id_without_trial,
      } = store.state.wsOpen
        ? await getPayPalRegistrationSubscriptionEnv()
        : await fetch("/api/paypal/getRegistrationSubscriptionEnv", {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
          })
          .then(res => res.json());
      this.clientId = client_id;
      this.planId = (this.isFromRegister ? plan_id : plan_id_without_trial);
      const locale = (this.state.user.language && this.state.namespaceSettings.companyInfo.countrySelected)
        ? this.state.user.language + '_' + this.state.namespaceSettings.companyInfo.countrySelected.value
        : '';
      return await paypalSubscriptionLoader(this.clientId, locale)
        .catch(() => paypalSubscriptionLoader(this.clientId));
    }
  },
  computed: {
    isAdmin(){
      return store.getUserIsAdmin(store.state.ownUUID);
    },
    getUrl() {
      return (
        this.namespaceName ||
        `${document.location.protocol}//${document.location.host}`
      ); //window.location.href;
    },
    checkIfFree() {
      return this.isFree || true;
    },
    getDaysToExpire() {
      return this.daysLeft || this.daysToExpire;
    }
  },
  created() {
    this.loaderPromise = this.loadData()
      .catch(err => {
        console.warn('loaderPromise Error', err);
        dispatchErrorAlert(err.message || err);
      });
  },
  mounted() {
    const vue = this;
    this.loaderPromise.then(loaderResult => {
      if (!loaderResult) return;
      const customCreate = {};
      if (!this.isFromRegister && this.daysLeft) {
        const date = new Date();
        date.setDate(date.getDate() + this.daysLeft);
        date.setUTCHours(0, 0, 0, 0);
        // Future subscription date if days left
        // https://developer.paypal.com/docs/business/subscriptions/add-capabilities/future-date/
        customCreate.start_time = date.toISOString();
      }
      this.loaderDone = true; // Trigger rending of target UI element.
      window.paypal
        .Buttons({
          style: {
            shape: "rect",
            color: "gold",
            layout: "vertical",
            label: "subscribe"
          },
          createSubscription: function(data, actions) {
            return actions.subscription.create({
              application_context: {
                shipping_preference: "NO_SHIPPING"
              },
              plan_id: vue.planId,
              ...customCreate
            });
          },
          onApprove: (data, actions) => {
            //console.log(data.subscriptionID);
            store.state.namespaceSettings.companyInfo.subscriptionID = data.subscriptionID;
            setNamespaceSetting('companyInfo', store.state.namespaceSettings.companyInfo);
            if ( this.isFromRegister ){
              setNamespaceSetting('setupDone', true);
              setNamespaceSetting('noNewUsers', true);
              localStorage.setItem('emailRegistered', store.state.namespaceSettings.companyInfo.email);
              localStorage.setItem('firstNameRegistered', store.state.namespaceSettings.companyInfo.firstName);
              localStorage.setItem('lastNameRegistered', store.state.namespaceSettings.companyInfo.lastName);
              this.setShowSetup(false);
            } else if (this.isFromModal) {
              this.closeModal();
            }
          }
        })
        .render("#paypal-button-container");
    });
  }
};
</script>

<style scoped lang="scss">
.logoLeftsideDiv{
  width: 155px;
  background: white;
  border-radius: 50%;
  height: 155px;
  padding: 3px;
  margin-bottom: 20px;
}
.priceStyle {
      border: 1px solid #d0cdcd;
    padding: 10px 20px;
    border-radius: 4px;
   
}
.titleSize {
  font-size: 18px;
}

.paypal-button-container{
  display: inline-block;
}
.verticalTop{
  vertical-align: top;
  // float: right;
}
.justifyContentEnd {
  justify-content: flex-end;
}
.contentRightSide {
  display: table-cell;
  vertical-align: middle;
}
.formRightSide {
  position: relative;
  // padding-top: 10%;
  width: 50%;
  // padding-bottom: 10%;
  display: table;
  padding-left: 10%;
  padding-right: 10%;
  height: 100%;
}
.backgroundImage {
  color: white;
  // align-items: center;
  // justify-content: center;
  // padding-top: 193px;
  display: table;
  // flex-direction: column;
  height: 100%;
  padding-left: 8%;
  padding-right: 8%;
  .divContentText {
    display: table-cell;
    vertical-align: middle;
  }
}
.copyRightDiv {
  position: absolute;
  bottom: 20;
  left: 30px;
  font-size: 13px;
}
.logoRegister {
  height: 40px;
  width: 40px;
}
.customFontSize {
  font-size: 17px;
}
.fill-half-width {
  width: 100%;
}
.fill-width {
  width: 100%;
}
.btnNextStep {
  width: 25%;
}
</style>
