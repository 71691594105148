<template>
 <v-dialog
    :model-value="showModal"
    @update:model-value="showModal = $event" persistent max-width="460" @keydown.esc="closeModal">
    <v-card>
      <HeaderModal
        :titleModal="$t('components.remotePersonCall.rejectCall')"
        :closeModalFunction="closeModal"
      />
      <v-row flex :style="{width: '100%', margin: 0}">
        <div class="d-flex" :style="{width:'100%'}">
          <div align="center">
            <v-badge
              bordered
              floating
              height="60"
              width="60"
              location="bottom end"
              :color="badgetClassObj"
              dot
              offset-x="22"
              offset-y="15"
            >
              <v-avatar tile size="150" class="borderRadius10 mx-4 my-2">
                  <DefaultAvatar
                    :size="150"
                    :userData="getUserData"
                    v-if="getUserData.avatar === 'img/default_profile_picture.png'"
                  ></DefaultAvatar>
                  <v-img
                    v-if="getUserData.avatar !== 'img/default_profile_picture.png'"
                    class="borderRadius10"
                    max-height="150"
                    max-width="150"
                    contain
                    :src="getUserData.avatar !== 'img/default_profile_picture.png' ?getUserData.avatar: 'img/default_profile_picture.png' "
                    lazy-src="assets/default_profile_picture.png"
                  ></v-img>
              </v-avatar>
            </v-badge>
          </div>
          <div class="text-center" :style="{width:'100%'}">
            <v-divider class="mt-4"></v-divider>
            <div class="mt-2 ml-4">
              <p color="primary" class="userNameCard mb-4">{{getUserData.titel}} {{getUserData.name}}</p>
              <p color="primary" class="mb-4">{{getUserData.email}}</p>
              <p class="dataUserCard my-0 mb-4">{{modalMsg}}</p>
            </div>
            <v-divider class="mt-4"></v-divider>
          </div>
        </div>
      </v-row>
      <FooterModal :closeModalFunction="closeModal" class="mt-4">
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import { getStatusColorByUser } from "../../utils/status";
import { wholeBarWithColor } from "../../utils/color"
import DefaultAvatar from "../image/defaultAvatar.vue";
import FooterModal from "./modalComponents/footerModal.vue";
import HeaderModal from "./modalComponents/headerModal.vue";

export default {
  components: { DefaultAvatar, FooterModal, HeaderModal },
  data() {
    return {
      state: store.state
    };
  },
  watch: {},
  mounted() {},
  methods: {
    wholeBarWithColors(){
      return wholeBarWithColor()
    },
    closeModal() {
      return store.setmodalPopUp(undefined);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    }
  },
  computed: {
    modalMsg(){
      if (this.showModal && !this.state.modalPopUp.msg) {
        return this.$t('components.remotePersonCall.rejectCall')
      } else {
        return this.state.modalPopUp.msg
      }
    },
    showModal() {
      return (
        store.state.modalPopUp && store.state.modalPopUp.type === "rejectCall"
      );
    },
    badgetClassObj() {
      if (this.wholeBarWithColors()) {
        return getStatusColorByUser(
          this.getUserData,
          this.isUserConnected
        );
      } else {
        return "rgba(0,0,0,0.3)";
      }
    },
    getUserData() {
      if (store.state.modalPopUp.uuid) {
        return store.state.group[store.state.modalPopUp.uuid].user;
      }
    },
    isUserConnected() {
        if (store.state.modalPopUp.uuid) {
        return store.state.group[store.state.modalPopUp.uuid].connected;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
