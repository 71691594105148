// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.displayInlineFlex[data-v-c6cd752e] {
  display: inline-flex;
}
.statsFontSize[data-v-c6cd752e] {
  font-size: 17px;
  text-align: center;
}
.usersAvailable[data-v-c6cd752e] {
  color: #008000;
}
.usersNotAvailable[data-v-c6cd752e] {
  color: #ffa500;
}
.usersHolidays[data-v-c6cd752e] {
  color: #7208ff;
}
.usersIllness[data-v-c6cd752e] {
  color: #fd939e;
}
.usersOffline[data-v-c6cd752e] {
  color: #808080;
}
.usersBusy[data-v-c6cd752e] {
  color: #ff0000;
}
.usersCoffeBreak[data-v-c6cd752e] {
  color: #795548;
}
.usersConnections[data-v-c6cd752e] {
  color: var(--v-primary-base) !important;
}
.usersConnectionsWhite[data-v-c6cd752e] {
  color: white !important;
}
.cursorPointer[data-v-c6cd752e] {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/statsNumbers.vue","webpack://./statsNumbers.vue"],"names":[],"mappings":"AACA;EACE,oBAAA;ACAF;ADEA;EACE,eAAA;EACA,kBAAA;ACCF;ADCA;EACE,cAAA;ACEF;ADAA;EACE,cAAA;ACGF;ADDA;EACE,cAAA;ACIF;ADFA;EACE,cAAA;ACKF;ADHA;EACE,cAAA;ACMF;ADJA;EACE,cAAA;ACOF;ADLA;EACE,cAAA;ACQF;ADNA;EACE,uCAAA;ACSF;ADPA;EACE,uBAAA;ACUF;ADRA;EACE,eAAA;ACWF","sourcesContent":["\n.displayInlineFlex{\n  display: inline-flex;\n}\n.statsFontSize {\n  font-size: 17px;\n  text-align: center;\n}\n.usersAvailable {\n  color: #008000;\n}\n.usersNotAvailable {\n  color: #ffa500;\n}\n.usersHolidays {\n  color: #7208ff;\n}\n.usersIllness {\n  color: #fd939e;\n}\n.usersOffline {\n  color: #808080;\n}\n.usersBusy {\n  color: #ff0000;\n}\n.usersCoffeBreak{\n  color: #795548;\n}\n.usersConnections {\n  color: var(--v-primary-base) !important;\n}\n.usersConnectionsWhite {\n  color: white !important;\n}\n.cursorPointer {\n  cursor: pointer;\n}\n",".displayInlineFlex {\n  display: inline-flex;\n}\n\n.statsFontSize {\n  font-size: 17px;\n  text-align: center;\n}\n\n.usersAvailable {\n  color: #008000;\n}\n\n.usersNotAvailable {\n  color: #ffa500;\n}\n\n.usersHolidays {\n  color: #7208ff;\n}\n\n.usersIllness {\n  color: #fd939e;\n}\n\n.usersOffline {\n  color: #808080;\n}\n\n.usersBusy {\n  color: #ff0000;\n}\n\n.usersCoffeBreak {\n  color: #795548;\n}\n\n.usersConnections {\n  color: var(--v-primary-base) !important;\n}\n\n.usersConnectionsWhite {\n  color: white !important;\n}\n\n.cursorPointer {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
