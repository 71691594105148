// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preLine[data-v-47e34741] {
  white-space: pre-line;
  padding: 0;
}
.scrollfaqs[data-v-47e34741] {
  height: 70vh;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/support/support.vue","webpack://./support.vue"],"names":[],"mappings":"AACA;EACE,qBAAA;EACA,UAAA;ACAF;ADEA;EACE,YAAA;EACA,cAAA;ACCF","sourcesContent":["\n.preLine {\n  white-space: pre-line;\n  padding: 0;\n}\n.scrollfaqs{\n  height: 70vh;\n  overflow: auto;\n}\n",".preLine {\n  white-space: pre-line;\n  padding: 0;\n}\n\n.scrollfaqs {\n  height: 70vh;\n  overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
