<template>
  <!-- attach="#remotePersonCallDiv" -->
  <!-- :attach="attachTo !== '' ? `#${attachTo}` : null" -->
  <v-dialog
    @click.stop.prevent
    :model-value="showRequestPaymentModal" @update:model-value="showRequestPaymentModal = $event"
    persistent
    max-width="570"
    class="paymentModal"
  >
      <v-card>
        <!-- Title -->
        <HeaderModal
          :titleModal="$t('components.paymentModal.titleRequestPayment')"
          :closeModalFunction="closeModal"
          :actualDate="actualDate"
        />
        <!-- End title -->

        <!-- Modal body -->
        <div>
          <v-row class="mx-3 mb-4 mt-5">
            <v-col cols="12" class="mb-2">
              <v-text-field
                @click.stop.prevent
                :model-value="customerNumber" @update:model-value="customerNumber = $event"
                :label="$t('components.paymentModal.customerNumber')"
                variant="outlined"
                :class="{ borderError: customerNumber == '' }"
                density="compact"
                hide-details="auto"
              >
              </v-text-field>
            </v-col>

            <!-- <v-col cols="6" >
                <p class="mb-0 font15 pt-2">{{$t('components.paymentModal.conversationWith')}}</p>
              </v-col> -->
            <v-col cols="12" class="mb-2">
              <template v-if="dataCombobox.users.length > 0">
                <v-combobox
                  @click.stop.prevent
                  :model-value="selectedUser" @update:model-value="selectedUser = $event"
                  :items="dataCombobox.users"
                  select
                  item-title="name"
                  item-value="uuid"
                  :label="$t('components.paymentModal.conversationWith')"
                  variant="outlined"
                  density="compact"
                  hide-details
                >
                </v-combobox>
              </template>
              <template v-else>
                <!-- ToDo: Add translation here -->
                <p>{{ $t("generics.dataNotAvailable") /* No Users Available */ }}</p>
              </template>
            </v-col>

            <v-col cols="12" class="mb-2 pt-0">
              <v-text-field
                @click.stop.prevent
                :model-value="note" @update:model-value="note = $event"
                :label="$t('components.paymentModal.note')"
                variant="outlined"
                density="compact"
                hide-details="auto"
                maxlength="250"
              >
              </v-text-field>
            </v-col>

            <!-- <v-col cols="5" class="mb-0 py-0 pt-0"> </v-col>
            <v-col cols="4" class="mb-0 py-0 pt-0">
              <v-checkbox
                :model-value="uptoOption" @update:model-value="uptoOption = $event"
                class="mt-0 inoutUpToOption"
                :label="$t('components.paymentModal.upToCheckBox')"
              ></v-checkbox>
            </v-col>
            <v-col cols="3" class="mb-0 py-0 pt-0"> </v-col> -->

            <v-col cols="5" class="mb-2 pt-0">
              <template v-if="getProducts.length > 0">
                <v-combobox
                v-model="selectedProduct"
                @update:model-value="selectedProduct = $event; checkProductCombobox($event)"
                :items="getProducts"
                item-value="productId"
                item-title="productName"
                :return-object="true"
                variant="outlined"
                density="compact"
                hide-details
                :label="$t('components.paymentModal.product')"
                >
                  <template v-slot:item="{ item }">
                    <div @click="changeProductCombo(item.raw)" class="productComboboxItem">
                      <span class="itemName">
                        {{ item.raw.productName }} - {{ formatProductPrice(item.raw.productPrice) }}
                        <font-awesome-icon
                          :icon="['fal', 'euro-sign']"
                          :style="{ fontSize: '15px' }"
                          v-if="state.namespaceSettings.currency === 'EUR'"
                        />
                        <font-awesome-icon
                          :icon="['far', 'pound-sign']"
                          :style="{ fontSize: '15px' }"
                          v-if="state.namespaceSettings.currency === 'GBP'"
                        />
                        <v-img
                          contain
                          height="20"
                          v-if="state.namespaceSettings.currency === 'CHF'"
                          src="img/paymentIcons/swiss-franc-black.png"
                          lazy-src="img/paymentIcons/swiss-franc.png"
                        ></v-img>
                      </span>
                    </div>
                  </template>
                </v-combobox>
              </template>
              <template v-else>
                <!-- ToDo: Add translation here -->
                <p>{{ $t("generics.dataNotAvailable") /* No Products Available */ }}</p>
              </template>
            </v-col>
            <v-col cols="4" class="mb-2 pt-0">
              <v-text-field
                @click.stop.prevent
                :model-value="callDuration" @update:model-value="callDuration = $event"
                variant="outlined"
                density="compact"
                :min="5"
                :class="`inputWithPrepend ${uptoOption ? 'uptoOption' : ''}`"
                type="number"
                :label="$t('components.paymentModal.callDuration')"
                hide-details="auto"
              >
                <template slot="prepend-inner" v-if="uptoOption">
                  <span>{{ $t("components.paymentModal.upToInput") }}</span>
                </template>
                <template slot="append" v-if="uptoOption">
                  <span>{{ $t("literals.Minutes") }}</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="3" class="mb-2 pt-0">
              <v-text-field
                @click.stop.prevent
                :model-value="totalAmount" @update:model-value="totalAmount = $event"
                max="10000"
                @change="checkAmountValue"
                @keyup="checkAmountValue"
                :rules="amountRules"
                :label="$t('components.paymentModal.price')"
                variant="outlined"
                density="compact"
                type="number"
                :class="{ borderError: totalAmount == '' || totalAmount === 0 }"
                hide-details="auto"
              >
              <template #append>
                <font-awesome-icon
                  :icon="['fal', 'euro-sign']"
                  class="mt-1"
                  :style="{ fontSize: '15px' }"
                  v-if="state.namespaceSettings.currency === 'EUR'"
                />
                <font-awesome-icon
                  :icon="['far', 'pound-sign']"
                  class="mt-1"
                  :style="{ fontSize: '15px' }"
                  v-if="state.namespaceSettings.currency === 'GBP'"
                />
                <v-img
                  contain
                  height="20"
                  v-if="state.namespaceSettings.currency === 'CHF'"
                  src="img/paymentIcons/swiss-franc-black.png"
                  lazy-src="img/paymentIcons/swiss-franc.png"
                ></v-img>
              </template>
              </v-text-field>
            </v-col>
            <!-- <v-col cols="5">
              </v-col>
              <v-col cols="4" class="py-0">
                 <v-checkbox
                  :model-value="uptoOption" @update:model-value="uptoOption = $event"
                  class="mt-0"
                  :label="$t('components.paymentModal.upToCheckBox')"
                ></v-checkbox>
              </v-col>
              <v-col cols="3">
              </v-col> -->
          </v-row>

          <!-- <v-row class="mx-0 mb-2">
            <v-col cols="12">
              <v-checkbox
                :model-value="muteCall" @update:model-value="muteCall = $event"
                class="mt-0"
                :label="$t('components.paymentModal.muteCall')"
              ></v-checkbox>
            </v-col>
          </v-row> -->
        </div>
        <!-- End modal body -->

        <!-- Footer -->
        <v-card color="primary" class="py-2 pl-4 pr-2 footerModal">
          <v-row class="px-2 btns w-100 mx-0 my-0">
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    variant="outlined"
                    :loading="loadingButton"
                    :class="`mr-2 buttonIconPrimaryFooter ${
                      disableSendButton ? 'buttonIconPrimaryFooterDisable' : ''
                    }`"
                    :disabled="disableSendButton"
                    color="white"
                    @click.stop.prevent="sendPaymentRequestToCustomer()"
                  >
                    {{ $t("components.paymentModal.buttonSend") }}
                  </v-btn>
                </template>
                <span>{{ $t("components.paymentModal.send") }}</span>
              </v-tooltip>
          </v-row>
        </v-card>
        <!-- End footer -->
      </v-card>
  </v-dialog>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store, { EventBus } from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
import { dispatchErrorAlert } from "../../../effector/alerts";
import {
  paymentModalStore,
  setRequestPaymentModal /*, setPaymentInProgressModal */,
} from "../../../effector/modals";
import HeaderModal from "../../modal/modalComponents/headerModal.vue";
import moment from "moment";
import { requestVisitorPayPalPayment } from "../../../lib/wsMsg";
export default {
  components: { HeaderModal },
  props: [],
  data() {
    const effector = {
      showRequestPaymentModal: paymentModalStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      totalAmount: "",
      amountRules: [(v) => !v || /^\d+([.,]\d{2}|)$/.test(v) || ""],
      selectedUser: {
        name: `${store.getTitelForUuid(store.state.ownUUID)} ${
          store.state.user.name
        }`,
        uuid: store.state.ownUUID,
      },
      customerNumber: "",
      callDuration: "",
      // muteCall: true,
      receiver: null,
      selectedProduct: null,
      attachTo: false,
      loadingButton: false,
      isMobile: isMobile(),
      uptoOption: false,
      note: "",
      // Effector
      ...effector,
    };
  },
  mounted() {
    this.receiver = this.showRequestPaymentModal.receiver;
    this.attachTo = this.showRequestPaymentModal.tileId;
    // EventBus.$emit('splitViews')
    const tmpObj = {
      participantInPayment: this.showRequestPaymentModal.tileId,
      uuidInPayment: this.showRequestPaymentModal.receiver,
    };
    EventBus.$emit("receivedPaymentRequest", tmpObj);
  },
  watch: {
    callDuration: 'calculateTotalAmount',
    selectedProduct: 'calculateTotalAmount',
    totalAmount() {
      setImmediate(() => {
        if (this.totalAmount <= 0 && typeof this.totalAmount !== "string") {
          this.totalAmount = 1;
        }
        if (this.totalAmount < 0) {
          this.totalAmount = "";
        }
      });
    },
  },
  methods: {
    calculateTotalAmount() {
      setImmediate(() => {
        if (this.callDuration <= 0 && typeof this.callDuration !== "string") {
          this.callDuration = '';
        }
        if (
          this.selectedProduct &&
          this.callDuration !== "" &&
          this.callDuration > 0
        ) {
          if (this.selectedProduct.productPrice) {
            const pricePerMinute = this.selectedProduct.productPrice / 60;
            this.totalAmount = (this.callDuration * pricePerMinute).toFixed(2);
          } else {
            this.totalAmount = '';
          }
        } else {
          this.totalAmount = '';
        }
      });
    },
    changeProductCombo(product){
      this.selectedProduct = product;
      if (this.selectedProduct.productPrice && this.callDuration > 0) {
        const pricePerMinute = this.selectedProduct.productPrice / 60;
        this.totalAmount = (this.callDuration * pricePerMinute).toFixed(2);
      } else {
        this.totalAmount = '';
      }
    },
    formatProductPrice(productPrice) {
      return parseFloat(productPrice).toLocaleString(this.$locale.current(), {
        minimumFractionDigits: 2,
      });
    },
    selectProduct(product) {
      //console.log('product', product, this.selectedProduct)
      this.preFillDurationAndPrice();
    },
    preFillDurationAndPrice() {
      this.callDuration = 60;
      this.totalAmount = this.selectedProduct.productPrice;
    },
    async sendPaymentRequestToCustomer() {
      this.loadingButton = true;
      const retVal = await requestVisitorPayPalPayment({
        show: true,
        total: this.totalAmount,
        customerNumber: this.customerNumber,
        callDuration: this.callDuration,
        // muteCall: this.muteCall,
        userToPay: {
          name: this.selectedUser.name,
          uuid: this.selectedUser.uuid,
        },
        receiver: this.receiver,
        productInfo: this.selectedProduct,
        tileId: this.attachTo,
        currency: this.state.namespaceSettings.currency,
        callUUID: this.getCallUUID,
        uptoOption: this.uptoOption,
        note: this.note,
      }).catch((err) => {
        dispatchErrorAlert(err.message);
        console.warn("requestVisitorPayPalPayment", err.stack || err);
      });
      if (!retVal) return;
      // if ( this.muteCall ){
      //   //Mute call Event
      //   EventBus.$emit('muteCall')
      // }
      this.loadingButton = false;
      setRequestPaymentModal(false);
      const obj = {
        uuid: store.state.ownUUID,
        tileId: this.attachTo,
      };
      store.state.user.paymentState.paymenInprogress = obj;
      // setPaymentInProgressModal(obj);
    },
    closeModal(event) {
      event.preventDefault();
      event.stopPropagation();
      setRequestPaymentModal(false);
    },
    isWaitingRoom(uuid) {
      return (
        this.state.group[uuid].user.visitorData &&
        this.state.group[uuid].user.visitorData.isWaitingRoom
      );
    },
    isVisitor(uuid) {
      return this.state.group[uuid].user.visitor;
    },
    titelPerson(uuid) {
      // console.log('uuid', uuid)
      return store.getTitelForUuid(uuid) || "";
    },
    checkAmountValue() {
      if (this.totalAmount <= 0) {
        this.totalAmount = "";
      } else if (this.totalAmount > 10000) {
        this.totalAmount = 10000;
      }
    },
  },
  computed: {
    getCallUUID() {
      const remoteStreamsKey = Object.keys(this.state.remoteBridgeStreams)[0];
      if (!remoteStreamsKey) return false;
      return remoteStreamsKey;
    },
    disableSendButton() {
      if (
        this.totalAmount <= 0 ||
        this.totalAmount == "" ||
        !this.totalAmount ||
        this.customerNumber === ""
      ) {
        return true;
      }
      return false;
    },
    getAttach() {
      if (this.attachTo && !this.isMobile) {
        const element = document.getElementById(this.attachTo);
        // console.log('getAttach ====> ', element)
        if (element) {
          return element;
        }
      }
      return false;
    },
    getProducts() {
      return store.state.namespaceSettings.adminProducts;
    },
    actualDate() {
      return moment(new Date(Date.now())).format("DD.MM.YYYY HH:mm");
    },
    dataCombobox() {
      const result = {
        users: [
          {
            name: `${this.titelPerson(store.state.ownUUID)} ${
              store.state.user.name
            }`,
            uuid: store.state.ownUUID,
          },
        ],
        visitors: [],
      };
      /*
      const users = this.state.remoteStreams || {};
      for (const key in users) {
        const data = {
          name: `${this.titelPerson(key)} ${
            (this.state.group[key].user || {}).name || ""
          }`,
          uuid: key,
        };
        if (!this.isWaitingRoom(data.uuid) && !this.isVisitor(data.uuid)) {
          result.users.push(data);
        } else {
          result.visitors.push(data);
        }
      }
      result.users.sort((a, b) => a.name.localeCompare(b.name));
      */
      return result;
    },
  },
};
</script>

<style scoped lang="scss">
.productComboboxItem{
  padding-left: 10px;
  padding-top: 6px;
  cursor: pointer;
}
.productComboboxItem:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
.buttonIconPrimaryFooter {
  box-shadow: none !important;
  border-radius: 4px !important;
  // background-color: white !important;
  min-width: 45px !important;
}
.buttonIconPrimaryFooterDisable {
  background-color: rgba(255, 255, 255, 0.4) !important;
  /* color: #11889d !important; */
}
// #remotePersonCallDiv{
//   .v-dialog__content{
//     background: #ffffff;
//     position: absolute !important
//   }
// }
.closeButton {
  border-radius: 4px;
  min-width: 45px;
}
.v-theme--light .v-dialog {
  .v-card {
    background: rgba(255, 255, 255, 0.7);
    word-break: break-word;
  }
}
.v-theme--dark .v-dialog {
  .v-card {
    background: rgba(30, 30, 30, 0.7);
    word-break: break-word;
  }
}
@media screen and (min-width: 993px) {
  .v-dialog__content {
    // background: #ffffff;
    position: absolute !important;
  }
}
.font15 {
  font-size: 15px;
}
.styleHeader {
  border-bottom: 1px solid var(--v-primary-base) !important;
  margin-bottom: 15px;
  color: var(--v-primary-base) !important;
}
.userStyle {
  padding: 5px;
  width: 90%;
}
.imageLogo {
  display: inline-block;
  vertical-align: middle;
}
</style>
<style lang="scss">
.inputTextRight {
  input {
    text-align: right !important;
  }
  .v-input__prepend-inner {
    margin-top: 0px !important;
    position: absolute;
    top: 13px;
  }
}
.inputWithPrepend {
  .v-input__prepend-inner {
    margin-top: 0px !important;
    width: 50px !important;
    max-width: 50px;
    position: relative;
    top: 13px;
    span {
      width: 50px !important;
      max-width: 50px;
    }
  }
  .v-input__append-inner {
    position: relative;
    top: 4px;
    width: 80px;
    max-width: 80px;
    span {
      width: 60px;
      text-align: right;
    }
  }
  &.uptoOption {
    .v-label--active {
      left: -47px !important;
      right: unset !important;
      left: -47px !important;
      right: unset !important;
      width: 200px;
      max-width: 122px;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.inoutUpToOption {
  label {
    font-size: 14px;
  }
  .v-input__slot {
    margin: 0px !important;
  }
}
.videBridgeContainer {
  .v-dialog {
    background-color: transparent !important;
  }
}
.borderError {
  fieldset {
    border-color: red !important;
  }
}
</style>