<template>
<div>
  <div style="font-size:14px;">

    {{ getEventStatus}} 
    <!-- {{  moment(new Date(rsvp[userUUID].lastChange)).format('MM.DD.YYYY H:mm') }} -->
  </div>
</div>
</template>

<script>
import moment from "../../../../sharedsrc/moment";
import store from "../../../store";

export default {
  props: ['entry','userUUID'],
  data() {
    return {
      // state: store.state,
      moment:moment
    };
  },
  mounted(){
    
  },
  computed: {
    getEventStatus(){
      if ( this.entry.creatorUUID !== store.state.ownUUID) return '';
      if (this.entry.rsvp[this.userUUID]) {
        if (this.entry.rsvp[this.userUUID].status === 'pending'){
          return  this.$t('components.eventEntryForm.pending');
        } else if (this.entry.rsvp[this.userUUID].status === 'accepted') {
          return  this.$t('components.eventEntryForm.accepted') + ' '+ this.moment(this.entry.rsvp[this.userUUID].lastChange).format('MM.DD.YYYY, HH:mm');
        }  else if (this.entry.rsvp[this.userUUID].status === 'rejected') {
          return  this.$t('components.eventEntryForm.rejected') + ' '+ this.moment(this.entry.rsvp[this.userUUID].lastChange).format('MM.DD.YYYY, HH:mm');
        }
      }
    },
    rsvp() {
      return this.entry.rsvp;
    },
    keys() {
      if (!this.rsvp) return [];
      return Object.keys(this.rsvp);
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>

</style>