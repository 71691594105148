import { conferenceDomain } from './domain';
// import { ownUserUUIDState } from '../users';

import {
  fillAllConferencesEvent,
  fillMyConferencesEvent,
} from './events';

// const ownUUID = ownUserUUIDState.getState();

export const allConferencesStore = conferenceDomain.createStore([], { name: 'allConferences' });
export const myConferencesStore = conferenceDomain.createStore([], { name: 'myConferences' });

allConferencesStore.on(fillAllConferencesEvent, (state, payload) => {
  // console.log('SOMEHOW allConferencesStore ', payload);
  return payload;
});

myConferencesStore.on(fillMyConferencesEvent, (state, payload) => {
  // console.log('SOMEHOW allConferencesStore ', payload);
  return payload;
});
