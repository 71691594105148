<template>
  <v-progress-linear
    @click="addMoreTime()"
    background-color="#bdc3c7"
    bg-opacity="1"
    height="24"
    :color="getPercentage <= 50 ?'barColorGreen': getPercentage > 50 ? 'barColorOrange' : 'barColorGreen' "
    :class="getClassProgressLinear"
    :value="getPercentage"
  >
    <template>
      <strong class="text-white" style="position:absolute; left:5px">{{getCallStart}}</strong>
      <strong  v-if="originalCallDuration != 31536000000 && originalCallDuration != 86400000" class="text-black" style="margin-right:25px">{{$t('generics.planned')}} {{originalCallTime}} Min</strong>
      <!-- <strong class="white--text">{{elapseTime}}</strong> -->
       <strong  v-if="originalCallDuration!= 31536000000 && originalCallDuration != 86400000" class="customRed" style="margin-left:25px">({{timerCall}})</strong>
      <strong class="text-white" style="position:absolute; right:5px">{{getCallEnd}}</strong>
    </template>

  </v-progress-linear>
</template>

<script>
import { useStore } from "effector-vue/composition";
import {
  callAddDurationEvent,
  // callLessDurationEven,
  callInfoStateStore,
} from "../../effector/call";
import store from "../../store";
import moment from "../../../sharedsrc/moment";
import { setErrorInCallModalEvent, setBeforeEndingCallModalEvent, resetBeforeEndingCallModallEvent, beforeEndingCallModalStore } from "../../effector/modals";
import {hasPrivilege} from "../../utils/privileges";
import { rtcHangup } from "../../lib/rtcConn";
import CallInfoManager from '../../lib/callInfoManager';

export default {
  props: [],
  components: {},
  data() {
    const effector = {
      showbeforeEndingCallModalStore: beforeEndingCallModalStore,
      callInfoStateStore: callInfoStateStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      moment: moment,
      secondsBeep1: 0,
      secondsBeep2: 0,
      secondsBeep3: 0,
      originalCallDuration: 31536000000,
      // Effector
      ...effector,
    };
  },
  watch:{
     "haveActiveCallComp": {
            handler(callActive) {
              if(callActive){
                this.transferModeratorIfNotPrivileges()
              }
            }
        },
  },
  unmounted() {
    // detect if regular user has modal active and the motedator end call
    if (this.showbeforeEndingCallModalStore) {
      resetBeforeEndingCallModallEvent();
    }
  },
  mounted(){
    this.originalCallDuration = this.state.durationMeeting
  },
  methods: {
    rtcHangup() {
      return rtcHangup();
    },
    formatTime(time) {
      if (time >= 3600000) {
        return this.moment.utc(time).format("hh:mm");
      } else {
        return this.moment.utc(time).format("mm");
      }
    },
    formatDate(time) {
      if (time >= 3600000) {
        return this.moment(time).format("HH:mm");
      } else {
        return this.moment(time).format("mm");
      }
    },
    addMoreTime() {
      if (this.state.ownerMeeting) {
        callAddDurationEvent();
      } else {
        const data = {
          show: true,
          header: this.$t("components.modalAddTimeMeeting.header"),
          body: this.$t("components.modalAddTimeMeeting.info"),
        };
        return store.setinfoModal(data);
      }
    },
    ringAudio() {
      try {
        // Get real devices id's
        const audioDeviceId = store.state.persisted.mediaDeviceSetup.audioOutputId;
        // Set outputs
        const audioOutput = new Audio("/media/callFinishTone.mp3");
        // Sync audioDevice
        let promise = Promise.resolve();
        if ('sinkId' in audioOutput && 'setSinkId' in audioOutput && audioDeviceId) {
          promise = audioOutput.setSinkId(audioDeviceId); // Here catch!
        }
        promise
          .then(() => audioOutput.play())
          .catch((error) =>
            console.warn("Failed to play notification audio", error)
          );
      } catch (error) {
        console.warn("Failed to play notification audio", error);
      }
    },
    hasPrivilegeMethod(userUUID){
      return hasPrivilege(userUUID)
    },
    transferModerator(uuid){
      //  console.log('owner ', uuid, this.getNameForUuid(uuid))
      return CallInfoManager.transferOwnership(uuid);
    },
    amIOwner(){
      return CallInfoManager.amIOwner();
    },
    transferModeratorIfNotPrivileges(){
            // check if user has privilege if not change the ownerMeeting #1340
      if(!this.hasPrivilegeMethod(this.state.ownUUID) && this.amIOwner()){
        if(this.state.user.visitor && this.state.user.visitorData.userInviter){
          const userUUID = this.state.user.visitorData.userInviter;
          if(this.state.user.rtcCallStatus.indexOf(userUUID) !== -1){
            this.transferModerator(userUUID)
          }
        }
      }
    }
  },
  computed: {
    getCallEnd(){
      if (! this.state.meetingStartAt || !this.state.durationMeeting) return "";
      if( this.originalCallDuration == 31536000000 || this.originalCallDuration == 86400000) return this.$t("components.meetingTime.unlimited")
      return this.formatDate( this.state.meetingStartAt+ this.state.durationMeeting)
    },
    getCallStart(){
      if(!this.state.meetingStartAt){
        return ""
      }else {
        return this.formatDate(this.state.meetingStartAt)
      }
    },
    haveActiveCallComp(){
      return this.callInfoStateStore.callActive
    },
    originalCallTime() {
      return this.formatTime(this.state.durationMeeting);
    },
    getClassProgressLinear() {
      if (
        this.state.currentTS - this.state.meetingStartAt >=
        this.state.durationMeeting - 60000
      ) {
        return "progressBarBlink";
      }
    },
    timerCall() {
      // let calc = (((store.state.durationMeeting-(store.state.currentTS - store.state.meetingStartAt))/1000)/60).toFixed(2)
      let milisegundos =
        this.state.durationMeeting -
        (this.state.currentTS - this.state.meetingStartAt);
      if (!milisegundos ||  milisegundos <= 0) return "00";
      let progress = "";
      // round up timer #218
      const m = moment(milisegundos);
      let roundUp = m.second() || m.millisecond() ? m.add(1, 'minute').startOf('minute') : m.startOf('minute');
      const roundUpMilisegundos = this.moment(roundUp).valueOf()
      
      if(roundUpMilisegundos > this.state.durationMeeting){
        roundUp = this.state.durationMeeting
      }
      if (roundUpMilisegundos >= 3600000 ) {
        progress = this.moment
          .utc(roundUp)
          // .add(this.moment.duration(1000))
          .format("hh:mm");
      } else {
        progress = this.moment
          .utc(roundUp)
          // .add(this.moment.duration(1000))
          .format("mm");
      }
      if (milisegundos <= 30000 && milisegundos > 20000) {
        // one ring
        if (this.secondsBeep1 < 1) {
          this.ringAudio();
          this.secondsBeep1 = this.secondsBeep1 + 1;
        }
      } else if (milisegundos <= 20000 && milisegundos > 10000) {
        // two rings
        if (this.secondsBeep2 < 2) {
          this.ringAudio();
          this.secondsBeep2 = this.secondsBeep2 + 1;
        }
      } else if (milisegundos <= 10000 && milisegundos > 0) {
        // three rings
        if (this.secondsBeep3 < 3) {
          this.ringAudio();
          this.secondsBeep3 = this.secondsBeep3 + 1;
        }
      }
      return progress;
    },
    elapseTime() {
      let milisegundos = this.state.currentTS - this.state.meetingStartAt;
      if (!milisegundos || milisegundos <= 0) return "00";
      let progress = "";
      if (milisegundos >= 3600000) {
        progress = this.moment
          .utc(milisegundos)
          .format("hh:mm");
      } else {
        progress = this.moment
          .utc(milisegundos)
          .format("mm");
      }
      return progress;
    },
    getPercentage() {
      let personRemote = Object.keys(this.state.remoteStreams)[0];
      if (
        this.state.rtc[personRemote] &&
        this.state.rtc[personRemote].ringingState === "ringing" &&
        this.state.meetingStartAt &&
        this.state.meetingDialUPTime
      ) {
        let timeIncall = this.state.currentTS - this.state.meetingStartAt;
        if (timeIncall >= this.state.meetingDialUPTime) {
          return rtcHangup();
        }
      }
      if (
        this.state.rtc[personRemote] &&
        this.state.rtc[personRemote].ringingState == "connected" &&
        this.state.meetingStartAt
      ) {
        let timeIncall = this.state.currentTS - this.state.meetingStartAt;
        let limitTimeCall = this.state.durationMeeting;
        let porcentaje = ((timeIncall * 100) / limitTimeCall).toFixed(0);
        if (!this.showbeforeEndingCallModalStore && (porcentaje > 200 || porcentaje < -15)) {
          setErrorInCallModalEvent(true);
          return rtcHangup();
        }
        if (porcentaje >= 100) {
          setBeforeEndingCallModalEvent(true);
          if (porcentaje >= 120 && porcentaje < 200) {
            if (this.showbeforeEndingCallModalStore) {
              resetBeforeEndingCallModallEvent()
            }
            return rtcHangup();
          }
          return 100;
        }
        return porcentaje;
      } else {
        return 0;
      }
    },
  },
};


</script>

<style  lang="scss">
.customRed {
  color: #b53a31;
}
.barGradient {
  // background: #2a3133 !important;
  background: #262626 !important;
} /* fallback for old browsers */
.barGradient {
  background: -webkit-linear-gradient(to right, #6dbac6, #2a3133) !important;
} /* Chrome 10-25, Safari 5.1-6 */
.barGradient {
  background: linear-gradient(to right, #6dbac6, #2a3133) !important;
} /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

.barGradientGrey {
  background: #bdc3c7 !important;
} /* fallback for old browsers */
.barGradientGrey {
  background: -webkit-linear-gradient(to right, #bdc3c7, #2c3e50) !important;
} /* Chrome 10-25, Safari 5.1-6 */
.barGradientGrey {
  background: linear-gradient(to right, #bdc3c7, #2c3e50) !important;
} /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

.barGradientOrange {
  background: #ff512f !important;
} /* fallback for old browsers */
.barGradientOrange {
  background: -webkit-linear-gradient(to right, #f09819, #ff512f) !important;
} /* Chrome 10-25, Safari 5.1-6 */
.barGradientOrange {
  background: linear-gradient(to right, #f09819, #ff512f) !important;
} /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

.barColorGreen {
  background: #262626 ;
}
.barColorOrange {
  background: #ff7043;
}
</style>