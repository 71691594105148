// import { timelineDomain.createEffect } from 'effector';
import { noteDomain } from './domain';

import { getUserNotes, createNoteEntry, getNoteEntryByUUID, updateNotesUsersParams, updateNoteViews, deleteNoteEntry } from '../../lib/wsMsg';

export const fetchMyNotesEffect = noteDomain.createEffect({
  name: 'fetchMyNotesEffect',
  handler: getUserNotes,
});

export const createNoteEntryEffect = noteDomain.createEffect({
  name: 'createNoteEntryEffect',
  handler: createNoteEntry,
});

// export const createReplyMessageEntryEffect = noteDomain.createEffect({
//   name: 'createReplyMessageEntryEffect',
//   handler: createReplyMessageEntry,
// });

export const getNoteEntryByUUIDEffect = noteDomain.createEffect({
  name: 'getNoteEntryByUUIDEffect',
  handler: getNoteEntryByUUID,
});
// export const markAsDeletedByUserEffect = noteDomain.createEffect({
//   name: 'markAsDeletedByUser',
//   handler: (payload) => { return updateSpecificMessagesParam(payload[0], payload[1], payload[2]); },
// });

// export const markAsViewedByUserEffect = noteDomain.createEffect({
//   name: 'updateMessagesUsersParams',
//   handler: (payload) => { return updateMessagesUsersParams(payload[0], payload[1]); }
// });

export const markAsReadedByUserEffect = noteDomain.createEffect({
  name: 'updateNotesUsersParams',
  handler: (payload) => { return updateNotesUsersParams(payload[0], payload[1], payload[2]); }
});

export const increaseOneViewNoteEffect = noteDomain.createEffect({
  name: 'updateNoteViews',
  handler: (payload) => { return updateNoteViews(payload[0]); }
});

export const deleteNoteEffect = noteDomain.createEffect({
  name: 'deleteNoteEntry',
  handler: (payload) => { return deleteNoteEntry(payload[0]); }
});

// export const markAsRepliedByUserEffect = noteDomain.createEffect({
//   name: 'updateMessagesUsersParams',
//   handler: (payload) => { return updateMessagesUsersParams(payload[0], payload[1]); }
// });

// export const markAsDeleteAlldByUserEffect = noteDomain.createEffect({
//   name: 'updateMessagesUsersParams',
//   handler: (payload) => { return updateMessagesUsersParams(payload[0], payload[1]); }
// });

// export const markAsViewedSpecificMessageEffect = noteDomain.createEffect({
//   name: 'updateSpecificMessagesParam',
//   handler: (payload) => { return updateSpecificMessagesParam(payload[0], payload[1], payload[2]); }
// });

// export const markAsReadSpecificNoteEffect = noteDomain.createEffect({
//   name: 'updateSpecificNotesParam',
//   handler: (payload) => { return updateNotesUsersParams(payload[0], payload[1], payload[2]); }
// });
