import { conferenceHallsDomain } from './domain';

import { 
  getUserConferenceHalls, 
  createConferenceHallEntry, 
  getConferenceHallEntryByUUID, 
  deleteConferenceHallEntry, 
  getMyConferencesHallDetailEntries, 
  updateConferenceHall,
 } from '../../lib/wsMsg';

export const fetchMyConferenceHallsEffect = conferenceHallsDomain.createEffect({
  name: 'fetchMyConferenceHallsEffect',
  handler: getUserConferenceHalls,
});

export const createConferenceHallEntryEffect = conferenceHallsDomain.createEffect({
  name: 'createConferenceHallEntryEffect',
  handler: createConferenceHallEntry,
});

export const getConferenceHallEntryByUUIDEffect = conferenceHallsDomain.createEffect({
  name: 'getConferenceHallEntryByUUIDEffect',
  handler: getConferenceHallEntryByUUID,
});

export const deleteConferenceHallEntryEffect = conferenceHallsDomain.createEffect({
  name: 'deleteConferenceHallEntryEffect',
  handler: (payload) => { return deleteConferenceHallEntry(payload[0]); }
});

export const getMyConferencesHallDetailEffect = conferenceHallsDomain.createEffect({
  name: 'getMyConferencesHallDetailEffect',
  handler: (payload) => { return getMyConferencesHallDetailEntries() }
});

export const updateConferenceHallEffect = conferenceHallsDomain.createEffect({
  name: 'updateConferenceHallEffect',
  handler: updateConferenceHall,
});
