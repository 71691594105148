<template>
  <div class="primaryBackground" :class="`${isMobile ? 'userListMobile' : (section === 'Users' ? 'userList' : (section == 'coffee-break' ? 'userListCoffee' : 'userListNormal'))}`">
    <div v-for="(item, index) in slicedPersons" :key="item.uuid" class="person" :id="'tixfor' + index">
      <UserListItem flat :person="item" :section="section" :typeData="typeData" />
    </div>
    <infinite-loading @infinite="infiniteHandler" :distance="10" >
      <template #error>
        <div v-html="'&nbsp;'"></div>
      </template>
      <template #complete>
        <div v-html="'&nbsp;'"></div>
       </template>
    </infinite-loading>
</div>
</template>

<script>
import UserListItem from './userListItem.vue';
import store from '../../store';
import { isMobile } from "../../lib/mobileUtil";
import InfiniteLoading from "v3-infinite-loading";
export default {
  components: { UserListItem,"infinite-loading": InfiniteLoading, },
  props: ['persons', 'section', 'typeData'],
  data() {
    return {
      state: store.state,
      sliceIndex: 20,
      isMobile: isMobile(),
    };
  },
  methods:{
    // getUserInfo(uuid){
    //   if (this.state.group[uuid]) {
    //     let result = this.state.group[uuid];
    //     result.uuid = uuid;
    //     result.user.uuid = uuid;
    //     return result;
    //   } else {
    //     return false;
    //   }
    // },
    infiniteHandler($state) {
      if (this.sliceIndex < this.persons.length) {
        this.sliceIndex += 10;
        $state.loaded();
      } else {
        $state.complete();
      }
    },
  },
  computed:{
    applyClass() {
      return (this.section == 'Users' && Object.keys(this.persons).length > 1 ) || (this.section == 'Favorites' && Object.keys(this.persons).length > 1 ) || (this.section == 'My Guests' && Object.keys(this.persons).length > 1 ) || (this.section == 'waitingroom' && Object.keys(this.persons).length > 1 )
    },
    applyOne() {
      return (this.state.searchTerm.length != '' && Object.keys(this.persons).length <=1) || (this.section == 'Users' && Object.keys(this.persons).length <= 1 ) || (this.section == 'Favorites' && Object.keys(this.persons).length <= 1) || (this.section == 'My Guests' && Object.keys(this.persons).length <= 1) || (this.section == 'waitingroom' && Object.keys(this.persons).length <= 1)
    },
    applyTwo() {
      return (this.state.searchTerm.length != '' && Object.keys(this.persons).length == 2) || (this.section == 'Users' && Object.keys(this.persons).length == 2 ) || (this.section == 'Favorites' && Object.keys(this.persons).length == 2) || (this.section == 'My Guests' && Object.keys(this.persons).length == 2) || (this.section == 'waitingroom' && Object.keys(this.persons).length <= 1)
    },
    slicedPersons() {
      return this.persons.slice(0, this.sliceIndex);
    },
  }
};
</script>

<style scoped lang="scss">
.vueScrollSidebar {
  .__rail-is-vertical {
    overflow: hidden !important;
    display: none !important;
  }
}
.scroller {
  height: 100%;
}
.vueScrollUsers{
  max-height: calc(100vh - 485px)!important;
    @media screen and (max-height: 650px) {
      max-height: unset !important;
    }
  & .__view{
    width: unset !important;
  }
}
.vueScrollOne{
  height: 140px !important;
  @media screen and (max-height: 650px) {
    height: 140px!important;
  }
}
.vueScrollTwo{
  // max-height: calc(100vh - 720px)!important;
  height: 280px !important;
  @media screen and (max-height: 650px) {
    height: 280px!important;
  }
}
.userListNormal{
  // max-height: calc(100% - 464px);
  max-height: calc(100vh - 63vh);
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-height: 795px) {
    max-height: 280px !important;
  }

}
.userListCoffee{
  max-height: calc(100vh - 73vh);
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-height: 795px) {
    max-height: 280px !important;
  }
}
.userList{
  // max-height: calc(100vh - 22vh);
  // min-height: calc(100vh - 38vh);
  height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-height: 795px) {
    max-height: 280px !important;
  }
}
.userListMobile{
  max-height: calc(100% - 364px);
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>

<style lang="scss">
.vueScrollUsers, .vueScrollOne{
  .__view{
    width: unset !important;
  }
}
</style>