// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-theme--light .noWhiteBg[data-v-7afaade8] {
  background: transparent !important;
}
.v-theme--dark .noWhiteBg[data-v-7afaade8] {
  background: transparent !important;
}
.noWhiteBg[data-v-7afaade8] {
  color: #38a2b8 !important;
}
.noWhiteBg svg[data-v-7afaade8] {
  font-size: 15px !important;
}
.locationIcon[data-v-7afaade8] {
  background-color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/locationIcon.vue","webpack://./locationIcon.vue"],"names":[],"mappings":"AACA;EACE,kCAAA;ACAF;ADGA;EACE,kCAAA;ACAF;ADGA;EACE,yBAAA;ACAF;ADCE;EACE,0BAAA;ACCJ;ADEA;EAEE,yBAAA;ACAF","sourcesContent":["\n.v-theme--light .noWhiteBg {\n  background: transparent!important;\n  // background: linear-gradient(#00000040, #00000099) !important;\n}\n.v-theme--dark .noWhiteBg {\n  background: transparent!important;\n  // background: linear-gradient(#00000099, #000000cc) !important;\n}\n.noWhiteBg {\n  color: #38a2b8 !important;\n  svg {\n    font-size: 15px !important;\n  }\n}\n.locationIcon {\n  // pointer-events: none;\n  background-color: #ffffff;\n}\n",".v-theme--light .noWhiteBg {\n  background: transparent !important;\n}\n\n.v-theme--dark .noWhiteBg {\n  background: transparent !important;\n}\n\n.noWhiteBg {\n  color: #38a2b8 !important;\n}\n.noWhiteBg svg {\n  font-size: 15px !important;\n}\n\n.locationIcon {\n  background-color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
