// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-btn--icon.v-size--large[data-v-540f6900] {
  width: 40px !important;
  height: 40px !important;
}
.darkGreyIcon[data-v-540f6900] {
  background-color: #464b4e !important;
  border-color: #464b4e !important;
}
.greenClass[data-v-540f6900] {
  font-weight: 800;
  color: green;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/calls/LocalBar/localLeftBar.vue","webpack://./localLeftBar.vue"],"names":[],"mappings":"AACA;EACE,sBAAA;EACA,uBAAA;ACAF;ADEA;EACE,oCAAA;EACA,gCAAA;ACCF;ADEA;EACE,gBAAA;EACA,YAAA;ACCF","sourcesContent":["\n.v-btn--icon.v-size--large{\n  width: 40px!important;\n  height: 40px!important;\n}\n.darkGreyIcon {\n  background-color: #464b4e !important;\n  border-color: #464b4e !important;\n}\n\n.greenClass {\n  font-weight: 800;\n  color: green;\n}\n",".v-btn--icon.v-size--large {\n  width: 40px !important;\n  height: 40px !important;\n}\n\n.darkGreyIcon {\n  background-color: #464b4e !important;\n  border-color: #464b4e !important;\n}\n\n.greenClass {\n  font-weight: 800;\n  color: green;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
