import { reminderModalDomain } from './domain';

import { setReminderModalEvent, resetReminderModalEvent } from './events';

export const reminderModalStore = reminderModalDomain.createStore(false);

reminderModalStore.on(setReminderModalEvent, (state, payload) => {
  return payload;
});

reminderModalStore.reset(resetReminderModalEvent);