<template>
  <div style="padding: 2px">
        <v-btn
          :width="isRegularGrid ? 25 : 20"
          :height="isRegularGrid ? 25 : 20"
          icon
          :color="isRegularGrid ? '#ffffff' : '#0000008a'"
          :class="{ noWhiteBg: isRegularGrid }"
          :style="
            defaultAvatar
              ? 'color:#808080;'
              : isRegularGrid
              ? 'color:#ffffff; font-size: 15px;'
              : null
        "
          v-on:click.stop.prevent="callUser(person.uuid || person.user.uuid)"
          v-bind:disabled="disableCallBtn(person)"
          class="callIcon"
        >
          <font-awesome-icon
            :icon="['fal', 'phone']"
            :style="{ fontSize: '14px' }"
          />
        </v-btn>
  </div>
</template>
<script>
import store from "../../store";
import { callPerson, disableCallBtn } from "../../utils/calls";
export default {
  props: ["person", "defaultAvatar", "isRegularGrid"],
  data() {
    return {
      state: store.state,
    };
  },
  methods: {
    callUser(uuid) {
      callPerson(uuid, this.$router);
    },
    disableCallBtn(personObj) {
      let person = personObj;
      return disableCallBtn(person);
    },
  },
};
</script>
<style scoped lang="scss">
.v-theme--light .noWhiteBg {
  background: linear-gradient(#00000040, #00000099) !important;
}
.v-theme--dark .noWhiteBg {
  background: linear-gradient(#00000099, #000000cc) !important;
}
.noWhiteBg {
  color: white !important;
  svg {
    font-size: 15px !important;
  }
}
.callIcon {
  background-color: #ffffff;
}
</style>