/**
 * @param {string} str
 */
function isJSON(str) {
  if (!str || typeof str !== 'string') return false;
  // https://github.com/prototypejs/prototype/blob/9e78eff0245c23b3104a814db0920f9b9740f938/src/prototype/lang/string.js#L715
  str = str.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@'); // eslint-disable-line no-useless-escape
  str = str.replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']'); // eslint-disable-line no-useless-escape
  str = str.replace(/(?:^|:|,)(?:\s*\[)+/g, '');
  return (/^[\],:{}\s]*$/).test(str);
}

/**
 * @param {string} str
 * @returns {unknown|undefined}
 */
function parseJSON(str) {
  let o = isJSON(str) || undefined;
  try {
    o = o && JSON.parse(str);
  } catch (e) {
    o = undefined;
  }
  return o;
}

/**
 * Converts an ArrayBuffer to a URL-safe base64 string.
 * @param {ArrayBuffer} arrayBuffer The ArrayBuffer to convert.
 * @returns {string} The URL-safe base64 encoded string.
 */
function arrayBufferToBase64Url(arrayBuffer) {
  const uint8Array = new Uint8Array(arrayBuffer);
  const base64String = (typeof window !== 'undefined')
    ? window.btoa(String.fromCharCode.apply(null, uint8Array)) // For browser
    : Buffer.from(uint8Array).toString('base64'); // For Node.js
  return base64String
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

/**
 * Converts a URL-safe base64 string to a Uint8Array.
 * @param {string} base64String The base64 string to convert.
 * @returns {Uint8Array} The resulting Uint8Array.
 */
function uint8ArrayFromBase64Url(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  if (typeof window !== 'undefined') { // For browser
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  } else { // For Node.js
    return new Uint8Array(Buffer.from(base64, 'base64'));
  }
}

module.exports = {
  default: {
    isJSON,
    parseJSON,
    arrayBufferToBase64Url,
    uint8ArrayFromBase64Url,
  },
  isJSON,
  parseJSON,
  arrayBufferToBase64Url,
  uint8ArrayFromBase64Url,
};
