// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.croppie-container[data-v-43fed479] {
  height: unset;
}
.croppie-container .cr-viewport[data-v-43fed479],
.croppie-container .cr-resizer[data-v-43fed479] {
  border: 2px solid #2a3133 !important;
}
.rotateIcons[data-v-43fed479] {
  position: absolute;
  right: 10%;
  top: 25%;
}
.rotateIcons button[data-v-43fed479]:nth-of-type(2) {
  margin-top: 10px;
}
.twitter-cropper[data-v-43fed479] {
  height: 256px;
  width: 256px;
}
.twitter-cropper__background[data-v-43fed479] {
  background-color: #edf2f4;
}
.twitter-cropper__foreground[data-v-43fed479] {
  background-color: #edf2f4;
}
.twitter-cropper__stencil[data-v-43fed479] {
  border: solid 5px rgb(29, 161, 242);
}
.croppie-container[data-v-43fed479] {
  height: unset;
}
.croppie-container .cr-viewport[data-v-43fed479],
.croppie-container .cr-resizer[data-v-43fed479] {
  border: 2px solid #2a3133 !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/uploadAvatarModal.vue","webpack://./uploadAvatarModal.vue"],"names":[],"mappings":"AAEA;EACE,aAAA;ACDF;ADGA;;EAEE,oCAAA;ACAF;ADEA;EACE,kBAAA;EACA,UAAA;EACA,QAAA;ACCF;ADAE;EACA,gBAAA;ACEF;ADCA;EACE,aAAA;EACA,YAAA;ACEF;ADDE;EACE,yBAAA;ACGJ;ADDE;EACE,yBAAA;ACGJ;ADDE;EACE,mCAAA;ACGJ;ADAA;EACE,aAAA;ACGF;ADDA;;EAEE,oCAAA;ACIF","sourcesContent":["\n\n.croppie-container {\n  height: unset;\n}\n.croppie-container .cr-viewport,\n.croppie-container .cr-resizer {\n  border: 2px solid #2a3133 !important;\n}\n.rotateIcons {\n  position: absolute;\n  right: 10%;\n  top: 25%;\n  button:nth-of-type(2) {\n  margin-top: 10px;\n  }\n}\n.twitter-cropper {\n  height: 256px;\n  width: 256px;\n  &__background {\n    background-color: #edf2f4;\n  }\n  &__foreground {\n    background-color: #edf2f4;\n  }\n  &__stencil {\n    border: solid 5px rgb(29, 161, 242);\n  }\n}\n.croppie-container {\n  height: unset;\n}\n.croppie-container .cr-viewport,\n.croppie-container .cr-resizer {\n  border: 2px solid #2a3133 !important;\n}\n",".croppie-container {\n  height: unset;\n}\n\n.croppie-container .cr-viewport,\n.croppie-container .cr-resizer {\n  border: 2px solid #2a3133 !important;\n}\n\n.rotateIcons {\n  position: absolute;\n  right: 10%;\n  top: 25%;\n}\n.rotateIcons button:nth-of-type(2) {\n  margin-top: 10px;\n}\n\n.twitter-cropper {\n  height: 256px;\n  width: 256px;\n}\n.twitter-cropper__background {\n  background-color: #edf2f4;\n}\n.twitter-cropper__foreground {\n  background-color: #edf2f4;\n}\n.twitter-cropper__stencil {\n  border: solid 5px rgb(29, 161, 242);\n}\n\n.croppie-container {\n  height: unset;\n}\n\n.croppie-container .cr-viewport,\n.croppie-container .cr-resizer {\n  border: 2px solid #2a3133 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
