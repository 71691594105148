
<template>
  <v-card class="mx-auto vCardSearch" width="100%" flat>
    <template v-if="!getSearchModel">
      <v-tooltip location="top">
        <template v-slot:activator="{ props }">
          <v-list-item-title v-bind="props" class="itemTitleSearch primary--text-sidepanel">
            <v-text-field
              density="compact"
              :label="''"
              class="pb-2 primary--text-sidepanel mainInputSearch mx-0 py-0 inputSearch"
              hide-details="true"
              :model-value="state.searchTerm" @update:model-value="state.searchTerm = $event"
              autofocus
              @blur="handlerBlur"
              @keydown.space="handleSpaceBar"
              :color="'white'"
              ref="searchInput"
            ></v-text-field>
          </v-list-item-title>
        </template>
        <span>{{ $t("generics.userSearch") }}</span>
      </v-tooltip>
    </template>
    <template v-if="getSearchModel">
      <v-tooltip location="top">
        <template v-slot:activator="{ props }">
          <v-list-item-title v-bind="props">
            <v-text-field
              density="compact"
              :color="'white'"
              :label="''"
              class="pb-2 primary--text-sidepanel mainInputSearch mx-0 py-0"
              hide-details="true"
              autofocus
              @blur="handlerBlur"
              @keydown.space="handleSpaceBar"
              :model-value="state.orgaSearchTerm.searching" @update:model-value="state.orgaSearchTerm.searching = $event"
            ></v-text-field>
          </v-list-item-title>
        </template>
        <span>{{ getSearchingText }}</span>
      </v-tooltip>
    </template>
    <v-btn
      v-if="showResetButton"
      icon
      density="comfortable"
       variant="text"
      class="closeButton closeButtonSearch"
      @click.stop.prevent="resetSearch()"
    >
      <font-awesome-icon
        class="primary--text-sidepanel"
        :icon="['fal', 'times']"
        :style="{ fontSize: '16px' }"
      />
    </v-btn>
  </v-card>
</template>
<script>
import store, { EventBus } from "../../store";

export default {
  data() {
    return {
      state: store.state,
    };
  },
  mounted() {
    EventBus.$on("sendOpenCloseSearchField", this.resetSearch);
  },
  unmounted(){
    EventBus.$off("sendOpenCloseSearchField", this.resetSearch);
  },
  computed: {
    isDarkMode() {
      return this.state.persisted.isDark;
    },
    showResetButton() {
      if (
        this.state.orgaSearchTerm.searching.length > 0 ||
        this.state.searchTerm.length > 0
      ) {
        return true;
      }
      return false;
    },
    getSearchingText() {
      if (this.state.orgaSearchTerm.type === "sections") {
        return this.$t("generics.searchSection");
      } else if (this.state.orgaSearchTerm.type === "departments") {
        return this.$t("generics.searchDepartment");
      } else if (this.state.orgaSearchTerm.type === "teams") {
        return this.$t("generics.searchTeam");
      }
    },
    getSearchModel: function () {
      let model = false;
      store.state.isAreaSearch = false;

      if (
        store.state.currentContentVisile.component === "teams" &&
        store.state.currentContentVisile.showComponent === true
      ) {
        model = true;
        store.state.isAreaSearch = true;
        store.state.orgaSearchTerm.type = "teams";
      } else if (
        store.state.currentContentVisile.component === "departments" &&
        store.state.currentContentVisile.showComponent === true
      ) {
        model = true;
        store.state.isAreaSearch = true;
        store.state.orgaSearchTerm.type = "departments";
      } else if (
        store.state.currentContentVisile.component === "sections" &&
        store.state.currentContentVisile.showComponent === true
      ) {
        model = true;
        store.state.isAreaSearch = true;
        store.state.orgaSearchTerm.type = "sections";
      }
      return model;
    },
  },
  methods: {
    /*
        #455 - cannot type spaces into search user box.
        This function prevents spaceBar keyDown to propagate into other components
        that trigger unwanted functions making us loose the focus
    */
    handleSpaceBar(event) {
      // event.preventDefault();
      event.stopPropagation();
    },
    handlerBlur() {
      if (
        this.state.searchTerm === "" &&
        this.state.orgaSearchTerm.searching === ""
      ) {
        this.state.showSearch = false;
      }
    },
    resetSearch() {
      this.state.searchTerm = "";
      this.state.orgaSearchTerm.searching = "";
      this.state.showSearch = false;
    },
  },
};
</script>

<style lang="scss">
.mainInputSearch {
  width: calc(100% - 7px);
  margin-top: 8px !important;
  margin-left: 2px !important;
}
.mainInputSearch {
  .v-text-field--solo .v-input__control {
    min-height: 30px !important;
  }
}
.titleGroup {
  .v-icon {
    font-size: 14px !important;
  }
}

.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.vCardSearch {
  & .itemTitleSearch {
    width: calc(100% - 36px) !important;
  }
}
</style>
<style scoped lang="scss">
.vCardSearch {
  display: flex;
  background: transparent !important;
  & .closeButtonSearch {
    position: relative;
    top: 10px;
    right: -7px;
  }
}
</style>