<template>
  <div>
    <div>Ws Connected: {{ wsConnected }}</div>
    <!-- <div>AllUsers Length: {{ allUserUUIDs.length }}</div> -->
    <div>{{thisGuyName}}'s Activity: {{ thisGuyActivity }}</div>
    <div>{{index}} {{thisGuysUUID}}</div>
    <div>{{timelineEvents.length}} {{unknownTimelineEvents.length}}</div>
  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import { wsConnectionStateStore } from '../../effector/ws';
import { getUserStateStore, allUserUUIDs } from '../../effector/users';
import { timelineEvents, unknownTimelineEvents } from '../../effector/timeline';
export default {
  data() {
    const effector = {
      wsConnected: wsConnectionStateStore,
      allUserUUIDs: allUserUUIDs,
      timelineEvents,
      unknownTimelineEvents,
      // messagesToMe,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      index: 0,
      // Effector
    ...effector,
    };
  },

  computed: {
    thisGuysUUID() {
      return this.allUserUUIDs[this.index];
    },
    thisGuyStore() {
      return getUserStateStore(this.thisGuysUUID);
    },
    thisGuy() {
      return this.thisGuyStore.getState();
    },
    thisGuyName() {
      if (!this.thisGuy || !this.thisGuy.user) return 'NonExtant!';
      return this.thisGuy.user.name;
    },
    thisGuyActivity() {
      if (!this.thisGuy || !this.thisGuy.user) return 'NonExtant!';
      return this.thisGuy.user.activity;
    },
  },
  created() {
    // this._int = setInterval(this.random.bind(this), 1000);
  },
  unmounted() {
    // clearInterval(this._int);
  },
  methods: {
    random() {
      if (!this.allUserUUIDs) return 0;
      this.index = Math.floor(Math.random() * this.allUserUUIDs.length);
    },
  },
};
</script>

<style scoped lang="scss">

</style>