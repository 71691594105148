
  <template>
  <div v-if="userExists" class="h100">
    <div v-if="isCardStyle" class="h100">
      <v-card
      width="100%"
      class="cardStyle"
      :style="getBorderByStatusFull(getPerson(user.uuid))"
      >
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <div v-bind="props" class="h100">
              <div class="topbar">
                <div class="userIconInfo">
                  <font-awesome-icon
                    v-if="user.isModerator"
                    class="mr-2"
                    :icon="['fal', 'users-crown']"
                    :style="{ fontSize: '20px' }"
                  />
                  <font-awesome-icon
                    v-if="!user.isGuest && !user.isModerator"
                    class="mr-2"
                    :icon="['fal', 'user']"
                    :style="{ fontSize: '20px' }"
                  />
                  <font-awesome-icon
                    v-if="user.isGuest"
                    class="mr-2"
                    :icon="['fal', 'user-clock']"
                    :style="{ fontSize: '20px' }"
                  />
                </div>
                <v-card-subtitle
                  style="color: #fff !important"
                  class="
                    callerName
                    font-weight-light
                    pt-0
                    pb-0
                    px-0
                    text-truncate
                    opacity-1
                  "
                >
                  {{ getTitelForUuid(user.uuid) }}&nbsp; {{ getName(user) }}
                </v-card-subtitle>
                <div class="frontButtons">
                  <v-menu
                    offset-y
                    scrollable
                    :close-on-click="true"
                    v-if="canEdit && user.uuid !== groupModerator"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        icon
                        variant="text"
                        density="compact"
                        v-bind="props"
                        style="float: right"
                        v-if="canEdit"
                        class="buttonCallFrontCard"
                      >
                        <font-awesome-icon
                          color="white"
                          :icon="['fal', 'ellipsis-v']"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-if="isEligible"
                        @click="toggleModerator(user)"
                      >
                        <v-list-item-title v-if="!user.isModerator">{{
                          $t("components.conferenceForm.makeModerator")
                        }}</v-list-item-title>
                        <v-list-item-title v-if="user.isModerator">{{
                          $t("components.conferenceForm.removeModerator")
                        }}</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        v-if="amIAdmin()"
                        @click="resendInvite(user.uuid)"
                      >
                        <!-- :disabled="!isEditingConference" -->
                        <v-list-item-title>{{
                          $t("components.inviteVisitor.resendInvLink")
                        }}</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        v-if="
                          !user.isGroup &&
                          !user.isTeam &&
                          !hasPrivilege(user.uuid)
                        "
                        @click="copyLink($event, user.uuid)"
                      >
                        <!-- :disabled="!isEditingConference" -->
                        <v-list-item-title>{{
                          $t("components.inviteVisitor.copyLink")
                        }}</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        v-if="!user.isGroup && !user.isTeam"
                        @click="checkSendMessage(user.uuid)"
                        :disabled="!isEditingConference"
                      >
                        <v-list-item-title>{{
                          $t("components.userProfile.sendAMessage")
                        }}</v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        v-if="user.uuid !== groupModerator"
                        @click="preDeleteUser($event, user)"
                      >
                      <v-tooltip location="top">
                        <template v-slot:activator="{ props }">
                          <v-list-item-title
                            v-bind="props"
                            :class="
                              !deleteActive
                                ? 'deleteBasketButtonNormal'
                                : 'deleteBasketButtonActive'
                            "
                            >{{
                              $t("components.conferenceForm.delete")
                            }}</v-list-item-title
                          >
                        </template>
                        <span>{{
                          $t("components.conferenceForm.confirmRemove")
                        }}</span>
                      </v-tooltip>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
              <v-img
                :src="getAvatarByType(user.uuid)"
                class="text-white align-end mx-auto"
                height="100%"
                width="256px"
              >
                <div
                  class="badMailDiv bg-red"
                  v-if="user.isGuest && !user.isModerator && userHasEmailError"
                >
                  <v-tooltip location="top" max-width="300">
                    <template #activator="{ props }">
                      <font-awesome-icon
                        v-bind="props"
                        :color="'white'"
                        :icon="['fas', 'envelope']"
                        :style="{ fontSize: '18px' }"
                      />
                    </template>
                    <span> {{ $t("components.inviteVisitor.badEmail") }}</span>
                  </v-tooltip>
                </div>
                <div
                  class="rsvpDiv bg-red"
                  v-if="
                    userHasAcceptedEvent === false &&
                    !user.isTeam &&
                    !user.isGroup &&
                    !user.isModerator 
                  "
                >
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <font-awesome-icon
                        v-bind="props"
                        :color="'white'"
                        :icon="['fal', 'times']"
                        :style="{ fontSize: '18px' }"
                      />
                    </template>
                    <span>
                      {{
                        user.isGuest
                          ? $t("components.conferenceForm.rejectedGuest")
                          : $t("components.conferenceForm.rejectedParticipant")
                      }}</span
                    >
                  </v-tooltip>
                </div>
                <div
                  class="rsvpDiv bg-green"
                  v-if="
                    userHasAcceptedEvent === true &&
                    !user.isTeam &&
                    !user.isGroup &&
                    !user.isModerator
                  "
                >
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <font-awesome-icon
                        v-bind="props"
                        :color="'white'"
                        :icon="['fal', 'check']"
                        :style="{ fontSize: '18px' }"
                      />
                    </template>
                    <span>
                      {{
                        user.isGuest
                          ? $t("components.conferenceForm.acceptedGuest")
                          : $t("components.conferenceForm.acceptedParticipant")
                      }}</span
                    >
                  </v-tooltip>
                </div>
              </v-img>
            </div>
          </template>
          <UserInfoTooltip :person="getPerson(user.uuid)"></UserInfoTooltip>
        </v-tooltip>
      </v-card>
    </div>
    <div v-else>
      <div style="position: relative">
        <font-awesome-icon
          :color="'#2a3133'"
          :icon="['fas', 'crown']"
          style="z-index: 9"
          :style="{
            fontSize: '18px',
            position: 'absolute',
            top: 0,
            left: -11,
            transform: 'rotate(-20deg)',
          }"
          v-if="user.isModerator"
        />
        <v-tooltip
          top
          max-width="300"
          v-if="user.isGuest && !user.isModerator && userHasEmailError"
        >
          <template v-slot:activator="{ props }">
            <font-awesome-icon
              v-bind="props"
              :color="'#ff0000'"
              :icon="['fas', 'envelope']"
              style="z-index: 9"
              :style="{
                fontSize: '18px',
                position: 'absolute',
                top: -10,
                left: -11,
                transform: 'rotate(-20deg)',
              }"
            />
          </template>
          <span>{{ $t("components.inviteVisitor.badEmail") }}</span>
        </v-tooltip>
        <v-img
          :style="
            getBorderByStatusFull(getPerson(user.uuid), '3px')
          "
          contain
          :src="user.isGroup ? user.groupAvatar : getAvatarByType(user.uuid)"
          class="userAvatar"
        >
        </v-img>
        <v-card-subtitle
          class="text-truncate ml-2 px-0 py-0 userName font-weight-medium"
        >
          {{ getTitelForUuid(user.uuid) }}&nbsp;{{ getName(user) }}
          <br />
          {{
            user.isModerator
              ? $t("components.conferenceForm.moderator")
              : user.isGuest
              ? $t("components.conferenceForm.guest")
              : $t("components.conferenceForm.user")
          }}
        </v-card-subtitle>

        <v-tooltip
          top
          v-if="
            userHasAcceptedEvent === false &&
            !user.isTeam &&
            !user.isGroup &&
            !user.isModerator
          "
        >
          <template v-slot:activator="{ props }">
            <font-awesome-icon
              v-bind="props"
              :color="'red'"
              :icon="['fal', 'times']"
              :style="{ fontSize: '20px', marginLeft: 5 }"
            />
          </template>
          <span>
            {{
              user.isGuest
                ? $t("components.conferenceForm.rejectedGuest")
                : $t("components.conferenceForm.rejectedParticipant")
            }}</span
          >
        </v-tooltip>
        <v-tooltip
          top
          v-if="
            userHasAcceptedEvent === true &&
            !user.isTeam &&
            !user.isGroup &&
            !user.isModerator
          "
        >
          <template v-slot:activator="{ props }">
            <font-awesome-icon
              v-bind="props"
              :color="'green'"
              :icon="['fal', 'check']"
              :style="{ fontSize: '20px', marginLeft: 5 }"
            />
          </template>
          <span>
            {{
              user.isGuest
                ? $t("components.conferenceForm.acceptedGuest")
                : $t("components.conferenceForm.acceptedParticipant")
            }}</span
          >
        </v-tooltip>
        <v-menu
          offset-y
          scrollable
          :close-on-click="true"
          v-if="canEdit && user.uuid !== groupModerator"
        >
          <template v-slot:activator="{ props }">
            <v-btn variant="text" density="compact" icon v-bind="props" style="float: right" v-if="canEdit">
              <font-awesome-icon
                :icon="['fal', 'ellipsis-v']"
                :style="{ fontSize: '20px' }"
              />
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="isEligible" @click="toggleModerator(user)">
              <v-list-item-title v-if="!user.isModerator">{{
                $t("components.conferenceForm.makeModerator")
              }}</v-list-item-title>
              <v-list-item-title v-if="user.isModerator">{{
                $t("components.conferenceForm.removeModerator")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="amIAdmin()"
              @click="resendInvite(user.uuid)"
              :disabled="!isEditingConference"
            >
              <v-list-item-title>{{
                $t("components.inviteVisitor.resendInvLink")
              }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="!user.isGroup && !user.isTeam && !hasPrivilege(user.uuid)"
              @click="copyLink($event, user.uuid)"
              :disabled="!isEditingConference"
            >
              <v-list-item-title>{{
                $t("components.inviteVisitor.copyLink")
              }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="!user.isGroup && !user.isTeam"
              @click="checkSendMessage(user.uuid)"
              :disabled="!isEditingConference"
            >
              <v-list-item-title>{{
                $t("components.userProfile.sendAMessage")
              }}</v-list-item-title>
            </v-list-item>
              <v-list-item
                v-if="user.uuid !== groupModerator"
                @click="preDeleteUser($event, user)"
              >
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-list-item-title
                      v-bind="props"
                      :class="
                        !deleteActive
                          ? 'deleteBasketButtonNormal'
                          : 'deleteBasketButtonActive'
                      "
                      >{{ $t("components.conferenceForm.delete") }}
                    </v-list-item-title>
                  </template>
                  <span>{{
                    $t("components.conferenceForm.confirmRemove")
                  }}</span>
                </v-tooltip>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import { getUserInviteLink } from "../../lib/wsMsg";
import { timelineEvents } from "../../effector/timeline";
import { dispatchSuccessAlert } from "../../effector/alerts";
import {
  getAvatarByType,
  getBorderByStatusFull,
} from "../../utils/basicFunctions";
import { hasPrivilege } from "../../utils/privileges";
import { setSendMessageModalEvent } from "../../effector/modals";
import UserInfoTooltip from "./userInfoTooltip.vue";
import ButtonsCardWrapper from "./buttonsCardWrapper.vue";
export default {
  components: {
    UserInfoTooltip,
    ButtonsCardWrapper,
  },
  props: [
    "user",
    "createGuestInvite",
    "createUserInvite",
    "groupModerator",
    "isEditingConference",
    "deleteUser",
    "toggleModerator",
    "isConferenceUser",
    "canEditEvent",
    "conference",
    "isCardStyle"
  ],
  data() {
    const effector = {
      timelineEvents: timelineEvents,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      deleteActive: false,
      dataCanEdit: false,
      // Effector
      ...effector,
    };
  },
  watch: {},

  computed: {
    userHasEmailError() {
      return store.state.group[this.user.uuid].user.visitorData.hasEmailError;
    },
    userExists() {
      if (this.user && this.state.group[this.user.uuid]) {
        return true;
      }
      return false;
    },
    userHasAcceptedEvent() {
      if (!this.conference) return;
      let hasDoneIt = false;
      const timelineEv = this.timelineEvents.find(
        (e) => e.confId === this.conference.confId
      );
      if (
        !timelineEv ||
        !timelineEv.rsvp ||
        this.user.uuid === null ||
        !timelineEv.rsvp[this.user.uuid]
      ) {
        hasDoneIt = null;
      } else if (timelineEv.rsvp[this.user.uuid].status === "rejected") {
        hasDoneIt = false;
      } else if (timelineEv.rsvp[this.user.uuid].status === "pending") {
        hasDoneIt = null;
      } else if (timelineEv.rsvp[this.user.uuid].status === "accepted") {
        hasDoneIt = true;
      }
      return hasDoneIt;
    },
    canEdit() {
      return this.canEditEvent;
    },
    isEligible() {
      let result = false;
      if (!this.user) return result;
      if (
        this.toggleModerator && 
        this.isConferenceUser &&
        !this.user.isGuest &&
        !this.user.isTeam &&
        !this.user.isGroup &&
        this.user.uuid !== this.groupModerator
      ) {
        result = true;
      } else {
        result = false;
      }
      return result;
    },
  },
  methods: {
    getBorderByStatusFull(person, customCss) {
      return getBorderByStatusFull(person, customCss);
    },
    amIAdmin() {
      if (!this.conference) {
        return true;
      }
      return (
        this.conference.confModerators.indexOf(store.state.ownUUID) !== -1 ||
        store.state.ownUUID === this.groupModerator
      );
    },
    isModerator() {
      return (
        this.conference.confModerators.indexOf(this.user.uuid) !== -1 ||
        this.user.uuid === this.groupModerator
      );
    },
    preDeleteUser(event, user) {
      event.stopPropagation();
      event.preventDefault();
      try {
        if (!this.deleteActive) {
          this.deleteActive = true;
          setTimeout(() => {
            this.deleteActive = false;
          }, 1500);
        } else {
          this.deleteActive = false;
          this.deleteUser(user);
        }
      } catch (err) {
        this.deleteActive = false;
        console.warn("Error deleting member", err);
      }
    },
    getPerson(uuid) {
      return store.state.group[uuid];
    },
    getName(user) {
      if (!user) return "";
      if (user.isGroup) {
        return this.$t("components.groups.agroup") + ": " + user.name;
      } else if (user.isTeam) {
        return this.$t("components.modalNote.team") + ": " + user.name;
      } else {
        return store.getNameForUuid(user.uuid) || "";
      }
    },
    getTitelForUuid(user) {
      return store.getTitelForUuid(user);
    },
    async resendInvite(uuid) {
      const person = store.state.group[uuid];
      if (person) {
        if (person.permissions == 10) {
          this.createGuestInvite(
            person.user,
            this.conference.confId,
            this.conference,
            true
          );
        } else {
          this.createUserInvite(
            person.user,
            this.conference.confId,
            this.conference,
            true
          );
        }
        dispatchSuccessAlert(
          this.$t("components.inviteVisitor.invitationLinkSent")
        );
      }
    },
    async copyLink(evt, uuid) {
      const instalink = await getUserInviteLink(uuid);
      if (instalink) {
        const selBox = document.createElement("textarea");
        selBox.style.position = "fixed";
        selBox.style.left = "0";
        selBox.style.top = "0";
        selBox.style.opacity = "0";
        selBox.value = instalink;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand("copy");
        document.body.removeChild(selBox);
        evt.target.classList.add("greenClass");
        setTimeout(() => {
          evt.target.classList.remove("greenClass");
        }, 1000);
      } else {
        evt.target.classList.add("redClass");
        setTimeout(() => {
          evt.target.classList.remove("redClass");
        }, 1000);
      }
    },

    checkSendMessage(uuid) {
      if (uuid === this.ownUUID) {
        // I'm the user, send message to others managers and users (#178)
        this.showSendMessageToAll = true;
      } else {
        this.setShowModalSenMsg(uuid);
      }
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    setShowModalSenMsg(uuid) {
      setSendMessageModalEvent({
        show: true,
        users: [uuid],
        type: "regularMsg",
      });
    },
    getAvatarByType(uuid) {
      return getAvatarByType(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
  },
};
</script>
<style scoped lang="scss">
.cardStyle {
  height: '100%'; 
  position: 'relative';
   background: '#888888';
}
.greenClass {
  font-weight: 800;
  color: green;
}

.redClass {
  font-weight: 800;
  color: red;
}

.userIconInfo {
  position: absolute;
  left: 13px;
  top: 5px;
  font-size: 15px;
}
.topbar {
  position: absolute;
  width: 100%;
  text-align: center;
  color: white;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  height: 30px;
  font-size: 15px;
  top: 0px;
  padding: 4px;
  & .callerName {
    max-width: 80%;
    margin: 0 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    opacity: 1;
  }
}
.badMailDiv {
  position: absolute;
  top: 70px;
  height: 25px;
  width: 25px;
  padding: 3px;
  right: 3px;
  border-radius: 50%;
  text-align: center;
  z-index: 999;
  &.red {
    background-color: red;
  }
  &.green {
    background-color: green;
  }
}
.rsvpDiv {
  position: absolute;
  top: 35px;
  height: 25px;
  width: 25px;
  padding: 3px;
  right: 3px;
  border-radius: 50%;
  text-align: center;
  z-index: 999;
  &.red {
    background-color: red;
  }
  &.green {
    background-color: green;
  }
}
.buttonCallFrontCard {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  font-size: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.cardWidth {
  max-width: 235px;
  box-shadow: none !important;
}

.frontButtons {
  position: absolute;
  right: 1px;
  bottom: 6px;
  padding: 0px;
  border-radius: 2px;
}
.deleteBasketButtonNormal {
  color: #201f1f !important;
}
.deleteBasketButtonActive {
  color: red !important;
  font-weight: 600 !important;
}
.userAvatar {
  height: 40px;
  width: 40px;
  display: inline-block;
  vertical-align: middle;
}
.userName {
  vertical-align: middle;
  display: inline-block;
  max-width: 50%;
}
@media (max-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
@media (min-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
@media (min-width: 765px) and (max-width: 1024px) {
  .cardWidth {
    max-width: 175px !important;
    min-width: 175px !important;
  }
}
</style>