<template>
  <v-dialog :model-value="showFullBridgeCallModal" @update:model-value="showFullBridgeCallModal = $event" persistent max-width="560" @keydown.esc="closeModal">
     <v-card>
      <HeaderModal
        :titleModal="$t('generics.info')"
        :closeModalFunction="closeModal"
      />
     <v-row class="mx-0">
     <v-col cols="12">
       {{$t('generics.fullBridgeCall')}}
      </v-col>
      </v-row>
      <template>
        <FooterModal
          :closeModalFunction="closeModal"
        >
        </FooterModal>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import {
  fullBridgeCallStore,
  resetFullBridgeCallModalEvent,
} from "../../effector/modals";
export default {
  props: [],
    components: {HeaderModal, FooterModal},
  data(){
    const effector = {
      showFullBridgeCallModal: fullBridgeCallStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      // Effector
      ...effector,
    };
  },
  mounted() {},
  unmounted() {},
  methods: {
    closeModal() {
      resetFullBridgeCallModalEvent();
    },
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
.thumbs-down {
  color: red;
}
.thumbs-up {
  color: green;
}
</style>