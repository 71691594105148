<template>
  <div>
    <v-row class="mx-0 py-2 text-center takeSnapshotScreen">
      <v-col class="px-0 takeSnapshotScreenSquare" cols="12">
        <!-- <WebCam
          ref="webcam"
          :device-id="deviceId"
          @error="onError"
          @cameras="onCameras"
          @camera-change="onCameraChange"
        /> -->
        <WebCam ref="webcam" @photoTaken="photoTakenEvent" :classList="'videoWebcam'"   @init="webcamInit"   />
      </v-col>
    </v-row>
    <v-card color="primary" class="py-2 pl-4 footerModal">
      <v-row class="px-2 btns mx-0">
        <v-col lg="6" class="px-0" align="right">
          <v-btn class="buttonPrimaryFooter" color="white" @click="take_snapshot">{{
            $t("components.camera.takeSnapshot")
          }}</v-btn>

          <v-btn
            style="border-radius: 4px!important; min-width: 45px"
            class="ml-3 mr-2"
            color="white"
            outlined
            icon
            tile
            @click="onStop"
          >
            <font-awesome-icon
              :icon="['fal', 'times']"
              :style="{ fontSize: '20px' }"
            />
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-spacer></v-spacer>
  </div>
</template>

<script>
import store from "../../store";
import { WebCam } from 'vue-camera-lib'
import {setErrorCameraPermissionsModalEvent} from "../../effector/modals"
export default {
  props: ["getSnapshot", "closeCamera"],
  components: { WebCam },
  data: () => ({
    state: store.state,
    showCam: false,
    showCroppie: false,
    deviceId: null,
    devices: [],
    cameras: [],
  }),
  mounted() {
    this.getSetting();
    this.cameras = this.$refs.webcam.cameras;
    if (this.cameras.length === 0) {
      // if no camera found, we will try to refresh cameras list each second until there is some camera
      let reloadCamInterval = setInterval(() => {
        this.loadCameras();
        if (this.cameras.length > 0) {
          clearInterval(reloadCamInterval);
        }
      }, 1000);
    }
  },
  watch: {
    // camera: function(id) {
    //   this.deviceId = id;
    // },
    devices: function () {
      this.getSetting();
      if (this.deviceId) return;
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        // this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    },
  },
  methods: {
    photoTakenEvent({ blob, image_data_url }) {
      store.settmpSnapshot(image_data_url);
      store.setErrorWebcam(undefined);
      this.getSnapshot(image_data_url);
      this.$emit('photoTaken', { blob, image_data_url })
    },
    loadCameras() {
      this.$refs.webcam.loadCameras();
      this.cameras = this.$refs.webcam.cameras;
    },
    webcamInit(deviceId) {
      this.deviceId = deviceId;
      this.$emit('init', this.deviceId);
    },
    getSetting() {
      const videoSetting = store.getRtcVideo();
      if (videoSetting && videoSetting.deviceId) {
        this.deviceId = videoSetting.deviceId;
      }
    },
    settmpSnapshot: function (...args) {
      store.settmpSnapshot(...args);
    },
    async take_snapshot() {
      try {
          await this.$refs.webcam.takePhoto();
      } catch (err) {
          console.log(err)
      }
      // const data_uri = this.$refs.webcam.capture();
      // store.settmpSnapshot(data_uri);
      // store.setErrorWebcam(undefined);
      // this.getSnapshot(data_uri);
    },
    // onStarted(stream) {
    //   console.log("On Started Event", stream);
    // },
    // onStopped(stream) {
    //   console.log("On Stopped Event", stream);
    // },
    onStop() {
      this.$refs.webcam.stop();
      store.setErrorWebcam(undefined);
      this.closeCamera();
    },
    onStart() {
      this.$refs.webcam.start();
    },
    onError(error) {
      if(error.name === 'NotAllowedError'){
        setErrorCameraPermissionsModalEvent(true)
        this.closeCamera()
      }
      store.setErrorWebcam(true);
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      // this.camera = deviceId;
    },
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
.takeSnapshotScreen video {
  height: 200px;
  width: 267px;
}
.takeSnapshotScreenSquare {
  max-width: 200px;
  overflow: hidden;
  min-height: 190px;
  margin: 0 auto;
}
</style>

