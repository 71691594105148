<template>
  <div>
    <v-card
      class="mx-auto drawerContentCard primaryBackground"
      :class="`${ !hasPrivilege ? 'guestWaitingRoomVcardStyles' : ''}`"
      :style="pushDrawerContentCard ? 'z-index: 1' : ''"
      min-width="320"
      max-width="320"
      id="drawerContentCard"
      v-if="(!mini && isMobile) || !isMobile"
    >
      <UploadUserPictureModal
        v-if="!hasUserAvatar && !amIGuest && showPictureModal"
        :showUploadUserPictureModal="showUploadUserPictureModal"
        :toggleUploadUserPictureModal="toggleUploadUserPictureModal"
      />
      <v-list-item lines="three">
        <v-avatar
          v-if="hasPrivilege"
          tile
          size="60"
          class="customAvatar borderRadius10 cursorPointer"
        >
          <v-tooltip location="bottom">
            <template v-slot:activator="{ props }">
              <DefaultAvatar
                v-bind="props"
                :size="60"
                :userData="state.user"
                v-if="state.user.avatar === 'img/default_profile_picture.png'"
                :setUserInModal="handleOpenModal"
              ></DefaultAvatar>
              <v-img
                v-bind="props"
                v-if="
                  !showCamera &&
                  state.user.avatar !== 'img/default_profile_picture.png'
                "
                class="mr-2 borderRadius10"
                max-height="60"
                max-width="60"
                :contain="getPictureSize"
                :src="state.user.avatar"
                lazy-src="assets/default_profile_picture.png"
                @click="handleOpenModal"
              ></v-img>
              <CameraPreview v-bind="props" v-if="showCamera" :width="80"></CameraPreview>
            </template>
            <UserInfoTooltip v-if="state.group && ownUUID in state.group" :person="state.group[ownUUID]"></UserInfoTooltip>
          </v-tooltip>
        </v-avatar>
        <v-tooltip bottom v-if="!hasPrivilege">
          <template v-slot:activator="{ props }">
            <v-avatar
              v-bind="props"
              tile
              size="60"
              class="customAvatar borderRadius10 cursorPointer"
            >
              <DefaultAvatar
                :size="60"
                :userData="state.user"
                v-if="state.user.avatar === 'img/default_profile_picture.png'"
                :setUserInModal="handleOpenModal"
              ></DefaultAvatar>
              <v-img
                v-if="
                  !showCamera &&
                  state.user.avatar !== 'img/default_profile_picture.png'
                "
                class="mx-2 borderRadius10"
                max-height="60"
                max-width="60"
                :contain="getPictureSize"
                :src="state.user.avatar"
                lazy-src="assets/default_profile_picture.png"
                @click="handleOpenModal"
              ></v-img>
              <CameraPreview v-if="showCamera" :width="80"></CameraPreview>
            </v-avatar>
          </template>
          <span>{{ $t("components.drawerContentCard.videoTestTT") }}</span>
        </v-tooltip>
        <CustomTT
          v-if="showCustomTT"
          :text="`${$t('components.hotkeys.controlKey')} + P`"
          :extraclass="'ctrP'"
          position="bottom"
        />
        <div :class="!hasPrivilege ? 'pt-0' : 'pt-1'">
          <div
            v-if="!hasPrivilege"
            class="font-weight-bold text-truncate"
            style="max-width: 178px; min-width: 178px"
          >
            <span>{{ $t("components.drawerContentCard.visitorLabel") }}</span>
          </div>
          <div style="display: flex">
            <div class="font-weight-bold text-truncate">
              <span class="spanUserName text-truncate"
                >{{ titel }} {{ state.user.name }}</span
              >
              <template v-if="hasPrivilege">
                <v-list-item-subtitle
                  class="d-flex statusSection locationSection my-0"
                >
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <div v-bind="props" class="d-flex">
                        <v-menu
                          :model-value="showMenuLocation" @update:model-value="showMenuLocation = $event"
                          :open-on-click="hideUserLocation ? false : true"
                          offset-y
                          :close-on-click="true"
                          max-height="450"
                          max-width="350"
                        >
                          <template v-slot:activator="{ props }">
                            <div
                              class="mx-1 cursorPointer statusLabel"
                              v-bind="props"
                            >
                              <template v-if="!hideUserLocation">
                                <font-awesome-icon
                                  v-if="
                                    state.user.userLocation === 'homeOffice'
                                  "
                                  :icon="['fal', 'house-user']"
                                  color="white"
                                  :style="{ fontSize: '16px' }"
                                />
                                <font-awesome-icon
                                  v-else-if="
                                    state.user.userLocation === 'outOfHouse'
                                  "
                                  :icon="['fal', 'car-building']"
                                  color="white"
                                />
                                <font-awesome-icon
                                  v-else-if="
                                    state.user.userLocation === 'realoOffice'
                                  "
                                  :icon="['fal', 'building']"
                                  color="white"
                                />
                                <span
                                  style="color:white"
                                  v-else-if="
                                    state.user.userLocation === 'notSpecified'
                                  "
                                  >--</span
                                >
                                <font-awesome-icon
                                  v-else
                                  :icon="['fal', 'location-arrow']"
                                />
                              </template>
                              <template v-if="hideUserLocation">
                                <p class="statusText text-primary">-</p>
                              </template>
                            </div>
                          </template>
                          <vue-scroll>
                            <div style="max-height: 425px">
                              <v-list density="compact" class="rounded">
                                <v-list-item
                                  v-for="(locationOption, i) in getLocations"
                                  :key="i"
                                  @click="setLocation(locationOption)"
                                  :id="'locationItem_' + i"
                                >
                                  <v-list-item-title
                                    style="font-size: .8125rem!important;"
                                    :class="isDarkMode ? 'white--text': 'primary--text'"
                                    class="statusItem font-weight-medium text-body-2"
                                    >{{
                                      locationListTranslated(locationOption)
                                    }}</v-list-item-title
                                  >
                                </v-list-item>
                              </v-list>
                            </div>
                          </vue-scroll>
                        </v-menu>
                      </div>
                    </template>
                    <span>{{
                      $t("components.drawerContentCard.myLocation")
                    }}</span>
                  </v-tooltip>
                </v-list-item-subtitle>
              </template>
            </div>
            <template v-if="hasPrivilege">
              <template v-if="openHolidaysCalendar">
                <HolidaysCalendarModal
                  :userUUID="ownUUID"
                  :closeModal="toggleShowHolidaysModal"
                  :showModal="openHolidaysCalendar"
                  :calendarStatus="calendarStatus"
                ></HolidaysCalendarModal>
              </template>
              <template v-if="openNoCallsModal">
                <NoCallsModal
                  :userUUID="ownUUID"
                  :showModal="openNoCallsModal"
                  :closeModal="toggleShowNoCallsModal"
                  :saveNoCallsStatusFunction="setNoCallsStatus"
                ></NoCallsModal>
              </template>
              <TwofaModalContainer
                v-if="show2faSettings"
                :showModalStatus="show2faSettings"
                :toggle2Modal2faSetting="toggle2Modal2faSetting"
              />
              <AdminSettingModal
                v-if="showAdminSettings"
                :openCloseSidebar="openCloseSidebar"
                :showAdminSettings="showAdminSettings"
                :toggleModalAdminSettings="toggleModalAdminSettings"
              />
              <ErrorRemoteStorage
                v-if="showErrorRemoteStorage"
                :showErrorRemoteStorage="showErrorRemoteStorage"
                :closeModalErrorRemoteStorage="closeModalErrorRemoteStorage"
              />
              <StatusColorModal
                :showStatusColorSettings="showStatusColorSettings"
                :toggleStatusColorSettings="toggleStatusColorSettings"
              />
              <ProtocolModal
                :showProtocol="showProtocol"
                :protocol="protocol"
              />
              <DialUpTimeModal
                :showDialUpTime="showDialUpTime"
                :dialUpTime="dialUpTime"
              />
              <StartViewModal
                v-if="startView"
                :showStartView="showStartView"
                :startView="startView"
              />
            </template>
            <DeviceSetupModal
              v-if="showSetupDevice || showDeviceGuest"
              :showSetupDevice="
                hasPrivilege ? showSetupDevice : showDeviceGuest
              "
              :toggleModalSettings="toggleModalSettings"
            />
            <SpeechliveModal
              :showSpeechlive="showSpeechlive"
              :speechlive="speechlive"
            />
          </div>
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <div
                v-bind="props"
                v-if="!hasPrivilege && getVisitorDate"
                class="text-truncate"
                style="max-width: 178px; min-width: 178px"
                :style="{ fontSize: '14px' }"
              >
                <span>{{ $t("components.drawerContentCard.available") }} </span>
              </div>
            </template>
            <span
              >{{ $t("components.drawerContentCard.availableSince") }}
              {{ getVisitorDate }}</span
            >
          </v-tooltip>
          <ExternalServices />
          <v-row class="mx-0" v-if="hasPrivilege">
            <v-col cols="12" class="pb-1 px-0 d-flex">
              <v-list-item-subtitle class="d-flex statusSection pt-3">
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <div v-bind="props" class="d-flex align-baseline">
                      <v-menu
                        :close-on-click="true"
                        offset-y
                        style="border-radius: 25px;"
                        location="bottom"
                        max-height="456"
                        max-width="350"
                        :model-value="showMenuStatus" @update:model-value="showMenuStatus = $event"
                      >
                        <template v-slot:activator="{ props }">
                          <div class="cursorPointer statusLabel" v-bind="props">
                            <p
                              :class="statusTextClass"
                              :style="`${(isDarkMode && state.user.activity == 'No status') ?  'color:white !important' : 'color:' + borderClass + '!important' }`"
                            >
                              {{ userActivityTranslated }}
                            </p>
                            <font-awesome-icon
                              :icon="['fas', 'chevron-down']"
                              class="primary--text-sidepanel statusIcon"
                            />
                          </div>
                        </template>
                        <vue-scroll>
                          <div style="max-height: 456px">
                            <v-list density="compact" ref="statusContainer" class="rounded">
                              <v-list-item
                                v-for="(
                                  activityOption, i
                                ) in getStatus"
                                :key="i"
                                @click="setStatus(activityOption)"
                                :tabindex="i"
                                :id="'statusItem_' + i"
                              >
                                <v-list-item-title
                                  class="statusItem text-body-2 font-weight-bold"
                                  :style="`${
                                    (isDarkMode && activityOption == 'No status') ?
                                    'color:white !important':
                                    'color:' + getStatusColor(activityOption) + '!important' }`"
                                >{{ $t(`status.${activityOption}`) }}
                                  <v-tooltip v-if="activityOption != 'No status'" top>
                                    <template v-slot:activator="{ props }">
                                      <font-awesome-icon
                                        v-bind="props"
                                        :color="`${
                                      (isDarkMode && activityOption == 'No status') ?
                                      'color:white !important':
                                      'color:' + getStatusColor(activityOption) + '!important' }`"
                                        :icon="['fal', 'info-circle']"
                                        :style="{
                                          fontSize: '17px',
                                        }"
                                      />
                                    </template>
                                    <span>
                                      {{
                                        $t(`status-tooltips.${activityOption}`)
                                      }}
                                    </span>
                                  </v-tooltip>
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </div>
                        </vue-scroll>
                      </v-menu>
                    </div>
                  </template>
                  <span>{{
                    $t("components.drawerContentCard.myStatus")
                  }}</span>
                </v-tooltip>
                <!-- DELETE BASKET AND ADDITIONAL STATUS PENCIL -->
                <v-tooltip top>
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" class="ml-4" icon variant="text" density="compact" x-small color="white" @click.stop.prevent="showCloseAditionalStatusInfoModal()">
                      <font-awesome-icon :icon="['fal', 'pen']" :style="{ fontSize: '14px' }"  />
                    </v-btn>
                  </template>
                  <span>{{
                    $t("components.aditionalStatusInfoModal.title")
                  }}</span>
                </v-tooltip>
                <v-btn variant="text" density="compact" v-show="hasAditionalStatus" class="ml-2" icon x-small color="white" @click.stop.prevent="">
                  <DeleteBasket :color="'white'" :delFunction="deleteAditionalStatus" extraClasses="" fontSize="14" prevent= false />
                </v-btn>
              </v-list-item-subtitle>
              <CustomTT
                v-if="showCustomTT"
                :text="`${$t('components.hotkeys.controlKey')} + A`"
                position="top"
                :extraclass="'ctra'"
              />
            </v-col>
          </v-row>
        </div>
      </v-list-item>
      <v-card
        :color="badgetClassObj"
        class="px-2 noBoxShadow"
        style="height: 36px"
        :style="{
          borderWidth: 1,
          borderColor: borderClass,
          borderStyle: 'solid',
        }"
      >
        <UserActionBar
          :setTotalNotificationCall="setTotalNotificationCall"
          :totalNotificationCall="totalNotificationCall"
          :phoneColorColor="phoneColorColor"
          :totalNotificationTicket="totalNotificationTicket"
          :amIGuest="amIGuest"
          :totalNotificationMessage="totalNotificationMessage"
          :setTotalNotificationMessage="setTotalNotificationMessage"
          :totalNotificationFile="totalNotificationFile"
          :setTotalNotificationFile="setTotalNotificationFile"
          :totalNotificationInvite="totalNotificationInvite"
          :resetInviteUserNotification="resetInviteUserNotification"
          :iconsBlack="!this.wholeBarWithColors()"
          :logout="logout"
          :isMobile="isMobile"
          :setUserInModal="setUserInModal"
          :toggleShowHolidaysModal="toggleShowHolidaysModal"
          :showCamera="showCamera"
          :cameraPreview="cameraPreview"
          :handleStatusClick="handleStatusClick"
        />
      </v-card>
      <AditionalStatusInfoModal
        v-if="showModalAditionalInfoStatus"
        :showModal="showModalAditionalInfoStatus"
        :closeModal="showCloseAditionalStatusInfoModal"
      />
      <RepresentativeModal
        v-if="showForwardUsers"
        :showModal="showForwardUsers"
        :closeModal="showCloseForwardUsers"
      />
      <UserSettingsNewModal
        v-if="showUserSettingsNewModal"
        :showModal="showUserSettingsNewModal"
        :toggle2faSettings="toggle2Modal2faSetting"
        :showProtocol="showProtocol"
        :showDialUpTime="showDialUpTime"
        :showStartView="showStartView"
        :showSpeechlive="showSpeechlive"
        :toggleShowHolidaysModal="toggleShowHolidaysModal"
      />
    </v-card>
    <v-tooltip right v-if="mini && isMobile">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" class="cursorPointer px-3">
          <template v-slot:prepend>
            <v-icon>
              <DefaultAvatar
                :size="30"
                :userData="state.user"
                v-if="state.user.avatar === 'img/default_profile_picture.png'"
                :setUserInModal="handleOpenModal"
              ></DefaultAvatar>
              <v-img
                v-if="
                  !showCamera &&
                  state.user.avatar !== 'img/default_profile_picture.png'
                "
                class="borderRadius50"
                max-height="30"
                max-width="30"
                :contain="getPictureSize"
                :src="state.user.avatar"
                lazy-src="assets/default_profile_picture.png"
                @click.stop.prevent="clickUserImageMini"
              ></v-img>
            </v-icon>
          </template>
        </v-list-item>
      </template>
      <span>{{ $t("components.sidebar.conferencesTT") }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import moment from "../../../sharedsrc/moment";
import { colorStatus, getStatusColorByUser } from "../../utils/status";
import { hasPrivilege, oneCallAndWaitingUser } from "../../utils/privileges";
import {
  personIsInBridgeCall,
  userInStaticRoom
} from "../../utils/calls";
import { wsCall } from "../../lib/wsConnect";
import {
  setNamespaceSetting,
  status2FA,
  removeUserPresenceWatching,
} from "../../lib/wsMsg";
import store, { EventBus, syncedGroupState, syncedUserState } from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import UserInfoTooltip from "../content/userInfoTooltip.vue";
import UserProfile from "../modal/userProfile.vue";
import NotificationDropDown from "./notificationDropDown.vue";
import { requestPermission } from "../../lib/messaging";
import DownloadsDropdown from "./downloadsDropdown.vue";
import DeviceSetupModal from "../modal/deviceSetupModal.vue";
import StatusColorModal from "../modal/statusColorModal.vue";
import CameraPreview from "../camera/cameraPreview.vue";
import DefaultAvatar from "../image/defaultAvatar.vue";
import AdminSettingModal from "../modal/newAdminSettingsModal.vue";
import ErrorRemoteStorage from "../modal/errorRemoteStorage.vue";
import UploadUserPictureModal from "../modal/uploadUserPictureModal.vue";
import HolidaysCalendarModal from "../modal/holidaysCalendarModal.vue";
import NoCallsModal from "../modal/noCallsModal.vue";
import ProtocolModal from "../modal/protocolModal.vue";
import DialUpTimeModal from "../modal/dialUpTimeModal.vue";
import StartViewModal from "../modal/startViewModal.vue";
import Settings from "./settings.vue";
import UserActionBar from "./userActionBar/userActionBar.vue";
import TwofaModalContainer from "../2fa/modals/2faModalContainer.vue";
import RepresentativeModal from "../modal/representativeModal.vue";
import UserSettingsNewModal from "../modal/userSettingsNewModal.vue";
import { wholeBarWithColor } from "../../utils/color";
import {
  getPictureSize,
  userHasHolidaysSetted,
  userHasIllnessSetted
} from "../../utils/basicFunctions";
import CustomTT from "../content/customTT.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";
import {
  agreementModalStateStore,
  setVideoTestModalEvent,
  userSettingsStore,
  setUserProfileEvent,
  setBreakStatusModalEvent
} from "../../effector/modals";
import { callInfoStateStore } from "../../effector/call";
import { watchList } from "../../effector/presence";
import AditionalStatusInfoModal from "../modal/aditionalStatusInfoModal.vue";
import SpeechliveModal from "../modal/speechliveModal.vue";
import Users from "./users.vue";
import ExternalServices from "../sidebar/externalServicesNew.vue";
import DeleteBasket from "../ui/deleteBasket.vue";

export default {
  props: ["primaryDrawer", "openCloseSidebar", "mini"],
  components: {
    UserInfoTooltip,
    UserProfile,
    NotificationDropDown,
    DownloadsDropdown,
    DeviceSetupModal,
    CameraPreview,
    DefaultAvatar,
    AdminSettingModal,
    ErrorRemoteStorage,
    UploadUserPictureModal,
    HolidaysCalendarModal,
    NoCallsModal,
    StatusColorModal,
    Settings,
    UserActionBar,
    ProtocolModal,
    TwofaModalContainer,
    RepresentativeModal,
    DialUpTimeModal,
    StartViewModal,
    AditionalStatusInfoModal,
    Users,
    SpeechliveModal,
    ExternalServices,
    CustomTT,
    UserSettingsNewModal,
    DeleteBasket
  },
  data() {
    const effector = {
      showModalUserAgreement: agreementModalStateStore,
      callInfo: callInfoStateStore,
      watchList: watchList,
      showCustomTT: hotkeysInfoStore,
      showUserSettingsNewModal: userSettingsStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      publicMsgs: store.state.namespaceSettings.publicMsgs,
      publicTasks: store.state.namespaceSettings.publicTasks,
      state: store.state,
      persisted: store.state.persisted,
      ownUUID: store.state.ownUUID,
      showSetupDevice: false,
      showAdminSettings: false,
      showUserSettings: {
        show: false,
        activeSection: "",
      },
      showStatusColorSettings: false,
      showVirtualOfficePrefix: false,
      rules: [(value) => !!value || this.$t("generics.required")],
      showModalRepresentative: false,
      setCurrentContentVisile: store.setCurrentContentVisile,
      showCamera: false,
      showUploadUserPictureModal: false,
      isMobile: isMobile(),
      protocol: false,
      dialUpTime: false,
      startView: false,
      show2faSettings: false,
      temporalStatus: "",
      showModalAditionalInfoStatus: false,
      showForwardUsers: false,
      showBeacon: false,
      speechlive: false,
      showMenuStatus: false,
      showMenuLocation: false,
      openHolidaysCalendar: false,
      openNoCallsModal: false,
      calendarStatus: null,
      selectedStatus: "Available",
      showPictureModal: store.state.user.dontShowAvatarMessage ? false : true,
      // Effector
      ...effector,
    }
  },
  computed: {
    hasAditionalStatus() {
      return !!this.state.user?.additionalStatus;
    },
    isDarkMode() {
      return this.state.persisted.isDark;
    },
    getStatus() {
      const tempStatus = [...store.state.userStatus];
      if (!store.state.namespaceSettings.showNoStatus) {
        const noStatusIndex = tempStatus.indexOf('No status');
        if (noStatusIndex !== -1) {
          tempStatus.splice(noStatusIndex, 1);
        }
      }
      if (!this.state.namespaceSettings.showCoffeeBreak) {
        const coffeeBreakIndex = tempStatus.indexOf('Coffee break');
        if (coffeeBreakIndex !== -1) {
          tempStatus.splice(coffeeBreakIndex, 1);
        }
      }
      return tempStatus;
    },
    userActivity() {
      return store.state.user.activity;
    },
    pushDrawerContentCard() {
      if (
        this.$route.path === "/invitationformuser" ||
        this.$route.path === "/invitationform"
      ) {
        return true;
      }
      return false;
    },
    holidaysSetted() {
      return userHasHolidaysSetted(this.state.user.uuid);
    },
    illnessSetted() {
      return userHasIllnessSetted(this.state.user.uuid);
    },
    statusTextClass() {
      if (this.$locale.current() === "es") return "statusText statusTextES";
      return "statusText";
    },
    getVisitorDate() {
      if (this.state.user) {
        return moment(this.state.user.presentFrom).format("YY-MM-DD HH:mm");
      }
      return null;
    },
    getPictureSize() {
      return getPictureSize();
    },
    showErrorRemoteStorage() {
      return store.state.namespaceSettings.remoteStorageError;
    },
    hideUserLocation() {
      if (
        (this.state.user && this.state.user.activity === "Holidays") ||
        this.state.namespaceSettings.locationPrivacity === "never" ||
        (this.state.namespaceSettings.locationPrivacity === "user" &&
          this.state.user.userSettings.showLocation === false)
      ) {
        return true;
      }
      return false;
    },
    getLocations() {
      let allLocations = [];
      allLocations = this.state.userLocations.concat(
        this.state.namespaceSettings.activeCompanyLocations
      );
      return allLocations;
    },
    showDeviceGuest() {
      if (!this.hasPrivilege && !this.state.user.guestDeviceSetted) {
        return true;
      } else {
        return false;
      }
    },
    hasPrivilege() {
      return hasPrivilege(this.ownUUID);
    },
    hasUserAvatar() {
      if (
        (store.state.user.avatar === "img/default_profile_picture.png" ||
          !store.state.user.avatar ||
          store.state.user.avatar === "") &&
        store.state.showModalUploadPicure
      ) {
        if (
          store.state.ownUUID !==
            (store.state.namespaceSettings.creatorInfo || {}).uuid &&
          store.state.user.name &&
          store.state.user.email
        ) {
          this.showUploadUserPictureModal = true;
        }
        return false;
      } else {
        return true;
      }
    },
    amIGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    titel() {
      return store.getTitelForUuid(this.state.ownUUID);
    },
    totalNotificationCall() {
      return this.state.persisted.totalNotificationCall > 0
        ? this.state.persisted.totalNotificationCall
        : 0;
    },
    totalNotificationTicket() {
      return this.state.persisted.totalNotificationTicket > 0
        ? this.state.persisted.totalNotificationTicket
        : 0;
    },
    totalNotificationMessage() {
      return this.state.persisted.totalNotificationMessage > 0
        ? this.state.persisted.totalNotificationMessage
        : 0;
    },
    totalNotificationFile() {
      return this.state.persisted.totalNotificationFile > 0
        ? this.state.persisted.totalNotificationFile
        : 0;
    },
    totalNotificationInvite() {
      return this.state.persisted.totalNotificationInvite > 0
        ? this.state.persisted.totalNotificationInvite
        : 0;
    },
    userActivityTranslated() {
      if (this.state.user) {
        const key = `status.${this.state.user.activity}`;
        const translation = this.$t(key) || key;
        return key === translation ? `${this.state.user.activity}` : translation;
      } else {
        return this.$t("status.Available");
      }
    },
    phoneColorColor() {
      if (this.wholeBarWithColors()) {
        return "#ffffff";
      } else {
        let myColor = getStatusColorByUser(this.state.user, this.state.wsOpen);
        return myColor;
      }
    },
    badgetClassObj() {
      if (this.wholeBarWithColors()) {
        return getStatusColorByUser(
          (this.state.group[this.ownUUID] || {}).user,
          this.state.wsOpen
        );
      } else {
        return "rgba(255,255,255,0.3)";
      }
    },
    borderClass() {
      return getStatusColorByUser(
        (this.state.group[this.ownUUID] || {}).user,
        this.state.wsOpen
      );
    },
  },
  mounted() {
    const delay = Promise.all([
      syncedGroupState(true),
      syncedUserState().catch(() => {}),
    ]);
    store.state.ownModalUser = true;
    store.state.userOnModal = {
      ...store.state.user,
      connected: store.state.wsOpen,
      uuid: store.state.ownUUID,
    };
    store.state.persisted.usersCustomStatus = [];
    EventBus.$on("openUserInfoEvent", this.setUserInModal);
    EventBus.$on("openMyStatusEvent", this.openMenuStatus);
    EventBus.$on("openMyLocationEvent", this.openMenuLocation);
    delay.then(() => {
      if (this.holidaysSetted) {
        this.setStatusHolidays(this.holidaysSetted);
      }
      if(this.illnessSetted) {
        this.setStatusIll(this.illnessSetted)
      }
      if (
        this.state.user.activity == "Coffee break" &&
        !this.state.namespaceSettings.showCoffeeBreak
      ) {
        this.setStatus("Busy");
      }
      this.selectedStatus = this.state.user.activity;
    });
  },
  unmounted(){
    EventBus.$off("openUserInfoEvent", this.setUserInModal);
    EventBus.$off("openMyStatusEvent", this.openMenuStatus);
    EventBus.$off("openMyLocationEvent", this.openMenuLocation);
  },
  created() {
    store.state.userOnModal = store.state.user;
    if (
      store.state.namespaceSettings.setupDone &&
      store.state.user.name &&
      store.state.user.email
    ) {
      this.showModal2fA();
    }
  },
  watch: {
    showModalUserAgreement: {
      handler: function (value) {
        if (!value) {
          this.showModaUploadPictureUser();
        }
      },
      deep: true,
    },
    holidaysSetted: {
      handler: function (hasHolidaysSet) {
        this.setStatusHolidays(hasHolidaysSet);
      },
      // immediate: true,
      deep: true,
    },
    illnessSetted: {
      handler: function (hasIllnesSet) {
        this.setStatusIll(hasIllnesSet);
      },
      // immediate: true,
      deep: true,
    },
    userActivity: {
      handler: function (status, previousStatus) {
        if (status && previousStatus && this.watchList && this.watchList.length > 0) {
          this.performMessages(previousStatus, status);
        }
      },
      deep: true,
    },
  },
  methods: {
    deleteAditionalStatus() {
      store.setAdditionalStatusUser('');
    },
    handleStatusClick() {
      this.openMenuStatus();
    },
    clickUserImageMini() {
      if (!this.mini) {
        this.openCloseSidebar();
      }
    },
    setStatusHolidays(hasHolidaysSet) {
      if (hasHolidaysSet && this.state.user.activity !== "Holidays") {
        this.state.user.activity = "Holidays";
      } else if (!hasHolidaysSet && this.state.user.activity == "Holidays") {
        this.state.user.activity =
          this.selectedStatus === "Holidays"
            ? "Available"
            : this.selectedStatus;
      }
    },
    setStatusIll(hasIllnessSe){
      if (hasIllnessSe && this.state.user.activity !== "Ill") {
        this.state.user.activity = "Ill";
      } else if (!hasIllnessSe && this.state.user.activity == "Ill") {
        this.state.user.activity =
          this.selectedStatus === "Ill"
            ? "Available"
            : this.selectedStatus;
      }
    },
    // checkIsDateInRange(startDate, endDate, date) {
    //   let range = moment().range(startDate, endDate);
    //   const sameDay =
    //     startDate.getTime() === endDate.getTime() &&
    //     endDate.getTime() === date.getTime() &&
    //     startDate.getTime() === date.getTime();
    //   if (range.contains(date) || sameDay) {
    //     return true;
    //   }
    //   return false;
    // },
    toggleShowHolidaysModal(status) {
      this.openHolidaysCalendar = !this.openHolidaysCalendar;
      this.calendarStatus = status;
    },
    toggleShowNoCallsModal(){
      this.openNoCallsModal = !this.openNoCallsModal;
    },
    openMenuLocation() {
      this.showMenuLocation = !this.showMenuLocation;
      if (this.showMenuLocation) {
        setTimeout(() => {
          document.getElementById("locationItem_0").focus();
        }, 500);
      }
    },
    openMenuStatus() {
      this.showMenuStatus = !this.showMenuStatus;
      if (this.showMenuStatus) {
        setTimeout(() => {
          document.getElementById("statusItem_0").focus();
        }, 500);
      }
    },
    handleOpenModal() {
      this.hasPrivilege ? this.setUserInModal() : this.setVideoTestModal();
    },
    setVideoTestModal() {
      setVideoTestModalEvent(true);
    },
    oneCallAndWaitingUser(uuid) {
      return oneCallAndWaitingUser(uuid);
    },
    showCloseForwardUsers() {
      this.showForwardUsers = !this.showForwardUsers;
    },
    showCloseAditionalStatusInfoModal() {
      this.showModalAditionalInfoStatus = !this.showModalAditionalInfoStatus;
    },
    closeModalErrorRemoteStorage() {
      setNamespaceSetting("remoteStorageError", false);
    },
    showModal2fA() {
      if (
        store.state.namespaceSettings.activate2FAType === "allUsers" ||
        store.getUserIsAdmin(this.ownUUID)
      ) {
        status2FA(true).then((enabled) => {
          this.show2faSettings =
            (!enabled || !enabled.method) && !(enabled && enabled.userSupport);
        });
      }
    },
    showModaUploadPictureUser() {
      if (!hasPrivilege(this.ownUUID) && !this.state.user.guestDeviceSetted) {
        this.setUserInModal();
      }
    },
    wholeBarWithColors() {
      return wholeBarWithColor();
    },
    locationListTranslated(value) {
      const key = `components.locations.${value}`;
      const translation = this.$t(key) || key;
      return key === translation ? `${value}` : translation;
    },
    logout() {
      const localSecretKey = "ownSecret_" + this.ownUUID;
      const localTwoTokenKey = "ownTwoToken_" + this.ownUUID;
      localStorage.removeItem(localSecretKey);
      localStorage.removeItem(localTwoTokenKey);
      localStorage.removeItem("temporalStatus");
      wsCall("revokeTwoToken");
      window.location.reload();
    },
    resetInviteUserNotification() {
      store.setTotalNotificationInvite(0);
    },
    cameraPreview(value) {
      this.showCamera = value;
      if (this.showCamera === true) {
        setTimeout(() => {
          this.showCamera = false;
        }, 30000);
      }
    },
    getStatusColor(status) {
      return colorStatus(status);
    },
    requestPermission() {
      return requestPermission();
    },
    toggleModalSettings() {
      this.showSetupDevice = !this.showSetupDevice;
      this.state.user.guestDeviceSetted = !this.state.user.guestDeviceSetted;
    },
    showProtocol() {
      this.protocol = !this.protocol;
    },
    showSpeechlive() {
      this.speechlive = !this.speechlive;
    },
    showDialUpTime() {
      this.dialUpTime = !this.dialUpTime;
    },
    showStartView() {
      this.startView = !this.startView;
    },
    toggleStatusColorSettings() {
      this.showStatusColorSettings = !this.showStatusColorSettings;
    },
    toggleModalAdminSettings() {
      this.showAdminSettings = !this.showAdminSettings;
    },
    toggle2Modal2faSetting() {
      this.show2faSettings = !this.show2faSettings;
    },
    setTotalNotificationCall: function (...args) {
      store.setTotalNotificationCall(...args);
    },
    setTotalNotificationFile: function (...args) {
      store.setTotalNotificationFile(...args);
    },
    setTotalNotificationMessage: function (...args) {
      store.setTotalNotificationMessage(...args);
    },
    setUserInModal() {
      store.state.ownModalUser = true;
      store.state.userOnModal = {
        ...store.state.user,
        connected: store.state.wsOpen,
        uuid: store.state.ownUUID,
      };
      this.toggleUserProfile();
    },
    toggleUserProfile() {
      if (this.hasPrivilege) {
        setUserProfileEvent(true);
      }
    },
    setShowModalUploadPicure(value) {
      return store.setShowModalUploadPicure(value);
    },
    toggleUploadUserPictureModal() {
      if (this.showUploadUserPictureModal) {
        this.setShowModalUploadPicure(false);
      }
      this.showUploadUserPictureModal = !this.showUploadUserPictureModal;
    },
    setStatus(status) {
      // const wasInStatus = store.state.user.originalActivity;
      // Reset always 'NoCallsAdditionalInfo' in order to enable regular additional info again
      store.setNoCallsAdditionalInfo('');
      // Check new status and perform required actions
      if (status === "Holidays" || status === "Ill") {
        this.toggleShowHolidaysModal(status);
        this.selectedStatus = status;
      } else if (status === "No Calls") {
        this.toggleShowNoCallsModal();
      } else {
        this.selectedStatus = status;
        // reset break time data if is need it
        if (this.state.user.breakTimeStart != '') this.state.user.breakTimeStart = '';
        if (this.state.user.breakTimeEnd != '') this.state.user.breakTimeEnd = '';
        if (status === "Break") {
          setBreakStatusModalEvent(true);
        }
        if(status === 'Available'){
          if(personIsInBridgeCall(this.ownUUID) && !userInStaticRoom(this.ownUUID)){
            store.state.user.originalActivity = status;
            store.setActivityUser('inCall');
          }else if(userInStaticRoom(this.ownUUID)){
            store.state.user.originalActivity = status;
            store.setActivityUser('inRoom');
          }else {
            store.state.user.originalActivity = status;
            store.setActivityUser(status);
          }
        }else{
          store.state.user.originalActivity = status;
          store.setActivityUser(status);
        }
        if (
          Date.now() > new Date(this.state.user.startHolidays).getTime() &&
          Date.now() < new Date(this.state.user.endHolidays).getTime()
        ) {
          this.setSaveHolidays(undefined, undefined, 'Holidays');
        }
        if (
          Date.now() > new Date(this.state.user.startIllness).getTime() &&
          Date.now() < new Date(this.state.user.endIllness).getTime()
        ) {
          this.setSaveHolidays(undefined, undefined, 'Ill');
        }
      }
      // if (this.watchList && this.watchList.length > 0) {
      //   this.performMessages(wasInStatus, status);
      // }
    },
    setNoCallsStatus(cause){
      // Set 'No Calls' status
      store.state.user.originalActivity = 'No Calls';
      store.setActivityUser('No Calls');
      this.selectedStatus = 'No Calls';
      // Save 'No Calls' status reason
      store.setNoCallsAdditionalInfo(cause);
      this.toggleShowNoCallsModal();
    },
    setSaveHolidays(startDate, endDate, type = 'Holidays') {
      store.setSaveHolidays(startDate, endDate, this.ownUUID, type);
    },
    setLocation(location) {
      this.state.user.userLocation = location;
    },
    performMessages(previousStatus, newstatus) {
      let reportingStatus = [
        "Out of Office",
        "No Calls",
        "Break",
        "Holidays",
        "inCall",
        "inRoom",
        "No status",
        "Busy",
        "Just urgent calls",
        "In meeting",
        "Ill"
      ];
      if (
        reportingStatus.indexOf(previousStatus) !== -1 &&
        reportingStatus.indexOf(newstatus) === -1
      ) {
        this.watchList.forEach((element) => {
          const userToSendMsg = {};
          const header = {
            translateMessage: {
              translateKey: "generics.isOnline",
              params: [store.state.user.name],
            },
          };
          userToSendMsg[element] = {
            userUUID: element,
            read: false,
            replied: false,
            deleted: false,
            viewed: false,
          };
          const dataMsg = {
            body: this.$t("generics.isOnline", [store.state.user.name]),
            header: header,
            date: Date.now(),
            users: userToSendMsg,
            isReply: false,
            creatorUUID: store.state.ownUUID,
            masterMessageUUID: null,
            parentMessageUUID: null,
            type: "message",
          };
          removeUserPresenceWatching(element, dataMsg);
        });
      }
    },
  },
};
</script>
<style>
.v-list-item__content {
    align-items: center;
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1;
    overflow: hidden;
    padding: 12px 0;
}
</style>
<style scoped lang="scss">

.spanUserName {
  display: inline-block;
  vertical-align: top;
  max-width: 178px;
  color: white;
}
.noBoxShadow {
  box-shadow: none !important;
}
.drawerContentCard {
  box-shadow: none !important;
}
.customAvatar {
  margin-right: 5px !important;
  margin-left: 0px !important;
  margin-top: 10px !important;
}
.svg-inline--fa {
  vertical-align: -0.225em;
}
.statusIcon {
  font-size: 12px;
  top: 1px;
  position: relative;
  margin-left: 5px;
}
.statusLabel {
  vertical-align: middle;
  position: relative;
  top: -3px;
}
.iconsBlack {
  button.white--text {
    color: black !important;
  }
}
.settings-right {
  display: inline-flex;
  float: right;
}
.d-flex {
  &.statusSection {
    align-items: baseline;
    position: relative;
    &.locationSection {
      width: 23px;
      display: inline-block !important;
      position: relative;
      top: -1px;
      text-align: center;
      padding-top: 6px !important;
    }
    .v-input {
      font-size: 14px !important;
      margin-left: 10px !important;
    }
  }
}
.cursorPointer {
  cursor: pointer;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.borderRadius10 {
  border-radius: 10px !important;
}
.borderRadius50 {
  border-radius: 50% !important;
}
.v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child) {
  margin-left: 0px !important;
}
.v-application .mx-1 {
  width: 100%;
}
.v-application .v-text-field .v-label {
  font-size: 14px !important;
}
.v-application .v-input input {
  padding-right: 25px !important;
  font-size: 14px !important;
}
.statusText {
  vertical-align: bottom;
  margin: 0px !important;
  display: inline-block;
  max-width: 94px;
  min-width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.statusTextES {
  margin-right: 2px !important;
}
.statusItem {
  font-weight: 600;
}
.switchWrapper {
  .v-input__control {
    height: 30px !important;
  }
  .v-input__slot {
    margin: 0 !important;
  }
}
.ctrP {
  bottom: -10px;
}
.ctrl {
  top: 31px;
}
.ctra {
  bottom: 35px;
  top: unset !important;
}
</style>