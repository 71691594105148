// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dominantSpeakerIcon[data-v-7bda125a] {
  position: absolute;
  display: flex;
  height: 30px;
  width: 28px;
  background: var(--v-primary-base);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  align-content: center;
  top: 35px;
  right: 9px;
  z-index: 1;
}
.dominantSpeakerIcon .point[data-v-7bda125a] {
  background: white;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  margin: 0px 1.7px;
  transition: height 0.1s linear;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/calls/dominanSpeakerIconCall.vue","webpack://./dominanSpeakerIconCall.vue"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;EACA,iCAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;EACA,qBAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;ACDF;ADEE;EACE,iBAAA;EACA,WAAA;EACA,UAAA;EACA,kBAAA;EACA,iBAAA;EACA,8BAAA;ACAJ","sourcesContent":["\n\n.dominantSpeakerIcon {\n  position: absolute;\n  display: flex;\n  height: 30px;\n  width: 28px;\n  background: var(--v-primary-base);\n  border-radius: 50%;\n  align-items: center;\n  justify-content: center;\n  align-content: center;\n  top: 35px;\n  right: 9px;\n  z-index: 1;\n  .point{\n    background: white;\n    height: 4px;\n    width: 4px;\n    border-radius: 50%;\n    margin: 0px 1.7px;\n    transition: height .1s linear;\n  }\n}\n\n",".dominantSpeakerIcon {\n  position: absolute;\n  display: flex;\n  height: 30px;\n  width: 28px;\n  background: var(--v-primary-base);\n  border-radius: 50%;\n  align-items: center;\n  justify-content: center;\n  align-content: center;\n  top: 35px;\n  right: 9px;\n  z-index: 1;\n}\n.dominantSpeakerIcon .point {\n  background: white;\n  height: 4px;\n  width: 4px;\n  border-radius: 50%;\n  margin: 0px 1.7px;\n  transition: height 0.1s linear;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
