import { combine } from 'effector';
import { companyDocumentDomain } from './domain';
import { setCompanyDocumentUUIDsEvent, setCompanyDocumentsInfoBatchedEvent, deleteCompanyDocumentEntryAndUUIDEvent, setCompanyDocumentDetailEvent, setCompanyDocumentInfoEvent  } from './events';
// import { ownUserUUIDState } from '../users';
export const myCompanyDocumentStore = companyDocumentDomain.createStore([], { name: 'myCompanyDocumentStore' }); // List of event UUIDs that I care about (owner of, invited by, etc)

const myCompanyDocumentDetails = companyDocumentDomain.createStore([], { name: 'myCompanyDocumentDetails' });

myCompanyDocumentDetails.on(setCompanyDocumentInfoEvent, (state, payload) => {
  const [docStoreUUID, entry] = payload;
  let changed = false;
  if (!docStoreUUID || !entry || docStoreUUID !== entry.docStoreUUID) {
    console.warn('Invalid call to setCompanyDocumentInfoEvent');
    return state;
  }
  const newArray = [...state];
  const extant = newArray.find(e => e.docStoreUUID === entry.docStoreUUID);
  if (!extant) {
    newArray.push(entry);
    changed = true;
  } else if (extant.updatedServerTs < entry.updatedServerTs) {
    Object.assign(extant, entry);
    changed = true;
  }
  return changed ? newArray : state; // If nothing new was added/altered, send same state to prevent updates
});

myCompanyDocumentStore.on(setCompanyDocumentUUIDsEvent, (state, payload) => {
  const newArray = [...state];
  let changed = false;
  payload.forEach((i) => {
    const extant = newArray.find(e => e.docStoreUUID === i.docStoreUUID);
    if (!extant) {
      newArray.push(i);
      changed = true;
    } else if (extant.updatedServerTs < i.updatedServerTs) {
      extant.updatedServerTs = i.updatedServerTs;
      changed = true;
    }
  });
  return changed ? newArray : state; // If nothing new was added/altered, send same state to prevent updates
});

myCompanyDocumentDetails.on(setCompanyDocumentDetailEvent, (state, payload) => {
  return [...(payload || [])];
});

myCompanyDocumentDetails.on(deleteCompanyDocumentEntryAndUUIDEvent, (state, payload) => {
  return state.filter(e => e.docStoreUUID !== payload);
});

myCompanyDocumentStore.on(deleteCompanyDocumentEntryAndUUIDEvent, (state, payload) => {
  return state.filter(t => t.docStoreUUID !== payload);
});

export const cachedCompanyDocuments = companyDocumentDomain.createStore({}, { name: 'cachedCompanyDocuments' });
cachedCompanyDocuments.on(myCompanyDocumentStore, (state, payload) => {
  return { ...state, [payload[0]]: payload[1] };
});

cachedCompanyDocuments.on(setCompanyDocumentsInfoBatchedEvent, (state, payload) => {
  return { ...state, ...payload };
});

cachedCompanyDocuments.on(deleteCompanyDocumentEntryAndUUIDEvent, (state, payload) => {
  const newState = { ...state };
  delete newState[payload];
  return newState;
});

const myCompanyDocuments = combine(myCompanyDocumentStore, cachedCompanyDocuments, (uuids, cachedCompanyDocuments) => {
  const confs = [];
  for (const uuidObj of uuids) {
    const uuid = uuidObj.docStoreUUID;
    confs.push(cachedCompanyDocuments[uuid] ? cachedCompanyDocuments[uuid] : uuid);
  }
  return confs;
});

export const myCompanyDocumentsDetails = myCompanyDocumentDetails.map(s => s.filter(e => typeof e === 'object'))
export const unknownCompanyDocuments = myCompanyDocuments.map(s => s.filter(e => typeof e === 'string'));
export const CompanyDocuments = myCompanyDocuments.map(s => s.filter(e => typeof e === 'object'));
