import { rootDomain } from '../rootDomain';

import { attachLoggingToDomain } from './util';
import { setShowBlinkenlightsEvent } from './events';

import blinkenlights from '../../components/dev/blinkenlights/blink';

attachLoggingToDomain(rootDomain);
setShowBlinkenlightsEvent.watch((enabled) => {
  if (enabled) {
    blinkenlights(rootDomain);
  } else {
    blinkenlights(null);
  }
});
