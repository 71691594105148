import { messages } from './state';
// import { ownUserUUIDState } from '../../effector/users';
import store from '../../store';

function order(a, b) {
    const aDate = a.creationServerTs || a.date;
    const bDate = b.creationServerTs || b.date;
    return aDate < bDate ? -1 : (aDate > bDate ? 1 : 0);
}

export function getMessageHistoryByUUID(masterMessageUUID) {
    let messagesResult = {};
    messagesResult = messages.map(state => state.filter(entry => entry.masterMessageUUID === masterMessageUUID).sort(order));
    return messagesResult;
}

export function getMessageHistoryByUserUUID(userUUID) {
    let messagesResult = {};
    // const ownUUID = ownUserUUIDState.getState();
    const ownUUID = store.state.ownUUID;
    // messagesResult = messages.map(state =>{console.log(state, "stateeeeeeeeeeee")})
    messagesResult = messages.map(state => state.filter(entry => 
        ((entry.creatorUUID === userUUID && Object.keys(entry.users).find((user) => user == ownUUID)) && entry.users[ownUUID].deleted == false) ||
        ((entry.creatorUUID === ownUUID && Object.keys(entry.users).find((user) => user == userUUID)) && entry.removedByCreator == false)
    ).sort(order));
    return messagesResult;
}

// "9f95b826-4e0e-4098-973e-134fdcd94e2f": {
//     "body": "mensaje 1",
//     "header": "individual message",
//     "date": 1615203493335,
//     "users": {
//       "28f5236d9df9964a91473a58049255b8": {
//         "userUUID": "28f5236d9df9964a91473a58049255b8",
//         "read": true,
//         "replied": false,
//         "deleted": false,
//         "viewed": true
//       }
//     },
//     "isReply": false,
//     "creatorUUID": "e545d7e24a1d9de6ce81900fe3b4dc3f",
//     "masterMessageUUID": "9f95b826-4e0e-4098-973e-134fdcd94e2f",
//     "parentMessageUUID": null,
//     "type": "message",
//     "messageUUID": "9f95b826-4e0e-4098-973e-134fdcd94e2f",
//     "removedByCreator": false,
//     "creationServerTs": 1615203493337,
//     "updatedServerTs": 1615203496235,
//     "entryVersion": 0
//   }
// }