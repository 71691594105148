import { visitorNameModalDomain } from './domain';

import { setVisitorNameModalEvent, resetVisitorNameModalEvent} from './events';

export const visitorNameModalStore = visitorNameModalDomain.createStore(false, { name: ' visitorNameModal' });

visitorNameModalStore.on(setVisitorNameModalEvent, (state, payload) => {
  return payload;
});

visitorNameModalStore.reset(resetVisitorNameModalEvent);
