<template>
  <div class="PWAButton">
    <div v-if="deferredPrompt">
      <button @click="register">Register PWA!</button>
    </div>
    <div v-else-if="outcome">
      PWA Register Outcome: {{ outcome }}
    </div>
    <div v-else-if="installed">
      PWA Installed! {{installed}}
    </div>
    <div v-else>
      Cannot Register PWA!
    </div>
  </div>
</template>

<script>
const d = {
  deferredPrompt: undefined,
  outcome: undefined,
  installed: false,
};

window.addEventListener('beforeinstallprompt', (e) => {
  d.deferredPrompt = e;
});

window.addEventListener('appinstalled', (e) => {
  d.installed = e;
});

export default {
  data() { return d; },
  computed: { },
  methods: {
    register() {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult) => {
        // if (choiceResult.outcome === 'accepted') {
        //   // console.log('User accepted the A2HS prompt');
        // } else {
        //   // console.log('User dismissed the A2HS prompt');
        // }
        this.outcome = choiceResult.outcome;
        this.deferredPrompt = null;
      });
    },
  }
}
</script>

<style lang="scss">
h1 {
  font-size: 15px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
}

.PWAButton {
  // background-color:white;
  border: 1px solid black;
}
</style>