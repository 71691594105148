<template>
  <div>
    <v-menu
      :close-on-content-click="false"
      :close-on-click="false"
      :position-x="260"
      :position-y="120"
      absolute
      offset-y
      max-height="100%"
      max-width="100%"
      content-class="inviteVisitorMenu"
      :top="!isNavBar"
      :bottom="isNavBar"
      :model-value="menu" @update:model-value="menu = $event"
      v-if="canInvite && !isMobile && !isIpad"
    >
      <template v-slot:activator="{ props }">
        <div v-bind="props">
          <v-btn
            :small="isNavBar"
            icon
            :class="{
              btnSendGuestInvitation:
                (typeInvite == 'guest' || typeInvite == 'user') && !isNavBar,
              buttonNavbar: isNavBar,
            }"
          >
            <font-awesome-icon
              class="text-primary"
              v-if="!isSidebar"
              :icon="['fal', 'user-plus']"
              :style="{ fontSize: '20px' }"
            />
            <font-awesome-icon
              class="text-primary"
              v-if="isSidebar"
              :icon="['fal', 'plus-circle']"
              :style="{ fontSize: '20px' }"
            />
            <CustomTT
              v-if="showCustomTT"
              :text="`${$t('components.hotkeys.controlKey')} + U`"
              position="top"
              :extraclass="'ctru'"
            />
          </v-btn>
        </div>
      </template>
      <!-- <vue-scroll> -->
      <!-- style="max-height: 100vh" -->
      <div>
        <InviteGuestPanel
          :closeMenu="closeMenu"
          :typeInvite="typeInvite"
          :fromRoot="fromRoot"
        />
        <!-- :width="'calc(100vw - 410px)'"
            :maxwidth="'calc(100vw - 410px)'" -->
      </div>
      <!-- </vue-scroll> -->
    </v-menu>

    <v-btn
      :small="isNavBar"
      icon
      :class="{
        btnSendGuestInvitation:
          (typeInvite == 'guest' || typeInvite == 'user') && !isNavBar,
        buttonNavbar: isNavBar,
      }"
      v-if="isIpad"
      @click.stop.prevent="dialogInvite = true"
    >
      <font-awesome-icon
        v-if="!isSidebar"
        :icon="['fal', 'user-plus']"
        :style="{ fontSize: !isNavBar ? '20px' : '14px' }"
      />
      <font-awesome-icon
        v-if="isSidebar"
        :icon="['fal', 'plus-circle']"
        :style="{ fontSize: !isNavBar ? '20px' : '14px' }"
      />
    </v-btn>

    <!-- <v-dialog
      :model-value="dialogInvite" @update:model-value="dialogInvite = $event"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-if="canInvite && (isMobile || isIpad)"
      persistent
    >
      <template v-slot:activator="{ on }">
        <v-btn color="white" icon v-on="on">
          <font-awesome-icon
            :icon="['fal', 'user-plus']"
            :style="{ fontSize: '20px' }"
          />
        </v-btn>
      </template>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialogInvite = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="px-0">{{
          $t("components.footernav.inviteUser")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items></v-toolbar-items>
      </v-toolbar> -->
      <InviteGuestPanel v-if="dialogInvite" :typeInvite="typeInvite" :fromRoot="fromRoot" />
    <!-- </v-dialog> -->
  </div>
</template>
<script>
import { useStore } from "effector-vue/composition";
import store, { EventBus } from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import InviteGuestPanel from "./inviteGuestPanel.vue";
import CustomTT from "../content/customTT.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";
export default {
  props: [
    "isLarge",
    "isXSmall",
    "isSmall",
    "typeInvite",
    "isSidebar",
    "resetInviteUserNotification",
    "isIpad",
    "isNavBar",
  ],
  components: { InviteGuestPanel, CustomTT },
  data() {
    const effector = {
      showCustomTT: hotkeysInfoStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
  
    return {
      state: store.state,
      menu: false,
      dialogInvite: false,
      fromRoot: true,
      isMobile: isMobile(),
      // Effector
      ...effector,
    };
  },
  mounted(){
    EventBus.$on("openInviteUserModalEvent", this.openMenu);
  },
  unmounted(){
    EventBus.$off("openInviteUserModalEvent", this.openMenu);
  },
  methods: {
    openMenu() {
      this.menu = true;
    },
    closeMenu() {
      this.menu = false;
    },
  },
  watch: {
    menu: {
      handler: function (value) {
        this.fromRoot = value;
        if (this.resetInviteUserNotification)
          this.resetInviteUserNotification();
        // console.log("showing / hiding", value);
      },
    },
  },
  computed: {
    canInvite() {
      if (store.getUserIsGuest(this.state.ownUUID)) {
        return false;
      }
      if (this.typeInvite === "guest") {
        return store.getUserCanInviteGuests(this.state.ownUUID);
      }
      return true;
    },
  },
};
</script>
<style scoped lang="scss">
.v-theme--dark {
  .inviteVisitorMenu {
    background-color: #1f1f1f;
  }
}
.v-theme--light {
  .inviteVisitorMenu {
    background-color: #ffffff;
  }
}
.inviteVisitorMenu {
  top: 0px !important;
  left: 0px !important;
  height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  & .invitationMenuList {
    width: 100% !important;
    padding-top: 0px;
    max-width: 100% !important;
    max-height: 100% !important;
  }
}
.btnSendGuestInvitation {
    position: absolute !important;
    right: 55px;
    /* bottom: 7px; */
    /* margin-right: 5px; */
    top: 5px;
}
.btnSend {
  float: right;
  margin-bottom: 20px;
}
.d-flex {
  p {
    &.email {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 65%;
      color: #2a3133;
      margin: 0px;
    }
    &.date {
      width: 50%;
      font-size: 12px;
      vertical-align: middle;
      margin: 4px;
    }
  }
}
.ctru{
  right: 45px;
}
</style>
