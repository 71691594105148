<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="570"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal :titleModal="$t('generics.info')" :closeModalFunction="closeModal" />
      <v-row :style="{ width: '100%', margin: 0 }" class="px-5">
        <v-card-text>
          <p class="breakSpaces">{{ isSuperVisorOrUserOrganisation ? $t("components.manageOrganisation.hasAmanagementFunction") : $t("components.userManagement.deleteUserText", [getUserName])}}</p>
        </v-card-text>
      </v-row>
      <FooterModal :closeModalFunction="closeModal">
        <v-btn color="primary" v-if="!isSuperVisorOrUserOrganisation" @click="deleteFunction">{{
          $t("generics.accept")
        }}</v-btn>
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import { checkUuidPresenceInOrganigram } from "../../utils/basicFunctions";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  props: ["showModal", "closeModal", "deleteUser", "userId"],
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
    };
  },
  methods: {
    deleteFunction() {
      if (this.userId === store.state.ownUUID) return;
      this.deleteUser(this.userId);
    },
    checkUuidPresenceInOrganigram(uuid){
      return checkUuidPresenceInOrganigram(uuid);
    }
  },
  computed:{
    isSuperVisorOrUserOrganisation() {
      return !!this.checkUuidPresenceInOrganigram(this.userId).length;
    },
    getUserName(){
      return this.state.group[this.userId] ? this.state.group[this.userId].user.name : '';
    }
  },
};
</script>
