<template>
 <v-dialog
    :model-value="showModal"
    @update:model-value="showModal = $event" persistent max-width="560">
    <div>
      <v-card class="mx-auto">
        <HeaderModal
          :titleModal="$t('components.adminSettingsModal.mailFooter')"
          :closeModalFunction="closeModal"
        />
        <v-card-text>
          <!-- :titleModal="$t('components.beforeEndingCallModal.title')" -->
          <v-textarea
            name="footerText"
            :label="$t('components.adminSettingsModal.mailFooter')"
            :model-value="footerText" @update:model-value="footerText = $event"
            no-resize
          ></v-textarea>
        </v-card-text>
        <FooterModal :closeModalFunction="closeModal" :showCancel="true">
          <v-btn class="ml-2 text-white" color="primary" @click="saveFooterText()">
            {{ $t("generics.save") }}</v-btn
          >
        </FooterModal>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import { setNamespaceSetting } from '../../lib/wsMsg';
import { resetMailFooterEvent, mailFooterStore } from "../../effector/modals";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  data() {
    const effector = {
      showModal: mailFooterStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      footerText: store.state.namespaceSettings.legalMailFooter || this.$tm('components.adminSettingsModal.legalMailFooter').join('\n'),
      // Effector
      ...effector,
    };
  },
  watch: {},
  mounted() {},
  methods: {
    saveFooterText() {
      this.saveData();
      this.closeModal();
    },
    saveData() {
      setNamespaceSetting('legalMailFooter', this.footerText);
      return store.setLegalMailFooter(this.footerText);
    },
    closeModal() {
      resetMailFooterEvent();
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>