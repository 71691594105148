<template>
  <div class="lateralPanel" :class="{ active: showLateralPanel, leftMobile: isMobile}">
    <div class="panelContent" v-if="showLateralPanel">
      <div class="listUsers">
        <div class="header">
          <v-text-field
            density="compact"
            single-line
            hide-details
            :label="$t('generics.search')"
            variant="outlined"
            :model-value="searchUsers" @update:model-value="searchUsers = $event"
            class="px-2 py-2"
            :class="{ hidden: dataType == 'messages' || dataType == 'aiText' }"
            clearable
            @click:clear="searchUsers = ''"
            append-inner-icon="mdi-magnify"
          >
            <!-- <font-awesome-icon
              :icon="['fal', 'search']"
              slot="append"
              :style="{ fontSize: '18px' }"
            /> -->
          </v-text-field>
          <v-tooltip left>
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" v-if="dataType == 'aiText'" icon variant="text" density="comfortable" class="mr-2" @click="downloadChatAi">
                <font-awesome-icon
                  :icon="['fal', 'download']"
                  :style="{ fontSize: '18px', color: '#ffffff' }"
                />
              </v-btn>
            </template>
            <span>{{
              $t("components.excelEditor.download")
            }}</span>
          </v-tooltip>
          <v-tooltip v-if="isMobile && (dataType == 'users' || dataType == 'favorites')" right>
            <template v-slot:activator="{ props }">
              <v-btn
                icon
                density="comfortable"
                variant="text"
                v-bind="props"
                @click="
                  showLateralPanel && dataType == 'users'
                    ? handlerShowLateralPanel(true, 'favorites')
                    : handlerShowLateralPanel(true, 'users')
                "
                class="mx-auto mt-2 mb-2"
              >
                <v-icon size="18">
                  <font-awesome-icon
                    v-if="dataType == 'favorites'"
                    :icon="['fas', 'star']"
                    class="primary--text-sidepanel"
                    :style="{fontSize: '18px'}"
                  />
                  <font-awesome-icon
                    v-else
                    :icon="['fal', 'star']"
                    class="primary--text-sidepanel"
                    :style="{fontSize: '18px'}"
                  />
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("components.sidebar.favoritesMiniTT") }}</span>
          </v-tooltip>
          <v-btn icon variant="text" density="compact" @click="closePanel" class="mr-2">
            <font-awesome-icon
              :icon="['fal', 'bars']"
              :style="{ fontSize: '18px', color: '#ffffff' }"
            />
          </v-btn>
        </div>
        <ReceivedAndSentMessagesDropDownEffector
          :notificationType="'message'"
          :closeMenu="closePanel"
          :menuOpen="showLateralPanel"
          :isLateralPanel="true"
          v-if="dataType == 'messages'"
        />
        <AiChat
          v-if="dataType == 'aiText'"
        />
        <v-row
          class="mx-auto rowUsers"
          v-if="dataType == 'users' || dataType == 'favorites' || dataType == 'participants'"
        >
          <v-list class="py-0">
              <!-- @click="handlerCallUser(person)" -->
            <v-list-item
              class="customheightList d-flex pb-2"
              v-for="(person, index) in slicedPersons"
              :key="index"
            >
             <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-avatar
                  v-bind="props"
                  class="cursorPointer"
                  :style="getBorderByStatusFull(person)"
                  @click="!disableCallBtnFunction(state.group[person.uuid]) ? handlerCallUser(person): null"
                >
                  <v-img
                    contain
                    class="text-white align-end"
                    height="80"
                    :src="getAvatarForUuid(person.uuid)"
                  >
                  </v-img>
                </v-avatar>
              </template>
                <UserInfoTooltip :person="state.group[person.uuid]"></UserInfoTooltip>
              </v-tooltip>
              <v-list-item-title class="d-flex justify-space-between customWidthPerson">
                <div  class="text-truncate pl-2">
                {{ person.name }}
                </div>
                <div class="d-flex" v-if="!isExternalUser(person.uuid)">
                  <v-tooltip location="top" v-if="isPayEnabled && isVisitorOrWaitingRoom(person.uuid)">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        icon
                        variant="text"
                        density="compact"
                        color="white"
                        @click.stop.prevent="showModalPaymentRequest(person.uuid)"
                        class="buttonMessage"
                      >
                        <font-awesome-icon :icon="['fal', 'euro-sign']" :style="{ fontSize: '16px' }" v-if="state.namespaceSettings.currency === 'EUR'"/>
                        <font-awesome-icon :icon="['far', 'pound-sign']" :style="{ fontSize: '16px' }" v-if="state.namespaceSettings.currency === 'GBP'"/>
                        <v-img
                        contain
                        height="20"
                        v-if="state.namespaceSettings.currency === 'CHF'"
                        src="img/paymentIcons/swiss-franc.png"
                        lazy-src="img/paymentIcons/swiss-franc.png"
                      ></v-img>

                      </v-btn>
                    </template>
                    <span>{{ $t('components.callsContent.requestPayment') }}</span>
                  </v-tooltip>
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      icon
                      variant="text"
                      density="compact"
                      color="white"
                      @click="setShowModalSenMsg(person.uuid, 'message')"
                      class="buttonMessage"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'paper-plane']"
                        :style="{ fontSize: '16px' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("components.userProfile.sendAMessage") }}</span>
                </v-tooltip>
                <v-tooltip location="top" v-if="!isInCallWithUs(person) && dataType !== 'participants'">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      icon
                      variant="text"
                      density="compact"
                      class="buttonCall"
                      :disabled="disableCallBtnFunction(state.group[person.uuid])"
                      v-on:click="handlerCallUser(person)"
                    >
                      <font-awesome-icon
                        :color="disableCallBtnFunction(state.group[person.uuid]) ? 'gray' : 'white'"
                        :icon="['fal', 'phone']"
                        :style="{ fontSize: '16px' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{
                    $t("components.userListItem.videoCallStart")
                  }}</span>
                </v-tooltip>
                <UserMoreActions
                  v-else
                  :isRegularGrid="false"
                  :person="state.group[person.uuid]"
                  :section="'Users'"
                  :customStyle="false"
                  :typeData="isVisitorOrWaitingRoom(person.uuid) ? 'waitingroom' : 'users'"
                  :isCompact="true"
                  :showUploadFile="true"
                  :sendFileTo="sendFileTo"
                  :isInCall="dataType == 'participants'"
                />
              </div>
              </v-list-item-title>
            </v-list-item>
            <infinite-loading :distance="10" @infinite="infiniteHandler">
               <template #error>
                  <div v-html="'&nbsp;'"></div>
                </template>
                <template #complete>
                  <div v-html="'&nbsp;'"></div>
                </template>
            </infinite-loading>
          </v-list>
        </v-row>
        <v-row
          class="mx-auto rowUsers"
          v-if="dataType == 'myWR' || dataType == 'wrFor'"
        >
          <v-list class="py-0">
            <v-list-item>
              <v-list-item-title class="text-center">
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn icon variant="text" density="compact" @click="handlerPlusWR" class="mr-2" v-bind="props">
                      <font-awesome-icon
                        :icon="['fal', 'plus']"
                        :style="{ fontSize: '30px', color: '#ffffff' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("components.sidebar.guestTT") }}</span>
                </v-tooltip>
              </v-list-item-title>
            </v-list-item>

              <!-- @click="handlerCallUser(person)" -->
            <v-list-item
              class="customheightList"
              v-for="(person, index) in peopleWaitingRoom"
              :key="index"
            >
              <v-avatar
                class="cursorPointer"
                :style="getBorderByStatusFull(person)"
                @click="!disableCallBtnFunction(state.group[person.uuid]) ? handlerCallUser(person): null"
              >
                <v-img
                  contain
                  class="text-white align-end"
                  height="80"
                  :src="getAvatarForUuid(person.uuid)"
                >
                </v-img>
              </v-avatar>
              <v-list-item-title class="d-flex justify-space-between">
                <div class="text-truncate">
                {{ person.name }}
                </div>
                <div>

                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      icon
                      variant="text"
                      density="compact"
                      color="white"
                      class="buttonMessage"
                      @click="setShowModalSenMsg(person.uuid, 'message')"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'paper-plane']"
                        :style="{ fontSize: '16px' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("components.userProfile.sendAMessage") }}</span>
                </v-tooltip>
                <v-tooltip location="top" v-if="!isInCallWithUs(person)">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      icon
                      variant="text"
                      density="compact"
                      :disabled="disableCallBtnFunction(state.group[person.uuid])"
                      v-on:click="handlerCallUser(person)"
                      class="buttonCall"
                    >
                      <font-awesome-icon
                        :color="disableCallBtnFunction(state.group[person.uuid]) ? 'gray' : 'white'"
                        :icon="['fal', 'phone']"
                        :style="{ fontSize: '16px' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{
                    $t("components.userListItem.videoCallStart")
                  }}</span>
                </v-tooltip>
                </div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-row>
      </div>
    </div>

    <AddVisitorsConference
      v-if="showAddVisitorConference"
      :isConferenceCall="isConferenceCall"
      :showModal="showAddVisitorConference"
      :closeModal="closeVisitorConference"
      :dataConference="getDataConference"
      :attachTo="'videos'"
    />
    <AddVisitorsCall
      v-if="showAddVisitorCall"
      :showModal="showAddVisitorCall"
      :closeModal="closeVisitorCall"
      :attachTo="'videos'"
    />
  </div>
</template>
<script>
import { useStore } from "effector-vue/composition";
import store, { EventBus } from "../../../../store";
import { getCompanyDocumentsForCall } from "../../../../lib/wsMsg";
import { getBorderByStatusFull } from "../../../../utils/basicFunctions";
import { disableCallBtn, callPerson } from "../../../../utils/calls";
import ReceivedAndSentMessagesDropDownEffector from "../../../sidebar/receivedAndSentMessagesDropDownEffector.vue";
import { markAsCallWaitingRoomUserEvent } from "../../../../effector/users/waitingInfo";
import { myConferenceDetails } from "../../../../effector/groupConferences";
import AddVisitorsCall from "../callInfo/addVisitorsCall.vue";
import AddVisitorsConference from "../../../conferenceLeftBar/addVisitorsConference.vue";
import { entries, sortBy } from "lodash-es";
import { setRequestPaymentModal, setHistoryMessagesModalEvent } from "../../../../effector/modals";
import UserInfoTooltip from "../../userInfoTooltip.vue";
import AiChat from "../aiChat.vue";
import InfiniteLoading from "v3-infinite-loading";
import UserMoreActions from "../../../content/userMoreActions.vue";
import { isMobile } from "../../../../lib/mobileUtil";
import { sendFileTo } from "../../../../lib/rtcConn";
import { webLicensedBaseFeatures } from "../../../../../sharedsrc/licensedFeatures";

export default {
  components: {
    ReceivedAndSentMessagesDropDownEffector,
    AddVisitorsConference,
    AddVisitorsCall,
    UserInfoTooltip,
    UserMoreActions,
    AiChat,
    "infinite-loading": InfiniteLoading,
  },
  props: [
    "showLateralPanel",
    "dataType",
    "handlerShowLateralPanel",
    "waitingRoomFor",
    "isConferenceCall",
  ],
  data() {
    const effector = {
      myConferenceDetails,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      searchUsers: "",
      showAddVisitorConference: false,
      dataConference: null,
      showAddVisitorCall: false,
      sliceIndex: 20,
      isMobile: isMobile(),
      // Effector
      ...effector,
    };
  },
  mounted(){
    EventBus.$on('handlerOpenVisitor', this.handlerPlusWR);
  },
  unmounted() {
    EventBus.$off('handlerOpenVisitor', this.handlerPlusWR);
  },
  methods: {
    isVisitorOrWaitingRoom(personUuid){
      return this.isWaitingRoom(personUuid) || this.isVisitor(personUuid);
    },
    isWaitingRoom(personUuid){
      return (this.state.group[personUuid].user.visitorData && this.state.group[personUuid].user.visitorData.isWaitingRoom)
    },
    isVisitor(personUuid){
      return (this.state.group[personUuid].user.visitor);
    },
    showModalPaymentRequest(personUuid){
      const obj = {
        receiver: personUuid,
        tileId: false,
        callUUID: store.state.user.bridgeCallInfo?.callUUID,
      };
      setRequestPaymentModal(obj);
    },
    sendFileTo(personUuid) {
      let uuid = personUuid;
      return sendFileTo(uuid);
    },
    isExternalUser(uuid) {
      return (uuid in this.state.group) && this.state.group[uuid].externalCall;
    },
    downloadChatAi() {
      EventBus.$emit('handlerDownloadChatAi');
    },
    infiniteHandler($state) {
      if (this.sliceIndex < this.searchFunction.length) {
        this.sliceIndex += 10;
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    setShowModalSenMsg(uuid, type = "") {
      setHistoryMessagesModalEvent(uuid);
      this.closePanel()
    },
    isInCallWithUs(person) {
      return (
        !!this.state.rtc[person.uuid] &&
        !!this.state.remoteStreams[person.uuid]
      );
    },
    handlerPlusWR() {
      if (this.isConferenceCall) {
        this.openVisitorConference();
      } else {
        this.openVisitorCall();
      }
      this.closePanel()
    },
    openVisitorCall() {
      this.showAddVisitorCall = true;
    },
    closeVisitorCall() {
      this.showAddVisitorCall = false;
    },
    openVisitorConference() {
      this.showAddVisitorConference = true;
    },
    closeVisitorConference() {
      this.showAddVisitorConference = false;
    },
    handlerCallUser(person) {
      if (!this.disableCallBtnFunction(this.state.group[person.uuid])) {
        this.callUser(person.uuid);
        if (this.dataType == "myWR" || this.dataType == "wrFor") {
          markAsCallWaitingRoomUserEvent(person.uuid);
        }
        this.closePanel()
      }
    },
    disableCallBtnFunction(personObj) {
      let person = personObj;
      return (disableCallBtn(person) === undefined ? true : disableCallBtn(person)) ;
    },
    callUser(uuid) {
      callPerson(uuid, this.$router);
    },
    closePanel() {
      if (this.showLateralPanel) {
        this.handlerShowLateralPanel(false, null);
      }
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getBorderByStatusFull(person) {
      if (!person || !person.uuid) return;
      return getBorderByStatusFull(this.state.group[person.uuid]);
    },
    getFinalPersons(persons) {
      // function to sort the userlist
      const personSort = persons.sort((a, b) => {
        if (a.connected && !b.connected) {
          return -1;
        } else if (!a.connected && b.connected) {
          return 1;
        }
        return (
          (a.user || {}).presentFrom ||
          a.name ||
          a.uuid ||
          ""
        ).localeCompare((b.user || {}).presentFrom || b.name || b.uuid || "");
      });
      // // do foreach to find user conference and replace for conference data
      // let actualConference = null;
      // personSort.forEach((person, index) => {
      //   const isConferencePerson =
      //     person.user.visitorData.conferenceUUID &&
      //     person.user.visitorData.conferenceUUID.length > 0
      //       ? true
      //       : false;
      //   const currentConference = person.user.visitorData.currentConference;
      //   if (actualConference !== currentConference) {
      //     actualConference = currentConference;
      //     if (isConferencePerson) {
      //       this.obConferences[currentConference].waitingRoomPosition =
      //         index + 1;
      //       personSort[index] = this.obConferences[currentConference];
      //     }
      //   }
      // });
      return personSort;
    },
  },
  computed: {
    isPayEnabled() {
      // See also websrc/components/modal/newAdminSettingsModal.vue isPayAvailable
      // See also websrc/components/content/inviteForm.vue isPayEnabled
      return (
        webLicensedBaseFeatures.isVisitorPayAvailable &&
        this.state.namespaceSettings.paySetting
      );
    },
    slicedPersons() {
      return this.searchFunction.slice(0, this.sliceIndex);
    },
    getDataConference() {
      let confId = this.isConferenceCall;
      let dataConference = null;
      dataConference = this.myConferenceDetails.find(
        (e) => e.confId === confId
      );
      if (!dataConference) {
        dataConference = getCompanyDocumentsForCall(confId, "conference");
      }
      this.dataConference = dataConference;
      return dataConference;
    },
    peopleWaitingRoom() {
      let persons = [];
      // let waitingPeople = [];
      if (this.waitingRoomFor) {
        persons = store.getWaitingRoomList(this.waitingRoomFor);
      } else {
        persons = store.getWaitingRoomList();
      }
      // waitingPeople = persons.filter(
      //   (e) => ((e.user.visitorData || {}).conferenceUUID || []).length == 0
      // );
      // console.log(waitingPeople,persons,  "waitingPeople")
      const finalPpl = this.getFinalPersons(persons);
      let tmpObj = {};
      finalPpl.forEach((ppl) => {
        if (!tmpObj[ppl.uuid]) {
          tmpObj[ppl.uuid] = {
            uuid: ppl.uuid,
            permissions: ppl.permissions,
            ...ppl.user,
          };
        }
      });
      // if (this.searchUsers && this.searchUsers !== '' && this.searchUsers.length >= 2) {
      //   return tmpObj.filter(user => user && ((user || {}).name || "").toLowerCase().indexOf(this.searchUsers.toLowerCase()) !== -1 )
      // }
      return tmpObj;
    },
    searchFunction() {
      if (
        this.searchUsers &&
        this.searchUsers !== "" &&
        this.searchUsers.length >= 2
      ) {
        if (this.dataType == "favorites") {
          return sortBy(
            entries(this.state.group).map(([uuid, value]) => ({
              uuid,
              permissions: value.permissions,
              ...value.user,
            })),
            ["name"]
          ).filter(
            (user) =>
              user &&
              ((user || {}).name || "")
                .toLowerCase()
                .indexOf(this.searchUsers.toLowerCase()) !== -1 &&
              !user.guest &&
              !user.visitor &&
              user.uuid !== this.ownUUID &&
              user.permissions != 10 &&
              store.getUuidFromFavorites(user.uuid)
          );
        } else if (this.dataType == "participants") {
          return sortBy(
            entries(this.state.group).map(([uuid, value]) => ({
              uuid,
              permissions: value.permissions,
              ...value.user,
            })),
            ["name"]
          ).filter(
            (user) =>
              user &&
              ((user || {}).name || "")
                .toLowerCase()
                .indexOf(this.searchUsers.toLowerCase()) !== -1 &&
              user.uuid !== this.ownUUID &&
              user.bridgeCallInfo?.callUUID === store.state.user.bridgeCallInfo?.callUUID
          );
        } else {
          return sortBy(
            entries(this.state.group).map(([uuid, value]) => ({
              uuid,
              permissions: value.permissions,
              ...value.user,
            })),
            ["name"]
          ).filter(
            (user) =>
              user &&
              ((user || {}).name || "")
                .toLowerCase()
                .indexOf(this.searchUsers.toLowerCase()) !== -1 &&
              !user.guest &&
              !user.visitor &&
              user.uuid !== this.ownUUID &&
              user.permissions != 10
          );
        }
      } else {
        if (this.dataType == "favorites") {
          return sortBy(
            entries(this.state.group).map(([uuid, value]) => ({
              uuid,
              permissions: value.permissions,
              ...value.user,
            })),
            ["name"]
          ).filter(
            (user) =>
              !user.guest &&
              !user.visitor &&
              user.permissions != 10 &&
              user.uuid !== this.ownUUID &&
              store.getUuidFromFavorites(user.uuid)
          );
        } else if (this.dataType == "participants") {
          return sortBy(
            entries(this.state.group).map(([uuid, value]) => ({
              uuid,
              permissions: value.permissions,
              ...value.user,
            })),
            ["name"]
          ).filter(
            (user) =>
              user.uuid !== this.ownUUID &&
              user.bridgeCallInfo?.callUUID === store.state.user.bridgeCallInfo?.callUUID
          );
        } else {
          return sortBy(
            entries(this.state.group).map(([uuid, value]) => ({
              uuid,
              permissions: value.permissions,
              ...value.user,
            })),
            ["name"]
          ).filter(
            (user) =>
              !user.guest &&
              !user.visitor &&
              user.permissions != 10 &&
              user.uuid !== this.ownUUID
          );
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.cursorPointer{
  cursor: pointer;
}
.customheightList{
  height: 50px!important;
}
// .buttonMessage{
//   position: absolute;
//   right: 5px;
// }
// .buttonCall{
//   position: absolute;
//   right: 40px;
// }
.lateralPanel {
  position: absolute;
  left: 0px;
  width: 0px;
  // height: calc(100% - 60px);
  height: 100%;
  opacity: 0;
  z-index: 9;
  transition: width 0.1s ease-in;
  color: white !important;
  background: #202224;
  &.active {
    opacity: 1;
    width: 350px;
    max-width: calc(100vw - 40px);
    left: 55px;
    z-index: 9;
  }
  .panelContent {
    height: 100%;
    .v-list {
      border-radius: 0px !important;
      background: transparent !important;
      width: 100%;
      max-height: calc(100% - 56px);
      .v-list-item {
       /* background: #202324 !important;*/
        color: white !important;
        /* NEW */
        background-color: #464b4e !important;
        border-color: #464b4e !important;
        margin: 0px !important;
      }
    }
  }
  .header {
    /*background-color: #202424;*/
    height: 56px;
    display: flex;
    align-items: center;
    /* NEW */
    background-color: #464b4e !important;
    border-color: #464b4e !important;
  }
  .listUsers {
    height: 100%;
  }

  .searchReduced {
    max-width: 90%;
  }
  .rowUsers {
    margin: 0;
    height: calc(100% - 56px);
    overflow: auto;
    max-height: calc(100% - 56px);
    .v-list {
      .v-avatar {
        border-radius: 5px !important;
      }
    }
  }
}
.leftMobile{
  left: 40px !important;
}
.customWidthPerson{
  width: calc(100% - 2.5em);
}
</style>
<style lang="scss">
.v-theme--light .listUsers {
  .header {
    .v-input__slot {
      background-color: white !important;
    }
  }
}
</style>