import { sendMessageBeforeConferenceDomain } from './domain';

import { setSendMessageBeforeConferenceEvent, resetSendMessageBeforeConferenceEvent } from './events';

export const sendMessageBeforeConferenceStore = sendMessageBeforeConferenceDomain.createStore(false, { name: 'sendMessageBeforeConference' });

sendMessageBeforeConferenceStore.on(setSendMessageBeforeConferenceEvent, (state, payload) => {
  return payload;
});

sendMessageBeforeConferenceStore.reset(resetSendMessageBeforeConferenceEvent);
