import { alertsDomain } from './domain';

export const addAlertEvent = alertsDomain.createEvent('addAlert');
export const delAlertEvent = alertsDomain.createEvent('delAlert');
export const clearAlertsEvent = alertsDomain.createEvent('clearAlerts');

export const dispatchSuccessAlert = alertsDomain.createEvent('dispatchSuccess');
export const dispatchInfoAlert = alertsDomain.createEvent('dispatchInfo');
export const dispatchWarningAlert = alertsDomain.createEvent('dispatchWarning');
export const dispatchErrorAlert = alertsDomain.createEvent('dispatchError');

// https://gist.github.com/gordonbrander/2230317
const ID = function () {
  return '_' + Math.random().toString(36).substr(2, 9);
};

dispatchSuccessAlert.watch((payload) => addAlertEvent({ id: ID(), type: 'success', text: payload }));
dispatchInfoAlert.watch((payload) => addAlertEvent({ id: ID(), type: 'info', text: payload }));
dispatchWarningAlert.watch((payload) => addAlertEvent({ id: ID(), type: 'warning', text: payload }));
dispatchErrorAlert.watch((payload) => addAlertEvent({ id: ID(), type: 'error', text: payload }));

// addAlertEvent.watch((payload) => console.log('addAlertEvent', payload));
// delAlertEvent.watch((payload) => console.log('delAlertEvent', payload));

// Debug
try {
  document.dispatchSuccessAlert = dispatchSuccessAlert;
  document.dispatchInfoAlert = dispatchInfoAlert;
  document.dispatchWarningAlert = dispatchWarningAlert;
  document.dispatchErrorAlert = dispatchErrorAlert;
} catch (err) { }
