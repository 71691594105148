// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notiUnread[data-v-08ff0d71] {
  background-color: #f5f5f5;
}
.btnRed[data-v-08ff0d71] {
  border-radius: 4px;
}
.vueScroll .__view[data-v-08ff0d71] {
  width: unset !important;
}
.divNotNotis[data-v-08ff0d71] {
  height: 40px;
  padding: 9px;
}
.divNotNotis .notNotifications[data-v-08ff0d71] {
  color: var(--form-text);
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/downloadsDropdown.vue","webpack://./downloadsDropdown.vue"],"names":[],"mappings":"AACA;EACE,yBAAA;ACAF;ADEA;EAEE,kBAAA;ACAF;ADGE;EACE,uBAAA;ACAJ;ADGA;EACE,YAAA;EACA,YAAA;ACAF;ADCE;EACE,uBAAA;ACCJ","sourcesContent":["\n.notiUnread {\n  background-color: #f5f5f5;\n}\n.btnRed {\n  // background: red;\n  border-radius: 4px;\n}\n.vueScroll {\n  .__view {\n    width: unset !important;\n  }\n}\n.divNotNotis {\n  height: 40px;\n  padding: 9px;\n  .notNotifications {\n    color: var(--form-text);\n  }\n}\n",".notiUnread {\n  background-color: #f5f5f5;\n}\n\n.btnRed {\n  border-radius: 4px;\n}\n\n.vueScroll .__view {\n  width: unset !important;\n}\n\n.divNotNotis {\n  height: 40px;\n  padding: 9px;\n}\n.divNotNotis .notNotifications {\n  color: var(--form-text);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
