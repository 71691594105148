
<template>
  <v-footer  class="primaryBackground footerContainer">
    <div class="d-flex justify-space-between align-center w100">
      <v-img
        v-if="mini"
        max-width="25"
        class="cursorPointer"
        :class="{
          'ml-3': isMobile && !mini,
          'ml-6 mr-0': !isMobile && !mini,
          'ml-0': mini,
        }"
        style="display: inline-flex"
        :src="'assets/icon.png'"
        @click="sheet = !sheet"
        contain
      ></v-img>
      <v-tooltip
        top
        v-if="dirty && !callInfoStateStore.callActive && !inBridgeCall && !mini"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            id="updateIcon"
            class="buttonReload"
            v-bind="props"
            icon
            variant="text"
            color="red"
            @click="reloadPage()"
          >
            <font-awesome-icon id="updateFont" :icon="['far', 'repeat']" />
          </v-btn>
        </template>
        <span>{{ $t("components.footer.newUpdate") }}</span>
      </v-tooltip>
      <v-btn
         variant="text"
        @click="sheet = !sheet"
        class="w100 "
        :style="{ textTransform: 'none' }"
        v-if="!mini"
      >
        <span style="font-size: 15px" class="primary--text-sidepanel">
          vOffice &copy;</span
        >
      </v-btn>
        <v-bottom-sheet  class="positionFooter"  :model-value="sheet" @update:model-value="sheet = $event" persistent>
          <v-sheet class="text-center" min-height="200px">
            <v-row :style="{ width: '100%', margin: 0 }" class="px-5 mx-0">
              <v-col cols="12" class="px-0">
                <v-btn class="mt-6" variant="text" color="red" @click="closeFooter()">{{
                  $t("components.footer.close")
                }}</v-btn>
              </v-col>
            </v-row>
            <!-- Add content footer -->
            <div>
              <Contact v-if="activeTab === 'ContactUs'" />
              <About v-if="activeTab === 'AboutUs'" />
            </div>
            <!-- End content footer -->
            <v-row justify="center" no-gutters class="py-8">
              <v-btn
                v-for="link in links"
                :key="link"
                variant="text"
                rounded
                class="my-2"
                @click="changeTab(link)"
                >{{ $t(`literals.${link}`) }}</v-btn
              >
              <v-col class="bg-primary py-2 text-center text-white" cols="12">
                <strong>RA-MICRO Software AG</strong>
                &copy; {{ new Date().getFullYear() }}
              </v-col>
            </v-row>
          </v-sheet>
        </v-bottom-sheet>
    </div>
  </v-footer>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import Contact from "./contact.vue";
import About from "./about.vue";
import { callInfoStateStore } from "../../effector/call";
import { isDirtyStore } from "../../effector/version";
import { isMobile } from "../../lib/mobileUtil";
import { amInABridgeCall } from "../../utils/calls";

export default {
  props: ["mini"],
  components: { Contact, About },
  data() {
    const effector = {
      dirty: isDirtyStore,
      callInfoStateStore: callInfoStateStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      sheet: false,
      state: store.state,
      isMobile: isMobile(),
      links: ["Imprint", "Privacy Policy", "Contact Us"],
      activeTab: "Home",
      // Effector
      ...effector,
    };
  },
  methods: {
    closeFooter() {
      this.sheet = !this.sheet;
      this.activeTab = "Home";
    },
    changeTab(tab) {
      this.activeTab = tab.replace(/ /g, "");
      if (tab == "Imprint") {
        this.openInNewTab("https://www.ra-micro.de/info/impressum.html");
      }
      if (tab == "Privacy Policy") {
        this.openInNewTab(
          "https://www.voffice.pro/datenschutzerklaerung-voffice-software"
        );
      }
    },
    openInNewTab(url) {
      window.open(url);
    },
    reloadPage() {
      window.location.reload();
    },
  },
  computed: {
    inBridgeCall() {
      return amInABridgeCall();
    },
  },
};
</script>
<style>
.positionFooter{
  margin-top: 65px!important;
}
</style>
<style scoped lang="scss">
.footerContainer{
  position: fixed;
  left: 0px;
    right: 0px;
    bottom: 0px;
}
.buttonReload {
  position: absolute;
  z-index: 999;
}
.w100 {
  width: 100%;
}
.cursorPointer {
  cursor: pointer;
}
#updateFont {
  animation: blink 1s linear infinite;
  animation-direction: alternate;
}
.w100 {
  width: 100%;
}

@keyframes blink {
  0% {
    font-size: 12px;
  }
  50% {
    font-size: 16px;
  }
  100% {
    font-size: 20px;
  }
}
</style>