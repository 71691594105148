// import { createEvent } from 'effector';
import { callDomain } from './domain';

export const setCallInfoStateFromManagerEvent = callDomain.createEvent('setCallInfoStateFromManager');

export const callAddDurationEvent = callDomain.createEvent('callAddDuration');
export const callLessDurationEvent = callDomain.createEvent('callLessDuration');

export const callSetMessageToParticipantsEvent = callDomain.createEvent('callSetMessageToParticipants');
export const callSetPresentationViewEvent = callDomain.createEvent('callSetPresentationView');

export const callSetWantToSpeak = callDomain.createEvent('callSetWantToSpeak');
export const callSetRemoteFullSizeFor = callDomain.createEvent('callSetRemoteFullSizeFor');

export const callSetAllowedToTalkUUIDs = callDomain.createEvent('callSetAllowedToTalkUUIDs');
export const callUpdateAddAllowedToTalkUUID = callDomain.createEvent('callUpdateAddAllowedToTalkUUID');
export const callUpdateRemoveAllowedToTalkUUID = callDomain.createEvent('callUpdateRemoveAllowedToTalkUUID');

export const callUpdateAddNotAllowedToTalkUUID = callDomain.createEvent('callUpdateAddNotAllowedToTalkUUID');
export const callUpdateRemoveNotAllowedToTalkUUID = callDomain.createEvent('callUpdateRemoveNotAllowedToTalkUUID');

// export const callUpdateDurationEvent = createEvent('callUpdateDurationEvent');
// export const callSetFullSizeEvent = createEvent('callSetFullSizeEvent');
// export const callRequestSpeakerEvent = createEvent('callRequestSpeakerEvent');
