<template>
  <div>
    <span>LogFilter</span>
    <input type='text' :model-value="filter" @update:model-value="filter = $event" />
  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import { loggingFilter, setLoggingFilterEvent } from '../../../effector/logging';

export default {
  // props: [],
  components: { },
  data() {
    const effector = {
      loggingFilter: loggingFilter,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      // Effector
    ...effector,
    };
  },
  methods: {
  },
  computed: {
    filter: {
      get() {
        return this.loggingFilter;
      },
      set(val) {
        setLoggingFilterEvent(val);
      },
    }
  },
};
</script>

<style scoped lang="scss">

</style>