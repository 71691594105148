import {

  dispatchReadedNoteEvent,
  dispatchFetchMyNotesEvent,
  setNoteUUIDsEvent,
  dispatchFetchNoteInfosEvent,
  setNotesInfoBatchedEvent,
  newNoteEvent,
  dispatchAddOneViewNoteEvent,
  dispatchDeleteNoteEvent
} from './events';

import {
  markAsReadedByUserEffect,
  fetchMyNotesEffect,
  getNoteEntryByUUIDEffect,
  createNoteEntryEffect,
  increaseOneViewNoteEffect,
  deleteNoteEffect
} from './effects';

import {
  unknownNotes,
  outdatedNotes
} from './state';

import { parallel } from '../../lib/asyncUtil';
import { wsConnectedEvent } from '../ws';

// TODO: Remove from here put in the actual compoinent for messages
wsConnectedEvent.watch(() => {
  dispatchFetchMyNotesEvent()
});

dispatchFetchMyNotesEvent.watch((payload) => {
  fetchMyNotesEffect();
});

fetchMyNotesEffect.done.watch(({ params, result }) => {
  setNoteUUIDsEvent(result);
});

// setMessageEventInfo.watch((payload) => {
//   console.log('Watch setMessageEventInfo', payload)
//   // const [entryUUID, entryInfo] = payload;
//   // const modalInfo = eventModalStateStore.getState();

//   // // console.log('setTimelineEventInfoEvent', payload, modalInfo, modalInfo && modalInfo.uuid === entryUUID);
//   // if (modalInfo && modalInfo.uuid === entryUUID) {
//   //   setEventStateModalEvent(entryInfo);
//   // }
// });

newNoteEvent.watch((payload) => {
  createNoteEntryEffect(payload);
});

// replyNoteEvent.watch((payload) => {
//   createReplyNoteEntryEffect(payload);
// });

unknownNotes.watch((state) => {
  // State: array of uuids we dont know about
  // console.log('unknownMessages', state);
  dispatchFetchNoteInfosEvent(state);
});

outdatedNotes.watch((state) => {
  // State: array of uuids we know about but are outdated
  // console.log('outdatedMessages', state);
  dispatchFetchNoteInfosEvent(state);
});

dispatchFetchNoteInfosEvent.watch(async (uuids) => {
  if (!uuids.length) return; // Nope
  if (getNoteEntryByUUIDEffect.pending.getState()) {
    // No need to fetch while fetching, new fetched things will trigger an update which will then fetch whatever new thing could be missing
    return;
  }
  const acc = {};
  await parallel(10, [...uuids], async (uuid) => {
    const info = await getNoteEntryByUUIDEffect(uuid);
    if (typeof info !== 'undefined' && info !== null) {
      acc[uuid] = info;
    }
  });
  const notEmpty = Object.keys(acc).length;
  // console.log('dispatchFetchNoteInfosEvent Fetched', notEmpty, acc);
  if (notEmpty) setNotesInfoBatchedEvent(acc);
});

// dispatchViewedMessageEvent.watch(() => {
//   const params = ['viewed', true];
//   markAsViewedByUserEffect(params);
// });

dispatchReadedNoteEvent.watch((noteUUID) => {
  // console.log(noteUUID, "noteUUID dispatchReadedNoteEvent")
  const params = [noteUUID, 'read', true];
  markAsReadedByUserEffect(params);
});

markAsReadedByUserEffect.done.watch((data) => {
  // dispatchAddOneViewNoteEvent(data.params[0])
});

dispatchAddOneViewNoteEvent.watch((noteUUID) => {
  const params = [noteUUID];
  increaseOneViewNoteEffect(params);
})

dispatchDeleteNoteEvent.watch((noteUUID) => {
  const params = [noteUUID];
  deleteNoteEffect(params);
})

// dispatchRepliedMessageEvent.watch(() => {
//   const params = ['replied', true];
//   markAsRepliedByUserEffect(params);
// });

// dispatchDeleteAllMessageEvent.watch(() => {
//   const params = ['deleted', true];
//   markAsDeleteAlldByUserEffect(params);
// });

// markAsViewedSpecificMessageEvent.watch((messageUUID) => {
//   const params = [messageUUID, 'viewed', true];
//   markAsViewedSpecificMessageEffect(params);
// });

// markAsReadSpecificNoteEvent.watch((noteUUID) => {
//   const params = [noteUUID, 'read', true];
//   markAsReadSpecificNoteEffect(params);
// });

// dispatchDeleteMessageEvent.watch((messageUUID) => {
//   const params = [messageUUID, 'deleted', true];
//   markAsDeletedByUserEffect(params);
// });
