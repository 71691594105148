<template>
  <v-dialog
    :model-value="showModalPaymentModal" @update:model-value="showModalPaymentModal = $event"
    :fullscreen="isMobile"
    persistent
    max-width="570"
    @click.stop.prevent
    hide-overlay
    class="paymentModal"
  >
    <div class="w100">
      <v-card>
        <!-- Title -->
        <HeaderModal
          :titleModal="$t('components.paymentModal.titleRequestPayment')"
          :closeModalFunction="declinePayment"
          :actualDate="actualDate"
        />
        <!-- End title -->
        <!-- Modal body -->
        <div
          v-if="
            !state.user.requestedPayment.successPayment &&
            !state.user.requestedPayment.errorPayment
          "
          :class="{ modalHeightMobile: isMobile }"
        >
          <v-row class="mx-3 mb-4 mt-5">
            <v-col cols="12" class="mb-2 pt-0">
              <p v-if="isPayPalSandbox" class="mb-3 -mt-2 colorRed">
                {{ $t("components.paymentModal.testMode") }}
              </p>
              <v-text-field
                @click.stop.prevent
                :model-value="state.user.requestedPayment.customerNumber" @update:model-value="state.user.requestedPayment.customerNumber = $event"
                :label="$t('components.paymentModal.customerNumber')"
                class="pointerNoEvents"
                variant="outlined"
                density="compact"
                readonly
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="mb-2 pt-0">
              <v-combobox
                @click.stop.prevent
                :model-value="state.user.requestedPayment.userToPay" @update:model-value="state.user.requestedPayment.userToPay = $event"
                readonly
                class="pointerNoEvents"
                select
                item-title="name"
                item-value="uuid"
                :label="$t('components.paymentModal.conversationWith')"
                variant="outlined"
                density="compact"
                hide-details
                append-icon=""
              ></v-combobox>
            </v-col>
            <v-col
              cols="12"
              class="mb-2 pt-0"
              v-if="
                state.user.requestedPayment.note &&
                state.user.requestedPayment.note != ''
              "
            >
              <v-text-field
                @click.stop.prevent
                :model-value="state.user.requestedPayment.note" @update:model-value="state.user.requestedPayment.note = $event"
                :label="$t('components.paymentModal.note')"
                class="pointerNoEvents"
                variant="outlined"
                density="compact"
                hide-details="auto"
                readonly
              >
              </v-text-field>
            </v-col>
            <v-col cols="5" class="mb-0 py-0 pt-0"> </v-col>
            <!-- <v-col cols="4" class="mb-0 py-0 pt-0">
              <v-checkbox
                disabled
                @click.stop.prevent
                :model-value="state.user.requestedPayment.uptoOption" @update:model-value="state.user.requestedPayment.uptoOption = $event"
                class="mt-0 inoutUpToOption"
                :label="$t('components.paymentModal.upToCheckBox')"
              ></v-checkbox>
            </v-col> -->
            <v-col cols="3" class="mb-0 py-0 pt-0"> </v-col>
            <v-col cols="5" class="mb-2 py-0">
              <v-combobox
                @click.stop.prevent
                :model-value="getProductName" @update:model-value="getProductName = $event"
                class="pointerNoEvents"
                select
                item-value="productId"
                item-text="productName"
                return-object
                variant="outlined"
                density="compact"
                hide-details
                :label="$t('components.paymentModal.product')"
                readonly
                append-icon=""
              ></v-combobox>
            </v-col>
            <v-col cols="4" class="mb-2 py-0">
              <v-text-field
                @click.stop.prevent
                :model-value="state.user.requestedPayment.callDuration" @update:model-value="state.user.requestedPayment.callDuration = $event"
                class="pointerNoEvents"
                variant="outlined"
                readonly
                density="compact"
                :min="5"
                type="number"
                :class="`inputWithPrepend ${
                  state.user.requestedPayment.uptoOption ? 'uptoOption' : ''
                }`"
                :label="$t('components.paymentModal.callDuration')"
                hide-details="auto"
              >
                <template
                  slot="prepend-inner"
                  v-if="state.user.requestedPayment.uptoOption"
                >
                  <span>{{ $t("components.paymentModal.upToInput") }}</span>
                </template>

                <template
                  slot="append"
                  v-if="state.user.requestedPayment.uptoOption"
                >
                  <span>{{ $t("literals.Minutes") }}</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="3" class="mb-2 py-0">
              <v-text-field
                @click.stop.prevent
                :model-value="state.user.requestedPayment.total" @update:model-value="state.user.requestedPayment.total = $event"
                :label="$t('components.paymentModal.price')"
                class="pointerNoEvents"
                variant="outlined"
                density="compact"
                readonly
                type="number"
                hide-details="auto"
              >
                <template style="margin-top: 12px" v-slot:append>
                  <font-awesome-icon
                    class="signClass"
                    :icon="['fal', 'euro-sign']"
                    :style="{ fontSize: '15px' }"
                    v-if="state.namespaceSettings.currency === 'EUR'"
                  />
                  <font-awesome-icon
                    class="signClass"
                    :icon="['far', 'pound-sign']"
                    :style="{ fontSize: '15px' }"
                    v-if="state.namespaceSettings.currency === 'GBP'"
                  />
                  <v-img
                    class="signClass"
                    contain
                    height="20"
                    v-if="state.namespaceSettings.currency === 'CHF'"
                    src="img/paymentIcons/swiss-franc-black.png"
                    lazy-src="img/paymentIcons/swiss-franc.png"
                  ></v-img>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <p v-if="isPayPalSandbox" class="mb-3 -mt-2 colorRed">
            {{ $t("components.paymentModal.testMode") }}
          </p>
          <v-col cols="12" v-if="state.user.requestedPayment.successPayment">
            <p class="mb-0">
              {{ $t("components.paymentModal.paymentSuccess") }}
            </p>
          </v-col>
          <v-col cols="12" v-if="state.user.requestedPayment.errorPayment">
            <p class="mb-0">{{ $t("components.paymentModal.paymentError") }}</p>
          </v-col>
        </div>
        <!-- End modal body -->
        <!-- Footer -->
        <v-card color="primary" class="py-2 pl-4 pr-2 footerModal">
          <v-row class="payPalButtonStyle pl-2 btns w-100 mx-0 my-0">
            <slot></slot>
            <PayPalButton
              @click.stop.prevent
              v-if="
                !state.user.requestedPayment.successPayment &&
                !state.user.requestedPayment.errorPayment
              "
              :amount="state.user.requestedPayment.total"
              :currency="state.namespaceSettings.currency"
              :experience="experienceOptions"
              :items="getItems"
              :invoiceNumber="state.user.requestedPayment.customerNumber"
            />
            <template>
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    variant="outlined"
                    icon
                    color="white"
                    tile
                    @click.stop.prevent="declinePayment($event)"
                    class="closeButton"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'times']"
                      :style="{ fontSize: '20px' }"
                    />
                  </v-btn>
                </template>
                <span>{{ $t("generics.close") }}</span>
              </v-tooltip>
            </template>
          </v-row>
        </v-card>
        <!-- End footer -->
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { useStore } from "effector-vue/composition";
import Vue from "@vue/compat";
import store, { EventBus } from "../../../store";
import { getPayPalRegistrationSubscriptionEnv } from "../../../lib/wsMsg";
import { isMobile } from "../../../lib/mobileUtil";
import { wsCall } from "../../../lib/wsConnect";
import {
  paymentEventModalStore,
  setRequestPaymentEventModal,
  setSuccessPayment,
} from "../../../effector/modals";
import { successPaymentEvent } from "../../../effector/rtc";
import HeaderModal from "../../modal/modalComponents/headerModal.vue";
import PayPalButton from "./payPalButton.vue";
import moment from "moment";
export default {
  components: { HeaderModal, PayPalButton },
  props: [],
  data(){
    const effector = {
      showPaymentEventModal: paymentEventModalStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      isMobile: isMobile(),
      state: store.state,
      isPayPalSandbox: false,
      errorCCPayment: false,
      errorTextCC: "",
      experienceOptions: {
        input_fields: {
          no_shipping: 1,
        },
      },
      myItems: [],
      attachTo: false,
      // Effector
      ...effector,
    }
  },
  created() {
    // Using getPayPalRegistrationSubscriptionEnv as it exposes the mode PayPal is configured in the environment
    getPayPalRegistrationSubscriptionEnv()
      .then(({ mode }) => {
        this.isPayPalSandbox = mode === "sandbox";
      })
      .catch((err) =>
        console.warn("getPayPalRegistrationSubscriptionEnv", err)
      );
  },
  mounted() {
    EventBus.$emit("splitViews");
    if (
      !this.state.user.requestedPayment.successPayment &&
      !this.state.user.requestedPayment.errorPayment
    ) {
      let productDescription;
      if (
        this.state.user.requestedPayment.callDuration &&
        this.state.user.requestedPayment.callDuration !== ""
      ) {
        productDescription = `${
          this.state.user.requestedPayment.callDuration
        } min - ${
          (this.state.user.requestedPayment.productInfo || {}).productName ||
          "vOffice Product"
        }`;
      } else {
        productDescription = `${
          (this.state.user.requestedPayment.productInfo || {}).productName ||
          "vOffice Product"
        }`;
      }
      const obj = {
        name: `${
          (this.state.user.requestedPayment.productInfo || {}).productName ||
          "vOffice Product"
        }`,
        category: "DIGITAL_GOODS",
        description: productDescription,
        unit_amount: {
          currency_code: this.state.namespaceSettings.currency,
          value: this.state.user.requestedPayment.total,
        },
        quantity: 1,
      };
      this.myItems.push(obj);
    }
    this.attachTo = this.state.user.requestedPayment.tileId;
    EventBus.$on("paymentSuccess", this.setPaymentSuccess);
    EventBus.$on("paymentCanceled", this.setPaymentCanceled);
  },
  unmounted(){
    EventBus.$off("paymentSuccess", this.setPaymentSuccess);
    EventBus.$off("paymentCanceled", this.setPaymentCanceled);
  },
  methods: {
    setPaymentCanceled() {
      const obj = {
        type: "paypalPayment",
        showCancelPayment: true,
        showErrorPayment: false,
        showSuccessPayment: false,
        attachTo: this.attachTo,
      };
      wsCall(
        "sendToUUID",
        this.state.user.requestedPayment.userToPay.uuid,
        obj
      );
      this.state.user.requestedPayment.show = false;
    },
    setPaymentSuccess() {
      const obj = {
        type: "paypalPayment",
        showCancelPayment: false,
        showErrorPayment: false,
        showSuccessPayment: true,
        attachTo: this.attachTo,
        total: this.state.user.requestedPayment.total,
      };
      wsCall(
        "sendToUUID",
        this.state.user.requestedPayment.userToPay.uuid,
        obj
      );
      this.state.user.requestedPayment.show = false;
      store.setPaymentState(obj);
    },
    declinePayment(event) {
      // Something else
      event.preventDefault();
      event.stopPropagation();
      store.state.user.requestedPayment.show = false;
      wsCall("sendToUUID", this.state.user.requestedPayment.userToPay.uuid, {
        type: "paypalPayment",
        showCancelPayment: true,
        attachTo: this.attachTo,
      });
    },
    async sendPayment(data) {
      this.sendingPayment = true;
      const dataForSuccess = {
        total: this.state.user.requestedPayment.total,
        customerNumber: this.state.user.requestedPayment.customerNumber,
        callDuration: this.callDuration,
        userToPay: {
          name: this.state.user.requestedPayment.userToPay.name,
          uuid: this.state.user.requestedPayment.userToPay.uuid,
        },
        receiver: this.state.user.requestedPayment.receiver,
        ownerMeeting: Object.keys(store.state.remoteStreams)[0],
        successPayment: true,
        errorPayment: false,
      };
      const result = await fetch("/api/creditcard/payment", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => res.json());

      this.sendingPayment = false;
      if (result.success) {
        this.errorTextCC = "";
        this.errorCCPayment = false;
        dataForSuccess.errorPayment = false;
        setSuccessPayment(store.state.ownUUID);
        successPaymentEvent(dataForSuccess);
        setTimeout(() => {
          setRequestPaymentEventModal(false);
        }, 500);
      } else {
        this.messageModal = Vue.prototype.$t(
          "components.paymentModal.errorPayment"
        );
        this.errorInPayment = true;
        this.showModal = true;
        dataForSuccess.successPayment = false;
        dataForSuccess.errorPayment = true;
        successPaymentEvent(dataForSuccess);
        setTimeout(() => {
          setRequestPaymentEventModal(false);
        }, 500);
      }
    },
    getHash() {
      let iframe = document.getElementById("nniframe").contentWindow;
      iframe.postMessage(
        JSON.stringify({ callBack: "getHash" }),
        "https://secure.novalnet.de"
      );
    },
  },
  computed: {
    getProductName() {
      return (
        (this.state.user.requestedPayment.productInfo || {}).productName || ""
      );
    },
    showModalPaymentModal() {
      return this.state.user.requestedPayment.show; //this.showPaymentEventModal && this.state.user.requestedPayment.receiver == this.state.ownUUID;
    },
    getItems() {
      return this.myItems;
    },
    actualDate() {
      return moment(new Date(Date.now())).format("DD.MM.YYYY HH:mm");
    },
  },
};
</script>
<style scoped lang="scss">
.-mt-2{
  margin-top: -12px;
}
.signClass {
  /* position: absolute; */
  top: 0;
  margin: auto;
  bottom: 0;
  right: 10px;
}
.modalHeightMobile {
  height: calc(100% - 185px);
}
.closeButton {
  border-radius: 4px !important;
  min-width: 45px !important;
}

.paymentModal {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.alignRiight {
  text-align: right;
}
.colorRed {
  color: red;
}
.fontSize13 {
  font-size: 13px;
}
a.fontSize13 {
  color: white !important;
  text-decoration: none;
}
.paymentError {
  color: red;
}
.colsForm {
  position: relative;
}
#localVideoDiv {
  .v-dialog__content {
    position: unset !important;
  }
}
.v-theme--light .v-dialog {
  .v-card {
    background: rgba(255, 255, 255, 1);
    word-break: break-word;
  }
}
.v-theme--dark .v-dialog {
  .v-card {
    background: rgba(30, 30, 30, 1);
    word-break: break-word;
  }
}
@media screen and (min-width: 993px) {
  .v-dialog__content {
    position: absolute !important;
  }
}
.paymentRadioButtons {
  height: 25px !important;
}
.paymentIcon {
  width: 25px;
  margin-right: 5px;
  margin-left: -3px;
}
.font15 {
  font-size: 15px;
}
.userStyle {
  padding: 5px;
  width: 90%;
}
.imageLogo {
  display: inline-block;
  vertical-align: middle;
}
.pointerNoEvents {
  cursor: pointer;
  pointer-events: none;
}

</style>
<style lang="scss">
.payPalButtonStyle .paypal-button {
  border: none;
}
.colsForm {
  .v-select {
    .v-text-field__details {
      bottom: -10px !important;
    }
  }
  .v-text-field__details {
    position: absolute;
    bottom: 0;
    margin-bottom: 0 !important;
    left: 0;
  }
}
.errorInput {
  & .v-input__control {
    border: 1px solid red;
  }
}
.inputTextRight {
  input {
    text-align: right !important;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.inoutUpToOption {
  label {
    font-size: 14px;
  }
  .v-input__slot {
    margin: 0px !important;
  }
}
/* Why transparent? looks horrible :(
.videBridgeContainer {
  .v-dialog {
    background-color: transparent !important;
  }
}
*/
.inputWithPrepend {
  .v-input__prepend-inner {
    margin-top: 0px !important;
    width: 50px !important;
    max-width: 50px;
    position: relative;
    top: 13px;
    span {
      width: 50px !important;
      max-width: 50px;
    }
  }
  .v-input__append-inner {
    position: relative;
    top: 4px;
    width: 80px;
    max-width: 80px;
    span {
      width: 60px;
      text-align: right;
    }
  }
  &.uptoOption {
    .v-label--active {
      left: -47px !important;
      right: unset !important;
      left: -47px !important;
      right: unset !important;
      width: 200px;
      max-width: 122px;
    }
  }
}
</style>