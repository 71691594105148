import { languageModalDomain } from './domain';

import { setLanguageModalEvent, resetLanguageModalEvent} from './events';

export const languageModalStore = languageModalDomain.createStore(false, { name: ' languageModal' });

languageModalStore.on(setLanguageModalEvent, (state, payload) => {
  return payload;
});

languageModalStore.reset(resetLanguageModalEvent);
