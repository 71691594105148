// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleGroup .v-icon[data-v-1e311418] {
  font-size: 14px !important;
}
.v-list[data-v-1e311418] {
  padding: 0;
}
.v-list-item__icon[data-v-1e311418] {
  margin-right: 8px !important;
}
.v-card[data-v-1e311418]:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/stats.vue","webpack://./stats.vue"],"names":[],"mappings":"AAEE;EACE,0BAAA;ACDJ;ADKA;EACE,UAAA;ACFF;ADKA;EACE,4BAAA;ACFF;ADKA;EACI,gBAAA;ACFJ","sourcesContent":["\n.titleGroup{\n  .v-icon{\n    font-size: 14px !important;\n  }\n}\n\n.v-list {\n  padding: 0;\n}\n\n.v-list-item__icon{\n  margin-right: 8px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped){\n    border-radius: 0;\n}\n\n\n",".titleGroup .v-icon {\n  font-size: 14px !important;\n}\n\n.v-list {\n  padding: 0;\n}\n\n.v-list-item__icon {\n  margin-right: 8px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
