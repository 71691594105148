// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heightMobileMessages[data-v-125ed0fa] {
  height: calc(100% - 243px);
}
.inputMessageWaitingRoom .v-input__append-inner[data-v-125ed0fa] {
  margin-top: 1px !important;
}
.inputMessageWaitingRoom .v-text-field input[data-v-125ed0fa] {
  color: black !important;
}
.contentMessagesIsReplyMobile[data-v-125ed0fa] {
  height: calc(100% - 10px) !important;
}
.contentMessagesIsReply[data-v-125ed0fa] {
  height: 350px;
  overflow: auto !important;
}
.btns[data-v-125ed0fa] {
  justify-content: flex-end;
}
.v-application .v-card__title[data-v-125ed0fa] {
  font-size: 18px !important;
  margin-bottom: 0px;
}
.vueScroll[data-v-125ed0fa] {
  height: calc(100% - 64px) !important;
}
.vueScroll .__view[data-v-125ed0fa] {
  width: unset !important;
}
.bR4[data-v-125ed0fa] {
  border-radius: 4px;
  width: 36px;
  height: 36px !important;
  min-width: 36px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/notificationsModalEffector.vue","webpack://./notificationsModalEffector.vue"],"names":[],"mappings":"AACA;EACE,0BAAA;ACAF;ADGE;EACE,0BAAA;ACAJ;ADEE;EACE,uBAAA;ACAJ;ADGA;EACE,oCAAA;ACAF;ADEA;EACE,aAAA;EAEA,yBAAA;ACAF;ADEA;EACE,yBAAA;ACCF;ADCA;EACE,0BAAA;EACA,kBAAA;ACEF;ADAA;EACE,oCAAA;ACGF;ADFE;EACE,uBAAA;ACIJ;ADDA;EACE,kBAAA;EACA,WAAA;EACA,uBAAA;EACA,0BAAA;ACIF","sourcesContent":["\n.heightMobileMessages {\n  height: calc(100% - 243px);\n}\n.inputMessageWaitingRoom {\n  .v-input__append-inner {\n    margin-top: 1px !important;\n  }\n  .v-text-field input {\n    color: black !important;\n  }\n}\n.contentMessagesIsReplyMobile {\n  height: calc(100% - 10px) !important;\n}\n.contentMessagesIsReply {\n  height: 350px;\n\n  overflow: auto !important;\n}\n.btns {\n  justify-content: flex-end;\n}\n.v-application .v-card__title {\n  font-size: 18px !important;\n  margin-bottom: 0px;\n}\n.vueScroll {\n  height: calc(100% - 64px) !important;\n  .__view {\n    width: unset !important;\n  }\n}\n.bR4 {\n  border-radius: 4px;\n  width: 36px;\n  height: 36px !important;\n  min-width: 36px !important;\n}\n",".heightMobileMessages {\n  height: calc(100% - 243px);\n}\n\n.inputMessageWaitingRoom .v-input__append-inner {\n  margin-top: 1px !important;\n}\n.inputMessageWaitingRoom .v-text-field input {\n  color: black !important;\n}\n\n.contentMessagesIsReplyMobile {\n  height: calc(100% - 10px) !important;\n}\n\n.contentMessagesIsReply {\n  height: 350px;\n  overflow: auto !important;\n}\n\n.btns {\n  justify-content: flex-end;\n}\n\n.v-application .v-card__title {\n  font-size: 18px !important;\n  margin-bottom: 0px;\n}\n\n.vueScroll {\n  height: calc(100% - 64px) !important;\n}\n.vueScroll .__view {\n  width: unset !important;\n}\n\n.bR4 {\n  border-radius: 4px;\n  width: 36px;\n  height: 36px !important;\n  min-width: 36px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
