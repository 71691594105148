// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vueScrollOrganistion .__view {
  width: unset !important;
}
.vListGroupSections .v-list-item {
  height: 36px !important;
  min-height: 36px !important;
  max-height: 36px !important;
}
.vListGroupSections .v-list-item__icon {
  margin: 5px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/departments.vue","webpack://./departments.vue"],"names":[],"mappings":"AAEE;EACE,uBAAA;ACDJ;ADKE;EACE,uBAAA;EACA,2BAAA;EACA,2BAAA;ACFJ;ADIE;EACE,sBAAA;ACFJ","sourcesContent":["\n.vueScrollOrganistion {\n  & .__view {\n    width: unset !important;\n  }\n}\n.vListGroupSections {\n  .v-list-item {\n    height: 36px !important;\n    min-height: 36px !important;\n    max-height: 36px !important;\n  }\n  .v-list-item__icon {\n    margin: 5px !important;\n  }\n}\n",".vueScrollOrganistion .__view {\n  width: unset !important;\n}\n\n.vListGroupSections .v-list-item {\n  height: 36px !important;\n  min-height: 36px !important;\n  max-height: 36px !important;\n}\n.vListGroupSections .v-list-item__icon {\n  margin: 5px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
