// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.streamCanvas[data-v-4bbed364] {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: black;
}
.streamVideo[data-v-4bbed364] {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.videoAway[data-v-4bbed364] {
  visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./websrc/components/rtc/streamCanvas.vue","webpack://./streamCanvas.vue"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,iBAAA;ACAF;ADGA;EACE,0BAAA;EACA,wBAAA;EACA,6BAAA;EACA,qBAAA;ACAF;ADGA;EACA,kBAAA;ACAA","sourcesContent":["\n.streamCanvas {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n  background: black;\n}\n\n.streamVideo {\n  -moz-transform: scaleX(-1);\n  -o-transform: scaleX(-1);\n  -webkit-transform: scaleX(-1);\n  transform: scaleX(-1);\n}\n\n.videoAway{\nvisibility: hidden;\n}\n",".streamCanvas {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n  background: black;\n}\n\n.streamVideo {\n  -moz-transform: scaleX(-1);\n  -o-transform: scaleX(-1);\n  -webkit-transform: scaleX(-1);\n  transform: scaleX(-1);\n}\n\n.videoAway {\n  visibility: hidden;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
