// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box[data-v-bd8e6660] {
  /*border-right: 3px solid #aa2626; */
  width: 7.6923076923%;
  display: inline-block;
  height: 36px;
  z-index: 5;
  pointer-events: none;
}
.v-theme--light .box[data-v-bd8e6660] {
  background-color: #f7f7f7;
}
.v-theme--dark .box[data-v-bd8e6660] {
  background-color: #363636;
}
.rulerDigit[data-v-bd8e6660] {
  width: 100%;
  font-size: 12px;
  text-align: right;
  position: relative;
  top: calc(100% - 1px);
  /* border-top: 1px solid #aa2626; */
  z-index: 9;
}`, "",{"version":3,"sources":["webpack://./websrc/components/meetingLine/meetingLineNowIndicator.vue","webpack://./meetingLineNowIndicator.vue"],"names":[],"mappings":"AACA;EACE,oCAAA;EACA,oBAAA;EACA,qBAAA;EACA,YAAA;EACA,UAAA;EACA,oBAAA;ACAF;ADGA;EACE,yBAAA;ACAF;ADGA;EACE,yBAAA;ACAF;ADEA;EACI,WAAA;EACA,eAAA;EAEA,iBAAA;EACA,kBAAA;EACA,qBAAA;EACA,mCAAA;EACA,UAAA;ACAJ","sourcesContent":["\n.box {\n  /*border-right: 3px solid #aa2626; */\n  width: calc(100% / 13);\n  display: inline-block;\n  height: 36px;\n  z-index: 5;\n  pointer-events: none;\n}\n\n.v-theme--light .box{\n  background-color: #f7f7f7;\n}\n\n.v-theme--dark .box{\n  background-color: #363636;\n}\n.rulerDigit{\n    width: 100%;\n    font-size: 12px;\n    // font-size: 0.8em;\n    text-align: right;\n    position: relative;\n    top: calc(100% - 1px);\n    /* border-top: 1px solid #aa2626; */\n    z-index: 9;\n}\n",".box {\n  /*border-right: 3px solid #aa2626; */\n  width: 7.6923076923%;\n  display: inline-block;\n  height: 36px;\n  z-index: 5;\n  pointer-events: none;\n}\n\n.v-theme--light .box {\n  background-color: #f7f7f7;\n}\n\n.v-theme--dark .box {\n  background-color: #363636;\n}\n\n.rulerDigit {\n  width: 100%;\n  font-size: 12px;\n  text-align: right;\n  position: relative;\n  top: calc(100% - 1px);\n  /* border-top: 1px solid #aa2626; */\n  z-index: 9;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
