<template>
<div>
  <v-menu offset-y :close-on-click="true" :max-height="370" :max-width="350">
    <template v-slot:activator="{ on }">
      <div v-on="on">
          <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <span
                v-on="on"
                @click="openCloseModalDirectLink()"
                class="cursorPointer"
              >
              <!-- :color="iconsBlack ? 'black' : 'white'" -->
                <font-awesome-icon :icon="['fas', 'user-friends']" :style="{ fontSize: '18px' }" class="primary--text" v-if="iconsBlack"/>
                <font-awesome-icon :icon="['fal', 'user-friends']" :style="{ fontSize: '18px' }" class="primary--text" v-else/>
              </span>
            </template>
            <!-- <span>{{ $t('components.drawerContentCard.missedCalls') }}</span> -->
            <span>{{ $t('components.drawerContentCard.directLink') }}</span>
          </v-tooltip>
      </div>
    </template>
  </v-menu>
    <DirectLinkInvitationModal v-if="showModalDirectLink"  :showModalDirectLink="showModalDirectLink" :openCloseModalDirectLink="openCloseModalDirectLink" />
</div>
</template>

<script>
import store from '../../../store';
import DirectLinkInvitationModal from '../../modal/directLinkInvitationModal.vue'
export default {
  props: ['iconsBlack'],
  components: { DirectLinkInvitationModal },
    data: () => ({
    state: store.state,
    showModalDirectLink: false
  }),
   methods: {
     openCloseModalDirectLink(){
       this.showModalDirectLink = !this.showModalDirectLink
     },
   },
}
</script>
<style scoped lang="scss">
.cursorPointer{
  cursor: pointer
}
</style>