<template>
  <v-card flat class="cardWidth mx-auto pt-0 mb-0" style="border: 2px solid #808080; border-radius: 4px 4px 0px 0px;">
    <v-img
      :src="getAvatarByType(ownUUID)"
      :class="`white--text align-end ${isMobile ? 'heightMob' : 'height240'}`"
      @click="setGuestInModal"
    >
      <div class="gradientBg dataWrapper" v-if="conferenceData">
        <p>
          {{ conferenceData.name }}
        </p>
        <p>
          {{ $t("components.holidays.start") }}:
          {{
            moment(new Date(conferenceData.date)).format("DD.MM.YYYY | H:mm")
          }}
        </p>
        <p>
          <!-- {{moment(conferenceData.time)}} -->
          {{ $t("components.eventEntryForm.duration") }}:
          {{ moment(conferenceData.time).format("H:mm") }}
        </p>
        <p>
          {{ $t("components.groups.totalUsers") }}:
          {{ conferenceData.membersLength }}
        </p>
      </div>
      <div class="gradientBg">
        <v-card-subtitle
          style="color: #fff !important"
          class="titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate"
        >
          {{ titelPerson(state.group[ownUUID]) }}
          {{ namePerson(state.group[ownUUID]) }}
        </v-card-subtitle>
        <ButtonsFrontCard
          :person="state.group[state.user.visitorData.visitorId]"
          :uuid="state.user.visitorData.visitorId"
          sectionName="members"
          :flipCard="flipCard"
          :index="0"
          typeData="waitingroom"
        />
      </div>
    </v-img>
    <!-- </div>
      </template> -->
    <!-- <UserInfoTooltip :person="state.group[ownUUID]"></UserInfoTooltip> -->
    <!-- </v-tooltip> -->
  </v-card>
</template>
<script>
import moment from "../../../../sharedsrc/moment";
import store, { EventBus } from "../../../store";
import { wsCall } from "../../../lib/wsConnect";
import DefaultAvatarGrid from "../../image/defaultAvatarGrid.vue";
import UserInfoTooltip from "../userInfoTooltip.vue";
import UserActionBar from "../../sidebar/userActionBar/userActionBar.vue";
import { wholeBarWithColor } from "../../../utils/color";
import { getStatusColorByUser } from "../../../utils/status";
import { isMobile } from "../../../lib/mobileUtil";
import { getAvatarByType } from "../../../utils/basicFunctions";
import ButtonsFrontCard from "../buttonsFrontCard.vue";
import ButtonsCardWrapper from "../buttonsCardWrapper.vue";
import { getTimelineEntryByUUID } from "../../../lib/wsMsg";
import { setUserProfileEvent } from "../../../effector/modals";
export default {
  data() {
    return {
      state: store.state,
      moment: moment,
      ownUUID: store.state.ownUUID,
      isMobile: isMobile(),
      showUserProfile: false,
      showSetupDevice: false,
      showCamera: false,
      conferenceData: undefined,
      person: undefined,
    };
  },
  props: [
    // "isAssistant",
    // "index",
    // "emailSalutation",
    // "person",
    // "handleClicks",
    // "setBorderByStatus",
    // "getTimeEntered",
    // "hasDasdGuestId",
    // "titelPerson",
    // "namePerson",
    // "getCallText",
    // "personFunction",
    // "disableCallBtn",
    // "callUser",
    // "checkSendMessage",
    // "mailBoxFull",
    // "disableSendFileBtn",
    // "sendFileTo",
    // "deleteVisitor",
  ],
  components: {
    DefaultAvatarGrid,
    UserInfoTooltip,
    UserActionBar,
    ButtonsFrontCard,
    ButtonsCardWrapper,
  },
  created() {
    if (store.state.user.visitorData.eventUUID) {
      getTimelineEntryByUUID(store.state.user.visitorData.eventUUID).then(
        (eventData) => {
          this.conferenceData = {
            name: eventData.name,
            date: eventData.start,
            time: new Date(eventData.end) - new Date(eventData.start),
            membersLength: eventData.users.length,
          };
        },
        (err) => {}
      );
    }
  },
  methods: {
    setGuestInModal() {
      this.person = store.state.group[store.state.user.visitorData.visitorId];
      store.state.ownModalUser =
        this.person.user.visitorData.visitorId === this.state.ownUUID;
      store.state.userOnModal = { ...this.person.user };
      store.state.userOnModal.connected = this.person.connected;
      store.state.userOnModal.uuid = this.person.user.visitorData.visitorId;
      store.state.userOnModal.userTS = this.person.userTS;
      // this.toggleUserProfile();
      setUserProfileEvent(true);
    },
    namePerson(person) {
      if (person && person.user.name) {
        return person.user.name;
      }
    },
    titelPerson(person) {
      return store.getTitelForUuid(person.uuid);
    },
    flipCard(reference) {
      this.$refs[reference][0].flipped = !this.$refs[reference][0].flipped;
    },
    getAvatarByType(uuid) {
      return getAvatarByType(uuid);
    },
    opencloseMicSettings() {
      EventBus.$emit("togleMicSettings");
    },
    cameraPreview(value) {
      this.showCamera = value;
      if (this.showCamera === true) {
        setTimeout(() => {
          this.showCamera = false;
        }, 30000);
      }
    },
    toggleModalSettings() {
      this.showSetupDevice = !this.showSetupDevice;
      this.state.user.guestDeviceSetted = !this.state.user.guestDeviceSetted;
      // if(!this.state.user.guestDeviceSetted){
      //   this.state.user.guestDeviceSetted = true;
      // } else {

      // }
    },
    setUserInModal() {
      store.state.ownModalUser = true;
      store.state.userOnModal = {
        ...store.state.user,
        connected: store.state.wsOpen,
        uuid: store.state.ownUUID,
      };
      this.toggleUserProfile();
    },
    toggleUserProfile() {
      this.showUserProfile = !this.showUserProfile;
    },
    setTotalNotificationCall: function (...args) {
      store.setTotalNotificationCall(...args);
    },
    setTotalNotificationFile: function (...args) {
      store.setTotalNotificationFile(...args);
    },
    setTotalNotificationMessage: function (...args) {
      store.setTotalNotificationMessage(...args);
    },
    wholeBarWithColors() {
      return wholeBarWithColor();
    },
    resetInviteUserNotification() {
      store.setTotalNotificationInvite(0);
    },
    logout() {
      const localSecretKey = "ownSecret_" + this.state.ownUUID;
      const localTwoTokenKey = "ownTwoToken_" + this.state.ownUUID;
      localStorage.removeItem(localSecretKey);
      localStorage.removeItem(localTwoTokenKey);
      localStorage.removeItem("temporalStatus");
      wsCall('revokeTwoToken');
      window.location.reload();
    },
  },
  computed: {
    totalNotificationCall() {
      return this.state.persisted.totalNotificationCall > 0
        ? this.state.persisted.totalNotificationCall
        : 0;
    },
    phoneColorColor() {
      if (this.wholeBarWithColors()) {
        return "#ffffff";
      } else {
        let myColor = getStatusColorByUser(this.state.user, this.state.wsOpen);
        return myColor;
      }
    },
    totalNotificationTicket() {
      return this.state.persisted.totalNotificationTicket > 0
        ? this.state.persisted.totalNotificationTicket
        : 0;
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
    amIGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    totalNotificationMessage() {
      return this.state.persisted.totalNotificationMessage > 0
        ? this.state.persisted.totalNotificationMessage
        : 0;
    },
    totalNotificationFile() {
      return this.state.persisted.totalNotificationFile > 0
        ? this.state.persisted.totalNotificationFile
        : 0;
    },
    totalNotificationInvite() {
      return this.state.persisted.totalNotificationInvite > 0
        ? this.state.persisted.totalNotificationInvite
        : 0;
    },
  },
};
</script>
<style scoped lang="scss">
.heightMob{
  height: 180px;
}
.height240{
  height: 240px;
}
.dataWrapper {
  top: 0;
  position: absolute !important;
  width: 100%;
  height: 100%;
  padding: 5px 5px;
}
.textBlack {
  color: black;
}
.v-theme--light .gradientBg {
  background: linear-gradient(#00000040, #00000099);
}
.v-theme--dark .gradientBg {
  background: linear-gradient(#00000099, #000000cc);
}
.gradientBg {
  position: relative;
}
.settingsButton {
  position: absolute;
  top: -4px;
  right: -3px;
}
.bannerLogo {
  position: absolute;
  max-height: 175px;
  max-width: 175px;
  right: 105px;
  top: 8px;
}
.iconDASD {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 1;
}
.backgroundColor {
  background-color: white !important;
}
.divEditBg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.noBRadius {
  border-radius: 0px !important;
}
.font13 {
  font-size: 13px;
}
.emptyBox {
  position: absolute;
  top: 40%;
}

.wrDelete {
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 9999;
}

.height50 {
  height: 50%;
}
.contentGridWrapper {
  // height: calc(100vh - 202px);
  height: calc(100vh - 130px);
  width: 99%;
  overflow: hidden;
}
.custom7cols {
  width: 14%;
  max-width: 14%;
  flex-basis: 14%;
}

.custom6cols {
  width: 16%;
  //   max-width: 16%;
  flex-basis: 16%;
}

.cardWidth {
  // max-width: 235px;
  max-width: 300px;
}
@media (max-width: 765px) {
  .custom6cols {
    width: 50% !important;
    min-width: 50% !important;
  }
}
// 6 columns
@media (max-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
@media (min-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
.footerCard {
  background: white;
  padding: 0 !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.v-card__subtitle {
  color: black !important;
  /* font-weight: 600 !important; */
}

.v-card__title {
  font-size: 15px !important;
}
.opactity0 {
  opacity: 0;
}
.blackboard {
  // background-image: url("/img/waitingroom_bg.png");
  background-size: cover;
  background-repeat: repeat-y;
}
.memberBackgroundOffline {
  border: 2px solid #808080;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.euroButton {
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: black;
  //   cursor: pointer;
}
.borderUnset {
  border: unset !important;
}
.headerKonf {
  margin-top: 31px;
  padding-bottom: 49px;
  margin: 0 auto;
  text-align: center;
  width: fit-content;
}
.imgButton {
  width: 30px;
}
</style>
