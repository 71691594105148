// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spanModalTitle[data-v-428034fc] {
  font-size: 18px;
  word-break: break-word;
  max-width: 90%;
  line-height: 2;
}
.heightContent[data-v-428034fc] {
  height: 207px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/2fa/modals/steps/2faSendSmsModal.vue","webpack://./2faSendSmsModal.vue"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,sBAAA;EACA,cAAA;EACA,cAAA;ACAF;ADEA;EACE,aAAA;ACCF","sourcesContent":["\n.spanModalTitle {\n  font-size: 18px;\n  word-break: break-word;\n  max-width: 90%;\n  line-height: 2;\n}\n.heightContent {\n  height: 207px;\n}\n",".spanModalTitle {\n  font-size: 18px;\n  word-break: break-word;\n  max-width: 90%;\n  line-height: 2;\n}\n\n.heightContent {\n  height: 207px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
