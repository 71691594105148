// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heigth70[data-v-6b30b10a] {
  height: 70%;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/videoTestModal.vue","webpack://./videoTestModal.vue"],"names":[],"mappings":"AACA;EACE,WAAA;ACAF","sourcesContent":["\n.heigth70 {\n  height: 70%;\n}\n",".heigth70 {\n  height: 70%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
