// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `pre[data-v-1645447c] {
  font-size: 8px;
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./websrc/components/dev/blinkenlights/store.vue","webpack://./store.vue"],"names":[],"mappings":"AACA;EACE,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;ACAF","sourcesContent":["\npre {\n  font-size: 8px;\n  max-height: 100px;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n","pre {\n  font-size: 8px;\n  max-height: 100px;\n  overflow-y: auto;\n  overflow-x: hidden;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
