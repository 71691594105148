import { guard } from 'effector';
import { slowClockTick } from './config';

import { clockDomain } from './domain';
import {
  setCurrentTsEvent,
} from './events';

// 'Fast' clock
export const currentTSStore = clockDomain.createStore(Date.now(), { name: 'currentTS' });
currentTSStore.on(setCurrentTsEvent, (state, payload) => { return payload; });

// 'Slow' clock, for cron and such
export const slowCurrentTSStore = clockDomain.createStore(Date.now(), { name: 'slowCurrentTS' });
function slowFilter(val) {
  const current = slowCurrentTSStore.getState();
  const minDiff = slowClockTick; // 60000 * 5;
  return (val - current >= minDiff);
}
guard({ source: currentTSStore, filter: slowFilter, target: slowCurrentTSStore });

// function isTsAfterNinePm(ts) {
//   const date = new Date(ts);
//   const hours = date.getHours();
//   // return (hours >= 21);
//   return (hours >= 15);
// }

// export const afterNinePmTsEvent = guard({ source: slowCurrentTSStore, filter: isTsAfterNinePm });
