// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerModalMobile[data-v-ca9a2e4c] {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  border-radius: 0px !important;
}
.v-application .v-card__title[data-v-ca9a2e4c] {
  font-size: 18px !important;
  margin-bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/sendMessageModalMobile.vue","webpack://./sendMessageModalMobile.vue"],"names":[],"mappings":"AAEA;EACA,kBAAA;EACI,SAAA;EACA,WAAA;EACA,OAAA;EACA,6BAAA;ACDJ;ADGA;EACE,0BAAA;EACA,kBAAA;ACAF","sourcesContent":["\n\n.footerModalMobile{\nposition: absolute;\n    bottom: 0;\n    width: 100%;\n    left: 0;\n    border-radius: 0px !important;\n}\n.v-application .v-card__title {\n  font-size: 18px !important;\n  margin-bottom: 0px;\n}\n",".footerModalMobile {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  left: 0;\n  border-radius: 0px !important;\n}\n\n.v-application .v-card__title {\n  font-size: 18px !important;\n  margin-bottom: 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
