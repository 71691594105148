<template>
  <div class="h100 justVideo">
    <div ref="localVideoDiv" class="relative localVideo">
      <!-- <div class="callerName">
        <span>{{getNameForUuid(state.ownUUID)}}</span>
      </div> -->
      <!-- <span v-if="state.ownUUID === ownerUUID" class="italicFont videoIdTag" >{{callInfo.callUUID || ''}}</span> -->
      <video
        id="local-video"
        v-if="!this.state.persisted.useBlurEffect"
        playsinline
        autoplay="autoplay"
        :src-object.prop.camel="state.localStreams.user"
        v-bind:class="{ 'd-none': !haveLocalVideo }"
        muted
      ></video>
      <StreamCanvas
        id="local-video"
        v-if="this.state.persisted.useBlurEffect"
        v-bind:class="{ 'd-none': !haveLocalVideo }"
        :stream="state.localStreams.user"
      ></StreamCanvas>

      <div class="noCameraStream" v-bind:class="{ 'd-none': haveLocalVideo }">
        <div class="noCameraContent">
          <v-img
            class="imageNotVideo"
            contain
            :src="getAvatarFromUuid(state.ownUUID)"
            lazy-src="assets/default_profile_picture.png"
          ></v-img>
          <div class="emptyDiv"></div>
        </div>
      </div>

      <div class="callerName">
        <span class="text-truncate">
          {{
            isVisitor(state.ownUUID)
              ? $t("components.userSettings.guest") + " - "
              : ""
          }}
          {{ getTitelForUuid(state.ownUUID) }}
          {{ getNameForUuid(state.ownUUID) }}
          <!-- {{
            isVisitor(state.ownUUID)
              ? " - " + getSubjectVisitor(state.ownUUID)
              : ""
          }} -->
          </span
        >
        <span v-if="state.ownUUID === ownerUUID" class="italicFont">
          Moderator
        </span>
        <!-- v-if="state.ownUUID === ownerUUID" -->
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <span
              v-bind="props"
              class="italicFont"
              style="float: right; margin-right: 9px"
            >
              P2P</span
            >
          </template>
          <span>{{ $t("components.remotePersonCall.p2p") }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
// import { tryWebcam, tryScreenShare, rtcHangup, toggleMuteLocalAudio } from '../../lib/rtcCall';
import {
  rtcHangup,
  // ensureUserStream,
  // updateCallTime
} from "../../lib/rtcConn";
import {
  tryWebcam,
  tryScreenShare,
  toggleMuteLocalAudio,
} from "../../lib/mediaDevices";
// import { Splitpanes, Pane } from "splitpanes";
// // import VueDragResize from "vue-drag-resize";
// import draggable from "vuedraggable";
// import RemotePersonCall from "../rtc/remotePersonCall.vue";
import StreamCanvas from "../rtc/streamCanvas.vue";
import moment from "../../../sharedsrc/moment";
// import ProgressLinear from "../progressLinear/progressLinear.vue";
import { ownerUUID, callInfoStateStore } from "../../effector/call";
import { isVisitor } from "../../utils/privileges";
import { getSubjectVisitor } from "../../utils/basicFunctions.js";

export default {
  components: { /* VueDragResize, */ StreamCanvas },
  props: [],
  data() {
    const effector = {
      ownerUUID: ownerUUID,
      callInfo: callInfoStateStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
  });
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      pLimitW: 0,
      pLimitH: 0,
      spaceShortcutCallAcceptTimeout: null,
      isIpad: this.$device.ipad,
      isIos: this.$device.ios,
      isLocalSoundMuted: false,
      tenSecondSound: false,
      fiveSecondSound: 0,
      actualSize: {
        remote: 80,
        me: 20,
        position: 0,
      },
      ctrlPressed: false,
      originalCallTime: undefined,
      moment: moment,
      secondsBeep1: 0,
      secondsBeep2: 0,
      secondsBeep3: 0,
      // Effector
      ...effector,
    };
  },
  methods: {
    getSubjectVisitor(uuid) {
      return getSubjectVisitor(uuid);
    },
    isVisitor(uuid) {
      return isVisitor(uuid);
    },
    getTitelForUuid(uuid) {
      if (store.getTitelForUuid(uuid) !== "unknown") {
        return store.getTitelForUuid(uuid);
      } else {
        return "";
      }
    },

    toggleMuteSound() {
      this.isLocalSoundMuted = !this.isLocalSoundMuted;
    },

    toggleUseBlurEffect() {
      store.setUseBlurEffect(!this.state.persisted.useBlurEffect);
      // ensureUserStream();
    },
    resizePane(event) {
      this.handleResize();
    },
    handleResize() {
      const el = this.$refs.localVideoDiv;
      if (el) {
        this.pLimitW = el.clientWidth;
        this.pLimitH = el.clientHeight;
      }
    },
    resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
      this.handleResize();
    },
    tryWebcam() {
      return tryWebcam();
    },
    tryScreenShare() {
      return tryScreenShare();
    },
    closeDisplayMedia() {
      return store.closeDisplayMedia();
    },
    rtcHangup() {
      // this.setdurationMeeting(undefined);
      // this.setOwnerMeeting(undefined);
      // this.setmeetingStartAt(undefined);
      this.notificationEnd = false;
      this.originalCallTime = undefined;
      return rtcHangup();
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getAvatarFromUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    toggleMuteLocalAudio() {
      return toggleMuteLocalAudio();
    },
    setdurationMeeting(time) {
      return store.setdurationMeeting(time);
    },
    setmeetingStartAt(value) {
      return store.setmeetingStartAt(value);
    },
    // setOwnerMeeting(value) {
    //   store.setOwnerMeeting(value);
    // },
    // setshowModalEndMeeting(value) {
    //   return store.setshowModalEndMeeting(value);
    // },
  },
  computed: {
    haveLocalVideo() {
      if (!this.state.localStreams.user) return false;
      for (const track of this.state.localStreams.user.getTracks()) {
        if (track.kind === "video") return true;
      }
      return false;
    },
    isLocalAudioMuted() {
      if (!this.state.localStreams.user) return false;
      if (!this.state.localStreams.lastMicChange) return false; // Just for the reference
      for (const track of this.state.localStreams.user.getTracks()) {
        if (track.kind === "audio") return !track.enabled;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.italicFont {
  font-style: italic;
}
.justVideo {
  position: absolute !important;
  bottom: 30px;
  max-width: 350px;
  width: 100%;
}

.faPhoneRotate {
  transform: rotate(230deg) !important;
}
.cardPositionInfo {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  margin: auto;
}
.cardPositionInfoLogo {
  position: absolute;
  left: 0;
  right: unset;
  bottom: 5px;
  margin: auto;
}
.opacity {
  opacity: 0.4;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}
video {
  display: flex;
  background-color: #424242;
  border-radius: 3px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
}
.h100 {
  height: calc((100% / 4) - 30px);
  z-index: 0;
  position: relative;
}

.oneRemote {
  height: calc(100% - 24px);
  z-index: 0;
  position: relative;
}
.twoRemote {
  height: calc((100% - 24px) / 2);
  z-index: 0;
  position: relative;
}
.threeRemote {
  height: calc((100% - 24px) / 3);
  z-index: 0;
  position: relative;
}
.callerName {
  // position: absolute;
  width: 100%;
  color: white;
  // z-index: 2;
  z-index: 99999999;
  height: 30px;
  padding: 6px;
  background-color: linear-gradient;
  background: rgb(21, 30, 39);
  // background: linear-gradient(
  //   0deg,
  //   rgba(21, 30, 39, 0.42) 0%,
  //   rgba(31, 43, 48, 0.43) 100%
  // );
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.86) 0%,
    rgba(0, 0, 0, 0.77) 100%
  );
  font-size: 15px;
  text-align: center;
}
.rtcStatus {
  font-size: 10px;
  z-index: 1;
  position: absolute;
  padding: 10px;
  background: #000000b5;
  border-radius: 3px;
  left: 5px;
  bottom: 5px;
  color: white;
}
.green {
  color: green;
}

.v-theme--light .noVideoStreamContent {
  background-color: #f2f2f2 !important;
}

.v-theme--dark .noVideoStreamContent {
  background-color: #272727 !important;
}

.videos {
  position: relative;
  height: 100%;
  width: 100%;
  .divButtons {
    display: block;
    position: absolute;
    text-align: center;
    width: 30px;
    height: auto;
    justify-content: center;
    left: 25px;
    top: calc(50% - 90px);
    .redBtn {
      background: red;
      color: white !important;
      border-radius: 4px !important;
    }
  }
  // &:hover {
  //   > .divButtons {
  //     display: block;
  //   }
  // }
  .remote-first {
    width: 100%;
    height: 100%;
    max-height: unset;
    &.sharing {
      position: absolute;
      bottom: 0;
      width: 350px;
      left: 0;
      height: auto;
      animation: width 0.7s linear;
    }
  }
  .remote-second {
    width: 100%;
    height: 100%;
    max-height: unset;
  }
  .noVideoStream {
    position: absolute;
    display: table;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: -10;
    font-weight: bold;
    font-size: 30px;
    .noVideoStreamContent {
      display: table-cell;
      vertical-align: middle;
      background: #f2f2f2f2;
    }
    .noVideoStreamAvatar {
      width: 65px;
      height: 65px;
      border-radius: 10px;
      margin: 10px auto 0px auto;
      padding-bottom: 0px !important;
    }
    .v-avatar .v-image {
      border-radius: 10px !important;
    }
    .callAvatarWrapper {
      padding-bottom: 0px;
      img,
      .v-avatar .v-image {
        width: 65px;
        height: 65px;
        border: 4px solid white;
        margin-bottom: 10px !important;
        border-radius: 10px !important;
      }
    }
  }
  .callingText {
    font-weight: normal;
    font-size: 15px;
  }
  #wave {
    margin-top: -15px;
    .dot {
      background: #2a3133;
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 3px;
      animation: wave 1.3s linear infinite;
    }
    .dot:nth-child(2) {
      animation-delay: -1.1s;
    }
    .dot:nth-child(3) {
      animation-delay: -0.9s;
    }

    @keyframes wave {
      0%,
      60%,
      100% {
        transform: initial;
      }

      30% {
        transform: translateY(-15px);
      }
    }
  }
}
.bg {
  background-color: black;
  z-index: 999999 !important;
}

.buttonsUser {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 45px;
  padding-top: 7px !important;
  bottom: 0px;
  position: absolute;
  width: 100%;
  z-index: 9999999999999;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  .pUserName {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0px;
    margin-top: 5px;
    color: white;
  }
  .divButtonsLocal {
    button {
      margin-right: 5px;
      color: white;
      // background: #2a3133;
      // border-radius: 4px;
      &:last-of-type {
        margin-right: 0px;
      }
      &.redBtn {
        // background: red;
      }
    }
  }
}

.v-theme--light .noCameraContent {
  background-color: #f2f2f2 !important;
}

.v-theme--dark .noCameraContent {
  background-color: #272727 !important;
}
.videoIdTag {
  float: left;
  margin-left: 4px;
  color: white;
  position: absolute;
  top: 32px;
  z-index: 9999;
  -webkit-text-stroke: 0.06em black;
  font-size: 1em;
  font-family: verdana;
}
.localVideo {
  /* grid-area: 2 / 3 / span 1 / span 2; */
  z-index: 999;
  // position: absolute;
  // bottom: 0;
  /*width: 340px;*/
  height: 100%;
  right: 0;
  // border: 1px solid #00000066;
  #local-video {
    width: 100%;
    max-height: unset;
    position: relative;
    bottom: 0;
    height: 100%;
  }

  #local-screen {
    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    max-height: unset;
  }
  .noCameraStream {
    // position: absolute;
    display: table;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: -10;
    font-weight: bold;
    font-size: 30px;
    .noCameraContent {
      display: table-cell;
      vertical-align: middle;
      .emptyDiv {
        height: 30px;
      }
    }
    .imageNotVideo {
      width: 65px;
      height: 65px;
      border-radius: 10px;
      margin: 0 auto;
    }
  }

  .buttonsIpad {
    bottom: 64px !important;
  }
  .buttonsUser {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 45px;
    padding-top: 7px !important;
    bottom: 0px;
    position: absolute;
    width: 100%;
    z-index: 9999999999999;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      bottom: 64px !important;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
      bottom: 64px !important;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
      bottom: 64px !important;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
      bottom: 64px !important;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      bottom: 64px !important;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
      bottom: 64px !important;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
      bottom: 64px !important;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
      bottom: 64px !important;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
      bottom: 64px !important;
    }
    .pUserName {
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 0px;
      margin-top: 5px;
      color: white;
    }
    .divButtonsLocal {
      button {
        margin-right: 5px;
        color: white;
        // background: #2a3133;
        // border-radius: 4px;
        &:last-of-type {
          margin-right: 0px;
        }
        &.redBtn {
          // background: red;
        }
      }
    }
  }
}
</style>