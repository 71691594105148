<template>
  <v-list v-if="canInviteGuests || sortedGuests.length">
    <v-list-group
      active-class="listColor"
      v-if="!isGuest"
      :value="state.searchTerm.length>0 ? false: null || isActive"
      @click="setList('guests')"
    >
      <template v-slot:activator>
        <v-icon :class="`${getRoute === '/guest' ? 'primary--text' : ''}`">
          <font-awesome-icon :icon="['fal', 'user-secret']" />
        </v-icon>
        <v-tooltip location="top">
          <template v-slot:activator="{ on }">
            <v-list-item-title
              v-on="on"
              :class="`${getRoute === '/guest' ? 'font-weight-bold primary--text' : ''}`"
              @click.stop.prevent="setCurrentContentVisile('', false, $router); handleGuestView()"
            >
              {{ $t('generics.myGuests') }}
              <span v-if="sortedGuests.length > 0">
                (<span class="textColor">{{connectedGuestLen}}</span>/ {{sortedGuests.length}})
              </span>
            </v-list-item-title>
          </template>
          <span>{{ $t('generics.myGuestsTT') }}</span>
        </v-tooltip>
        <v-tooltip location="top" v-if="!isMobile">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <InviteGuest :typeInvite="'guest'" :isSidebar="true" />
            </div>
          </template>
          <span>{{ $t('components.footernav.inviteGuest') }}</span>
        </v-tooltip>
      </template>
      <UserList :persons="sortedGuests" :section="'My Guests'" />
    </v-list-group>
  </v-list>
</template>
<script>
import store, { EventBus } from "../../store";
import UserList from "./userList.vue";
import InviteGuest from "../navbar/inviteGuest.vue";

export default {
  props: [
    "persons",
    "filterPerson",
    "setCurrentContentVisile",
    "isGuest",
    "active",
    "setList",
    "isMobile"
  ],
  components: {
    UserList,
    InviteGuest
  },
  data: () => ({
    state: store.state
  }),
  methods: {
    handleGuestView() {
      if (!this.isMobile) {
        this.setCurrentContentVisile("guest", true, this.$router);
      }
    }
  },
  computed: {
    canInviteGuests() {
      return store.getUserCanInviteGuests(this.state.ownUUID);
    },
    sortedGuests: function() {
      const guests = [];
      for (let uuid in store.state.group) {
        const exists = store.getUuidFromGuests(uuid);
        if (exists) {
          const person = store.state.group[uuid];
          if (!person || !person.user || person.user.name == "") continue;
          guests.push({ ...person, uuid });
        }
      }
      return guests.filter(this.filterPerson).sort((a, b) => {
        // Compare by last interaction
        // const aLast = store.getLastInteractionWithUuid(a.uuid);
        // const bLast = store.getLastInteractionWithUuid(b.uuid);
        // const now = store.state.currentTS;
        // const min = 60 * 1000;
        // const hour = 60 * min;
        // const buckets = [10 * min, 30 * min, hour, 2 * hour, hour * 24];

        // function getBucketByTimeDiff(now, then) {
        //   const diff = now - then;
        //   for (let i = buckets.length - 1; i > 0; i--) {
        //     if (diff > buckets[i]) return i;
        //   }
        //   return 0;
        // }
        // const aBucketInt = getBucketByTimeDiff(now, aLast);
        // const bBucketInt = getBucketByTimeDiff(now, bLast);
        // if (aBucketInt > bBucketInt) {
        //   return 1;
        // } else if (aBucketInt < bBucketInt) {
        //   return -1;
        // }
        // // compare by last action
        // const aBucketAct = getBucketByTimeDiff(now, a.userTS);
        // const bBucketAct = getBucketByTimeDiff(now, b.userTS);
        // if (aBucketAct > bBucketAct) {
        //   return 1;
        // } else if (aBucketAct < bBucketAct) {
        //   return -1;
        // }
        // Compare by connected state
        if (a.connected && !b.connected) {
          return -1;
        } else if (!a.connected && b.connected) {
          return 1;
        }
        return ((a.user || {}).name || a.uuid || "").localeCompare(
          (b.user || {}).name || b.uuid || ""
        );
      });
    },
    connectedGuestLen: function() {
      return this.sortedGuests.filter(p => p.connected).length;
    },
    isActive() {
      return this.active == "guests";
    },
    getRoute() {
      return this.$route.path;
    }
  }
};
</script>

<style  lang="scss">
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.titleGroup {
  .v-icon {
    font-size: 14px !important;
  }
}

.v-list {
  padding: 0;
}

.v-list-item__icon {
  margin-right: 8px !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.textColor {
  color: var(--v-primary-base);
}

.v-theme--dark .listColor {
  color: white !important;
}
.v-theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>

