<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="740"
    @keydown.esc="closeModal"
  >
    <v-card >
      <HeaderModal
        :titleModal="$t('components.aditionalStatusInfoModal.title')"
        :closeModalFunction="saveStatus"
      />
      <v-card-text>
        <v-textarea
          id="textareaStatus"
          name="input-7-1"
          counter
          color="primary"
          no-resize
          variant="outlined"
          maxlength="150"
          :model-value="additionalStatus" @update:model-value="additionalStatus = $event"
        ></v-textarea>
      </v-card-text>
      <FooterModal :closeModalFunction="closeModal">
        <v-btn
          color="primary"
          :disabled="!additionalStatus?.trim().length"
          @click="saveStatus()"
          >{{ $t("generics.save") }}</v-btn
        >
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  props: ["showModal", "closeModal"],
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
      additionalStatus: store.state.user.additionalStatus,
    };
  },
  watch: {},
  mounted() {},
  methods: {
    saveStatus() {
      store.setAdditionalStatusUser(this.additionalStatus);
      this.closeModal();
    },
    resetStatus() {
      this.additionalStatus = "";
    },
  },
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>