// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lockMessageIcon[data-v-f2d22c62] {
  position: absolute;
  right: -6px;
  top: -10px;
}
.backOnLine[data-v-f2d22c62] {
  width: 25px;
}
.rotatedArrow[data-v-f2d22c62] {
  font-size: 16px;
  transform: rotate(45deg);
  margin-left: -2px;
  margin-top: 0px;
}
.height50[data-v-f2d22c62] {
  height: 50%;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/backOrganisationCard.vue","webpack://./backOrganisationCard.vue"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,WAAA;EACA,UAAA;ACAF;ADEA;EACE,WAAA;ACCF;ADEA;EACE,eAAA;EACA,wBAAA;EACA,iBAAA;EACA,eAAA;ACCF;ADCA;EACE,WAAA;ACEF","sourcesContent":["\n.lockMessageIcon{\n  position: absolute;\n  right: -6px;\n  top: -10px;\n}\n.backOnLine {\n  width: 25px;\n}\n\n.rotatedArrow {\n  font-size: 16px;\n  transform: rotate(45deg);\n  margin-left: -2px;\n  margin-top: 0px;\n}\n.height50 {\n  height: 50%;\n}\n",".lockMessageIcon {\n  position: absolute;\n  right: -6px;\n  top: -10px;\n}\n\n.backOnLine {\n  width: 25px;\n}\n\n.rotatedArrow {\n  font-size: 16px;\n  transform: rotate(45deg);\n  margin-left: -2px;\n  margin-top: 0px;\n}\n\n.height50 {\n  height: 50%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
