import { sendBugDomain } from './domain';

import { setSendBugModalEvent, resetSendBugModalEvent } from './events';

export const sendBugModalStore = sendBugDomain.createStore(false, { name: 'sendBugModal' });

sendBugModalStore.on(setSendBugModalEvent, (state, payload) => {
  return payload;
});

sendBugModalStore.reset(resetSendBugModalEvent);
