import {
  newMessageEvent,
  replyMessageEvent,
  setMessageUUIDsEvent,
  // setMessageEventInfo,
  dispatchFetchMyMessagesEvent,
  dispatchFetchMessageInfosEvent,
  setMessagesInfoBatchedEvent,
  dispatchDeleteMessageEvent,
  dispatchViewedMessageEvent,
  dispatchReadedMessageEvent,
  dispatchRepliedMessageEvent,
  dispatchDeleteAllMessageEvent,
  dispatchDeleteAllSentMessageEvent,
  dispatchDeleteConversationEvent,
  markAsViewedSpecificMessageEvent,
  markAsReadSpecificMessageEvent
  // dispatchGetMessageHistoryEvent,
  // deleteMessageEntryAndUUIDEvent,
} from './events';

import {
  fetchMyMessagesEffect,
  createMessageEntryEffect,
  createReplyMessageEntryEffect,
  getMessageEntryByUUIDEffect,
  markAsViewedByUserEffect,
  markAsReadedByUserEffect,
  markAsRepliedByUserEffect,
  markAsDeleteAlldByUserEffect,
  markAsViewedSpecificMessageEffect,
  markAsReadSpecificMessageEffect,
  markAsDeletedByUserEffect,
  markAsDeleteAllSentByEffect,
  markAsDeleteConversationByEffect
  // getMessageHistoryEffect,
} from './effects';

import {
  unknownMessages,
  outdatedMessages
} from './state';

import { parallel } from '../../lib/asyncUtil';
import { wsConnectedEvent } from '../ws';

// TODO: Remove from here put in the actual compoinent for messages
wsConnectedEvent.watch(() => {
  dispatchFetchMyMessagesEvent()
});

dispatchFetchMyMessagesEvent.watch((payload) => {
  fetchMyMessagesEffect();
});

fetchMyMessagesEffect.done.watch(({ params, result }) => {
  setMessageUUIDsEvent(result);
});

// setMessageEventInfo.watch((payload) => {
//   console.log('Watch setMessageEventInfo', payload)
//   // const [entryUUID, entryInfo] = payload;
//   // const modalInfo = eventModalStateStore.getState();

//   // // console.log('setTimelineEventInfoEvent', payload, modalInfo, modalInfo && modalInfo.uuid === entryUUID);
//   // if (modalInfo && modalInfo.uuid === entryUUID) {
//   //   setEventStateModalEvent(entryInfo);
//   // }
// });

newMessageEvent.watch((payload) => {
  createMessageEntryEffect(payload);
});

replyMessageEvent.watch((payload) => {
  createReplyMessageEntryEffect(payload);
});

unknownMessages.watch((state) => {
  // State: array of uuids we dont know about
  // console.log('unknownMessages', state);
  dispatchFetchMessageInfosEvent(state);
});

outdatedMessages.watch((state) => {
  // State: array of uuids we know about but are outdated
  // console.log('outdatedMessages', state);
  dispatchFetchMessageInfosEvent(state);
});

dispatchFetchMessageInfosEvent.watch(async (uuids) => {
  if (!uuids.length) return; // Nope
  if (getMessageEntryByUUIDEffect.pending.getState()) {
    // No need to fetch while fetching, new fetched things will trigger an update which will then fetch whatever new thing could be missing
    return;
  }
  const acc = {};
  await parallel(10, [...uuids], async (uuid) => {
    const info = await getMessageEntryByUUIDEffect(uuid);
    if (typeof info !== 'undefined' && info !== null) {
      acc[uuid] = info;
    }
  });
  const notEmpty = Object.keys(acc).length;
  // console.log('dispatchFetchMessageInfosEvent Fetched', notEmpty, acc);
  if (notEmpty) setMessagesInfoBatchedEvent(acc);
});

dispatchViewedMessageEvent.watch(() => {
  const params = ['viewed', true];
  markAsViewedByUserEffect(params);
});

dispatchReadedMessageEvent.watch(() => {
  const params = ['read', true];
  markAsReadedByUserEffect(params);
});

dispatchRepliedMessageEvent.watch(() => {
  const params = ['replied', true];
  markAsRepliedByUserEffect(params);
});

dispatchDeleteAllMessageEvent.watch(() => {
  const params = ['deleted', true];
  markAsDeleteAlldByUserEffect(params);
});

dispatchDeleteAllSentMessageEvent.watch(() => {
  const params = ['removedByCreator', true];
  markAsDeleteAllSentByEffect(params);
});

dispatchDeleteConversationEvent.watch((userUUID) => {
  const params = [userUUID, 'deleted', true];
  markAsDeleteConversationByEffect(params);
});

markAsViewedSpecificMessageEvent.watch((messageUUID) => {
  const params = [messageUUID, 'viewed', true];
  markAsViewedSpecificMessageEffect(params);
});

markAsReadSpecificMessageEvent.watch((messageUUID) => {
  const params = [messageUUID, 'read', true];
  markAsReadSpecificMessageEffect(params);
});

dispatchDeleteMessageEvent.watch((messageUUID) => {
  const params = [messageUUID, 'deleted', true];
  markAsDeletedByUserEffect(params);
});
