 <template>
  <div id="groupshead">
    <v-main
      :class="`px-0 py-0 contentGridWrapper`"
      v-on:scroll.native="handleScroll"
    >
      <v-row
        class="mx-auto"
        v-if="sortedData.length > 0 && sortedMembers.length === 0"
      >
        <v-col cols="12" class="pb-1 px-2">
          <p class="apartTitle mb-0">{{ $t("components.conferenceForm.conferenceTemplate") }}</p>
        </v-col>
        <v-col class="custom6cols pb-1 px-2" cols="6">
          <v-card
            class="cardWidth cursorPointer"
            :style="{ border: `1px solid #efefef` }"
            @click="clickOnAddCard"
            v-if="$route.path === '/groups'"
          >
            <div class="divCardAdd">
              <div class="divAdd">
                <font-awesome-icon :icon="['fal', 'plus']" class="buttonAdd" />
                <p class="mb-0 mt-2">
                  {{ $t("components.conferenceForm.newConference") }}
                </p>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col
          v-for="(section, index) in sortedData"
          :key="index"
          class="custom6cols pb-1 px-2"
          cols="6"
          v-show="activeGroupId === section.id || activeGroupId === 0"
          v-if="section.isTemplate"
        >
          <v-card
            class="cardWidth"
            style="cursor: pointer"
            :style="{ border: `1px solid #efefef` }"
            @click.stop.prevent="editTemplate(section.id)"
          >
            <v-img
              src="img/video-conference.svg"
              class="text-white align-end"
              height="180px"
            >
              <div class="bg-white text-black">
                <v-card-title
                  style="height: 25px"
                  class="titleCard  pt-1 pb-1 px-2 customFontSize"
                >
                  {{ section.title }}
                </v-card-title>
                <v-card-subtitle
                  class="subTitleCard  pb-1 px-2 customFontSize"
                >
                  {{ $t("components.groups.members") }}:
                  {{ section.members.length }}</v-card-subtitle
                >
              </div>
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    v-on:click="editTemplate(section.id)"
                    icon
                    class="buttonEdit"
                  >
                    <font-awesome-icon :icon="['fal', 'pen']" />
                  </v-btn>
                </template>
                <span>{{ $t("generics.edit") }}</span>
              </v-tooltip>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </div>
</template>

<script>
import store, { EventBus } from "../../store";
export default {
  components: {},
  data() {
    return {
      state: store.state,
      group: { user: { group: true } },
      ownUUID: store.state.ownUUID,
      activeGroupId: 0,
      members: false,
    };
  },
  mounted: function () {
    if (this.totalGroup && this.totalGroup === 1) {
      this.openMembersGroup();
    }
  },
  watch: {
    totalGroup() {
      if (this.totalGroup && this.totalGroup === 1) {
        this.openMembersGroup();
      }
    },
  },
  methods: {
    handleScroll() {
      EventBus.$emit("closeMenu");
    },
    clickOnAddCard() {
      EventBus.$emit("openNewConferenceEvent");
    },
    editTemplate(templateId) {
      this.$router.push({ path: `/newconference/${templateId}` });
    },
    openMembersGroup() {
      this.showGroupAndMembers(this.sortedData[0]);
      this.members = !this.members;
    },
    showGroupAndMembers(group) {
      if (this.activeGroupId === 0) {
        this.activeGroupId = group.id;
      } else {
        this.activeGroupId = 0;
      }
    },
    getUserInfo(uuid) {
      if (this.state.group[uuid]) {
        let result = this.state.group[uuid];
        result.uuid = uuid;
        result.user.uuid = uuid;
        return result;
      } else {
        return false;
      }
    },
  },
  computed: {
    sortedMembers() {
      let members = [];
      store.state.user.userGroups.forEach((element) => {
        if (element.id === this.activeGroupId) {
          if (element.members) {
            element.members.forEach((member) => {
              let ouser = this.getUserInfo(member.uuid);
              if (ouser && ouser.user) {
                members.push(ouser);
              }
            });
          }
          if (element.guests) {
            element.guests.forEach((member) => {
              let ouser = this.getUserInfo(member.uuid);
              if (ouser && ouser.user) {
                members.push(ouser);
              }
            });
          }
          if (element.visitors) {
            element.visitors.forEach((member) => {
              let ouser = this.getUserInfo(member.visitorId);
              if (ouser && ouser.user) {
                members.push(ouser);
              }
            });
          }
        }
      });
      return members;
    },
    sortedData() {
      const information = [];
      store.state.user.userGroups.forEach((element) => {
        information.push(element);
      });
      return information;
    },
    totalGroup() {
      return this.sortedData.length;
    },
  },
};
</script>
<style scoped lang="scss">
.divCardAdd {
  height: 180px;
  display: table;
  width: 100%;
}
.customFontSize {
  font-size: 14px !important;
  word-break: break-word;
  max-width: 85%;
}
.buttonEdit {
  position: absolute;
  right: 2px;
  bottom: 4px;
  svg {
    font-size: 15px;
    // color: white;
  }
}
.cardActions {
  height: 30px;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
}
.titleCard {
  height: 25px !important;
  line-height: 0 !important;
}
.subTitleCard {
  // color: rgb(255, 255, 255) !important;
  margin-top: -6px !important;
}
.v-theme--light .gradientBg {
  background: linear-gradient(#00000040, #00000099);
}
.v-theme--dark .gradientBg {
  background: linear-gradient(#00000099, #000000cc);
}
.gradientBg {
  min-height: 44px;
}
.colorWhite {
  color: white !important;
  opacity: 1!important;
}
.cardWidth {
  box-shadow: none !important;
  border: 1px solid #efefef;
}
.cursorPointer {
  cursor: pointer;
}
.buttonAdd {
  font-size: 49px;
  color: var(--v-accent-base);
}
.divAdd {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.apartTitle {
  font-weight: 600;
  font-size: 17px;
}
.cardWidth.v-theme--light:focus {
  background: white !important;
}
.contentGridWrapper {
  // height: calc(100vh - 202px);
  height: calc(100vh - 130px);
  overflow: auto;
}
.custom7cols {
  width: 14%;
  max-width: 14%;
  flex-basis: 14%;
}

.custom6cols {
  width: 16%;
  //   max-width: 16%;
  flex-basis: 16%;
}

@media (max-width: 765px) {
  .contentGridWrapper .custom6cols {
    width: 50%;
    min-width: 50%;
  }
}
// 6 columns
@media (max-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
@media (min-width: 1082px) {
  .cardWidth {
    width: 100%;
  }
}
.v-card__subtitle {
  color: black !important;
  /* font-weight: 600 !important; */
}

.v-card__title {
  font-size: 15px !important;
}

</style>

<style lang="scss">
.v-ripple__animation {
  opacity: 0 !important;
}

.v-card--link:focus:before {
  opacity: 0 !important;
}
</style>