// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatarList {
  border-radius: 4px !important;
}
.greenClass {
  font-weight: 800;
  color: green;
}
.guestTableList td {
  height: 55px !important;
  font-weight: 300 !important;
  font-size: 16px !important;
}
.pointer {
  cursor: pointer;
}
.v-theme--dark.v-data-table tbody tr td {
  color: white !important;
}
.v-theme--light.v-data-table thead tr th {
  color: black !important;
}
.v-theme--dark.v-data-table thead tr th {
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/waitingRoom/guestTable.vue","webpack://./guestTable.vue"],"names":[],"mappings":"AACA;EACE,6BAAA;ACAF;ADEA;EACE,gBAAA;EACA,YAAA;ACCF;ADCA;EACE,uBAAA;EACA,2BAAA;EACA,0BAAA;ACEF;ADAA;EACE,eAAA;ACGF;ADDA;EACE,uBAAA;ACIF;ADFA;EACE,uBAAA;ACKF;ADHA;EACE,uBAAA;ACMF","sourcesContent":["\n.avatarList {\n  border-radius: 4px !important;\n}\n.greenClass {\n  font-weight: 800;\n  color: green;\n}\n.guestTableList td {\n  height: 55px !important;\n  font-weight: 300 !important;\n  font-size: 16px !important;\n}\n.pointer {\n  cursor: pointer;\n}\n.v-theme--dark.v-data-table tbody tr td {\n  color: white !important;\n}\n.v-theme--light.v-data-table thead tr th {\n  color: black !important;\n}\n.v-theme--dark.v-data-table thead tr th {\n  color: white !important;\n}\n",".avatarList {\n  border-radius: 4px !important;\n}\n\n.greenClass {\n  font-weight: 800;\n  color: green;\n}\n\n.guestTableList td {\n  height: 55px !important;\n  font-weight: 300 !important;\n  font-size: 16px !important;\n}\n\n.pointer {\n  cursor: pointer;\n}\n\n.v-theme--dark.v-data-table tbody tr td {\n  color: white !important;\n}\n\n.v-theme--light.v-data-table thead tr th {\n  color: black !important;\n}\n\n.v-theme--dark.v-data-table thead tr th {\n  color: white !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
