// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w-100[data-v-99799668] {
  width: 100%;
}
.footerModal[data-v-99799668] {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
}
.footerModal .btns[data-v-99799668] {
  justify-content: flex-end;
}
.guestOrBeaconfooterModal[data-v-99799668] {
  width: 100%;
  border-radius: 0px !important;
}
.guestOrBeaconfooterModal .btns[data-v-99799668] {
  justify-content: space-between !important;
  align-items: center;
}
.guestOrBeaconfooterModal .btns .infoLabel[data-v-99799668] {
  color: black !important;
  max-width: 50%;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/modalComponents/footerModal.vue","webpack://./footerModal.vue"],"names":[],"mappings":"AACA;EACE,WAAA;ACAF;ADEA;EACE,WAAA;EACA,6BAAA;EACA,uBAAA;ACCF;ADAE;EACE,yBAAA;ACEJ;ADCA;EACI,WAAA;EACA,6BAAA;ACEJ;ADAI;EACE,yCAAA;EACA,mBAAA;ACEN;ADDM;EACE,uBAAA;EACA,cAAA;ACGR","sourcesContent":["\n.w-100 {\n  width: 100%;\n}\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n  .btns {\n    justify-content: flex-end;\n  }\n}\n.guestOrBeaconfooterModal {\n    width: 100%;\n    border-radius: 0px !important;\n    // color: white;\n    .btns {\n      justify-content: space-between !important;\n      align-items: center;\n      .infoLabel {\n        color: black !important;\n        max-width: 50%;\n      }\n    }\n  }\n",".w-100 {\n  width: 100%;\n}\n\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n}\n.footerModal .btns {\n  justify-content: flex-end;\n}\n\n.guestOrBeaconfooterModal {\n  width: 100%;\n  border-radius: 0px !important;\n}\n.guestOrBeaconfooterModal .btns {\n  justify-content: space-between !important;\n  align-items: center;\n}\n.guestOrBeaconfooterModal .btns .infoLabel {\n  color: black !important;\n  max-width: 50%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
