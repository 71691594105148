import { paymentModalDomain } from './domain';

import Vue from '@vue/compat';
import { requestVisitorPayPalPayment } from '../../../lib/wsMsg';

export const setRequestedPaymentEffector = paymentModalDomain.createEffect({
  name: 'setRequestedPaymentEffector',
  handler: (payload) => {  return requestVisitorPayPalPayment(payload) },
});

