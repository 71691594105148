// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pointer[data-v-2c407ff9] {
  cursor: pointer;
}
.camMicIcon[data-v-2c407ff9] {
  width: 20px;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./websrc/components/navbar/settingsWaitingRoomUser.vue","webpack://./settingsWaitingRoomUser.vue"],"names":[],"mappings":"AACA;EACE,eAAA;ACAF;ADEA;EACE,WAAA;EACA,YAAA;ACCF","sourcesContent":["\n.pointer {\n  cursor: pointer;\n}\n.camMicIcon {\n  width: 20px;\n  height: auto;\n}\n",".pointer {\n  cursor: pointer;\n}\n\n.camMicIcon {\n  width: 20px;\n  height: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
