<template>
  <v-dialog :model-value="showWelcomeMessage" @update:model-value="showWelcomeMessage = $event" persistent max-width="560">
    <v-card>
      <!-- <v-card-title class="headline">
        <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
        {{ $t('components.welcomeMessage.welcome') }}
      </v-card-title> -->
      <!-- <v-divider></v-divider> -->
      <HeaderModal
        :titleModal="$t('components.welcomeMessage.welcome')"
        :closeModalFunction="closeWelcomeModal"
      />
      <vue-scroll class="vueScrollWelcomMessage">
          <v-row flex class="mx-0">
            <v-col lg="12" class="px-0 py-0 mx-0">
              <v-card-text class="textWelcome">
                Willkommen in Ihrem vOffice.

                Sie sind als erster Benutzer mit Administratorrechten eingerichtet. Sie können nun:

                1. Gäste zu einer Videokonferenz mit bis zu 5 Teilnehmern einladen und dann per Videoanruf kontaktieren. Klicken Sie dazu auf Hörer in der grünen Menüleiste unter dem Bild.

                2. Weitere Benutzer hinzufügen. Rufen Sie die Einstellungen mit Klick auf das Zahnrad-Symbol oben rechts neben dem Bild auf. Wählen Sie User/Benutzer
                
                3. Fügen Sie in der Tabelle weitere Benutzer hinzu. Mindestangaben sind Name und E-Mail-Adresse. Speichern Sie die Tabelle.

                4. Die Benutzer erhalten automatisch eine Einladung zum Beitritt zum vOffice.
                
                5. Organisations-Strukturen: Bereiche, Abteilungen und Teams hinzufügen. Rufen Sie die Einstellungen mit Klick auf das Zahnrad-Symbol oben rechts neben dem Bild auf. Wählen Sie Benutzer.

                6. Ordnen Sie Benutzer den Strukturen zu.

                7. Lassen Sie sich bei der Einrichtung vom vOffice-Support telefonisch beraten.

                8. Benutzer zu Ihren Favoriten hinzufügen. Rufen Sie die Benutzer über das Suchfeld auf. Öffnen Sie das Drei-Punkte-Symbol im Benutze- Menü und fügen Sie den ausgwählten Benutzer zu Ihren Favoriten hinzu.

                9. Sollte der Benutzer keine Einladung erhalten haben, können Sie erneut eine Einladung senden.
              </v-card-text>
            </v-col>
          </v-row>
      </vue-scroll>
      <!-- <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
        <v-row class="px-2 btns" flex :style="{width: '100%', margin: 0}">
          <v-col lg="6" class="px-0 py-0" align="right">
            <v-btn color="primary" @click="closeWelcomeModal">{{ $t('generics.close') }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-spacer></v-spacer> -->
      <FooterModal :closeModalFunction="closeWelcomeModal" class="mt-4">
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import FooterModal from "./modalComponents/footerModal.vue";
import HeaderModal from "./modalComponents/headerModal.vue";


export default {
  components: { FooterModal, HeaderModal },
  props: ['showWelcomeMessage'],
  data: () => ({
    state: store.state
  }),
  methods: {
    closeWelcomeModal() {
      store.state.persisted.showWelcomeMesagge = false;
    }
  }
};
</script>

<style scoped lang="scss">
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
.bodyContainer {
  margin: 40px auto;
}
.textWelcome{
  white-space: pre-line;
}
.vueScrollWelcomMessage{
  height: 450px !important;
}
</style>