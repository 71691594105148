// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cursorPointer[data-v-79b84e66] {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/paypal/products.vue","webpack://./products.vue"],"names":[],"mappings":"AACA;EACE,eAAA;ACAF","sourcesContent":["\n.cursorPointer{\n  cursor: pointer;\n}\n",".cursorPointer {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
