<template>
  <div class="px-0 py-0">
    <v-row>
      <v-col class="mx-2">
        <v-card variant="outlined" class="cardStyle">
          <StatsNumbers />
          <div class="d-flex justify-space-between pa-4">
            <span class="text-subtitle-1 font-weight-bold">
              {{ $t("components.statusContainer.userListStatus") }}:
              <span :style="{ color: getStatusColor + '!important' }">{{
                statusType
              }}</span>
            </span>
            <span class="d-flex align-center">
              <v-text-field
                class="pt-0"
                style="width: 310px"
                :model-value="search" @update:model-value="search = $event"
                append-inner-icon="mdi-magnify"
                :label="$t('generics.search')"
                variant="outlined"
                density="compact"
                hide-details
              ></v-text-field>
              <span class="ml-4 pointer" @click="closeStatus()">
                <font-awesome-icon
                  :icon="['fal', 'times']"
                  :style="{ fontSize: '20px' }"
                />
              </span>
            </span>
          </div>
          <div>
            <v-row class="py-2 contentWrapper mx-0">
              <v-col
                cols="12"
                class="text-center"
                v-if="filteredInterUsers.length <= 0"
              >
                {{ $t("generics.dataNotAvailable") }}
              </v-col>
              <v-col
                cols="3"
                class="userCard d-flex"
                v-for="user in filteredInterUsers"
                :key="user.uuid"
              >
                <v-avatar
                  class="profile"
                  color="grey"
                  size="35"
                  tile
                  :class="{
                    pointer:
                      !disableCallBtn(user.uuid) && user.uuid !== state.ownUUID,
                  }"
                  v-on:click="
                    !disableCallBtn(user.uuid) && user.uuid !== state.ownUUID
                      ? callUser(user.uuid)
                      : ''
                  "
                >
                  <v-img
                    v-if="
                      user.avatar &&
                      user.avatar !== 'img/default_profile_picture.png'
                    "
                    :src="user.avatar || 'img/default_profile_picture.png'"
                    lazy-src="img/default_profile_picture.png"
                  ></v-img>
                  <DefaultAvatar
                    :userData="user"
                    v-if="
                      !user.avatar ||
                      user.avatar === 'img/default_profile_picture.png'
                    "
                    :lowerText="true"
                  ></DefaultAvatar>
                </v-avatar>
                <span class="pl-2 pt-2 text-truncate">{{ user.name }}</span>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from "../../store";
import { callPerson, disableCallBtn } from "../../utils/calls";
import DefaultAvatar from "../image/defaultAvatar.vue";
import StatsNumbers from "./statsNumbers.vue";
export default {
  components: { DefaultAvatar, StatsNumbers },
  props: ["internUsers", "statusType"],
  data() {
    return {
      state: store.state,
      setCurrentContentVisile: store.setCurrentContentVisile,
      search: "",
    };
  },
  mounted() {},
  methods: {
    callUser(uuid) {
      callPerson(uuid, this.$router);
    },
    getPersonByUuid(uuid) {
      return store.getPersonByUuid(uuid);
    },
    disableCallBtn(uuid) {
      if (!uuid) return true;
      let person = this.state.group[uuid];
      return disableCallBtn(person);
    },
    closeStatus() {
      this.setCurrentContentVisile("home", true, this.$router);
    },
  },
  computed: {
    getStatusColor() {
      switch (this.$route.path) {
        case "/status/available":
          return "#008000";
        case "/status/not-available":
          return "#ffa500";
        case "/status/no-call":
          return "#ff0000";
        case "/status/in-call":
          return "var(--v-primary-base)";
        case "/status/offline":
          return "#808080";
        case "/status/total":
          return "";
        case "/status/holidays":
          return "#7208ff";
        case "/status/coffee-break":
          return "#795548";
        case "/status/in-room":
          return "#0abce6";
        default:
          return "";
      }
    },
    filteredInterUsers() {
      if (!this.search) return this.internUsers;
      return this.internUsers.filter((item) => {
        if (!this.search) return this.internUsers;
        return (
          item.name.toLowerCase().includes(this.search.toLowerCase()) ||
          item.titel.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
};
</script>
<style scoped lang="scss">
.userCard {
  height: fit-content;
}
.contentWrapper {
  width: 100%;
  height: auto;
  max-height: calc(100vh - 355px);
  min-height: auto;
  overflow: auto;
}
.cardStyle {
  height: calc(100vh - 250px);
  min-height: calc(100vh - 250px);
}
</style>