// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vueScrollUsers .__view, .vueScrollOne .__view {
  width: unset !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/userList.vue","webpack://./userList.vue"],"names":[],"mappings":"AAEE;EACE,uBAAA;ACDJ","sourcesContent":["\n.vueScrollUsers, .vueScrollOne{\n  .__view{\n    width: unset !important;\n  }\n}\n",".vueScrollUsers .__view, .vueScrollOne .__view {\n  width: unset !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
