<template>
  <v-dialog
    :model-value="dialUpTime" @update:model-value="dialUpTime = $event"
    persistent
    max-width="650"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="isMobile ?$t('components.dialUpTime.titleMobile') :  $t('components.dialUpTime.title')"
        :closeModalFunction="closeModal"
      />
      <div class="d-flex mt-2 w100">
        <v-list-item class="py-0 w100 switchWrapper">
          <div class="py-0 px-3 my-0 w100">
            <v-select
              :model-value="storeDialUpTime" @update:model-value="storeDialUpTime = $event; changeDialUpTime()"
              :items="dialUpTimeItem"
              item-title="state"
              item-value="value"
              :label="$t('components.dialUpTime.incommingCallDuration')"
            ></v-select>
          </div>
        </v-list-item>
      </div>
      <div class="d-flex w100">
        <v-list-item class="py-0 w100 switchWrapper">
          <div class="py-0 px-3 my-0 w100">
            <v-select
              :model-value="storeNumberNotificationWaitingroom" @update:model-value="storeNumberNotificationWaitingroom = $event; changeNumberNotificationWaitingroom()"
              :items="numberNotificationWaitingroomItem"
              item-title="state"
              item-value="value"
              :label="$t('components.dialUpTime.waitingroomNotification')"
            ></v-select>
          </div>
        </v-list-item>
      </div>
      <div class="d-flex px-3">
        <v-list-item class="py-0 px-3 switchWrapper">
          <div class="py-0 my-0">
            <v-list-item-subtitle class="text-subtitle-2 d-flex ml-0">{{
              $t("components.adminSettingsModal.soundSignal")
            }}</v-list-item-subtitle>
            <v-radio-group
              inline
              :model-value="dingDongTimeForRepeat" @update:model-value="dingDongTimeForRepeat = $event; changedingDongTimeForRepeat()"
              :row="!isMobile"
            >
              <v-radio :label="isMobile? `1 ${$t('literals.Minutes')}`: '1'" value="60000"></v-radio>
              <v-radio :class="!isMobile?'ml-3': ''" :label="isMobile?`2 ${$t('literals.Minutes')}`: '2'" value="120000"></v-radio>
              <v-radio :class="!isMobile?'ml-3': ''" :label="isMobile?`3 ${$t('literals.Minutes')}`: '3'" value="180000"></v-radio>
              <v-radio
                :class="!isMobile?'ml-3': ''"
                :label="`5 ${$t('literals.Minutes')}`"
                value="300000"
              ></v-radio>
            </v-radio-group>
          </div>
        </v-list-item>
      </div>
      <FooterModal :closeModalFunction="closeModal" />
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  props: ["dialUpTime", "showDialUpTime"],
  data: () => ({
    state: store.state,
    isMobile: isMobile(),
    storeDialUpTime: store.state.user.userSettings.dialUpTime || 30000,
    storeNumberNotificationWaitingroom:
      store.state.user.userSettings.numberNotificationWaitingroom || 2,
    dingDongTimeForRepeat:
      store.state.user.userSettings.dingDongTimeForRepeat || "180000",
  }),
  methods: {
    changedingDongTimeForRepeat() {
      const temporalUserSettings = store.state.user.userSettings;
      temporalUserSettings.dingDongTimeForRepeat = this.dingDongTimeForRepeat;
      this.setUserSettings(temporalUserSettings);
    },
    setUserSettings(userSettings) {
      return store.setUserSettings(userSettings);
    },
    changeNumberNotificationWaitingroom() {
      const temporalUserSettings = store.state.user.userSettings;
      temporalUserSettings.numberNotificationWaitingroom =
        this.storeNumberNotificationWaitingroom;
      this.setUserSettings(temporalUserSettings);
    },
    changeDialUpTime() {
      const temporalUserSettings = store.state.user.userSettings;
      temporalUserSettings.dialUpTime = this.storeDialUpTime;
      this.setUserSettings(temporalUserSettings);
    },
    closeModal() {
      this.showDialUpTime();
    },
  },
  computed: {
    dialUpTimeItem() {
      return [
        { state: this.$t("components.meetingTime.unlimited"), value: 86400000 },
        { state: "00:10", value: 10000 },
        { state: "00:15", value: 15000 },
        { state: "00:20", value: 20000 },
        { state: "00:30", value: 30000 },
      ];
    },
    numberNotificationWaitingroomItem() {
      return [
        { state: this.$t("components.meetingTime.unlimited"), value: 86400000 },
        { state: "1", value: 1 },
        { state: "2", value: 2 },
        { state: "5", value: 5 },
        { state: "10", value: 10 },
      ];
    },
  },
};
</script>
<style lang="scss">
.v-menu__content{
  margin-top: 36px;
}
</style>
<style scoped lang="scss">
.closeButton {
  background-color: green !important;
  border-radius: 0 !important;
}
</style>