// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.titleGroup .v-icon {
  font-size: 14px !important;
}
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.textColor {
  color: var(--v-primary-base);
}
.v-theme--dark .listColor {
  color: white !important;
}
.v-theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
.iconsSize20 {
  width: 20px !important;
  height: 20px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/visitors.vue","webpack://./visitors.vue"],"names":[],"mappings":"AACA;EACE,2BAAA;ACAF;ADGE;EACE,0BAAA;ACAJ;ADIA;EACE,UAAA;ACDF;ADIA;EACE,4BAAA;ACDF;ADIA;EACE,gBAAA;ACDF;ADGA;EACE,4BAAA;ACAF;ADGA;EACE,uBAAA;ACAF;ADEA;EACE,qCAAA;ACCF;ADCA;EACE,sBAAA;EACA,uBAAA;ACEF","sourcesContent":["\n.v-list-item .v-list-item__title {\n  line-height: 2.5 !important;\n}\n.titleGroup {\n  .v-icon {\n    font-size: 14px !important;\n  }\n}\n\n.v-list {\n  padding: 0;\n}\n\n.v-list-item__icon {\n  margin-right: 8px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}\n.textColor {\n  color: var(--v-primary-base);\n}\n\n.v-theme--dark .listColor {\n  color: white !important;\n}\n.v-theme--light .listColor {\n  color: rgba(0, 0, 0, 0.87) !important;\n}\n.iconsSize20 {\n  width: 20px !important;\n  height: 20px !important;\n}\n",".v-list-item .v-list-item__title {\n  line-height: 2.5 !important;\n}\n\n.titleGroup .v-icon {\n  font-size: 14px !important;\n}\n\n.v-list {\n  padding: 0;\n}\n\n.v-list-item__icon {\n  margin-right: 8px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}\n\n.textColor {\n  color: var(--v-primary-base);\n}\n\n.v-theme--dark .listColor {\n  color: white !important;\n}\n\n.v-theme--light .listColor {\n  color: rgba(0, 0, 0, 0.87) !important;\n}\n\n.iconsSize20 {\n  width: 20px !important;\n  height: 20px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
