<template>
  <div class="devPanel" v-if="showDevPanel">
    <EffectorTest />
    <div>File Transfer Testing (WIP)</div>
    <ul>
      <li v-for="(entry, key) in state.FileTransferModal.files" v-bind:key="key">
        <FileTransferEntry :entry="entry" />
      </li>
    </ul>
  </div>
</template>

<script>
import store from '../../store';
import FileTransferEntry from './fileTransferEntry.vue';
import EffectorTest from './effector.vue';


export default {
  components: { FileTransferEntry, EffectorTest },
  data() {
    return {
      state: store.state,
    };
  },
  methods: { },
  computed: {
    showDevPanel() {
      // return this.state.dev || (Object.keys(this.state.FileTransferModal.files).length > 0);
      return this.state.dev;
    },
  }
};
</script>

<style scoped lang="scss">
.devPanel {
  // position: fixed;
  // top: 100px;
  // right: 5px;
  // border: 1px solid black;
  // background-color: rgba(204, 204, 204, 0.884);
  // display: none;
    position: fixed;
    bottom: 0px;
    left: 5px;
    border: 1px solid black;
    background-color: rgba(204,204,204,0.884);
    pointer-events: none;
}
</style>