<template>
  <v-dialog
    :model-value="showModalEventType" @update:model-value="showModalEventType = $event"
    persistent
    max-width="570"
    @keydown.esc="cancelModalEventTypeFunction"
  >
    <template>
      <v-card class="mx-auto">
        <HeaderModal :titleModal="$t('components.meetingLineModal.eventType')" :closeModalFunction="cancelModalEventTypeFunction" />
        <v-row class="mx-0">
           <v-radio-group :model-value="typeVideoCall" @update:model-value="typeVideoCall = $event" row density="compact" class="mt-0" @change="changeEventType">
            <v-row class="mb-0 mx-0">
              <v-col cols="12">
              <v-tooltip location="top" max-width="300">
                <template v-slot:activator="{ props }">
                  <v-radio v-on="on" value="directCall">
                    <template v-slot:label>
                      <v-img
                        v-if="!theme.global.current.dark"
                        v-on="on"
                        class="mr-1"
                        src="img/direct-call-b.svg"
                        max-height="40"
                        max-width="40"
                        width="40"
                        height="40"
                        contain
                      ></v-img>
                      <v-img
                        v-else
                        v-on="on"
                        class="mr-1"
                        src="img/direct-call-b-white.svg"
                        max-height="40"
                        max-width="40"
                        width="40"
                        height="40"
                        contain
                      ></v-img>
                    <span v-bind="props">{{ $t("components.callsContent.meetingCall") }}</span>
                    </template>
                  </v-radio>
                </template>
                <span>{{
                  $t("components.callsContent.direktCallTT")
                }}</span>
              </v-tooltip>
              </v-col>
              <v-col cols="12">
                <v-tooltip location="top" max-width="300">
                  <template v-slot:activator="{ props }" >
                    <v-radio v-on="on" value="bridgeCall">
                      <template v-slot:label>
                        <v-img
                          v-on="on"
                          v-if="!theme.global.current.dark"
                          class="mr-1"
                          src="img/direct-call-c.svg"
                          max-height="40"
                          max-width="40"
                          width="40"
                          height="40"
                          contain
                        ></v-img>
                        <v-img
                          v-else
                          v-on="on"
                          class="mr-1"
                          src="img/direct-call-c-white.svg"
                          max-height="40"
                          max-width="40"
                          width="40"
                          height="40"
                          contain
                        ></v-img>
                        <span v-bind="props">{{ $t("components.callsContent.conferenceCall") }}</span>
                      </template>
                    </v-radio>
                  </template>
                  <span 
                    >{{ $t("components.callsContent.bridgeCallTT") }}
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-row>
        <FooterModal :closeModalFunction="cancelModalEventTypeFunction">
           <v-btn
              color="primary"
              @click="closeModalEventTypeFunction"
            >{{ $t('generics.ok') }}</v-btn>
        </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { useTheme } from 'vuetify';

export default {
  props: ["showModalEventType","showModalEventTypeFunction", "closeModalEventTypeFunction", "typeVideoCall", "changeEventType", "cancelModalEventTypeFunction"],
  components: {
    HeaderModal,
    FooterModal,
  },
  data: () => ({
    theme: useTheme(),
    state: store.state,
  }),
  mounted() {},
  unmounted() {},

  watch: {},
  methods: {
    
  },
};
</script>

<style scoped lang="scss">

</style>
