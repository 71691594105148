import { directCallInviteModalDomain } from './domain';

import { setDirectCalLInviteModalEvent, resetDirectCallInviteModalEvent } from './events';

export const directCallInvitationModalStore = directCallInviteModalDomain.createStore(false);

directCallInvitationModalStore.on(setDirectCalLInviteModalEvent, (state, payload) => {
  return payload;
});

directCallInvitationModalStore.reset(resetDirectCallInviteModalEvent);