<template>
<div>
  APPOINTMENT!!!!
</div>
</template>

<script>
// import store from "../../../store";

export default {
  props: ['entry'],
  data() {
    return {
      // state: store.state,
    };
  },
  mounted(){
    
  },
  computed: {
    
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>

</style>