import { externalServicesModalDomain } from './domain';

import { setExternalServicesModalEvent, resetExternalServicesModalEvent} from './events';

export const externalServicesModalStore = externalServicesModalDomain.createStore(false, { name: 'externalServicesModal' });

externalServicesModalStore.on(setExternalServicesModalEvent, (state, payload) => {
  return payload;
});

externalServicesModalStore.reset(resetExternalServicesModalEvent);
