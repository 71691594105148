// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.devPanel[data-v-218f3691] {
  position: fixed;
  bottom: 0px;
  left: 5px;
  border: 1px solid black;
  background-color: rgba(204, 204, 204, 0.884);
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./websrc/components/dev/devPanel.vue","webpack://./devPanel.vue"],"names":[],"mappings":"AACA;EAOI,eAAA;EACA,WAAA;EACA,SAAA;EACA,uBAAA;EACA,4CAAA;EACA,oBAAA;ACNJ","sourcesContent":["\n.devPanel {\n  // position: fixed;\n  // top: 100px;\n  // right: 5px;\n  // border: 1px solid black;\n  // background-color: rgba(204, 204, 204, 0.884);\n  // display: none;\n    position: fixed;\n    bottom: 0px;\n    left: 5px;\n    border: 1px solid black;\n    background-color: rgba(204,204,204,0.884);\n    pointer-events: none;\n}\n",".devPanel {\n  position: fixed;\n  bottom: 0px;\n  left: 5px;\n  border: 1px solid black;\n  background-color: rgba(204, 204, 204, 0.884);\n  pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
