// import { timelineDomain.createEvent } from 'effector';
import { conferenceHallsDomain } from './domain';

export const newConferenceHallEvent = conferenceHallsDomain.createEvent('newConferenceHall');
export const updateConferenceHallEvent = conferenceHallsDomain.createEvent('updateConferenceHall');

export const dispatchFetchMyConferenceHallsEvent = conferenceHallsDomain.createEvent('dispatchFetchMyConferenceHallsEvent');
export const dispatchDeleteConferenceHallEvent = conferenceHallsDomain.createEvent('dispatchDeleteConferenceHallEvent');
export const getMyConferenceDetailsEvent = conferenceHallsDomain.createEvent('getMyConferenceDetailsEvent');
export const setConferenceHallDetailEvent = conferenceHallsDomain.createEvent('setConferenceHallDetailEvent');

export const dispatchFetchConferenceHallInfosEvent = conferenceHallsDomain.createEvent('dispatchFetchConferenceHallInfosEvent');

export const setConferenceHallUUIDsEvent = conferenceHallsDomain.createEvent('setConferenceHallUUIDs');
export const setConferenceHallInfoEvent = conferenceHallsDomain.createEvent('setConferenceHallInfo');
export const deleteConferenceHallEntryAndUUIDEvent = conferenceHallsDomain.createEvent('deleteConferenceHallEntryAndUUID');
export const setConferenceHallsInfoBatchedEvent = conferenceHallsDomain.createEvent('setConferenceHallsInfoBatched');
