<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="690"
    @keydown.esc="closeModal"
  >
    <v-card>
      <!-- <v-card-title class="text-h5 d-flex">
        <v-img
          class="mr-1"
          src="assets/icon.png"
          max-height="30"
          max-width="30"
          contain
        ></v-img>
        {{ $t("components.2faOtp.title") }}
      </v-card-title> -->
      <HeaderModal
            :titleModal="$t('components.2faOtp.title')"
            :closeModalFunction="closeModal"
          />
      <div class="pt-2 mx-6 text-body-2">
        <div class="py-2">
          {{ $t("components.2faOtp.scanSentence") }}
        </div>
      </div>
      <v-card-text>
        <div class="d-flex justify-center text-center">
          <div class="w100 h100 text-center">
            <div class="w100 h100 text-center d-flex justify-center text-center">
              <template v-if="loadingQR || !!!otpKeyQR">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                <div class="mt-3">
                  {{ $t("components.2faSms.loadingQR") }} ...
                </div>
              </template>
              <v-img
                v-if="!!otpKeyQR && !loadingQR"
                max-height="240"
                max-width="240"
                :src="otpKeyQR"></v-img>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <v-text-field
            @keyup.enter="otpCode && showModalActivation()"
            autocomplete="off"
            :model-value="otpCode" @update:model-value="otpCode = $event"
            placeholder=" "
            variant="outlined"
            density="compact"
            @keydown.space.prevent
            required
            autofocus
          ></v-text-field>
          <v-alert v-if="errMsg" type="error" :dismissible="true">{{
            errMsg
          }}</v-alert>
          <div class="d-flex justify-end mt-3">
            <v-btn
              color="primary"
              hide-details
              small
              :disabled="!otpCode"
              @click="showModalActivation()"
              >{{ $t("components.2faSms.btnEnable") }}</v-btn
            >
          </div>
        </div>
      </v-card-text>
      <!-- footer modal -->
      <FooterModal :closeModalFunction="closeModal" class="mt-4">
      </FooterModal>
    </v-card>
    <TwoFactorActivationModal
      v-if="showActivation"
      :showActivation="showActivation"
      :toggle2faActivationModal="toggle2faActivationModal"
      :saveEntryNoPhone="saveEntryNoPhone"
    ></TwoFactorActivationModal>
  </v-dialog>
</template>

<script>
import store from "../../../store";
import { setup2FA, enable2FA } from "../../../lib/wsMsg";
import TwoFactorActivationModal from "./steps/2faActivationModal.vue";
import FooterModal from "../../modal/modalComponents/footerModal.vue";
import HeaderModal from "../../modal/modalComponents/headerModal.vue";

export default {
  props: ["showModal", "toggle2faOtp"],
  components: {
    TwoFactorActivationModal,
    FooterModal,
    HeaderModal
  },
  data() {
    return {
      state: store.state,
      errMsg: "",
      otpKeyBase32: "",
      otpKeyQR: "",
      otpKeyUrl: "",
      otpCode: "",
      showActivation: false,
      loadingQR: true,
    };
  },
  methods: {
    saveEntryNoPhone() {
      this.showActivation = false;
      return this.enable2faOtp();
    },
    toggle2faActivationModal() {
      this.showActivation = !this.showActivation;
    },
    showModalActivation() {
      if (
        this.state.user.recoveryPhone ||
        this.state.persisted.ignoreRecoveryPhone
      ) {
        return this.enable2faOtp();
      } else {
        this.showActivation = true;
      }
    },
    async loadData() {
      this.errMsg = "";
      const result = await setup2FA("otp").catch((err) => {
        this.errMsg = err.message || err;
        console.warn(err);
        return false;
      });
      if (result) {
        this.otpKeyBase32 = result.base32;
        this.otpKeyQR = result.google_auth_qr;
        this.otpKeyUrl = result.otpauth_url;
        setTimeout(() => {
          this.loadingQR = false;
        }, 1000);
      } else if (!this.errMsg) {
        this.errMsg = this.$t("components.2faSms.anErrorOccurred");
      }
    },
    closeModal() {
      this.toggle2faOtp();
    },
    async enable2faOtp() {
      this.errMsg = "";
      const result = await enable2FA(this.otpCode).catch((err) => {
        this.errMsg = err.message || err;
        console.warn(err);
        return false;
      });
      if (result) {
        this.toggle2faOtp(true);
      } else if (!this.errMsg) {
        this.errMsg = this.$t("components.2faOtp.invalidCode");
      }
    },
  },
  watch: {
    showModal: {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.loadData();
        } else {
          this.otpKeyBase32 = "";
          this.otpKeyQR = "";
          this.otpKeyUrl = "";
          this.otpCode = "";
          this.loadingQR = true;
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
