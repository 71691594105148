
<template>
  <!-- <dialog-drag :options="options" class="dialog-1"> -->
  <vue-resizable
    class="dragFullScreen"
    :min-width="197"
    :min-height="144"
    :width="width"
    :top="ownVideo ? top : ''"
    :height="height"
    @mount="ownVideo ? eHandler : localHandler"
    @resize:move="ownVideo ? eHandler : localHandler"
    :dragSelector="dragSelector"
  >
    <div class="resizable-content">
      <video
        id="local-video"
        class="local-video drag-container-1"
        autoplay="autoplay"
        :src-object.prop.camel="getVideoSrc"
        v-bind:class="{
          hidden: !getVideoSrc || !haveRemoteFirstVideo,
        }"
        :muted="true"
        v-if="getVideoSrc"
        @click.prevent
        :width="videoWidth"
        :height="videoHeigth"
      ></video>
      <!-- 
        <video
          id="local-screen"
          playsinline
          autoplay="autoplay"
          :src-object.prop.camel="state.remoteStreams[state.presentationView.owner].second"
          v-bind:class="{ hidden: !state.remoteStreams[state.presentationView.owner].second }"
          :muted="true"
          v-if="state.remoteStreams[state.presentationView.owner]"
          @click.prevent
        ></video> -->
<!-- getVideoSrc && haveRemoteFirstVideo -->
      <div
        :class="
          getVideoSrc && haveRemoteFirstVideo
            ? 'hidden'
            : 'noCameraStream'
        "
        :style="{ width: imageWidth, height: imageheigth }"
      >
        <div class="noCameraContent">
          <v-img
            class="imageNotVideo"
            contain
            :src="getAvatarFromUuid(userSrc)"
            lazy-src="assets/default_profile_picture.png"
          ></v-img>
          <div class="emptyDiv"></div>
        </div>
      </div>
    </div>
  </vue-resizable>
  <!-- </dialog-drag> -->
</template>
<script>
import store from "../../store";
// import DialogDrag from "vue-dialog-drag";
import VueResizable from "vue-resizable";
export default {
  components: {
    // DialogDrag,
    VueResizable,
  },
  props: ["userSrc", "ownVideo"],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      dragSelector: ".resizable-content",
      videoWidth: 197,
      videoHeigth: 144,
      imageWidth: 197,
      imageheigth: 197,
      width: 197,
      height: 197,
      top: 650
      // options: {
      //   buttonPin: false,
      //   buttonClose: false,
      //   width: 209,
      //   height: 165,
      // },
    };
  },
  beforeUnmount() {},
  unmounted() {},
  mounted() {},
  watch: {},
  methods: {
    localHandler(data) {
      this.videoWidth = data.width;
      this.imageWidth = data.width;
      this.imageheigth = data.height;
      this.videoHeigth = data.height;
      this.height = data.height;
      this.width = data.width;
      this.top = data.top
    },
    eHandler(data) {
      this.videoWidth = data.width;
      this.imageWidth = data.width;
      this.imageheigth = data.height;
      this.videoHeigth = data.height;
      this.height = data.height;
      this.width = data.width;
    },
    getAvatarFromUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
  },
  computed: {
    getVideoSrc(){
      if (this.ownVideo){
        return this.state.localStreams.user;
      } else {
        return this.state.remoteStreams[this.userSrc].first;
      }
    },
    haveRemoteFirstVideo() {
      if (this.ownVideo){
        if (!this.userSrc) return false;
        if (!this.userSrc) return false;
        for (const track of this.state.localStreams.user.getTracks()) {
          if (track.kind === "video") return true;
        }
      } else {
        if (!this.state.remoteStreams[this.userSrc]) return false;
        if (!this.state.remoteStreams[this.userSrc].first) return false;
        for (const track of this.state.remoteStreams[
          this.userSrc
        ].first.getTracks()) {
          if (track.kind === "video") return true;
        }
      }
      return false;
    },
  },
};
</script>
<style scoped lang="scss">
.dragFullScreen {
  z-index: 99999;
}
.local-video {
  // width: 197px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
}

.noCameraStream {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
}
.noCameraContent {
  width: 100%;
  height: 100%;
  position: relative;
}
.imageNotVideo {
  // width: 154px;
  width: 100%;
  height: 100%;
  position: relative;
}
// .dialog-drag {
//   background-color: black !important;
// }
.resizable-content {
  height: 100%;
  width: 100%;
  background-color: black;
  border: 3px dashed red;
  cursor: move;
}
</style>
