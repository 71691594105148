<template>
  <v-main class="px-0 py-0">
    <v-row>
      <v-col class="mx-2 mr-6">
        <v-card class="elevation-0">
          <template>
            <v-data-table
              :headers="headers"
              :items="users"
              :items-per-page="itemsPerPage"
              class="guestTableList elevation-0"
              :page.sync="page"
              @page-count="pageCount = $event"
              fixed-header
              height="calc(100vh - 265px)"
            >
              <template v-slot:item.avatar="{ item }">
                <v-avatar class="avatarList" size="36">
                  <img
                    :src="item.avatar || 'img/default_profile_picture.png'"
                    lazy-src="img/default_profile_picture.png"
                  />
                </v-avatar>
              </template>
              <template v-slot:item.sendMessage="{ item }">
                <v-btn
                  v-if="item"
                  icon
                  @click="setShowModalSenMsg(item.visitorData.visitorId)"
                >
                  <font-awesome-icon
                    :icon="['fal', 'paper-plane']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <template v-slot:item.editGuest="{ item }">
                <v-tooltip location="top">
                  <template v-slot:activator="{ on }">
                    <v-btn v-if="item" v-on="on" icon>
                      <font-awesome-icon
                        :icon="['fal', 'edit']"
                        :style="{ fontSize: '20px' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("generics.inProgress") }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.resentEmail="{ item }">
                <v-btn
                  v-if="item"
                  icon
                  @click="
                    SendLinkToVisitor(
                      item.visitorData.visitorId,
                      item.visitorData
                    )
                  "
                >
                  <font-awesome-icon
                    :icon="['fal', 'redo']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <template v-slot:item.copyLink="{ item }">
                <v-btn
                  v-if="item"
                  icon
                  @click="copyLink($event, item.visitorData)"
                >
                  <font-awesome-icon
                    :icon="['fal', 'copy']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <template v-slot:item.deleteGuest="{ item }">
                <v-btn v-if="item" icon>
                  <DeleteBasket
                    :indexData="item.visitorData.visitorId"
                    :delFunction="deleteVisitor"
                    extraClasses=""
                    fontSize="20"
                    prevent="false"
                  />
                </v-btn>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import store from "../../../store";
import DeleteBasket from "../../../components/ui/deleteBasket.vue";
import { setHistoryMessagesModalEvent } from "../../../effector/modals";
import { dispatchSuccessAlert } from "../../../effector/alerts";
import { sendVisitorEmail } from "../../../lib/inviteHelpers";
import { getUserInviteLink } from "../../../lib/wsMsg";
import { deleteVisitor } from "../../../utils/waitingRoom";
export default {
  props: ["users", "headers", "itemsPerPage"],
  components: { DeleteBasket },
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      setCurrentContentVisile: store.setCurrentContentVisile,
      page: 1,
      pageCount: 0,
      link: "",
    };
  },
  methods: {
    async copyLink(evt, visitor) {
      let instalink = visitor.instalink;
      if (!instalink) {
        if (this.link === "") {
          instalink = await getUserInviteLink(visitor.visitorId);
          visitor.instalink = instalink;
        } else {
          instalink = this.link;
          visitor.instalink = this.link;
        }
      }
      const selBox = document.createElement("textarea");
      selBox.style.position = "fixed";
      selBox.style.left = "0";
      selBox.style.top = "0";
      selBox.style.opacity = "0";
      selBox.value = instalink;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand("copy");
      document.body.removeChild(selBox);
      evt.target.classList.add("greenClass");
      setTimeout(() => {
        evt.target.classList.remove("greenClass");
        this.link = "";
      }, 1000);
    },
    async deleteVisitor(visId) {
      await deleteVisitor(visId, this.timelineEvents);
    },
    async setShowModalSendInvitation(person) {
      store.state.sendInvitationGuestModal = {
        newInvitation: true,
        invitationData: {
          name: person.name,
          email: person.emailAlias || person.email,
          date: new Date(),
        },
      };
      dispatchSuccessAlert(
        this.$t("components.inviteVisitor.invitationLinkSent")
      );
    },
    async SendLinkToVisitor(uuid, visitor) {
      if (((this.state.group[uuid] || {}).user || {}).visitor) {
        const result = await sendVisitorEmail(visitor);
        if (result) {
          dispatchSuccessAlert(
            this.$t("components.inviteVisitor.invitationLinkSent")
          );
        }
      } else {
        this.setShowModalSendInvitation(visitor);
      }
    },
    setShowModalSenMsg(usersUUID) {
      setHistoryMessagesModalEvent(usersUUID);
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.avatarList {
  border-radius: 4px !important;
}
.greenClass {
  font-weight: 800;
  color: green;
}
.guestTableList td {
  height: 55px !important;
  font-weight: 300 !important;
  font-size: 16px !important;
}
.pointer {
  cursor: pointer;
}
.v-theme--dark.v-data-table tbody tr td {
  color: white !important;
}
.v-theme--light.v-data-table thead tr th {
  color: black !important;
}
.v-theme--dark.v-data-table thead tr th {
  color: white !important;
}
</style>