<template>
  <div :class="!isChildren ? 'orgaPosition' : ''">
    <div class="tree-row" :class="'top-left'" :style="{ transform: `scale(${zoomLevel / 100})` }">
      <div v-for="node in items" :key="node.id" class="tree-node">
        <div :class="getPersonAssistantsByUuids(
                    node.supervisors,
                    node.assistants
                  ).length ? 'node-content-Assisntants': 'node-content'">
          <div class="d-flex">
            <div class="rounded-lg" :class="[getBorderStyleSupervisor(node.supervisors, index)]"
              v-for="(supervisor, index) in node.supervisors" :key="(node.id || node.uuid) + 'supervisor' + supervisor"
              :style="getBorderCard(node.supervisors, supervisor, index)">
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <v-img v-bind="props" :height="node.supervisors.length > 2 ? '44' : '88'" width="auto"
                    :src="getAvatarForUuid(supervisor)" class="align-end"
                    :class="appearInAHigherSection(supervisor, node) ? 'grayScale' : ''" cover loading="lazy"
                    :lazy-src="getDefaultPicture">
                    <div class="gradientBg align-center text-white d-flex">
                      <div class="text-truncate pl-3 pt-1 userNameContainer text-caption">
                        {{ getTitelByUuid(supervisor) }}
                        {{ getNameByUuid(supervisor) }}
                      </div>
                      <div>
                        <ButtonsFrontCard :isOrga="true" :person="getPersonByUuid(supervisor)" :uuid="supervisor"
                          :isRegularGrid="true" @click.stop.prevent />
                      </div>
                    </div>
                    <div class="gradientBg align-center d-flex flex-row text-white justify-space-between"
                      v-if="hasRepresentative(supervisor)"
                      :style="getBorderByStatusFull(getRepresentativeByUuid(supervisor))">

                      <div class="d-flex flex-row align-center" style="max-width: 85%">
                        <!-- <div class="status-dot d-flex" :style="{ backgroundColor: representativeStatusColor(item.uuid) }"></div> -->
                        <CallIcon v-if="!disableCallBtn(getRepresentativeByUuid(supervisor))"
                          :person="getRepresentativeByUuid(supervisor)" :isRegularGrid="true" :ownUUID="ownUUID"
                          class="mr-1"></CallIcon>
                        <span class="text-truncate pl-2 text-caption">
                          {{ getRepresentativeName(supervisor) }}
                        </span>
                      </div>
                    </div>
                  </v-img>
                </template>
                <UserInfoTooltip :person="getPersonByUuid(supervisor)"></UserInfoTooltip>
              </v-tooltip>
            </div>
            <div class="d-flex  align-content-end align-end">
              <div class="font-weight-bold assistantName" v-if="getPersonAssistantsByUuids(
                    node.supervisors,
                    node.assistants
                  ).length">
                  {{ $t("components.organisationCarousel.assistant") }}
                  </div>
                  <v-card v-for="(assistant, index) in getPersonAssistantsByUuids(
                    node.supervisors,
                    node.assistants
                  )" :key="(node.id || node.uuid) + 'assistant' + assistant" class="ml-2 cardWithImg" max-width="80"
                    height="60" flat>
                    <div :style="getBorderCard([], assistant, index)">
                      <v-tooltip location="top">
                        <template v-slot:activator="{ props }">
                          <v-img v-bind="props" height="53" width="auto" :src="getAvatarForUuid(assistant)"
                            class="align-end removeBorderLeft removeBorderRight" cover loading="lazy"
                            :lazy-src="getDefaultPicture">
                            <div class="gradientBg align-center d-flex text-white" v-if="!hasRepresentative(assistant)">
                              <div class="text-truncate pl-3 pt-1 text-caption userNameContainer">
                                {{ getTitelByUuid(assistant) }}
                                {{ getNameByUuid(assistant) }}
                                <!-- {{ getTitelByUuid(assistant) }} {{ getNameByUuid(assistant) }} -->
                              </div>
                              <div>
                                <ButtonsFrontCard :isOrga="true" :person="getPersonByUuid(assistant)" :uuid="assistant"
                                  :isRegularGrid="true" @click.stop.prevent />
                              </div>
                            </div>
                            <div class="gradientBg align-center text-white d-flex justify-space-between"
                              v-if="hasRepresentative(assistant)"
                              :style="getBorderByStatusFull(getRepresentativeByUuid(assistant))">
                              <div class="d-flex align-center pl-1 representativeUserName">
                                <!-- <div class="status-dot d-flex" :style="{ backgroundColor: representativeStatusColor(assistant) }"></div> -->
                                <CallIcon v-if="!disableCallBtn(getRepresentativeByUuid(assistant))"
                                  :person="getRepresentativeByUuid(assistant)" :isRegularGrid="true" :ownUUID="ownUUID"
                                  class="mr-1"></CallIcon>
                                <div class="text-truncate pl-1 text-caption">
                                  {{ getRepresentativeName(assistant) }}
                                </div>
                              </div>
                              <div>
                                <ButtonsFrontCard :isOrga="true" :person="getPersonByUuid(assistant)" :uuid="assistant"
                                  :isRegularGrid="true" @click.stop.prevent />
                              </div>
                            </div>
                          </v-img>
                        </template>
                        <UserInfoTooltip :person="getPersonByUuid(assistant)"></UserInfoTooltip>
                      </v-tooltip>
                    </div>
                  </v-card>
                </div>
          </div>
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <div v-bind="props" class="px-2 text-caption text-truncate">{{ node.name }}</div>
            </template>
            <span>{{ node.name }}</span>
          </v-tooltip>
        </div>
        <div v-if="isChildren" class="line vertical-line-node"></div>
        <div v-if="node.children && node.children.length" class="children-container">
          <div class="line vertical-line"></div>
          <div class="horizontal-line"></div>
          <div class="tree-row">
            <fullOrganigram :isChildren="true" :items="node.children" />
          </div>
        </div>
      </div>
    </div>
    <div :class="showSidebar ? 'zoom-controls-sidebar' : 'zoom-controls-no-sidebar'" v-if="!isChildren">
      <div class="d-flex flex-column text-center">
        <v-btn-group variant="outlined" divided>
          <v-btn @click="zoomIn" icon="mdi-magnify-plus-outline"></v-btn>
          <v-btn @click="zoomOut" icon="mdi-magnify-minus-outline"></v-btn>
        </v-btn-group>
        <span>{{ zoomLevel }}%</span>

      </div>
    </div>
  </div>
</template>

<script>
import store from "../../../store";
import {
  getBorderByStatusFull,
  getCustomBorderByStatusFull
} from "../../../utils/basicFunctions";
import UserInfoTooltip from "../userInfoTooltip.vue";
import ButtonsFrontCard from "../buttonsFrontCard.vue";
import { hasRepresentative } from "../../../utils/representatives";
import { disableCallBtn } from "../../../utils/calls";
import CallIcon from "../callIcon.vue";

export default {
  name: "fullOrganigram",
  directives: {},
  components: {
    UserInfoTooltip,
    ButtonsFrontCard,
    CallIcon
  },
  props: [
    "items",
    "isChildren"
  ],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      zoomLevel: 100,
      isLading: true
    };
  },
  watch: {

  },
  mounted() { 
  },
  beforeUnmount() {

  },
  unmounted() {

  },
  methods: {
    getPersonAssistantsByUuids(uuids, assistantsData) {
      let uniqueAssistants = new Set(assistantsData || []);
      if (uuids && uuids.length) {
        for (let i = 0; i < uuids.length; i++) {
          const uuid = uuids[i];
          const person = store.state.group[uuid]?.user;

          if (person && Array.isArray(person.assistants)) {
            for (let j = 0; j < person.assistants.length; j++) {
              const assistant = person.assistants[j];
              uniqueAssistants.add(assistant);
            }
          }

        }
      }
      return Array.from(uniqueAssistants);
    },
    getRepresentativeName(uuid) {
      return store.getUserRepresentativeName(uuid);
    },
    disableCallBtn(uuid) {
      const person = this.getPersonByUuid(uuid);
      return disableCallBtn(person);
    },
    getRepresentativeByUuid(uuid) {
      if (!uuid) return;
      const person = this.getPersonByUuid(uuid);
      if (!person) return;
      const representative = person.user?.representative?.value || '';
      return this.getPersonByUuid(representative);
    },
    hasRepresentative(uuid) {
      if (uuid === this.state.ownUUID) return false;
      return hasRepresentative(uuid);
    },
    getNameByUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelByUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    zoomIn() {
      if (this.zoomLevel < 200) { // Limitar a 200%
        this.zoomLevel += 10;
      }
    },
    zoomOut() {
      if (this.zoomLevel > 10) { // Limitar a 10%
        this.zoomLevel -= 10;
      }
    },
    findParentNode(dataset, childNodeId) {
      for (const node of dataset) {
        if (node.children?.some(child => child.id === childNodeId)) {
          return node;
        }
        // If not found, search recursively through children
        if (node.children) {
          const foundNode = this.findParentNode(node.children, childNodeId);
          if (foundNode) return foundNode;
        }
      }
      return null; // Parent not found
    },
    getBorderByStatusFull(person) {
      try {
        return getBorderByStatusFull(person);
      } catch { }
    },
    getCustomBorderByStatusFull(person, position, removeRadius = false) {
      try {
        return getCustomBorderByStatusFull(person, position, removeRadius);
      } catch { }
    },
    getPersonByUuid(uuid) {
      const person = store.getPersonByUuid(uuid) || {};
      return person;
    },
    appearInAHigherSection(userUuid, startingNode) {
      let currentNode = startingNode;
      const dataset = JSON.parse(JSON.stringify(this.state.namespaceSettings.newOrganigram || []));
      while (true) {
        // Find parent node
        const parentNode = this.findParentNode(dataset, currentNode.id);
        // If no more parents (we reached the root), stop the loop
        if (!parentNode) break;
        // Check if userUuid is in supervisors of the parent node
        if (parentNode.supervisors.includes(userUuid)) {
          return true;
        }
        // Move up the tree
        currentNode = parentNode;
      }
      return false; // User is not a supervisor at a higher level than the starting node
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getBorderCard(supervisorArray, uuid, index) {
      if (supervisorArray.length > 1) {
        // adjust the border for 4 people
        if (index == 0 || index == 2) {
          if (supervisorArray.length === 3 && index == 2) { // 3 people
            return this.getCustomBorderByStatusFull(
              this.getPersonByUuid(uuid),
              "third",
              true
            );
          }
          // remove right border for the first one
          if (index == 0) {
            return this.getCustomBorderByStatusFull(
              this.getPersonByUuid(uuid),
              "right"
            );
          } else if (index == 2) {
            // Remove also border radius #455
            return this.getCustomBorderByStatusFull(
              this.getPersonByUuid(uuid),
              "right",
              true
            );
          }
        } else if (index == 1 || index == 3) {
          // remove left border for the second one
          if (index == 1) {
            return this.getCustomBorderByStatusFull(
              this.getPersonByUuid(uuid),
              "left"
            );
          } else if (index == 3) {
            // Remove also border radius #455
            return this.getCustomBorderByStatusFull(
              this.getPersonByUuid(uuid),
              "left",
              true
            );
          }
        }
      } else {
        return `${this.getBorderByStatusFull(this.getPersonByUuid(uuid))};  border-radius: 5px 5px 0px 0px!important;`;
      }
    },
    getBorderStyleSupervisor(supervisors, index) {
      switch (supervisors.length) {
        case 1:
          return "containerImg100";
        case 2:
          if (index === 0) {
            return "containerImg50 removeBorderRight";
          } else if (index === 1) {
            return "containerImg50 removeBorderLeft";
          } else {
            return "containerImg100";
          }
        case 3:
          if (index === 0) {
            return "containerImg50 removeBorderRight removeBorderBottom";
          } else if (index === 1) {
            return "containerImg50 removeBorderLeft removeBorderBottom";
          } else if (index === 2) {
            return "containerImg100 removeBorderTop bottomImagePosition";
          } else {
            return "containerImg100";
          }
        case 4:
          if (index === 0) {
            return "containerImg50 removeBorderRight removeBorderBottom";
          } else if (index === 1) {
            return "containerImg50 removeBorderLeft removeBorderBottom";
          } else if (index === 2) {
            return "containerImg50 removeBorderRight removeBorderTop";
          } else if (index === 3) {
            return "containerImg50 removeBorderLeft removeBorderTop";
          } else {
            return "containerImg100";
          }
        default:
          return "containerImg100";
      }
    },
  },
  computed: {
    getDefaultPicture() {
      return "img/default_profile_picture.png";
    },
    showSidebar() {
      return this.state.persisted.showSidebar;
    },
  },
};
</script>

<style lang="scss" scoped>
.orgaPosition {
  position: absolute;
  left: 10px;
}

.tree-row {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 20px;
  gap: 40px;
  /* Espaciado entre nodos */
}

.top-center {
  transform-origin: top center
    /* Punto de referencia para el zoom */
}

.top-left {
  transform-origin: top left
    /* Punto de referencia para el zoom */
}

.tree-node {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.node-content {
  // padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 120px;
  max-height: 120px;
  min-width: 120px;
  min-height: 120px;
}
.node-content-Assisntants{
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  // max-width: 120px;
  max-height: 120px;
  // min-width: 120px;
  min-height: 120px;
}
.children-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.line {
  background-color: #ccc;
  position: absolute;
}

.vertical-line {
  width: 2px;
  height: 20px;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.vertical-line-node {
  width: 2px;
  height: 20px;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.horizontal-line {
  width: calc(100% - 40px);
  /* Ajuste dinámico para abarcar múltiples hijos */
  height: 2px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ccc;
  position: absolute;
}

.children-container>.tree-row {
  position: relative;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.zoom-controls-sidebar {
  position: fixed;
  left: 335px;
  bottom: 40px;
}

.zoom-controls-no-sidebar {
  position: fixed;
  left: 70px;
  bottom: 40px;
}

// same old orga
.containerImg50 {
  border-right: 1px solid white;
  max-width: 50%;
  width: 60px;
}

.containerImg100 {
  max-width: 100%;
  width: 120px;
}

.removeBorderRight {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: 0px !important;
}

.removeBorderLeft {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left: 0px !important;
}

.removeBorderBottom {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom: 0px !important;
}

.removeBorderTop {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-top: 0px !important;
}

.userNameContainer {
  width: 85%;
}

.gradientBg {
  height: 35px;
}

.v-theme--light .gradientBg {
  background: linear-gradient(#00000040, #00000099);
}
.assistantName{
  position: absolute;
  top: 0;
  left: 130px;
}
</style>
