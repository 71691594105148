// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link[data-v-1c3efbda] {
  color: var(--v-primary-base) !important;
}
.rememberPinStyle[data-v-1c3efbda] {
  width: 80%;
  margin: 0 auto;
}
.spanModalTitle[data-v-1c3efbda] {
  font-size: 18px;
  word-break: break-word;
  max-width: 90%;
  line-height: 2;
}
.divInput[data-v-1c3efbda] {
  width: 80%;
  margin: 0 auto;
}
#two_sandbox[data-v-1c3efbda] {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: auto;
  height: auto;
  z-index: 4;
}
.footerModal[data-v-1c3efbda] {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
}
.footerModal .btns[data-v-1c3efbda] {
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./websrc/components/2fa/2fa.vue","webpack://./2fa.vue"],"names":[],"mappings":"AACA;EACE,uCAAA;ACAF;ADEA;EACI,UAAA;EACA,cAAA;ACCJ;ADCA;EACE,eAAA;EACA,sBAAA;EACA,cAAA;EACA,cAAA;ACEF;ADAA;EACE,UAAA;EACA,cAAA;ACGF;ADDA;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;ACIF;ADFA;EACE,WAAA;EACA,6BAAA;EACA,uBAAA;ACKF;ADJE;EACE,yBAAA;ACMJ","sourcesContent":["\n.link {\n  color: var(--v-primary-base) !important;\n}\n.rememberPinStyle {\n    width: 80%;\n    margin: 0 auto;\n}\n.spanModalTitle {\n  font-size: 18px;\n  word-break: break-word;\n  max-width: 90%;\n  line-height: 2;\n}\n.divInput{\n  width: 80%;\n  margin: 0 auto;\n}\n#two_sandbox {\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  right: 0px;\n  bottom: 0px;\n  width: auto;\n  height: auto;\n  z-index: 4;\n}\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n  .btns {\n    justify-content: flex-end;\n  }\n}\n",".link {\n  color: var(--v-primary-base) !important;\n}\n\n.rememberPinStyle {\n  width: 80%;\n  margin: 0 auto;\n}\n\n.spanModalTitle {\n  font-size: 18px;\n  word-break: break-word;\n  max-width: 90%;\n  line-height: 2;\n}\n\n.divInput {\n  width: 80%;\n  margin: 0 auto;\n}\n\n#two_sandbox {\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  right: 0px;\n  bottom: 0px;\n  width: auto;\n  height: auto;\n  z-index: 4;\n}\n\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n}\n.footerModal .btns {\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
