// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flip-horizontal {
  transform: scaleX(-1);
}
.waitingRoomBadge .v-badge__wrapper, .coffeeBreakBadge .v-badge__wrapper {
  /* position: fixed !important; */
}
.waitingRoomBadge .v-badge__wrapper .v-badge__badge, .coffeeBreakBadge .v-badge__wrapper .v-badge__badge {
  padding-left: 2px;
  padding-right: 0;
  position: absolute;
  /* width: 100%; */
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/calls/sidebarCall/sidebarCall.vue","webpack://./sidebarCall.vue"],"names":[],"mappings":"AACA;EACE,qBAAA;ACAF;ADGE;EACE,gCAAA;ACAJ;ADCI;EACE,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;ACCN","sourcesContent":["\n.flip-horizontal {\n  transform: scaleX(-1);\n}\n.waitingRoomBadge, .coffeeBreakBadge {\n  .v-badge__wrapper {\n    /* position: fixed !important; */\n    .v-badge__badge {\n      padding-left: 2px;\n      padding-right: 0;\n      position: absolute;\n      /* width: 100%; */\n    }\n  }\n}\n",".flip-horizontal {\n  transform: scaleX(-1);\n}\n\n.waitingRoomBadge .v-badge__wrapper, .coffeeBreakBadge .v-badge__wrapper {\n  /* position: fixed !important; */\n}\n.waitingRoomBadge .v-badge__wrapper .v-badge__badge, .coffeeBreakBadge .v-badge__wrapper .v-badge__badge {\n  padding-left: 2px;\n  padding-right: 0;\n  position: absolute;\n  /* width: 100%; */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
