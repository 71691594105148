// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cursorAuto[data-v-6f5a432e] {
  cursor: auto;
}
.w80[data-v-6f5a432e] {
  width: 80%;
}
.listHeight[data-v-6f5a432e] {
  max-height: 160px;
  overflow: auto;
}
.fontSize15[data-v-6f5a432e] {
  font-size: 15px !important;
}
.vueScrollOrganistion .divScroll[data-v-6f5a432e] {
  max-height: calc(100vh - 650px) !important;
}
@media screen and (max-height: 650px) {
.vueScrollOrganistion .divScroll[data-v-6f5a432e] {
    max-height: calc(100vh - 150px) !important;
}
}
.v-list[data-v-6f5a432e] {
  padding: 0;
}
.v-list-item__icon[data-v-6f5a432e] {
  margin-right: 8px !important;
}
.v-card[data-v-6f5a432e]:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-list-item .v-list-item__title[data-v-6f5a432e] {
  line-height: 2.5 !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/sections.vue","webpack://./sections.vue"],"names":[],"mappings":"AACA;EACE,YAAA;ACAF;ADEA;EACE,UAAA;ACCF;ADCA;EACE,iBAAA;EACA,cAAA;ACEF;ADAA;EACE,0BAAA;ACGF;ADAE;EACE,0CAAA;ACGJ;ADFI;AAFF;IAGI,0CAAA;ACKJ;AACF;ADDA;EACE,UAAA;ACIF;ADDA;EACE,4BAAA;ACIF;ADDA;EACE,gBAAA;ACIF;ADFA;EACE,2BAAA;ACKF","sourcesContent":["\n.cursorAuto{\n  cursor: auto;\n}\n.w80 {\n  width: 80%;\n}\n.listHeight{\n  max-height: 160px;\n  overflow:auto;\n}\n.fontSize15 {\n  font-size: 15px !important;\n}\n.vueScrollOrganistion {\n  & .divScroll {\n    max-height: calc(100vh - 650px) !important;\n    @media screen and (max-height: 650px) {\n      max-height: calc(100vh - 150px) !important;\n    }\n  }\n  // avoiding empty spaces\n}\n.v-list {\n  padding: 0;\n}\n\n.v-list-item__icon {\n  margin-right: 8px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}\n.v-list-item .v-list-item__title {\n  line-height: 2.5 !important;\n}\n",".cursorAuto {\n  cursor: auto;\n}\n\n.w80 {\n  width: 80%;\n}\n\n.listHeight {\n  max-height: 160px;\n  overflow: auto;\n}\n\n.fontSize15 {\n  font-size: 15px !important;\n}\n\n.vueScrollOrganistion .divScroll {\n  max-height: calc(100vh - 650px) !important;\n}\n@media screen and (max-height: 650px) {\n  .vueScrollOrganistion .divScroll {\n    max-height: calc(100vh - 150px) !important;\n  }\n}\n\n.v-list {\n  padding: 0;\n}\n\n.v-list-item__icon {\n  margin-right: 8px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}\n\n.v-list-item .v-list-item__title {\n  line-height: 2.5 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
