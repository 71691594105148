import store from '../store';

export function hasPrivilege(userUuid) {
  // function to check if the user is a normal user or a invited user.
  if (store.state.group[userUuid] && store.state.group[userUuid].user) {
    if ((store.state.group[userUuid].user.guest || store.state.group[userUuid].user.visitor) || (store.state.group[userUuid].permissions === 10 && store.state.group[userUuid].user.visitorData && store.state.group[userUuid].user.visitorData.isWaitingRoom)) {
      return false;
    }
  }
  return true;
}
export function isConferenceVisitor(userUuid) {
  if (!hasPrivilege(userUuid) && store.state.group[userUuid].user.visitorData && store.state.group[userUuid].user.visitorData.conferenceUUID && store.state.group[userUuid].user.visitorData.conferenceUUID.length) {
    return true
  } else {
    return false
  }
}

export function isOnlyOneCallUser(userUuid) {
  // function to check in the invited user is oneCall
  if (store.state.group[userUuid] && store.state.group[userUuid].user && store.state.group[userUuid].permissions === 10) {
    if (store.state.group[userUuid].user.visitorData && store.state.group[userUuid].user.visitorData.isOneTime && !store.state.group[userUuid].user.visitorData.isWaitingRoom) {
      return true;
    }
  }
  return false;
}

export function oneCallAndWaitingUser(userUuid) {
  // function to check if the invited user is oneCall and waiting
  if (store.state.group[userUuid] && store.state.group[userUuid].user && store.state.group[userUuid].permissions === 10) {
    if (store.state.group[userUuid].user.visitorData && store.state.group[userUuid].user.visitorData.isOneTime && store.state.group[userUuid].user.visitorData.isWaitingRoom) {
      return true;
    }
  }
  return false;
}

export function isWaitingRoomUser(userUuid) {
  // function to check if the invited user is waiting
  if (store.state.group[userUuid] && store.state.group[userUuid].user && store.state.group[userUuid].permissions === 10) {
    if (store.state.group[userUuid].user.visitor && !store.state.group[userUuid].user.visitorData) {
      return true;
    }
    if (store.state.group[userUuid].user.visitorData && store.state.group[userUuid].user.visitorData.isWaitingRoom) {
      return true;
    }
  }
  return false;
}

export function isVisitor(userUuid) {
  // function to check if the invited user is waiting
  if (store.state.group[userUuid] && store.state.group[userUuid].user && store.state.group[userUuid].permissions === 10 && store.state.group[userUuid].user.visitor && !isWaitingRoomUser(userUuid)) {
    return true;
  }
  return false;
}

export function isAdmin(userUUID) {
  return store.getUserIsAdmin(userUUID);
}

export function basicView() {
  return store.state.namespaceSettings.basicView;
}

export function getSupervisorUuid(organigramApart) {
  try {
    if (organigramApart.supervisors.length > 0) {
      return organigramApart.supervisors[0].uuid;
    } else {
      return false;
    }
  } catch { }
}

export function userSupervisorSection(userUUID) {
  const sections = Object.keys(store.state.namespaceSettings.processedOrganisation.sections);
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    const supervisorUUID = getSupervisorUuid(store.state.namespaceSettings.processedOrganisation.sections[section]
    );
    if (supervisorUUID === userUUID) return true
  }
  return false;
}

export function userSupervisorDepartment(userUUID) {
  const departments = Object.keys(store.state.namespaceSettings.processedOrganisation.departments);
  for (let i = 0; i < departments.length; i++) {
    const department = departments[i];
    const supervisorUUID = getSupervisorUuid(store.state.namespaceSettings.processedOrganisation.departments[department]
    );
    if (supervisorUUID === userUUID) return true
  }
  return false;
}

export function userSupervisorTeam(userUUID) {
  const teams = Object.keys(store.state.namespaceSettings.processedOrganisation.teams);
  for (let i = 0; i < teams.length; i++) {
    const team = teams[i];
    const supervisorUUID = getSupervisorUuid(store.state.namespaceSettings.processedOrganisation.teams[team]
    );
    if (supervisorUUID === userUUID) return true
  }
  return false;
}