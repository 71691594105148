<template>
  <v-dialog
    persistent
     :model-value="showModal"
    @update:model-value="showModal= $event"
    max-width="720"
    @keydown.esc="closeModal"
    :fullscreen="isMobile"
  >
    <div>
      <v-card>
        <HeaderModal
          :titleModal="
            notificationInfo.type == 'message'
              ? $t('components.notificationsModal.message')
              : 'Notification'
          "
          :closeModalFunction="closeModal"
        />
        <v-divider></v-divider>
        <v-row
          flex
          :style="{ width: '100%', margin: 0 }"
          @click="!isWaitingRoomUser ? handleClickZone(notificationInfo) : null"
        >
          <v-col v-for="n in 1" :key="n" class="col-12">
            <div v-if="n == 1" class="d-flex">
              <v-badge
                bordered
                overlay
                height="60"
                width="60"
                bottom
                :color="badgetClassObj(notificationInfo)"
                dot
                offset-x="17"
                offset-y="9"
              >
                <v-avatar
                  tile
                  size="76"
                  class="borderRadius10"
                  style="margin: 0px 10px"
                  v-if="!isASentMessageNotification"
                >
                  <DefaultAvatar
                    :size="76"
                    :userData="getPerson(notificationInfo.creatorUUID).user"
                    v-if="
                      getAvatarForUuid(notificationInfo.creatorUUID) ===
                      'img/default_profile_picture.png'
                    "
                  ></DefaultAvatar>
                  <v-img
                    v-if="
                      getAvatarForUuid(notificationInfo.creatorUUID) !==
                      'img/default_profile_picture.png'
                    "
                    class="borderRadius10"
                    max-height="76"
                    max-width="76"
                    contain
                    :src="getAvatarForUuid(notificationInfo.creatorUUID)"
                    lazy-src="img/default_profile_picture.png"
                  ></v-img>
                </v-avatar>
                <!-- just for sent messages we punt user avatar that a sent the message  -->
                <v-avatar
                  tile
                  size="76"
                  class="borderRadius10"
                  style="margin: 0px 10px"
                  v-else
                >
                  <DefaultAvatar
                    :size="76"
                    :userData="
                      getPerson(Object.keys(notificationInfo.users)[0]).user
                    "
                    v-if="
                      getAvatarForUuid(
                        Object.keys(notificationInfo.users)[0]
                      ) === 'img/default_profile_picture.png'
                    "
                  ></DefaultAvatar>
                  <v-img
                    v-if="
                      getAvatarForUuid(
                        Object.keys(notificationInfo.users)[0]
                      ) !== 'img/default_profile_picture.png'
                    "
                    class="borderRadius10"
                    max-height="76"
                    max-width="76"
                    contain
                    :src="
                      getAvatarForUuid(Object.keys(notificationInfo.users)[0])
                    "
                    lazy-src="img/default_profile_picture.png"
                  ></v-img>
                </v-avatar>
              </v-badge>
              <v-row :style="{ width: '80%', margin: 0 }">
                <v-col cols="12" class="pb-0 pt-1 d-flex">
                  <p color="primary" class="userNameCard mb-0 pb-0 mr-auto">
                    {{
                      isASentMessageNotification
                        ? getNameForUuid(Object.keys(notificationInfo.users)[0])
                        : getNameForUuid(notificationInfo.creatorUUID)
                    }}
                  </p>
                  <v-tooltip
                    top
                    v-if="
                      (notificationInfo.type === 'call' ||
                        notificationInfo.type === 'message') &&
                      !isWaitingRoomUser &&
                      notificationInfo.creatorUUID != ownUUID
                    "
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        id="buttonCallDirectly"
                        :style="
                          isCall
                            ? 'width:70px !important; height:70px !important;'
                            : 'width:60px !important'
                        "
                        v-bind="props"
                        color="primary"
                        class="bR4"
                        :disabled="disableCallBtn"
                        v-on:click="
                          disableCallBtn
                            ? false
                            : callUser(notificationInfo.creatorUUID);
                          closeModal();
                        "
                      >
                        <font-awesome-icon
                          v-if="notificationInfo.type === 'call'"
                          :icon="['fal', 'phone']"
                          :style="{ fontSize: '50px' }"
                        />
                        <font-awesome-icon
                          v-if="notificationInfo.type === 'message'"
                          :icon="['fal', 'phone']"
                          :style="{ fontSize: '20px' }"
                        />
                      </v-btn>
                    </template>
                    <span>{{
                      disableCallBtn
                        ? "Dieser Benutzer ist zur Zeit offline"
                        : $t("components.userProfile.callUser")
                    }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row
          :style="{ width: '100%', margin: 0 }"
          class="px-5"
          v-if="
            notificationInfo.type === 'call' &&
            !isWaitingRoomUser &&
            state.persisted.tmpMessages.showMessageInitiateCallSpace
          "
        >
          <p style="font-size: 14px; margin: 0 auto; font-weight: 400">
            * {{ $t("components.notificationsModal.callSpaceBar") }}
          </p>
        </v-row>
        <v-row
          flex
          :style="{ width: '100%', margin: 0 }"
          :class="{ heightMobileMessages: isMobile }"
        >
          <v-col class="col-12">
            <template
              v-if="
                notificationInfo.type === 'message' ||
                notificationInfo.type === 'plannerEvent'
              "
            >
              <v-container
                :class="`contentMessagesIsReply ${
                  isMobile ? 'contentMessagesIsReplyMobile' : ''
                }`"
                ref="messageContainer"
              >
                <MessageHistoryEffector :messages="getHistoryMessages" />
              </v-container>
              <div class="px-6">
                <v-divider></v-divider>
              </div>
            </template>
          </v-col>
        </v-row>
        <v-card :class="isDark ? 'footersBackgroundMessagesDark' : 'footersBackgroundMessages'" class="py-2 pl-4 mt-4 pr-2 footerModal">
          <v-row class="px-2 btns" flex :style="{ width: '100%', margin: 0 }">
            <v-col lg="12" class="px-0 py-0 d-flex align-center" align="right">

              <v-text-field
                :model-value="replyMessage" @update:model-value="replyMessage = $event"
                class="inputMessageWaitingRoom"
                @keyup.enter="
                  replyMessage.trim().length > 0
                    ? sendReply(notificationInfo)
                    : null
                "
                 :bg-color="isDark ? '#1e1e1e' : '#fff'"
                autofocus
                hide-details
                variant="outlined"
                single-line
                maxlength="250"
                density="compact"
                :placeholder="$t('components.notificationsModal.reply')"
              >
              <template v-slot:append-inner>
                  <v-btn
                    class="ml-2"
                    @click="sendReply(notificationInfo)"
                    icon
                    density="compact"
                    variant="text"
                    :disabled="replyMessage.trim().length === 0"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'paper-plane']"
                      :style="{ fontSize: '20px' }"
                      color="primary"
                    />
                  </v-btn>
                </template>
              </v-text-field>
              <v-btn
                color="primary"
                style="border-radius: 4px; min-width: 45px;"
                icon
                tile
                variant = "outlined"
                density="comfortable"
                class="ml-3 mr-2 greyBtn"
                @click="closeModal()"
                rounded="5px"
              >
                <font-awesome-icon
                  :icon="['fal', 'times']"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-spacer></v-spacer>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import { useStore } from "effector-vue/composition";
import store, { EventBus } from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import { callPerson, disableCallBtn } from "../../utils/calls";
import { getStatusColorByUser } from "../../utils/status";
import CamAndMic from "../firstSetup/camAndMic.vue";
import moment from "../../../sharedsrc/moment";
import UserMessageComp from "../sidebar/userMessageComponent.vue";
import { isWaitingRoomUser } from "../../utils/privileges";
import MessageHistoryEffector from "../content/messageHistoryEffector.vue";
import {
  resetNotificationModalEvent,
  notificationModalStore,
} from "../../effector/modals";
import {
  cachedMessages,
  newMessageEvent,
  markAsViewedSpecificMessageEvent,
  markAsReadSpecificMessageEvent,
  getMessageHistoryByUserUUID,
} from "../../effector/message";
import ReplyMessageEffector from "../content/replyMessageEffector.vue";
import DefaultAvatar from "../image/defaultAvatar.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "./modalComponents/footerModal.vue";

const hasOwn = Object.prototype.hasOwnProperty;

export default {
  components: {
    CamAndMic,
    UserMessageComp,
    ReplyMessageEffector,
    DefaultAvatar,
    MessageHistoryEffector,
    HeaderModal,
    FooterModal
  },
  data(){
    const effector = {
      showModal: notificationModalStore,
      cachedMessages: cachedMessages,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      loading: false,
      chip: true,
      moment,
      setCurrentContentVisile: store.setCurrentContentVisile,
      ownUUID: store.state.ownUUID,
      showTextareaReply: false,
      replyText: "",
      rules: [(v) => !!v || "", (v) => !!v.trim() || "Message can not be blank"],
      finalText: "",
      messageHistory: "",
      replyMessage: "",
      isMobile: isMobile(),
      // Effector
      ...effector,
    }
  },
  watch: {
    // if you have the modal open we update viewed and read message store
    getHistoryMessages: {
      handler: function (messages) {
        this.updateMessageParams(messages);
      },
      deep: true,
    },
    totalMessages: {
      handler: function (total) {
        if (total) {
          const percentage =
            (this.$refs["messageContainer"].scrollTop /
              (this.$refs["messageContainer"].scrollHeight -
                this.$refs["messageContainer"].clientHeight)) *
            100;
          if (percentage > 70) {
            setTimeout(() => {
              this.scrollToBottom();
            }, 300);
          }
        }
      },
    },
  },
  mounted: function () {
    if (!this.isWaitingRoomUser) {
      document.addEventListener("keydown", this.handleModalSpace, {
        capture: false,
        passive: false,
      });
    }
    this.updateMessageParams(this.getHistoryMessages);
    this.scrollToBottom();
  },
  beforeUnmount() {
    if (!this.isWaitingRoomUser) {
      document.removeEventListener("keydown", this.handleModalSpace);
    }
  },
  methods: {
    scrollToBottom() {
      // scroll to bottom
      this.$refs["messageContainer"].scrollTop =
        this.$refs["messageContainer"].scrollHeight;
    },
    updateMessageParams(data) {
      for (const messages in data) {
        if (hasOwn.call(data, messages)) {
          const message = data[messages];
          if (message.users[this.state.ownUUID]) {
            if (!message.users[this.state.ownUUID].viewed) {
              markAsViewedSpecificMessageEvent(message.messageUUID);
            }
            if (!message.users[this.state.ownUUID].read) {
              markAsReadSpecificMessageEvent(message.messageUUID);
            }
          }
        }
      }
    },
    getPerson(uuid) {
      return this.state.group[uuid];
    },
    badgetClassObj(notification) {
      const userUUID = notification.creatorUUID;
      return getStatusColorByUser(
        this.state.group[userUUID].user,
        this.state.group[userUUID].connected
      );
    },
    closeModal() {
      resetNotificationModalEvent();
    },
    sendReply(message) {
      const userToSend = this.isASentMessageNotification
        ? Object.keys(message.users)[0]
        : message.creatorUUID;
      const userToSendMsg = {};
      userToSendMsg[userToSend] = {
        userUUID: userToSend,
        read: false,
        replied: false,
        deleted: false,
        viewed: false,
      };
      const dataMsg = {
        body: this.replyMessage,
        header: "individual message",
        date: Date.now(),
        users: userToSendMsg,
        isReply: false,
        creatorUUID: this.ownUUID,
        masterMessageUUID: null,
        parentMessageUUID: null,
        type: "message",
      };
      newMessageEvent(dataMsg);
      this.replyMessage = "";
    },
    handleClickZone(key) {
      if (key.type === "call") {
        this.disableCallBtn ? false : this.callUser(key.creatorUUID);
      }
    },
    handleModalSpace(evt) {
      let e = evt || window.event,
        target = e.target || e.srcElement;
      if (evt.keyCode != 32) return;
      const mssgLen = store.state.tmpMessagetext.trim().length || 0;
      let type = this.resolveType();
      if (type === "" && mssgLen < 5) return; ///only types
      if (type === "" && mssgLen > 5) type = "message"; // messaging from call or other places with no type
      const hitarea = target.tagName.toUpperCase() || "unknown";
      const origin = this.resolveComponent();
      if (
        origin.trim().length === 0 &&
        type !== "call" &&
        origin.trim().length === 0 &&
        type !== "message"
      )
        return; ///
      switch (type) {
        case "message":
          if (hitarea === "BODY" && origin === "modal") {
            EventBus.$emit("sendMyMessage");
          } else if (hitarea === "BODY" && origin === "" && mssgLen > 5) {
            EventBus.$emit("sendMyMessage");
          } else if (
            hitarea === "DIV" &&
            origin === "comp" &&
            store.state.tmpMessagetext.trim().length > 0
          ) {
            if (store.state.tmpMessagetext.trim().length === 0) return;
            this.$refs.userMssgComp[0].preparedataToSendMsg(
              store.state.showCompSendMsg.uuid,
              store.state.tmpMessagetext
            );
          } else if (hitarea === "DIV" && origin === "comp") {
            let uuid = Object.keys(store.state.showModalNotify)[0];
            this.disableCallBtn ? false : this.callUser(uuid);
          } else if (hitarea === "DIV" && origin.trim().length === 0) {
            let uuid = Object.keys(store.state.showModalNotify)[0];
            this.disableCallBtn ? false : this.callUser(uuid);
          }
          break;
        case "call":
          // eslint-disable-next-line no-case-declarations
          let uuid = Object.keys(store.state.showModalNotify)[0];
          this.disableCallBtn ? false : this.callUser(uuid);
          break;
        default:
          break;
      }
    },
    resolveType() {
      let hresult = "";
      if (((store.state.showModalSenMsg || {}).type || "") !== "") {
        hresult = store.state.showModalSenMsg.type;
      }
      if (
        (((store.state.showCompSendMsg || {}).type || {}).type || "") !== ""
      ) {
        hresult = store.state.showCompSendMsg.type.type;
      }
      if (
        hresult === "" &&
        Object.keys(store.state.showModalNotify).length > 0
      ) {
        try {
          hresult =
            store.state.showModalNotify[
              Object.keys(store.state.showModalNotify)[0]
            ].type;
        } catch (error) {
          hresult = "";
        }
      }
      return hresult;
    },
    resolveComponent() {
      let hcomp = "";
      if (((store.state.showModalSenMsg || {}).type || "") !== "") {
        hcomp = "modal";
      } else if (((store.state.showCompSendMsg || {}).type || "") !== "") {
        hcomp = "comp";
      }
      return hcomp;
    },
    callUser(uuid) {
      setTimeout(function () {
        callPerson(uuid, this.$router);
      }, 200);
      this.resetChip();
      this.toggleNotificationModal();
    },
    toggleNotificationModal() {
      if (this.isCall) {
        store.setShowMessageInitiateCallSpace(false);
      }
      this.state.showModalNotify = {};
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    resetChip() {
      this.chip = true;
    },
    removeMessage: function (...args) {
      this.loading = true;
      store.removeMessage(...args);
      this.loading = false;
      this.toggleNotificationModal();
    },
    isSentMessage(message) {
      if (message && message.creatorUUID == this.ownUUID) {
        return true;
      } else {
        return false;
      }
    },
    getDataUserMessage(message) {
      if (this.isSentMessage(message)) {
        return Object.keys(message.users)[0];
      } else {
        return message.creatorUUID;
      }
    },
  },
  computed: {
    isDark() {
      return store.state.persisted.isDark;
    },
    isASentMessageNotification() {
      return this.notificationInfo.creatorUUID == this.ownUUID;
    },
    totalMessages() {
      return this.getHistoryMessages.length;
    },
    getHistoryMessages() {
      return this.historyMessagesStore.getState();
    },
    historyMessagesStore() {
      const userUUID = this.getDataUserMessage(this.notificationInfo);
      return getMessageHistoryByUserUUID(userUUID);
    },
    notificationInfo() {
      return this.cachedMessages[this.showModal];
    },
    isWaitingRoomUser() {
      return isWaitingRoomUser(this.ownUUID);
    },
    isCall() {
      return this.notificationInfo && this.notificationInfo.type === "call";
    },
    disableCallBtn() {
      if (this.notificationInfo) {
        let person = this.state.group[this.notificationInfo.creatorUUID];
        person.uuid = this.notificationInfo.creatorUUID;
        return disableCallBtn(person);
      } else {
        return true;
      }
    },
    cols() {
      const { lg, sm, md, xl } = this.$vuetify.display;
      return lg ? [4, 8] : sm ? [4, 8] : md ? [4, 8] : xl ? [4, 8] : [12, 12];
    },
  },
};
</script>
<style scoped lang="scss">
.heightMobileMessages {
  height: calc(100% - 243px);
}
.inputMessageWaitingRoom {
  .v-input__append-inner {
    margin-top: 1px !important;
  }
  .v-text-field input {
    color: black !important;
  }
}
.contentMessagesIsReplyMobile {
  height: calc(100% - 10px) !important;
}
.contentMessagesIsReply {
  height: 350px;

  overflow: auto !important;
}
.btns {
  justify-content: flex-end;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
.vueScroll {
  height: calc(100% - 64px) !important;
  .__view {
    width: unset !important;
  }
}
.bR4 {
  border-radius: 4px;
  width: 36px;
  height: 36px !important;
  min-width: 36px !important;
}
</style>