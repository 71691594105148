<template>
  <OrganisationRegularGrid :data="departments" />
</template>

<script>
import store from "../../store";
import OrganisationRegularGrid from "./organisationRegularGrid.vue";
export default {
  components: { OrganisationRegularGrid },
  data() {
    return {
      state: store.state,
      departments:
        store.state.namespaceSettings.processedOrganisation.departments,
    };
  },
};
</script>