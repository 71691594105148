import { listenerRaisedHandDomain } from './domain';
import { setListenerRaisedHandEvent, resetListenerRaisedHandEvent, appendListenerRaisedHandEvent } from './events';
import { receivedSpecificUserStateEvent } from '../users/events';

export const listenerRaisedHandStore = listenerRaisedHandDomain.createStore({}, { name: 'listenerRaisedHand' });

listenerRaisedHandStore.on(setListenerRaisedHandEvent, (state, payload) => {
  return payload;
});

listenerRaisedHandStore.on(appendListenerRaisedHandEvent, (state, payload) => {
  const { uuid, inPodium, raisedHand, tsRaiseHand } = payload;
  return { ...state, [uuid]: { inPodium, raisedHand, tsRaiseHand } };
});

listenerRaisedHandStore.on(receivedSpecificUserStateEvent, (state, payload) => {
  const [uuid, entry] = payload;
  if (!state[uuid]) return state; // No changes
  const bridgeCallId = entry && entry.connected && entry.user && (entry.user.inBridgeCall || entry.user.inBridgeCallListener);
  if (!bridgeCallId) return state; // No changes
  const inPodium = !!entry.user.inBridgeCall;
  return { ...state, [uuid]: { inPodium, ...state[uuid] } };
});

listenerRaisedHandStore.reset(resetListenerRaisedHandEvent);