// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerContainer[data-v-f14f4e52] {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.buttonReload[data-v-f14f4e52] {
  position: absolute;
  z-index: 999;
}
.w100[data-v-f14f4e52] {
  width: 100%;
}
.cursorPointer[data-v-f14f4e52] {
  cursor: pointer;
}
#updateFont[data-v-f14f4e52] {
  animation: blink-f14f4e52 1s linear infinite;
  animation-direction: alternate;
}
.w100[data-v-f14f4e52] {
  width: 100%;
}
@keyframes blink-f14f4e52 {
0% {
    font-size: 12px;
}
50% {
    font-size: 16px;
}
100% {
    font-size: 20px;
}
}`, "",{"version":3,"sources":["webpack://./websrc/components/footer/footer.vue","webpack://./footer.vue"],"names":[],"mappings":"AACA;EACE,eAAA;EACA,SAAA;EACE,UAAA;EACA,WAAA;ACAJ;ADEA;EACE,kBAAA;EACA,YAAA;ACCF;ADCA;EACE,WAAA;ACEF;ADAA;EACE,eAAA;ACGF;ADDA;EACE,4CAAA;EACA,8BAAA;ACIF;ADFA;EACE,WAAA;ACKF;ADFA;AACE;IACE,eAAA;ACKF;ADHA;IACE,eAAA;ACKF;ADHA;IACE,eAAA;ACKF;AACF","sourcesContent":["\n.footerContainer{\n  position: fixed;\n  left: 0px;\n    right: 0px;\n    bottom: 0px;\n}\n.buttonReload {\n  position: absolute;\n  z-index: 999;\n}\n.w100 {\n  width: 100%;\n}\n.cursorPointer {\n  cursor: pointer;\n}\n#updateFont {\n  animation: blink 1s linear infinite;\n  animation-direction: alternate;\n}\n.w100 {\n  width: 100%;\n}\n\n@keyframes blink {\n  0% {\n    font-size: 12px;\n  }\n  50% {\n    font-size: 16px;\n  }\n  100% {\n    font-size: 20px;\n  }\n}\n",".footerContainer {\n  position: fixed;\n  left: 0px;\n  right: 0px;\n  bottom: 0px;\n}\n\n.buttonReload {\n  position: absolute;\n  z-index: 999;\n}\n\n.w100 {\n  width: 100%;\n}\n\n.cursorPointer {\n  cursor: pointer;\n}\n\n#updateFont {\n  animation: blink 1s linear infinite;\n  animation-direction: alternate;\n}\n\n.w100 {\n  width: 100%;\n}\n\n@keyframes blink {\n  0% {\n    font-size: 12px;\n  }\n  50% {\n    font-size: 16px;\n  }\n  100% {\n    font-size: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
