<template>
 <v-dialog
    :model-value="showModal"
    @update:model-value="showModal = $event" persistent max-width="560">
    <template>
      <v-card class="mx-auto">
        <v-card-title class="headline" primary-title>
          <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
          {{$t('components.stopCallModal.title')}}
        </v-card-title>
        <v-card-text>
          {{$t('components.stopCallModal.text')}}
          <div class="mt-8 d-flex justify-center">
            <div class="mx-3 pointer">
              <v-btn
                class="primary"
                @click="acceptCloseCall()"
              >{{$t('components.userAgreementModal.ok')}}</v-btn>
              <v-btn
                class="primary"
                @click="cancelCloseCall()"
              >{{$t('components.userAgreementModal.no')}}</v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../store";

export default {
  props: ["showModal", "closeModal", "stopCall"],
  data: () => ({
    state: store.state,
  }),
  watch: {},
  methods: {
    acceptCloseCall() {
      this.stopCall();
      this.closeModal();
    },
    cancelCloseCall() {
      this.closeModal();
    },
    onKeyUp(event) {
      if (event.type === "keyup" && event.keyCode === 32) {
        this.stopCall();
      }
    },
  },
  computed: {},
  mounted() {
    document.addEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
  },
  unmounted() {
    document.removeEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
  },
};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
</style>