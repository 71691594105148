import Vue from '@vue/compat';
// import { v4 as uuidv4 } from 'uuid';
import { getWebrtcConnections, amIConnectedTo } from './rtcConn';
import { ownUserUUIDState } from '../effector/users';
import { setCallInfoStateFromManagerEvent } from '../effector/call';
import { dispatchSuccessAlert } from '../effector/alerts';
import { setQualityVotingModalEvent } from '../effector/modals';
import { dispatchColorChangeTimelineEntry } from '../effector/timeline';
import { isVisitor, isWaitingRoomUser } from '../utils/privileges';
import store from '../store';
import { isGuestOrVisitor } from "../utils/routerAcl.js";
import uniqid from 'uniqid';
import Router from '../router';

export class CallInfoManager {
  constructor() {
    // this.log('Constructor');
    this.cronInterval = 5000;
    this.resetState();
    // this.scheduleCron();
  }

  // == Logging Start ==
  info(...args) { console.info('CallInfoManager', ...args); }
  log(...args) { console.log('CallInfoManager', ...args); }
  warn(...args) { console.warn('CallInfoManager', ...args); }
  error(...args) { console.error('CallInfoManager', ...args); }
  // == Logging End ==

  // == State Start ==
  getState() { return this.state; }

  setState(newState, force = false) {
    this.state = { ...(this.state || {}), ...newState };
    setCallInfoStateFromManagerEvent(this.state);
    try {
      this.syncToStore();
    } catch (err) {
      this.info('setState missing store', err.message);
    }
    this.syncToPeers(force);
  }

  async setStateFromRemote(newState, conn) {
    this.log('setStateFromRemote', conn.name, newState);
    if (this.state.callActive && conn.uuid === this.state.ownerUUID) {
      this.setState(newState);
    } else if (this.isNextInLine(conn.uuid) && !amIConnectedTo(this.state.ownerUUID)) {
      this.setState(newState);
    } else if (!this.state.callActive) {
      this.setState(newState);
    } else {
      throw new Error('Not call owner, not accepting');
    }
  }
  // == State End ==

  // == State Changes Start ==
  resetState() {
    // if(this.amIOwner()){
    //   console.log('amIOwner', this.amIOwner())
    // }
    this.setState({
      recoveryLockedTillTs: null,
      callActive: false,
      ownerUUID: undefined,
      group: [],
      // mode: 'default',
      callStartTs: undefined,
      callDurationMs: undefined,
      presentationView: {
        show: false,
        owner: undefined,
        fromScreenshare: false,
      },
      messageToParticipants: {
        sound: undefined,
        audio: undefined
      },
      speakerRequests: [],

      remoteFullSize: false,
      remoteToFullSizeUUID: null,
      // remoteToFullSizeObject: null,

      allowedToTalk: [],
      notAllowedToTalk: [],

      callUUID: undefined,
      isConference: null,
      confId: null,
      ownerConference: null,
      color: null

      // ETC
    });
  }

  async startNewCall() {
    this.setState({
      callActive: true,
      // callUUID: uuidv4(),
      callUUID: uniqid(),
      ownerUUID: ownUserUUIDState.getState(),
      callStartTs: Date.now(),
      callDurationMs: store.state.durationMeeting || (60000 * 20), // TODO: better default / remove from store state?
      presentationView: store.state.presentationView, // FIXME
      infiniteCall: store.state.durationMeeting ? false : true,
      isConference: store.state.userInConference ? store.state.userInConference.isConference : null,
      confId: store.state.userInConference ? store.state.userInConference.confId : null,
      ownerConference: store.state.userInConference ? store.state.userInConference.ownerConference : null,
      color:  store.state.userInConference ? store.state.userInConference.color : null,
    });
  }

  getCallUUID() {
    return this.state.callUUID || '';
  }

  async addUuidToGroup(uuid) {
    if (!this.amIOwner()) throw new Error('Not call owner');
    if (this.state.group.indexOf(uuid) !== -1) return;
    this.setState({ ...this.state, group: [...this.state.group, uuid] });
  }
  // == State Changes End ==

  // == Cron Start ==
  scheduleCron() {
    // this.log('scheduleCron');
    if (this._cronTimeout) clearTimeout(this._cronTimeout);
    this._cronTimeout = setTimeout(this.cron.bind(this), this.cronInterval);
  }

  async cron() {
    // const connections = getWebrtcConnections();
    try {
      // this.log('cron tick', this.state);
      await this.checkOwnerRecovery();
      // await this.syncToPeers();
    } catch (err) {
      this.warn('cron', err);
    }
    // if (Object.keys(connections).length) {
    if (this.haveCallActive()) {
      this.scheduleCron();
    }
  }
  // == Cron End

  // == Utils
  genId() {

  }

  // == Derived State Helpers Start ==
  amIOwner() {
    return this.haveCallActive() && (this.state.ownerUUID === ownUserUUIDState.getState());
  }

  haveCallActive() {
    return this.state.callActive;
  }

  isWaitingRoomUser(uuid) {
    return isWaitingRoomUser(uuid);
  }

  setEndCallDateVisitors() {
    return store.setEndCallDateVisitor();
  }

  isRecoveryLocked() {
    if (!this.state.recoveryLockedTillTs) return false;
    return (Date.now() <= this.state.recoveryLockedTillTs);
  }

  isNextInLine(uuid) {
    if (!this.state.group || !this.state.group.length) return false;
    const ownUUID = ownUserUUIDState.getState();
    for (const n of this.state.group) {
      // this.log('isNextInLine', ownUUID, n, uuid, amIConnectedTo(n));
      if (n === uuid && (n === ownUUID || amIConnectedTo(n))) return true;
      else if (n === uuid && !amIConnectedTo(n)) continue;
      else return false;
    }
  }

  getCurrentCallRunningTime() {
    if (!this.state.callActive) return 0;
    return Date.now() - this.state.callStartTs;
  }

  isUUIDOwnerOfPresentation(uuid) {
    if (uuid === this.state.ownerUUID) return true;
    if (uuid === this.state.presentationView.owner) return true;
    return false;
  }
  // == Derived State Helpers End ==

  // == Syncronization Start ==
  async syncToPeers(force = false) {
    // Sync to peers if owner?
    if (!force && !this.amIOwner()) return;

    const connections = getWebrtcConnections();
    for (const c of Object.values(connections)) {
      try {
        await c.setRemoteCallInfoState(this.state);
      } catch (err) {
        this.warn('syncToPeers', c.name, err);
      }
    }
  }

  syncToStore() {
    if (store) {
      store.setOwnerMeeting(this.amIOwner());
      store.setmeetingStartAt(this.state.callStartTs);
      store.setdurationMeeting(this.state.callDurationMs);
      store.setMessageToParticipants(this.state.messageToParticipants);
      store.setPresentationView(this.state.presentationView.show, this.state.presentationView.owner, this.state.presentationView.fromScreenshare);
      store.setSpeakerViewRequests(this.state.speakerRequests);
      store.setRemotePresentationFullSize(this.state.remoteFullSize);
      store.setRemotePresentationFullSizeUUID(this.state.remoteToFullSizeUUID);
      // store.setRemotePresentationFullSizeObject(this.state.remoteToFullSizeObject);
    } else {
      this.warn('syncToStore missing store', store);
    }
  }
  // == Syncronization End ==

  // == Peer Handling Start ==
  // On new inited connection
  async onOpenConnection(conn) {
    // this.log('onOpenConnection', conn);

    const remoteState = await conn.getRemoteCallInfoState();
    // this.log('remoteState', remoteState);

    const remoteHasCallActive = remoteState.callActive;

    const isFreshCall = !remoteHasCallActive && !this.state.callActive;
    const areWeTryingToStartACall = conn.callStarter;

    if (isFreshCall && areWeTryingToStartACall) {
      this.log('onOpenConnection me startNewCall', conn.name);
      // New call, and we own it
      await this.startNewCall();
      await this.addUuidToGroup(conn.uuid);
      store.appendOutgoingCallHistory(conn.uuid, this.getCallUUID());
    } else if (isFreshCall && !areWeTryingToStartACall) {
      this.log('onOpenConnection they startNewCall', conn.name);
      // New call, opposing side reigns
      // Do nothing
      store.appendIncomingCallHistory(conn.uuid, this.getCallUUID());
    } else if (!isFreshCall && !this.state.callActive) {
      this.log('onOpenConnection their existing call', conn.name);
      // Existing call, we joined, take their stuff!
      this.setState(remoteState);
      store.appendIncomingCallHistory(conn.uuid, this.getCallUUID());
    } else if (!isFreshCall && this.state.callActive) {
      this.log('onOpenConnection my existing call', conn.name);
      // Existing call, they joined, they will take out stuff
      // Do nothing
      if (this.amIOwner()) await this.addUuidToGroup(conn.uuid);
    } else {
      // Something else happened?!
      this.warn('onOpenConnection unknown case', { conn, state: this.state, remoteState, remoteHasCallActive, isFreshCall, areWeTryingToStartACall });
    }

    // this.cron();
    this.scheduleCron();
  }

  async onCloseConnection(conn) {
    this.log('onCloseConnection', conn);

    if (Object.keys(getWebrtcConnections()).length === 0) {
      //chnage color event meeting done
      // console.log('heeeeereeeeeeee', ownUserUUIDState.getState(), store.state.userInConference)
      if ( store.state.userInConference ){
        if ( store.state.userInConference.isConference &&
          store.state.userInConference.confId &&
          ownUserUUIDState.getState() == store.state.userInConference.ownerConference &&
          store.state.userInConference.color == 'red'){
            dispatchColorChangeTimelineEntry([store.state.userInConference.confId, 'orange']);
            store.setUserInConference(undefined);
        }
      }

      if ( store.state.namespaceSettings.qualityLogging){
        this.showQualityVotingModalEvent();
      }
      // Set the call end time for visitors in their store
      if (isVisitor(ownUserUUIDState.getState()) || isWaitingRoomUser(ownUserUUIDState.getState())) {
        this.setEndCallDateVisitors();
      }
      this.resetState();
    } else {
      if (conn.uuid === this.state.ownerUUID) {
       // console.log('heeeeereeeeeeee 2222222222222', this.state.ownerUUID, store.state.userInConference)
        if ( store.state.userInConference ){
          if ( store.state.userInConference.isConference &&
            store.state.userInConference.confId &&
            this.state.ownerUUID == store.state.userInConference.ownerConference &&
            store.state.userInConference.color == 'red'){
              dispatchColorChangeTimelineEntry([store.state.userInConference.confId, 'orange']);
              store.setUserInConference(undefined);
          }
        }
        if (!this.isNextInLine(ownUserUUIDState.getState())) return;
        // Lost connection to owner!
        this.checkOwnerRecovery();
      }

      if (conn.uuid === this.state.presentationView.owner && this.amIOwner()) {
        this.setState({ presentationView: { ...this.state.presentationView, owner: ownUserUUIDState.getState() } });
      }
    }
    this.redirectToStartView();
  }

  showQualityVotingModalEvent() {
    try {
      if (this.amIOwner() && store.state.namespaceSettings.showCountQualityStatistics) {
        setQualityVotingModalEvent(true);
      }
    } catch (error) {
      console.warn('showQualityVotingModalEvent Error', error);
    }
  }

  async rpcOwner(func, ...args) {
    if (this.amIOwner()) throw new Error('Cannot RPC owner, we are the owner.');
    const connections = getWebrtcConnections();
    const ownerConn = connections[this.state.ownerUUID];
    if (!ownerConn) {
      this.checkOwnerRecovery();
      throw new Error('Not connected to owner');
    }
    return await ownerConn.rpc.rpc(func, ...args); // eslint-disable-line no-return-await
  }

  // Internal use
  _setOwner(uuid) {
    this.setState({ ownerUUID: uuid, group: this.state.group.filter(u => u !== uuid) }, true);
  }

  async checkOwnerRecovery() {
    try {
      if (this.isRecoveryLocked()) return;
      if (this._ownerRecoveryRunning) return;
      this._ownerRecoveryRunning = true;
      if (!this.state.callActive) return; // throw new Error('Call not active');
      const ownerUUID = this.state.ownerUUID;
      if (!ownerUUID) return; // throw new Error('No call owner set');
      const ownUUID = ownUserUUIDState.getState();
      if (ownUUID === ownerUUID) return; // Nothing to do.
      const amConnectedToOwner = await amIConnectedTo(ownerUUID);
      if (amConnectedToOwner) return; // Nothing to do!

      const promises = [];
      for (const conn of Object.values(getWebrtcConnections())) {
        try {
          if (conn.uuid === ownerUUID) throw new Error('Sanity Failure: We just checked he doesnt exist, he does now.');
          promises.push(conn.hasRemoteConnectionTo(ownerUUID));
        } catch (err) {
          this.warn('checkOwnerRecovery Remote owner connection check failed', err, conn);
        }
      }
      const answers = await Promise.all(promises); // If this throws, we want to abort anyway, to not create splitbrains on accident.
      const anyoneHasConnection = answers.find(b => b);
      this.log('checkOwnerRecovery anyoneHasConnection', anyoneHasConnection, answers, promises);
      if (anyoneHasConnection) return; // Nothing to do, hopefully?

      if (!this.isNextInLine(ownUserUUIDState.getState())) return; // Not new master, exit.

      // Declare ourselves god!

      // this.setState({ ownerUUID: ownUUID, group: this.state.group.filter(u => u !== ownUUID) });
      if (this.isRecoveryLocked()) {
        this.warn('Aborted owner recovery due to it being locked!');
        return;
      }
      this._setOwner(ownUUID);
    } catch (err) {
      this.warn('checkOwnerRecovery Error:', err);
    } finally {
      // this.log('checkOwnerRecovery _ownerRecoveryRunning false');
      this._ownerRecoveryRunning = false;
    }
  }
  // == Peer Handling End ==

  // == External Intents Start ==
  adjustCallDurationMs(diff) {
    if (typeof diff !== 'number') throw new Error('Invalid argument');
    if (!this.amIOwner()) throw new Error('Not call owner');
    const oldDuration = (this.state.callDurationMs || (60000 * 20));
    const newDuration = oldDuration + diff;

    const minimumDuration = this.getCurrentCallRunningTime();
    if (newDuration > minimumDuration) {
      this.log('adjustCallDurationMs', oldDuration, '+', diff, '==', newDuration);
      this.setState({ callDurationMs: newDuration });
    }
  }

  // HACK: this is just to get the "current" version of the messages running for now
  // FIXME: this whole thing is bad, multiple people toggling mute etc gets out of whack
  async setMessageToParticipants(message) {
    // this.log('setMessageToParticipants', message);
    if (this.amIOwner()) {
      this.setState({ messageToParticipants: message });
    } else {
      await this.rpcOwner('setMessageToParticipants', message);
    }
  }

  async setPresentationView(obj) {
    // this.log('setPresentationView', obj);
    if (this.amIOwner()) {
      this.setState({
        presentationView: {
          show: obj.show || obj.value,
          owner: obj.owner,
          fromScreenshare: obj.fromScreenshare,
        }
      });
    } else {
      await this.rpcOwner('setPresentationView', obj);
    }
  }

  transferOwnership(uuid) {
    if (this.amIOwner()) {
      const lockedTill = Date.now() + (1000 * 15);
      const obj = { ownerUUID: uuid, group: this.state.group.filter(u => u !== uuid), recoveryLockedTillTs: lockedTill };
      if (ownUserUUIDState.getState() === this.state.presentationView.owner) {
        obj.presentationView = { ...this.state.presentationView, owner: uuid };
      }
      this.log('transferOwnership', obj);
      this.setState(obj, true);
    } else {
      throw new Error('Not owner of call');
    }
  }

  async setSpeakerRequestForUUID(uuid, wantsToSpeak) {
    this.log('setSpeakerRequestForUUID', uuid, wantsToSpeak, this.state.speakerRequests, this.amIOwner());
    if (this.amIOwner()) {
      let speakers = this.state.speakerRequests || [];
      if (wantsToSpeak) {
        if (speakers.indexOf(uuid) === -1) {
          speakers.push(uuid);
          dispatchSuccessAlert(store.getNameForUuid(uuid) + ' ' + Vue.prototype.$t('components.callsContent.speakerViewRequested'));
        }
      } else {
        const index = speakers.indexOf(uuid);
        if (index !== -1) {
          speakers = speakers.splice(index, 1);
        }
      }

      this.setState({ speakerRequests: speakers });
    } else {
      await this.rpcOwner('setSpeakerRequestForUUID', uuid, wantsToSpeak);
    }
  }

  async setWantToSpeak(wantsToSpeak) {
    return this.setSpeakerRequestForUUID(ownUserUUIDState.getState(), wantsToSpeak);
  }

  async setFullSizeForUUID(uuid) {
    if (this.amIOwner()) {
      if (uuid) {
        // set to this guy
        this.setState({ remoteFullSize: true, remoteToFullSizeUUID: uuid, speakerRequests: this.state.speakerRequests.filter(u => u !== uuid) });
      } else {
        // reset
        this.setState({ remoteFullSize: false, remoteToFullSizeUUID: null });
      }
    } else {
      await this.rpcOwner('setFullSizeForUUID', uuid);
    }
  }

  async setAllowedToTalk(uuids) {
    if (this.amIOwner()) {
      this.setState({ allowedToTalk: uuids || [] });
    } else {
      throw new Error('Not owner of call');
    }
  }

  async addToAllowedToTalk(uuid) {
    if (this.amIOwner()) {
      const callState = this.getState();

      if (callState.notAllowedToTalk.indexOf(uuid) !== -1) {
        this.removeToNotAllowedToTalk(uuid);
      }

      const currentAllowedToTalk = callState.allowedToTalk;
      if (currentAllowedToTalk.indexOf(uuid) === -1) {
        currentAllowedToTalk.push(uuid);
      }
      this.setState({ allowedToTalk: currentAllowedToTalk || [] });
      // console.log('allowed to talk setAdd', currentAllowedToTalk)
    } else {
      throw new Error('Not owner of call');
    }
  }

  async removeToAllowedToTalk(uuid) {
    if (this.amIOwner()) {
      const callState = this.getState();
      const currentAllowedToTalk = callState.allowedToTalk;
      const newState = currentAllowedToTalk.filter((id) => id !== uuid);
      this.setState({ allowedToTalk: newState || [] });
      // console.log('allowed to talk setRemove', newState)
    } else {
      throw new Error('Not owner of call');
    }
  }

  async setNotAllowedToTalk(uuids) {
    if (this.amIOwner()) {
      this.setState({ notAllowedToTalk: uuids || [] });
    } else {
      throw new Error('Not owner of call');
    }
  }

  async addToNotAllowedToTalk(uuid) {
    if (this.amIOwner()) {
      const callState = this.getState();

      if (callState.allowedToTalk.indexOf(uuid) !== -1) {
        this.removeToAllowedToTalk(uuid);
      }

      const currentAllowedToTalk = callState.notAllowedToTalk;
      if (currentAllowedToTalk.indexOf(uuid) === -1) {
        currentAllowedToTalk.push(uuid);
      }
      this.setState({ notAllowedToTalk: currentAllowedToTalk || [] });
      // console.log('not allowed to talk setAdd', currentAllowedToTalk)
    } else {
      throw new Error('Not owner of call');
    }
  }

  async removeToNotAllowedToTalk(uuid) {
    if (this.amIOwner()) {
      const callState = this.getState();
      const currentAllowedToTalk = callState.notAllowedToTalk;
      const newState = currentAllowedToTalk.filter((id) => id !== uuid);
      this.setState({ notAllowedToTalk: newState || [] });
      // console.log('not allowed to talk setRemove', newState)
    } else {
      throw new Error('Not owner of call');
    }
  }

  // redirect startview
  async redirectToStartView() {
    const startView = '/' + (isGuestOrVisitor() ? 'home' : (store.state.user.userSettings.startView || 'my-favorites'));
    if (Router.currentRoute.path !== startView) Router.push(startView);
  }
  // == External Intents End ==
}

export default new CallInfoManager();
