// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.__view {
  width: unset !important;
}
.v-theme--light.v-input textarea::placeholder {
  color: black !important;
}
.v-theme--dark.v-input textarea::placeholder {
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/inviteVisitorModal.vue","webpack://./inviteVisitorModal.vue"],"names":[],"mappings":"AACA;EACE,uBAAA;ACAF;ADGE;EACE,uBAAA;ACAJ;ADKE;EACE,uBAAA;ACFJ","sourcesContent":["\n.__view {\n  width: unset !important;\n}\n.v-theme--light.v-input textarea {\n  &::placeholder {\n    color: black !important;\n  }\n}\n\n.v-theme--dark.v-input textarea {\n  &::placeholder {\n    color: white !important;\n  }\n}\n",".__view {\n  width: unset !important;\n}\n\n.v-theme--light.v-input textarea::placeholder {\n  color: black !important;\n}\n\n.v-theme--dark.v-input textarea::placeholder {\n  color: white !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
