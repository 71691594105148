// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdfContainer[data-v-6f320f21] {
  height: calc(100vh - 165px);
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/waitingRoom/waitingRoomBackgroundPDF.vue","webpack://./waitingRoomBackgroundPDF.vue"],"names":[],"mappings":"AACA;EACE,2BAAA;ACAF","sourcesContent":["\n.pdfContainer{\n  height: calc(100vh - 165px);\n}\n",".pdfContainer {\n  height: calc(100vh - 165px);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
