<template>
  <UserListItem flat :person="visitorGroupEntry" :section="'users'" />
</template>

<script>
import store from "../../../store";
import DefaultAvatar from "../../image/defaultAvatar.vue";
import { getStatusColorByUser } from "../../../utils/status";
import UserListItem from "../../sidebar/userListItem.vue";
export default {
  props: ['user'],
  components:{
    DefaultAvatar,
    UserListItem
  },
  data() {
    return {
      state: store.state,
    };
  },
  mounted(){
    
  },
  computed: {
    setBorderByStatus() {
      try {
        return getStatusColorByUser(this.visitorUser, this.connected);
        
      } catch {}
    },
    visitorUUID() {
      return this.user;
    },
    visitorGroupEntry() {
      return this.state.group[this.visitorUUID] || {};
    },
    visitorUser() {
      return this.visitorGroupEntry.user || {};
    },
    name() {
      return this.visitorUser.name || 'Unknown';
    },
    connected() {
      return this.visitorGroupEntry.connected || false;
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>

</style>