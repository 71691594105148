<template>
  <div>
    <div v-if="entry">
      {{ personName }}: {{ entry.fileName }}
      <div v-if="percentDone">{{ entry.sizeSoFar }} / {{ entry.expectedSize }} {{ percentDone }} </div>
      <div v-if="entry.incoming">
        {{ $t('components.fileTransferEntry.incoming') }}
        <div v-if="entry.expectedMD5 && entry.actualMD5">MD5 Match: {{ md5Match }} Expected: {{ entry.expectedMD5 }} Actual: {{ entry.actualMD5 }}</div>
        <button v-show="entry.resolve" @click="entry.resolve(true)">{{ $t('generics.accept') }}</button>
        <button v-show="entry.resolve" @click="entry.resolve(false)">{{ $t('generics.reject') }}</button>
        <a v-show="entry.complete" :href="entry.finalData" :download="entry.fileName" target="_blank">{{ $t('components.fileItemList.download') }}</a>
      </div>
      <div v-if="entry.outgoing">
        Outgoing
        Pending: {{ entry.pending }} Accepted: {{ entry.accepted }}
      </div>
      <div v-show="entry.complete">Complete!</div>
      <button v-show="entry.complete" @click="remove()">[X]</button>
    </div>
    <div v-else> {{ $t('components.fileTransferEntry.brokenEntry') }} </div>
  </div>
</template>

<script>
import store from '../../store';
export default {
  props: ['entry'],
  data() {
    return {
      // state: store.state,
      // FileTransferModal: store.state.FileTransferModal,
    };
  },
  methods: {
    remove() {
      store.delFileTransferFor(this.entry.uuid, this.entry.fileName);
    },
  },
  computed: {
    personName() {
      return store.getNameForUuid(this.entry.uuid);
    },
    percentDone() {
      if (!this.entry.sizeSoFar) return 0;
      return ( (100 / this.entry.expectedSize) * this.entry.sizeSoFar ).toFixed(1);
    },
    md5Match() {
      return this.entry.expectedMD5 === this.entry.actualMD5;
    }
  }
};
</script>

<style scoped lang="scss">
.devPanel {
  // position: fixed;
  // top: 100px;
  // right: 5px;
  // border: 1px solid black;
  // background-color: rgba(204, 204, 204, 0.884);
  // display: none;
      position: fixed;
    bottom: 0px;
    left: 5px;
    border: 1px solid black;
    background-color: rgba(204,204,204,0.884);
    pointer-events: none;
}
</style>