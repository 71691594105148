// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentWrapper[data-v-9b3d459e] {
  max-width: 200px;
}
.borderRadius5[data-v-9b3d459e] {
  border-radius: 5px !important;
}
.cursorPointer[data-v-9b3d459e] {
  cursor: pointer;
}
.v-application--is-ltr .v-list-item__avatar[data-v-9b3d459e]:last-of-type:not(:only-child) {
  margin-left: 0px !important;
}
.v-card .v-list-item[data-v-9b3d459e] {
  padding-left: 4px !important;
}
.v-card[data-v-9b3d459e]:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0px !important;
}
.grayScale[data-v-9b3d459e] {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}`, "",{"version":3,"sources":["webpack://./websrc/components/meetingLine/eventViews/oneCallParticipants.vue","webpack://./oneCallParticipants.vue"],"names":[],"mappings":"AACA;EACE,gBAAA;ACAF;ADEA;EACE,6BAAA;ACCF;ADCA;EACE,eAAA;ACEF;ADAA;EACE,2BAAA;ACGF;ADDA;EACE,4BAAA;ACIF;ADFA;EACE,6BAAA;ACKF;ADHA;EACE,+BAAA;EACA,uBAAA;ACMF","sourcesContent":["\n.contentWrapper {\n  max-width: 200px;\n}\n.borderRadius5 {\n  border-radius: 5px !important;\n}\n.cursorPointer {\n  cursor: pointer;\n}\n.v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child) {\n  margin-left: 0px !important;\n}\n.v-card .v-list-item {\n  padding-left: 4px !important;\n}\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0px !important;\n}\n.grayScale {\n  -webkit-filter: grayscale(100%);\n  filter: grayscale(100%);\n}\n",".contentWrapper {\n  max-width: 200px;\n}\n\n.borderRadius5 {\n  border-radius: 5px !important;\n}\n\n.cursorPointer {\n  cursor: pointer;\n}\n\n.v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child) {\n  margin-left: 0px !important;\n}\n\n.v-card .v-list-item {\n  padding-left: 4px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0px !important;\n}\n\n.grayScale {\n  -webkit-filter: grayscale(100%);\n  filter: grayscale(100%);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
