<template>
  <v-row class="px-4">
    <v-col cols="2">
      <DailyReportUsersList
        :setSelectedUser="setSelectedUser"
        :selectedUser="selectedUser"
      />
    </v-col>
    <v-col cols="10">
      <div class="text-right px-3">
      <v-btn @click="setDailyReport" class="primary" >{{ $t("components.adminSettingsModal.addDailyReport")}}</v-btn>
      </div>
      <DailyReportsTable :selectedUser="selectedUser" />
    </v-col>
  </v-row>
</template>

<script>
import store from "../../../store";
import DailyReportUsersList from "./dailyReportUsersList.vue";
import DailyReportsTable from "./dailyReportTable.vue";
import {
  setDailyReportModalEvent
} from "../../../effector/modals";

export default {
  components: { DailyReportUsersList, DailyReportsTable },
  data() {
    return {
      state: store.state,
      selectedUser: undefined,
    };
  },
  methods: {
    setDailyReport(){
       setDailyReportModalEvent(true)
    },
    setSelectedUser(userUUID) {
      this.selectedUser = userUUID || this.state.ownUUID;
    },
  },
  computed: {},
};
</script>