// import { timelineDomain.createEvent } from 'effector';
import { groupConferenceDomain } from './domain';

export const newGroupConferenceEvent = groupConferenceDomain.createEvent('newGroupConference');
export const updateGroupConferenceEvent = groupConferenceDomain.createEvent('updateGroupConference');

export const dispatchFetchMyGroupConferencesEvent = groupConferenceDomain.createEvent('dispatchFetchMyGroupConferencesEvent');
export const dispatchDeleteGroupConferenceEvent = groupConferenceDomain.createEvent('dispatchDeleteGroupConferenceEvent');
export const getMyConferenceDetailsEvent = groupConferenceDomain.createEvent('getMyConferenceDetailsEvent');
export const setGroupConferenceDetailEvent = groupConferenceDomain.createEvent('setGroupConferenceDetailEvent');

export const dispatchFetchGroupConferenceInfosEvent = groupConferenceDomain.createEvent('dispatchFetchGroupConferenceInfosEvent');

export const setGroupConferenceUUIDsEvent = groupConferenceDomain.createEvent('setGroupConferenceUUIDs');
export const setGroupConferenceInfoEvent = groupConferenceDomain.createEvent('setGroupConferenceInfo');
export const deleteGroupConferenceEntryAndUUIDEvent = groupConferenceDomain.createEvent('deleteGroupConferenceEntryAndUUID');
export const setGroupConferencesInfoBatchedEvent = groupConferenceDomain.createEvent('setGroupConferencesInfoBatched');
