// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-input--radio-group.v-input--radio-group--row .v-radio[data-v-c49b0060] {
  margin-right: 16px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/startViewModal.vue","webpack://./startViewModal.vue"],"names":[],"mappings":"AACA;EACE,6BAAA;ACAF","sourcesContent":["\n.v-input--radio-group.v-input--radio-group--row .v-radio {\n  margin-right: 16px !important;\n}\n",".v-input--radio-group.v-input--radio-group--row .v-radio {\n  margin-right: 16px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
