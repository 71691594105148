// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userNameCard[data-v-4bb1e44b] {
  margin-bottom: 0px;
  font-size: 19px;
  color: #2a3133;
}
.pointer[data-v-4bb1e44b] {
  cursor: pointer;
}
.thumbs-down[data-v-4bb1e44b] {
  color: red;
}
.thumbs-up[data-v-4bb1e44b] {
  color: green;
}
.faPhoneRotate[data-v-4bb1e44b] {
  transform: rotate(230deg) !important;
}
.borderRadius10[data-v-4bb1e44b] {
  border-radius: 10px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/callPersonConferenceHallModal.vue","webpack://./callPersonConferenceHallModal.vue"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,eAAA;EACA,cAAA;ACAF;ADEA;EACE,eAAA;ACCF;ADCA;EACE,UAAA;ACEF;ADAA;EACE,YAAA;ACGF;ADDA;EACE,oCAAA;ACIF;ADFA;EACE,8BAAA;ACKF","sourcesContent":["\n.userNameCard {\n  margin-bottom: 0px;\n  font-size: 19px;\n  color: #2a3133;\n}\n.pointer {\n  cursor: pointer;\n}\n.thumbs-down {\n  color: red;\n}\n.thumbs-up {\n  color: green;\n}\n.faPhoneRotate {\n  transform: rotate(230deg) !important;\n}\n.borderRadius10{\n  border-radius: 10px !important;\n}\n",".userNameCard {\n  margin-bottom: 0px;\n  font-size: 19px;\n  color: #2a3133;\n}\n\n.pointer {\n  cursor: pointer;\n}\n\n.thumbs-down {\n  color: red;\n}\n\n.thumbs-up {\n  color: green;\n}\n\n.faPhoneRotate {\n  transform: rotate(230deg) !important;\n}\n\n.borderRadius10 {\n  border-radius: 10px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
