<template>
  <v-dialog persistent :model-value="showUploadUserPictureModal" @update:model-value="showUploadUserPictureModal = $event" max-width="570">
    <v-card>
      <HeaderModal
        :titleModal="$t('components.uploadUserPictureModal.providePhoto')"
        :closeModalFunction="closeUserProfileAndCamera"
      >
        <v-spacer></v-spacer>
      </HeaderModal>
      <div v-show="showCroppie">
        <v-row class="d-flex justify-center mx-auto pt-5 pb-2">
          <v-col cols="6" class="py-0">
            <cropper
              :stencil-size="stencilSize"
              :default-size="defaultSize"
              ref="cropper"
              class="twitter-cropper"
              background-class="twitter-cropper__background"
              foreground-class="twitter-cropper__foreground"
              image-restriction="stencil"
              :stencil-props="{
                lines: {},
                handlers: {},
                movable: false,
                scalable: false,
                aspectRatio: 1,
                previewClass: 'twitter-cropper__stencil',
              }"
              :transitions="false"
              :canvas="true"
              :debounce="false"
              :min-width="150"
              :min-height="150"
              :src="temporalAvatar"
              @change="onChange"
            />
            <v-slider :model-value="zoomValue" @update:model-value="zoomValue = $event; onZoom(zoomValue)"></v-slider>
          </v-col>
          <div class="rotateIcons">
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-btn density="comfortable" variant="text" v-bind="props" icon color="grey" @click="rotate(-90)">
                  <font-awesome-icon
                    :icon="['fal', 'undo']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.navbar.rotateLeft") }}</span>
            </v-tooltip>
            <br />
            <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-btn density="comfortable" variant="text" v-bind="props" icon color="grey" @click="rotate(90)">
                  <font-awesome-icon
                    :icon="['fal', 'undo']"
                    :style="{ fontSize: '20px' }"
                    class="fa-flip-horizontal"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.navbar.rotateRight") }}</span>
            </v-tooltip>
          </div>
        </v-row>
        <v-card color="primary" class="py-2 pl-4 footerModal">
          <v-row class="px-2 btns" flex :style="{ width: '100%', margin: 0 }">
            <v-col class="px-0" align="right">
              <v-btn
                class="buttonPrimaryFooter"
                flat
                color="white"
                @click="crop"
                >{{ $t("generics.save") }}</v-btn
              >
              <v-btn
                class="ml-3 mr-2"
                color="white"
                style="border-radius: 4px!important; min-width: 45px"
                icon
                tile
                variant="outlined"
                @click="cancelCrop"
              >
                <font-awesome-icon
                  :icon="['fal', 'times']"
                  :style="{ fontSize: '20px' }"
              /></v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-spacer></v-spacer>
      </div>
      <v-row flex :style="{ width: '100%', margin: 0 }" v-if="!showCroppie">
        <input
          type="file"
          ref="fileInput"
          class="d-none"
          @change="showCroppieModal($event)"
        />
        <v-col>
          <div align="center">
            <v-avatar tile size="150" class="borderRadius10">
                <DefaultAvatar
                  :size="150"
                  :userData="state.user"
                  v-if="
                    !state.user.avatar ||
                    state.user.avatar === 'img/default_profile_picture.png'
                  "
                ></DefaultAvatar>
                <v-img
                  v-if="
                    state.user.avatar &&
                    state.user.avatar !== 'img/default_profile_picture.png'
                  "
                  class="borderRadius10"
                  max-height="150"
                  max-width="150"
                  contain
                  :src="state.user.avatar"
                  lazy-src="assets/default_profile_picture.png"
                ></v-img>
              <div
                flex
                class="uploadAvatarButtons p-4 mx-auto"
                v-if="state.ownModalUser"
                :class="{
                  hasImage:
                    state.user.avatar != 'img/default_profile_picture.png',
                  hasNoImage:
                    state.user.avatar == 'img/default_profile_picture.png',
                }"
              >
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      flat
                      v-bind="props"
                      color="primary"
                      class="mb-2"
                      isSmall="true"
                      v-on:click="selectAvatar"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'upload']"
                        :style="{ fontSize: '20px' }"
                      />
                    </v-btn>
                  </template>
                  <span>{{ $t("components.navbar.uploadPicture") }}</span>
                </v-tooltip>
              </div>
            </v-avatar>
          </div>
        </v-col>
        <v-row :style="{ width: '100%', margin: 0 }">
          <v-col>
            <v-checkbox
              :model-value="dontShowModal" @update:model-value="dontShowModal = $event; updateDontShowAgain()"
              :label="$t('generics.dontShowAgain')"
              hide-details
              class="mt-0"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-row>
      <FooterModal
        v-if="!showCroppie"
        :closeModalFunction="closeUserProfileAndCamera"
        :hideDefaultButtons="true"
      >
        <v-btn
          style="border-radius: 4px!important; min-width: 45px"
          class="ml-3 mr-2"
          tile
          @click="closeUserProfileAndCamera"
        >
          {{ $t("components.newVersionAvailable.later") }}
        </v-btn>
      </FooterModal>
    </v-card>
  </v-dialog>
</template>
<script>
import store from "../../store";
import DefaultAvatar from "../image/defaultAvatar.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  props: ["showUploadUserPictureModal", "toggleUploadUserPictureModal"],
  components: { DefaultAvatar, HeaderModal, FooterModal },
  data: () => ({
    state: store.state,
    showCroppie: false,
    ownUUID: store.state.ownUUID,
    dontShowModal: store.state.user.dontShowAvatarMessage,
    zoomValue: 0,
    temporalAvatar: '',
  }),
  methods: {
    updateDontShowAgain() {
      this.$set(this.state.user, "dontShowAvatarMessage", this.dontShowModal);
    },
    closeModal() {
      if (window.stream) {
        window.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      if (this.toggleModalSettings) {
        this.toggleModalSettings();
      }
    },
    closeUserProfileAndCamera() {
      this.toggleUploadUserPictureModal();
    },
    onChange(result) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const { coordinates, imageSize } = cropper;
        if (
          imageSize.width / imageSize.height >
          coordinates.width / coordinates.height
        ) {
          // Determine the position of slider bullet
          // It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
          this.zoom =
            (cropper.imageSize.height - cropper.coordinates.height) /
            (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
        } else {
          this.zoom =
            (cropper.imageSize.width - cropper.coordinates.width) /
            (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
        }
      }
    },
    onZoom(result) {
      const value = result / 100
      const cropper = this.$refs.cropper;
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight;
          const imageHeight = cropper.imageSize.height;
          // Determine the current absolute zoom and the new absolute zoom
          // to calculate the sought relative zoom value
          cropper.zoom(
            (imageHeight - this.zoom * (imageHeight - minHeight)) /
              (imageHeight - value * (imageHeight - minHeight))
          );
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth;
          const imageWidth = cropper.imageSize.width;
          cropper.zoom(
            (imageWidth - this.zoom * (imageWidth - minWidth)) /
              (imageWidth - value * (imageWidth - minWidth))
          );
        }
      }
    },
    defaultSize({ imageSize }) {
      return {
        width: Math.min(imageSize.height, imageSize.width),
        height: Math.min(imageSize.height, imageSize.width),
      };
    },
    stencilSize({ boundaries }) {
      return {
        width: Math.min(boundaries.height, boundaries.width) - 10,
        height: Math.min(boundaries.height, boundaries.width) - 10,
      };
    },
    resizeImage(imagePath, newWidth, newHeight) {
      const originalImage = new Image();
      originalImage.src = imagePath;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      return new Promise((resolve, reject) => {
        originalImage.addEventListener('error', (err) => reject(err));
        originalImage.addEventListener('load', () => {
          const originalWidth = originalImage.naturalWidth;
          const originalHeight = originalImage.naturalHeight;
          const aspectRatio = originalWidth/originalHeight;
          if (newHeight === undefined) {
            newHeight = newWidth/aspectRatio;
          }
          canvas.width = newWidth;
          canvas.height = newHeight;
          ctx.drawImage(originalImage, 0, 0, newWidth, newHeight);
          const dataUrl = canvas.toDataURL("image/jpeg", 0.9);
          return resolve(dataUrl);
        });
      });
    },
    async crop() {
      const canvas = this.$refs.cropper.getResult().canvas;
      if (canvas) {
        const base64Image = canvas.toDataURL("image/jpeg");
        const resizedImage = await this.resizeImage(base64Image, 256);
        // Do something with the base64 image
        store.setAvatar(resizedImage, this.state.user.uuid);
        this.showCroppie = false;
      }
    },
    cancelCrop() {
      this.showCroppie = false;
      this.resetInputAvatar();
    },
    result(output) {
      this.cropped = output;
    },
    rotate(rotationAngle) {
      // Rotates the image
      this.$refs.cropper.rotate(rotationAngle);
    },
    selectAvatar() {
      this.$refs.fileInput.click();
    },
    resetInputAvatar() {
      this.$refs.fileInput.value = "";
    },
    showCroppieModal(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      var reader = new FileReader();
      reader.onload = (e) => {
        this.temporalAvatar = e.target.result;
        this.showCroppie = true;
      };
      reader.readAsDataURL(files[0]);
      this.resetInputAvatar();
    },
  },
  computed: {
    cols() {
      const { lg, sm, md, xl } = this.$vuetify.display;
      return lg ? [4, 8] : sm ? [4, 8] : md ? [4, 8] : xl ? [4, 8] : [12, 12];
    },
  },
};
</script>

<style scoped lang="scss">
.twitter-cropper {
  height: 256px;
  width: 256px;
  &__background {
    background-color: #edf2f4;
  }
  &__foreground {
    background-color: #edf2f4;
  }
  &__stencil {
    border: solid 5px rgb(29, 161, 242);
  }
}
.rotateIcons {
  position: absolute;
  right: 16%;
  top: 40%;
}
video {
  height: 256;
  width: 256;
  min-width: 256;
  border: 2px solid #f2f2f2;
  background: black;
}

.btns {
  justify-content: flex-end;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
.uploadAvatarButtons {
  position: absolute;
  left: 0px;
  height: 150px;
  width: 150px;
  top: 0px;
  border-radius: 10px;
  text-align: center;
  padding-top: 100px;
  button {
    height: 33px !important;
    min-width: 45px !important;
    padding: 0 10px !important;
  }
  &.hasImage {
    background: #00000030;
    display: none;
    &:hover {
      display: block;
    }
  }
  &.hasNoImage {
    display: none;
    &:hover {
      display: block;
    }
  }
}
.borderRadius10 {
  &:hover > .hasImage {
    display: block;
  }
  &:hover > .hasNoImage {
    display: block;
  }
}
/*.croppie-container {
  height: unset;
  margin: 40px auto;
}*/
.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  border: 2px solid #2a3133 !important;
}
</style>
<style lang="scss">
.sliderSize .cr-slider-wrap {
  width: 100%;
  margin: 15px auto;
}
.sliderSize .cr-slider {
  width: 256px;
}
</style>