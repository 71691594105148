// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userNameCard[data-v-02d3e93c] {
  margin-bottom: 0px;
  font-size: 19px;
  color: #2a3133;
}
.dataUserCard[data-v-02d3e93c] {
  margin-bottom: 0px;
}
.borderRadius10[data-v-02d3e93c],
.v-avatar .v-image[data-v-02d3e93c] {
  border-radius: 10px !important;
}
.w-200[data-v-02d3e93c] {
  width: 200px;
}
.w-100-230[data-v-02d3e93c] {
  width: calc(100% - 230px);
}
.footerModal[data-v-02d3e93c] {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
}
.footerModal .btns[data-v-02d3e93c] {
  justify-content: flex-end;
}
.w-100[data-v-02d3e93c] {
  width: 100%;
}
.centerRow[data-v-02d3e93c] {
  align-items: center;
  justify-content: center !important;
}
.faPhoneRotate[data-v-02d3e93c] {
  transform: rotate(230deg) !important;
}
.v-input--radio-group.v-input--radio-group--row .v-radio[data-v-02d3e93c] {
  margin-right: unset !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/incomingCallSub.vue","webpack://./incomingCallSub.vue"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,eAAA;EACA,cAAA;ACAF;ADEA;EACE,kBAAA;ACCF;ADCA;;EAEE,8BAAA;ACEF;ADAA;EACE,YAAA;ACGF;ADDA;EACE,yBAAA;ACIF;ADFA;EACE,WAAA;EACA,6BAAA;EACA,uBAAA;ACKF;ADJE;EACE,yBAAA;ACMJ;ADHA;EACE,WAAA;ACMF;ADJA;EACE,mBAAA;EACA,kCAAA;ACOF;ADLA;EACE,oCAAA;ACQF;ADNA;EACE,8BAAA;ACSF","sourcesContent":["\n.userNameCard {\n  margin-bottom: 0px;\n  font-size: 19px;\n  color: #2a3133;\n}\n.dataUserCard {\n  margin-bottom: 0px;\n}\n.borderRadius10,\n.v-avatar .v-image {\n  border-radius: 10px !important;\n}\n.w-200 {\n  width: 200px;\n}\n.w-100-230 {\n  width: calc(100% - 230px);\n}\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n  .btns {\n    justify-content: flex-end;\n  }\n}\n.w-100 {\n  width: 100%;\n}\n.centerRow {\n  align-items: center;\n  justify-content: center !important;\n}\n.faPhoneRotate {\n  transform: rotate(230deg) !important;\n}\n.v-input--radio-group.v-input--radio-group--row .v-radio {\n  margin-right: unset !important;\n}\n",".userNameCard {\n  margin-bottom: 0px;\n  font-size: 19px;\n  color: #2a3133;\n}\n\n.dataUserCard {\n  margin-bottom: 0px;\n}\n\n.borderRadius10,\n.v-avatar .v-image {\n  border-radius: 10px !important;\n}\n\n.w-200 {\n  width: 200px;\n}\n\n.w-100-230 {\n  width: calc(100% - 230px);\n}\n\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n}\n.footerModal .btns {\n  justify-content: flex-end;\n}\n\n.w-100 {\n  width: 100%;\n}\n\n.centerRow {\n  align-items: center;\n  justify-content: center !important;\n}\n\n.faPhoneRotate {\n  transform: rotate(230deg) !important;\n}\n\n.v-input--radio-group.v-input--radio-group--row .v-radio {\n  margin-right: unset !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
