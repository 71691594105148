// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.playerClass[data-v-02092a8c] {
  height: 100%;
  width: 100%;
}
.preventClick[data-v-02092a8c] {
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/calls/recorderIframe.vue","webpack://./recorderIframe.vue"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,WAAA;ACAF;ADEA;EACE,oBAAA;ACCF","sourcesContent":["\n.playerClass{\n  height: 100%;\n  width: 100%;\n}\n.preventClick {\n  pointer-events: none;\n}\n",".playerClass {\n  height: 100%;\n  width: 100%;\n}\n\n.preventClick {\n  pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
