// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sliderSize .cr-slider-wrap {
  width: 100%;
  margin: 15px auto;
}
.sliderSize .cr-slider {
  width: 256px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/firstModal.vue","webpack://./firstModal.vue"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,iBAAA;ACAF;ADEA;EACE,YAAA;ACCF","sourcesContent":["\n.sliderSize .cr-slider-wrap {\n  width: 100%;\n  margin: 15px auto;\n}\n.sliderSize .cr-slider {\n  width: 256px;\n}\n",".sliderSize .cr-slider-wrap {\n  width: 100%;\n  margin: 15px auto;\n}\n\n.sliderSize .cr-slider {\n  width: 256px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
