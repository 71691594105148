// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.optOutCheckbox[data-v-d877c1be] {
  width: 5%;
  display: inline-block;
  margin-top: 0px;
  padding-top: 0px;
  position: relative;
  top: -4px;
  text-align: right;
}
.spanCheckBox[data-v-d877c1be] {
  width: 90%;
  display: inline-block;
}
.m-0a[data-v-d877c1be] {
  margin: 0 auto !important;
}
.positionRelative[data-v-d877c1be] {
  position: relative;
}
.whatsAppIcon[data-v-d877c1be] {
  position: absolute;
  top: 9px;
  right: 20px;
}
.inputPhone2FA[data-v-d877c1be] {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
  border-bottom: none !important;
  height: 38px;
}
.redBordered[data-v-d877c1be] {
  border: 1px solid red;
}
.greenBordered[data-v-d877c1be] {
  border-top: 1px solid green;
  border-bottom: 1px solid green;
}`, "",{"version":3,"sources":["webpack://./websrc/components/2fa/modals/steps/2faActivationModal.vue","webpack://./2faActivationModal.vue"],"names":[],"mappings":"AACA;EACE,SAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,SAAA;EACA,iBAAA;ACAF;ADEA;EACE,UAAA;EACA,qBAAA;ACCF;ADCA;EACE,yBAAA;ACEF;ADAA;EACE,kBAAA;ACGF;ADDA;EACE,kBAAA;EACA,QAAA;EACA,WAAA;ACIF;ADFA;EACE,0HAAA;EAEA,8BAAA;EACA,YAAA;ACIF;ADFA;EACE,qBAAA;ACKF;ADHA;EACE,2BAAA;EACA,8BAAA;ACMF","sourcesContent":["\n.optOutCheckbox {\n  width: 5%;\n  display: inline-block;\n  margin-top: 0px;\n  padding-top: 0px;\n  position: relative;\n  top: -4px;\n  text-align: right;\n}\n.spanCheckBox {\n  width: 90%;\n  display: inline-block;\n}\n.m-0a {\n  margin: 0 auto !important;\n}\n.positionRelative {\n  position: relative;\n}\n.whatsAppIcon {\n  position: absolute;\n  top: 9px;\n  right: 20px;\n}\n.inputPhone2FA {\n  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),\n    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;\n  border-bottom: none !important;\n  height: 38px;\n}\n.redBordered {\n  border: 1px solid red;\n}\n.greenBordered {\n  border-top: 1px solid green;\n  border-bottom: 1px solid green;\n}\n",".optOutCheckbox {\n  width: 5%;\n  display: inline-block;\n  margin-top: 0px;\n  padding-top: 0px;\n  position: relative;\n  top: -4px;\n  text-align: right;\n}\n\n.spanCheckBox {\n  width: 90%;\n  display: inline-block;\n}\n\n.m-0a {\n  margin: 0 auto !important;\n}\n\n.positionRelative {\n  position: relative;\n}\n\n.whatsAppIcon {\n  position: absolute;\n  top: 9px;\n  right: 20px;\n}\n\n.inputPhone2FA {\n  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;\n  border-bottom: none !important;\n  height: 38px;\n}\n\n.redBordered {\n  border: 1px solid red;\n}\n\n.greenBordered {\n  border-top: 1px solid green;\n  border-bottom: 1px solid green;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
