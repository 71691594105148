<template>
  <v-row class="pt-3" id="v-tour-userActionBar" @click="showStatus">
    <div class="d-flex pl-2 pr-4 justify-space-between w100">
      <ReceivedAndSentmessages
        :setTotalNotificationFile="setTotalNotificationFile"
      />
      <Calls
        :setTotalNotificationCall="setTotalNotificationCall"
        :totalNotificationCall="totalNotificationCall"
        :phoneColorColor="phoneColorColor"
        :iconsBlack="iconsBlack"
      />
    </div>
    <CustomTT
      v-if="showCustomTT"
      :text="`${$t('components.hotkeys.controlKey')} + Q`"
      position="bottom"
      :extraclass="'ctrq'"
    />
  </v-row>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store, { EventBus } from "../../../store";
import InviteGuest from "../../navbar/inviteGuest.vue";
import Calls from "./calls.vue";
import SentMessages from "./sentMessages.vue";
import ReceivedAndSentmessages from "./receivedAndSentmessages.vue";
import Files from "./files.vue";
import Protocol from "./protocol.vue";
import DirectLinkInvitation from "./directLinkInvitation.vue";
import DeviceSetupModal from "../../modal/deviceSetupModal.vue";
import CustomTT from "../../content/customTT.vue";
import { hotkeysInfoStore } from "../../../effector/hotkeysInfo";
import { hasPrivilege } from "../../../utils/privileges";
export default {
  props: [
    "setTotalNotificationCall",
    "totalNotificationCall",
    "phoneColorColor",
    "handleStatusClick",
    "totalNotificationFile",
    "setTotalNotificationFile",
    "iconsBlack",
  ],
  components: {
    DeviceSetupModal,
    Calls,
    SentMessages,
    ReceivedAndSentmessages,
    Files,
    InviteGuest,
    Protocol,
    DirectLinkInvitation,
    CustomTT,
  },
  data() {
  const effector = {
    showCustomTT: hotkeysInfoStore,
  };
  Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
  });
  return {
    state: store.state,
    ownUUID: store.state.ownUUID,
    // Effector
    ...effector,
  }
  },

  mounted() {
    EventBus.$on("focusQuickBarEvent", this.focusFirstElement);
  },
  unmounted(){
    EventBus.$off("focusQuickBarEvent", this.focusFirstElement);
  },
  methods: {
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    showStatus() {
      this.handleStatusClick();
    },
    focusFirstElement() {
      document.getElementById("v-tour-messages").focus();
    },
  },
};
</script>
<style scoped>
.w100 {
  width: 100%;
}
.ctrq {
  z-index: 9;
  left: 14px;
  top: 54px;
}
</style>