<template>
  <v-list max-width="320" @click.stop.prevent>
    <v-list-group
      active-class="listColor"
      v-if="!isGuest && !mini"
      @click="handlerClickSupport($event)"
      :append-icon="null"
      :expand-icon="null"
    >
      <template v-slot:activator>
        <v-list-item class="pointer">
          <template v-slot:prepend>
            <v-icon size="18">
              <font-awesome-icon
                :icon="['fal', 'headset']"
                class="primary--text-sidepanel"
              />
            </v-icon>
          </template>
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
                <!-- class="primary--text-sidepanel" -->
              <v-list-item-title
              class="primary--text-sidepanel"
                @click.stop.prevent="
                  handlerClickSupport();
                "
                v-bind="props"
              >
                {{ $t("generics.support") }}
              </v-list-item-title>
            </template>
            <span>{{ $t("generics.support") }}</span>
          </v-tooltip>
        </v-list-item>
        <!-- <CustomTT
          v-if="showCustomTT"
          :text="`${$t('components.hotkeys.controlKey')} + B`"
          position="bottom"
          :extraclass="'ctrb'"
        /> -->
      </template>
      <!-- <UserList :persons="sortedCoffeeBreakUsers" :section="'coffee-break'" /> -->
    </v-list-group>
    <v-tooltip right v-if="!isGuest && mini">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" class="cursorPointer">
          <v-icon size="18" @click.stop.prevent="handlerClickSupport()">
            <font-awesome-icon
              :icon="['fal', 'headset']"
              class="primary--text-sidepanel"
            />
          </v-icon>
        </v-list-item>
      </template>
      <span>{{ $t("generics.support") }}</span>
    </v-tooltip>
  </v-list>
</template>

<script>
import store from "../../store";
import { setSendBugModalEvent } from "../../effector/modals";

export default {
  props: [
    "mini",
    "isGuest",
    "isMobile",
  ],
  components: {},
  data: () => ({
    state: store.state,
  }),
  mounted() {},
  unmounted() {},
  methods: {
    handlerClickSupport(event) {
      setSendBugModalEvent(true)
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.cursorPointer {
  cursor: pointer;
}
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.textColor {
  color: #008000;
}
.v-theme--dark .listColor {
  color: white !important;
}
.v-theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
.ctrb {
  left: 15px;
}
</style>
