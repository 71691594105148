// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-theme--dark .userNameCard {
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/joinBridgeCallModal.vue","webpack://./joinBridgeCallModal.vue"],"names":[],"mappings":"AAEE;EACE,uBAAA;ACDJ","sourcesContent":["\n.v-theme--dark {\n  .userNameCard {\n    color: white !important;\n  }\n}\n",".v-theme--dark .userNameCard {\n  color: white !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
