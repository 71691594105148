import { logoutModalDomain } from './domain';

import { setlogoutModalEvent, resetlogoutModalEvent} from './events';

export const logoutModalStore = logoutModalDomain.createStore(false, { name: ' logoutModal' });

logoutModalStore.on(setlogoutModalEvent, (state, payload) => {
  return payload;
});

logoutModalStore.reset(resetlogoutModalEvent);
