<template>
  <!-- attach="#remotePersonCallDiv" -->
  <!-- :attach="getAttach" -->
  <v-dialog
    :model-value="showPaymentInProgress" @update:model-value="showPaymentInProgress = $event"
    class="paymentModal"
    persistent
    max-width="570"
    hide-overlay
  >
    <div class="w100">
      <v-card class="mx-auto">
        <!-- Title -->
        <!-- :closeModalFunction="closeModal" -->
        <HeaderModal
          :titleModal="$t('components.paymentModal.titleRequestPayment')"
          :actualDate="actualDate"
          :closeModalFunction="closeModal"
        />
        <!-- End title -->

        <!-- Modal body -->
        <v-card-text class="mt-6 pb-7">
          {{ $t("components.paymentModal.paymentInProgress") }}
        </v-card-text>
        <!-- End modal body -->

        <!-- Footer -->
        <!-- End footer -->
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import store, { EventBus } from "../../../store";
import { isMobile } from "../../../lib/mobileUtil";
// import { paymentInProgressModalStore, setPaymentInProgressModal } from "../../../effector/modals";
import HeaderModal from "../../modal/modalComponents/headerModal.vue";
import moment from "moment";
export default {
  props: [],
  components: { HeaderModal },
  data: () => ({
    state: store.state,
    attachTo: false,
    isMobile: isMobile(),
  }),
  mounted() {
    EventBus.$emit("splitViews");
    this.attachTo = this.state.user.paymentState.paymenInprogress.tileId;
  },
  methods: {
    closeModal(event) {
      event.preventDefault();
      event.stopPropagation();
      this.state.user.paymentState.paymenInprogress = false;
      //setPaymentInProgressModal(false);
    },
  },
  computed: {
    actualDate() {
      return moment(new Date(Date.now())).format("DD.MM.YYYY HH:mm");
    },
    getAttach() {
      if (this.attachTo && !this.isMobile) {
        const element = document.getElementById(this.attachTo);
        // console.log('getAttach ====> ', element)
        if (element) {
          return element;
        }
      }
      return false;
    },
    showPaymentInProgress() {
      return this.state.user.paymentState.paymenInprogress;
    },
  },
};
</script>

<style scoped lang="scss">
.v-theme--light .v-dialog {
  .v-card {
    background: rgba(255, 255, 255, 1);
    word-break: break-word;
  }
}
.v-theme--dark .v-dialog {
  .v-card {
    background: rgba(30, 30, 30, 1);
    word-break: break-word;
  }
}
@media screen and (min-width: 993px) {
  .v-dialog__content {
    // background: #ffffff;
    position: absolute !important;
  }
}
.font15 {
  font-size: 15px;
}
.styleHeader {
  border-bottom: 1px solid var(--v-primary-base) !important;
  margin-bottom: 15px;
  color: var(--v-primary-base) !important;
}
.userStyle {
  padding: 5px;
  width: 90%;
}
.imageLogo {
  display: inline-block;
  vertical-align: middle;
}
</style>
<style lang="scss">
/*
.videBridgeContainer {
  .v-dialog {
    background-color: transparent !important;
  }
}
  */
</style>