import sanitize from './lib/sanitize';
import Vue from '@vue/compat';
import { createApp /*, configureCompat */ } from 'vue';
import { i18n, $locale } from './i18n';
import vuetify from './vuetify';
import router from './router';
import routerAcl from './utils/routerAcl';
import vuescroll from 'vuescroll';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Vue3linkify from "vue-3-linkify";
// import VueCroppie from 'vue-croppie';
// import VueVirtualScroller from 'vue-virtual-scroller';
// import enableInlineVideo from 'iphone-inline-video';
// import InfiniteLoading from 'vue-infinite-loading';
// import VueDragResize from 'vue-drag-resize';
// import VueMask from 'v-mask';
// import linkify from 'vue-linkify';
// import { VueEffector } from 'effector-vue';
// import VueTour from 'vue-tour'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import FontsActuallyUsed from './fontawesome.js';
import VueMain from './vue/main.vue';

// Vue.use(VueTour);
// Vue.use(InfiniteLoading, { /* options */ });
// Vue.use(VueRouter);
// Vue.use(VueCroppie);
// Vue.use(vuescroll);
// Vue.use(VueVirtualScroller);
// Vue.use(VueEffector);
// Vue.component('vue-drag-resize', VueDragResize);
// Vue.use(VueMask);
// Vue.directive('linkified', linkify);

/*
Vue.prototype.$vuescrollConfig = {
  bar: {
    showDelay: 500,
    onlyShowBarOnScroll: false,
    keepShow: true,
    background: '#2a3133',
    opacity: 1,
    specifyBorderRadius: false,
    minSize: 0,
    size: '8px',
    disable: false
  }
};
const ignoredMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';
Vue.config.warnHandler = (message, vm, componentTrace) => {
  if (message !== ignoredMessage) {
    console.error(message + componentTrace);
  }
};
*/

// Opt-in to the new render function API globally
// configureCompat({ RENDER_FUNCTION: false });

// https://github.com/vuejs/vue-hot-reload-api/issues/61
const app = createApp(VueMain)
  .use(i18n)
  .use(routerAcl(router))
  .use(vuetify);

app.use(vuescroll, {
  bar: {
    showDelay: 500,
    onlyShowBarOnScroll: false,
    keepShow: true,
    background: '#2a3133',
    opacity: 1,
    specifyBorderRadius: false,
    minSize: 0,
    size: '8px',
    disable: false
  }
});
app.use(VueTelInput,  {
  mode: 'auto',
});
app.use(Vue3linkify);
app.component('cropper', Cropper);
app.component('font-awesome-icon', FontAwesomeIcon); // Register component globally
library.add(...FontsActuallyUsed); // Include needed icons

app.config.globalProperties.$delete = Vue.delete;
app.config.globalProperties.$set = Vue.set;
app.config.globalProperties.$vuetify = vuetify;
app.config.globalProperties.$locale = $locale;

Vue.prototype.$sanitize = sanitize;
app.config.globalProperties.$sanitize = sanitize;

/*
app.directive('focus', {
  inserted (el) {
    Vue.nextTick(() => el.focus());
  }
});
app.vuetify = vuetify;
*/


let inited = false;
const init = () => {
  if (inited) return;
  inited = true;
  try {
    app.mount('#main_anchor');
  } catch (err) {
    console.error('Init Vue Error:', err);
  }
};

const getInstance = () => {
  if (!inited) init();
  return app;
};

export default {
  app,
  init,
  getInstance
};
