import {
  setCurrentTsEvent,
} from './events';

import { fastClockTick } from './config';

// import {
//   currentTSStore,
// } from './state';

setInterval(() => {
  setCurrentTsEvent(Date.now());
}, fastClockTick);
