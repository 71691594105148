<template>
  <div :style="{height: 'calc(100vh - 80px)' }" class="mx-0" v-if="!hideWaitingRoom">
    <div class="h100" v-if="!isWaitingRoom">
      <v-card height="100%" class="px-0 py-0" flat>
        <v-row class="ml-0">
          <WaitingRoomBackground />
          <v-col class="pt-10 pl-5 col-xl-12 col-lg-12 col-md-12">
            <RegularGridWaitingRoomNew />
          </v-col>
          <div class="text-center" v-if="!selectedConference">
            <div class="divSocialMedia" v-if="hasSocialMedia">
              <v-btn
                class="mr-2"
                icon
                :href="getFacebookLink"
                v-if="getFacebookLink"
                color="#0076FB"
                target="_blank"
                flat
              >
                <font-awesome-icon
                  :icon="['fab', 'facebook']"
                  :style="{ fontSize: '25px' }"
                />
              </v-btn>
              <v-btn
                class="mr-2"
                icon
                :href="getTwitterLink"
                v-if="getTwitterLink"
                color="#1da1f2"
                target="_blank"
                flat
              >
                <font-awesome-icon
                  :icon="['fab', 'twitter']"
                  :style="{ fontSize: '25px' }"
                />
              </v-btn>
              <v-btn
                class="mr-2"
                icon
                :href="getYoutubeLink"
                v-if="getYoutubeLink"
                color="red"
                target="_blank"
                flat
              >
                <font-awesome-icon
                  :icon="['fab', 'youtube']"
                  :style="{ fontSize: '25px' }"
                />
              </v-btn>
              <v-btn
                class="mr-2"
                icon
                :href="getLinkedinLink"
                v-if="getLinkedinLink"
                color="#2962ff"
                target="_blank"
                flat
              >
                <font-awesome-icon
                  :icon="['fab', 'linkedin']"
                  :style="{ fontSize: '25px' }"
                />
              </v-btn>

              <v-btn
                class="mr-2"
                :href="getInstagramLink"
                v-if="getInstagramLink"
                icon
                target="_blank"
                flat
              >
                <svg
                  enable-background="new 0 0 24 24"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <linearGradient
                    id="SVGID_1_"
                    gradientTransform="matrix(0 -1.982 -1.844 0 -132.522 -51.077)"
                    gradientUnits="userSpaceOnUse"
                    x1="-37.106"
                    x2="-26.555"
                    y1="-72.705"
                    y2="-84.047"
                  >
                    <stop offset="0" stop-color="#fd5" />
                    <stop offset=".5" stop-color="#ff543e" />
                    <stop offset="1" stop-color="#c837ab" />
                  </linearGradient>
                  <path
                    d="m1.5 1.633c-1.886 1.959-1.5 4.04-1.5 10.362 0 5.25-.916 10.513 3.878 11.752 1.497.385 14.761.385 16.256-.002 1.996-.515 3.62-2.134 3.842-4.957.031-.394.031-13.185-.001-13.587-.236-3.007-2.087-4.74-4.526-5.091-.559-.081-.671-.105-3.539-.11-10.173.005-12.403-.448-14.41 1.633z"
                    fill="url(#SVGID_1_)"
                  />
                  <path
                    d="m11.998 3.139c-3.631 0-7.079-.323-8.396 3.057-.544 1.396-.465 3.209-.465 5.805 0 2.278-.073 4.419.465 5.804 1.314 3.382 4.79 3.058 8.394 3.058 3.477 0 7.062.362 8.395-3.058.545-1.41.465-3.196.465-5.804 0-3.462.191-5.697-1.488-7.375-1.7-1.7-3.999-1.487-7.374-1.487zm-.794 1.597c7.574-.012 8.538-.854 8.006 10.843-.189 4.137-3.339 3.683-7.211 3.683-7.06 0-7.263-.202-7.263-7.265 0-7.145.56-7.257 6.468-7.263zm5.524 1.471c-.587 0-1.063.476-1.063 1.063s.476 1.063 1.063 1.063 1.063-.476 1.063-1.063-.476-1.063-1.063-1.063zm-4.73 1.243c-2.513 0-4.55 2.038-4.55 4.551s2.037 4.55 4.55 4.55 4.549-2.037 4.549-4.55-2.036-4.551-4.549-4.551zm0 1.597c3.905 0 3.91 5.908 0 5.908-3.904 0-3.91-5.908 0-5.908z"
                    fill="#fff"
                  />
                </svg>
              </v-btn>
              <v-btn
                style="
                  height: 32px;
                  min-width: 32px !important;
                  width: 32px !important;
                "
                :href="getWebLink"
                v-if="getWebLink"
                target="_blank"
                color="primary"
                density="compact"
                flat
              >
                <font-awesome-icon
                  :icon="['fal', 'globe']"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </div>
          </div>
        </v-row>
      </v-card>
    </div>
    <div class="h100" v-if="isWaitingRoom">
      <v-card height="100%" flat>
        <v-row class="mx-0">
          <WaitingRoomBackground class="backGuest" />
          <v-col
            :class="`backGuestBox px-0 py-0  ${
              isMobile
                ? 'col-12 backGuestBoxMobile'
                : 'col-xl-3 col-lg-3 col-md-5 mt-6 mb-6 ml-6'
            }`"
          >
            <v-row>
              <v-col class="col-xl-12 col-lg-12 col-md-12 py-0"> </v-col>
              <!-- <v-col cols="12" class="pt-5 pb-3 px-7 pb-0 py-0">
                <p class="namePersonStyle font-weight-medium mb-3 text-black">
                  {{ titelPerson(getInviterPerson) }}
                  {{ namePerson(getInviterPerson) }}
                  {{
                    $t("components.waitingRoom.userInform", [userConecctedAt])
                  }}
                </p>
                <v-divider></v-divider>
                <p
                  v-if="
                    !isMobile &&
                    getWaitingRoomExpectedTime !== undefined &&
                    getWaitingRoomExpectedTime.length > 0
                  "
                  class="pt-3 mb-0 pb-0 text-black"
                >
                  {{
                    $t("components.waitingRoom.timeExpectedText", [
                      getWaitingRoomExpectedTime,
                    ])
                  }}
                </p>
              </v-col> -->
              <div
                class="divSocialMediaGuestMobile"
                v-if="hasSocialMedia && isMobile"
              >
                <v-btn
                  class="mr-2"
                  :href="getFacebookLink"
                  v-if="getFacebookLink"
                  icon
                  color="#0076FB"
                  target="_blank"
                  flat
                >
                  <font-awesome-icon
                    :icon="['fab', 'facebook']"
                    :style="{ fontSize: '25px' }"
                  />
                </v-btn>
                <v-btn
                  class="mr-2"
                  :href="getTwitterLink"
                  v-if="getTwitterLink"
                  icon
                  color="#1da1f2"
                  target="_blank"
                  flat
                >
                  <font-awesome-icon
                    :icon="['fab', 'twitter']"
                    :style="{ fontSize: '25px' }"
                  />
                </v-btn>
                <v-btn
                  class="mr-2"
                  :href="getYoutubeLink"
                  v-if="getYoutubeLink"
                  icon
                  color="red"
                  target="_blank"
                  flat
                >
                  <font-awesome-icon
                    :icon="['fab', 'youtube']"
                    :style="{ fontSize: '25px' }"
                  />
                </v-btn>
                <v-btn
                  class="mr-2"
                  icon
                  :href="getLinkedinLink"
                  v-if="getLinkedinLink"
                  color="#2962ff"
                  target="_blank"
                  flat
                >
                  <font-awesome-icon
                    :icon="['fab', 'linkedin']"
                    :style="{ fontSize: '25px' }"
                  />
                </v-btn>
                <v-btn
                  class="mr-2"
                  :href="getInstagramLink"
                  v-if="getInstagramLink"
                  icon
                  target="_blank"
                  flat
                >
                  <svg
                    enable-background="new 0 0 24 24"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <linearGradient
                      id="SVGID_1_"
                      gradientTransform="matrix(0 -1.982 -1.844 0 -132.522 -51.077)"
                      gradientUnits="userSpaceOnUse"
                      x1="-37.106"
                      x2="-26.555"
                      y1="-72.705"
                      y2="-84.047"
                    >
                      <stop offset="0" stop-color="#fd5" />
                      <stop offset=".5" stop-color="#ff543e" />
                      <stop offset="1" stop-color="#c837ab" />
                    </linearGradient>
                    <path
                      d="m1.5 1.633c-1.886 1.959-1.5 4.04-1.5 10.362 0 5.25-.916 10.513 3.878 11.752 1.497.385 14.761.385 16.256-.002 1.996-.515 3.62-2.134 3.842-4.957.031-.394.031-13.185-.001-13.587-.236-3.007-2.087-4.74-4.526-5.091-.559-.081-.671-.105-3.539-.11-10.173.005-12.403-.448-14.41 1.633z"
                      fill="url(#SVGID_1_)"
                    />
                    <path
                      d="m11.998 3.139c-3.631 0-7.079-.323-8.396 3.057-.544 1.396-.465 3.209-.465 5.805 0 2.278-.073 4.419.465 5.804 1.314 3.382 4.79 3.058 8.394 3.058 3.477 0 7.062.362 8.395-3.058.545-1.41.465-3.196.465-5.804 0-3.462.191-5.697-1.488-7.375-1.7-1.7-3.999-1.487-7.374-1.487zm-.794 1.597c7.574-.012 8.538-.854 8.006 10.843-.189 4.137-3.339 3.683-7.211 3.683-7.06 0-7.263-.202-7.263-7.265 0-7.145.56-7.257 6.468-7.263zm5.524 1.471c-.587 0-1.063.476-1.063 1.063s.476 1.063 1.063 1.063 1.063-.476 1.063-1.063-.476-1.063-1.063-1.063zm-4.73 1.243c-2.513 0-4.55 2.038-4.55 4.551s2.037 4.55 4.55 4.55 4.549-2.037 4.549-4.55-2.036-4.551-4.549-4.551zm0 1.597c3.905 0 3.91 5.908 0 5.908-3.904 0-3.91-5.908 0-5.908z"
                      fill="#fff"
                    />
                  </svg>
                </v-btn>
                <v-btn
                  style="
                    height: 32px;
                    min-width: 32px !important;
                    width: 32px !important;
                  "
                  :href="getWebLink"
                  v-if="getWebLink"
                  color="primary"
                  target="_blank"
                  density="compact"
                  flat
                >
                  <font-awesome-icon
                    :icon="['fal', 'globe']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </div>
            </v-row>
          </v-col>
          <v-col
            class="col-xl-9 col-lg-9 col-md-7 my-0 py-0 px-2"
            v-if="!isMobile"
          >
            <v-row class="mx-0">
              <v-col cols="12" class="text-center my-0 py-0">
                <div
                  class="divSocialMediaGuest mt-2"
                  v-if="hasSocialMedia && !isMobile"
                >
                  <v-btn
                    class="mr-2"
                    :href="getFacebookLink"
                    v-if="getFacebookLink"
                    icon
                    variant="text"
                    color="#0076FB"
                    target="_blank"
                    flat
                  >
                    <font-awesome-icon
                      :icon="['fab', 'facebook']"
                      :style="{ fontSize: '25px' }"
                    />
                  </v-btn>
                  <v-btn
                    class="mr-2"
                    :href="getTwitterLink"
                    v-if="getTwitterLink"
                    icon
                    color="#1da1f2"
                    variant="text"
                    target="_blank"
                    flat
                  >
                    <font-awesome-icon
                      :icon="['fab', 'twitter']"
                      :style="{ fontSize: '25px' }"
                    />
                  </v-btn>
                  <v-btn
                    class="mr-2"
                    :href="getYoutubeLink"
                    v-if="getYoutubeLink"
                    icon
                    color="red"
                    target="_blank"
                    variant="text"
                    flat
                  >
                    <font-awesome-icon
                      :icon="['fab', 'youtube']"
                      :style="{ fontSize: '25px' }"
                    />
                  </v-btn>
                  <v-btn
                    class="mr-2"
                    icon
                    :href="getLinkedinLink"
                    v-if="getLinkedinLink"
                    color="#2962ff"
                    target="_blank"
                    variant="text"
                    flat
                  >
                    <font-awesome-icon
                      :icon="['fab', 'linkedin']"
                      :style="{ fontSize: '25px' }"
                    />
                  </v-btn>
                  <v-btn
                    class="mr-2"
                    :href="getInstagramLink"
                    v-if="getInstagramLink"
                    icon
                    target="_blank"
                    variant="text"
                    flat
                  >
                    <svg
                      enable-background="new 0 0 24 24"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <linearGradient
                        id="SVGID_1_"
                        gradientTransform="matrix(0 -1.982 -1.844 0 -132.522 -51.077)"
                        gradientUnits="userSpaceOnUse"
                        x1="-37.106"
                        x2="-26.555"
                        y1="-72.705"
                        y2="-84.047"
                      >
                        <stop offset="0" stop-color="#fd5" />
                        <stop offset=".5" stop-color="#ff543e" />
                        <stop offset="1" stop-color="#c837ab" />
                      </linearGradient>
                      <path
                        d="m1.5 1.633c-1.886 1.959-1.5 4.04-1.5 10.362 0 5.25-.916 10.513 3.878 11.752 1.497.385 14.761.385 16.256-.002 1.996-.515 3.62-2.134 3.842-4.957.031-.394.031-13.185-.001-13.587-.236-3.007-2.087-4.74-4.526-5.091-.559-.081-.671-.105-3.539-.11-10.173.005-12.403-.448-14.41 1.633z"
                        fill="url(#SVGID_1_)"
                      />
                      <path
                        d="m11.998 3.139c-3.631 0-7.079-.323-8.396 3.057-.544 1.396-.465 3.209-.465 5.805 0 2.278-.073 4.419.465 5.804 1.314 3.382 4.79 3.058 8.394 3.058 3.477 0 7.062.362 8.395-3.058.545-1.41.465-3.196.465-5.804 0-3.462.191-5.697-1.488-7.375-1.7-1.7-3.999-1.487-7.374-1.487zm-.794 1.597c7.574-.012 8.538-.854 8.006 10.843-.189 4.137-3.339 3.683-7.211 3.683-7.06 0-7.263-.202-7.263-7.265 0-7.145.56-7.257 6.468-7.263zm5.524 1.471c-.587 0-1.063.476-1.063 1.063s.476 1.063 1.063 1.063 1.063-.476 1.063-1.063-.476-1.063-1.063-1.063zm-4.73 1.243c-2.513 0-4.55 2.038-4.55 4.551s2.037 4.55 4.55 4.55 4.549-2.037 4.549-4.55-2.036-4.551-4.549-4.551zm0 1.597c3.905 0 3.91 5.908 0 5.908-3.904 0-3.91-5.908 0-5.908z"
                        fill="#fff"
                      />
                    </svg>
                  </v-btn>
                  <v-btn
                    style="
                      height: 32px;
                      min-width: 32px !important;
                      width: 32px !important;
                    "
                    :href="getWebLink"
                    v-if="getWebLink"
                    color="primary"
                    target="_blank"
                    density="compact"
                    flat
                  >
                    <font-awesome-icon
                      :icon="['fal', 'globe']"
                      :style="{ fontSize: '20px' }"
                    />
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <!-- </div> -->
      <div class="phoneContainer">
        <v-btn
            x-large
            variant="text"
            @click="logoutRedIcon"
          >
            <font-awesome-icon
              :icon="['fas', 'phone']"
              :style="{ fontSize: '60px', color: 'red', transform: 'rotate(225deg)' }"
            />
          </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "../../../../sharedsrc/moment";
import store, { EventBus } from "../../../store";
import { wsCallStartBridgeCall } from "../../../utils/calls";
import { isMobile } from "../../../lib/mobileUtil";
import RegularGridWaitingRoomNew from "./regularGridWaitingRoomNew.vue";
import GuestTable from "./guestTable.vue";
import TemplateUserVisitorWaitingRoomNew from "./templateUserVisitorWaitingRoomNew.vue";
import MessagesWaitingRoom from "./messagesWaitingRoom.vue";
import WaitingRoomBackground from "./waitingRoomBackground.vue";
import WaitingRoomBackgroundPDF from "./waitingRoomBackgroundPDF.vue";
import { setlogoutModalEvent } from "../../../effector/modals";
// import { v4 as uuidv4 } from "uuid";
import { uid } from "../../../utils/basicFunctions";

export default {
  components: {
    RegularGridWaitingRoomNew,
    GuestTable,
    TemplateUserVisitorWaitingRoomNew,
    MessagesWaitingRoom,
    WaitingRoomBackground,
    WaitingRoomBackgroundPDF,
  },
  data() {
    return {
      state: store.state,
      setCurrentContentVisile: store.setCurrentContentVisile,
      tab: null,
      itemsPerPage: -1,
      isWaitingRoom: false,
      selectedConference: false,
      conferenceObject: {},
      isMobile: isMobile(),
      userConecctedAt: "",
      moment: moment,
      hideWaitingRoom: false,
    };
  },
  mounted() {
    EventBus.$on("selectedConference", this.selectedConferenceEvent);
    this.presenceHours();
    setTimeout(() => {
      if (store.state.user && store.state.user.visitorData) {
        const isBeaconCallInvite = this.isBeaconInvite;
        const inviter =
          (store.state.user.visitorData || {}).userInviter || false;
        if (isBeaconCallInvite && inviter) {
          if (this.canCallUserDirectly(inviter)) {
            this.callInviter(inviter);
          }
          if (
            this.state.group[inviter].user.userSettings.beaconCall ==
            "directCall"
          ) {
            this.hideWaitingRoom = true;
          }
          if (this.canCallUserInCall(inviter)) {
            this.hideWaitingRoom = false;
          }
        }
      }
    }, 1000);
  },
  created() {
    if (store.state.user && store.state.user.visitorData) {
      this.isWaitingRoom = true;
    }
  },
  destroyed() {
    EventBus.$off("selectedConference", this.selectedConferenceEvent);
  },
  watch: {
    /*
    "state.user.inBridgeCall": {
      deep: true,
      handler: function (newValue, oldValue) {
        if (oldValue && !newValue && this.isBeaconInvite) {
          window.location.href = "https://www.voffice.pro";
        }
      },
    },
    */
  },
  methods: {
    logoutRedIcon() {
      window.location.href = '/api/waitingroom/leave';
    },
    logout() {
      setlogoutModalEvent(true);
    },
    selectedConferenceEvent(params) {
      this.selectedConference = params.selectedConference == "" ? false : true;
      this.conferenceObject = params.conferenceObject;
    },
    canCallUserDirectly(inviter) {
      return (
        this.state.group[inviter] &&
        this.state.group[inviter].connected &&
        !this.state.group[inviter].user.inBridgeCall &&
        this.state.group[inviter].user.userSettings.enabledBeaconUserSetting &&
        this.state.group[inviter].user.userSettings.beaconCall &&
        this.state.group[inviter].user.userSettings.beaconCall == "directCall"
      );
    },
    canCallUserInCall(inviter) {
      return (
        this.state.group[inviter] &&
        this.state.group[inviter].connected &&
        this.state.group[inviter].user.inBridgeCall &&
        this.state.group[inviter].user.userSettings.enabledBeaconUserSetting &&
        this.state.group[inviter].user.userSettings.beaconInCall &&
        this.state.group[inviter].user.userSettings.beaconInCall == "active"
      );
    },
    callInviter(inviter) {
      const id = uid();
      const callDuration =
        store.state.namespaceSettings.defaultMeetingDuration.value.toString();
      const info = {
        callUUID: id,
        calling: [inviter],
        isNormalMode: true,
        initiator: this.state.ownUUID,
        callStartTs: Date.now(),
        callDurationMs: parseInt(callDuration) || 60000 * 20,
        infiniteCall: false,
        isAudioOnly: false,
      };
      const interval = setInterval(() => {
        if (store.state.remoteBridgeStreams && store.state.remoteBridgeStreams[id] && store.state.remoteBridgeStreams[id].roomId) {
          clearInterval(interval);
          const infoStartBridgeCall = {
            callUUID: id,
            isNormalMode: true,
            initiator: this.state.ownUUID,
            callStartTs: Date.now(),
            callDurationMs: parseInt(callDuration) || 60000 * 20,
            infiniteCall: false,
            isAudioOnly: false,
            roomId: store.state.remoteBridgeStreams[id].roomId,
            roomGuid: store.state.remoteBridgeStreams[id].roomGuid,
          };
          wsCallStartBridgeCall(inviter, this.state.ownUUID, infoStartBridgeCall);
        } else if (!store.state.remoteBridgeStreams[id]) {
          clearInterval(interval);
        }
      }, 200);
      store.addRemoteBridgeStream(id, info);
      this.setCurrentContentVisile("", false, this.$router);
    },
    presenceHours() {
      this.userConecctedAt = this.moment(new Date()).format("H:mm");
    },
    namePerson(person) {
      if (person && person.user.name) {
        return person.user.name;
      }
    },
    titelPerson(person) {
      if (person.user) {
        return store.getTitelForUuid(person.user.uuid);
      }
      return "";
    },
  },
  computed: {
    isBeaconInvite() {
      return store.state.group[store.state.ownUUID].beaconCallInvite;
    },
    getWaitingRoomExpectedTime() {
      return store.state.namespaceSettings.waitingRoomExpectedTime;
    },
    getFacebookLink() {
      return store.state.namespaceSettings.waitingRoomSocialMedia.facebookLink;
    },
    getInstagramLink() {
      return store.state.namespaceSettings.waitingRoomSocialMedia.instagramLink;
    },
    getYoutubeLink() {
      return store.state.namespaceSettings.waitingRoomSocialMedia.youtubeLink;
    },
    getTwitterLink() {
      return store.state.namespaceSettings.waitingRoomSocialMedia.twitterLink;
    },
    getLinkedinLink() {
      return store.state.namespaceSettings.waitingRoomSocialMedia.linkedinLink;
    },
    getWebLink() {
      return store.state.namespaceSettings.waitingRoomSocialMedia.webLink;
    },
    hasSocialMedia() {
      if (
        this.getWebLink ||
        this.getTwitterLink ||
        this.getYoutubeLink ||
        this.getInstagramLink ||
        this.getFacebookLink ||
        this.getLinkedinLink
      ) {
        return true;
      }
      return false;
    },
    getInviterPerson() {
      const myInviter = this.state.user.visitorData.userInviter;
      if (store.state.group[myInviter]) {
        return store.state.group[myInviter];
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.phoneContainer{
  position: absolute;
  bottom: 0;
  right: 2%;
}
.namePersonStyle {
  max-width: 300px;
  overflow: hidden;
}
.backGuestBoxMobile {
  margin: 50px auto;
}
.backGuestBox {
  background-color: white;
  z-index: 2;
  max-width: 300px;
  border-radius: 4px;
}

@media (max-width: 1082px) {
  .backGuestBox {
    width: 100%;
  }
}
@media (min-width: 1082px) {
  .backGuestBox {
    width: 100%;
  }
}
.divSocialMedia {
  display: inline;
  float: right;
  background-color: transparent;
  border-radius: 4px;
  position: absolute;
  padding: 15px 20px;
  right: 10px;
}
.divSocialMediaGuest {
  display: inline;
  float: right;
  background-color: transparent;
  border-radius: 4px;
  position: absolute;
  // z-index: 99;
  padding: 15px 20px;
  right: 10px;
}
.divSocialMediaGuestMobile {
  background-color: transparent;
  border-radius: 4px;
  position: absolute;
  // z-index: 99;
  padding: 15px 20px;
  right: 17px;
  top: -7px;
}
</style>