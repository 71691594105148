import { combine } from 'effector';
import { representativeDomain } from './domain';
import { setRepresentedUuidEvent, resetRepresentedUuidEvent } from './events';
import { ownUserUUIDState, allUsersState } from '../users';
import { persistedStateStore } from '../persisted';

// Selected UUID to represent (if any)
export const uuidToRepresentStore = representativeDomain.createStore(null, { name: 'uuidToRepresent' });

uuidToRepresentStore.on(setRepresentedUuidEvent, (state, payload) => { return payload; });
uuidToRepresentStore.reset(resetRepresentedUuidEvent);

// Acting As UUID
const actingAsUuidCombine = combine(ownUserUUIDState, uuidToRepresentStore, (ownUUID, uuidToRepresent) => {
  // console.log('actingAsUuidCombine', ownUUID, uuidToRepresent);
  return uuidToRepresent ? uuidToRepresent : ownUUID;
});

export const actingAsUuidStore = representativeDomain.createStore(null, { name: 'actingAsUuid' });
actingAsUuidStore.on(actingAsUuidCombine, (state, payload) => { return payload; });

// Represented Users
const representedUsersFromPersisted = persistedStateStore.map((state) => { return state.representedUsers || { active: false, users: [] }; });

export const representedUsersStore = representativeDomain.createStore(null, { name: 'representedUsers' });
representedUsersStore.on(representedUsersFromPersisted, (state, payload) => { return payload.users.filter(s => typeof s === 'string'); });

export const representedUsersStateCombine = combine(representedUsersStore, allUsersState, (representedUsers, allUsers) => {
  if (!representedUsers || !allUsers) return {};
  const representedUsersRes = {};
  for (const uuid of representedUsers) {
    representedUsersRes[uuid] = { state: allUsers[uuid], invitees: {} };
  }

  const userUUIDS = Object.keys(allUsers);
  for (const uuid of userUUIDS) {
    const state = allUsers[uuid];
    if (state && state.connected && state.user && state.user.visitorData && state.user.visitorData.isWaitingRoom && representedUsers.indexOf(state.user.visitorData.userInviter) !== -1) {
      const invitedBy = state.user.visitorData.userInviter;
      representedUsersRes[invitedBy].invitees[uuid] = state;
    }
  }

  return representedUsersRes;
});

// representedUsersStateCombine.watch((state) => {
//   console.log('representedUsersStateCombine', state);
// });
