import { addEditOrganigramModalDomain } from './domain';

import { setAddEditOrganigramModalEvent, resetAddEditOrganigramModalEvent} from './events';

export const addEditOrganigramModalStore = addEditOrganigramModalDomain.createStore(false, { name: 'addEditOrganigramModal' });

addEditOrganigramModalStore.on(setAddEditOrganigramModalEvent, (state, payload) => {
  return payload;
});

addEditOrganigramModalStore.reset(resetAddEditOrganigramModalEvent);
