
import {
  newCompanyDocumentEvent,
  updateCompanyDocumentEvent,
  dispatchFetchMyCompanyDocumentsEvent,
  dispatchDeleteCompanyDocumentEvent,
  dispatchFetchCompanyDocumentInfosEvent,
  setCompanyDocumentsInfoBatchedEvent,
  setCompanyDocumentUUIDsEvent,
  getMyCompanyDocumentsDetailsEvent,
  setCompanyDocumentDetailEvent,
  
} from './events';

import {
  fetchMyCompanyDocumentsEffect,
  getCompanyDocumentEntryByUUIDEffect,
  createCompanyDocumentEntryEffect,
  updateCompanyDocumentEffect,
  deleteCompanyDocumentEntryEffect,
  getMyCompanyDocumentEntriesDetailEffect
} from './effects';

import { parallel } from '../../lib/asyncUtil';
import { wsConnectedEvent } from '../ws';

// TODO: Remove from here put in the actual compoinent for messages
wsConnectedEvent.watch(() => {
  dispatchFetchMyCompanyDocumentsEvent();
  getMyCompanyDocumentsDetailsEvent();
});

dispatchFetchMyCompanyDocumentsEvent.watch((payload) => {
  fetchMyCompanyDocumentsEffect();
});

fetchMyCompanyDocumentsEffect.done.watch(({ params, result }) => {
  setCompanyDocumentUUIDsEvent(result);
});

getMyCompanyDocumentsDetailsEvent.watch((payload) => {
  getMyCompanyDocumentEntriesDetailEffect();
});

getMyCompanyDocumentEntriesDetailEffect.done.watch(({ params, result }) => {
  setCompanyDocumentDetailEvent(result);
});

newCompanyDocumentEvent.watch((payload) => {
  createCompanyDocumentEntryEffect(payload);
});

updateCompanyDocumentEvent.watch((payload) => {
  updateCompanyDocumentEffect(payload);  
});

updateCompanyDocumentEffect.done.watch(({ params, result }) => {
  fetchMyCompanyDocumentsEffect();
});

dispatchFetchCompanyDocumentInfosEvent.watch(async (confIds) => {
  if (!confIds.length) return; // Nope
  if (getCompanyDocumentEntryByUUIDEffect.pending.getState()) {
    // No need to fetch while fetching, new fetched things will trigger an update which will then fetch whatever new thing could be missing
    return;
  }
  const acc = {};
  await parallel(10, [...confIds], async (confId) => {
    const info = await getCompanyDocumentEntryByUUIDEffect(confId);
    if (typeof info !== 'undefined' && info !== null) {
      acc[confId] = info;
    }
  });
  const notEmpty = Object.keys(acc).length;
  // console.log('dispatchFetchCompanyDocumentInfosEvent Fetched', notEmpty, acc);
  if (notEmpty) setCompanyDocumentsInfoBatchedEvent(acc);
});

dispatchDeleteCompanyDocumentEvent.watch((confId) => {
  const params = [confId];
  deleteCompanyDocumentEntryEffect(params);
});
