import { alertsArrayStore } from './store';
import {
  // addAlertEvent,
  delAlertEvent,
  clearAlertsEvent,

  dispatchSuccessAlert,
  dispatchInfoAlert,
  dispatchWarningAlert,
  dispatchErrorAlert,
} from './events';

export {
  // Stores
  alertsArrayStore,

  // Events
  // addAlertEvent,
  delAlertEvent,
  clearAlertsEvent,

  dispatchSuccessAlert,
  dispatchInfoAlert,
  dispatchWarningAlert,
  dispatchErrorAlert,
};
