// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userContainer[data-v-7eb831a7] {
  max-height: 235px;
  overflow: auto;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/dailyReportSettingsModal.vue","webpack://./dailyReportSettingsModal.vue"],"names":[],"mappings":"AACA;EACE,iBAAA;EACA,cAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;ACAF","sourcesContent":["\n.userContainer {\n  max-height: 235px;\n  overflow: auto;\n  width: 100%;\n  min-width: 100%;\n  max-width: 100%;\n}\n",".userContainer {\n  max-height: 235px;\n  overflow: auto;\n  width: 100%;\n  min-width: 100%;\n  max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
