<template>
    <p style="margin-left: 300px; margin-top: 303px; width: 100%;">TDB VISITORS CONTENT TO SHOW</p>
  <!-- <GorupsCardGrid :typeData="'groups'" :data="groups" /> -->
</template>

<script>
import store from "../../store";
import GorupsCardGrid from "./groupsCardGrid.vue";
export default {
  components: { GorupsCardGrid },
  data() {
    return {
      state: store.state,
    };
  },
  methods: {},
  computed: {
    groups () {
      return store.state.user.userGroups;
    }
  },
  mounted: function() {}
};
</script>

<style scoped lang="scss">
</style>