import { qualityVotingModalDomain } from './domain';

import { setQualityVotingModalEvent, resetQualityVotingModalEvent, setBadQualityMessageModalEvent, resetBadQualityMessageModalEvent, setRateMessageModalEvent, resetRateMessageModalEvent } from './events';

export const qualityVotingModalStore = qualityVotingModalDomain.createStore(false, { name: 'qualityVotingModal' });
export const badQualityMessageModalStore = qualityVotingModalDomain.createStore(false, { name: 'badQualityMessageModal' });
export const rateMessageModalStore = qualityVotingModalDomain.createStore(false, { name: 'rateMessageModal' });

qualityVotingModalStore.on(setQualityVotingModalEvent, (state, payload) => {
  return payload;
});

qualityVotingModalStore.reset(resetQualityVotingModalEvent);

badQualityMessageModalStore.on(setBadQualityMessageModalEvent, (state, payload) => {
  return payload;
});

badQualityMessageModalStore.reset(resetBadQualityMessageModalEvent);

rateMessageModalStore.on(setRateMessageModalEvent, (state, payload) => {
  return payload;
});
rateMessageModalStore.reset(resetRateMessageModalEvent);
// qualityVotingModalStore.watch((state) => { console.log('qualityVotingModalStore', state); });
