// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-application .ml-0 {
  margin-left: 0 !important;
}
.v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 48px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
}
.v-list-item__content {
  flex-wrap: nowrap;
}
.v-list-item-action {
  align-items: flex-end !important;
  align-self: stretch !important;
  justify-content: space-between;
  white-space: nowrap;
  flex-direction: column;
}
.v-list-item__action, .v-list-item__avatar, .v-list-item__icon {
  display: inline-flex;
  min-width: 24px;
}
v-list-item-action-text {
  font-size: 0.75rem;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/notificationsWaitingRoomDropDownEffector.vue","webpack://./notificationsWaitingRoomDropDownEffector.vue"],"names":[],"mappings":"AACA;EACI,yBAAA;ACAJ;ADEA;EACI,mBAAA;EACA,aAAA;EACA,cAAA;EACA,sBAAA;EACA,gBAAA;EACA,aAAA;EACA,eAAA;EACA,kBAAA;EACA,qBAAA;ACCJ;ADCA;EACE,iBAAA;ACEF;ADAA;EACI,gCAAA;EACA,8BAAA;EACA,8BAAA;EACA,mBAAA;EACA,sBAAA;ACGJ;ADDA;EACI,oBAAA;EACA,eAAA;ACIJ;ADFA;EACI,kBAAA;ACKJ","sourcesContent":["\n.v-application .ml-0 {\n    margin-left: 0 !important;\n}\n.v-list-item {\n    align-items: center;\n    display: flex;\n    flex: 1 1 100%;\n    letter-spacing: normal;\n    min-height: 48px;\n    outline: none;\n    padding: 0 16px;\n    position: relative;\n    text-decoration: none;\n}\n.v-list-item__content{\n  flex-wrap: nowrap;\n}\n.v-list-item-action {\n    align-items: flex-end !important;\n    align-self: stretch !important;\n    justify-content: space-between;\n    white-space: nowrap;\n    flex-direction: column;\n}\n.v-list-item__action, .v-list-item__avatar, .v-list-item__icon {\n    display: inline-flex;\n    min-width: 24px;\n}\nv-list-item-action-text {\n    font-size: .75rem ;\n}\n",".v-application .ml-0 {\n  margin-left: 0 !important;\n}\n\n.v-list-item {\n  align-items: center;\n  display: flex;\n  flex: 1 1 100%;\n  letter-spacing: normal;\n  min-height: 48px;\n  outline: none;\n  padding: 0 16px;\n  position: relative;\n  text-decoration: none;\n}\n\n.v-list-item__content {\n  flex-wrap: nowrap;\n}\n\n.v-list-item-action {\n  align-items: flex-end !important;\n  align-self: stretch !important;\n  justify-content: space-between;\n  white-space: nowrap;\n  flex-direction: column;\n}\n\n.v-list-item__action, .v-list-item__avatar, .v-list-item__icon {\n  display: inline-flex;\n  min-width: 24px;\n}\n\nv-list-item-action-text {\n  font-size: 0.75rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
