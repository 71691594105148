import { conferenceDomain } from './domain';

import { getAllConferences, getMyConferences, createConferenceEntry, clearAllConferences, deleteConference, deleteGroupFromUser, editConferenceMembers } from '../../lib/wsMsg';

export const fetchAllConferencesEffect = conferenceDomain.createEffect({
  handler: getAllConferences,
  name: 'fetchAllConferencesEffect',
});

export const fetchtMyConferencesEffect = conferenceDomain.createEffect({
  handler: getMyConferences,
  name: 'fetchtMyConferencesEffect',
});

export const createConferenceEntryEffect = conferenceDomain.createEffect({
  handler: createConferenceEntry,
  name: 'createConferenceEntryEffect',
});

export const deleteConferenceEffect = conferenceDomain.createEffect({
  handler: deleteConference,
  name: 'deleteConferenceEffect',
});

export const deleteGroupFromUserEffect = conferenceDomain.createEffect({
  handler: deleteGroupFromUser,
  name: 'deleteGroupFromUserEffect',
});

export const clearAllConferencesEffect = conferenceDomain.createEffect({
  handler: clearAllConferences,
  name: 'clearAllConferencesEffect',
});

export const updateConferenceEffect = conferenceDomain.createEffect({
  handler: editConferenceMembers,
  name: 'updateConferenceEffect',
});
