// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badgetWithData {
  padding: 0 !important;
}
.v-badge__badge {
  font-weight: 300 !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/userActionBar/receivedAndSentmessages.vue","webpack://./receivedAndSentmessages.vue"],"names":[],"mappings":"AACA;EACE,qBAAA;ACAF;ADEA;EACE,2BAAA;ACCF","sourcesContent":["\n.badgetWithData{\n  padding: 0 !important;\n}\n.v-badge__badge{\n  font-weight: 300 !important;\n}\n",".badgetWithData {\n  padding: 0 !important;\n}\n\n.v-badge__badge {\n  font-weight: 300 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
