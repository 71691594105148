// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selactive[data-v-1fbb7ff0] {
  background-color: rgba(193, 193, 193, 0.4);
}
.w-100[data-v-1fbb7ff0] {
  width: 100%;
}
.userNameCard[data-v-1fbb7ff0] {
  margin-bottom: 0px;
  font-size: 19px;
  color: #2a3133;
}
.dataUserCard[data-v-1fbb7ff0] {
  margin-bottom: 0px;
}
.borderRadius10[data-v-1fbb7ff0],
.v-avatar .v-image[data-v-1fbb7ff0] {
  border-radius: 10px !important;
}
.w-200[data-v-1fbb7ff0] {
  width: 200px;
}
.w-100-230[data-v-1fbb7ff0] {
  width: calc(100% - 230px);
}
.footerModal[data-v-1fbb7ff0] {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
}
.footerModal .btns[data-v-1fbb7ff0] {
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/addToGroupModal.vue","webpack://./addToGroupModal.vue"],"names":[],"mappings":"AACA;EACE,0CAAA;ACAF;ADEA;EACE,WAAA;ACCF;ADCA;EACE,kBAAA;EACA,eAAA;EACA,cAAA;ACEF;ADAA;EACE,kBAAA;ACGF;ADDA;;EAEE,8BAAA;ACIF;ADFA;EACE,YAAA;ACKF;ADHA;EACE,yBAAA;ACMF;ADJA;EACE,WAAA;EACA,6BAAA;EACA,uBAAA;ACOF;ADNE;EACE,yBAAA;ACQJ","sourcesContent":["\n.selactive {\n  background-color: #c1c1c166;\n}\n.w-100 {\n  width: 100%;\n}\n.userNameCard {\n  margin-bottom: 0px;\n  font-size: 19px;\n  color: #2a3133;\n}\n.dataUserCard {\n  margin-bottom: 0px;\n}\n.borderRadius10,\n.v-avatar .v-image {\n  border-radius: 10px !important;\n}\n.w-200 {\n  width: 200px;\n}\n.w-100-230 {\n  width: calc(100% - 230px);\n}\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n  .btns {\n    justify-content: flex-end;\n  }\n}\n",".selactive {\n  background-color: rgba(193, 193, 193, 0.4);\n}\n\n.w-100 {\n  width: 100%;\n}\n\n.userNameCard {\n  margin-bottom: 0px;\n  font-size: 19px;\n  color: #2a3133;\n}\n\n.dataUserCard {\n  margin-bottom: 0px;\n}\n\n.borderRadius10,\n.v-avatar .v-image {\n  border-radius: 10px !important;\n}\n\n.w-200 {\n  width: 200px;\n}\n\n.w-100-230 {\n  width: calc(100% - 230px);\n}\n\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n}\n.footerModal .btns {\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
