<template>
  <v-app id="sandbox">
    <PayPal :isFromRegister="false" :daysLeft="0"/>
  </v-app>
</template>
<script>
// import store from "../../store";
import PayPal from "../firstSetup/paypal.vue"
export default {
  components: {PayPal} ,
  data() {
    return {};
  },
  methods: {},
  computed: {}
};
</script>

<style scoped lang="scss">

</style>