import { hotkeysInfoDomain } from './domain';
import {setHotkeysInfoEvent, resetHotkeysInfoEvent} from './events';

export const hotkeysInfoStore = hotkeysInfoDomain.createStore(false, { name: 'hotkeysInfo' });

hotkeysInfoStore.on(setHotkeysInfoEvent, (state, payload) => {
  return payload;
});

hotkeysInfoStore.reset(resetHotkeysInfoEvent);
