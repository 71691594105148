<template>
  <v-dialog
    persistent
     :model-value="fullCall"
    @update:model-value="fullCall= $event"
    max-width="560"
    @keydown.esc="closeModalAdvice"
  >
    <v-card>
      <HeaderModal
        :titleModal="
          $t('components.fullCallModal.conferenceCallLimitedTo5People')
        "
        :closeModalFunction="closeModalAdvice"
      />
      <v-divider></v-divider>
      <v-row flex :style="{ width: '100%' }" class="bodyContainer">
        <v-col lg="6" class="px-0 py-0 mx-auto text-center">
          <v-card-text
            >You have reached the limit of users in a conference
            call.</v-card-text
          >
        </v-col>
      </v-row>
      <FooterModal :closeModalFunction="closeModalAdvice" :showClose="true" />
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  props: ["fullCall"],
  data: () => ({
    state: store.state,
  }),
  methods: {
    closeModalAdvice() {
      store.setCallFull(false);
    },
  },
};
</script>

<style scoped lang="scss">
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
.bodyContainer {
  margin: 40px auto;
}
</style>