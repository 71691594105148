// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableSearch input[data-v-1c28a8c7] {
  color: black !important;
}
.profile[data-v-1c28a8c7] {
  border-radius: 4px;
}
.tableMenu[data-v-1c28a8c7] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--v-primary-color);
  border: 1px solid var(--v-primary-color);
  border-bottom: 0;
  width: calc(100% - 1px);
}
.tableMenu .v-btn[data-v-1c28a8c7],
.tableMenu span[data-v-1c28a8c7] {
  color: #ffffff !important;
}
.v-theme--dark.v-data-table tbody tr td[data-v-1c28a8c7] {
  color: white !important;
}
.v-theme--light.v-data-table thead tr th[data-v-1c28a8c7] {
  color: black !important;
}
.v-theme--dark.v-data-table thead tr th[data-v-1c28a8c7] {
  color: white !important;
}
.cursorPointer[data-v-1c28a8c7] {
  cursor: pointer;
}
tr.v-data-table__selected[data-v-1c28a8c7] {
  background: #044b41 !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/userManagement/userManagementTable.vue","webpack://./userManagementTable.vue"],"names":[],"mappings":"AAEE;EACE,uBAAA;ACDJ;ADIA;EACE,kBAAA;ACDF;ADGA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,wCAAA;EACA,wCAAA;EACA,gBAAA;EACA,uBAAA;ACAF;ADEE;;EAEE,yBAAA;ACAJ;ADGA;EACE,uBAAA;ACAF;ADEA;EACE,uBAAA;ACCF;ADCA;EACE,uBAAA;ACEF;ADAA;EACE,eAAA;ACGF;ADDA;EACE,8BAAA;ACIF","sourcesContent":["\n.tableSearch {\n  input {\n    color: black !important;\n  }\n}\n.profile {\n  border-radius: 4px;\n}\n.tableMenu {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: var(--v-primary-color);\n  border: 1px solid var(--v-primary-color);\n  border-bottom: 0;\n  width: calc(100% - 1px);\n\n  .v-btn,\n  span {\n    color: #ffffff !important;\n  }\n}\n.v-theme--dark.v-data-table tbody tr td {\n  color: white !important;\n}\n.v-theme--light.v-data-table thead tr th {\n  color: black !important;\n}\n.v-theme--dark.v-data-table thead tr th {\n  color: white !important;\n}\n.cursorPointer {\n  cursor: pointer;\n}\ntr.v-data-table__selected {\n  background: #044b41 !important;\n}\n",".tableSearch input {\n  color: black !important;\n}\n\n.profile {\n  border-radius: 4px;\n}\n\n.tableMenu {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: var(--v-primary-color);\n  border: 1px solid var(--v-primary-color);\n  border-bottom: 0;\n  width: calc(100% - 1px);\n}\n.tableMenu .v-btn,\n.tableMenu span {\n  color: #ffffff !important;\n}\n\n.v-theme--dark.v-data-table tbody tr td {\n  color: white !important;\n}\n\n.v-theme--light.v-data-table thead tr th {\n  color: black !important;\n}\n\n.v-theme--dark.v-data-table thead tr th {\n  color: white !important;\n}\n\n.cursorPointer {\n  cursor: pointer;\n}\n\ntr.v-data-table__selected {\n  background: #044b41 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
