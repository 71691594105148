<template>
  <v-dialog v-if="showModal"  :model-value="showModal"
    @update:model-value="showModal= $event" persistent max-width="560">
      <v-card>
        <HeaderModal :titleModal="$t('components.companyRegister.regPaypal.headline')" :closeModalFunction="closeModal" />
        <PayPal :isFromRegister="false" :daysLeft="daysLeft" :closeModal="closeModal" :isFromModal="true"/>
        <FooterModal :closeModalFunction="closeModal" :showClose="true" />
        <v-spacer></v-spacer>
      </v-card>
  </v-dialog>
</template>

<script>
import { webLicensedBaseFeatures } from "../../../sharedsrc/licensedFeatures";
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import PayPal from "../firstSetup/paypal.vue";
import moment from "../../../sharedsrc/moment";
export default {
  components:{
    HeaderModal,
    FooterModal,
    PayPal
  },
  data() {
    return {
      state: store.state,
      showAlmostExpired: false,
      daysLeft: 3,
      moment: moment
    };
  },
  watch: { },
  mounted() { },
  methods: {
    closeModal() {
      this.showAlmostExpired = false;
      const shownDate = this.moment(new Date()).format('DD.MM.YYYY');
      store.setAlmostExpired(shownDate);
    }
  },
  computed: {
    hasBeenShown(){
      return this.state.user.almostExpiredWarned === this.moment(new Date()).format('DD.MM.YYYY')
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
    isRaMicro() {
      return (
        webLicensedBaseFeatures.isRaMicro ||
        webLicensedBaseFeatures.isRaMicroCustomer
      );
    },
    showModal() {
      const subscriptionTs = this.moment(this.state.namespaceSettings.creatorInfo.ts);
      // == Start: Skip license almost expired modal if domain setup before PayPal introduced
      const skipCheckTsBefore = new Date('2021-03-11T17:00:00Z');
      if (subscriptionTs.toDate() < skipCheckTsBefore) return false;
      if (this.isRaMicro) return false; // Skip license almost expired for lawyer customers
      if (process.env.PKG) return false; // Override to false if packaged version
      // == End
      const actualDate = this.moment(Date.now());
      this.daysLeft = Math.abs(this.moment(actualDate).diff(this.moment(subscriptionTs), 'days') - 30);
      if ( this.amIAdmin && !this.state.namespaceSettings.companyInfo.subscriptionID && this.daysLeft < 4 && !this.hasBeenShown){
        this.showAlmostExpired = true;
      }
      return this.showAlmostExpired;
    }
  }
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
