import { waitingInfoStore, waitingForInfoStore } from './state';
import { markAsCallWaitingRoomUserEvent, markAsCallWaitingRoomForUserEvent } from './events'
import { receivedAllUserStateEvent, receivedSpecificUserStateEvent } from '../events';
import { ownUserUUIDState, ownUserState } from '../state';

// Check all visitors in waiting room and populate the state 
waitingInfoStore.on(receivedAllUserStateEvent, (state, payload) => {
  const uuidUser = ownUserUUIDState.getState();
  return Object.entries(payload)
    .filter(([uuid, entry]) => { // Filter all visitors in user's waiting room
      return (entry && entry.connected && entry.user && entry.user.visitorData && entry.user.visitorData.isWaitingRoom && entry.user.visitorData.userInviter === uuidUser);
    })
    .reduce((obj, [uuid, entry]) => ({ // Convert to object
      ...obj,
      visitors: {
        ...obj.visitors,
        [uuid]: entry.user.visitorData,
      }
    }), state);
});

// Modify the state based on a single user event
waitingInfoStore.on(receivedSpecificUserStateEvent, (state, payload) => {
  const uuidUser = ownUserUUIDState.getState();
  const [uuid, entry] = payload;
  const bool = (entry && entry.connected && entry.user && entry.user.visitorData && entry.user.visitorData.isWaitingRoom && entry.user.visitorData.userInviter === uuidUser);
  if (!bool) {
    // Remove visitor if logged out
    if (state.visitors[uuid] || state.called[uuid] /* || state.seen[uuid] */) {
      return {
        called: JSON.parse(JSON.stringify({
          ...state.called,
          [uuid]: undefined,
        })),
        // seen: JSON.parse(JSON.stringify({
        //   ...state.seen,
        //   [uuid]: undefined,
        // })),
        visitors: JSON.parse(JSON.stringify( {
          ...state.visitors,
          [uuid]: undefined,
        })),
      };
    } else {
      return state; // No changes
    }
  }
  // Populate the respective entry
  return {
    ...state,
    visitors: {
      ...state.visitors,
      [uuid]: entry.user.visitorData,
    },
  };
});
waitingInfoStore.on(markAsCallWaitingRoomUserEvent, (state, payload) => {
  return {
    ...state,
    called: {
      ...state.called,
      [payload]: true,
    },
  };
});

// EVENTS WAITING ROOM FOR ASSISTANT
// Check all visitors in waiting room and populate the state 
waitingForInfoStore.on(receivedAllUserStateEvent, (state, payload) => {
  return Object.entries(payload)
    .filter(([uuid, entry]) => { // Filter all visitors in user's waiting room
      return (entry && entry.connected && entry.user && entry.user.visitorData && entry.user.visitorData.isWaitingRoom
        && ownUserState.getState().user ? (ownUserState.getState().user.assists || []).indexOf(entry.user.visitorData.userInviter) !== -1 : false);
    })
    .reduce((obj, [uuid, entry]) => ({ // Convert to object
      ...obj,
      visitors: {
        ...obj.visitors,
        [uuid]: entry.user.visitorData,
      }
    }), state);
});

// Modify the state based on a single user event
waitingForInfoStore.on(receivedSpecificUserStateEvent, (state, payload) => {
  const [uuid, entry] = payload;
  const bool = (entry && entry.connected && entry.user && entry.user.visitorData && entry.user.visitorData.isWaitingRoom
    && ownUserState.getState().user ? (ownUserState.getState().user.assists || []).indexOf(entry.user.visitorData.userInviter) !== -1 : false);
  if (!bool) {
    // Remove visitor if logged out
    if (state.visitors[uuid] || state.called[uuid] /* || state.seen[uuid] */) {
      return {
        called: JSON.parse(JSON.stringify({
          ...state.called,
          [uuid]: undefined,
        })),
        // seen: JSON.parse(JSON.stringify({
        //   ...state.seen,
        //   [uuid]: undefined,
        // })),
        visitors: JSON.parse(JSON.stringify( {
          ...state.visitors,
          [uuid]: undefined,
        })),
      };
    } else {
      return state; // No changes
    }
  }
  // Populate the respective entry
  return {
    ...state,
    visitors: {
      ...state.visitors,
      [uuid]: entry.user.visitorData,
    },
  };
});

waitingForInfoStore.on(markAsCallWaitingRoomForUserEvent, (state, payload) => {
  return {
    ...state,
    called: {
      ...state.called,
      [payload]: true,
    },
  };
});
