<template>
<div class="px-0 py-0" style="width:100%;height:95vh;">
  <v-row class="mx-0">
      <v-card flat :width="'100%'">
        <div>
          <v-row class="mx-0">
            <v-col cols="12" :lg="12" :sm="12" :md="12" :xl="12">
              <v-sheet tile height="54" color="primary-lighten-1" class="d-flex"> <!-- :color="!theme.global.current.dark ? 'grey lighten-3': null" :class="{'calendarDark': theme.global.current.dark}" -->

                <v-col cols="4" class="px-2 py-3">
                  <span class="text-subtitle-1 font-weight-bold py-2">{{ $t('components.calendarContainer.videoCallPlanner') }} {{getUserNameUserCalendar(uuidToRepresentStore || state.ownUUID)}} </span>
                 <v-btn variant="text" class="refreshData"  icon  @click="toggleHome()">
                  <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '20px' }" />
                </v-btn>
                 <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn  variant="text" v-bind="props" class="closeHome"  icon  @click="fetchBlockedData()">
                        <font-awesome-icon :icon="['far', 'redo']" :style="{ fontSize: '16px' }" />
                      </v-btn>
                    </template>
                      <span>{{ $t('generics.update') }}</span>
                 </v-tooltip>

                </v-col>
                <!-- <v-col cols="4" class="px-2 py-3 text-center">
                  <v-btn icon class="btnCalendar" @click="prevMonth()">
                     <font-awesome-icon :icon="['fal', 'chevron-left']" :style="{ color: 'white', fontSize: '20px' }" />
                  </v-btn>
                  <span class="text-white text-subtitle-1 font-weight-bold">{{calendarMonth}} {{calendarYear}}</span>
                  <v-btn icon class="btnCalendar" @click="nextMonth()">
                     <font-awesome-icon :icon="['fal', 'chevron-right']" :style="{ color: 'white', fontSize: '20px' }" />
                  </v-btn>
                </v-col> -->

                <!-- <v-col cols="4" class="px-0 py-0">
                  <v-select return-object :model-value="type" @update:model-value="type = $event" item-text="name" item-value="value" :items="types" density="compact" outlined hide-details :label="$t('components.calendarContainer.type')" class="ma-2" ></v-select>
                </v-col> -->

                <!-- <v-col cols="4" class="px-0 py-0">
                  <v-select v-if="hasRepresentative && 1 === 2" return-object :model-value="representing" @update:model-value="representing = $event" item-text="name" item-value="value" :items="peopleIRepresent" density="compact" outlined hide-details :label="$t('generics.user')"  class="ma-2"></v-select>
                </v-col> -->
                <!-- <v-select return-object :model-value="mode" @update:model-value="mode = $event" item-text="name" item-value="vale" :items="modes" density="compact" outlined hide-details :label="$t('components.calendarContainer.eventOverlap')"  class="ma-2"></v-select> -->
                <!-- <v-select :model-value="weekday" @update:model-value="weekday = $event" :items="weekdays" density="compact" outlined hide-details :label="$t('components.calendarContainer.weekdays')"  class="ma-2"></v-select> -->

                <!-- <v-btn icon class="ma-2" @click="$refs.calendar.next()">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn> -->
              </v-sheet>
              <v-sheet class="mb-6">
                <v-calendar
                  class="calendarSize"
                  ref="calendar"
                  @change="calChangeHandler"
                  :model-value="value"
                  @update:model-value="value = $event"
                  :start="startCalendarDay"
                  :end="endCalendarDate"
                  :weekdays="weekday"
                  :type="'month'"
                  :events="events"
                  :event-overlap-mode="mode.value"
                  :event-overlap-threshold="30"
                  :event-color="getEventColor"
                  @click:event="showEvent"
                  @click:day="syncTimeline"
                  @click:date="syncTimeline"
                  :locale="this.$locale.current()">
                  <!-- <template v-slot:event="{ event }">
                   <strong>{{displayHours(event.start)}} {{state.user.language !== 'en' ? $t('generics.hours') : ''}}</strong> {{event.name}}
                  </template> -->
                </v-calendar>
                <!-- @change="getEvents" -->
                <!-- show events -->
                <v-menu :model-value="selectedOpen" @update:model-value="selectedOpen = $event" :close-on-content-click="false" :activator="selectedElement" offset-x>
                  <v-card v-if="!wantDelete" color="grey-lighten-4" max-width="350px" width="350px" min-width="350px" flat>
                    <v-toolbar :color="selectedEvent.color" dark>
                      <!-- <v-btn icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>-->
                      <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                      <v-toolbar-title class="text-truncate" v-html="sanitizeSelectedEventName"></v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn v-if="checkImSupervisor" icon >
                        <DeleteBasket :indexData="selectedEvent.uuid" :delFunction="deleteEvent" extraClasses="" fontSize="20" prevent= false />
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <span class="text-truncate" v-html="sanitizeSelectedEventDetails"></span>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text color="secondary" @click="selectedOpen = false">{{ $t('generics.cancel') }}</v-btn>
                    </v-card-actions>
                  </v-card>
                  <!-- REPLACED WITH DELETE BASKET  -->
                  <!-- add card for aceept delete -->
                  <!-- <v-card v-if="wantDelete" color="grey lighten-4" max-width="350px" min-width="350px" flat>
                    <v-toolbar :color="selectedEvent.color" dark>
                      <v-toolbar-title class="text-truncate" v-html="'delete event ' + sanitizeSelectedEventName"></v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <span class="text-truncate" v-html="sanitizeSelectedEventDetails"></span>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text color="secondary" @click="wantDelete = false">{{ $t('generics.cancel') }}</v-btn>
                      <v-btn text color="secondary" @click="deleteEvent(selectedEvent.uuid)">accept</v-btn>
                    </v-card-actions>
                  </v-card> -->
                </v-menu>
              </v-sheet>
            </v-col>
          </v-row>
        </div>
      </v-card>
  </v-row>
</div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import NewEventForm from '../timeline/newEventForm.vue'
import { setEventStateModalEvent } from '../../effector/modals';
import { getBlockedTimes } from '../../lib/wsMsg';
import { timelineEvents, dispatchFetchMyItemsForDateEvent, newTimelineItemEvent, deleteTimelineItemEvent, pleaseShowDateInTimelineEvent } from '../../effector/timeline';
import { uuidToRepresentStore } from '../../effector/representative';
import { dispatchErrorAlert } from "../../effector/alerts";
import DeleteBasket from "../ui/deleteBasket.vue";
// import { hasRepresentative } from "../../utils/representatives";

// import {
//   getSectionCalendar,
//   setSectionCalendar,
//   deleteSectionCalendarEvent
// } from "../../lib/wsMsg";
// import {
//   v4 as uuidv4
// } from "uuid";

export default {
  components: { NewEventForm, DeleteBasket },
  data() {
    const effector = {
      rawEvents: timelineEvents,
      uuidToRepresentStore: uuidToRepresentStore
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      calendarMonth: '',
      calendarYear: '',
      state: store.state,
      ownUUID: store.state.ownUUID,
      setCurrentContentVisile: store.setCurrentContentVisile,
      representing: {
        name: store.state.user.name,
        value: store.state.ownUUID
      },
      type: {
        name: this.$t("components.calendarContainer.monthly"),
        value: "month"
      },
      types: [
        { name: this.$t("components.calendarContainer.monthly"), value: "month" },
        { name: this.$t("components.calendarContainer.week"), value: "week" },
        { name: this.$t("components.calendarContainer.day"), value: "day" }
      ],
      mode: {
        name: this.$t("components.calendarContainer.column"),
        value: "column"
      },
      modes: [
        { name: this.$t("components.calendarContainer.stack"), value: "stack" },
        {
          name: this.$t("components.calendarContainer.column"),
          value: "column"
        }
      ],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        {
          text: this.$t("components.calendarContainer.rageDays.SunSat"),
          value: [0, 1, 2, 3, 4, 5, 6]
        },
        {
          text: this.$t("components.calendarContainer.rageDays.MonSun"),
          value: [1, 2, 3, 4, 5, 6, 0]
        },
        {
          text: this.$t("components.calendarContainer.rageDays.MonFri"),
          value: [1, 2, 3, 4, 5]
        },
        {
          text: this.$t("components.calendarContainer.rageDays.MonWedFri"),
          value: [1, 3, 5]
        }
      ],
      value: [new Date()],
      // events: [],
      colors: [
        {
          name: this.$t("components.calendarContainer.colors.blue"),
          value: "blue"
        },
        {
          name: this.$t("components.calendarContainer.colors.indigo"),
          value: "indigo"
        },
        {
          name: this.$t("components.calendarContainer.colors.deepPurple"),
          value: "deep-purple"
        },
        {
          name: this.$t("components.calendarContainer.colors.cyan"),
          value: "cyan"
        },
        {
          name: this.$t("components.calendarContainer.colors.green"),
          value: "green"
        },
        {
          name: this.$t("components.calendarContainer.colors.orange"),
          value: "orange"
        },
        {
          name: this.$t("components.calendarContainer.colors.greyDarken"),
          value: "grey darken-1"
        }
      ],
      colorSelected: {
        name: this.$t("components.calendarContainer.colors.blue"),
        value: "blue"
      },
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party"
      ],
      startEvent: "",
      startMenu: false,
      startTimeMenu: false,
      timeStart: null,
      endEvent: "",
      endMenu: false,
      endTimeMenu: false,
      timeEnd: null,
      eventName: "",
      eventDetail: "",
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      wantDelete: false,
      myReservedEvents: [],
      // Effector
      ...effector,
    };
  },
  methods: {
    prevMonth(){
      this.$refs.calendar.prev()
    },
    nextMonth(){
      this.$refs.calendar.next()
    },
    toggleHome(){
      if(this.$route.path === '/calendar'){
        this.gotoPage('/home');
      } else {
        this.gotoPage('/calendar');
      }
    },
    gotoPage(route) {
      this.setCurrentContentVisile(route, true, this.$router);
    },
    getNameForUuid(uuid){
        return store.getNameForUuid(uuid)
    },
    getUserNameUserCalendar(uuid){
      if(uuid !==this.state.ownUUID){
        return this.getNameForUuid(uuid);
      }else {
        return '';
      }
    },
    syncTimeline({ date }){
      // get events for dates
      // alert(date)
      pleaseShowDateInTimelineEvent(date);

    },
    calChangeHandler(page) {
      const dateCounter = new Date(page.start.date);
      const endDate = new Date(page.end.date);
      this.calendarMonth = this.$t(`components.calendarContainer.months.${page.start.month}`);
      this.calendarYear = page.start.year;
      // eslint-disable-next-line no-unmodified-loop-condition
      while (dateCounter <= endDate) {
        dispatchFetchMyItemsForDateEvent(dateCounter.toISOString());
        dateCounter.setDate(dateCounter.getDate() + 1);
      }
    },
    saveNewEvent() {
      const dateTimeStart = this.startEvent + " " + this.timeStart;
      const dateTimeEnd = this.endEvent + " " + this.timeEnd;
      const newEntryInfo = {
        name: this.eventName,
        // start: this.formatDate(new Date(dateTimeStart), true),
        start: new Date(dateTimeStart),
        // end: this.formatDate(new Date(dateTimeEnd), true),
        end: new Date(dateTimeEnd),
        color: this.colorSelected.value,
        details: this.eventDetail,
        // uuid: uuidv4()
      };
      newTimelineItemEvent(newEntryInfo);
      // this.events.push(newEntryInfo);
      // setSectionCalendar(this.getUserCalendar, this.events);
      this.resetInputEvent();
    },
    async deleteEvent(entryUUID) {
      this.selectedOpen = false;
      this.wantDelete = false;
      deleteTimelineItemEvent(entryUUID);
      // deleteSectionCalendarEvent(this.getUserCalendar, entryUUID).then(events => {
      //   this.events = events;
      // });
    },
    resetInputEvent() {
      this.startEvent = "";
      this.startMenu = false;
      this.startTimeMenu = false;
      this.timeStart = null;
      this.endEvent = "";
      this.endMenu = false;
      this.endTimeMenu = false;
      this.timeEnd = null;
      this.colorSelected = {
        name: this.$t("components.calendarContainer.colors.blue"),
        value: "blue"
      },
      this.eventName = "";
      this.eventDetail = "";
    },
    showEvent({
      nativeEvent,
      event
    }) {
      if(event.clickable){
        if ( event.eventType && event.eventType == 'directCall'){
          setEventStateModalEvent(event);
        }else{
          this.$router.push({ path: `/newconference/${event.confId}` });
        }
      }else{
        if (event.eventType === 'external'){
          dispatchErrorAlert(this.$t('components.conferenceForm.externalConference'));
        } else {
          dispatchErrorAlert(this.$t('components.conferenceForm.reocurringConf'));
        }

      }

    },
    async fetchBlockedData() {
      let blockSlots = [];
      const blockedTimes = await getBlockedTimes();
      if (blockedTimes && Object.keys(blockedTimes).length){
        if (blockedTimes.apple && blockedTimes.apple.length){
          blockedTimes.apple.forEach(slot => {
            const blockSlot = {
              // start: this.formatDate(new Date(slot.start), true), //"2021-5-27 11:59",
              // end: this.formatDate(new Date(slot.end), true), //"2021-5-27 12:59",
              start: new Date(slot.start), 
              end: new Date(slot.end), 
              eventType: "external",
              name: 'Apple: ' + this.$t('components.conferenceForm.externalConference'),
              title: this.$t('components.conferenceForm.externalConference'),
              type: "external",
            };
            blockSlots.push(blockSlot);
          });
        }
       if (blockedTimes.outlook && blockedTimes.outlook.length){
          blockedTimes.outlook.forEach(slot => {
            const blockSlot = {
              // start: this.formatDate(new Date(slot.start), true), //"2021-5-27 11:59",
              // end: this.formatDate(new Date(slot.end), true), //"2021-5-27 12:59",
              start: new Date(slot.start),
              end: new Date(slot.end),
              eventType: "external",
              name: 'Outlook: ' + this.$t('components.conferenceForm.externalConference'),
              title: this.$t('components.conferenceForm.externalConference'),
              type: "external",
            };
            blockSlots.push(blockSlot);
          });
       }
      }
      this.myReservedEvents =  blockSlots;
    },
    getEventColor(event) {
      if ( event.childFromReocurring ) return 'blue';
      if (event.eventType === "external") return "gray"
      if ( !event.rsvp ){
        return event.color;
      }else{
        const usersAccepted = [];
        const usersRejected = [];
        for ( const userUUID of Object.keys(event.rsvp) ){
          const rsvp = event.rsvp[userUUID];
          if (rsvp && rsvp.status === 'accepted'){
            rsvp.color = 'green';
            usersAccepted.push(userUUID);
          }else if ( rsvp && rsvp.status === 'rejected' ){
            rsvp.color = 'red';
            usersRejected.push(userUUID);
          }else if ( rsvp && rsvp.status === 'pending' ){
            rsvp.color = event.color;
          }
        }
        const myUUID = this.uuidToRepresentStore || this.state.ownUUID
        if ( event.creatorUUID == myUUID && usersAccepted.length === Object.keys(event.rsvp).length ){
          return 'green';
        }else if ( event.creatorUUID == myUUID && usersRejected.length > 0 ) {
          return 'red';
        } else if ( event.rsvp[myUUID] ){
          return event.rsvp[myUUID].color;
        }else{
          return event.color
        }
      }
    },
    // rnd(a, b) {
    //   return Math.floor((b - a + 1) * Math.random()) + a;
    // },
    formatDate(a, withTime) {
      return withTime ?
        `${a.getFullYear()}-${a.getMonth() +
            1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}` :
        `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
    displayHours(str) {
      const hours = str.split(/[ T]/)[1];
      return hours.split(":")
        .map((e) => e.padStart(2, "0"))
        .join(":");
    }
  },
  computed: {
    getCalendarTitle(){
    },
    startCalendarDay(){
      return moment(Date.now()).format('YYYY-MM-DD');
    },
    endCalendarDate(){
      return moment(this.startCalendarDay, 'YYYY-MM-DD').add(30, 'days').format('YYYY-MM-DD');
    },
    sanitizeSelectedEventName() {
      return this.$sanitize(this.selectedEvent.name)
    },
    sanitizeSelectedEventDetails() {
      return this.$sanitize(this.selectedEvent.details)
    },
    events() {
      let events = [];
      this.rawEvents.filter(e => new Date(e.end) > new Date()).map((entry) => {
        let allMyEvents = {
          ...entry,
          start: new Date(entry.start),
          end: new Date(entry.end),
          clickable: true,
          childFromReocurring: false
        }
        events.push(allMyEvents);
          const element = entry;
          if (element.isReOcurring){
            const interval = element.confIsReoInterval || "";
            const frequency = +element.confIsReoFrequency || 0;
            let newStart = new Date(entry.start);
            let newEnd = new Date(entry.end);
            let adjustDays = 0;
            for (let index = 1; index <= frequency; index++) {
              let conferenceStartMoment = moment(newStart).add(adjustDays, 'day').add(index, interval == 'd' ? 'days' : interval == 'w' ? 'weeks' : interval == 'm' ? 'months' : 'days');
              let conferenceStartWeekDay = conferenceStartMoment.isoWeekday();
              if ([6 /* Saturday */, /* Sunday */ 7].includes(conferenceStartWeekDay)) {
                const offset = 8 - conferenceStartWeekDay;
                conferenceStartMoment = moment(conferenceStartMoment).add(offset, 'day');
                conferenceStartWeekDay = conferenceStartMoment.isoWeekday(); // Moved to Monday
                adjustDays += offset;
              }
              const conferenceEndMoment = moment(newEnd).add(adjustDays, 'day').add(index, interval == 'd' ? 'days' : interval == 'w' ? 'weeks' : interval == 'm' ? 'months' : 'days')
              const newEvent = {
                ...entry,
                start: conferenceStartMoment.toDate(),
                end: conferenceEndMoment.toDate(),
                clickable: false,
                childFromReocurring: true
              }
              events.push(newEvent)
            }
          }
      });

      if (this.myReservedEvents && this.myReservedEvents.length){
        this.myReservedEvents.forEach(block => {
          events.push(block);
        });
      }

      return events;
    },
    userList() {
      return store.getProcessedUserList();
    },
    canAddEvent() {
      return !(
        this.eventName !== "" &&
        this.eventDetail !== "" &&
        this.startEvent !== "" &&
        this.timeStart &&
        this.endEvent !== "" &&
        this.timeEnd
      );
    },
    checkImSupervisor() {
      return true; // Fuck that
    },
  },
  mounted() {
    this.fetchBlockedData();
  },
};
</script>

<style lang="scss" scoped>
.calendarSize {
  height: calc(100vh - 170px);
  overflow: auto;
  padding-bottom: 20px;
}
.btnCalendar{
  margin: 0px !important;
    color: white;
}
.text-white{
  color: white;
}
.calendarDark{
  background: #303030;
  border: 1px solid #9e9e9e;
  border-bottom: 0px;
}
.iconAddEvent{
  font-size: 20px;
  vertical-align: middle;
  position: relative;
  top: -2px;
  left: 5px;
}
.closeHome{
  position: absolute;
  right: 50px;
}
.refreshData{
  position: absolute;
  right: 20px;
}
</style>

<style lang="scss">
.gray{
  color:  white !important;
  background-color: rgb(175, 173, 173) !important;
}
.v-calendar-weekly__day.v-future, .v-calendar-weekly__day.v-present{
  cursor: pointer;
}
.v-calendar .v-event {
  margin-left: 6px!important;
  padding-left: 6px!important;
}
</style>
