<template>
  <div>
    <v-list :width="width" :max-width="maxwidth" density="compact"  style="margin: 0;padding: 0;">
      <v-row style="width: 100%; margin: 0">
        <v-card flat class="d-flex" style="margin-left: 20px;width: auto;">
          <v-card flat class="ml-auto font-weight-bold">VideoCall Besuch</v-card>
        </v-card>
      </v-row>
      <v-divider></v-divider>
      <v-list-item  style="margin: 0;padding: 0;">
        <v-list-item-title @click.stop.prevent>
          <v-form ref="form" :model-value="valid" @update:model-value="valid = $event" lazy-validation>
            <v-row style="width: 100%; margin: 0">
            <!-- /////// NAME ///////// -->
              <v-col class="col-12 px-2 py-2">
                <v-text-field 
                  :model-value="uname" @update:model-value="uname = $event" 
                  required
                  :rules="userNameRules"
                  disabled="true">
                </v-text-field>
              </v-col>
            </v-row>

              <!-- /////// Time Rules ///////// -->
             <v-row style="width: 100%; margin: 0">
              <v-col class="col-12 px-2 py-2">
                 <v-checkbox
                  :model-value="uWithoutRules" @update:model-value="uWithoutRules = $event"
                  class="mt-0"
                  label="Arulezeit"
                ></v-checkbox>
              </v-col>
            </v-row>
            
            <v-row style="width: 100%; margin: 0">
                <!-- /////// Calendar ///////// -->
              <v-col class="col-6 px-2 py-2">
                <v-checkbox
                  :model-value="uCalendar" @update:model-value="uCalendar = $event"
                  class="mt-0"
                  label="bis: Kalender"
                ></v-checkbox>
              </v-col>
               <!-- /////// Paylink ///////// -->
              <v-col class="col-6 px-2 py-2">
                <v-checkbox
                  :model-value="uPayLink" @update:model-value="uPayLink = $event"
                  class="mt-0"
                  label="Paylink"
                ></v-checkbox>
              </v-col>
            </v-row>
             <!-- /////// Internal Note ///////// -->
            <v-row style="width: 100%; margin: 0">
              <v-col cols="12" class="py-0 px-2">
                <v-textarea
                  :model-value="internalnote" @update:model-value="internalnote = $event"
                  name="noteinput"
                  :no-resize="true"
                  class="py-0 my-0"
                  rows="2"
                  placeholder="Interne Bemerkung"
                  counter
                  maxlength="80"
                />
              </v-col>
            </v-row>
            <v-row style="width: 100%; margin: 0">
              <div class="mx-auto"></div>
            </v-row>
          </v-form>
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <v-card color="primary" class="py-2 pl-4 mt-4 pr-2  footerModal">
      <v-row class="px-2 btns w-100 mx-0 my-0">
        <template>
          <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <v-btn :disabled="!valid" v-on="on" icon color="white" @click="handleNewInstaLink">
                <font-awesome-icon :icon="['fal', 'check']" :style="{ fontSize: '20px' }" />
              </v-btn>
            </template>
            <span>{{ $t('generics.accept') }}</span>
          </v-tooltip>

          <v-tooltip location="top">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon color="white" @click.stop.prevent="closeMenu()">
                <font-awesome-icon :icon="['fal', 'times']" :style="{ fontSize: '20px' }" />
              </v-btn>
            </template>
            <span>{{ $t('generics.close') }}</span>
          </v-tooltip>
        </template>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import store from "../../store";
import {
  addUserInviters,
  createNewUser,
  isEmailRegistered,
  sendInviteMailCustom,
  setNamespaceSetting
} from "../../lib/wsMsg";
import { v4 as uuidv4 } from "uuid";
import moment from "../../../sharedsrc/moment";
import sendInviteInfoModal from "../modal/sendInviteInfoModal.vue";
export default {
  components: { sendInviteInfoModal },
  props: ["closeMenu", "width", "maxwidth", "fromRoot", "typeInvite", "visitor"],
  data() {
    return {
      state: store.state,
      uname: this.visitor.name,
      internalnote:'',
      uPayLink:'',
      uCalendar:'',
      uWithoutRules:false,
      //   customMessage: store.state.namespaceSettings.customInviteGuestMessage,
      //   moment: moment,
      valid: true,
      sendingInvitation: false,
      // dialog: false,
      userNameRules: [v => !!v || this.$t('generics.nameRequired')],
      phoneRules: [v => /^\d*$/.test(v) || ""],
      emailRules: [
        v => !!v || "", //remove red mesagge ticket #132
        v => /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) || "" //remove red mesagge ticket #132
      ],
      // radios: "guest",
      showInviteInfo: false,
     
    };
  },
  mounted() {
    // console.log("mounted refresh Text ", this.refreshText);
    this.refreshText = true;
    this.loadActualMessage();
  },
  methods: {
    onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    selectId(e) {
      // console.log("Event from select", e);
      this.title = e.id;
    },
    clearForm() {
      this.username = "";
      this.email = "";
      this.title = "";
      this.company = "";
      this.tel = "";
      this.note = "";
      this.subject = "";
      this.instalink = "";
      this.created = "";
    },
    handleNewInstaLink() {
      this.clearForm();
    },
    closeShowInviteInfo() {
      this.showInviteInfo = false;
    },
    hasUuidBeenOnPage(uuid) {
      const person = this.state.group[uuid];
      return ((person || {}).user || {}).rtcCallStart !== undefined;
    },
    loadActualMessage() {
      if (this.typeInvite === "guest") {
        this.loadGuestCustomMessage();
      } else {
        this.loadCustomMessage();
      }
    },
    loadGuestCustomMessage() {
      this.customMessage =
        store.state.namespaceSettings.customInviteGuestMessage;
    },
    loadCustomMessage() {
      this.customMessage = store.state.namespaceSettings.customInviteMessage;
    },
    saveCustomMessage() {
      if (this.typeInvite === "guest") {
        store.state.namespaceSettings.customInviteGuestMessage = this.customMessage;
        setNamespaceSetting("customInviteGuestMessage", this.customMessage);
      } else {
        store.state.namespaceSettings.customInviteMessage = this.customMessage;
        setNamespaceSetting("customInviteMessage", this.customMessage);
      }
    },
    clearData() {
      this.username = "";
      this.email = "";
    }
  },
  computed: {
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
    titleItems(){
       return [
        { id: 0, text: this.$t('components.inviteVisitor.without')},
        { id: 1, text:  this.$t('components.inviteVisitor.mr') },
        { id: 2, text:  this.$t('components.inviteVisitor.ms') },
        { id: 3, text:  this.$t('components.inviteVisitor.company') }
      ]
    },
    
  },
  watch: {
    fromRoot: {
      handler: function(value) {
        if (value === true) {
          this.loadActualMessage();
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped lang="scss">
.height35 {
  height: 35px;
}
form {
  font-weight: normal;
  input {
    font-size: 14px !important;
  }
}
.v-list-item {
  padding: 0px !important;
}
.btnSend {
  float: right;
  // margin-bottom: 20px;
}
.d-flex {
  p {
    &.email {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 65%;
      color: #2a3133;
      margin: 0px;
    }
    &.date {
      width: 40%;
      font-size: 12px;
      vertical-align: middle;
      margin: 4px;
      text-align: right;
    }
  }
}
.btns {
  justify-content: flex-end;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
</style>
