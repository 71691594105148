<template>
  <div>
    <!-- <WebCam
      ref="webcam"
      :device-id="deviceId"
      @error="onError"
      @cameras="onCameras"
      @camera-change="onCameraChange"
      :width="width"
      :height="height || 500"
    /> -->
    <WebCam ref="webcam" :classList="'videoWebcam'"   @init="webcamInit"   />
  </div>
</template>

<script>
import store from "../../store";
import { WebCam } from 'vue-camera-lib'
import { setErrorCameraPermissionsModalEvent } from "../../effector/modals";
export default {
  props: ["closeCamera", "width", "height"],
  components: { WebCam },
  data: () => ({
    state: store.state,
    deviceId: null,
    devices: [],
    cameras: [],
  }),
  mounted() {
    this.getSetting();
    this.cameras = this.$refs.webcam.cameras;
    if (this.cameras.length === 0) {
      // if no camera found, we will try to refresh cameras list each second until there is some camera
      let reloadCamInterval = setInterval(() => {
        this.loadCameras();
        if (this.cameras.length > 0) {
          clearInterval(reloadCamInterval);
        }
      }, 1000);
    }
  },
  watch: {
    devices: function () {
      this.getSetting();
      if (this.deviceId) return;
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.deviceId = first.deviceId;
      }
    },
  },
  methods: {
    loadCameras() {
      this.$refs.webcam.loadCameras();
      this.cameras = this.$refs.webcam.cameras;
    },
    webcamInit(deviceId) {
      this.deviceId = deviceId;
      this.$emit('init', this.deviceId);
    },
    getSetting() {
      const videoSetting = store.getRtcVideo();
      if (videoSetting && videoSetting.deviceId) {
        this.deviceId = videoSetting.deviceId;
      }
    },
    settmpSnapshot: function (...args) {
      store.settmpSnapshot(...args);
    },
    onError(error) {
      if (error.name === "NotAllowedError") {
        setErrorCameraPermissionsModalEvent(true);
        this.closeCamera();
      }
      store.setErrorWebcam(true);
    },
    onCameras(cameras) {
      this.devices = cameras;
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
    },
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },
};
</script>
