<template>
  <div  class="pa-0 col-12 cursorPointer">
    <v-progress-linear
      @click="addMoreTime()"
      bg-color="#bdc3c7"
      bg-opacity="1"
      height="24"
      :color="
        getPercentage <= 50
          ? 'barColorGreen'
          : getPercentage > 50
          ? 'barColorOrange'
          : 'barColorGreen'
      "
      :class="getClassProgressLinear"
      :model-value="getPercentage"
      class="positionProgressSamba"
    >
      <!-- <template> -->
        <strong class="text-white startCall">{{
          getCallStart
        }}</strong>
        <strong
          v-if="
            originalCallDuration != 31536000000 &&
            originalCallDuration != 86400000
          "
          class="text-white"
          style="margin-right: 25px"
          >{{ $t("generics.planned") }} {{ originalCallTime }} Min</strong
        >
        <!-- <strong class="white--text">{{ elapseTime }}</strong> -->
        <strong
          v-if="
            originalCallDuration != 31536000000 &&
            originalCallDuration != 86400000
          "
          class="customRed"
          style="margin-left: 25px"
          >({{ timerCall }})</strong
        >
        <strong class="text-white endCall">{{
          getCallEnd
        }}</strong>
      <!-- </template> -->
    </v-progress-linear>
    <template v-if="showEndingModal">
      <BeforeEndingCallBridgeModal
        :showModal="showEndingModal"
        :closeModal="openCloseBeforeEndingModal"
        :hangUpBridgeCall="hangUpBridgeCall"
        :addMoreTime="addMoreTime"
        :callUUID="getCallUuid"
        :amIModerator="amIModerator"
      />
    </template>
  </div>
</template>

<script>
import store from "../../store";
import moment from "../../../sharedsrc/moment";
import { setErrorInCallModalEvent } from "../../effector/modals";
import BeforeEndingCallBridgeModal from "../modal/beforeEndingCallBridgeModal.vue";

export default {
  props: [
    "amIModerator",
    "updateTimeForUser",
    "setCallDuration",
    "hangUpBridgeCall",
    "redirectToStartView",
    "isConferenceCall",
    "rejectCall",
  ],
  components: { BeforeEndingCallBridgeModal },
  data() {
    return {
      state: store.state,
      moment: moment,
      secondsBeep1: 0,
      secondsBeep2: 0,
      secondsBeep3: 0,
      ctrlPressed: false,
      spaceShortcutCallAcceptTimeout: null,
      showEndingModal: false,
      originalCallDuration: 31536000000,
    };
  },
  watch: {},
  mounted() {
    if ((this.state.remoteBridgeStreams[this.getCallUuid] || {}).callDurationMs) {
      this.originalCallDuration =
        this.state.remoteBridgeStreams[this.getCallUuid].callDurationMs;
    }
    document.addEventListener("keydown", this.onKeyDown, {
      capture: true,
      passive: true,
    });
    document.addEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
  },
  unmounted() {
    document.removeEventListener("keydown", this.onKeyDown, {
      capture: true,
      passive: true,
    });
    document.removeEventListener("keyup", this.onKeyUp, {
      capture: true,
      passive: true,
    });
  },
  methods: {
    openCloseBeforeEndingModal(value) {
      // this.showEndingModal = value ? value : !this.showEndingModal;
      this.showEndingModal = value;
    },
    onKeyDown(event) {
      if (event.type === "keydown" && event.keyCode === 17) {
        this.ctrlPressed = true;
      }
    },
    onKeyUp(event) {
      if (event.type === "keyup" && event.keyCode === 13) {
        if (this.ctrlPressed) {
          if (
            event.target.nodeName !== "TEXTAREA" &&
            event.target.nodeName !== "INPUT"
          ) {
            return this.lessTime();
          }
        }
      } else if (event.type === "keyup" && event.keyCode === 17) {
        this.ctrlPressed = false;
      }
    },
    hangupBridgeCall() {
      this.hangUpBridgeCall();
      this.redirectToStartView();
    },
    formatTime(time) {
      return time / (1000 * 60);
    },
    formatDate(time) {
      if (time >= 3600000) {
        return this.moment(time).format("HH:mm");
      } else {
        return this.moment(time).format("mm");
      }
    },
    lessTime() {
      // let currentCallDuration =
      //   this.state.remoteBridgeStreams[this.getCallUuid].callDurationMs;
      if (this.amIModerator) {
        this.adjustCallDurationMs(-60000);
      } else {
        const data = {
          show: true,
          header: this.$t("components.modalAddTimeMeeting.header"),
          body: this.$t("components.modalAddTimeMeeting.info"),
        };
        return store.setinfoModal(data);
      }
    },
    addMoreTime(newTime = 60000) {
      // let currentCallDuration =
      //   this.state.remoteBridgeStreams[this.getCallUuid].callDurationMs;
      if (this.amIModerator) {
        if (this.state.nerd) {
          this.adjustCallDurationMs(1800000);
        } else {
          this.adjustCallDurationMs(newTime);
        }
      } else {
        const data = {
          show: true,
          header: this.$t("components.modalAddTimeMeeting.header"),
          body: this.$t("components.modalAddTimeMeeting.info"),
        };
        return store.setinfoModal(data);
      }
    },
    adjustCallDurationMs(diff) {
      if (typeof diff !== "number") throw new Error("Invalid argument");
      if (!this.amIModerator) throw new Error("Not call owner");
      const oldDuration =
        this.state.remoteBridgeStreams[this.getCallUuid].callDurationMs ||
        60000 * 20;
      const newDuration = oldDuration + diff;

      const minimumDuration = this.getCurrentCallRunningTime();
      if (newDuration > minimumDuration) {
        this.setCallDuration(this.getCallUuid, newDuration);
        this.updateTimeForUser(newDuration);
      }
    },
    getCurrentCallRunningTime() {
      if (!this.state.remoteBridgeStreams[this.getCallUuid]) return 0;
      return (
        Date.now() -
        this.state.remoteBridgeStreams[this.getCallUuid].callStartTs
      );
    },
  },
  computed: {
    getCallEnd() {
      if (
        !this.state.remoteBridgeStreams[this.getCallUuid] ||
        !this.state.remoteBridgeStreams[this.getCallUuid].callStartTs ||
        !this.state.remoteBridgeStreams[this.getCallUuid].callDurationMs
      )
        return "00:00";
      if (
        this.originalCallDuration == 31536000000 ||
        this.originalCallDuration == 86400000
      )
        return this.$t("components.meetingTime.unlimited");
      return this.formatDate(
        this.state.remoteBridgeStreams[this.getCallUuid].callStartTs +
          this.state.remoteBridgeStreams[this.getCallUuid].callDurationMs
      );
    },
    getCallStart() {
      if (!this.state.remoteBridgeStreams[this.getCallUuid]) return "";
      return this.formatDate(
        this.state.remoteBridgeStreams[this.getCallUuid].callStartTs
      );
    },
    getCallUuid() {
      const callUuid = this.state.user.inBridgeCall;
      return callUuid;
    },
    originalCallTime() {
      if (!this.state.remoteBridgeStreams[this.getCallUuid]) return "";
      return this.formatTime(
        this.state.remoteBridgeStreams[this.getCallUuid].callDurationMs
      );
    },
    getClassProgressLinear() {
      if (
        this.state.remoteBridgeStreams[this.getCallUuid] &&
        this.state.currentTS -
          this.state.remoteBridgeStreams[this.getCallUuid].callStartTs >=
          this.state.remoteBridgeStreams[this.getCallUuid].callDurationMs -
            60000
      ) {
        return "progressBarBlink";
      }
    },
    timerCall() {
      if (!this.state.remoteBridgeStreams[this.getCallUuid]) return "00";
      let milisegundos =
        this.state.remoteBridgeStreams[this.getCallUuid].callDurationMs -
        (this.state.currentTS -
          this.state.remoteBridgeStreams[this.getCallUuid].callStartTs);
      if (!milisegundos || milisegundos <= 0) return "00";
      // round up timer #218
      const m = moment(milisegundos);
      let roundUp =
        m.second() || m.millisecond()
          ? m.add(1, "minute").startOf("minute")
          : m.startOf("minute");
      const roundUpMilisegundos = this.moment(roundUp).valueOf();
      if (
        roundUpMilisegundos >
        this.state.remoteBridgeStreams[this.getCallUuid].callDurationMs
      ) {
        roundUp =
          this.state.remoteBridgeStreams[this.getCallUuid].callDurationMs;
      }
      return roundUp / (1000 * 60);
    },
    elapseTime() {
      let milisegundos =
        this.state.currentTS -
        this.state.remoteBridgeStreams[this.getCallUuid].callStartTs;
      if (!milisegundos || milisegundos <= 0) return "00";
      let progress = "";
      if (milisegundos >= 3600000) {
        progress = this.moment.utc(milisegundos).format("hh:mm");
      } else {
        progress = this.moment.utc(milisegundos).format("mm");
      }
      return progress;
    },
    getPercentage() {
      if (
        (this.state.remoteBridgeStreams[this.getCallUuid] || {}).callStartTs
      ) {
        let timeIncall =
          this.state.currentTS -
          this.state.remoteBridgeStreams[this.getCallUuid].callStartTs;
        let limitTimeCall =
          this.state.remoteBridgeStreams[this.getCallUuid].callDurationMs;
        let porcentaje = ((timeIncall * 100) / limitTimeCall).toFixed(0);
        if (!this.showEndingModal && (porcentaje > 200 || porcentaje < -15)) {
          setErrorInCallModalEvent(true);
          return this.rejectCall();
        }
        // code cheking by minutes and seconds
        if (limitTimeCall <= timeIncall) {
          this.openCloseBeforeEndingModal(true);
          const thirtySecondsMore = limitTimeCall + 30000;
          if (thirtySecondsMore <= timeIncall) {
            if (this.showEndingModal) {
              this.openCloseBeforeEndingModal(false);
            }
            return this.hangupBridgeCall();
          }
          return 100;
        }
        return porcentaje;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style  lang="scss">
.startCall {
  position: absolute;
  left: 5px;
}
.endCall {
  position: absolute;
  right: 5px;
}
.cursorPointer {
  cursor: pointer;
}
.customRed {
  color: #b53a31;
}
.barGradient {
  background: #2a3133 !important;
} /* fallback for old browsers */
.barGradient {
  background: -webkit-linear-gradient(to right, #6dbac6, #2a3133) !important;
} /* Chrome 10-25, Safari 5.1-6 */
.barGradient {
  background: linear-gradient(to right, #6dbac6, #2a3133) !important;
} /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

.barGradientGrey {
  background: #bdc3c7 !important;
} /* fallback for old browsers */
.barGradientGrey {
  background: -webkit-linear-gradient(to right, #bdc3c7, #2c3e50) !important;
} /* Chrome 10-25, Safari 5.1-6 */
.barGradientGrey {
  background: linear-gradient(to right, #bdc3c7, #2c3e50) !important;
} /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

.barGradientOrange {
  background: #ff512f !important;
} /* fallback for old browsers */
.barGradientOrange {
  background: -webkit-linear-gradient(to right, #f09819, #ff512f) !important;
} /* Chrome 10-25, Safari 5.1-6 */
.barGradientOrange {
  background: linear-gradient(to right, #f09819, #ff512f) !important;
} /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

.bg-barColorGreen {
  background: #262626 !important;
}
.bg-barColorOrange {
  background: #ff7043 !important;
}
.positionProgressSamba{
  z-index: 1;
}
</style>