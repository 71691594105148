<template>
  <v-dialog :model-value="showModal" @update:model-value="showModal = $event" persistent max-width="570"
    @keydown.esc="closeModal">
    <v-card>
      <HeaderModal :titleModal="popUpTitle" :closeModalFunction="closeModal" />
      <v-row :style="{ width: '100%', margin: 0 }" class="px-4 py-2">
        <!-- NO avatar popUp -->
        <v-card-text v-if="(params.showAvatar === undefined || params.showAvatar === false)">
          <p class="breakSpaces">{{ popUpMsg }}</p>
        </v-card-text>
        <!-- PopUp with avatar -->
        <v-col v-else v-for="n in 2" :key="n" :lg="cols[n - 1]" :md="cols[n - 1]" :sm="cols[n - 1]" :xl="cols[n - 1]"
          :class="{ 'text-center': isMobile && n == 1 }" class="my-0 py-0 px-0">
          <div class="pl-3" v-if="n == 1">
            <v-avatar tile size="150" class="borderRadius4" :style="getBorderByStatusFull(
              state.group[params.uuidJoiningUser]
            )">
              <v-img class="borderRadius4" max-height="150" max-width="150" contain
                :src="getAvatarForUuid(params.uuidJoiningUser)" lazy-src="img/default_profile_picture.png" v-if="
                  getAvatarForUuid(params.uuidJoiningUser) !==
                  'img/default_profile_picture.png'
                "></v-img>
              <DefaultAvatar :size="120" :userData="state.group[params.uuidJoiningUser].user" v-if="
                getAvatarForUuid(params.uuidJoiningUser) ===
                'img/default_profile_picture.png'
              "></DefaultAvatar>
            </v-avatar>
          </div>
          <div class="pt-3 pr-3 text-center" v-if="n == 2" :style="{ width: '100%' }">
            <v-divider class="mt-4"></v-divider>
            <div class="mt-2 ml-4">
              <p color="primary" class="userNameCard">
                {{ getUserData(params.uuidJoiningUser).titel }} {{ getUserData(params.uuidJoiningUser).name }}
              </p>
              <p color="primary">{{ popUpMsg }}</p>
            </div>
            <v-divider class="mt-4"></v-divider>
          </div>
        </v-col>
      </v-row>
      <FooterModal :closeModalFunction="closeModal" />
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../store";
import { getBorderByStatusFull } from "../../utils/basicFunctions";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
      params: (store.state.modalPopUp || {}).params || []
    };
  },
  methods: {
    getUserData(userUuid) {
      return store.state.group[userUuid].user;
    },
    titelPerson(uuid) {
      return store.getTitelForUuid(uuid);
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getBorderByStatusFull(person) {
      return getBorderByStatusFull(person);
    },
    closeModal() {
      return store.setmodalPopUp(undefined);
    },
  },
  computed: {
    popUpMsg() {
      if (this.state.modalPopUp?.msg && /^[\w\d_.-]+$/.test(this.state.modalPopUp.msg)) {
        const params = (this.state.modalPopUp || {}).paramsMsg || [];
        return this.$t(this.state.modalPopUp.msg, params) || this.state.modalPopUp.msg;
      }
      return this.state.modalPopUp?.msg || "";
    },
    popUpTitle() {
      const params = (this.state.modalPopUp || {}).params || [];
      return this.$t((this.state.modalPopUp || {}).title || 'generics.info', params);
    },
    showModal() {
      return store.state.modalPopUp && store.state.modalPopUp.type === "popUp";
    },
    cols() {
      const { lg, sm, md, xs, xl } = this.$vuetify.display; // eslint-disable-line no-unused-vars
      return lg ? [4, 8] : sm ? [4, 8] : md ? [4, 8] : xl ? [4, 8] : [12, 12];
    },
  },
};
</script>
<style scoped lang="scss">
.breakSpaces {
  white-space: break-spaces;
}

.borderRadius4 {
  border-radius: 4px !important;
}

.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;

  .btns {
    justify-content: flex-end;
  }
}
</style>
