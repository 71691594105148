import { withResolvers } from './asyncUtil';

export const themeState = {
  isDark: false
};

function getIsDark () {
  try {
    return JSON.parse(localStorage.getItem('isDark'));
  } catch (err) {
    console.warn('getIsDark Error:', err);
  }
  return false;
}

function setIsDark (val) {
  try {
    localStorage.setItem('isDark', JSON.stringify(val));
  } catch (err) {
    console.warn('setIsDark Error:', err);
  }
}

export function applyIsDark (isDark) {
  try {
    // const themeElem = document.getElementById('themeSheet');
    // themeElem.href = isDark ? '/style/dark.css' : '/style/light.css';
    // themeState.isDark = isDark;
    setIsDark(isDark);
  } catch (err) {
    console.warn('applyIsDark Error:', err);
  }
}

try {
  applyIsDark(getIsDark());
} catch (err) {
  console.warn('Initial Load Apply Theme Error:', err);
}

const { promise, resolve: removedSplashResolve } = withResolvers();
export const removedSplashPromise = promise;

const pokeRemoveSplashStart = Date.now();
let pokeRemoveSplashTimeout;
let removedSplash = false;

function removeSplash () {
  const splash = document.getElementById('loading-wrapper');
  splash.classList.add('hidden');
  removedSplash = true;
  removedSplashResolve();
}

export function pokeRemoveSplash () {
  if (removedSplash) return;
  if (pokeRemoveSplashTimeout) clearTimeout(pokeRemoveSplashTimeout);

  if (Date.now() - pokeRemoveSplashStart > 7000) {
    return removeSplash();
  }

  pokeRemoveSplashTimeout = setTimeout(() => {
    removeSplash();
  }, 500);
}
