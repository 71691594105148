// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#inviteVisitorMenu {
  height: 100% !important;
}
#inviteVisitorMenu .v-label {
  font-weight: 500 !important;
  font-size: 14px;
}
#inviteVisitorMenu .invitationMenuList {
  width: 100% !important;
  padding-top: 0px;
  max-width: 100% !important;
  max-height: 100% !important;
  min-height: 100%;
}
.v-dialog--fullscreen {
  z-index: 999999 !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/navbar/inviteGuestPanel.vue","webpack://./inviteGuestPanel.vue"],"names":[],"mappings":"AACA;EACE,uBAAA;ACAF;ADCE;EACE,2BAAA;EACA,eAAA;ACCJ;ADCE;EACE,sBAAA;EACA,gBAAA;EACA,0BAAA;EACA,2BAAA;EACA,gBAAA;ACCJ;ADEA;EACE,0BAAA;ACCF","sourcesContent":["\n#inviteVisitorMenu {\n  height: 100% !important;\n  & .v-label {\n    font-weight: 500 !important;\n    font-size: 14px;\n  }\n  &  .invitationMenuList{\n    width: 100% !important;\n    padding-top: 0px;\n    max-width: 100% !important;\n    max-height: 100% !important;\n    min-height: 100%;\n  }\n}\n.v-dialog--fullscreen {\n  z-index: 999999 !important;\n}\n","#inviteVisitorMenu {\n  height: 100% !important;\n}\n#inviteVisitorMenu .v-label {\n  font-weight: 500 !important;\n  font-size: 14px;\n}\n#inviteVisitorMenu .invitationMenuList {\n  width: 100% !important;\n  padding-top: 0px;\n  max-width: 100% !important;\n  max-height: 100% !important;\n  min-height: 100%;\n}\n\n.v-dialog--fullscreen {\n  z-index: 999999 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
