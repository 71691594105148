 <template>
  <v-list two-line class="messagesWrapper">
    <div>
      <v-list-item-group active-class="primary--text">
        <div v-for="(message, index) in messageArray" :key="index">
          <v-list-item @click="openNotification(message.messageUUID)" :key="index" v-if="message">
            <template>
              <v-avatar
                tile
                size="60"
                class="borderRadius10 cursorPointer"
                :style="{border: `2px solid ${setBorderByStatus(state.group[message.creatorUUID])}`}"
              >
                <DefaultAvatarGrid
                  :size="120"
                  :userData="state.group[message.creatorUUID]"
                  v-if="getAvatarForUuid(message.creatorUUID) == 'img/default_profile_picture.png'"
                ></DefaultAvatarGrid>
                <v-img
                  v-if="getAvatarForUuid(message.creatorUUID) != 'img/default_profile_picture.png'"
                  class="mx-2 borderRadius10"
                  max-height="60"
                  max-width="60"
                  contain
                  :src="getAvatarForUuid(message.creatorUUID)"
                ></v-img>
              </v-avatar>

              <v-list-item-content>
                <v-list-item-title
                  :class="( message.type==='message' && !message.users[ownUUID].read) ? 'font-weight-bold textBlue':''"
                  v-text="getNameForUuid(message.creatorUUID)"
                ></v-list-item-title>
                <v-list-item-subtitle
                  class="text--primary"
                  v-text="message.type === 'call' ? $t('components.notificationsModal.missedCall') : message.body || 'New message'"
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text v-text="formatNoticationDate(message.date)"></v-list-item-action-text>
              </v-list-item-action>
            </template>
          </v-list-item>
        </div>
      </v-list-item-group>
    </div>
  </v-list>
</template>

<script>
import { useStore } from "effector-vue/composition";
import moment from "../../../../sharedsrc/moment";
import store from "../../../store";
import { getStatusColorByUser } from '../../../utils/status';
import { messagesToMe } from "../../../effector/message";
import { setNotificationModalEvent } from "../../../effector/modals";
export default {
  data() {
    const effector = {
       messagesToMe: messagesToMe
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      moment: moment,
      // Effector
      ...effector,
    };
  },
  methods: {
    formatNoticationDate(date) {
      return this.moment(date).format("DD.MM.YYYY, H:mm");
    },
    getNameForUuid: function (...args) {
      return store.getNameForUuid(...args);
    },
    getAvatarForUuid: function (...args) {
      return store.getAvatarForUuid(...args);
    },
    setBorderByStatus(person) {
      return getStatusColorByUser(person.user, person.connected);
    },
    openNotification(messageUUID) {
      setNotificationModalEvent(messageUUID);
    },
  },
  computed: {
    messageArray() {
      return this.messagesToMe
    },
    // userSubordinate() {
    //   return this.state.user.subordinateActiveUser;
    // },
  },
};
</script>

<style>
.messagesWrapper {
  height: calc(100vh - 130px) !important;
  overflow: auto !important;
}
</style>