import { callUserInCallModalDomain } from './domain';

import { setCallUserInCallModalEvent, resetCallUserInCallModalEvent} from './events';

export const callUserInCallModalStore = callUserInCallModalDomain.createStore(false, { name: ' callUserInCallModal' });

callUserInCallModalStore.on(setCallUserInCallModalEvent, (state, payload) => {
  return payload;
});

callUserInCallModalStore.reset(resetCallUserInCallModalEvent);
