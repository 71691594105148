<template>
  <v-dialog
      :model-value="showcanJoinConferencesModal.show" 
      @update:model-value="showcanJoinConferencesModal.show = $event"
      persistent
      max-width="630"
      @keydown.esc="closeModal"
    >
      <v-card>
        <HeaderModal 
          :titleModal="titleModal" 
          :closeModalFunction="closeModal" 
          />
        <v-row class="overflow-auto pt-6 pl-4" style="min-height: 200px!important">
          <v-col
            v-for="n in 2"
            :key="n"
            :lg="cols[n - 1]"
            :md="cols[n - 1]"
            :sm="cols[n - 1]"
            :xl="cols[n - 1]"
            :class="{ 'text-center': isMobile && n == 1 }"
            class="my-0 py-0 px-0"
          >
            <div class="pl-3" v-if="n == 1">
              <v-avatar
                tile
                size="150"
                class="borderRadius4"
                :style="
                  getBorderByStatusFull(
                    state.group[showcanJoinConferencesModal.calledUserUUID]
                  )"
              >
                <v-img
                  class="borderRadius4"
                  max-height="150"
                  max-width="150"
                  contain
                  :src="getAvatarForUuid(showcanJoinConferencesModal.calledUserUUID)"
                  lazy-src="img/default_profile_picture.png"
                  v-if="
                    getAvatarForUuid(showcanJoinConferencesModal.calledUserUUID) !==
                    'img/default_profile_picture.png'
                  "
                ></v-img>
                <DefaultAvatar
                  :size="120"
                  :userData="state.group[showcanJoinConferencesModal.calledUserUUID].user"
                  v-if="
                    getAvatarForUuid(showcanJoinConferencesModal.calledUserUUID) ===
                    'img/default_profile_picture.png'
                  "
                ></DefaultAvatar>
              </v-avatar>
            </div>

            <div class="pt-3 pr-3" v-if="n == 2">
              <p>{{ $t("components.joinStartedConference.joinToDesc") }}</p>
              <span v-for="(person, index) in getUsersInCall" :key="person">
                {{ titelPerson(person.uuid) }} {{person.name}}{{ getUsersInCall.length - 1 == index ? "" : "," }}
              </span>
            </div>
          </v-col>
        </v-row>
        <FooterModal :closeModalFunction="closeModal">
          <v-btn color="primary" class="ml-3" @click="sendMessage(showcanJoinConferencesModal.calledUserUUID)">{{ $t("components.userListItem.callBackTT") }}</v-btn>
          <v-btn color="primary" class="ml-3" @click="tryJoin">{{ $t("generics.call") }}</v-btn>
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-btn
                style="border-radius: 4px!important; min-width: 45px; padding: 7px 0px"
                class="ml-3 h-100"
                variant="outlined"
                v-bind="props"
                icon
                color="primary"
                tile
                @click="
                  setShowModalSenMsg(showcanJoinConferencesModal.calledUserUUID, 'message')
                "
              >
                <font-awesome-icon
                  :icon="['fal', 'paper-plane']"
                  :style="{ fontSize: '20px' }"
                />
              </v-btn>
            </template>
            <span>{{ $t("components.userProfile.sendAMessage") }}</span>
          </v-tooltip>
        </FooterModal>
      </v-card>
    </v-dialog>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import { wsCall } from "../../lib/wsConnect";
import {
  canJoinConferenceStore,
  callUserInCallModalStore,
  resetCanJoinConferenceEvent,
  setHistoryMessagesModalEvent
} from "../../effector/modals";
import {myConferenceDetails,} from "../../effector/groupConferences";
import { bridgeInfoStore } from "../../effector/users/bridgeInfo";
import { getBorderByStatusFull } from "../../utils/basicFunctions";
import { hasPrivilege } from "../../utils/privileges.js";
import DefaultAvatar from "../image/defaultAvatar.vue";
import { newMessageEvent } from "../../effector/message";

export default {
  props: [],
    components: {HeaderModal, FooterModal, DefaultAvatar},
  data(){
    const effector = {
      showcanJoinConferencesModal: canJoinConferenceStore,
      callUserInCallModalStore: callUserInCallModalStore,
      myConferenceDetails: myConferenceDetails,
      bridgeInfoStore: bridgeInfoStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      // Effector
      ...effector,
    }
  },
  mounted() {
  },
  unmounted() {
  },
  methods: {
    setShowModalSenMsg(uuid) {
      setHistoryMessagesModalEvent(uuid);
      this.closeModal();
    },
    titelPerson(uuid) {
      return store.getTitelForUuid(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getBorderByStatusFull(person) {
      return getBorderByStatusFull(person);
    },
    preparedataToSendMsgEffector(id, text, header) {
      const userToSendMsg = {};
      userToSendMsg[id] = {
        userUUID: id,
        read: false,
        replied: false,
        deleted: false,
        viewed: false,
      };
      const dataMsg = {
        body: text,
        header: header,
        date: Date.now(),
        users: userToSendMsg,
        isReply: false,
        creatorUUID: this.ownUUID,
        masterMessageUUID: null,
        parentMessageUUID: null,
        type: "message",
      };
      newMessageEvent(dataMsg);
    },
    tryJoin(){
      if(this.amIConferenceOwner){
        this.joinToConference()
      }else{
        wsCall("sendToUUID", this.showcanJoinConferencesModal?.calledUserUUID, {
          type: "joinConference",
          message: this.$t('components.callsContent.joinConference'),
          sender: store.state.ownUUID,
        });
        setTimeout(() => {
          wsCall("sendToUUID", this.ownUUID, {
            type: "popUp",
            message: "If you're accepted in the conference, you will receive an incoming call",
            title: "Trying to join conference",
            params: [],
            sender: this.showcanJoinConferencesModal?.calledUserUUID,
          });
        }, 500);
      }
      this.closeModal()
    },
    closeModal() {
      resetCanJoinConferenceEvent();
    },
    joinToConference(){
      const conferenceMemberArray = this.getConference?.members.map(user=>{
        return user.uuid
      })
      const userInConferenceCall = conferenceMemberArray.filter(uuid=>{
        const getCallInfo = this.state.group[uuid]?.user?.bridgeCallInfo || undefined;
        const getCallUUID = (this.state.group[uuid]?.user?.inBridgeCall || "").toString();
        return getCallInfo && !getCallInfo.conferenceHall && getCallInfo.isConferenceCall && getCallInfo.confId === this.getConference.confId.toString() && getCallUUID
      })
      if(userInConferenceCall.length>0){
        const usersUuid = userInConferenceCall[0]
        // condition to know if the conference is in process and get the info and get inside the call as a listener
        const getCallInfo = this.state.group[usersUuid]?.user?.bridgeCallInfo || undefined;
        if(getCallInfo) {
          // there is a conference call working which the user is part of it but user doesn't be inside atm
          const userData = getCallInfo?.conferenceAttendees.find(e => e.uuid == this.state.ownUUID );
          const getCallUUID = (this.state.group[usersUuid]?.user?.inBridgeCall || "").toString();
          if(userData && getCallUUID) {
            store.addRemoteBridgeStream(getCallUUID, { ...getCallInfo, ...getCallInfo.infoCall, isAudioOnly: true, calling: [] })
            store.setCurrentContentVisile("", false, this.$router);
          }
        }
      }
    },
    sendMessage(userUUID) {
      if (!userUUID) return false;
      const title = this.state.user.titel && this.state.user.titel !== "unknown"
        ? this.state.user.titel
        : "";
      const text = this.$t("components.userListItem.callBackMessage", [
        `${title} ${this.state.user.name}`.trim(),
      ]);
      const header = {
        translateMessage: {
          translateKey: "components.userListItem.callBackMessage",
          params: [`${title} ${this.state.user.name}`.trim()],
        },
      };
      this.preparedataToSendMsgEffector(userUUID, text, header);
      this.closeModal()
    },
  },
  computed:{
    titleModal() {
      const typePerson = this.hasPrivilege(this.showcanJoinConferencesModal.calledUserUUID)
        ? this.$t("generics.userLowercase")
        : this.$t("generics.guestLowercase");
      if (
        !store.state.group[this.showcanJoinConferencesModal.calledUserUUID]?.beaconCallInvite
      ) {
        return (
          this.$t("components.joinStartedConference.joinToTitle") +
          " - " +
          typePerson +
          " " +
          this.getNameForUuid(this.showcanJoinConferencesModal.calledUserUUID)
        );
      } else {
        return this.$t("components.joinStartedConference.joinToTitle") + " " + typePerson;
      }
    },
    getUsersInCall() {
      const conferenceAttendees = this.state.group[this.showcanJoinConferencesModal.calledUserUUID].user.bridgeCallInfo.conferenceAttendees;
      if (conferenceAttendees.length) {
        return conferenceAttendees;
      } else if (
        this.bridgeInfoStore &&
        this.bridgeInfoStore.calls &&
        Object.keys(this.bridgeInfoStore.calls).length
      ) {
        if (
          this.bridgeInfoStore.calls[
            this.showcanJoinConferencesModal.infoStartBridgeCall?.callUUID
          ]
        ) {
          return this.bridgeInfoStore.calls[
            this.showcanJoinConferencesModal.infoStartBridgeCall?.callUUID
          ].users;
        }
      } 
    },
    cols() {
      const { lg, sm, md, xs, xl } = this.$vuetify.display; // eslint-disable-line no-unused-vars
      return lg ? [4, 8] : sm ? [4, 8] : md ? [4, 8] : xl ? [4, 8] : [12, 12];
    },
    getConference(){
      if(!this.showcanJoinConferencesModal?.calledUserUUID || !this.callUserInCallModalStore?.confId ) return false
      const conference = this.myConferenceDetails.find(
        (e) => e.confId === this.callUserInCallModalStore.confId 
      );
      return conference
    },
    amIConferenceOwner(){
      if(!this.getConference) return false;
      if(this.getConference?.confOwner !== this.state.ownUUID) return false
      const conferenceMemberArray = this.getConference?.members.map(user=>{
        return user.uuid
      })
      const userInConferenceCall = conferenceMemberArray.filter(uuid=>{
        const getCallInfo = this.state.group[uuid]?.user?.bridgeCallInfo || undefined;
        const getCallUUID = (this.state.group[uuid]?.user?.inBridgeCall || "").toString();
        return getCallInfo && !getCallInfo.conferenceHall && getCallInfo.isConferenceCall && getCallInfo.confId === this.getConference.confId.toString() && getCallUUID
      })
      const amIInThisConference = conferenceMemberArray.find(uuid => uuid == this.state.ownUUID );
      if(userInConferenceCall.length && amIInThisConference){
        return true
      }else{
        return false
      }
    },
  }

};
</script>

<style scoped lang="scss">
.pointer {
  cursor: pointer;
}
.thumbs-down {
  color: red;
}
.thumbs-up {
  color: green;
}
</style>