// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filteringContent[data-v-d6013b04] {
  position: absolute;
  top: 2px;
  right: 5px;
  z-index: 1;
}
.filteringContentWithLoading[data-v-d6013b04] {
  position: absolute;
  top: 2px;
  right: 130px;
  z-index: 1;
}
.loadingContent[data-v-d6013b04] {
  position: absolute;
  top: 2px;
  right: 5px;
  z-index: 1;
}
.contentGridWrapper[data-v-d6013b04] {
  height: calc(100vh - 130px);
  overflow: auto;
  overflow-x: hidden;
}
.hideTimeline[data-v-d6013b04] {
  height: calc(100vh - 70px) !important;
  overflow: auto;
  overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/organigram/newOrganisationGrid.vue","webpack://./newOrganisationGrid.vue"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,UAAA;ACAF;ADEA;EACE,kBAAA;EACA,QAAA;EACA,YAAA;EACA,UAAA;ACCF;ADCA;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,UAAA;ACEF;ADAA;EACE,2BAAA;EACA,cAAA;EACA,kBAAA;ACGF;ADDA;EACI,qCAAA;EACA,cAAA;EACA,kBAAA;ACIJ","sourcesContent":["\n.filteringContent{\n  position: absolute;\n  top: 2px;\n  right: 5px;\n  z-index: 1;\n}\n.filteringContentWithLoading{\n  position: absolute;\n  top: 2px;\n  right: 130px;\n  z-index: 1;\n}\n.loadingContent {\n  position: absolute;\n  top: 2px;\n  right: 5px;\n  z-index: 1;\n}\n.contentGridWrapper {\n  height: calc(100vh - 130px);\n  overflow: auto;\n  overflow-x: hidden;\n}\n.hideTimeline{\n    height: calc(100vh - 70px)!important;\n    overflow: auto;\n    overflow-x: hidden;\n  }\n",".filteringContent {\n  position: absolute;\n  top: 2px;\n  right: 5px;\n  z-index: 1;\n}\n\n.filteringContentWithLoading {\n  position: absolute;\n  top: 2px;\n  right: 130px;\n  z-index: 1;\n}\n\n.loadingContent {\n  position: absolute;\n  top: 2px;\n  right: 5px;\n  z-index: 1;\n}\n\n.contentGridWrapper {\n  height: calc(100vh - 130px);\n  overflow: auto;\n  overflow-x: hidden;\n}\n\n.hideTimeline {\n  height: calc(100vh - 70px) !important;\n  overflow: auto;\n  overflow-x: hidden;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
