<template >
  <v-main style="width:100%;height:100%;" class="px-0 py-0">
    <v-card width="100%" height="100%">
      <iframe
        width="100%"
        height="100%"
        src="https://vviewer.com "
        title="rdp"
      ></iframe>
    </v-card>
  </v-main>
</template>

<script>
import store from "../../store";
import { basicView } from "../../utils/privileges";
export default {
  components: {},
  data() {
    return {
      state: store.state
    };
  },
  methods: {},

  computed: {
    isBasicView(){
      return basicView();
    },
  },
  mounted: function() {
    if (this.isBasicView){
      this.$router.push({ path: "/home" });
    }
  }
};
</script>

<style scoped lang="scss">
</style>