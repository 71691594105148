<template>
  <v-list
    tree-line
    v-if="messagesArray.length !==0 || Object.keys(state.FileTransferModal.files).length > 0"
    :style="`${customHeight ? 'height:500px' : 'height: 310px'}`"
  >
    <vue-scroll class="vueScroll">
      <div style="height: 310px">
        <v-list-item-group active-class="primary--text">
          <!-- this shows when files are available for download -->
          <div v-if="Object.keys(state.FileTransferModal.files).length !==0">
            <div v-for="(entry, key) in state.FileTransferModal.files" v-bind:key="key">
              <FileItemList :entry="entry" />
            </div>
          </div>
          <!-- This shows for when files are not available for download -->
          <div v-for="(message, index) in messagesArray" :key="index">
            <template v-for="(individual, i) in message" @click="setTotalNotificationFile(message)">
              <v-list-item
                :key="i"
                v-if="(individual || {}).info"
                :class="message.read === false ? 'notiUnread': ''"
              >
                <template>
                  <v-avatar
                    tile
                    size="60"
                    class="borderRadius10 cursorPointer"
                  >
                    <v-img
                      class="mx-2 borderRadius10"
                      max-height="60"
                      max-width="60"
                      contain
                      :src="getAvatarForUuid(message.userUUID)"
                    ></v-img>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="getNameForUuid(message.userUUID)"></v-list-item-title>
                    <v-list-item-subtitle class="text--primary" v-text="individual.fileName"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text
                      v-text="new Date(message.date).toLocaleDateString([], { hour: '2-digit', minute:'2-digit'})"
                    ></v-list-item-action-text>
                    <v-flex class="mt-5">
                      <v-tooltip location="top">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon disabled>
                            <font-awesome-icon :icon="['fal', 'download']" color="grey lighten-1"></font-awesome-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('components.downloadsDropdown.fileNotAvailable') }}</span>
                      </v-tooltip>
                      <v-tooltip location="top">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            icon
                          >
                            <DeleteBasket :indexData="message" :delFunction="setTotalNotificationFile" :extraFunction="removeMessage" :extraFunctionParams="[message.userUUID, message.uuidMessage, undefined]" extraClasses="" fontSize="17" prevent= true />
                          </v-btn>
                        </template>
                        <span>{{ $t('generics.delete') }}</span>
                      </v-tooltip>
                    </v-flex>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </template>
          </div>
        </v-list-item-group>
      </div>
    </vue-scroll>
    <v-tooltip location="top">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          style="float:right;margin: 5px 5px 0px 0px;"
          class="btnRed"
          icon
          color="white"
          @click="removeAllListedItems()"
        >
        <DeleteBasket indexData="" :delFunction="removeAllListedItems" extraClasses="" fontSize="20" prevent= false />
        </v-btn>
      </template>
      <span>{{ $t('components.downloadsDropdown.removeAll') }}</span>
    </v-tooltip>
  </v-list>
  <v-list v-else>
    <v-list-item>
      <v-list-item-title>{{ $t('components.notificationDropDown.noNewFiles') }}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>
<script>
import store from "../../store";
import FileItemList from "./fileItemList.vue";
import moment from "../../../sharedsrc/moment";
import DeleteBasket from "../ui/deleteBasket.vue";
export default {
  props: ["customHeight"],
  components: { FileItemList, DeleteBasket },
  data() {
    return {
      state: store.state,
      persisted: store.state.persisted,
      moment,
      user: store.state.user,
    };
  },
  watch: {},
  methods: {
    setTotalNotificationFile: function (message) {
      let totalChips = this.state.persisted.totalNotificationFile;
      if (!message.read && totalChips > 0) {
        store.setTotalNotificationFile(--totalChips);
      }
      if (!message.read) {
        store.setMessageAsRead(message.uuidMessage, message);
      }
    },
    remove(uuid, filename) {
      store.delFileTransferFor(uuid, filename);
    },
    removeMessage: function (...args) {
      store.removeMessage(...args);
    },
    getNameForUuid: function (...args) {
      return store.getNameForUuid(...args);
    },
    getAvatarForUuid: function (...args) {
      return store.getAvatarForUuid(...args);
    },
    setShowModalNotify: function (...args) {
      store.setShowModalNotify(...args);
    },
    settotalNotification: function (...args) {
      store.settotalNotification(...args);
    },
    removeAllListedItems: function (...args) {
      if (this.messagesArray.length > 0) {
        if (this.messagesArray[0].info !== undefined) {
          const type = this.messagesArray[0].info.notificationType;
          this.messagesArray.forEach((message) => {
            if (message.info.notificationType === type) {
              store.setTotalNotificationFile(0);
              this.removeMessage(
                message.userUUID,
                message.uuidMessage,
                undefined
              );
            }
          });
        }
      }
    },
  },
  computed: {
    percentDone() {
      if (!this.entry.sizeSoFar) return 0;
      return ((100 / this.entry.expectedSize) * this.entry.sizeSoFar).toFixed(
        1
      );
    },
    md5Match() {
      return this.entry.expectedMD5 === this.entry.actualMD5;
    },
    messagesArray: function () {
      const messages = [];
      for (let userUUID in store.state.persisted.userMessages.messages) {
        const userMessages =
          store.state.persisted.userMessages.messages[userUUID];
        if (!userMessages) continue;
        for (let msgUUID in userMessages) {
          const date = userMessages[msgUUID].date;
          const notificationType = userMessages[msgUUID].info.notificationType;
          if (notificationType === "file") {
            const fileKey =
              userUUID + "_" + userMessages[msgUUID].info.fileName;
            if (!store.state.FileTransferModal.files[fileKey]) {
              messages.push({ ...userMessages[msgUUID], userUUID, date });
            } else {
              store.state.FileTransferModal.files[fileKey].msgUUID = msgUUID;
            }
          }
        }
      }
      return messages.sort((a, b) => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);
        return aDate > bDate
          ? -1
          : aDate < bDate
          ? 1
          : (a.uuid || "").localeCompare(b.uuid || "");
      });
    },
  },
};
</script>
<style scoped lang="scss">
.notiUnread {
  background-color: #f5f5f5;
}
.btnRed {
  // background: red;
  border-radius: 4px;
}
.vueScroll {
  .__view {
    width: unset !important;
  }
}
.divNotNotis {
  height: 40px;
  padding: 9px;
  .notNotifications {
    color: var(--form-text);
  }
}
</style>