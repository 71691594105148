<template>
  <v-dialog
    persistent
    :model-value="showTransferVisitorModal" @update:model-value="showTransferVisitorModal = $event"
    max-width="560"
    @keydown.esc="closeTransferVisitorModal"
  >
    <v-card>
      <!-- <v-card-title class="headline justify-center">
        <v-layout row wrap justify-start>
          <v-img class="mx-2 logo" max-height="40" max-width="40" src="assets/icon.png" contain></v-img>
          <label style="margin-left: 50px; line-height: 2em;">{{getModalTitle(visitor.name)}}</label>
        </v-layout>
        
      </v-card-title> -->
      <HeaderModal :titleModal="getModalTitle" />
      <v-divider></v-divider>
      <v-row class="px-2 mx-0">
        <!-- <v-col cols="4">{{$t('components.inviteVisitor.visitorType')}}</v-col> -->
        <!-- <v-col cols="8">
           <v-radio-group :model-value="isWaitingRoom" @update:model-value="isWaitingRoom = $event">
            <v-radio
              class="mx-2"
              :label="$t('components.inviteVisitor.waitingRoomCall')"
              value="isWaitingRoom"
            ></v-radio>
            <v-radio
              class="mx-2"
              :label="$t('components.inviteVisitor.directCall')"
              value="isGuest"
            ></v-radio>
           </v-radio-group>
        </v-col> -->
        <v-col cols="4" class="d-flex">
          <span class="align-center d-flex">
            {{ $t("components.inviteVisitor.transferTo") }}
          </span>
        </v-col>
        <v-col cols="8">
          <v-combobox
            :model-value="selectedUser" @update:model-value="selectedUser = $event"
            :items="getUserList"
            select
            :search-input.sync="searchUsers"
            item-text="name"
            :label="$t('generics.users')"
            density="compact"
            variant="outlined"
            auto-select-first
            @change="searchUsers = ''"
            hide-details="auto"
          ></v-combobox>
        </v-col>
      </v-row>
      <!-- <v-divider></v-divider>
      <v-layout row wrap justify-end style="margin: 0px;">
        <v-row class="px-2 btns mx-0" flex style="margin: 0px;">
        <v-col class="px-2 py-2" align="right">
          <v-btn :disabled="!isDataValid" color="primary" @click="prepTransfer">{{$t('components.inviteVisitor.transfer')}}</v-btn>
       
          <v-btn color="primary" @click="closeTransferVisitorModal">{{ $t('generics.close') }}</v-btn>
        </v-col>
      </v-row>
      </v-layout> -->
      <FooterModal
        :closeModalFunction="closeTransferVisitorModal"
        :showFooter="true"
      >
        <v-btn :disabled="!isDataValid" color="primary" @click="prepTransfer">{{
          $t("components.inviteVisitor.transfer")
        }}</v-btn>
      </FooterModal>
    </v-card>
  </v-dialog>
</template>


<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  props: [
    "showTransferVisitorModal",
    "closeTransferVisitorModal",
    "doTransferVisitor",
    "visitor",
  ],
  data: () => ({
    state: store.state,
    save: false,
    isWaitingRoom: "isWaitingRoom",
    selectedUser: "",
    searchUsers: null,
    rules: [(value) => !!value || "Required."],
  }),
  mounted() {
    if (this.visitor.isWaitingRoom) {
      this.isWaitingRoom = "isWaitingRoom";
    } else {
      this.isWaitingRoom = "isGuest";
    }
  },
  methods: {
    getUserTitel(uuid) {
      return store.getTitelForUuid(uuid);
    },
    prepTransfer() {
      if (this.selectedUser.value && this.isWaitingRoom !== "") {
        this.doTransferVisitor(
          this.selectedUser.value,
          this.uuid,
          this.isWaitingRoom
        );
      }
    },
  },
  computed: {
    getModalTitle() {
      return this.$t("components.inviteVisitor.transferVisitorName", [
        this.visitor.user.name,
      ]);
    },
    getUserList() {
      const persons = Object.keys(store.state.group)
        .filter((uuid) => {
          const person = store.state.group[uuid];
          return (
            person &&
            person.user &&
            person.user.name &&
            person.permissions !== 10 &&
            !person.user.guest &&
            !person.user.visitor
          );
        })
        .map((uuid) => {
          const person = store.state.group[uuid];
          // if (person.user.titel) {
          return {
            name: this.getUserTitel(uuid) + " " + person.user.name,
            value: uuid,
          };
          // } else {
          //   return { name: person.user.name, value: uuid };
          // }
        });
      return persons.sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
      });
    },
    uuid() {
      return (
        this.visitor.visitorId || this.visitor.uuid || this.visitor.user?.uuid
      );
    },
    isDataValid() {
      // console.log(this.selectedUser)
      if (
        this.isWaitingRoom &&
        this.iswaiting != "" &&
        this.selectedUser &&
        this.selectedUser != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btns {
  justify-content: flex-end;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}

.logo {
  left: 0;
  position: absolute;
}
</style>