import { combine } from 'effector';
import { setRepresentativeUserUUIDEffect, setConferenceForRepresentiveEffect, removeConferenceForRepresentiveEffect, setVisitorForRepresentiveEffect } from './effects';
import { uuidToRepresentStore, actingAsUuidStore, representedUsersStore } from './state';
import { representativeHasChangedEvent, resetRepresentedUuidEvent } from './events';
import { wsConnectionStateStore } from '../ws';
import { resetAndRefetchDatesEvent } from '../timeline';

// actingAsUuidStore.watch((state) => {
//   if (!state) return;
//   setRepresentativeUserUUIDEffect(state);
// });

combine(wsConnectionStateStore, actingAsUuidStore, (wsConnected, uuid) => {
  if (!wsConnected || !uuid) return;
  setRepresentativeUserUUIDEffect(uuid);
});

setRepresentativeUserUUIDEffect.done.watch(({ params, result }) => {
  // console.log('setRepresentativeUserUUIDEffect done', params, result);
  representativeHasChangedEvent();
});

setRepresentativeUserUUIDEffect.fail.watch(({ params, error }) => {
  // console.log('setRepresentativeUserUUIDEffect fail', params, error);
  if (error.message && error.message.indexOf('Timeout') !== -1) return;
  resetRepresentedUuidEvent();
});

setConferenceForRepresentiveEffect.done.watch(({ params, result }) => {
  // console.log('setConferenceForRepresentiveEffect done', params, result);
});

setConferenceForRepresentiveEffect.fail.watch(({ params, error }) => {
  // console.log('setConferenceForRepresentiveEffect fail', params, error);
});

removeConferenceForRepresentiveEffect.done.watch(({ params, result }) => {
  // console.log('removeConferenceForRepresentiveEffect done', params, result);
});

removeConferenceForRepresentiveEffect.fail.watch(({ params, error }) => {
  // console.log('removeConferenceForRepresentiveEffect fail', params, error);
});

setVisitorForRepresentiveEffect.done.watch(({ params, result }) => {
  // console.log('setVisitorForRepresentiveEffect done', params, result);
});

setVisitorForRepresentiveEffect.fail.watch(({ params, error }) => {
  // console.log('setVisitorForRepresentiveEffect fail', params, error);
});

representativeHasChangedEvent.watch(() => {
  // console.log('representativeHasChangedEvent');
  resetAndRefetchDatesEvent();
});

combine(uuidToRepresentStore, representedUsersStore, (representing, representable) => {
  // console.log('reset representative combine', representing, representable);
  if (!representing || !representable) return;
  if (representable.indexOf(representing) === -1) {
    resetRepresentedUuidEvent();
  }
});
