<template>
  <v-app id="sandbox" >
    <v-app-bar :clipped-left="primaryDrawer.clipped" app class="toTheLeft">
      <!-- <v-app-bar-nav-icon   v-if="primaryDrawer.type !== 'permanent'"/> -->
       <v-img class="mx-2" src="assets/icon.png" max-height="45" max-width="45" contain ></v-img>
       <v-toolbar-title class="linkApp d-none d-md-flex font-weight-bold body-1" >vOffice</v-toolbar-title>
    </v-app-bar>
    <v-main class="justPaddingTop"> 
      <v-card fill-height class="mx-auto elevation-0 text-center" flat max-width="100%">
        <v-card-title class="primary--text fontSize22">
          Decrypt Error
        </v-card-title>
        <v-divider></v-divider>
        <v-row flex class="mx-0">
          <v-col cols="12" class="px-0 py-0 text-center">
            <v-card-text class="fontSize17">
              {{$t('components.decryptError.errorText')}}
            </v-card-text>
          </v-col>
         
        </v-row>
        <v-spacer></v-spacer>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import store from "../../store";
export default {
  data() {
    return {
      isLarge: this.$vuetify.display.lgAndUp,
      isSmall: this.$vuetify.display.mdOnly,
      isXSmall: this.$vuetify.display.smAndDown,
      drawers: ['Default (no property)', 'Permanent', 'Temporary'],
      primaryDrawer: {
        model: null,
        type: 'default (no property)',
        clipped: false,
        floating: false,
        mini: false,
      },
      state: store.state,
      setSetUpConfigRoute: store.setSetUpConfigRoute,
      email: "",
      valid: true,
      requestingAccess: false,
      showResult: false,
      message: '',
      emailSent: false,
      emailRules: [
        v => !!v || "",
        v => (v || '').indexOf(' ') < 0 || "No spaces are allowed",
        v => /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) ||  this.$t('generics.emailMustBeValid')
      ]
    };
  },
  methods: {
   
  }
};
</script>

<style scoped lang="scss">
.toTheLeft{
  left: 0px !important;
}
.justPaddingTop{
  padding: 64px 0px 0px 0px !important;
}
.fontSize17{
  font-size: 17px !important;
}
.fontSize22{
  font-size: 22px !important;
}
.title {
  color: #2a3133;
}
.requestButton {
  background-color: #2a3133 !important;
  border-color: #2a3133 !important;
}
.linkApp {
  text-decoration: unset;
  color: unset !important;
}
</style>