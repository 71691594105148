// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dayTimeOverlay[data-v-c7b948aa] {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0px;
  display: grid;
  font-size: 14px;
  width: 100%;
}
.keepLineaBreak[data-v-c7b948aa] {
  white-space: pre-line;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/conferenceDateTimeOverlay.vue","webpack://./conferenceDateTimeOverlay.vue"],"names":[],"mappings":"AACA;EACE,oCAAA;EACA,kBAAA;EACA,QAAA;EACA,aAAA;EACA,eAAA;EACA,WAAA;ACAF;ADEA;EACE,qBAAA;ACCF","sourcesContent":["\n.dayTimeOverlay {\n  background-color: rgba(0, 0, 0, 0.50);\n  position: absolute;\n  top: 0px;\n  display: grid;\n  font-size: 14px;\n  width: 100%;\n}\n.keepLineaBreak{\n  white-space:pre-line\n}\n",".dayTimeOverlay {\n  background-color: rgba(0, 0, 0, 0.5);\n  position: absolute;\n  top: 0px;\n  display: grid;\n  font-size: 14px;\n  width: 100%;\n}\n\n.keepLineaBreak {\n  white-space: pre-line;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
