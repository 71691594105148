// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.colorWhite[data-v-4366217e] {
  color: white !important;
}
.containerTeams[data-v-4366217e] {
  max-height: 470px;
  min-height: 150px;
  overflow: auto;
}
.cardWidth[data-v-4366217e] {
  max-width: 100px;
  box-shadow: none !important;
}
.theme--light .gradientBg[data-v-4366217e] {
  background: linear-gradient(rgba(0, 0, 0, 0.2509803922), rgba(0, 0, 0, 0.6));
}
.theme--dark .gradientBg[data-v-4366217e] {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8));
}
.gradientBg[data-v-4366217e] {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/showTeamsModal.vue","webpack://./showTeamsModal.vue"],"names":[],"mappings":"AACA;EACE,uBAAA;ACAF;ADEA;EACE,iBAAA;EACA,iBAAA;EACA,cAAA;ACCF;ADCA;EACE,gBAAA;EACA,2BAAA;ACEF;ADAA;EACE,4EAAA;ACGF;ADAA;EACE,mEAAA;ACGF;ADAA;EACE,kBAAA;ACGF","sourcesContent":["\n.colorWhite {\n  color: white !important;\n}\n.containerTeams {\n  max-height: 470px;\n  min-height: 150px;\n  overflow: auto;\n}\n.cardWidth {\n  max-width: 100px;\n  box-shadow: none !important;\n}\n.theme--light .gradientBg {\n  background: linear-gradient(#00000040, #00000099);\n}\n\n.theme--dark .gradientBg {\n  background: linear-gradient(#00000099, #000000cc);\n}\n\n.gradientBg {\n  position: relative;\n}\n",".colorWhite {\n  color: white !important;\n}\n\n.containerTeams {\n  max-height: 470px;\n  min-height: 150px;\n  overflow: auto;\n}\n\n.cardWidth {\n  max-width: 100px;\n  box-shadow: none !important;\n}\n\n.theme--light .gradientBg {\n  background: linear-gradient(rgba(0, 0, 0, 0.2509803922), rgba(0, 0, 0, 0.6));\n}\n\n.theme--dark .gradientBg {\n  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8));\n}\n\n.gradientBg {\n  position: relative;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
