// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-theme--dark .v-menu__content[data-v-6311cc1c] {
  background-color: #1e1e1e;
}
.v-theme--light .v-menu__content[data-v-6311cc1c] {
  background-color: #fff;
}
.v-menu__content[data-v-6311cc1c] {
  /* width */
  /* Track */
}
.v-menu__content[data-v-6311cc1c] ::-webkit-scrollbar {
  width: 8px;
}
.v-menu__content[data-v-6311cc1c] ::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.v-menu__content[data-v-6311cc1c] ::-webkit-scrollbar-thumb {
  background: #2a3133;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/userActionBar/receivedAndSentmessages.vue","webpack://./receivedAndSentmessages.vue"],"names":[],"mappings":"AACA;EACE,yBAAA;ACAF;ADEA;EACE,sBAAA;ACCF;ADCA;EAEE,UAAA;EAIA,UAAA;ACFF;ADDE;EACE,UAAA;ACGJ;ADAE;EACE,mBAAA;EACA,eAAA;EACA,kBAAA;ACEJ;ADAE;EACE,mBAAA;EACA,mBAAA;ACEJ","sourcesContent":["\n.v-theme--dark .v-menu__content {\n  background-color: #1e1e1e;\n}\n.v-theme--light .v-menu__content {\n  background-color: #fff;\n}\n.v-menu__content {\n  // max-height: 370px !important;\n  /* width */\n  ::-webkit-scrollbar {\n    width: 8px;\n  }\n  /* Track */\n  ::-webkit-scrollbar-track {\n    border-radius: 10px;\n    margin-top: 8px;\n    margin-bottom: 8px;\n  }\n  ::-webkit-scrollbar-thumb {\n    background: #2a3133;\n    border-radius: 10px;\n  }\n}\n",".v-theme--dark .v-menu__content {\n  background-color: #1e1e1e;\n}\n\n.v-theme--light .v-menu__content {\n  background-color: #fff;\n}\n\n.v-menu__content {\n  /* width */\n  /* Track */\n}\n.v-menu__content ::-webkit-scrollbar {\n  width: 8px;\n}\n.v-menu__content ::-webkit-scrollbar-track {\n  border-radius: 10px;\n  margin-top: 8px;\n  margin-bottom: 8px;\n}\n.v-menu__content ::-webkit-scrollbar-thumb {\n  background: #2a3133;\n  border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
