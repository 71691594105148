<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    persistent
    max-width="570"
    @keydown.esc="closeModal"
  >
      <v-card class="mx-auto">
        <HeaderModal
          :titleModal="$t('generics.changeLog')"
          :closeModalFunction="closeModal"
        />
        <!-- <v-tabs class="pl-4 pb-3">
          <v-tab v-on:click="toggleType('interface')">Interface</v-tab>
          <v-tab v-on:click="toggleType('tech')">Technik</v-tab>
        </v-tabs> -->
        <v-card-text class="scrollBox">
          <div class="changeLogDiv">
            {{ changelogText }}
          </div>
        </v-card-text>
      <FooterModal class="mt-4" :closeModalFunction="closeModal">
      </FooterModal>
      <v-spacer></v-spacer>
      </v-card>
  </v-dialog>
</template>

<script>
import { useStore } from "effector-vue/composition";
import {
  resetChangelogModalEvent,
  changelogModalStore,
} from "../../effector/modals";
import { getChangelog } from "../../lib/wsMsg";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
export default {
  components: { HeaderModal, FooterModal },
  data() {
    const effector = {
    showModal: changelogModalStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      changelogText: "",
      type: "interface",
      // Effector
      ...effector,
    }
  },
    watch: {
    type() {
      this.loadChangelog();
    },
  },
  mounted() {
    this.loadChangelog();
  },
  methods: {
    loadChangelog() {
      this.changelogText = "Loading...";
      getChangelog(this.type, this.$locale.current().toLowerCase())
        .then(text => (this.changelogText = text))
        .catch(err => (this.changelogText = err.message));
    },
    closeModal() {
      resetChangelogModalEvent();
    },
    toggleType(value) {
      if (value === "interface" || value === "tech") {
        this.type = value;
      }
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.scrollBox {
    overflow-y: scroll;
    }
.changeLogDiv {
  word-break: break-word;
  white-space: pre-line;
  max-height:350px;
}
.pointer {
  cursor: pointer;
}
.thumbs-down {
  color: red;
}
.thumbs-up {
  color: green;
}
</style>