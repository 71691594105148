import Vue from '@vue/compat';
import { createI18n, useI18n } from 'vue-i18n';
import moment from "../sharedsrc/moment";
import { defaultLocale, messages, availableLocales } from './_locales/index.js';

const navigatorLanguage = () => {
  return window.navigator.language && `${window.navigator.language}`.slice(0, 2).toLowerCase();
};

const getLanguage = () => {
  const language = window.localStorage.getItem('language') || navigatorLanguage() || defaultLocale;
  const supported = language && availableLocales.includes(language);
  if (supported) return language;
  return defaultLocale;
};

const i18n = createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: getLanguage(),
  allowComposition: true,
  fallbackLocale: defaultLocale,
  messages
});
moment.locale(i18n.global.locale.value);

const { t: $t, tm: $tm } = i18n.global;
Vue.prototype.$t = $t;
Vue.prototype.$tm = $tm;

const $locale = {
  change(language) {
    i18n.global.locale.value = language;
    window.localStorage.setItem('language', language);
    moment.locale(i18n.global.locale.value);
  },
  current() {
    return i18n.global.locale.value;
  }
};
Vue.prototype.$locale = $locale;

export {
  i18n,
  useI18n,
  $t,
  $tm,
  $locale,
};
