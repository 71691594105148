// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.speechLiveIconWhite.v-theme--light.v-btn.v-btn--icon[data-v-0b9d4876] {
  color: black !important;
}
.speechLiveIcon[data-v-0b9d4876] {
  position: relative;
  background-color: #c1c1c1 !important;
}
.speechLiveIconWhite[data-v-0b9d4876] {
  position: relative;
  background-color: #c1c1c1 !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/speechLiveIcon.vue","webpack://./speechLiveIcon.vue"],"names":[],"mappings":"AACA;EACE,uBAAA;ACAF;ADGA;EACE,kBAAA;EACA,oCAAA;ACAF;ADEA;EACE,kBAAA;EACA,oCAAA;ACCF","sourcesContent":["\n.speechLiveIconWhite.v-theme--light.v-btn.v-btn--icon{\n  color: black!important;\n}\n\n.speechLiveIcon {\n  position: relative;\n  background-color: #c1c1c1 !important;\n}\n.speechLiveIconWhite{\n  position: relative;\n  background-color: #c1c1c1 !important;\n}\n",".speechLiveIconWhite.v-theme--light.v-btn.v-btn--icon {\n  color: black !important;\n}\n\n.speechLiveIcon {\n  position: relative;\n  background-color: #c1c1c1 !important;\n}\n\n.speechLiveIconWhite {\n  position: relative;\n  background-color: #c1c1c1 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
