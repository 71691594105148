<template>
 <v-dialog
    :model-value="showModal"
    @update:model-value="showModal = $event" persistent max-width="560" @keydown.esc="closeModal">
    <v-card>
      <!-- <v-card-title class="text-h5 d-flex">
         <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
         {{ $t('components.2faDisable.title') }} {{tfaActiveCodeLabel}}
        </v-card-title> -->
      <HeaderModal
        :titleModal="$t('components.2faDisable.title')"
        :closeModalFunction="closeModal"
      />
      <v-card-text>
        <div v-if="enabled === 'sms' || (phoneNumber && smsRecovery)" class="d-flex">
          <div class="tex0t-truncate text-body-1" v-if="phoneNumber">
            <span>{{ $t('components.2faSms.phoneNumberLine') }}</span>
            {{ phoneNumberCensored }}
          </div>
          <div class="tex0t-truncate text-body-1" v-if="!phoneNumber">
            <span>{{ $t('components.2faSms.noPhoneNumberLine') }}</span>
          </div>
        </div>
        <div v-if="enabled === 'sms'" class="d-flex mb-4">
          <v-btn
            variant="outlined"
            density="compact"
            hide-details
            :disabled="requestSmsDone"
            small
            color="error"
            @click="requestSms()"
          >{{ $t('components.2faSms.sendSMS') }}</v-btn>
          <span v-if="requestSmsDone" class="px-2 align-center d-flex">{{ $t('components.2fa.sentSMSMsg') }}</span>
        </div>
        <v-alert v-if="errMsg && !showCodeInput" type="error" :dismissible="true">{{ errMsg }}</v-alert>
        <div v-if="showCodeInput" class="mt-3">
          <v-text-field
            @keyup.enter="tfaCode && disable2fa()"
            autocomplete="off"
            :model-value="tfaCode" @update:model-value="tfaCode = $event"
            :label="tfaCodeLabel"
            :append-icon="`${showPassword ? 'mdi-eye-off' : 'mdi-eye'}`"
            :type="`${showPassword ? 'text' : 'password'}`"
            @click:append="showPass()"
            placeholder=" "
            variant="outlined"
            density="compact"
            @keydown.space.prevent
            @keypress="onCodeInput"
            autofocus
            required
          ></v-text-field>
            <!-- :rules="[v => !!v || this.$t('components.2faOtp.codeRequired')]" -->
          <v-alert v-if="errMsg" type="error" :dismissible="true">{{ errMsg }}</v-alert>
          <div class="d-flex justify-end mt-3">
            <v-btn
              color="primary"
              hide-details
              small
              :disabled="!tfaCode"
              @click="disable2fa()"
            >{{ $t('components.2faModal.btnDisable') }}</v-btn>
          </div>
        </div>
      </v-card-text>
      <!-- footer modal -->
      <!-- <v-card  class="py-2 pl-4 mt-4 pr-2 footerModal footersBackground">
        <v-row class="px-2 btns mx-0" flex>
          <v-col cols="6" class="px-0" align="left">
            <v-btn
              v-if="enabled !== 'sms' && smsRecovery"
              color="primary"
              @click="handleForgotPin()"
            >{{ getForgotPinText() }}</v-btn>
          </v-col>
          <v-col cols="6" class="px-0" align="right">
             <v-btn @click="closeModal()"
              color="primary"
              style="border-radius: 4px; min-width: 45px;"
              icon
              tile
              variant = "outlined"
              density="comfortable"
              class="ml-3 mr-2 greyBtn"
              rounded="5px"
          > 
             <font-awesome-icon
            :icon="['fal', 'times']"
            :style="{ fontSize: '20px' }"
          />
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-spacer></v-spacer> -->
      <FooterModal :closeModalFunction="closeModal" class="mt-4">
        <v-btn
              v-if="enabled !== 'sms' && smsRecovery"
              color="primary"
              class="mx-2"
              @click="handleForgotPin()"
            >{{ getForgotPinText() }}</v-btn>
      </FooterModal>
    </v-card>
    <TwoFactorActivationModal v-if="showActivation" 
        :showActivation="showActivation" 
        :toggle2faActivationModal="toggle2faActivationModal" 
        :saveEntryNoPhone="saveEntryNoPhone" 
      ></TwoFactorActivationModal>
      <TwoFactorSendSmsModal v-if="showSendSMSModal" 
        :toggleShowSendSMS="toggleShowSendSMS" 
        :showSendSMSModal="showSendSMSModal" 
        :requestSms="requestSms" 
      ></TwoFactorSendSmsModal>
  </v-dialog>
</template>

<script>
import store from "../../../store";
import { status2FA, setup2FA, disable2FA } from "../../../lib/wsMsg";
import TwoFactorSendSmsModal from "./steps/2faSendSmsModal.vue";
import TwoFactorActivationModal from "./steps/2faActivationModal.vue";
import FooterModal from "../../modal/modalComponents/footerModal.vue";
import HeaderModal from "../../modal/modalComponents/headerModal.vue";

export default {
  props: ["showModal", "toggle2faDisable", "redirectTo", "goToModal"],
  components:{TwoFactorSendSmsModal, TwoFactorActivationModal, FooterModal, HeaderModal},
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      requestSmsDone: false,
      enabled: false,
      pinProtectionType: "",
      smsRecovery: false,
      errMsg: "",
      tfaCode: "",
      showPassword: false,
      showSendSMSModal: false,
      showActivation: false,
    };
  },
  methods: {
    onCodeInput(evt) {
      if (this.pinProtectionType === 'complex') return;
      if (evt.which < 48 || evt.which > 57) evt.preventDefault();
    },
    getForgotPinText(){
      if (this.enabled == "otp"){
        return this.$t('components.2faSms.forgotOTP'); 
      } else {
        return this.$t('components.2faSms.forgotPin');
      }
    },
    saveEntryNoPhone(){
     this.showActivation = false; 
    },
    handleForgotPin (){
      // if no phone show phone entry modal
      if (!this.phoneNumber){
        this.showActivation = true; 
      } else {
        this.showSendSMSModal = true;
      }      
    },
    toggle2faActivationModal(){
      this.showActivation = !this.showActivation;
    },
    toggleShowSendSMS(){
      this.showSendSMSModal = !this.showSendSMSModal;
    },
    showPass(){
      this.showPassword = !this.showPassword;
    },
    goToNewModal(where){
      this.goToModal(where)
    },
    async loadData() {
      const enabled = await status2FA();
      if (enabled && enabled.method === 'sms') await this.requestSms();
      this.enabled = enabled ? enabled.method : false;
      this.pinProtectionType = enabled ? (enabled.type || 'simple') : 'simple';
      this.smsRecovery = enabled ? (enabled.admin && enabled.smsRecovery && enabled.method !== 'sms') : false;
    },
    closeModal() {
      this.toggle2faDisable();
    },
    async requestSms() {
      this.errMsg = "";
      const result = await setup2FA("sms").catch(err => {
        this.errMsg = err.message || err;
        console.warn(err);
        return false;
      });
      if (result) {
        this.requestSmsDone = true;
      } else if (!this.errMsg) {
        this.errMsg = this.$t('components.2faSms.anErrorOccurred');
      }
    },
    async disable2fa() {
      this.errMsg = "";
      const result = await disable2FA(this.tfaCode).catch(err => {
        this.errMsg = err.message || err;
        console.warn(err);
        return false;
      });
      if (result) {
        if(this.redirectTo){
          this.goToNewModal(this.redirectTo)
        }
        this.toggle2faDisable();
      } else if (!this.errMsg) {
        this.errMsg = this.$t('components.2faOtp.invalidCode');
      }
    }
  },
  computed: {
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    phoneNumber() {
      if (this.smsRecovery) return this.state.user.recoveryPhone;
      return (this.state.user.mobilePhone && this.state.user.mobilePhone !== 'unknown')
        ? (this.state.user.mobilePhone ? `${this.state.user.mobilePhone}` : '')
        : (this.state.user.phone ? `${this.state.user.phone}` : '');
    },
    phoneNumberCensored() {
      if (this.phoneNumber) return this.phoneNumber.replace(/^(\+[\d]{2})(.+)([\d]{2})$/, (...matches) => {
        return matches[1] + matches[2].replace(/\d/g, '*') + matches[3];
      });
    },
    showCodeInput() {
      return (this.requestSmsDone || (this.enabled !== 'sms'));
    },
    tfaActiveCodeLabel(){
       switch (this.enabled) {
        case "otp":
          return this.$t("components.2fa.OTPMethod");
        case "pin":
          if (this.pinProtectionType === 'complex') {
            return this.$t("components.2fa.pinMethodComplex"); 
          } else {
            return this.$t("components.2fa.pinMethod");
          } 
        case "sms":
          return this.$t("components.2fa.smsMethod");
      }
      return "";
    },
    tfaCodeLabel() {
      switch (this.enabled) {
        case "otp":
          return this.$t("components.2fa.enterOtpCode");
        case "pin":
          if (this.pinProtectionType === 'complex') {
            return this.$t("components.2fa.enterComplexPinCode"); 
          } else {
            return this.$t("components.2fa.enterPinCode");
          } 
        case "sms":
          return this.$t("components.2fa.enterSmsCode");
      }
      return "";
    }
  },
  watch: {
    showModal: {
      immediate: true,
      handler: function(value) {
        if (value) {
          this.loadData();
        } else {
          this.enabled = false;
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
