// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.waitingRoomBadge[data-v-556a5b3a] {
  height: 100%;
}
.footerImg[data-v-556a5b3a] {
  position: relative;
  top: 14px;
  margin: auto;
  border-radius: 50% !important;
}
.footerElement[data-v-556a5b3a] {
  width: 25%;
}
.fullHeight[data-v-556a5b3a] {
  height: 100% !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/mobile/footernav.vue","webpack://./footernav.vue"],"names":[],"mappings":"AACA;EACE,YAAA;ACAF;ADEA;EACE,kBAAA;EACA,SAAA;EACA,YAAA;EACA,6BAAA;ACCF;ADCA;EACE,UAAA;ACEF;ADAA;EACE,uBAAA;ACGF","sourcesContent":["\n.waitingRoomBadge{\n  height: 100%;\n}\n.footerImg{\n  position: relative;\n  top: 14px;\n  margin: auto;\n  border-radius: 50% !important;\n}\n.footerElement{\n  width: calc(100% / 4);\n}\n.fullHeight{\n  height: 100% !important;\n}\n\n",".waitingRoomBadge {\n  height: 100%;\n}\n\n.footerImg {\n  position: relative;\n  top: 14px;\n  margin: auto;\n  border-radius: 50% !important;\n}\n\n.footerElement {\n  width: 25%;\n}\n\n.fullHeight {\n  height: 100% !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
