// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroller[data-v-db28f41a] {
  height: 100%;
}
.vueScrollTemplates[data-v-db28f41a] {
  height: 260px !important;
}
@media screen and (max-height: 650px) {
.vueScrollTemplates[data-v-db28f41a] {
    height: 260px !important;
}
}
.vueScrollTemplates .__view[data-v-db28f41a] {
  width: unset !important;
}
.vueScrollOne[data-v-db28f41a] {
  height: 65px !important;
}
@media screen and (max-height: 650px) {
.vueScrollOne[data-v-db28f41a] {
    height: 65px !important;
}
}
.vueScrollTwo[data-v-db28f41a] {
  height: 130px !important;
}
@media screen and (max-height: 650px) {
.vueScrollTwo[data-v-db28f41a] {
    height: 130px !important;
}
}
.vueScrollThree[data-v-db28f41a] {
  height: 195px !important;
}
@media screen and (max-height: 650px) {
.vueScrollThree[data-v-db28f41a] {
    height: 195px !important;
}
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/templateList.vue","webpack://./templateList.vue"],"names":[],"mappings":"AAEA;EACE,YAAA;ACDF;ADGA;EACE,wBAAA;ACAF;ADCI;AAFJ;IAGM,wBAAA;ACEJ;AACF;ADDE;EACE,uBAAA;ACGJ;ADCA;EACE,uBAAA;ACEF;ADDG;AAFH;IAGM,uBAAA;ACIJ;AACF;ADDA;EAEE,wBAAA;ACGF;ADFG;AAHH;IAIM,wBAAA;ACKJ;AACF;ADFA;EAEE,wBAAA;ACIF;ADHG;AAHH;IAIM,wBAAA;ACMJ;AACF","sourcesContent":["\n\n.scroller {\n  height: 100%;\n}\n.vueScrollTemplates{\n  height: 260px !important;\n    @media screen and (max-height: 650px) {\n      height: 260px !important;\n    }\n  & .__view{\n    width: unset !important;\n  }\n}\n\n.vueScrollOne{\n  height: 65px !important;\n   @media screen and (max-height: 650px) {\n      height: 65px!important;\n  }\n}\n\n.vueScrollTwo{\n  // max-height: calc(100vh - 720px)!important;\n  height: 130px !important;\n   @media screen and (max-height: 650px) {\n      height: 130px!important;\n  }\n}\n\n.vueScrollThree{\n  // max-height: calc(100vh - 720px)!important;\n  height: 195px !important;\n   @media screen and (max-height: 650px) {\n      height: 195px!important;\n  }\n}\n\n",".scroller {\n  height: 100%;\n}\n\n.vueScrollTemplates {\n  height: 260px !important;\n}\n@media screen and (max-height: 650px) {\n  .vueScrollTemplates {\n    height: 260px !important;\n  }\n}\n.vueScrollTemplates .__view {\n  width: unset !important;\n}\n\n.vueScrollOne {\n  height: 65px !important;\n}\n@media screen and (max-height: 650px) {\n  .vueScrollOne {\n    height: 65px !important;\n  }\n}\n\n.vueScrollTwo {\n  height: 130px !important;\n}\n@media screen and (max-height: 650px) {\n  .vueScrollTwo {\n    height: 130px !important;\n  }\n}\n\n.vueScrollThree {\n  height: 195px !important;\n}\n@media screen and (max-height: 650px) {\n  .vueScrollThree {\n    height: 195px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
