<template>
<!--  attach="#remotePersonCallDiv" -->
 <v-dialog
    :model-value="showModal"
    @update:model-value="showModal = $event" persistent max-width="560">
    <template>
      <v-card class="mx-auto">
         <!-- Title -->
        <HeaderModal
          :titleModal="$t('components.conferenceForm.titleInviteUser')"
          :closeModalFunction="closeModal"
        />
        <!-- End title -->

        <!-- Modal body -->
        <v-card-text>
          <v-row class="mx-0">
            <v-col cols="12">
              <v-combobox
                :model-value="selectedUser" @update:model-value="selectedUser = $event"
                :items="dataCombobox"
                :search-input.sync="searchUsers"
                :item-disabled="disableItem"
                select
                item-text="name"
                item-value="uuid"
                :label="$t('components.conferenceForm.vOfficeUsers')"
                density="compact"
                variant="outlined"
                class="mt-3"
                @change="handleChangeUsers"
                hide-details
                autofocus
              ></v-combobox>
            </v-col>
            <v-col cols="12" v-if="isConference">
               <v-checkbox
                :model-value="showUserName" @update:model-value="showUserName = $event"
                :label="$t('components.conferenceForm.showVisitorName')"
                class="mt-0 fontSize14 mb-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :model-value="userFunction" @update:model-value="userFunction = $event"
                variant="outlined"
                density="compact"
                hide-details="auto"
                :label="$t('components.inviteVisitor.function')"
                :autofocus="editingFunction"
                :readonly="!editingFunction"
              >
                <template slot="append" class="noMarginTop">
                  <v-btn icon  @click="edit()" color="primary">
                      <font-awesome-icon :icon="['fas', 'pen']" :style="{ fontSize: '17px' }" />
                  </v-btn>
                  <v-btn icon @click="editFunction()" color="primary">
                      <font-awesome-icon :icon="['fal', 'save']" :style="{ fontSize: '17px' }" />
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" v-if="isConference">
               <v-checkbox
                :model-value="isModerator" @update:model-value="isModerator = $event"
                :label="$t('components.conferenceForm.moderator')"
                class="mt-0 fontSize14 mb-0"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- End modal body -->

        <!-- Footer -->
        <FooterModal :hideDefaultButtons="true">
            <v-btn
              color="primary"
              flat
              :disabled="!selectedUser"
              @click="saveMember(true)"
            >
             {{ $t('components.addGroupModal.add')}}
            </v-btn>
            <v-btn
              color="primary"
              flat
              :disabled="!selectedUser"
              @click="saveMember(false)"
            >
               {{ $t('components.addGroupModal.addplus')}}
            </v-btn>
          </FooterModal>
        <!-- End footer -->
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import store from "../../../store";
import HeaderModal from "../../modal/modalComponents/headerModal.vue";
import FooterModal from "../../modal/modalComponents/footerModal.vue";
export default {
  components:{HeaderModal, FooterModal},
  props: ["showModal", "closeModal", "isConference", "members", "addMember"],
  data: () => ({
    state: store.state,
    isModerator: false,
    showUserName: false,
    userFunction: '',
    editingFunction: false,
    selectedUser: null,
    searchUsers: null

  }),
  mounted(){},
  methods: {
    edit(){
      this.editingFunction = true;
    },
    editFunction(){
      if ( this.userFunction !== '' ){
        this.editingFunction = false;
        this.selectedUser.userFunction = this.userFunction;
      }
    },
    handleChangeUsers(user){
      if ( user ){
        this.userFunction = this.getUserFunction;
      }
    },
    disableItem(item) {
      return this.members.find(e => e.uuid === item.uuid) != undefined;
    },
    saveMember(close){
      if ( this.selectedUser ){
        this.selectedUser.isModerator = this.isModerator
        this.selectedUser.userFunction = this.userFunction;
        if ( this.addMember ){
          this.addMember(this.selectedUser);
        }
        if ( close ){
          this.closeModal();
        }

        this.resetModalUsers();
      }
    },
    resetModalUsers(){
      this.searchUsers = null;
      this.selectedUser = null;
      this.isModerator = false;
      this.showUserName = false;
      this.userFunction = '';
      this.editingFunction = false;
    }
  },
  computed: {
    getUserFunction() {
      if (this.selectedUser){
        const userUUID = this.selectedUser.uuid
        if (this.state.group[userUUID].user && this.state.group[userUUID].user.position){
          this.userFunction = this.state.group[userUUID].user.position;
        } else {
          this.userFunction = "";
        }
      }
      return this.userFunction;
    },
    dataCombobox() {
      const usersArray = [];
      const users = this.state.group || {};
      for (const key in users) {
        let data;
        if ( this.isConference ){
          data = { 
            name: (users[key].user || {}).name || "", 
            uuid: key, 
            isGuest: false,
            isModerator: this.isModerator,
            showUserName: this.showUserName,
            userFunction: this.userFunction
          };
        }else{
          data = { 
            name: (users[key].user || {}).name || "", 
            uuid: key, 
            isGuest: false,
            isModerator: this.isModerator,
            userFunction: this.userFunction
          };
        }
        if ( data ){
          if (data.uuid !== store.state.ownUUID && data.name !== "") {
            if (this.members.find(e => e.uuid === data.uuid)) continue;
              if(!users[key].user.visitor){
               usersArray.push(data);
              }
          }
        }
      }
      usersArray.sort((a, b) => a.name.localeCompare(b.name));
      return usersArray;
    },
  },
 
};
</script>

<style scoped lang="scss">

</style>