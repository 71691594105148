import { allUsersState } from './state';

const userStateFilterCache = {};

export function getUserStateStore(uuid) {
  if (!userStateFilterCache[uuid]) {
    userStateFilterCache[uuid] = allUsersState.map(state => state[uuid]);
  }
  // console.log('userStateFilterCache', uuid, userStateFilterCache[uuid], userStateFilterCache);
  return userStateFilterCache[uuid];
}
