import { callBridgeIsFullDomain } from './domain';

import { setCallBridgeIsFullEvent, resetCallBridgeIsFullEvent } from './events';

export const callBridgeIsFullStore = callBridgeIsFullDomain.createStore(false, { name: 'callBridgeIsFull' });

callBridgeIsFullStore.on(setCallBridgeIsFullEvent, (state, payload) => {
  return payload;
});

callBridgeIsFullStore.reset(resetCallBridgeIsFullEvent);
