import { combine } from 'effector';
import { groupConferenceDomain } from './domain';
import {
  setGroupConferenceUUIDsEvent,
  setGroupConferencesInfoBatchedEvent,
  deleteGroupConferenceEntryAndUUIDEvent,
  setGroupConferenceDetailEvent,
  setGroupConferenceInfoEvent
} from './events';
import { resetAndRefetchDatesEvent } from '../timeline/events';
// import { ownUserUUIDState } from '../users';
export const myGroupConferenceStore = groupConferenceDomain.createStore([], { name: 'myGroupConferenceStore' }); // List of event UUIDs that I care about (owner of, invited by, etc)

const myGroupConferenceDetails = groupConferenceDomain.createStore([], { name: 'myGroupsConferenceDetails' });

myGroupConferenceDetails.on(setGroupConferenceInfoEvent, (state, payload) => {
  const [confId, entry] = payload;
  let extant = null
  let changed = false;
  if (!confId || !entry || confId !== entry.confId) {
    console.warn('Invalid call to setGroupConferenceInfoEvent');
    return state;
  }
  const newArray = [...state];
  if(newArray && newArray.length && newArray[0]) {
    extant = newArray.find(e => e.confId === entry.confId);
  }
  
  if (!extant) {
    newArray.push(entry);
    changed = true;
  } else if (extant.updatedServerTs < entry.updatedServerTs) {
    Object.assign(extant, entry);
    changed = true;
  }
  return changed ? newArray : state; // If nothing new was added/altered, send same state to prevent updates
});

myGroupConferenceStore.on(setGroupConferenceUUIDsEvent, (state, payload) => {
  const newArray = [...state];
  let changed = false;
  payload.forEach((i) => {
    const extant = newArray.find(e => e.confId === i.confId);
    if (!extant) {
      newArray.push(i);
      changed = true;
    } else if (extant.updatedServerTs < i.updatedServerTs) {
      extant.updatedServerTs = i.updatedServerTs;
      changed = true;
    }
  });
  return changed ? newArray : state; // If nothing new was added/altered, send same state to prevent updates
});

myGroupConferenceDetails.on(setGroupConferenceDetailEvent, (state, payload) => {
  return [...(payload || [])];
});

myGroupConferenceDetails.on(deleteGroupConferenceEntryAndUUIDEvent, (state, payload) => {
  if(state && state.length && state[0]) {
    return state.filter(e => e.confId !== payload);
  }
  return [];
});

myGroupConferenceStore.on(deleteGroupConferenceEntryAndUUIDEvent, (state, payload) => {
  return state.filter(t => t.confId !== payload);
});

export const cachedConferences = groupConferenceDomain.createStore({}, { name: 'cachedConferences' });
cachedConferences.on(myGroupConferenceStore, (state, payload) => {
  return { ...state, [payload[0]]: payload[1] };
});

cachedConferences.on(setGroupConferencesInfoBatchedEvent, (state, payload) => {
  return { ...state, ...payload };
});

cachedConferences.on(deleteGroupConferenceEntryAndUUIDEvent, (state, payload) => {
  const newState = { ...state };
  delete newState[payload];
  return newState;
});

const myGroupConferences = combine(myGroupConferenceStore, cachedConferences, (uuids, cachedConferences) => {
  const confs = [];
  for (const uuidObj of uuids) {
    const uuid = uuidObj.uuid;
    confs.push(cachedConferences[uuid] ? cachedConferences[uuid] : uuid);
  }
  return confs;
});

myGroupConferenceStore.reset(resetAndRefetchDatesEvent);
myGroupConferenceDetails.reset(resetAndRefetchDatesEvent);
cachedConferences.reset(resetAndRefetchDatesEvent);

export const myConferenceDetails = myGroupConferenceDetails.map(s => s.filter(e => typeof e === 'object'))
export const unknownGroupConferences = myGroupConferences.map(s => s.filter(e => typeof e === 'string'));
export const groupConferences = myGroupConferences.map(s => s.filter(e => typeof e === 'object'));
