// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-img__img--preload[data-v-8ebf1212] {
  filter: blur(1px) !important;
}
.textBlue[data-v-8ebf1212] {
  color: #2a3133;
}
.btnRed[data-v-8ebf1212] {
  border-radius: 4px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.vueScroll[data-v-8ebf1212] {
  overflow: auto;
  height: 310px;
}
.vueScroll .__view[data-v-8ebf1212] {
  width: unset !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/notificationDropDown.vue","webpack://./notificationDropDown.vue"],"names":[],"mappings":"AACA;EACE,4BAAA;ACAF;ADEA;EACE,cAAA;ACCF;ADCA;EACE,kBAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;ACEF;ADAA;EACE,cAAA;EACA,aAAA;ACGF;ADFE;EACE,uBAAA;ACIJ","sourcesContent":["\n.v-img__img--preload {\n  filter: blur(1px) !important;\n}\n.textBlue {\n  color: #2a3133;\n}\n.btnRed {\n  border-radius: 4px;\n  position: absolute;\n  bottom: 0;\n  right: 0;\n}\n.vueScroll {\n  overflow: auto;\n  height: 310px;\n  .__view {\n    width: unset !important;\n  }\n}\n",".v-img__img--preload {\n  filter: blur(1px) !important;\n}\n\n.textBlue {\n  color: #2a3133;\n}\n\n.btnRed {\n  border-radius: 4px;\n  position: absolute;\n  bottom: 0;\n  right: 0;\n}\n\n.vueScroll {\n  overflow: auto;\n  height: 310px;\n}\n.vueScroll .__view {\n  width: unset !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
