// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-application .whiteBorder {
  border-color: #fff !important;
  border: 2px solid #fff !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/alerts/alertItem.vue","webpack://./alertItem.vue"],"names":[],"mappings":"AAEE;EACE,6BAAA;EACA,iCAAA;ACDJ","sourcesContent":["\n.v-application {\n  .whiteBorder {\n    border-color: #fff !important;\n    border: 2px solid #fff !important;\n  }\n}\n",".v-application .whiteBorder {\n  border-color: #fff !important;\n  border: 2px solid #fff !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
