// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonWhiteBackground[data-v-ddf4ff42] {
  background-color: white !important;
}
.twitter-cropper[data-v-ddf4ff42] {
  height: 256px;
  width: 256px;
}
.twitter-cropper__background[data-v-ddf4ff42] {
  background-color: #edf2f4;
}
.twitter-cropper__foreground[data-v-ddf4ff42] {
  background-color: #edf2f4;
}
.twitter-cropper__stencil[data-v-ddf4ff42] {
  border: solid 5px rgb(29, 161, 242);
}
.croppie-container[data-v-ddf4ff42] {
  height: unset;
}
.croppie-container .cr-viewport[data-v-ddf4ff42],
.croppie-container .cr-resizer[data-v-ddf4ff42] {
  border: 2px solid #2a3133 !important;
}
.rotateIcons[data-v-ddf4ff42] {
  position: absolute;
  right: 16%;
  top: 40%;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/uploadGroupConfImg.vue","webpack://./uploadGroupConfImg.vue"],"names":[],"mappings":"AACA;EACE,kCAAA;ACAF;ADEA;EACE,aAAA;EACA,YAAA;ACCF;ADAE;EACE,yBAAA;ACEJ;ADAE;EACE,yBAAA;ACEJ;ADAE;EACE,mCAAA;ACEJ;ADCA;EACE,aAAA;ACEF;ADAA;;EAEE,oCAAA;ACGF;ADDA;EACE,kBAAA;EACA,UAAA;EACA,QAAA;ACIF","sourcesContent":["\n.buttonWhiteBackground{\n  background-color: white !important;\n}\n.twitter-cropper {\n  height: 256px;\n  width: 256px;\n  &__background {\n    background-color: #edf2f4;\n  }\n  &__foreground {\n    background-color: #edf2f4;\n  }\n  &__stencil {\n    border: solid 5px rgb(29, 161, 242);\n  }\n}\n.croppie-container {\n  height: unset;\n}\n.croppie-container .cr-viewport,\n.croppie-container .cr-resizer {\n  border: 2px solid #2a3133 !important;\n}\n.rotateIcons {\n  position: absolute;\n  right: 16%;\n  top: 40%;\n}\n",".buttonWhiteBackground {\n  background-color: white !important;\n}\n\n.twitter-cropper {\n  height: 256px;\n  width: 256px;\n}\n.twitter-cropper__background {\n  background-color: #edf2f4;\n}\n.twitter-cropper__foreground {\n  background-color: #edf2f4;\n}\n.twitter-cropper__stencil {\n  border: solid 5px rgb(29, 161, 242);\n}\n\n.croppie-container {\n  height: unset;\n}\n\n.croppie-container .cr-viewport,\n.croppie-container .cr-resizer {\n  border: 2px solid #2a3133 !important;\n}\n\n.rotateIcons {\n  position: absolute;\n  right: 16%;\n  top: 40%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
