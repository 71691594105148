// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-application .v-card__title[data-v-8fa123f4] {
  font-size: 18px !important;
  margin-bottom: 0px;
}
.bodyContainer[data-v-8fa123f4] {
  margin: 40px auto;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/fullCallModal.vue","webpack://./fullCallModal.vue"],"names":[],"mappings":"AACA;EACE,0BAAA;EACA,kBAAA;ACAF;ADEA;EACE,iBAAA;ACCF","sourcesContent":["\n.v-application .v-card__title {\n  font-size: 18px !important;\n  margin-bottom: 0px;\n}\n.bodyContainer {\n  margin: 40px auto;\n}\n",".v-application .v-card__title {\n  font-size: 18px !important;\n  margin-bottom: 0px;\n}\n\n.bodyContainer {\n  margin: 40px auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
