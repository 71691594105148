import { timelineEventModalDomain } from './domain';
import { EventBus } from '../../../store';
import { setEventStateModalEvent, resetEventStateModalEvent, setReminderModaltState } from './events';

export const eventModalStateStore = timelineEventModalDomain.createStore(null, { name: 'eventModalState' });

// eventModalStateStore.on(setEventStateModalEvent);

eventModalStateStore.on(setEventStateModalEvent, (state, payload) => {
  // console.log('Updated Modal Event..... ', payload)
  EventBus.$emit('setActingUUID');
  return payload;
});

eventModalStateStore.on(setReminderModaltState, (state, payload) => {
  return payload;
});

// eventModalStateStore.on(resetEventStateModalEvent, (state, payload) => {
//   return payload;
// });

eventModalStateStore.reset(resetEventStateModalEvent);

// eventModalStateStore.watch((state) => { console.log('eventModalStateStore', state); });
