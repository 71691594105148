import { persistedDomain } from './domain';

import {
  savedPersistedStateEvent,
} from './events';

export const persistedStateStore = persistedDomain.createStore({}, { name: 'persistedState' });

persistedStateStore.on(savedPersistedStateEvent, (state, payload) => { 
 // console.log('saved persisted ')
  return payload; 
});

