<template>
  <div class="localButtonsBar myLocal">
    <!-- left side bar -->
    <div class="leftLocalBar">
        <!-- END CHAT BUTTON -->
      <v-tooltip location="top">
        <template v-slot:activator="{ props }">
          <v-btn
            large
            icon
            color="white"
            class="darkGreyIcon pAbsoluteLeft"
            @click="openCloseChat"
            v-bind="props"
          >
            <font-awesome-icon
              :icon="['fal', 'comments']"
              :style="{ fontSize: '20px' }"
            />
            <v-badge
              v-if="hasUnreadChatMessages"
              right
              color="error"
              overlap
              offset-x="25"
              offset-y="3"
              class="docsBadgeDots"
              :content="totalUnreadChatMessages"
              :value="hasUnreadChatMessages"
            ></v-badge>
          </v-btn>
        </template>
        <span>{{ $t("components.callsContent.openGroupChat") }}</span>
      </v-tooltip>
      <!-- END CHAT BUTTON -->
      <!-- USER LIST BUTTON -->
      <v-tooltip location="top">
        <template v-slot:activator="{ props }">
          <v-btn
            large
            icon
            color="white"
            class="darkGreyIcon pAbsoluteLeftUserList"
            @click="toggleUserList"
            v-bind="props"
          >
            <font-awesome-icon
              :icon="['fal', 'users']"
              :style="{ fontSize: '20px' }"
            />
          </v-btn>
        </template>
        <span>{{ $t("components.adminSettingsModal.userInfo") }}</span>
      </v-tooltip>
       <!-- END USER LIST BUTTON -->
    </div>
    <!-- end left side bar -->
    <!-- center side bar d -->
    <div class="rightLocalBar">
      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-btn
            large
            icon
            color="white"
            class="toggleMuteAudio darkGreyIcon"
            :class="{ 'mx-3': isMobile, 'mx-6': !isMobile }"
            v-bind="props"
            :disabled="!videoReady"
            @click="toggleMuteVideoFunction()"
          >
            <font-awesome-icon
              v-if="!audioOutputMuted && videoReady"
              :icon="['fal', 'volume-up']"
              :style="{ fontSize: '20px' }"
            />
            <img
              v-if="audioOutputMuted && videoReady"
              class="volume-up-slash"
              src="/img/volume-slash-red-white.svg"
            />
            <img
              v-if="!videoReady"
              class="volume-up-slash"
              src="/img/volume-slash-red-white-grey.svg"
            />
          </v-btn>
        </template>
        <span>{{
          audioOutputMuted
            ? $t("components.callsContent.unmuteSoundAllParticipants")
            : $t("components.callsContent.muteAllParticipants")
        }}</span>
      </v-tooltip>
      <v-tooltip location="top" v-if="!isMobile">
        <template #activator="{ props }">
          <v-btn
            large
            icon
            color="white"
            v-bind="props"
            @click="toggleLocalFullScreen()"
            class="darkGreyIcon"
            :class="{ 'mx-3': isMobile, 'mx-6': !isMobile }"
          >
            <font-awesome-icon
              :icon="['fal', 'expand-wide']"
              :style="{ fontSize: '20px' }"
            />
          </v-btn>
        </template>
        <span
          >{{
            !fullScreenActive
              ? $t("generics.fullscreen")
              : $t("generics.normalScreen")
          }}
        </span>
      </v-tooltip>
      <v-tooltip location="top" v-if="!isMobile">
        <template #activator="{ props }">
          <v-btn
            large
            icon
            color="white"
            v-bind="props"
            class="darkGreyIcon"
            :class="{ 'mx-3': isMobile, 'mx-6': !isMobile }"
            @click="toggleRequestToSpeakConferenceHall()"
          >
            <font-awesome-icon
              :icon="['fal', 'hand-point-up']"
              :style="{ fontSize: '20px' }"
              v-if="!requestedRaiseHand"
            />
             <font-awesome-icon
              :icon="['fas', 'hand-point-up']"
              :style="{ fontSize: '20px' }"
              v-else
            />
           
          </v-btn>
        </template>
        <span
          > {{
              !requestedRaiseHand
                ? $t("components.callsContent.requestWord")
                : $t("components.callsContent.endRequestWord")
            }} 
        </span>
      </v-tooltip>
      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-btn
            large
            icon
            color="white"
            v-bind="props"
            :loading="rejectingCall"
            :disabled="rejectingCall"
            @click="rejectCallFromLocal()"
            class="bg-red"
            :class="{ 'mx-3': isMobile, 'mx-6': !isMobile }"
          >
            <font-awesome-icon
              :icon="['fal', 'phone']"
              class="faPhoneRotate"
              :style="{ fontSize: '20px' }"
            />
            <template v-slot:loader>
              <span class="custom-loader">
                <font-awesome-icon
                  :icon="['fal', 'spinner-third']"
                  :style="{ fontSize: '15px', color: 'white' }"
                />
              </span>
            </template>
          </v-btn>
        </template>
        <span>{{ $t("generics.hangUp") }}</span>
      </v-tooltip>
      <!--  3 POINT MENU -->
      <div class="pAbsoluteRight">
        <v-menu
          class="customMenuLocal"
          content-class="customDotsMenuLocal"
          :model-value="menuOpen" @update:model-value="menuOpen = $event"
          right
          :offset-x="true"
          :close-on-content-click="true"
          z-index="999999"
          :attach="!isMobile ? '.myLocal' : false"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              large
              class="buttonsCall darkGreyIcon"
              :class="{ 'mx-6': !isMobile }"
              color="white"
              icon
             
              @click.stop.prevent
              v-bind="props"
            >
              <font-awesome-icon
                :icon="['fal', 'ellipsis-v']"
                :style="{ fontSize: '20px' }"
              />
              <v-badge
                v-if="hasDocuments"
                right
                color="error"
                overlap
                offset-x="25"
                offset-y="3"
                class="docsBadgeDots"
                :content="getTotalDocumentsRead"
                :value="getTotalDocumentsRead"
              ></v-badge>
            </v-btn>
          </template>
          <v-list class="text-left" dark>
            <v-list-item @click="toggleCamMic" v-if="!fullScreenActive">
              <v-icon>
                <font-awesome-icon
                  :icon="['fal', 'cog']"
                  :style="{ fontSize: '20px' }"
                />
              </v-icon>
              <v-list-item-title>
                {{ $t("components.navbar.camAndMic") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="!hasDocuments"
              @click="openConferenceDocumentsModal()"
            >
              <v-icon>
                <font-awesome-icon
                  :icon="['fal', 'file-upload']"
                  :style="{ fontSize: '20px' }"
                />
                <v-badge
                  v-if="hasDocuments"
                  right
                  color="error"
                  overlap
                  offset-x="25"
                  offset-y="3"
                  class="docsBadge"
                  :content="getTotalDocumentsRead"
                  :value="getTotalDocumentsRead"
                ></v-badge>
              </v-icon>
              <v-list-item-title>
                {{ $t("components.callsContent.documentManagementShare") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- END 3 POINT MENU -->
      </div>
    </div>
      <DocumentsManager
        v-if="showConferenceDocumentsModal"
        :showModal="showConferenceDocumentsModal"
        :eventType="isConferenceCall ? 'conference' : 'uuid'"
        :documentsStore="isConferenceCall ? conferenceStore : callStore"
        :amIModerator="getAmIModerator"
        :attachTo="'iframe'"
        :setTempDocStore="setTempDocStore"
        :isConferenceCall="isConferenceCall ? isConferenceCall : false"
      />
  </div>
</template>
<script>
import { useStore } from "effector-vue/composition";
import store, { EventBus } from "../../../store";
import { webLicensedBaseFeatures } from "../../../../sharedsrc/licensedFeatures";
import { isMobile } from "../../../lib/mobileUtil";
import {
  getCompanyDocumentsForCall,
} from "../../../lib/wsMsg";
import { bridgeInfoStore } from "../../../effector/users/bridgeInfo";
import {
  newCompanyDocumentEvent,
  updateCompanyDocumentEvent,
  myCompanyDocumentsDetails
} from "../../../effector/companyDocuments";
import {
  conferenceDocumentsStore,
  setConferenceDocumentsEvent
} from "../../../effector/modals";
import { v4 as uuidv4 } from "uuid";
import DocumentsManager from "../../documents/documentsMgr.vue";
import {
  myConferenceDetails,
} from "../../../effector/groupConferences";
import {
  myConferenceHallDetails,
} from "../../../effector/conferenceHalls";
import ManageConference from "../../conferenceLeftBar/manageConference.vue";
import {
  timelineEvents,
} from "../../../effector/timeline";
import LocalLeftBar from "./LocalBar/localLeftBar.vue";
import { isConferenceCall } from "../../../utils/calls";

export default {
  components: { DocumentsManager, ManageConference, LocalLeftBar },
  props: [
    "rejectCall",
    "rejectingCall",
    "toggleVideo",
    "micMute",
    "toggleMuteAudio",
    "toggleVideoMute",
    "toggleLocalFullScreen",
    "localFullScreen",
    "toggleShareScreen",
    "currentDesktop",
    "getMyDesktopRoomUserID",
    "room",
    "stopShareScreen",
    "getconfId",
    "presenterMode",
    "fullScreenActive",
    "speakerView",
    "getTotalReceivedChatMessages",
    "chatOpen",
    "getIsConferenceHall",
    "toggleRequestToSpeakConferenceHall",
    "requestToSpeakConferenceHall",
    "chatMessageNotificationSound",
    // new props
    "toggleOpenChat",
    "toggleUserList",
    "requestedRaiseHand",
    "videoReady",
    "toggleVideoClapprPlayerMute"
  ],
  data() {
    const effector = {
      bridgeInfoStore: bridgeInfoStore,
      showConferenceDocumentsModal: conferenceDocumentsStore,
      myConferenceDetails: myConferenceDetails,
      myConferenceHallDetails: myConferenceHallDetails,
      cacheTimeline: timelineEvents,
      myDocuments: myCompanyDocumentsDetails,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      menuOpen: false,
      isMobile: isMobile(),
      windowWidth: window.innerWidth,
      totalChatMessagesRead: 0,
      chatMounted: false,
      hasVideoDevice: false,
      runtimeStopStartShareScreenActive: false,
      loadingMic: false,
      loadingVideo: false,
      audioOutputMuted: this.isVideoMuted,
      tempDocumentStore: null,
      totalDocumentsRead: 0,
      // Effector
      ...effector,
    };
  },
  watch: {
    docCount: {
      handler: function (totalDocuments) {
        if (this.totalDocumentsRead > totalDocuments) {
          this.totalDocumentsRead = totalDocuments;
        }
      },
    },
    getTotalReceivedChatMessages: {
      handler: function (received) {
        if (
          received &&
          received !== this.totalChatMessagesRead &&
          this.chatOpen
        ) {
          this.totalChatMessagesRead = this.getTotalReceivedChatMessages;
        }
        // fire sound notification
        if (
          received &&
          received !== this.totalChatMessagesRead &&
          !this.chatOpen &&
          this.chatMounted &&
          this.chatMessageNotificationSound
        ) {
          this.chatMessageNotification();
        }
      },
    },
    currentDesktop: {
      handler: function (id) {
        if (!id && this.runtimeStopStartShareScreenActive) {
          this.runtimeStopStartShareScreenActive = false;
          this.toggleShareScreen();
        }
      },
    },
  },
  mounted() {
    this.getNavigatorDevices();
    window.addEventListener("resize", this.handleResize);
    // prevent sound in mounte
    setTimeout(() => {
      this.chatMounted = true;
    }, 10000);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    openConferenceDocumentsModal() {
      setConferenceDocumentsEvent(
        this.isConferenceCall ? this.isConferenceCall : this.getCallUUID
      );
      this.totalDocumentsRead = this.docCount;
    },
    setTempDocStore(tempStore) {
      this.tempDocumentStore = tempStore;
    },
    async toggleMuteVideoFunction() {
      let audioOutputMuted = await this.toggleVideoMute();
      if (audioOutputMuted === null) {
        audioOutputMuted = await this.toggleVideoClapprPlayerMute();
      }
      if (audioOutputMuted !== null) {
        this.audioOutputMuted = audioOutputMuted;
      }
    },
    rejectCallFromLocal() {
      const callUUID= this.state.user.inBridgeCallListener
      store.removeRemoteBridgeStreams(callUUID);
    },
    stopStartShareScreen() {
      if (
        this.currentDesktop &&
        this.currentDesktop == this.getMyDesktopRoomUserID
      ) {
        this.runtimeStopStartShareScreenActive = true;
        this.stopShareScreen();
      }
    },
    getNavigatorDevices() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        return;
      }
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            if (device.kind === "videoinput") {
              this.hasVideoDevice = true;
            }
          });
        })
        .catch((err) => {
          console.error(err.name + ": " + err.message);
        });
    },
    chatMessageNotification() {
      const audioPath = '/media/notificationSound.mp3';
      // Get real devices id's
      const ringingDeviceId = store.state.persisted.mediaDeviceSetup.ringingOutputId;
      const audioDeviceId = store.state.persisted.mediaDeviceSetup.audioOutputId;
      // Pre-Set outputs
      const audioOutput = new Audio(audioPath);
      const ringingOutput = new Audio(audioPath);
      // Sync audioDevice
      let promise = Promise.resolve();
      if ('sinkId' in audioOutput && 'setSinkId' in audioOutput && audioDeviceId) {
        promise = audioOutput.setSinkId(audioDeviceId);
      }
      promise
        .then(() => audioOutput.play())
        .catch(err => console.warn('calls(callListenerLocalButtons) Failed to play notification audio on audioOutput', err));
      // Sync && Play at ringing device only if we have ringingDeviceId for it and if it's different from audioOutputId
      if (audioDeviceId && ringingDeviceId && 'sinkId' in ringingOutput && 'setSinkId' in ringingOutput && ringingDeviceId !== audioDeviceId) {
        promise = ringingOutput.setSinkId(ringingDeviceId);
        promise
          .then(() => ringingOutput.play())
          .catch(err => console.warn('calls(callListenerLocalButtons) Failed to play notification audio on ringingOutput', err));
      }
    },
    openCloseChat() {
      if (
        !this.chatOpen &&
        this.totalChatMessagesRead !== this.getTotalReceivedChatMessages
      ) {
        this.totalChatMessagesRead = this.getTotalReceivedChatMessages;
      }
      this.toggleOpenChat();
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    getConferenceId() {
      return this.getconfId;
    },
    toggleCamMic() {
      EventBus.$emit("togleMicSettings");
    },
    async createDocumentStore(id) {
      if (this.isConferenceCall) {
        let original = {}
        if(this.getIsConferenceHall){
          original = this.myConferenceHallDetails.find((e) => e.confId == id);
        }else{
          original = this.myConferenceDetails.find((e) => e.confId == id);
        }
        if (original && original.confId) {
          const existingDocStore = await getCompanyDocumentsForCall(
            id,
            "conference"
          );
          if (existingDocStore) {
            existingDocStore.docMembersUUIDS = original.confUUIDS;
            existingDocStore.moderators = original.confModerators;
            await updateCompanyDocumentEvent(existingDocStore);
          } else {
            const entry = {
              docStoreUUID: uuidv4(),
              docMembersUUIDS: original.confUUIDS,
              moderators: original.confModerators,
              creator: original.creator,
              callUUID: false,
              confId: id,
              timelineId: false,
              templateId: false,
              documents: [],
            };
            await newCompanyDocumentEvent(entry);
          }
        }
      } else {
        const existingDocStore = await getCompanyDocumentsForCall(id, "uuid");
        const tmpUsersArray = [];
        if (this.bridgeInfoStore && this.bridgeInfoStore.users) {
          Object.keys(this.bridgeInfoStore.users).forEach((user) => {
            if (tmpUsersArray.indexOf(user == -1)) {
              tmpUsersArray.push(user);
            }
          });
        }

        if (existingDocStore) {
          existingDocStore.docMembersUUIDS = tmpUsersArray;
          existingDocStore.moderators = [this.state.ownUUID];
          await updateCompanyDocumentEvent(existingDocStore);
        } else {
          const entry = {
            docStoreUUID: uuidv4(),
            docMembersUUIDS: tmpUsersArray,
            moderators: [this.state.ownUUID],
            creator: this.state.ownUUID,
            callUUID: id,
            confId: false,
            timelineId: false,
            templateId: false,
            documents: [],
          };
          await newCompanyDocumentEvent(entry);
        }
      }
    },
  },
  computed: {
    isPkg() {
      return webLicensedBaseFeatures.isPkg;
    },
    getTotalDocumentsRead() {
      const total = this.docCount - this.totalDocumentsRead;
      if (total <= 0) {
        return 0;
      } else {
        return total;
      }
    },
    docCount() {
      if (this.isConferenceCall) {
        let cStore = this.conferenceStore;
        if (cStore && cStore.documents) {
          return cStore.documents.length;
        } else {
          return 0;
        }
      } else {
        let total = 0;
        let callUUID = this.getCallUUID;
        let docs = this.myDocuments.find((e) => e.callUUID === callUUID);
        if (docs && docs.documents.length) {
          total = docs.documents.length;
        }
        return total;
      }
    },
    getCallUUID(){
      return this.state.user?.inBridgeCallListener || false;
    },
    isConferenceCall() {
      const callUUID = this.getCallUUID;
      return isConferenceCall(callUUID);
    },
    callStore() {
      let foundStore = null;
      let callUUID = this.getCallUUID;

      if (callUUID) {
        foundStore = this.myDocuments.find((e) => e.callUUID === callUUID);
        if (foundStore && foundStore.callUUID) {
          return foundStore;
        }
      }
      //// build more cases for different sources...
    },
    conferenceStore() {
      let foundStore = null;
      let confId = this.getConferenceId() || this.isConferenceCall;
      if (confId) {
        foundStore = this.myDocuments.find((e) => e.confId === confId);
        if (foundStore && foundStore.confId) {
          return foundStore;
        }
      }
      //// build more cases for different sources...
    },
    hasDocuments() {
      if (this.isConferenceCall) {
        let cStore = this.conferenceStore;
        if (cStore && cStore.documents && cStore.documents.length) {
          return true;
        } else {
          return false;
        }
      } else {
        let callUUID = this.getCallUUID;
        let docs = this.myDocuments.find((e) => e.callUUID === callUUID);
        if (docs && docs.documents && docs.documents.length) {
          return true;
        } else {
          return false;
        }
      }
    },
    isVideoMuted() {
      return !store.state.canAutoplayWithSound
    },
    totalUnreadChatMessages() {
      const total =
        this.getTotalReceivedChatMessages - this.totalChatMessagesRead;
      if (total <= 0) {
        return 0;
      }
      return total;
    },
    hasUnreadChatMessages() {
      if (this.totalChatMessagesRead !== this.getTotalReceivedChatMessages) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped lang="scss">
 .localButtonsBar {
    background-color: #202124;
    backdrop-filter: blur(5px);
    color: white;
    z-index: 10 !important;
    align-content: center;
    &.myLocal {
      height: 60px;
    }
  }
  .buttonsBar,
  .localButtonsBar {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    z-index: 1;
    opacity: 1;
  }
.v-btn--icon.v-size--large {
  width: 40px !important;
  height: 40px !important;
}
.darkGreyIcon {
  background-color: #464b4e !important;
  border-color: #464b4e !important;
}
.pAbsoluteLeft {
  position: absolute;
  left: 0;
}
.pAbsoluteLeftUserList{
   position: absolute;
  left: 60px;
}
.pAbsoluteRight {
  position: absolute;
  right: 0;
}
.docsBadgeDots {
  height: 15px !important;
  min-width: 15px !important;
  position: absolute !important;
  left: 35px !important;
  bottom: 0px !important;
}
.docsBadge {
  height: 15px !important;
  min-width: 15px !important;
  position: absolute !important;
  left: 35px !important;
  bottom: 15px !important;
}
.switchContainer {
  position: relative;
  top: -4px;
}
.progressBarLevelBridge {
  width: 1%;
  height: 3px;
  max-height: 3px;
  min-height: 3px;
  background-color: #2a3133;
}
.progressBarBridge {
  width: 100%;
  background-color: transparent;
  position: absolute;
  bottom: -17px;
}
.customDotsMenuLocal {
  bottom: 55px !important;
  top: unset !important;
  right: 45px !important;
  left: unset !important;
}
.microphone-slash {
  width: 25px !important;
  height: 25px !important;
  color: #fff;
}
.volume-up-slash {
  width: 25px !important;
  height: 25px !important;
  color: #fff;
}

.video-slash {
  width: 25px !important;
  height: 25px !important;
}

.imgButton {
  width: 25px;
}
.progressBarBridge {
  position: absolute !important;
  left: 0px !important;
  bottom: -17px !important;
}
.faPhoneRotate {
  transform: rotate(225deg) !important;
}
.leftLocalBar {
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;
  height: 100%;
}
.rightLocalBar {
  display: flex;
  justify-content: center;
  width: 100%;
}
.custom-loader {
  animation: loader 1s infinite;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>