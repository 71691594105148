// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fontSize16[data-v-36dbe1fc] {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/finishCallModal.vue","webpack://./finishCallModal.vue"],"names":[],"mappings":"AACE;EACE,eAAA;ACAJ","sourcesContent":["\n  .fontSize16 {\n    font-size:  16px;\n  }\n",".fontSize16 {\n  font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
