<template>
  <RegularGrid :typeData="'coffee-break'" />
</template>

<script>
import store from "../../store";
import RegularGrid from "./regularGrid.vue";
export default {
  components: { RegularGrid },
  data() {
    return {
      state: store.state,
    };
  },
  mounted() {
    if (
      this.$route.path === "/coffee-break" &&
      !this.state.namespaceSettings.showCoffeeBreak
    ) {
      this.$router.push({ path: "/home" });
    }
  },
};
</script>