// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breakSpaces[data-v-27751719] {
  white-space: break-spaces;
}
.borderRadius4[data-v-27751719] {
  border-radius: 4px !important;
}
.footerModal[data-v-27751719] {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
}
.footerModal .btns[data-v-27751719] {
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/infoPopUpModal.vue","webpack://./infoPopUpModal.vue"],"names":[],"mappings":"AACA;EACE,yBAAA;ACAF;ADGA;EACE,6BAAA;ACAF;ADGA;EACE,WAAA;EACA,6BAAA;EACA,uBAAA;ACAF;ADEE;EACE,yBAAA;ACAJ","sourcesContent":["\n.breakSpaces {\n  white-space: break-spaces;\n}\n\n.borderRadius4 {\n  border-radius: 4px !important;\n}\n\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n\n  .btns {\n    justify-content: flex-end;\n  }\n}\n",".breakSpaces {\n  white-space: break-spaces;\n}\n\n.borderRadius4 {\n  border-radius: 4px !important;\n}\n\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n}\n.footerModal .btns {\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
