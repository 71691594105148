import { joinBridgeCallModalDomain } from './domain';

import { setJoinBridgeCallModalEvent, resetJoinBridgeCallModalEvent} from './events';

export const joinBridgeCallStore = joinBridgeCallModalDomain.createStore(false, { name: ' joinBridgeCallModal' });

joinBridgeCallStore.on(setJoinBridgeCallModalEvent, (state, payload) => {
  return payload;
});

joinBridgeCallStore.reset(resetJoinBridgeCallModalEvent);
// qualityVotingModalStore.watch((state) => { console.log('qualityVotingModalStore', state); });
