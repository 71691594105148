// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
}
.footerModal .btns {
  justify-content: flex-end;
}
.v-theme--dark .inputPhone2FA .vti__dropdown.open,
.v-theme--dark .inputPhone2FA .vti__dropdown:hover {
  background-color: #202020;
  color: black;
}
.v-theme--dark .inputPhone2FA .vti__dropdown-list,
.v-theme--dark .inputPhone2FA .vti__dropdown-list:hover {
  background: #202020;
  color: white !important;
  border: none;
}
.v-theme--dark .inputPhone2FA .vti__dropdown-item.highlighted,
.v-theme--dark .inputPhone2FA .vti__dropdown-item:hover {
  background: #202020;
  color: white !important;
}
.v-theme--dark .inputPhone2FA .vti__country-code,
.v-theme--dark .inputPhone2FA .vti__dropdown-arrow {
  color: rgba(255, 255, 255, 0.7019607843) !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/2fa/modals/2faSms.vue","webpack://./2faSms.vue"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,6BAAA;EACA,uBAAA;ACAF;ADCE;EACE,yBAAA;ACCJ;ADGE;;EAEE,yBAAA;EACA,YAAA;ACAJ;ADEE;;EAEE,mBAAA;EACA,uBAAA;EACA,YAAA;ACAJ;ADEE;;EAEE,mBAAA;EACA,uBAAA;ACAJ;ADEE;;EAEE,mDAAA;ACAJ","sourcesContent":["\n.footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n  .btns {\n    justify-content: flex-end;\n  }\n}\n.v-theme--dark .inputPhone2FA {\n  .vti__dropdown.open,\n  .vti__dropdown:hover {\n    background-color: #202020;\n    color: black;\n  }\n  .vti__dropdown-list,\n  .vti__dropdown-list:hover {\n    background: #202020;\n    color: white !important;\n    border: none;\n  }\n  .vti__dropdown-item.highlighted,\n  .vti__dropdown-item:hover {\n    background: #202020;\n    color: white !important;\n  }\n  .vti__country-code,\n  .vti__dropdown-arrow {\n    color: #ffffffb3 !important;\n  }\n}\n",".footerModal {\n  width: 100%;\n  border-radius: 0px !important;\n  color: white !important;\n}\n.footerModal .btns {\n  justify-content: flex-end;\n}\n\n.v-theme--dark .inputPhone2FA .vti__dropdown.open,\n.v-theme--dark .inputPhone2FA .vti__dropdown:hover {\n  background-color: #202020;\n  color: black;\n}\n.v-theme--dark .inputPhone2FA .vti__dropdown-list,\n.v-theme--dark .inputPhone2FA .vti__dropdown-list:hover {\n  background: #202020;\n  color: white !important;\n  border: none;\n}\n.v-theme--dark .inputPhone2FA .vti__dropdown-item.highlighted,\n.v-theme--dark .inputPhone2FA .vti__dropdown-item:hover {\n  background: #202020;\n  color: white !important;\n}\n.v-theme--dark .inputPhone2FA .vti__country-code,\n.v-theme--dark .inputPhone2FA .vti__dropdown-arrow {\n  color: rgba(255, 255, 255, 0.7019607843) !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
