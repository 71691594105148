import { versionDomain } from './domain';
import { gotVersionFromSigEvent, snoozeUpdateEvent } from './events';

export const gitCommitFromSigStore = versionDomain.createStore(null, { name: 'gitCommitFromSig' });

gitCommitFromSigStore.on(gotVersionFromSigEvent, (state, payload) => { return payload; });

export const isDirtyStore = gitCommitFromSigStore.map((state) => {
  if (state === null) return false;
  const latestCommit = state.shortHash || '';
  const localCommit = process.env.PACKAGE_COMMIT || '';
  return localCommit.indexOf(latestCommit) !== 0;
});

export const lastUpdateSnoozeStore = versionDomain.createStore(null, { name: 'lastUpdateSnoozeStore' });
lastUpdateSnoozeStore.on(snoozeUpdateEvent, (state, payload) => { return Date.now(); });

// const dirty = this.state.gitCommit.committedOn !== 0 && this.state.gitCommit.shortHash !== newValue.shortHash;
// Vue.set(this.state, 'gitCommit', {
//   dirty,
//   committedOn: newValue.committedOn,
//   subject: newValue.subject,
//   shortHash: newValue.shortHash,
//   author: newValue.author.name
// });

// packageCommit: process.env.PACKAGE_COMMIT,
// packageVersion: process.env.PACKAGE_VERSION,