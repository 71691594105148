import { combine } from 'effector';
import { noteDomain } from './domain';

import { setNoteUUIDsEvent, setNotesInfoEvent, setNotesInfoBatchedEvent, deleteNoteEntryAndUUIDEvent  } from './events';

import { ownUserUUIDState } from '../users';

export const myNotesStore = noteDomain.createStore([], { name: 'myNotesStore' }); // List of event UUIDs that I care about (owner of, invited by, etc)

myNotesStore.on(setNoteUUIDsEvent, (state, payload) => {
  const newArray = [...state];
  let changed = false;
  payload.forEach((i) => {
    const extant = newArray.find(e => e.uuid === i.uuid);
    if (!extant) {
      newArray.push(i);
      changed = true;
    } else if (extant.updatedServerTs < i.updatedServerTs) {
      extant.updatedServerTs = i.updatedServerTs;
      changed = true;
    }
  });
  return changed ? newArray : state; // If nothing new was added/altered, send same state to prevent updates
});

myNotesStore.on(deleteNoteEntryAndUUIDEvent, (state, payload) => {
  return state.filter(t => t.uuid !== payload);
});

export const cachedNotes = noteDomain.createStore({}, { name: 'cachedNotes' });
cachedNotes.on(setNotesInfoEvent, (state, payload) => {
  return { ...state, [payload[0]]: payload[1] };
});

cachedNotes.on(setNotesInfoBatchedEvent, (state, payload) => {
  return { ...state, ...payload };
});

cachedNotes.on(deleteNoteEntryAndUUIDEvent, (state, payload) => {
  const newState = { ...state };
  delete newState[payload];
  return newState;
});

const myNotes = combine(myNotesStore, cachedNotes, (uuids, cachedNotes) => {
  const notes = [];
  for (const uuidObj of uuids) {
    const uuid = uuidObj.uuid;
    notes.push(cachedNotes[uuid] ? cachedNotes[uuid] : uuid);
  }
  return notes;
});

export const unknownNotes = myNotes.map(s => s.filter(e => typeof e === 'string'));
export const notes = myNotes.map(s => s.filter(e => typeof e === 'object'));

// export const messagesToMe = notes.map((s) => {
//   const ownUUID = ownUserUUIDState.getState();
//   let temporal = [];
//   temporal = s.filter((e) => {
//     return e && e.users && e.users[ownUUID] && (e.type === 'message' || e.type === 'plannerEvent') && !e.users[ownUUID].deleted;
//   });

//   return temporal.sort(function (a, b) {
//     const aDate = new Date(a.date);
//     const bDate = new Date(b.date);
//     return aDate > bDate
//       ? -1
//       : aDate > bDate
//         ? 1
//         : (a.uuid || '').localeCompare(b.uuid || '');
//   });
// });

export const unreadNotesToMe = notes.map((s) => {
  const ownUUID = ownUserUUIDState.getState();
  let unreadNoteObj = {}
  s.forEach(e => {
    if(e && e.users && e.users[ownUUID] && !e.users[ownUUID].read ){
      unreadNoteObj[e.noteUUID] = e
    }
  });
  // const unread = s.filter((e) => {
  //   // return e && e.users && e.users[ownUUID] && !e.users[ownUUID].read 
  //   if(e && e.users && e.users[ownUUID] && !e.users[ownUUID].read ){
  //     console.log("demtrppppppppp")
  //     return e.noteUUID
  //   }
  // });
  // console.log(unread, "unread")
  return unreadNoteObj
});

// export const unreadMessagesToMe = messages.map((s) => {
//   const ownUUID = ownUserUUIDState.getState();
//   const unread = s.filter((e) => {
//     return e && e.users && e.users[ownUUID] && (e.type === 'message' || e.type === 'plannerEvent') && !e.users[ownUUID].read && !e.users[ownUUID].deleted;
//   });
//   return unread.length
// });

export const outdatedNotes = combine(myNotesStore, cachedNotes, (uuidAndTimings, cachedEvents) => {
  const outdatesUUIDs = [];
  for (const uuidObj of uuidAndTimings) {
    const entry = cachedEvents[uuidObj.noteUUID];
    if (entry && uuidObj.updatedServerTs > entry.updatedServerTs) {
      outdatesUUIDs.push(uuidObj.noteUUID);
    }
  }
  return outdatesUUIDs;
});
// noteUUID:{
//   noteUUID: noteUUID,
//   creatorUUID: creatorUUID
//   views: 0
//   users:{
//     userUuid{
//       userUuid: userUuid,
//       read: false
//     }
//   }
// }

// messageUUID: {
//   messageUUID: number
//   body: ''
//   header: ''
//   date: timestamp
//   users: object Array {
//    userUUID:{
    //   userUUID: userUUID,
    //   read: false,
    //   deleted: false
    // viewed:false
    // }
//   }
//   reply: false
//   creatorUUID: number
//   masterMessageUUID: Number
//   type: string
// }
