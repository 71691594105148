// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paymentIcon[data-v-33dfdc6c] {
  width: 30px;
  vertical-align: middle;
}
.custom-loader-verify-code[data-v-33dfdc6c] {
  animation: loader-33dfdc6c 1s infinite;
}
@-moz-keyframes loader-33dfdc6c {
from {
    transform: rotate(0);
}
to {
    transform: rotate(360deg);
}
}
@-webkit-keyframes loader-33dfdc6c {
from {
    transform: rotate(0);
}
to {
    transform: rotate(360deg);
}
}
@-o-keyframes loader-33dfdc6c {
from {
    transform: rotate(0);
}
to {
    transform: rotate(360deg);
}
}
@keyframes loader-33dfdc6c {
from {
    transform: rotate(0);
}
to {
    transform: rotate(360deg);
}
}`, "",{"version":3,"sources":["webpack://./websrc/components/firstSetup/verifyEmailRegister.vue","webpack://./verifyEmailRegister.vue"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,sBAAA;ACAJ;ADEE;EACE,sCAAA;ACCJ;ADCE;AACE;IACE,oBAAA;ACEJ;ADAE;IACE,yBAAA;ACEJ;AACF;ADAE;AACE;IACE,oBAAA;ACEJ;ADAE;IACE,yBAAA;ACEJ;AACF;ADAE;AACE;IACE,oBAAA;ACEJ;ADAE;IACE,yBAAA;ACEJ;AACF;ADAE;AACE;IACE,oBAAA;ACEJ;ADAE;IACE,yBAAA;ACEJ;AACF","sourcesContent":["\n  .paymentIcon {\n    width: 30px;\n    vertical-align: middle;\n  }\n  .custom-loader-verify-code {\n    animation: loader 1s infinite;\n  }\n  @-moz-keyframes loader {\n    from {\n      transform: rotate(0);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }\n  @-webkit-keyframes loader {\n    from {\n      transform: rotate(0);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }\n  @-o-keyframes loader {\n    from {\n      transform: rotate(0);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }\n  @keyframes loader {\n    from {\n      transform: rotate(0);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }\n",".paymentIcon {\n  width: 30px;\n  vertical-align: middle;\n}\n\n.custom-loader-verify-code {\n  animation: loader 1s infinite;\n}\n\n@-moz-keyframes loader {\n  from {\n    transform: rotate(0);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes loader {\n  from {\n    transform: rotate(0);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n@-o-keyframes loader {\n  from {\n    transform: rotate(0);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n@keyframes loader {\n  from {\n    transform: rotate(0);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
