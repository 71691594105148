// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerIframe[data-v-20296249] {
  width: 100%;
  height: 90vh;
}
.containerIframeCall[data-v-20296249] {
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
  z-index: 99999;
}
.iframeCall[data-v-20296249] {
  position: absolute;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/calls/externalSambaCall.vue","webpack://./externalSambaCall.vue"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,YAAA;ACAJ;ADEE;EACE,aAAA;EACC,eAAA;EACA,MAAA;EACA,YAAA;EACA,OAAA;EACA,cAAA;ACCL;ADCE;EACE,kBAAA;ACEJ","sourcesContent":["\n  .containerIframe{\n    width: 100%;\n    height: 90vh;\n  }\n  .containerIframeCall{\n    height: 100vh;\n     position: fixed;\n     top: 0;\n     width: 100vw;\n     left: 0;\n     z-index: 99999;\n  }\n  .iframeCall{\n    position: absolute;\n  }\n",".containerIframe {\n  width: 100%;\n  height: 90vh;\n}\n\n.containerIframeCall {\n  height: 100vh;\n  position: fixed;\n  top: 0;\n  width: 100vw;\n  left: 0;\n  z-index: 99999;\n}\n\n.iframeCall {\n  position: absolute;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
