// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#updateIcon[data-v-28f285fe] {
  animation: blink-28f285fe 1s linear infinite;
}
.vueScrollFooter[data-v-28f285fe] {
  height: calc(100% - 350px) !important;
}
.vueScrollFooterMobile[data-v-28f285fe] {
  height: calc(100% - 400px) !important;
}
.textColor[data-v-28f285fe] {
  color: var(--v-primary-base);
}
@keyframes blink-28f285fe {
0% {
    opacity: 0;
}
50% {
    opacity: 0.5;
}
100% {
    opacity: 1;
}
}`, "",{"version":3,"sources":["webpack://./websrc/components/footer/about.vue","webpack://./about.vue"],"names":[],"mappings":"AACA;EACE,4CAAA;ACAF;ADEA;EACE,qCAAA;ACCF;ADCA;EACE,qCAAA;ACEF;ADAA;EACE,4BAAA;ACGF;ADAA;AACE;IACE,UAAA;ACGF;ADDA;IACE,YAAA;ACGF;ADDA;IACE,UAAA;ACGF;AACF","sourcesContent":["\n#updateIcon {\n  animation: blink 1s linear infinite;\n}\n.vueScrollFooter {\n  height: calc(100% - 350px) !important;\n}\n.vueScrollFooterMobile {\n  height: calc(100% - 400px) !important;\n}\n.textColor {\n  color: var(--v-primary-base);\n}\n\n@keyframes blink {\n  0% {\n    opacity: 0;\n  }\n  50% {\n    opacity: 0.5;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n","#updateIcon {\n  animation: blink 1s linear infinite;\n}\n\n.vueScrollFooter {\n  height: calc(100% - 350px) !important;\n}\n\n.vueScrollFooterMobile {\n  height: calc(100% - 400px) !important;\n}\n\n.textColor {\n  color: var(--v-primary-base);\n}\n\n@keyframes blink {\n  0% {\n    opacity: 0;\n  }\n  50% {\n    opacity: 0.5;\n  }\n  100% {\n    opacity: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
