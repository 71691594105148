import { beaconDomainWhitelistDomain } from './domain';

import { setBeaconDomainWhitelistEvent, resetBeaconDomainWhitelistEvent } from './events';

export const beaconDomainWhitelistStore = beaconDomainWhitelistDomain.createStore(false, { name: 'beaconDomainWhitelist' });

beaconDomainWhitelistStore.on(setBeaconDomainWhitelistEvent, (state, payload) => {
  return payload;
});

beaconDomainWhitelistStore.reset(resetBeaconDomainWhitelistEvent);
