// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.media-control.live {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/calls/recorderIframe.vue","webpack://./recorderIframe.vue"],"names":[],"mappings":"AACA;EACE,wBAAA;ACAF","sourcesContent":["\n.media-control.live{\n  display: none!important;\n}\n",".media-control.live {\n  display: none !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
