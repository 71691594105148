import { wsDomain } from './domain';
import { wsConnectedEvent, wsDisconnectedEvent } from './events';
import { wsConnectionStateStore } from './state';

const wsConnectedEffect = wsDomain.createEffect(() => wsConnectedEvent());

export {
  // Events
  wsConnectedEvent,
  wsDisconnectedEvent,

  // Effects
  wsConnectedEffect,

  // Stores
  wsConnectionStateStore,
};
