import { createApp } from 'vue';
import vuetify from '../../../vuetify';
import BlinkenLights from './blinkenlights.vue';

let blinkHolder;
export default function blinkenlights(domain) {
  // console.log('blinkenlights', blinkHolder, domain);
  if (!blinkHolder && domain) {
    blinkHolder = createApp(BlinkenLights, { domain }).use(vuetify);
    // BlinkenLights.vuetify = vuetify;

    // console.log('BLINKENLIGHTS', domain, blinkHolder);
    const container = document.createElement('div');
    document.body.appendChild(container);
    blinkHolder.mount(container);
  } else if (blinkHolder) {
    // If there's a current domain, we set the display accordingly
    if (domain) {
      blinkHolder.$children[0].setDisplay(!!domain);
    }
    // If the domain is falsy, we unmount the app
    else {
      // Call unmount on the blinkHolder Vue application instance
      blinkHolder.unmount();
      
      // Clean up the container from the body
      if (blinkHolder._container) {
        document.body.removeChild(blinkHolder._container);
      }

      // Clear the blinkHolder reference
      blinkHolder = null;
    }
  }
}
