// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-picker-wrapper[data-v-423bf9b6] {
  display: flex;
  align-items: center;
}
.pencil-icon[data-v-423bf9b6] {
  cursor: pointer;
  margin-left: 8px;
}
.dateTeamStartBasket[data-v-423bf9b6] {
  cursor: pointer;
  margin-top: 3px;
}
.h78vh[data-v-423bf9b6] {
  height: 78vh;
}
.videoButtonTT[data-v-423bf9b6] {
  max-width: 250px;
  background: rgb(97, 97, 97);
  opacity: 1 !important;
}
.rowSettingsMobile[data-v-423bf9b6] {
  max-height: 100%;
  overflow: auto;
}
.rowSettings[data-v-423bf9b6] {
  max-height: 500px;
  overflow: auto;
}
.saveButton[data-v-423bf9b6] {
  float: right;
  margin-right: 40px;
}
@media (max-width: 800px) {
.rowSettings[data-v-423bf9b6] {
    max-height: 350px !important;
}
}
.mw-445[data-v-423bf9b6] {
  min-width: 445px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/userSettingsNewModal.vue","webpack://./userSettingsNewModal.vue"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,mBAAA;ACAF;ADEA;EACE,eAAA;EACA,gBAAA;ACCF;ADCA;EACE,eAAA;EACA,eAAA;ACEF;ADAA;EACE,YAAA;ACGF;ADDA;EACE,gBAAA;EACA,2BAAA;EACA,qBAAA;ACIF;ADFA;EAEE,gBAAA;EACA,cAAA;ACIF;ADFA;EACE,iBAAA;EACA,cAAA;ACKF;ADHA;EACE,YAAA;EACA,kBAAA;ACMF;ADJA;AACE;IACE,4BAAA;ACOF;AACF;ADLA;EACE,gBAAA;ACOF","sourcesContent":["\n.date-picker-wrapper {\n  display: flex;\n  align-items: center;\n}\n.pencil-icon {\n  cursor: pointer;\n  margin-left: 8px;\n}\n.dateTeamStartBasket{\n  cursor: pointer;\n  margin-top: 3px;\n}\n.h78vh{\n  height: 78vh;\n}\n.videoButtonTT {\n  max-width: 250px;\n  background: rgb(97, 97, 97);\n  opacity: 1 !important;\n}\n.rowSettingsMobile {\n  // max-height: calc(100% - 133px);\n  max-height: 100%;\n  overflow: auto;\n}\n.rowSettings {\n  max-height: 500px;\n  overflow: auto;\n}\n.saveButton {\n  float: right;\n  margin-right: 40px;\n}\n@media (max-width: 800px) {\n  .rowSettings {\n    max-height: 350px!important;\n  }\n}\n.mw-445 {\n  min-width: 445px;\n}\n",".date-picker-wrapper {\n  display: flex;\n  align-items: center;\n}\n\n.pencil-icon {\n  cursor: pointer;\n  margin-left: 8px;\n}\n\n.dateTeamStartBasket {\n  cursor: pointer;\n  margin-top: 3px;\n}\n\n.h78vh {\n  height: 78vh;\n}\n\n.videoButtonTT {\n  max-width: 250px;\n  background: rgb(97, 97, 97);\n  opacity: 1 !important;\n}\n\n.rowSettingsMobile {\n  max-height: 100%;\n  overflow: auto;\n}\n\n.rowSettings {\n  max-height: 500px;\n  overflow: auto;\n}\n\n.saveButton {\n  float: right;\n  margin-right: 40px;\n}\n\n@media (max-width: 800px) {\n  .rowSettings {\n    max-height: 350px !important;\n  }\n}\n.mw-445 {\n  min-width: 445px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
