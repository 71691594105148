// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statusSpan[data-v-7977b94e] {
  width: 12px;
  height: 12px;
  position: absolute;
  border-radius: 50%;
  top: 14px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/calls/callInfo/addVisitorsCall.vue","webpack://./addVisitorsCall.vue"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,SAAA;ACAF","sourcesContent":["\n.statusSpan {\n  width: 12px;\n  height: 12px;\n  position: absolute;\n  border-radius: 50%;\n  top: 14px;\n}\n",".statusSpan {\n  width: 12px;\n  height: 12px;\n  position: absolute;\n  border-radius: 50%;\n  top: 14px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
