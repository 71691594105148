<template>
    <div class="content-container">
        <!-- <video controls>
            <source src="https://cloud.ra-micro.de/index.php/s/GxT8RxDH8jdwTYP/download" type="video/mp4">
            Your browser does not support the video tag.
        </video> -->
        <img class="socialMediaImg" src="/img/vOfficeSocialMediaPic.png" />
        <a href="https://xd.adobe.com/view/3997d75f-b00a-47ce-b0ea-8a2535b34856-6cc4/" target="_blank"
            class="adobe-link text-primary">Demo</a>
    </div>
</template>

<script>
import store, { EventBus } from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import moment from "../../../sharedsrc/moment";

export default {
    props: ["sidebar", "mini"],
    components: {},
    data() {
        return {
            state: store.state,
            ownUUID: store.state.ownUUID,
        };
    },
    watch: {},
    mounted() { },
    unmounted() { },
    methods: {},
    computed: {},
};
</script>

<style scoped lang="scss">
.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90%;
    width: 100%;
    margin: auto;
    margin-top: 3%;
    padding: 0px 10px;
}
.socialMediaImg {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
}
.mainContentContainer {
    .content-container {
        height: 90%;
        width: 100%;
        margin-top: 3%;
        padding: 0px 10px;
    }
}

video {
    max-width: 100%;
    margin-bottom: 20px;
}

.adobe-link {
    text-decoration: none;
    color: blue;
    font-weight: bold;
    margin-top: 10px;
}
</style>