import { conferenceDocumentsDomain } from './domain';

import { setConferenceDocumentsEvent, resetConferenceDocumentsEvent } from './events';

export const conferenceDocumentsStore = conferenceDocumentsDomain.createStore(false, { name: 'conferenceDocuments' });

conferenceDocumentsStore.on(setConferenceDocumentsEvent, (state, payload) => {
  return payload;
});

conferenceDocumentsStore.reset(resetConferenceDocumentsEvent);
