// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.keepLineaBreak[data-v-0db23898] {
  white-space: pre-line;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/groupInfoTooltip.vue","webpack://./groupInfoTooltip.vue"],"names":[],"mappings":"AACA;EACE,qBAAA;ACAF","sourcesContent":["\n.keepLineaBreak{\n  white-space:pre-line\n}\n",".keepLineaBreak {\n  white-space: pre-line;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
