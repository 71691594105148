import store, { syncedUserState } from '../store';
import Vue from '@vue/compat';
import { deleteUserByUUID, getGroupConferenceEntryByUUID } from '../lib/wsMsg';
import { userInCall } from "../utils/calls";
import { parallel } from "../lib/asyncUtil";
import {
  deleteConferenceEffect,
} from '../effector/conference';
import { updateGroupConferenceEvent } from '../effector/groupConferences';
import {
  timelineEvents,
  updateTimelineItemEffect,
  deleteTimelineEntryEffect,
} from '../effector/timeline';
import {
  dispatchErrorAlert,
} from "../effector/alerts";
import { wsCall } from "../lib/wsConnect";

export async function removeUser(uuid) {
  if (store.state.group[uuid]) await deleteUserByUUID(uuid);
  store.setUserVisitor(uuid, undefined);
}

async function cleanUpZeroMembersGroups() {
  const userGroups = store.state.user.userGroups;
  const zeroMemberGroups = userGroups.filter(e => !e.members?.length || !e.members.filter(v => !!v).length);
  for (const targetZero of zeroMemberGroups) {
    store.deleteGroup(targetZero.id);
    await deleteConferenceEffect(targetZero.id);
  }
}

async function editConfEvent(visId, extantEvent, timelineId) {
  // update event
  extantEvent.users = extantEvent.users.filter(u => u !== visId);
  await updateTimelineItemEffect([extantEvent.uuid, extantEvent]);
  if (extantEvent.users.length === 0) {
    await deleteTimelineEntryEffect(timelineId);
  }
}

async function deleteUserFromTimelineEvent(visId) {
  const myTimelineEvents = timelineEvents.getState();
  const extantEvents = myTimelineEvents.filter(e => e.users.indexOf(visId) !== -1) || [];
  // adjust users in event
  let collisions = false;
  if (extantEvents && extantEvents.length > 0) {
    for (const extant of extantEvents) {
      if (extant.collisions && extant.collisions.length > 0) {
        collisions = true;
        break;
      }
    }
    if (!collisions) {
      for (const extantEvent of extantEvents) {
        await editConfEvent(visId, extantEvent, extantEvent.uuid);
      }
    }
  }
  return collisions;
}

async function deleteVisitorImpl(visId, cleanUpConferences = true) {
  try {
    store.state.isConfDeleting = true;
    // user object
    if ((store.state.group[visId] || {}).user) {
      // user membership to events find by userid
      const resp = await deleteUserFromTimelineEvent(visId);
      if (!resp) {
        // user groups membership
        if (cleanUpConferences) await syncedUserState(() => store.deleteUsersFromGroups([visId]));
        await removeUser(visId);
        if (cleanUpConferences) await syncedUserState(() => cleanUpZeroMembersGroups());
      } else {
        dispatchErrorAlert(Vue.prototype.$t('components.waitingRoom.DeleteUserMultieventError'));
      }
    } else {
      console.warn('deleteVisitorImpl visitor not found');
    }
  } catch (error) {
    console.warn('deleteVisitorImpl error', error.stack);
  } finally {
    store.state.isConfDeleting = false;
  }
}

let currentDeleteVisitorPromise;

export async function deleteVisitor(visId, cleanUpConferences = true) {
  if (userInCall(visId)) {
    const data = {
      show: true,
      header: Vue.prototype.$t("generics.info"),
      body: Vue.prototype.$t("components.callsContent.endCallFirst"),
    };
    store.setinfoModal(data);
    return Promise.resolve(false);
  } else {
    const visitorData = store.state.persisted.userVisitors[visId] || ((store.state.group[visId] || {}).user || {}).visitorData;
    if (visitorData && visitorData.conferenceUUID && visitorData.conferenceUUID.length) {
      const conferences = visitorData.conferenceUUID;
      await parallel(1, [...conferences], async (confId) => {
        const entry = await getGroupConferenceEntryByUUID(confId);
        if (entry && entry.confUUIDS) {
          entry.confUUIDS = entry.confUUIDS.filter(e => e !== visId);
          entry.members = entry.members.filter(e => e.uuid !== visId);
          updateGroupConferenceEvent(entry)
        }
      });
    }
    currentDeleteVisitorPromise = currentDeleteVisitorPromise
      ? currentDeleteVisitorPromise.then(() => deleteVisitorImpl(visId, cleanUpConferences))
      : deleteVisitorImpl(visId, cleanUpConferences);
    const linkDateFrom = (visitorData || {}).linkDateFrom || "";
    const linkTimeFrom = (visitorData || {}).linkTimeFrom || "";
    const msg = linkDateFrom.length > 0 && linkTimeFrom.length > 0 ? Vue.prototype.$t("components.waitingRoom.deleteWaitingroomMsgParam", [linkDateFrom, linkTimeFrom]) : Vue.prototype.$t("components.waitingRoom.deleteWaitingroomMsg");
    sendMessagePopUp(visId, msg);
    return currentDeleteVisitorPromise;
  }
}
function sendMessagePopUp(uuid, message) {
  wsCall("sendToUUID", uuid, {
    type: "popUp",
    message: message,
    sender: store.state.ownUUID,
  });
}

export async function deleteVisitors(members) {
  if (members && members.length > 0) {
    await syncedUserState(() => store.deleteUsersFromGroups(members));
    await syncedUserState(() => cleanUpZeroMembersGroups());
    for (const extantUser of members) {
      await deleteVisitorImpl(extantUser, false);
    }
  }
}