<template>
    <SocialMediaContent />
</template>

<script>
  import store from "../../store";
  import SocialMediaContent from "./socialMediaContent.vue";
  // const hasOwn = Object.prototype.hasOwnProperty;
  export default {
    components: { SocialMediaContent },
    data() {
      return {
        state: store.state,
      };
    },
    methods: {},
    computed: {
    },
    mounted() {
    },
    unmounted() {
    },
  };
</script>