// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollBox[data-v-4a074b03] {
  overflow-y: scroll;
}
.changeLogDiv[data-v-4a074b03] {
  word-break: break-word;
  white-space: pre-line;
  max-height: 350px;
}
.pointer[data-v-4a074b03] {
  cursor: pointer;
}
.thumbs-down[data-v-4a074b03] {
  color: red;
}
.thumbs-up[data-v-4a074b03] {
  color: green;
}`, "",{"version":3,"sources":["webpack://./websrc/components/modal/changelogModal.vue","webpack://./changelogModal.vue"],"names":[],"mappings":"AACA;EACI,kBAAA;ACAJ;ADEA;EACE,sBAAA;EACA,qBAAA;EACA,iBAAA;ACCF;ADCA;EACE,eAAA;ACEF;ADAA;EACE,UAAA;ACGF;ADDA;EACE,YAAA;ACIF","sourcesContent":["\n.scrollBox {\n    overflow-y: scroll;\n    }\n.changeLogDiv {\n  word-break: break-word;\n  white-space: pre-line;\n  max-height:350px;\n}\n.pointer {\n  cursor: pointer;\n}\n.thumbs-down {\n  color: red;\n}\n.thumbs-up {\n  color: green;\n}\n",".scrollBox {\n  overflow-y: scroll;\n}\n\n.changeLogDiv {\n  word-break: break-word;\n  white-space: pre-line;\n  max-height: 350px;\n}\n\n.pointer {\n  cursor: pointer;\n}\n\n.thumbs-down {\n  color: red;\n}\n\n.thumbs-up {\n  color: green;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
