<template>
  <div v-show="showCroppie">
    <v-row class="d-flex justify-center mx-auto pt-5 pb-2 pl-3">
      <v-col cols="6" class="py-0">
        <cropper
          :stencil-size="stencilSize"
          :default-size="defaultSize"
          ref="cropper"
          class="twitter-cropper"
          background-class="twitter-cropper__background"
          foreground-class="twitter-cropper__foreground"
          image-restriction="stencil"
          :stencil-props="{
            lines: {},
            handlers: {},
            movable: false,
            scalable: false,
            aspectRatio: 1,
            previewClass: 'twitter-cropper__stencil',
          }"
          :transitions="false"
          :canvas="true"
          :debounce="false"
          :min-width="150"
          :min-height="150"
          :src="temporalAvatar"
          @change="onChange"
        />
          <v-slider :model-value="zoomValue" @update:model-value="zoomValue = $event; onZoom(zoomValue)"></v-slider>
      </v-col>
      <div class="rotateIcons">
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-btn density="comfortable" variant="text" v-bind="props" icon color="grey" @click="rotate(-90)">
              <font-awesome-icon
                :icon="['fal', 'undo']"
                :style="{ fontSize: '20px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.navbar.rotateLeft") }}</span>
        </v-tooltip>
        <br />
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-btn  density="comfortable" variant="text" v-bind="props" icon color="grey" @click="rotate(90)">
              <font-awesome-icon
                :icon="['fal', 'undo']"
                :style="{ fontSize: '20px' }"
                class="fa-flip-horizontal"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.navbar.rotateRight") }}</span>
        </v-tooltip>
      </div>
    </v-row>
    <v-card color="primary" class="py-2 pl-4 footerModal">
      <v-row class="px-2 btns" flex :style="{ width: '100%', margin: 0 }">
        <v-col class="px-0 py-0" align="right">
          <v-btn class="buttonPrimaryFooter" color="white" @click="crop()">{{
            $t("generics.save")
          }}</v-btn>
          <v-btn
            class="ml-3 mr-2"
            color="white"
            style="border-radius: 4px!important; min-width: 45px"
            icon
            tile
            variant="outlined"
            @click="cancelCrop()"
          >
            <font-awesome-icon
              :icon="['fal', 'times']"
              :style="{ fontSize: '20px' }"
            />
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-spacer></v-spacer>
  </div>
</template>

<script>
import store from "../../store";
import { getPictureSize } from "../../utils/basicFunctions";
export default {
  components: {},
  props: [
    "showCroppie",
    "closeCroppie",
    "person",
    "temporalAvatar",
    "resetInputAvatar",
  ],
  data() {
    return {
      state: store.state,
      zoomValue: 0,
    };
  },
  methods: {
    onChange(result) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const { coordinates, imageSize } = cropper;
        if (
          imageSize.width / imageSize.height >
          coordinates.width / coordinates.height
        ) {
          // Determine the position of slider bullet
          // It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
          this.zoom =
            (cropper.imageSize.height - cropper.coordinates.height) /
            (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
        } else {
          this.zoom =
            (cropper.imageSize.width - cropper.coordinates.width) /
            (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
        }
      }
    },
    onZoom(result) {
      const value = result / 100
      const cropper = this.$refs.cropper;
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight;
          const imageHeight = cropper.imageSize.height;
          // Determine the current absolute zoom and the new absolute zoom
          // to calculate the sought relative zoom value
          cropper.zoom(
            (imageHeight - this.zoom * (imageHeight - minHeight)) /
              (imageHeight - value * (imageHeight - minHeight))
          );
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth;
          const imageWidth = cropper.imageSize.width;
          cropper.zoom(
            (imageWidth - this.zoom * (imageWidth - minWidth)) /
              (imageWidth - value * (imageWidth - minWidth))
          );
        }
      }
    },
    defaultSize({ imageSize }) {
      return {
        width: Math.min(imageSize.height, imageSize.width),
        height: Math.min(imageSize.height, imageSize.width),
      };
    },
    stencilSize({ boundaries }) {
      return {
        width: Math.min(boundaries.height, boundaries.width) - 10,
        height: Math.min(boundaries.height, boundaries.width) - 10,
      };
    },
    resizeImage(imagePath, newWidth, newHeight) {
      const originalImage = new Image();
      originalImage.src = imagePath;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      return new Promise((resolve, reject) => {
        originalImage.addEventListener('error', (err) => reject(err));
        originalImage.addEventListener('load', () => {
          const originalWidth = originalImage.naturalWidth;
          const originalHeight = originalImage.naturalHeight;
          const aspectRatio = originalWidth/originalHeight;
          if (newHeight === undefined) {
            newHeight = newWidth/aspectRatio;
          }
          canvas.width = newWidth;
          canvas.height = newHeight;
          ctx.drawImage(originalImage, 0, 0, newWidth, newHeight);
          const dataUrl = canvas.toDataURL("image/jpeg", 0.9);
          return resolve(dataUrl);
        });
      });
    },
    async crop() {
      const canvas = this.$refs.cropper.getResult().canvas;
      if (canvas) {
        const base64Image = canvas.toDataURL("image/jpeg");
        const resizedImage = await this.resizeImage(base64Image, 256);
        // Do something with the base64 image
        store.setAvatar(resizedImage, this.person.uuid);
        if (((this.state.group[this.person.uuid] || {}).user || {}).avatarRectangle)
          store.setAvatarRectangle(resizedImage, this.person.uuid);
      }
      this.closeCroppie();
    },
    cancelCrop() {
      this.closeCroppie();
      this.resetInputAvatar();
    },
    rotate(rotationAngle) {
      this.$refs.cropper.rotate(rotationAngle);
    },
  },
  computed: {
    getPictureSize() {
      return getPictureSize();
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.twitter-cropper {
  height: 256px;
  width: 256px;
  &__background {
    background-color: #edf2f4;
  }
  &__foreground {
    background-color: #edf2f4;
  }
  &__stencil {
    border: solid 5px rgb(29, 161, 242);
  }
}
.croppie-container {
  height: unset;
}
.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  border: 2px solid #2a3133 !important;
}
.rotateIcons {
  position: absolute;
  right: 16%;
  top: 40%;
}
</style>
<style lang="scss">
.sliderSize .cr-slider-wrap {
  width: 100%;
  margin: 15px auto;
}
.sliderSize .cr-slider {
  width: 256px;
}
</style>