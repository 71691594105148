<template>
  <v-dialog
    :model-value="showAddEditOrganigramModal.show" @update:model-value="showAddEditOrganigramModal.show = $event"
    persistent
    max-width="560"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="isEdit? $t('components.organisationGrid.editDepart') : $t('components.organisationGrid.addDepart')"
        :closeModalFunction="closeModal"
      />
      <v-card-text class="">
        <div class="mb-2">
          <v-text-field
            :model-value="name" @update:model-value="name = $event"
            :disabled="addUsers"
            density="compact"
            :label="$t('components.organisationGrid.areaFunction')"
            variant="outlined"
            hide-details
          ></v-text-field>
        </div>
        <div class="mb-2">
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <v-switch
              class="pb-1 mt-0"
                v-bind="props"
                hide-details
                :model-value="sortByDepartment" @update:model-value="sortByDepartment = $event"
                :label="
                  $t(
                    'components.userSettings.sortByDepartment'
                  )
                "
              ></v-switch>
            </template>
            <span>
              {{ $t("components.userSettings.sortByDepartmentName") }}
            </span>
          </v-tooltip>
        </div>
        <div class="mb-4">
          <v-checkbox
            hide-details
            v-if="!isEdit && canAddUsers"
            :disabled="disabledAddUsers"
            :model-value="addUsers" @update:model-value="addUsers = $event"
            :label="$t('components.organisationGrid.teamMember')"
          ></v-checkbox>
        </div>
        <div class="mt-2">
          <v-combobox
            :disabled="!addUsers && selectedUsers.length === 4"
            variant="outlined"
            :model-value="selectedUsers" 
            @update:model-value="selectedUsers = $event; searchUsers = ''; limitUserSelected($event)"
            :items="dataComboboxAllUsers"
            :search-input.sync="searchUsers"
            select
            :label="!addUsers ? $t('components.organisationGrid.manager') : $t('generics.users')"
            multiple
            hide-details
            class="mb-5 managerCombobox"
            density="compact"
            item-value="value"
            item-title="text"
            :return-object="true"
          >
            <!-- <template v-slot:selection="data"> -->
            <!--  // keep empty we don't want to show the selection. decision by design -->
            <!-- <div v-if="data">
              </div>
            </template> -->
          </v-combobox>
          <v-chip
            class="mb-2 mr-2 chipContainer"
            default
            label
            closable
            v-for="user in selectedUsers"
            :key="user.text"
            @click:close="removeUserSelected(user.value)"
          >
            <v-avatar  width="32px!important" height="32px!important" min-width="32px!important" left class="rounded mr-2">
              <v-img class="rounded"  :src="getAvatarForUuid(user.value)"></v-img>
            </v-avatar>
            {{ user.text }}
          </v-chip>
        </div>
        <div class="mt-2" v-if="!addUsers">
          <v-combobox
            v-if="!addUsers"
            :disabled="addUsers"
            variant="outlined"
            :model-value="selectedAssistants" 
            @update:model-value="selectedAssistants = $event; searchUsers = '';"
            :items="dataComboboxAllUsers"
            select
            :label="$t('excel.assistants')"
            multiple
            hide-details
            class="mb-5"
            density="compact"
            item-value="value"
            item-title="text"
            :return-object="true"
          >
            <template v-slot:selection="data">
              <!--  // keep empty we don't want to show the selection. decision by design -->
              <div v-if="data"></div>
            </template>
          </v-combobox>
          <v-chip
            class="mb-2 mr-2 chipContainer"
            default
            label
            closable
            v-for="user in selectedAssistants"
            :key="user.text"
            @click:close="removeAssistantsSelected(user.value)"
          >
            <v-avatar tile left class="rounded mr-2">
              <v-img  width="32px" height="32px" class="rounded" :src="getAvatarForUuid(user.value)"></v-img>
            </v-avatar>
            {{ user.text }}
          </v-chip>
        </div>
      </v-card-text>
      <FooterModal :closeModalFunction="closeModal">
        <v-btn v-if="isEdit" text color="error" class="leftSidePosition ml-3" @click="remove()"> {{$t('components.organisationGrid.removeDepart')}}</v-btn>
        <v-btn flat color="primary" class="mr-2" :disabled="disableSave" @click="save()">
          {{ $t("generics.save") }}</v-btn
        >
      </FooterModal>
      <v-spacer></v-spacer>
    </v-card>
  </v-dialog>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store, { EventBus } from "../../store";
import { v4 as uuidv4 } from "uuid";
import {
  resetAddEditOrganigramModalEvent,
  addEditOrganigramModalStore,
} from "../../effector/modals";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import { hasPrivilege } from "../../utils/privileges.js";
export default {
  props: ["errorInCall", "closeModalError"],
  components: {
    FooterModal,
    HeaderModal,
  },
  data() {
    const effector = {
      showAddEditOrganigramModal: addEditOrganigramModalStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      name: "",
      searchUsers: null,
      selectedUsers: [],
      selectedAssistants: [],
      addUsers: false,
      sortByDepartment: true,
      // Effector
      ...effector,
    };
  },
  watch: {
    addUsers: {
      immediate: true,
      handler: function (isEnabled) {
        // console.log(this.name, isEnabled);
        const data = this.getDataStore.data;
        if (isEnabled && !this.name.length) {
          this.name = data.name;
        }
      },
    },
    selectedUsers: {
      immediate: true,
      handler: function (selected) {
        // Filter out any selections that don't have both value and text properties
        const filteredSelected = selected.filter(e => e && e.value && e.text);
        if (!this.arraysAreEqual(this.selectedUsers, filteredSelected)) {
          // Using `$nextTick` to avoid immediate reactivity updates
          this.$nextTick(() => {
            this.selectedUsers = filteredSelected;
          });
        }
      }
    },    
    selectedAssistants: {
      immediate: true,
      handler: function (selected) {
        // Filter out any selections that don't have both value and text properties
        const filteredSelected = selected.filter(e => e && e.value && e.text);
        if (!this.arraysAreEqual(this.selectedAssistants, filteredSelected)) {
          // Using `$nextTick` to avoid immediate reactivity updates
          this.$nextTick(() => {
            this.selectedAssistants = filteredSelected;
          });
        }
      }
    }
  },
  created() {},
  mounted() {
    const data = this.getDataStore.data;
    // console.log("11111111111", this.getDataStore);
    if (this.isEdit) {
      this.loadData();
    }
    // if (data && data.children && data.children[0]&& data.children[0].isUser) {
    //   this.loadData();
    // }
    if (data && data.usersSections) {
      this.loadData();
    }
  },
  unmounted() {},
  methods: {
    arraysAreEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) return false;
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    },
    loadDataUsers() {},
    loadData() {
      const data = this.getDataStore.data;
      this.name = data.name;
      this.sortByDepartment = data.sortByDepartment !== undefined ? data.sortByDepartment : true;
      this.selectedUsers = this.preparedComboBox(data.supervisors);
      this.selectedAssistants = this.preparedComboBox(data.assistants);
      if (data.children && data.children[0] &&  data.children[0].isUser && !this.isEdit) {
        const usersUuid = data["children"].map((e) => e.uuid);
        this.selectedUsers = this.preparedComboBox(usersUuid);
        this.addUsers = true;
      }
    },
    preparedComboBox(uuids) {
      if (!uuids) return [];
      const users = JSON.parse(JSON.stringify(this.dataComboboxAllUsers));
      return users.filter((e) => uuids.includes(e.value));
    },
    save() {
      // do logic her to save in to DB
      const id = uuidv4();
      let dataToSave = [];
      const userFilter = this.selectedUsers.filter(e => e && e.value).map((e) => e.value);
      const assistansFilter = this.selectedAssistants.filter(e => e && e.value).map((e) => e.value);
      if (!this.addUsers) {
        dataToSave = [
          {
            id: id,
            name: this.name,
            supervisors: userFilter,
            assistants: assistansFilter,
            sortByDepartment: this.sortByDepartment
            // children: this.getDataStore.data.children || [],
          },
          this.getDataStore,
        ];
      } else {
        let dataChildren = []
        const getDataStore = JSON.parse(JSON.stringify(this.getDataStore));
        if(userFilter.length){
          dataChildren = userFilter.map((e) => {
            return {
              isUser: this.getDataStore.data.id,
              uuid: e,
            };
          });
        }else {
          getDataStore.edit = true
        }
        dataToSave = [
          {
            id: this.getDataStore.data.id,
            name: this.name,
            supervisors: this.getDataStore.data.supervisors,
            assistants: assistansFilter,
            sortByDepartment: this.sortByDepartment,
            children: dataChildren,
          },
          getDataStore,
        ];
      }
      EventBus.$emit("saveOrganigram", dataToSave);
      this.closeModal();
    },
    remove(){
       const data = this.getDataStore.data;
       EventBus.$emit("removeSectionOrganigram", data);
       this.closeModal();
    },
    limitUserSelected(e) {
      if (!this.addUsers && e.length > 4) {
        e.pop();
      }
    },
    removeUserSelected(uuid) {
      this.selectedUsers = this.removeElementByValue(this.selectedUsers, uuid);
    },
    removeAssistantsSelected(uuid) {
      this.selectedAssistants = this.removeElementByValue(
        this.selectedAssistants,
        uuid
      );
    },
    removeElementByValue(array, uuid) {
      return array.filter(function (element) {
        return element.value !== uuid;
      });
    },

    closeModal() {
      resetAddEditOrganigramModalEvent();
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
  },
  computed: {
    disabledAddUsers() {
      const data = this.getDataStore;
      if (
        data.data.children &&
        data.data.children[0] &&
        data.data.children[0].isUser
      ) {
        return true;
      }
      return false;
    },
    canAddUsers() {
      const data = this.getDataStore;
      if(!data) return false;

      return (
        data.isOpen ||
        (data.data && data.data.children &&
          data.data.children[0] &&
          data.data.children[0].isUser)
      );
    },
    isEdit() {
      const data = this.getDataStore;
      return data && data.edit;
    },
    getDataStore() {
      return this.showAddEditOrganigramModal;
    },
    disableSave() {
      if (!this.name.length || (!this.selectedUsers.length && !this.addUsers)) {
        return true;
      }
    },
    dataComboboxAllUsers() {
      const membersList = JSON.parse(JSON.stringify(this.state.group));
      if (
        this.selectedUser?.uuid &&
        membersList &&
        membersList[this.selectedUser?.uuid]
      ) {
        delete membersList[this.selectedUser?.uuid];
      }
      let finalResult = [];
      for (let uuid in membersList) {
        let person = membersList[uuid];
        if (
          person &&
          person.user &&
          person.user.name &&
          person.user.uuid &&
          this.hasPrivilege(person.user.uuid)
        ) {
          const item = {
            value: person.user.uuid,
            text: person.user.name,
          };
          finalResult.push(item);
        }
      }
      return finalResult;
    },
  },
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
.leftSidePosition{
  position: absolute;
  left: 0;
}
.chipContainer{
  height: 41px!important;
  background: var(--v-primary-100) !important;;
}
.chipContainer .rounded {
  border-radius: 4px;
}
</style>
<style >
.chipContainer .v-chip__close{
  margin-left: 16px!important;
}
.managerCombobox .v-theme--light.v-input--is-disabled,
.managerCombobox .v-theme--light.v-input--is-disabled input,
.managerCombobox .v-theme--light.v-input--is-disabled {
  background: #fafafa;
}

</style>
