// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.debugContainer[data-v-574731ea] {
  position: absolute;
  top: 60px;
  right: 60px;
  z-index: 3;
  background: black;
  color: white;
  padding: 10px;
  max-width: 350px;
  border: 1px solid red;
  max-height: 580px;
  overflow: auto;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/calls/debugCall/debugCall.vue","webpack://./debugCall.vue"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,UAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,qBAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;ACAF","sourcesContent":["\n.debugContainer {\n  position: absolute;\n  top: 60px;\n  right: 60px;\n  z-index: 3;\n  background: black;\n  color: white;\n  padding: 10px;\n  max-width: 350px;\n  border: 1px solid red;\n  max-height: 580px;\n  overflow: auto;\n  font-size: 12px;\n}\n",".debugContainer {\n  position: absolute;\n  top: 60px;\n  right: 60px;\n  z-index: 3;\n  background: black;\n  color: white;\n  padding: 10px;\n  max-width: 350px;\n  border: 1px solid red;\n  max-height: 580px;\n  overflow: auto;\n  font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
