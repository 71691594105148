<template>
  <div v-show="loaderDone" id="paypal-button" class="paypal-button"></div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { getPayPalSettings, logVisitorPayPalPayment } from "../../../lib/wsMsg";
import { EventBus } from "../../../store";
import { dispatchErrorAlert } from "../../../effector/alerts";
import paypalLoader from '../../../lib/paypalLoader';
import defaultProps from '../../../utils/paypal/defaultProps';
import additionalProps from '../../../utils/paypal/additionalProps';
import { propTypes, assignToPropertyObject } from '../../../utils/paypal/paypalProp';

const assignTo = assignToPropertyObject(additionalProps);

export default {
  props: Object.assign(
    defaultProps(),
    additionalProps.vmProps(),
  ),
  data: () => ({
    clientId: undefined,
    loaderPromise: undefined,
    loaderDone: false, 
  }),
  methods: {
    async loadData() {
      const { APIUser } = await getPayPalSettings();
      this.clientId = APIUser;
      const currencyCode = this.currency;
      return await paypalLoader(this.clientId, currencyCode);
    },
    // payment() {
    //   const vue = this;
    //   const transaction = Object.assign({
    //     amount: {
    //       total: this.amount,
    //       currency: this.currency,
    //       details: this.details,
    //     },
    //   }, assignTo(vue, propTypes.TRANSACTION));
    //   if (transaction.shipping_address && transaction.item_list) {
    //     transaction.item_list.shipping_address = transaction.shipping_address;
    //     delete transaction.shipping_address;
    //   }
    //   const payment = {
    //     transactions: [transaction],
    //   };
    //   return window.paypal.rest.payment.create(
    //     this.env,
    //     this.client,
    //     Object.assign({ payment }, assignTo(vue, propTypes.PAYMENT)),
    //   );
    // },
    // onAuthorize(data, actions) {
    //   const vue = this;
    //   vue.$emit('payment-authorized', data);
    //   if (this.commit) {
    //     return actions.payment.execute().then((response) => {
    //       vue.$emit('payment-completed', response);
    //     });
    //   }
    //   return true;
    // },
    // onCancel(data) {
    //   const vue = this;
    //   vue.$emit('payment-cancelled', data);
    // },
  },
  created() {
    this.loaderPromise = this.loadData()
      .catch(err => {
        console.warn('loaderPromise Error', err);
        dispatchErrorAlert(err.message || err);
      });
  },
  mounted() {
    const vue = this;
    this.loaderPromise.then((loaderResult) => {
      if (!loaderResult) return;
      this.loaderDone = true; // Trigger rending of target UI element

      const totalAmount = this.amount;
      const currencyCode = this.currency;
      const invoice_id = `vof-${this.invoiceNumber}`;

      const options = {
        style:{
          layout:  'horizontal',
          height:  36, 
          //size:   'large',
          color:   'silver',
          shape:   'rect',
          label:   'paypal',
          tagline: false
        },
        createOrder: function(data, actions) {
          // This function sets up the details of the transaction, including the amount and line item details.
          return actions.order.create({
            application_context: {
              shipping_preference: "NO_SHIPPING"
            },
            purchase_units: [{
              amount: {
                value: totalAmount,
                currency_code: currencyCode,
                breakdown: {
                  item_total: {
                    value: totalAmount,
                    currency_code: currencyCode
                  }
                }
              },
              invoice_id,
              soft_descriptor: 'vOffice',
              items: vue.items
            }]
          });
        },
        onApprove: function(data, actions) {
          // This function captures the funds from the transaction.
          return actions.order.capture().then(function(details) {
            // This function shows a transaction success message to your buyer.
            logVisitorPayPalPayment(details)
              .catch(err => console.warn('logVisitorPayPalPayment', err.message || err));
            EventBus.$emit('paymentSuccess');
          });
        },
        onCancel: function(data, actions) {
          EventBus.$emit('paymentCanceled');
        }
      }
      window.paypal.Buttons(options).render('#paypal-button');
    });
  },
};
</script>

<style lang="scss">
.paypal-button{
  // vertical-align: middle;
  // width: 43%;
  // display: inline-block;
  margin-right: 15px;
  // height: 35px;
  .paypal-buttons{
    height: 35px !important;
  }
}
</style>