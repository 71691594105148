<template>
<!-- UNUSED MODAL -->
    <v-dialog
      :model-value="showUpgrade" @update:model-value="showUpgrade = $event"
      width="500"
    >
      <template v-slot:activator="{ on }">
                  
         <v-btn color="primary" v-on="on" >
              <font-awesome-icon :icon="['far', 'shopping-cart']" :style="{ fontSize: '16px' }"/>
            <span class="hidden-sm-and-down pl-1">{{ $t('components.upgradeModal.upgrade') }}</span>
          </v-btn>

      </template>

      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
          {{ $t('components.upgradeModal.privacyPolicy') }}
        </v-card-title>

        <v-card-text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            {{ $t('components.upgradeModal.iAccept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>


<script>
import store from "../../store";
// import { callUUID, getWebrtcConnections } from '../../lib/rtcCall';

export default {
  components: {  },
  data: () => ({
    //dialog: false,
    state: store.state,
    showUpgrade: false,
  }),
  methods: {

  },
  computed: {
    cols() {
      const { lg, sm, md, xs } = this.$vuetify.display;
      return lg ? [4, 8] : sm ? [4, 8] : md ? [4, 8] : [12, 12];
    },
  }
};
</script>

<style scoped lang="scss">
video {
  height: 256;
  width: 256;
  min-width: 256;
  border: 2px solid #f2f2f2;
  background: black;
  // border-radius: 10px;
  // margin-top: -57px;
  // margin-right: 20px;
}

.btns {
  justify-content: flex-end;
}
.userNameCard {
  margin-bottom: 0px;
  font-size: 19px;
  // color: #2a3133;
}
.v-application .v-card__title {
  font-size: 18px !important;
  margin-bottom: 0px;
}
.uploadAvatarButtons {
  position: absolute;
  left: 0px;
  height: 150px;
  width: 150px;
  top: 0px;
  border-radius: 10px;
  text-align: center;
  padding-top: 100px;

  &.hasImage {
    background: #00000030;
    display: none;
    &:hover {
      display: block;
    }
  }
  &.hasNoImage {
    display: none;
    &:hover {
      display: block;
    }
  }
}
.borderRadius10 {
  &:hover > .hasImage {
    display: block;
  }
  &:hover > .hasNoImage {
    display: block;
  }
}
.croppie-container {
  height: unset;
  margin: 40px auto;
}
.croppie-container .cr-viewport,
.croppie-container .cr-resizer {
  border: 2px solid #2a3133 !important;
}
</style>