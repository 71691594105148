// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-theme--light .listUsers .header .v-input__slot {
  background-color: white !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/calls/sidebarCall/callLateralPanel.vue","webpack://./callLateralPanel.vue"],"names":[],"mappings":"AAGI;EACE,kCAAA;ACFN","sourcesContent":["\n.v-theme--light .listUsers {\n  .header {\n    .v-input__slot {\n      background-color: white !important;\n    }\n  }\n}\n",".v-theme--light .listUsers .header .v-input__slot {\n  background-color: white !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
