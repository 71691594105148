// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.height35[data-v-721d6c5e] {
  height: 35px;
}
form[data-v-721d6c5e] {
  font-weight: normal;
}
form input[data-v-721d6c5e] {
  font-size: 14px !important;
}
.v-list-item[data-v-721d6c5e] {
  padding: 0px !important;
}
.btnSend[data-v-721d6c5e] {
  float: right;
}
.d-flex p.email[data-v-721d6c5e] {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 65%;
  color: #2a3133;
  margin: 0px;
}
.d-flex p.date[data-v-721d6c5e] {
  width: 40%;
  font-size: 12px;
  vertical-align: middle;
  margin: 4px;
  text-align: right;
}
.btns[data-v-721d6c5e] {
  justify-content: flex-end;
}
.v-application .v-card__title[data-v-721d6c5e] {
  font-size: 18px !important;
  margin-bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/navbar/sendInstaLinkPanel.vue","webpack://./sendInstaLinkPanel.vue"],"names":[],"mappings":"AACA;EACE,YAAA;ACAF;ADEA;EACE,mBAAA;ACCF;ADAE;EACE,0BAAA;ACEJ;ADCA;EACE,uBAAA;ACEF;ADAA;EACE,YAAA;ACGF;ADEI;EACE,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,UAAA;EACA,cAAA;EACA,WAAA;ACCN;ADCI;EACE,UAAA;EACA,eAAA;EACA,sBAAA;EACA,WAAA;EACA,iBAAA;ACCN;ADGA;EACE,yBAAA;ACAF;ADEA;EACE,0BAAA;EACA,kBAAA;ACCF","sourcesContent":["\n.height35 {\n  height: 35px;\n}\nform {\n  font-weight: normal;\n  input {\n    font-size: 14px !important;\n  }\n}\n.v-list-item {\n  padding: 0px !important;\n}\n.btnSend {\n  float: right;\n  // margin-bottom: 20px;\n}\n.d-flex {\n  p {\n    &.email {\n      text-overflow: ellipsis;\n      white-space: nowrap;\n      overflow: hidden;\n      width: 65%;\n      color: #2a3133;\n      margin: 0px;\n    }\n    &.date {\n      width: 40%;\n      font-size: 12px;\n      vertical-align: middle;\n      margin: 4px;\n      text-align: right;\n    }\n  }\n}\n.btns {\n  justify-content: flex-end;\n}\n.v-application .v-card__title {\n  font-size: 18px !important;\n  margin-bottom: 0px;\n}\n",".height35 {\n  height: 35px;\n}\n\nform {\n  font-weight: normal;\n}\nform input {\n  font-size: 14px !important;\n}\n\n.v-list-item {\n  padding: 0px !important;\n}\n\n.btnSend {\n  float: right;\n}\n\n.d-flex p.email {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  width: 65%;\n  color: #2a3133;\n  margin: 0px;\n}\n.d-flex p.date {\n  width: 40%;\n  font-size: 12px;\n  vertical-align: middle;\n  margin: 4px;\n  text-align: right;\n}\n\n.btns {\n  justify-content: flex-end;\n}\n\n.v-application .v-card__title {\n  font-size: 18px !important;\n  margin-bottom: 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
