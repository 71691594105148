import { breakStatusModalDomain } from './domain';

import { setBreakStatusModalEvent, resetBreakStatusModalEvent} from './events';

export const breakStatusModalStore = breakStatusModalDomain.createStore(false, { name: 'breakStatusModal' });

breakStatusModalStore.on(setBreakStatusModalEvent, (state, payload) => {
  return payload;
});

breakStatusModalStore.reset(resetBreakStatusModalEvent);
