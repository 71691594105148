<template>
  <div style="padding: 2px">
    <v-tooltip right>
      <template v-slot:activator="{ props }" >
      <v-checkbox
        class="mt-0 pt-0 customCheckboxStyles"
        :model-value="checkBox" @update:model-value="checkBox = $event"
        hide-details
        v-bind="props"
        @click.stop.prevent="addUserToCallQueue"
        :disabled="(multiUserCallQueueStore.length+1)>18"
      ></v-checkbox>
        </template>
      <span> {{ $t('components.organisationGrid.addUserGroupCallTT') }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import { multiUserCallQueueStore, setMultiUserCallQueueEvent, resetMultiUserCallQueueEvent } from "../../effector/multiUserCallQueue"
export default {
  props: ["person"],
  data() {
    const effector = {
      multiUserCallQueueStore: multiUserCallQueueStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      checkBox: false,
      // Effector
      ...effector,
    };
  },
  watch:{
    multiUserCallQueueStore: {
      immediate: true,
      handler: function (usersInArray) {
        this.checkUser(usersInArray);
      },
    },
  },
  mounted(){
    this.checkUser(this.multiUserCallQueueStore)
  },
  unmounted(){
    // if(this.multiUserCallQueueStore){
    //   resetMultiUserCallQueueEvent()
    // }
  },
  methods: {
    checkUser(usersInArray){
       if (usersInArray && this.person.user.uuid) {
          if(usersInArray.indexOf(this.person.user.uuid)!==-1){
            this.checkBox = true
          }else{
            this.checkBox = false
          }
        }else{
           this.checkBox = false
        }
    },
    addUserToCallQueue() {
      const userUuid = this.person.user.uuid;
      if (!userUuid) return;
      const userQueue = this.multiUserCallQueueStore ? [...this.multiUserCallQueueStore] : [];
      if (userQueue.length > 19) return;
      const indexUser = userQueue.indexOf(userUuid);
      if (indexUser == -1) {
        userQueue.push(userUuid);
      } else {
        userQueue.splice(indexUser, 1);
      }
      if (userQueue.length == 0) {
        resetMultiUserCallQueueEvent();
      } else {
        setMultiUserCallQueueEvent(userQueue);
      }
    }
  },
  computed: {
  },
};
</script>
<style lang="scss">
.v-input.v-checkbox {
  height: unset !important;
  min-height: unset !important;
  .v-selection-control {
    min-height: unset !important;
    height: unset !important;
    .v-selection-control__wrapper{
      height: unset !important;
      width: unset !important;
      .v-selection-control__input{
        height: unset !important;
        width: unset !important;
      }
    }
  }
}

</style>