<template>
  <div class="px-0 py-0">
    <v-row>
      <v-col class="mx-2">
        <v-card variant="outlined">
          <StatsNumbers />
          <div class="d-flex justify-space-between pa-4">
            <span class="text-subtitle-1 font-weight-bold">
              {{ $t("components.statusContainer.userListStatus") }}:
              <span :style="{ color: getStatusColor + '!important' }">{{
                statusType
              }}</span>
            </span>
            <span class="d-flex align-center">
              <v-text-field
                class="pt-0"
                variant="outlined"
                density="compact"
                style="width: 310px"
                :model-value="search" @update:model-value="search = $event"
                append-inner-icon="mdi-magnify"
                :label="$t('generics.search')"
                hide-details
                ></v-text-field>
                <!-- single-line -->
              <span class="ml-4 pointer" @click="closeStatus()">
                <font-awesome-icon
                  :icon="['fal', 'times']"
                  :style="{ fontSize: '20px' }"
                />
              </span>
            </span>
          </div>
          <div>
            <v-data-table
              :headers="headers"
              :items="users"
              :items-per-page="itemsPerPage"
              :search="search"
              class="elevation-1"
              :page.sync="page"
              @page-count="pageCount = $event"
              fixed-header
              height="calc(100vh - 365px)"
              @update:items-per-page="getItemPerPage"
            >
              <template v-slot:item.avatar="{ item }">
                <v-avatar
                  class="profile"
                  color="grey"
                  size="35"
                  tile
                  :class="{
                    pointer:
                      !disableCallBtn(item.uuid) && item.uuid !== state.ownUUID,
                  }"
                  v-on:click="
                    !disableCallBtn(item.uuid) && item.uuid !== state.ownUUID
                      ? callUser(item.uuid) : ''
                  "
                >
                  <v-img
                    v-if="
                      item.avatar &&
                      item.avatar !== 'img/default_profile_picture.png'
                    "
                    :src="item.avatar || 'img/default_profile_picture.png'"
                    lazy-src="img/default_profile_picture.png"
                  ></v-img>
                  <DefaultAvatar
                    :userData="item"
                    v-if="
                      !item.avatar ||
                      item.avatar === 'img/default_profile_picture.png'
                    "
                    :lowerText="true"
                  ></DefaultAvatar>
                </v-avatar>
              </template>
              <!-- add send message -->
              <template v-slot:item.sendMessage="{ item }">
                <v-btn variant="text" v-if="item && item.uuid !== state.ownUUID" icon @click="setShowModalSenMsg(item.uuid)">
                  <font-awesome-icon
                    :icon="['fal', 'paper-plane']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <!-- holidays -->
              <template v-slot:item.holidays="{ item }">
                <span :class="item.isInHolidays ? 'holidaysColor' : null">{{
                  item.holidays
                }}</span>
              </template>
              <!-- holidays -->
              <template v-slot:item.illness="{ item }">
                <span v-if="item.startIllness && item.startIllness.length" :class="item.startIllness ? 'illColor' : null">{{
                  moment(item.startIllness).format('DD.MM.YYYY')
                }} - {{
                  moment(item.endIllness).format('DD.MM.YYYY')
                }}</span>
              </template>
              <!-- conected -->
              <template v-slot:item.presentFrom="{ item }">
                <span v-if="item.connected && canSeeLastLogin">{{
                  item.presentFrom || ""
                }}</span>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from "../../store";
import { callPerson, disableCallBtn } from "../../utils/calls";
import DefaultAvatar from "../image/defaultAvatar.vue";
import StatsNumbers from "./statsNumbers.vue";
import { setHistoryMessagesModalEvent } from "../../effector/modals";
import moment from "../../../sharedsrc/moment";
export default {
  props: ["users", "headers", "itemsPerPage", "statusType", "getItemPerPage"],
  components: { DefaultAvatar, StatsNumbers },
  data() {
    return {
      state: store.state,
      moment: moment,
      search: "",
      setCurrentContentVisile: store.setCurrentContentVisile,
      page: 1,
      pageCount: 0,
    };
  },
  methods: {
    setShowModalSenMsg(uuid) {
      setHistoryMessagesModalEvent(uuid);
    },
    callUser(uuid) {
      callPerson(uuid, this.$router);
    },
    closeStatus() {
      this.setCurrentContentVisile("home", true, this.$router);
    },
    disableCallBtn(uuid) {
      if(!uuid) return true
      let person = this.state.group[uuid];
      return disableCallBtn(person);
    },
  },
  computed: {
    canSeeLastLogin() {
      return this.amIAdmin && this.state.namespaceSettings.loginDaily;
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
    getStatusColor() {
      switch (this.$route.path) {
        case "/status/available":
          return "#008000";
        case "/status/not-available":
          return "#ffa500";
        case "/status/no-call":
          return "#ff0000";
        case "/status/in-call":
          return "var(--v-primary-base)";
        case "/status/offline":
          return "#808080";
        case "/status/total":
          return "";
        case "/status/holidays":
          return "#7208ff";
        case "/status/illness":
          return "#fd939e";
        case "/status/coffee-break":
          return "#795548";
        case "/status/in-room":
          return "#0abce6";
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss">
.lockMessageIcon {
  position: absolute;
  right: -6px;
  top: -10px;
}
.pointer {
  cursor: pointer;
}
.v-theme--dark.v-data-table tbody tr td {
  color: white !important;
}
.v-theme--light.v-data-table thead tr th {
  color: black !important;
}
.v-theme--dark.v-data-table thead tr th {
  color: white !important;
}
.holidaysColor {
  color: #7208ff !important;
}
.illColor {
  color: #fd939e !important;
}
.sizePagination {
  max-width: 120px;
  margin-right: 0px;
  padding-right: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
</style>