// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-99407a06]  .v-select__selections {
  flex-wrap: initial;
}
[data-v-99407a06]  .v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: inherit;
}
[data-v-99407a06]  .v-data-footer {
  margin-right: 0 !important;
}
[data-v-99407a06]  .v-input__slot {
  padding: 0 !important;
}
[data-v-99407a06]  .v-theme--light .v-data-table__expanded__content {
  background-color: #fafafa;
}
[data-v-99407a06]  .v-theme--dark .v-data-table__expanded__content {
  background-color: #121212;
}
[data-v-99407a06]  .v-text-field__details {
  padding: 0 !important;
}
[data-v-99407a06]  .v-data-table tbody tr:hover {
  background: initial !important;
}
.dot[data-v-99407a06] {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./websrc/components/config/userManager.vue","webpack://./userManager.vue"],"names":[],"mappings":"AACA;EACE,kBAAA;ACAF;ADGA;EACE,mBAAA;ACAF;ADGA;EACE,0BAAA;ACAF;ADGA;EACE,qBAAA;ACAF;ADGA;EACE,yBAAA;ACAF;ADGA;EACE,yBAAA;ACAF;ADGA;EACE,qBAAA;ACAF;ADGA;EACE,8BAAA;ACAF;ADGA;EACE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,qBAAA;ACAF","sourcesContent":["\n:deep() .v-select__selections {\n  flex-wrap: initial;\n}\n\n:deep() .v-data-table tbody tr.v-data-table__expanded__content {\n  box-shadow: inherit;\n}\n\n:deep() .v-data-footer {\n  margin-right: 0 !important;\n}\n\n:deep() .v-input__slot {\n  padding: 0 !important;\n}\n\n:deep() .v-theme--light .v-data-table__expanded__content {\n  background-color: #fafafa;\n}\n\n:deep() .v-theme--dark .v-data-table__expanded__content {\n  background-color: #121212;\n}\n\n:deep() .v-text-field__details {\n  padding: 0 !important;\n}\n\n:deep() .v-data-table tbody tr:hover {\n  background: initial !important;\n}\n\n.dot {\n  height: 12px;\n  width: 12px;\n  border-radius: 50%;\n  display: inline-block;\n}\n",":deep() .v-select__selections {\n  flex-wrap: initial;\n}\n\n:deep() .v-data-table tbody tr.v-data-table__expanded__content {\n  box-shadow: inherit;\n}\n\n:deep() .v-data-footer {\n  margin-right: 0 !important;\n}\n\n:deep() .v-input__slot {\n  padding: 0 !important;\n}\n\n:deep() .v-theme--light .v-data-table__expanded__content {\n  background-color: #fafafa;\n}\n\n:deep() .v-theme--dark .v-data-table__expanded__content {\n  background-color: #121212;\n}\n\n:deep() .v-text-field__details {\n  padding: 0 !important;\n}\n\n:deep() .v-data-table tbody tr:hover {\n  background: initial !important;\n}\n\n.dot {\n  height: 12px;\n  width: 12px;\n  border-radius: 50%;\n  display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
