/* globals localStorage */
import store from '../store';
import { setShowBlinkenlightsEvent } from '../effector/logging';

const cheatMachines = {};

const KonamiDebug = false;

const KeyCodes = {
  enter: 13,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  a: 65,
  b: 66,
  d: 68,
  q: 81,
  i: 73,
  13: 'enter',
  37: 'left',
  38: 'up',
  39: 'right',
  40: 'down',
  65: 'a',
  66: 'b',
  68: 'd',
  81: 'q',
  73: 'i'
};

// function codes(str) {
//   const map = {};
//   str.split('').forEach((c) => {
//     if (!map[c]) {
//       map[c] = c.charCodeAt(0);
//     }
//   });
//   return map;
// }

const KonamiCode = [KeyCodes.up, KeyCodes.up, KeyCodes.down, KeyCodes.down, KeyCodes.left, KeyCodes.right, KeyCodes.left, KeyCodes.right, KeyCodes.b, KeyCodes.a, KeyCodes.enter];

function storeCheat (name, newVal) {
  try {
    cheatMachines[name].enabled = newVal;
    localStorage.setItem('PowerOfGlasses_' + name, JSON.stringify(newVal));
  } catch (err) {
    //
  }
  return newVal;
}

function toggleCheat (name) { return storeCheat(name, !getCheatStored(name)); }

function successfullCheat (name) {
  const retVal = toggleCheat(name);
  if (KonamiDebug) console.log('successfullCheat - NewState:', cheatMachines[name].enabled);
  return retVal;
}

function getCheatStored (name) {
  try {
    return JSON.parse(localStorage.getItem('PowerOfGlasses_' + name)) || false;
  } catch (err) {
    return false;
  }
}

function CheatStateMachine (cheatContext, key) {
  // console.log('CheatStateMachine', cheatContext, key);
  if (cheatContext.code[cheatContext.state] === key || cheatContext.code[cheatContext.state] === KeyCodes[key]) {
    if (KonamiDebug) console.log('CheatStateMachine', cheatContext.name, 'STEP - Got:', key, KeyCodes[key], 'Step:', cheatContext.state);
    cheatContext.state++;
    if (cheatContext.state >= cheatContext.code.length) {
      cheatContext.state = 0;
      cheatContext.toggle();
    }
  } else if (cheatContext.state !== 0) {
    if (KonamiDebug) console.log('CheatStateMachine', cheatContext.name, 'RESET - Got:', key, KeyCodes[key], 'Expected:', cheatContext.code[cheatContext.state], KeyCodes[cheatContext.code[cheatContext.state]], 'Step:', cheatContext.state);
    cheatContext.state = 0;
  }
}

function onKeyUp (event) {
  // console.log('onKeyUp', event.type, event.keyCode, event.key);
  if (event.type === 'keyup' && event.keyCode in KeyCodes) {
    for (const name in cheatMachines) {
      const cheatContext = cheatMachines[name];
      CheatStateMachine(cheatContext, event.keyCode);
    }
  }
}

let partyDeg = 0;
function party() {
  try {
    const elem = document.getElementById('sandbox');
    if (!elem) return;
    partyDeg += 15;
    if (partyDeg > 360) partyDeg = 0;
    elem.style.filter = 'hue-rotate(' + partyDeg + 'deg)';
    // console.log('party', partyDeg, elem.style);
  } catch (err) {
    console.warn('party', err);
  }
}

function unparty() {
  try {
    const elem = document.getElementById('sandbox');
    if (!elem) return;
    elem.style.filter = '';
  } catch (err) {
    console.warn('unparty', err);
  }
}

let partyInterval;
function partyHandler(enabled) {
  if (enabled) {
    partyInterval = setInterval(party, 150);
  } else {
    clearInterval(partyInterval);
    unparty();
  }
}

export default () => {
  try {
    document.addEventListener('keyup', onKeyUp, { capture: false, passive: true });
    // store.state.nerd = getKonamiStored();

    addCheatCode('Konami', KonamiCode, (enabled) => {
      store.state.nerd = enabled;
    });

    addCheatCode('GodMode', ['i', 'd', 'd', 'q', 'd'], (enabled) => {
      store.state.dev = enabled;
    });

    addCheatCode('PartyTime', ['i', 'd', 'd', 'q', 'q'], partyHandler);
  } catch (err) {
    // console.warn('Error:', err);
  }
};

// Init this immediately for better stats
try {
  addCheatCode('Blinkenlights', ['i', 'q', 'q', 'q', 'd'], setShowBlinkenlightsEvent);
} catch (err) {
  // console.warn('Error:', err);
}

export function addCheatCode(name, code, cb) {
  cheatMachines[name] = { name, code, cb, state: 0, enabled: getCheatStored(name),
    toggle: () => {
      const newVal = successfullCheat(name);
      if (cb) cb(newVal);
    },
  };
  if (cb) cb(cheatMachines[name].enabled);
}

// Konami.addCheat(['I', 'D', 'D', 'Q', 'D'], () => { console.log('GODMODE YAY'); })

// === OLD ===

// function toggleKonami () { storeKonami(!store.state.nerd); }

// function successfullKonami () {
//   toggleKonami();
//   if (KonamiDebug) console.log('successfullKonami - NewState:', store.state.nerd);
// }

// Event Feeding
// function onKeyUp (event) {
//   if (event.type === 'keyup' && event.keyCode in KeyCodes) {
//     KonamiStateMachine(event.keyCode);
//   }
// }

// Up Up Down Down Left Right Left Right B A Enter
// function KonamiStateMachine (key) {
//   if (KonamiCode[Konamistate] === key) {
//     if (KonamiDebug) console.log('KonamiStateMachine STEP - Got:', KeyCodes[key], 'Step:', Konamistate);
//     Konamistate++;
//     if (Konamistate >= KonamiCode.length) {
//       Konamistate = 0;
//       successfullKonami();
//     }
//   } else if (Konamistate !== 0) {
//     if (KonamiDebug) console.log('KonamiStateMachine RESET - Got:', KeyCodes[key], 'Expected:', KeyCodes[KonamiCode[Konamistate]], 'Step:', Konamistate);
//     Konamistate = 0;
//   }
// }

// function storeKonami (newVal) {
//   try {
//     store.state.nerd = newVal;
//     localStorage.setItem('PowerOfGlasses', JSON.stringify(newVal));
//     document.dispatchEvent(new CustomEvent('nerdModeToggle', { detail: newVal }));
//   } catch (err) {
//     //
//   }
// }

// let Konamistate = 0;

// function getKonamiStored () {
//   try {
//     return JSON.parse(localStorage.getItem('PowerOfGlasses'));
//   } catch (err) {
//     return false;
//   }
// }
