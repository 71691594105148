// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textRight[data-v-b05acc30] {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./websrc/components/conferenceRightBar/conferenceDocuments.vue","webpack://./conferenceDocuments.vue"],"names":[],"mappings":"AACA;EACE,iBAAA;ACAF","sourcesContent":["\n.textRight {\n  text-align: right;\n}\n",".textRight {\n  text-align: right;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
