<template >
  <v-main style="width:100%;height:100%;" class="px-0 py-0">
    <v-row>

    </v-row>
  </v-main>
</template>

<script>
import store from "../../store";

export default {

  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID

    };
  },
   computed: {
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    }
  },
};
</script>

<style scoped lang="scss">

</style>