<script>
import store, { syncedGroupState } from "../../store";
import { disableCallBtn, callPerson } from "../../utils/calls";
export default {
  components: {},
  data() {
    return {
      state: store.state,
    };
  },
  created() {
    if (this.$route.params.email) {
      const email = this.$route.params.email;
      syncedGroupState().then(() => {
        const uuid = store.getUuidForEmail(email);
        const person = this.state.group[uuid];
        if (person && uuid && !disableCallBtn(person)) {
          callPerson(uuid, this.$router, true);
        } else {
          let body = "Error";
          if (!uuid || !person) {
            body = this.$t("components.externalCall.userNotExist");
          } else {
            if (!person.connected) {
              body = this.$t("components.externalCall.userOffline");
            }else{
              if (person.user.activity === "Out of Office") {
                body = this.$t("components.externalCall.userOutOffice");
              }
              if (person.user.activity === "No Calls") {
                body = this.$t("components.externalCall.userNoCall");
              }
            }
            // if (store.state.isCallFull) {
            //   body = this.$t("components.externalCall.fullCall");
            // }
          }
          this.$router.push({ path: "/home" });
          const data = {
            show: true,
            header: this.$t("generics.info"),
            body: body,
          };
          return store.setinfoModal(data);
        }
      });
    }
  },
  mounted() {},
  methods: {
    disableCallBtn(personObj) {
      let person = personObj;
      return disableCallBtn(person);
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
</style>