// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cursorPointer[data-v-d3bd9300] {
  cursor: pointer;
}
.btnInviteVisitor[data-v-d3bd9300] {
  position: absolute !important;
  right: 57px;
  top: 5px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/visitors.vue","webpack://./visitors.vue"],"names":[],"mappings":"AACA;EACE,eAAA;ACAF;ADEA;EACE,6BAAA;EACA,WAAA;EACA,QAAA;ACCF","sourcesContent":["\n.cursorPointer {\n  cursor: pointer;\n}\n.btnInviteVisitor {\n  position: absolute !important;\n  right: 57px;\n  top: 5px;\n}\n",".cursorPointer {\n  cursor: pointer;\n}\n\n.btnInviteVisitor {\n  position: absolute !important;\n  right: 57px;\n  top: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
