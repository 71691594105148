<template>
<div>
    <v-form :model-value="validData" @update:model-value="validData = $event">
      <v-row flex class="w-100 mx-0 my-0">
        <!-- select waiting room or direct call -->
        <v-row class="mx-0 my-0" v-show="userListToSend.length < 4">
          <v-col cols="6" class="py-0">
            <v-combobox
              :model-value="selectedUserName" @update:model-value="selectedUserName = $event; handleSelect($event)"
              :items="filteredInvitees"
              class="mt-3"
              :label="$t('components.inviteVisitor.name')"
              hide-details
              density="compact"
              variant="outlined"
              item-text="name"
              item-value="id"
              return-object
              :rules="userListToSend.length > 0 ? nada : userNameRules"
              :item-disabled="handlerDisableItem"
              :disabled="atUserLimit"
              @keyup="checkEmail($event)" 
            ></v-combobox>
          </v-col>
          <v-col cols="5" class="py-0">
            <v-combobox
              :model-value="selectedEmail" @update:model-value="selectedEmail = $event; handleSelect($event)"
              :items="filteredInvitees"
              class="mt-3 ml-10"
              :label="$t('components.inviteVisitor.invitationEmail')"
              hide-details
              variant="outlined"
              density="compact"
              item-text="email"
              item-value="id"
              return-object
              :rules="userListToSend.length > 0 ? nada : emailRules"
              :item-disabled="handlerDisableItem"
              :disabled="atUserLimit"
              @keyup="checkEmail($event)"
            ></v-combobox>
          </v-col>
           <v-col cols="1" class="py-0" style="height: 30px;margin-top: 7px;">
             <v-tooltip location="top">
              <template v-slot:activator="{ props }">
                <v-btn 
                v-bind="props" 
                icon 
                style="margin-top: 6px;" 
                @click="addUserToList()"
                :disabled="!validData || isProcessing"
                >
                  <font-awesome-icon :icon="['fal', 'plus-circle']" :style="{ fontSize: '30px' }"/>
                </v-btn>
              </template>
                <span>{{ $t('generics.add') }}</span>
            </v-tooltip>
           </v-col>
        </v-row>
      </v-row>
    </v-form>
    <v-divider class="listDivider"></v-divider>
    <!-- list user to invite -->
    <v-row class="w-100 mx-0 my-0" >
      <v-list-item v-for="(data, index) in userListToSend" :key="index"  :class="{'rowOdd': index % 2 === 0, 'rowEven': index % 2 !== 0 }">
          <v-list-item-content>
            <v-list-item><strong>{{data.name}}:</strong>&nbsp; {{data.email}}</v-list-item>
          </v-list-item-content>
          <v-icon>
            <v-btn icon :disabled="isProcessing" style="margin-top: -9px;">
                <DeleteBasket :indexData="index" :delFunction="removeUserList" extraClasses="" fontSize="20" prevent= false />
            </v-btn>
          </v-icon>
      </v-list-item>
    </v-row>
    <!-- end list user to invite -->
  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
// import Vue from "@vue/compat";
import store, { EventBus } from "../../store";
import { v4 as uuidv4 } from "uuid";
// import { createVisitor } from "../../lib/inviteHelpers";
// import { dispatchSuccessAlert } from "../../effector/alerts";
// import { isEmailRegistered, sendInviteMailCustom } from "../../lib/wsMsg";
import { actingAsUuidStore } from '../../effector/representative';
import DeleteBasket from "../../components/ui/deleteBasket.vue";

//personToAddToGroup
export default {
  data() {
    const effector = {
      actingAsUuid: actingAsUuidStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      invitateType: "waiting",
      isProcessing: false,
      isforMail: false,
      isdup: false,
      dupError: "",
      userNameRules: [(v) => !!v || ""], //this.$t("generics.nameRequired")],
      emailRules: [
        (v) => !!v || "", //remove red mesagge ticket #132
        (v) => /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) || "", //remove red mesagge ticket #132
      ],
      nada:[],
      userListToSend: [],
      selectedUserName: null,
      selectedEmail: null,
      validData: false,
      isWaitingRoom: false,
      // Effector
      ...effector,
    };
  },
  props:['disableItem', 'allSelectedUsers', 'inviteList'],
  components:{ DeleteBasket },
  mounted(){
    EventBus.$emit('setReference', this);
    if (this.inviteList && this.inviteList.length > 0){
      this.userListToSend = this.inviteList;
    }
     // console.log('grandchild mounted')
  },
  watch: {},
  methods: {
    handlerDisableItem(item) {
      if ( this.disableItem ){
        if (this.atUserLimit) return;
        return this.allSelectedUsers.find(e => e.email === item.email || e.name === item.name || e.id === item.id || e.uuid === item.uuid) != undefined;
      }
    },
    handleSelect(evt) {
      // console.log("invitee selected or written");
      if (evt && evt.email && evt.name) {
        this.selectedEmail = evt.email;
        this.selectedUserName = evt.name;
      }
    },
    validateDuplication(iemail) {
      const oemail = this.userListToSend.find((e) => e.email === iemail);
      if (oemail) {
        this.isdup = true;
        this.dupError = "duplicated email";
      } else {
        this.isdup = false;
        this.dupError = "";
      }
    },
  
    getUserTitel(uuid) {
      return store.getTitelForUuid(uuid);
    },

    async addUserToList() {
     // console.log(this.selectedEmail, this.selectedUserName)
      if(!(/^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test((this.selectedEmail || ""))) || this.selectedUserName === null || (this.selectedUserName || "").trim().length < 3){
        return;
      }
      this.dup = false;
      const oemail = this.userListToSend.find(
        (e) => e.email === this.selectedEmail
      );
      // console.log('oemail',oemail)
      if (oemail) {
        this.dup = true;
        return;
      }
      // add to data list to remember if new
      const extant = await store.state.user.outsideInvitees.find(
        (e) => e.email === this.selectedEmail
      );

      if (!extant) {
        const existing = store.state.user.outsideInvitees;
        const newItem = {
          id: uuidv4(),
          name: this.selectedUserName,
          email: this.selectedEmail,
        };
        existing.push(newItem);
        // console.log(existing)
        store.setOutsideInvitees(existing);
      }


      const dupToSend = this.userListToSend.find(
        (e) => e.email === this.selectedEmail
      );

      if(!dupToSend){
        this.userListToSend.push({
          name: this.selectedUserName,
          email: this.selectedEmail,
        });
      }
    
      const dupToInvite = this.inviteList.find(
        (e) => e.email === this.selectedEmail
      );

      if(!dupToInvite){
        this.inviteList.push({
          name: this.selectedUserName,
          email: this.selectedEmail,
        });
      }

      this.cleanForm();
    },
    cleanForm() {
      this.selectedUserName = null;
      this.selectedEmail = null;
    },
    removeUserList(index) {
      this.userListToSend.splice(index, 1);
      this.inviteList.splice(index, 1);
    },
    checkEmail(evt){
      if (/^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(evt.target.value) && this.selectedUserName !== null){
        this.selectedEmail = evt.target.value;
      }else{
         this.selectedEmail = null;
      }
    },
  },
  computed: {
    atUserLimit() {
      if (!this.allSelectedUsers) return false;
      return ((this.allSelectedUsers.length + this.userListToSend.length) >= 4);
    },
    filteredInvitees() {
      let myInvitees = store.state.user.outsideInvitees;
      return myInvitees.filter((invitee) => {
        for (
          let index = 0;
          index < Object.keys(this.userListToSend).length;
          index++
        ) {
          const element = Object.keys(this.userListToSend)[index];
          if (invitee.email === this.userListToSend[element].email) {
            return false;
          }
        }
        return true;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.listDivider {
    margin-top: 16px;
}
.buttonAddUserToList {
  position: relative;
  top: 13px;
}
.rowOdd{
  background-color: #d8dfe02b  !important;
  height: 39px;
}
.rowEven{
  background-color: #b9c2c42b  !important;
  height: 39px;
}
.selactive {
  background-color: #c1c1c166;
}
.w-100 {
  width: 100%;
}
.userNameCard {
  margin-bottom: 0px;
  font-size: 19px;
  color: #2a3133;
}
.dataUserCard {
  margin-bottom: 0px;
}
.borderRadius10,
.v-avatar .v-image {
  border-radius: 10px !important;
}
.w-200 {
  width: 200px;
}
.w-100-230 {
  width: calc(100% - 230px);
}
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
