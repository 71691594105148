// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cursorPointer[data-v-5dd1113c] {
  cursor: pointer;
}
.v-list-item .v-list-item__title[data-v-5dd1113c] {
  line-height: 2.5 !important;
}
.v-list[data-v-5dd1113c] {
  padding: 0;
}
.v-list-item__icon[data-v-5dd1113c] {
  margin-right: 8px !important;
}
.v-card[data-v-5dd1113c]:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.textColor[data-v-5dd1113c] {
  color: #008000;
}
.v-theme--dark .listColor[data-v-5dd1113c] {
  color: white !important;
}
.v-theme--light .listColor[data-v-5dd1113c] {
  color: rgba(0, 0, 0, 0.87) !important;
}
.ctrb[data-v-5dd1113c] {
  left: 15px;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/supportCall.vue","webpack://./supportCall.vue"],"names":[],"mappings":"AACA;EACE,eAAA;ACAF;ADEA;EACE,2BAAA;ACCF;ADCA;EACE,UAAA;ACEF;ADAA;EACE,4BAAA;ACGF;ADDA;EACE,gBAAA;ACIF;ADFA;EACE,cAAA;ACKF;ADHA;EACE,uBAAA;ACMF;ADJA;EACE,qCAAA;ACOF;ADLA;EACE,UAAA;ACQF","sourcesContent":["\n.cursorPointer {\n  cursor: pointer;\n}\n.v-list-item .v-list-item__title {\n  line-height: 2.5 !important;\n}\n.v-list {\n  padding: 0;\n}\n.v-list-item__icon {\n  margin-right: 8px !important;\n}\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}\n.textColor {\n  color: #008000;\n}\n.v-theme--dark .listColor {\n  color: white !important;\n}\n.v-theme--light .listColor {\n  color: rgba(0, 0, 0, 0.87) !important;\n}\n.ctrb {\n  left: 15px;\n}\n",".cursorPointer {\n  cursor: pointer;\n}\n\n.v-list-item .v-list-item__title {\n  line-height: 2.5 !important;\n}\n\n.v-list {\n  padding: 0;\n}\n\n.v-list-item__icon {\n  margin-right: 8px !important;\n}\n\n.v-card:not(.v-sheet--tile):not(.v-card--shaped) {\n  border-radius: 0;\n}\n\n.textColor {\n  color: #008000;\n}\n\n.v-theme--dark .listColor {\n  color: white !important;\n}\n\n.v-theme--light .listColor {\n  color: rgba(0, 0, 0, 0.87) !important;\n}\n\n.ctrb {\n  left: 15px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
