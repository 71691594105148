<template>
 <v-dialog
    :model-value="showModal"
    @update:model-value="showModal = $event" persistent max-width="580" @keydown.esc="toggleMeetingTimeGlobalDuration">
    <v-card>
      <!-- <v-card-title class="headline">
        <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
        {{$t('components.meetingTime.globalDefaultTime')}}
      </v-card-title> -->
      <HeaderModal :titleModal="$t('components.meetingTime.globalDefaultTime')" :closeModalFunction="toggleMeetingTimeGlobalDuration" />
      <v-row class="mx-0 px-3">
        <v-col class="col-12 px-1 py-1 pt-2">
          <v-select
            :model-value="selectGlobalTime"
            @update:model-value="$event => (selectGlobalTime = $event, changeGlobalMeetingDuration())"
            :items="items"
            item-title="state"
            item-value="value"
            :label="$t('components.meetingTime.globalDefaultTime')"
            return-object
          ></v-select>
        </v-col>
      </v-row>
      <!-- /////// Footer area ///////// -->
       <FooterModal :closeModalFunction="toggleMeetingTimeGlobalDuration" />
      <!-- <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
        <v-row class="px-2 btns w-100 mx-0 my-0">
          <template>
            <v-tooltip location="top">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon color="white" @click="toggleMeetingTimeGlobalDuration" class="mr-3">
                  <font-awesome-icon :icon="['fal', 'check']" :style="{ fontSize: '20px' }" />
                </v-btn>
              </template>
              <span>{{ $t('generics.accept') }}</span>
            </v-tooltip>
          </template>
        </v-row>
      </v-card>
      <v-spacer></v-spacer> -->
    </v-card>
  </v-dialog>
</template>
<script>
import store from "../../store";
import { setNamespaceSetting } from "../../lib/wsMsg";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";

export default {
  props: ["toggleMeetingTimeGlobalDuration", "showModal"],
    components:{
    HeaderModal,
    FooterModal
  },
  data() {
    return {
      state: store.state,
      selectGlobalTime: { state: "05:00", value: 300000 },
      items: [
        { state: "03:00", value: 180000 },
        { state: "05:00", value: 300000 },
        { state: "10:00", value: 600000 },
        { state: "20:00", value: 1200000 },
        { state: "30:00", value: 1800000 },
        { state: "40:00", value: 2400000 },
        { state: "50:00", value: 3000000 },
        { state: "60:00", value: 3600000 }
      ]
    };
  },
  mounted: function() {
    this.selectGlobalTime = this.state.namespaceSettings.defaultMeetingDuration;
  },
  methods: {
    changeGlobalMeetingDuration() {
      setNamespaceSetting("defaultMeetingDuration", this.selectGlobalTime);
    }
  },

  computed: {}
};
</script>
<style scoped lang="scss">
</style>