// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w100 {
  width: 100%;
}
.guestWaitingRoomVcardStyles {
  height: calc(100vh - 80px) !important;
  margin-top: 56px;
}
.v-app-bar.v-toolbar.v-theme--light {
  background: rgb(245, 245, 245) !important;
}
.toolbarGuestWaitingRoom {
  height: 80px;
}
@media (max-width: 475px) {
.v-toolbar__content {
    padding-left: 0px !important;
    padding-right: 10px !important;
}
}`, "",{"version":3,"sources":["webpack://./websrc/components/navbar/navbarNew.vue","webpack://./navbarNew.vue"],"names":[],"mappings":"AAIA;EACE,WAAA;ACHF;ADKA;EACE,qCAAA;EACA,gBAAA;ACFF;ADIA;EACE,yCAAA;ACDF;ADGA;EACE,YAAA;ACAF;ADGE;AADF;IAEI,4BAAA;IACA,8BAAA;ACCF;AACF","sourcesContent":["\n// .v-theme--light {\n//   --v-theme-surface: rgb(245,245,245) !important;\n// }\n.w100 {\n  width: 100%;\n}\n.guestWaitingRoomVcardStyles {\n  height: calc(100vh - 80px) !important;\n  margin-top: 56px;\n}\n.v-app-bar.v-toolbar.v-theme--light{\n  background: rgb(245,245,245) !important;\n}\n.toolbarGuestWaitingRoom {\n  height: 80px;\n}\n.v-toolbar__content {\n  @media (max-width: 475px) {\n    padding-left: 0px !important;\n    padding-right: 10px !important;\n  }\n}\n",".w100 {\n  width: 100%;\n}\n\n.guestWaitingRoomVcardStyles {\n  height: calc(100vh - 80px) !important;\n  margin-top: 56px;\n}\n\n.v-app-bar.v-toolbar.v-theme--light {\n  background: rgb(245, 245, 245) !important;\n}\n\n.toolbarGuestWaitingRoom {\n  height: 80px;\n}\n\n@media (max-width: 475px) {\n  .v-toolbar__content {\n    padding-left: 0px !important;\n    padding-right: 10px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
