<template>
 <v-dialog
    :model-value="showModal"
    @update:model-value="showModal = $event" persistent max-width="580" @keydown.esc="toggleMeetingTimeRelay">
    <v-card>
      <!-- <v-card-title class="headline">
        <v-img class="mr-1" src="assets/icon.png" max-height="30" max-width="30" contain></v-img>
        {{$t('components.meetingTime.forceRelay')}}
      </v-card-title> -->
       <HeaderModal :titleModal="$t('components.meetingTime.forceRelay')" :closeModalFunction="toggleMeetingTimeRelay" />
      <v-row class="mx-0 px-3">
        <v-col class="col-12 px-1 py-1 pt-2">
          <v-switch
            class="my-0 py-2"
            @change="userRelay()"
            :disabled="relayByAdmin"
            :model-value="individualRelay" @update:model-value="individualRelay = $event"
            :label="$t('components.adminSettingsModal.relayServerInfo0')"
            width="100%"
          ></v-switch>
          <v-list-item class="py-0 switchWrapper">
            <v-list-item-content>
              <!-- <v-list-item-title>{{ $t('components.adminSettingsModal.relayServerInfo0') }}</v-list-item-title> -->
              <div>
                <p>{{ $t('components.adminSettingsModal.relayServerInfo3') }}</p>
                <p>{{ $t('components.adminSettingsModal.relayServerInfo2') }}</p>
                <p>{{ $t('components.adminSettingsModal.relayServerInfo1') }}</p>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <!-- /////// Footer area ///////// -->
       <FooterModal :closeModalFunction="toggleMeetingTimeRelay" />
      <!-- <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
        <v-row class="px-2 btns w-100 mx-0 my-0">
          <template>
            <v-tooltip location="top">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon color="white" @click="toggleMeetingTimeRelay" class="mr-3">
                  <font-awesome-icon :icon="['fal', 'check']" :style="{ fontSize: '20px' }" />
                </v-btn>
              </template>
              <span>{{ $t('generics.accept') }}</span>
            </v-tooltip>
          </template>
        </v-row>
      </v-card>
      <v-spacer></v-spacer> -->
    </v-card>
  </v-dialog>
</template>
<script>
import store from "../../store";
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";

export default {
  props: ["toggleMeetingTimeRelay", "showModal"],
  components: { HeaderModal, FooterModal },
  data() {
    return {
      state: store.state,
      showRelayText: false,
      individualRelay: store.state.namespaceSettings.forceRelay === false ? store.state.user.individualRelay : store.state.namespaceSettings.forceRelay
    };
  },
  mounted: function() {},
  methods: {
    userRelay() {
      // function to set the individual user relay
      this.state.user.individualRelay = !this.state.user.individualRelay;
    }
  },
  computed: {
    relayByAdmin(){
      return this.state.namespaceSettings.forceRelay
    }
  }
};
</script>
<style scoped lang="scss">
</style>