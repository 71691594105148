// import { timelineDomain.createEvent } from 'effector';
import { noteDomain } from './domain';

export const newNoteEvent = noteDomain.createEvent('newNote');
// export const replyMessageEvent = noteDomain.createEvent('replyMessageEvent');

export const setNoteUUIDsEvent = noteDomain.createEvent('setNoteUUIDs');

export const dispatchFetchMyNotesEvent = noteDomain.createEvent('dispatchFetchMyNotesEvent');
export const dispatchFetchNoteInfoEvent = noteDomain.createEvent('dispatchFetchNoteInfoEvent');
export const dispatchFetchNoteInfosEvent = noteDomain.createEvent('dispatchFetchNoteInfosEvent');

export const setNotesInfoEvent = noteDomain.createEvent('setNotesInfo');
export const setNotesInfoBatchedEvent = noteDomain.createEvent('setNotesInfoBatched');
// export const deleteMessageEntryAndUUIDEvent = noteDomain.createEvent('deleteMessageEntryAndUUID');

// export const dispatchDeleteMessageEvent = noteDomain.createEvent('dispatchDeleteMessage');
// export const dispatchViewedMessageEvent = noteDomain.createEvent('dispatchViewedMessageEvent');
// export const dispatchRepliedMessageEvent = noteDomain.createEvent('dispatchRepliedMessageEvent');
export const dispatchReadedNoteEvent = noteDomain.createEvent('dispatchReadedNoteEvent');
export const dispatchAddOneViewNoteEvent = noteDomain.createEvent('dispatchAddOneViewNoteEvent');
export const dispatchDeleteNoteEvent = noteDomain.createEvent('dispatchDeleteNoteEvent');
export const deleteNoteEntryAndUUIDEvent = noteDomain.createEvent('deleteNoteEntryAndUUID');

// export const markAsViewedSpecificMessageEvent = noteDomain.createEvent('markAsViewedSpecificMessageEvent');
// export const markAsReadSpecificNoteEvent = noteDomain.createEvent('markAsReadSpecificNoteEvent');

// export const dispatchDeleteAllMessageEvent = noteDomain.createEvent('dispatchDeleteAllMessage');

// export const showUserIsTypingEvent = noteDomain.createEvent('showUserIsTypingEvent');
