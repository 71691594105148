import { errorInCallDomain } from './domain';

import { setErrorInCallModalEvent, resetErrorInCallModalEvent } from './events';

export const errorInCallModalStore = errorInCallDomain.createStore(false, { name: 'errorInCallModal' });

errorInCallModalStore.on(setErrorInCallModalEvent, (state, payload) => {
  return payload;
});

errorInCallModalStore.reset(resetErrorInCallModalEvent);
