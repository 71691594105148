// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heightDesktop[data-v-4000ba72] {
  height: calc(100vh - 580px);
}
.heightMobileIOS[data-v-4000ba72] {
  height: calc(100vh - 650px);
}
.heightMobile[data-v-4000ba72] {
  height: calc(100vh - 600px);
}
.contentMessagesIsReply[data-v-4000ba72] {
  height: calc(100% - 52px) !important;
  overflow: auto !important;
}
.cardWidth[data-v-4000ba72] {
  max-width: 300px !important;
  box-shadow: none !important;
}
@media (max-width: 765px) {
.custom6cols[data-v-4000ba72] {
    width: 50% !important;
    min-width: 50% !important;
}
}
@media (max-width: 1082px) {
.cardWidth[data-v-4000ba72] {
    width: 100%;
}
}
@media (min-width: 1082px) {
.cardWidth[data-v-4000ba72] {
    width: 100%;
}
}
.footerCard[data-v-4000ba72] {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./websrc/components/content/waitingRoom/messagesWaitingRoom.vue","webpack://./messagesWaitingRoom.vue"],"names":[],"mappings":"AACA;EACE,2BAAA;ACAF;ADEA;EACE,2BAAA;ACCF;ADCA;EACE,2BAAA;ACEF;ADAA;EACE,oCAAA;EACA,yBAAA;ACGF;ADDA;EACE,2BAAA;EACA,2BAAA;ACIF;ADFA;AACE;IACE,qBAAA;IACA,yBAAA;ACKF;AACF;ADFA;AACE;IACE,WAAA;ACIF;AACF;ADFA;AACE;IACE,WAAA;ACIF;AACF;ADDA;EAEE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;ACEF","sourcesContent":["\n.heightDesktop{\n  height: calc(100vh - 580px);\n}\n.heightMobileIOS{\n  height: calc(100vh - 650px);\n}\n.heightMobile{\n  height: calc(100vh - 600px);\n}\n.contentMessagesIsReply {\n  height: calc(100% - 52px) !important;\n  overflow: auto !important;\n}\n.cardWidth {\n  max-width: 300px !important;\n  box-shadow: none !important;\n}\n@media (max-width: 765px) {\n  .custom6cols {\n    width: 50% !important;\n    min-width: 50% !important;\n  }\n}\n// 6 columns\n@media (max-width: 1082px) {\n  .cardWidth {\n    width: 100%;\n  }\n}\n@media (min-width: 1082px) {\n  .cardWidth {\n    width: 100%;\n  }\n}\n\n.footerCard {\n  //background: #2a3133;\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  //border: 2px solid #2a3133;\n}\n",".heightDesktop {\n  height: calc(100vh - 580px);\n}\n\n.heightMobileIOS {\n  height: calc(100vh - 650px);\n}\n\n.heightMobile {\n  height: calc(100vh - 600px);\n}\n\n.contentMessagesIsReply {\n  height: calc(100% - 52px) !important;\n  overflow: auto !important;\n}\n\n.cardWidth {\n  max-width: 300px !important;\n  box-shadow: none !important;\n}\n\n@media (max-width: 765px) {\n  .custom6cols {\n    width: 50% !important;\n    min-width: 50% !important;\n  }\n}\n@media (max-width: 1082px) {\n  .cardWidth {\n    width: 100%;\n  }\n}\n@media (min-width: 1082px) {\n  .cardWidth {\n    width: 100%;\n  }\n}\n.footerCard {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
