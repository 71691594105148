<template>
  <v-main class="px-0 py-0">
    <v-row>
      <v-col class="mx-2">
        <v-card variant="outlined">
          <div class="d-flex justify-space-between pa-4">
            <span class="subtitle-1 font-weight-bold">
              {{ $t("components.stats.conferenceStats") }}
            </span>
            <span class="d-flex align-center">
              <v-text-field
                class="pt-0"
                style="width: 310px"
                :model-value="search" @update:model-value="search = $event"
                append-icon="mdi-magnify"
                :label="$t('generics.search')"
                single-line
                hide-details
              ></v-text-field>
              <span class="ml-4 pointer" @click="closeStatus()">
                <font-awesome-icon
                  :icon="['fal', 'times']"
                  :style="{ fontSize: '20px' }"
                />
              </span>
            </span>
          </div>
            <v-data-table
              :headers="headers"
              :items="users"
              :items-per-page="itemsPerPage"
              :search="search"
              class="elevation-1"
              :page.sync="page"
              @page-count="pageCount = $event"
              fixed-header
              height="calc(100vh - 365px)"
              @update:items-per-page="getItemPerPage"
            >
              <template v-slot:item.sendMessage="{ item }">
                <v-btn v-if="item" icon variant="text" density="comfortable" @click="setShowModalSenMsg(item.users)">
                  <font-awesome-icon
                    :icon="['fal', 'paper-plane']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
            </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import moment from "../../../../../sharedsrc/moment";
import store from "../../../../store";
import { setSendMessageModalEvent } from "../../../../effector/modals";
export default {
  props: ["users", "headers", "itemsPerPage", "getItemPerPage"],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      search: "",
      setCurrentContentVisile: store.setCurrentContentVisile,
      page: 1,
      pageCount: 0,
      moment: moment,
    };
  },
  methods: {
    setShowModalSenMsg(usersUUID) {
      let temporalArray = usersUUID.filter((uuid) => uuid != this.ownUUID);
      setSendMessageModalEvent({
        show: true,
        users: temporalArray,
        type: "conferenceMsg",
      });
    },
    closeStatus() {
      this.setCurrentContentVisile("home", true, this.$router);
    },
  },
};
</script>

<style lang="scss">
.lockMessageIcon {
  position: absolute;
  right: -6px;
  top: -10px;
}
.pointer {
  cursor: pointer;
}
.v-theme--dark.v-data-table tbody tr td {
  color: white !important;
}
.v-theme--light.v-data-table thead tr th {
  color: black !important;
}
.v-theme--dark.v-data-table thead tr th {
  color: white !important;
}
</style>