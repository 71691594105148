// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cursorPointer[data-v-0fd3fcd2] {
  cursor: pointer;
}
.mt--[data-v-0fd3fcd2] {
  margin-top: -10px;
}
.spanDate[data-v-0fd3fcd2] {
  position: absolute;
  bottom: -11px;
  font-size: 14px;
  font-weight: normal;
  left: 48px;
}
.groupList[data-v-0fd3fcd2] {
  max-height: 275px;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (max-height: 650px) {
.groupList[data-v-0fd3fcd2] {
    max-height: 280px !important;
}
}
.groupListMobile[data-v-0fd3fcd2] {
  max-height: 275px;
  overflow-y: scroll;
  overflow-x: hidden;
}
[data-v-0fd3fcd2]  .v-list-group__header__append-icon {
  min-width: 0px !important;
}
.team-list[data-v-0fd3fcd2] {
  padding: 0 24px;
}
.ctrk[data-v-0fd3fcd2] {
  z-index: 2;
  bottom: -10px;
  left: 15px;
}
.iconsSize20[data-v-0fd3fcd2] {
  width: 20px !important;
  height: 20px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/sidebar/conference.vue","webpack://./conference.vue"],"names":[],"mappings":"AACA;EACE,eAAA;ACAF;ADEA;EACE,iBAAA;ACCF;ADCA;EACE,kBAAA;EACA,aAAA;EACA,eAAA;EACA,mBAAA;EACA,UAAA;ACEF;ADAA;EACE,iBAAA;EACA,kBAAA;EACA,kBAAA;ACGF;ADFE;AAJF;IAKI,4BAAA;ACKF;AACF;ADFA;EACE,iBAAA;EACA,kBAAA;EACA,kBAAA;ACKF;ADFA;EACE,yBAAA;ACKF;ADFA;EACE,eAAA;ACKF;ADHA;EACE,UAAA;EACA,aAAA;EACA,UAAA;ACMF;ADJA;EACE,sBAAA;EACA,uBAAA;ACOF","sourcesContent":["\n.cursorPointer {\n  cursor: pointer;\n}\n.mt-- {\n  margin-top: -10px;\n}\n.spanDate {\n  position: absolute;\n  bottom: -11px;\n  font-size: 14px;\n  font-weight: normal;\n  left: 48px;\n}\n.groupList {\n  max-height: 275px;\n  overflow-y: scroll;\n  overflow-x: hidden;\n  @media screen and (max-height: 650px) {\n    max-height: 280px !important;\n  }\n}\n\n.groupListMobile {\n  max-height: 275px;\n  overflow-y: scroll;\n  overflow-x: hidden;\n}\n\n:deep() .v-list-group__header__append-icon {\n  min-width: 0px !important;\n}\n\n.team-list {\n  padding: 0 24px;\n}\n.ctrk {\n  z-index: 2;\n  bottom: -10px;\n  left: 15px;\n}\n.iconsSize20{\n  width: 20px !important;\n  height: 20px !important;\n}\n",".cursorPointer {\n  cursor: pointer;\n}\n\n.mt-- {\n  margin-top: -10px;\n}\n\n.spanDate {\n  position: absolute;\n  bottom: -11px;\n  font-size: 14px;\n  font-weight: normal;\n  left: 48px;\n}\n\n.groupList {\n  max-height: 275px;\n  overflow-y: scroll;\n  overflow-x: hidden;\n}\n@media screen and (max-height: 650px) {\n  .groupList {\n    max-height: 280px !important;\n  }\n}\n\n.groupListMobile {\n  max-height: 275px;\n  overflow-y: scroll;\n  overflow-x: hidden;\n}\n\n:deep() .v-list-group__header__append-icon {\n  min-width: 0px !important;\n}\n\n.team-list {\n  padding: 0 24px;\n}\n\n.ctrk {\n  z-index: 2;\n  bottom: -10px;\n  left: 15px;\n}\n\n.iconsSize20 {\n  width: 20px !important;\n  height: 20px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
