// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-8dcce972]  .v-btn--icon {
  width: 24px !important;
  height: 24px !important;
}`, "",{"version":3,"sources":["webpack://./websrc/components/config/DiscardableInput.vue","webpack://./DiscardableInput.vue"],"names":[],"mappings":"AACA;EACI,sBAAA;EACA,uBAAA;ACAJ","sourcesContent":["\n:deep() .v-btn--icon {\n    width: 24px !important;\n    height: 24px !important;\n}\n",":deep() .v-btn--icon {\n  width: 24px !important;\n  height: 24px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
