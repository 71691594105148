<template>
  <div class="pdfContainer">
    <pdf
      :src="getPdf"
      @num-pages="pageCount = $event"
      :page="currentPage"
      style="height: calc(100% - 100px); overflow: auto"
      class="mb-3"
    ></pdf>
    <!--style="height: calc(100% - 100px); overflow: auto"-->
    <div class="toolbarPDF pt-1">
      <v-btn flat fab x-small :disabled="noPrevPage" @click="prevPage">
        <font-awesome-icon
          :icon="['fal', 'chevron-left']"
          :style="{ fontSize: '12px'}"
        />
      </v-btn>
      <span  class="mx-2">
      {{currentPage}} - {{pageCount}}</span>
      <v-btn flat fab x-small :disabled="noNextPage" @click="nextPage">
        <font-awesome-icon
          :icon="['fal', 'chevron-right']"
          :style="{ fontSize: '12px' }"
        />
      </v-btn>
    </div>
  </div>
</template>

<script>
import store, { EventBus } from "../../../store";
import pdf from "vue-pdf";
export default {
  components: { pdf },
  data() {
    return {
      state: store.state,
      pageCount: 0,
      zoom: 100,
      currentPage: 1,
    };
  },
  props: [],
  watch: {},
  methods: {
    prevPage() {
      this.currentPage--;
    },
    nextPage() {
      this.currentPage++;
    },
  },
  computed: {
    getPdf() {
      return store.state.namespaceSettings.waitingRoomBackground
    },
    noPrevPage() {
      return this.currentPage <= 1;
    },
    noNextPage() {
      return this.currentPage === this.pageCount;
    },
  },
};
</script>

<style scoped lang="scss">
.pdfContainer{
  height: calc(100vh - 165px);
}
</style>