import store from '../../store';
import { transferVisitor } from '../../lib/wsMsg';
import { callTransferedEvent } from './events';

callTransferedEvent.watch(async (data) => {
  // console.log('handling the event');
  const src = data.source;
  const dest = data.transferedTo;
  const visitor = (store.state.group[src] || {}).user;
  if (visitor && visitor.visitor && visitor.visitorData) {
    const result = await transferVisitor(dest, src);
    if (result) {
      store.setUserVisitor(src, undefined);
    }
  }
});
