<template>
  <v-dialog
     :model-value="showModal"
    @update:model-value="showModal= $event"
    v-if="enabled"
    persistent
    max-width="460"
    @keydown.esc="closeModal"
  >
    <v-card>
      <HeaderModal
        :titleModal="$t('components.2faModal.twoFactorAuthentication')"
        :closeModalFunction="closeModal"
      />
      <v-card-text class="pb-0 mb-0 pt-2">
        <div v-if="enabled === 'sms'" class="d-flex">
          <div class="tex0t-truncate body-1">
            <span>{{ $t("components.2faSms.phoneNumber") }}</span>
            {{ phoneNumber }}
          </div>
        </div>
        <div v-if="enabled === 'sms'" class="d-flex mb-4">
          <v-btn
            variant="outlined"
            density="compact"
            hide-details
            :disabled="requestSmsDone"
            small
            color="error"
            @click="requestSms()"
            >{{ $t("components.2faSms.sendSMS") }}</v-btn
          >
          <span v-if="requestSmsDone" class="px-2 align-center d-flex">{{
            $t("components.2fa.sentSMSMsg")
          }}</span>
        </div>
        <v-alert
          v-if="errMsg && !showCodeInput"
          type="error"
          :dismissible="true"
          >{{ errMsg }}</v-alert
        >
        <div v-if="showCodeInput" class="mt-3">
          <v-text-field
            @keyup.enter="tfaCode && test2FA()"
            autocomplete="off"
            :model-value="tfaCode" @update:model-value="tfaCode = $event"
            :label="tfaCodeLabel"
            placeholder=" "
            variant="outlined"
            density="compact"
            @keydown.space.prevent
            required
            :append-icon="`${showPassword ? 'mdi-eye-off' : 'mdi-eye'}`"
            :type="`${showPassword ? 'text' : 'password'}`"
            hide-details
            @click:append="showPass()"
          ></v-text-field>
          <v-alert v-if="errMsg" type="error" :dismissible="true">{{
            errMsg
          }}</v-alert>
          <div class="d-flex justify-end mt-3"></div>
        </div>
      </v-card-text>
      <!-- footer modal -->
      <FooterModal :closeModalFunction="closeModal">
        <v-btn color="primary" :disabled="!tfaCode" @click="test2FA()">{{
          $t("components.2fa.login")
        }}</v-btn>
      </FooterModal>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../../../store";
import { status2FA, setup2FA, test2FA } from "../../../lib/wsMsg";
import FooterModal from "../../modal/modalComponents/footerModal.vue";
import HeaderModal from "../../modal/modalComponents/headerModal.vue";
export default {
  props: ["showModal", "toggle2faAdminConfirmation"],
  components: {
    FooterModal,
    HeaderModal,
  },
  data() {
    return {
      state: store.state,
      requestSmsDone: false,
      enabled: false,
      pinProtectionType: "",
      errMsg: "",
      tfaCode: "",
      showPassword: false,
    };
  },
  methods: {
    showPass() {
      this.showPassword = !this.showPassword;
    },
    async loadData() {
      if (!this.state.namespaceSettings.adminSettingsEnable2FA) {
        // Namespace does not demand 2FA authentication to access admin settings
        return this.toggle2faAdminConfirmation(true);
      }
      const enabled = await status2FA();
      if (!enabled) {
        // Logged-In User doesn't have 2FA enabled
        return this.toggle2faAdminConfirmation(true);
      }
      if (enabled && enabled.method === "sms") await this.requestSms();
      this.enabled = enabled ? enabled.method : false;
      this.pinProtectionType = enabled.type || "simple";
    },
    closeModal() {
      this.toggle2faAdminConfirmation(false);
    },
    async requestSms() {
      this.errMsg = "";
      const result = await setup2FA("sms").catch((err) => {
        this.errMsg = err.message || err;
        console.warn(err);
        return false;
      });
      if (result) {
        this.requestSmsDone = true;
      } else if (!this.errMsg) {
        this.errMsg = this.$t("components.2faSms.anErrorOccurred");
      }
    },
    async test2FA() {
      this.errMsg = "";
      const result = await test2FA(this.tfaCode).catch((err) => {
        this.errMsg = err.message || err;
        console.warn(err);
        return false;
      });
      if (result) {
        this.toggle2faAdminConfirmation(true);
      } else if (!this.errMsg) {
        this.errMsg = this.$t("components.2faOtp.invalidCode");
      }
    },
  },
  computed: {
    phoneNumber() {
      return this.state.user.mobilePhone &&
        this.state.user.mobilePhone !== "unknown"
        ? this.state.user.mobilePhone
          ? `${this.state.user.mobilePhone}`
          : ""
        : this.state.user.phone
        ? `${this.state.user.phone}`
        : "";
    },
    showCodeInput() {
      return this.requestSmsDone || this.enabled !== "sms";
    },
    tfaCodeLabel() {
      switch (this.enabled) {
        case "otp":
          return this.$t("components.2fa.enterOtpCode");
        case "pin":
          if (this.pinProtectionType === "complex") {
            return this.$t("components.2fa.enterComplexPinCode");
          } else {
            return this.$t("components.2fa.enterPinCode");
          }
        case "sms":
          return this.$t("components.2fa.enterSmsCode");
      }
      return "";
    },
  },
  watch: {
    showModal: {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.loadData();
        } else {
          this.enabled = false;
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
.footerModal {
  width: 100%;
  border-radius: 0px !important;
  color: white !important;
  .btns {
    justify-content: flex-end;
  }
}
</style>
